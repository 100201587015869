// Libs
import React from 'react';
import { connect } from 'react-redux';
// hooks
import { useTheme } from 'hooks';
// Components
import { CommentsBox, CloseIcon, CreatedBy, Modal } from 'components';
// Actions
import { mutate1Object as mutate1ObjectInSupervisor } from 'screens/Supervisor/actions';
// Selectors
import { selectCommentModal } from 'screens/Supervisor/selectors';
import { selectIsMobile } from 'screens/Dashboard/selectors';
// Constants
import GENERAL from 'utils/constants/general';
// CSS
import styles from './CommentModal.module.css';

const { RESOURCES } = GENERAL.ENV;

export const CommentModal = ({
	// state
	commentModal,
	isMobile,
	// actions
	mutate1ObjectInSupervisor,
}) => {
	const { isOpen, selectedElement } = commentModal;
	const { theme } = useTheme();

	const handleOnCancel = () =>
		mutate1ObjectInSupervisor('commentModal', {
			isOpen: false,
			selectedElement: null,
		});

	return (
		<Modal
			visible={isOpen}
			onCancel={handleOnCancel}
			footer={null}
			closable={false}
			centered={isMobile}
			bodyStyle={isMobile ? { height: '100%' } : undefined}
		>
			<div
				className={isMobile ? styles.commentModalMobile : styles.commentModal}
			>
				<div className={styles.closeButtonWrapper}>
					<CloseIcon button onClick={handleOnCancel} />
				</div>
				{selectedElement?.id && (
					<>
						<div
							style={{ backgroundColor: theme._primaryBackgroundColor }}
							className={styles.createdByWrapper}
						>
							<div
								style={{ color: theme._primaryColor }}
								className={styles.collectorName}
							>
								{selectedElement?.name ?? ''}
							</div>
							<div
								style={{ color: theme._primaryColor }}
								className={styles.valueWrapper}
							>
								{selectedElement?.value ?? ''}
							</div>
							<div className={styles.bottom}>
								<CreatedBy
									userName={selectedElement?.userName}
									userId={selectedElement?.createdBy}
									date={selectedElement?.updatedAt}
								/>
							</div>
						</div>
						<CommentsBox
							resourceKey={RESOURCES.KEYS.COLLECTOR}
							resourceId={selectedElement?.id}
							showReplyBox={true}
						/>
					</>
				)}
			</div>
		</Modal>
	);
};

const mapStateToProps = state => ({
	commentModal: selectCommentModal(state),
	isMobile: selectIsMobile(state),
});

const mapDispatchToProps = {
	mutate1ObjectInSupervisor,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentModal);
