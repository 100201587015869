// Libs
import React from 'react';
import { Modal } from '../Utils';
// Utils
import { HeadCreateLineModal } from '../Utils/Modal/subs/Billings/Payments/CreateLineModal';
import { HeadSignPaymentModal } from '../Utils/Modal/subs/Billings/Payments/SignPaymentModal';
import { getSpecificUsers } from 'utils/libs';
import { getSelectedPaycuts } from 'utils/filters/Billings/paycuts';
import { getSelectedPayments } from 'utils/filters/Billings/payments';
// Components
import { HeadSignPaycutModal } from '../Utils/Modal/subs/Billings/Payments/SignPaycutModal';

function ModalBillings(props) {
	const {
		// BILLINGS
		payments,
		paycuts,
		pays,
		signPaycutModal,
		mutate1Object,
		signPaycut,
		signPaymentModal,
		createPaymentModal,
		createPayment,
		getPaycutResume,
		generatePaycutPDF,
		getPaycutsModal,
		getPaycutsResumeModal,
		createPaycutModal,
		signPayment,
		getPaymentResume,
		generatePaymentPDF,
		setPaymentReopen,
		setPaymentReady,
		getPaymentsResumeModal,
		createLineModal,
		createPaymentLine,
		createPaymentLineItem,
		resetCreatePaymentLine,
		enterInvoiceOrdersModal,
		enterInvoiceOrders,
		// ORDERS
		mutate1ObjectInOrders,
		ordersState,
		resetOrderInformation,
		sendToast,
		getOrder,
		// API
		departments,
		services,
		getDepartments,
		users,
		coins,
		line_items,
		getLineItems,
		createPaycut,
		getServices,
		// GENERAL
		profile,
	} = props;
	const allPaycuts = payments.paycuts.data;
	const allPayments = payments.pays.data;

	const totalUsers = props.users.data;
	const { control, getOrderInformationModal } = ordersState;

	const order =
		getOrderInformationModal.order_id &&
		control.orders.find(
			ord => ord.order_id === getOrderInformationModal.order_id,
		);
	const selectedPaycuts = getSelectedPaycuts(
		payments.paycuts.data,
		paycuts.selected.items,
	);
	const selectedPayments = getSelectedPayments(
		allPayments,
		pays.selected.items,
	);
	const firstSelectedPaycut = selectedPaycuts[0];
	const firstSelectedPayment = selectedPayments[0];

	// Renders
	return (
		<>
			{/* SIGN PAYCUT */}
			{paycuts.selected.items.length > 0 && (
				<Modal
					modalId='SignPaycutModal'
					title={
						<HeadSignPaycutModal
							resume_paycut={payments.resume_paycut}
							selectedPaycutsIds={paycuts.selected.items}
							paycuts={allPaycuts}
							profile={profile}
							getPaycutsModal={getPaycutsModal}
							getPaycutResume={getPaycutResume}
							generatingPDF={paycuts.generatingPDF}
							generatePaycutPDF={generatePaycutPDF}
							getPaycutsResumeModal={getPaycutsResumeModal}
							mutate1ObjectInBillings={mutate1Object}
						/>
					}
					visible={signPaycutModal.isOpen}
					onCancel={() => {
						mutate1Object('signPaycutModal', { isOpen: false });
						mutate1Object('getPaycutsResumeModal', { pdfGenerated: false });
					}}
					footer={null}
					isSpinning={payments.resume_paycut.loading}
					sizeSpinning='large'
					delaySpinning={50}
					tipSpinning='Un momento porfavor...'
					customProps={{
						totalUsers: users.data,
						selectedPaycutsIds: paycuts.selected.items,
						paycuts: allPaycuts,
						coins: coins.data,
						signPaycut,
						resume_paycut: payments.resume_paycut,
						signPaycutModal,
						profile,
					}}
				/>
			)}

			{/* CREATE INVOICE PAYCUT */}
			<Modal
				modalId='CreateInvoicePaycutModal'
				title='Crear Corte'
				visible={createPaycutModal.isOpen}
				onCancel={() => mutate1Object('createPaycutModal', { isOpen: false })}
				footer={null}
				isSpinning={createPaycutModal.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					createPaycutModal,
					createPaycut,
					departments: departments.data,
					coins: coins.data,
					mutate1ObjectInBillings: mutate1Object,
					getDepartments,
				}}
			/>

			{/* SIGN PAYMENT */}
			{pays.selected.items.length >= 1 && (
				<Modal
					modalId='SignPaymentModal'
					title={
						<HeadSignPaymentModal
							resume={payments.resume}
							pay={firstSelectedPayment}
							profile={profile}
							signPaymentModal={signPaymentModal}
							mutate1ObjectInBillings={mutate1Object}
							getPaymentResume={getPaymentResume}
							generatingPDF={pays.generatingPDF}
							updateState={pays.updateState}
							generatePaymentPDF={generatePaymentPDF}
							setPaymentReopen={setPaymentReopen}
							setPaymentReady={setPaymentReady}
							getPaymentsResumeModal={getPaymentsResumeModal}
						/>
					}
					visible={signPaymentModal.isOpen}
					onCancel={() => {
						mutate1Object('signPaymentModal', { isOpen: false });
						mutate1Object('getPaymentsResumeModal', { pdfGenerated: false });
					}}
					footer={null}
					isSpinning={payments.resume.loading}
					sizeSpinning='large'
					delaySpinning={50}
					tipSpinning='Un momento porfavor...'
					customProps={{
						totalUsers,
						pay: firstSelectedPayment,
						signPaymentModal,
						signPayment,
						resume: payments.resume,
						coins: coins.data,
						orders: control.orders,
						getOrder,
						mutate1ObjectInOrders,
						inModule: getPaycutsModal.type,
						profile,
					}}
				/>
			)}

			{/* CREATE PAYMENT */}
			{selectedPaycuts.length === 1 && firstSelectedPaycut && (
				<Modal
					modalId='CreatePaymentModal'
					title='Crear Pago'
					visible={createPaymentModal.isOpen}
					onCancel={() =>
						mutate1Object('createPaymentModal', { isOpen: false })
					}
					footer={null}
					isSpinning={createPaymentModal.loading}
					sizeSpinning='large'
					delaySpinning={50}
					tipSpinning='Un momento porfavor...'
					customProps={{
						receivers: getSpecificUsers(totalUsers, {
							entities: true,
							experts: true,
						}),
						createPaymentModal,
						paycutId: firstSelectedPaycut.id,
						profile,
						mutate1ObjectInBillings: mutate1Object,
						createPayment,
					}}
				/>
			)}

			{/* CREATE INVOICE PAYMENT */}
			{selectedPaycuts.length === 1 &&
				firstSelectedPaycut &&
				getPaycutsModal.type === 'invoice' && (
					<Modal
						modalId='CreateInvoicePaymentModal'
						title='Crear Cobro'
						visible={createPaymentModal.isOpen}
						onCancel={() =>
							mutate1Object('createPaymentModal', { isOpen: false })
						}
						footer={null}
						isSpinning={createPaymentModal.loading}
						sizeSpinning='large'
						delaySpinning={50}
						tipSpinning='Un momento porfavor...'
						customProps={{
							createPaymentModal,
							paycut: firstSelectedPaycut,
							services,
							mutate1ObjectInBillings: mutate1Object,
							createPayment,
							getServices,
							sendToast,
						}}
					/>
				)}

			{/* IMPORT INVOICE ORDERS */}
			{selectedPayments.length === 1 && firstSelectedPayment && (
				<Modal
					modalId='EnterInvoiceOrdersModal'
					title='Ingresar órdenes'
					visible={enterInvoiceOrdersModal.isOpen}
					footer={null}
					onCancel={() => resetOrderInformation()}
					isSpinning={enterInvoiceOrdersModal.loading}
					sizeSpinning='large'
					delaySpinning={50}
					tipSpinning='Ingresando órdenes...'
					customProps={{
						pay: firstSelectedPayment,
						enterInvoiceOrdersModal,
						profile,
						mutate1ObjectInBillings: mutate1Object,
						enterInvoiceOrders,
					}}
				/>
			)}

			{/* CREATE LINE */}
			{selectedPayments.length === 1 && firstSelectedPayment && (
				<Modal
					modalId='CreateLineModal'
					title={
						<HeadCreateLineModal
							createLineModal={createLineModal}
							mutate1ObjectInBillings={mutate1Object}
						/>
					}
					visible={createLineModal.isOpen}
					onCancel={() => {
						mutate1Object('createLineModal', { isOpen: false });
						resetCreatePaymentLine();
					}}
					footer={null}
					isSpinning={createLineModal.loading}
					sizeSpinning='large'
					delaySpinning={50}
					tipSpinning='Un momento porfavor...'
					customProps={{
						line_items,
						payId: firstSelectedPayment.id,
						paidTo: firstSelectedPayment.paid_to,
						createLineModal,
						coins: coins.data,
						getLineItems,
						mutate1ObjectInBillings: mutate1Object,
						createPaymentLine,
						createPaymentLineItem,
					}}
				/>
			)}
		</>
	);
}

export default ModalBillings;
