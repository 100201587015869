/**
 * Welcome to MenuBuilderConfig
 * Max steps to implement new Menu: 3
 */

import KEYWORDS from '../keywords';

/**
 * Step 1: Import custom menu to render in Feed
 */
import { PhotoReportMenu } from '../ElementViewBuilder/views/PhotoReportViewer/Menu';
const { ROUTES } = KEYWORDS;
// <--HERE

/**
 * Step 2: Add custom menu keys
 */
const authorizedMenuKeys = {
	SUPERVISOR_ELEMENT: `${ROUTES.FEED.PATH}/supervisor/:elementId`,
	// <--HERE
};

/**
 * Step 3: Add custom menu key value pairs
 */
const authorizedMenuComponents = {
	[authorizedMenuKeys.SUPERVISOR_ELEMENT]: PhotoReportMenu,
	// <--HERE
};

class MenuBuilderConfig {
	static getAuthorizedMenuReactNodes() {
		return authorizedMenuComponents;
	}
}

export default MenuBuilderConfig;
