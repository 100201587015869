// Libs
import React from 'react';
// Hooks
import { useTheme } from 'hooks';
// Components
import { Grid } from 'components';

const SharedGridScreen = ({
	children,
	isMobile,
	rootObserverRef,
	customGridConfig,
}) => {
	const { theme, isDarkTheme } = useTheme();
	return (
		<Grid
			ref={rootObserverRef}
			width='100%'
			height='calc(100vh - 56px)'
			padding={isMobile ? '16px 0' : '16px'}
			className='animated fadeIn'
			overflowY='auto'
			gap='16px'
			justifyContent='center'
			alignItems='baseline'
			alignContent='baseline'
			backgroundColor={
				isDarkTheme
					? theme._primaryBackgroundColor
					: theme._secondaryBackgroundColor
			}
			{...customGridConfig}
		>
			{children}
		</Grid>
	);
};

export default SharedGridScreen;
