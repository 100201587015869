// Constants
import GENERAL from '../../utils/constants/general';
// Validators
import { requestGenerateReportValidator } from './reports.validator';

const { REPORTS, DASHBOARD } = GENERAL;

export function mutateDirectProps(keyValuePairs) {
	return {
		type: REPORTS.MUTATE_DIRECT_PROPS,
		payload: keyValuePairs,
	};
}

export function mutate1Object(obj1Name, keyValuePairs) {
	return {
		type: REPORTS.MUTATE_1OBJECT,
		payload: { obj1Name, keyValuePairs },
	};
}

export function fetchReports() {
	return {
		type: REPORTS.FETCH_REPORTS,
	};
}

export function resetGenerateReportModal() {
	return {
		type: REPORTS.RESET_GENERATE_REPORT_MODAL,
	};
}

export function requestGenerateReport(payload, reportParams) {
	const error = requestGenerateReportValidator(payload, reportParams);

	if (error) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: error,
				type: 'warn',
			},
		};
	}

	return {
		type: REPORTS.REQUEST_GENERATE_REPORT,
		payload: {
			reportId: payload.reportId,
			reportTable: payload.reportTable,
			toEmails: payload.toEmails,
			ccEmails: payload.ccEmails,
			subject: payload.subject,
			message: payload.message,
			params: payload.params,
		},
	};
}
