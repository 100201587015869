// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
// Utils
import { getRowHeightInvoiceOrdersMobile } from '../../../utils';
import { getSelectedPayments } from 'utils/filters/Billings/payments';
import { filterPaymentOrders } from 'utils/filters/Billings/orders';
import GENERAL from 'utils/constants/general';
// Components
import { VirtualizedList } from 'screens/Utils';
import { Wrapper, Empty } from 'components';

const { ENV } = GENERAL;

function PaymentsOrdersMobile(props) {
	if (
		!Array.isArray(props?.pays?.selected?.items) ||
		props?.pays?.selected?.items?.length === 0 ||
		!props?.pays?.getOrders
	) {
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYS_MOBILE} />;
	}

	const {
		// BILLINGS
		pays,
		orders,
		mutate1Object,
		// ORDERS
		mutate1ObjectInOrders,
		ordersState,
		getOrder,
		// API
		payments,
		coins,
		// LOGIN
		profile,
	} = props;
	const allOrders = payments.orders.data;
	const { control } = ordersState;

	const selectedPayments = getSelectedPayments(
		payments.pays.data,
		pays.selected.items,
	);
	const firstSelectedPayment = selectedPayments[0];

	const filteredPaymentOrders = filterPaymentOrders(allOrders, orders.filters);

	const rowHeight = getRowHeightInvoiceOrdersMobile(pays.getOrders);

	return (
		<Wrapper padding='8px' justifyContent='center' className='animated fadeIn'>
			{!payments.orders.loading && filteredPaymentOrders.length === 0 ? (
				<Empty style={{ marginTop: '20px' }} />
			) : (
				<VirtualizedList
					cardId='invoiceOrderCard'
					listStyle={{
						height: document.documentElement.clientHeight,
						width: document.documentElement.clientWidth,
					}}
					rowHeight={rowHeight}
					spinTitle='Consultando Órdenes...'
					isSpinning={payments.orders.loading}
					rows={filteredPaymentOrders}
					customProps={{
						pay: firstSelectedPayment,
						orders: control.orders,
						editedOrders: [...orders.editedOrders],
						mutate1ObjectInBillings: mutate1Object,
						mutate1ObjectInOrders,
						getOrder,
						mode: orders.mode,
						paysManage: pays,
						rowHeight,
						profile,
						coins: coins.data,
					}}
				/>
			)}
		</Wrapper>
	);
}

export default PaymentsOrdersMobile;
