// Libs
import React from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import IconContainer from 'components/IconContainer';
import MediumIcon from './MediumIcon';
import NormalIcon from './NormalIcon';

const SelectedIcon = ({ size, fill }) => {
	return {
		// large: <LargeIcon fill={fill} />,
		medium: <MediumIcon fill={fill} />,
		normal: <NormalIcon fill={fill} />,
		// small: <SmallIcon fill={fill} />,
	}[size];
};

const BillingIcon = ({ hoverable, size = 'normal', selected, button }) => {
	const { theme } = useTheme();
	const [hover, setHover] = React.useState(false);

	const fill = React.useMemo(
		() =>
			hoverable && (hover || selected)
				? theme._secondaryColor
				: theme._mediumEmphasisColor,
		[hover, selected],
	);

	return (
		<div
			onMouseEnter={hoverable ? () => setHover(true) : null}
			onMouseLeave={hoverable ? () => setHover(false) : null}
		>
			{button ? (
				<IconContainer size={size}>
					<SelectedIcon size={size} fill={fill} />
				</IconContainer>
			) : (
				<SelectedIcon size={size} fill={fill} />
			)}
		</div>
	);
};

export default BillingIcon;
