import React from 'react';

const StarIconSVG = ({ fill, fillOpacity = '0.80', onClick }) => (
	<svg
		onClick={onClick}
		width='6.5'
		height='6.5'
		viewBox='0 0 4 4'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M0.950195 1.99609V1.71582C0.950195 1.32389 1.07324 1.00033 1.31934 0.745117C1.56999 0.489909 1.90951 0.362305 2.33789 0.362305C2.77083 0.362305 3.11263 0.489909 3.36328 0.745117C3.61393 1.00033 3.73926 1.32389 3.73926 1.71582V1.99609C3.73926 2.38346 3.61393 2.70475 3.36328 2.95996C3.11719 3.21061 2.77767 3.33594 2.34473 3.33594C1.91634 3.33594 1.57682 3.21061 1.32617 2.95996C1.07552 2.70475 0.950195 2.38346 0.950195 1.99609Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);

export default StarIconSVG;
