// Libs
import React from 'react';
import { useSelector } from 'react-redux';
// Utils
import BuildDependencyRowData from './BuildDependencyRowData';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Components
import DataFieldSwitcher from 'components/DataFieldManager/Switcher';
import { OverviewField } from 'components/DataFieldManager/types';
import { Wrapper, Span, Divider } from 'components';

const CustomItemValue = ({ field, rowData, profile }) => {
	const fieldActionTypes = {
		[field.type]: {
			// onChange: handleCustomValue,
		},
	};
	return (
		<DataFieldSwitcher
			field={field}
			rowData={rowData}
			fieldActionTypes={fieldActionTypes}
			profile={profile}
		/>
	);
};

const Field = ({ field, profile, rowData }) => {
	return (
		<Wrapper
			margin='5px 0px'
			padding='0 10px'
			width='100%'
			justifyContent='space-between'
			alignItems='baseline'
		>
			{/* Concept */}
			<Wrapper width='100%' justifyContent='start' margin='0px' padding='0px'>
				<Span disabled>{field.name}</Span>
			</Wrapper>

			{/* Value */}
			<Wrapper width='100%' justifyContent='start' margin='0px' padding='0px'>
				<CustomItemValue field={field} rowData={rowData} profile={profile} />
			</Wrapper>
		</Wrapper>
	);
};
const Fields = ({ section, profile, metadata }) => {
	return section.fields.map(f => {
		const field = new OverviewField(f);
		if (field.main) return null;

		const rowData = new BuildDependencyRowData(field, metadata).run();
		return (
			<Field
				key={field.id.toString()}
				field={field}
				rowData={rowData}
				profile={profile}
			/>
		);
	});
};

const SectionHeader = ({ section }) => {
	const mainItem = section.fields.find(field => field?.main);
	const value = mainItem?.uiValue || mainItem?.value;
	return (
		<Wrapper
			margin='10px 0'
			padding='0 10px'
			width='100%'
			justifyContent='space-between'
			alignItems='baseline'
		>
			{/* Title */}
			<Span width='100%' disabled>
				{section.title}
			</Span>
			{/* Value */}
			<Span width='100%' emphasis>
				{value || '-'}
			</Span>
		</Wrapper>
	);
};

export default function DetailsTab({ orderId, data, metadata }) {
	const profile = useSelector(selectProfile);

	if (!data || !orderId) return null;

	const sections = Object.keys(data)
		.map(section => ({
			section: data[section],
			sort: data[section].sort,
		}))
		.sort((a, b) => (a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0));

	return (
		<Wrapper
			padding='0'
			flexDirection='column'
			overflow='auto'
			height={`${document.documentElement.clientHeight - 65}px`}
		>
			{sections.map(({ section }, idx) => {
				if (!section) return null;

				return (
					<Wrapper
						flexDirection='column'
						margin='0px'
						padding='0px'
						width='100%'
						key={idx}
					>
						<SectionHeader section={section} />
						<Fields section={section} profile={profile} metadata={metadata} />
						{idx + 1 < sections.length && <Divider />}
					</Wrapper>
				);
			})}
		</Wrapper>
	);
}
