// Libs
import React from 'react';
import styled from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Utils
import MODULE_KEYWORDS from 'components/Modules/keywords';
// Components
import Central16 from './Central/Central16';
import Residential16 from './Residential/Residential16';
import Cabinet16 from './Cabinet/Cabinet16';
import Radiobase16 from './Radiobase/Radiobase16';
import RadioV2 from 'components/RadioV2';

const { ICONS } = MODULE_KEYWORDS.MODULES.ITEMS.ICON;
const Icon = styled.div`
	height: 16px;
	margin-right: 10px;
	text-align: center;
`;

const OrderTargetIcon = ({ value, isSelected }) => {
	const { theme } = useTheme();
	const fill = React.useMemo(
		() => (isSelected ? theme._secondaryColor : theme._mediumEmphasisColor),
		[isSelected],
	);

	if (!ICONS[value]) return <RadioV2 isSelected={isSelected} />;

	return (
		<Icon>
			{value === ICONS.CENTRAL && <Central16 fill={fill} />}
			{value === ICONS.RESIDENTIAL && <Residential16 fill={fill} />}
			{value === ICONS.CABINET && <Cabinet16 fill={fill} />}
			{value === ICONS.RADIOBASE && <Radiobase16 fill={fill} />}
		</Icon>
	);
};

export default OrderTargetIcon;
