// Libs
import { all } from 'redux-saga/effects';
// Sagas
import feed from 'core/saga';
import login from 'screens/Login/saga';
import signup from 'screens/Signup/saga';
import dashboard from 'screens/Dashboard/saga';
import users from 'screens/Users/saga';
import clients from 'screens/Clients/saga';
import contracts from 'screens/Contracts/saga';
import orders from 'screens/Orders/saga';
import billings from 'screens/Billings/saga';
import warehouses from 'screens/Warehouses/saga';
import analytics from 'screens/Analytics/saga';
import reports from 'screens/Reports/reports.saga';
import collector from 'components/Collector/saga';
import uploadResource from 'components/UploadResource/saga';
import manageInventory from 'components/ConsumeInventory/saga';
import modules from 'components/Modules/saga';
import api from './api/saga';

//  Method 1:
export default function* rootSaga() {
	yield all([
		feed(),
		login(),
		signup(),
		dashboard(),
		users(),
		clients(),
		contracts(),
		orders(),
		billings(),
		warehouses(),
		analytics(),
		reports(),
		collector(),
		uploadResource(),
		manageInventory(),
		modules(),
		api(),
	]);
}

//  Method 2:
// function startSagas(...sagas) {
//   return function* rootSaga() {
//     yield sagas.map(saga => fork(saga))
//   }
// }

// const rootSaga = startSagas(login)

// export default rootSaga
