// Libs
import React from 'react';
import { connect } from 'react-redux';
// Selectors
import { selectModeView } from 'screens/Dashboard/selectors';
import { selectProfile } from 'screens/Login/selectors';
// Components
import { AutoSyncOfflineData } from 'components';
import { RealtimeCoordProvider } from 'components/CoordManager';
import { Connect as CoreConnect } from 'core';

const Feed = React.memo(({ modeView, profile }) => (
	<RealtimeCoordProvider profile={profile}>
		<CoreConnect isMobile={modeView === 'cards'} profile={profile} />
		<AutoSyncOfflineData />
	</RealtimeCoordProvider>
));

const mapStateToProps = state => ({
	modeView: selectModeView(state),
	profile: selectProfile(state),
});
export default connect(mapStateToProps)(Feed);
