// Libs
import React from 'react';
import { useTheme } from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// Components
import { Wrapper, Span } from 'components';

export default function ShowErrorCard({ error, counter }) {
	const theme = useTheme();
	return (
		<Wrapper
			height='65px'
			padding='10px'
			backgroundColor='rgba(0,0,0,0.5)'
			borderRadius='10px'
		>
			<CopyToClipboard text={error}>
				<Span
					hoverable
					maxHeight='65px'
					fontWeight='bold'
					fontSize='xl'
					color={theme.fonts.colors.warn}
					overflow='auto'
				>
					{error}
				</Span>
			</CopyToClipboard>
		</Wrapper>
	);
}
