// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mediumInventoryFamilyIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='4'
			y='2'
			width='26'
			height='26'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.6667 9.78663L22.2133 2.2533L29.76 9.78663L22.2133 17.3333L14.6667 9.78663ZM14.6667 9.78663V14.6666H4V3.99996H14.6667V9.78663ZM22.2133 17.3333H17.3333V28H28V17.3333H22.2133ZM14.6667 28H4V17.3333H14.6667V28Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mediumInventoryFamilyIcon)'>
			<rect width='32' height='32' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
