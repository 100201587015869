//Libs
import React from 'react';
//Components
import { Wrapper, Span, Button, Modal, LogoIcon } from 'components';

const ConfirmationButtons = ({
	confirmMessage,
	isLoading,
	onConfirm,
	onCancel,
}) => {
	return (
		<>
			<Span>{confirmMessage}</Span>
			<Wrapper width='100%' justifyContent='space-evenly'>
				<div style={{ marginRight: '10px' }}>
					<Button mode='secondary' onClick={onCancel}>
						Cancelar
					</Button>
				</div>
				<div>
					{isLoading ? (
						<LogoIcon spin={true} />
					) : (
						<Button mode='primary' boxShadow radius onClick={onConfirm}>
							Confirmar
						</Button>
					)}
				</div>
			</Wrapper>
		</>
	);
};

const ConfirmOrder = ({
	title,
	confirmMessage,
	visible,
	isLoading,
	onConfirm,
	onCancel,
}) => {
	return (
		<Modal visible={visible} title={title} footer={null} onCancel={onCancel}>
			<Wrapper
				height='160px'
				flexDirection='column'
				justifyContent='space-around'
			>
				<ConfirmationButtons
					confirmMessage={confirmMessage}
					isLoading={isLoading}
					onConfirm={onConfirm}
					onCancel={onCancel}
				/>
			</Wrapper>
		</Modal>
	);
};

export default ConfirmOrder;
