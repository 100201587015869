// Services
import ApiServiceV2 from 'services/apiService/apiServiceV2';

const endpoint = '/warehouses_transactions_docs_items';
const useWarehouseTransactionDocItemApi = () => {
	return {
		/**
		 * Get materials from billed order
		 * @param {Object[]} items
		 * @param {int} items[0].id
		 * @param {boolean} items[0].value
		 * @returns void
		 */
		updateTransactionItems: (items, operation) =>
			ApiServiceV2.put({
				url: `${endpoint}`,
				data: { items, operation },
			}),
	};
};

export default useWarehouseTransactionDocItemApi;
