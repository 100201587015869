// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'underscore';
// Utils
import GENERAL from 'utils/constants/general';
// Components
import { TableVirtualized, Flag, Span, Button } from 'components';
// Styles
import { filterTransactions } from 'utils/filters/Warehouses/transactions';
import { getArea, getZone } from '../../../utils';
import '../../../css/Transactions.css';

const { ENV, DIVISION_ID } = GENERAL;

const { Table, Column, Header, Row, Cell } = TableVirtualized;

function ItemsTransactionDocDesktop(props) {
	const {
		transactions,
		itemsTransactionDoc,
		transactionsManage,
		createPackage,
		warehousePackages,
		warehouseAreas,
		warehouseZones,
		mutate1Object,
		mutate1ObjectApi,
		sendToast,
		profile,
	} = props;
	const itemsData = [...transactions.items.data];
	const transaction = React.useMemo(
		() =>
			transactionsManage.docId &&
			transactions.data.find(trn => trn.id === transactionsManage.docId),
		[transactionsManage.docId, transactions.data],
	);

	const filteredInventoryItems = React.useMemo(
		() => filterTransactions(itemsData, itemsTransactionDoc.filters),
		[itemsData, itemsTransactionDoc.filters],
	);

	if (!transactionsManage.docId) {
		return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_DESKTOP} />;
	}

	// Functions
	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: itemsData,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: itemsTransactionDoc.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('itemsTransactionDoc', {
							filters: updatedFilters,
						}),
				}}
			>
				{label}
			</Header>
		);
	}

	function getIndexCellRenderer({ rowData, rowIndex }) {
		let flagPriorityStyle = {};

		if (rowData.priority) flagPriorityStyle = { ...rowData.priority.style };
		return (
			<Cell>
				<Flag style={flagPriorityStyle} height='20px' />
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	}

	function onAddSerieToPackage(serie, warehouse_package_id) {
		const data = itemsData.map(item => {
			if (item.serie === serie)
				item.warehouse_package_id = warehouse_package_id;
			return item;
		});
		mutate1ObjectApi('transactions', {
			items: { ...transactions.items, data },
		});
	}

	function getPackageCellRenderer({ rowData }) {
		const idx = _.findIndex(itemsData, item => item.id === rowData.id);

		// Delete serie from package
		function onDeleteSerieFromPackage() {
			itemsData[idx].warehouse_package_id = null;
			mutate1ObjectApi('transactions', {
				items: { ...transactions.items, data: itemsData },
			});
		}

		// create non-serialized package
		function onCreateNonSerializedPackage() {
			// Is there an open series package?
			if (createPackage.created && createPackage.serialized)
				sendToast({
					message: 'Primero complete el paquete de series abierto',
					type: 'warn',
				});
			// Was a package already created for the article found?
			const packages = warehousePackages.data.filter(
				pkg => pkg.item_id === rowData.item_id,
			);
			if (packages.length === 1) {
				itemsData[idx].warehouse_package_id = packages[0].id;
				mutate1ObjectApi('transactions', {
					items: { ...transactions.items, data: itemsData },
				});
			} else
				mutate1Object('createPackage', {
					operation: itemsTransactionDoc.operation,
					isOpen: true,
					serialized: false,
					id: undefined,
					item_id: rowData.item_id,
					state_id: undefined,
					amount: undefined,
					warehouse_area_id: undefined,
					warehouse_zone_id: undefined,
					created: false,
					mustCreateZone: false,
					mustCreateArea: false,
					zone: undefined,
					area: undefined,
				});
		}

		// Mouse enter id package
		function onMouseEnterIdPackage() {
			itemsData[idx].insideIdPackage = true;
			mutate1ObjectApi('transactions', {
				items: { ...transactions.items, data: itemsData },
			});
		}

		// Mouse leave id package
		function onMouseLeaveIdPackage() {
			itemsData[idx].insideIdPackage = false;
			mutate1ObjectApi('transactions', {
				items: { ...transactions.items, data: itemsData },
			});
		}

		return itemsTransactionDoc.operation !== 'reception' ? (
			<div>{rowData.warehouse_package_id}</div>
		) : (
			<div className='desktop-transactions-table-index-cell-container'>
				{!rowData.is_serialized ? (
					rowData.warehouse_package_id ? (
						<div>{rowData.warehouse_package_id}</div>
					) : (
						<Button
							className='desktop-transactions-button-add-delete-serie'
							onClick={onCreateNonSerializedPackage}
						>
							Nuevo
						</Button>
					)
				) : rowData.warehouse_package_id ? (
					<div
						style={{ width: '100%' }}
						onMouseEnter={onMouseEnterIdPackage}
						onMouseLeave={onMouseLeaveIdPackage}
					>
						{!rowData.insideIdPackage ? (
							<div>{rowData.warehouse_package_id}</div>
						) : (
							<Button
								className='desktop-transactions-button-add-delete-serie'
								style={{ color: 'darkgray' }}
								onClick={onDeleteSerieFromPackage}
							>
								Quitar
							</Button>
						)}
					</div>
				) : (
					createPackage.created &&
					createPackage.serialized && (
						<Button
							className='desktop-transactions-button-add-delete-serie'
							onClick={() =>
								onAddSerieToPackage(rowData.serie, createPackage.id)
							}
						>
							Agregar
						</Button>
					)
				)}
			</div>
		);
	}

	function getRowRenderer({ key, columns, style, rowData }) {
		const selectedItem = itemsTransactionDoc.selectedItems.find(
			itemCode => itemCode === rowData.item_code,
		);
		let styleRow = { ...style };

		// Is transaction selected?
		if (selectedItem) {
			styleRow = {
				...styleRow,
				color: 'lightseagreen',
				backgroundColor: 'rgb(44,44,54)',
			};
		}

		return (
			<Row key={key} style={styleRow}>
				{columns}
			</Row>
		);
	}

	// Render
	return (
		<Table
			highBackground
			width={document.documentElement.clientWidth}
			height={document.documentElement.clientHeight - 115}
			headerHeight={44}
			rowHeight={40}
			rowCount={filteredInventoryItems.length}
			rowGetter={({ index }) => filteredInventoryItems[index]}
			rowRenderer={getRowRenderer}
		>
			<Column
				dataKey='index'
				label='#'
				width={50}
				headerRenderer={({ label }) => <Header>{label}</Header>}
				cellRenderer={getIndexCellRenderer}
			/>
			<Column
				dataKey='item_code'
				label='Código'
				width={100}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
			/>
			<Column
				dataKey='item_name'
				label='Nombre'
				width={600}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
			/>
			<Column
				dataKey='serialized'
				label='Serializado'
				width={100}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
			/>
			{/* <Column label="Valor" width={90} headerRenderer={getHeaderRenderer} cellRenderer={getValueCellRendered} /> */}
			{transaction && transaction.transaction_type_id === 28 && (
				<Column
					dataKey='action_label'
					label='Acción'
					width={100}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
			)}
			<Column
				dataKey='amount'
				label='Cantidad'
				width={100}
				headerRenderer={props => getHeaderRenderer(props, 'numeric')}
			/>
			<Column
				dataKey='serie'
				label='Serie'
				width={200}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
			/>
			<Column
				dataKey='state_name'
				label='Estado'
				width={100}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
			/>
			{transaction &&
				transaction.dwh_owner_division_id === DIVISION_ID.ENTITY &&
				transaction.dwh_owner_id === profile.entity.id && (
					<Column
						dataKey='zone_name'
						label='Zona'
						width={130}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={({ rowData }) =>
							getZone(
								rowData.warehouse_package_id,
								warehousePackages.data,
								warehouseAreas.data,
								warehouseZones.data,
							)
						}
					/>
				)}
			{transaction &&
				transaction.dwh_owner_division_id === DIVISION_ID.ENTITY &&
				transaction.dwh_owner_id === profile.entity.id && (
					<Column
						dataKey='area_name'
						label='Area'
						width={130}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={({ rowData }) =>
							getArea(
								rowData.warehouse_package_id,
								warehousePackages.data,
								warehouseAreas.data,
							)
						}
					/>
				)}
			{transaction &&
				transaction.dwh_owner_division_id === DIVISION_ID.ENTITY &&
				transaction.dwh_owner_id === profile.entity.id && (
					<Column
						dataKey='warehouse_package_id'
						label='Paquete'
						width={80}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getPackageCellRenderer}
					/>
				)}
		</Table>
	);
}

export default ItemsTransactionDocDesktop;
