// Libs
import React from 'react';
export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='normalSerieIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='1'
			y='4'
			width='23'
			height='16'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17 4L10 8.04V15.96L17 20L24 15.96V8.04L17 4ZM17 6.31L20.8 8.5L17 10.69L13.21 8.5L17 6.31ZM1 9V7H8V9H1ZM12 10.11L16 12.42V17.11L12 14.81V10.11ZM22 14.81V10.11L18 12.42V17.11L22 14.81ZM3 11V13H8V11H3ZM5 17V15H8V17H5Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#normalSerieIcon)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
