// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Utils
import GENERAL from 'utils/constants/general';
import { xlsx } from 'utils/libs';
// Components
import {
	Wrapper,
	Link,
	IconButton,
	Popover,
	Menu,
	RefreshIcon,
	LogoIcon,
	FiltersIcon,
	ExcelIcon,
} from 'components';
// Styles
import '../index.css';

const { ENV } = GENERAL;

export default function WarehousesList({
	showPopoverMode,
	warehouses,
	stock,
	control,
	mutate1ObjectInWarehouses,
	mutate1ObjectApi,
	getWarehouses,
	getStockArticles,
	profile,
	modeView,
}) {
	const theme = useTheme();
	const filterContent = (
		<>
			<Menu.Wrapper>
				<Menu.Item
					style={
						control.mode === 'individual'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() =>
						mutate1ObjectInWarehouses('control', {
							mode: 'individual',
							showPopoverMode: false,
						})
					}
				>
					Individual
				</Menu.Item>
				<Menu.Item
					style={
						control.mode === 'consolidate'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() =>
						mutate1ObjectInWarehouses('control', {
							mode: 'consolidate',
							showPopoverMode: false,
						})
					}
				>
					Consolidado
				</Menu.Item>
				<Menu.Item
					style={
						control.mode === 'all'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
				>
					<Link
						to={
							modeView === 'table'
								? ENV.ROUTES.PATHS.WAREHOUSES_STOCK_ARTICLES_DESKTOP
								: ENV.ROUTES.PATHS.WAREHOUSES_STOCK_ARTICLES_MOBILE
						}
						onClick={() => {
							mutate1ObjectApi('stock', {
								...stock,
								warehouseId: 'all',
								ownerId: profile.organization.settings.mainEntity,
							});
							mutate1ObjectInWarehouses('control', {
								mode: 'all',
								showPopoverMode: false,
							});
							getStockArticles(
								'all',
								profile.organization.settings.mainEntity,
								'all',
							);
						}}
					>
						Todos
					</Link>
				</Menu.Item>
			</Menu.Wrapper>
		</>
	);

	return (
		<Wrapper
			height='48px'
			justifyContent='space-between'
			padding='10px 0 10px 0'
			mediumBackground
		>
			<div />
			<Wrapper padding='0'>
				{!warehouses.loading && (
					<ExcelIcon
						button
						onClick={() =>
							xlsx.exportWarehousesListToExcel('Almacenes', warehouses.data)
						}
					/>
				)}

				{!warehouses.loading ? (
					<RefreshIcon button onClick={getWarehouses} />
				) : (
					<LogoIcon button spin />
				)}

				<Popover
					content={filterContent}
					placement='bottomRight'
					title={null}
					trigger='click'
					visible={showPopoverMode}
					onVisibleChange={() =>
						mutate1ObjectInWarehouses('control', {
							showPopoverMode: !showPopoverMode,
						})
					}
				>
					<div>
						<FiltersIcon button />
					</div>
				</Popover>
			</Wrapper>
		</Wrapper>
	);
}
