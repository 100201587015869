import { createSelector } from 'reselect';
import Immutable from 'immutable';

const selectSupervisor = state => state.supervisor;

export const selectSupervisorControl = createSelector(
	[selectSupervisor],
	({ control }) => ({
		...control,
		data: Immutable.List(control.data).toJS(),
	}),
);
export const selectCommentDetailsDrawer = createSelector(
	[selectSupervisor],
	({ commentDetailsDrawer }) => commentDetailsDrawer,
);

export const selectCommentModal = createSelector(
	[selectSupervisor],
	({ commentModal }) => commentModal,
);
