// @flow
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { grid } from '../constants';

// Components
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from 'antd';
import OrderItem from './order-item';
import Title from './title';

// Actions
import { mutate1Object } from 'screens/Orders/actions';

// Selectors
import { selectProfile } from 'screens/Login/selectors';
import {
	selectAllExpertsInfo,
	selectGetOrdersQueryModal,
} from 'screens/Orders/selectors';

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
	if (isDraggingOver) {
		return 'blue';
	}
	if (isDraggingFrom) {
		return 'orange';
	}

	return '#222526';
};

const Wrapper = styled.div`
	background-color: ${({ isDraggingOver, isDraggingFrom }) =>
		getBackgroundColor(isDraggingOver, isDraggingFrom)};
	display: flex;
	flex-direction: column;
	opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
	padding: ${grid}px;
	border: ${grid}px;
	padding-bottom: 0;
	transition:
		background-color 0.2s ease,
		opacity 0.1s ease;
	user-select: none;
	width: 330px;
`;

const scrollContainerHeight = 32;

const DropZone = styled.div`
	/* stop the list collapsing when empty */
	min-height: ${scrollContainerHeight}vh;

	/*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
	padding-bottom: ${grid}px;
`;

const ScrollContainer = styled.div`
	overflow-x: hidden;
	overflow-y: auto;
	max-height: ${scrollContainerHeight}vh;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;
/* stylelint-enable */

const InnerOrderList = React.memo(function ({
	orders,
	zoneId,
	profile,
	mutate1Object,
}) {
	const customProps = { profile, mutate1Object };
	return orders.map((order, index) => (
		<Draggable
			key={order.order_id}
			draggableId={`${order.order_id}-${zoneId}`}
			index={index}
		>
			{(dragProvided, dragSnapshot) => (
				<OrderItem
					key={order.order_id}
					order={order}
					isDragging={dragSnapshot.isDragging}
					provided={dragProvided}
					customProps={customProps}
				/>
			)}
		</Draggable>
	));
});

function InnerList({
	orders,
	zoneId,
	dropProvided,
	title,
	profile,
	mutate1Object,
}) {
	const titleContent = title ? <Title>{title}</Title> : null;

	return (
		<Container>
			{titleContent}
			<DropZone ref={dropProvided.innerRef}>
				<InnerOrderList
					orders={orders}
					zoneId={zoneId}
					profile={profile}
					mutate1Object={mutate1Object}
				/>
				{dropProvided.placeholder}
			</DropZone>
		</Container>
	);
}

function OrderList({
	ignoreContainerClipping,
	internalScroll,
	scrollContainerStyle,
	isDropDisabled,
	listId = 'LIST',
	listType,
	style,
	orders,
	zoneId,
	title,
	// state
	profile,
	allExpertsInfo,
	getOrdersQueryModal,
	// actions
	mutate1Object,
}) {
	const isDataLoading =
		allExpertsInfo?.isLoading || getOrdersQueryModal.loading;
	return isDataLoading ? (
		<Button
			loading
			block
			style={{
				height: `${scrollContainerHeight}vh`,
				border: 'none',
				backgroundColor: 'rgba(255,255,255,0.1)',
			}}
		/>
	) : (
		<Droppable
			droppableId={`${listId}-${zoneId}`}
			type={listType}
			ignoreContainerClipping={ignoreContainerClipping}
			isDropDisabled={isDropDisabled}
		>
			{(dropProvided, dropSnapshot) => (
				<Wrapper
					style={style}
					isDraggingOver={dropSnapshot.isDraggingOver}
					isDropDisabled={isDropDisabled}
					isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
					{...dropProvided.droppableProps}
				>
					{internalScroll ? (
						<ScrollContainer
							onWheel={event => {
								event.stopPropagation();
							}}
							style={scrollContainerStyle}
						>
							<InnerList
								orders={orders}
								title={title}
								zoneId={zoneId}
								dropProvided={dropProvided}
								profile={profile}
								mutate1Object={mutate1Object}
							/>
						</ScrollContainer>
					) : (
						<InnerList
							orders={orders}
							title={title}
							dropProvided={dropProvided}
						/>
					)}
				</Wrapper>
			)}
		</Droppable>
	);
}

const mapStateToProps = state => ({
	profile: selectProfile(state),
	allExpertsInfo: selectAllExpertsInfo(state),
	getOrdersQueryModal: selectGetOrdersQueryModal(state),
});

const actions = {
	mutate1Object,
};

export default connect(mapStateToProps, actions)(OrderList);
