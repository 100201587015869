//Libs
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
//Utils
import COLLECTOR_KEYWORDS from 'components/Collector/keywords';
import { sendToast as _sendToast } from 'screens/Dashboard/actions';
//Components
import { Wrapper, Span, Signature, AddIcon, DeleteIcon } from 'components';
import CollectorUtils from 'components/Collector/CollectorUtils';
import PreviewResource from './PreviewResource';
import UploadResourceV1 from './UploadResourceV1';
import UploadResourceV2 from './UploadResourceV2';
import UploadResourceUtils from './UploadResourceUtils';
import { uploadResource as _uploadResource } from './actions';
//Styles
import './style.css';

const { DUPLICATION } = COLLECTOR_KEYWORDS.COLLECTORS;

const CameraSwitch = ({
	authConfig,
	authOptions,
	fileProps,
	profile,
	setResource,
	resetResource,
	uploadResource,
	sendToast,
}) => {
	if (
		authOptions.isSynapseCameraActive
		// authConfig.version === 2 //TODO: Al descomentar esta línea se vuelve a imponer desde la base de datos el uso de la cámara de Synapse
		// No descomentar hasta que se haya habilitado el uso del flash, zoom, y enfoque manual
	) {
		return (
			<UploadResourceV2
				authConfig={authConfig}
				isPhotoBackupActive={authOptions.isPhotoBackupActive}
				fileProps={fileProps}
				profile={profile}
				setResource={setResource}
				uploadResource={uploadResource}
				sendToast={sendToast}
			/>
		);
	}

	return (
		<UploadResourceV1
			authConfig={authConfig}
			isPhotoBackupActive={authOptions.isPhotoBackupActive}
			fileProps={fileProps}
			profile={profile}
			setResource={setResource}
			resetResource={resetResource}
			uploadResource={uploadResource}
			sendToast={sendToast}
		/>
	);
};

function DuplicableButtons({
	fileProps,
	handleOnDuplicateElement,
	handleOnDeleteElement,
}) {
	return (
		<Wrapper
			padding='0'
			height='70px'
			flexDirection='column'
			justifyContent='space-around'
			position='absolute'
			right='-45px'
		>
			<AddIcon
				button
				onClick={e => {
					e.stopPropagation();
					handleOnDuplicateElement({
						level: DUPLICATION.LEVELS.PHOTO,
						element: CollectorUtils.getFormattedDuplicateElement(
							DUPLICATION.LEVELS.PHOTO,
							{ photo: fileProps },
						),
					});
				}}
			/>
			<DeleteIcon
				onClick={e => {
					e.stopPropagation();
					handleOnDeleteElement({
						level: DUPLICATION.LEVELS.PHOTO,
						element: CollectorUtils.getFormattedDuplicateElement(
							DUPLICATION.LEVELS.PHOTO,
							{ photo: fileProps },
						),
					});
				}}
			/>
		</Wrapper>
	);
}

function UploadButton({
	children,
	width,
	height,
	theme,
	authOptions,
	duplicableButtons,
}) {
	return (
		<Wrapper
			position='relative'
			padding='0'
			width={width}
			height={height}
			justifyContent='center'
			style={{
				border: `1px ${theme.colors.border.color} dashed`,
			}}
		>
			{authOptions.showActions && children}
			{duplicableButtons && duplicableButtons()}
		</Wrapper>
	);
}

function PhotoLabel({ children, authOptions, theme }) {
	return (
		<>
			{children}
			{(!authOptions.hideLabel || authOptions.highlighLabel) && (
				<Span
					wordBreak='break-word'
					textAlign='center'
					fontSize='s'
					fontWeight={
						authOptions.highlighLabel ? 'bold' : theme.span.fontWeight
					}
					color={
						authOptions.highlighLabel
							? theme.colors.error.color
							: theme.colors.text.medium
					}
				>
					{authOptions.hideLabel ? 'Requerido' : authOptions.label}
				</Span>
			)}
		</>
	);
}

const getInitialState = {
	src: null,
	isLoading: false,
	isError: false,
	isSuccess: false,
	isVideo: false,
};
const UploadResource = ({
	profile,
	width = '70px',
	height = '70px',
	isDuplicable,
	authConfig = {},
	fileProps = {},
	collector,
	options = {},
	resources,
	handleOnDuplicateElement,
	handleOnDeleteElement,
}) => {
	const theme = useTheme();
	//State
	const [resource, setResource] = React.useState(getInitialState);
	//Get auth options
	const authOptions = {
		showActions:
			options?.showActions !== undefined ? options.showActions : true,
		upload: options?.upload !== undefined ? options.upload : true,
		erase: options?.erase !== undefined ? options.erase : true,
		label: options?.label !== undefined ? options.label : '',
		hideLabel: options?.hideLabel,
		highlighLabel: options?.highlighLabel,
		profilePhoto: options?.profilePhoto,
		isPhotoBackupActive: options?.isPhotoBackupActive,
		isSynapseCameraActive: options?.isSynapseCameraActive,
	};
	const dispatch = useDispatch();
	const uploadResource = payload => dispatch(_uploadResource(payload));
	const sendToast = payload => dispatch(_sendToast(payload));

	//Update Resource
	React.useEffect(() => {
		let isMounted = true;
		UploadResourceUtils.getResourceFromResources(fileProps).then(
			({ src, file, status }) => {
				const { isLoading, isError, isSuccess } =
					UploadResourceUtils.getStatus(status);

				if (isMounted)
					setResource(prev => ({
						...prev,
						src,
						file,
						isLoading,
						isError,
						isSuccess,
					}));
			},
		);
		return () => {
			isMounted = false;
		};
	}, [resources, fileProps]);

	//HANDLERS
	const resetResource = () => setResource(getInitialState);

	const { src, file, isLoading, isError, isSuccess } = resource;

	if (src || file)
		return (
			<PhotoLabel authOptions={authOptions} theme={theme}>
				<PreviewResource
					width={width}
					height={height}
					file={file}
					src={src}
					collector={collector}
					fileProps={fileProps}
					authOptions={authOptions}
					isLoading={isLoading}
					isError={isError}
					isSuccess={isSuccess}
					uploadResource={uploadResource}
					duplicableButtons={() =>
						isDuplicable && (
							<DuplicableButtons
								fileProps={fileProps}
								handleOnDuplicateElement={handleOnDuplicateElement}
								handleOnDeleteElement={handleOnDeleteElement}
							/>
						)
					}
				/>
			</PhotoLabel>
		);

	// if (authOptions.profilePhoto) return <UploadButtonProfilePhoto />; //TODO: Sacar esto de aqui y crear su propio flujo para subir la foto de perfil

	if (authOptions.upload && !file && !src && fileProps.signercanvas)
		return (
			<Signature
				fileProps={fileProps}
				isLoading={isLoading}
				setResource={setResource}
				uploadResource={uploadResource}
			/>
		);

	return (
		<PhotoLabel authOptions={authOptions} theme={theme}>
			<UploadButton
				width={width}
				height={height}
				theme={theme}
				authOptions={authOptions}
				duplicableButtons={() =>
					isDuplicable && (
						<DuplicableButtons
							fileProps={fileProps}
							handleOnDuplicateElement={handleOnDuplicateElement}
							handleOnDeleteElement={handleOnDeleteElement}
						/>
					)
				}
			>
				<CameraSwitch
					authConfig={authConfig}
					authOptions={authOptions}
					fileProps={fileProps}
					profile={profile}
					setResource={setResource}
					resetResource={resetResource}
					uploadResource={uploadResource}
					sendToast={sendToast}
				/>
			</UploadButton>
		</PhotoLabel>
	);
};

export default React.memo(UploadResource);
