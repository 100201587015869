import Immutable from 'immutable';
import { filterDataByFilterOptions } from '..';

function filterConflictsImporting(orders, filters, userAssignment) {
	let filteredOrders = Immutable.List(orders).toJS();

	filteredOrders = filterDataByFilterOptions(filteredOrders, filters);
	if (
		userAssignment === 'withConflicts' ||
		userAssignment === 'withoutConflicts'
	) {
		filteredOrders = filteredOrders.filter(
			order => order.previewAssignment || order.mappingErrors === 'N/A',
		);
	}
	return filteredOrders;
}

export { filterConflictsImporting };
