// Libs
import { Dropdown } from 'antd';
import styled from 'styled-components';

const { Button } = Dropdown;

const DropdownStyled = styled(Dropdown)``;
const ButtonStyled = styled(Button)``;

export default {
	Wrapper: DropdownStyled,
	Button: ButtonStyled,
};
