// Libs
import React from 'react';
// Components
import { Drawer, Wrapper, Button } from 'components';
import { VirtualizedList } from 'screens/Utils';
import ImgBugFixing from 'assets/media/img/bugFixing.svg';

export default function ShowErrors({ showErrors, mutate1Object }) {
	return (
		<Drawer
			key={showErrors.placement}
			width={`${document.documentElement.clientWidth}px`}
			header_font_weight='bold'
			content_border='1px solid red'
			title={showErrors.title}
			placement={showErrors.placement}
			closable={false}
			onClose={() => mutate1Object('showErrors', { isOpen: false, errors: [] })}
			visible={showErrors.isOpen}
		>
			<Wrapper flexDirection='column' justifyContent='space-between'>
				<VirtualizedList
					cardId='showErrorCard'
					listStyle={{
						height: document.documentElement.clientHeight * 0.9,
						width: document.documentElement.clientWidth,
					}}
					rowHeight={70}
					spinTitle={undefined}
					rows={showErrors.errors}
					customProps={{}}
				/>

				<Wrapper
					padding='10px'
					alignItems='flex-end'
					width='100%'
					style={{
						position: 'absolute',
						bottom: 0,
					}}
					justifyContent='space-between'
				>
					<img src={ImgBugFixing} style={{ width: '200px' }} />
					<Button
						color='white'
						onClick={() =>
							mutate1Object('showErrors', { isOpen: false, errors: [] })
						}
					>
						Entendido
					</Button>
				</Wrapper>
			</Wrapper>
		</Drawer>
	);
}
