// Libs
import React from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import {
	Wrapper,
	Subtitle,
	UploadIcon,
	LogoIcon,
	CloseIcon,
	CheckIcon,
} from 'components';

export const PROCESS_STATUS = {
	LOADING: 'loading',
	SUCCESS: 'success',
	UNSUCCESS: 'unsuccess',
};

const ICON_STATUS = {
	[PROCESS_STATUS.LOADING]: () => <LogoIcon spin />,
	[PROCESS_STATUS.SUCCESS]: () => <CheckIcon />,
	[PROCESS_STATUS.UNSUCCESS]: () => <CloseIcon />,
};

const ShowMessage = ({ message, status }) => {
	const Icon = ICON_STATUS[status] ? ICON_STATUS[status]() : null;
	return (
		<Wrapper
			width='100%'
			height='100%'
			justifyContent='center'
			padding='0 16px'
			disabledBackground
		>
			<Subtitle width='100%' textAlign='center'>
				{message}
			</Subtitle>
			{Icon}
		</Wrapper>
	);
};

const Dragging = ({ drag }) => (
	<Wrapper
		elementRef={drag}
		width='100%'
		height='100%'
		justifyContent='center'
		padding='0'
		mediumBackground
	>
		<Subtitle>Suelte los archivos</Subtitle>
	</Wrapper>
);

const UploadButton = ({ disabled, fileTypes, onChange }) => (
	<label>
		<UploadIcon disabled={disabled} />
		<input
			type='file'
			disabled={disabled}
			style={{ display: 'none' }}
			accept={fileTypes.join(',')}
			multiple
			onChange={onChange}
		/>
	</label>
);

const FilesDragAndDrop = ({
	width = '100%',
	height = '60px',
	disabled,
	count,
	fileTypes = [],
	processing,
	handleChange,
}) => {
	const { theme } = useTheme();
	const [errorMessage, setErrorMessage] = React.useState(undefined);
	const [dragging, setDragging] = React.useState(false);
	const drag = React.useRef(null);
	const drop = React.useRef(null);

	const handleDragEnter = e => {
		e.preventDefault();
		e.stopPropagation();

		if (e.target !== drag.current) {
			setDragging(true);
		}
	};

	const handleDragLeave = e => {
		e.preventDefault();
		e.stopPropagation();

		if (e.target === drag.current) {
			setDragging(false);
		}
	};

	const handleDragOver = e => {
		e.preventDefault();
		e.stopPropagation();
	};

	const checkValidInputFiles = files => {
		// check if the provided count prop is less than uploaded count of files
		if (count && count < files.length) {
			setErrorMessage(
				`Sólo ${count} archivo${count !== 1 ? 's' : ''} a la vez`,
			);
			return false;
		}

		// check if some uploaded file is not in one of the allowed fileTypes
		if (
			files.some(
				file =>
					!fileTypes.some(type =>
						file.name.toLowerCase().endsWith(type.toLowerCase()),
					),
			)
		) {
			setErrorMessage(
				`Sólo se aceptan los siguientes formatos: [${fileTypes.join(', ')}]`,
			);
			return false;
		}

		return true;
	};

	const handleDrop = e => {
		e.preventDefault();
		e.stopPropagation();

		setDragging(false);

		// this is required to convert FileList object to array
		const files = [...e.dataTransfer.files];
		if (!files || !files.length) return;

		if (!checkValidInputFiles(files)) return;

		handleChange(files);
	};

	const handleSelectFiles = e => {
		e.preventDefault();

		const files = [...e.target.files];
		if (!files || !files.length) return;

		if (!checkValidInputFiles(files)) return;

		handleChange(files);
	};

	React.useEffect(() => {
		if (errorMessage) setTimeout(() => setErrorMessage(undefined), 2500);
	}, [errorMessage]);

	const joinedFileTypes = fileTypes.join(', ');
	React.useEffect(() => {
		if (disabled || !drop.current) return;
		drop.current.addEventListener('dragover', handleDragOver);
		drop.current.addEventListener('drop', handleDrop);
		drop.current.addEventListener('dragenter', handleDragEnter);
		drop.current.addEventListener('dragleave', handleDragLeave);

		return () => {
			if (disabled || !drop.current) return;
			drop.current.removeEventListener('dragover', handleDragOver);
			drop.current.removeEventListener('drop', handleDrop);
			drop.current.removeEventListener('dragenter', handleDragEnter);
			drop.current.removeEventListener('dragleave', handleDragLeave);
		};
	}, [disabled, joinedFileTypes]);

	const message = errorMessage || processing.message;
	const status = errorMessage ? PROCESS_STATUS.UNSUCCESS : processing.status;

	return (
		<Wrapper
			padding='0'
			elementRef={drop}
			width={width}
			height={height}
			border={`1px dashed ${theme._mediumEmphasisColor}`}
			flexDirection='column'
			justifyContent='center'
			disabledBackground={disabled}
		>
			{message ? (
				<ShowMessage message={message} status={status} />
			) : dragging ? (
				<Dragging drag={drag} />
			) : (
				<>
					<Subtitle>Arrastre los archivos aquí</Subtitle>
					<Wrapper padding='0'>
						<Subtitle margin='0 10px 0 0'>Seleccionar</Subtitle>
						<div style={{ height: '24px' }}>
							<UploadButton
								disabled={disabled}
								fileTypes={fileTypes}
								onChange={handleSelectFiles}
							/>
						</div>
					</Wrapper>
				</>
			)}
		</Wrapper>
	);
};

export default FilesDragAndDrop;
