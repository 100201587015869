//  Libs
import React from 'react';
// Components
import { DatePicker, Button } from 'components';
import Select from 'components/Select';
// Styles
import '../../../index.css';

const { Option } = Select;

export default function GetPaycutsModal(props) {
	const { getPaycutsModal, getPaycuts, mutate1ObjectInBillings } = props;

	return (
		<div className='modal-container'>
			<div className='create-article-form-items'>
				<DatePicker
					style={{ marginTop: '10px' }}
					placeholder='Desde*'
					value={getPaycutsModal.fromDate}
					onChange={value =>
						mutate1ObjectInBillings('getPaycutsModal', { fromDate: value })
					}
				/>

				<DatePicker
					style={{ marginTop: '20px' }}
					placeholder='Hasta*'
					value={getPaycutsModal.toDate}
					onChange={value =>
						mutate1ObjectInBillings('getPaycutsModal', { toDate: value })
					}
				/>

				<div className='form-action-button'>
					<Button
						className='button-style-1'
						onClick={() => {
							getPaycuts(getPaycutsModal);
							mutate1ObjectInBillings('getPaycutsModal', {
								category: undefined,
							});
						}}
					>
						Consultar
					</Button>
				</div>
			</div>
		</div>
	);
}
