// Libs
import React from 'react';
// Components
import { Wrapper, Empty } from 'components';
import { VirtualizedList } from 'screens/Utils';

// Empty Transactions?
function isEmptyTransactions(filteredTransactions) {
	return filteredTransactions.length === 0;
}

function TransactionsMobile(props) {
	const {
		transactions,
		transactionsManage,
		filterTransactionsSearchInData,
		mutate1Object,
		profile,
		getItemsTransactionDocs,
		signWTDModal,
		signWTD,
		deleteTransaction,
	} = props;

	// Filter Transactions
	const filteredTransactions = filterTransactionsSearchInData(
		transactions.data,
		transactionsManage.searchInData.searchBy,
		transactionsManage.searchInData.data,
	);

	return (
		<Wrapper padding='0' justifyContent='center'>
			{isEmptyTransactions(filteredTransactions) ? (
				<Empty margin='20px 0 0 0' />
			) : (
				<VirtualizedList
					cardId='transactionCard'
					listStyle={{
						height: document.documentElement.clientHeight * 0.83,
						width: document.documentElement.clientWidth * 1.04,
					}}
					rowHeight={210}
					rows={filteredTransactions}
					isSpinning={transactions.loading}
					customProps={{
						mutate1ObjectInWarehouses: mutate1Object,
						getItemsTransactionDocs,
						signWTD,
						profile,
						signWTDModal,
						transactionsManage,
						deleteTransaction,
					}}
				/>
			)}
		</Wrapper>
	);
}

export default TransactionsMobile;
