//Libs
import React from 'react';
//Components
import { Wrapper, Modal, Span, Button } from 'components';

const AutoFillServiceTaskCollectors = ({
	visible,
	autoFillCollector,
	serviceTasks,
	onConfirm,
	onCancel,
}) => {
	const { serviceTaskName } =
		serviceTasks.find(
			st => st.serviceTaskId === autoFillCollector.serviceTaskId,
		) || {};

	return (
		<Modal
			visible={visible}
			title='Confirmar acción'
			footer={null}
			onCancel={onCancel}
		>
			<Wrapper
				height='160px'
				flexDirection='column'
				justifyContent='space-around'
			>
				<Span textAlign='center'>
					{`Esta acción sobreescribirá los recolectores de ${serviceTaskName}`}
				</Span>
				<Wrapper>
					<div style={{ marginRight: '10px' }}>
						<Button mode='secondary' onClick={onCancel}>
							Cancelar
						</Button>
					</div>
					<div>
						<Button mode='primary' onClick={onConfirm}>
							Confirmar
						</Button>
					</div>
				</Wrapper>
			</Wrapper>
		</Modal>
	);
};

export default AutoFillServiceTaskCollectors;
