// Utils
import GENERAL from '../../utils/constants/general';
// Validator
import {
	getControlSeriesValidator,
	getPaycutsValidator,
	getPaycutsResumeValidator,
	getPaymentsValidator,
	getTemplatesValidator,
} from './actionsValidator';

const { APIDATA, DASHBOARD } = GENERAL;

// GENERAL
export function mutate1ObjectApi(obj1Name, keyValuePairs) {
	return {
		type: APIDATA.MUTATE_1OBJECT,
		payload: { obj1Name, keyValuePairs },
	};
}

// USERS
export function getRoles() {
	return {
		type: APIDATA.GET_ROLES,
	};
}

export function getPermissions() {
	return {
		type: APIDATA.GET_PERMISSIONS,
	};
}

export function getOrganizationUsers() {
	return {
		type: APIDATA.GET_ORGANIZATION_USERS,
	};
}

export function updateUserCoord(newCoord) {
	return {
		type: APIDATA.UPDATE_USER_COORD,
		payload: newCoord,
	};
}

// ORDERS
export function getDepartments() {
	return {
		type: APIDATA.GET_DEPARTMENTS,
	};
}

export function getServices(department_id) {
	return {
		type: APIDATA.GET_SERVICES,
		payload: department_id,
	};
}

export function getClientCategories({ serviceId, departmentId }) {
	return {
		type: APIDATA.GET_CLIENT_CATEGORIES,
		payload: { serviceId, departmentId },
	};
}

export function getOrderStates() {
	return {
		type: APIDATA.GET_ORDER_STATES,
	};
}

export function getOrderEvents() {
	return {
		type: APIDATA.GET_ORDER_EVENTS,
	};
}

// BILLINGS
export function getCoins() {
	return {
		type: APIDATA.GET_COINS,
	};
}

export function getPaycuts(getPaycutsModal) {
	const errors = getPaycutsValidator(getPaycutsModal);

	if (errors) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: errors,
				type: 'warn',
				duration: 60,
			},
		};
	}

	return {
		type: APIDATA.GET_PAYCUTS,
		payload: getPaycutsModal,
	};
}

export function getPayments(paycutIds) {
	const errors = getPaymentsValidator(paycutIds);

	if (errors) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: errors,
				type: 'warn',
				duration: 60,
			},
		};
	}

	return {
		type: APIDATA.GET_PAYMENTS,
		payload: paycutIds,
	};
}

export function getInvoicePays(paycutIds) {
	return {
		type: APIDATA.GET_INVOICE_PAYS,
		payload: paycutIds,
	};
}

export function getPaymentResume(payIds) {
	return {
		type: APIDATA.GET_PAYMENT_RESUME,
		payload: payIds,
	};
}

export function getPaycutResume(getPaycutsResumeModal) {
	const errors = getPaycutsResumeValidator(getPaycutsResumeModal);

	if (errors) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: errors,
				type: 'warn',
				duration: 60,
			},
		};
	}

	return {
		type: APIDATA.GET_PAYCUT_RESUME,
		payload: getPaycutsResumeModal,
	};
}

export function getPaymentLines(payIds, paidTo) {
	return {
		type: APIDATA.GET_PAYMENTS_LINES,
		payload: { payIds, paidTo },
	};
}

export function getPaymentOrders(payIds, getOrders) {
	return {
		type: APIDATA.GET_PAYMENTS_ORDERS,
		payload: { payIds, getOrders },
	};
}

export function updatePaymentIdLine(
	line_id,
	assign_in,
	payment_id,
	paycut_id,
	paidTo,
) {
	return {
		type: APIDATA.UPDATE_PAYMENT_ID_LINE,
		payload: { line_id, assign_in, payment_id, paycut_id, paidTo },
	};
}

export function getLineItems() {
	return {
		type: APIDATA.GET_LINE_ITEMS,
	};
}

// WAREHOUSES
export function getTransactionTypes() {
	return {
		type: APIDATA.GET_TRANSACTION_TYPES,
	};
}

export function getIncomeMethods() {
	return {
		type: APIDATA.GET_INCOME_METHODS,
	};
}

export function getMeasuredUnits() {
	return {
		type: APIDATA.GET_MEASURED_UNITS,
	};
}

export function getItemStates() {
	return {
		type: APIDATA.GET_ITEM_STATES,
	};
}

export function getWarehousePackages() {
	return {
		type: APIDATA.GET_WAREHOUSE_PACKAGES,
	};
}

export function getZonesAreas() {
	return {
		type: APIDATA.GET_WAREHOUSE_ZONES_AREAS,
	};
}

export function getItemFamilies() {
	return {
		type: APIDATA.GET_ITEM_FAMILIES,
	};
}

export function getArticles() {
	return {
		type: APIDATA.GET_ARTICLES,
	};
}

export function getWarehouses() {
	return {
		type: APIDATA.GET_WAREHOUSES,
	};
}

export function getStockArticles(warehouseId, ownerId, queryType) {
	if (!warehouseId || !ownerId || !queryType) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Por favor indique nuevamente el almacén',
				type: 'warn',
			},
		};
	}
	return {
		type: APIDATA.GET_STOCK_ARTICLES,
		payload: { warehouseId, ownerId, queryType },
	};
}

export function getStockSeries(
	warehouseId,
	ownerId,
	queryType,
	itemId,
	stateId,
) {
	if (!warehouseId || !ownerId || !queryType || !itemId || !stateId) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Por favor indique nuevamente el artículo',
				type: 'warn',
			},
		};
	}
	return {
		type: APIDATA.GET_STOCK_SERIES,
		payload: { warehouseId, ownerId, queryType, itemId, stateId },
	};
}

export function getControlSeries(request, pagination = false) {
	const errors = getControlSeriesValidator(request);

	if (errors) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: errors,
				type: 'warn',
				duration: 60,
			},
		};
	}

	return {
		type: APIDATA.GET_CONTROL_SERIES,
		payload: { request, pagination },
	};
}

export function getTransactionDocs(getTransactions) {
	const { mode, docNumber, fromDate, toDate } = getTransactions;

	if (
		(mode === 'document' && !docNumber) ||
		(mode === 'completed' && (!fromDate || !toDate))
	)
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: 'Debe completar todos los campos', type: 'warn' },
		};

	return {
		type: APIDATA.GET_TRANSACTION_DOCS,
		payload: getTransactions,
	};
}

export function getInventoryToTransfer(warehouseId) {
	return {
		type: APIDATA.GET_INVENTORY_TO_TRANSFER,
		payload: warehouseId,
	};
}

export function getItemsTransactionDocs(wtdId) {
	return {
		type: APIDATA.GET_ITEMS_TRANSACTION_DOCS,
		payload: wtdId,
	};
}

export function getSerieHistory(serie) {
	return {
		type: APIDATA.GET_SERIE_HISTORY,
		payload: serie,
	};
}

export function updateWTDSigner(document_id, nextSigner) {
	return {
		type: APIDATA.UPDATE_WTD_SIGNER,
		payload: { document_id, nextSigner },
	};
}

export function signWTD(document_id, nextSigner, complete, operation) {
	if (!document_id || (!complete && !nextSigner))
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Por favor seleccione el próximo firmante',
				type: 'warn',
			},
		};

	return {
		type: APIDATA.SIGN_WTD,
		payload: { document_id, nextSigner, complete, operation },
	};
}

// ZONES
export function onGetZonesToAssignOrders() {
	return {
		type: APIDATA.GET_ZONES_TO_ASSIGN_ORDERS,
	};
}

// TEMPLATES
export function onGetTemplates(request) {
	const errors = getTemplatesValidator(request);

	if (errors) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: errors,
				type: 'warn',
				duration: 60,
			},
		};
	}

	return {
		type: APIDATA.GET_TEMPLATES,
		payload: request,
	};
}

// RESET MODULE DATA
export function doResetApiData(resetModuleData) {
	return {
		type: APIDATA.RESET_MODULE_DATA,
		payload: resetModuleData,
	};
}

export function getClientsWithQueryVariant(queryVariant) {
	return {
		type: APIDATA.GET_CLIENTS_WITH_QUERY_VARIANT,
		payload: queryVariant,
	};
}

export function getContractsByClientId({ clientId, departmentId }) {
	return {
		type: APIDATA.GET_CONTRACTS_BY_CLIENT_ID,
		payload: { clientId, departmentId },
	};
}

export function getContractTypes(departmentId) {
	return {
		type: APIDATA.GET_CONTRACT_TYPES,
		payload: { departmentId },
	};
}

export function getFrequencies(departmentId) {
	return {
		type: APIDATA.GET_FREQUENCIES,
		payload: departmentId,
	};
}
