// Libs
import React from 'react';
import styled, { css } from 'styled-components';
// Components
import IconButton from '@mui/material/IconButton';
import icon from './gps.svg';
import selectedIcon from './gps.svg';

const Icon = styled.div`
	background-image: ${props =>
		css`url(${props.selected ? selectedIcon : icon})`};
	width: 24px;
	height: 24px;
`;

const GPSIcon = ({ button, selected, onClick }) =>
	button ? (
		<IconButton onClick={onClick}>
			<Icon selected={selected} />
		</IconButton>
	) : (
		<Icon onClick={onClick} selected={selected} />
	);

export default GPSIcon;
