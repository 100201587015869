import exportMultiSheetToExcel from './MultiSheet';

export function exportOrdersToExcel(fileName, data, profile) {
	data = data.reduce((acc, row) => {
		acc.push({
			Orden: `${profile.organization.settings.order_prefix}${row.odt_id}`,
			'Comentario Evento': row.event_comment,
			Evento: row.event_name,
			'Programada Synapse': row.formatReprogramAt,
			'Codigo de Trabajo': row.work_code,
			Fecha: row.formatExecutionAt,
			Cliente: row.client_name,
			Provincia: row.city,
			Canton: row.township,
			Zona: row.zone,
			Comentarios: row.fault_notes,
			Tecnico: row.expert_name,
			Entidad: row.entity_name,
			Servicio: row.service_name,
			Posicion: row.att_position,
			'Pos. Actualizada': row.last_att_position_at,
			'Fecha Evento': row.formatLastEventAt,
			Estado: row.state_name,
			Programada: row.formatProgramAt,
			Reservada: row.formatReservationAt,
			Dilacion: row.dilation,
			Rango: row.external_range,
			Seguimiento: row.expertTrackingState,
			Creada: row.created_at,
			Prioridad: row.priority && row.priority.label,
			'Razon de Prioridad': row.priority && row.priority.reason,
			Precio:
				profile &&
				profile.user.settings.orders &&
				profile.user.settings.orders.price.viewIndividual
					? row.price.price
					: '',
		});

		return acc;
	}, []);

	exportMultiSheetToExcel(fileName, [
		{
			name: fileName,
			data,
		},
	]);
}

export function exportOrdersDynamicDataToExcel(fileName, data) {
	data = data.reduce((acc, order) => {
		const row = {};
		order.forEach(col => {
			if (col.key) row[col.key] = col.value;
		});
		acc.push(row);
		return acc;
	}, []);

	exportMultiSheetToExcel(fileName, [
		{
			name: fileName,
			data,
		},
	]);
}
