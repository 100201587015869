// Libs
import { createSelector } from 'reselect';
import Immutable from 'immutable';

const selectOrders = state => state.orders;
const selectCollector = state => state.collector;

export const selectCompleteOrderId = createSelector(
	[selectCollector],
	({ reviewManage }) => reviewManage.orderId,
);
export const selectUpdateOrderModal = createSelector(
	[selectOrders],
	({ control }) => control.updateOrderModal,
);
export const selectSelectedOrders = createSelector(
	[selectOrders],
	({ control }) => control.selectedOrders,
);
export const getOrderInformation = createSelector(
	[selectOrders],
	({ getOrderInformationModal }) => getOrderInformationModal,
);
export const selectSendEventModal = createSelector(
	[selectOrders],
	({ sendEventModal }) => sendEventModal,
);
export const selectEnterNewOrdersModal = createSelector(
	[selectOrders],
	({ enterNewOrdersModal }) => enterNewOrdersModal,
);
export const selectOrderReassignmentModal = createSelector(
	[selectOrders],
	({ ordersReassignmentModal }) => ordersReassignmentModal,
);

export const selectGetOrdersQueryModal = createSelector(
	[selectOrders],
	({ getOrdersQueryModal }) => getOrdersQueryModal,
);
export const selectGetInfoOrdersIncomeModal = createSelector(
	[selectOrders],
	({ getInfoOrdersIncomeModal }) => getInfoOrdersIncomeModal,
);

export const selectControlOrders = createSelector(
	[selectOrders],
	({ control }) => Immutable.List(control.orders).toJS(),
);

export const selectAllExpertsInfo = createSelector(
	[selectOrders],
	({ experts }) => experts,
);

export const selectControlOrdersByExpert = createSelector(
	[selectControlOrders, selectAllExpertsInfo],
	(orders, allExpInfo) =>
		allExpInfo.data.reduce((acc, exp) => {
			let realizableOrderCount = 0;
			let affectationOrderCount = 0;

			acc[exp.assignedTechId] = {
				...exp,
				orders: orders
					.filter(order => {
						const orderIsAssignedToExp =
							order.assigned_tech_id === exp.assignedTechId &&
							order.zone_id === exp.zoneId;

						if (orderIsAssignedToExp) {
							if (order.event_props.realizable) realizableOrderCount++;
							else if (order.event_props.affectation) affectationOrderCount++;
						}
						return orderIsAssignedToExp;
					})
					?.map(order => ({
						...order,
						expert: exp,
					})),
				realizableOrderCount,
				affectationOrderCount,
			};

			return acc;
		}, {}),
);
