// Libs
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import axios from 'axios';
// Utils
import asyncErrorsHandler from '../../store/asyncErrorsHandler';
import AuthService from '../../utils/libs/auth/AuthService';
import GENERAL from '../../utils/constants/general';
import API from '../../utils/constants/api';

const { BILLINGS, DASHBOARD, APIDATA } = GENERAL;
const auth = new AuthService();
const getPayments = state => state.api.payments;
const getBillingsState = state => state.billings;
const getPaycutsModal = state => state.billings.getPaycutsModal;
const getPaycuts = state => state.billings.paycuts;
const getPaymentOrders = state => state.api.payments.orders;

/** **** WATCHER SAGAS *******/
function* createPaymentWatcher() {
	yield takeLatest(BILLINGS.CREATE_PAYMENT, createPaymentWorker);
}

function* createPaymentLineWatcher() {
	yield takeLatest(BILLINGS.CREATE_PAYMENT_LINE, createPaymentLineWorker);
}

function* createPaymentLineItemWatcher() {
	yield takeLatest(
		BILLINGS.CREATE_PAYMENT_LINE_ITEM,
		createPaymentLineItemWorker,
	);
}

function* signPaycutWatcher() {
	yield takeLatest(BILLINGS.SIGN_PAYCUT, signPaycutWorker);
}

function* signPaymentWatcher() {
	yield takeLatest(BILLINGS.SIGN_PAYMENT, signPaymentWorker);
}

function* updatePaymentOrdersWatcher() {
	yield takeLatest(BILLINGS.UPDATE_PAYMENT_ORDERS, updatePaymentOrdersWorker);
}

function* syncPaycutWatcher() {
	yield takeLatest(BILLINGS.SYNC_PAYCUT, syncPaycutWorker);
}

function* generatePaymentPDFWatcher() {
	yield takeLatest(BILLINGS.GENERATE_PAYMENT_PDF, generatePaymentPDFWorker);
}

function* generatePaycutPDFWatcher() {
	yield takeLatest(BILLINGS.GENERATE_PAYCUT_PDF, generatePaycutPDFWorker);
}

function* setPaymentReopenWatcher() {
	yield takeLatest(BILLINGS.SET_PAYMENT_REOPEN, setPaymentReopenWorker);
}

function* setPaymentReadyWatcher() {
	yield takeLatest(BILLINGS.SET_PAYMENT_READY, setPaymentReadyWorker);
}

function* createPaycutWatcher() {
	yield takeLatest(BILLINGS.CREATE_PAYCUT, createPaycutWorker);
}

function* enterInvoiceOrdersWatcher() {
	yield takeLatest(BILLINGS.ENTER_INVOICE_ORDERS, enterInvoiceOrdersWorker);
}

/** **** WORKER SAGAS *******/
function* createPaycutWorker(action) {
	const paycutsModal = yield select(getPaycutsModal);
	const paycut = action.payload;
	const { name, department_id, coin_id, from_date, to_date } = paycut;

	try {
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createPaycutModal',
				keyValuePairs: { loading: true },
			},
		});
		const response = yield call(
			axios.post,
			API.DOMAIN.concat('/paycuts/invoice'),
			{ name, department_id, coin_id, from_date, to_date },
			auth.sendToken(),
		);
		const { paycutId } = response.data;
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createPaycutModal',
				keyValuePairs: { loading: false },
			},
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: `Corte [${paycutId}] creado`,
				type: 'success',
				duration: 1.5,
			},
		});
		yield put({ type: BILLINGS.RESET_CREATE_INVOICE_PAYCUT });
		yield put({ type: APIDATA.GET_PAYCUTS, payload: paycutsModal });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'createPaycutModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield createPaycutWorker(action);
			},
		);
	}
}

function* createPaymentWorker(action) {
	const createPayment = action.payload;
	const { paycut_id, type } = createPayment;

	let uri, successMessage;
	if (type === 'payment') {
		uri = '/payments';
		successMessage = 'Pago';
	} else if (type === 'invoice') {
		uri = '/payments/invoice';
		successMessage = 'Cobro';
	}

	try {
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createPaymentModal',
				keyValuePairs: { loading: true },
			},
		});
		const response = yield call(
			axios.post,
			API.DOMAIN.concat(uri),
			createPayment,
			auth.sendToken(),
		);
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: `${successMessage} creado: [${response.data}]`,
				duration: 2,
			},
		});
		yield put({ type: BILLINGS.RESET_CREATE_PAYMENT });
		yield put({ type: APIDATA.GET_PAYMENTS, payload: paycut_id });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'createPaymentModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield createPaymentWorker(action);
			},
		);
	}
}

function* createPaymentLineWorker(action) {
	const createPaymentLine = action.payload;
	const { payId, apply_to } = createPaymentLine;

	try {
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createLineModal',
				keyValuePairs: { loading: true },
			},
		});
		yield call(
			axios.post,
			API.DOMAIN.concat('/payments_lines'),
			createPaymentLine,
			auth.sendToken(),
		);
		yield put({ type: BILLINGS.RESET_CREATE_PAYMENT_LINE });
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: `Linea creada`, type: 'success', duration: 1.5 },
		});
		if (payId && apply_to)
			yield put({
				type: APIDATA.GET_PAYMENTS_LINES,
				payload: { payIds: payId, paidTo: apply_to },
			});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'createLineModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield createPaymentLineWorker(action);
			},
		);
	}
}

function* createPaymentLineItemWorker(action) {
	const createPaymentLine = action.payload;
	const { operation } = createPaymentLine;

	try {
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createLineModal',
				keyValuePairs: { loading: true },
			},
		});

		if (operation === 'editItem') {
			yield call(
				axios.put,
				API.DOMAIN.concat('/payments_lines_items'),
				createPaymentLine,
				auth.sendToken(),
			);
			yield put({
				type: DASHBOARD.TOAST_MESSAGE,
				payload: {
					message: `Item actualizado`,
					type: 'success',
					duration: 1.5,
				},
			});
		} else if (operation === 'createItem') {
			yield call(
				axios.post,
				API.DOMAIN.concat('/payments_lines_items'),
				createPaymentLine,
				auth.sendToken(),
			);
			yield put({
				type: DASHBOARD.TOAST_MESSAGE,
				payload: { message: `Item creado`, type: 'success', duration: 1.5 },
			});
		}
		yield put({ type: BILLINGS.RESET_CREATE_PAYMENT_LINE });
		yield put({ type: APIDATA.GET_LINE_ITEMS });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'createLineModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield createPaymentLineItemWorker(action);
			},
		);
	}
}

function* signPaycutWorker(action) {
	const paycutIds = action.payload;
	const paycutsModal = yield select(getPaycutsModal);

	try {
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'signPaycutModal',
				keyValuePairs: { loading: true },
			},
		});
		yield call(
			axios.put,
			API.DOMAIN.concat('/paycuts/sign'),
			{ paycutIds },
			auth.sendToken(),
		);
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'signPaycutModal',
				keyValuePairs: { loading: false, isOpen: false },
			},
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: `Transacción completada!`,
				duration: 1.5,
				type: 'success',
			},
		});
		yield put({ type: APIDATA.GET_PAYCUTS, payload: paycutsModal });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'signPaycutModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield signPaycutWorker(action);
			},
		);
	}
}

function* signPaymentWorker(action) {
	const { payment_id, inModule } = action.payload;
	const paycuts = yield select(getPaycuts);

	try {
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'signPaymentModal',
				keyValuePairs: { loading: true },
			},
		});
		yield call(
			axios.put,
			API.DOMAIN.concat('/payments/sign'),
			{ payment_id },
			auth.sendToken(),
		);
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'signPaymentModal',
				keyValuePairs: { loading: false, isOpen: false },
			},
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: `Pago firmado`, duration: 1.5, type: 'success' },
		});

		if (inModule === 'payments') {
			yield put({
				type: APIDATA.GET_PAYMENTS,
				payload: paycuts.selected.items,
			});
		} else if (inModule === 'invoices') {
			yield put({
				type: APIDATA.GET_INVOICE_PAYS,
				payload: paycuts.selected.items,
			});
		}
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'signPaymentModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield signPaymentWorker(action);
			},
		);
	}
}

function* updatePaymentOrdersWorker(action) {
	const { paycutId, payId, editedOrders } = action.payload;
	const paymentOrders = yield select(getPaymentOrders);
	const billingsState = yield select(getBillingsState);
	const { selected, getOrders } = billingsState.pays;

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { orders: { ...paymentOrders, loading: true } },
			},
		});
		yield call(
			axios.put,
			API.DOMAIN.concat(`/payments_orders`),
			{ paycutId, payId, editedOrders },
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.GET_PAYMENTS_ORDERS,
			payload: { payIds: selected.items, getOrders },
		});
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'orders',
				keyValuePairs: { mode: 'view', editedOrders: [] },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: { obj1Name: 'orders', keyValuePairs: { mode: 'edit' } },
				});
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'payments',
						keyValuePairs: { orders: { ...paymentOrders, loading: false } },
					},
				});
			},
			function* () {
				yield updatePaymentOrdersWorker(action);
			},
		);
	}
}

function* syncPaycutWorker(action) {
	const paycut_id = action.payload;
	const paycutsModal = yield select(getPaycutsModal);

	try {
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'paycuts',
				keyValuePairs: { synchronizing: { [paycut_id]: true } },
			},
		});
		yield call(
			axios.post,
			API.DOMAIN.concat(`/paycuts/sync`),
			{ paycut_id },
			auth.sendToken(),
		);
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'paycuts',
				keyValuePairs: { synchronizing: { [paycut_id]: false } },
			},
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: 'Sincronizado', type: 'success' },
		});
		yield put({ type: APIDATA.GET_PAYCUTS, payload: paycutsModal });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'paycuts',
						keyValuePairs: { synchronizing: { [paycut_id]: false } },
					},
				});
			},
			function* () {
				yield syncPaycutWorker(action);
			},
		);
	}
}

function* generatePaymentPDFWorker(action) {
	const paymentIds = action.payload;
	const payments = yield select(getPayments);
	const data = [...payments.pays.data];

	try {
		// generatingPDF: true
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: { obj1Name: 'pays', keyValuePairs: { generatingPDF: true } },
		});
		// Requesting
		yield call(
			axios.post,
			API.DOMAIN.concat(`/payments/generatePDF`),
			{ paymentIds },
			auth.sendToken(),
		);
		// pdfGenerated: true
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getPaymentsResumeModal',
				keyValuePairs: { pdfGenerated: true },
			},
		});
		// Update pdf_generated if payments selected is 1
		if (!Array.isArray(paymentIds)) {
			data.map(pay => {
				if (pay.id === paymentIds) pay.pdf_generated = true;
				return pay;
			});
			yield put({
				type: APIDATA.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'payments',
					keyValuePairs: { pays: { ...payments.pays, data } },
				},
			});
		}
		// generatingPDF: false
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: { obj1Name: 'pays', keyValuePairs: { generatingPDF: false } },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'pays',
						keyValuePairs: { generatingPDF: false },
					},
				});
			},
			function* () {
				yield generatePaymentPDFWorker(action);
			},
		);
	}
}

function* generatePaycutPDFWorker(action) {
	const { paycutIds, excludePayrollUsers, isInvoice, type } = action.payload;
	const payments = yield select(getPayments);
	const data = [...payments.paycuts.data];

	try {
		// generatingPDF: true
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: { obj1Name: 'paycuts', keyValuePairs: { generatingPDF: true } },
		});
		// Requesting
		yield call(
			axios.post,
			API.DOMAIN.concat(`/paycuts/generatePDF`),
			{ paycutIds, excludePayrollUsers, isInvoice, type },
			auth.sendToken(),
		);
		// pdfGenerated: true
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getPaycutsResumeModal',
				keyValuePairs: { pdfGenerated: true },
			},
		});
		// Update pdf_generated if paycuts selected is 1
		if (type === 'paycuts' && paycutIds.length === 1) {
			data.map(paycut => {
				if (paycut.id === paycutIds[0]) paycut.pdf_generated = true;
				return paycut;
			});
			yield put({
				type: APIDATA.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'payments',
					keyValuePairs: { paycuts: { ...payments.paycuts, data } },
				},
			});
		}
		// generatingPDF: false
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: { obj1Name: 'paycuts', keyValuePairs: { generatingPDF: false } },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'paycuts',
						keyValuePairs: { generatingPDF: false },
					},
				});
			},
			function* () {
				yield generatePaycutPDFWorker(action);
			},
		);
	}
}

function* setPaymentReopenWorker(action) {
	const { payment_id, paycut_id } = action.payload;
	const payments = yield select(getPayments);
	const paymentsData = [...payments.pays.data];
	const paycutsData = [...payments.paycuts.data];

	try {
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: { obj1Name: 'pays', keyValuePairs: { updateState: true } },
		});
		yield call(
			axios.put,
			API.DOMAIN.concat(`/payments/open`),
			{ payment_id },
			auth.sendToken(),
		);

		paymentsData.map(pay => {
			if (pay.id === payment_id) pay.state = 'open';
			return pay;
		});
		paycutsData.map(paycut => {
			if (paycut.id === paycut_id) paycut.state = 'open';
			return paycut;
		});
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { pays: { ...payments.pays, data: paymentsData } },
			},
		});
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { paycuts: { ...payments.paycuts, data: paycutsData } },
			},
		});
		yield put({ type: APIDATA.GET_PAYMENT_RESUME, payload: payment_id });
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: { obj1Name: 'pays', keyValuePairs: { updateState: false } },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: { obj1Name: 'pays', keyValuePairs: { updateState: false } },
				});
			},
			function* () {
				yield setPaymentReopenWorker(action);
			},
		);
	}
}

function* setPaymentReadyWorker(action) {
	const { payment_id } = action.payload;
	const payments = yield select(getPayments);
	const paymentsData = [...payments.pays.data];
	const paycutsData = [...payments.paycuts.data];

	try {
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: { obj1Name: 'pays', keyValuePairs: { updateState: true } },
		});
		yield call(
			axios.put,
			API.DOMAIN.concat(`/payments/ready`),
			{ payment_id },
			auth.sendToken(),
		);

		let paycut_id;
		paymentsData.map(pay => {
			if (pay.id === payment_id) {
				pay.state = 'ready';
				paycut_id = pay.paycut_id;
			}
			return pay;
		});

		paycutsData.map(paycut => {
			if (paycut.id === paycut_id) {
				const payOpen = paymentsData.filter(
					pay => pay.paycut_id === paycut.id && pay.state === 'open',
				);
				if (payOpen.length <= 0) {
					paycut.state = 'ready';
				}
			}
			return paycut;
		});

		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { pays: { ...payments.pays, data: paymentsData } },
			},
		});
		yield put({ type: APIDATA.GET_PAYMENT_RESUME, payload: payment_id });
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: { obj1Name: 'pays', keyValuePairs: { updateState: false } },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: { obj1Name: 'pays', keyValuePairs: { updateState: false } },
				});
			},
			function* () {
				yield setPaymentReadyWorker(action);
			},
		);
	}
}

function* enterInvoiceOrdersWorker(action) {
	const body = action.payload;
	const billingsState = yield select(getBillingsState);
	const { selected, getOrders } = billingsState.pays;

	try {
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'enterInvoiceOrdersModal',
				keyValuePairs: { loading: true },
			},
		});
		yield call(
			axios.post,
			API.DOMAIN.concat('/payments_orders/enterInvoiceOrders'),
			body,
			auth.sendToken(),
		);
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'enterInvoiceOrdersModal',
				keyValuePairs: { loading: false, isOpen: false },
			},
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: `Transacción completada`,
				type: 'success',
				duration: 1.5,
			},
		});
		yield put({
			type: APIDATA.GET_PAYMENTS_ORDERS,
			payload: { payIds: selected.items, getOrders },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: BILLINGS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'enterInvoiceOrdersModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield enterInvoiceOrdersWorker(action);
			},
		);
	}
}

/** **** EXPORT DEFAULT ROOT SAGA *******/
export default function* rootSaga() {
	yield all([
		createPaymentWatcher(),
		createPaymentLineWatcher(),
		createPaymentLineItemWatcher(),
		signPaycutWatcher(),
		signPaymentWatcher(),
		updatePaymentOrdersWatcher(),
		syncPaycutWatcher(),
		generatePaycutPDFWatcher(),
		generatePaymentPDFWatcher(),
		setPaymentReopenWatcher(),
		setPaymentReadyWatcher(),
		createPaycutWatcher(),
		enterInvoiceOrdersWatcher(),
	]);
}
