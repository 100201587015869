// @flow
import styled, { css } from 'styled-components';
import { grid } from '../constants';

// $ExpectError - not sure why
export default styled.h4`
	padding: ${grid}px;
	transition: background-color ease 0.2s;
	flex-grow: 1;
	user-select: none;
	position: relative;
	color: whitesmoke;

	${({ ellipsis }) =>
		ellipsis &&
		css`
			display: inline-block;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		`}

	&:focus {
		outline: 2px solid green;
		outline-offset: 2px;
	}
`;
