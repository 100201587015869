// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='centralIcon16'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='1'
			y='2'
			width='14'
			height='12'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.99992 4.66667H14.6666V14H1.33325V2H7.99992V4.66667ZM2.66659 12.6667H3.99992V11.3333H2.66659V12.6667ZM3.99992 10H2.66659V8.66667H3.99992V10ZM2.66659 7.33333H3.99992V6H2.66659V7.33333ZM3.99992 4.66667H2.66659V3.33333H3.99992V4.66667ZM5.33325 12.6667H6.66658V11.3333H5.33325V12.6667ZM6.66658 10H5.33325V8.66667H6.66658V10ZM5.33325 7.33333H6.66658V6H5.33325V7.33333ZM6.66658 4.66667H5.33325V3.33333H6.66658V4.66667ZM7.99992 12.6667H13.3333V6H7.99992V7.33333H9.33325V8.66667H7.99992V10H9.33325V11.3333H7.99992V12.6667ZM11.9999 7.33333H10.6666V8.66667H11.9999V7.33333ZM10.6666 10H11.9999V11.3333H10.6666V10Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#centralIcon16)'>
			<rect width='16' height='16' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
