import React from 'react';
import { useDispatch } from 'react-redux';
//Components
import { Title } from 'components';
import { Button, Table, Form } from 'antd';
import { InventoryContainer } from './styledComponents';
import {
	leftColumns,
	rightColumns,
	inventoryColumns,
	mergedColumns,
} from './tableConfigs/columns';
import { EditableCell, TableTransfer } from './components';
//Services
import {
	deleteRecord,
	editRecord,
	cancelEdit,
	saveRecord,
	handleAddMaterials,
	handleSaveMaterials,
	handleCancelMaterials,
} from './services/inventoryService';
// Hooks
import { useInventory } from './hooks/useInventory';
import useTheme from 'hooks/useTheme';

const Inventory = ({ inventory }) => {
	const { theme } = useTheme();
	const dispatch = useDispatch();
	const {
		data,
		setData,
		editingKey,
		setEditingKey,
		materials,
		setMaterials,
		targetKeys,
		setTargetKeys,
		showTransfer,
		setShowTransfer,
		pagination,
		setPagination,
		paginationTransfer,
		setPaginationTransfer,
		fetchMaterials,
	} = useInventory(inventory);

	const [form] = Form.useForm();

	const isEditing = record => record.id === editingKey;

	const isEditable = (record, dataIndex) => {
		if (record.is_initial_final) {
			if (dataIndex === 'amount') {
				return false;
			} else if (
				dataIndex === 'initial_amount' ||
				dataIndex === 'final_amount'
			) {
				return true;
			}
		} else {
			if (dataIndex === 'amount') {
				return true;
			} else if (
				dataIndex === 'initial_amount' ||
				dataIndex === 'final_amount'
			) {
				return false;
			}
		}
		return true;
	};

	const onPaginationChange = pagination => {
		setPagination(pagination);
	};

	const onPaginationChangeTransfer = paginationTransfer => {
		setPaginationTransfer(paginationTransfer);
	};

	const onChange = nextTargetKeys => {
		setTargetKeys(nextTargetKeys);
	};

	return (
		<Form form={form} component={false}>
			<InventoryContainer theme={theme}>
				{showTransfer ? (
					<Title>Agregar Material al Inventario</Title>
				) : (
					<Title>Inventario de Materiales</Title>
				)}
				{showTransfer ? (
					<>
						{materials.length > 0 ? (
							<>
								<div>
									<Button
										style={{ marginRight: '5px' }}
										type='default'
										onClick={() => handleCancelMaterials(setShowTransfer)}
									>
										Cancelar
									</Button>
									<Button
										type='primary'
										onClick={() =>
											handleSaveMaterials(
												targetKeys,
												materials,
												data,
												setData,
												setShowTransfer,
												dispatch,
											)
										}
									>
										Guardar Materiales
									</Button>
								</div>
								<div
									style={{ height: '80vh', overflowY: 'auto', width: '100%' }}
								>
									<TableTransfer
										dataSource={materials}
										targetKeys={targetKeys}
										showSearch
										oneWay
										onChange={onChange}
										filterOption={(inputValue, item) =>
											item.item.indexOf(inputValue) !== -1 ||
											item.name.indexOf(inputValue) !== -1
										}
										leftColumns={leftColumns}
										rightColumns={rightColumns}
										paginationTransfer={paginationTransfer}
										onPaginationChangeTransfer={onPaginationChangeTransfer}
										titles={[
											'Materiales Disponibles',
											'Materiales en Inventario',
										]}
									/>
								</div>
							</>
						) : (
							<span>Cargando materiales...</span>
						)}
					</>
				) : (
					<>
						<Button
							type='primary'
							onClick={() =>
								handleAddMaterials(
									fetchMaterials,
									setMaterials,
									data,
									setTargetKeys,
									setShowTransfer,
									dispatch,
								)
							}
						>
							Agregar Material
						</Button>
						<Table
							components={{
								body: {
									cell: EditableCell,
								},
							}}
							bordered
							dataSource={data}
							columns={mergedColumns(
								inventoryColumns(
									theme,
									isEditing,
									key =>
										saveRecord(
											key,
											form,
											data,
											setData,
											setEditingKey,
											dispatch,
										),
									() => cancelEdit(setEditingKey),
									record => editRecord(record, form, setEditingKey),
									key => deleteRecord(key, data, setData, dispatch),
									editingKey,
									isEditable,
								),
								isEditing,
								isEditable,
							)}
							rowClassName='editable-row'
							onChange={onPaginationChange}
							size='middle'
							scroll={{ y: 'calc(70vh)' }}
							pagination={{
								...pagination,
								pageSizeOptions: ['10', '20', '50', '100'],
								showSizeChanger: true,
								position: ['bottomCenter'],
							}}
						/>
					</>
				)}
			</InventoryContainer>
		</Form>
	);
};

export default Inventory;
