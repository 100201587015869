// Libs
import React, { useContext } from 'react';
// Components
import { OrderInformation } from 'components/OrderInformation';
import { ElementViewBuilderContext } from 'core/context';

const OrderViewer = () => {
	const { payload = {} } = useContext(ElementViewBuilderContext);

	return <OrderInformation orderId={payload.elementId} />;
};

export default OrderViewer;
