// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
// Utils
import { getPaymentColorState, isPayRowSelected } from '../../../utils';
import { formatNumber, coinsUtils } from 'utils/libs';
import { filterPayments } from 'utils/filters/Billings/payments';
import GENERAL from 'utils/constants/general';
// Components
import { Wrapper, TableVirtualized, Flag, Span, Spin, Link } from 'components';

const { ENV } = GENERAL;
const { Table, Column, Header, Row, Cell } = TableVirtualized;

function PaymentsManageDesktop(props) {
	if (
		!Array.isArray(props?.paycuts?.selected?.items) ||
		props?.paycuts?.selected?.items?.length === 0
	) {
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_PAYCUTS_DESKTOP} />;
	}

	const {
		// BILLINGS
		paycuts,
		pays,
		mutate1Object,
		getPaymentLines,
		getPaymentOrders,
		// API
		payments,
		coins,
		// GENERAL
		profile,
	} = props;
	const allPayments = payments.pays.data;
	const filteredPays = filterPayments(allPayments, pays.filters);

	// ------RENDERS------

	// HEADER
	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: allPayments,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: pays.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('pays', { filters: updatedFilters }),
				}}
			>
				{label}
			</Header>
		);
	}

	// ROW
	function getRowRenderer({ key, columns, style, rowData }) {
		let styleRow = { ...style };
		const payIds = Array.isArray(rowData.id) ? [...rowData.id] : [rowData.id];

		// Is payment selected?
		if (isPayRowSelected(pays.selected, payIds)) {
			styleRow = {
				...styleRow,
				color: 'lightseagreen',
				backgroundColor: 'rgb(44,44,54)',
			};
		}

		return (
			<Row
				key={key}
				style={styleRow}
				onClick={() => {
					mutate1Object('pays', {
						selected: { ...pays.selected, items: payIds },
					});
				}}
			>
				{columns}
			</Row>
		);
	}

	// ID
	function getIDCellRenderer({ rowData }) {
		return (
			<Cell>
				<Flag style={getPaymentColorState(rowData.state)} height='20px' />
				<Span margin='0 0 0 5px'>{rowData.id}</Span>
			</Cell>
		);
	}

	// LINE
	function getLineCellRenderer({ rowData }) {
		return (
			<Link
				className='desktop-warehouses-list-table-name-cell-container'
				to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_LINES_DESKTOP}
				onClick={() => {
					const payIds = Array.isArray(rowData.id)
						? [...rowData.id]
						: [rowData.id];
					mutate1Object('pays', {
						selected: { ...pays.selected, items: payIds },
					});
					getPaymentLines(payIds, rowData.paid_to);
				}}
			>
				{rowData.lines_quantity}/{rowData.unlines_quantity}
			</Link>
		);
	}

	// ORDERS QTY
	function getOrdersQtyCellRenderer({ rowData, dataKey }) {
		function canGetOrders() {
			if (Number(rowData[dataKey]) <= 0) return false;
			return true;
		}

		return (
			<Link
				to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_ORDERS_DESKTOP}
				onClick={() => {
					if (canGetOrders()) {
						const payIds = Array.isArray(rowData.id)
							? [...rowData.id]
							: [rowData.id];

						mutate1Object('pays', {
							selected: { ...pays.selected, items: payIds },
							getOrders: dataKey,
						});
						getPaymentOrders(payIds, dataKey);
					}
				}}
			>
				{rowData[dataKey]}
			</Link>
		);
	}

	// AMOUNT
	function getAmountCellRenderer({ rowData, dataKey }) {
		const coinSymbol = coinsUtils.getDataByCoinId(
			rowData.coin_id,
			'symbol',
			coins.data,
		);

		return (
			<Cell>
				{dataKey === 'production_amount'
					? formatNumber.new(rowData.production_amount, coinSymbol, 2)
					: dataKey === 'production_charge' &&
					  formatNumber.new(rowData.production_charge, coinSymbol, 2)}
			</Cell>
		);
	}

	// COST/SALE
	function getCostSaleCellRenderer({ rowData }) {
		const { cost, sale } = rowData;

		return !isNaN(cost) && !isNaN(sale) ? (
			<Cell>
				{cost}% / {sale}%
			</Cell>
		) : (
			<div />
		);
	}

	return (
		<Wrapper padding='0' className='animated fadeIn'>
			<Spin
				spinning={payments.pays.loading}
				size='large'
				delay={50}
				tip={'Consultando Pagos...'}
			>
				<Table
					highBackground
					width={document.documentElement.clientWidth}
					height={document.documentElement.clientHeight - 115}
					headerHeight={44}
					rowHeight={25}
					rowCount={filteredPays.length}
					rowGetter={({ index }) => filteredPays[index]}
					rowRenderer={getRowRenderer}
				>
					{paycuts.selected.items.length === 1 && (
						<Column
							dataKey='id'
							label='ID'
							width={70}
							headerRenderer={props => getHeaderRenderer(props, 'numeric')}
							cellRenderer={getIDCellRenderer}
						/>
					)}
					<Column
						dataKey='paid_to_name'
						label='Nombre'
						width={200}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='orders_total'
						label='Atendidas'
						width={100}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getOrdersQtyCellRenderer}
					/>
					<Column
						dataKey='orders_topay'
						label='Pagar'
						width={70}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getOrdersQtyCellRenderer}
					/>
					<Column
						dataKey='orders_notpay'
						label='No Pagar'
						width={60}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getOrdersQtyCellRenderer}
					/>
					<Column
						dataKey='orders_previous'
						label='Anteriores'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getOrdersQtyCellRenderer}
					/>
					<Column
						dataKey='orders_pending'
						label='Pendientes de Pago'
						width={100}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getOrdersQtyCellRenderer}
					/>
					<Column
						dataKey='orders_paid'
						label='Pago Final'
						width={70}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getOrdersQtyCellRenderer}
					/>
					<Column
						dataKey='lines_quantity'
						label='Lineas'
						width={50}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getLineCellRenderer}
					/>
					<Column
						dataKey='production_amount'
						label='Costo'
						width={110}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getAmountCellRenderer}
					/>
					<Column
						dataKey='production_charge'
						label='Venta'
						width={110}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getAmountCellRenderer}
					/>
					<Column
						dataKey=''
						label='Costo / Venta'
						width={115}
						headerRenderer={({ label }) => <Header>{label}</Header>}
						cellRenderer={getCostSaleCellRenderer}
					/>
					<Column
						dataKey='payroll_label'
						label='Planilla'
						width={50}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					{paycuts.selected.items.length === 1 && (
						<Column
							dataKey='template_name'
							label='Template'
							width={120}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
						/>
					)}
					<Column
						dataKey='state_label'
						label='Estado'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='signed_label'
						label='Firmado'
						width={70}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='creator_name'
						label='Creador'
						width={110}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='entity_name'
						label='Entidad'
						width={110}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
				</Table>
			</Spin>
		</Wrapper>
	);
}

export default PaymentsManageDesktop;
