// Libs
import React from 'react';
import _ from 'underscore';
import { Card, InputNumber } from 'antd';
// Utils
import { getFlagColorOrderCard } from '../../../../../Billings/utils';
import { formatNumber, coinsUtils } from '../../../../../../utils/libs';
// Styles
import './PaymentOrderCard.css';

const { Meta } = Card;

export default function PaymentOrderCard(props) {
	const {
		id,
		order_id,
		odt_id,
		service_name,
		expert_name,
		completed_code_name,
		amount,
		bonus,
		charge,
		price,
		counter,
		cost,
		sale,
		saleCostColor,
		customProps,
	} = props;
	const {
		orders,
		editedOrders,
		mutate1ObjectInBillings,
		mutate1ObjectInOrders,
		getOrder,
		mode,
		paysManage,
		rowHeight,
		profile,
		coins,
	} = customProps;
	const coinSymbol = coinsUtils.getDataByCoinId(price.coinId, 'symbol', coins);
	const amountPrice = formatNumber.new(amount, coinSymbol, 2);
	const chargePrice = formatNumber.new(charge, coinSymbol, 2);
	const bonusPrice = formatNumber.new(bonus, coinSymbol, 2);
	const formatPrice = price && formatNumber.new(price.price, coinSymbol, 2);
	const idx = _.findIndex(editedOrders, ord => ord.id === id);
	const flagColor = getFlagColorOrderCard(props, paysManage.getOrders, profile);
	const metaHeight = rowHeight - 5;

	function onClickOdtId() {
		if (!orders.filter(ord => ord.order_id === order_id)[0]) {
			getOrder(order_id);
		}
		mutate1ObjectInOrders('getOrderInformationModal', {
			order_id,
			isOpen: true,
		});
	}

	function validCostSale() {
		return !isNaN(cost) && !isNaN(sale);
	}

	return (
		<div>
			<div className='payment_order-flag-payment-state' style={flagColor} />
			<Meta
				id='payment_order-card-container'
				style={{ height: metaHeight }}
				title={
					<div
						style={{ height: metaHeight - 15 }}
						className='payment_order-container-title-card'
					>
						<div className='payment_order-card-order-price-container'>
							<span className='payment_order-card-order' onClick={onClickOdtId}>
								{odt_id}
							</span>
							<div />
						</div>

						{mode === 'view' ? (
							<div>
								<div>
									{service_name && (
										<div style={{ height: '18px' }}>
											<span className='payment_order-body-concept-item'>
												Servicio:
											</span>
											<span className='payment_order-card-creator'>
												{service_name}
											</span>
										</div>
									)}
									{expert_name && (
										<div style={{ height: '18px' }}>
											<span className='payment_order-body-concept-item'>
												Técnico:
											</span>
											<span className='payment_order-card-created_at'>
												{expert_name}
											</span>
										</div>
									)}
									{completed_code_name && (
										<div style={{ height: '18px' }}>
											<span className='payment_order-body-concept-item'>
												Código Completado:
											</span>
											<span className='payment_order-card-created_at'>
												{completed_code_name}
											</span>
										</div>
									)}
									{paysManage.getOrders === 'orders_paid' ? (
										<div style={{ height: '18px' }}>
											<span className='payment_order-body-concept-item'>
												Costo:
											</span>
											<span className='payment_order-card-created_at'>
												{amountPrice}
											</span>
										</div>
									) : (
										<div style={{ height: '18px' }}>
											<span className='payment_order-body-concept-item'>
												Precio:
											</span>
											<span className='payment_order-card-created_at'>
												{formatPrice}
											</span>
										</div>
									)}
									{paysManage.getOrders === 'orders_paid' && (
										<div style={{ height: '18px' }}>
											<span className='payment_order-body-concept-item'>
												Venta:
											</span>
											<span className='payment_order-card-created_at'>
												{chargePrice}
											</span>
										</div>
									)}
									{paysManage.getOrders === 'orders_paid' &&
										validCostSale() && (
											<div style={{ height: '18px' }}>
												<span className='payment_order-body-concept-item'>
													Costo/Venta:
												</span>
												<span className='payment_order-card-created_at'>
													{cost}% / {sale}%
												</span>
											</div>
										)}
									{paysManage.getOrders === 'orders_paid' && (
										<div style={{ height: '18px' }}>
											<span className='payment_order-body-concept-item'>
												Bono:
											</span>
											<span className='payment_order-card-created_at'>
												{bonusPrice}
											</span>
										</div>
									)}
								</div>
								<div style={{ height: '18px', textAlign: 'end' }}>
									<span className='payment_order-card-index'>{counter}</span>
								</div>
							</div>
						) : (
							<InputNumber
								style={{ marginTop: '7px', width: '100%' }}
								placeholder='Bono*'
								min={0.0}
								step={0.1}
								value={
									idx !== -1
										? editedOrders[idx].bonus
										: bonus >= 0.01
										? bonus
										: undefined
								}
								formatter={value =>
									`${coinSymbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								}
								parser={value => {
									value = value.replace(coinSymbol, '');
									value = value.replace(/\$\s?|(,*)/g, '');
									value = value.replace(/ /g, '');
									return value;
								}}
								onChange={value => {
									if (value >= 0.01 || value != bonus) {
										if (idx === -1) {
											editedOrders.push({
												id,
												bonus: value,
											});
										} else editedOrders[idx].bonus = value || 0.0;
									} else {
										if (idx !== -1) {
											mutate1ObjectInBillings('orders', {
												editedOrders: editedOrders.filter(ord => ord.id !== id),
											});
										}
									}
									mutate1ObjectInBillings('orders', { editedOrders });
								}}
							/>
						)}
					</div>
				}
			/>
		</div>
	);
}
