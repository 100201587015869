// Libs
import React from 'react';
import { Link } from 'react-router-dom';
// Utils
import GENERAL from 'utils/constants/general';
import { formatNumber, coinsUtils } from 'utils/libs';
import {
	getPaymentColorState,
	thisBelongsToMyEntity,
} from 'screens/Billings/utils';
// Components
import { Card } from 'antd';
import { Button, DocumentIcon, Popover } from 'components';
// Styles
import './InvoicePayCard.css';

const { Meta } = Card;
const { ENV } = GENERAL;

function canGetOrders(ordersType, key, getPaycutsModal) {
	if (
		(getPaycutsModal.type === 'payment' && Number(ordersType) <= 0) ||
		(getPaycutsModal.type === 'invoice' &&
			key !== 'orders_paid' &&
			Number(ordersType) <= 0)
	) {
		return false;
	}
	return true;
}

export default function InvoicePayCard(props) {
	const {
		id,
		payment_name,
		orders_total,
		orders_topay,
		orders_notpay,
		orders_previous,
		orders_pending,
		orders_paid,
		lines_quantity,
		production_amount,
		production_charge,
		state_label,
		state,
		paid_to,
		paid_to_name,
		creator_name,
		entity_name,
		entity_id,
		coin_id,
		paycut_from_date,
		paycut_to_date,
		template_name,
		customProps,
	} = props;
	const {
		paysManage,
		coins,
		profile,
		getPaycutsModal,
		getPaymentResume,
		getPaymentOrders,
		getPaymentLines,
		mutate1ObjectInBillings,
	} = customProps;
	const coinSymbol = coinsUtils.getDataByCoinId(coin_id, 'symbol', coins);
	const productionAmount = formatNumber.new(production_amount, coinSymbol, 2);
	const productionCharge = formatNumber.new(production_charge, coinSymbol, 2);
	const difference = formatNumber.new(
		production_amount - production_charge,
		coinSymbol,
		2,
	);
	const payIds = Array.isArray(id) ? [...id] : [id];

	const GetOrdersContent = (
		<ul className='pay-popover-list'>
			<li>
				<Link
					disabled={
						!canGetOrders(orders_total, 'orders_total', getPaycutsModal)
					}
					id={
						!canGetOrders(orders_total, 'orders_total', getPaycutsModal)
							? 'pay-popover-item-disabled'
							: 'pay-popover-item'
					}
					to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_ORDERS_MOBILE}
					onClick={() => {
						mutate1ObjectInBillings('pays', {
							selected: { ...paysManage.selected, items: payIds },
							getOrders: 'orders_total',
						});
						getPaymentOrders(payIds, 'orders_total');
					}}
				>
					Atendidas ({orders_total})
				</Link>
			</li>
			<li>
				<Link
					disabled={
						!canGetOrders(orders_topay, 'orders_topay', getPaycutsModal)
					}
					id={
						!canGetOrders(orders_topay, 'orders_topay', getPaycutsModal)
							? 'pay-popover-item-disabled'
							: 'pay-popover-item'
					}
					to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_ORDERS_MOBILE}
					onClick={() => {
						mutate1ObjectInBillings('pays', {
							selected: { ...paysManage.selected, items: payIds },
							getOrders: 'orders_topay',
						});
						getPaymentOrders(payIds, 'orders_topay');
					}}
				>
					Cobrar ({orders_topay})
				</Link>
			</li>
			<li>
				<Link
					disabled={
						!canGetOrders(orders_notpay, 'orders_notpay', getPaycutsModal)
					}
					id={
						!canGetOrders(orders_notpay, 'orders_notpay', getPaycutsModal)
							? 'pay-popover-item-disabled'
							: 'pay-popover-item'
					}
					to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_ORDERS_MOBILE}
					onClick={() => {
						mutate1ObjectInBillings('pays', {
							selected: { ...paysManage.selected, items: payIds },
							getOrders: 'orders_notpay',
						});
						getPaymentOrders(payIds, 'orders_notpay');
					}}
				>
					No Cobrar ({orders_notpay})
				</Link>
			</li>
			<li>
				<Link
					disabled={
						!canGetOrders(orders_previous, 'orders_previous', getPaycutsModal)
					}
					id={
						!canGetOrders(orders_previous, 'orders_previous', getPaycutsModal)
							? 'pay-popover-item-disabled'
							: 'pay-popover-item'
					}
					to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_ORDERS_MOBILE}
					onClick={() => {
						mutate1ObjectInBillings('pays', {
							selected: { ...paysManage.selected, items: payIds },
							getOrders: 'orders_previous',
						});
						getPaymentOrders(payIds, 'orders_previous');
					}}
				>
					Anteriores ({orders_previous})
				</Link>
			</li>
			<li>
				<Link
					disabled={
						!canGetOrders(orders_pending, 'orders_pending', getPaycutsModal)
					}
					id={
						!canGetOrders(orders_pending, 'orders_pending', getPaycutsModal)
							? 'pay-popover-item-disabled'
							: 'pay-popover-item'
					}
					to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_ORDERS_MOBILE}
					onClick={() => {
						mutate1ObjectInBillings('pays', {
							selected: { ...paysManage.selected, items: payIds },
							getOrders: 'orders_pending',
						});
						getPaymentOrders(payIds, 'orders_pending');
					}}
				>
					Pendientes de Pago ({orders_pending})
				</Link>
			</li>
			<li>
				<Link
					disabled={!canGetOrders(orders_paid, 'orders_paid', getPaycutsModal)}
					id={
						!canGetOrders(orders_paid, 'orders_paid', getPaycutsModal)
							? 'pay-popover-item-disabled'
							: 'pay-popover-item'
					}
					to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_ORDERS_MOBILE}
					onClick={() => {
						mutate1ObjectInBillings('pays', {
							selected: { ...paysManage.selected, items: payIds },
							getOrders: 'orders_paid',
						});
						getPaymentOrders(payIds, 'orders_paid');
					}}
				>
					Pago Final ({orders_paid})
				</Link>
			</li>
		</ul>
	);

	return (
		<div>
			<div
				className='invoice_pay-flag-payment-state'
				style={getPaymentColorState(state)}
			/>
			<Meta
				id='invoice_pay-card-container'
				title={
					<div className='invoice_pay-container-title-card'>
						<span className='invoice_pay-card-document'>
							{id}-{payment_name || paid_to_name}
						</span>

						<div style={{ height: '18px' }}>
							<span className='invoice_pay-body-concept-item'>Creado por:</span>
							<span className='invoice_pay-card-creator'>
								{creator_name} - {entity_name}
							</span>
						</div>
						<div style={{ height: '18px' }}>
							<span className='invoice_pay-body-concept-item'>Desde:</span>
							<span className='invoice_pay-card-created_at'>
								{paycut_from_date}
							</span>
						</div>
						<div style={{ height: '18px' }}>
							<span className='invoice_pay-body-concept-item'>Hasta:</span>
							<span className='invoice_pay-card-created_at'>
								{paycut_to_date}
							</span>
						</div>
					</div>
				}
				description={
					<div>
						<div className='invoice_pay-container-data-input'>
							<div className='invoice_pay-data'>
								<div className='invoice_pay-body-container-item'>
									<div className='invoice_pay-body-concept-item'>
										Cobrado: <b>{productionAmount}</b>
									</div>
								</div>
								{thisBelongsToMyEntity(entity_id, profile) && (
									<div className='invoice_pay-body-container-item'>
										<div className='invoice_pay-body-concept-item'>
											Cobrar: <b>{productionCharge}</b>
										</div>
									</div>
								)}
								{thisBelongsToMyEntity(entity_id, profile) && (
									<div className='invoice_pay-body-container-item'>
										<div className='invoice_pay-body-concept-item'>
											Diferencia: <b>{difference}</b>
										</div>
									</div>
								)}
							</div>

							<div className='invoice_pay-data'>
								<div className='invoice_pay-body-container-item'>
									<div className='invoice_pay-body-concept-item'>
										Template: <b>{template_name}</b>
									</div>
								</div>
								<div className='invoice_pay-body-container-item'>
									<div className='invoice_pay-body-concept-item'>
										Estado: <b>{state_label}</b>
									</div>
								</div>
							</div>
						</div>

						<div className='invoice_pay-buttons-container'>
							<Popover
								content={GetOrdersContent}
								placement='top'
								title={null}
								trigger='click'
							>
								<Button style={{ height: '28px', fontSize: '13px' }}>
									Ordenes
								</Button>
							</Popover>

							{thisBelongsToMyEntity(entity_id, profile) ? (
								<Link
									to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_LINES_MOBILE}
									onClick={() => {
										mutate1ObjectInBillings('pays', {
											selected: { ...paysManage.selected, items: payIds },
										});
										getPaymentLines(payIds, paid_to);
									}}
								>
									<Button
										style={{
											marginLeft: '10px',
											height: '28px',
											fontSize: '13px',
										}}
									>
										Líneas ({lines_quantity})
									</Button>
								</Link>
							) : (
								<span className='invoice_pay-lines-label'>
									Líneas ({lines_quantity})
								</span>
							)}
							<DocumentIcon
								onClick={() => {
									mutate1ObjectInBillings('pays', {
										selected: { ...paysManage.selected, items: payIds },
									});
									mutate1ObjectInBillings('signPaymentModal', { isOpen: true });
									getPaymentResume(payIds);
								}}
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
}
