// Libs
import React from 'react';
import dayjs from 'dayjs';
// Hooks
import useNearScreen from 'hooks/useNearScreen';
// Components
import { Wrapper, Subtitle } from 'components';
import NotificationCard from './NotificationCard';

function IntersectedLastNotification({
	rootObserverRef,
	getNotifications,
	lastRowId,
}) {
	// Near screen to update seen
	const [isNearScreen, fromRef] = useNearScreen({
		root: rootObserverRef?.current,
		rootMargin: '50px',
		observeOnce: false,
	});
	React.useEffect(() => {
		if (isNearScreen && lastRowId >= 2) getNotifications({ lastRowId });
	}, [isNearScreen, lastRowId]);

	return <Wrapper elementRef={fromRef} padding='0' />;
}

function NotificationList({
	isFetching,
	profile,
	notifications,
	getNotifications,
	markNotification,
	selectNotification,
}) {
	const rootObserverRef = React.useRef();
	const { news, previous } = React.useMemo(
		() =>
			notifications.reduce(
				(acc, notification) => {
					if (
						notification.seen &&
						dayjs() - dayjs(notification.createdAt) > 10800000
					) {
						acc.previous.push(notification);
					} else {
						acc.news.push(notification);
					}
					return acc;
				},
				{
					news: [],
					previous: [],
				},
			),
		[notifications],
	);
	const lastRowId = React.useMemo(
		() => notifications[notifications.length - 1]?.userNotificationId,
		[notifications],
	);
	const isTotalLastRow = React.useMemo(
		() =>
			Number(lastRowId) ===
			Number(notifications[notifications.length - 1]?.totalLastRowId),
		[lastRowId],
	);

	if (!profile?.user?.id) return;

	return (
		<Wrapper
			elementRef={rootObserverRef}
			padding='0 16px 16px 16px'
			flexDirection='column'
			alignItems='stretch'
			width='100%'
			height='100%'
			maxHeight={`${document.documentElement.clientHeight - 50}px`}
			overflow='auto'
			mediumBackground
		>
			<Subtitle margin='20px 0 0 0'>Nuevas</Subtitle>
			{news.map((notification, idx) => (
				<NotificationCard
					key={notification.userNotificationId}
					profile={profile}
					notification={notification}
					selectNotification={selectNotification}
					markNotification={markNotification}
					rootObserverRef={rootObserverRef}
				/>
			))}
			<Subtitle margin='20px 0 0 0'>Anteriores</Subtitle>
			{previous.map((notification, idx) => (
				<NotificationCard
					key={notification.userNotificationId}
					profile={profile}
					notification={notification}
					selectNotification={selectNotification}
					markNotification={markNotification}
					rootObserverRef={rootObserverRef}
				/>
			))}
			{!isFetching && !isTotalLastRow && (
				<IntersectedLastNotification
					rootObserverRef={rootObserverRef}
					getNotifications={getNotifications}
					lastRowId={lastRowId}
				/>
			)}
		</Wrapper>
	);
}

export default NotificationList;
