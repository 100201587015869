//  Constants
import GENERAL from 'utils/constants/general';
import Immutable from 'immutable';
import _ from 'underscore';
import moment from 'moment-timezone';
/// /TODO: DEPRECATED:
import { isCanContinueProcessImportOrders } from './utils';
import { filterConflictsImporting } from 'utils/filters/Orders/ConflictsImporting';

const { ORDERS, ENV } = GENERAL;
const now = moment();

//  InitialState
const initialState = {
	// View and control orders
	control: {
		orders: [],
		selectedOrders: [],
		filteredProjects: [],
		filterOptionSubmenuOpenKeys: [],
		filters: {},
		role: 'view', // view, reassignment, sorting
		view: ENV.DEVICE_TYPE === 'Mobile' ? 'users' : 'table', // users, events, services, list, table
		subToolbarStyle: ENV.DEVICE_TYPE === 'Mobile' ? 'MOBILE' : 'DESKTOP',
		searchInData: {
			renderToolbar: false,
			data: '',
			searchBy: 'all', // deepSearch, order, contract, client, expert, company, event, state, all //Nota: Se debe dejar en all, para que llame el método que obtiene las ordenes en el componentDidMount
			showPopover: false,
			pressEnter: false,
		},
		sorting: {
			loading: false,
			selectedUsers: {},
		},
		myAssignedZones: {
			isOpen: false,
			userBoZones: [],
			selectedZone: undefined,
		},
		updateOrderModal: {
			isOpen: false,
			loading: false,
			success: false,
			tryItAgain: false,
			// Data
			completedAuthorizationCode: undefined,
		},
	},
	experts: {
		isLoading: false,
		data: [],
	},
	// -------------------------------------MODALS------------------------------------
	// Reassign order to User
	ordersReassignmentModal: {
		collapseActiveKey: -1,
		isOpen: false,
		loading: false,
		reassigning: false,
		data: [],
		/**
		 * assignment
		 *
		 * local: Reasigna la orden al usuario unicamente de forma local en el FrontEnd (Utilizada por ejemplo, para la asignación local de usuarios al importar ordenes)
		 * absolute: Reasigna la orden de forma absoluta en la Base de Datos (Utilizada por ejemplo, para la reasignación de órdenes en el control)
		 *
		 * NOTA: Se debe definir al llamar el modal (isOpen: true)
		 *
		 */
		assignment: undefined,
		filterUsers: [],
		viewTotalUsers: false,
		userSearching: '',
	},
	// Build query to get orders from API
	getOrdersQueryModal: {
		isOpen: false,
		loading: false,
		fromDate: now,
		toDate: now,
		fromTime: now,
		toTime: now,
		eventCategoryToSearch: 'dailyTracking',
		eventToSearch: 'all',
		stateToSearch: 'all',
		searchTo: 'expert', // expert, company
		expertToSearch: 'all',
		entityToSearch: 'all',
	},
	// Get info from orders income
	getInfoOrdersIncomeModal: {
		isOpen: false,
		state: 'start',
		fromDate: now,
		toDate: now,
		data: [],
	},
	// Get info from orders income
	getOrderInformationModal: {
		activeTab: '1',
		order_id: null,
		isOpen: false,
		loading: false,
		settingOrderProps: false,
		history: [],
		auditedOrderReport: {},
		// Comments
		viewComments: false,
		newComment: undefined,
		sendingComment: false,
		// Reports
		billingReport: {
			id: undefined,
			order_id: undefined,
			inventory: [],
			comments: [],
		},
		photoReport: {
			path: {},
			comments: [],
		},
		expedients: [],
	},
	// Send an event to selected orders
	sendEventModal: {
		isOpen: false,
		loading: false,
		eventId: '',
		reprogramDate: now,
		reprogramTime: now,
		comment: '',
	},
	// -------------------------------------MANAGEMENT------------------------------------
	// Enter new orders
	enterNewOrdersModal: {
		isOpen: false,
		activeTab: '1',
		// Department
		validationTemplateId: undefined,
		// Date format
		dateFormat: undefined,
		// Imported File
		importedFile: {
			loaded: undefined, // undefined: No se ingresado ningun archivo aún, false: Ya se ingresó pero se está procesando, true: Lectura y depuración terminada
			name: undefined,
			data: [],
		},
		ordersExistsValidationState: 'waiting', // [waiting, validating, validated, error]
		/**
		 * mappingValidationOk
		 *
		 * undefined: No se ha validado aún
		 * false: Mapeo inválido
		 * true: Mapeo validado correctamente
		 */
		mappingValidationOk: undefined,
		/**
		 * userAssignment
		 *
		 * waiting: Cuando aún no se ha llegado al paso de asignar usuarios
		 * error: Cuando se produce un error en el proceso de asignación del API
		 * assigning: Cuando ya se está enviando al API los datos para que éste asigne los usuarios
		 * withConflicts: Cuando el API responde con la asignación de los usuarios, se realiza la revisión de la asignación y se encuentran conflictos
		 * withoutConflicts: Cuando el API responde con la asignación de los usuarios, se realiza la revisión de la asignación y no se encuentran conflictos
		 * confirmed: Cuando el administrador manualmente confirma la asignación de los usuarios
		 */
		userAssignment: 'waiting', // [waiting, error, assigning, withConflicts, withoutConflicts, confirmed]
		// Request to Save Orders
		request: {
			status: 'waiting', // [waiting, sending, error, success]
		},
		// Selected rows
		selected: {
			items: [],
		},
		// Filter options
		filters: {},
		// On Hover Cells
		onHoverCells: {},
	},
	// Enter new zones
	zones: {
		loading: false,
		newIncomes: [],
	},
	// Automatic orders assignment
	automaticOrdersAssignment: {
		loading: false,
		orders: [],
		globalFilter: '',
		selectedOrders: [],
	},
};

const orderReducer = (state = initialState, action) => {
	switch (action.type) {
		case ORDERS.MUTATE_DIRECT_PROPS: {
			const keyValuePairs = action.payload;
			return {
				...state,
				...keyValuePairs,
			};
		}
		case ORDERS.MUTATE_1OBJECT: {
			const { obj1Name, keyValuePairs } = action.payload;
			const newObj = { ...state[obj1Name], ...keyValuePairs };
			return {
				...state,
				[obj1Name]: newObj,
			};
		}
		case ORDERS.SET_USER_ASSIGNMENT_TO_ORDERS: {
			/// /TODO: DEPRECATED:
			const {
				ordersAssignment,
				odtField,
				assignedTechField,
				validateFieldsTemplate,
			} = action.payload;
			let conflicts = false;
			const data = Immutable.List(state.enterNewOrdersModal.importedFile.data)
				.toJS()
				.map(order => {
					// Matching order
					const orderIdx = _.findIndex(
						ordersAssignment,
						ord => ord[odtField.id] === order[odtField.id],
					);
					const orderAssignment = orderIdx !== -1 && ordersAssignment[orderIdx];
					// Not matched order?
					if (!orderAssignment) {
						order[assignedTechField.id] = undefined;
						order[assignedTechField.name] = '';
						order.entity_id = undefined;
						order.entity_name = '';
					}
					// Matched order?
					else {
						order = {
							...order,
							...orderAssignment,
							warranty_label: orderAssignment.warranty ? 'Si' : 'No',
						};
					}

					// Validate Assignment
					if (
						!order.exists &&
						(!order[assignedTechField.id] || !order.entity_id)
					) {
						order.mappingErrors = 'N/A';
						conflicts = true;
					}

					return order;
				});

			// Set filters & userAssignment
			const filters = { ...state.enterNewOrdersModal.filters };
			let userAssignment;
			if (conflicts) {
				filters.mappingErrors = {
					checkedList: ['N/A'],
					indeterminate: true,
					checkedAll: false,
				};
				userAssignment = 'withConflicts';
			} else {
				userAssignment = 'withoutConflicts';
			}

			if (
				isCanContinueProcessImportOrders(
					data,
					validateFieldsTemplate,
					userAssignment,
				) &&
				filterConflictsImporting(data, filters, userAssignment).length === 0
			) {
				userAssignment = 'confirmed';
			}

			return {
				...state,
				enterNewOrdersModal: {
					...state.enterNewOrdersModal,
					userAssignment,
					importedFile: {
						...state.enterNewOrdersModal.importedFile,
						data,
					},
					filters,
				},
			};
		}
		case ORDERS.RESEST_ENTER_NEW_ORDERS: {
			const props = action.payload;

			return {
				...state,
				enterNewOrdersModal: {
					...state.enterNewOrdersModal,
					...props,
					// Imported File
					importedFile: {
						loaded: undefined,
						name: undefined,
						data: [],
					},
					ordersExistsValidationState: 'waiting',
					mappingValidationOk: undefined,
					userAssignment: 'waiting',
					// Request to Save Orders
					request: {
						status: 'waiting',
					},
					// Selected rows
					selected: {
						items: [],
					},
					// Filter options
					filters: {},
					// On Hover Cells
					onHoverCells: {},
				},
			};
		}
		case ORDERS.ORDERS_EXISTS_VALIDATE_SUCCESS: {
			const { ordersExists, odtField } = action.payload;
			const data = Immutable.List(state.enterNewOrdersModal.importedFile.data)
				.toJS()
				.map(row => {
					row.exists =
						_.findIndex(
							ordersExists,
							ord => ord.odt_id === row[odtField.id],
						) !== -1;
					return row;
				});

			return {
				...state,
				enterNewOrdersModal: {
					...state.enterNewOrdersModal,
					importedFile: {
						...state.enterNewOrdersModal.importedFile,
						data,
					},
					ordersExistsValidationState: 'validated',
				},
			};
		}
		case ORDERS.GET_COUNT_ORDERS_TO_REASSIGNMENT_SUCCESS: {
			const { assignedTechIds, data } = action.payload;
			const newData = Immutable.List(state.ordersReassignmentModal.data)
				.toJS()
				.filter(row => {
					return assignedTechIds.indexOf(row.assigned_tech_id) === -1;
				})
				.concat(data);

			return {
				...state,
				ordersReassignmentModal: {
					...state.ordersReassignmentModal,
					data: newData,
					loading: false,
				},
			};
		}
		case ORDERS.RESET_ORDERS_REASSIGNMENT_MODAL: {
			const props = action.payload || {};
			return {
				...state,
				ordersReassignmentModal: {
					...state.ordersReassignmentModal,
					...props,
					collapseActiveKey: -1,
					isOpen: false,
					loading: false,
					data: [],
					assignment: undefined,
					filterUsers: [],
					viewTotalUsers: false,
					userSearching: '',
				},
			};
		}
		case ORDERS.RESET_ORDER_INFORMATION: {
			return {
				...state,
				getOrderInformationModal: {
					...initialState.getOrderInformationModal,
				},
			};
		}
		case ORDERS.GET_ORDER_SUCCESS: {
			const order = action.payload;
			const orders = [...state.control.orders];

			const idx = _.findIndex(orders, ord => ord.order_id === order.order_id);
			if (idx === -1) orders.push(order);
			else if (idx !== -1) orders[idx] = order;

			return {
				...state,
				control: {
					...state.control,
					orders,
				},
			};
		}
		case ORDERS.SEND_ORDER_EVENTS_SUCCESS: {
			const { eventData, ordersToUpdateEvent, comment, reprogramAt, users } =
				action.payload;
			const orders = [...state.control.orders];
			let expert_name = '';

			// Setting user name
			if (eventData.assigned_tech_id) {
				const idx = _.findIndex(
					users,
					user => user.id === eventData.assigned_tech_id,
				);
				if (idx !== -1) expert_name = users[idx].name;
			}

			// Setting eventData to selected orders
			ordersToUpdateEvent.forEach(item => {
				const idx = _.findIndex(
					orders,
					order => order.order_id === item.order_id,
				);
				if (idx !== -1) {
					if (eventData.assigned_tech_id) {
						orders[idx].assigned_tech_id = eventData.assigned_tech_id;
						orders[idx].expert_name = expert_name;
					}
					if (reprogramAt) {
						orders[idx].reprogram_at = eventData.reprogramAt;
						orders[idx].formatReprogramAt = eventData.formatReprogramAt;
					}
					orders[idx].event_id = eventData.eventId;
					orders[idx].event_name = eventData.eventName;
					orders[idx].event_comment = comment;
					orders[idx].event_props = eventData.orderEventProps;
					orders[idx].last_event_at = eventData.lastEventAt;
					orders[idx].formatLastEventAt = eventData.formatLastEventAt;
				}
			});

			return {
				...state,
				control: {
					...state.control,
					orders,
					selectedOrders: [],
				},
				sendEventModal: {
					isOpen: false,
					loading: false,
					eventId: '',
					reprogramDate: now,
					reprogramTime: now,
					comment: '',
				},
			};
		}
		case ORDERS.UPDATE_ORDER_IN_MODAL: {
			return {
				...state,
				control: {
					...state.control,
					updateOrderModal: {
						...state.control.updateOrderModal,
						...action.payload,
					},
				},
			};
		}
		case ORDERS.UPDATE_ORDER_IN_CONTROL_ORDERS: {
			const updatedOrder = action.payload;
			return {
				...state,
				control: {
					...state.control,
					orders: state.control.orders.reduce((acc, ord) => {
						if (ord.order_id === updatedOrder.order_id) {
							acc.push(updatedOrder);
						} else {
							acc.push(ord);
						}
						return acc;
					}, []),
				},
			};
		}
		case ORDERS.RESET: {
			return initialState;
		}
		default:
			return state;
	}
};

export default orderReducer;
