// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
// Utils
import { VirtualizedList } from 'screens/Utils';
import { getSelectedPayments } from 'utils/filters/Billings/payments';
import { filterPaymentLines } from 'utils/filters/Billings/lines';
import GENERAL from 'utils/constants/general';
// Components
import { Wrapper, Empty } from 'components';

const { ENV } = GENERAL;

function PaymentsLinesMobile(props) {
	if (
		!Array.isArray(props?.pays?.selected?.items) ||
		props.pays.selected.items.length === 0
	) {
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYS_MOBILE} />;
	}

	const {
		// BILLINGS
		pays,
		lines,
		updatePaymentIdLine,
		// ORDERS
		mutate1ObjectInOrders,
		ordersState,
		getOrder,
		// API
		payments,
		coins,
	} = props;
	const allLines = payments.lines.data;
	const { control } = ordersState;

	const selectedPayments = getSelectedPayments(
		payments.pays.data,
		pays.selected.items,
	);
	const firstSelectedPayment = selectedPayments[0];

	const filteredPaymentLines = filterPaymentLines(allLines, lines.filters);

	return (
		<Wrapper padding='8px' justifyContent='center' className='animated fadeIn'>
			{!payments.lines.loading && filteredPaymentLines.length === 0 ? (
				<Empty style={{ marginTop: '20px' }} />
			) : (
				<VirtualizedList
					cardId='invoiceLineCard'
					listStyle={{
						height: document.documentElement.clientHeight,
						width: document.documentElement.clientWidth,
					}}
					rowHeight={135}
					spinTitle='Consultando Lineas...'
					isSpinning={payments.lines.loading}
					rows={filteredPaymentLines}
					customProps={{
						selectedPayments,
						firstSelectedPayment,
						orders: control.orders,
						coins: coins.data,
						getOrder,
						mutate1ObjectInOrders,
						updatePaymentIdLine,
					}}
				/>
			)}
		</Wrapper>
	);
}

export default PaymentsLinesMobile;
