// Libs
import React from 'react';
// Components
import {
	Wrapper,
	TableVirtualized,
	Flag,
	Span,
	Checkbox,
	Spin,
	Link,
	IconButton,
	LogoIcon,
} from 'components';
// Utils
import GENERAL from 'utils/constants/general';
import { formatNumber, coinsUtils } from 'utils/libs';
import { filterPaycuts } from 'utils/filters/Billings/paycuts';
import { getPaymentColorState } from '../../../utils';
import { SyncIcon } from 'components/Icons';

const { ENV } = GENERAL;
const {
	Table,
	Column,
	Header,
	Row,
	Cell,
	onClickRow,
	onDoubleClickRow,
	onRightClickRow,
} = TableVirtualized;

function PaycutsDesktop(props) {
	const {
		payments,
		paycuts,
		coins,
		mutate1Object,
		getPayments,
		syncPaycut,
		profile,
	} = props;
	const allPaycuts = payments.paycuts.data;
	const filteredPaycuts = filterPaycuts(allPaycuts, paycuts.filters);

	// -----RENDERS------

	// HEADER
	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: allPaycuts,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: paycuts.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('paycuts', { filters: updatedFilters }),
				}}
			>
				{label}
			</Header>
		);
	}

	// ROW
	function getRowRenderer({ key, columns, style }) {
		const onFilterItems = filteredItems =>
			mutate1Object('paycuts', {
				selected: { ...paycuts.selected, items: filteredItems },
			});

		return (
			<Row
				key={key}
				style={style}
				onContextMenu={e =>
					onRightClickRow(
						e,
						'id',
						paycuts.selected.items,
						filteredPaycuts,
						onFilterItems,
					)
				}
			>
				{columns}
			</Row>
		);
	}

	// ID
	function getIDCellRenderer({ rowData }) {
		const onClickedRow = filteredItems =>
			mutate1Object('paycuts', {
				selected: { ...paycuts.selected, items: filteredItems },
			});

		return (
			<Cell
				onClick={() => onClickRow(rowData.id, paycuts.selected, onClickedRow)}
				onDoubleClick={() =>
					onDoubleClickRow(
						'id',
						paycuts.selected,
						filteredPaycuts,
						mutate1Object,
						'paycuts',
					)
				}
			>
				<Flag style={getPaymentColorState(rowData.state)} height='20px' />
				<Checkbox
					margin='2px 0 2px 5px'
					checked={paycuts.selected.items.indexOf(rowData.id) !== -1}
					onChange={() =>
						onClickRow(rowData.id, paycuts.selected, onClickedRow)
					}
				/>
				<Span margin='0 0 0 5px'>{rowData.id}</Span>
			</Cell>
		);
	}

	// NAME
	function getNameCellRenderer({ rowData }) {
		return (
			<Link
				to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_PAYS_DESKTOP}
				onClick={() => {
					mutate1Object('paycuts', {
						selected: { ...paycuts.selected, items: [rowData.id] },
					});
					getPayments([rowData.id]);
				}}
			>
				{rowData.name}
			</Link>
		);
	}

	// COIN
	function getCoinCellRenderer({ rowData }) {
		return (
			<Cell>
				{coinsUtils.getDataByCoinId(
					rowData.coin_id,
					'abbreviation',
					coins.data,
				)}
			</Cell>
		);
	}

	// AMOUNT
	function getAmountCellRenderer({ rowData, dataKey }) {
		return (
			<Cell>
				{dataKey === 'production_amount'
					? formatNumber.new(rowData.production_amount, rowData.coin_symbol, 2)
					: dataKey === 'production_charge' &&
					  formatNumber.new(rowData.production_charge, rowData.coin_symbol, 2)}
			</Cell>
		);
	}

	// COST/SALE
	function getCostSaleCellRenderer({ rowData }) {
		const { cost, sale } = rowData;

		return !isNaN(cost) && !isNaN(sale) ? (
			<Cell>
				{cost}% / {sale}%
			</Cell>
		) : (
			<div />
		);
	}

	// SYNC
	function getSyncCellRenderer({ rowData }) {
		return (
			<Wrapper padding='0'>
				{rowData.state === 'ready' || rowData.state === 'released' ? (
					paycuts.synchronizing[rowData.id] ? (
						<LogoIcon spin button />
					) : (
						<SyncIcon button onClick={() => syncPaycut(rowData.id)} />
					)
				) : (
					<div />
				)}
			</Wrapper>
		);
	}

	return (
		<Wrapper padding='0' className='animated fadeIn'>
			<Spin
				spinning={payments.paycuts.loading}
				size='large'
				delay={50}
				tip={'Consultando Cortes...'}
			>
				<Table
					highBackground
					width={document.documentElement.clientWidth}
					height={document.documentElement.clientHeight - 115}
					headerHeight={44}
					rowHeight={25}
					rowCount={filteredPaycuts.length}
					rowGetter={({ index }) => filteredPaycuts[index]}
					rowRenderer={getRowRenderer}
				>
					<Column
						dataKey='id'
						label='ID'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getIDCellRenderer}
					/>
					<Column
						dataKey='name'
						label='Nombre'
						width={250}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getNameCellRenderer}
					/>
					<Column
						dataKey='coin_abbreviation'
						label='Moneda'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getCoinCellRenderer}
					/>
					<Column
						dataKey='iva'
						label='IVA'
						width={70}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
					/>
					<Column
						dataKey='production_amount'
						label='Costo'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getAmountCellRenderer}
					/>
					<Column
						dataKey='production_charge'
						label='Venta'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getAmountCellRenderer}
					/>
					<Column
						dataKey=''
						label='Costo / Venta'
						width={135}
						headerRenderer={({ label }) => <Header>{label}</Header>}
						cellRenderer={getCostSaleCellRenderer}
					/>
					<Column
						dataKey='from_date'
						label='Desde'
						width={100}
						headerRenderer={({ dataKey, label }) => (
							<Header
								filter={{
									dataKey,
									data: allPaycuts,
									orderType: 'date',
									dateFormat: 'YYYY-MM-DD',
									filters: paycuts.filters,
									onChangedFilter: updatedFilters =>
										mutate1Object('paycuts', { filters: updatedFilters }),
								}}
							>
								{label}
							</Header>
						)}
					/>
					<Column
						dataKey='to_date'
						label='Hasta'
						width={100}
						headerRenderer={({ dataKey, label }) => (
							<Header
								filter={{
									dataKey,
									data: allPaycuts,
									orderType: 'date',
									dateFormat: 'YYYY-MM-DD',
									filters: paycuts.filters,
									onChangedFilter: updatedFilters =>
										mutate1Object('paycuts', { filters: updatedFilters }),
								}}
							>
								{label}
							</Header>
						)}
					/>
					<Column
						dataKey='state_label'
						label='Estado'
						width={85}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='signed_label'
						label='Firmado'
						width={100}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='department_name'
						label='Departamento'
						width={140}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='created_at'
						label='Creado'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'date')}
					/>
					<Column
						dataKey='updated_at'
						label='Actualizado'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'date')}
					/>
					<Column
						dataKey='sync'
						label='Sincronizar'
						width={80}
						headerRenderer={({ label }) => <Header>{label}</Header>}
						cellRenderer={getSyncCellRenderer}
					/>
				</Table>
			</Spin>
		</Wrapper>
	);
}

export default PaycutsDesktop;
