import React from 'react';

// Components
import { Skeleton } from 'components';

import styles from './CreatedBy.module.css';

function CreatedBySkeleton({ active }) {
	return (
		<div className={styles.createdBy}>
			<Skeleton.Avatar active={active} shape='circle' />
			<div style={{ paddingLeft: '10px' }}>
				<Skeleton
					title={{ width: '95px', style: { margin: 0 } }}
					paragraph={{
						rows: 1,
						width: '70px',
						style: { margin: 0, marginTop: '5px' },
					}}
					active={active}
				/>
			</div>
		</div>
	);
}

export default React.memo(CreatedBySkeleton);
