import React from 'react';
// Components
import { Reactions, Skeleton } from 'components';
import Wrapper from './Wrapper';
// Styles
import styles from './ActionsBox.module.css';

const ActionsBoxSkeleton = ({
	active,
	reactionCount = 2,
	noBackground = false,
	justButtons = false,
}) => {
	return (
		<Wrapper
			className={
				!noBackground ? styles.actionsBox : styles.actionsBoxTransparent
			}
			justChildren={justButtons}
		>
			<Wrapper
				className={styles.actionButtonsContainer}
				justChildren={justButtons}
			>
				<Skeleton.Button
					shape='round'
					active={active}
					style={{ marginRight: '5px' }}
				/>
			</Wrapper>

			{/* REACTIONS */}
			<Wrapper className={styles.reactionsContainer} justChildren={justButtons}>
				<Reactions.Skeleton buttonCount={reactionCount} active={active} />
			</Wrapper>
		</Wrapper>
	);
};

export default ActionsBoxSkeleton;
