import ApiService from 'services/apiService/apiService';

const useOrderReportsApi = async ({ otdId }) => {
	return ApiService.fetch(ApiService.endpoints().GET_ORDER_REPORTS, {
		reqParams: {
			otdId,
		},
	});
};

export default useOrderReportsApi;
