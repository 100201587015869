// Libs
import React, { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/libs/dateFormats';
import { ElementViewBuilderContext, ModalBuilderContext } from 'core/context';
import { ElementViewBuilderConfig } from 'core/ElementViewBuilder';
// Utils
import CollectorEcosystemAuthorization from 'components/Collector/Authorization';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Components
import { ModalBuilderConfig } from 'core/ModalBuilder';
import {
	Badge,
	DeleteIcon,
	Divider,
	MapIcon,
	MoreActions,
	Span,
	Title,
	Wrapper,
} from 'components';
import CollectorInventorySync from 'components/Collector/CollectorInventorySync';
import { UserComment, DataInfo } from '../Shared';

const TitleWrapper = ({ userName, profile, record, isMoreActions }) => {
	const { setElementView } = useContext(ElementViewBuilderContext);

	const modalKeys = ModalBuilderConfig.getAuthorizedModalKeys();
	const elementViewKeys =
		ElementViewBuilderConfig.getAuthorizedElementViewKeys();

	const { setModal } = useContext(ModalBuilderContext);
	const [_, setRerender] = useState(0);

	const handles = useRef({
		event: '',
		sync: {
			onClick: () => {},
		},
		deleteAuditedOTD: {
			onclick: () =>
				setModal(modalKeys.orderDeleteAuditedOTD, {
					selectedOTDId: record.id,
				}),
		},
	});

	const setHandleSync = ({ onClick, onEvent }) => {
		handles.current.sync.onClick = onClick;
		handles.current.event = onEvent;
		setRerender(prev => prev + 1);
	};

	const isAuth = new CollectorEcosystemAuthorization(
		profile,
	).checkDeleteOtdReport();

	const deleteComponent = isAuth && {
		title: 'Borrar reporte',
		onClick: handles.current.deleteAuditedOTD.onclick,
		color: 'red',
		icon: DeleteIcon,
	};
	const syncWarehouseComponent = (
		<CollectorInventorySync otdId={record.id} setHandleSync={setHandleSync} />
	);

	const syncWharehouse = {
		title: 'Sincronizar almacen',
		onClick: handles.current.sync.onClick,
		component: syncWarehouseComponent,
	};

	const handleOnClickMapButton = () => {
		setElementView({
			elementViewKey: elementViewKeys.MAP_ELEMENT,
			elementId: record.id,
		});
	};

	//  TODO: add notification for event to MoreActions
	const list = [
		{
			title: 'Mapa',
			onClick: handleOnClickMapButton,
			icon: MapIcon,
		},
		syncWharehouse,
		deleteComponent,
	];
	return (
		<Wrapper
			width='100%'
			justifyContent='space-between'
			alignItems='center'
			padding=' 0px 0px 5px 0px'
		>
			<Title ellipsis>{userName}</Title>
			{isMoreActions && list.length && (
				<Wrapper padding='0' position='relative'>
					<Badge offset='5px' size='11px' event={handles.current.event}>
						<MoreActions list={list} />
					</Badge>
				</Wrapper>
			)}
		</Wrapper>
	);
};

const CardData = ({
	odtId,
	createdAt,
	profile,
	clientName,
	clientIdc,
	serviceName,
}) => {
	const isClient =
		String(clientName).toLowerCase() !== String(clientIdc).toLowerCase();

	return (
		<Wrapper
			padding='0px'
			flexDirection='column'
			alignItems='flex-start'
			width='100%'
			height='93px'
		>
			<Wrapper
				gap='4px'
				padding='3px 0px 0px 0px'
				width='100%'
				justifyContent='flex-start'
			>
				<DataInfo data={odtId} />
				<DataInfo data={'-'} />
				<Span disabled>{formatDate(createdAt, profile, 'ddd DD/MM')}</Span>
			</Wrapper>
			<Wrapper
				margin='5px 0 0 0'
				padding='0px'
				width='100%'
				flexDirection='column'
				alignItems='flex-start'
			>
				<DataInfo data={clientIdc} maxWidth='100%' />
				{isClient && <DataInfo data={clientName} maxWidth='100%' />}

				<Wrapper padding='0' margin='5px 0 0 0' width='100%'>
					<DataInfo data={serviceName} maxWidth='100%' />
				</Wrapper>
			</Wrapper>
		</Wrapper>
	);
};

export default function ({ record, isMoreActions = true }) {
	const { userName, odtId, createdAt, clientIdc, clientName, serviceName } =
		record;
	const profile = useSelector(selectProfile);

	return (
		<Wrapper
			padding='16px 0px 0px 16px'
			flexDirection='column'
			position='relative'
			alignItems='flex-start'
			justifyContent='space-between'
		>
			<TitleWrapper
				userName={userName}
				profile={profile}
				record={record}
				isMoreActions={isMoreActions}
			/>

			<CardData
				odtId={odtId}
				createdAt={createdAt}
				profile={profile}
				clientIdc={clientIdc}
				clientName={clientName}
				serviceName={serviceName}
			/>

			<Divider style={{ margin: '3px 0px' }} width='290px' height='1px' />

			<UserComment text={record.userComment} />
		</Wrapper>
	);
}
