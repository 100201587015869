import _ from 'underscore';

function getMyAllowedWarehouses(warehouses, profile) {
	if (profile.entity.id === profile.organization.settings.mainEntity) {
		// Retornar los almacenes que no son del proveedor
		return warehouses.filter(
			warehouse => !warehouse.props || !warehouse.props.provider,
		);
	}
	return warehouses.filter(
		warehouse =>
			// Retornar los almacenes que son posesión de mi entidad
			warehouse.owner_id === profile.entity.id ||
			// Retornar los almacenes que pertenecen a mi entidad
			warehouse.entity_id === profile.entity.id,
	);
}

function getMyEntityWarehouses(warehouses, profile) {
	if (profile.entity.id === profile.organization.settings.mainEntity)
		return warehouses;

	return warehouses.filter(
		warehouse =>
			// Retornar los almacenes que son posesión de mi entidad
			warehouse.owner_id === profile.entity.id ||
			// Retornar los almacenes que pertenecen a mi entidad
			warehouse.entity_id === profile.entity.id,
	);
}

function getConsolidateWarehouses(warehouses, entities) {
	return warehouses.filter(warehouse => {
		const idx = _.findIndex(
			entities,
			entity => warehouse.owner_id === entity.id,
		);
		if (idx !== -1) return warehouse;
	});
}

function getMyOwnWarehouses(warehouses, profile) {
	return warehouses.filter(
		warehouse =>
			// Retornar los almacenes que son posesión de mi entidad
			warehouse.owner_id === profile.entity.id && // El almacén no contenga props
			(!warehouse.props || // El almacén no puede ser del abonado
				(!warehouse.props.fClient &&
					// El almacén no puede ser proveedor
					!warehouse.props.provider)),
	);
}

function getWarehousesFilteringWarehouse(warehouses, warehouseId) {
	return warehouses.filter(warehouse => warehouse.id !== warehouseId);
}

function getProviderWarehouses(warehouses) {
	return warehouses.filter(
		warehouse => warehouse.props && warehouse.props.provider,
	);
}

function filterWarehousesSearchInData(warehouses, searchBy, data) {
	if (!data || data === '') return warehouses;

	switch (searchBy) {
		case 'name':
			return warehouses.filter(warehouse =>
				warehouse.name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'owner':
			return warehouses.filter(warehouse =>
				warehouse.owner_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'entity':
			return warehouses.filter(warehouse =>
				warehouse.entity_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'creator':
			return warehouses.filter(warehouse =>
				warehouse.creator_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'all':
			return warehouses.filter(
				warehouse =>
					warehouse.name.toUpperCase().includes(data.toUpperCase()) ||
					warehouse.owner_name.toUpperCase().includes(data.toUpperCase()) ||
					warehouse.entity_name.toUpperCase().includes(data.toUpperCase()) ||
					warehouse.creator_name.toUpperCase().includes(data.toUpperCase()),
			);
	}
}

function filterStockArticlesSearchInData(stockArticles, searchBy, data) {
	switch (searchBy) {
		case 'code':
			return stockArticles.filter(item =>
				item.item_code.toUpperCase().includes(data.toUpperCase()),
			);
		case 'name':
			return stockArticles.filter(item =>
				item.item_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'all':
			return stockArticles.filter(
				item =>
					item.item_code.toUpperCase().includes(data.toUpperCase()) ||
					item.item_name.toUpperCase().includes(data.toUpperCase()),
			);
	}
}

function filterStockSeriesSearchInData(stockSeries, searchBy, data) {
	switch (searchBy) {
		case 'code':
			return stockSeries.filter(item =>
				item.item_code.toUpperCase().includes(data.toUpperCase()),
			);
		case 'name':
			return stockSeries.filter(item =>
				item.item_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'serie':
		case 'scan':
			return stockSeries.filter(item =>
				item.serie.toUpperCase().includes(data.toUpperCase()),
			);
		case 'warehouse':
			return stockSeries.filter(item =>
				item.warehouse_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'all':
			return stockSeries.filter(
				item =>
					item.item_code.toUpperCase().includes(data.toUpperCase()) ||
					item.item_name.toUpperCase().includes(data.toUpperCase()) ||
					item.serie.toUpperCase().includes(data.toUpperCase()) ||
					item.warehouse_name.toUpperCase().includes(data.toUpperCase()),
			);
		default:
			return stockSeries;
	}
}

export {
	getMyAllowedWarehouses,
	getProviderWarehouses,
	getMyEntityWarehouses,
	getMyOwnWarehouses,
	getWarehousesFilteringWarehouse,
	getConsolidateWarehouses,
	filterWarehousesSearchInData,
	filterStockArticlesSearchInData,
	filterStockSeriesSearchInData,
};
