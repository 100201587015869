// Libs
import styled from 'styled-components';

const StyledGrid = styled.div`
	background-color: ${props => props.backgroundColor};
	display: ${props => props.theme.grid.display};
	margin: ${props => props.margin || props.theme.grid.margin};
	padding: ${props => props.padding || props.theme.grid.padding};
	position: ${props => props.position || props.theme.grid.position};
	top: ${props => props.top || props.theme.grid.top};
	left: ${props => props.left || props.theme.grid.left};
	width: ${props => props.width || props.theme.grid.width};
	height: ${props => props.height || props.theme.grid.height};
	min-height: ${props => props.minHeight || props.theme.grid.minHeight};
	grid-template-columns: ${props => props.columns || props.theme.grid.columns};
	grid-template-rows: ${props => props.rows || props.theme.grid.rows};
	grid-auto-flow: ${props =>
		props.gridAutoFlow || props.theme.grid.gridAutoFlow};
	gap: ${props => props.gap || props.theme.grid.gap};
	overflow-x: ${props => props.overflowX || props.theme.grid.overflowX};
	overflow-y: ${props => props.overflowY || props.theme.grid.overflowY};
	border: ${props => props.border || props.theme.grid.border};
	z-index: ${props => props.zIndex || props.theme.grid.zIndex};
	justify-content: ${props => props.justifyContent};
	align-items: ${props => props.alignItems};
	align-content: ${props => props.alignContent};
`;

export default StyledGrid;
