import { createSelector } from 'reselect';

const selectDashboardState = state => state.dashboard;

export const selectNotificationControl = createSelector(
	[selectDashboardState],
	dashboard => dashboard.notificationControl,
);

export const selectModeView = createSelector(
	[selectDashboardState],
	({ modeView }) => modeView,
);
export const selectIsMobile = createSelector(
	[selectModeView],
	modeView => modeView === 'cards',
);

export const selectDndOrdersDrawer = createSelector(
	[selectDashboardState],
	({ dndOrdersDrawer }) => dndOrdersDrawer,
);

export const selectEditProfileDrawer = createSelector(
	[selectDashboardState],
	dashboard => dashboard.editProfileDrawer,
);
