import React from 'react';
import { connect } from 'react-redux';
// Components
import Span from './Span/Span';
// Actions
import * as apiActions from 'store/api/actions';

const actions = {
	mutate1ObjectApi: apiActions.mutate1ObjectApi,
	getSerieHistory: apiActions.getSerieHistory,
};

function GetSerieHistory({
	serie,
	onClickCustomAction,
	mutate1ObjectApi,
	getSerieHistory,
}) {
	return (
		<Span
			cursorPointer
			onClick={() => {
				mutate1ObjectApi('serieHistory', { isOpen: true });
				getSerieHistory(serie);
				if (onClickCustomAction) onClickCustomAction();
			}}
		>
			{serie}
		</Span>
	);
}

export default connect(null, actions)(GetSerieHistory);
