import { CenteredText, StyledPopconfirm } from '../styledComponents';
import { Typography } from 'antd';

export const leftColumns = [
	{
		dataIndex: 'item',
		title: 'Código',
	},
	{
		dataIndex: 'name',
		title: 'Nombre',
	},
];

export const rightColumns = [
	{
		dataIndex: 'item',
		title: 'Código',
	},
	{
		dataIndex: 'name',
		title: 'Nombre',
	},
];

export const inventoryColumns = (
	theme,
	isEditing,
	save,
	cancel,
	edit,
	deleteRecord,
	editingKey,
) => [
	{
		title: 'Código',
		dataIndex: 'item',
		key: 'item',
		render: text => (
			<CenteredText style={{ color: `${theme._primaryColor}` }}>
				{text}
			</CenteredText>
		),
		width: 150,
	},
	{
		title: 'Nombre',
		dataIndex: 'name',
		key: 'name',
		sorter: (a, b) => a.name.localeCompare(b.name),
		sortDirections: ['ascend', 'descend'],
		render: text => (
			<span style={{ color: `${theme._primaryColor}` }}>{text}</span>
		),
	},
	{
		title: 'Serializado',
		dataIndex: 'is_serialized',
		key: 'is_serialized',
		filters: [
			{ text: 'Sí', value: true },
			{ text: 'No', value: false },
		],
		onFilter: (value, record) => record.is_serialized === value,
		render: serialized => (
			<CenteredText style={{ color: `${theme._primaryColor}` }}>
				{serialized ? 'Sí' : 'No'}
			</CenteredText>
		),
		width: 110,
	},
	{
		title: 'Serie',
		dataIndex: 'serie_id',
		key: 'serie_id',
		width: 100,
		render: text => (
			<CenteredText style={{ color: `${theme._primaryColor}` }}>
				{text}
			</CenteredText>
		),
	},
	{
		title: 'Cantidad',
		dataIndex: 'amount',
		key: 'amount',
		sorter: (a, b) => a.amount - b.amount,
		sortDirections: ['ascend', 'descend'],
		width: 100,
		editable: true,
		render: text => (
			<CenteredText style={{ color: `${theme._primaryColor}` }}>
				{text}
			</CenteredText>
		),
	},
	{
		title: 'Inicio',
		dataIndex: 'initial_amount',
		key: 'initial_amount',
		width: 100,
		editable: true,
		render: text => (
			<CenteredText style={{ color: `${theme._primaryColor}` }}>
				{text}
			</CenteredText>
		),
	},
	{
		title: 'Fin',
		dataIndex: 'final_amount',
		key: 'final_amount',
		width: 100,
		editable: true,
		render: text => (
			<CenteredText style={{ color: `${theme._primaryColor}` }}>
				{text}
			</CenteredText>
		),
	},
	{
		title: 'Fecha',
		dataIndex: 'updated_at',
		key: 'updated_at',
		sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
		sortDirections: ['ascend', 'descend'],
		render: date => (
			<span style={{ color: `${theme._primaryColor}` }}>
				{new Date(date).toLocaleDateString('es-ES', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				})}
			</span>
		),
		width: 100,
	},
	{
		title: 'Opciones',
		dataIndex: 'options',
		width: 150,
		render: (_, record) => {
			const editable = isEditing(record);
			return editable ? (
				<CenteredText style={{ color: `${theme._primaryColor}` }}>
					<span>
						<Typography.Link
							onClick={() => save(record.id)}
							style={{ marginRight: 8, color: `${theme._primaryColor}` }}
						>
							Save
						</Typography.Link>
						<StyledPopconfirm
							title='¿Estás seguro de que deseas cancelar?'
							onConfirm={cancel}
							okText='OK'
							cancelText='Cancel'
							okButtonProps={{
								style: {
									backgroundColor: `${theme._secondaryBackgroundColor}`,
									color: `${theme._primaryColor}`,
								},
							}}
							cancelButtonProps={{
								style: {
									backgroundColor: `${theme._secondaryBackgroundColor}`,
									color: `${theme._primaryColor}`,
								},
							}}
						>
							<a style={{ color: `${theme._primaryColor}` }}>Cancel</a>
						</StyledPopconfirm>
					</span>
				</CenteredText>
			) : (
				<CenteredText style={{ color: `${theme._primaryColor}` }}>
					<span>
						<Typography.Link
							disabled={editingKey !== ''}
							onClick={() => edit(record)}
							style={{ marginRight: 8, color: `${theme._primaryColor}` }}
						>
							Edit
						</Typography.Link>
						<StyledPopconfirm
							title='¿Estás seguro de que deseas eliminar este registro?'
							onConfirm={() => deleteRecord(record.id)}
							okText='OK'
							cancelText='Cancel'
							okButtonProps={{
								style: {
									backgroundColor: `${theme._secondaryBackgroundColor}`,
									color: `${theme._primaryColor}`,
								},
							}}
							cancelButtonProps={{
								style: {
									backgroundColor: `${theme._secondaryBackgroundColor}`,
									color: `${theme._primaryColor}`,
								},
							}}
						>
							<a style={{ color: `${theme._primaryColor}` }}>Delete</a>
						</StyledPopconfirm>
					</span>
				</CenteredText>
			);
		},
	},
];

export const mergedColumns = (inventoryColumns, isEditing, isEditable) =>
	inventoryColumns.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: record => ({
				record,
				inputType:
					col.dataIndex === 'amount' ||
					col.dataIndex === 'initial_amount' ||
					col.dataIndex === 'final_amount'
						? 'number'
						: 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
				editable: isEditable(record, col.dataIndex),
			}),
		};
	});
