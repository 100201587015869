// Libs
import React from 'react';
// Hooks
import { useTheme } from 'hooks';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Refresh';

const RefreshIcon = ({ onClick, onlyIcon }) => {
	const { theme } = useTheme();

	return onlyIcon ? (
		<Icon fill={theme._primaryColor} onClick={onClick} />
	) : (
		<IconButton onClick={onClick}>
			<Icon fill={theme._primaryColor} />
		</IconButton>
	);
};

export default RefreshIcon;
