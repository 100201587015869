import React from 'react';

const Icon = ({ fill, fillOpacity = '0.80', onClick }) => (
	<svg
		onClick={onClick}
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='filterIconMask24'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='3'
			y='6'
			width='18'
			height='12'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3 6V8H21V6H3ZM10 18H14V16H10V18ZM18 13H6V11H18V13Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#filterIconMask24)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);

export default Icon;
