// Libs
import styled from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Modal as _Modal } from 'antd';

const StyledModal = styled(_Modal)`
	.ant-modal-title {
		color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
		line-height: auto;
	}
	.ant-modal-close-x {
		width: 50px;
		height: 50px;
		line-height: 50px;
		color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
	}
	.ant-modal-header {
		padding: 10px 16px;
		border-bottom: ${({ $newTheme }) => `1px solid ${$newTheme._borderColor}`};
		background-color: ${({ $newTheme }) => $newTheme._primaryBackgroundColor};
	}
	.ant-modal-body {
		height: ${({ height }) => height};
		min-height: 90px;
		padding: 0px;
	}
	.ant-modal-content {
		background-color: ${({ $newTheme, secondaryBackgroundColor }) =>
			secondaryBackgroundColor || $newTheme._primaryBackgroundColor};
	}
`;

const Modal = ({ secondaryBackgroundColor, ...props }) => {
	const { theme } = useTheme();

	return (
		<StyledModal
			{...props}
			$newTheme={theme}
			secondaryBackgroundColor={secondaryBackgroundColor}
		/>
	);
};

export default Modal;
