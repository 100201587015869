//  Libs
import React from 'react';
import { useTheme } from 'styled-components';
import { Wrapper, Divider, InputNumber, DeleteIcon, AddIcon } from 'components';
import Select from 'components/Select';

const { Option } = Select;

export default function ConfigViewSaleCost(props) {
	const { configViewSaleCostModal, mutate1ObjectInBillings } = props;
	const { filters } = configViewSaleCostModal;
	const theme = useTheme();

	return (
		<Wrapper flexDirection='column' backgroundColor={theme.colors.primary[500]}>
			{/* DIVIDER */}
			<Divider color='gray' orientation='left'>
				Costo Operativo
			</Divider>
			{/* FILTER CONTAINER */}
			<Wrapper
				margin='10px 0 0 0'
				padding='0'
				width='100%'
				flexDirection='column'
			>
				{filters.map((filter, idx) => (
					<Wrapper
						key={idx}
						padding='0'
						width='100%'
						justifyContent='space-between'
					>
						{/* Operator */}
						<Select
							width='210px'
							placeholder='Operador'
							value={filter.operator}
							onChange={value => {
								const flts = [...filters];
								flts[idx].operator = value;
								mutate1ObjectInBillings('configViewSaleCostModal', {
									filters: flts,
								});
							}}
						>
							<Option value={'='}>
								<em>Igual a</em>
							</Option>
							<Option value={'<'}>
								<em>Menor que</em>
							</Option>
							<Option value={'<='}>
								<em>Menor o igual a</em>
							</Option>
							<Option value={'>'}>
								<em>Mayor que</em>
							</Option>
							<Option value={'>='}>
								<em>Mayor o igual a</em>
							</Option>
						</Select>

						{/* VALUE % */}
						<InputNumber
							width='80px'
							placeholder='Valor (%)'
							value={filter.value}
							min={0.0}
							step={0.1}
							onChange={value => {
								const flts = [...filters];
								flts[idx].value = value;
								mutate1ObjectInBillings('configViewSaleCostModal', {
									filters: flts,
								});
							}}
						/>

						{/* COLOR */}
						{/* <ColorPicker
              animation="slide-up"
              color={filter.color}
              onChange={(colors) => {
                let flts = [...filters];
                flts[idx].color = colors.color;
                mutate1ObjectInBillings("configViewSaleCostModal", {
                  filters: flts,
                });
              }}
            /> */}

						{/* DELETE */}
						<DeleteIcon
							onClick={() => {
								const flts = filters.filter((row, index) => index !== idx);
								mutate1ObjectInBillings('configViewSaleCostModal', {
									filters: flts,
								});
							}}
						/>
					</Wrapper>
				))}
			</Wrapper>
			{/* ADD FILTER */}
			<AddIcon
				button
				onClick={() => {
					const flts = [...filters];
					flts.push({
						value: undefined,
						operator: undefined,
						color: '#000000',
					});
					mutate1ObjectInBillings('configViewSaleCostModal', {
						filters: flts,
					});
				}}
			/>
		</Wrapper>
	);
}
