// Libs
import React from 'react';
// Utils
import { buildPayload } from './utils';
// Components
import { Tooltip } from 'antd';
import {
	Title,
	Wrapper,
	Span,
	CloseIcon,
	SaveIcon,
	LogoIcon,
	BackIcon,
	RefreshIcon,
} from 'components';
import EditIcon from '../Icons/EditIcon';

export default ({
	profile,
	editProfileDrawer,
	someFieldsChanged,
	getMyProfile,
	mutate1Object,
	onClose,
	userInfoValues,
	updateUserProfile,
}) => {
	const { loading, isEditing } = editProfileDrawer;

	return (
		<Wrapper padding='0' height='56px' justifyContent='space-between'>
			<Wrapper padding='0'>
				<BackIcon onClick={onClose} />
				<Title fontSize='l' margin='0 10px'>
					{profile.user.name}
				</Title>
			</Wrapper>

			<Wrapper padding='0' alignItems='center'>
				{/* DETAIL ORDER */}
				{isEditing ? (
					<>
						<Tooltip title='Descartar cambios'>
							<div>
								<CloseIcon
									button
									onClick={() =>
										mutate1Object('editProfileDrawer', { isEditing: false })
									}
								/>
							</div>
						</Tooltip>
						{someFieldsChanged && (
							<Tooltip title='Guardar cambios'>
								<SaveIcon
									button
									onClick={() => {
										updateUserProfile(
											buildPayload(userInfoValues, profile.user.profile),
										);
										mutate1Object('editProfileDrawer', { isEditing: false });
									}}
								/>
							</Tooltip>
						)}
					</>
				) : (
					<Tooltip title='Editar'>
						<div>
							<EditIcon
								onClick={() =>
									mutate1Object('editProfileDrawer', { isEditing: true })
								}
							/>
						</div>
					</Tooltip>
				)}

				{!isEditing && (
					<>
						{loading ? (
							<LogoIcon spin button />
						) : (
							<RefreshIcon
								onClick={() => {
									getMyProfile();
									mutate1Object('editProfileDrawer', { loading: true });
								}}
							/>
						)}
					</>
				)}
			</Wrapper>
		</Wrapper>
	);
};
