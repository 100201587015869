// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Utils
import { isRecordSigned, isPaymentSigned } from '../../../utils/libs';
// Components
import { List } from 'react-virtualized';
import { Wrapper, Card } from 'components';
import { Spin } from 'antd';
import {
	// DASHBOARD
	ShowErrorCard,
	// ORDERS
	OrderCard,
	OrderHistoryCard,
	BillingMaterialCard,
	IncomeOrderHistoryCard,
	CommentsCard,
	// PAYMENTS
	PaycutCard,
	PayCard,
	PaymentOrderCard,
	PaymentLineCard,
	PaymentOrderResumeCard,
	PaymentLineResumeCard,
	PaycutResumeCard,
	// INVOICES
	InvoicePayCard,
	InvoicePaycutCard,
	InvoiceOrderCard,
	// WAREHOUSES
	SerieHistoryCard,
	WarehouseCard,
	StockArticleItemCard,
	StockSerieItemCard,
	TransactionCard,
	ItemTransactionCard,
	InventoryTransactionCard,
	WarehouseZoneCard,
	WarehouseAreaCard,
	// MARKETPLACE
	MarketItemCard,
} from './cards';
// Styles
import './VirtualizedList.css';

function getCustomCard(cardId, props) {
	switch (cardId) {
		// Dashboard
		case 'showErrorCard':
			return <ShowErrorCard {...props} />;
		// Orders
		case 'order':
			return <OrderCard {...props} />;
		case 'orderHistory':
			return <OrderHistoryCard {...props} />;
		case 'incomeOrderHistory':
			return <IncomeOrderHistoryCard {...props} />;
		case 'billingMaterial':
			return <BillingMaterialCard {...props} />;
		case 'comments':
			return <CommentsCard {...props} />;
		// Payments
		case 'paycutCard':
			return <PaycutCard {...props} />;
		case 'payCard':
			return <PayCard {...props} />;
		case 'paymentOrderResumeCard':
			return <PaymentOrderResumeCard {...props} />;
		case 'paymentOrderCard':
			return <PaymentOrderCard {...props} />;
		case 'paymentLineCard':
			return <PaymentLineCard {...props} />;
		case 'paymentLineResumeCard':
			return <PaymentLineResumeCard {...props} />;
		case 'paycutResumeCard':
			return <PaycutResumeCard {...props} />;
		// Invoices
		case 'invoicePayCard':
			return <InvoicePayCard {...props} />;
		case 'invoicePaycutCard':
			return <InvoicePaycutCard {...props} />;
		case 'invoiceOrderCard':
			return <InvoiceOrderCard {...props} />;
		// Warehouses
		case 'serieHistoryCard':
			return <SerieHistoryCard {...props} />;
		case 'warehouseCard':
			return <WarehouseCard {...props} />;
		case 'stockArticleItemCard':
			return <StockArticleItemCard {...props} />;
		case 'stockSerieItemCard':
			return <StockSerieItemCard {...props} />;
		case 'transactionCard':
			return <TransactionCard {...props} />;
		case 'itemTransactionCard':
			return <ItemTransactionCard {...props} />;
		case 'inventoryTransactionCard':
			return <InventoryTransactionCard {...props} />;
		case 'warehouseZoneCard':
			return <WarehouseZoneCard {...props} />;
		case 'warehouseAreaCard':
			return <WarehouseAreaCard {...props} />;
		// Marketplace
		case 'marketItemCard':
			return <MarketItemCard {...props} />;
	}
}

function showRibbon(cardId, record, customProps = {}) {
	const { profile } = customProps;

	switch (cardId) {
		case 'paycutCard':
			return !isRecordSigned(record.signed, profile.user.id);
		case 'payCard':
			return !isPaymentSigned(record, profile.user.id);
		case 'invoicePaycutCard':
			return !isRecordSigned(record.signed, profile.user.id);
		case 'invoicePayCard':
			return !isRecordSigned(record.signed, profile.user.id);
		case 'transactionCard':
			return !record.completed;
		default:
			return false;
	}
}

function getRibbon(cardId, record, customProps = {}) {
	switch (cardId) {
		case 'paycutCard':
			return (
				<div className='wrap'>
					<span className='ribbon6'>No Firmado</span>
				</div>
			);
		case 'payCard':
			return (
				<div className='wrap'>
					<span className='ribbon6'>No Firmado</span>
				</div>
			);
		case 'invoicePaycutCard':
			return (
				<div className='wrap'>
					<span className='ribbon6'>No Firmado</span>
				</div>
			);
		case 'invoicePayCard':
			return (
				<div className='wrap'>
					<span className='ribbon6'>No Firmado</span>
				</div>
			);
		case 'transactionCard':
			return (
				<div className='wrap'>
					<span className='ribbon6'>Pendiente</span>
				</div>
			);
		default:
			return false;
	}
}

function VirtualizedList(props) {
	const theme = useTheme();
	const {
		cardId,
		interpolatedRowStyle,
		listStyle,
		cardStyle,
		rowHeight,
		Actions = [],
		Cover,
		rows,
		isSpinning = false,
		spinTitle = 'Cargando...',
		spinSize = 'large',
		spinDelay = 50,
		onRowsRendered,
		buildActions,
		customProps,
	} = props;

	function rowRenderer({
		key, // Unique key within array of rows
		index, // Index of row within collection
		isScrolling, // The List is currently being scrolled
		isVisible, // This row is visible within the List (eg it is not an overscanned row)
		style, // Style object to be applied to row (to position it)
	}) {
		const record = rows[index];
		const ribbon = showRibbon(cardId, record, customProps);
		if (typeof record === 'object') {
			record.counter = index + 1;
		}

		if (buildActions && record) {
			Actions = buildActions(record);
		}

		return (
			<Wrapper
				key={key}
				padding='0'
				justifyContent='center'
				style={{ ...style, width: '100%', height: 'auto' }}
			>
				<div
					id={interpolatedRowStyle ? 'interpolated-style' : undefined}
					className={ribbon ? 'ribbon' : undefined}
					style={{
						width: cardStyle?.width ?? '100%',
						height: cardStyle?.height ?? 'auto',
						boxShadow: theme.card.boxShadow,
					}}
				>
					{ribbon && getRibbon(cardId, record, customProps)}

					<Card
						id='card-container'
						key={key}
						style={{
							width: cardStyle?.width ?? '100%',
							height: cardStyle?.height ?? 'auto',
							padding: cardStyle?.padding,
						}}
						cover={Cover}
						actions={Actions.length > 0 ? Actions : null}
					>
						{getCustomCard(cardId, { ...record, customProps })}
					</Card>
				</div>
			</Wrapper>
		);
	}

	return (
		<div>
			<Spin
				spinning={isSpinning}
				size={spinSize}
				delay={spinDelay}
				tip={spinTitle}
			>
				<List
					className='container-list-rows' // static
					width={listStyle.width}
					height={listStyle.height}
					rowCount={rows.length}
					rowHeight={rowHeight}
					rowRenderer={rowRenderer}
					style={{ width: listStyle.width, background: listStyle.background }}
					// style={{ width: listStyle.width - 15 }}
					onRowsRendered={onRowsRendered}
				/>
			</Spin>
		</div>
	);
}

export default VirtualizedList;
