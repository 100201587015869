// Keywords
import KEYWORDS from './keywords';
const { ACTIONS } = KEYWORDS;

// Getters
export const getProjects = payload => ({
	type: ACTIONS.GET_PROJECTS,
	payload,
});
export const getServices = ({ orderTargetId }) => ({
	type: ACTIONS.GET_SERVICES,
	payload: { orderTargetId },
});
export const getExperts = ({ projectId }) => ({
	type: ACTIONS.GET_EXPERTS,
	payload: { projectId },
});
export const getLatestDataFromProcessedSQL = payload => ({
	type: ACTIONS.GET_LATEST_DATA_FROM_PROCESSED_SQL,
	payload,
});
export const getEveryDataFromProcessedSQL = payload => ({
	type: ACTIONS.GET_EVERY_DATA_FROM_PROCESSED_SQL,
	payload,
});
export const getElementView = payload => ({
	type: ACTIONS.GET_ELEMENT_VIEW,
	payload,
});
// Setters
export function mutateDirectProps(keyValuePairs) {
	return {
		type: ACTIONS.MUTATE_DIRECT_PROPS,
		payload: keyValuePairs,
	};
}
export function mutate1Object(obj1Name, keyValuePairs) {
	return {
		type: ACTIONS.MUTATE_1OBJECT,
		payload: { obj1Name, keyValuePairs },
	};
}
export function toggleDatasource() {
	return {
		type: ACTIONS.TOGGLE_DATASOURCE,
	};
}
export const setDatasource = payload => ({
	type: ACTIONS.SET_DATASOURCE,
	payload,
});
export const updateDatasourceItem = payload => ({
	type: ACTIONS.UPDATE_DATASOURCE_ITEM,
	payload,
});
export const removeDatasourceItem = elementId => ({
	type: ACTIONS.REMOVE_DATASOURCE_ITEM,
	payload: elementId,
});
export const setSearchDatasource = payload => ({
	type: ACTIONS.SET_SEARCH_DATASOURCE,
	payload,
});

export const mutateSubDataFilter = payload => ({
	type: ACTIONS.MUTATE_SUB_DATA_FILTER,
	payload,
});
export const mutateUserParams = payload => ({
	type: ACTIONS.MUTATE_DATA_FILTER_USER_PARAMS,
	payload,
});
// Resets
export const resetProjects = () => ({
	type: ACTIONS.RESET_PROJECTS,
});
export const resetOrderTargets = () => ({
	type: ACTIONS.RESET_ORDER_TARGETS,
});
export const resetServices = () => ({
	type: ACTIONS.RESET_SERVICES,
});
export const resetDataFilters = payload => ({
	type: ACTIONS.RESET_DATA_FILTERS,
	payload,
});
export const resetDatasource = () => ({
	type: ACTIONS.RESET_DATASOURCE,
});
export const resetDisplay = () => ({
	type: ACTIONS.RESET_DISPLAY,
});
export const resetActionForm = () => ({
	type: ACTIONS.RESET_ACTION_FORM,
});
export const resetUserParams = () => ({
	type: ACTIONS.RESET_DATA_FILTER_USER_PARAMS,
});
export const resetCustomFilterPropsInDataFilter = selectedDataFilterId => ({
	type: ACTIONS.RESET_CUSTOM_FILTER_PROPS_IN_DATA_FILTER,
	payload: { selectedDataFilterId },
});
export function reset() {
	return {
		type: ACTIONS.RESET,
	};
}
