import styled, { css } from 'styled-components';

const FlagStyled = styled.div`
	min-width: ${props =>
		props.width ? props.width : props.theme.flag.minWidth};
	height: ${props => (props.height ? props.height : props.theme.flag.height)};
	background-color: ${props =>
		props.backgroundColor
			? props.backgroundColor
			: props.theme.flag.backgroundColor};
	${props =>
		props.absolute &&
		css`
			position: ${props.theme.flag.absolute.position};
			left: ${props.theme.flag.absolute.left};
		`}
	${props =>
		props.circle &&
		css`
			border-radius: ${props.theme.flag.borderRadius};
		`}
`;

export default FlagStyled;
