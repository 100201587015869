// Libs
import React from 'react';
// Hooks
import { useTheme } from 'hooks';
// Components
import ActionsBox from 'core/ElementBuilder/elements/Supervisor/ActionsBox';
import { Skeleton, CreatedBy } from 'components';
import CollectorPhoto from './CollectorPhoto';
import WrapperSwitcher from './WrapperSwitcher';
// Styles
import styles from './Collector.module.css';

const CollectorSkeleton = ({
	withPhotos = true,
	photosCount = 6,
	active = false,
	isMobile,
}) => {
	const { theme } = useTheme();
	return (
		<div
			className={
				isMobile
					? styles.collectorSectionSkeletonMobile
					: styles.collectorSectionSkeleton
			}
		>
			<div
				style={{
					backgroundColor: theme._secondaryBackgroundColor,
				}}
				className={isMobile ? styles.collectorInfoMobile : styles.collectorInfo}
			>
				<div className={styles.collectorName}>
					<Skeleton
						active={active}
						title={{ width: '200px' }}
						paragraph={false}
					/>
				</div>
				<div className={styles.valueWrapper}>
					<Skeleton active={active} paragraph={{ rows: 1 }} title={false} />
				</div>
				<div className={styles.bottom}>
					<CreatedBy.Skeleton active={active} />
					<div className={styles.actionsWrapper}>
						<ActionsBox.Skeleton
							active={active}
							noBackground={true}
							justButtons={true}
						/>
					</div>
				</div>
			</div>
			{withPhotos && (
				<WrapperSwitcher
					className={styles.allCollectorRows}
					justChildren={!isMobile}
				>
					{[...Array(Number(!isMobile ? photosCount : 1)).keys()].map(
						photoNumber => (
							<CollectorPhoto.Skeleton key={photoNumber} active={active} />
						),
					)}
				</WrapperSwitcher>
			)}
		</div>
	);
};

export default CollectorSkeleton;
