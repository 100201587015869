// Libs
import React from 'react';
// Context
import { RealtimeNotificationHandler } from 'context/RealtimeContext/Handlers';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Badge } from 'antd';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationList from './NotificationList';
import { Drawer, Title, Wrapper, CloseIcon } from 'components';

const NotificationsTitle = ({ title, onClose }) => (
	<Wrapper
		padding='10px 10px 10px 16px'
		justifyContent='space-between'
		mediumBackground
	>
		<Title>{title}</Title>
		<CloseIcon button onClick={onClose} />
	</Wrapper>
);

// Notifications Component
export const Notifications = ({
	// Props
	isMobile,
	// State
	profile,
	notificationControl,
	// Actions
	mutate1ObjectInDashboard,
	getNotifications,
	selectNotification,
	markNotification,
}) => {
	const { theme } = useTheme();
	const { show, notifications, isFetching } = notificationControl;

	const noSeenNotificationAmount = React.useMemo(() => {
		return notifications.reduce((acc, notification) => {
			if (!notification.seen) acc++;
			return acc;
		}, 0);
	}, [notifications]);

	return (
		<>
			<Drawer
				key='notificationDrawer'
				className='animated fadeIn fastest'
				height='100%'
				width='100%'
				$maxWidth={!isMobile ? '400px' : '100%'}
				header_font_weight='bold'
				placement={'right'}
				closable={false}
				visible={show}
				title={
					<NotificationsTitle
						title='Notificaciones'
						onClose={() =>
							mutate1ObjectInDashboard('notificationControl', {
								...notificationControl,
								show: false,
							})
						}
					/>
				}
			>
				<NotificationList
					isFetching={isFetching}
					profile={profile}
					notifications={notifications}
					markNotification={markNotification}
					selectNotification={selectNotification}
					getNotifications={getNotifications}
				/>
			</Drawer>
			<IconButton
				onClick={() => {
					if (!show) getNotifications();
					mutate1ObjectInDashboard('notificationControl', {
						...notificationControl,
						show: !show,
					});
				}}
			>
				<Badge
					count={noSeenNotificationAmount}
					overflowCount={99}
					style={{
						backgroundColor: 'transparent',
						color: 'lightcoral',
						boxShadow: '0 0 0 1px #d9d9d9 inset',
						fontFamily: 'Roboto',
					}}
				>
					<NotificationsIcon
						style={{
							color: theme._mediumEmphasisColor,
						}}
					/>
				</Badge>
			</IconButton>
		</>
	);
};

export default React.memo(({ isMobile }) => (
	<RealtimeNotificationHandler>
		{({
			profile,
			notificationControl,
			mutate1ObjectInDashboard,
			getNotifications,
			selectNotification,
			markNotification,
		}) => (
			<Notifications
				isMobile={isMobile}
				profile={profile}
				notificationControl={notificationControl}
				mutate1ObjectInDashboard={mutate1ObjectInDashboard}
				getNotifications={getNotifications}
				selectNotification={selectNotification}
				markNotification={markNotification}
			/>
		)}
	</RealtimeNotificationHandler>
));
