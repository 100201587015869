export default class GlobalUtils {
	static selectCurrentProps(priorityProps = []) {
		return priorityProps.reduce((acc, props) => {
			if (typeof props === 'object') {
				Object.keys(props).forEach(key => {
					if (!!props[key] && typeof props[key] === 'object') {
						acc[key] = {
							...this.checkObject(acc[key]),
							...props[key],
						};
					} else acc[key] = props[key];
				});
			}
			return acc;
		}, {});
	}

	static checkNumber(number) {
		const _num = Number(number);
		return !isNaN(_num) ? _num : 0;
	}

	static checkObject(obj) {
		return obj && typeof obj === 'object' && !Array.isArray(obj) ? obj : {};
	}

	static checkArray(arr) {
		return Array.isArray(arr) ? arr : [];
	}

	static isEmptyObject(object = {}) {
		return Object.keys(object).length === 0;
	}

	static removeDuplicates(arr) {
		return arr.filter((key, idx) => arr.indexOf(key) === idx);
	}

	static excludeArrayKey(removeKey, arr) {
		return arr.filter(key => key !== removeKey);
	}

	static flattenArray(value) {
		return Array.isArray(value) ? value.sort().join('') : value;
	}

	static grayscale(pixels) {
		for (let p = 0; p < pixels.length; p += 4) {
			let red = pixels[p];
			let green = pixels[p + 1];
			let blue = pixels[p + 2];
			// let alpha = pixels[p + 3];

			let gris = (red + green + blue) / 3;

			pixels[p] = gris;
			pixels[p + 1] = gris;
			pixels[p + 2] = gris;
		}
		return pixels;
	}

	/**
	 * Hermite resize - fast image resize/resample using Hermite filter. 1 cpu version!
	 * More info: https://github.com/viliusle/Hermite-resize
	 * @param {HtmlElement} canvas
	 * @param {int} width
	 * @param {int} height
	 * @param {boolean} resize_canvas if true, canvas will be resized. Optional.
	 */
	static resampleSingle(canvas, width, height, resize_canvas) {
		var width_source = canvas.width;
		var height_source = canvas.height;
		width = Math.round(width);
		height = Math.round(height);

		var ratio_w = width_source / width;
		var ratio_h = height_source / height;
		var ratio_w_half = Math.ceil(ratio_w / 2);
		var ratio_h_half = Math.ceil(ratio_h / 2);

		var ctx = canvas.getContext('2d');
		var ctx2 = resize_canvas.getContext('2d');
		var img = ctx.getImageData(0, 0, width_source, height_source);
		var img2 = ctx2.createImageData(width, height);
		var data = img.data;
		var data2 = img2.data;

		for (var j = 0; j < height; j++) {
			for (var i = 0; i < width; i++) {
				var x2 = (i + j * width) * 4;
				var weight = 0;
				var weights = 0;
				var weights_alpha = 0;
				var gx_r = 0;
				var gx_g = 0;
				var gx_b = 0;
				var gx_a = 0;
				var center_y = (j + 0.5) * ratio_h;
				var yy_start = Math.floor(j * ratio_h);
				var yy_stop = Math.ceil((j + 1) * ratio_h);
				for (var yy = yy_start; yy < yy_stop; yy++) {
					var dy = Math.abs(center_y - (yy + 0.5)) / ratio_h_half;
					var center_x = (i + 0.5) * ratio_w;
					var w0 = dy * dy; //pre-calc part of w
					var xx_start = Math.floor(i * ratio_w);
					var xx_stop = Math.ceil((i + 1) * ratio_w);
					for (var xx = xx_start; xx < xx_stop; xx++) {
						var dx = Math.abs(center_x - (xx + 0.5)) / ratio_w_half;
						var w = Math.sqrt(w0 + dx * dx);
						if (w >= 1) {
							//pixel too far
							continue;
						}
						//hermite filter
						weight = 2 * w * w * w - 3 * w * w + 1;
						var pos_x = 4 * (xx + yy * width_source);
						//alpha
						gx_a += weight * data[pos_x + 3];
						weights_alpha += weight;
						//colors
						if (data[pos_x + 3] < 255)
							weight = (weight * data[pos_x + 3]) / 250;
						gx_r += weight * data[pos_x];
						gx_g += weight * data[pos_x + 1];
						gx_b += weight * data[pos_x + 2];
						weights += weight;
					}
				}
				data2[x2] = gx_r / weights;
				data2[x2 + 1] = gx_g / weights;
				data2[x2 + 2] = gx_b / weights;
				data2[x2 + 3] = gx_a / weights_alpha;
			}
		}

		ctx2.putImageData(img2, 0, 0);
	}

	static executeStringifyCustomFunction(fn, props, defaultValue) {
		if (!fn) return defaultValue;
		try {
			const customFn = new Function(`return ${fn}`)();
			return customFn(props);
		} catch (err) {
			SentryService.sendError(err);
			return 'Err';
		}
	}
}
