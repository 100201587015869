import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ApiServiceV2 } from 'services';
import { sendToast } from 'screens/Dashboard/actions';

const useProfileData = userId => {
	const dispatch = useDispatch();
	const [dataFromMenus, setDataFromMenus] = useState(null);
	const [selectedProjectId, setSelectedProjectId] = useState([]);
	const [selectedBussinesLineId, setSelectedBussinesLineId] = useState([]);
	const [profiles, setProfiles] = useState([]);
	const [selectedModuleId, setSelectedModuleId] = useState([]);

	const fetchDataFromMenus = useCallback(() => {
		let isMounted = true;

		ApiServiceV2.get({
			url: `/users/getDataFromMenus/${userId}`,
		})
			.then(response => {
				if (isMounted) {
					setDataFromMenus(response);
				}
			})
			.catch(error => {
				if (isMounted) {
					console.error('Error catch:', error);
				}
			});

		return () => {
			isMounted = false;
		};
	}, [userId]);

	const updateUserProfile = async (profileId, active) => {
		try {
			const response = await ApiServiceV2.put({
				url: `/users/registerProfile/${userId}`,
				data: { profileId, active },
			});
			if (response.success) {
				getProfiles(selectedModuleId);
				fetchDataFromMenus();
				dispatch(
					sendToast({
						message: 'Perfil actualizado correctamente',
						type: 'success',
					}),
				);
			} else {
				throw new Error('La actualización del perfil falló');
			}
		} catch (error) {
			dispatch(
				sendToast({
					message: error.message,
					type: 'error',
				}),
			);
		}
	};

	const getProfiles = async moduleId => {
		ApiServiceV2.get({
			url: `/users/getUserProfiles/${userId}/${selectedProjectId}/${selectedBussinesLineId}/${moduleId}`,
		})
			.then(profiles => {
				setProfiles(profiles);
			})
			.catch(error => {
				console.error('Error catch:', error);
			});
	};

	return {
		dataFromMenus,
		profiles,
		selectedModuleId,
		selectedProjectId,
		selectedBussinesLineId,
		setSelectedModuleId,
		fetchDataFromMenus,
		updateUserProfile,
		getProfiles,
		setSelectedProjectId,
		setSelectedBussinesLineId,
	};
};

export default useProfileData;
