// Libs
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
// Utils
import { filterOption } from 'utils/libs';
// Actions
import { requestGenerateReport as _requestGenerateReport } from 'screens/Reports/reports.actions';
// Hooks
import { useGenerateReport } from 'modules/Reports/hooks';
// Components
import { DefaultForm } from 'core/FormBuilder';
import {
	Wrapper,
	Button,
	TextField,
	Divider,
	DatePicker,
	TimePicker,
} from 'components';
// Styles
import styles from './Generate.module.css';
import Select from 'components/Select';

const { Option } = Select;
const { TextArea, Input } = TextField;

const isRequiredParam = param => !!param?.required;
// const isMultipleParam = param => !!param?.multiple;

const buildReportParams = (components, reportParams) =>
	components.reduce((acc, component) => {
		acc = { ...acc, ...component.params };
		acc[component.param] = {
			...component.config,
			type: component.type,
		};
		return acc;
	}, reportParams || {});

const Generate = ({
	isMobile,
	form,
	values,
	actionPayload,
	setValues,
	submitForm,
	getProcessedComponentSqlData,
}) => {
	const { reportId, reportTable, reportProps = {} } = actionPayload;
	const reportParams = useMemo(
		() => buildReportParams(form.components, actionPayload.reportParams),
		[form.components, actionPayload.reportParams],
	);
	const { users } = useGenerateReport();
	const dispatch = useDispatch();
	const onGenerateReportClick = () =>
		dispatch(
			_requestGenerateReport(
				{
					reportId,
					reportTable,
					toEmails: values.toEmails,
					ccEmails: values.ccEmails,
					subject: values.subject,
					message: values.message,
					params: {
						...values,
						toEmails: undefined,
						ccEmails: undefined,
						subject: undefined,
						message: undefined,
					},
				},
				reportParams,
			),
		);

	return (
		<div className={styles.container}>
			<Divider color='gray' orientation='left' style={{ margin: '16px 0 0 0' }}>
				Filtros del Reporte
			</Divider>

			<DefaultForm
				isMobile={isMobile}
				form={form}
				values={values}
				setValues={setValues}
				submitForm={submitForm}
				getProcessedComponentSqlData={getProcessedComponentSqlData}
			/>

			{/* FROM DATE */}
			{reportParams.fromDateParam && (
				<Wrapper padding='0' alignItems='baseline' width='100%'>
					<DatePicker
						style={{ width: '100%', marginTop: '20px' }}
						placeholder={
							isRequiredParam(reportParams.fromDateParam) ? 'Desde*' : 'Desde'
						}
						value={values.fromDateParam}
						onChange={value =>
							setValues(prev => ({ ...prev, fromDateParam: value }))
						}
					/>
					{reportParams.fromDateParam.type === 'datetime' && (
						<TimePicker
							style={{ width: '100%', marginTop: '10px' }}
							allowClear={false}
							value={values.fromDateParam}
							onChange={value =>
								setValues(prev => ({ ...prev, fromDateParam: value }))
							}
						/>
					)}
				</Wrapper>
			)}

			{/* TO DATE */}
			{reportParams.toDateParam && (
				<Wrapper padding='0' alignItems='baseline' width='100%'>
					<DatePicker
						placeholder={
							isRequiredParam(reportParams.toDateParam) ? 'Hasta*' : 'Hasta'
						}
						style={{ width: '100%', marginTop: '10px' }}
						value={values.toDateParam}
						onChange={value =>
							setValues(prev => ({ ...prev, toDateParam: value }))
						}
					/>

					{reportParams.toDateParam.type === 'datetime' && (
						<TimePicker
							style={{ width: '100%', marginTop: '10px' }}
							allowClear={false}
							value={values.toDateParam}
							onChange={value =>
								setValues(prev => ({ ...prev, toDateParam: value }))
							}
						/>
					)}
				</Wrapper>
			)}

			<Divider color='gray' orientation='left' style={{ margin: '32px 0 0 0' }}>
				Detalle del Correo
			</Divider>

			{/* TO EMAILS */}
			<Select
				placeholder='Destinatarios*'
				mode='tags'
				margin='20px 0 0 0'
				width='100%'
				showSearch
				value={values.toEmails}
				filterOption={filterOption}
				onChange={value => setValues(prev => ({ ...prev, toEmails: value }))}
			>
				{users
					.filter(
						usr =>
							!values.ccEmails || values.ccEmails.indexOf(usr.email) === -1,
					)
					.map((user, idx) => (
						<Option key={idx} value={user.email}>
							{user.name}
						</Option>
					))}
			</Select>

			{/* CC EMAILS */}
			<Select
				placeholder='Con copia a...'
				mode='tags'
				margin='10px 0 0 0'
				width='100%'
				showSearch
				value={values.ccEmails}
				filterOption={filterOption}
				onChange={value => setValues(prev => ({ ...prev, ccEmails: value }))}
			>
				{users
					.filter(
						usr =>
							!values.toEmails || values.toEmails.indexOf(usr.email) === -1,
					)
					.map((user, idx) => (
						<Option key={idx} value={user.email}>
							{user.name}
						</Option>
					))}
			</Select>

			{/* SUBJECT */}
			<Input
				margin='10px 0 0 0'
				required
				placeholder='Asunto'
				name='subject'
				value={values.subject ?? reportProps.subject}
				onChange={e =>
					setValues(prev => ({ ...prev, subject: e.target.value }))
				}
			/>

			{/* COMMENT */}
			<TextArea
				margin='10px 0 0 0'
				placeholder='Mensaje'
				rows={3}
				value={values.message ?? reportProps.text}
				onChange={e =>
					setValues(prev => ({ ...prev, message: e.target.value }))
				}
			/>

			<Wrapper width='100%' justifyContent='center'>
				<Button
					margin='10px 0 0 0'
					mode='primary'
					onClick={onGenerateReportClick}
				>
					Generar
				</Button>
			</Wrapper>
		</div>
	);
};

export default Generate;
