// import React from "react";
// import styles from "./Tag.module.css";
// import styled, { css } from "styled-components";

// const _Tag = styled.div`
// 	margin: ${(props) => (props.margin ? props.margin : props.theme.tag.margin)};
// `;

// const Tag = ({ children, margin }) => {
// 	return (
// 		<_Tag margin={margin} className={styles.wrapperTag}>
// 			{children}
// 		</_Tag>
// 	);
// };

// export default Tag;

// Libs
import cx from 'classnames';
// Hooks
import useTheme from 'hooks/useTheme';
// Styles
import styles from './Tag.module.css';

const Tag = props => {
	const { theme, sharedStyles } = useTheme();

	return (
		<span
			style={{
				margin: props.margin,
				padding: props.padding,
			}}
			className={cx(
				styles.wrapperTag,
				theme.secondaryColor,
				sharedStyles.standardTextStructure,
			)}
		>
			{props.children}
		</span>
	);
};

export default Tag;
