// Libs
import React, { useRef } from 'react';
import { useTheme } from 'styled-components';
import {
	Wrapper,
	Span,
	Menu,
	Button,
	Divider,
	Popover,
	Spin,
	EyeIcon,
	SettingsIcon,
	RefreshIcon,
	LogoIcon,
	PersonIcon,
} from 'components';
// Utils
import {
	getTotalProduction,
	getTotalBonifications,
	getTotalDiscounts,
	getTotalToPay,
	groupResumeOrders,
	recordsHaveAllowedStates,
} from 'screens/Billings/utils';
import { coinsUtils } from 'utils/libs';
import { CloudStorage, platform } from 'components/AppConfig.json';
import GENERAL from 'utils/constants/general';
import orderBy from 'utils/orderBy';
// Styles
import './SignPaymentModal.css';
// Components
import VirtualizedList from '../../../../Lists/VirtualizedList';

const { ENV } = GENERAL;

function canOpenReport(payments, getPaymentsResumeModal, profile) {
	const env = process.env.NODE_ENV;
	const organization = profile.organization.settings.photoStore.main;
	const company = profile.company.settings.photoStore.main;
	const entity_id = profile.entity.id;
	const reportUri = `${CloudStorage}/${platform}/${env}/${company}/${organization}/reports/payments/${entity_id}`;

	let reportId = null;

	if (
		payments.length === 1 &&
		(payments[0].pdf_generated || getPaymentsResumeModal.pdfGenerated)
	) {
		reportId = `${payments[0].id}.pdf`;
	} else if (payments.length > 1 && getPaymentsResumeModal.pdfGenerated) {
		reportId = `${ENV.PAYCUTS.FUSION_PAYCUTS_KEY}${profile.user.id}.pdf`;
	}

	return reportId ? `${reportUri}/${reportId}` : null;
}

function GetOptionsResumeContent(props) {
	const {
		pay,
		resume,
		profile,
		setPaymentReopen,
		setPaymentReady,
		generatingPDF,
		updateState,
		generatePaymentPDF,
		getPaymentsResumeModal,
	} = props;

	const allPaymentsHaveAllowedStates = recordsHaveAllowedStates(
		resume.payments,
		['ready', 'agreed', 'released'],
	);

	const canOpenReportURI = canOpenReport(
		resume.payments,
		getPaymentsResumeModal,
		profile,
	);

	return (
		<Spin
			spinning={generatingPDF || updateState}
			size='small'
			delay={50}
			tip={''}
		>
			<Menu.Wrapper>
				{pay.entity_id === profile.entity.id && (
					<Menu.Group title='Cambiar Estado'>
						<Menu.Item
							disabled={!allPaymentsHaveAllowedStates}
							onClick={() => setPaymentReopen(pay.id, pay.paycut_id)}
						>
							Abrir
						</Menu.Item>
						<Menu.Item
							disabled={pay.state !== 'open'}
							onClick={() => setPaymentReady(pay.id)}
						>
							Cerrar
						</Menu.Item>
					</Menu.Group>
				)}
				<Menu.Group title='Exportar'>
					<Menu.Item
						disabled={
							!recordsHaveAllowedStates(resume.payments, ['released', 'paid'])
						}
						onClick={() => {
							if (canOpenReportURI) {
								window.open(canOpenReportURI);
							} else {
								generatePaymentPDF(pay.id);
							}
						}}
					>
						PDF ({canOpenReportURI ? 'Abrir' : 'Generar'})
					</Menu.Item>
				</Menu.Group>
			</Menu.Wrapper>
		</Spin>
	);
}

function GetModeViewResumeContent(props) {
	const { signPaymentModal, mutate1ObjectInBillings, generatingPDF } = props;
	const theme = useTheme();
	return (
		<Spin spinning={generatingPDF} size='small' delay={50} tip={''}>
			<Menu.Wrapper>
				<Menu.Group title='Producción'>
					<Menu.Item
						style={
							!signPaymentModal.groupOrders && {
								backgroundColor: theme.colors.primary[500],
							}
						}
						onClick={() =>
							mutate1ObjectInBillings('signPaymentModal', {
								groupOrders: false,
							})
						}
					>
						Individual
					</Menu.Item>
					<Menu.Item
						style={
							signPaymentModal.groupOrders && {
								backgroundColor: theme.colors.primary[500],
							}
						}
						onClick={() =>
							mutate1ObjectInBillings('signPaymentModal', { groupOrders: true })
						}
					>
						Agrupadas
					</Menu.Item>
				</Menu.Group>
			</Menu.Wrapper>
		</Spin>
	);
}

export function HeadSignPaymentModal(props) {
	const {
		resume,
		pay,
		profile,
		signPaymentModal,
		mutate1ObjectInBillings,
		getPaymentResume,
		generatingPDF,
		updateState,
		generatePaymentPDF,
		setPaymentReopen,
		setPaymentReady,
		getPaymentsResumeModal,
	} = props;

	return (
		<Wrapper width='95%' height='28px' justifyContent='space-between'>
			<Span fontSize='xl'>Resumen</Span>
			<Wrapper padding='0'>
				<Popover
					trigger='click'
					placement='bottomRight'
					title={null}
					content={
						<GetModeViewResumeContent
							signPaymentModal={signPaymentModal}
							mutate1ObjectInBillings={mutate1ObjectInBillings}
							generatingPDF={generatingPDF}
						/>
					}
				>
					<div>
						<EyeIcon button />
					</div>
				</Popover>

				{pay && (
					<Popover
						trigger='click'
						placement='bottomRight'
						title={null}
						content={
							<GetOptionsResumeContent
								pay={pay}
								resume={resume}
								profile={profile}
								setPaymentReopen={setPaymentReopen}
								setPaymentReady={setPaymentReady}
								generatingPDF={generatingPDF}
								updateState={updateState}
								generatePaymentPDF={generatePaymentPDF}
								getPaymentsResumeModal={getPaymentsResumeModal}
							/>
						}
					>
						<div>
							<SettingsIcon />
						</div>
					</Popover>
				)}

				{resume.loading ? (
					<LogoIcon button spin />
				) : (
					<RefreshIcon onClick={() => getPaymentResume(pay.id)} />
				)}
			</Wrapper>
		</Wrapper>
	);
}

function GetResumeItem({ concept, value, color }) {
	const theme = useTheme();

	return (
		<Wrapper padding='5px' justifyContent='space-between' width='95%'>
			<Span fontSize='l' color={color || theme.fonts.colors.concept}>
				{concept}
			</Span>
			<Span fontSize='l' color={color || theme.fonts.colors.value}>
				{value}
			</Span>
		</Wrapper>
	);
}

export default function SignPaymentModal(props) {
	const {
		pay,
		totalUsers,
		signPaymentModal,
		signPayment,
		resume,
		coins,
		orders,
		getOrder,
		mutate1ObjectInOrders,
		inModule,
		profile,
	} = props;
	const theme = useTheme();
	const coinSymbol = coinsUtils.getDataByCoinId(pay.coin_id, 'symbol', coins);
	const bonifications = resume.lines.filter(line => line.action === 'credit');
	const discounts = resume.lines.filter(line => line.action === 'debit');
	const prodAmounts = getTotalProduction(resume.orders, pay.iva, coinSymbol);
	const bonusAmounts = getTotalBonifications(
		bonifications,
		pay.iva,
		coinSymbol,
	);
	const discountsAmounts = getTotalDiscounts(discounts, pay.iva, coinSymbol);
	const totalToPay = getTotalToPay(
		prodAmounts.productionTotal,
		bonusAmounts.bonificationTotal,
		discountsAmounts.discountTotal,
		coinSymbol,
	);
	const targetRef = useRef();
	const signed = resume.payments.reduce((acc, payment) => {
		if (Array.isArray(payment.signed)) acc = acc.concat(payment.signed);
		return acc;
	}, []);
	orderBy(signed, 'objectDate', {
		objectKey: 'created_at',
		dateFormat: profile?.user?.settings?.date_format,
	});

	return (
		<Wrapper padding='0' flexDirection='column' overflow='auto'>
			<Wrapper
				elementRef={targetRef}
				width='100%'
				flexDirection='column'
				justifyContent='space-between'
			>
				{/* PRODUCTION */}
				{resume.orders.length > 0 && (
					<>
						<Divider color='gray'>Producción ({resume.orders.length})</Divider>
						<Wrapper flexDirection='column' width='100%'>
							<VirtualizedList
								cardId='paymentOrderResumeCard'
								listStyle={{
									// height: signPaymentModal.groupOrders
									//   ? groupResumeOrders(resume.orders).length * 80
									//   : document.documentElement.clientHeight * 0.9,
									height: document.documentElement.clientHeight * 0.5,
									width: targetRef.current ? targetRef.current.offsetWidth : 0,
								}}
								rowHeight={85}
								rows={
									signPaymentModal.groupOrders
										? groupResumeOrders(resume.orders)
										: resume.orders
								}
								customProps={{
									coinSymbol,
									orders,
									mutate1ObjectInOrders,
									getOrder,
									groupOrders: signPaymentModal.groupOrders,
								}}
							/>
							<GetResumeItem concept='Subtotal' value={prodAmounts.prod} />
							<GetResumeItem concept='IVA' value={prodAmounts.prodIva} />
						</Wrapper>
					</>
				)}

				{/* BONIFICATION */}
				{bonifications.length > 0 && (
					<>
						<Divider color='gray'>
							Bonificación ({bonifications.length})
						</Divider>
						<Wrapper flexDirection='column' width='100%'>
							<VirtualizedList
								cardId='paymentLineResumeCard'
								listStyle={{
									// height: bonifications.length * 135,
									height: document.documentElement.clientHeight * 0.5,
									width: targetRef.current ? targetRef.current.offsetWidth : 0,
								}}
								rowHeight={135}
								spinTitle='Consultando registros...'
								rows={bonifications}
								customProps={{
									coinSymbol,
									orders,
									getOrder,
									mutate1ObjectInOrders,
								}}
							/>
							<GetResumeItem concept='Subtotal' value={bonusAmounts.bonus} />
							<GetResumeItem concept='IVA' value={bonusAmounts.bonusIva} />
						</Wrapper>
					</>
				)}

				{/* DISCOUNTS */}
				{discounts.length > 0 && (
					<>
						<Divider color='gray'>Descuentos ({discounts.length})</Divider>
						<Wrapper flexDirection='column' width='100%'>
							<VirtualizedList
								cardId='paymentLineResumeCard'
								listStyle={{
									// height: discounts.length * 135,
									height: document.documentElement.clientHeight * 0.5,
									width: targetRef.current ? targetRef.current.offsetWidth : 0,
								}}
								rowHeight={135}
								spinTitle='Consultando registros...'
								rows={discounts}
								customProps={{
									coinSymbol,
									orders,
									getOrder,
									mutate1ObjectInOrders,
								}}
							/>
							<GetResumeItem concept='Subtotal' value={discountsAmounts.disc} />
							<GetResumeItem concept='IVA' value={discountsAmounts.discIva} />
						</Wrapper>
					</>
				)}

				{/* RESUME */}
				<Divider color='gray'>Resumen</Divider>
				<Wrapper flexDirection='column' width='100%'>
					<GetResumeItem concept='Producción' value={prodAmounts.prodTotal} />
					<GetResumeItem
						concept='Bonificación'
						value={`+ ${bonusAmounts.bonusTotal}`}
					/>
					<GetResumeItem
						concept='Descuentos'
						value={`- ${discountsAmounts.discTotal}`}
					/>
					<GetResumeItem concept='IVA' value={`${pay.iva}%`} />
					<GetResumeItem
						concept='Total a Pagar'
						value={totalToPay}
						color={theme.fonts.colors.emphasis_1}
					/>
				</Wrapper>

				{/* SIGNED */}
				<Divider color='gray'>Firmas</Divider>
				<Wrapper
					width='100%'
					margin='20px 0 10px 0'
					borderRadius='20px'
					backgroundColor={theme.colors.secondary[800]}
					overflow='auto'
				>
					{signed.map((sign, idx) => {
						const user = totalUsers.find(user => user.id === sign.user_id);
						if (!user) return null;

						let style = { color: 'rgba(255, 255, 255, 0.6)' };

						if (sign.paid) style = { color: 'green' };
						else if (sign.released) style = { color: 'yellow' };
						else if (sign.agreed) style = { color: 'deepskyblue' };

						return (
							user && (
								<Wrapper key={idx} padding='0px'>
									<PersonIcon style={style} />
									<Wrapper
										margin='0 10px 10px 0'
										padding='0px'
										flexDirection='column'
										alignItems='baseline'
									>
										<Span style={style} margin='0 10px'>
											{user.name} ({sign.payment_id})
										</Span>
										<Span margin='0 10px' color='cadetblue'>
											{sign.created_at}
										</Span>
									</Wrapper>
								</Wrapper>
							)
						);
					})}
				</Wrapper>

				{recordsHaveAllowedStates(resume.payments, [
					'ready',
					'agreed',
					'released',
				]) && (
					<Wrapper width='100%' justifyContent='flex-end'>
						{signPaymentModal.loading ? (
							<LogoIcon button spin />
						) : (
							<Button
								mode='primary'
								onClick={() => signPayment(pay.id, inModule)}
							>
								Firmar
							</Button>
						)}
					</Wrapper>
				)}
			</Wrapper>
		</Wrapper>
	);
}
