// Libs
import React from 'react';
// Utils
import { OrderUtils } from 'utils';
// Components
import { AddIcon, IconButton, SearchIcon } from 'components';
import { CustomToolbar } from '../sharedFunctions';
// Styles
import '../index.css';

export default function Reception(props) {
	const {
		selectNotification,
		mutate1ObjectInWarehouses,
		transactionsManage,
		inventoryReception,
		handleOnClick,
	} = props;

	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={transactionsManage.titleToolbar}
			left={
				<IconButton
					type={
						inventoryReception.modeView === 'editPath' ? 'close' : 'arrow-left'
					}
					onClick={
						inventoryReception.modeView === 'editPath'
							? handleOnClick
							: () => {
									mutate1ObjectInWarehouses('inventoryReception', {
										modeView: 'editPath',
									});
									mutate1ObjectInWarehouses('transactionsManage', {
										titleToolbar: 'Recibiendo',
									});
							  }
					}
				/>
			}
			right={
				<>
					<SearchIcon
						onClick={() =>
							OrderUtils.onClickSearchButton(
								mutate1ObjectInOrders,
								searchInData,
							)
						}
					/>

					{/* Add Orders */}
					{profile.entity.id === profile.organization.settings.mainEntity && (
						<AddIcon
							button
							onClick={() =>
								onResetEnterNewOrders({
									isOpen: true,
								})
							}
						/>
					)}
				</>
			}
		/>
	);
}
