// Libs
import React from 'react';

const Refresh = ({ fill, fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='refreshIconMask'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='4'
			y='4'
			width='16'
			height='16'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.64 6.35C16.19 4.9 14.2 4 11.99 4C7.57 4 4 7.58 4 12C4 16.42 7.57 20 11.99 20C15.72 20 18.83 17.45 19.72 14H17.64C16.82 16.33 14.6 18 11.99 18C8.68 18 5.99 15.31 5.99 12C5.99 8.69 8.68 6 11.99 6C13.65 6 15.13 6.69 16.21 7.78L12.99 11H19.99V4L17.64 6.35Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#refreshIconMask)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);

export default Refresh;
