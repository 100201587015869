// Libs
import { useSelector } from 'react-redux';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Styles
import darkTheme from 'themes/darkTheme.module.css';
import lightTheme from 'themes/lightTheme.module.css';
import sharedStyles from 'themes/sharedStyles.module.css';

const useTheme = () => {
	const profile = useSelector(selectProfile);
	const themeProfile = profile?.user?.settings?.theme || 'dark';
	const theme = {
		dark: darkTheme,
		light: lightTheme,
	}[themeProfile];
	const isDarkTheme = themeProfile === 'dark';
	return { theme, sharedStyles, isDarkTheme, themeProfile };
};

export default useTheme;
