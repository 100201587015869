// Libs
import { IconButton, Input, InputAdornment } from '@mui/material';
import { Button, Link, Logo, LogoIcon, Wrapper } from 'components';
import { PersonIcon } from 'components/Icons';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useUserApi } from 'hooks';
import { useHistory } from 'react-router-dom';
import { sendToast } from 'screens/Dashboard/actions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { withStyles } from '@mui/styles';
import useTheme from 'hooks/useTheme';

import GENERAL from 'utils/constants/general';

// style
import styles from './RecoveryPassword.module.css';

const { ENV } = GENERAL;

const customStyles = theme => ({
	button: {
		top: '10px',
		fontFamily: 'Roboto',
	},
	textField: {
		width: '200px',
		minHeight: '48px',
		height: '48px',
		paddingLeft: '15px',
		backgroundColor: 'rgba(255,255,255,.07)',
		color: '#fff',
		marginBottom: '10px',
		fontFamily: 'Roboto',
	},
	icon: {
		color: 'rgba(255, 255, 255, 0.54)',
	},
});

const RecoveryPassword = ({ classes, sendToast }) => {
	const { theme } = useTheme();
	const history = useHistory();
	const inputRef = useRef(null);

	const [state, setState] = useState({
		isLoading: false,
		step: 1,
		idc: null,
		recoveryCode: null,
		newPassword: null,
		showNewPassword: false,
		colorInput: 'info',
	});

	const {
		recoveryPasswordRequest,
		recoveryPasswordConfirm,
		recoveryPasswordUpdate,
	} = useUserApi();

	const onInputFocus = () =>
		setTimeout(() => {
			inputRef?.current?.focus();
		}, 100);

	useEffect(() => {
		// INFO
		if (state.step === 1) {
			sendToast({
				message: 'Ingrese su usuario',
			});
			onInputFocus();
		} else if (state.step === 2) {
			sendToast({
				message: 'Ingrese el codigo de recuperación que recibió en su correo',
			});
			setState(prev => ({ ...prev }));
		} else if (state.step === 3) {
			sendToast({
				message: 'Ingrese su nueva contraseña',
			});
			setState(prev => ({ ...prev }));
		}
	}, [state.step, inputRef?.current]);

	const onChange = e =>
		setState(prev => ({
			...prev,
			[e.target.name]: e.target.value?.trim(),
		}));

	const onFetch = async () => {
		setState(prev => ({ ...prev, isLoading: true }));
		if (state.step === 1)
			return recoveryPasswordRequest(state.idc).then(() => {
				setState(prev => ({
					...prev,
					step: 2,
					isLoading: false,
					colorInput: 'info',
				}));
				onInputFocus();
			});
		else if (state.step === 2)
			return recoveryPasswordConfirm(state.idc, state.recoveryCode).then(() => {
				setState(prev => ({ ...prev, step: 3, isLoading: false }));
				onInputFocus();
			});
		else if (state.step === 3)
			return recoveryPasswordUpdate(state.idc, state.newPassword).then(() => {
				// INFO SUCCESS
				sendToast({
					message: 'Se ha actualizado la contraseña exitosamente',
					type: 'success',
				});
				history.push(ENV.ROUTES.PATHS.LOGIN);
			});
	};

	const onClick = () => {
		let err = false;
		let message = '';

		// Validations
		if (!state.idc) {
			err = true;
			message = 'Ingrese su usuario';
		} else if (state.step === 2 && !state.recoveryCode) {
			err = true;
			message = 'Ingrese su codigo de recuperación';
		} else if (
			state.step === 3 &&
			(!state.newPassword || state.newPassword.length <= 6)
		) {
			if (!state.newPassword) {
				err = true;
				message = 'Ingrese su nueva contraseña mayor a 6 dígitos';
			}
			if (state.newPassword.length <= 6) {
				err = true;
				message = 'Ingrese su nueva contraseña';
			}
		}

		if (err) {
			onInputFocus();
			setState(prev => ({ ...prev, colorInput: 'error' }));
			sendToast({
				message,
				type: 'warning',
			});
			return;
		}

		onFetch()?.catch(err => {
			sendToast({
				message: err.message,
				type: 'error',
			});
			setState(prev => ({
				...prev,
				colorInput: 'error',
				isLoading: false,
			}));
			onInputFocus();
		});
	};

	const disabledIdc = state.step !== 1;

	return (
		<>
			<div>
				<div className={styles.card}>
					{/* LOGO */}
					<Logo position='absolute' left='100px' top='-55px' opacity='.7' />

					<div className={styles.inputs}>
						{/* IDC */}
						<Input
							style={{
								color: !disabledIdc
									? theme._mediumEmphasisColor
									: theme._inactiveColor,
							}}
							inputRef={state.step === 1 ? inputRef : null}
							color={state.colorInput}
							className={classes.textField}
							name='idc'
							placeholder='Usuario'
							disabled={disabledIdc}
							onChange={onChange}
							value={state.idc}
							type='text'
							endAdornment={
								<InputAdornment position='end'>
									<IconButton>
										<PersonIcon className={classes.icon} />
									</IconButton>
								</InputAdornment>
							}
						/>

						{/* RECOVERY CODE */}
						{state.step === 2 && (
							<Input
								style={{ color: theme._mediumEmphasisColor }}
								inputRef={state.step === 2 ? inputRef : null}
								color={state.colorInput}
								className={classes.textField}
								name='recoveryCode'
								placeholder='Código de recuperación'
								onChange={onChange}
								value={state.recoveryCode}
								type='text'
							/>
						)}

						{/* NEW PASSWORD */}
						{state.step === 3 && (
							<Input
								inputRef={state.step === 3 ? inputRef : null}
								className={classes.textField}
								color={state.colorInput}
								name='newPassword'
								placeholder='Nueva Contraseña'
								onChange={onChange}
								value={state.newPassword}
								type={state.showNewPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position='end'>
										<IconButton
											onClick={() =>
												setState(prev => ({
													...prev,
													showNewPassword: !prev.showNewPassword,
												}))
											}
										>
											{state.showNewPassword ? (
												<VisibilityOff className={classes.icon} />
											) : (
												<Visibility className={classes.icon} />
											)}
										</IconButton>
									</InputAdornment>
								}
							/>
						)}
					</div>

					<div className={styles.bottoms}>
						{state.isLoading ? (
							<LogoIcon spin />
						) : (
							<Button onClick={onClick}>Siguiente</Button>
						)}
						<div>
							<span style={{ marginRight: '7px', color: 'white' }}>
								¿Ya tiene cuenta?
							</span>
							<Link style={{ color: '#1890ff' }} to={ENV.ROUTES.PATHS.LOGIN}>
								Ingresar
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
const actions = { sendToast };

export default connect(
	null,
	actions,
)(withStyles(customStyles)(RecoveryPassword));
