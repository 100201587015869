// Libs
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import axios from 'axios';
// Utils
import asyncErrorsHandler from 'store/asyncErrorsHandler';
import GENERAL from 'utils/constants/general';
import API from 'utils/constants/api';
import AuthService from 'utils/libs/auth/AuthService';
import KEYWORDS from './keywords';
// Selectors
import { selectMarketplaceItems } from './selectors';

const { MODULES } = GENERAL;
const auth = new AuthService();

//* ******* WATCHER SAGAS ***********/
function* getMyActiveModulesWatcher() {
	yield takeLatest(MODULES.GET_MY_ACTIVE_MODULES, getMyActiveModulesWorker);
}
function* getMyDatasourceAccessWatcher() {
	yield takeLatest(
		MODULES.GET_MY_DATASOURCE_ACCESS,
		getMyDatasourceAccessWorker,
	);
}
function* getOrderTargetsWatcher() {
	yield takeLatest(MODULES.GET_ORDER_TARGETS, getOrderTargetsWorker);
}
function* getMarketBannersWatcher() {
	yield takeLatest(MODULES.GET_MARKET_BANNERS, getMarketBannersWorker);
}
function* getMarketModulesWatcher() {
	yield takeLatest(MODULES.GET_MARKET_MODULES, getMarketModulesWorker);
}
function* getMarketItemsWatcher() {
	yield takeLatest(MODULES.GET_MARKET_ITEMS, getMarketItemsWorker);
}
function* updateMarketItemInstallationStatusWatcher() {
	yield takeLatest(
		MODULES.UPDATE_MARKETITEM_INSTALLATION_STATUS,
		updateMarketItemInstallationStatusWorker,
	);
}

//* ******* WORKERS SAGAS ***********/
function* getMyActiveModulesWorker() {
	try {
		yield put({
			type: MODULES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'accessControl',
				keyValuePairs: { state: KEYWORDS.STATE.LOADING },
			},
		});
		const { data: modules } = yield call(
			axios.get,
			API.DOMAIN.concat('/modules'),
			auth.sendToken(),
		);
		yield put({
			type: MODULES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'accessControl',
				keyValuePairs: { state: KEYWORDS.STATE.SUCCESS, modules },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				let state = KEYWORDS.STATE.ERROR;
				if (err.response.status === 401) {
					state = KEYWORDS.STATE.UNAUTHORIZED;
				}
				yield put({
					type: MODULES.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'accessControl',
						keyValuePairs: { state },
					},
				});
			},
			function* () {
				yield getMyActiveModulesWorker();
			},
		);
	}
}

function* getMyDatasourceAccessWorker(action) {
	const { moduleItemId, orderTargetId, onLoading, onSuccess } = action.payload;

	if (typeof onSuccess !== 'function') return;

	try {
		if (onLoading) {
			const actions = onLoading();
			for (const action of actions) {
				yield put(action);
			}
		}
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat(
				`/modules/v1/getMyDatasourceAccess/${moduleItemId}/${orderTargetId}`,
			),
			auth.sendToken(),
		);

		const actions = onSuccess(data);
		for (const action of actions) {
			yield put(action);
		}
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getMyDatasourceAccessWorker(action);
		});
	}
}

function* getOrderTargetsWorker(action) {
	const { moduleItemId, projectId, onLoading, onSuccess } = action.payload;

	if (typeof onSuccess !== 'function') return;

	try {
		if (onLoading) {
			const actions = onLoading();
			for (const action of actions) {
				yield put(action);
			}
		}
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat(
				`/modules/v1/orderTargets/${moduleItemId}/${projectId}`,
			),
			auth.sendToken(),
		);

		const actions = onSuccess(data);
		for (const action of actions) {
			yield put(action);
		}
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getOrderTargetsWorker(action);
		});
	}
}

function* getMarketBannersWorker() {
	try {
		yield put({
			type: MODULES.MUTATE_MARKETPLACE,
			payload: {
				obj1Name: 'banners',
				keyValuePairs: { isFetching: true },
			},
		});
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat('/modules/banners'),
			auth.sendToken(),
		);

		yield put({
			type: MODULES.MUTATE_MARKETPLACE,
			payload: {
				obj1Name: 'banners',
				keyValuePairs: { isFetching: false, data },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getMarketBannersWorker();
		});
	}
}

function* getMarketModulesWorker() {
	try {
		yield put({
			type: MODULES.MUTATE_MARKETPLACE,
			payload: {
				obj1Name: 'modules',
				keyValuePairs: { isFetching: true },
			},
		});
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat('/modules/marketModules'),
			auth.sendToken(),
		);
		yield put({
			type: MODULES.MUTATE_MARKETPLACE,
			payload: {
				obj1Name: 'modules',
				keyValuePairs: { isFetching: false, data },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getMarketModulesWorker();
		});
	}
}

function* getMarketItemsWorker(action) {
	const { moduleId, lastRowId, limit } = action.payload;
	try {
		yield put({
			type: MODULES.MUTATE_MARKETPLACE,
			payload: {
				obj1Name: 'items',
				keyValuePairs: { isFetching: true },
			},
		});
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat(
				`/modules/marketItems/${moduleId}/${lastRowId}/${limit}`,
			),
			auth.sendToken(),
		);
		yield put({
			type: MODULES.MUTATE_MARKETPLACE,
			payload: {
				obj1Name: 'items',
				keyValuePairs: { isFetching: false, data },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getMarketItemsWorker(action);
		});
	}
}

function* updateMarketItemInstallationStatusWorker(action) {
	const marketItems = yield select(selectMarketplaceItems);
	const {
		marketItemId: moduleItemId,
		newStatus,
		coverage,
		users,
	} = action.payload;
	try {
		yield put({
			type: MODULES.MUTATE_MARKETPLACE,
			payload: {
				obj1Name: 'items',
				keyValuePairs: { isFetching: true },
			},
		});
		const { data: item } = yield call(
			axios.put,
			API.DOMAIN.concat(
				`/modules/marketItems/installation/status/${moduleItemId}`,
			),
			{
				newStatus,
				coverage,
				users,
			},
			auth.sendToken(),
		);
		yield put({
			type: MODULES.MUTATE_MARKETPLACE,
			payload: {
				obj1Name: 'items',
				keyValuePairs: {
					isFetching: false,
					data: marketItems.data.map(i => (i.id === item.id ? item : i)),
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield updateMarketItemInstallationStatusWorker(action);
		});
	}
}

// Export default Root Saga
export default function* rootSaga() {
	yield all([
		getMyActiveModulesWatcher(),
		getMarketBannersWatcher(),
		getMarketModulesWatcher(),
		getMarketItemsWatcher(),
		updateMarketItemInstallationStatusWatcher(),
		getMyDatasourceAccessWatcher(),
		getOrderTargetsWatcher(),
	]);
}
