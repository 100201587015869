// Icons
import {
	AddShopingCartIcon,
	LibraryIcon,
	SerieIcon,
	WarehouseIcon,
} from 'components/Icons';
const BASE = '/dashboard/warehouse';

export default Object.freeze({
	ROUTES: {
		BASE_ROUTE: {
			ID: 'wrh',
			ITEM: 'Almacén',
			TITLE: 'Almacén',
			PATH: BASE,
		},
		// NEW
		CATALOG: {
			ID: 'cat',
			ITEM: 'Catálogo',
			TITLE: 'Catálogo',
			PATH: `${BASE}/catalog`,
			ICON: LibraryIcon,
		},
		// NEW
		REQUESTS: {
			ID: 'wrs',
			ITEM: 'Solicitudes',
			TITLE: 'Solicitudes',
			PATH: `${BASE}/requests`,
			ICON: AddShopingCartIcon,
		},
		ARTICLES: {
			ID: 'art',
			ITEM: 'Artículos',
			TITLE: 'Artículos',
			PATH: `${BASE}/articles`,
			ICON: LibraryIcon,
		},
		WAREHOUSES: {
			ID: 'wrs',
			ITEM: 'Almacenes',
			TITLE: 'Almacenes',
			PATH: `${BASE}/warehouses`,
			ICON: WarehouseIcon,
		},

		SERIALIZED: {
			ID: 'srz',
			ITEM: 'Series',
			TITLE: 'Series',
			PATH: `${BASE}/serialized`,
			ICON: SerieIcon,
		},
		TRANSACTIONS: {
			ID: 'trs',
			ITEM: 'Transacciones',
			TITLE: 'Transacciones',
			PATH: `${BASE}/transactions`,
			ICON: WarehouseIcon,
		},
	},
});
