import AbsAuthorization from 'core/Authorization/manager';

export class OrderAuthorization extends AbsAuthorization {
	constructor(profile) {
		super(profile);
	}

	checkEditOrderWarranty() {
		return !!this.roles[this.checkOrderL2Manager()];
	}
}

export default OrderAuthorization;
