/**
 * Welcome to ActionBuilderConfig
 * Max steps to implement new Action: 2
 */

import { GlobalUtils } from 'utils';

import { DefaultAction } from './actions';

/**
 * Step 1: Add your authorized action key from <master.datafilter_actions.key>
 */
const authorizedActionKeys = {
	createOrder: 'createOrder',
	importOrders: 'importOrders',
	generateReport: 'generateReport',
	createInventoryArticle: 'createInventoryArticle',
	// <--HERE
};

/**
 * Step 2: Add your authorized action react component
 */
const authorizedActionComponents = {
	// <--HERE
};

class ActionBuilderConfig {
	static getAuthorizedActionKeys() {
		return authorizedActionKeys;
	}

	/**
	 * @param {string} actionKey - The action key
	 * @returns Authorized action component
	 */
	static getAuthorizedActionComponentReactNode(actionKey) {
		return authorizedActionComponents[actionKey] || DefaultAction;
	}

	/**
	 * @param {Object[]} actions - The module setted actions array from selected data filter item
	 * @returns Authorized actions
	 */
	static getAuthorizedActions(actions) {
		return GlobalUtils.checkArray(actions).filter(
			action => !!authorizedActionKeys[action.key], // Call to gateway to access the authorized action keys
		);
	}
}

export default ActionBuilderConfig;
