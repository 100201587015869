import React from 'react';

// CHARTS
import Pie from './Pie';
import Bar from './Bar';

const Chart = ({ type, data, config, theme }) => {
	const charts = {
		PIE: <Pie data={data} config={config} theme={theme} />,
		BAR: <Bar type={type} data={data} config={config} theme={theme} />,
	};

	return charts[type] ?? null;
};

export default Chart;
