import React, { createContext, useState } from 'react';

const ElementViewBuilderContext = createContext({});
const { Provider } = ElementViewBuilderContext;

const initialState = {
	elementViewKey: undefined,
	elementId: undefined,
	// ...{}
};
const ElementViewBuilderProvider = ({ children }) => {
	const [payload, setPayload] = useState(initialState);

	const setElementView = (props = {}) =>
		setPayload(prev => ({ ...prev, ...props }));

	const cleanElementView = () => setPayload(initialState);

	const value = { payload, setElementView, cleanElementView };

	return <Provider value={value}>{children}</Provider>;
};

export { ElementViewBuilderProvider, ElementViewBuilderContext };
