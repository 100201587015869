// Libs
import React from 'react';
// Utils
import { calculateTimers } from './utils';

const Timer = ({
	startAt,
	timeElapsed,
	timeRemaining,
	freezeTimer,
	render,
	delay = 1000,
}) => {
	const [timers, setTimers] = React.useState({});

	// Set Timers
	React.useEffect(() => {
		const timer = setTimeout(() => {
			setTimers(
				calculateTimers({
					startAt,
					timeElapsed,
					timeRemaining,
					freezeTimer,
				}),
			);
		}, delay);
		// Clear timeout if the component is unmounted
		return () => clearTimeout(timer);
	});

	if (
		(timeRemaining && !timers.timeRemaining) ||
		(timeElapsed && !timers.timeElapsed)
	) {
		return null;
	}
	return render({
		timeRemaining: timers.timeRemaining,
		timeElapsed: timers.timeElapsed,
	});
};

export default Timer;
