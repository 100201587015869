// Libs
import React from 'react';
import { connect } from 'react-redux';
// Utils
import { GlobalUtils } from 'utils';
// Actions
import { mutate1Object as mutate1ObjectInCore } from '../actions';
// Selectors
import {
	selectAuthorizedElements,
	selectAuthorizedScreens,
	selectScreenKey,
	selectSelectedDataFilterId,
	selectSelectedDataSourceConfig,
	selectSelectedScreenId,
	selectSelectedElementId,
} from '../selectors';
// Service
import { ApiService } from 'services';
// Components
import { Wrapper, Dropdown } from 'components';
import ElementPicker from '../ElementBuilder/ElementPicker';
import { ScreenBuilderConfig, ScreenPicker } from '../ScreenBuilder';

const DisplayOptionsMenu = ({
	selectedScreenId,
	selectedElementId,
	screens,
	elements,
	onMutateScreen,
	onMutateElement,
}) => {
	return (
		<Wrapper
			mediumBackground
			padding='10px'
			width='100%'
			flexDirection='column'
			maxWidth='200px'
		>
			{/* Iterate & Render all screen options */}
			<ScreenPicker
				selectedScreenId={selectedScreenId}
				screens={screens}
				onMutateScreen={onMutateScreen}
			/>
			{/* Iterate & Render all element options */}
			<ElementPicker
				selectedElementId={selectedElementId}
				elements={elements}
				onMutateElement={onMutateElement}
			/>
		</Wrapper>
	);
};

/**
 * @param {Object} display - The display current settings
 * @param {Object[]} display.screens - The screen array from selectedDataFilterId
 * @param {int} display.selectedScreenId
 * @param {int} selectedDataFilterId
 * @param {Object} selectedDataSourceConfig
 * @param {function} mutate1ObjectInCore - The mutate1ObjectInCore redux action
 * @returns {ReactNode}
 */
const DisplayPicker = ({
	// State
	selectedDataFilterId,
	selectedDataSourceConfig,
	selectedScreenId,
	selectedElementId,
	screenKey,
	authorizedScreens,
	authorizedElements,
	// Actions
	mutate1ObjectInCore,
}) => {
	// On Mutate display
	const onMutateScreen = selectedScreenId =>
		mutate1ObjectInCore('display', { selectedScreenId });
	const onMutateElement = selectedElementId =>
		mutate1ObjectInCore('display', { selectedElementId });

	const sortScreens = ({ orderScreenByIds, authorizedScreens }) => {
		const authorizedScreensIds = authorizedScreens.map(screen => screen.id);
		const sortScreensIds = orderScreenByIds
			.concat(
				authorizedScreensIds.filter(
					screenId => !orderScreenByIds.includes(screenId),
				),
			)
			.filter(screenOrderIdx => authorizedScreensIds.includes(screenOrderIdx));

		return sortScreensIds.map(id =>
			authorizedScreens.find(screen => screen.id == id),
		);
	};

	// Get Screen & Element option list data
	React.useEffect(() => {
		if (!selectedDataFilterId) return;

		mutate1ObjectInCore('display', {
			isFetching: true,
		});

		ApiService.fetch(ApiService.endpoints().GET_FEED_DISPLAY_OPTIONS, {
			reqParams: { selectedDataFilterId },
		}).then(_screens => {
			const orderScreenByIds = selectedDataSourceConfig.orderScreensIds;
			const authorizedScreens = ScreenBuilderConfig.getAuthorizedScreens(
				GlobalUtils.checkArray(_screens),
			);

			const screens = orderScreenByIds?.length
				? sortScreens({ orderScreenByIds, authorizedScreens })
				: authorizedScreens;

			mutate1ObjectInCore('display', {
				isFetching: false,
				selectedScreenId: screens[0]?.id,
				screens,
			});
		});
	}, [selectedDataFilterId]);

	// Select first element
	React.useEffect(() => {
		if (selectedScreenId && authorizedElements.length)
			onMutateElement(authorizedElements[0].id);
	}, [selectedScreenId, authorizedElements]);

	const Icon =
		ScreenBuilderConfig.getPickerIcon(screenKey) ||
		ScreenBuilderConfig.getPickerIcon(
			ScreenBuilderConfig.authorizedScreenKeys().standardGridV1,
		);

	return (
		<Dropdown.Wrapper
			trigger={['click']}
			overlay={
				<DisplayOptionsMenu
					selectedScreenId={selectedScreenId}
					selectedElementId={selectedElementId}
					screens={authorizedScreens}
					elements={authorizedElements}
					onMutateScreen={onMutateScreen}
					onMutateElement={onMutateElement}
				/>
			}
		>
			<Icon button selected />
		</Dropdown.Wrapper>
	);
};

const mapStateToProps = state => ({
	selectedDataFilterId: selectSelectedDataFilterId(state),
	selectedDataSourceConfig: selectSelectedDataSourceConfig(state),
	selectedScreenId: selectSelectedScreenId(state),
	selectedElementId: selectSelectedElementId(state),
	screenKey: selectScreenKey(state),
	authorizedScreens: selectAuthorizedScreens(state),
	authorizedElements: selectAuthorizedElements(state),
});
const actions = { mutate1ObjectInCore };
export default connect(mapStateToProps, actions)(DisplayPicker);
