import { Chip } from '@mui/material';
import styled, { css } from 'styled-components';

const ChipStyled = styled(Chip)`
	margin: ${props => props.margin};
	position: ${({ position }) => position};
	top: ${({ top }) => top};
	bottom: ${({ bottom }) => bottom};
	left: ${({ left }) => left};
	right: ${({ right }) => right};
	height: ${({ height }) => height};
	&#selectable:hover {
		background-color: ${({ theme }) => theme.colors.hover.color};
	}
	.css-6od3lo-MuiChip-label {
		color: #fff;
	}
	${({ selected }) =>
		selected &&
		css`
			&#selectable {
				background-color: ${({ theme }) => theme.colors.hover.color};
			}
		`}
`;

export default ChipStyled;
