// Libs
import React from 'react';
import { Wrapper, Title } from 'components';
// Illustrations
import SuccessFactors from './MobileFeed';

const MobileFeedIllustration = ({ title, width, fontSize, className }) => (
	<Wrapper
		className={className}
		width='100%'
		height='80%'
		justifyContent='center'
		alignItems='center'
	>
		<Wrapper flexDirection='column' alignItems='center'>
			<SuccessFactors width={width} />
			{title && (
				<Title textAlign='center' margin='10px 0 0 0' fontSize={fontSize}>
					{title}
				</Title>
			)}
		</Wrapper>
	</Wrapper>
);

export default MobileFeedIllustration;
