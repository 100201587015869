// Libs
import React, { useState, useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Utils
import { getReactions, submitReaction } from './Reactions.utils';
// Config
import ReactionBuilderConfig from './reaction.config';
// Components
import {
	LogoIcon,
	Popover,
	Rate,
	SubCaption,
	AntButton,
	Span,
} from 'components';
// Skeleton
import ReactionsSkeleton from './ReactionsSkeleton';
// Styles
import styles from './Reactions.module.css';

const ReactionButton = ({
	reactionKey,
	isLoading,
	disabled,
	onClick,
	count,
}) => {
	const ButtonIcon = ReactionBuilderConfig.getReactionIcon(reactionKey);
	if (!ButtonIcon) return null;
	return (
		<AntButton disabled={disabled} onClick={onClick} type='link'>
			{!isLoading ? (
				<div className={styles.buttonContentWrapper}>
					{Number(count) > 0 && <SubCaption disabled>{count}</SubCaption>}
					<Suspense fallback={<LogoIcon spin={true} />}>
						<ButtonIcon />
					</Suspense>
				</div>
			) : (
				<LogoIcon spin={true} />
			)}
		</AntButton>
	);
};

const Reactions = ({
	// Props
	resourceKey,
	resourceId,
	externalReactionHandler = {},
	// State
	profile,
}) => {
	// TODO: (e) use this to find which reaction the user has selected if any
	const { id: userId, name: userName } = profile.user;
	const { extReactions, extSetted, setExtReactions } = externalReactionHandler; // This line provide external support with purpose prevent reloading by virtualization

	const [submittingReactionId, setSubmittingReactionId] = useState(-1);
	const [{ isFetching }, setState] = useState({
		isFetching: false,
	});
	const [localReactions, setLocalReactions] = useState(
		() => extReactions || [],
	);

	useEffect(() => {
		let isMounted = true;
		if (extSetted) return;

		setState(prev => ({ ...prev, isFetching: true }));
		getReactions({
			resourceKey,
			resourceId,
		}).then(data => {
			if (!isMounted) return; // avoid memory leaks
			const reactions = Array.isArray(data) ? data : [];
			if (setExtReactions)
				setExtReactions(prev => ({
					...prev,
					reactions,
					setted: true,
				}));
			else setLocalReactions(reactions);
			setState(prev => ({ ...prev, isFetching: false }));
		});

		return () => {
			isMounted = false;
		};
	}, [extSetted]);

	const handleSubmitReaction = async (reactionId, rating) => {
		setSubmittingReactionId(reactionId);
		const data = await submitReaction({
			resourceKey,
			resourceId,
			reactionId,
			rating,
		});
		if (!data) return;
		if (setExtReactions)
			setExtReactions(prev => ({
				...prev,
				setted: false,
			}));
		else setLocalReactions(data);
		setSubmittingReactionId(-1);
	};

	const isSubmitting = submittingReactionId !== -1;

	const reactions = React.useMemo(
		() => (extReactions?.length ? extReactions : localReactions),
		[extReactions, localReactions],
	);

	return (
		<>
			{!isFetching ? (
				reactions.map(({ reactionId, reactionKey, hasRating, count }) =>
					!hasRating ? (
						<ReactionButton
							key={reactionId}
							reactionKey={reactionKey}
							isLoading={reactionId === submittingReactionId}
							disabled={isSubmitting}
							count={count}
							onClick={() => handleSubmitReaction(reactionId)}
						/>
					) : (
						<Popover
							key={reactionId}
							title={null}
							trigger='click'
							content={
								<div className={styles.popoverContent}>
									<Rate
										disabled={isSubmitting}
										onChange={value => handleSubmitReaction(reactionId, value)}
										allowClear={true}
										allowHalf={true}
									/>
								</div>
							}
						>
							<ReactionButton
								reactionKey={reactionKey}
								isLoading={reactionId === submittingReactionId}
								disabled={isSubmitting}
								count={count}
							/>
						</Popover>
					),
				)
			) : (
				<LogoIcon spin={true} />
			)}
		</>
	);
};

const mapStateToProps = state => ({
	profile: selectProfile(state),
});

Reactions.Skeleton = ReactionsSkeleton;

export default connect(mapStateToProps, null)(Reactions);
