export const USER_FIELDS = {
	NAME: 'name',
	EMAILS: 'emails',
	PHONES: 'phones',
};

export const SETTINGS_FIELDS = {
	DATE_FORMAT: 'dateFormat',
	DARK_THEME: 'darkTheme',
};

export const EMPLOYER_FIELDS = {
	ENTITY_ID: 'entityId',
	ORGANIZATION_ID: 'organizationId',
	COMPANY_ID: 'companyId',
};

export const userFieldsInitialState = {
	[USER_FIELDS.NAME]: {
		nullable: false,
		value: undefined,
	},
	[USER_FIELDS.EMAILS]: {
		nullable: true,
		values: [undefined, undefined],
	},
	[USER_FIELDS.PHONES]: {
		nullable: true,
		values: [undefined, undefined],
	},
};

export const settingsFieldsInitialState = {
	[SETTINGS_FIELDS.DATE_FORMAT]: {
		nullable: false,
		value: undefined,
	},
	[SETTINGS_FIELDS.DARK_THEME]: {
		nullable: false,
		value: undefined,
	},
};

export const employerFieldsInitialState = {
	[EMPLOYER_FIELDS.ENTITY_ID]: {
		nullable: false,
		value: undefined,
	},
	[EMPLOYER_FIELDS.ORGANIZATION_ID]: {
		nullable: false,
		value: undefined,
	},
	[EMPLOYER_FIELDS.COMPANY_ID]: {
		nullable: false,
		value: undefined,
	},
};
