import styled from 'styled-components';
import { Transfer } from 'antd';
// Hooks
import useTheme from 'hooks/useTheme';

const CustomTransfer = styled(Transfer)`
	.ant-transfer-operation {
		button {
			background-color: ${props => props.theme._surfaceBackgroundColor};
			border-color: ${props => props.theme._surfaceBackgroundColor};
			&:hover {
				background-color: ${props => props.theme._borderColor};
				border-color: ${props => props.theme._borderColor};
			}
		}
	}
	.ant-transfer-list-header {
		background: ${props => props.theme._surfaceBackgroundColor};
		color: ${props => props.theme._primaryColor};
	}
	.ant-transfer-list-body-search-wrapper {
		span {
			background: ${props => props.theme._transparentBackgroundColor};
		}
	}
`;

const StyledTransfer = props => {
	const { theme } = useTheme();

	return (
		<CustomTransfer theme={theme} {...props}>
			{props.children}
		</CustomTransfer>
	);
};

export default StyledTransfer;
