// Libs
import React from 'react';
import { Card } from 'antd';
// Utils
import { formatNumber } from 'utils/libs';
// Styles
import './PaymentOrderResumeCard.css';

const { Meta } = Card;

export default function PaymentOrderResumeCard(props) {
	const {
		order_id,
		odt_id,
		expert_name,
		task_id,
		task_name,
		amount,
		bonus,
		service_name,
		counter,
		totalCount,
		customProps,
		devices,
		distance,
		unitPrice,
	} = props;
	const { coinSymbol, orders, mutate1ObjectInOrders, getOrder, groupOrders } =
		customProps;
	const amountPrice = formatNumber.new(amount, coinSymbol, 2);
	const bonusPrice = formatNumber.new(bonus, coinSymbol, 2);
	const flagBonus =
		bonus && bonus >= 0.01 ? { backgroundColor: 'white' } : undefined;

	return (
		<div>
			<div className='order_resume-flag-payment-state' style={flagBonus} />
			<Meta
				id='order_resume-card-container'
				title={
					groupOrders ? (
						<div className='order_resume-container-title-card'>
							<span className='order_resume-card-task'>{task_name}</span>

							{/* <div style={{ height: '18px' }}><span className="order_resume-body-concept-item">Equipos:</span>
                <span className="order_resume-card-creator">{devices}</span>
              </div>
              <div style={{ height: '18px' }}><span className="order_resume-body-concept-item">Distancia:</span>
                <span className="order_resume-card-creator">{distance}</span>
              </div> */}
							<div style={{ height: '18px' }}>
								<span className='order_resume-body-concept-item'>
									Unidades:
								</span>
								<span className='order_resume-card-creator'>{totalCount}</span>
							</div>
							<div className='order_resume-footer'>
								<div style={{ height: '18px' }}>
									<span className='order_resume-body-concept-item'>Monto:</span>
									<span className='order_resume-card-created_at'>
										{formatNumber.new(unitPrice, coinSymbol, 2)} /{' '}
									</span>
									<span className='order_resume-card-created_at'>
										{formatNumber.new(amount + bonus, coinSymbol, 2)}
									</span>
								</div>
								<span className='order_resume-counter'>{counter}</span>
							</div>
						</div>
					) : (
						<div className='order_resume-container-title-card'>
							<div className='order_resume-card-order-price-container'>
								<span
									className='order_resume-card-order'
									onClick={() => {
										if (!orders.filter(ord => ord.order_id === order_id)[0])
											getOrder(order_id);
										mutate1ObjectInOrders('getOrderInformationModal', {
											order_id,
											isOpen: true,
										});
									}}
								>
									{odt_id}
								</span>
								<span className='order_resume-card-price'>{`${amountPrice} / ${bonusPrice}`}</span>
							</div>

							<div style={{ height: '18px' }}>
								<span className='order_resume-body-concept-item'>Tipo:</span>
								<span className='order_resume-card-creator'>
									{service_name}
								</span>
							</div>
							<div className='order_resume-footer'>
								<div style={{ height: '18px' }}>
									<span className='order_resume-body-concept-item'>
										Técnico:
									</span>
									<span className='order_resume-card-created_at'>
										{expert_name}
									</span>
								</div>
								<span className='order_resume-counter'>{counter}</span>
							</div>
						</div>
					)
				}
			/>
		</div>
	);
}
