// Libs
import React from 'react';
import _ from 'underscore';
import { Redirect } from 'react-router-dom';
// Utils
import GENERAL from 'utils/constants/general';
import { formatNumber, coinsUtils } from 'utils/libs';
import { getSelectedPayments } from 'utils/filters/Billings/payments';
import { filterPaymentLines } from 'utils/filters/Billings/lines';
// Components
import {
	Wrapper,
	Button,
	TableVirtualized,
	Flag,
	Span,
	Spin,
	LogoIcon,
} from 'components';

const { ENV } = GENERAL;
const { Table, Column, Header, Row, Cell } = TableVirtualized;

function PaymentsLinesDesktop(props) {
	if (
		!Array.isArray(props?.pays?.selected?.items) ||
		props.pays.selected.items.length === 0
	) {
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYS_DESKTOP} />;
	}

	const {
		// BILLINGS
		pays,
		lines,
		mutate1Object,
		updatePaymentIdLine,
		// ORDERS
		mutate1ObjectInOrders,
		ordersState,
		getOrder,
		// API
		payments,
		coins,
		profile,
		mutate1ObjectApi,
	} = props;
	const allLines = payments.lines.data;
	const { control } = ordersState;

	const selectedPayments = getSelectedPayments(
		payments.pays.data,
		pays.selected.items,
	);
	const firstSelectedPayment = selectedPayments[0];

	const filteredPaymentLines = filterPaymentLines(allLines, lines.filters);

	// ------RENDERS------

	// HEADER
	function getHeaderRenderer({ dataKey, label }, orderType) {
		const onChangedFilter = updatedFilters =>
			mutate1Object('lines', { filters: updatedFilters });

		return (
			<Header
				filter={{
					dataKey,
					data: allLines,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: lines.filters,
					onChangedFilter,
				}}
			>
				{label}
			</Header>
		);
	}

	// ROW
	function getRowRenderer({ key, columns, style }) {
		return (
			<Row key={key} style={style}>
				{columns}
			</Row>
		);
	}

	// INDEX
	function getIndexCellRenderer({ rowData, rowIndex }) {
		let flagPriorityStyle = { backgroundColor: 'white' };
		if (rowData.payment_id) flagPriorityStyle = { backgroundColor: 'green' };

		return (
			<Cell>
				<Flag style={flagPriorityStyle} height='20px' />
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	}

	// ORDER
	function getOrderCellRenderer({ rowData }) {
		return (
			<Cell
				onClick={() => {
					if (
						!control.orders.filter(ord => ord.order_id === rowData.order_id)[0]
					) {
						getOrder(rowData.order_id);
					}
					mutate1ObjectInOrders('getOrderInformationModal', {
						order_id: rowData.order_id,
						isOpen: true,
					});
				}}
			>
				{rowData.odt_id}
			</Cell>
		);
	}

	// AMOUNT
	function getAmountCellRenderer({ rowData }) {
		const coinSymbol = coinsUtils.getDataByCoinId(
			rowData.coin_id,
			'symbol',
			coins.data,
		);
		return <Cell>{formatNumber.new(rowData.amount, coinSymbol, 2)}</Cell>;
	}

	// PAYMENT APPLIED
	function getPaymentIdCellRenderer({ rowData }) {
		const linesData = [...payments.lines.data];
		const idx = _.findIndex(linesData, line => line.id === rowData.id);

		function onMouseEnter() {
			linesData[idx].PaymentIdfocused = true;
			mutate1ObjectApi('payments', {
				lines: { ...payments.lines, data: linesData },
			});
		}

		function onMouseLeave() {
			linesData[idx].PaymentIdfocused = false;
			mutate1ObjectApi('payments', {
				lines: { ...payments.lines, data: linesData },
			});
		}

		return (
			<Wrapper
				padding='0'
				height='40px'
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{rowData.updatingPaymentId ? (
					<LogoIcon spin button />
				) : selectedPayments.length === 1 &&
				  firstSelectedPayment?.state === 'open' &&
				  !firstSelectedPayment?.signed ? (
					rowData.PaymentIdfocused ? (
						rowData.payment_id ? (
							<Button
								width='100%'
								onClick={() =>
									updatePaymentIdLine(
										rowData.id,
										null,
										firstSelectedPayment.id,
										firstSelectedPayment.paycut_id,
										firstSelectedPayment.paid_to,
									)
								}
							>
								Quitar
							</Button>
						) : (
							<Button
								width='100%'
								onClick={() =>
									updatePaymentIdLine(
										rowData.id,
										firstSelectedPayment.id,
										firstSelectedPayment.id,
										firstSelectedPayment.paycut_id,
										firstSelectedPayment.paid_to,
									)
								}
							>
								Aplicar
							</Button>
						)
					) : rowData.payment_id ? (
						<div>{rowData.payment_id}</div>
					) : (
						<div>No</div>
					)
				) : rowData.payment_id ? (
					<div>{rowData.payment_id}</div>
				) : (
					<div>No</div>
				)}
			</Wrapper>
		);
	}

	return (
		<Wrapper padding='0' className='animated fadeIn'>
			<Spin
				spinning={payments.lines.loading}
				size='large'
				delay={50}
				tip={'Consultando Lineas...'}
			>
				<Table
					highBackground
					width={document.documentElement.clientWidth}
					height={document.documentElement.clientHeight - 115}
					headerHeight={44}
					rowHeight={25}
					rowCount={filteredPaymentLines.length}
					rowGetter={({ index }) => filteredPaymentLines[index]}
					rowRenderer={getRowRenderer}
				>
					<Column
						dataKey='index'
						label='#'
						width={50}
						headerRenderer={({ label }) => <Header>{label}</Header>}
						cellRenderer={getIndexCellRenderer}
					/>
					<Column
						dataKey='line'
						label='Linea'
						width={270}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='odt_id'
						label='Orden'
						width={110}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getOrderCellRenderer}
					/>
					<Column
						dataKey='description'
						label='Descripción'
						width={270}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='amount'
						label='Monto'
						width={100}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getAmountCellRenderer}
					/>
					<Column
						dataKey='tracts'
						label='Tracto'
						width={70}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
					/>
					<Column
						dataKey='apply_to_name'
						label='Corresponde'
						width={160}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='payment_id'
						label='Aplicado'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getPaymentIdCellRenderer}
					/>
					<Column
						dataKey='action_label'
						label='Acción'
						width={70}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='creator_name'
						label='Creador'
						width={160}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='entity_name'
						label='Entidad'
						width={160}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='created_at'
						label='Creado'
						width={130}
						headerRenderer={props => getHeaderRenderer(props, 'date')}
					/>
					<Column
						dataKey='updated_at'
						label='Actualizado'
						width={130}
						headerRenderer={props => getHeaderRenderer(props, 'date')}
					/>
				</Table>
			</Spin>
		</Wrapper>
	);
}

export default PaymentsLinesDesktop;
