// Scrapers
import XlsxOrderScraping from './XlsxOrderScraping';
import TxtOrderScraping from './TxtOrderScraping';

export default class OrderFieldScraping {
	constructor(
		{ xlsxOrders = [], txtOrders = [] },
		fields,
		formComponentValues,
	) {
		this.xlsxOrders = xlsxOrders;
		this.txtOrders = txtOrders;
		this.fields = fields;
		this.formComponentValues = formComponentValues;
	}

	async run() {
		const [scrapedXlsxOrders, scrapedTxtOrders] = await Promise.all([
			new XlsxOrderScraping(
				this.xlsxOrders,
				this.fields,
				this.formComponentValues,
			).run(),
			new TxtOrderScraping(
				this.txtOrders,
				this.fields,
				this.formComponentValues,
			).run(),
		]);

		return scrapedXlsxOrders.concat(scrapedTxtOrders);
	}
}
