import React from 'react';

const Icon = ({ fill, fillOpacity = '0.80', onClick, disabled }) => (
	<svg
		cursor={disabled ? 'not-allowed' : 'pointer'}
		onClick={onClick}
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='maskUploadIcon24'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='5'
			y='4'
			width='14'
			height='17'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9 11V17H15V11H19L12 4L5 11H9ZM5 19V21H19V19H5Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#maskUploadIcon24)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);

export default Icon;
