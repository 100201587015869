// Libs
import React from 'react';
import { Empty } from 'antd';
import { Redirect } from 'react-router-dom';
// Components
import { VirtualizedList } from 'screens/Utils';
import { Wrapper } from 'components';
// Utils
import GENERAL from 'utils/constants/general';
import { filterPayments } from 'utils/filters/Billings/payments';

const { ENV } = GENERAL;

function PaymentsManageMobile(props) {
	if (
		!Array.isArray(props?.paycuts?.selected?.items) ||
		props.paycuts.selected.items.length === 0
	) {
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_PAYCUTS_MOBILE} />;
	}

	const {
		// BILLINGS
		pays,
		mutate1Object,
		getPaymentResume,
		getPaymentOrders,
		getPaymentLines,
		// API
		payments,
		coins,
		// GENERAL
		profile,
	} = props;
	const allPayments = payments.pays.data;
	const filteredPays = filterPayments(allPayments, pays.filters);

	return (
		<Wrapper padding='8px' justifyContent='center' className='animated fadeIn'>
			{!payments.pays.loading && filteredPays.length === 0 ? (
				<Empty style={{ marginTop: '20px' }} />
			) : (
				<VirtualizedList
					cardId='payCard'
					listStyle={{
						height: document.documentElement.clientHeight,
						width: document.documentElement.clientWidth,
					}}
					rowHeight={220}
					spinTitle='Consultando Pagos...'
					isSpinning={payments.pays.loading}
					rows={filteredPays}
					customProps={{
						paysManage: pays,
						coins: coins.data,
						profile,
						getPaymentResume,
						getPaymentOrders,
						getPaymentLines,
						mutate1ObjectInBillings: mutate1Object,
					}}
				/>
			)}
		</Wrapper>
	);
}

export default PaymentsManageMobile;
