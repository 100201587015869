// Icons
import { lazy } from 'react';

const CheckIcon = lazy(() => import('components/Icons/CheckIcon'));
const CloseIcon = lazy(() => import('components/Icons/CloseIcon'));

const reactionKeys = {
	approval: 'approval',
	disapproval: 'disapproval',
};

export const reactionIcons = Object.freeze({
	[reactionKeys.approval]: CheckIcon,
	[reactionKeys.disapproval]: CloseIcon,
});

export default class ReactionBuilderConfig {
	static getReactionIcon(reactionKey) {
		return reactionIcons[reactionKey];
	}
}
