// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
// Components
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import { Wrapper, Logo } from 'components';
import useTheme from 'hooks/useTheme';
// Utils
import GENERAL from 'utils/constants/general';
// Actions
import * as actions from './actions';
// Styles
import './index.css';
import { PersonIcon } from 'components/Icons';

const styles = () => ({
	button: {
		top: '10px',
		fontFamily: 'Roboto',
	},
	textField: {
		width: '200px',
		minHeight: '48px',
		height: '48px',
		paddingLeft: '15px',
		backgroundColor: 'rgba(255,255,255,.07)',
		color: '#fff',
		marginBottom: '10px',
		fontFamily: 'Roboto',
	},
	icon: {
		color: 'rgba(255, 255, 255, 0.54)',
	},
});

const { ENV } = GENERAL;

const InputController = ({
	classes,
	handleOnChangeInput,
	user,
	showPassword,
	showNewPassword,
	newPassword,
	password,
	doShowPassword,
	showNewPasswordInput,
}) => {
	const { theme } = useTheme();

	return (
		<>
			<Input
				style={{ color: theme._mediumEmphasisColor }}
				required
				name='user'
				placeholder='Usuario*'
				className={classes.textField}
				onChange={handleOnChangeInput}
				value={user}
				type='text'
				endAdornment={
					<InputAdornment position='end'>
						<IconButton>
							<PersonIcon className={classes.icon} />
						</IconButton>
					</InputAdornment>
				}
			/>

			{/* PASSWORD */}
			<Input
				style={{ color: theme._mediumEmphasisColor }}
				required
				placeholder='Contraseña*'
				id='adornment-password'
				name='password'
				className={classes.textField}
				type={showPassword ? 'text' : 'password'}
				value={password}
				onChange={handleOnChangeInput}
				endAdornment={
					<InputAdornment position='end'>
						<IconButton
							onClick={() => doShowPassword('showPassword')}
							onMouseDown={event => event.preventDefault()}
						>
							{showPassword ? (
								<VisibilityOff className={classes.icon} />
							) : (
								<Visibility className={classes.icon} />
							)}
						</IconButton>
					</InputAdornment>
				}
			/>

			{/* NEW PASSWORD */}
			{showNewPasswordInput && (
				<Input
					style={{ color: theme._mediumEmphasisColor }}
					required
					placeholder='Nueva contraseña*'
					id='adornment-newPassword'
					name='newPassword'
					className={classes.textField}
					type={showNewPassword ? 'text' : 'password'}
					value={newPassword}
					onChange={handleOnChangeInput}
					endAdornment={
						<InputAdornment position='end'>
							<IconButton
								onClick={() => doShowPassword('showNewPassword')}
								onMouseDown={event => event.preventDefault()}
							>
								{showNewPassword ? (
									<VisibilityOff className={classes.icon} />
								) : (
									<Visibility className={classes.icon} />
								)}
							</IconButton>
						</InputAdornment>
					}
				/>
			)}
		</>
	);
};

const LoginButton = ({
	user,
	password,
	newPassword,
	logged,
	doLogin,
	loading,
}) => {
	const { theme } = useTheme();
	return loading ? (
		<div style={{ minWidth: 24, textAlign: 'center' }}>
			<CircularProgress variant='indeterminate' size={24} thickness={4} />
		</div>
	) : (
		<div className='container-login-form-action-buttons'>
			<Button
				type='button'
				onClick={() => {
					doLogin(user, password, newPassword);
				}}
				variant={logged !== undefined && !logged ? 'contained' : 'outlined'}
				color={logged !== undefined && !logged ? 'secondary' : 'primary'}
			>
				<span style={{ color: theme._primaryColor }}>Entrar</span>
			</Button>
			<div style={{ marginTop: '10px', textAlign: 'center' }}>
				<span style={{ color: theme._primaryColor, marginRight: '7px' }}>
					¿No tiene cuenta?
				</span>
				<Link to={ENV.ROUTES.PATHS.SIGNUP}>Registrarme</Link>
			</div>
			<div style={{ marginTop: '10px', textAlign: 'center' }}>
				<span style={{ color: theme._primaryColor, marginRight: '7px' }}>
					¿No recuerda la contraseña?
				</span>
				<Link to={ENV.ROUTES.PATHS.RECOVERY_PASSWORD}>Recuperar</Link>
			</div>
		</div>
	);
};

class Login extends Component {
	constructor(...props) {
		super(...props);
	}

	/** ****************** JSX RENDER ********************/
	render() {
		const {
			classes,
			user,
			password,
			showPassword,
			logged,
			handleOnChangeInput,
			doShowPassword,
			doLogin,
			showNewPasswordInput,
			newPassword = '',
			showNewPassword,
			loading,
		} = this.props;

		return (
			<Wrapper
				className='animated fadeIn'
				width='100%'
				padding='0'
				position='relative'
				justifyContent='center'
			>
				<Wrapper
					position='relative'
					display='flex'
					flexDirection='column'
					padding='4em 1.5em 1.5em 1.5em'
					maxWidth='370px'
					height='300px'
					borderRadius='25px'
					boxShadow='0 8px 16px 0 rgb(0 0 0)'
					mediumBackground
					margin='0 10%'
				>
					{/* LOGO */}
					<Logo position='absolute' top='-55px' opacity='.8' />

					{/* USER */}

					<InputController
						classes={classes}
						handleOnChangeInput={handleOnChangeInput}
						user={user}
						showPassword={showPassword}
						showNewPassword={showNewPassword}
						newPassword={newPassword}
						password={password}
						doShowPassword={doShowPassword}
						showNewPasswordInput={showNewPasswordInput}
					/>

					{/* LOGIN-BUTTON */}
					<LoginButton
						user={user}
						password={password}
						newPassword={newPassword}
						logged={logged}
						doLogin={doLogin}
						loading={loading}
					/>
				</Wrapper>
			</Wrapper>
		);
	}
}

/** ****************** PROPTYPES ********************/
Login.propTypes = {
	// Props
	classes: PropTypes.object.isRequired,
	user: PropTypes.string.isRequired,
	password: PropTypes.string.isRequired,
	showPassword: PropTypes.bool,
	logged: PropTypes.bool,
	showNewPasswordInput: PropTypes.bool,
	newPassword: PropTypes.string,
	showNewPassword: PropTypes.bool,
	// Functions
	handleOnChangeInput: PropTypes.func.isRequired,
	doShowPassword: PropTypes.func.isRequired,
	doLogin: PropTypes.func.isRequired,
	doReset: PropTypes.func.isRequired,
};

/** ****************** EXPORT ********************/
export default connect(
	state => ({
		user: state.login.user,
		password: state.login.password,
		logged: state.login.logged,
		loading: state.login.loading,
		showPassword: state.login.showPassword,
		showNewPasswordInput: state.login.showNewPasswordInput,
		newPassword: state.login.newPassword,
		showNewPassword: state.login.showNewPassword,
	}),
	actions,
)(withStyles(styles)(withTheme(Login)));
