// Libs
import React from 'react';
import { Card } from 'antd';
// Utils
import { formatNumber } from '../../../../../../utils/libs';
import { getPaymentColorState } from '../../../../../Billings/utils';
// Styles
import './PaycutResumeCard.css';

const { Meta } = Card;

export default function PaycutResumeCard(props) {
	const {
		name,
		name_billing,
		payment_name,
		orders_count,
		production,
		credits,
		discounts,
		iva,
		subtotal,
		subtotal_with_iva,
		total,
		state,
		customProps,
	} = props;
	const { coin_symbol } = customProps;
	const productionFormatted = formatNumber.new(production, coin_symbol, 2);
	const creditsFormatted = formatNumber.new(credits, coin_symbol, 2);
	const discountsFormatted = formatNumber.new(discounts, coin_symbol, 2);
	const subtotalFormatted = formatNumber.new(subtotal, coin_symbol, 2);
	const totalTaxes = formatNumber.new(subtotal_with_iva, coin_symbol, 2);
	const totalFormatted = formatNumber.new(total, coin_symbol, 2);

	return (
		<div>
			<div
				className='pay-flag-payment-state'
				style={getPaymentColorState(state)}
			/>
			<Meta
				id='paycut_resume_meta-card-container'
				title={
					<div className='paycut_resume-container-title-card'>
						<span className='paycut_resume-card-document'>
							{payment_name || name_billing || name}
						</span>
					</div>
				}
				description={
					<div>
						<div className='paycut_resume-container-data-input'>
							<div className='paycut_resume-data'>
								<div className='paycut_resume-body-container-item'>
									<div className='paycut_resume-body-concept-item'>
										Ordenes: <b>{orders_count}</b>
									</div>
								</div>
								<div className='paycut_resume-body-container-item'>
									<div className='paycut_resume-body-concept-item'>
										Producción: <b>{productionFormatted}</b>
									</div>
								</div>
								<div className='paycut_resume-body-container-item'>
									<div className='paycut_resume-body-concept-item'>
										Créditos: <b>{creditsFormatted}</b>
									</div>
								</div>
								<div className='paycut_resume-body-container-item'>
									<div className='paycut_resume-body-concept-item'>
										Débitos: <b>{discountsFormatted}</b>
									</div>
								</div>
								<div className='paycut_resume-body-container-item'>
									<div className='paycut_resume-body-concept-item'>
										Subtotal: <b>{subtotalFormatted}</b>
									</div>
								</div>
								<div className='paycut_resume-body-container-item'>
									<div className='paycut_resume-body-concept-item'>
										IVA: <b>{iva}</b>
									</div>
								</div>
								<div className='paycut_resume-body-container-item'>
									<div className='paycut_resume-body-concept-item'>
										Impuestos: <b>{totalTaxes}</b>
									</div>
								</div>
								<div className='paycut_resume-body-container-item'>
									<div className='paycut_resume-body-concept-item-total'>
										Total a Pagar: <b>{totalFormatted}</b>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
}
