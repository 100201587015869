// Libs
import React from 'react';
// Components
import Wrapper from './Wrapper/Wrapper';
import InBuildingIMG from 'assets/media/img/inBuilding.svg';

export default function InBuilding() {
	return (
		<Wrapper flexDirection='column'>
			<img style={{ width: '300px' }} src={InBuildingIMG} alt='' />
			<span style={{ color: 'white' }}>En construcción</span>
		</Wrapper>
	);
}
