// Utils
import { xlsx, convertFileToBase64 } from 'utils/libs';
// Services
import { ReportsApiService } from 'services';

const FILE_TYPES = {
	TXT: 'txt',
	XLSX: 'xlsx',
	PDF: 'pdf',
};

const MIME_TYPES = {
	'text/plain': FILE_TYPES.TXT,
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
		FILE_TYPES.XLSX,
	'application/pdf': FILE_TYPES.PDF,
};

const getFiletypeFromMimetype = file => {
	if (!file) return;
	return MIME_TYPES[file.type];
};

const readTxtFile = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = e => {
			const text = e.target.result;
			resolve(text);
		};
		reader.onerror = err => {
			reject(null);
		};
		reader.readAsText(file);
	});

const processTxtOrders = (txtFileData, boundary) =>
	txtFileData
		.filter(data => !!data?.trim())
		.reduce((acc, data) => [...acc, ...data.split(boundary)], [])
		.filter(data => !!data?.trim());

const processXlsxOrders = xlsxFileData =>
	xlsxFileData
		.filter(data => !!data)
		.reduce((acc, data) => [...acc, ...data], []);

export default class ReadOrderFromFiles {
	constructor(files, boundary) {
		this.files = files;
		this.boundary = boundary;
		this.txtPromises = [];
		this.xlsxPromises = [];
	}

	async run() {
		this.readXlsx();
		this.readTxt();
		await this.readPdf();

		return {
			xlsxOrders: await Promise.all(this.xlsxPromises).then(xlsxFileData =>
				processXlsxOrders(xlsxFileData),
			),
			txtOrders: await Promise.all(this.txtPromises).then(txtFileData =>
				processTxtOrders(txtFileData, this.boundary),
			),
		};
	}

	readXlsx() {
		this.files.map(file => {
			if (getFiletypeFromMimetype(file) !== FILE_TYPES.XLSX) return;
			this.xlsxPromises.push(xlsx.readExcelFile(file));
		});
	}

	readTxt() {
		this.files.map(file => {
			if (getFiletypeFromMimetype(file) !== FILE_TYPES.TXT) return;
			this.txtPromises.push(readTxtFile(file));
		});
	}

	async readPdf() {
		const atLeastOnePdfFound = this.files.find(
			file => getFiletypeFromMimetype(file) === FILE_TYPES.PDF,
		);
		if (!atLeastOnePdfFound) return;

		const base64FilePromises = [];

		this.files.map(file => {
			if (getFiletypeFromMimetype(file) !== FILE_TYPES.PDF) return;
			base64FilePromises.push(convertFileToBase64(file));
		});

		const base64Files = await Promise.all(base64FilePromises);

		this.txtPromises.push(
			new ReportsApiService()
				.post({
					url: '/pdf/convertPdfsToTxt',
					data: {
						boundary: this.boundary,
						base64Files,
					},
				})
				.then(async ({reportUrl: fileBuffer}) => {
					const text = Buffer.from(fileBuffer).toString();
					return text;
				})
				.catch(err => null),
		);
	}
}
