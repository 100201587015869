// Libs
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
// Utils
import GENERAL from 'utils/constants/general';
// Selectors
import { selectCoins, selectDepartments } from 'store/api/selectors';
// Components
import { OrdersManage, ConflictsImporting } from './Orders';
import {
	Wrapper,
	Span,
	Radio,
	Button,
	RefreshIcon,
	LogoIcon,
	DeleteIcon,
	FiltersIcon,
} from 'components';
import {
	// PAYMENTS
	PaymentPaycuts,
	PaymentPays,
	PaymentOrders,
	PaymentLines,
	// INVOICES
	InvoicePays,
} from './Billings';
import {
	WarehousesList,
	StockArticles,
	StockSeries,
	ControlSeries,
	TransactionsInventoryToTransfer,
	ItemsTransactionDoc,
	Reception,
} from './Warehouses';
import Analytics from './Analytics';
import Supervisor from './Supervisor';
// Styles
import './index.css';

const { ENV, DIVISION_ID } = GENERAL;

// ----------------------------------------------- JSX RENDER COMPONENTS ------------------------------------------------
const UsersSubToolbar = ({
	users,
	control,
	mutate1ObjectInUsers,
	getOrganizationUsers,
}) => {
	const theme = useTheme();

	return (
		<Wrapper
			height='48px'
			justifyContent='space-between'
			padding='10px 0 10px 10px'
			mediumBackground
		>
			{control.selectedUsers.length > 0 ? (
				<div className='subtoolbar-content'>
					<div className='text-users-selected'>
						<Span fontWeight='bold' color={theme.fonts.colors.default}>
							{control.selectedUsers.length} Usuario(s) seleccionado(s)
						</Span>
					</div>
					<div>
						<Button
							style={{ marginRight: '10px', height: '30px' }}
							onClick={() =>
								mutate1ObjectInUsers('setUserConfigurationModal', {
									isOpen: true,
								})
							}
						>
							Configurar
						</Button>
					</div>
				</div>
			) : (
				<div className='subtoolbar-content'>
					<Radio.Group
						value={control.filterView}
						onChange={e =>
							mutate1ObjectInUsers('control', { filterView: e.target.value })
						}
					>
						<Radio.Button value='active'>Activos</Radio.Button>
						<Radio.Button value='inactive'>Inactivos</Radio.Button>
						<Radio.Button value='applicants'>Solicitantes</Radio.Button>
						<Radio.Button value='rejected'>Rechazados</Radio.Button>
					</Radio.Group>
					<div>
						{!users.loading ? (
							<RefreshIcon onClick={() => getOrganizationUsers()} />
						) : (
							<LogoIcon button spin />
						)}
					</div>
				</div>
			)}
		</Wrapper>
	);
};

const ArticlesSubToolbar = props => {
	const { articles, getArticles } = props;
	return (
		<Wrapper
			padding='0'
			width='100%'
			mediumBackground
			justifyContent='flex-end'
		>
			{!articles.loading ? (
				<RefreshIcon button onClick={getArticles} />
			) : (
				<LogoIcon button spin />
			)}
		</Wrapper>
	);
};

const TransactionsSubToolbar = ({
	profile,
	getTransactionDocs,
	transactions,
	transactionsManage,
	getTransactionsModal,
	mutate1ObjectInWarehouses,
	signWTDModal,
	signWTD,
	deleteTransaction,
	history,
}) => {
	const transaction = transactions.data.find(
		transaction => transaction.id === transactionsManage.docId,
	);
	const showCompleteButton =
		transaction &&
		!transaction.completed &&
		transaction.dwh_owner_division_id === DIVISION_ID.ENTITY && // Is the owner an entity?
		transaction.dwh_owner_id === profile.entity.id; // Is my entity the owner of the destination warehouse?

	return (
		<Wrapper
			padding='0'
			justifyContent='flex-end'
			width='100%'
			height='48px'
			mediumBackground
		>
			<div className='transactions-subtoolbar-content'>
				<div />
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{history.location.pathname ===
						ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_DESKTOP &&
						transaction &&
						!signWTDModal.loading && (
							<Button
								margin='0 10px 0 0'
								onClick={() =>
									mutate1ObjectInWarehouses('signWTDModal', { isOpen: true })
								}
							>
								Firmas
							</Button>
						)}

					{history.location.pathname ===
						ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_DESKTOP &&
						showCompleteButton &&
						(signWTDModal.loading ? (
							<LogoIcon spin={true} margin='0 10px 0 0' />
						) : (
							<Button
								margin='0 10px 0 0'
								onClick={() =>
									signWTD(
										transaction.id,
										transaction.signer_id,
										true,
										'transaction',
									)
								}
							>
								Completar
							</Button>
						))}

					{history.location.pathname ===
						ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_DESKTOP &&
						transaction &&
						!transaction.completed &&
						transaction.created_by === profile.user.id && (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{transactionsManage.deleting ? (
									<LogoIcon button spin />
								) : (
									<DeleteIcon
										onClick={() => deleteTransaction(transaction.id)}
									/>
								)}
							</div>
						)}

					{!transactions.loading ? (
						<RefreshIcon
							button
							onClick={() => getTransactionDocs(getTransactionsModal)}
						/>
					) : (
						<LogoIcon button spin />
					)}

					<FiltersIcon
						button
						onClick={() =>
							mutate1ObjectInWarehouses('getTransactionsModal', {
								isOpen: true,
								mode: 'pending',
							})
						}
					/>
				</div>
			</div>
		</Wrapper>
	);
};

function SubToolbar(props) {
	const {
		modeView,
		history,
		api,
		users,
		orders,
		payments,
		warehouses,
		profile,
		mutate1ObjectInUsers,
		mutate1ObjectInOrders,
		mutate1ObjectInBillings,
		mutate1ObjectInWarehouses,
		mutate1ObjectApi,
		getControlOrders,
		getPaycuts,
		getPayments,
		getInvoicePays,
		getPaymentResume,
		getPaycutResume,
		getPaymentLines,
		getPaymentOrders,
		getWarehouses,
		getArticles,
		getStockArticles,
		getStockSeries,
		resetAdjustArticles,
		getInventoryToTransfer,
		getTransactionDocs,
		getItemsTransactionDocs,
		updatePaymentOrders,
		getOrganizationUsers,
		getCountOrdersToReassignment,
		updateOrdersSort,
		sendTransferTransaction,
		sendToast,
		signWTD,
		updateTransaction,
		deleteTransaction,
		onResetEnterNewOrders,
		// Props
		coins,
		departments,
	} = props;
	const location = useLocation();
	const { id, title } = React.useMemo(() => {
		let id, title;
		for (const path in ENV.ROUTES.PATHS) {
			if (location.pathname === ENV.ROUTES.PATHS[path]) {
				title = ENV.ROUTES.TITLES[path];
				id = ENV.ROUTES.IDS[path];
				return {
					id,
					title,
				};
			}
		}
		return { id, title };
	}, [location]);

	switch (id) {
		case ENV.ROUTES.IDS.USERS:
			return (
				<UsersSubToolbar
					users={api.users}
					control={users.control}
					mutate1ObjectInUsers={mutate1ObjectInUsers}
					getOrganizationUsers={getOrganizationUsers}
				/>
			);
		case ENV.ROUTES.IDS.COMMAND_CENTER: {
			return <GeolocationSubToolbar />;
		}
		case ENV.ROUTES.IDS.ORDERS:
			return (
				<OrdersManage
					users={api.users.data}
					gains={api.gains}
					orders={orders}
					mutate1ObjectInOrders={mutate1ObjectInOrders}
					getControlOrders={getControlOrders}
					getCountOrdersToReassignment={getCountOrdersToReassignment}
					allProjects={departments.data}
					profile={profile}
				/>
			);
		case ENV.ROUTES.IDS.CONFLICT_IMPORTED_ORDERS:
			return (
				<ConflictsImporting
					ordersManage={orders}
					mutate1ObjectInOrders={mutate1ObjectInOrders}
					onResetEnterNewOrders={onResetEnterNewOrders}
					templates={api.templates}
				/>
			);
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_PAYCUTS_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_PAYCUTS_DESKTOP:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_PAYCUTS_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_PAYCUTS_DESKTOP:
			return (
				<PaymentPaycuts
					paymentsApi={api.payments}
					payments={payments}
					history={history}
					mutate1ObjectInBillings={mutate1ObjectInBillings}
					getPaycutResume={getPaycutResume}
					getPaycuts={getPaycuts}
					getPayments={getPayments}
					getInvoicePays={getInvoicePays}
				/>
			);
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_PAYS_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_PAYS_DESKTOP:
			return (
				<PaymentPays
					mutate1ObjectInBillings={mutate1ObjectInBillings}
					payments={api.payments}
					pays={payments.pays}
					paycuts={payments.paycuts}
					getPayments={getPayments}
					getPaymentResume={getPaymentResume}
					modeView={modeView}
				/>
			);
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_PAYS_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_PAYS_DESKTOP:
			return (
				<InvoicePays
					billingsState={payments}
					api={api}
					modeView={modeView}
					getPaymentResume={getPaymentResume}
					mutate1ObjectInBillings={mutate1ObjectInBillings}
					getInvoicePays={getInvoicePays}
				/>
			);
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_LINES_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_LINES_DESKTOP:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_LINES_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_LINES_DESKTOP:
			return (
				<PaymentLines
					payments={api.payments}
					paysManage={payments.pays}
					getPaymentLines={getPaymentLines}
				/>
			);
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_ORDERS_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_ORDERS_DESKTOP:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_ORDERS_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_ORDERS_DESKTOP:
			return (
				<PaymentOrders
					api={api}
					billingsManage={payments}
					getPaymentOrders={getPaymentOrders}
					mutate1ObjectInBillings={mutate1ObjectInBillings}
					updatePaymentOrders={updatePaymentOrders}
					profile={profile}
					coins={coins.data}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_MANAGE_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_MANAGE_DESKTOP:
			return (
				<WarehousesList
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					showPopoverMode={warehouses.control.showPopoverMode}
					getWarehouses={getWarehouses}
					warehouses={api.warehouses}
					stock={api.stock}
					control={warehouses.control}
					mutate1ObjectApi={mutate1ObjectApi}
					getStockArticles={getStockArticles}
					profile={profile}
					modeView={modeView}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_STOCK_ARTICLES_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_STOCK_ARTICLES_DESKTOP:
			return (
				<StockArticles
					getStockArticles={getStockArticles}
					stock={api.stock}
					control={warehouses.control}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					stockArticlesManage={warehouses.stockArticlesManage}
					resetAdjustArticles={resetAdjustArticles}
					modeView={modeView}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_STOCK_SERIES_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_STOCK_SERIES_DESKTOP:
			return (
				<StockSeries
					getStockSeries={getStockSeries}
					stock={api.stock}
					control={warehouses.control}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_CONTROL_SERIES_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_CONTROL_SERIES_DESKTOP:
			return (
				<ControlSeries
					controlSeries={warehouses.controlSeries}
					stock={api.stock}
					itemStates={api.itemStates}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					mutate1ObjectApi={mutate1ObjectApi}
					modeView={modeView}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_ARTICLES_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_ARTICLES_DESKTOP:
			return (
				<ArticlesSubToolbar articles={api.articles} getArticles={getArticles} />
			);
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_DESKTOP:
			return (
				<TransactionsSubToolbar
					profile={profile}
					transactions={api.transactions}
					transactionsManage={warehouses.transactionsManage}
					getTransactionDocs={getTransactionDocs}
					getTransactionsModal={warehouses.getTransactionsModal}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					signWTDModal={warehouses.signWTDModal}
					signWTD={signWTD}
					deleteTransaction={deleteTransaction}
					history={history}
				/>
			);
		// Transfer
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_INVENTORY_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_INVENTORY_DESKTOP:
		// New entry
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_DESKTOP:
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_MOBILE:
			return (
				<TransactionsInventoryToTransfer
					inventory={api.transactions.inventory}
					createTransaction={warehouses.createTransaction}
					createNewEntryItem={warehouses.createNewEntryItem}
					createPackage={warehouses.createPackage}
					getInventoryToTransfer={getInventoryToTransfer}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					sendTransferTransaction={sendTransferTransaction}
					sendToast={sendToast}
					inventoryToTransferManage={warehouses.inventoryToTransferManage}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_ITEMS_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP:
			return (
				<ItemsTransactionDoc
					items={api.transactions.items}
					createPackage={warehouses.createPackage}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					mutate1ObjectApi={mutate1ObjectApi}
					updateTransaction={updateTransaction}
					sendToast={sendToast}
					itemsTransactionDoc={warehouses.itemsTransactionDoc}
					getItemsTransactionDocs={getItemsTransactionDocs}
					transactionsManage={warehouses.transactionsManage}
					transactions={api.transactions}
					profile={profile}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_RECEPTION:
			return (
				<Reception
					inventoryReception={warehouses.inventoryReception}
					warehouseZones={api.warehouseZones}
					warehouseAreas={api.warehouseAreas}
					warehousePackages={api.warehousePackages}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
				/>
			);
		case ENV.ROUTES.IDS.ANALYTICS: {
			return <Analytics />;
		}
		case ENV.ROUTES.IDS.SUPERVISOR: {
			return <Supervisor />;
		}
		default:
			return <div />;
	}
}
const mapStateToProps = state => ({
	coins: selectCoins(state),
	departments: selectDepartments(state),
});
export default connect(mapStateToProps)(SubToolbar);
