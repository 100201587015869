// Libs
import React, { Suspense, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
// Context
import { ElementViewBuilderContext } from 'core/context';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Drawer } from 'components';
// Config
import ElementViewBuilderConfig from './elementView.config';

const ElementViewLocalSwitcher = ({ isMobile }) => {
	const { theme, isDarkTheme } = useTheme();
	const { payload, cleanElementView } = useContext(ElementViewBuilderContext);

	const ElementViewComponent =
		ElementViewBuilderConfig.getAuthorizedElementViewReactNode(
			payload.elementViewKey,
		);
	if (!ElementViewComponent) return null;

	const elementViewConfig =
		ElementViewBuilderConfig.getAuthorizedElementViewConfig(
			payload.elementViewKey,
		);

	return (
		<Drawer
			key='elementViewContainer'
			className='animated fadeIn faster'
			height='100%'
			width='100%'
			backgroundColor={!isDarkTheme && theme._secondaryBackgroundColor}
			$maxWidth={!isMobile ? elementViewConfig.maxWidth : '100%'}
			$noOverflow={elementViewConfig.noOverflow}
			placement={elementViewConfig.placement || 'right'}
			closable={false}
			onClose={cleanElementView}
			open={true}
		>
			<ElementViewComponent isMobile={isMobile} />
		</Drawer>
	);
};

const ElementViewPathSwitcher = ({ isMobile }) => {
	const elementViews =
		ElementViewBuilderConfig.getAuthorizedElementViewReactNodes();

	return (
		<Switch>
			{Object.keys(elementViews).map(path => {
				const ElementViewComponent = elementViews[path];
				if (!ElementViewComponent) return null;

				return (
					<Route
						exact
						key={path}
						path={path}
						children={<ElementViewComponent isMobile={isMobile} />}
					/>
				);
			})}
		</Switch>
	);
};

const ElementViewBuilder = ({ shortcutPath, isMobile, elementKey }) => {
	if (shortcutPath) return <ElementViewPathSwitcher isMobile={isMobile} />;
	return <ElementViewLocalSwitcher isMobile={isMobile} />;
};

export default ElementViewBuilder;
