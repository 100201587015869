// Libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'utils/libs/dateFormats';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Components
import CopyToClipboard from 'react-copy-to-clipboard';
import {
	Wrapper,
	ShowMoreText,
	Acronym,
	Subtitle,
	Span,
	PointTextIcon,
} from 'components';
// Styles
import styles from './Supervisor.module.css';

export const DataInfo = ({ data, maxWidth = '150px' }) => {
	const dispatch = useDispatch();
	return (
		<CopyToClipboard
			text={data}
			onCopy={() => dispatch(sendToast({ message: 'Texto copiado' }))}
		>
			<Span maxWidth={maxWidth} hoverable ellipsis disabled>
				{data}
			</Span>
		</CopyToClipboard>
	);
};

const PostInfo = ({ record }) => {
	const profile = useSelector(selectProfile);

	return (
		<div className={styles.postInfo}>
			<Acronym text={record.userName} />
			<div style={{ paddingLeft: '10px' }}>
				<Subtitle>{record.userName}</Subtitle>
				<Wrapper flexWrap={'wrap'} padding='0' alignItems='baseline'>
					<DataInfo data={record.odtId} />
					<PointTextIcon />
					<DataInfo data={record.clientIdc} />
					{String(record.clientName).toLowerCase() !==
						String(record.clientIdc).toLowerCase() && (
						<>
							<PointTextIcon />
							<DataInfo data={record.clientName} />
						</>
					)}
					<PointTextIcon />
					<DataInfo data={record.serviceName} />
					<PointTextIcon />
					<Span disabled>
						{formatDate(record.createdAt, profile, 'ddd DD/MM')}
					</Span>
				</Wrapper>
			</div>
		</div>
	);
};

const UserComment = ({ text, showLines = 1 }) => {
	const dispatch = useDispatch();

	if (!text) return null;

	const limitText = 430;
	const ellipsis = text.length > limitText ? '...' : '';

	return (
		<ShowMoreText lines={showLines} onClick={() => console.log()}>
			<CopyToClipboard
				text={text}
				onCopy={() => dispatch(sendToast({ message: 'Texto copiado' }))}
			>
				<Span hoverable disabled>
					{`${text.slice(0, limitText)}${ellipsis}`}
				</Span>
			</CopyToClipboard>
		</ShowMoreText>
	);
};

export { PostInfo, UserComment };
