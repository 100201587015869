// Libs
import React from 'react';
import { useSelector } from 'react-redux';
// Utils
import { CollectorDynamics } from 'components/Collector/utils';
import CollectorUtils from 'components/Collector/CollectorUtils';
import ConsumeIntegrationUtils from '../ConsumeInventory/IntegrationUtils';
import ConsumeInventory from '../ConsumeInventory/ConsumeInventory';
import ConsumeInventoryUtils from '../ConsumeInventory/ConsumeInventoryUtils';
// Selectors
import {
	selectConsumedItems,
	selectToInvoiceInventory,
	selectToInvoiceSerializedInventory,
	selectToUninstallInventory,
} from './selectors';

const CollectorEcosystemIntegration = React.memo(
	({
		docId,
		order,
		collector,
		collectorValues,
		collectorEcosystemAutoSyncRunning,
		onCollectorValuesChange,
	}) => {
		const consumedItems = useSelector(selectConsumedItems);
		const toInvoiceInventory = useSelector(selectToInvoiceInventory);
		const toInvoiceSerializedInventory = useSelector(
			selectToInvoiceSerializedInventory,
		);
		const toUninstallInventory = useSelector(selectToUninstallInventory);
		const [consumedItemValues, setConsumedItemValues] = React.useState({});

		const getConsumedItemValues = () =>
			collectorValues.reduce((acc, cv) => {
				if (
					CollectorUtils.checkIsSuccessInventoryCollectorValue(cv) &&
					CollectorDynamics.getReferencedCollector(collector, cv)
				) {
					const { key, value } =
						ConsumeIntegrationUtils.getControlledConsumedItemFormat(
							cv.inventoryItemId,
							cv.inventorySerieId,
							cv.inventoryAmount,
							cv.inventoryInitialAmount,
							cv.inventoryFinalAmount,
						);
					acc[key] = value;
				}
				return acc;
			}, {});

		React.useEffect(() => {
			setConsumedItemValues(getConsumedItemValues());
		}, []);

		const totalConsumedItemValues = React.useMemo(() => {
			if (collectorEcosystemAutoSyncRunning) return {};

			const successInventoryCollectorValues =
				CollectorUtils.getSuccessInventoryCollectorValues(collectorValues);
			if (!successInventoryCollectorValues.length) return {};

			return Object.keys(getConsumedItemValues()).reduce(
				(totalValues, consumedItemKey) => {
					const [searchedItemId, searchedSerieId] =
						ConsumeIntegrationUtils.getSplittedKey(consumedItemKey);

					totalValues[consumedItemKey] = successInventoryCollectorValues.reduce(
						(totalValue, cv) => {
							if (
								ConsumeIntegrationUtils.checkIsInventoryItem({
									checking: 'isDifferent',
									searchedItemId,
									searchedSerieId,
									inventoryItemId: cv.inventoryItemId,
									inventorySerieId: cv.inventorySerieId,
								})
							)
								return totalValue;

							const totalAmount =
								Number(ConsumeIntegrationUtils.getSplittedKey(totalValue)[1]) ||
								0;

							totalValue =
								ConsumeIntegrationUtils.getControlledConsumedItemFormat(
									cv.inventoryItemId,
									cv.inventorySerieId,
									totalAmount + Number(cv.inventoryAmount),
									cv.inventoryInitialAmount,
									cv.inventoryFinalAmount,
								).value;
							return totalValue;
						},
						'',
					);

					return totalValues;
				},
				{},
			);
		}, [collectorEcosystemAutoSyncRunning, collectorValues]);

		const consumedItemsResume = React.useMemo(() => {
			if (!Object.keys(consumedItemValues).length)
				return {
					articles: [],
					serialized: [],
					uninstallSerialized: [],
				};

			const _consumedItems = Object.keys(consumedItemValues).reduce(
				(acc, consumedItemKey) => {
					const [itemId, serieId] =
						ConsumeIntegrationUtils.getSplittedKey(consumedItemKey);
					const amount = ConsumeIntegrationUtils.getSplittedKey(
						consumedItemValues[consumedItemKey],
					)[1];
					const consumedItem = consumedItems.find(item =>
						serieId
							? Number(item.serieId) === Number(serieId)
							: Number(item.itemId) === Number(itemId),
					);
					if (consumedItem && amount)
						acc.push({ ...consumedItem, value: amount });
					return acc;
				},
				[],
			);

			return ConsumeInventoryUtils.getConsumedItemsResume(
				order.order_id,
				false,
				_consumedItems,
				toInvoiceInventory
					.concat(toInvoiceSerializedInventory)
					.concat(toUninstallInventory),
			);
		}, [
			consumedItemValues,
			order.order_id,
			consumedItems,
			toInvoiceInventory,
			toInvoiceSerializedInventory,
			toUninstallInventory,
		]);

		// Handlers
		const onConsumedItemChange = ({
			itemId,
			serieId,
			amount,
			initialAmount,
			finalAmount,
		}) => {
			const { key, value } =
				ConsumeIntegrationUtils.getControlledConsumedItemFormat(
					itemId,
					serieId,
					amount,
					initialAmount,
					finalAmount,
				);
			setConsumedItemValues(current => ({ ...current, [key]: value }));
			onCollectorValuesChange({
				value: 'inventory',
				collectorProps: CollectorUtils.getCollectorProps(
					{ order_id: order.order_id, docId },
					collector,
					{ itemId, serieId, amount, initialAmount, finalAmount },
				),
			});
		};

		return (
			<ConsumeInventory
				order={order}
				collectorEcosystemIntegration={{
					controlledSyncRunning: collectorEcosystemAutoSyncRunning,
					controlledInventoryFilter: collector.inventoryFilter,
					controlledItemValues: consumedItemValues,
					controlledTotalItemValues: totalConsumedItemValues,
					controlledConsumedItemsResume: consumedItemsResume,
					onControlledConsumedItemChange: onConsumedItemChange,
				}}
			/>
		);
	},
);

export default CollectorEcosystemIntegration;
