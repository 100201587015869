// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M1.33325 7.99998C1.33325 4.31998 4.31992 1.33331 7.99992 1.33331C11.6799 1.33331 14.6666 4.31998 14.6666 7.99998C14.6666 11.68 11.6799 14.6666 7.99992 14.6666C4.31992 14.6666 1.33325 11.68 1.33325 7.99998ZM2.66659 7.99998C2.66659 10.9466 5.05325 13.3333 7.99992 13.3333C10.9466 13.3333 13.3333 10.9466 13.3333 7.99998C13.3333 5.05331 10.9466 2.66665 7.99992 2.66665C5.05325 2.66665 2.66659 5.05331 2.66659 7.99998Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
