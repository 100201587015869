// Services
import ApiServiceV2 from 'services/apiService/apiServiceV2';

const endpoint = '/warehouses_transactions_docs';
const useWarehouseTransactionDocApi = () => {
	return {
		/**
		 * Get materials from billed order
		 * @param {int} orderId
		 * @returns Object[]
		 */
		getBillingMaterials: orderId =>
			ApiServiceV2.get({
				url: `${endpoint}/getBillingMaterials/${orderId}`,
			}),
	};
};

export default useWarehouseTransactionDocApi;
