import styled from 'styled-components';

const InventoryContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	color: ${props => props.theme._inactiveColor};
	background-color: ${props => props.theme._secondaryBackgroundColor};
	padding: 20px;
	width: 100%;

	.ant-table {
		background-color: ${props => props.theme._primaryBackgroundColor};
	}

	.ant-table-thead > tr > th {
		background-color: ${props => props.theme._primaryBackgroundColor};
		color: ${props => props.theme._primaryColor};
	}

	.anticon {
		color: ${props => props.theme._primaryColor};
	}

	.ant-pagination-item a {
		color: ${props => props.theme._primaryColor};
	}

	.ant-table-column-sort {
		background: ${props => props.theme._surfaceBackgroundColor};
	}

	.ant-table-tbody
		> tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
		> td,
	.ant-table-tbody > tr > td.ant-table-cell-row-hover,
	.ant-table-thead
		> tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
		> td {
		background: ${props => props.theme._transparentBackgroundColor};
		color: ${props => props.theme._borderColor};
	}

	.ant-pagination-item-active {
		background: ${props => props.theme._secondaryBackgroundColor};
		border-color: ${props => props.theme._primaryColor};
		a {
			color: ${props => props.theme._primaryColor};
		}
	}
`;

export default InventoryContainer;
