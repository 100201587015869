// Libs
import React from 'react';
import styled, { css } from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';

const Button = styled(IconButton)`
	${props =>
		props.shadow &&
		css`
			-webkit-box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
			box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
		`}
`;

const StreamIcon = ({ button, onClick, shadow, selected }) => {
	const { theme } = useTheme();

	const fill = React.useMemo(
		() => (selected ? theme._highEmphasisColor : theme._mediumEmphasisColor),
		[selected],
	);

	if (button) {
		return (
			<Button shadow={shadow} onClick={onClick}>
				<Icon fill={fill} />
			</Button>
		);
	}

	return <Icon fill={fill} onClick={onClick} />;
};

export default StreamIcon;
