// Services
import ApiServiceV2 from 'services/apiService/apiServiceV2';

const useCollectorManager = () => {
	return {
		// Get Order transaction doc data
		getPhotoReport: ({ elementId }) =>
			ApiServiceV2.get({
				url: `/collectorManager/v1/photoReport/${elementId}`,
			}),
		// Get task layout
		getTaskLayout: ({ otdId, templateId, serviceId, filled }) =>
			ApiServiceV2.get({
				url: `/collectorManager/v1/taskLayout/${otdId}/${templateId}/${serviceId}/${filled}`,
			}),
		// Get review layout
		getReviewLayout: ({
			otdId,
			templateId,
			serviceId,
			serviceTaskId,
			filled,
		}) =>
			ApiServiceV2.get({
				url: `/collectorManager/v1/reviewLayout/${otdId}/${templateId}/${serviceId}/${serviceTaskId}/${filled}`,
			}),
		// Get group layout
		getGroupLayout: ({
			otdId,
			templateId,
			serviceId,
			serviceTaskId,
			reviewId,
			filled,
		}) =>
			ApiServiceV2.get({
				url: `/collectorManager/v1/groupLayout/${otdId}/${templateId}/${serviceId}/${serviceTaskId}/${reviewId}/${filled}`,
			}),
		// Get subgroup layout
		getSubgroupLayout: ({
			otdId,
			templateId,
			serviceId,
			serviceTaskId,
			reviewId,
			groupId,
			filled,
		}) =>
			ApiServiceV2.get({
				url: `/collectorManager/v1/subgroupLayout/${otdId}/${templateId}/${serviceId}/${serviceTaskId}/${reviewId}/${groupId}/${filled}`,
			}),
		// Get collector layout
		getCollectorLayout: ({
			otdId,
			templateId,
			serviceId,
			serviceTaskId,
			reviewId,
			groupId,
			subgroupId,
			filled,
		}) =>
			ApiServiceV2.get({
				url: `/collectorManager/v1/collectorLayout/${otdId}/${templateId}/${serviceId}/${serviceTaskId}/${reviewId}/${groupId}/${subgroupId}/${filled}`,
			}),
		// Upload binary file
		uploadFile: formData =>
			ApiServiceV2.post({
				url: `/collectorManager/v1/uploadFile`,
				data: formData,
			}),

		// Upload coords photo
		updatePhotoCoords: photoData =>
			ApiServiceV2.put({
				url: `/collectorManager/v1/photoCoords`,
				data: photoData,
			}),

		mergePDFReports: otdId =>
			ApiServiceV2.put({
				url: `/collectorManager/v1/generateMergePdf/${otdId}`,
			}),

		getMergedReportUrl: otdId =>
			ApiServiceV2.get({
				url: `/collectorManager/v1/getMergedReportUrl/${otdId}`,
			}),

		mergeContractPdfReports: otdId =>
			ApiServiceV2.put({
				url: `/collectorManager/v1/mergeContractPdfReports/${otdId}`,
			}),

		getMergedContractReportUrl: otdId =>
			ApiServiceV2.get({
				url: `/collectorManager/v1/getMergedContractReportUrl/${otdId}`,
			}),
	};
};

export default useCollectorManager;
