//Libs
import React from 'react';
import { Wrapper, Title, LogoIcon } from 'components';
//Illustrations
import ThrowAway from './ThrowAway';

const ThrowAwayIllustration = ({
	title,
	width,
	fontSize,
	className,
	loading,
}) => (
	<Wrapper
		className={className}
		width='100%'
		height='80%'
		justifyContent='center'
		alignItems='center'
	>
		<Wrapper flexDirection='column' alignItems='center' position='relative'>
			<ThrowAway width={width} />
			{title && (
				<Title textAlign='center' margin='10px 0 0 0' fontSize={fontSize}>
					{title}
				</Title>
			)}
			{loading && (
				<Wrapper padding='0' position='absolute' bottom='-20px' right='0px'>
					<LogoIcon margin='0 0 16px 0' spin={true} size='l' />
				</Wrapper>
			)}
		</Wrapper>
	</Wrapper>
);

export default ThrowAwayIllustration;
