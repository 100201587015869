export function onCreateControlSeriesTransactionValidator(request) {
	const { dwarehouseId, stateId, incomeMethodId } = request;
	let errors = '';

	if (!dwarehouseId && !stateId) {
		errors += '[Se debe indicar el almacén destino o el nuevo estado]';
	}
	if (!incomeMethodId) {
		errors += '[Se debe indicar el método de transferencia]';
	}

	if (errors === '') return null;
	return errors;
}
