import React from 'react';
import { Modal } from '../Utils';

export default ({
	// Props
	reports,
	generateReportModal,
	users,
	// Actions
	mutate1ObjectInReports,
	resetGenerateReportModal,
}) => {
	// Getters
	const report = React.useMemo(() => {
		if (!generateReportModal.reportId || !Array.isArray(reports)) return null;
		const _report = reports.find(
			report => report.id === generateReportModal.reportId,
		);
		if (!_report) return null;
		return {
			..._report,
			reportParams: _report.reportParams || _report.params || {},
			reportProps: _report.reportProps || _report.props || {},
		};
	}, [generateReportModal.reportId]);

	return (
		<>
			{/* CONTROL SERIES TRANSACTION */}
			{generateReportModal.isOpen && report && (
				<Modal
					modalId='GenerateReportModal'
					title={report.name ?? 'Generar Reporte'}
					visible={generateReportModal.isOpen}
					onCancel={() => resetGenerateReportModal()}
					footer={null}
					isSpinning={generateReportModal.isFetching}
					sizeSpinning='large'
					delaySpinning={50}
					tipSpinning='Enviando el reporte a los correos indicados...'
					customProps={{
						// Props
						generateReportModal,
						report,
						users,
						// Actions
						mutate1ObjectInReports,
					}}
				/>
			)}
		</>
	);
};
