// Libs
import React from 'react';
// Utils
import { xlsx } from 'utils/libs';
// Components
import {
	Wrapper,
	Radio,
	Span,
	RefreshIcon,
	LogoIcon,
	EditIcon,
	CloseIcon,
	CheckIcon,
} from 'components';
// Styles
import '../index.css';
import { ExcelIcon } from 'components/Icons';

export default function StockArticles({
	modeView,
	stock,
	control,
	stockArticlesManage,
	getStockArticles,
	mutate1ObjectInWarehouses,
	resetAdjustArticles,
}) {
	return (
		<Wrapper
			height='48px'
			justifyContent='space-between'
			padding='10px 0 10px 10px'
			mediumBackground
		>
			<div />
			<div>
				{!stock.articles.loading && stockArticlesManage.mode === 'view' && (
					<ExcelIcon
						button
						onClick={() => {
							if (control.mode === 'individual')
								xlsx.exportIndividualStockArticlesToExcel(
									'Artículos',
									stock.articles.data,
								);
							else if (control.mode === 'consolidate' || control.mode === 'all')
								xlsx.exportConsolidateStockArticlesToExcel(
									'Artículos',
									stock.articles.data,
								);
						}}
					/>
				)}

				{modeView === 'table' &&
					control.mode === 'individual' &&
					stockArticlesManage.mode === 'view' && (
						<EditIcon
							button
							onClick={() =>
								mutate1ObjectInWarehouses('stockArticlesManage', {
									mode: 'adjust',
								})
							}
						/>
					)}

				{control.mode === 'individual' &&
					stockArticlesManage.mode === 'adjust' && (
						<CloseIcon
							button
							onClick={() => {
								mutate1ObjectInWarehouses('stockArticlesManage', {
									mode: 'view',
								});
								resetAdjustArticles();
							}}
						/>
					)}

				{control.mode === 'individual' &&
					stockArticlesManage.mode === 'adjust' && (
						<CheckIcon
							button
							onClick={() =>
								mutate1ObjectInWarehouses('completeAdjust', { isOpen: true })
							}
						/>
					)}

				{!stock.articles.loading ? (
					<RefreshIcon
						button
						onClick={() =>
							getStockArticles(stock.warehouseId, stock.ownerId, control.mode)
						}
					/>
				) : (
					<LogoIcon button spin />
				)}
			</div>
		</Wrapper>
	);
}
