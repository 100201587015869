// Libs
import React from 'react';
import { useTheme } from 'styled-components';
import { Wrapper, Span } from 'components';

export default function WarehouseZoneCard({ id, name, customProps }) {
	const { inventoryReception, mutate1ObjectInWarehouses } = customProps;
	const theme = useTheme();

	return (
		<Wrapper
			height='45px'
			cursor='pointer'
			backgroundColor={
				inventoryReception.areaId === id
					? theme.colors.selected[500]
					: theme.colors.primary[500]
			}
			onClick={() =>
				mutate1ObjectInWarehouses('inventoryReception', { areaId: id })
			}
		>
			<Span
				fontSize='m'
				color={
					inventoryReception.areaId === id
						? theme.colors.text.high
						: theme.colors.text.medium
				}
			>
				{name}
			</Span>
		</Wrapper>
	);
}
