//Libs
import React from 'react';
import { connect } from 'react-redux';
//Utils
import GENERAL from 'utils/constants/general';
//Services
import { ApiService } from 'services';
//Actions
import { mutateConsumeInventoryControl } from 'components/ConsumeInventory/actions';
//Components
import { Wrapper, Dropdown, IconButton, Menu, LogoIcon } from 'components';
//Selectors
import { selectIsFetchingWTD, selectWTD } from '../selectors';
// Utils
import ConsumeInventoryUtils from '../ConsumeInventoryUtils';
import KEYWORDS from '../keywords';

const { MANAGE_PROPS } = GENERAL.ENV;

const UpdateStateItem = ({
	wtd,
	isFetchingWTD,
	mutateConsumeInventoryControl,
}) => {
	const onClick = React.useCallback(() => {
		const state = ConsumeInventoryUtils.getCorrespondingState(wtd.state);
		mutateConsumeInventoryControl({ isFetchingWTD: true });
		ApiService.fetch(ApiService.endpoints().UPDATE_WTD, {
			reqParams: {
				wtdId: wtd.id,
			},
			bodyParams: {
				state,
			},
		}).then(() => {
			mutateConsumeInventoryControl({
				wtd: {
					...wtd,
					state,
				},
				isFetchingWTD: false,
			});
		});
	}, [wtd]);

	if (isFetchingWTD) return <LogoIcon spin={true} size='s' />;

	return (
		<Wrapper padding='0' onClick={onClick}>
			{wtd.state === KEYWORDS.STATE.OPEN ? 'Finalizar cambios' : 'Editar'}
		</Wrapper>
	);
};

const OptionMenu = ({ wtd, isFetchingWTD, mutateConsumeInventoryControl }) => {
	return (
		<Menu.Wrapper>
			{/* EDIT */}
			<Menu.Item>
				{/* <UpdateStateItem
          wtd={wtd}
          isFetchingWTD={isFetchingWTD}
          mutateConsumeInventoryControl={mutateConsumeInventoryControl}
        /> */}
			</Menu.Item>
		</Menu.Wrapper>
	);
};

const OptionList = ({
	//Props
	order,
	//Redux
	wtd,
	isFetchingWTD,
	//Actions
	mutateConsumeInventoryControl,
}) => {
	React.useEffect(() => {
		mutateConsumeInventoryControl({ isFetchingWTD: true });
		ApiService.fetch(ApiService.endpoints().GET_WTD, {
			reqParams: {
				orderId: order.order_id,
				transactionTypekey: 'myInstall',
			},
		}).then(_wtd =>
			mutateConsumeInventoryControl({ wtd: _wtd, isFetchingWTD: false }),
		);
	}, [order.order_id]);

	//The order must be invoiced to see the options
	if (!wtd || !order.event_props?.[MANAGE_PROPS.FLAGS.BILLED]) return null;
	return null;

	return (
		<Dropdown.Wrapper
			trigger={['click']}
			overlay={
				<OptionMenu
					wtd={wtd}
					isFetchingWTD={isFetchingWTD}
					mutateConsumeInventoryControl={mutateConsumeInventoryControl}
				/>
			}
		>
			<IconButton size='m' type='more' />
		</Dropdown.Wrapper>
	);
};

const mapStateToProps = state => ({
	wtd: selectWTD(state),
	isFetchingWTD: selectIsFetchingWTD(state),
});
const actions = {
	mutateConsumeInventoryControl,
};

export default connect(mapStateToProps, actions)(OptionList);
