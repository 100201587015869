// Libs
import React from 'react';
import styled, { css } from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';
import SmallIcon from './SmallIcon';

const SMALL_SIZE = 'small';

const Button = styled(IconButton)`
	cursor: pointer;
	${props =>
		props.shadow &&
		css`
			-webkit-box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
			box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
		`}
`;

const RenderIcon = ({ size }) => {
	const { theme } = useTheme();

	if (size === SMALL_SIZE) {
		return <SmallIcon fill={theme._inactiveColor} />;
	}
	return <Icon fill={theme._inactiveColor} />;
};

const CheckIcon = ({ button, onClick, shadow, size }) => {
	if (button) {
		return (
			<Button shadow={shadow} onClick={onClick}>
				<RenderIcon size={size} />
			</Button>
		);
	}

	return (
		<div onClick={onClick}>
			<RenderIcon size={size} />
		</div>
	);
};

export default CheckIcon;
