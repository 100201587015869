import React, { useEffect } from 'react';
import { mutate1Object } from 'screens/Warehouses/actions';
import { useSelector, useDispatch } from 'react-redux';
import { handleOnClickCreateArticle } from 'screens/Warehouses/actions';
import {
	selectCreateArticle,
	selectItemFamilies,
	selectMeasuredUnits,
	selectCoins,
} from 'screens/Warehouses/selectors';
import { CreateArticle } from 'screens/Warehouses/subs';
import { getCoins, getItemFamilies, getMeasuredUnits } from 'store/api/actions';

const CreateInventoryItem = () => {
	const createArticle = useSelector(selectCreateArticle);
	const coins = useSelector(selectCoins);
	const measuredUnits = useSelector(selectMeasuredUnits);
	const itemFamilies = useSelector(selectItemFamilies);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getItemFamilies());
		dispatch(getMeasuredUnits());
		dispatch(getCoins());
	}, []);

	return (
		<CreateArticle
			createArticle={createArticle}
			measuredUnits={measuredUnits}
			coins={coins}
			itemFamilies={itemFamilies}
			mutate1Object={(obj1Name, keyValuePairs) =>
				dispatch(mutate1Object(obj1Name, keyValuePairs))
			}
			handleOnClickCreateArticle={() =>
				dispatch(handleOnClickCreateArticle(createArticle))
			}
		/>
	);
};

export default CreateInventoryItem;
