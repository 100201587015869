// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.99992 1.33331C4.31992 1.33331 1.33325 4.31998 1.33325 7.99998C1.33325 11.68 4.31992 14.6666 7.99992 14.6666C11.6799 14.6666 14.6666 11.68 14.6666 7.99998C14.6666 4.31998 11.6799 1.33331 7.99992 1.33331ZM7.99992 13.3333C5.05325 13.3333 2.66659 10.9466 2.66659 7.99998C2.66659 5.05331 5.05325 2.66665 7.99992 2.66665C10.9466 2.66665 13.3333 5.05331 13.3333 7.99998C13.3333 10.9466 10.9466 13.3333 7.99992 13.3333ZM11.3333 7.99998C11.3333 9.84093 9.84087 11.3333 7.99992 11.3333C6.15897 11.3333 4.66658 9.84093 4.66658 7.99998C4.66658 6.15903 6.15897 4.66665 7.99992 4.66665C9.84087 4.66665 11.3333 6.15903 11.3333 7.99998Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
