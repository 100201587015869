import GENERAL from 'utils/constants/general';

const { SUPERVISOR } = GENERAL;

export const mutateDirectProps = keyValuePairs => ({
	type: SUPERVISOR.MUTATE_DIRECT_PROPS,
	payload: keyValuePairs,
});

export const mutate1Object = (obj1Name, keyValuePairs) => ({
	type: SUPERVISOR.MUTATE_1OBJECT,
	payload: { obj1Name, keyValuePairs },
});
export const resetCommentDetailsDrawer = () => ({
	type: SUPERVISOR.RESET_COMMENT_DETAILS_DRAWER,
});

export const resetSupervisor = () => ({
	type: SUPERVISOR.RESET,
});
