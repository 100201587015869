// Libs
import React from 'react';
// Utils
import { getSelectedPaycuts } from 'utils/filters/Billings/paycuts';
// Components
import { AddIcon } from 'components';
import { CustomToolbar } from '../../sharedFunctions';
import ToolbarBackButton from '../../ToolbarBackButton';
// Styles
import '../../index.css';

export function PaymentPays(props) {
	const {
		title,
		selectNotification,
		mutate1ObjectInBillings,
		handleOnClick,
		payments,
		paycuts,
	} = props;
	const selectedPaycuts = getSelectedPaycuts(
		payments.paycuts.data,
		paycuts.selected.items,
	);
	const firstSelectedPaycut = selectedPaycuts[0];

	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={title}
			left={<ToolbarBackButton handleOnClick={handleOnClick} />}
			right={
				<>
					{/* Add Payment */}
					{selectedPaycuts.length === 1 &&
						firstSelectedPaycut?.state === 'open' && (
							<AddIcon
								button
								onClick={() =>
									mutate1ObjectInBillings('createPaymentModal', {
										isOpen: true,
									})
								}
							/>
						)}
				</>
			}
		/>
	);
}
