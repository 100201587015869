export default Object.freeze({
	STATE: {
		LOADING: 'loading',
		SUCCESS: 'success',
		UNAUTHORIZED: 'unauthorized',
		ERROR: 'error',
	},
	MODULES: {
		KEYS: {
			ANALYTICS: 'analytics',
			USERS: 'users',
			ORDERS: 'orders',
			REPORTS: 'reports',
			SUPERVISOR: 'supervisor',
			BILLINGS: 'billings',
			INVENTORY: 'inventory',
			SERIALIZED: 'serialized',
			TRANSACTIONS: 'transactions',
			INVENTORY_FAMILY: 'inventoryFamily',
			WAREHOUSES: 'warehouses',
		},
		ICON: {
			TYPES: {
				INITIAL: 'initial',
				IMAGE: 'image',
				ICON: 'icon',
			},
		},
		ITEMS: {
			KEYS: {
				TARGETS: 'module.targets',
				COMMAND_CENTER: {
					CONTROL: 'command_center.control',
				},
				ANALYTICS: {
					CONTROL: 'analytics.control',
				},
				USERS: {
					CONTROL: 'users.control',
				},
				ORDERS: {
					CONTROL: 'orders.control',
					FILTERS: 'orders.control.filters',
				},
				REPORTS: {
					CONTROL: 'reports.control',
				},
				SUPERVISOR: {
					CONTROL: 'supervisor.control',
					FILTERS: 'supervisor.control.filters',
				},
				BILLINGS: {
					PAYMENTS: 'billings.payments.control',
					INVOICES: 'billings.invoices.control',
				},
				INVENTORY: {
					ITEMS: {
						CONTROL: 'inventory.items.control',
					},
					WAREHOUSES: {
						CONTROL: 'inventory.warehouses.control',
						FILTERS: 'inventory.warehouses.control.filters',
					},
					SERIES: {
						CONTROL: 'inventory.series.control',
					},
					TRANSACTIONS: {
						CONTROL: 'inventory.transactions.control',
					},
				},
				WAREHOUSE: {
					CATALOG: {
						CONTROL: 'warehouse.catalog.control',
						FILTERS: 'warehouse.catalog.control.filters',
					},
					REQUESTS: {
						CONTROL: 'warehouse.requests.control',
						FILTERS: 'warehouse.requests.control.filters',
					},
				},
			},
			COMPONENTS: {
				MENU: {
					ITEM: 'menu.item',
				},
				FILTER: {
					ITEM: 'filter.item',
				},
			},
			ICON: {
				TYPES: {
					INITIAL: 'initial',
					IMAGE: 'image',
					ICON: 'icon',
				},
				ICONS: {
					CENTRAL: 'central',
					RESIDENTIAL: 'residential',
					CABINET: 'cabinet',
					RADIOBASE: 'radiobase',
				},
			},
			STATUS: {
				INSTALL: 'install',
				UNINSTALL: 'uninstall',
				INSTALLED: 'installed',
				NO_INSTALLED: 'no_installed',
			},
			COVERAGE: {
				MY_USER: 'user',
				USERS: 'users',
				ENTITY: 'entity',
				ORGANIZATION: 'organization',
			},
		},
	},
	ROUTES: {
		ROOT: '/',
		ONBOARDING: {
			LOGIN: '/login',
			FETCHING_MODULES: '/fetchingModules',
			NO_MODULE_ESTABLISHED: '/noModuleEstablished',
		},
		DASHBOARD: {
			ORDERS: {
				CONTROL: '/dashboard/orders',
			},
		},
	},
});
