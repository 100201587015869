// Libs
import React from 'react';
// Components
import Input from '@mui/material/Input';
import { CloseIcon, SearchIcon } from 'components';
import { CustomSearchToolbar } from '../sharedFunctions';
// Styles
import '../index.css';

export default function SearchReportsToolbar({
	mutate1ObjectInReports,
	searchInData,
}) {
	return (
		<CustomSearchToolbar
			left={
				<Input
					id='toolbar-search-order-input'
					style={{ height: '100%', width: '100%' }}
					autoFocus
					name='data'
					placeholder='Buscar...'
					value={searchInData.data}
					onChange={e =>
						mutate1ObjectInReports('control', {
							searchInData: {
								...searchInData,
								[e.target.name]: e.target.value,
							},
						})
					}
					startAdornment={<SearchIcon />}
					endAdornment={
						<CloseIcon
							button
							onClick={() =>
								mutate1ObjectInReports('control', {
									searchInData: {
										...searchInData,
										renderToolbar: false,
										data: '',
									},
								})
							}
						/>
					}
				/>
			}
		/>
	);
}
