// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'underscore';
// Utils
import GENERAL from 'utils/constants/general';
import {
	filterStockArticlesSearchInData,
	getMyEntityActiveUsers,
} from 'utils/libs';
import { filterArticleStock } from 'utils/filters/Warehouses/articleStock';
// Components
import { Modal } from 'screens/Utils';
import {
	Wrapper,
	Radio,
	TableVirtualized,
	Link,
	Flag,
	Span,
	InputNumber,
} from 'components';
// Styles
import '../../../css/Stock.css';

const { ENV } = GENERAL;
const { Table, Column, Header, Row, Cell } = TableVirtualized;

function StockArticlesDesktop(props) {
	if (!props.stock.warehouseId || !props.stock.ownerId || !props.control.mode) {
		return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSES_MANAGE_DESKTOP} />;
	}

	const {
		stock,
		stockArticlesManage,
		control,
		getStockSeries,
		mutate1ObjectApi,
		mutate1Object,
		completeAdjust,
		sendTransferTransaction,
		profile,
	} = props;
	const users = getMyEntityActiveUsers(props.users.data, profile);
	const allStockItems = [...stock.articles.data];

	let filteredStockItems = allStockItems;
	if (stockArticlesManage.mode === 'adjust')
		filteredStockItems = filteredStockItems.filter(item => !item.is_serialized);
	filteredStockItems = filterStockArticlesSearchInData(
		filteredStockItems,
		stockArticlesManage.searchInData.searchBy,
		stockArticlesManage.searchInData.data,
	);

	filteredStockItems = filterArticleStock(
		allStockItems,
		stockArticlesManage.filters,
	);

	// HEADER
	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: allStockItems,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: stockArticlesManage.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('stockArticlesManage', {
							filters: updatedFilters,
						}),
				}}
			>
				{label}
			</Header>
		);
	}
	// INDEX
	function getIndexCellRenderer({ rowData, rowIndex }) {
		let flagPriorityStyle = {};

		if (rowData.priority) flagPriorityStyle = { ...rowData.priority.style };
		return (
			<Cell>
				<Flag style={flagPriorityStyle} height='20px' />
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	}

	function getAdjustTypeCellRenderer({ rowData }) {
		return (
			<div className='desktop-stock-table-index-cell-container'>
				<Radio.Group
					value={stockArticlesManage.relativeOption[rowData.id]}
					onChange={e => {
						mutate1Object('stockArticlesManage', {
							relativeOption: {
								...stockArticlesManage.relativeOption,
								[rowData.id]: e.target.value,
							},
							adjustArticles: stockArticlesManage.adjustArticles.filter(
								adjArt => adjArt.item_id !== rowData.id,
							),
						});
					}}
				>
					<Radio.Button value='add'>Sumar</Radio.Button>
					<Radio.Button value='subtract'>Restar</Radio.Button>
				</Radio.Group>
			</div>
		);
	}

	function getAdjustValueCellRenderer({ rowData }) {
		let adjustArticles = [...stockArticlesManage.adjustArticles];
		const idx = _.findIndex(
			adjustArticles,
			adjArt => adjArt.item_id === rowData.id,
		);

		return (
			<div className='desktop-stock-table-index-cell-container'>
				<InputNumber
					placeholder='Cantidad*'
					disabled={
						stockArticlesManage.adjustType === 'relative' &&
						!stockArticlesManage.relativeOption[rowData.id]
					}
					value={idx !== -1 ? adjustArticles[idx].amount : undefined}
					min={0}
					max={
						stockArticlesManage.adjustType === 'relative' &&
						stockArticlesManage.relativeOption[rowData.id] === 'subtract'
							? rowData.amount
							: undefined
					}
					onChange={value => {
						if (
							stockArticlesManage.adjustType === 'relative' &&
							(!value || value === 0)
						) {
							if (idx !== -1)
								adjustArticles = adjustArticles.filter(
									adjArt => adjArt.item_id !== rowData.id,
								);
						} else if (idx === -1) {
							adjustArticles.push({
								item_id: rowData.id,
								amount: value,
								action: stockArticlesManage.relativeOption[rowData.id],
							});
						} else {
							adjustArticles[idx].amount = value;
						}
						mutate1Object('stockArticlesManage', { adjustArticles });
					}}
				/>
			</div>
		);
	}

	function getAmountCellRenderer({ rowData }) {
		if (rowData.is_serialized) {
			return (
				<Link
					to={ENV.ROUTES.PATHS.WAREHOUSES_STOCK_SERIES_DESKTOP}
					onClick={() => {
						mutate1ObjectApi('stock', {
							...stock,
							itemId: rowData.id,
							stateId: rowData.state_id,
						});
						getStockSeries(
							stock.warehouseId,
							stock.ownerId,
							control.mode,
							rowData.id,
							rowData.state_id,
						);
					}}
				>
					<div>{rowData.amount}</div>
				</Link>
			);
		} else {
			return (
				<Cell>
					<Span>{rowData.amount}</Span>
				</Cell>
			);
		}
	}

	// function handleOnClickRow(warehouseClicked, selectedItems) {
	//   let idx = _.findIndex(selectedItems, codeItem => codeItem === warehouseClicked);
	//   if (idx === -1) {
	//     selectedItems.push(warehouseClicked);
	//     mutate1Object('stockArticlesManage', { selectedItems });
	//   }
	// }

	// function handleOnRightClickRow(e, warehouseClicked, selectedItems) {
	//   e.preventDefault();

	//   let idx = _.findIndex(selectedItems, codeItem => codeItem === warehouseClicked);
	//   if (idx !== -1) selectedItems = selectedItems.filter(codeItem => codeItem !== warehouseClicked);
	//   else selectedItems = [];
	//   mutate1Object('stockArticlesManage', { selectedItems });
	// }

	function getRowRenderer({ key, columns, style, rowData }) {
		const selectedItems = [...stockArticlesManage.selectedItems];
		let styleRow = { ...style };

		// Is warehouse selected?
		const idx = _.findIndex(
			selectedItems,
			codeItem => codeItem === rowData.item_code,
		);
		if (idx !== -1) {
			styleRow = {
				...styleRow,
				color: 'lightseagreen',
				backgroundColor: 'rgb(44,44,54)',
			};
		}

		return (
			<Row key={key} style={styleRow}>
				{columns}
			</Row>
		);
	}

	// Render
	return (
		<Wrapper padding='0' className='animated fadeIn'>
			{stockArticlesManage.mode === 'adjust' ? (
				<Table
					highBackground
					width={document.documentElement.clientWidth}
					height={document.documentElement.clientHeight - 115}
					headerHeight={44}
					rowHeight={40}
					rowCount={filteredStockItems.length}
					rowGetter={({ index }) => filteredStockItems[index]}
					rowRenderer={getRowRenderer}
				>
					<Column
						dataKey='index'
						label='#'
						width={50}
						headerRenderer={({ label }) => <Header>{label}</Header>}
						cellRenderer={getIndexCellRenderer}
					/>
					<Column
						dataKey='item_code'
						label='Código'
						width={70}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='item_name'
						label='Nombre'
						width={310}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					{stockArticlesManage.adjustType === 'relative' && (
						<Column
							label='Accion'
							width={180}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
							cellRenderer={getAdjustTypeCellRenderer}
						/>
					)}
					<Column
						label='Valor'
						width={150}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getAdjustValueCellRenderer}
					/>
					<Column
						dataKey='amount'
						label='Cantidad'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getAmountCellRenderer}
					/>
					<Column
						dataKey='amount_transit'
						label='Tránsito'
						width={80}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
					/>
					<Column
						dataKey='serie'
						label='Serie'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='warehouse_name'
						label='Almacén'
						width={150}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='created_at'
						label='Creado'
						width={130}
						headerRenderer={props => getHeaderRenderer(props, 'date')}
					/>
					<Column
						dataKey='updated_at'
						label='Actualizado'
						width={130}
						headerRenderer={props => getHeaderRenderer(props, 'date')}
					/>
				</Table>
			) : control.mode === 'individual' ? (
				<Table
					highBackground
					width={document.documentElement.clientWidth}
					height={document.documentElement.clientHeight - 115}
					headerHeight={44}
					rowHeight={40}
					rowCount={filteredStockItems.length}
					rowGetter={({ index }) => filteredStockItems[index]}
					rowRenderer={getRowRenderer}
				>
					<Column
						dataKey='index'
						label='#'
						width={50}
						headerRenderer={({ label }) => <Header>{label}</Header>}
						cellRenderer={getIndexCellRenderer}
					/>
					<Column
						dataKey='item_code'
						label='Código'
						width={70}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='item_name'
						label='Nombre'
						width={280}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='serialized'
						label='Serializado'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='amount'
						label='Cantidad'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getAmountCellRenderer}
					/>
					<Column
						dataKey='amount_transit'
						label='Tránsito'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
					/>
					<Column
						dataKey='serie'
						label='Serie'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					{/* <Column
						dataKey='allowed_to_use_label'
						label='Permitido'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/> */}
					<Column
						dataKey='state_name'
						label='Estado'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='warehouse_package_id'
						label='Paquete'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
					/>
					<Column
						dataKey='area_name'
						label='Area'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='zone_name'
						label='Zona'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='warehouse_name'
						label='Almacén'
						width={140}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='warehouse_transaction_doc_id'
						label='Documento'
						width={110}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='created_at'
						label='Creado'
						width={130}
						headerRenderer={props => getHeaderRenderer(props, 'date')}
					/>
					<Column
						dataKey='updated_at'
						label='Actualizado'
						width={130}
						headerRenderer={props => getHeaderRenderer(props, 'date')}
					/>
				</Table>
			) : (
				(control.mode === 'consolidate' || control.mode === 'all') && (
					<Table
						highBackground
						width={document.documentElement.clientWidth}
						height={document.documentElement.clientHeight - 115}
						headerHeight={44}
						rowHeight={40}
						rowCount={filteredStockItems.length}
						rowGetter={({ index }) => filteredStockItems[index]}
						rowRenderer={getRowRenderer}
					>
						<Column
							dataKey='index'
							label='#'
							width={50}
							headerRenderer={({ label }) => <Header>{label}</Header>}
							cellRenderer={getIndexCellRenderer}
						/>
						<Column
							dataKey='item_code'
							label='Código'
							width={90}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='item_name'
							label='Nombre'
							width={310}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='serialized'
							label='Serializado'
							width={120}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='amount'
							label='Cantidad'
							width={120}
							headerRenderer={props => getHeaderRenderer(props, 'numeric')}
							cellRenderer={getAmountCellRenderer}
						/>
						<Column
							dataKey='amount_transit'
							label='Tránsito'
							width={100}
							headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						/>
						<Column
							dataKey='serie'
							label='Serie'
							width={120}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='state_name'
							label='Estado'
							width={100}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='entity_name'
							label='Entidad'
							width={170}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='warehouse_transaction_doc_id'
							label='Documento'
							width={110}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='created_at'
							label='Creado'
							width={130}
							headerRenderer={props => getHeaderRenderer(props, 'date')}
						/>
						<Column
							dataKey='updated_at'
							label='Actualizado'
							width={130}
							headerRenderer={props => getHeaderRenderer(props, 'date')}
						/>
					</Table>
				)
			)}
			<Modal
				modalId='CompleteAdjustModal'
				title='Completar Ajuste'
				visible={completeAdjust.isOpen}
				onCancel={() => mutate1Object('completeAdjust', { isOpen: false })}
				footer={null}
				isSpinning={completeAdjust.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					stockArticlesManage,
					completeAdjust,
					users,
					ownerId: stock.ownerId,
					warehouseId: stock.warehouseId,
					mutate1ObjectInWarehouses: mutate1Object,
					sendTransferTransaction,
				}}
			/>
		</Wrapper>
	);
}

export default StockArticlesDesktop;
