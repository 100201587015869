// Libs
import styled, { css } from 'styled-components';

const SpanStyled = styled.span`
	position: ${props =>
		props.position ? props.position : props.theme.text.position};
	width: ${props => (props.width ? props.width : props.theme.text.width)};
	height: ${props => (props.height ? props.height : props.theme.text.height)};
	margin: ${props => (props.margin ? props.margin : props.theme.text.margin)};
	padding: ${props =>
		props.padding ? props.padding : props.theme.text.padding};
	font-size: ${props =>
		props.fontSize
			? props.theme.fonts.size[props.fontSize]
			: props.theme.fonts.size.default};
	color: ${props =>
		props.color
			? props.theme.fonts.colors[props.colors]
			: props.theme.fonts.colors.default};
	text-decoration: ${props => props.theme.text.textDecoration};
	border: ${props => props.theme.text.border};
	${props =>
		props.hoverable &&
		css`
			&:hover {
				background: ${props => props.theme.text.hoverable.background};
				text-shadow: ${props => props.theme.text.hoverable.textShadow};
				-webkit-text-fill-color: ${props =>
					props.theme.text.hoverable.webkitTextfillColor};
				background-clip: ${props => props.theme.text.hoverable.backgroundClip};
				-webkit-background-clip: ${props =>
					props.theme.text.hoverable.webkitBackgroundClip};
			}
			cursor: ${props => props.theme.text.hoverable.cursor};
		`};
	${props =>
		props.primary &&
		css`
			color: ${props => props.theme.colors.text.high};
		`}
	${props =>
		props.secondary &&
		css`
			color: ${props => props.theme.colors.text.medium};
		`}
`;

export default SpanStyled;
