import styled from 'styled-components';
import { Popconfirm } from 'antd';

const StyledPopconfirm = styled(Popconfirm)`
	.ant-popover-buttons {
		display: flex;
		justify-content: flex-end;
	}
`;

export default StyledPopconfirm;
