// Libs
import React from 'react';
// Components
import {
	PieChart,
	Pie as _Pie,
	Cell,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';
// Styles
import styles from './TwoLevelPie.module.css';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (
	dataKey,
	{ cx, cy, midAngle, innerRadius, outerRadius, ...res },
) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);
	return (
		<text
			x={x}
			y={y}
			fill='white'
			textAnchor={x > cx ? 'start' : 'end'}
			dominantBaseline='central'
		>
			{res[dataKey]}
			{/* {`${(percent * 100).toFixed(0)}%`} */}
		</text>
	);
};

// const data02 = [
//   { name: "Pendiente", value: 100 },
//   { name: "Reprogramada", value: 70 },
//   { name: "Pendiente", value: 73 },
//   { name: "No ejecutado", value: 30 },
//   { name: "Suspendido por mal clima", value: 146 },
//   { name: "Zona de dificil acceso", value: 50 },
//   { name: "Accidente laboral", value: 50 },
// ];
/**
 * See data structure example:
 * https://recharts.org/en-US/examples/TwoLevelPieChart
 */
const TwoLevelPie = ({ data, data2, config = {}, onClickChartSegment }) => {
	const {
		colors,
		values = ['value'],
		width = 300,
		height = 300,
		labelLine = false,
	} = config;
	const dataKey = values[0];
	return (
		<div className={styles.twoPieContainer} style={{ width, height }}>
			<ResponsiveContainer width='100%' height='100%'>
				<PieChart width={width} height={height}>
					<Tooltip />
					<_Pie
						dataKey={dataKey}
						data={data}
						cx='50%'
						cy='50%'
						labelLine={labelLine}
						label={props => renderCustomizedLabel(dataKey, props)}
						outerRadius={60}
						fill='#8884d8'
						onClick={onClickChartSegment}
					>
						{data.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={colors[index % colors.length]}
							/>
						))}
					</_Pie>
					<_Pie
						data={data2}
						dataKey='value'
						cx='50%'
						cy='50%'
						innerRadius={70}
						outerRadius={100}
						fill='#82ca9d'
						label
						onClick={onClickChartSegment}
					/>
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

export default TwoLevelPie;
