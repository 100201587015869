// Libs
import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
// Context
import { ModalBuilderContext } from 'core/context';
import { ModalBuilderConfig } from 'core/ModalBuilder';
// Components
import ProfileSection from './ProfileSection';
import ToggleContainer from './ToggleContainer';
import MenuContainer from './MenuContainer';
// Config
import MenuBuilderConfig from './menu.config';
// Utils
import KEYWORDS from '../keywords';
// Datasource
import { DatasourceBuilder } from '../DatasourceBuilder';

const { ROUTES } = KEYWORDS;

const customMenus = MenuBuilderConfig.getAuthorizedMenuReactNodes();

const MenuContentSwitcher = () => {
	return (
		<Switch>
			{Object.keys(customMenus).map(path => {
				const CustomMenu = customMenus[path];
				if (!CustomMenu) return null;
				return <Route key={path} exact path={path} children={<CustomMenu />} />;
			})}
			<Route
				path={[ROUTES.FEED_MODULE.PATH, ROUTES.FEED.PATH]}
				children={<DatasourceBuilder />}
			/>
		</Switch>
	);
};

const MenuBuilder = ({ isOpen, isMobile, profile, toggleDatasource }) => {
	const { setModal } = useContext(ModalBuilderContext);
	return (
		<>
			<ToggleContainer isOpen={isOpen} isMobile={isMobile}>
				<ProfileSection
					profile={profile}
					toggleDatasource={toggleDatasource}
					onAvatarClick={() =>
						setModal(ModalBuilderConfig.getAuthorizedModalKeys().userProfile, {
							userId: profile?.user.id,
						})
					}
				/>
				<MenuContainer>
					<MenuContentSwitcher />
				</MenuContainer>
			</ToggleContainer>
			{isOpen && !isMobile && <div style={{ width: '300px' }} />}
		</>
	);
};
export default MenuBuilder;
