// Libs
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import axios from 'axios';
// Utils
import { GlobalUtils, OrderUtils } from 'utils';
import {
	unifyDatetime,
	convertOrderHistoryDatesToMyTimezone,
	formatData,
} from 'utils/libs/dateFormats';
import AuthService from 'utils/libs/auth/AuthService';
import GENERAL from 'utils/constants/general';
import API from 'utils/constants/api';
import { getCoords } from 'utils/libs';
import capitalize from 'utils/libs/capitalize';
import asyncErrorsHandler from 'store/asyncErrorsHandler';

// Actions
import {
	mutate1Object,
	onResetOrdersReassignmentModal,
	updateOrderLocally,
} from './actions';
import { sendToast } from 'screens/Dashboard/actions';

const { ORDERS, DASHBOARD, APIDATA } = GENERAL;
const auth = new AuthService();
const getOrdersQueryModal = state => state.orders.getOrdersQueryModal;
const getControl = state => state.orders.control;
const getProfile = state => state.login.profile;
const getUsers = state => state.api.users.data;
const getEnterNewOrdersModal = state => state.orders.enterNewOrdersModal;

function* onSendOrdersToUserAssignmentWatcher() {
	yield takeLatest(
		ORDERS.SEND_ORDERS_TO_USER_ASSIGNMENT,
		onSendOrdersToUserAssignmentWorker,
	);
}
function* onSendImportOrdersToProcessAndSaveWatcher() {
	yield takeLatest(
		ORDERS.SEND_IMPORT_ORDERS_TO_PROCESS_AND_SAVE,
		onSendImportOrdersToProcessAndSaveWorker,
	);
}
function* onSaveOrdersReassignmentToUserWatcher() {
	yield takeLatest(
		ORDERS.SAVE_ORDERS_REASSIGNMENT_TO_USER,
		onSaveOrdersReassignmentToUserWorker,
	);
}
function* saveOrderReassignmentWatcher() {
	yield takeLatest(
		ORDERS.SAVE_ORDERS_REASSIGNMENT_TO_USER_V2,
		saveOrderReassignmentWorker,
	);
}
function* watch_SendNewIncomeZones() {
	yield takeLatest(ORDERS.SEND_NEW_INCOME_ZONES, saveNewIncomeZones);
}
function* watch_GetControlOrders() {
	yield takeLatest(ORDERS.GET_CONTROL_ORDERS, getControlOrders);
}
function* watch_SendOrderEvent() {
	yield takeLatest(ORDERS.SEND_ORDER_EVENTS, sendOrderEvents);
}
function* getCountOrdersToReassignmentWatcher() {
	yield takeLatest(
		ORDERS.GET_COUNT_ORDERS_TO_REASSIGNMENT,
		getCountOrdersToReassignmentWorker,
	);
}
function* watch_UpdateOrdersSort() {
	yield takeLatest(ORDERS.UPDATE_ORDERS_SORT, updateOrdersSort);
}
function* getOrderWatcher() {
	yield takeLatest(ORDERS.GET_ORDER, getOrderWorker);
}
function* makeDeepSearchWatcher() {
	yield takeLatest(ORDERS.MAKE_DEEP_SEARCH, makeDeepSearchWorker);
}
function* watch_GetInfoOrdersIncome() {
	yield takeLatest(ORDERS.GET_INFO_ORDERS_INCOME, getInfoOrdersIncome);
}
function* watch_GetPhotoReport() {
	yield takeLatest(ORDERS.GET_PHOTO_REPORT, getPhotoReport);
}
function* sendCommentReportWatcher() {
	yield takeLatest(ORDERS.SEND_COMMENT_REPORT, sendCommentReportWorker);
}
function* sendRejectInventoryValidationWatcher() {
	yield takeLatest(
		ORDERS.SEND_REJECT_INVENTORY_VALIDATION,
		sendRejectInventoryValidationWorker,
	);
}
function* revertBillWatcher() {
	yield takeLatest(ORDERS.REVERT_BILL, revertBillWorker);
}
function* setOrderPropsWatcher() {
	yield takeLatest(ORDERS.SET_ORDER_PROPS, setOrderPropsWorker);
}
function* onOrdersExistsValidateWatcher() {
	yield takeLatest(ORDERS.ORDERS_EXISTS_VALIDATE, onOrdersExistsValidateWorker);
}
function* approveCompletedAuthorizationCodeWatcher() {
	yield takeLatest(
		ORDERS.APPROVE_COMPLETED_AUTHORIZATION_CODE,
		approveCompletedAuthorizationCodeWorker,
	);
}
function* createOneOrderWatcher() {
	yield takeLatest(ORDERS.CREATE_ONE_ORDER, createOneOrderWorker);
}
function* getAllExpertsInfoWatcher() {
	yield takeLatest(ORDERS.GET_ALL_EXPERTS_INFO, getAllExpertsInfoWorker);
}

// Workers
function* onSendOrdersToUserAssignmentWorker(action) {
	const {
		orders,
		dateFormat,
		validationTemplateId,
		odtField,
		assignedTechField,
		validateFieldsTemplate,
	} = action.payload;

	try {
		// Set state userAssignment: assigning
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'enterNewOrdersModal',
				keyValuePairs: { userAssignment: 'assigning' },
			},
		});
		// Send orders
		const { data } = yield call(
			axios.put,
			API.DOMAIN.concat('/orders/import/autoUserAssignment'),
			{ orders, dateFormat, validationTemplateId },
			auth.sendToken(),
		);
		// Set userAssignment to orders
		yield put({
			type: ORDERS.SET_USER_ASSIGNMENT_TO_ORDERS,
			payload: {
				ordersAssignment: data,
				odtField,
				assignedTechField,
				validateFieldsTemplate,
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'enterNewOrdersModal',
						keyValuePairs: { userAssignment: 'error' },
					},
				});
			},
			function* () {
				yield onSendOrdersToUserAssignmentWorker(action);
			},
		);
	}
}

function* onSendImportOrdersToProcessAndSaveWorker(action) {
	const { orders, dateFormat, validationTemplateId } = action.payload;
	const enterNewOrdersModal = yield select(getEnterNewOrdersModal);
	const ordersQueryModal = yield select(getOrdersQueryModal);

	try {
		// Set request status: "sending"
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'enterNewOrdersModal',
				keyValuePairs: {
					request: { ...enterNewOrdersModal.request, status: 'sending' },
				},
			},
		});
		// Send orders
		yield call(
			axios.post,
			API.DOMAIN.concat('/orders/import/processAndSave'),
			{ orders, dateFormat, validationTemplateId },
			auth.sendToken(),
		);
		// Set request status: "success"
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'enterNewOrdersModal',
				keyValuePairs: {
					importedFile: {
						...enterNewOrdersModal.importedFile,
						data: [],
					},
					request: {
						...enterNewOrdersModal.request,
						status: 'success',
					},
				},
			},
		});
		// Get Orders
		yield put({
			type: ORDERS.GET_CONTROL_ORDERS,
			payload: ordersQueryModal,
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'enterNewOrdersModal',
						keyValuePairs: {
							request: { ...enterNewOrdersModal.request, status: 'error' },
						},
					},
				});
			},
			function* () {
				yield onSendImportOrdersToProcessAndSaveWorker(action);
			},
		);
	}
}

function* onSaveOrdersReassignmentToUserWorker(action) {
	const { orderIds, userId } = action.payload;
	const coords = yield call(getCoords);
	const ordersQueryModal = yield select(getOrdersQueryModal);

	try {
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'ordersReassignmentModal',
				keyValuePairs: { reassigning: true },
			},
		});
		// Update order assignment
		yield call(
			axios.put,
			API.DOMAIN.concat('/orders/v1/reassignment'),
			{ orderIds, userId, coords },
			auth.sendToken(),
		);
		// When Response
		yield put({
			type: ORDERS.RESET_ORDERS_REASSIGNMENT_MODAL,
			payload: {
				reassigning: false,
			},
		});
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'control',
				keyValuePairs: { selectedOrders: [] },
			},
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: 'Reasignado!', type: 'success' },
		});
		// Get Orders
		yield put({
			type: ORDERS.GET_CONTROL_ORDERS,
			payload: ordersQueryModal,
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'ordersReassignmentModal',
						keyValuePairs: { reassigning: false },
					},
				});
			},
			function* () {
				yield onSaveOrdersReassignmentToUserWorker(action);
			},
		);
	}
}

function* saveOrderReassignmentWorker(action) {
	const { orderIds, userId } = action.payload;
	const coords = yield call(getCoords);

	try {
		yield put(
			mutate1Object('ordersReassignmentModal', {
				reassigning: true,
			}),
		);

		// Update order assignment
		const { data } = yield call(
			axios.put,
			API.DOMAIN.concat('/orders/v1/reassignment'),
			{ orderIds, userId, coords },
			auth.sendToken(),
		);

		// When Response
		yield put(onResetOrdersReassignmentModal({ reassigning: false }));
		yield put(
			mutate1Object('control', {
				selectedOrders: [],
			}),
		);

		// Notify user
		yield put(sendToast({ message: 'Reasignado!', type: 'success' }));

		// Update order(s) in orders.control.orders
		GlobalUtils.checkArray(data).forEach(function* (updatedOrder) {
			yield put(updateOrderLocally(updatedOrder));
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put(
					mutate1Object('ordersReassignmentModal', {
						reassigning: false,
					}),
				);
			},
			function* () {
				yield saveOrderReassignmentWorker(action);
			},
		);
	}
}

function* saveNewIncomeZones(action) {
	const newIncomeZones = action.payload;

	try {
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: { obj1Name: 'zones', keyValuePairs: { loading: true } },
		});
		yield call(
			axios.post,
			API.DOMAIN.concat('/zones'),
			newIncomeZones,
			auth.sendToken(),
		);
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'zones',
				keyValuePairs: { newIncomes: [], loading: false },
			},
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: 'Zonas actualizadas!' },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: { obj1Name: 'zones', keyValuePairs: { loading: false } },
				});
			},
			function* () {
				yield saveNewIncomeZones(action);
			},
		);
	}
}

function* getControlOrders(action) {
	let {
		searchTo,
		expertToSearch,
		entityToSearch,
		stateToSearch,
		eventCategoryToSearch,
		eventToSearch,
		fromDate,
		fromTime,
		toDate,
		toTime,
	} = action.payload;

	if (eventCategoryToSearch !== 'affectation') {
		fromTime = '00:00';
		toTime = '23:59';
	}

	const profile = yield select(getProfile);
	const control = yield select(getControl);
	const fromDateFormatted = unifyDatetime(
		fromDate,
		fromTime,
		profile.organization.tz,
	);
	const toDateFormatted = unifyDatetime(
		toDate,
		toTime,
		profile.organization.tz,
	);

	try {
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrdersQueryModal',
				keyValuePairs: { loading: true },
			},
		});
		yield put({ type: APIDATA.GET_GAINS_ENTITY });
		const response = yield call(
			axios.get,
			API.DOMAIN.concat(
				`/orders/control/${searchTo}/${expertToSearch}/${entityToSearch}/${stateToSearch}/${eventCategoryToSearch}/${eventToSearch}/${fromDateFormatted}/${toDateFormatted}`,
			),
			auth.sendToken(),
		);
		const orders =
			eventCategoryToSearch === 'affectation'
				? response.data.map(order => {
						order.client_name = capitalize(order.client_name, true);
						order.city = capitalize(order.city, true);
						order.township = capitalize(order.township, true);
						order.zone = capitalize(order.zone, true);
						order.formatReservationAt = order.reservation_at;
						order.formatExecutionAt = order.execution_at;
						order.formatProgramAt = order.program_at;
						order.formatLastEventAt = order.last_event_at;
						order.formatReprogramAt = order.reprogram_at;
						return order;
				  })
				: response.data.map(record =>
						formatData(
							record,
							profile.organization.tz,
							profile.user.settings.date_format,
						),
				  );

		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'control',
				keyValuePairs: {
					orders,
					selectedOrders: [],
					myAssignedZones: {
						...control.myAssignedZones,
						userBoZones: OrderUtils.getMyAssignedZones(orders),
					},
				},
			},
		});
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrdersQueryModal',
				keyValuePairs: { loading: false, isOpen: false },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'getOrdersQueryModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield getControlOrders(action);
			},
		);
	}
}

function* getInfoOrdersIncome(action) {
	const infoOrdersIncomeModal = action.payload;
	const { fromDate, toDate } = infoOrdersIncomeModal;
	const profile = yield select(getProfile);

	try {
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getInfoOrdersIncomeModal',
				keyValuePairs: { state: 'loading', data: [] },
			},
		});
		const response = yield call(
			axios.get,
			API.DOMAIN.concat(
				`/orders/history-income-orders/${fromDate.format(
					'YYYY-MM-DD',
				)}/${toDate.format('YYYY-MM-DD')}`,
			),
			auth.sendToken(),
		);
		const data = response.data.map(record =>
			convertOrderHistoryDatesToMyTimezone(
				record,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getInfoOrdersIncomeModal',
				keyValuePairs: { state: 'loaded', data },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getInfoOrdersIncome(action);
		});
	}
}

function* getCountOrdersToReassignmentWorker(action) {
	const assignedTechIds = action.payload;

	try {
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'ordersReassignmentModal',
				keyValuePairs: {
					loading: true,
				},
			},
		});
		const { data } = yield call(
			axios.post,
			API.DOMAIN.concat(`/orders/v1/countOrdersToReassignment`),
			{ assignedTechIds },
			auth.sendToken(),
		);
		yield put({
			type: ORDERS.GET_COUNT_ORDERS_TO_REASSIGNMENT_SUCCESS,
			payload: { assignedTechIds, data },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'ordersReassignmentModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield getCountOrdersToReassignmentWorker(action);
			},
		);
	}
}

function* getOrderWorker(action) {
	const { orderId, onLoading, onSuccess, onError } = action.payload;
	const profile = yield select(getProfile);

	try {
		if (onLoading) yield onLoading();
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrderInformationModal',
				keyValuePairs: { loading: true },
			},
		});
		const response = yield call(
			axios.get,
			API.DOMAIN.concat(`/orders/order/adm/v1/${orderId}`),
			auth.sendToken(),
		);
		const order = [response.data].map(record =>
			formatData(
				record,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		)[0];

		if (onSuccess) yield onSuccess(order);
		yield put({ type: ORDERS.GET_ORDER_SUCCESS, payload: order });
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrderInformationModal',
				keyValuePairs: { loading: false },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				if (onError) yield onError(err);
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'getOrderInformationModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield getOrderWorker(action);
			},
		);
	}
}

function* makeDeepSearchWorker(action) {
	const data = action.payload;
	const profile = yield select(getProfile);

	try {
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrdersQueryModal',
				keyValuePairs: { loading: true },
			},
		});
		const response = yield call(
			axios.get,
			API.DOMAIN.concat(`/orders/deep-search/adm/v1/${data}`),
			auth.sendToken(),
		);
		const orders = response.data.map(record =>
			formatData(
				record,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);

		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrdersQueryModal',
				keyValuePairs: { loading: false },
			},
		});
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: { obj1Name: 'control', keyValuePairs: { orders } },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'getOrdersQueryModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield makeDeepSearchWorker(action);
			},
		);
	}
}

// function* getBillingMaterials(action) {
//   const orderId = action.payload;
//   const profile = yield select(getProfile);
//   const users = yield select(getUsers);

//   try {
//     yield put({
//       type: ORDERS.MUTATE_1OBJECT,
//       payload: {
//         obj1Name: "getOrderInformationModal",
//         keyValuePairs: { loading: true },
//       },
//     });
//     const response = yield call(
//         axios.get,
//         API.DOMAIN.concat(
//           `/warehouses_transactions_docs/getBillingMaterials/${orderId}`
//         ),
//         auth.sendToken()
//       ),
//       billingReport = {
//         id: response.data.id,
//         order_id: response.data.order_id,
//         inventory:
//           response.data.inventory &&
//           response.data.inventory.map((record) =>
//             formatData(
//               record,
//               profile.organization.tz,
//               profile.user.settings.date_format
//             )
//           ),
//         comments:
//           response.data.comments &&
//           response.data.comments.map((record) =>
//             formatData(
//               record,
//               profile.organization.tz,
//               profile.user.settings.date_format,
//               { users }
//             )
//           ),
//         completed: response.data.completed,
//       };
//     yield put({
//       type: ORDERS.MUTATE_1OBJECT,
//       payload: {
//         obj1Name: "getOrderInformationModal",
//         keyValuePairs: { billingReport, loading: false },
//       },
//     });
//   } catch (err) {
//     yield asyncErrorsHandler(err, undefined, function* () {
//       yield getBillingMaterials(action);
//     });
//   }
// }

// function* getOrderHistory(action) {
//   const orderId = action.payload;
//   const profile = yield select(getProfile);

//   try {
//     yield put({
//       type: ORDERS.MUTATE_1OBJECT,
//       payload: {
//         obj1Name: "getOrderInformationModal",
//         keyValuePairs: { loading: true, orderId },
//       },
//     });
//     const response = yield call(
//       axios.get,
//       API.DOMAIN.concat(`/orders/history/${orderId}`),
//       auth.sendToken()
//     );
//     const history = response.data.map((record) =>
//       convertOrderHistoryDatesToMyTimezone(
//         record,
//         profile.organization.tz,
//         profile.user.settings.date_format
//       )
//     );
//     yield put({
//       type: ORDERS.MUTATE_1OBJECT,
//       payload: {
//         obj1Name: "getOrderInformationModal",
//         keyValuePairs: { history, loading: false },
//       },
//     });
//   } catch (err) {
//     yield asyncErrorsHandler(
//       err,
//       function* () {
//         yield put({
//           type: ORDERS.MUTATE_1OBJECT,
//           payload: {
//             obj1Name: "getOrderInformationModal",
//             keyValuePairs: { history: [], loading: false },
//           },
//         });
//       },
//       function* () {
//         yield getOrderHistory(action);
//       }
//     );
//   }
// }

function* getPhotoReport(action) {
	const order_id = action.payload;
	const profile = yield select(getProfile);
	const users = yield select(getUsers);

	try {
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrderInformationModal',
				keyValuePairs: { loading: true },
			},
		});
		const response = yield call(
			axios.get,
			API.DOMAIN.concat(`/photo_reports/${order_id}`),
			auth.sendToken(),
		);
		const photoReport = {
			path: response.data.path,
			comments:
				response.data.comments &&
				response.data.comments.map(record =>
					formatData(
						record,
						profile.organization.tz,
						profile.user.settings.date_format,
						{ users },
					),
				),
		};
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrderInformationModal',
				keyValuePairs: { photoReport, loading: false },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getPhotoReport(action);
		});
	}
}

function* sendOrderEvents(action) {
	const { selectedOrders, sendEventModal, users, tz } = action.payload;
	const coords = yield call(getCoords);
	const orderEvents = {
		selectedOrders,
		eventId: sendEventModal.eventId,
		reprogramAt: unifyDatetime(
			sendEventModal.reprogramDate,
			sendEventModal.reprogramTime,
			tz,
		),
		comment: sendEventModal.comment,
		coords,
	};

	try {
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: { obj1Name: 'sendEventModal', keyValuePairs: { loading: true } },
		});
		const response = yield call(
			axios.put,
			API.DOMAIN.concat('/orders/event_admin'),
			orderEvents,
			auth.sendToken(),
		);
		const eventData = response.data;
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'sendEventModal',
				keyValuePairs: { loading: false, isOpen: false },
			},
		});
		yield put({
			type: ORDERS.SEND_ORDER_EVENTS_SUCCESS,
			payload: {
				eventData,
				ordersToUpdateEvent: orderEvents.selectedOrders,
				comment: orderEvents.comment,
				reprogramAt: eventData.reprogramAt,
				users,
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'sendEventModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield sendOrderEvents(action);
			},
		);
	}
}

function* updateOrdersSort(action) {
	const { updateOrders } = action.payload;
	const control = yield select(getControl);
	const ordersQueryModal = yield select(getOrdersQueryModal);
	const sorting = control.sorting;

	try {
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'control',
				keyValuePairs: { sorting: { ...sorting, loading: true } },
			},
		});
		yield call(
			axios.put,
			API.DOMAIN.concat(`/orders/update_orders_position/`),
			updateOrders,
			auth.sendToken(),
		);
		yield put({
			type: ORDERS.GET_CONTROL_ORDERS,
			payload: ordersQueryModal,
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: 'Ruta actualizada!', type: 'success' },
		});
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'control',
				keyValuePairs: {
					role: 'view',
					sorting: { ...sorting, loading: false, selectedUsers: {} },
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'control',
						keyValuePairs: { sorting: { ...sorting, loading: false } },
					},
				});
			},
			function* () {
				yield updateOrdersSort(action);
			},
		);
	}
}

function* sendCommentReportWorker(action) {
	const { comment, order_id, report } = action.payload;
	const body = { comment, order_id };

	try {
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrderInformationModal',
				keyValuePairs: { sendingComment: true },
			},
		});
		if (report === 'billingReport') {
			yield call(
				axios.post,
				API.DOMAIN.concat('/billings/v1/comments/add'),
				body,
				auth.sendToken(),
			);
			yield put({ type: ORDERS.GET_BILLING_MATERIALS, payload: order_id });
		} else if (report === 'photoReport') {
			yield call(
				axios.post,
				API.DOMAIN.concat('/photo_reports/comments/add'),
				body,
				auth.sendToken(),
			);
			yield put({ type: ORDERS.GET_PHOTO_REPORT, payload: order_id });
		}
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrderInformationModal',
				keyValuePairs: {
					sendingComment: false,
					newComment: undefined,
					viewComments: true,
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'getOrderInformationModal',
						keyValuePairs: { sendingComment: false },
					},
				});
			},
			function* () {
				yield sendCommentReportWorker(action);
			},
		);
	}
}

function* revertBillWorker(action) {
	const order_id = action.payload;
	// const ordersQueryModal = yield select(getOrdersQueryModal);

	try {
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrderInformationModal',
				keyValuePairs: { loading: true },
			},
		});
		yield call(
			axios.delete,
			API.DOMAIN.concat(`/billings/v3/${order_id}`),
			auth.sendToken(),
		);
		yield put({ type: ORDERS.RESET_ORDER_INFORMATION });
		yield put({ type: APIDATA.GET_GAINS_ENTITY });
		yield put({ type: ORDERS.GET_ORDER, payload: { orderId: order_id } });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'getOrderInformationModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield revertBillWorker(action);
			},
		);
	}
}

function* setOrderPropsWorker(action) {
	const body = action.payload;

	try {
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrderInformationModal',
				keyValuePairs: { settingOrderProps: true },
			},
		});
		yield call(
			axios.put,
			API.DOMAIN.concat(`/orders/v1/setProps`),
			body,
			auth.sendToken(),
		);
		if (body.action === 'setWarranty') {
			yield put({ type: APIDATA.GET_GAINS_ENTITY });
		}
		if (body.events?.onSuccess) {
			yield body.events.onSuccess(body.props.warranty);
		}
		yield put({ type: ORDERS.GET_ORDER, payload: { orderId: body.order_id } });
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getOrderInformationModal',
				keyValuePairs: { settingOrderProps: false },
			},
		});
	} catch (err) {
		if (body.events?.onError) {
			yield body.events.onError();
		}
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'getOrderInformationModal',
						keyValuePairs: { settingOrderProps: false },
					},
				});
			},
			function* () {
				yield setOrderPropsWorker(action);
			},
		);
	}
}

function* onOrdersExistsValidateWorker(action) {
	const { request, odtField } = action.payload;

	try {
		// Precall
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'enterNewOrdersModal',
				keyValuePairs: { ordersExistsValidationState: 'validating' },
			},
		});
		// Call POST /orders/exists
		const { data: ordersExists } = yield call(
			axios.post,
			API.DOMAIN.concat(`/orders/exists`),
			request,
			auth.sendToken(),
		);
		// Postcall > Validate success
		yield put({
			type: ORDERS.ORDERS_EXISTS_VALIDATE_SUCCESS,
			payload: { ordersExists, odtField },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'enterNewOrdersModal',
						keyValuePairs: { ordersExistsValidationState: 'error' },
					},
				});
			},
			function* () {
				yield onOrdersExistsValidateWorker(action);
			},
		);
	}
}

function* sendRejectInventoryValidationWorker(action) {
	const idRow = action.payload;
	// TODO: Debo revisar de que era esto y quitarlo o terminarlo
	// try {
	//   yield put({
	//     type: ORDERS.MUTATE_1OBJECT,
	//     payload: {
	//       obj1Name: "getOrderInformationModal",
	//       keyValuePairs: { sendingComment: true },
	//     },
	//   });
	//   if (report === "billingReport") {
	//     yield call(
	//       axios.post,
	//       API.DOMAIN.concat("/billings/v1/comments/add"),
	//       body,
	//       auth.sendToken()
	//     );
	//     yield put({ type: ORDERS.GET_BILLING_MATERIALS, payload: order_id });
	//   } else if (report === "photoReport") {
	//     yield call(
	//       axios.post,
	//       API.DOMAIN.concat("/photo_reports/comments/add"),
	//       body,
	//       auth.sendToken()
	//     );
	//     yield put({ type: ORDERS.GET_PHOTO_REPORT, payload: order_id });
	//   }
	//   yield put({
	//     type: ORDERS.MUTATE_1OBJECT,
	//     payload: {
	//       obj1Name: "getOrderInformationModal",
	//       keyValuePairs: {
	//         sendingComment: false,
	//         newComment: undefined,
	//         viewComments: true,
	//       },
	//     },
	//   });
	// } catch (err) {
	//   yield asyncErrorsHandler(err, function* () {
	//     yield put({
	//       type: ORDERS.MUTATE_1OBJECT,
	//       payload: {
	//         obj1Name: "getOrderInformationModal",
	//         keyValuePairs: { sendingComment: false },
	//       },
	//     });
	//   });
	// }
}

function* approveCompletedAuthorizationCodeWorker(action) {
	const profile = yield select(getProfile);
	const { orderId, completedAuthorizationCode } = action.payload;

	try {
		// Send message
		yield put({
			type: ORDERS.UPDATE_ORDER_IN_MODAL,
			payload: { loading: true },
		});

		// Call api
		const { data } = yield call(
			axios.put,
			API.DOMAIN.concat(`/orders/v1/approveCompletedAuthorizationCode`),
			{ orderId, completedAuthorizationCode },
			auth.sendToken(),
		);

		// Update order in control orders
		yield put({
			type: ORDERS.UPDATE_ORDER_IN_CONTROL_ORDERS,
			payload: [data].map(record =>
				formatData(
					record,
					profile.organization.tz,
					profile.user.settings.date_format,
				),
			)[0],
		});

		// Send message
		yield put({
			type: ORDERS.UPDATE_ORDER_IN_MODAL,
			payload: { loading: false, success: true },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.UPDATE_ORDER_IN_MODAL,
					payload: {
						loading: false,
						tryItAgain: true,
					},
				});
			},
			function* () {
				yield approveCompletedAuthorizationCodeWorker(action);
			},
		);
	}
}

function* createOneOrderWorker(action) {
	const ordersQueryModal = yield select(getOrdersQueryModal);
	const payload = action.payload;

	try {
		// Loading
		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'enterNewOrdersModal',
				keyValuePairs: { request: { status: 'sending' } },
			},
		});

		// Call api
		yield call(
			axios.post,
			API.DOMAIN.concat(`/orders/V1`),
			payload,
			auth.sendToken(),
		);

		yield put({
			type: ORDERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'enterNewOrdersModal',
				keyValuePairs: { isOpen: false, request: { status: 'success' } },
			},
		});
		// Get Orders
		yield put({
			type: ORDERS.GET_CONTROL_ORDERS,
			payload: ordersQueryModal,
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'enterNewOrdersModal',
						keyValuePairs: { request: { status: 'error' } },
					},
				});
			},
			function* () {
				yield createOneOrderWorker(action);
			},
		);
	}
}

function* getAllExpertsInfoWorker(action) {
	try {
		yield put(
			mutate1Object('experts', {
				isLoading: true,
			}),
		);

		// const { data } = yield call(
		//   axios.get,
		//   `${API.DOMAIN.concat(`/orders/getOrganizationExperts`)}`,
		//   auth.sendToken()
		// );

		const data = [
			{
				assignedTechId: 1,
				assignedTechName: 'Edgard Morales',
				workCode: 'sir2321',
				realizableOrderCount: 4,
				affectationOrderCount: 3,
				busy: 'libre', // ocupado, muy ocupado, libre,
				zoneId: 1,
				zoneName: 'Primer zona',
			},
			{
				assignedTechId: 2,
				assignedTechName: 'Juan Carlos',
				workCode: 'sir2322',
				realizableOrderCount: 2,
				affectationOrderCount: 7,
				busy: 'libre', // ocupado, muy ocupado, libre,
				zoneId: 2,
				zoneName: 'Segunda zona',
			},
			{
				assignedTechId: 3,
				assignedTechName: 'Luis Gonzalez',
				workCode: 'sir2323',
				realizableOrderCount: 6,
				affectationOrderCount: 7,
				busy: 'muy ocupado', // ocupado, muy ocupado, libre,
				zoneId: 1,
				zoneName: 'Primer zona',
			},
			{
				assignedTechId: 4,
				assignedTechName: 'Victor Sevilla',
				workCode: 'sir2324',
				realizableOrderCount: 1,
				affectationOrderCount: 8,
				busy: 'ocupado', // ocupado, muy ocupado, libre,
				zoneId: 2,
				zoneName: 'Segunda zona',
			},
			{
				assignedTechId: 5,
				assignedTechName: 'Edgard Morales',
				workCode: 'sir2325',
				realizableOrderCount: 10,
				affectationOrderCount: 8,
				busy: 'ocupado', // ocupado, muy ocupado, libre,
				zoneId: 2,
				zoneName: 'Segunda zona',
			},
			{
				assignedTechId: 6,
				assignedTechName: 'Steve Jobs',
				workCode: 'sir2326',
				realizableOrderCount: 10,
				affectationOrderCount: 6,
				busy: 'ocupado', // ocupado, muy ocupado, libre,
				zoneId: 3,
				zoneName: 'Tercera zona',
			},
			{
				assignedTechId: 7,
				assignedTechName: 'Dennis Ritchie',
				workCode: 'sir2327',
				realizableOrderCount: 9,
				affectationOrderCount: 9,
				busy: 'ocupado', // ocupado, muy ocupado, libre,
				zoneId: 3,
				zoneName: 'Tercera zona',
			},
			{
				assignedTechId: 8,
				assignedTechName: 'Steve Woz',
				workCode: 'sir2328',
				realizableOrderCount: 10,
				affectationOrderCount: 6,
				busy: 'ocupado', // ocupado, muy ocupado, libre,
				zoneId: 5,
				zoneName: 'Quinta zona',
			},
			{
				assignedTechId: 9,
				assignedTechName: 'Dennis Morales',
				workCode: 'sir2329',
				realizableOrderCount: 9,
				affectationOrderCount: 9,
				busy: 'ocupado', // ocupado, muy ocupado, libre,
				zoneId: 4,
				zoneName: 'Cuarta zona',
			},
			{
				assignedTechId: 10,
				assignedTechName: 'Elon',
				workCode: 'sir23210',
				realizableOrderCount: 10,
				affectationOrderCount: 6,
				busy: 'ocupado', // ocupado, muy ocupado, libre,
				zoneId: 4,
				zoneName: 'Cuarta zona',
			},
			{
				assignedTechId: 11,
				assignedTechName: 'Tim Cook',
				workCode: 'sir23211',
				realizableOrderCount: 7,
				affectationOrderCount: 9,
				busy: 'ocupado', // ocupado, muy ocupado, libre,
				zoneId: 4,
				zoneName: 'Cuarta zona',
			},
		];

		yield put(
			mutate1Object('experts', {
				data,
				isLoading: false,
			}),
		);
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put(
					mutate1Object('experts', {
						data: [],
						isLoading: false,
					}),
				);
			},
			function* () {
				yield getAllExpertsInfoWorker(action);
			},
		);
	}
}

//* ******* FUNCTIONS ***********/

//  Export default Root Saga
export default function* rootSaga() {
	yield all([
		onSendOrdersToUserAssignmentWatcher(),
		onSendImportOrdersToProcessAndSaveWatcher(),
		onSaveOrdersReassignmentToUserWatcher(),
		saveOrderReassignmentWatcher(),
		watch_GetPhotoReport(),
		watch_SendNewIncomeZones(),
		watch_GetControlOrders(),
		watch_SendOrderEvent(),
		getCountOrdersToReassignmentWatcher(),
		watch_UpdateOrdersSort(),
		getOrderWatcher(),
		// watch_GetOrderHistory(),
		watch_GetInfoOrdersIncome(),
		makeDeepSearchWatcher(),
		sendCommentReportWatcher(),
		sendRejectInventoryValidationWatcher(),
		revertBillWatcher(),
		setOrderPropsWatcher(),
		onOrdersExistsValidateWatcher(),
		approveCompletedAuthorizationCodeWatcher(),
		createOneOrderWatcher(),
		getAllExpertsInfoWatcher(),
	]);
}
