import Immutable from 'immutable';
import { filterDataByFilterOptions } from '../';

function filterOrders(orders, filters) {
	let filteredOrders = Immutable.List(orders).toJS();
	filteredOrders = filterDataByFilterOptions(filteredOrders, filters);
	return filteredOrders;
}

export { filterOrders };
