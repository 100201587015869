// Libs
import React from 'react';
// Components
import ReceptionDesktop from './Desktop';
import ReceptionMobile from './Mobile';

function Reception(props) {
	const { modeView } = props;

	return (
		<div className='animated fadeIn'>
			{modeView === 'table' && <ReceptionDesktop {...props} />}
			{modeView === 'cards' && <ReceptionMobile {...props} />}
		</div>
	);
}

export default Reception;
