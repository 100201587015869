import { lazy } from 'react';

/**
 * Welcome to ElementViewBuilderConfig
 * Max steps to implement new ElementView: 4
 */

import KEYWORDS from 'core/keywords';
import PhotoReportViewer from './views/PhotoReportViewer/PhotoReportViewer';
import ChartViewer from './views/ChartViewer/ChartViewer';
import OrderViewer from './views/OrderViewer/OrderViewer';
import MapViewer from './views/MapViewer/MapViewer';

/**
 * Step 1: Import custom element view
 */
const { ROUTES } = KEYWORDS;
// <--HERE

/**
 * Step 2: Add custom element view keys
 */
const authorizedElementViewKeys = {
	SUPERVISOR_ELEMENT: `${ROUTES.FEED.PATH}/supervisor/:elementId`,
	CHART_ELEMENT: `${ROUTES.FEED.PATH}/analytics/:elementId`,
	ORDER_ELEMENT: `${ROUTES.FEED.PATH}/orders/:elementId`,
	MAP_ELEMENT: `${ROUTES.FEED.PATH}/map/:elementId`,
	// <--HERE
};

/**
 * Step 3: Add custom element view key value pairs
 */
const authorizedElementViewComponents = {
	[authorizedElementViewKeys.SUPERVISOR_ELEMENT]: PhotoReportViewer,
	[authorizedElementViewKeys.CHART_ELEMENT]: ChartViewer,
	[authorizedElementViewKeys.ORDER_ELEMENT]: OrderViewer,
	[authorizedElementViewKeys.MAP_ELEMENT]: MapViewer,
	// <--HERE
};

/**
 * Step 4: Add custom element view key value pairs
 */
const authorizedElementViewConfig = {
	[authorizedElementViewKeys.SUPERVISOR_ELEMENT]: {
		maxWidth: '100%',
		placement: 'right',
		noOverflow: true,
	},
	[authorizedElementViewKeys.CHART_ELEMENT]: {
		maxWidth: '800px',
		placement: 'right',
		noOverflow: true,
	},
	[authorizedElementViewKeys.ORDER_ELEMENT]: {
		maxWidth: '700px',
		placement: 'right',
		noOverflow: false,
	},
	[authorizedElementViewKeys.MAP_ELEMENT]: {
		maxWidth: '100%',
		placement: 'left',
		noOverflow: false,
	},
	// <--HERE
};

class ElementViewBuilderConfig {
	static getAuthorizedElementViewKeys() {
		return authorizedElementViewKeys;
	}

	static getAuthorizedElementViewReactNodes() {
		return authorizedElementViewComponents;
	}

	static getAuthorizedElementViewReactNode(elementViewKey) {
		return authorizedElementViewComponents[elementViewKey];
	}

	static getAuthorizedElementViewConfig(elementViewKey) {
		return authorizedElementViewConfig[elementViewKey] || {};
	}
}

export default ElementViewBuilderConfig;
