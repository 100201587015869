// Libs
import dayjs from 'dayjs';
// Utils
import sortBy from 'utils/orderBy';
import GENERAL from 'utils/constants/general';
const { ENV } = GENERAL;

const DEFAULT_MILLISECONDS = 0;
const NOT_CONFIGURED = {};

export const checkDayWord = days => {
	if (days <= 0) return '';
	const unit = days === 1 ? 'día' : 'días';
	return `${days} ${unit}`;
};

export const checkAddZero = count => (count < 10 ? `0${count}` : `${count}`);

export const checkExpiredTime = timeRemaining =>
	parseInt(timeRemaining) === DEFAULT_MILLISECONDS;

export const calculateDifference = diff => ({
	days: Math.floor(diff / (1000 * 60 * 60 * 24)),
	hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
	minutes: Math.floor((diff / 1000 / 60) % 60),
	seconds: Math.floor((diff / 1000) % 60),
});

const checkValidParamsTimeRemaining = ({ startAt, timeRemaining }) =>
	startAt && typeof startAt === 'string' && !isNaN(parseInt(timeRemaining));

const checkValidParamsTimeElapsed = ({ startAt, timeElapsed }) =>
	startAt && typeof startAt === 'string' && !isNaN(parseInt(timeElapsed));

const calculateTimeRemaining = ({ timeRemaining, startAt, freezeTimer }) => {
	// Check valid params
	if (!checkValidParamsTimeRemaining({ startAt, timeRemaining })) {
		return NOT_CONFIGURED;
	}

	// Freeze timer?
	if (freezeTimer) {
		return {
			timeRemaining,
			freezeTimer,
		};
	}

	// Expired remaining time?
	const now = dayjs();
	const expirationAt = dayjs(startAt).add(timeRemaining); // Get total remaining time
	const expirationTime = expirationAt.diff(now);
	if (expirationTime < 0) {
		return {
			timeRemaining: DEFAULT_MILLISECONDS,
		};
	}

	// Return expiration times
	return {
		timeRemaining: expirationTime,
		expirationAt,
	};
};

const calculateTimeElapsed = ({ timeElapsed, startAt, freezeTimer }) => {
	// Check valid params
	if (!checkValidParamsTimeElapsed({ startAt, timeElapsed })) {
		return NOT_CONFIGURED;
	}

	// Freeze timer?
	if (freezeTimer) {
		return {
			timeElapsed,
			freezeTimer,
		};
	}

	// Now is before than start at?
	const now = dayjs();
	const diff = now.diff(startAt);
	if (diff < 0) {
		return {
			timeElapsed,
		};
	}

	// Return total elapsed time
	const totalElapsed = dayjs(diff).add(timeElapsed);
	return {
		timeElapsed: totalElapsed,
	};
};

export const calculateTimers = ({
	startAt,
	timeElapsed,
	timeRemaining,
	freezeTimer,
}) => ({
	timeRemaining: calculateTimeRemaining({
		timeRemaining,
		startAt,
		freezeTimer,
	}),
	timeElapsed: calculateTimeElapsed({ timeElapsed, startAt, freezeTimer }),
});

export const setOrderSortTimer = ({ orders, time }) => {
	const REMAINING = 'remaining';
	const opts = {
		calculateTime:
			time === REMAINING ? calculateTimeRemaining : calculateTimeElapsed,
		calculatedTime: time === REMAINING ? 'timeRemaining' : 'timeElapsed',
		timeProp: time === REMAINING ? 'time_remaining' : 'time_elapsed',
	};

	orders.map(order => {
		// Get sort timer position
		const sortTimer = opts.calculateTime({
			[opts.calculatedTime]: order[opts.timeProp],
			startAt: order.start_at,
			freezeTimer: order.event_props?.[ENV.MANAGE_PROPS.FLAGS.FREEZE_TIMER],
		})?.[opts.calculatedTime];
		// Sort timer is number?
		if (Number(sortTimer)) {
			order.sortTimer = sortTimer;
		} else {
			order.sortTimer = 0;
		}
		return order;
	});

	sortBy(orders, 'object', { objectKey: 'sortTimer', reverse: true });
};
