// Libs
import React from 'react';
import { useTheme } from 'styled-components';
import _ from 'underscore';
import Immutable from 'immutable';
// Utils
import orderBy from '../../../../../../utils/orderBy';
import { formatNumber } from '../../../../../../utils/libs';
// Components
import { ResponsiveLine } from '@nivo/line';
import { Wrapper, Span } from 'components';

const themeTemp = {
	axis: {
		legend: {
			text: {
				fill: '#fff',
				fontSize: 12,
				fontFamily: 'Roboto',
			},
		},
		ticks: {
			text: {
				fill: '#fff',
				fontSize: 12,
				fontFamily: 'Roboto',
			},
		},
	},
};

function formatPaycuts(paycuts) {
	const pycts = Immutable.List(paycuts).toJS();
	orderBy(pycts, 'object', { objectKey: 'id' });

	return [
		{
			id: 'Venta',
			color: 'hsl(217, 70%, 50%)',
			data: pycts.reduce((acc, paycut) => {
				acc.push({
					x: paycut.id,
					y: paycut.production_charge,
					prodCharge: formatNumber.new(
						paycut.production_charge,
						paycut.coin_symbol,
						2,
					),
					prodAmount: formatNumber.new(
						paycut.production_amount,
						paycut.coin_symbol,
						2,
					),
					gainAmount: formatNumber.new(
						Number(paycut.production_charge) - Number(paycut.production_amount),
						paycut.coin_symbol,
						2,
					),
					sale: paycut.sale,
					cost: paycut.cost,
					name: paycut.name,
				});
				return acc;
			}, []),
		},
		{
			id: 'Costo',
			color: 'hsl(217, 70%, 50%)',
			data: pycts.reduce((acc, paycut) => {
				acc.push({
					x: paycut.id,
					y: paycut.production_amount,
					prodCharge: formatNumber.new(
						paycut.production_charge,
						paycut.coin_symbol,
						2,
					),
					prodAmount: formatNumber.new(
						paycut.production_amount,
						paycut.coin_symbol,
						2,
					),
					gainAmount: formatNumber.new(
						Number(paycut.production_charge) - Number(paycut.production_amount),
						paycut.coin_symbol,
						2,
					),
					sale: paycut.sale,
					cost: paycut.cost,
					name: paycut.name,
				});
				return acc;
			}, []),
		},
	];
}

export default function PaycutsProductionLine({
	paycuts,
	paymentsAnalytics,
	mutate1Object,
	getPayments,
}) {
	const formattedData = formatPaycuts(paycuts);
	const theme = useTheme();

	return (
		<Wrapper width='100%' height='500px'>
			<ResponsiveLine
				data={formattedData}
				margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
				theme={themeTemp}
				curve={'monotoneY'}
				enableArea={true}
				tooltip={({ point }) => {
					const { data, serieId } = point;
					const { prodAmount, prodCharge, gainAmount, cost, sale, name } = data;

					return (
						<Wrapper
							flexDirection='column'
							alignItems='flex-start'
							backgroundColor={theme.colors.secondary[800]}
						>
							{/* NAME */}
							<Span fontWeight='bold' fontSize='xl'>
								{name}
							</Span>
							{/* VENTA */}
							<Wrapper
								width='100%'
								padding='0 10px 0 10px'
								justifyContent='space-between'
							>
								<Span fontSize='m' color={theme.fonts.colors.concept}>
									Venta
								</Span>
								<Span fontSize='m' color={theme.fonts.colors.value}>
									{prodCharge} ({sale}%)
								</Span>
							</Wrapper>
							{/* COSTO */}
							<Wrapper
								width='100%'
								padding='0 0 0 10px'
								justifyContent='space-between'
							>
								<Span fontSize='m' color={theme.fonts.colors.concept}>
									Costo
								</Span>
								<Span fontSize='m' color={theme.fonts.colors.value}>
									{prodAmount} ({cost}%)
								</Span>
							</Wrapper>
							{/* GAIN */}
							<Wrapper
								width='100%'
								padding='0 0 0 10px'
								justifyContent='space-between'
							>
								<Span fontSize='m' color={theme.fonts.colors.concept}>
									Ganancia
								</Span>
								<Span fontSize='m' color={theme.fonts.colors.value}>
									{gainAmount}
								</Span>
							</Wrapper>
							{/* <Wrapper width="100%" padding="10px 0 0 10px">
                <Span fontSize="m" cursor="pointer" hoverColor="lightgray">
                  Ver pagos
                </Span>
              </Wrapper> */}
						</Wrapper>
					);
				}}
				onClick={({ data }) => {
					const { x } = data;
					mutate1Object('pays', {
						analytics: { ...paymentsAnalytics, isOpen: true },
					});
					getPayments([x]);
				}}
				xScale={{ type: 'point' }}
				yScale={{
					type: 'linear',
					min: 'auto',
					max: 'auto',
					stacked: false,
					reverse: false,
				}}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					orient: 'bottom',
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'Cortes',
					legendOffset: 36,
					legendPosition: 'middle',
				}}
				axisLeft={{
					orient: 'left',
					tickSize: 0, // 5,
					tickPadding: 0, // 5,
					tickRotation: 48, // 0,
					legend: 'Montos',
					legendOffset: -54, // -40,
					legendPosition: 'middle',
				}}
				// enableSlices="x"
				colors={{ scheme: 'nivo' }}
				pointSize={10}
				pointColor={{ theme: 'background' }}
				pointBorderWidth={2}
				pointBorderColor={{ from: 'serieColor' }}
				pointLabel='y'
				pointLabelYOffset={-12}
				useMesh={true}
				legends={[
					{
						anchor: 'bottom-right',
						direction: 'column',
						justify: false,
						translateX: 100,
						translateY: 0,
						itemsSpacing: 0,
						itemDirection: 'left-to-right',
						itemWidth: 80,
						itemHeight: 20,
						itemOpacity: 0.75,
						symbolSize: 12,
						symbolShape: 'circle',
						symbolBorderColor: 'rgba(0, 0, 0, .5)',
						itemTextColor: '#fff',
						effects: [
							{
								on: 'hover',
								style: {
									itemBackground: 'rgba(0, 0, 0, .03)',
									itemOpacity: 1,
								},
							},
						],
					},
				]}
			/>
		</Wrapper>
	);
}
