export default {
	separador: ',', // separador para los miles
	sepDecimal: '.', // separador para los decimales
	formatear: function (num) {
		num += '';
		const splitStr = num.split(',');
		let splitLeft = splitStr[0];
		const splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
		const regx = /(\d+)(\d{3})/;
		while (regx.test(splitLeft)) {
			splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
		}
		return this.simbol + splitLeft + splitRight;
	},
	new: function (num, simbol, fixed) {
		this.simbol = simbol || '';
		return this.formatear(Number(num).toFixed(fixed));
	},
	setMask: function (num, mask = '#-####-####') {
		const str = num.toString();
		if (!str) return '';

		let idx = 0;
		return mask.split('').reduce((acc, char) => {
			acc += char !== '#' ? char : !isNaN(str[idx]) ? str[idx] : '';
			if (char === '#') idx++;
			return acc;
		}, '');
	},
};
