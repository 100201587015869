// Libs
import React from 'react';
// Utils
import { getHumanize } from 'utils/libs/dateFormats';
// Components
import { Acronym, Span, Wrapper, CopyToClipboard } from 'components';
import { PointTextIcon, StarIcon } from 'components/Icons';

const Header = ({ name, rating, createdAt }) => (
	<Wrapper padding='0' width='100%' margin='0 0 16px 0'>
		<Acronym text={name} />
		<Wrapper
			padding='0'
			margin='0 0 0 16px'
			flexDirection='column'
			alignItems='flex-start'
		>
			<Span maxWidth='235px' ellipsis>
				{name}
			</Span>
			<Wrapper padding='0'>
				<StarIcon />
				<Span disabled>{rating}</Span>
				<PointTextIcon />
				<Span maxWidth='170px' ellipsis disabled>
					{getHumanize({ date: createdAt })}
				</Span>
			</Wrapper>
		</Wrapper>
	</Wrapper>
);

const ConceptValueContainer = ({ children }) => (
	<Wrapper width='100%' padding='0' margin='0 0 10px 0'>
		{children}
	</Wrapper>
);

const ConceptValueLine = ({ concept, value }) => (
	<Wrapper padding='0'>
		{concept && (
			<Span margin='0 8px 0 0' disabled>
				{concept}
			</Span>
		)}
		<CopyToClipboard value={value}>
			<Span maxWidth='100px' ellipsis>
				{value}
			</Span>
		</CopyToClipboard>
	</Wrapper>
);

const Body = ({ idc, phones, email }) => (
	<Wrapper padding='0' flexDirection='column' width='288px'>
		<ConceptValueContainer>
			{/* Identification Card */}
			<ConceptValueLine concept='Cédula:' value={idc} />
		</ConceptValueContainer>

		<ConceptValueContainer>
			{/* Phones */}
			<ConceptValueLine concept='Teléfono:' value={phones || 'ND'} />
			<PointTextIcon />
			{/* Email */}
			<ConceptValueLine concept='Correo:' value={email || 'ND'} />
		</ConceptValueContainer>
	</Wrapper>
);

const ClientPostV1 = ({ record, isNearScreen }) => {
	return (
		<Wrapper mediumBackground maxWidth='320px' flexDirection='column'>
			<Header
				name={record.name}
				rating={record.rating}
				createdAt={record.createdAt}
			/>
			<Body idc={record.idc} phones={record.phones} email={record.email} />
		</Wrapper>
	);
};

export default ClientPostV1;
