// Libs
import React from 'react';
import useTheme from 'hooks/useTheme';
// Components
import Icon from './Icon';

const StarIcon = ({ onClick }) => {
	const { theme } = useTheme();

	return <Icon fill={theme._inactiveColor} onClick={onClick} />;
};

export default StarIcon;
