import React from 'react';
import styled, { css } from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Span, Wrapper } from 'components';

import { Input as _Input } from 'antd';

const FieldName = styled(Span)`
	margin-left: 4px;
	font-size: xl;
	font-weight: bold;
	color: #00d5ad;
	letter-spacing: 0.05em;
	${props =>
		props.required &&
		css`
			&:after {
				content: ' *';
				color: #eb383e;
			}
		`}
`;

const Input = styled(_Input)`
	margin-bottom: 10px;
	background: transparent;
	color: #a5a5a5;

	.ant-input,
	.ant-input-group-addon {
		background: transparent;
		color: #a5a5a5;
	}
`;

const SectionDescription = ({ children }) => (
	<Wrapper
		margin='5px 0 0 0'
		padding='2px'
		flexDirection='column'
		alignItems='inherit'
	>
		{children}
	</Wrapper>
);

const LineDescription = ({
	wrapperStyle,
	conceptStyle,
	valueStyle,
	concept,
	value,
	padding,
}) => (
	<Wrapper
		style={{ ...wrapperStyle }}
		padding={padding || '2px'}
		justifyContent='space-between'
		alignItems='baseline'
	>
		<Span
			color='mediumaquamarine'
			style={{ marginRight: '10px', ...conceptStyle }}
		>
			{concept}
		</Span>
		<CopyToClipboard text={value}>
			<Span hovarable style={{ textAlign: 'end', ...valueStyle }}>
				{value}
			</Span>
		</CopyToClipboard>
	</Wrapper>
);

export default {
	FieldName,
	Input,
	SectionDescription,
	LineDescription,
};
