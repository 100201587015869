// Libs
import React, { useContext, useState } from 'react';
// Context
import { ModalBuilderContext } from 'core/context';
// Hooks
import { useOrderEventAssignment } from 'hooks';
// Components
import {
	Wrapper,
	Modal,
	Button,
	TextField,
	Title,
	Subtitle,
	DatePicker,
	TimePicker,
} from 'components';
import Updating from 'components/DataFieldManager/Updating';
// Styles
import styles from './UpdateOrderEvent.module.css';

const { TextArea } = TextField;

const UpdateOrderEvent = () => {
	const { payload: modalPayload, cleanModal } = useContext(ModalBuilderContext);
	const { state: eventState, onSetEventAssignment } = useOrderEventAssignment();
	const [state, _setState] = useState({
		reprogramDate: undefined,
		reprogramTime: undefined,
		comment: undefined,
	});
	const setState = newProps =>
		_setState(current => ({ ...current, ...newProps }));

	return (
		<Modal
			title={null}
			closable={false}
			centered={false}
			visible={true}
			onCancel={cleanModal}
			footer={null}
		>
			<Wrapper
				highBackground
				width='100%'
				margin='0'
				alignItems='flex-start'
				flexDirection='column'
			>
				<Title>Establecer Evento</Title>
				<Subtitle margin='16px 0 0 0'>
					Orden: {modalPayload.rowData.odtId}
				</Subtitle>
				<Subtitle margin='0 0 10px 0'>
					Evento: {modalPayload.option.name}
				</Subtitle>
				{modalPayload.option.props?.reprogram && (
					<Wrapper
						width='50%'
						padding='0'
						margin='0 0 10px 0'
						flexDirection='column'
						alignItems='stretch'
					>
						{/* PROGRAM DATE */}
						<DatePicker
							margin='0 0 5px 0'
							placeholder='Fecha*'
							value={state.reprogramDate}
							onChange={value => setState({ reprogramDate: value })}
						/>

						{/* PROGRAM TIME */}
						<TimePicker
							placeholder='Hora*'
							value={state.reprogramTime}
							onChange={value => setState({ reprogramTime: value })}
						/>
					</Wrapper>
				)}
				<TextArea
					allowClear
					placeholder='Comentario*'
					rows={3}
					value={state.comment}
					onChange={e => setState(e.target.value)}
				/>
				<Wrapper
					width='100%'
					margin='16px 0 0 0'
					padding='0'
					justifyContent='center'
				>
					{eventState.isSetting ? (
						<Updating className={styles.eventContainer} />
					) : (
						<Button
							onClick={() =>
								onSetEventAssignment(
									{
										...state,
										field: modalPayload.field,
										rowData: modalPayload.rowData,
										option: modalPayload.option,
									},
									cleanModal,
								)
							}
						>
							Confirmar
						</Button>
					)}
				</Wrapper>
			</Wrapper>
		</Modal>
	);
};

export default UpdateOrderEvent;
