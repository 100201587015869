import _ from 'underscore';

// Retorna la propiedad <get> de la moneda solicitada
/**
 * get: abbreviation, symbol, name, created_by, created_at
 */
export function getDataByCoinId(coinId, get, coins) {
	if (!coinId || !get || !Array.isArray(coins)) return null;

	const idx = _.findIndex(coins, coin => coin.id === coinId);
	if (idx === -1) {
		return null;
	}
	return coins[idx][get];
}
