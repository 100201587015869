// Libs
import React from 'react';
// Components
import { Title } from 'components';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
// Styles
import styles from './SimpleBar.module.css';

const SimpleBar = ({ data, config = {} }) => {
	const {
		width = 500,
		height = 300,
		values = ['amount', 'pv'],
		xDataKey = 'name',
		fill = {
			area1: '#8884d8',
			area2: '#82ca9d',
		},
	} = config;
	return (
		<div className={styles.simpleBarContainer} style={{ width, height }}>
			<ResponsiveContainer width='100%' height='100%'>
				<BarChart
					width={width}
					height={height}
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis dataKey={xDataKey} />
					<YAxis />
					<Tooltip />
					<Legend />
					<Bar dataKey={values[0]} fill={fill.area1} />
					<Bar dataKey={values[1]} fill={fill.area2} />
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default SimpleBar;
