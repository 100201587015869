// Hooks
import useTheme from 'hooks/useTheme';
// Styles
import styles from './Switch.module.css';

const Switch2 = ({ id = Math.random(), checked, onChange }) => {
	const { theme } = useTheme();
	return (
		<>
			<input
				type='checkbox'
				id={`${id}switch`}
				checked={checked}
				onChange={onChange}
				className={styles.switch}
			/>
			<label
				htmlFor={`${id}switch`}
				className={styles.point}
				style={{
					backgroundColor: checked
						? theme._secondaryColor
						: theme._disabledBackgroundColor,
				}}
			/>
		</>
	);
};

export default Switch2;
