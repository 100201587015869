export default class RealtimeUtils {
	static getMyProfileSubscriber(
		profile = {},
		{ companyId, organizationId, entityId, userId } = {},
	) {
		const { company, organization, entity, user } = profile;
		if (!company?.id || !organization?.id || !entity?.id || !user?.id) return;

		return {
			companyId: companyId || company.id,
			organizationId: organizationId || organization.id,
			entityId: entityId || entity.id,
			userId: userId || user.id,
		};
	}

	static findTopicInSubscribedTopics(action, subscribedActions = {}) {
		return Object.keys(subscribedActions).find(
			subscribedAction => subscribedAction === action,
		);
	}
}
