// Libs
import React, { useContext } from 'react';
// Context
import { ElementViewBuilderContext } from 'core/context';
// Config
import { ElementViewBuilderConfig } from 'core/ElementViewBuilder';
// Components
import { ChartBuilder } from 'components';

const ChartPostV1 = ({ record }) => {
	const { setElementView } = useContext(ElementViewBuilderContext);
	const elementViewKeys =
		ElementViewBuilderConfig.getAuthorizedElementViewKeys();

	return (
		<ChartBuilder
			chart={{ id: record.id }}
			onSetElementView={() =>
				setElementView({
					elementViewKey: elementViewKeys.CHART_ELEMENT,
					elementId: record.id,
				})
			}
		/>
	);
};

export default ChartPostV1;
