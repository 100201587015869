// Libs
import React from 'react';
// Config
import ChartBuilderConfig from './chart.config';
// Components
import { Wrapper, Title } from 'components';

const ChartTableBuilder = ({ title = 'Detalle', key, data, config }) => {
	const ChartTable = ChartBuilderConfig.getAuthorizedChartTableReactNode(key);
	if (!ChartTable) return null;

	return (
		<Wrapper
			padding='0'
			width='100%'
			flexDirection='column'
			alignItems='flex-start'
		>
			<Title margin='0 0 0 10px'>{title}</Title>
			<ChartTable data={data} config={config} />
		</Wrapper>
	);
};

export default ChartTableBuilder;
