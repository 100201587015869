import ApiService from 'services/apiService/apiService';

export const fetchComments = async ({
	resourceKey,
	resourceId,
	parentId,
	limit = 10,
	lastRowId = 0,
}) => {
	const data = await ApiService.fetch(ApiService.endpoints().GET_COMMENTS, {
		reqParams: {
			resourceKey,
			resourceId,
			parentId: parentId || null,
			lastRowId,
			limit,
		},
	});

	return data;
};

export const submitComment = async ({
	resourceKey,
	resourceId,
	parentId,
	content,
}) => {
	const data = await ApiService.fetch(ApiService.endpoints().SUBMIT_COMMENT, {
		bodyParams: {
			resourceKey,
			resourceId,
			parentId: parentId ?? null,
			message: content,
		},
	});

	return data;
};
