// Libs
import React, { useCallback, useEffect, useState } from 'react';
// Utils
import { debounce } from 'utils/libs';
// Hooks
import { useCollectorManager } from 'hooks';
// Components
import { Wrapper } from 'components';
import { EmptyIllustration } from 'components/Illustrations';
// Styles
import styles from './CollectorBuilder.module.css';
// Components
import Collector from '../Collector';

const CollectorBuilder = ({
	isMobile,
	photoReport,
	selectedIds,
	logo,
	isCollectorFetching,
	reviewing,
	onFetchedCollector,
	onUpdatedCoordSuccess,
}) => {
	const { id: otdId, templateId, serviceId } = photoReport;
	const { getCollectorLayout } = useCollectorManager();
	const [showSkeleton, setShowSkeleton] = useState(true);
	const [collectors, setCollectors] = useState(true);
	const {
		selectedTaskId,
		selectedReviewId,
		selectedGroupId,
		selectedSubgroupId,
	} = selectedIds;

	// Debounced call
	const debounced = useCallback(
		debounce(callback => callback(), 200),
		[],
	);

	// Fetch Collectors
	const combinedIdx = `${selectedTaskId}${selectedReviewId}${selectedGroupId}${selectedSubgroupId}`;
	useEffect(() => {
		setCollectors([]);
		setShowSkeleton(true);
	}, [combinedIdx]);

	useEffect(() => {
		let isMounted = true;
		if (
			!selectedTaskId ||
			!selectedReviewId ||
			selectedGroupId === undefined ||
			selectedSubgroupId === undefined ||
			!isCollectorFetching
		)
			return;
		debounced(() =>
			getCollectorLayout({
				otdId,
				templateId,
				serviceId,
				serviceTaskId: selectedTaskId,
				reviewId: selectedReviewId,
				groupId: selectedGroupId,
				subgroupId: selectedSubgroupId,
				filled: true, // TODO: Quitar hardcoded
			}).then(collectors => {
				if (!isMounted) return;
				setShowSkeleton(false);
				setCollectors(collectors);
				onFetchedCollector();
			}),
		);
		return () => {
			isMounted = false;
		};
	}, [isCollectorFetching]);

	// No data
	if (!showSkeleton && !collectors[0]) {
		return (
			<div className={styles.groupWrapper}>
				<EmptyIllustration
					title='No hay datos en esta sección'
					width={!isMobile ? '400px' : '200px'}
					fontSize='2em'
				/>
			</div>
		);
	}

	return (
		<div className={styles.groupWrapper}>
			<Wrapper
				padding='0'
				width='98%'
				height={'calc(100% - 56px)'}
				justifyContent='center'
			>
				<div
					className={
						isMobile ? styles.collectorsWrapperMobile : styles.collectorsWrapper
					}
				>
					{!showSkeleton ? (
						collectors.map(coll => (
							<Collector
								key={coll.id}
								otdId={otdId}
								collector={coll}
								isMobile={isMobile}
								serviceTaskId={selectedTaskId}
								logo={logo}
								reviewing={reviewing}
								onUpdatedCoordSuccess={onUpdatedCoordSuccess}
							/>
						))
					) : (
						<>
							<Collector.Skeleton active={true} isMobile={isMobile} />
							<Collector.Skeleton active={true} isMobile={isMobile} />
						</>
					)}
				</div>
			</Wrapper>
		</div>
	);
};

export default CollectorBuilder;
