import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ElementViewBuilderContext } from 'core/context';
//Components
import { Drawer, LeftArrowsIcon, Title } from 'components';
import Map from 'components/Map/Map';
import { MapDrawerToolbar } from './Toolbar';
import { PhotoReportMenu } from '../PhotoReportViewer/Menu';
// Selectors
import { selectDatasource } from 'core/selectors';
// Hooks
import { useCollectorManager } from 'hooks';

const MapViewer = ({ isMobile }) => {
	const routeParams = useParams();
	const { getPhotoReport } = useCollectorManager();
	const datasource = useSelector(selectDatasource);
	const { payload = {}, cleanElementView } = useContext(
		ElementViewBuilderContext,
	);
	const elementId = routeParams.elementId || payload.elementId;
	const [s, _setState] = useState({
		selectedTaskId: undefined,
		selectedReviewId: undefined,
		selectedGroupId: undefined,
		selectedSubgroupId: undefined,
		isCollectorFetching: false,
		photoReport: undefined,
		reviewing: false,
	});
	const setState = newProps =>
		_setState(current => ({ ...current, ...newProps }));

	const [isQuickNavigationDrawerVisible, setIsQuickNavigationDrawerVisible] =
		useState(true);

	// Get PhotoReport
	useEffect(() => {
		const _photoReport = datasource.data.find(
			item => item.id.toString() === elementId,
		);
		if (_photoReport) {
			setState({ photoReport: _photoReport });
			return;
		}
		getPhotoReport({ elementId }).then(photoReport =>
			setState({ photoReport }),
		);
		return () => {
			cleanElementView();
		};
	}, [elementId]);

	const toggleQuickNavigationDrawer = () =>
		setIsQuickNavigationDrawerVisible(!isQuickNavigationDrawerVisible);

	const canRenderMenu =
		s.photoReport?.id && s.photoReport.serviceId && s.photoReport.templateId;
	return (
		<>
			<MapDrawerToolbar
				onClose={cleanElementView}
				quickNavigation={toggleQuickNavigationDrawer}
			/>
			<Map
				photoReport={s.photoReport}
				serviceTaskId={s.selectedTaskId}
				selectedMarkerId={s.selectedReviewId}
			/>
			<Drawer
				key='elementViewContainer'
				className='animated fadeIn faster'
				height='100%'
				width='100%'
				$maxWidth='300px'
				closable={false}
				open={isQuickNavigationDrawerVisible}
				placement='left'
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						paddingLeft: '10px',
						margin: '10px',
					}}
				>
					<Title>Navegación Rápida</Title>
					<LeftArrowsIcon onClick={toggleQuickNavigationDrawer} />
				</div>
				{canRenderMenu && (
					<PhotoReportMenu
						isMobile={isMobile}
						photoReport={s.photoReport}
						selectedIds={{
							selectedTaskId: s.selectedTaskId,
							selectedReviewId: s.selectedReviewId,
							selectedGroupId: s.selectedGroupId,
							selectedSubgroupId: s.selectedSubgroupId,
						}}
						onMenuItemClick={props => {
							setState(props);
						}}
					/>
				)}
			</Drawer>
		</>
	);
};

export default MapViewer;
