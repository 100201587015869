import orderBy from '../orderBy';

// Returns options to Header Filter
/*
 *orderType: [text, numeric, date, object]
 */
function getFilterOptionsList(dataKey, filters, data, orderType, dateFormat) {
	// Existe algún otro filtro seleccionado?
	if (Object.keys(filters).filter(flt => flt !== dataKey).length >= 1) {
		var options = data.reduce((acc, row) => {
			const option = row[dataKey];

			if (option !== undefined && option !== null) {
				let approved = true;

				for (const key in filters) {
					if (key !== dataKey) {
						if (filters[key].checkedList.indexOf(row[key]) === -1) {
							approved = false;
						}
					}
				}

				if (approved && acc.indexOf(option.toString()) === -1) {
					acc.push(option.toString());
				}
			}

			return acc;
		}, []);
	}
	// No hay otros filtros seleccionados?
	else {
		var options = data.reduce((acc, row) => {
			const option = row[dataKey];
			if (option !== undefined && option !== null) {
				if (acc.indexOf(option.toString()) === -1) {
					acc.push(option.toString());
				}
			}
			return acc;
		}, []);
	}

	orderBy(options, orderType, { dateFormat });

	return options;
}

function filterDataByFilterOptions(data, filters) {
	if (!Object.keys(filters).length) return data;
	for (const dataKey in filters) {
		const { checkedList } = filters[dataKey];
		const lowerCheckedList = checkedList.map(item => item.toLowerCase());

		data = data.filter(row => {
			const rowValue = row[dataKey]?.toString().toLowerCase();
			if (rowValue) return lowerCheckedList.indexOf(rowValue) !== -1;
		});
	}
	return data;
}

function filterDataBySelectedOptions(data, selected, key) {
	const { checkAll, items } = selected;
	if (checkAll) return data;
	if (items.length === 0) return data;
	return data.filter(row => items.indexOf(row[key]) !== -1);
}

// Checkbox & Search Input Events
function onChangeFilter(props, dataKey, filters, onChangedFilter) {
	const _filters = { ...filters };
	if (props.checkAll) {
		delete _filters[dataKey];
	} else {
		_filters[dataKey] = {
			..._filters[dataKey],
			...props,
		};
	}
	onChangedFilter(_filters);
}

function getSearchInputProps(e, options) {
	const value = e.target.value.toLowerCase();
	const checkedList = options.filter(option =>
		option.toLowerCase().includes(value),
	);

	return {
		inputSearch: value,
		checkedList,
		indeterminate: !!checkedList.length && checkedList.length < options.length,
		checkAll: checkedList.length === options.length,
	};
}

function getCheckAllProps(e, options) {
	return {
		checkedList: e.target.checked ? options : [],
		indeterminate: false,
		checkAll: e.target.checked,
		inputSearch: undefined,
	};
}

function getCheckProps(checkedList, options) {
	return {
		checkedList,
		indeterminate: !!checkedList.length && checkedList.length < options.length,
		checkAll: checkedList.length === options.length,
	};
}

export {
	getFilterOptionsList,
	filterDataByFilterOptions,
	filterDataBySelectedOptions,
	onChangeFilter,
	getSearchInputProps,
	getCheckAllProps,
	getCheckProps,
};
