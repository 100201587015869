import { createSelector } from 'reselect';

const selectReportState = state => state.reports;

export const selectReportControl = createSelector(
	[selectReportState],
	reports => reports.control,
);

export const selectIsFetching = createSelector(
	[selectReportState],
	reports => reports.control.isFetching,
);

export const selectReportList = createSelector(
	[selectReportState],
	reports => reports.control.reports,
);
export const selectGenerateReportModal = createSelector(
	[selectReportState],
	reports => reports.generateReportModal,
);
