// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Utils
import { getSelectedPaycuts } from 'utils/filters/Billings/paycuts';
// Components
import {
	Wrapper,
	IconButton,
	Dropdown,
	Menu,
	Span,
	EyeIcon,
	RefreshIcon,
	LogoIcon,
} from 'components';

export default function Pays({
	payments,
	pays,
	paycuts,
	getPayments,
	mutate1ObjectInBillings,
	getPaymentResume,
	modeView,
}) {
	const theme = useTheme();
	const selectedPaycuts = getSelectedPaycuts(
		payments.paycuts.data,
		paycuts.selected.items,
	);
	const firstSelectedPaycut = selectedPaycuts[0];

	const MenuViewButton = (
		<Menu.Wrapper>
			<Menu.Item
				onClick={() => {
					mutate1ObjectInBillings('signPaymentModal', { isOpen: true });
					getPaymentResume(pays.selected.items);
				}}
			>
				Resumen
			</Menu.Item>
		</Menu.Wrapper>
	);

	return (
		<Wrapper
			height='48px'
			justifyContent='flex-start'
			padding='10px 0 10px 10px'
			mediumBackground
		>
			<Wrapper width='100%' padding='0'>
				{selectedPaycuts.length > 1 ? (
					<Span
						fontWeight='bold'
						fontSize='l'
						color={theme.fonts.colors.default}
					>
						Cortes fusionados: {selectedPaycuts.length}
					</Span>
				) : (
					<Span
						fontWeight='bold'
						fontSize='l'
						color={theme.fonts.colors.default}
					>
						Corte: {firstSelectedPaycut?.name}
					</Span>
				)}
			</Wrapper>

			<Wrapper padding='0'>
				{/* UPDATE PAYMENTS */}
				{!payments.pays.loading ? (
					<RefreshIcon onClick={() => getPayments(paycuts.selected.items)} />
				) : (
					<LogoIcon button spin />
				)}

				{/* VIEW */}
				{modeView === 'table' && pays.selected.items.length >= 1 && (
					<Dropdown.Wrapper
						placement='bottomRight'
						trigger={['click']}
						overlay={MenuViewButton}
					>
						<EyeIcon button />
					</Dropdown.Wrapper>
				)}
			</Wrapper>
		</Wrapper>
	);
}
