// Libs
import React from 'react';
import styled from 'styled-components';
// Components
import IconButton from '@mui/material/IconButton';
import icon from './full-star.svg';
import smallIcon from './small-full-star.svg';

const SIZES = {
	SMALL: 'small',
	NORMAL: 'normal',
};

const Icon = styled.div`
	background-image: ${props =>
		props.size === SIZES.SMALL ? `url(${smallIcon})` : `url(${icon})`};
	width: ${props => (props.size === SIZES.SMALL ? '16px' : '24px')};
	height: ${props => (props.size === SIZES.SMALL ? '16px' : '24px')};
`;

const FullStarIcon = ({ size, button, onClick }) =>
	button ? (
		<IconButton onClick={onClick}>
			<Icon size={size} />
		</IconButton>
	) : (
		<Icon onClick={onClick} size={size} />
	);

export default FullStarIcon;
