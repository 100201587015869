// Libs
import dayjs from 'dayjs';

export default class MapUtils {
	static isExistLayer(map, id) {
		return !!map.getLayer(id);
	}

	static addLayer(map, point, { id, type, layout = {}, paint }) {
		map.addLayer({
			id,
			type,
			source: {
				type: 'geojson',
				data: point,
			},
			layout,
			paint,
		});
	}

	static removeLayer(map, id) {
		map.removeLayer(id).removeSource(id);
	}

	static setPoint({ map, layerId, coords }) {
		// Set start point
		const point = {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					properties: {},
					geometry: {
						type: 'Point',
						coordinates: coords,
					},
				},
			],
		};

		if (map.getLayer(layerId)) {
			map.getSource(layerId).setData(point);
		} else {
			this.addLayer(map, point, {
				id: layerId,
				type: 'circle',
				paint: {
					'circle-radius': 5,
					'circle-color': '#f30',
				},
			});
		}
	}

	// create a function to make a directions request
	static async getRouteData({
		startCoords,
		endCoords,
		profileRoute = 'driving-traffic',
		steps = false,
	}) {
		try {
			// make a directions request using cycling profile
			// an arbitrary startCoords will always be the same
			// only the endCoords or destination will change
			const query = await fetch(
				`https://api.mapbox.com/directions/v5/mapbox/${profileRoute}/${startCoords[0]},${startCoords[1]};${endCoords[0]},${endCoords[1]}?steps=${steps}&geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
				{ method: 'GET' },
			);
			const json = await query.json();
			const routeData = json.routes[0];
			return routeData;
		} catch (err) {
			console.log(err);
		}
	}

	static setRoute({
		routeData,
		map,
		routeId = 'route',
		lineColor = '#3887be',
	}) {
		try {
			const route = routeData.geometry.coordinates;
			const geojson = {
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'LineString',
					coordinates: route,
				},
			};
			// if the route already exists on the map, we'll reset it using setData
			if (map.getSource(routeId)) {
				map.getSource(routeId).setData(geojson);
			}
			// otherwise, we'll make a new request
			else {
				this.addLayer(map, geojson, {
					id: routeId,
					type: 'line',
					layout: {
						'line-join': 'round',
						'line-cap': 'round',
					},
					paint: {
						'line-color': lineColor,
						'line-width': 5,
						'line-opacity': 0.75,
					},
				});
			}
		} catch (err) {
			console.log(err);
		}
	}

	static getMapStyle() {
		const now = dayjs();
		const startTimeDay = dayjs(`${now.format('YYYY-MM-DD')} 05:59`);
		const endTimeDay = dayjs(`${now.format('YYYY-MM-DD')} 17:59`);

		return now.isAfter(endTimeDay) || now.isBefore(startTimeDay)
			? 'mapbox://styles/mapbox/dark-v10'
			: 'mapbox://styles/mapbox/streets-v11';
	}

	static getRandomLineColor() {
		const colors = [
			'#BA2100',
			'#D8CB00',
			'#000AD8',
			'#D8009A',
			'#00D1D8',
			'#2ED800',
			'#D89A00',
			'#00D8AD',
			'#D800B0',
			'#6DC783',
			'#000000',
		];
		const idx = Math.floor(Math.random() * colors.length - 1);
		return colors[idx];
	}

	// Get CoordsInBounds?
	static coordsInBounds(mapBounds, coords) {
		const { latitude: lat, longitude: lng } = coords;
		const { _ne, _sw } = mapBounds;
		if (!_ne || !_sw) return true;
		return lat <= _ne.lat && lat >= _sw.lat && lng <= _ne.lng && lng >= _sw.lng;
	}

	static setUserCoords({ users, userId, connected, coords }) {
		const idx = users.findIndex(user => user.id === userId);
		if (idx !== -1) {
			users[idx].connected = connected;
			users[idx].coords = coords;
		}
		return users;
	}
}
