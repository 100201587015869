// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export default reorder;

export const reorderDataMap = ({ dataMap, source, destination }) => {
	const sourceDroppableId = source.droppableId.split('-')[0];
	const destinationDroppableId = destination.droppableId.split('-')[0];

	const current = [...dataMap[sourceDroppableId].orders];
	const next = [...dataMap[destinationDroppableId].orders];
	const target = current[source.index];

	// moving to same list
	if (sourceDroppableId === destinationDroppableId) {
		const reordered = reorder(current, source.index, destination.index);
		const result = {
			...dataMap,
			[sourceDroppableId]: {
				...dataMap[sourceDroppableId],
				orders: reordered,
			},
		};
		return {
			dataMap: result,
		};
	}

	// moving to different list

	// remove from original
	current.splice(source.index, 1);
	// insert into next
	next.splice(destination.index, 0, target);

	const result = {
		...dataMap,
		[sourceDroppableId]: {
			...dataMap[sourceDroppableId],
			orders: current,
		},
		[destinationDroppableId]: {
			...dataMap[destinationDroppableId],
			orders: next,
		},
	};

	return {
		dataMap: result,
	};
};
