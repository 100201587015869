//  Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
// Utils
import { getRowHeightPaymentOrdersMobile } from '../../../utils';
import { filterPaymentOrders } from 'utils/filters/Billings/orders';
import GENERAL from 'utils/constants/general';
// Components
import { Wrapper, Empty } from 'components';
import { VirtualizedList } from 'screens/Utils';

const { ENV } = GENERAL;

function PaymentsOrdersMobile(props) {
	if (
		!Array.isArray(props?.pays?.selected?.items) ||
		props.pays.selected.items.length === 0
	) {
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_PAYS_MOBILE} />;
	}

	const {
		// BILLINGS
		pays,
		orders,
		mutate1Object,
		// ORDERS
		mutate1ObjectInOrders,
		ordersState,
		getOrder,
		// API
		payments,
		coins,
		// LOGIN
		profile,
	} = props;
	const allOrders = payments.orders.data;
	const { control } = ordersState;
	const rowHeight = getRowHeightPaymentOrdersMobile(pays.getOrders);

	const filteredPaymentOrders = filterPaymentOrders(allOrders, orders.filters);

	return (
		<Wrapper padding='8px' justifyContent='center' className='animated fadeIn'>
			{!payments.orders.loading && filteredPaymentOrders.length === 0 ? (
				<Empty style={{ marginTop: '20px' }} />
			) : (
				<VirtualizedList
					cardId='paymentOrderCard'
					listStyle={{
						height: document.documentElement.clientHeight,
						width: document.documentElement.clientWidth,
					}}
					rowHeight={rowHeight}
					spinTitle='Consultando Órdenes...'
					isSpinning={payments.orders.loading}
					rows={filteredPaymentOrders}
					customProps={{
						orders: control.orders,
						editedOrders: [...orders.editedOrders],
						mutate1ObjectInBillings: mutate1Object,
						mutate1ObjectInOrders,
						getOrder,
						mode: orders.mode,
						paysManage: pays,
						rowHeight,
						profile,
						coins: coins.data,
					}}
				/>
			)}
		</Wrapper>
	);
}

export default PaymentsOrdersMobile;
