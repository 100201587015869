// Libs
import React, { useState } from 'react';
import cx from 'classnames';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Divider, Span, SubCaption, Subtitle } from 'components';
import UserData from './UserData/UserData';
// Styles
import styles from './User.module.css';
import { ProfileActivationModal } from 'components/DataFieldManager/ProfileActivationModal';

const CustomValue = ({ concept, value }) => (
	<div className={styles.custom_value__list}>
		<Span ellipsis emphasis>
			{concept}
		</Span>
		<Span disabled ellipsis>
			{value}
		</Span>
	</div>
);

const CustomList = ({ dynamicSection }) => {
	return (
		<div className={styles.list_custom_value}>
			<div className={styles.list_custom_title}>
				<Subtitle emphasis>{dynamicSection.name}</Subtitle>
			</div>
			{dynamicSection?.fields.map(field => (
				<CustomValue concept={field.name} value={field.value || 'ND'} />
			))}
		</div>
	);
};

const UserPostV1 = ({ record }) => {
	const { theme, isDarkTheme } = useTheme();
	const dynamicData = record.dynamicData || [];
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleCancelModal = () => {
		setIsModalOpen(false);
	};
	const showModal = () => {
		setIsModalOpen(true);
	};

	return (
		<div
			className={cx(
				styles.card,
				isDarkTheme
					? theme.secondaryBackgroundColor
					: theme.primaryBackgroundColor,
			)}
		>
			<UserData record={record} />
			{!!dynamicData.length && <Divider width='95%' margin='5px 0' />}
			{dynamicData.map(dynamicSection => (
				<CustomList dynamicSection={dynamicSection} />
			))}
			<div
				className={cx(styles.assignProfileContainer, theme.borderColor)}
				onClick={showModal}
			>
				<SubCaption>{`${record.assignedRoleCount} Perfil${
					record.assignedRoleCount !== 1 ? 'es' : ''
				} asignado${record.assignedRoleCount !== 1 ? 's' : ''}`}</SubCaption>
			</div>
			{isModalOpen && (
				<ProfileActivationModal
					userData={{
						userId: record.id,
						name: record.name,
						rating: record.rating,
						jobPosition: record.job_position,
					}}
					isModalOpen={isModalOpen}
					handleCancelModal={handleCancelModal}
				/>
			)}
		</div>
	);
};

export default UserPostV1;
