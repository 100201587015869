// Utils
import GENERAL from '../../utils/constants/general';
import KEYWORDS from './keywords';
const { ANALYTICS } = GENERAL;

/** **** INITIALSTATE *******/
const initialState = {
	orders: {
		newImportProcess: {
			request: {
				event: 'ORDERS_NEW_IMPORT_PROCESS',
				from_date: undefined,
				to_date: undefined,
			},
			data: [
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T06:48:37.000Z',
					newOrders: 8,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T07:50:35.000Z',
					newOrders: 12,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T08:11:00.000Z',
					newOrders: 2,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T08:50:58.000Z',
					newOrders: 5,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T09:19:54.000Z',
					newOrders: 9,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T09:39:31.000Z',
					newOrders: 14,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T09:47:18.000Z',
					newOrders: 12,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T09:52:44.000Z',
					newOrders: 0,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T10:38:37.000Z',
					newOrders: 5,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T11:01:02.000Z',
					newOrders: 2,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T11:23:43.000Z',
					newOrders: 7,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T11:45:06.000Z',
					newOrders: 4,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T11:55:32.000Z',
					newOrders: 25,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T13:19:29.000Z',
					newOrders: 20,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T14:16:57.000Z',
					newOrders: 10,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T14:57:30.000Z',
					newOrders: 15,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '10',
					trackedAt: '2020-09-14T15:39:06.000Z',
					newOrders: 4,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T06:59:37.000Z',
					newOrders: 10,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T07:30:35.000Z',
					newOrders: 15,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T08:25:00.000Z',
					newOrders: 2,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T08:49:58.000Z',
					newOrders: 5,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T09:24:54.000Z',
					newOrders: 9,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T09:48:31.000Z',
					newOrders: 14,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T10:00:18.000Z',
					newOrders: 12,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T10:05:44.000Z',
					newOrders: 0,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T10:38:37.000Z',
					newOrders: 5,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T11:01:02.000Z',
					newOrders: 2,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T11:32:43.000Z',
					newOrders: 7,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T11:50:06.000Z',
					newOrders: 4,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T11:59:32.000Z',
					newOrders: 25,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T13:27:29.000Z',
					newOrders: 20,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T14:32:57.000Z',
					newOrders: 11,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T15:00:30.000Z',
					newOrders: 15,
				},
				{
					name: 'ORDERS_NEW_IMPORT_PROCESS',
					distinct_id: '11',
					trackedAt: '2020-09-14T15:45:06.000Z',
					newOrders: 4,
				},
			],
		},
		newOrdersVsBilled: [],
		billedByEntities: [],
	},
	chartDashboard: {
		loading: false,
		charts: [],
	},
	getChartsControl: {
		segment: KEYWORDS.SEGMENT.ORDERS,
		params: {},
	},
};

const analyticsReducer = (state = initialState, action) => {
	switch (action.type) {
		case ANALYTICS.MUTATE_DIRECT_PROPS: {
			const keyValuePairs = action.payload;
			return {
				...state,
				...keyValuePairs,
			};
		}
		case ANALYTICS.MUTATE_1OBJECT: {
			const { obj1Name, keyValuePairs } = action.payload;
			const newObj = { ...state[obj1Name], ...keyValuePairs };

			return {
				...state,
				[obj1Name]: newObj,
			};
		}
		case ANALYTICS.RESET: {
			return initialState;
		}
		default:
			return state;
	}
};

export default analyticsReducer;
