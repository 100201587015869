import { ApiServiceV2 } from 'services';
import { sendToast } from 'screens/Dashboard/actions';

export const deleteRecord = async (key, data, setData, dispatch) => {
	try {
		const response = await ApiServiceV2.delete({
			url: `/inventoryManager/v1/collector-value-inventory/${key}`,
		});
		if (response.status === 'success') {
			const newData = data.filter(item => item.id !== key);
			setData(newData);
			dispatch(
				sendToast({
					message: response.message,
					type: 'success',
				}),
			);
		} else {
			dispatch(
				sendToast({
					message: response.message,
					type: 'error',
				}),
			);
		}
	} catch (errInfo) {
		dispatch(
			sendToast({
				message: 'Error al eliminar el registro',
				type: 'error',
			}),
		);
	}
};

export const editRecord = (record, form, setEditingKey) => {
	form.setFieldsValue({
		id: '',
		name: '',
		is_serialized: '',
		serie_id: '',
		amount: '',
		initial_amount: '',
		final_amount: '',
		updated_at: '',
		...record,
	});
	setEditingKey(record.id);
};

export const cancelEdit = setEditingKey => {
	setEditingKey('');
};

export const saveRecord = async (
	key,
	form,
	data,
	setData,
	setEditingKey,
	dispatch,
) => {
	try {
		const row = await form.validateFields();
		const newData = [...data];
		const index = newData.findIndex(item => key === item.id);
		if (index > -1) {
			const item = newData[index];
			if (
				typeof row.amount === 'undefined' &&
				typeof row.initial_amount !== 'undefined' &&
				typeof row.final_amount !== 'undefined'
			) {
				row.amount = row.initial_amount - row.final_amount;
			}
			newData.splice(index, 1, {
				...item,
				...row,
			});
			const response = await ApiServiceV2.put({
				url: `/inventoryManager/v1/collector-value-inventory/${key}`,
				data: row,
			});
			if (response.status === 'success') {
				setData(newData);
				setEditingKey('');
				dispatch(
					sendToast({
						message: response.message,
						type: 'success',
					}),
				);
			} else {
				dispatch(
					sendToast({
						message: response.message,
						type: 'error',
					}),
				);
			}
		} else {
			newData.push(row);
			setData(newData);
			setEditingKey('');
		}
	} catch (errInfo) {
		dispatch(
			sendToast({
				message: 'Error al validar los datos',
				type: 'error',
			}),
		);
	}
};

export const fetchMaterials = async (setMaterials, dispatch) => {
	try {
		const response = await ApiServiceV2.get({
			url: '/items/',
		});
		if (response) {
			setMaterials(
				response.map(material => ({
					key: material.item_id.toString(),
					item: material.item,
					name: material.name,
					isSerialized: material.is_serialized
						? 'Serialized'
						: 'Non-serialized',
					isInitialFinal: material.is_initial_final,
					serieId: material.serie_id,
				})),
			);
		} else {
			dispatch(
				sendToast({
					message: 'Error al cargar los materiales: Respuesta inválida',
					type: 'error',
				}),
			);
		}
	} catch (error) {
		dispatch(
			sendToast({
				message: `Error al cargar los materiales: ${error.message}`,
				type: 'error',
			}),
		);
	}
};

export const handleAddMaterials = async (
	fetchMaterials,
	setMaterials,
	data,
	setTargetKeys,
	setShowTransfer,
) => {
	await fetchMaterials(setMaterials);
	const currentInventoryKeys = data.map(item => item.item_id.toString());
	setTargetKeys(currentInventoryKeys);
	setShowTransfer(true);
};

export const handleSaveMaterials = async (
	targetKeys,
	materials,
	data,
	setData,
	setShowTransfer,
	dispatch,
) => {
	const collectorValueId = data.length > 0 ? data[0].collector_value_id : null;
	if (!collectorValueId) {
		dispatch(
			sendToast({
				message: 'No se pudo determinar el collector_value_id',
				type: 'error',
			}),
		);
		return;
	}

	const existingItemIds = data.map(item => item.item_id.toString());

	const selectedMaterials = materials
		.filter(
			material =>
				targetKeys.includes(material.key) &&
				!existingItemIds.includes(material.key),
		)
		.map(material => ({
			collector_value_id: collectorValueId,
			item_id: material.key,
			serie_id: material.serie_id || null,
			amount: 0,
			initial_amount: null,
			final_amount: null,
			created_at: new Date().toISOString(),
			updated_at: new Date().toISOString(),
		}));

	if (selectedMaterials.length === 0) {
		dispatch(
			sendToast({
				message: 'No se seleccionaron materiales para incluir',
				type: 'warning',
			}),
		);
		return;
	}

	try {
		const response = await ApiServiceV2.post({
			url: '/inventoryManager/v1/collector-value-inventory',
			data: selectedMaterials,
		});
		if (response.status === 'success') {
			dispatch(
				sendToast({
					message: 'Materiales agregados exitosamente',
					type: 'success',
				}),
			);
			const updatedData = [...data, ...response.data];
			setData(updatedData);
			setShowTransfer(false);
		} else {
			dispatch(
				sendToast({
					message: response.message,
					type: 'error',
				}),
			);
		}
	} catch (error) {
		dispatch(
			sendToast({
				message: 'Error al agregar los materiales',
				type: 'error',
			}),
		);
	}
};

export const handleCancelMaterials = setShowTransfer => {
	setShowTransfer(false);
};
