//  Constants
import GENERAL from '../../utils/constants/general';
const { SIGNUP, DASHBOARD } = GENERAL;

//  Functions
export const doSignup = (
	idc,
	name,
	phone,
	password,
	entity_id,
	organization_id,
) => {
	if (idc) idc = idc.trim();
	if (name) name = name.trim();
	if (
		!idc ||
		idc === '' ||
		!name ||
		name === '' ||
		!phone ||
		phone === '' ||
		!password ||
		password === '' ||
		!entity_id ||
		!organization_id
	) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: 'Debe completar todos los campos', type: 'warn' },
		};
	}
	return {
		type: SIGNUP.SIGNUP,
		payload: { idc, name, phone, password, entity_id, organization_id },
	};
};

export function mutateDirectProps(keyValuePairs) {
	return {
		type: SIGNUP.MUTATE_DIRECT_PROPS,
		payload: keyValuePairs,
	};
}

export function getSignupDataWorker() {
	return {
		type: SIGNUP.GET_SIGNUP_DATA,
	};
}

export const doReset = () => {
	return {
		type: SIGNUP.RESET,
	};
};
