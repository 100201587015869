//Libs
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import axios from 'axios';
import GENERAL from 'utils/constants/general';
//Utils
import asyncErrorsHandler from 'store/asyncErrorsHandler';
import AuthService from 'utils/libs/auth/AuthService';
import UploadResourceUtils from 'components/UploadResource/UploadResourceUtils';
//Selectors
import {
	selectWorkflow,
	selectSelectedTransactionDocId,
	selectCompleteOrderId,
} from './selectors';
//Keywords
import KEYWORDS from './keywords';
//Types
import COLLECTOR_TYPES from './types';
import CollectorUtils from './CollectorUtils';

const { ORDERS, DASHBOARD } = GENERAL;
const auth = new AuthService();

//Selectors
const getOrdersModal = state => state.orders.getOrdersModal;

//******** WATCHER SAGAS ***********/
function* getSavedCollectorValuesByOrderWatcher() {
	yield takeLatest(
		COLLECTOR_TYPES.GET_SAVED_COLLECTOR_VALUES_BY_ORDER,
		getSavedCollectorValuesByOrderWorker,
	);
}
function* getSavedCollectorValuesByTransactionDocWatcher() {
	yield takeLatest(
		COLLECTOR_TYPES.GET_SAVED_COLLECTOR_VALUES_BY_TRANSACTION_DOC,
		getSavedCollectorValuesByTransactionDocWorker,
	);
}
function* saveCollectorValuesWatcher() {
	yield takeLatest(
		COLLECTOR_TYPES.SAVE_COLLECTOR_VALUES,
		saveCollectorValuesWorker,
	);
}
function* autoSyncCollectorValuesWatcher() {
	yield takeLatest(
		COLLECTOR_TYPES.START_AUTO_SYNC_COLLECTOR_VALUES,
		autoSyncCollectorValuesWorker,
	);
}
function* completeAuditedReportWatcher() {
	yield takeLatest(
		COLLECTOR_TYPES.COMPLETE_AUDITED_REPORT,
		completeAuditedReportWorker,
	);
}
function* getAuditedTransactionDocsWatcher() {
	yield takeLatest(
		COLLECTOR_TYPES.GET_AUDITED_TRANSACTION_DOCS,
		getAuditedTransactionDocsWorker,
	);
}
function* updateStateAuditedTransactionDocWatcher() {
	yield takeLatest(
		COLLECTOR_TYPES.UPDATE_STATE_AUDITED_TRANSACTION_DOC,
		updateStateAuditedTransactionDocWorker,
	);
}
function* generateReportWatcher() {
	yield takeLatest(COLLECTOR_TYPES.GENERATE_REPORT, generateReportWorker);
}

//******** WORKERS SAGAS ***********/

function* getSavedCollectorValuesByOrderWorker(action) {
	const workflow = yield select(selectWorkflow);
	const { orderId } = action.payload;

	//Get URI
	const uri = CollectorUtils.getURI({
		orderId,
		workflow,
		context:
			KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT.GET_SAVED_COLLECTOR_VALUES_BY_ORDER,
	});
	if (!uri) return null;

	try {
		//Set isFetching
		yield put({
			type: COLLECTOR_TYPES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'reviewManage',
				keyValuePairs: { isFetching: true },
			},
		});
		//Call API & get order collectorValues
		const response = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(uri),
			auth.sendToken(),
		);
		const { collectorValues: onlineCollectorValues } = response.data;

		//----- COLLECTOR VALUES ------
		//Combine collector values with success collectors
		const combinedCollectorValues = yield CollectorUtils.combineCollectorValues(
			onlineCollectorValues,
			{ format: 'beforeMatch' },
		);
		//Update redux state
		yield put({
			type: COLLECTOR_TYPES.GET_SAVED_COLLECTOR_VALUES_SUCCESS,
			payload: combinedCollectorValues,
		});
		//----- UPLOAD RESOURCES ------
		//Get formatted saved DB Resources
		const resources =
			UploadResourceUtils.getFormattedResourceFromCollectorValues(
				{ order_id: orderId },
				onlineCollectorValues,
			);
		//Combine offline resources with savedDB resources and update offline resources
		yield UploadResourceUtils.combineResources(resources, {
			format: 'beforeMatch',
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getSavedCollectorValuesByOrderWorker(action);
		});
	}
}

function* getSavedCollectorValuesByTransactionDocWorker(action) {
	const workflow = yield select(selectWorkflow);
	const { docId, orderId } = action.payload;

	//Get URI
	const uri = CollectorUtils.getURI({
		docId,
		workflow,
		context:
			KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
				.GET_SAVED_COLLECTOR_VALUES_BY_TRANSACTION_DOC,
	});
	if (!uri) return null;

	try {
		//Set Transaction doc
		yield put({
			type: COLLECTOR_TYPES.SET_TRANSACTION_DOCS,
			payload: {
				docId,
			},
		});
		//Set isFetching
		yield put({
			type: COLLECTOR_TYPES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'reviewManage',
				keyValuePairs: { isFetching: true },
			},
		});
		//Call API & get order collectorLayout
		const response = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(uri),
			auth.sendToken(),
		);
		const { collectorValues: onlineCollectorValues, collectorLayout } =
			response.data;

		//Get formatted saved DB Collector Values
		const collectorValues = CollectorUtils.getFormattedCollectorValues(
			onlineCollectorValues,
		);
		//Update redux state
		yield put({
			type: COLLECTOR_TYPES.GET_SAVED_COLLECTOR_VALUES_BY_OTD_SUCCESS,
			payload: { collectorValues, collectorLayout },
		});
		//Get formatted saved DB Resources
		const resources =
			UploadResourceUtils.getFormattedResourceFromCollectorValues(
				{ order_id: orderId, docId },
				onlineCollectorValues,
			);
		//Combine offlineResources with onlineResources
		yield UploadResourceUtils.combineResources(resources, {
			format: 'beforeMatch',
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getSavedCollectorValuesByTransactionDocWorker(action);
		});
	}
}

function* getAuditedTransactionDocsWorker(action) {
	const workflow = yield select(selectWorkflow);
	const { orderId } = action.payload;

	//Get URI
	const uri = CollectorUtils.getURI({
		orderId,
		workflow,
		context: KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT.GET_AUDITED_TRANSACTION_DOCS,
	});
	if (!uri) return null;

	try {
		//Set isFetching Transaction Docs
		yield put({
			type: COLLECTOR_TYPES.SET_TRANSACTION_DOCS,
			payload: {
				isFetching: true,
			},
		});
		//Call to API
		const { data } = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(uri),
			auth.sendToken(),
		);
		//Set isFetching Transaction Docs
		yield put({
			type: COLLECTOR_TYPES.SET_TRANSACTION_DOCS,
			payload: {
				data,
				isFetching: false,
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				//Set isFetching Transaction Docs
				yield put({
					type: COLLECTOR_TYPES.SET_TRANSACTION_DOCS,
					payload: {
						isFetching: false,
					},
				});
			},
			function* () {
				yield getAuditedTransactionDocsWorker(action);
			},
		);
	}
}

function* updateStateAuditedTransactionDocWorker(action) {
	const workflow = yield select(selectWorkflow);
	const { docId, orderId, state } = action.payload;

	//Get URI
	const uri = CollectorUtils.getURI({
		docId,
		state,
		workflow,
		context:
			KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
				.UPDATE_STATE_AUDITED_TRANSACTION_DOC,
	});
	if (!uri) return null;

	try {
		yield put({
			type: COLLECTOR_TYPES.SET_STATE_COLLECTOR_TRANSACTION_DOC,
			payload: {
				[docId]: KEYWORDS.TRANSACTION_DOCS.STATUS.LOADING,
			},
		});
		yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(uri),
			auth.sendToken(),
		);
		yield put({
			type: COLLECTOR_TYPES.SET_STATE_COLLECTOR_TRANSACTION_DOC,
			payload: {
				[docId]: KEYWORDS.TRANSACTION_DOCS.STATUS.SUCCESS,
			},
		});
		//Get Transaction Docs
		yield put({
			type: COLLECTOR_TYPES.GET_AUDITED_TRANSACTION_DOCS,
			payload: { orderId },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: COLLECTOR_TYPES.SET_STATE_COLLECTOR_TRANSACTION_DOC,
					payload: {
						[docId]: KEYWORDS.TRANSACTION_DOCS.STATUS.ERROR,
					},
				});
			},
			function* () {
				yield updateStateAuditedTransactionDocWorker(action);
			},
		);
	}
}

function* saveCollectorValuesWorker(action) {
	const workflow = yield select(selectWorkflow);
	const docId = yield select(selectSelectedTransactionDocId);
	const orderId = yield select(selectCompleteOrderId);
	const { collectorValues } = action.payload;

	//Get URI
	const uri = CollectorUtils.getURI({
		workflow,
		context: KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT.SAVE_COLLECTOR_VALUES,
	});
	if (!uri || !collectorValues.length) return null;

	try {
		//Set sendingCollectorValues
		yield put({
			type: COLLECTOR_TYPES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'reviewManage',
				keyValuePairs: { sendingCollectorValues: true },
			},
		});
		//Call API & get success collectors
		const { data: successCollectorValues } = yield call(
			axios.post,
			process.env.REACT_APP_API_URL.concat(uri),
			{ collectorValues },
			auth.sendToken(),
		);
		const duplicatedCollectorLayout =
			yield CollectorUtils.updateOfflineDuplicatedCollectorLayout(
				docId,
				orderId,
				successCollectorValues,
			);
		//Combine collector values with success collectors
		const combinedCollectorValues = yield CollectorUtils.combineCollectorValues(
			successCollectorValues,
			{ format: 'afterMatch' },
		);
		//Update redux state
		yield put({
			type: COLLECTOR_TYPES.SAVE_AUDITED_ORDER_COLLECTOR_VALUES_SUCCESS,
			payload: {
				collectorValues: combinedCollectorValues,
				duplicatedCollectorLayout,
			},
		});
		//Active AutoSync
		yield put({
			type: COLLECTOR_TYPES.SET_AUTO_SYNC,
			payload: { active: true },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				//Set error collectors
				const errorCollectorValues = collectorValues.map(cv => {
					cv.status = KEYWORDS.COLLECTORS.STATUS.ERROR;
					return cv;
				});
				//Combine collector values with error collectors
				const combinedCollectorValues =
					yield CollectorUtils.combineCollectorValues(errorCollectorValues);
				//Update redux state
				yield put({
					type: COLLECTOR_TYPES.SAVE_AUDITED_ORDER_COLLECTOR_VALUES_SUCCESS,
					payload: { collectorValues: combinedCollectorValues },
				});
				//Active AutoSync
				yield put({
					type: COLLECTOR_TYPES.SET_AUTO_SYNC,
					payload: { active: true },
				});
			},
			function* () {
				yield saveCollectorValuesWorker(action);
			},
		);
	}
}

function* autoSyncCollectorValuesWorker(action) {
	const workflow = yield select(selectWorkflow);
	const docId = yield select(selectSelectedTransactionDocId);
	const orderId = yield select(selectCompleteOrderId);
	const { collectorValues } = action.payload;

	//Get URI
	const uri = CollectorUtils.getURI({
		workflow,
		context: KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT.AUTO_SYNC_COLLECTOR_VALUES,
	});
	if (!uri || !collectorValues.length) return null;

	try {
		//Set auto sync
		yield put({
			type: COLLECTOR_TYPES.SET_AUTO_SYNC,
			payload: { active: false, isFetching: true },
		});
		//Call to api & get success collectors
		const { data: successCollectorValues } = yield call(
			axios.post,
			process.env.REACT_APP_API_URL.concat(uri),
			{ collectorValues },
			auth.sendToken(),
		);
		const duplicatedCollectorLayout =
			yield CollectorUtils.updateOfflineDuplicatedCollectorLayout(
				docId,
				orderId,
				successCollectorValues,
			);
		//Combine collector values with success collectors
		const combinedCollectorValues = yield CollectorUtils.combineCollectorValues(
			successCollectorValues,
			{ format: 'afterMatch' },
		);
		//Update redux state
		yield put({
			type: COLLECTOR_TYPES.SAVE_AUDITED_ORDER_COLLECTOR_VALUES_SUCCESS,
			payload: {
				collectorValues: combinedCollectorValues,
				duplicatedCollectorLayout,
			},
		});
		//Set auto sync
		yield put({
			type: COLLECTOR_TYPES.SET_AUTO_SYNC,
			payload: { active: true, isFetching: false },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				//Set error collectors
				const errorCollectorValues = collectorValues.map(cv => {
					cv.status = KEYWORDS.COLLECTORS.STATUS.ERROR;
					return cv;
				});
				//Combine collector values with error collectors
				const combinedCollectorValues =
					yield CollectorUtils.combineCollectorValues(errorCollectorValues);
				//Update redux state
				yield put({
					type: COLLECTOR_TYPES.SAVE_AUDITED_ORDER_COLLECTOR_VALUES_SUCCESS,
					payload: { collectorValues: combinedCollectorValues },
				});
			},
			undefined,
		);
	}
}

function* completeAuditedReportWorker(action) {
	const workflow = yield select(selectWorkflow);
	const docId = yield select(selectSelectedTransactionDocId);
	const { orderId } = action.payload;

	//Get URI
	const uri = CollectorUtils.getURI({
		workflow,
		context: KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT.COMPLETE_AUDITED_REPORT,
	});
	if (!uri) return null;

	try {
		yield call(
			axios.post,
			process.env.REACT_APP_API_URL.concat(uri),
			{ auditOrderId: orderId, docId },
			auth.sendToken(),
		);
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Reporte completado!',
				type: 'success',
			},
		});
		yield put({
			type: COLLECTOR_TYPES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'reviewManage',
				keyValuePairs: { sendingReport: false },
			},
		});

		//Get Transaction Docs
		if (docId) {
			yield put({
				type: COLLECTOR_TYPES.GET_AUDITED_TRANSACTION_DOCS,
				payload: { orderId },
			});
		} else {
			//Get orders
			const getOrdersModalData = yield select(getOrdersModal);
			const { category, fromDate, toDate } = getOrdersModalData;
			yield put({
				type: ORDERS.GET_ORDERS,
				payload: { category, fromDate, toDate },
			});
		}
		//Reset Required Validation to Waiting
		yield put({
			type: COLLECTOR_TYPES.UPDATE_COLLECTOR_REQUIRED_VALIDATION,
			payload: { state: KEYWORDS.REQUIRED_VALIDATION.STATE.WAITING },
		});
	} catch (err) {
		yield asyncErrorsHandler(err, function* () {
			yield put({
				type: COLLECTOR_TYPES.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'reviewManage',
					keyValuePairs: { sendingReport: false },
				},
			});
		});
	}
}

function* generateReportWorker(action) {
	const workflow = yield select(selectWorkflow);
	const { docId, serviceTaskId, format } = action.payload;

	//Get URI
	const uri = CollectorUtils.getURI({
		workflow,
		context: KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT.GENERATE_REPORT,
	});
	if (!uri) return null;

	try {
		//Start Generating
		yield put({
			type: COLLECTOR_TYPES.GENERATE_REPORT_START,
			payload: { docId, serviceTaskId, format },
		});
		//Call api
		const { data } = yield call(
			axios.post,
			process.env.REACT_APP_API_URL.concat(uri),
			{ otdId: docId, serviceTaskId, format },
			auth.sendToken(),
		);
		//Generated
		yield put({
			type: COLLECTOR_TYPES.GENERATE_REPORT_SUCCESS,
			payload: {
				docId: data.otdId,
				serviceTaskId: data.serviceTaskId,
				reportUrl: data.reportUrl,
				format,
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: COLLECTOR_TYPES.GENERATE_REPORT_FAILED,
					payload: { docId, serviceTaskId, format },
				});
			},
			function* () {
				yield generateReportWorker(action);
			},
		);
	}
}

//  Export default Root Saga
export default function* rootSaga() {
	yield all([
		getSavedCollectorValuesByOrderWatcher(),
		getSavedCollectorValuesByTransactionDocWatcher(),
		saveCollectorValuesWatcher(),
		autoSyncCollectorValuesWatcher(),
		completeAuditedReportWatcher(),
		getAuditedTransactionDocsWatcher(),
		updateStateAuditedTransactionDocWatcher(),
		generateReportWatcher(),
	]);
}
