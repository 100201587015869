// Utils
import GENERAL from '../../utils/constants/general';
const { REPORTS } = GENERAL;

const generateReportModal = {
	isOpen: false,
	isFetching: false,
	reportId: undefined,
	reportTable: undefined,
	toEmails: [],
	ccEmails: [],
	subject: undefined,
	message: undefined,
	params: {},
};

const INITIAL_STATE = {
	control: {
		isFetching: false,
		reports: [],
		searchInData: {
			renderToolbar: false,
			data: '',
		},
	},
	generateReportModal,
};

const reportReducer = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;

	switch (type) {
		case REPORTS.MUTATE_DIRECT_PROPS: {
			const keyValuePairs = payload;
			return {
				...state,
				...keyValuePairs,
			};
		}
		case REPORTS.MUTATE_1OBJECT: {
			const { obj1Name, keyValuePairs } = payload;
			const newObj = { ...state[obj1Name], ...keyValuePairs };
			return {
				...state,
				[obj1Name]: newObj,
			};
		}
		case REPORTS.RESET_GENERATE_REPORT_MODAL: {
			return {
				...state,
				generateReportModal,
			};
		}
		default:
			return state;
	}
};

export default reportReducer;
