// Libs
import React from 'react';
// Components
import { Wrapper } from 'components';
import Story from './Story';

const stories = [
	{
		id: 1,
		createdBy: 1,
		src: 'https://3.bp.blogspot.com/-2gJhM1tn3DE/WHfIMCP-PlI/AAAAAAAADbg/OPwNxtXO3vAUTpqKsXsput0ixAVAHcKeQCLcB/s1600/Antenas.png',
	},
	{
		id: 2,
		createdBy: 2,
		src: 'https://3.bp.blogspot.com/-2gJhM1tn3DE/WHfIMCP-PlI/AAAAAAAADbg/OPwNxtXO3vAUTpqKsXsput0ixAVAHcKeQCLcB/s1600/Antenas.png',
	},
	{
		id: 3,
		createdBy: 3,
		src: 'https://3.bp.blogspot.com/-2gJhM1tn3DE/WHfIMCP-PlI/AAAAAAAADbg/OPwNxtXO3vAUTpqKsXsput0ixAVAHcKeQCLcB/s1600/Antenas.png',
	},
	{
		id: 4,
		createdBy: 4,
		src: 'https://3.bp.blogspot.com/-2gJhM1tn3DE/WHfIMCP-PlI/AAAAAAAADbg/OPwNxtXO3vAUTpqKsXsput0ixAVAHcKeQCLcB/s1600/Antenas.png',
	},
	{
		id: 5,
		createdBy: 5,
		src: 'https://3.bp.blogspot.com/-2gJhM1tn3DE/WHfIMCP-PlI/AAAAAAAADbg/OPwNxtXO3vAUTpqKsXsput0ixAVAHcKeQCLcB/s1600/Antenas.png',
	},
	{
		id: 6,
		createdBy: 6,
		src: 'https://3.bp.blogspot.com/-2gJhM1tn3DE/WHfIMCP-PlI/AAAAAAAADbg/OPwNxtXO3vAUTpqKsXsput0ixAVAHcKeQCLcB/s1600/Antenas.png',
	},
	{
		id: 7,
		createdBy: 7,
		src: 'https://3.bp.blogspot.com/-2gJhM1tn3DE/WHfIMCP-PlI/AAAAAAAADbg/OPwNxtXO3vAUTpqKsXsput0ixAVAHcKeQCLcB/s1600/Antenas.png',
	},
	{
		id: 8,
		createdBy: 8,
		src: 'https://3.bp.blogspot.com/-2gJhM1tn3DE/WHfIMCP-PlI/AAAAAAAADbg/OPwNxtXO3vAUTpqKsXsput0ixAVAHcKeQCLcB/s1600/Antenas.png',
	},
	{
		id: 9,
		createdBy: 9,
		src: 'https://3.bp.blogspot.com/-2gJhM1tn3DE/WHfIMCP-PlI/AAAAAAAADbg/OPwNxtXO3vAUTpqKsXsput0ixAVAHcKeQCLcB/s1600/Antenas.png',
	},
];

const WorkStory = ({}) => {
	return (
		<Wrapper
			padding='10px 10px 24px 0'
			width='100%'
			minHeight='150px'
			overflow='auto'
		>
			{stories.map((story, idx) => (
				<div key={idx}>
					<Story story={story} />
				</div>
			))}
		</Wrapper>
	);
};

export default WorkStory;
