// Libs
import React from 'react';
import { connect } from 'react-redux';
// Utils
import { getCoords } from 'utils/libs';
// Actions
import * as userActions from './actions';
import {
	getRoles,
	getPermissions,
	updateUserCoord,
	doResetApiData,
	mutate1ObjectApi,
} from 'store/api/actions';
// Selectors
import {
	selectControl,
	selectMyProfile,
	selectMyUserProfileEdit,
	selectUserConfigurationEdit,
} from './selectors';
import { selectModeView } from 'screens/Dashboard/selectors';
import {
	selectPermissions,
	selectRoles,
	selectUsers,
} from 'store/api/selectors';
// Components
import { Wrapper } from 'components';
import { UserControlMobile, UserControlDesktop } from './Control';

function UsersControl(props) {
	const {
		modeView,
		profile,
		getRoles,
		getPermissions,
		updateUserCoord,
		doReset,
		doResetApiData,
	} = props;

	React.useEffect(() => {
		getRoles();
		getPermissions();

		getCoords().then(({ coords }) => {
			updateUserCoord({
				id: profile.user.id,
				coords,
			});
		});
		return () => {
			doReset();
			doResetApiData('USERS');
		};
	}, []);

	return (
		<Wrapper padding='0' className='animated fadeIn'>
			{modeView === 'cards' && <UserControlMobile {...props} />}
			{modeView === 'table' && <UserControlDesktop {...props} />}
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	control: selectControl(state),
	profile: selectMyProfile(state),
	userProfileModal: selectMyUserProfileEdit(state),
	setUserConfigurationModal: selectUserConfigurationEdit(state),
	modeView: selectModeView(state),
	users: selectUsers(state),
	roles: selectRoles(state),
	permissions: selectPermissions(state),
});

const actions = {
	...userActions,
	getRoles,
	getPermissions,
	updateUserCoord,
	doResetApiData,
	mutate1ObjectApi,
};

export default connect(mapStateToProps, actions)(UsersControl);
