// Libs
import { InputNumber } from 'antd';
import styled from 'styled-components';
// Hooks
import { useTheme } from 'hooks';

const InputNumberStyled = styled(InputNumber)`
	margin: ${props =>
		props.margin ? props.margin : props.theme.inputNumber.margin};
	width: ${props =>
		props.width ? props.width : props.theme.inputNumber.width};
	font-size: ${props =>
		props.fontSize ? props.fontSize : props.theme.fonts.size.default};
	&.ant-input-number {
		width: ${props =>
			props.width ? props.width : props.theme.inputNumber.width};
		height: ${props =>
			props.height ? props.height : props.theme.inputNumber.height};
		color: ${({ color, $newTheme }) =>
			color ? color : $newTheme._primaryColor};
		background-color: ${props =>
			props.backgroundColor
				? props.backgroundColor
				: props.theme.inputNumber.backgroundColor};
	}
`;

export default props => {
	const { theme } = useTheme();

	return <InputNumberStyled $newTheme={theme} {...props} />;
};
