// Utils
import KEYWORDS from './keywords';
import MODULE_KEYWORDS from 'components/Modules/keywords';
import { GlobalUtils } from 'utils';
class CoreUtils {
	/**
	 * @param {Object} prevDataFilterUserParams
	 * @param {Object} dataFilterUserParams
	 * @param {Object[]} customCompareParams
	 * @returns boolean
	 */
	static checkUserParamsDiff({
		prevDataFilterUserParams = {},
		dataFilterUserParams,
		customCompareParams,
	}) {
		if (customCompareParams) {
			return !!customCompareParams.find(
				param =>
					GlobalUtils.flattenArray(dataFilterUserParams[param]) !==
					GlobalUtils.flattenArray(prevDataFilterUserParams[param]),
			);
		}
		const isDiff = {
			new: !!Object.keys(dataFilterUserParams).find(
				param =>
					GlobalUtils.flattenArray(dataFilterUserParams[param]) !==
					GlobalUtils.flattenArray(prevDataFilterUserParams[param]),
			),
			prev: !!Object.keys(prevDataFilterUserParams).find(
				param =>
					GlobalUtils.flattenArray(dataFilterUserParams[param]) !==
					GlobalUtils.flattenArray(prevDataFilterUserParams[param]),
			),
		};
		return isDiff.new || isDiff.prev;
	}

	/**
	 * @param {Object[]} subDataFilters
	 * @param {Object} dataFilterUserParams
	 * @returns boolean
	 */
	static checkRequiredUserParams({ subDataFilters, dataFilterUserParams }) {
		if (!subDataFilters || !Array.isArray(subDataFilters)) return false;
		if (subDataFilters.length === 0) return true;

		const isPendingRequired = !!subDataFilters.find(
			({ param, required, state }) =>
				required &&
				(state !== MODULE_KEYWORDS.STATE.SUCCESS ||
					!dataFilterUserParams[param] ||
					(Array.isArray(dataFilterUserParams[param]) &&
						dataFilterUserParams[param].length === 0)),
		);

		return !isPendingRequired;
	}

	/**
	 * @param {int} selectedProjectId
	 * @param {int} selectedOrderTargetFilterId
	 * @param {Object[]} selectedServiceIds
	 * @param {string} joinedServiceIds
	 * @param {string} prevJoinedServiceIds
	 * @param {boolean} dataFilterIsFetching
	 * @param {int} selectedDataFilterId
	 * @param {int} prevSelectedDataFilterId
	 * @param {Object} prevDataFilterUserParams
	 * @param {Object} dataFilterUserParams
	 * @param {Object[]} customCompareParams
	 * @returns boolean
	 */
	static checkMustFetchData({
		isReloadDatasource,
		// User params
		dataFilterUserParams,
		subDataFilters,
	}) {
		return (
			isReloadDatasource &&
			this.checkRequiredUserParams({ subDataFilters, dataFilterUserParams })
		);
	}

	/**
	 * @param {string} dataFilterLastRowId - Last row id
	 * @param {function} mutate1ObjectInCore - Mutate direct props in feed reducer
	 * @param {function} setDatasource - Add to array datasource in module state redux
	 * @param {function} resetDatasource
	 * @returns actions object
	 */
	static onGettedDataEvents({
		dataFilterLastRowId,
		mutate1ObjectInCore,
		setDatasource,
		resetDatasource,
	}) {
		return {
			onLoading: function* () {
				if (dataFilterLastRowId === 0) resetDatasource();
				mutate1ObjectInCore('datasource', {
					status: KEYWORDS.STATUS.LOADING,
				});
			},
			onSuccess: function* ({ payload = {} }) {
				mutate1ObjectInCore('datasource', {
					status: KEYWORDS.STATUS.SUCCESS,
				});
				setDatasource({
					paging: payload.paging,
					fields: payload.fields,
					newData: payload.data,
					singleData: payload.singleData,
				});
			},
			onError: function* () {
				mutate1ObjectInCore('datasource', { status: KEYWORDS.STATUS.ERROR });
			},
		};
	}

	/**
	 * @param {Object[]} currentData
	 * @param {Object[]} newData
	 * @returns Object[]
	 */
	static appendOrResetDatasource(currentData, newData) {
		return currentData.concat(GlobalUtils.checkArray(newData));
	}

	static setDatasourceSingleData(singleData) {
		return GlobalUtils.checkObject(singleData);
	}

	static setOrResetDatasourceFields(currentFields, newFields) {
		return GlobalUtils.checkArray(newFields).length
			? GlobalUtils.checkArray(newFields)
			: currentFields;
	}

	static setDatasourcePaging({ currentData, currentPaging, newData, paging }) {
		const _paging = GlobalUtils.checkObject(paging);
		// const totalRowCount = _paging.totalRowCount || currentPaging.totalRowCount;
		return {
			...currentPaging,
			..._paging,
			totalRowCount:
				currentData.length +
				_paging.totalRowCount -
				GlobalUtils.checkArray(newData).length,
		};
	}
}

export default CoreUtils;
