// export function getCities(zones) {
//   return zones.reduce((acc, zone) => {
//     if (acc.findIndex((city) => city.cityName === zone.cityName) === -1) {
//       acc.push(zone);
//     }
//     return acc;
//   }, []);
// }

// export function getTownships(zones, cityId) {
//   return zones
//     .filter((zone) => zone.cityId === cityId)
//     .reduce((acc, zone) => {
//       if (
//         acc.findIndex(
//           (township) => township.townshipName === zone.townshipName
//         ) === -1
//       ) {
//         acc.push(zone);
//       }
//       return acc;
//     }, []);
// }

// export function getZones(zones, cityId, townshipId) {
//   return zones
//     .filter((zone) => zone.cityId === cityId && zone.townshipId === townshipId)
//     .reduce((acc, zone) => {
//       if (acc.findIndex((zne) => zne.zoneName === zone.zoneName) === -1) {
//         acc.push(zone);
//       }
//       return acc;
//     }, []);
// }

/// /TODO: DEPRECATED:
import _ from 'underscore';

export function getCities(zones) {
	return zones.reduce((acc, zone) => {
		if (_.findIndex(acc, city => city.city_name === zone.city_name) === -1) {
			acc.push(zone);
		}
		return acc;
	}, []);
}
/// /TODO: DEPRECATED:
export function getTownships(zones, cityId) {
	return zones
		.filter(zone => zone.city_id === cityId)
		.reduce((acc, zone) => {
			if (
				_.findIndex(
					acc,
					township => township.township_name === zone.township_name,
				) === -1
			) {
				acc.push(zone);
			}
			return acc;
		}, []);
}
/// /TODO: DEPRECATED:
export function getZones(zones, cityId, townshipId) {
	return zones
		.filter(zone => zone.city_id === cityId && zone.township_id === townshipId)
		.reduce((acc, zone) => {
			if (_.findIndex(acc, zne => zne.zone_name === zone.zone_name) === -1) {
				acc.push(zone);
			}
			return acc;
		}, []);
}
