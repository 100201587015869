// Libs
import React from 'react';
import { Link } from 'react-router-dom';
// Config
import AppConfig from 'components/AppConfig.json';
// Components
import { Wrapper, Avatar, Title, Subtitle, LeftArrowsIcon } from 'components';
import VersionApp from './VersionApp';

const ProfileSection = ({ profile, toggleDatasource, onAvatarClick }) => (
	<Wrapper padding='0' minHeight='110px' position='relative'>
		<Avatar
			size='large'
			avatar={{ src: profile?.user?.profile?.photo }}
			onClick={onAvatarClick}
		/>
		<Wrapper padding='0 16px' flexDirection='column' alignItems='flex-start'>
			<Title ellipsis width='160px'>
				{profile?.user?.name}
			</Title>
			<Subtitle ellipsis>{profile?.entity?.name}</Subtitle>
		</Wrapper>
		<Wrapper padding='0' position='absolute' right='-5px' top='8px'>
			<LeftArrowsIcon onClick={toggleDatasource} />
		</Wrapper>
		<Link to='/dashboard/orders'>
			<VersionApp />
		</Link>
	</Wrapper>
);

export default ProfileSection;
