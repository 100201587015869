// Libs
import React, { Fragment } from 'react';
// Cells
import {
	IndexCell,
	PriceCell,
	OrderCountdownCell,
	AssignedTechIdCell,
	EventIdCell,
	ZoneIdCell,
	DefaultCell,
	DefaultEditableCell,
	MergedPDFReportCell,
	OrderIdCell,
	WarrantyCell,
} from './CustomCells';

const customCellKeyComponents = {
	index: IndexCell,
	price: PriceCell,
	orderTemporizator: OrderCountdownCell,
	assignedTechId: AssignedTechIdCell,
	eventId: EventIdCell,
	odtId: OrderIdCell,
	isWarranty: WarrantyCell,
	// zoneId: ZoneIdCell,
	// <-- Add new custom cell key:component
};

const getDefaultCell = ({ rowData, field }) => {
	return rowData[`${field.id}Validation`] ? DefaultEditableCell : DefaultCell;
};

const CellSwitcher = ({ rowData, field }) =>
	customCellKeyComponents[field.id] || getDefaultCell({ rowData, field });

const DataFieldSwitcher = ({
	rowIndex,
	rowData,
	field,
	selected,
	fieldActionTypes,
	profile,
	setState,
	Container = Fragment,
}) => {
	const SelectedCell = CellSwitcher({
		rowData,
		field,
	});

	return (
		<Container>
			<SelectedCell
				rowIndex={rowIndex}
				rowData={rowData}
				field={field}
				selected={selected}
				fieldActionTypes={fieldActionTypes}
				profile={profile}
				setState={setState}
			/>
		</Container>
	);
};

export default DataFieldSwitcher;
