// Libs
import React from 'react';
// Components
import { Wrapper, Acronym } from 'components';

const AvatarCarousel = ({
	size,
	selectedAvatarId,
	avatarList,
	skeleton,
	spin,
	onClick,
}) => {
	const skeletonItems = [{}, {}, {}, {}, {}];
	const avatars = skeleton ? skeletonItems : avatarList;

	return (
		<Wrapper padding='0 0 0 10px' overflow='auto'>
			{avatars.map((avatar, idx) => (
				<div key={idx} onClick={() => onClick(avatar)}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							cursor: 'pointer',
						}}
					>
						<Acronym text={avatar.name} />
						{avatar.name}
					</div>
				</div>
			))}
		</Wrapper>
	);
};

export default AvatarCarousel;
