// Libs
import React from 'react';
import styled from 'styled-components';
// Utils
import { CloudStorage } from 'components/AppConfig.json';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Avatar, Comment } from 'components';
import { Input } from 'antd';
// Icons
import { LoadingOutlined } from '@ant-design/icons';
// Styles
import styles from './CommentsBox.module.css';

const { TextArea: _TextArea } = Input;

const AddReply = ({ onChange, onSubmit, submitting, value, userId }) => {
	const { theme } = useTheme();
	return (
		<Comment
			$isReplyBox={true}
			avatar={
				<div className={styles.addReplyAvatarWrapper}>
					<Avatar
						size='normal'
						fit
						avatar={{
							src: CloudStorage.concat(
								`/syn4pse/users/avatars/${
									userId ? `${userId}.jpg` : `default.svg`
								}`,
							),
						}}
					/>
				</div>
			}
			content={
				submitting ? (
					<div className={styles.loadingWrapper}>
						<LoadingOutlined />
					</div>
				) : (
					<TextArea
						style={{ color: theme._inactiveColor }}
						autoSize={{
							minRows: 1,
							maxRows: 4,
						}}
						onPressEnter={onSubmit}
						maxLength={300}
						rows={1}
						onChange={onChange}
						value={value}
					/>
				)
			}
		/>
	);
};

const TextArea = styled(_TextArea)`
	background-color: transparent;
	border: none;
	border-radius: 15px;
	resize: none;
`;

export default AddReply;
