// Libs
import { memo, useState, useEffect, useCallback } from 'react';
// Utils
import { GlobalUtils } from 'utils';
import { debounce, filterOption } from 'utils/libs';
import FormBuilderConfig from 'core/FormBuilder/form.config';
// Components
import { LogoIcon, Wrapper } from 'components';
import Select from 'components/Select';

const { Option } = Select;

const filterOptionInOptions = (value, options) =>
	value.filter(
		val => options.findIndex(o => parseInt(o.id) === parseInt(val)) !== -1,
	);

const SelectionField = memo(
	({
		componentId,
		placeholder,
		value,
		paramValues,
		disabled,
		config,
		onChange,
		getProcessedComponentSqlData,
	}) => {
		const [data, setData] = useState({
			isFetching: false,
			options: [],
		});

		const fetchData = async searchValue => {
			setData(prev => ({ ...prev, isFetching: true }));
			const _options = await getProcessedComponentSqlData({
				componentId,
				searchValue,
				paramValues,
				returnKey: 'options',
			});

			const options = GlobalUtils.checkArray(_options);

			setData(prev => ({
				...prev,
				options,
				isFetching: false,
			}));

			if (config?.autoselect) {
				const autoSelectedValue = options[0]?.id;
				if (autoSelectedValue) onChange(autoSelectedValue);
			}
		};

		const fetchDebouncedData = useCallback(debounce(fetchData, 700), [
			paramValues,
		]);

		useEffect(() => {
			if (!disabled && config?.autoselect) fetchData();
		}, [FormBuilderConfig.checkParamValuesChange(paramValues)]);

		return (
			<Select
				width='100%'
				disabled={disabled}
				showSearch
				mode={config?.multiple ? 'tags' : undefined}
				filterOption={filterOption}
				value={value}
				placeholder={placeholder}
				onSearch={fetchDebouncedData}
				onChange={value => {
					if (config?.multiple)
						value = filterOptionInOptions(value, data.options);
					onChange(value);
				}}
				onDropdownVisibleChange={isOpen => isOpen && fetchData()}
				dropdownRender={menu =>
					data.isFetching && !data.options.length ? (
						<Wrapper width='100%' justifyContent='center'>
							<LogoIcon spin={true} />
						</Wrapper>
					) : (
						menu
					)
				}
			>
				{data.options.map(item => (
					<Option key={item.id} value={item.id}>
						{item.name}
					</Option>
				))}
			</Select>
		);
	},
);

export default SelectionField;
