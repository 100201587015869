// @flow
import React from 'react';
import styled from 'styled-components';
import { borderRadius, grid } from '../constants';

// Components
import { Card } from 'components';
import { OrderCard } from 'screens/Utils/Lists/cards';

const imageSize = 40;

const Container = styled.a`
	border-radius: ${borderRadius}px;
	border: 1px solid transparent;
	border-color: ${({ isDragging }) => (isDragging ? 'green' : 'transparent')};
	background-color: ${({ isDragging }) => (isDragging ? '#8b8b8b' : '#181a1b')};
	box-shadow: ${({ isDragging }) =>
		isDragging ? `2px 2px 1px white` : 'none'};
	box-sizing: border-box;
	padding: ${grid}px;
	min-height: ${imageSize}px;
	margin-bottom: ${grid}px;
	user-select: none;

	/* anchor overrides */
	color: whitesmoke;

	&:hover {
		color: green;
		text-decoration: none;
	}

	&:active {
		color: black;
		text-decoration: none;
	}

	&:focus {
		outline: none;
		border-color: green;
		box-shadow: none;
	}

	/* flexbox */
	display: flex;
`;

function getStyle(provided, style) {
	if (!style) {
		return provided.draggableProps.style;
	}

	return {
		...provided.draggableProps.style,
		...style,
	};
}

function OrderItem({ order, isDragging, provided, style, index, customProps }) {
	return (
		<Container
			isDragging={isDragging}
			ref={provided.innerRef}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
			style={getStyle(provided, style)}
			data-is-dragging={isDragging}
			data-testid={order.order_id}
			data-index={index}
			aria-label={`${order.expert.name} order ${order.event_comment}`}
			onClick={() =>
				customProps.mutate1Object('getOrderInformationModal', {
					order_id: order.order_id,
					isOpen: true,
				})
			}
		>
			<Card
				id='card-container'
				key={index}
				style={{ width: '100%', background: 'transparent', padding: 0 }}
				actions={null}
			>
				<OrderCard {...order} customProps={customProps} compactMode />
			</Card>
		</Container>
	);
}

export default React.memo(OrderItem);
