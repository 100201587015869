const namedFieldScraping = ({ order, field }) => {
	if (!field.name) return '';
	return String(order[field.name] || field.default || '');
};

export default class XlsxScrapingProcess {
	constructor(order, fields) {
		this.order = order;
		this.fields = fields;
	}

	run() {
		return this.fields.reduce((_order, field) => {
			_order[field.id] = namedFieldScraping({
				order: this.order,
				field,
			}).trim();

			return _order;
		}, {});
	}
}
