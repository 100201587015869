// Libs
import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
// Components
import { Wrapper, Span, AddIcon } from 'components';
import { Avatar as _Avatar } from 'antd';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import DefaultAvatarImg from 'assets/media/img/default-avatar.png';
import PhotoUtils from 'utils/PhotoUtils';

const BORDER_WIDTH = 4;
const BORDER_HEIGHT = 4;

const SkeletonAvatar = ({ size, name }) => {
	const theme = useTheme();
	const { large, medium, normal, small } = theme.sizes;

	let width = large.width;
	let height = large.height;
	if (size === medium.name) {
		width = medium.width;
		height = medium.height;
	} else if (size === normal.name) {
		width = normal.width;
		height = normal.height;
	} else if (size === small.name) {
		width = small.width;
		height = small.height;
	}
	return (
		<SkeletonTheme
			color={theme.avatar.skeleton.color}
			highlightColor={theme.avatar.skeleton.highlightColor}
		>
			<Skeleton circle={true} width={width} height={height} />
			{name && (
				<div style={{ marginTop: '5px' }}>
					<Skeleton />
				</div>
			)}
		</SkeletonTheme>
	);
};

const AvatarWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const AvatarBorder = styled.div`
	position: absolute;
	width: ${({ theme }) => `${theme.sizes.large.width}px`};
	height: ${({ theme }) => `${theme.sizes.large.height}px`};
	border-radius: ${({ theme }) => theme.avatar.border.borderRadius};
	background: ${({ theme }) => theme.avatar.border.background1};
	background: ${({ theme }) => theme.avatar.border.background2};
	display: flex;
	justify-content: center;
	align-items: center;
	${({ theme, size }) =>
		size === theme.sizes.medium.name &&
		css`
			width: ${({ theme }) => `${theme.sizes.medium.width}px`};
			height: ${({ theme }) => `${theme.sizes.medium.height}px`};
		`};
	${({ theme, size }) =>
		size === theme.sizes.normal.name &&
		css`
			width: ${({ theme }) => `${theme.sizes.normal.width}px`};
			height: ${({ theme }) => `${theme.sizes.normal.height}px`};
		`};
	${({ theme, size }) =>
		size === theme.sizes.small.name &&
		css`
			width: ${({ theme }) => `${theme.sizes.small.width}px`};
			height: ${({ theme }) => `${theme.sizes.small.height}px`};
		`};
	${({ spin }) =>
		spin &&
		css`
			animation: spin 1s linear infinite;

			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		`}
`;

const AvatarPhoto = styled(_Avatar)`
	width: ${({ theme }) => `${theme.sizes.large.width - BORDER_WIDTH}px`};
	height: ${({ theme }) => `${theme.sizes.large.height - BORDER_HEIGHT}px`};
	${({ theme, size }) =>
		size === theme.sizes.medium.name &&
		css`
			width: ${({ theme }) => `${theme.sizes.medium.width - BORDER_WIDTH}px`};
			height: ${({ theme }) =>
				`${theme.sizes.medium.height - BORDER_HEIGHT}px`};
		`};
	${({ theme, size }) =>
		size === theme.sizes.normal.name &&
		css`
			width: ${({ theme }) => `${theme.sizes.normal.width - BORDER_WIDTH}px`};
			height: ${({ theme }) =>
				`${theme.sizes.normal.height - BORDER_HEIGHT}px`};
		`};
	${({ theme, size }) =>
		size === theme.sizes.small.name &&
		css`
			width: ${({ theme }) => `${theme.sizes.small.width - BORDER_WIDTH}px`};
			height: ${({ theme }) => `${theme.sizes.small.height - BORDER_HEIGHT}px`};
		`};
	&.ant-avatar > img {
		object-fit: contain;
	}
`;

const AvatarName = styled(Span)`
	margin-top: 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: ${({ theme, isActiveAvatar }) =>
		isActiveAvatar ? theme.colors.text.high : theme.colors.text.medium};
	width: ${({ theme }) => `${theme.sizes.large.width}px`};
	${({ theme, size }) =>
		size === theme.sizes.medium.name &&
		css`
			width: ${({ theme }) => `${theme.sizes.medium.width}px`};
		`};
	${({ theme, size }) =>
		size === theme.sizes.normal.name &&
		css`
			width: ${({ theme }) => `${theme.sizes.normal.width}px`};
		`};
	${({ theme, size }) =>
		size === theme.sizes.small.name &&
		css`
			width: ${({ theme }) => `${theme.sizes.small.width}px`};
		`};
`;

const AvatarGroup = ({
	src,
	name,
	add,
	spin,
	size,
	isActiveAvatar,
	onClick,
	onMouseEnter,
}) => (
	<Wrapper padding='0' flexDirection='column'>
		<AvatarWrapper className='animated fadeIn' onClick={onClick}>
			<AvatarBorder spin={spin} size={size} />
			<AvatarPhoto src={src} size={size} onMouseEnter={onMouseEnter} />
			{add && <AddIcon bold add size={size} fill='black' />}
		</AvatarWrapper>
		{name && (
			<AvatarName
				size={size}
				fontSize='xs'
				textAlign='center'
				isActiveAvatar={isActiveAvatar}
			>
				{name}
			</AvatarName>
		)}
	</Wrapper>
);

const Avatar = ({
	avatar = {},
	skeleton,
	spin,
	size,
	fit,
	selectedAvatarId,
	onClick,
	maskIcon,
}) => {
	const [myState, setMyState] = React.useState({
		isFetching: false,
		src: undefined,
	});
	const { id: avatarId, name: avatarName, src: avatarSrc, add } = avatar;
	const isActiveAvatar = avatarId && avatarId === selectedAvatarId;

	React.useEffect(() => {
		let isMounted = true;
		setMyState({ isFetching: true });
		PhotoUtils.getRemoteImageResource({
			src: avatarSrc,
			defaultSrc: DefaultAvatarImg,
		}).then(src => {
			if (isMounted) setMyState({ isFetching: false, src });
		});
		return () => {
			isMounted = false;
		};
	}, [avatarSrc]);
	const [isMasked, setIsMasked] = useState(false);
	const switchMasked = () => setIsMasked(prevState => !prevState);

	return (
		<Wrapper
			padding={fit ? '0px' : '10px'}
			cursor='pointer'
			position='relative'
		>
			{skeleton || myState.isFetching ? (
				<SkeletonAvatar size={size} name={avatarName} />
			) : (
				<>
					<AvatarGroup
						src={myState.src}
						name={avatarName}
						add={add}
						spin={spin && isActiveAvatar}
						size={size}
						isActiveAvatar={isActiveAvatar}
						onClick={onClick}
						onMouseEnter={switchMasked}
					/>
					{isMasked && maskIcon && (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								backgroundColor: 'rgba(0, 0, 0, .4)',
								position: 'absolute',
								top: '0px',
								left: '0px',
								width: '100%',
								height: '100%',
								borderRadius: '50%',
							}}
							onMouseLeave={switchMasked}
							onClick={onClick}
						>
							{maskIcon}
						</div>
					)}
				</>
			)}
		</Wrapper>
	);
};

export default Avatar;
