import AbsAuthorization from 'core/Authorization/manager';

export class DashboardAuthorization extends AbsAuthorization {
	constructor(profile) {
		super(profile);
	}

	checkEditCatalogue() {
		return !!this.roles[this.checkCatalogueL2Manager()];
	}
	checkEditInventory() {
		return !!this.roles[this.checkTransaccionL2Manager()];
	}
}

export default DashboardAuthorization;
