//Libs
import React from 'react';
import styled from 'styled-components';
//Components
import IconButton from '@mui/material/IconButton';
import icon from './download.svg';

const Icon = styled.div`
	margin: ${props => props.margin};
	background-image: url(${icon});
	width: 24px;
	height: 24px;
`;

const DownloadIcon = ({ button, onClick, margin }) =>
	button ? (
		<IconButton onClick={onClick}>
			<Icon />
		</IconButton>
	) : (
		<Icon margin={margin} onClick={onClick} />
	);

export default DownloadIcon;
