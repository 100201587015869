// Libs
import React, { useState, useEffect } from 'react';
// Hooks
import { useOrderApi } from 'hooks';
// Components
import { Wrapper } from 'components';
import OrderHistoryCard from 'screens/Utils/Lists/cards/Orders/OrderHistoryCard';
import { EmptyIllustration } from 'components/Illustrations';
import Spining from '../Spining';

export default function HistoryTab({ orderId }) {
	const { getOrderHistory } = useOrderApi();
	const [state, _setState] = useState({
		isFetching: false,
		history: [],
	});
	const setState = props => _setState(prev => ({ ...prev, ...props }));

	useEffect(() => {
		let isMounted = true;
		setState({ isFetching: true });
		getOrderHistory(orderId)
			.then(history => isMounted && setState({ history, isFetching: false }))
			.catch(() => isMounted && setState({ isFetching: false }));

		return () => {
			isMounted = false;
		};
	}, [orderId]);

	if (state.isFetching) {
		return <Spining />;
	}

	const empty = !state.history.length;
	return (
		<Wrapper
			padding='0'
			height={`${document.documentElement.clientHeight - 132}px`}
			margin={empty ? '0 0 20px 0' : ''}
			flexDirection='column'
			alignItems='stretch'
			overflow='auto'
		>
			{empty ? (
				<EmptyIllustration
					title='No se encontraron registros'
					width='200px'
					fontSize='2em'
				/>
			) : (
				state.history.map(row => <OrderHistoryCard key={row.id} {...row} />)
			)}
		</Wrapper>
	);
}
