//  Constants
import GENERAL from '../../utils/constants/general';
const { ENV, CONTRACTS } = GENERAL;

const initialState = {
	createContract: {
		status: ENV.STATUS.INITIAL,
	},
	updateContractModal: {
		isOpen: false,
		loading: false,
		contractIds: [],
		fieldsToUpdate: {
			city_id: undefined,
			township_id: undefined,
			zone_id: undefined,
		},
	},
};

export default function contractReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONTRACTS.MUTATE_DIRECT_PROPS: {
			const keyValuePairs = payload;
			return {
				...state,
				...keyValuePairs,
			};
		}
		case CONTRACTS.MUTATE_1OBJECT: {
			const { obj1Name, keyValuePairs } = payload;
			const newObj = { ...state[obj1Name], ...keyValuePairs };
			return {
				...state,
				[obj1Name]: newObj,
			};
		}
		case CONTRACTS.RESET: {
			return initialState;
		}
		default:
			return state;
	}
}
