// Libs
import React from 'react';
// Services
import MQTTService, { STATUS, TOPICS, EVENTS } from 'services/mqttService';

const RealtimeContext = React.createContext({});
const { Provider, Consumer: RealtimeConsumer } = RealtimeContext;

// PROVIDER
const RealtimeProvider = ({ children }) => {
	const [client, setClient] = React.useState(null);
	const [status, setStatus] = React.useState(STATUS.DISCONNECTED);
	const [payload, setPayload] = React.useState(null);

	// Connect
	const mqttConnect = () => {
		setStatus(STATUS.CONNECTING);
		setClient(MQTTService.connect());
	};

	React.useEffect(() => {
		let isMounted = true;

		if (!client) {
			if (!isMounted) return;
			mqttConnect();
		} else {
			client.on(EVENTS.CONNECT, () => {
				console.log(STATUS.CONNECTED);
				if (!isMounted) return;
				setStatus(STATUS.CONNECTED);
			});
			client.on(EVENTS.RECONNECT, () => {
				console.log(STATUS.RECONNECTING);
				if (!isMounted) return;
				setStatus(STATUS.RECONNECTING);
			});
			client.on(EVENTS.ERROR, err => {
				console.error('Connection error: ', err);
				client.end();
			});
			client.on(EVENTS.MESSAGE, (topic, _message) => {
				console.log('Message receive: ', topic);

				if (!isMounted) return;
				const { action } = MQTTService.getDecodedTopic(topic);

				const message = MQTTService.getMessage(_message);
				if (!message) return;

				setPayload({ action, message });
			});
		}

		return () => {
			isMounted = false;
		};
	}, [client]);

	// Subscribe
	const mqttSub = React.useCallback(
		(topic, subscriber, { qos = 2 } = {}) => {
			if (!client || !topic) return;
			if (!MQTTService.validateSubscriber(subscriber)) return;

			const _topic = MQTTService.setTopic(topic, subscriber);
			if (!_topic) return;

			client.subscribe(_topic, { qos }, error => {
				if (error) {
					console.log('Subscribe to topics error', error);
					return;
				}
				console.log(`Subscribed to topic: ${_topic}`);
			});
		},
		[client],
	);

	// Unsubscribe
	const mqttUnSub = React.useCallback(
		(topic, subscriber) => {
			if (!client || !topic) return;
			if (!MQTTService.validateSubscriber(subscriber)) return;

			const _topic = MQTTService.setTopic(topic, subscriber);
			if (!_topic) return;

			client.unsubscribe(_topic, error => {
				if (error) {
					console.log('Unsubscribe error', error);
					return;
				}
				console.log(`Unsubscribed from topic: ${_topic}`);
			});
		},
		[client],
	);

	// Publish
	const mqttPublish = React.useCallback(
		(topic, subscriber, payload, { qos = 2 } = {}) => {
			if (!client || !topic) return;
			if (!MQTTService.validateSubscriber(subscriber)) return;
			if (!MQTTService.validatePayload(payload)) return;

			// Topic absolute path
			const _topic = MQTTService.setTopic(topic, subscriber);
			if (!_topic) return;

			// Formatted payload
			const _payload = MQTTService.setPublisherPayload(payload);

			client.publish(_topic, _payload, { qos }, error => {
				if (error) {
					console.log('Publish error: ', error);
				}
			});
		},
		[client],
	);

	// Disconnect
	const mqttDisconnect = React.useCallback(() => {
		if (!client) return;
		client.end(() => setStatus(STATUS.DISCONNECTED));
	}, [client]);

	const value = React.useMemo(
		() => ({
			client,
			isConnected: status === STATUS.CONNECTED,
			payload,
			mqttSub,
			mqttUnSub,
			mqttPublish,
			mqttDisconnect,
		}),
		[client, status, payload],
	);

	return <Provider value={value}>{children}</Provider>;
};

export { RealtimeContext, RealtimeProvider, RealtimeConsumer, TOPICS };
