//  Constants
import GENERAL from '../../utils/constants/general';
import { onUpdateContractValidation } from './actionsValidators';
const { CONTRACTS, DASHBOARD } = GENERAL;

export function mutateDirectProps(keyValuePairs) {
	return {
		type: CONTRACTS.MUTATE_DIRECT_PROPS,
		payload: keyValuePairs,
	};
}

export function mutate1Object(obj1Name, keyValuePairs) {
	return {
		type: CONTRACTS.MUTATE_1OBJECT,
		payload: { obj1Name, keyValuePairs },
	};
}

export const onCreateContract = contract => ({
	type: CONTRACTS.CREATE_CONTRACT,
	payload: contract,
});

export function onUpdateContract(updateContractModal) {
	const errors = onUpdateContractValidation(updateContractModal);

	if (errors) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: errors,
				type: 'warn',
				duration: 60,
			},
		};
	}

	return {
		type: CONTRACTS.UPDATE_CONTRACT,
		payload: updateContractModal,
	};
}
