// Libs
import cx from 'classnames';
// Hooks
import useTheme from 'hooks/useTheme';
// Styles
import styles from './SubCaption.module.css';

const SubCaption = props => {
	const { theme, sharedStyles } = useTheme();
	return (
		<span
			onClick={props.onClick}
			style={{ width: props.width, margin: props.margin }}
			className={cx(
				sharedStyles.standardTextStructure,
				styles.subcaption,
				theme.mediumEmphasisColor,
				{
					[sharedStyles.hoverable]: !!props.hoverable,
					[sharedStyles.ellipsis]: !!props.ellipsis,
					[sharedStyles.wordBreak]: !!props.wordBreak,
					[sharedStyles[`textAlign_${props.textAlign}`]]: !!props.textAlign,
					[theme.highEmphasisColor]: !!props.emphasis,
					[theme.hoverable]: !!props.hoverable,
					[theme.secondaryColor]: !!props.selected,
					[theme.inactiveColor]: !!props.disabled,
				},
			)}
		>
			{props.children}
		</span>
	);
};

export default SubCaption;
