import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
	position: sticky;
	top: 0;
	bottom: 0;
	z-index: 999;
	${({ alignment }) =>
		alignment &&
		css`
			${alignment}: 0;
		`}

	background-color: rgba(24, 26, 27, .9);
	border-color: transparent;
	color: whitesmoke;
	width: 3em;

	&:hover {
		background-color: green;
	}
`;

const Paddle = ({ alignment, onClick, visible = true, children }) => {
	return (
		visible && (
			<StyledButton
				alignment={alignment}
				onClick={onClick}
				children={children}
			/>
		)
	);
};

export default Paddle;
