// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M26 12V2H22V12H18V2H14V12H10V2H6.2V12H4V14H28V12H26ZM26 18H22V28H18V18H14V28H10V18H6.2V28H4V30H28V28H26V18Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
