// Libs
import React from 'react';
// Utils
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
// Actions
import * as warehouseActions from './actions';
import * as dashboardActions from '../Dashboard/actions';
import * as contractActions from '../Contracts/actions';
import * as orderActions from '../Orders/actions';
import * as apiActions from 'store/api/actions';
// Selectors
import { selectCoins } from 'store/api/selectors';
// Components
import ModalsWarehouses from './Modals';
import { Wrapper } from 'components';
// Styles
import './index.css';
const actions = {
	// WAREHOUSES
	...warehouseActions,
	// DASHBOARD
	sendToast: dashboardActions.sendToast,
	// CONTRACTS
	mutate1ObjectInContracts: contractActions.mutate1Object,
	onUpdateContract: contractActions.onUpdateContract,
	// ORDERS
	mutate1ObjectInOrders: orderActions.mutate1Object,
	revertBill: orderActions.revertBill,
	setOrderProps: orderActions.setOrderProps,
	resetOrderInformation: orderActions.resetOrderInformation,
	sendCommentReport: orderActions.sendCommentReport,
	getOrder: orderActions.getOrder,
	// API
	mutate1ObjectApi: apiActions.mutate1ObjectApi,
	getCoins: apiActions.getCoins,
	getTransactionTypes: apiActions.getTransactionTypes,
	getIncomeMethods: apiActions.getIncomeMethods,
	getMeasuredUnits: apiActions.getMeasuredUnits,
	getItemStates: apiActions.getItemStates,
	getWarehousePackages: apiActions.getWarehousePackages,
	getZonesAreas: apiActions.getZonesAreas,
	getItemFamilies: apiActions.getItemFamilies,
	getArticles: apiActions.getArticles,
	getWarehouses: apiActions.getWarehouses,
	getStockArticles: apiActions.getStockArticles,
	getStockSeries: apiActions.getStockSeries,
	getControlSeries: apiActions.getControlSeries,
	getTransactionDocs: apiActions.getTransactionDocs,
	getInventoryToTransfer: apiActions.getInventoryToTransfer,
	getItemsTransactionDocs: apiActions.getItemsTransactionDocs,
	getSerieHistory: apiActions.getSerieHistory,
	updateWTDSigner: apiActions.updateWTDSigner,
	signWTD: apiActions.signWTD,
	doResetApiData: apiActions.doResetApiData,
};

//  **************************************************** COMPONENT CLASS *************************************************************
class Warehouses extends React.Component {
	constructor(...props) {
		super(...props);

		this.filterArticlesSearchInData =
			this.filterArticlesSearchInData.bind(this);
		this.filterTransactionsSearchInData =
			this.filterTransactionsSearchInData.bind(this);
		this.filterItemsTransactionDocsSearchInData =
			this.filterItemsTransactionDocsSearchInData.bind(this);
	}

	//  ******************************************************* LIFECYCLES ****************************************************************
	componentDidMount() {
		this.props.getCoins();
		this.props.getTransactionTypes();
		this.props.getIncomeMethods();
		this.props.getMeasuredUnits();
		this.props.getItemStates();
		this.props.getZonesAreas();
		this.props.getWarehousePackages();
		this.props.getItemFamilies();
		this.props.getArticles();
		this.props.getWarehouses();
		this.props.getTransactionDocs(this.props.getTransactionsModal);
	}

	componentWillUnmount() {
		this.props.doReset();
		this.props.doResetApiData('WAREHOUSES');
	}

	//  ******************************************************* HANDLERS ****************************************************************
	//  ****************************************************** FUNCTIONS ************************************************************
	filterArticlesSearchInData(articles, searchBy, data) {
		switch (searchBy) {
			case 'name':
				return articles.filter(article =>
					article.name.toUpperCase().includes(data.toUpperCase()),
				);
			case 'code':
				return articles.filter(article =>
					article.item.toUpperCase().includes(data.toUpperCase()),
				);
			case 'creator':
				return articles.filter(article =>
					article.creator_name.toUpperCase().includes(data.toUpperCase()),
				);
			case 'entity':
				return articles.filter(article =>
					article.entity_name.toUpperCase().includes(data.toUpperCase()),
				);
			case 'all':
				return articles.filter(
					article =>
						article.name.toUpperCase().includes(data.toUpperCase()) ||
						article.item.toUpperCase().includes(data.toUpperCase()) ||
						article.creator_name.toUpperCase().includes(data.toUpperCase()) ||
						article.entity_name.toUpperCase().includes(data.toUpperCase()),
				);
		}
	}

	filterTransactionsSearchInData(transactions, searchBy, data) {
		switch (searchBy) {
			case 'doc_number':
				return transactions.filter(transaction =>
					transaction.doc_number.toUpperCase().includes(data.toUpperCase()),
				);
			case 'transaction_type':
				return transactions.filter(transaction =>
					transaction.transaction_type_name
						.toUpperCase()
						.includes(data.toUpperCase()),
				);
			case 'method':
				return transactions.filter(transaction =>
					transaction.method_name.toUpperCase().includes(data.toUpperCase()),
				);
			case 'owarehouse':
				return transactions.filter(transaction =>
					transaction.owarehouse_name
						.toUpperCase()
						.includes(data.toUpperCase()),
				);
			case 'dwarehouse':
				return transactions.filter(transaction =>
					transaction.dwarehouse_name
						.toUpperCase()
						.includes(data.toUpperCase()),
				);
			case 'signer':
				return transactions.filter(transaction =>
					transaction.signer_name.toUpperCase().includes(data.toUpperCase()),
				);
			case 'creator':
				return transactions.filter(transaction =>
					transaction.creator_name.toUpperCase().includes(data.toUpperCase()),
				);
			case 'entity':
				return transactions.filter(transaction =>
					transaction.entity_name.toUpperCase().includes(data.toUpperCase()),
				);
			case 'completed':
				return transactions.filter(transaction =>
					transaction.completed_label
						.toUpperCase()
						.includes(data.toUpperCase()),
				);
			case 'edit':
				return transactions.filter(transaction =>
					transaction.edit_label.toUpperCase().includes(data.toUpperCase()),
				);
			case 'all':
				return transactions.filter(
					transaction =>
						transaction.doc_number.toUpperCase().includes(data.toUpperCase()) ||
						transaction.transaction_type_name
							.toUpperCase()
							.includes(data.toUpperCase()) ||
						transaction.method_name
							.toUpperCase()
							.includes(data.toUpperCase()) ||
						transaction.owarehouse_name
							.toUpperCase()
							.includes(data.toUpperCase()) ||
						transaction.dwarehouse_name
							.toUpperCase()
							.includes(data.toUpperCase()) ||
						(transaction.signer_name &&
							transaction.signer_name
								.toUpperCase()
								.includes(data.toUpperCase())) ||
						transaction.creator_name
							.toUpperCase()
							.includes(data.toUpperCase()) ||
						transaction.entity_name
							.toUpperCase()
							.includes(data.toUpperCase()) ||
						transaction.completed_label
							.toUpperCase()
							.includes(data.toUpperCase()) ||
						transaction.edit_label.toUpperCase().includes(data.toUpperCase()),
				);
		}
	}

	filterItemsTransactionDocsSearchInData(items, searchBy, data) {
		switch (searchBy) {
			case 'code':
				return items.filter(item =>
					item.item_code.toUpperCase().includes(data.toUpperCase()),
				);
			case 'name':
				return items.filter(item =>
					item.item_name.toUpperCase().includes(data.toUpperCase()),
				);
			case 'serie':
				return items.filter(
					item =>
						item.serie && item.serie.toUpperCase().includes(data.toUpperCase()),
				);
			case 'all':
				return items.filter(
					item =>
						item.item_code.toUpperCase().includes(data.toUpperCase()) ||
						item.item_name.toUpperCase().includes(data.toUpperCase()) ||
						(item.serie &&
							item.serie.toUpperCase().includes(data.toUpperCase())),
				);
		}
	}

	//  ******************************************************** JSX RENDER ****************************************************************
	render() {
		return (
			<Wrapper padding='0'>
				{this.props.routes.map((route, i) => (
					<Route
						key={i}
						path={route.path}
						render={props => (
							<route.component
								{...props}
								{...this.props}
								routes={route.routes}
								filterArticlesSearchInData={this.filterArticlesSearchInData}
								filterTransactionsSearchInData={
									this.filterTransactionsSearchInData
								}
								filterItemsTransactionDocsSearchInData={
									this.filterItemsTransactionDocsSearchInData
								}
							/>
						)}
					/>
				))}
				<ModalsWarehouses {...this.props} />
			</Wrapper>
		);
	}
}

//  ********************************************************** PROPTYPES ***************************************************************
Warehouses.propTypes = {
	// Props
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	// Functions
	mutateDirectProps: PropTypes.func.isRequired,
	mutate1Object: PropTypes.func.isRequired,
	getTransactionTypes: PropTypes.func.isRequired,
	getWarehouses: PropTypes.func.isRequired,
	getIncomeMethods: PropTypes.func.isRequired,
	getArticles: PropTypes.func.isRequired,
	sendToast: PropTypes.func.isRequired,
};

export default connect(
	state => ({
		...state.warehouses,
		// -----------EXTERNAL PROPS-----------------
		// LOGIN
		profile: state.login.profile,
		// DASHBOARD
		modeView: state.dashboard.modeView,
		// USERS
		users: {
			data: state.api.users.data,
		},
		// ORDERS
		ordersState: {
			control: {
				orders: state.orders.control.orders,
			},
			getOrderInformationModal: {
				...state.orders.getOrderInformationModal,
			},
		},
		// CONTRACTS
		updateContractModal: state.contracts.updateContractModal,
		// API
		coins: selectCoins(state),
		transactionTypes: state.api.transactionTypes,
		incomeMethods: state.api.incomeMethods,
		measuredUnits: state.api.measuredUnits,
		itemStates: state.api.itemStates,
		warehousePackages: state.api.warehousePackages,
		warehouseAreas: state.api.warehouseAreas,
		warehouseZones: state.api.warehouseZones,
		itemFamilies: state.api.itemFamilies,
		articles: {
			loading: state.api.articles.loading,
			data: state.api.articles.data,
		},
		warehouses: {
			loading: state.api.warehouses.loading,
			data: state.api.warehouses.data,
		},
		stock: {
			warehouseId: state.api.stock.warehouseId,
			ownerId: state.api.stock.ownerId,
			itemId: state.api.stock.itemId,
			stateId: state.api.stock.stateId,
			articles: {
				loading: state.api.stock.articles.loading,
				data: state.api.stock.articles.data,
			},
			series: {
				loading: state.api.stock.series.loading,
				data: state.api.stock.series.data,
			},
		},
		serieHistory: {
			isOpen: state.api.serieHistory.isOpen,
			loading: state.api.serieHistory.loading,
			data: state.api.serieHistory.data,
		},
		transactions: {
			loading: state.api.transactions.loading,
			data: state.api.transactions.data,
			items: {
				loading: state.api.transactions.items.loading,
				data: state.api.transactions.items.data,
			},
			inventory: {
				loading: state.api.transactions.inventory.loading,
				data: state.api.transactions.inventory.data,
			},
		},
		zones: {
			...state.api.zones,
		},
	}),
	actions,
)(Warehouses);
