// Libs
import React from 'react';
import { Link } from 'react-router-dom';
import { Select, Input, Button } from 'antd';
import {
	filterOption,
	getSigners,
	getMyAllowedWarehouses,
	getMyOwnWarehouses,
	getProviderWarehouses,
	getWarehousesFilteringWarehouse,
} from '../../../../../utils/libs';
// Utils
import GENERAL from '../../../../../utils/constants/general';
// Styles
import '../../index.css';

const { ENV } = GENERAL;
const { Option } = Select;
const { TextArea } = Input;

export default function CreateTransactionModal(props) {
	const {
		totalUsers,
		createTransaction,
		incomeMethods,
		warehouses,
		mutate1ObjectInWarehouses,
		getInventoryToTransfer,
		history,
		profile,
	} = props;
	const signers = getSigners(totalUsers, createTransaction.owhOwnerId);
	const owarehouses =
		createTransaction.mode === 'newEntry'
			? getProviderWarehouses(warehouses)
			: getMyAllowedWarehouses(warehouses, profile);

	let dwarehouses = getWarehousesFilteringWarehouse(
		warehouses,
		createTransaction.owarehouseId,
	);
	if (
		createTransaction.mode === 'newEntry' ||
		createTransaction.mode === 'trsReceive'
	) {
		dwarehouses = getMyOwnWarehouses(dwarehouses, profile);
	}

	function getPath() {
		if (
			history.location.pathname ===
			ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_DESKTOP
		) {
			if (createTransaction.mode === 'newEntry') {
				return ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_DESKTOP;
			}
			if (createTransaction.mode === 'trsSend') {
				return ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_INVENTORY_DESKTOP;
			}
			if (createTransaction.mode === 'trsReceive') {
				return ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_RECEPTION;
			}
		} else if (
			history.location.pathname ===
			ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_MOBILE
		) {
			if (createTransaction.mode === 'newEntry') {
				return ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_MOBILE;
			}
			if (createTransaction.mode === 'trsSend') {
				return ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_INVENTORY_MOBILE;
			}
			if (createTransaction.mode === 'trsReceive') {
				return ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_RECEPTION;
			}
		}
		return '';
	}

	const path = getPath();

	return (
		<div className='modal-container'>
			<div className='create-article-form-items'>
				<Select
					className='form-item-standard create-article-form-items-select'
					showSearch
					placeholder='Origen*'
					value={createTransaction.owarehouseId}
					filterOption={filterOption}
					onChange={value => {
						const warehouse = warehouses.filter(
							warehouse => warehouse.id === value,
						)[0];
						mutate1ObjectInWarehouses('createTransaction', {
							owarehouseId: value,
							owhOwnerId: warehouse.owner_id,
							dwarehouseId: undefined,
							signer: undefined,
						});
					}}
				>
					{owarehouses.map((warehouse, idx) => (
						<Option key={idx} value={warehouse.id}>
							<em>{warehouse.name}</em>
						</Option>
					))}
				</Select>

				<Select
					className='form-item-standard create-article-form-items-select'
					showSearch
					placeholder='Destino*'
					value={createTransaction.dwarehouseId}
					filterOption={filterOption}
					onChange={value => {
						if (createTransaction.mode === 'newEntry') {
							const dwh = dwarehouses.filter(
								warehouse => warehouse.id === value,
							)[0];
							mutate1ObjectInWarehouses('createTransaction', {
								dwarehouseId: value,
								dwarehouseName: dwh.name,
							});
						} else
							mutate1ObjectInWarehouses('createTransaction', {
								dwarehouseId: value,
							});
					}}
				>
					{dwarehouses.map((warehouse, idx) => (
						<Option key={idx} value={warehouse.id}>
							<em>{warehouse.name}</em>
						</Option>
					))}
				</Select>

				<Select
					className='form-item-standard create-article-form-items-select'
					showSearch
					placeholder='Autorizado Por*'
					value={createTransaction.signer}
					filterOption={filterOption}
					onChange={value =>
						mutate1ObjectInWarehouses('createTransaction', { signer: value })
					}
				>
					{signers.map((user, idx) => (
						<Option key={idx} value={user.id}>
							<em>{user.name}</em>
						</Option>
					))}
				</Select>

				<Select
					className='form-item-standard create-article-form-items-select'
					placeholder='Método*'
					value={createTransaction.incomeMethod}
					onChange={value =>
						mutate1ObjectInWarehouses('createTransaction', {
							incomeMethod: value,
						})
					}
				>
					{incomeMethods.map((method, idx) => (
						<Option key={idx} value={method.id}>
							<em>{method.name}</em>
						</Option>
					))}
				</Select>

				<TextArea
					style={{ width: '260px' }}
					className='form-item-standard'
					placeholder='Comentario'
					rows={3}
					value={createTransaction.comment}
					onChange={e =>
						mutate1ObjectInWarehouses('createTransaction', {
							comment: e.target.value,
						})
					}
				/>

				<div className='create-article-form-action-button'>
					{/* SHOW ARTICLES & SERIES BUTTON */}
					<Link
						disabled={
							!createTransaction.owarehouseId ||
							!createTransaction.dwarehouseId ||
							!createTransaction.incomeMethod ||
							!createTransaction.signer
						}
						to={path}
					>
						<Button
							className='button-style-1'
							disabled={
								!createTransaction.owarehouseId ||
								!createTransaction.dwarehouseId ||
								!createTransaction.incomeMethod ||
								!createTransaction.signer
							}
							onClick={() => {
								if (createTransaction.mode !== 'newEntry') {
									getInventoryToTransfer(createTransaction.owarehouseId);
								}
								mutate1ObjectInWarehouses('createTransaction', {
									isOpen: false,
								});
							}}
						>
							Ingresar inventario
						</Button>
					</Link>
				</div>
			</div>
		</div>
	);
}
