import _ from 'underscore';
import GENERAL from '../constants/general';

const { DIVISION_ID } = GENERAL;

function getSigners(users, userId) {
	const activeUsers = getActiveUsers(users);
	const idx = _.findIndex(activeUsers, user => user.id === userId);

	if (idx === -1) return [];

	const user = activeUsers[idx];
	if (user.division_id === DIVISION_ID.ENTITY)
		return activeUsers.filter(
			usr => usr.entity_id === user.id && usr.division_id === DIVISION_ID.ADM,
		);
	else if (user.division_id === DIVISION_ID.EXP)
		return activeUsers.filter(usr => usr.id === user.id);
}

function getSignersAndMyAdmUsers(users, userId, profile) {
	const activeUsers = getActiveUsers(users);
	const idx = _.findIndex(activeUsers, user => user.id === userId);

	if (idx === -1) return [];

	const user = activeUsers[idx];
	if (user.division_id === DIVISION_ID.ENTITY)
		return users.filter(
			usr =>
				(usr.entity_id === user.id || usr.entity_id === profile.entity.id) &&
				usr.division_id === DIVISION_ID.ADM,
		);
	else if (user.division_id === DIVISION_ID.EXP)
		return users.filter(
			usr =>
				usr.id === user.id ||
				(usr.entity_id === profile.entity.id &&
					usr.division_id === DIVISION_ID.ADM),
		);
}

function getSignersWTransactionsAndMyAdmUsers(users, transaction, profile) {
	if (!transaction) return [];

	const activeUsers = getActiveUsers(users);
	const idxOwhOwner = _.findIndex(
		activeUsers,
		user => user.id === transaction.owh_owner_id,
	);
	const idxDwhOwner = _.findIndex(
		activeUsers,
		user => user.id === transaction.dwh_owner_id,
	);
	const owhOwner = activeUsers[idxOwhOwner];
	const dwhOwner = activeUsers[idxDwhOwner];

	if (idxOwhOwner === -1 || idxDwhOwner === -1) return [];
	if (owhOwner.division_id === DIVISION_ID.ENTITY) {
		if (dwhOwner.division_id === DIVISION_ID.ENTITY) {
			return users.filter(
				user =>
					// Admins que pertenezcan a mi entidad
					(user.entity_id === profile.entity.id &&
						user.division_id === DIVISION_ID.ADM) ||
					// Admins que pertenezcan a la entidad origen
					(user.entity_id === owhOwner.id &&
						user.division_id === DIVISION_ID.ADM) ||
					// Admins que pertenezcan a la entidad destino
					(user.entity_id === dwhOwner.id &&
						user.division_id === DIVISION_ID.ADM),
			);
		} else if (dwhOwner.division_id === DIVISION_ID.EXP) {
			return users.filter(
				user =>
					// Admins que pertenezcan a mi entidad
					(user.entity_id === profile.entity.id &&
						user.division_id === DIVISION_ID.ADM) ||
					// Admins que pertenezcan a la entidad origen
					(user.entity_id === owhOwner.id &&
						user.division_id === DIVISION_ID.ADM) ||
					// Experto destino
					user.id === dwhOwner.id,
			);
		}
	} else if (owhOwner.division_id === DIVISION_ID.EXP) {
		if (dwhOwner.division_id === DIVISION_ID.ENTITY) {
			return users.filter(
				user =>
					// Admins que pertenezcan a mi entidad
					(user.entity_id === profile.entity.id &&
						user.division_id === DIVISION_ID.ADM) ||
					// Admins que pertenezcan a la entidad destino
					(user.entity_id === dwhOwner.id &&
						user.division_id === DIVISION_ID.ADM) ||
					// Experto origen
					user.id === owhOwner.id,
			);
		} else if (dwhOwner.division_id === DIVISION_ID.EXP) {
			return users.filter(
				user =>
					// Admins que pertenezcan a mi entidad
					(user.entity_id === profile.entity.id &&
						user.division_id === DIVISION_ID.ADM) ||
					// Experto origen
					user.id === owhOwner.id ||
					// Experto destino
					user.id === dwhOwner.id,
			);
		}
	}
}

function getMyEntityUsers(users, profile) {
	// Único método que retorna incluso los usuarios inactivos. Éste método se debe quedar así
	if (profile.entity.id === profile.organization.settings.mainEntity)
		return users;
	return users.filter(user => {
		if (
			user.division_id === DIVISION_ID.ENTITY &&
			(user.id === profile.entity.id ||
				(user.released &&
					(user.released[profile.entity.id] || user.released[profile.user.id])))
		)
			return user;
		else if (
			(user.division_id === DIVISION_ID.ADM ||
				user.division_id === DIVISION_ID.EXP) &&
			(user.entity_id === profile.entity.id ||
				(user.released &&
					(user.released[profile.entity.id] || user.released[profile.user.id])))
		)
			return user;
	});
}

function getActiveUsers(users) {
	return users.filter(user => user.active);
}

function getMyEntityActiveUsers(users, profile) {
	return getMyEntityUsers(getActiveUsers(users), profile);
}

function getSpecificUsers(users, { entities, admins, experts }) {
	return getActiveUsers(users).filter(user => {
		if (entities && user.division_id === DIVISION_ID.ENTITY) return user;
		if (admins && user.division_id === DIVISION_ID.ADM) return user;
		if (experts && user.division_id === DIVISION_ID.EXP) return user;
	});
}

function getNameByUserId(userId, users) {
	const user = users.filter(usr => usr.id === userId)[0];
	if (user) return user.name;
	return 'Desconocido';
}

function getUsersWithEmail(users) {
	return users.reduce((acc, user) => {
		if (Array.isArray(user.profile.emails) && user.profile.emails.length > 0) {
			acc.push({
				id: user.id,
				name: user.name,
				email: user.profile.emails[0],
			});
		}
		return acc;
	}, []);
}

export {
	getSigners,
	getSignersAndMyAdmUsers,
	getSignersWTransactionsAndMyAdmUsers,
	getMyEntityUsers,
	getActiveUsers,
	getSpecificUsers,
	getMyEntityActiveUsers,
	getNameByUserId,
	getUsersWithEmail,
};
