import exportMultiSheetToExcel from './MultiSheet';
import { getZone, getArea } from 'screens/Warehouses/utils';

export function exportWarehousesListToExcel(fileName, data) {
	data = data.reduce((acc, row) => {
		acc.push({
			Almacen: row.name,
			Propietario: row.owner_name,
			Entidad: row.entity_name,
			Tipo: row.type,
			País: row.country,
			Direccion: row.address,
			Creador: row.creator_name,
			Creado: row.created_at,
			Actualizado: row.updated_at,
		});

		return acc;
	}, []);

	exportMultiSheetToExcel(fileName, [
		{
			name: fileName,
			data,
		},
	]);
}

export function exportIndividualStockArticlesToExcel(fileName, data) {
	data = data.reduce((acc, row) => {
		acc.push({
			Codigo: row.item_code,
			Nombre: row.item_name,
			Serializado: row.serialized,
			Cantidad: row.amount,
			Tránsito: row.amount_transit,
			// Permitido: row.allowed_to_use_label,
			Estado: row.state_name,
			Paquete: row.warehouse_package_id,
			Area: row.area_name,
			Zona: row.zone_name,
			Almacén: row.warehouse_name,
			Documento: row.warehouse_transaction_doc_id,
			Creado: row.created_at,
			Actualizado: row.updated_at,
		});

		return acc;
	}, []);

	exportMultiSheetToExcel(fileName, [
		{
			name: fileName,
			data,
		},
	]);
}

export function exportConsolidateStockArticlesToExcel(fileName, data) {
	data = data.reduce((acc, row) => {
		acc.push({
			Codigo: row.item_code,
			Nombre: row.item_name,
			Serializado: row.serialized,
			Cantidad: row.amount,
			Tránsito: row.amount_transit,
			Estado: row.state_name,
			Entidad: row.entity_name,
			Documento: row.warehouse_transaction_doc_id,
			Creado: row.created_at,
			Actualizado: row.updated_at,
		});

		return acc;
	}, []);

	exportMultiSheetToExcel(fileName, [
		{
			name: fileName,
			data,
		},
	]);
}

export function exportStockSeriesToExcel(fileName, data) {
	data = data.reduce((acc, row) => {
		acc.push({
			Codigo: row.item_code,
			Nombre: row.item_name,
			Serie: row.serie,
			// Permitido: row.allowed_to_use_label,
			Estado: row.state_name,
			Almacen: row.warehouse_name,
			Zona: row.zone_name,
			Area: row.area_name,
			Paquete: row.warehouse_package_id,
			Documento: row.doc_number,
			Creado: row.created_at,
			Actualizado: row.updated_at,
		});

		return acc;
	}, []);

	exportMultiSheetToExcel(fileName, [
		{
			name: fileName,
			data,
		},
	]);
}

export function exportControlSeriesToExcel(fileName, data) {
	data = data.reduce((acc, row) => {
		acc.push({
			Codigo: row.item_code,
			Nombre: row.item_name,
			Serie: row.serie,
			Estado: row.state_name,
			Documento: row.doc_number,
			Propietario: row.owner_name,
			Entidad: row.entity_name,
			Creado: row.created_at,
			Actualizado: row.updated_at,
		});

		return acc;
	}, []);

	exportMultiSheetToExcel(fileName, [
		{
			name: fileName,
			data,
		},
	]);
}

export function exportResumeReceiveInventory(
	fileName,
	foundData,
	notFoundData,
	zones,
	areas,
	packages,
) {
	foundData = foundData.reduce((acc, row) => {
		acc.push({
			Código: row.item_code,
			Nombre: row.item_name,
			Serializado: row.serialized,
			Cantidad: row.amount,
			Serie: row.serie,
			Estado: row.state_name,
			Almacén: row.warehouse_name,
			Orden: row.odt_id,
			Cliente: row.client_name,
			Técnico: row.expert_name,
			Zona: getZone(row.warehouse_package_id, packages, areas, zones),
			Área: getArea(row.warehouse_package_id, packages, areas),
			Paquete: row.warehouse_package_id,
		});
		return acc;
	}, []);
	notFoundData = notFoundData.reduce((acc, row) => {
		acc.push({
			Serie: row.serie,
		});
		return acc;
	}, []);

	exportMultiSheetToExcel(fileName, [
		{
			name: 'Encontrados',
			data: foundData,
		},
		{
			name: 'No encontrados',
			data: notFoundData,
		},
	]);
}

export function exportItemsTransactionDoc(fileName, data, transaction) {
	data = data.reduce((acc, row) => {
		acc.push({
			// ["Fecha Entrada"]: row.created_at, //TODO: Se debe traer la fecha en que se creó la serie (no el documento de transacción)
			Documento: transaction.doc_number,
			Código: row.item_code,
			Serie: row.serie,
			Cantidad: row.amount,
			Equipo: row.item_name,
			'Asignado a': transaction.dwarehouse_name,
			'Fecha Asignación': row.created_at,
		});

		return acc;
	}, []);

	exportMultiSheetToExcel(fileName, [
		{
			name: fileName,
			data,
		},
	]);
}
