// Libs
import React from 'react';
import { connect } from 'react-redux';
// Actions
import {
	getServices,
	getOrderStates,
	onGetZonesToAssignOrders,
} from 'store/api/actions';
import { mutate1Object } from 'screens/Orders/actions';
// Selectors
import { selectEnterNewOrdersModal } from 'screens/Orders/selectors';
// Components
import { Wrapper, Tabs } from 'components';
// Tabs
import CreateOrderTab from './CreateOrderTab';
import ImportOrdersTab from './ImportOrdersTab';

const CreateOrdersModal = ({
	// Props
	enterNewOrdersModal,
	// Actions
	mutate1Object,
	getServices,
	getOrderStates,
	onGetZonesToAssignOrders,
}) => {
	const { activeTab } = enterNewOrdersModal;

	React.useEffect(() => {
		if (activeTab === '2') {
			getOrderStates();
			onGetZonesToAssignOrders();
			getServices();
		}
	}, [activeTab]);

	return (
		<Wrapper>
			<Tabs.Wrap
				tabPosition='top'
				activeKey={activeTab}
				onChange={activeKey =>
					mutate1Object('enterNewOrdersModal', { activeTab: activeKey })
				}
			>
				<Tabs.Pane tab='Crear' key='1'>
					<CreateOrderTab />
				</Tabs.Pane>
				<Tabs.Pane tab='Importar' key='2'>
					<ImportOrdersTab />
				</Tabs.Pane>
			</Tabs.Wrap>
		</Wrapper>
	);
};

const mapStateToProps = state => ({
	enterNewOrdersModal: selectEnterNewOrdersModal(state),
});
const actions = {
	mutate1Object,
	getServices,
	getOrderStates,
	onGetZonesToAssignOrders,
};
export default connect(mapStateToProps, actions)(CreateOrdersModal);
