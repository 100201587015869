// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Utils
import { filterOption } from 'utils/libs';
// Components
import { Wrapper, Button, TextField } from 'components';
import Select from 'components/Select';

const { Option } = Select;
const { TextArea } = TextField;

export default function ControlSeriesTransactionModal(props) {
	const {
		// Props
		warehouses,
		itemStates,
		incomeMethods,
		controlSeries,
		controlSeriesTransactionModal,
		// Actions
		mutate1ObjectInWarehouses,
		onCreateControlSeriesTransaction,
	} = props;
	const theme = useTheme();

	return (
		<Wrapper flexDirection='column' backgroundColor={theme.colors.primary[500]}>
			{/* DESTINY WAREHOUSE */}
			<Select
				width='100%'
				showSearch
				placeholder='Destino'
				value={controlSeriesTransactionModal.dwarehouseId}
				filterOption={filterOption}
				onChange={value =>
					mutate1ObjectInWarehouses('controlSeriesTransactionModal', {
						dwarehouseId: value,
					})
				}
			>
				<Option value={undefined}>Seleccione</Option>
				{warehouses.data.map((warehouse, idx) => (
					<Option key={idx} value={warehouse.id}>
						{warehouse.name}
					</Option>
				))}
			</Select>

			{/* NEW STATE */}
			<Select
				margin='10px 0 0 0'
				width='100%'
				showSearch
				placeholder='Estado'
				value={controlSeriesTransactionModal.stateId}
				filterOption={filterOption}
				onChange={value =>
					mutate1ObjectInWarehouses('controlSeriesTransactionModal', {
						stateId: value,
					})
				}
			>
				<Option value={undefined}>Seleccione</Option>
				{itemStates.map((state, idx) => (
					<Option key={idx} value={state.id}>
						{state.name}
					</Option>
				))}
			</Select>

			{/* INCOME METHOD */}
			<Select
				margin='10px 0 0 0'
				width='100%'
				placeholder='Método*'
				value={controlSeriesTransactionModal.incomeMethodId}
				onChange={value =>
					mutate1ObjectInWarehouses('controlSeriesTransactionModal', {
						incomeMethodId: value,
					})
				}
			>
				{incomeMethods.map((method, idx) => (
					<Option key={idx} value={method.id}>
						{method.name}
					</Option>
				))}
			</Select>

			{/* COMMENT */}
			<TextArea
				margin='10px 0 0 0'
				placeholder='Comentario'
				rows={3}
				value={controlSeriesTransactionModal.comment}
				onChange={e =>
					mutate1ObjectInWarehouses('controlSeriesTransactionModal', {
						comment: e.target.value,
					})
				}
			/>

			<Button
				margin='10px 0 0 0'
				mode='primary'
				onClick={() =>
					onCreateControlSeriesTransaction({
						...controlSeriesTransactionModal,
						series: controlSeries.selected.items,
					})
				}
			>
				Confirmar
			</Button>
		</Wrapper>
	);
}
