// Libs
import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
// Utils
import { filterOption, getMyEntityWarehouses } from 'utils/libs';
import { getSpecificUsers, getUsersWithEmail } from 'utils/libs/filterUsers';
// Actions
import { getProjects } from 'core/actions';
import { requestGenerateReport } from 'screens/Reports/reports.actions';
import { getArticles, getWarehouses } from 'store/api/actions';
// Selectors
import {
	selectInventoryItems,
	selectWarehouses,
} from 'screens/Warehouses/selectors';
import { selectProjects } from 'core/selectors';
// Components
import {
	Wrapper,
	Button,
	TextField,
	Divider,
	DatePicker,
	TimePicker,
} from 'components';
import Select from 'components/Select';
import { selectProfile } from 'screens/Login/selectors';

const { Option } = Select;
const { TextArea, Input } = TextField;

const isRequiredParam = param => !!param?.required;
const isMultipleParam = param => !!param?.multiple;

function GenerateReportModal({
	// State
	projects,
	warehouses,
	inventoryItems = [],
	// Actions
	getProjects,
	getWarehouses,
	getArticles,
	// Props
	generateReportModal,
	report,
	users,
	mutate1ObjectInReports,
	requestGenerateReport,
}) {
	const usersWithEmail = useCallback(getUsersWithEmail(users), [users]);
	const { reportParams, reportProps } = report;
	const {
		params: userParams,
		toEmails,
		ccEmails,
		subject,
		message,
	} = generateReportModal;

	useEffect(() => {
		if (reportParams.projectsParam) getProjects({ moduleItemId: null });
		if (reportParams.warehouseParam) getWarehouses();
		if (reportParams.itemParam) getArticles();
	}, []);

	return (
		<Wrapper flexDirection='column' alignItems='flex-start' highBackground>
			{/* REPORT DETAILS */}
			{reportParams && Object.keys(reportParams).length > 0 && (
				<Divider color='gray' orientation='left'>
					Detalle del Reporte
				</Divider>
			)}

			{/* ENTITY PARAM */}
			{reportParams.entityParam && (
				<Select
					placeholder={
						isRequiredParam(reportParams.entityParam)
							? 'Contratistas*'
							: 'Contratistas'
					}
					mode={isMultipleParam(reportParams.entityParam) ? 'tags' : undefined}
					margin='20px 0 0 0'
					width='100%'
					showSearch
					value={userParams.entityParam}
					filterOption={filterOption}
					onChange={value => {
						if (isMultipleParam(reportParams.entityParam))
							// Allow listed ids only on select
							value = value.filter(
								val =>
									warehouses.findIndex(
										p => parseInt(p.id) === parseInt(val),
									) !== -1,
							);
						mutate1ObjectInReports('generateReportModal', {
							params: {
								...userParams,
								entityParam: isMultipleParam(reportParams.entityParam)
									? value
									: [value],
							},
						});
					}}
				>
					{getSpecificUsers(users, {
						entities: true,
					}).map((entity, idx) => (
						<Option key={idx} value={entity.id.toString()}>
							{entity.name}
						</Option>
					))}
				</Select>
			)}

			{/* PROJECTS PARAM */}
			{reportParams.projectsParam && (
				<Select
					placeholder={
						isRequiredParam(reportParams.projectsParam)
							? 'Proyectos*'
							: 'Proyectos'
					}
					mode='tags'
					margin='20px 0 0 0'
					width='100%'
					showSearch
					value={userParams.projectsParam}
					filterOption={filterOption}
					onChange={value => {
						// Allow only ids listed on select
						value = value.filter(
							val =>
								projects.findIndex(p => parseInt(p.id) === parseInt(val)) !==
								-1,
						);
						mutate1ObjectInReports('generateReportModal', {
							params: {
								...userParams,
								projectsParam: value,
							},
						});
					}}
				>
					{projects.map((project, idx) => (
						<Option key={idx} value={project.id.toString()}>
							{project.name}
						</Option>
					))}
				</Select>
			)}

			{/* WAREHOUSE PARAM */}
			{reportParams.warehouseParam && (
				<Select
					placeholder={
						isRequiredParam(reportParams.warehouseParam)
							? 'Almacenes*'
							: 'Almacenes'
					}
					mode={
						isMultipleParam(reportParams.warehouseParam) ? 'tags' : undefined
					}
					margin='20px 0 0 0'
					width='100%'
					showSearch
					value={userParams.warehouseParam}
					filterOption={filterOption}
					onChange={value => {
						if (isMultipleParam(reportParams.warehouseParam))
							// Allow listed ids only on select
							value = value.filter(
								val =>
									warehouses.findIndex(
										p => parseInt(p.id) === parseInt(val),
									) !== -1,
							);
						mutate1ObjectInReports('generateReportModal', {
							params: {
								...userParams,
								warehouseParam: isMultipleParam(reportParams.warehouseParam)
									? value
									: [value],
							},
						});
					}}
				>
					{warehouses.map((warehouse, idx) => (
						<Option key={idx} value={warehouse.id.toString()}>
							{warehouse.name}
						</Option>
					))}
				</Select>
			)}

			{/* INVENTORY ITEM PARAM */}
			{reportParams.itemParam && (
				<Select
					placeholder={
						isRequiredParam(reportParams.itemParam) ? 'Artículos*' : 'Artículos'
					}
					mode='tags'
					margin='20px 0 0 0'
					width='100%'
					showSearch
					value={userParams.itemParam}
					filterOption={filterOption}
					onChange={value => {
						// Allow only ids listed on select
						if (reportParams.itemParam?.onlyIdsListed) {
							value = value.filter(
								val =>
									inventoryItems.findIndex(
										it => parseInt(it.item_id) === parseInt(val),
									) !== -1,
							);
						}
						mutate1ObjectInReports('generateReportModal', {
							params: {
								...userParams,
								itemParam: value,
							},
						});
					}}
				>
					{inventoryItems.map((item, idx) => (
						<Option key={idx} value={item.item_id.toString()}>
							{`<${item.item}> ${item.shortName ?? item.name}`}
						</Option>
					))}
				</Select>
			)}

			{/* FROM DATE */}
			{reportParams.fromDateParam && (
				<Wrapper padding='0' alignItems='baseline' width='100%'>
					<DatePicker
						style={{ width: '100%', marginTop: '20px' }}
						placeholder={
							isRequiredParam(reportParams.fromDateParam) ? 'Desde*' : 'Desde'
						}
						value={userParams.fromDateParam}
						onChange={value =>
							mutate1ObjectInReports('generateReportModal', {
								params: {
									...userParams,
									fromDateParam: value,
								},
							})
						}
					/>
					{reportParams.fromDateParam.type === 'datetime' && (
						<TimePicker
							style={{ width: '100%', marginTop: '10px' }}
							allowClear={false}
							value={userParams.fromDateParam}
							onChange={value =>
								mutate1ObjectInReports('generateReportModal', {
									params: {
										...userParams,
										fromDateParam: value,
									},
								})
							}
						/>
					)}
				</Wrapper>
			)}

			{/* TO DATE */}
			{reportParams.toDateParam && (
				<Wrapper padding='0' alignItems='baseline' width='100%'>
					<DatePicker
						placeholder={
							isRequiredParam(reportParams.toDateParam) ? 'Hasta*' : 'Hasta'
						}
						style={{ width: '100%', marginTop: '10px' }}
						value={userParams.toDateParam}
						onChange={value =>
							mutate1ObjectInReports('generateReportModal', {
								params: {
									...userParams,
									toDateParam: value,
								},
							})
						}
					/>

					{reportParams.toDateParam.type === 'datetime' && (
						<TimePicker
							style={{ width: '100%', marginTop: '10px' }}
							allowClear={false}
							value={userParams.toDateParam}
							onChange={value =>
								mutate1ObjectInReports('generateReportModal', {
									params: {
										...userParams,
										toDateParam: value,
									},
								})
							}
						/>
					)}
				</Wrapper>
			)}

			<Divider color='gray' orientation='left'>
				Detalle del Correo
			</Divider>

			{/* TO EMAILS */}
			<Select
				placeholder='Destinatarios*'
				mode='tags'
				margin='20px 0 0 0'
				width='100%'
				showSearch
				value={toEmails}
				filterOption={filterOption}
				onChange={value =>
					mutate1ObjectInReports('generateReportModal', {
						toEmails: value,
					})
				}
			>
				{usersWithEmail
					.filter(usr => ccEmails.indexOf(usr.email) === -1)
					.map((user, idx) => (
						<Option key={idx} value={user.email}>
							{user.name}
						</Option>
					))}
			</Select>

			{/* CC EMAILS */}
			<Select
				placeholder='Con copia a...'
				mode='tags'
				margin='10px 0 0 0'
				width='100%'
				showSearch
				value={ccEmails}
				filterOption={filterOption}
				onChange={value =>
					mutate1ObjectInReports('generateReportModal', {
						ccEmails: value,
					})
				}
			>
				{usersWithEmail
					.filter(usr => toEmails.indexOf(usr.email) === -1)
					.map((user, idx) => (
						<Option key={idx} value={user.email}>
							{user.name}
						</Option>
					))}
			</Select>

			{/* SUBJECT */}
			<Input
				margin='10px 0 0 0'
				required
				placeholder='Asunto'
				name='subject'
				value={subject ?? reportProps.subject}
				onChange={e =>
					mutate1ObjectInReports('generateReportModal', {
						subject: e.target.value,
					})
				}
			/>

			{/* COMMENT */}
			<TextArea
				margin='10px 0 0 0'
				placeholder='Mensaje'
				rows={3}
				value={message ?? reportProps.text}
				onChange={e =>
					mutate1ObjectInReports('generateReportModal', {
						message: e.target.value,
					})
				}
			/>

			<Wrapper width='100%' justifyContent='center'>
				<Button
					margin='10px 0 0 0'
					mode='primary'
					onClick={() =>
						requestGenerateReport(generateReportModal, reportParams)
					}
				>
					Generar
				</Button>
			</Wrapper>
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	projects: selectProjects(state),
	warehouses: getMyEntityWarehouses(
		selectWarehouses(state),
		selectProfile(state),
	),
	inventoryItems: selectInventoryItems(state),
});

const actions = {
	getProjects,
	getWarehouses,
	getArticles,
	requestGenerateReport,
};

export default connect(mapStateToProps, actions)(GenerateReportModal);
