// Libs
import React from 'react';
// Components
import { Wrapper, Spin, SaveIcon, EyeIcon, DeleteIcon } from 'components';
import SignatureCanvas from 'react-signature-canvas';

const Signature = ({ isLoading, handleChangeSignature }) => {
	const signatureRef = React.useRef();
	const [myState, setMyState] = React.useState({
		show: false,
	});

	const changeState = newState => setMyState({ ...myState, ...newState });
	const clear = () => signatureRef.current.clear();
	const handleShow = () => changeState({ show: !myState.show });
	const handleSave = () =>
		handleChangeSignature(
			signatureRef.current.getTrimmedCanvas().toDataURL('image/png'),
		);

	return (
		<Spin height='unset' spinning={isLoading} size='small' delay={50}>
			<Wrapper padding='0'>
				<EyeIcon button onClick={handleShow} />
				{myState.show && <DeleteIcon onClick={clear} />}
				{myState.show && <SaveIcon button onClick={handleSave} />}
			</Wrapper>
			{myState.show && (
				<Wrapper
					boxShadowInner1
					padding='0'
					width='100%'
					height='200px'
					flexDirection='column'
					alignItems='stretch'
				>
					<SignatureCanvas
						ref={signatureRef}
						penColor='#000'
						canvasProps={{
							width: 270,
							height: 200,
						}}
					/>
				</Wrapper>
			)}
		</Spin>
	);
};

export default Signature;
