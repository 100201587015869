import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import GENERAL from 'utils/constants/general';

const { ENV } = GENERAL;

function Transactions(props) {
	const { routes, history, modeView } = props;

	if (
		history.location.pathname === ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS &&
		modeView === 'cards'
	)
		return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_MOBILE} />;
	else if (
		history.location.pathname === ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS &&
		modeView === 'table'
	)
		return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_DESKTOP} />;

	return (
		<div className='animated fadeIn'>
			{routes.map((route, i) => {
				return (
					<Route
						key={i}
						path={route.path}
						render={oProps => (
							<route.component {...oProps} {...props} routes={route.routes} />
						)}
					/>
				);
			})}
		</div>
	);
}

export default Transactions;
