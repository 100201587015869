// Libs
import React from 'react';
export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mediumOrderIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='4'
			y='2'
			width='24'
			height='28'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M25.3333 5.33329H19.76C19.2 3.78663 17.7333 2.66663 16 2.66663C14.2667 2.66663 12.8 3.78663 12.24 5.33329H6.66667C5.2 5.33329 4 6.53329 4 7.99996V26.6666C4 28.1333 5.2 29.3333 6.66667 29.3333H25.3333C26.8 29.3333 28 28.1333 28 26.6666V7.99996C28 6.53329 26.8 5.33329 25.3333 5.33329ZM16 5.33329C16.7333 5.33329 17.3333 5.93329 17.3333 6.66663C17.3333 7.39996 16.7333 7.99996 16 7.99996C15.2667 7.99996 14.6667 7.39996 14.6667 6.66663C14.6667 5.93329 15.2667 5.33329 16 5.33329ZM9.33333 24H18.6667V21.3333H9.33333V24ZM22.6667 18.6666H9.33333V16H22.6667V18.6666ZM9.33333 13.3333H22.6667V10.6666H9.33333V13.3333Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mediumOrderIcon)'>
			<rect width='32' height='32' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
