// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M28 12V14.6667H4L5.33333 13.3334L13.3333 5.33337L15.2267 7.21337L10.44 12H28ZM4 20V17.3334H28L26.6667 18.6667L18.6667 26.6667L16.7733 24.7867L21.56 20H4Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
