// Libs
import React from 'react';
import { connect } from 'react-redux';
// Utils
import GENERAL from 'utils/constants/general';
import { getCoords } from 'utils/libs';
// Selectors
import {
	selectStructuredLocations,
	selectContractTypes,
	// selectContractFrequencies,
} from 'store/api/selectors';
import { selectCreateContract } from 'screens/Contracts/selectors';
// Actions
import {
	getContractTypes,
	onGetZonesToAssignOrders,
	// getFrequencies
} from 'store/api/actions';
import {
	mutate1Object as mutate1ObjectInContracts,
	onCreateContract,
} from 'screens/Contracts/actions';
// Components
import {
	Wrapper,
	Span as _Span,
	TextField,
	InputNumber,
	Span,
	Button,
	Tooltip,
	Cascader,
	GPSIcon,
	Icon,
} from 'components';
import Select from 'components/Select';

const { Option } = Select;
const { Input, TextArea } = TextField;
const { ENV } = GENERAL;

const FORM_ELEMENTS = {
	CONTRACT_NUMBER: 'contractNumber',
	ADDRESS: 'address',
	LATITUDE: 'latitude',
	LONGITUDE: 'longitude',
	ZONE_ID: 'zoneId',
	FREQUENCY_ID: 'frequencyId',
	CONTRACT_TYPE_ID: 'contractTypeId',
};

const initialState = {
	[FORM_ELEMENTS.CONTRACT_NUMBER]: {
		value: undefined,
		required: false,
	},
	[FORM_ELEMENTS.ADDRESS]: {
		value: undefined,
		required: true,
	},
	[FORM_ELEMENTS.LATITUDE]: {
		value: 0.0,
		required: false,
	},
	[FORM_ELEMENTS.LONGITUDE]: {
		value: 0.0,
		required: false,
	},
	[FORM_ELEMENTS.ZONE_ID]: {
		value: undefined,
		required: true,
	},
	[FORM_ELEMENTS.CONTRACT_TYPE_ID]: {
		value: undefined,
		required: true,
	},
	// [FORM_ELEMENTS.FREQUENCY_ID]: {
	//   value: undefined,
	//   required: false,
	// },
};

function CreateContract({
	structuredLocations,
	contractTypes,
	myChangeState,
	selectedClientId,
	departmentId,
	getContractTypes,
	createContract,
	mutate1ObjectInContracts,
	onGetZonesToAssignOrders,
	onCreateContract,
	// getFrequencies,
	// frequencies,
}) {
	const [loadingCoords, setLoadingCoords] = React.useState(false);
	const [contractInfo, setContractInfo] = React.useState(initialState);
	const changeContractInfo = newState =>
		setContractInfo({ ...contractInfo, ...newState });

	const onChange = (key, value) =>
		changeContractInfo({
			[key]: { ...contractInfo[key], value },
		});

	React.useEffect(() => {
		onGetZonesToAssignOrders();
	}, []);

	React.useEffect(() => {
		if (departmentId > 0) getContractTypes(departmentId);
		// getFrequencies();
	}, [departmentId]);

	React.useEffect(() => {
		if (createContract.status === ENV.STATUS.SUCCESS) {
			myChangeState({ activeForm: ENV.ORDERS.CREATE.TABS.CREATE_ORDER });
			mutate1ObjectInContracts('createContract', {
				status: ENV.STATUS.INITIAL,
			});
		}
	}, [createContract.status]);

	const isRequiredDataReady = React.useMemo(
		() =>
			!Object.keys(contractInfo).some(
				key =>
					contractInfo[key].required &&
					(contractInfo[key].value === undefined ||
						contractInfo[key].value === null ||
						contractInfo[key].value === ''),
			),
		[contractInfo],
	);

	const buildFormData = () => {
		const form = {
			[FORM_ELEMENTS.CONTRACT_NUMBER]:
				contractInfo[FORM_ELEMENTS.CONTRACT_NUMBER].value,
			[FORM_ELEMENTS.ADDRESS]: contractInfo[FORM_ELEMENTS.ADDRESS].value,
			[FORM_ELEMENTS.ZONE_ID]: contractInfo[FORM_ELEMENTS.ZONE_ID].value,
			[FORM_ELEMENTS.CONTRACT_TYPE_ID]:
				contractInfo[FORM_ELEMENTS.CONTRACT_TYPE_ID].value,
			clientId: selectedClientId,
			departmentId,
			coords: {
				latitude: contractInfo[FORM_ELEMENTS.LATITUDE].value,
				longitude: contractInfo[FORM_ELEMENTS.LONGITUDE].value,
			},
			// frequency_id: contractInfo[FORM_ELEMENTS.FREQUENCY_ID].value,
		};

		return Object.keys(form).reduce((acc, key) => {
			if (form[key] !== undefined && form[key] !== null && form[key] !== '')
				acc[key] = form[key];
			return acc;
		}, {});
	};

	return (
		<Wrapper flexDirection='column' alignItems='stretch'>
			<Span required>Tipo de Contrato</Span>
			<Select
				margin='0 0 10px 0'
				value={contractInfo[FORM_ELEMENTS.CONTRACT_TYPE_ID].value}
				onChange={value => onChange(FORM_ELEMENTS.CONTRACT_TYPE_ID, value)}
				placeholder='Seleccione un Tipo'
			>
				{contractTypes.data.map(contractType => (
					<Option key={contractType.id} value={contractType.id}>
						{contractType.name}
					</Option>
				))}
			</Select>
			{/* <Span>Frecuencia</Span>
      <Select
        disabled={!contractInfo[FORM_ELEMENTS.CONTRACT_TYPE_ID].value}
        margin="0 0 10px 0"
        value={contractInfo[FORM_ELEMENTS.FREQUENCY_ID].value}
        onChange={(value) => onChange(FORM_ELEMENTS.FREQUENCY_ID, value)}
        placeholder="Seleccione una Frecuencia"
      >
        {frequencies?.data.map((frequency) => (
          <Option key={frequency.id} value={frequency.id}>
            {frequency.name}
          </Option>
        ))}
      </Select> */}
			<Span>Número de Contrato</Span>
			<Input
				maxLength={25}
				disabled={!contractInfo[FORM_ELEMENTS.CONTRACT_TYPE_ID].value}
				margin='0 0 10px 0'
				value={contractInfo[FORM_ELEMENTS.CONTRACT_NUMBER].value}
				onChange={e => onChange(FORM_ELEMENTS.CONTRACT_NUMBER, e.target.value)}
				placeholder='¿Ya posee un número de contrato?'
			/>
			<Span required>Ubicación</Span>
			<Cascader
				disabled={!contractInfo[FORM_ELEMENTS.CONTRACT_TYPE_ID].value}
				fieldNames={{ label: 'name', value: 'id', children: 'subs' }}
				options={structuredLocations}
				expandTrigger='hover'
				allowClear={false}
				onChange={([, , value]) => onChange(FORM_ELEMENTS.ZONE_ID, value)}
				placeholder='Seleccione una Ubicación'
			/>
			<Span margin='10px 0 0 0' required>
				Dirección
			</Span>
			<TextArea
				maxLength={500}
				disabled={!contractInfo[FORM_ELEMENTS.CONTRACT_TYPE_ID].value}
				rows={3}
				margin='0 0 10px 0'
				value={contractInfo[FORM_ELEMENTS.ADDRESS].value}
				onChange={e => onChange(FORM_ELEMENTS.ADDRESS, e.target.value)}
			/>
			<Span>Coordenadas</Span>
			<Wrapper padding='0' margin='0 0 20px 0' justifyContent='space-between'>
				<Wrapper padding='0'>
					<Span margin='0 5px 0 10px'>Latitud</Span>
					<InputNumber
						disabled={!contractInfo[FORM_ELEMENTS.CONTRACT_TYPE_ID].value}
						max={90.999999}
						min={-90}
						step={0.000001}
						placeholder='Latitud'
						value={contractInfo[FORM_ELEMENTS.LATITUDE].value}
						onChange={value => onChange(FORM_ELEMENTS.LATITUDE, value)}
					/>
				</Wrapper>
				<Wrapper padding='0'>
					<Span margin='0 5px 0 10px'>Longitud</Span>
					<InputNumber
						disabled={!contractInfo[FORM_ELEMENTS.CONTRACT_TYPE_ID].value}
						max={180.999999}
						min={-180}
						step={0.000001}
						placeholder='Longitud'
						value={contractInfo[FORM_ELEMENTS.LONGITUDE].value}
						onChange={value => onChange(FORM_ELEMENTS.LONGITUDE, value)}
					/>
				</Wrapper>
				<Tooltip placement='top' title='Obtener ubicación actual'>
					{loadingCoords ? (
						<Icon type='loading' />
					) : (
						<GPSIcon
							onClick={() => {
								setLoadingCoords(true);
								getCoords().then(({ coords }) => {
									if (coords) {
										changeContractInfo({
											[FORM_ELEMENTS.LATITUDE]: {
												value: coords.latitude,
											},
											[FORM_ELEMENTS.LONGITUDE]: {
												value: coords.longitude,
											},
										});
									}
									setLoadingCoords(false);
								});
							}}
						/>
					)}
				</Tooltip>
			</Wrapper>

			{/* CREATE CONTRACT */}
			{createContract.status === ENV.STATUS.LOADING ? (
				<Icon
					margin='20px 0 0 0'
					type='loading'
					className='style-toolbar-buttons'
				/>
			) : (
				<>
					<Button
						mode='primary'
						margin='0 0 10px 0'
						disabled={!isRequiredDataReady}
						onClick={() => onCreateContract(buildFormData())}
					>
						Generar Contrato
					</Button>
					<Button
						onClick={() =>
							myChangeState({ activeForm: ENV.ORDERS.CREATE.TABS.CREATE_ORDER })
						}
					>
						Cancelar
					</Button>
				</>
			)}
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	structuredLocations: selectStructuredLocations(state),
	contractTypes: selectContractTypes(state),
	createContract: selectCreateContract(state),
	// frequencies: selectContractFrequencies(state),
});
const actions = {
	getContractTypes,
	mutate1ObjectInContracts,
	onGetZonesToAssignOrders,
	onCreateContract,
	// getFrequencies,
};

export default connect(mapStateToProps, actions)(CreateContract);
