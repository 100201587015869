// Libs
import React from 'react';
// Components
import { Wrapper, Drawer } from 'components';

const ToggleContainer = ({ children, isOpen, isMobile }) => (
	<Drawer
		key='menuToogleContainer'
		className='animated fadeIn faster'
		height='100%'
		width='100%'
		$maxWidth={!isMobile ? '300px' : '100%'}
		header_font_weight='bold'
		placement='left'
		closable={false}
		visible={isOpen}
	>
		<Wrapper
			padding='0 10px 10px 10px'
			width='100%'
			height='100vh'
			flexDirection='column'
			alignItems='stretch'
			overflow='auto'
			highBackground
		>
			{children}
		</Wrapper>
	</Drawer>
);

export default ToggleContainer;
