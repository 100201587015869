// Libs
import React, { createContext, useState, useMemo } from 'react';

const ModalBuilderContext = createContext({});
const { Provider } = ModalBuilderContext;

// PROVIDER
const ModalBuilderProvider = ({ children }) => {
	const [payload, setPayload] = useState({});

	const value = useMemo(
		() => ({
			payload,
			setModal: (modalKey, props = {}) =>
				setPayload(prev => ({
					...prev,
					...props,
					modalKey,
				})),
			cleanModal: () => {
				setPayload(prev => ({
					...prev,
					closing: true,
				}));
				setTimeout(() => setPayload({}), 300);
			},
		}),
		[payload],
	);

	return <Provider value={value}>{children}</Provider>;
};

export { ModalBuilderProvider, ModalBuilderContext };
