// Libs
import styled from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Checkbox } from 'antd';

const CheckboxGroup = Checkbox.Group;

const CheckboxGroupStyled = styled(CheckboxGroup)`
	display: ${props =>
		props.display ? props.display : props.theme.checkboxGroup.display};
	flex-direction: ${props =>
		props.flex_direction
			? props.flex_direction
			: props.theme.checkboxGroup.flexDirection};
	justify-content: ${props =>
		props.justifyContent
			? props.justifyContent
			: props.theme.checkboxGroup.justifyContent};
	align-items: ${props =>
		props.alignItems ? props.alignItems : props.theme.checkboxGroup.alignItems};
	.ant-checkbox + span {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.25px;
	}
	.ant-checkbox-wrapper {
		color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
	}
`;

export default props => {
	const { theme } = useTheme();
	return <CheckboxGroupStyled {...props} $newTheme={theme} />;
};
