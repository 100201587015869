import React from 'react';
import { Table } from 'antd';
import { StyledTableTransfer, StyledTransfer } from '../styledComponents';

const TableTransfer = ({
	leftColumns,
	rightColumns,
	paginationTransfer,
	onPaginationChangeTransfer,
	...restProps
}) => (
	<StyledTransfer {...restProps}>
		{({
			direction,
			filteredItems,
			onItemSelect,
			onItemSelectAll,
			selectedKeys: listSelectedKeys,
			disabled: listDisabled,
		}) => {
			const columns = direction === 'left' ? leftColumns : rightColumns;
			const rowSelection = {
				getCheckboxProps: () => ({
					disabled: listDisabled,
				}),
				onChange(selectedRowKeys) {
					onItemSelectAll(selectedRowKeys, 'replace');
				},
				selectedRowKeys: listSelectedKeys,
				selections: [
					Table.SELECTION_ALL,
					Table.SELECTION_INVERT,
					Table.SELECTION_NONE,
				],
			};
			return (
				<StyledTableTransfer
					rowSelection={rowSelection}
					columns={columns}
					dataSource={filteredItems}
					size='small'
					style={{
						pointerEvents: listDisabled ? 'none' : undefined,
					}}
					onRow={({ key, disabled: itemDisabled }) => ({
						onClick: () => {
							if (itemDisabled || listDisabled) {
								return;
							}
							onItemSelect(key, !listSelectedKeys.includes(key));
						},
					})}
					onChange={onPaginationChangeTransfer}
					pagination={{
						...paginationTransfer,
						pageSizeOptions: ['10', '20', '50', '100'],
						showSizeChanger: true,
						position: ['bottomCenter'],
					}}
				/>
			);
		}}
	</StyledTransfer>
);

export default TableTransfer;
