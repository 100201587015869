// Libs
import React from 'react';
export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mediumSerieIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='1'
			y='5'
			width='31'
			height='22'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.6666 5.33337L13.3333 10.72V21.28L22.6666 26.6667L31.9999 21.28V10.72L22.6666 5.33337ZM22.6666 8.41337L27.7333 11.3334L22.6666 14.2534L17.6133 11.3334L22.6666 8.41337ZM1.33325 12V9.33337H10.6666V12H1.33325ZM15.9999 13.48L21.3333 16.56V22.8134L15.9999 19.7467V13.48ZM29.3333 19.7467V13.48L23.9999 16.56V22.8134L29.3333 19.7467ZM3.99992 14.6667V17.3334H10.6666V14.6667H3.99992ZM6.66659 22.6667V20H10.6666V22.6667H6.66659Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mediumSerieIcon)'>
			<rect width='32' height='32' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
