// Libs
import React from 'react';
// Utils
import { formatNumber } from 'utils/libs';
import KEYWORDS from '../keywords';
// Components
import {
	Wrapper,
	Title,
	LineDescription,
	Avatar,
	Subtitle,
	FullStarIcon,
	Caption,
	Button,
	CommentIcon,
	AddIcon,
	DoubleCheckIcon,
} from 'components';
import { Card } from 'antd';

const { Meta } = Card;

const MarketItem = ({
	id,
	name,
	description,
	icon,
	ratingAndComments = {},
	priceAndSymbol = {},
	status: currentStatus,
	customProps,
}) => {
	const { rect, handleOnClickUpdateStatus } = customProps;
	const { rating = 5.0, comments } = ratingAndComments;
	const { price = 0, priceSymbol } = priceAndSymbol;
	const titleWidth = `${rect?.width - 158}px`;
	const descriptionWidth = `${rect?.width - 118}px`;
	const formattedPrice = formatNumber.new(price, priceSymbol, 1);

	return (
		<Meta
			avatar={<Avatar bgTransparent avatar={{ src: icon }} />}
			title={
				<Wrapper
					padding='0'
					margin='0 0 10px 0'
					flexDirection='column'
					alignItems='flex-start'
				>
					<Wrapper width='100%' padding='0' justifyContent='space-between'>
						<Title ellipsis width={titleWidth}>
							{name}
						</Title>
						<Subtitle>{formattedPrice}</Subtitle>
					</Wrapper>
				</Wrapper>
			}
			description={
				<Wrapper padding='0 10px 0 10px' justifyContent='space-between'>
					<Wrapper padding='0' flexDirection='column' alignItems='flex-start'>
						<LineDescription
							conceptStyle={{ width: descriptionWidth }}
							conceptEllipsis
							fontSize='m'
							textAlign='left'
							concept={description}
						/>
						<Wrapper
							width={descriptionWidth}
							padding='0'
							justifyContent='space-between'
						>
							<Wrapper padding='0'>
								<Wrapper padding='0' margin='0 10px 0 0'>
									<Caption margin='0 2px 0 0'>
										{Number(rating).toFixed(1)}
									</Caption>
									<FullStarIcon size='small' />
								</Wrapper>
								<Wrapper padding='0'>
									<Caption margin='0 2px 0 0'>{comments}</Caption>
									<CommentIcon size='small' />
								</Wrapper>
							</Wrapper>
							<Wrapper
								padding='0'
								cursor='pointer'
								onClick={() =>
									handleOnClickUpdateStatus({ marketItemId: id, currentStatus })
								}
							>
								{currentStatus ===
									KEYWORDS.MODULES.ITEMS.STATUS.NO_INSTALLED && (
									<>
										<Button>Instalar</Button>
										<AddIcon bold size='small' />
									</>
								)}
								{currentStatus === KEYWORDS.MODULES.ITEMS.STATUS.INSTALLED && (
									<>
										<Button success>Instalado</Button>
										<DoubleCheckIcon size='small' success />
									</>
								)}
							</Wrapper>
						</Wrapper>
					</Wrapper>
				</Wrapper>
			}
		/>
	);
};

export default MarketItem;
