// //Libs
// import styled, { css } from "styled-components";

// const ButtonStyled2 = styled.button`
// 	background-color: unset;
// 	border: unset;
// 	margin: ${(props) => props.margin ?? props.theme.button.margin};
// 	height: ${(props) => props.theme.button.height};
// 	padding: ${(props) => props.theme.button.padding};
// 	max-width: ${({ maxWidth }) => maxWidth};
// 	position: ${({ position }) => position};
// 	top: ${({ top }) => top};
// 	bottom: ${({ bottom }) => bottom};
// 	left: ${({ left }) => left};
// 	right: ${({ right }) => right};
// 	align-items: center;
// 	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
// 	${(props) =>
// 		props.ellipsis &&
// 		css`
// 			text-overflow: ellipsis;
// 			white-space: nowrap;
// 			overflow: hidden;
// 		`}
// 	${(props) =>
// 		(!props.mode || props.mode === "primary") &&
// 		css`
// 			font-family: ${(props) => props.theme.button.primary.fontFamily};
// 			font-style: ${(props) => props.theme.button.primary.fontStyle};
// 			font-weight: ${(props) => props.theme.button.primary.fontWeight};
// 			font-size: ${(props) => props.theme.button.primary.fontSize};
// 			line-height: ${(props) => props.theme.button.primary.lineHeight};
// 			text-align: ${(props) => props.theme.button.primary.textAlign};
// 			letter-spacing: ${(props) => props.theme.button.primary.letterSpacing};
// 			text-transform: ${(props) => props.theme.button.primary.textTransform};
// 			color: ${(props) => props.theme.button.primary.color};
// 		`};
// 	${(props) =>
// 		props.mode === "secondary" &&
// 		css`
// 			font-family: ${(props) => props.theme.button.primary.fontFamily};
// 			font-style: ${(props) => props.theme.button.primary.fontStyle};
// 			font-weight: ${(props) => props.theme.button.secondary.fontWeight};
// 			font-size: ${(props) => props.theme.button.primary.fontSize};
// 			line-height: ${(props) => props.theme.button.primary.lineHeight};
// 			text-align: ${(props) => props.theme.button.primary.textAlign};
// 			letter-spacing: ${(props) => props.theme.button.primary.letterSpacing};
// 			text-transform: ${(props) => props.theme.button.primary.textTransform};
// 			color: ${(props) => props.theme.button.secondary.color};
// 		`};
// 	${(props) =>
// 		props.radius &&
// 		css`
// 			padding: 10px;
// 			border-radius: 50px;
// 		`};
// 	${(props) =>
// 		props.disabled &&
// 		css`
// 			color: ${(props) => props.theme.colors.text.disabled};
// 		`};
// 	${(props) =>
// 		props.success &&
// 		css`
// 			color: ${(props) => props.theme.colors.success.color};
// 		`};
// 	${(props) =>
// 		props.boxShadow &&
// 		css`
// 			-webkit-box-shadow: -10px 0px 13px -7px #000000,
// 				10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
// 			box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
// 				5px 5px 15px 5px rgba(0, 0, 0, 0);
// 		`};
// 	&:hover {
// 		color: ${({ theme, disabled }) => !disabled && theme.colors.hover.color};
// 	}
// `;

// export default ButtonStyled;

// Libs
import cx from 'classnames';
// Hooks
import useTheme from 'hooks/useTheme';
// Styles
import styles from './Button.module.css';

const ButtonStyled = props => {
	const { children } = props;
	const { theme, sharedStyles } = useTheme();

	return (
		<button
			onClick={props.onClick}
			style={{
				margin: props.margin,
				textTransform: props.lettercase ? props.lettercase : 'uppercase',
			}}
			className={cx(
				styles.button,
				theme.mediumEmphasisColor,
				theme.transparentBackgroundColor,
				{
					[sharedStyles[`textAlign_${props.textAlign}`]]: !!props.textAlign,
					[sharedStyles.disabled]: !!props.disabled,
					[theme.focus]: !!props.focus,
					[theme.hoverable]: true,
					[theme.inactiveColor]: !!props.disabled,
				},
			)}
		>
			{children}
		</button>
	);
};

export default ButtonStyled;
