// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M1.33325 2H2.66657H3.33325H7.33325H8.66659H12.6666H13.3332H14.6666V4H13.3332V14H2.66657V4H1.33325V2ZM3.9999 4H7.33325V7.33334H5.99994V8.66668H7.33325V12.6667H3.9999V4ZM8.66659 8.66668V12.6667H11.9999V4H8.66659V7.33334H9.99994V8.66668H8.66659Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
