// Libs
import React, { useMemo } from 'react';
import styled from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';

const IconContainer = styled.div`
	height: 24px;
`;

const BusinessIcon = ({ hoverable, button, onClick }) => {
	const { theme } = useTheme();
	const [isHovered, setIsHover] = React.useState(false);
	const fill = useMemo(() => {
		if (hoverable && isHovered) return theme._secondaryColor;
		return theme._mediumEmphasisColor;
	}, [isHovered, hoverable, theme]);
	return (
		<div
			onMouseEnter={hoverable ? () => setIsHover(true) : null}
			onMouseLeave={hoverable ? () => setIsHover(false) : null}
		>
			{button ? (
				<IconButton onClick={onClick}>
					<Icon fill={fill} />
				</IconButton>
			) : (
				<IconContainer>
					<Icon onClick={onClick} fill={fill} />
				</IconContainer>
			)}
		</div>
	);
};

export default BusinessIcon;
