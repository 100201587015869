// Libs
import React from 'react';
import styled, { css } from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
// import BackgroundIcon from "assets/media/img/background-order-img.png";

const Icon = styled.div`
	background-size: contain;
	min-width: ${({ theme }) => `${theme.sizes.small.width}px`};
	height: ${({ theme }) => `${theme.sizes.small.height}px`};
	font-size: 10px;
	${({ size, theme }) =>
		size === theme.sizes.normal.name &&
		css`
			min-width: ${({ theme }) => `${theme.sizes.normal.width}px`};
			height: ${({ theme }) => `${theme.sizes.normal.height}px`};
		`};
	${({ size, theme }) =>
		size === theme.sizes.medium.name &&
		css`
			min-width: ${({ theme }) => `${theme.sizes.medium.width}px`};
			height: ${({ theme }) => `${theme.sizes.medium.height}px`};
		`};
	${({ size, theme }) =>
		size === theme.sizes.large.name &&
		css`
			min-width: ${({ theme }) => `${theme.sizes.large.width}px`};
			height: ${({ theme }) => `${theme.sizes.large.height}px`};
		`};
	color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	${({ $newTheme, selected }) =>
		selected &&
		css`
			box-shadow: inset 0px 0px 10px -3px ${$newTheme._mediumEmphasisColor};
		`}
`;

const InitialResourceIcon = ({ initial, size, selected }) => {
	const { theme } = useTheme();
	return (
		<Icon size={size} selected={selected} $newTheme={theme}>
			{initial}
		</Icon>
	);
};

export default InitialResourceIcon;
