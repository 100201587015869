// Libs
import _ from 'underscore';
// Utils
import { formatNumber } from '../../utils/libs';

// TODO: Tengo que borrar este metodo cuando ya lo deje de usar en todos lados
export function filterPaycutsSearchInData(paycuts, searchBy, data) {
	switch (searchBy) {
		case 'id':
			return paycuts.filter(paycut =>
				paycut.id.toString().includes(data.toUpperCase()),
			);
		case 'name':
			return paycuts.filter(paycut =>
				paycut.name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'creator':
			return paycuts.filter(paycut =>
				paycut.creator_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'entity':
			return paycuts.filter(paycut =>
				paycut.entity_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'all':
			return paycuts.filter(
				paycut =>
					paycut.id.toString().includes(data.toUpperCase()) ||
					paycut.name.toUpperCase().includes(data.toUpperCase()) ||
					paycut.creator_name.toUpperCase().includes(data.toUpperCase()) ||
					paycut.entity_name.toUpperCase().includes(data.toUpperCase()),
			);
	}
}

// TODO: Tengo que borrar este metodo cuando ya lo deje de usar en todos lados
export function filterPaymentsSearchInData(payments, searchBy, data) {
	switch (searchBy) {
		case 'id':
			return payments.filter(payment =>
				payment.id.toString().includes(data.toUpperCase()),
			);
		case 'paid_to_name':
			return payments.filter(payment =>
				payment.paid_to_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'state':
			return payments.filter(payment =>
				payment.state_label.toUpperCase().includes(data.toUpperCase()),
			);
		case 'creator':
			return payments.filter(payment =>
				payment.creator_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'entity':
			return payments.filter(payment =>
				payment.entity_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'all':
			return payments.filter(
				payment =>
					payment.id.toString().includes(data.toUpperCase()) ||
					payment.paid_to_name.toUpperCase().includes(data.toUpperCase()) ||
					payment.state_label.toUpperCase().includes(data.toUpperCase()) ||
					payment.creator_name.toUpperCase().includes(data.toUpperCase()) ||
					payment.entity_name.toUpperCase().includes(data.toUpperCase()),
			);
	}
}

export function filterPaymentLinesSearchInData(paymentLines, searchBy, data) {
	switch (searchBy) {
		case 'line':
			return paymentLines.filter(line =>
				line.line.toUpperCase().includes(data.toUpperCase()),
			);
		case 'payment_id':
			return paymentLines.filter(line =>
				line.payment_id.toString().includes(data.toUpperCase()),
			);
		case 'action':
			return paymentLines.filter(line =>
				line.action_label.toUpperCase().includes(data.toUpperCase()),
			);
		case 'creator':
			return paymentLines.filter(line =>
				line.creator_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'entity':
			return paymentLines.filter(line =>
				line.entity_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'all':
			return paymentLines.filter(
				line =>
					line.line.toUpperCase().includes(data.toUpperCase()) ||
					// || line.applied_label.toUpperCase().includes(data.toUpperCase())
					line.payment_id.toString().includes(data.toUpperCase()) ||
					line.action_label.toUpperCase().includes(data.toUpperCase()) ||
					line.creator_name.toUpperCase().includes(data.toUpperCase()) ||
					line.entity_name.toUpperCase().includes(data.toUpperCase()),
			);
	}
}

function filterPaymentOrdersSearchInData(paymentOrders, searchBy, data) {
	if (!data || data === '') return [...paymentOrders];

	switch (searchBy) {
		case 'order':
			return paymentOrders.filter(order =>
				order.odt_id.toUpperCase().includes(data.toUpperCase()),
			);
		case 'service':
			return paymentOrders.filter(order =>
				order.service_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'expert':
			return paymentOrders.filter(order =>
				order.expert_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'completed_code':
			return paymentOrders.filter(
				order =>
					order.completed_code_name &&
					order.completed_code_name.toUpperCase().includes(data.toUpperCase()),
			);
		case 'amount':
			return paymentOrders.filter(
				order =>
					(order.amount &&
						order.amount
							.toString()
							.toUpperCase()
							.includes(data.toUpperCase())) ||
					(order.price &&
						order.price.toString().toUpperCase().includes(data.toUpperCase())),
			);
		case 'bonus':
			return paymentOrders.filter(order =>
				order.bonus.toString().toUpperCase().includes(data.toUpperCase()),
			);
		case 'all':
			return paymentOrders.filter(
				order =>
					(order.odt_id &&
						order.odt_id.toUpperCase().includes(data.toUpperCase())) ||
					(order.service_name &&
						order.service_name.toUpperCase().includes(data.toUpperCase())) ||
					(order.expert_name &&
						order.expert_name.toUpperCase().includes(data.toUpperCase())) ||
					(order.completed_code_name &&
						order.completed_code_name
							.toUpperCase()
							.includes(data.toUpperCase())) ||
					(order.amount &&
						order.amount
							.toString()
							.toUpperCase()
							.includes(data.toUpperCase())) ||
					(order.price &&
						order.price
							.toString()
							.toUpperCase()
							.includes(data.toUpperCase())) ||
					(order.bonus &&
						order.bonus.toString().toUpperCase().includes(data.toUpperCase())),
			);
	}
}

function getLastTotalRow(rows) {
	const lastTotalRow = rows.reduce(
		(acc, order) => {
			const { price, amount, bonus, charge } = order;

			acc.price.price += Number(price.price);
			acc.amount += Number(amount);
			acc.bonus += Number(bonus);
			acc.charge += Number(charge);

			acc.price.symbol = price.symbol;

			return acc;
		},
		{
			odt_id: 'Total',
			price: {
				price: 0,
				symbol: undefined,
			},
			amount: 0,
			bonus: 0,
			charge: 0,
		},
	);

	rows.push(lastTotalRow);
	return rows;
}

// TODO:Borrar este metodo cuando ya no se este usando por nadie
export function filterBillingOrders(orders, searchBy, data) {
	orders = filterPaymentOrdersSearchInData(orders, searchBy, data);
	// orders = getLastTotalRow(orders);
	return orders;
}

export function getTotalProduction(orders, iva, coin_symbol) {
	const production = orders.reduce((acc, order) => {
		acc += Number(order.amount) + Number(order.bonus);
		return acc;
	}, 0.0);
	const productionIva = (production * iva) / 100;
	const productionTotal = production + productionIva;

	return {
		prod: formatNumber.new(production, coin_symbol, 2),
		prodIva: formatNumber.new(productionIva, coin_symbol, 2),
		prodTotal: formatNumber.new(productionTotal, coin_symbol, 2),
		productionTotal,
	};
}

export function getTotalBonifications(lines, iva, coin_symbol) {
	const bonification = lines.reduce((acc, line) => {
		acc += Number(line.amount);
		return acc;
	}, 0.0);
	const bonificationIva = (bonification * iva) / 100;
	const bonificationTotal = bonification + bonificationIva;

	return {
		bonus: formatNumber.new(bonification, coin_symbol, 2),
		bonusIva: formatNumber.new(bonificationIva, coin_symbol, 2),
		bonusTotal: formatNumber.new(bonificationTotal, coin_symbol, 2),
		bonificationTotal,
	};
}

export function getTotalDiscounts(lines, iva, coin_symbol) {
	const discount = lines.reduce((acc, line) => {
		acc += Number(line.amount);
		return acc;
	}, 0.0);
	const discountIva = (discount * iva) / 100;
	const discountTotal = discount + discountIva;

	return {
		disc: formatNumber.new(discount, coin_symbol, 2),
		discIva: formatNumber.new(discountIva, coin_symbol, 2),
		discTotal: formatNumber.new(discountTotal, coin_symbol, 2),
		discountTotal,
	};
}

export function getTotalToPay(
	productionTotal,
	bonificationTotal,
	discountTotal,
	coin_symbol,
) {
	return formatNumber.new(
		productionTotal + bonificationTotal - discountTotal,
		coin_symbol,
		2,
	);
}

export function getPaymentColorState(state) {
	if (state === 'open') return { backgroundColor: 'white' };
	else if (state === 'ready') return { backgroundColor: 'darkcyan' };
	else if (state === 'agreed') return { backgroundColor: 'deepskyblue' };
	else if (state === 'released') return { backgroundColor: 'yellow' };
	else if (state === 'paid') return { backgroundColor: 'green' };
}

export function getFlagColorOrderCard(orderData, getOrders, profile) {
	const { props, priority, bonus, price, amount, charge } = orderData;
	const priceComparation = thisBelongsToMyEntity(orderData.entity_id, profile)
		? charge
		: price.price;

	switch (getOrders) {
		case 'orders_notpay':
			if (props && props.noPay) return { backgroundColor: 'black' };
			else if (priority) return { ...priority.style };
			break;
		case 'orders_paid':
			if (amount < priceComparation) return { backgroundColor: 'red' };
			else if (amount > priceComparation) return { backgroundColor: 'yellow' };
			else if (bonus && bonus >= 0.01) return { backgroundColor: 'white' };
			break;
		default:
			return {};
	}
	return {};
}

export function groupResumeOrders(orders) {
	const grouped = [];

	orders.map(order => {
		const idx = _.findIndex(
			grouped,
			ord => ord.task_id === order.task_id && ord.unitPrice === order.amount,
		);
		if (idx === -1) {
			grouped.push({
				service_id: order.service_id,
				service_name: order.service_name,
				task_id: order.task_id,
				task_name: order.task_name,
				amount: order.amount,
				bonus: order.bonus,
				unitPrice: order.amount,
				devices: order.devices,
				distance: order.distance,
				totalCount: 1,
			});
		} else {
			grouped[idx].amount += order.amount;
			grouped[idx].bonus += order.bonus;
			grouped[idx].totalCount++;
		}
	});

	grouped.sort(function (a, b) {
		if (a.service_name > b.service_name) {
			return 1;
		}
		if (a.service_name < b.service_name) {
			return -1;
		}
		// a must be equal to b
		return 0;
	});

	// orders.map(order => {
	//   let idx = _.findIndex(grouped, ord => ord.service_id === order.service_id);
	//   if (idx === -1) {
	//     grouped.push({
	//       service_id: order.service_id,
	//       service_name: order.service_name,
	//       amount: order.amount,
	//       bonus: order.bonus,
	//       totalCount: 1
	//     });
	//   } else {
	//     grouped[idx].amount += order.amount;
	//     grouped[idx].bonus += order.bonus;
	//     grouped[idx].totalCount++;
	//   }
	// });
	return grouped;
}

export function formatPaymentsToPaycutReport(pays, coin_symbol) {
	const totals = pays.reduce(
		(acc, payment) => {
			acc.orders_count += Number(payment.orders_count);
			acc.production += Number(payment.production);
			acc.credits += Number(payment.credits);
			acc.discounts += Number(payment.discounts);
			acc.subtotal += Number(payment.subtotal);
			acc.subtotal_with_iva += Number(payment.subtotal_with_iva);
			acc.total += Number(payment.total);
			return acc;
		},
		{
			orders_count: 0,
			production: 0,
			credits: 0,
			discounts: 0,
			subtotal: 0,
			subtotal_with_iva: 0,
			total: 0,
		},
	);

	totals.production = formatNumber.new(totals.production, coin_symbol, 2);
	totals.credits = formatNumber.new(totals.credits, coin_symbol, 2);
	totals.discounts = formatNumber.new(totals.discounts, coin_symbol, 2);
	totals.subtotal = formatNumber.new(totals.subtotal, coin_symbol, 2);
	totals.subtotal_with_iva = formatNumber.new(
		totals.subtotal_with_iva,
		coin_symbol,
		2,
	);
	totals.total = formatNumber.new(totals.total, coin_symbol, 2);
	totals.iva = pays[0] && pays[0].iva;

	return totals;
}

export function getRowHeightInvoiceOrdersMobile(getOrders) {
	if (getOrders === 'orders_notpay') return 150;
	else if (getOrders === 'orders_paid') return 185;
	else return 130;
}

export function getRowHeightPaymentOrdersMobile(getOrders) {
	if (getOrders === 'orders_notpay') return 130;
	else if (getOrders === 'orders_paid') return 185;
	else return 130;
}

export function getCostSale(costAmount, saleAmount) {
	let cost, sale;

	if (!saleAmount || saleAmount == 0.0) {
		if (!costAmount || costAmount == 0.0) cost = 0;
		else cost = 100;
		sale = 0;
	} else {
		cost = (costAmount / saleAmount) * 100;
		sale = 100 - cost;
	}
	return {
		cost: Number(Number(cost).toFixed(2)),
		sale: Number(Number(sale).toFixed(2)),
	};
}

// TODO: Tengo que borrar este metodo cuando ya lo deje de usar en todo lado
export function applySaleCostViewConfig(
	data,
	fieldToValidate,
	configViewSaleCostModal,
) {
	const { filters } = configViewSaleCostModal;

	return data.map(row => {
		row.saleCostColor = undefined;

		for (const filter of filters) {
			const { value, operator, color } = filter;

			switch (operator) {
				case '=':
					if (row[fieldToValidate] === value) {
						row.saleCostColor = color;
						return row;
					}
					break;
				case '<':
					if (row[fieldToValidate] < value) {
						row.saleCostColor = color;
						return row;
					}
					break;
				case '<=':
					if (row[fieldToValidate] <= value) {
						row.saleCostColor = color;
						return row;
					}
					break;
				case '>':
					if (row[fieldToValidate] > value) {
						row.saleCostColor = color;
						return row;
					}
					break;
				case '>=':
					if (row[fieldToValidate] >= value) {
						row.saleCostColor = color;
						return row;
					}
					break;
			}
		}

		return row;
	});
}

export function thisBelongsToMyEntity(entityId, profile) {
	return entityId === profile?.entity?.id;
}

export function processIncomingOrders(orders, selectedFile) {
	const { columns } = selectedFile;

	return orders.reduce((acc, order) => {
		const odtIdColumn = columns.filter(column => column.odt_id)[0];
		const amountColumn = columns.filter(column => column.amount)[0];
		const bonusColumn = columns.filter(column => column.bonus)[0];
		const odt_id = order[odtIdColumn.colname];
		const idxExists = _.findIndex(acc, item => item.odt_id === odt_id);

		if (idxExists === -1) {
			const row = {
				odt_id,
				amount: 0,
				bonus: 0,
				bonus_detail: undefined,
			};

			if (!bonusColumn) {
				row.amount = order[amountColumn.colname];
			} else {
				const isBonusIdx = _.findIndex(
					bonusColumn.bonus_detail,
					detail => detail === order[bonusColumn.colname],
				);
				if (isBonusIdx !== -1) {
					row.bonus = order[amountColumn.colname];
					row.bonus_detail = order[bonusColumn.colname];
				} else {
					row.amount = order[amountColumn.colname];
				}
			}
			acc.push(row);
		} else {
			const row = {
				...acc[idxExists],
			};

			if (!bonusColumn) {
				row.amount += order[amountColumn.colname];
			} else {
				const isBonusIdx = _.findIndex(
					bonusColumn.bonus_detail,
					detail => detail === order[bonusColumn.colname],
				);
				if (isBonusIdx !== -1) {
					row.bonus += order[amountColumn.colname];
					row.bonus_detail = order[bonusColumn.colname];
				} else {
					row.amount += order[amountColumn.colname];
				}
			}
			acc.splice(idxExists, 1, row);
		}

		return acc;
	}, []);
}

// El estado de los records se encuentra en el array <allowedStates>?
export function recordsHaveAllowedStates(records, allowedStates) {
	if (records.length === 0) return false;
	return records.reduce((acc, record) => {
		if (allowedStates.indexOf(record.state) === -1) {
			acc = false;
		}
		return acc;
	}, true);
}

export function isPayRowSelected(selected, payIds) {
	if (selected.items.length !== payIds.length) return false;
	return payIds.reduce((acc, payId, idx) => {
		if (payId !== selected.items[idx]) {
			acc = false;
		}
		return acc;
	}, true);
}

export function fusionPaycutValid(paycuts) {
	const { coins, iva } = paycuts.reduce(
		(acc, paycut) => {
			const coin_id = Number(paycut.coin_id);
			const iva = Number(paycut.iva);

			if (acc.coins.indexOf(coin_id) === -1) {
				acc.coins.push(coin_id);
			}
			if (acc.iva.indexOf(iva) === -1) {
				acc.iva.push(iva);
			}
			return acc;
		},
		{ coins: [], iva: [] },
	);

	return coins.length === 1 && iva.length === 1;
}
