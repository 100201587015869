// Libs
import React from 'react';
import { connect } from 'react-redux';
// Actions
import {
	getServices,
	mutate1Object as mutate1ObjectInCore,
} from 'core/actions';
// Selector
import {
	selectSelectedOrderTargetFilterId,
	selectSelectedServiceIds,
	selectServicesIsFetching,
	selectServices,
} from 'core/selectors';
// Components
import {
	Wrapper,
	LogoIcon,
	CheckboxV2,
	Caption,
	Tooltip,
	SectionTitle,
	Section,
	Span,
} from 'components';

const styles = {
	container: {
		padding: '0',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
	item: {
		padding: '0',
		margin: '0 0 5px 0',
	},
};

const ServiceItem = React.memo(
	({ style, service, selectedServiceIds, selectedAll, onClick }) => {
		const isSelected = React.useMemo(
			() =>
				(service.id === 'all' && selectedAll) ||
				selectedServiceIds.findIndex(serviceId => serviceId === service.id) !==
					-1,
			[selectedServiceIds, selectedAll],
		);
		return (
			<Wrapper style={style}>
				<Tooltip title={service.name} placement='right'>
					<Wrapper
						padding='0'
						width='250px'
						height='20px'
						justifyContent='stretch'
						alignItems='center'
						overflow='hidden'
						onClick={() => onClick(service.id)}
					>
						<CheckboxV2 isSelected={isSelected} />
						<Span width='100%' hoverable selected={isSelected} ellipsis>
							{service.name}
						</Span>
					</Wrapper>
				</Tooltip>
			</Wrapper>
		);
	},
);

const _ServicePicker = React.memo(
	({
		// Projects
		selectedOrderTargetFilterId,
		// Services
		selectedServiceIds,
		isFetching,
		services,
		getServices,
		// Command Center
		mutate1ObjectInCore,
	}) => {
		// Get services
		React.useEffect(() => {
			if (!selectedOrderTargetFilterId) return;
			getServices({ orderTargetId: selectedOrderTargetFilterId });
		}, [selectedOrderTargetFilterId]);

		// Handle see more
		const [seeMore, setSeeMore] = React.useState('hide'); // hide, show, selected
		React.useEffect(() => {
			if (services.length <= 3) setSeeMore('hide');
			else if (services.length > 3 && seeMore === 'hide') {
				setSeeMore('show');
			}
		}, [seeMore, services]);

		// Filter with see more
		const filteredServices = React.useMemo(() => {
			const filter = () =>
				services.length > 3 && seeMore !== 'selected'
					? services.filter((p, idx) => idx <= 2)
					: services;

			if (services.length === 0) return filter();
			return [
				{
					id: 'all',
					name: 'Todos',
				},
			].concat(filter());
		}, [seeMore, services]);

		const containerStyle = React.useMemo(() => styles.container, [isFetching]);

		// Is selected all?
		const selectedAll = React.useMemo(
			() => selectedServiceIds.length === services.length,
			[selectedServiceIds, services],
		);

		const onClickServiceItem = React.useCallback(
			serviceId => {
				if (serviceId === 'all') {
					var _selectedServiceIds = selectedAll ? [] : services.map(s => s.id);
				} else {
					const isSelected =
						selectedServiceIds.findIndex(sId => sId === serviceId) !== -1;

					if (!isSelected) {
						var _selectedServiceIds = [...selectedServiceIds, serviceId];
					} else {
						var _selectedServiceIds = selectedServiceIds.filter(
							sId => sId !== serviceId,
						);
					}
				}
				mutate1ObjectInCore('dataFilters', {
					lastRowId: 0,
					reloadSubDataFilters: true,
				});
				mutate1ObjectInCore('services', {
					selectedServiceIds: _selectedServiceIds,
				});
				mutate1ObjectInCore('datasource', {
					reload: true,
				});
			},
			[services, selectedServiceIds, selectedAll],
		);

		return (
			<Section>
				<SectionTitle title='Servicios' empty={filteredServices.length === 0} />
				<Wrapper {...containerStyle}>
					{isFetching && <LogoIcon spin={true} />}
					{!isFetching &&
						filteredServices.map((service, idx) => (
							<ServiceItem
								key={service.id}
								service={service}
								selectedServiceIds={selectedServiceIds}
								selectedAll={selectedAll}
								style={styles.item}
								onClick={onClickServiceItem}
							/>
						))}

					{seeMore === 'show' && seeMore !== 'selected' && (
						<Caption
							emphasis
							hoverable
							margin='10px 0 0 0'
							onClick={() => setSeeMore('selected')}
						>
							Ver más
						</Caption>
					)}
				</Wrapper>
			</Section>
		);
	},
);

const mapStateToProps = state => ({
	// Order Targets
	selectedOrderTargetFilterId: selectSelectedOrderTargetFilterId(state),
	// Services
	selectedServiceIds: selectSelectedServiceIds(state),
	isFetching: selectServicesIsFetching(state),
	services: selectServices(state),
});

const actions = {
	getServices,
	mutate1ObjectInCore,
};
export default connect(mapStateToProps, actions)(_ServicePicker);
