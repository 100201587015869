// Libs
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
// Context
import { ElementViewBuilderContext } from 'core/context';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
// Hooks
import useTheme from 'hooks/useTheme';
import { OrderCard } from 'synapse';
// Config
import { ElementViewBuilderConfig } from 'core/ElementViewBuilder';
import { AssignedTechId, EventId } from 'components/DataFieldManager';

const OrderListV1 = ({ record, fields }) => {
	const { isDarkTheme } = useTheme();
	const dispatch = useDispatch();

	const { setElementView } = useContext(ElementViewBuilderContext);
	const elementViewKeys =
		ElementViewBuilderConfig.getAuthorizedElementViewKeys();

	const assignedTechIdField = fields.find(
		field => field.id === 'assignedTechId',
	);
	const eventIdField = fields.find(field => field.id === 'eventId');

	return (
		<OrderCard
			data={record}
			events={
				eventIdField && (
					<EventId
						rowData={{
							id: record.id,
							odtId: record.odtId,
							eventId: record.eventId,
							eventName: record.eventName,
						}}
						field={eventIdField}
						style='dropdown'
					/>
				)
			}
			assignedTech={
				assignedTechIdField && (
					<AssignedTechId
						rowData={{
							id: record.id,
							zoneId: record.zoneId,
							serviceId: record.serviceId,
							centralId: record.centralId,
							assignedTechId: record.assignedTechId,
							assignedTechName: record.assignedTechName,
						}}
						field={assignedTechIdField}
						style='dropdown'
					/>
				)
			}
			darkTheme={isDarkTheme}
			// onBillingClick={() => {}}
			onMoreInfoClick={() => {
				setElementView({
					elementId: record.id,
					elementViewKey: elementViewKeys.ORDER_ELEMENT,
				});
			}}
			onCopy={() => {
				dispatch(sendToast({ message: 'Texto copiado' }));
			}}
		/>
	);
};

export default OrderListV1;
