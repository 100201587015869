// Libs
import React from 'react';
import { useDispatch } from 'react-redux';
// Actions
import { mutate1Object as mutate1ObjectInCore } from 'core/actions';
// Components
import { Span, Wrapper } from 'components';

const DefaultAction = ({ action, icon, payload }) => {
	const dispatch = useDispatch();
	const onActionClick = props =>
		dispatch(mutate1ObjectInCore('actionForm', props));

	return (
		<Wrapper
			padding='5px'
			cursor='pointer'
			onClick={e => {
				e.stopPropagation();
				onActionClick({
					payload,
					isOpen: true,
					formId: action.formId,
				});
			}}
		>
			{icon}
			<Span margin='0 10px'>{action.title}</Span>
		</Wrapper>
	);
};

export default DefaultAction;
