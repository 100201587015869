// Libs
import React from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
import styled, { css } from 'styled-components';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';

const Button = styled(IconButton)`
	${props =>
		props.shadow &&
		css`
			-webkit-box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
			box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
			background-color: rgba(0, 0, 0, 0.5) !important;
		`}
`;

const CloseIcon = ({ button, shadow, cursorPointer, onClick }) => {
	const { theme } = useTheme();

	if (button) {
		return (
			<Button shadow={shadow} onClick={onClick}>
				<Icon fill={theme._inactiveColor} cursorPointer={cursorPointer} />
			</Button>
		);
	}

	return (
		<Icon
			fill={theme._inactiveColor}
			cursorPointer={cursorPointer}
			onClick={onClick}
		/>
	);
};

export default CloseIcon;
