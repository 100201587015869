import moment from 'moment';

/**
 * The prop <dateFormat> is only required when <orderType> is "date"
 * The prop <objectKey> is only required when <orderType> is "object"
 * */
export default function orderBy(data, orderType, props) {
	const { dateFormat, objectKey, reverse } = props;

	switch (orderType) {
		case 'text':
			data.sort();
			break;
		case 'numeric':
			data.sort((a, b) => {
				return a - b;
			});
			break;
		case 'date':
			data.sort((a, b) => {
				return moment(a, dateFormat) - moment(b, dateFormat);
			});
			break;
		case 'objectDate':
			data.sort(function (a, b) {
				if (reverse) {
					return (
						moment(b[objectKey], dateFormat) - moment(a[objectKey], dateFormat)
					);
				}
				return (
					moment(a[objectKey], dateFormat) - moment(b[objectKey], dateFormat)
				);
			});
			break;
		case 'object':
			data.sort(function (a, b) {
				if (a[objectKey] > b[objectKey]) {
					return 1;
				}
				if (a[objectKey] < b[objectKey]) {
					return -1;
				}
				// a must be equal to b
				return 0;
			});
			break;
	}
}
