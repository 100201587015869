// Libs
import React from 'react';
// Components
import { Wrapper } from 'components';

const MenuContainer = ({ children }) => (
	<Wrapper
		padding='0'
		flexDirection='column'
		alignItems='stretch'
		height='100%'
		overflowX='hidden'
		position='relative'
	>
		{children}
	</Wrapper>
);

export default MenuContainer;
