// Libs
import React, { useContext } from 'react';
// Services
import { SentryService } from 'services';
// Context
import { RealtimeContext } from 'context/RealtimeContext';
import { CoordProvider } from './CoordContext';

const RealtimeCoordProvider = ({ children, profile }) => {
	const { mqttPublish } = useContext(RealtimeContext);

	return (
		<CoordProvider
			// connectedLink={props.linked?.connected}
			connectedLink={true}
			timeout={
				profile?.user?.settings?.coords?.timeout ||
				profile?.entity?.settings?.coords?.timeout ||
				profile?.organization?.settings?.coords?.timeout
			}
			syncDelay={
				profile?.user?.settings?.coords?.syncDelay ||
				profile?.entity?.settings?.coords?.syncDelay ||
				profile?.organization?.settings?.coords?.syncDelay
			}
			onDisconnectedLink={() =>
				mqttPublish(
					'currentGeolocation',
					RealtimeUtils.getMyProfileSubscriber(profile),
					{
						userId: profile?.user?.id,
						connected: false,
						coords: null,
					},
				)
			}
			onSyncCoords={coords => {
				//Send new coords to mqtt
				mqttPublish(
					'currentGeolocation',
					RealtimeUtils.getMyProfileSubscriber(profile),
					{
						userId: profile?.user?.id,
						connected: true,
						coords,
					},
				);
			}}
			errorHandler={SentryService.sendError}
		>
			{children}
		</CoordProvider>
	);
};

export default RealtimeCoordProvider;
