// Libs
import React from 'react';
import cx from 'classnames';
// Components
import { EmptyIllustration } from 'components/Illustrations';
import FormComponentBuilder from './FormComponentBuilder';
// Styles
import styles from './DefaultForm.module.css';

const DefaultForm = ({
	isMobile,
	externalDisabled,
	form,
	values,
	empty,
	setValues,
	submitForm,
	getProcessedComponentSqlData,
}) => {
	if (empty)
		return (
			<EmptyIllustration
				title='Formulario no configurado'
				width={!isMobile ? '300px' : '100px'}
				fontSize='2em'
			/>
		);

	return (
		<div className={styles.defaultFormContainer}>
			{form.components.map(component => (
				<div
					key={component.id}
					className={cx(styles.defaultComponentContainer, {
						[styles.noneDisplay]: component.config?.hide,
					})}
				>
					<FormComponentBuilder
						key={component.id}
						externalDisabled={externalDisabled}
						componentId={component.id}
						componentParam={component.param}
						params={component.params}
						config={component.config}
						type={component.type}
						title={component.title}
						placeholder={component.placeholder}
						values={values}
						setValues={setValues}
						submitForm={submitForm}
						sending={form.sending}
						getProcessedComponentSqlData={getProcessedComponentSqlData}
					/>
				</div>
			))}
		</div>
	);
};

export default DefaultForm;
