import React from 'react';
import { connect } from 'react-redux';
// Components
import Span from './Span/Span';
// Actions
import * as orderActions from 'screens/Orders/actions';

const actions = {
	// ORDERS
	mutate1ObjectInOrders: orderActions.mutate1Object,
	getOrder: orderActions.getOrder,
};

function GetOrderInfo({
	order_id,
	odt_id,
	onClickCustomAction,
	control,
	mutate1ObjectInOrders,
	getOrder,
}) {
	return (
		<Span
			cursorPointer
			onClick={() => {
				if (!control.orders.filter(ord => ord.order_id === order_id)[0]) {
					getOrder(order_id);
				}
				mutate1ObjectInOrders('getOrderInformationModal', {
					order_id,
					isOpen: true,
				});
				if (onClickCustomAction) onClickCustomAction();
			}}
		>
			{odt_id}
		</Span>
	);
}

export default connect(
	state => ({
		control: {
			orders: state.orders.control.orders,
		},
	}),
	actions,
)(GetOrderInfo);
