// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
// Utils
import { getPaymentColorState } from '../../../utils';
import { formatNumber, coinsUtils } from 'utils/libs';
import { filterPaycuts } from 'utils/filters/Billings/paycuts';
import GENERAL from 'utils/constants/general';
// Components
import {
	Wrapper,
	TableVirtualized,
	Flag,
	Span,
	Checkbox,
	Spin,
	Link,
} from 'components';
// Styles
import './Desktop.css';

const { ENV } = GENERAL;
const {
	Table,
	Column,
	Header,
	Row,
	Cell,
	onClickRow,
	onDoubleClickRow,
	onRightClickRow,
} = TableVirtualized;

function PaycutsDesktop(props) {
	if (
		!props?.profile?.entity?.id ||
		!props?.profile?.organization?.settings?.mainEntity ||
		props?.profile?.entity?.id !==
			props?.profile?.organization?.settings?.mainEntity
	) {
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYS_DESKTOP} />;
	}

	const { payments, paycuts, coins, profile, mutate1Object, getInvoicePays } =
		props;
	const allPaycuts = payments.paycuts.data;
	const filteredPaycuts = filterPaycuts(allPaycuts, paycuts.filters);

	// -----RENDERS------

	// HEADER
	function getHeaderRenderer({ dataKey, label }, orderType) {
		const onChangedFilter = updatedFilters =>
			mutate1Object('paycuts', { filters: updatedFilters });
		return (
			<Header
				filter={{
					dataKey,
					data: allPaycuts,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: paycuts.filters,
					onChangedFilter,
				}}
			>
				{label}
			</Header>
		);
	}

	// ROW
	function getRowRenderer({ key, columns, style }) {
		const onFilterItems = filteredItems =>
			mutate1Object('paycuts', {
				selected: { ...paycuts.selected, items: filteredItems },
			});
		return (
			<Row
				key={key}
				style={style}
				onContextMenu={e =>
					onRightClickRow(
						e,
						'id',
						paycuts.selected.items,
						filteredPaycuts,
						onFilterItems,
					)
				}
			>
				{columns}
			</Row>
		);
	}

	// ID
	function getIDCellRenderer({ rowData }) {
		const onClickedRow = filteredItems =>
			mutate1Object('paycuts', {
				selected: { ...paycuts.selected, items: filteredItems },
			});

		return (
			<Cell
				onClick={() => onClickRow(rowData.id, paycuts.selected, onClickedRow)}
				onDoubleClick={() =>
					onDoubleClickRow(
						'id',
						paycuts.selected,
						filteredPaycuts,
						mutate1Object,
						'paycuts',
					)
				}
			>
				<Flag style={getPaymentColorState(rowData.state)} height='20px' />
				<Checkbox
					margin='2px 0 2px 5px'
					checked={paycuts.selected.items.indexOf(rowData.id) !== -1}
					onChange={() =>
						onClickRow(rowData.id, paycuts.selected, onClickedRow)
					}
				/>
				<Span margin='0 0 0 5px'>{rowData.id}</Span>
			</Cell>
		);
	}

	// NAME
	function getNameCellRenderer({ rowData }) {
		return (
			<Link
				to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYS_DESKTOP}
				onClick={() => {
					mutate1Object('paycuts', {
						selected: { ...paycuts.selected, items: [rowData.id] },
					});
					getInvoicePays([rowData.id]);
				}}
			>
				<div>{rowData.name}</div>
			</Link>
		);
	}

	// COIN
	function getCoinCellRenderer({ rowData }) {
		return (
			<Cell>
				{coinsUtils.getDataByCoinId(
					rowData.coin_id,
					'abbreviation',
					coins.data,
				)}
			</Cell>
		);
	}

	// AMOUNT
	function getAmountCellRenderer({ rowData, dataKey }) {
		return (
			<Cell>
				{dataKey === 'production_amount'
					? formatNumber.new(rowData.production_amount, rowData.coin_symbol, 2)
					: dataKey === 'production_charge' &&
					  formatNumber.new(rowData.production_charge, rowData.coin_symbol, 2)}
			</Cell>
		);
	}

	// DIFFERENCE
	function getDifferenceAmountsCellRenderer({ rowData }) {
		const { production_amount, production_charge, coin_symbol } = rowData;
		const difference = production_amount - production_charge;

		return <Cell>{formatNumber.new(difference, coin_symbol, 2)}</Cell>;
	}

	return (
		<Wrapper padding='0' className='animated fadeIn'>
			<Spin
				spinning={payments.paycuts.loading}
				size='large'
				delay={50}
				tip={'Consultando Cortes...'}
			>
				<Table
					highBackground
					width={document.documentElement.clientWidth}
					height={document.documentElement.clientHeight - 115}
					headerHeight={44}
					rowHeight={25}
					rowCount={filteredPaycuts.length}
					rowGetter={({ index }) => filteredPaycuts[index]}
					rowRenderer={getRowRenderer}
				>
					<Column
						dataKey='id'
						label='ID'
						width={100}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getIDCellRenderer}
					/>
					<Column
						dataKey='name'
						label='Nombre'
						width={250}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getNameCellRenderer}
					/>
					<Column
						dataKey='coin_abbreviation'
						label='Moneda'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getCoinCellRenderer}
					/>
					<Column
						dataKey='iva'
						label='IVA'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
					/>
					<Column
						dataKey='production_amount'
						label='Cobrado'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getAmountCellRenderer}
					/>
					<Column
						dataKey='production_charge'
						label='Cobrar'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getAmountCellRenderer}
					/>
					<Column
						dataKey='difference'
						label='Diferencia'
						width={120}
						headerRenderer={({ label }) => <Header>{label}</Header>}
						cellRenderer={getDifferenceAmountsCellRenderer}
					/>
					<Column
						dataKey='from_date'
						label='Desde'
						width={100}
						headerRenderer={({ dataKey, label }) => (
							<Header
								filter={{
									dataKey,
									data: allPaycuts,
									orderType: 'date',
									dateFormat: 'YYYY-MM-DD',
									filters: paycuts.filters,
									onChangedFilter: updatedFilters =>
										mutate1Object('paycuts', { filters: updatedFilters }),
								}}
							>
								{label}
							</Header>
						)}
					/>
					<Column
						dataKey='to_date'
						label='Hasta'
						width={100}
						headerRenderer={({ dataKey, label }) => (
							<Header
								filter={{
									dataKey,
									data: allPaycuts,
									orderType: 'date',
									dateFormat: 'YYYY-MM-DD',
									filters: paycuts.filters,
									onChangedFilter: updatedFilters =>
										mutate1Object('paycuts', { filters: updatedFilters }),
								}}
							>
								{label}
							</Header>
						)}
					/>
					<Column
						dataKey='state_label'
						label='Estado'
						width={100}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='signed_label'
						label='Firmado'
						width={100}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='department_name'
						label='Departamento'
						width={140}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='creator_name'
						label='Creador'
						width={130}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='entity_name'
						label='Entidad'
						width={130}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='created_at'
						label='Creado'
						width={125}
						headerRenderer={props => getHeaderRenderer(props, 'date')}
					/>
					<Column
						dataKey='updated_at'
						label='Actualizado'
						width={125}
						headerRenderer={props => getHeaderRenderer(props, 'date')}
					/>
				</Table>
			</Spin>
		</Wrapper>
	);
}

export default PaycutsDesktop;
