// Libs
import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import _ from 'underscore';
// Utils
import { Modal } from '../Utils';
import { HeaderOrdersReassignmentModal } from '../Utils/Modal/subs/Orders/OrdersReassignmentModal';
import { getNameByUserId } from 'utils/libs';
import GENERAL from 'utils/constants/general';
// Actions
import {
	getControlOrders,
	getInfoOrdersIncome,
	getCountOrdersToReassignment,
	mutate1Object,
	onSaveOrdersReassignmentToUser,
	onResetOrdersReassignmentModal,
	sendOrderEvents,
} from './actions';
import { sendToast } from 'screens/Dashboard/actions';
import { onGetTemplates } from 'store/api/actions';
// Selectors
import { selectMyEntityActiveUsers } from 'screens/Users/selectors';
import { selectTemplates } from 'store/api/selectors';
import {
	getOrderInformation,
	selectEnterNewOrdersModal,
	selectGetInfoOrdersIncomeModal,
	selectGetOrdersQueryModal,
	selectOrderReassignmentModal,
	selectSelectedOrders,
	selectSendEventModal,
} from './selectors';
// Styles
import './Control/Desktop.css';
import { selectModeView } from '../Dashboard/selectors';
import { selectProfile } from '../Login/selectors';
// import { OrderUtils } from "utils";
/// /TODO: DEPRECATED:
import { getValidatorFieldsTemplate } from './utils';

const { ENV } = GENERAL;
// Método utilizado en el proceso de importación de órdenes
// al reasignar una orden a un usuario específico
function onChangeUserAssignmentInImportedOrders(
	user,
	enterNewOrdersModal,
	users,
	templates,
	mutate1Object,
	sendToast,
) {
	const { id, name, division_id, entity_id } = user;
	const { validationTemplateId, importedFile, selected } = enterNewOrdersModal;
	const data = Immutable.List(importedFile?.data).toJS();

	// Get Template Validator & Fields
	const templateValidator =
		validationTemplateId &&
		templates.data.filter(template => template.id === validationTemplateId)[0];
	// const templateValidatorFields =
	//   OrderUtils.getTemplateValidatorFields(templateValidator);

	/// /TODO: DEPRECATED:
	const templateValidatorFields = getValidatorFieldsTemplate(templateValidator);
	// Get ODT Field
	const odtFieldIdx = _.findIndex(
		templateValidatorFields,
		field => field.id === 'order_odt_id',
	);
	const odtField = odtFieldIdx !== -1 && templateValidatorFields[odtFieldIdx];
	// Get Assigned Tech Field
	const assignedTechFieldIdx = _.findIndex(
		templateValidatorFields,
		field => field.id === 'assigned_tech_id',
	);
	const assignedTechField =
		assignedTechFieldIdx !== -1 &&
		templateValidatorFields[assignedTechFieldIdx];
	// Required Fields Validation
	if (!odtField) {
		sendToast({
			message:
				'El campo requerido <order_odt_id> no fue encontrado en el template',
		});
		return;
	}
	if (!assignedTechField) {
		sendToast({
			message:
				'El campo requerido <assigned_tech_id> no fue encontrado en el template',
		});
		return;
	}

	// Setter
	function setNewValues(order) {
		if (order) {
			// Set User
			order[assignedTechField.id] = id;
			order[assignedTechField.name] = name;

			// Set Entity
			if (division_id === 1) {
				// If selected user is: ENTITY
				order.entity_id = id;
				order.entity_name = name;
			} else if (division_id === 3) {
				// If selected user is: EXPERT
				order.entity_id = entity_id;
				order.entity_name = getNameByUserId(entity_id, users);
			}
		}
	}

	// Se recorren las ordenes seleccionadas
	Array.isArray(selected?.items) &&
		selected.items.map(orderSelected => {
			const orderIdx = _.findIndex(
				data,
				ord => ord[odtField.id] === orderSelected,
			);
			setNewValues(data[orderIdx]);
		});

	mutate1Object('enterNewOrdersModal', {
		importedFile: {
			...importedFile,
			data,
		},
		selected: {
			items: [],
		},
		onHoverCells: {},
	});
	mutate1Object('ordersReassignmentModal', {
		isOpen: false,
	});
}

function ModalOrders(props) {
	const {
		// Props
		orders,
		// State
		users,
		templates,
		selectedOrders,
		modeView,
		getOrdersQueryModal,
		getInfoOrdersIncomeModal,
		getOrderInformationModal,
		ordersReassignmentModal,
		enterNewOrdersModal,
		sendEventModal,
		profile,
		// Actions
		getControlOrders,
		getInfoOrdersIncome,
		getCountOrdersToReassignment,
		onGetTemplates,
		mutate1Object,
		onSaveOrdersReassignmentToUser,
		onResetOrdersReassignmentModal,
		sendOrderEvents,
		sendToast,
	} = props;
	const orderSelectedToGetInformation = React.useMemo(
		() =>
			getOrderInformationModal.order_id &&
			orders.find(ord => ord.order_id === getOrderInformationModal.order_id),
		[getOrderInformationModal.order_id, orders],
	);

	React.useEffect(() => {
		onGetTemplates({
			...templates.request,
			prop: ENV.TEMPLATES.PROPS.VALIDATE_IMPORT_ORDERS,
		});
	}, []);

	return (
		<>
			{/**
			 * GET INFO ORDERS INCOME
			 *
			 * Este Modal muestra la lista de importación de ordenes que se han realizado
			 *
			 */}
			{getInfoOrdersIncomeModal.isOpen && (
				<Modal
					modalId='GetInfoOrdersIncomeModal'
					title={
						getInfoOrdersIncomeModal.state === 'start' ? (
							'Consultar'
						) : (
							<div
								className='title-history-orders-income'
								onClick={() =>
									mutate1Object('getInfoOrdersIncomeModal', { state: 'start' })
								}
							>
								<span>Atrás</span>
							</div>
						)
					}
					visible={getInfoOrdersIncomeModal.isOpen}
					footer={getInfoOrdersIncomeModal.state === 'start' && undefined}
					onCancel={() =>
						mutate1Object('getInfoOrdersIncomeModal', {
							isOpen: false,
							state: 'start',
							data: [],
						})
					}
					onOk={() => getInfoOrdersIncome(getInfoOrdersIncomeModal)}
					isSpinning={getInfoOrdersIncomeModal.state === 'loading'}
					sizeSpinning='large'
					delaySpinning={50}
					tipSpinning='Consultando. Un momento porfavor...'
					customProps={{
						getInfoOrdersIncomeModal,
						mutate1Object,
						modeView,
					}}
				/>
			)}

			{/* GET ORDERS QUERY MODAL */}
			{getOrdersQueryModal.isOpen && (
				<Modal
					modalId='GetOrdersModal'
					title='Consultar'
					visible={getOrdersQueryModal.isOpen}
					onCancel={() =>
						mutate1Object('getOrdersQueryModal', { isOpen: false })
					}
					onOk={() => getControlOrders(getOrdersQueryModal)}
					isSpinning={getOrdersQueryModal.loading}
					sizeSpinning='large'
					delaySpinning={50}
					tipSpinning='Consultando. Un momento porfavor...'
					customProps={{
						getOrdersQueryModal,
						users,
						mutate1Object,
					}}
				/>
			)}

			{/* SEND AN EVENT MODAL */}
			{sendEventModal.isOpen && (
				<Modal
					modalId='SendEventModal'
					title='Establecer evento'
					visible={sendEventModal.isOpen}
					onCancel={() => mutate1Object('sendEventModal', { isOpen: false })}
					onOk={() =>
						sendOrderEvents(
							selectedOrders,
							sendEventModal,
							users,
							profile.organization.tz,
						)
					}
					isSpinning={sendEventModal.loading}
					sizeSpinning='large'
					delaySpinning={50}
					tipSpinning='Estableciendo evento... Un momento porfavor'
					customProps={{
						sendEventModal,
						mutate1Object,
					}}
				/>
			)}

			{/* ORDERS REASSIGNMENT MODAL */}
			{ordersReassignmentModal.isOpen && (
				<Modal
					modalId='OrdersReassignmentModal'
					title={
						<HeaderOrdersReassignmentModal
							ordersReassignmentModal={ordersReassignmentModal}
							mutate1Object={mutate1Object}
						/>
					}
					visible={ordersReassignmentModal.isOpen}
					onCancel={onResetOrdersReassignmentModal}
					footer={null}
					isSpinning={ordersReassignmentModal.reassigning}
					sizeSpinning='large'
					delaySpinning={50}
					tipSpinning={'Procesando solicitud...'}
					customProps={{
						// Props
						ordersReassignmentModal,
						users,
						// Actions
						mutate1Object,
						getCountOrdersToReassignment,
						onReassignment:
							ordersReassignmentModal.assignment === 'local'
								? user =>
										onChangeUserAssignmentInImportedOrders(
											user,
											enterNewOrdersModal,
											users,
											templates,
											mutate1Object,
											sendToast,
										)
								: user =>
										onSaveOrdersReassignmentToUser(
											selectedOrders.reduce((acc, order) => {
												acc.push(order.order_id);
												return acc;
											}, []),
											user.id,
										),
					}}
				/>
			)}

			{/* ENTER NEW ORDERS */}
			{enterNewOrdersModal.isOpen && (
				<Modal
					modalId='CreateOrdersModal'
					title='Crear orden'
					visible={enterNewOrdersModal.isOpen}
					tipSpinning='Cargando datos...'
					footer={null}
					onCancel={() =>
						mutate1Object('enterNewOrdersModal', { isOpen: false })
					}
				/>
			)}
		</>
	);
}

const mapStateToProps = state => ({
	users: selectMyEntityActiveUsers(state),
	templates: selectTemplates(state),
	selectedOrders: selectSelectedOrders(state),
	modeView: selectModeView(state),
	getOrdersQueryModal: selectGetOrdersQueryModal(state),
	getInfoOrdersIncomeModal: selectGetInfoOrdersIncomeModal(state),
	getOrderInformationModal: getOrderInformation(state),
	ordersReassignmentModal: selectOrderReassignmentModal(state),
	enterNewOrdersModal: selectEnterNewOrdersModal(state),
	sendEventModal: selectSendEventModal(state),
	profile: selectProfile(state),
});
const actions = {
	getControlOrders,
	getInfoOrdersIncome,
	getCountOrdersToReassignment,
	onGetTemplates,
	mutate1Object,
	onSaveOrdersReassignmentToUser,
	onResetOrdersReassignmentModal,
	sendOrderEvents,
	sendToast,
};
export default connect(mapStateToProps, actions)(ModalOrders);
