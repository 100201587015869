// Libs
import React from 'react';
// Components
import { Wrapper, IconButton, RefreshIcon, LogoIcon } from 'components';
// Utils
import { getSelectedPayments } from 'utils/filters/Billings/payments';

export default function Lines(props) {
	const { payments, paysManage, getPaymentLines } = props;

	const selectedPayments = getSelectedPayments(
		payments.pays.data,
		paysManage.selected.items,
	);
	const firstSelectedPayment = selectedPayments[0];

	// TODO: Poner como el total dependiendo de las lineas que son filtradas
	// function calculateDynamicNet() {
	//   if (selectedPayments.length === 1 && firstSelectedPayment) {
	//     const net = payments.lines.data.reduce((acc, line) => {
	//       if (line.payment_id === firstSelectedPayment.id) {
	//         if (line.action === "debit") acc -= Number(line.amount);
	//         else if (line.action === "credit") acc += Number(line.amount);
	//       }
	//       return acc;
	//     }, Number(firstSelectedPayment.payable_amount));

	//     return formatNumber.new(net, firstSelectedPayment.coin_symbol, 2);
	//   }
	// }

	return (
		<Wrapper
			width='100%'
			height='48px'
			padding='10px 0 10px 10px'
			justifyContent='space-between'
			mediumBackground
		>
			<div />
			<Wrapper padding='0'>
				{/* UPDATE LINES */}
				{!payments.lines.loading ? (
					<RefreshIcon
						button
						onClick={() =>
							getPaymentLines(
								firstSelectedPayment.id,
								firstSelectedPayment.paid_to,
							)
						}
					/>
				) : (
					<LogoIcon button spin />
				)}
			</Wrapper>
		</Wrapper>
	);
}
