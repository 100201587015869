// Libs
import _ from 'underscore'
import React, { useCallback, useEffect, useRef, useState } from 'react';
// Components
import { Badge } from 'antd';
// Hooks
import { useNearScreen } from 'hooks';
// Styles
import styles from '../Collector/Collector.module.css';

const PhotosWrapperMobile = ({ className, children }) => {
	const [showScrollbar, setShowScrollbar] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	const [currentIndex, setCurrentIndex] = useState(0);
	const [childrenCount, setChildrenCount] = useState(1);

	useEffect(() => {
		setIsMounted(true);

		return () => {
			setIsMounted(false);
		};
	}, []);

	const scrollHasStarted = useCallback(
		_.debounce(
			isSafe => {
				if (isSafe) {
					setShowScrollbar(true);
				}
			},
			50,
			true,
		),
		[],
	);

	const scrollHasEnded = useCallback(
		_.debounce(isSafe => {
			if (isSafe) {
				setShowScrollbar(false);
			}
		}, 100),
		[],
	);

	const handleOnScroll = () => {
		scrollHasStarted(isMounted);
		scrollHasEnded(isMounted);
	};

	const scrollableRef = useRef(null);
	useNearScreen({
		root: scrollableRef.current,
		rootMargin: '0px',
		threshold: 0.52,
		observeChildren: true,
		onIntersection: entries => {
			const element = entries[0];

			if (element?.isIntersecting && isMounted) {
				setCurrentIndex(
					Array.from(element.target.parentElement.children).indexOf(
						element.target,
					),
				);
			}
		},
	});

	useEffect(() => {
		if (scrollableRef.current) {
			const container = scrollableRef.current ?? {};

			if (container.children?.length) {
				setChildrenCount(container.children.length);
			}
		}
	}, [scrollableRef.current]);

	return (
		<Badge
			className={styles.badgeWrapper}
			count={childrenCount > 1 ? `${currentIndex + 1}/${childrenCount}` : 0}
			offset={[-40, 55]}
			style={{
				backgroundColor: 'rgba(37, 37, 38, 0.8)',
				boxShadow: 'none',
			}}
		>
			<div
				ref={scrollableRef}
				className={
					!showScrollbar || childrenCount <= 1
						? styles.collectorPhotoWrapperMobileScrollbarHidden
						: className
				}
				onScroll={handleOnScroll}
			>
				{children}
			</div>
		</Badge>
	);
};

const WrapperSwitcher = ({ className, justChildren, children }) =>
	justChildren ? (
		<div className={styles.collectorPhotos}>{children}</div>
	) : (
		<PhotosWrapperMobile className={className}>{children}</PhotosWrapperMobile>
	);

export default WrapperSwitcher;
