export const getCompanyIdAndOrgIdFromEntityId = (
	entityId,
	companies,
	organizations,
	entities,
) => {
	const entity = entities.find(ent => ent.id === entityId);
	const organization = organizations.find(
		org => org.id === entity.organization_id,
	);
	const company = companies.find(comp => comp.id === organization.company_id);
	return [company?.id, organization?.id];
};

export const someFieldsChanged = fieldsToUpdate => {
	for (const key in fieldsToUpdate) {
		if (Array.isArray(fieldsToUpdate[key]?.values))
			if (fieldsToUpdate[key].values.some(val => val !== undefined))
				return true;
		if (fieldsToUpdate[key].value !== undefined) return true;
	}
	return false;
};

export const buildPayload = fieldsInfo => {
	const payload = Object.keys(fieldsInfo).reduce((acc, key) => {
		let val = fieldsInfo[key].value ?? fieldsInfo[key].values;
		if (Array.isArray(val)) {
			if (val.every(item => item === undefined)) return acc;
			val = val.filter(item => item !== '' && item !== undefined);
		}
		if (val) acc[key] = val;
		return acc;
	}, {});

	return payload;
};
