// Libs
import React from 'react';
// Utils
import { capitalize } from 'utils/libs';
import { getHumanize } from 'utils/libs/dateFormats';
// Components
import { Title, Span } from 'components';
import { StarIcon, PointTextIcon } from 'components/Icons';
// Styles
import styles from '../User.module.css';

const Header = ({ name, rating, createdAt }) => {
	return (
		<div className={styles.titles__header}>
			<div className={styles.title__header}>
				<div className={styles.grid1row}>
					<Title width='190px' ellipsis>
						{capitalize(name, true)}
					</Title>
				</div>
				<div className={styles.general_values1}>
					<StarIcon />
					<Span disabled>{rating}</Span>
					<PointTextIcon />
					<Span ellipsis width='100%' disabled>
						{getHumanize({ date: createdAt })}
					</Span>
				</div>
			</div>
		</div>
	);
};

export default Header;
