// Libs
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import _ from 'underscore';
import moment from 'moment';
// Utils
import GENERAL from 'utils/constants/general';
import { filterStockSeriesSearchInData } from 'utils/libs';
// Components
import { TableVirtualized, Flag, Span } from 'components';
import { onScanStockSeries } from '../../../utils';
// Styles
import '../../../css/Stock.css';

const { Table, Column, Header, Row, Cell } = TableVirtualized;

const { ENV } = GENERAL;
const now = moment();

function StockSeriesDesktop(props) {
	const {
		stock,
		control,
		stockSeriesManage,
		mutate1Object,
		getItemsTransactionDocs,
		getTransactionDocs,
		getSearchingSeries,
		getSerieHistory,
		mutate1ObjectApi,
		serieHistory,
		sendToast,
		modeView,
		profile,
	} = props;

	if (
		stockSeriesManage.searchInData.searchBy !== 'scan' &&
		stockSeriesManage.searchInData.searchBy !== 'serie' &&
		(!stock.warehouseId ||
			!stock.ownerId ||
			!control.mode ||
			!stock.itemId ||
			!stock.stateId)
	)
		return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSES_STOCK_ARTICLES_DESKTOP} />;

	const filteredStockSeries = filterStockSeriesSearchInData(
		stock.series.data,
		stockSeriesManage.searchInData.searchBy,
		stockSeriesManage.searchInData.data,
	);
	if (stockSeriesManage.searchInData.searchBy === 'scan')
		onScanStockSeries(
			stockSeriesManage.searchInData,
			getSearchingSeries,
			mutate1Object,
		);

	// Functions
	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: filteredStockSeries,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: stockSeriesManage.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('stockSeriesManage', {
							filters: updatedFilters,
						}),
				}}
			>
				{label}
			</Header>
		);
	}

	function getIndexCellRenderer({ rowData, rowIndex }) {
		let flagPriorityStyle = {};
		if (rowData.priority) flagPriorityStyle = { ...rowData.priority.style };
		return (
			<Cell>
				<Flag style={flagPriorityStyle} height='20px' />
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	}

	function getDocNumberCellRenderer({ rowData }) {
		return (
			<Link
				className='desktop-transactions-table-docnumber-cell-container'
				to={ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP}
				onClick={() => {
					mutate1Object('transactionsManage', {
						docId: rowData.warehouse_transaction_doc_id,
						titleToolbar: 'Detalle de transacción',
					});
					getTransactionDocs({
						mode: 'document',
						docNumber: rowData.warehouse_transaction_doc_id,
						fromDate: now,
						toDate: now,
					});
					getItemsTransactionDocs(rowData.warehouse_transaction_doc_id);
				}}
			>
				<Cell>{rowData.doc_number}</Cell>
			</Link>
		);
	}

	function getSerieCellRenderer({ rowData }) {
		return (
			<Cell
				onClick={() => {
					mutate1ObjectApi('serieHistory', { isOpen: true });
					getSerieHistory(rowData.serie);
				}}
			>
				{rowData.serie}
			</Cell>
		);
	}

	function getRowRenderer({ key, columns, style, rowData }) {
		const selectedSeries = [...stockSeriesManage.selectedSeries];
		let styleRow = { ...style };

		// Is warehouse selected?
		const idx = _.findIndex(selectedSeries, serie => serie === rowData.serie);
		if (idx !== -1) {
			styleRow = {
				...styleRow,
				color: 'lightseagreen',
				backgroundColor: 'rgb(44,44,54)',
			};
		}

		return (
			<Row key={key} style={styleRow}>
				{columns}
			</Row>
		);
	}

	//  Render
	return (
		<Table
			highBackground
			width={document.documentElement.clientWidth}
			height={document.documentElement.clientHeight - 115}
			headerHeight={44}
			rowHeight={40}
			rowCount={filteredStockSeries.length}
			rowGetter={({ index }) => filteredStockSeries[index]}
			rowRenderer={getRowRenderer}
		>
			<Column
				dataKey='index'
				label='#'
				width={50}
				headerRenderer={({ label }) => <Header>{label}</Header>}
				cellRenderer={getIndexCellRenderer}
			/>
			<Column
				dataKey='item_code'
				label='Código'
				width={70}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
			/>
			<Column
				dataKey='item_name'
				label='Nombre'
				width={240}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
			/>
			<Column
				dataKey='serie'
				label='Serie'
				width={140}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
				cellRenderer={getSerieCellRenderer}
			/>
			{/* <Column
				dataKey='allowed_to_use_label'
				label='Permitido'
				width={70}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
			/> */}
			<Column
				dataKey='state_name'
				label='Estado'
				width={70}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
			/>
			<Column
				dataKey='warehouse_name'
				label='Almacén'
				width={130}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
			/>
			<Column
				dataKey='zone_name'
				label='Zona'
				width={90}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
			/>
			<Column
				dataKey='area_name'
				label='Area'
				width={90}
				headerRenderer={props => getHeaderRenderer(props, 'text')}
			/>
			<Column
				dataKey='warehouse_package_id'
				label='Paquete'
				width={90}
				headerRenderer={props => getHeaderRenderer(props, 'numeric')}
			/>
			<Column
				dataKey='doc_number'
				label='Documento'
				width={90}
				headerRenderer={props => getHeaderRenderer(props, 'numeric')}
				cellRenderer={getDocNumberCellRenderer}
			/>
			<Column
				dataKey='created_at'
				label='Creado'
				width={120}
				headerRenderer={props => getHeaderRenderer(props, 'date')}
			/>
			<Column
				dataKey='updated_at'
				label='Actualizado'
				width={120}
				headerRenderer={props => getHeaderRenderer(props, 'date')}
			/>
		</Table>
	);
}

export default StockSeriesDesktop;
