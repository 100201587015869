// Components
import { HamburguerIcon, SearchIcon } from 'components';
import { CustomToolbar } from '../sharedFunctions';
// Styles
import '../index.css';

export default function ReportsToolbar({
	title,
	showNavBar,
	selectNotification,
	mutate1ObjectInReports,
	searchInData,
}) {
	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={title}
			left={<HamburguerIcon button onClick={showNavBar} />}
			right={
				<SearchIcon
					button
					onClick={() =>
						mutate1ObjectInReports('control', {
							searchInData: { ...searchInData, renderToolbar: true },
						})
					}
				/>
			}
		/>
	);
}
