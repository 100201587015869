// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Components
import { Drawer, Wrapper, Button, Span, Divider, Spin } from 'components';
import ImgGraphics2 from 'assets/media/img/graphics2.svg';
import {
	PaycutsProductionLine,
	PaysProductionLine,
} from 'screens/Analytics/charts';
const backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' width='80' height='80'%3E%3Cg fill='%23010002' fill-opacity='0.4'%3E%3Cpath d='M0 0h80v80H0V0zm20 20v40h40V20H20zm20 35a15 15 0 1 1 0-30 15 15 0 0 1 0 30z' opacity='.5'%3E%3C/path%3E%3Cpath d='M15 15h50l-5 5H20v40l-5 5V15zm0 50h50V15L80 0v80H0l15-15zm32.07-32.07l3.54-3.54A15 15 0 0 1 29.4 50.6l3.53-3.53a10 10 0 1 0 14.14-14.14zM32.93 47.07a10 10 0 1 1 14.14-14.14L32.93 47.07z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");`;

function onCloseDrawer(
	paycutsAnalytics,
	paymentsAnalytics,
	ordersAnalytics,
	linesAnalytics,
	mutate1Object,
) {
	mutate1Object('paycuts', {
		analytics: { ...paycutsAnalytics, isOpen: false },
	});
	mutate1Object('pays', {
		analytics: { ...paymentsAnalytics, isOpen: false },
	});
	mutate1Object('orders', {
		analytics: { ...ordersAnalytics, isOpen: false },
	});
	mutate1Object('lines', {
		analytics: { ...linesAnalytics, isOpen: false },
	});
}

function isVisibleDrawer(
	paycutsAnalytics,
	paymentsAnalytics,
	ordersAnalytics,
	linesAnalytics,
) {
	return (
		paycutsAnalytics.isOpen ||
		paymentsAnalytics.isOpen ||
		ordersAnalytics.isOpen ||
		linesAnalytics.isOpen
	);
}

function TitleAnalytics({
	paycutsAnalytics,
	paymentsAnalytics,
	ordersAnalytics,
	linesAnalytics,
	mutate1Object,
	theme,
}) {
	return (
		<Wrapper
			padding='0'
			justifyContent='space-between'
			className='animated fadeIn'
		>
			<Span fontSize='s18'>Estadísticas</Span>
			<Button
				color={theme.colors.text.high}
				onClick={() =>
					onCloseDrawer(
						paycutsAnalytics,
						paymentsAnalytics,
						ordersAnalytics,
						linesAnalytics,
						mutate1Object,
					)
				}
			>
				Cerrar
			</Button>
		</Wrapper>
	);
}

export default function PaymentsAnalytics({
	paycutsAnalytics,
	paymentsAnalytics,
	ordersAnalytics,
	linesAnalytics,
	paycuts,
	payments,
	orders,
	lines,
	coins,
	mutate1Object,
	getPayments,
}) {
	const theme = useTheme();
	return (
		<Drawer
			key={'right'}
			width={`${document.documentElement.clientWidth}px`}
			placement={'right'}
			closable={false}
			header_font_weight='bold'
			content_border='1px solid red'
			headerStyle={{ height: '56px', padding: '10px' }}
			title={
				<TitleAnalytics
					paycutsAnalytics={paycutsAnalytics}
					paymentsAnalytics={paymentsAnalytics}
					ordersAnalytics={ordersAnalytics}
					linesAnalytics={linesAnalytics}
					mutate1Object={mutate1Object}
					theme={theme}
				/>
			}
			onClose={() =>
				onCloseDrawer(
					paycutsAnalytics,
					paymentsAnalytics,
					ordersAnalytics,
					linesAnalytics,
					mutate1Object,
				)
			}
			visible={isVisibleDrawer(
				paycutsAnalytics,
				paymentsAnalytics,
				ordersAnalytics,
				linesAnalytics,
			)}
		>
			<img
				src={ImgGraphics2}
				style={{ position: 'fixed', left: '10%', zIndex: -1 }}
			/>
			<Wrapper
				padding='56px 10px 10px 10px'
				flexDirection='column'
				justifyContent='space-between'
				backgroundColor='rgba(0,0,0,.85)'
			>
				{/* PAYCUTS (PRODUCTION) */}
				{paycuts.data.length > 0 && (
					<Wrapper width='100%' flexDirection='column' borderRadius='50px'>
						<Divider color={theme.colors.text.high} orientation='left'>
							Producción (Cortes)
						</Divider>
						<PaycutsProductionLine
							paycuts={paycuts.data}
							paymentsAnalytics={paymentsAnalytics}
							mutate1Object={mutate1Object}
							getPayments={getPayments}
						/>
					</Wrapper>
				)}
				{/* PAYMENTS (PRODUCTION) */}
				<Spin
					spinning={payments.loading}
					size='large'
					delay={50}
					tip={'Consultando datos...'}
				>
					<Wrapper width='100%' flexDirection='column' borderRadius='50px'>
						<Divider color={theme.colors.text.high} orientation='left'>
							Producción (Pagos)
						</Divider>
						<Wrapper width='100%' height='600px' flexDirection='column'>
							<Span color={theme.fonts.colors.concept} fontSize='xl'>
								Corte: {payments.data[0]?.paycut_id || 'No seleccionado'}
							</Span>
							<PaysProductionLine payments={payments.data} coins={coins} />
						</Wrapper>
					</Wrapper>
				</Spin>
			</Wrapper>
		</Drawer>
	);
}
