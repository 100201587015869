import { memo } from 'react';

import { DatePicker } from 'components';

const DateField = memo(({ title, value, onChange }) => (
	<DatePicker
		width='100%'
		allowClear={false}
		placeholder={title}
		value={value}
		onChange={date => onChange(date.utc().format())}
	/>
));

export default DateField;
