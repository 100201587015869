import Immutable from 'immutable';
import _ from 'underscore';
import { filterDataByFilterOptions } from '../';

function filterPaycuts(paycuts, filters) {
	let filteredPaycuts = Immutable.List(paycuts).toJS();

	filteredPaycuts = filterDataByFilterOptions(filteredPaycuts, filters);
	return filteredPaycuts;
}

function getSelectedPaycuts(paycuts, selectedPaycutsIds) {
	if (!Array.isArray(selectedPaycutsIds) || !Array.isArray(paycuts)) return [];

	return selectedPaycutsIds.reduce((acc, paycutId) => {
		const idx = _.findIndex(paycuts, paycut => paycut.id === paycutId);
		if (idx !== -1) acc.push(paycuts[idx]);
		return acc;
	}, []);
}

export { filterPaycuts, getSelectedPaycuts };
