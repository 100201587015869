// Libs
import React from 'react';
import PropTypes from 'prop-types';
// Utils
import RouteWithSubRoutes from '../utils/routes/RouteWithSubRoutes';
// Styles
import 'antd/dist/antd.css';

const Root = ({ routes }) =>
	routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />);

Root.propTypes = {
	routes: PropTypes.array.isRequired,
};

export default Root;
