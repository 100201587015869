// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
// Utils
import {
	getPaymentColorState,
	thisBelongsToMyEntity,
	isPayRowSelected,
} from '../../../utils';
import { formatNumber } from 'utils/libs';
import { getSelectedPaycuts } from 'utils/filters/Billings/paycuts';
import { filterPayments } from 'utils/filters/Billings/payments';
import GENERAL from 'utils/constants/general';
// Components
import { Wrapper, TableVirtualized, Flag, Span, Spin, Link } from 'components';

const { ENV } = GENERAL;
const { Table, Column, Header, Row, Cell } = TableVirtualized;

function InvoicesDesktop(props) {
	if (
		props?.profile?.entity?.id ===
			props?.profile?.organization?.settings?.mainEntity &&
		(!Array.isArray(props?.paycuts?.selected?.items) ||
			props?.paycuts?.selected?.items?.length === 0)
	) {
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYCUTS_DESKTOP} />;
	}

	const {
		// BILLINGS
		paycuts,
		pays,
		mutate1Object,
		getPaycutsModal,
		// API
		payments,
		getPaymentOrders,
		getPaymentLines,
		// GENERAL
		profile,
	} = props;
	const allPayments = payments.pays.data;

	const selectedPaycuts = getSelectedPaycuts(
		payments.paycuts.data,
		paycuts.selected.items,
	);
	const firstSelectedPaycut = selectedPaycuts[0];

	const filteredPays = filterPayments(allPayments, pays.filters);

	// ------RENDERS------

	// HEADER
	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: allPayments,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: pays.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('pays', { filters: updatedFilters }),
				}}
			>
				{label}
			</Header>
		);
	}

	// ROW
	function getRowRenderer({ key, columns, style, rowData }) {
		let styleRow = { ...style };
		const payIds = Array.isArray(rowData.id) ? [...rowData.id] : [rowData.id];

		// Is payment selected?
		if (isPayRowSelected(pays.selected, payIds)) {
			styleRow = {
				...styleRow,
				color: 'lightseagreen',
				backgroundColor: 'rgb(44,44,54)',
			};
		}

		return (
			<Row
				key={key}
				style={styleRow}
				onClick={() => {
					mutate1Object('pays', {
						selected: { ...pays.selected, items: payIds },
					});
				}}
			>
				{columns}
			</Row>
		);
	}

	// ID
	function getIDCellRenderer({ rowData }) {
		return (
			<Cell>
				<Flag style={getPaymentColorState(rowData.state)} height='20px' />
				<Span margin='0 0 0 5px'>{rowData.id}</Span>
			</Cell>
		);
	}

	// AMOUNT
	function getAmountCellRenderer({ rowData, dataKey }) {
		return (
			<Cell>
				{dataKey === 'production_amount'
					? formatNumber.new(rowData.production_amount, rowData.coin_symbol, 2)
					: dataKey === 'production_charge' &&
					  formatNumber.new(rowData.production_charge, rowData.coin_symbol, 2)}
			</Cell>
		);
	}

	// PAYMENT HAVE NAME
	function paymentHaveName() {
		return getPaycutsModal.type === 'invoice';
	}

	// NAME
	function getNameCellRenderer({ rowData }) {
		return (
			<Cell>
				{paymentHaveName() ? rowData.payment_name : rowData.paid_to_name}
			</Cell>
		);
	}

	// ORDERS QTY
	function getOrdersQtyCellRenderer({ rowData, dataKey }) {
		function canGetOrders() {
			if (
				(getPaycutsModal.type === 'payment' && Number(rowData[dataKey]) <= 0) ||
				(getPaycutsModal.type === 'invoice' &&
					dataKey !== 'orders_paid' &&
					Number(rowData[dataKey]) <= 0)
			) {
				return false;
			}
			return true;
		}

		return (
			<Link
				to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_ORDERS_DESKTOP}
				onClick={() => {
					if (canGetOrders()) {
						const payIds = Array.isArray(rowData.id)
							? [...rowData.id]
							: [rowData.id];

						mutate1Object('pays', {
							selected: { ...pays.selected, items: payIds },
							getOrders: dataKey,
						});
						getPaymentOrders(payIds, dataKey);
					}
				}}
			>
				{rowData[dataKey]}
			</Link>
		);
	}

	// LINE
	function getLineCellRenderer({ rowData }) {
		return thisBelongsToMyEntity(rowData.entity_id, profile) ? (
			<Link
				to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_LINES_DESKTOP}
				onClick={() => {
					const payIds = Array.isArray(rowData.id)
						? [...rowData.id]
						: [rowData.id];
					mutate1Object('pays', {
						selected: { ...pays.selected, items: payIds },
					});
					getPaymentLines(payIds, rowData.paid_to);
				}}
			>
				{<div>{rowData.lines_quantity}</div>}
			</Link>
		) : (
			<div>{rowData.lines_quantity}</div>
		);
	}

	// DIFFERENCE
	function getDifferenceAmountsCellRenderer({ rowData }) {
		const { production_amount, production_charge, coin_symbol } = rowData;
		const difference = production_amount - production_charge;

		return <Cell>{formatNumber.new(difference, coin_symbol, 2)}</Cell>;
	}

	return (
		<Wrapper padding='0' className='animated fadeIn'>
			<Spin
				spinning={payments.pays.loading}
				size='large'
				delay={50}
				tip={'Consultando Pagos...'}
			>
				<Table
					highBackground
					width={document.documentElement.clientWidth}
					height={document.documentElement.clientHeight - 115}
					headerHeight={44}
					rowHeight={25}
					rowCount={filteredPays.length}
					rowGetter={({ index }) => filteredPays[index]}
					rowRenderer={getRowRenderer}
				>
					{/* {(getPaycutsModal.type === "payment" ||
            (getPaycutsModal.type === "invoice" &&
              paycuts.selected.items.length === 1)) && (
            <Column
              dataKey="id"
              label="ID"
              width={70}
              headerRenderer={(props) => getHeaderRenderer(props, "numeric")}
              cellRenderer={getIDCellRenderer}
            />
          )} */}
					<Column
						dataKey='id'
						label='ID'
						width={70}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getIDCellRenderer}
					/>
					<Column
						dataKey={paymentHaveName() ? 'payment_name' : 'paid_to_name'}
						label='Nombre'
						width={200}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getNameCellRenderer}
					/>
					<Column
						dataKey='orders_total'
						label='Atendidas'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getOrdersQtyCellRenderer}
					/>
					<Column
						dataKey='orders_topay'
						label='Cobrar'
						width={60}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getOrdersQtyCellRenderer}
					/>
					<Column
						dataKey='orders_notpay'
						label='No Cobrar'
						width={60}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getOrdersQtyCellRenderer}
					/>
					<Column
						dataKey='orders_previous'
						label='Anteriores'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getOrdersQtyCellRenderer}
					/>
					<Column
						dataKey='orders_pending'
						label='Pendientes de Pago'
						width={100}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getOrdersQtyCellRenderer}
					/>
					<Column
						dataKey='orders_paid'
						label='Pago Final'
						width={70}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getOrdersQtyCellRenderer}
					/>
					<Column
						dataKey='lines_quantity'
						label='Lineas'
						width={50}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getLineCellRenderer}
					/>
					{getPaycutsModal.type === 'payment' && (
						<Column
							dataKey='coin_abbreviation'
							label='Moneda'
							width={60}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
						/>
					)}
					<Column
						dataKey='production_amount'
						label='Cobrado'
						width={110}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getAmountCellRenderer}
					/>

					{thisBelongsToMyEntity(firstSelectedPaycut?.entity_id, profile) && (
						<Column
							dataKey='production_charge'
							label='Cobrar'
							width={110}
							headerRenderer={props => getHeaderRenderer(props, 'numeric')}
							cellRenderer={getAmountCellRenderer}
						/>
					)}
					{thisBelongsToMyEntity(firstSelectedPaycut?.entity_id, profile) && (
						<Column
							dataKey='difference'
							label='Diferencia'
							width={110}
							headerRenderer={props => getHeaderRenderer(props, 'numeric')}
							cellRenderer={getDifferenceAmountsCellRenderer}
						/>
					)}
					<Column
						dataKey='template_name'
						label='Template'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='state_label'
						label='Estado'
						width={90}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='signed_label'
						label='Firmado'
						width={70}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					{getPaycutsModal.type === 'payment' && (
						<Column
							dataKey='paycut_from_date'
							label='Desde'
							width={100}
							headerRenderer={({ dataKey, label }) => (
								<Header
									filter={{
										dataKey,
										data: allPayments,
										orderType: 'date',
										dateFormat: 'YYYY-MM-DD',
										filters: pays.filters,
										onChangedFilter: updatedFilters =>
											mutate1Object('pays', { filters: updatedFilters }),
									}}
								>
									{label}
								</Header>
							)}
						/>
					)}
					{getPaycutsModal.type === 'payment' && (
						<Column
							dataKey='paycut_to_date'
							label='Hasta'
							width={100}
							headerRenderer={({ dataKey, label }) => (
								<Header
									filter={{
										dataKey,
										data: allPayments,
										orderType: 'date',
										dateFormat: 'YYYY-MM-DD',
										filters: pays.filters,
										onChangedFilter: updatedFilters =>
											mutate1Object('pays', { filters: updatedFilters }),
									}}
								>
									{label}
								</Header>
							)}
						/>
					)}
					{getPaycutsModal.type === 'invoice' && selectedPaycuts.length > 1 && (
						<Column
							dataKey='paycut_id'
							label='Corte'
							width={70}
							headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						/>
					)}
					<Column
						dataKey='creator_name'
						label='Creador'
						width={110}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='entity_name'
						label='Entidad'
						width={110}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
				</Table>
			</Spin>
		</Wrapper>
	);
}

export default InvoicesDesktop;
