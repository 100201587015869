// Libs
import cx from 'classnames';
// Hooks
import useTheme from 'hooks/useTheme';
// Styles
import styles from './Title.module.css';

const Title = props => {
	const { theme, sharedStyles } = useTheme();
	return (
		<span
			style={{
				color: props.color ?? theme._primaryColor,
				width: props.width,
				margin: props.margin,
			}}
			className={cx(
				sharedStyles.standardTextStructure,
				styles.title,
				theme.highEmphasisColor,
				{
					[sharedStyles.ellipsis]: !!props.ellipsis,
					[sharedStyles.cursorPointer]: !!props.cursorPointer,
					[sharedStyles[`textAlign_${props.textAlign}`]]: !!props.textAlign,
				},
			)}
		>
			{props.children}
		</span>
	);
};

export default Title;
