// Libs
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
// Hooks
import { useUserApi } from 'hooks';
// Components
import { Wrapper, LogoIcon, Select } from 'components';
// Styles

const Selection = ({ state, onSetUserProfileActive }) => {
	const [isOpen, setIsOpen] = useState(false);
	if (!state.entityAssignment.length) return null;

	const currentEntity = state.entityAssignment.find(
		entity => !!entity.selected,
	);

	return (
		<Select
			width='100%'
			value={!isOpen || state.isLoading ? currentEntity.name : currentEntity.id}
			onChange={onSetUserProfileActive}
			onDropdownVisibleChange={visible => setIsOpen(visible)}
			dropdownRender={menu => (
				<>
					{menu}
					{state.isLoading && (
						<Wrapper width='100%' justifyContent='center'>
							<LogoIcon spin={true} />
						</Wrapper>
					)}
				</>
			)}
		>
			{state.entityAssignment.map(entity => (
				<Select.Option key={entity.id} value={entity.id}>
					{entity.name}
				</Select.Option>
			))}
		</Select>
	);
};

const SelectUserProfileActive = ({ userId }) => {
	const { getEntityAssignment, setUserProfileActive } = useUserApi();
	const dispatch = useDispatch();

	const [state, _setState] = useState({
		entityAssignment: [],
		isLoading: false,
	});

	const setState = newData =>
		_setState(current => ({ ...current, ...newData }));

	const onSetUserProfileActive = entityId =>
		setUserProfileActive({ userId, entityId }).then(() =>
			window.location.reload(),
		);

	useEffect(() => {
		let isMounted = true;
		setState({ isLoading: true });
		getEntityAssignment(userId)
			.then(entityAssignment => {
				if (isMounted) setState({ entityAssignment, isLoading: false });
			})
			.catch(({ message, type }) => {
				if (!isMounted) return;
				dispatch(
					sendToast({
						message:
							message ||
							'Tuve un problema al realizar la consulta de los datos. Por favor inténtalo más tarde',
						type: type || 'warn',
					}),
				);
				setState({ isLoading: false });
			});
		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<Selection state={state} onSetUserProfileActive={onSetUserProfileActive} />
	);
};

export default SelectUserProfileActive;
