// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Components
import {
	Wrapper,
	Span,
	IconButton,
	RefreshIcon,
	LogoIcon,
	EditIcon,
	CloseIcon,
	CheckIcon,
} from 'components';
// Utils
import { filterBillingOrders } from 'screens/Billings/utils';
import { formatNumber, xlsx, coinsUtils } from 'utils/libs';
import { getSelectedPayments } from 'utils/filters/Billings/payments';
import { ExcelIcon } from 'components/Icons';

export default function PaymentOrdersSubToolbar(props) {
	const {
		api,
		billingsManage,
		getPaymentOrders,
		mutate1ObjectInBillings,
		updatePaymentOrders,
		profile,
		coins,
	} = props;
	const theme = useTheme();
	const { orders, pays } = billingsManage;
	const selectedPayments = getSelectedPayments(
		api?.payments?.pays?.data,
		pays?.selected?.items,
	);
	const firstSelectedPayment = selectedPayments[0];
	const coinSymbol = coinsUtils.getDataByCoinId(
		firstSelectedPayment?.coin_id,
		'symbol',
		coins,
	);

	const filteredPaymentOrders = filterBillingOrders(
		api.payments.orders.data,
		orders.searchInData.searchBy,
		orders.searchInData.data,
	);

	function calculateDynamicProduction() {
		if (selectedPayments.length > 0 && api.payments.orders.data.length > 0) {
			const prod = api.payments.orders.data.reduce((acc, order) => {
				if (pays.getOrders === 'orders_paid') {
					acc += Number(order.amount) + Number(order.bonus);
				} else {
					acc += Number(order.price.price);
				}
				return acc;
			}, 0.0);

			return pays.getOrders === 'orders_paid'
				? formatNumber.new(prod, coinSymbol, 2)
				: formatNumber.new(prod, coinSymbol, 2);
		} else return 0.0;
	}

	function getDisplayedText() {
		if (pays.getOrders === 'orders_total') return 'Ordenes';
		if (pays.getOrders === 'orders_topay') return 'Pagar/Cobrar';
		if (pays.getOrders === 'orders_notpay') return 'No Pagar/Cobrar';
		if (pays.getOrders === 'orders_previous') return 'Anteriores';
		if (pays.getOrders === 'orders_pending') return 'Pendientes';
		if (pays.getOrders === 'orders_paid') return 'Pagando';
	}

	return (
		<Wrapper
			width='100%'
			height='48px'
			padding='10px 0 10px 10px'
			justifyContent='space-between'
			mediumBackground
		>
			{/* TITLE */}
			<Wrapper padding='0'>
				<Span fontWeight='bold' fontSize='l' color={theme.fonts.colors.default}>
					{`${getDisplayedText()} (${calculateDynamicProduction()})`}
				</Span>
			</Wrapper>

			<Wrapper padding='0'>
				{/* EDIT */}
				{pays.getOrders === 'orders_paid' &&
					orders.mode === 'view' &&
					selectedPayments.length === 1 &&
					firstSelectedPayment?.state === 'open' &&
					!firstSelectedPayment?.signed && (
						<EditIcon
							onClick={() =>
								mutate1ObjectInBillings('orders', { mode: 'edit' })
							}
						/>
					)}

				{/* EDIT CANCEL */}
				{!api.payments.orders.loading && orders.mode === 'edit' && (
					<CloseIcon
						button
						onClick={() => {
							mutate1ObjectInBillings('orders', {
								mode: 'view',
								editedOrders: [],
							});
						}}
					/>
				)}

				{/* EDIT CONFIRMATION */}
				{!api.payments.orders.loading && orders.mode === 'edit' && (
					<CheckIcon
						button
						onClick={() =>
							updatePaymentOrders(
								firstSelectedPayment?.paycut_id,
								firstSelectedPayment?.id,
								orders.editedOrders,
							)
						}
					/>
				)}

				{/* UPDATE ORDERS */}
				{!api.payments.orders.loading ? (
					<RefreshIcon
						button
						onClick={() =>
							getPaymentOrders(pays.selected.items, pays.getOrders)
						}
					/>
				) : (
					<LogoIcon button spin />
				)}

				{/* EXCEL EXPORT */}
				<ExcelIcon
					button
					onClick={() =>
						xlsx.exportBillingOrdersToExcel(
							'orders',
							filteredPaymentOrders,
							profile,
							coinSymbol,
							pays.getOrders,
						)
					}
				/>
			</Wrapper>
		</Wrapper>
	);
}
