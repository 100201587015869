// Libs
import React, { useState, useCallback } from 'react';
import moment from 'moment';
// Utils
import GENERAL from 'utils/constants/general';
import { debounce, formatNumber } from 'utils/libs';
// Components
import {
	Wrapper,
	TableVirtualized,
	Flag,
	Timer,
	TimeRemaining,
	Span,
	Checkbox,
	TextField,
	InputNumber,
	TimePicker,
	DatePicker,
	Dropdown,
	Menu,
	ReportIcon,
} from 'components';
import {
	OnlyReadField,
	AssignedTechId,
	EventId,
	ZoneId,
	Warranty,
} from 'components/DataFieldManager';
import OrderId from '../OrderId/OrderId';
import MergedPDFReport from '../MergedPDFReport/MergedPDFReportContext';

const { ENV } = GENERAL;

const { onClickRow } = TableVirtualized;
const { Input } = TextField;

export const DefaultEditableCell = ({
	rowData,
	field,
	fieldActionTypes = {},
}) => {
	const [localValue, setLocalValue] = useState(() => rowData[field.id]);

	const onChange = fieldActionTypes[field.type]?.onChange || function () {};

	const onDebouncedChange = useCallback(
		debounce(value => onChange(value, { rowData, field }), 1000),
		[rowData[field.id]],
	);

	const handleOnChange = value => {
		setLocalValue(value);
		onDebouncedChange(value);
	};

	const getDefaultComponent = {
		string: () => (
			<Input
				value={localValue}
				onChange={e => handleOnChange(e.target.value)}
			/>
		),
		int: () => (
			<InputNumber
				value={localValue}
				onChange={value => handleOnChange(value)}
			/>
		),
		decimal: () => (
			<InputNumber
				value={localValue}
				onChange={value => handleOnChange(value)}
			/>
		),
		date: () => (
			<DatePicker
				allowClear={false}
				value={moment(localValue)}
				onChange={date => handleOnChange(date.utc().format())}
			/>
		),
		datetime: () => (
			<Wrapper padding='0'>
				<DatePicker
					allowClear={false}
					value={moment(localValue)}
					onChange={date => handleOnChange(date.utc().format())}
				/>
				<TimePicker
					allowClear={false}
					value={moment(localValue)}
					onChange={date => handleOnChange(date.utc().format())}
				/>
			</Wrapper>
		),
		time: () => (
			<TimePicker
				allowClear={false}
				value={moment(localValue)}
				onChange={date => {
					setLocalValue(date.format());
					onDebouncedChange(date.utc().format());
				}}
			/>
		),
	}[field.type];

	if (!getDefaultComponent) return null;
	return getDefaultComponent();
};

export const DefaultCell = ({ rowData, field, profile }) => (
	<OnlyReadField rowData={rowData} field={field} profile={profile} />
);

export const IndexCell = ({ rowIndex, rowData, field, selected, setState }) => {
	const _onClickRow = filteredItems =>
		setState(prev => ({
			...prev,
			selected: {
				...prev.selected,
				items: filteredItems,
			},
		}));
	const flag = rowData[field.id]?.flag;
	const index = rowIndex + 1;

	return (
		<>
			{flag && <Flag style={flag.style || {}} height='20px' />}
			{field.unselectable ? (
				<Span>{index}</Span>
			) : (
				<Checkbox
					checked={selected.items.indexOf(rowData.id) !== -1}
					onChange={() => onClickRow(rowData.id, selected, _onClickRow)}
				>
					{index}
				</Checkbox>
			)}
		</>
	);
};

export const PriceCell = ({ rowData }) => {
	const { price = { price: 0.0, symbol: '' } } = rowData;
	const orderPrice = formatNumber.new(price.price, price.symbol, 2);

	return <Span>{orderPrice}</Span>;
};

export const OrderCountdownCell = ({ rowData }) => {
	const { startAt, timeRemaining, eventProps } = rowData;
	return (
		<Timer
			startAt={startAt}
			timeRemaining={timeRemaining}
			freezeTimer={eventProps?.[ENV.MANAGE_PROPS.FLAGS.FREEZE_TIMER]}
			render={({ timeRemaining } = {}) => (
				<TimeRemaining time={timeRemaining} />
			)}
		/>
	);
};

export const AssignedTechIdCell = ({ rowData, field }) => (
	<AssignedTechId rowData={rowData} field={field} />
);

export const EventIdCell = ({ rowData, field }) => (
	<EventId rowData={rowData} field={field} />
);

export const ZoneIdCell = ({ rowData, field }) => (
	<ZoneId rowData={rowData} field={field} />
);

export const MergedPDFReportCell = ({ rowData }) =>
	!rowData.otds?.length ? null : (
		<Dropdown.Wrapper
			trigger={['click']}
			overlay={
				<Menu.Wrapper>
					{rowData.otds.map(({ id } = {}) => (
						<Menu.Item key={id}>
							<MergedPDFReport resourceId={id} style={'Icon'} />
						</Menu.Item>
					))}
				</Menu.Wrapper>
			}
		>
			<div>
				<ReportIcon selected button />
			</div>
		</Dropdown.Wrapper>
	);

export const WarrantyCell = ({ rowData, field }) => (
	<Warranty rowData={rowData} field={field} />
);

export const OrderIdCell = ({ rowData }) => (
	<OrderId elementId={rowData.id} odtId={rowData.odtId} />
);
