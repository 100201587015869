// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mask0_21958_735'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='3'
			y='3'
			width='18'
			height='18'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5 5V19H19V12H21V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3H12V5H5ZM14 5V3H21V10H19V6.41L9.17 16.24L7.76 14.83L17.59 5H14Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mask0_21958_735)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
