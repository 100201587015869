// Libs
import React from 'react';
import styled, { css } from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';
import BoldIcon from './BoldIcon';
import SmallIcon from './SmallIcon';
import SmallBoldIcon from './SmallBoldIcon';

const SMALL_SIZE = 'small';

const Button = styled(IconButton)`
	${props =>
		props.shadow &&
		css`
			-webkit-box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
			box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
		`}
`;

const AbsoluteIcon = styled.div`
	position: absolute;
	bottom: -10px;
	right: 0;
	${props =>
		props.shadow &&
		css`
			-webkit-box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
			box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
		`}
`;

const RenderIcon = ({ size, bold, fill }) => {
	const { theme } = useTheme();
	const fillColor = fill ?? theme._inactiveColor;

	if (size === SMALL_SIZE) {
		if (bold) return <SmallBoldIcon fill={fillColor} />;
		return <SmallIcon fill={fillColor} />;
	}
	if (bold) return <BoldIcon fill={fillColor} />;
	return <Icon fill={fillColor} />;
};

const AddIcon = ({ button, bold, onClick, shadow, add, size, fill }) => {
	if (button) {
		return (
			<Button shadow={shadow} onClick={onClick}>
				<RenderIcon size={size} bold={bold} />
			</Button>
		);
	}
	if (add) {
		return (
			<AbsoluteIcon onClick={onClick} shadow={shadow}>
				<RenderIcon size={size} bold={bold} fill={fill} />
			</AbsoluteIcon>
		);
	}

	return (
		<div style={{ display: 'contents' }} onClick={onClick}>
			<RenderIcon size={size} bold={bold} />
		</div>
	);
};

export default AddIcon;
