// Libs
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
// Components
import { CommentsBox, CreatedBy, Drawer, Wrapper, CloseIcon } from 'components';
import ImageGallery from 'react-image-gallery';
// Selectors
import { selectCommentDetailsDrawer } from 'screens/Supervisor/selectors';
import { selectIsMobile } from 'screens/Dashboard/selectors';
// Actions
import { mutate1Object } from 'screens/Supervisor/actions';
// Constants
import GENERAL from 'utils/constants/general';
// Styles
import cx from 'classnames';
import { useTheme } from 'hooks';
import styles from './CommentDetailsDrawer.module.css';
// Components
import Item from './Item';

const { RESOURCES } = GENERAL.ENV;

const CommentDetailsDrawer = ({
	// State
	commentDetailsDrawer,
	isMobile,
	// Actions
	mutate1Object,
}) => {
	const {
		isOpen,
		selectedElementId,
		data,
		showPhotos,
		showComments,
		showButtons,
	} = commentDetailsDrawer ?? {};

	const { theme } = useTheme();

	const handleOnClose = () =>
		mutate1Object('commentDetailsDrawer', { isOpen: false });

	const handleOnSlide = useCallback(
		currentIndex =>
			mutate1Object('commentDetailsDrawer', {
				selectedElementId: Array.isArray(data)
					? data[currentIndex]?.id ?? null
					: null,
			}),
		[data],
	);

	const selectedIndex = useMemo(
		() =>
			Array.isArray(data)
				? data.findIndex(ele => ele.id === selectedElementId)
				: -1,
		[data, selectedElementId],
	);

	const imgItems = useMemo(
		() =>
			Array.isArray(data)
				? data.map(ele => ({
						original: ele.src,
						thumbnail: ele.src,
						originalClass: styles.imgWrapper,
						thumbnailClass: styles.thumbnailWrapper,
						coords: {
							longitude: ele.longitude,
							latitude: ele.latitude,
							updatedAt: ele.updatedAt,
						},
				  }))
				: [],
		[data],
	);

	return (
		<Drawer
			title={null}
			placement='bottom'
			closable={false}
			onClose={handleOnClose}
			visible={isOpen}
			height='100%'
			$maxWidth='100%'
			bodyStyle={{ height: '100%' }}
		>
			<Wrapper highBackground width='100%' height='100%' padding='0'>
				<div className={styles.mainContainer}>
					<div className={styles.closeButtonWrapper}>
						<CloseIcon button shadow={isMobile} onClick={handleOnClose} />
					</div>
					{showPhotos && (
						<div
							style={{
								backgroundColor: theme._primaryBackgroundColor,
							}}
							className={cx(styles.imageViewerWrapper, {
								[styles.imageViewerWrapperFullWidth]: !showComments,
							})}
						>
							<ImageGallery
								startIndex={selectedIndex !== -1 ? selectedIndex : 0}
								showThumbnails={true}
								showPlayButton={false}
								showFullscreenButton={showButtons}
								showNav={showButtons}
								onSlide={handleOnSlide}
								items={imgItems}
								renderItem={item => (
									<Item
										coords={item.coords}
										original={item.original}
										description={item.description}
										originalAlt={item.originalAlt}
									/>
								)}
							/>
						</div>
					)}
					{showComments && (
						<div
							style={{
								backgroundColor: theme._secondaryBackgroundColor,
							}}
							className={styles.commentsBoxContainer}
						>
							{Array.isArray(data) && data[selectedIndex]?.id ? (
								<>
									<div
										style={{
											backgroundColor: theme._secondaryBackgroundColor,
										}}
										className={styles.createdByWrapper}
									>
										<CreatedBy
											userName={data[selectedIndex]?.userName}
											date={data[selectedIndex]?.updatedAt}
										/>
									</div>
									<CommentsBox
										resourceKey={RESOURCES.KEYS.COLLECTOR_PHOTO}
										resourceId={data[selectedIndex]?.id}
										showReplyBox={true}
									/>
								</>
							) : null}
						</div>
					)}
				</div>
			</Wrapper>
		</Drawer>
	);
};

const mapStateToProps = state => ({
	commentDetailsDrawer: selectCommentDetailsDrawer(state),
	isMobile: selectIsMobile(state),
});

const mapDispatchToProps = {
	mutate1Object,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CommentDetailsDrawer);
