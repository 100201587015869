// Libs
import React from 'react';
// Utils
import { xlsx } from 'utils/libs';
// Styles
import '../index.css';
import { ExcelIcon } from 'components/Icons';
import { LogoIcon, RefreshIcon, Wrapper } from 'components';

export default function StockSeries(props) {
	const { stock, control, getStockSeries } = props;

	return (
		<Wrapper padding='0px' justifyContent='flex-end' mediumBackground>
			{!stock.series.loading && (
				<ExcelIcon
					button
					onClick={() =>
						xlsx.exportStockSeriesToExcel('Series', stock.series.data)
					}
				/>
			)}

			{!stock.series.loading ? (
				<RefreshIcon
					button
					onClick={() =>
						getStockSeries(
							stock.warehouseId,
							stock.ownerId,
							control.mode,
							stock.itemId,
							stock.stateId,
						)
					}
				/>
			) : (
				<LogoIcon button spin />
			)}
		</Wrapper>
	);
}
