// Libs
import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import './utils/libs/indexedDB/idbHandler';
// Service Worker
import registerServiceWorker from './registerServiceWorker';
// Services
import {
	SentryService,
	// MonitorService
} from 'services';
// Routes
import RouteWithSubRoutes from './utils/routes/RouteWithSubRoutes';
// Store
import store from './store/configureStore';
// Context
import { RealtimeProvider } from 'context/RealtimeContext';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { notification } from 'antd';
import { ModuleAccessControl, AppContainer } from 'components';
// Global and multiuse Styles
import 'normalize.css';
import './index.css';
import './Badges.css';
import 'moment/locale/es';
import 'dayjs/locale/es';
import 'animate.css/animate.compat.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-virtualized/styles.css';
// Style
import { GlobalStyle } from 'themes';

// Run
dotenv.config();
SentryService.run();
// MonitorService.run();

notification.config({
	placement: 'bottomRight',
	bottom: 0,
});

const o = window.screen.orientation;
if (o) {
	o.lock('portrait')
		.then(res => console.log(res))
		.catch(err => console.log(err));
}

const App = ({ theme, routes }) => {
	const { theme: _theme } = useTheme();
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle $newTheme={_theme} />
			<AppContainer>
				<Switch>
					{routes.map((route, i) => (
						<RouteWithSubRoutes key={i} {...route} />
					))}
				</Switch>
			</AppContainer>
		</ThemeProvider>
	);
};

Raven.context(function () {
	ReactDOM.render(
		<Provider store={store}>
			<RealtimeProvider>
				<Router>
					<ModuleAccessControl
						rootApp={({ theme, routes }) => (
							<App theme={theme} routes={routes} />
						)}
					/>
				</Router>
			</RealtimeProvider>
		</Provider>,
		document.getElementById('root'),
	);

	// Register Official Service Worker
	registerServiceWorker();
});
