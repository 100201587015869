//  Libs
import React, { useEffect } from 'react';
import { Button, Input, InputNumber, Select } from 'antd';
//  Styles
import '../../../index.css';

const { Option } = Select;

export default function CreateInvoicePaymentModal(props) {
	const {
		createPaymentModal,
		paycut,
		services,
		mutate1ObjectInBillings,
		createPayment,
		getServices,
	} = props;

	useEffect(() => {
		getServices(paycut.department_id);
	}, []);

	return (
		<div className='modal-container'>
			<div className='create-article-form-items'>
				<Input
					className='form-item-standard'
					placeholder='Nombre*'
					maxLength={100}
					value={createPaymentModal.name}
					onChange={e =>
						mutate1ObjectInBillings('createPaymentModal', {
							name: e.target.value,
						})
					}
				/>

				<InputNumber
					className='form-item-standard'
					placeholder='IVA'
					min={0.0}
					step={0.1}
					value={createPaymentModal.iva}
					formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
					parser={value => {
						value = value.replace(/\$\s?|(,*)/g, '');
						value = value.replace(/ /g, '');
						return value;
					}}
					onChange={value =>
						mutate1ObjectInBillings('createPaymentModal', { iva: value })
					}
				/>

				<Select
					className='form-item-standard'
					mode='multiple'
					style={{ width: '100%' }}
					placeholder='Seleccione los servicios*'
					options={createPaymentModal.service_ids}
					onChange={value =>
						mutate1ObjectInBillings('createPaymentModal', {
							service_ids: value,
						})
					}
				>
					{services.data.map(service => (
						<Option key={service.id}>{service.name}</Option>
					))}
				</Select>

				<div className='form-action-button'>
					<Button
						className='button-style-1'
						onClick={() =>
							createPayment(paycut.id, createPaymentModal, 'invoice')
						}
					>
						Crear
					</Button>
				</div>
			</div>
		</div>
	);
}
