//  Libs
import _ from 'underscore';
//  Constants
import GENERAL from '../../utils/constants/general';
const { USERS } = GENERAL;

//  InitialState
const initialState = {
	control: {
		filterView: 'active', // active, inactive, applicants, rejected
		selectedUsers: [],
		filters: {},
		searchInData: {
			renderToolbar: false,
			showPopover: false,
			data: '',
			searchBy: 'all', // name, owner, entity, creator
		},
	},
	userProfileModal: {
		isOpen: false,
		loading: false,
		userId: undefined,
	},
	setUserConfigurationModal: {
		isOpen: false,
		loading: false,
		selectedRoles: [],
		userPermissions: {},
	},
};

const userReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case USERS.MUTATE_1OBJECT: {
			const { obj1Name, keyValuePairs } = payload;
			const newObj = { ...state[obj1Name], ...keyValuePairs };
			return {
				...state,
				[obj1Name]: newObj,
			};
		}
		case USERS.MUTATE_DIRECT_PROPS: {
			const keyValuePairs = payload;
			return {
				...state,
				...keyValuePairs,
			};
		}
		case USERS.RESET: {
			return initialState;
		}
		default:
			return state;
	}
};

export default userReducer;
