// Libs
import React from 'react';
// Components
import { Title } from 'components';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';
// Styles
import styles from './TwoSimplePie.module.css';

const TwoSimplePie = ({ data, data2, config = {}, onClickChartSegment }) => {
	const { width = 400, height = 400, values, values2, colors } = config;
	return (
		<div className={styles.twoSimplePieContainer} style={{ width, height }}>
			<ResponsiveContainer width='100%' height='100%'>
				<PieChart width={width} height={height}>
					<Pie
						dataKey={values[0]}
						isAnimationActive={true}
						data={data}
						cx='50%'
						cy='50%'
						outerRadius={60}
						fill={colors[0]}
						label
						onClick={onClickChartSegment}
					/>
					<Pie
						dataKey={values2[0]}
						data={data2}
						cx={250}
						cy={150}
						innerRadius={30}
						outerRadius={60}
						fill={colors[1]}
						onClick={onClickChartSegment}
					/>
					<Tooltip />
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

export default TwoSimplePie;
