// Libs
import React from 'react';
import { Card } from 'antd';
import { Avatar } from 'components';
import { CloudStorage } from 'components/AppConfig.json';
// Hooks
import { useTheme } from 'hooks';
// Styles
import './OrderHistoryCard.css';
import '../../VirtualizedList.css';
import { formatDate } from 'utils/libs/dateFormats';

const { Meta } = Card;

export default function OrderHistoryCard({
	event_name,
	event_props,
	expert_name,
	creator_name,
	entity_name,
	details,
	created_at,
	order_id,
	to_order_id,
	created_by,
}) {
	const { theme } = useTheme();

	return (
		<Meta
			id='order_history_card-meta'
			avatar={
				<Avatar
					size='normal'
					avatar={{
						src: CloudStorage.concat(
							`/syn4pse/users/avatars/${
								created_by ? `${created_by}.jpg` : `default.svg`
							}`,
						),
					}}
				/>
			}
			title={
				<div className='container-title-card'>
					<span style={{ color: theme._primaryColor }} className='card-creator'>
						{creator_name}
						<span className='card-company'> - {entity_name}</span>
					</span>
					<span className='card-created-at'>
						{formatDate(created_at, null, 'DD-MM-YYYY HH:mm')}
					</span>
				</div>
			}
			description={
				<div className='order_history-container-description-card scrollable'>
					<div className='order_history-card-container-item'>
						<div className='card-concept'>Evento:</div>
						<div className='card-value'>{event_name}</div>
					</div>
					{expert_name && (
						<div className='order_history-card-container-item'>
							<div className='card-concept'>Asignación:</div>
							<div className='card-value'>{expert_name}</div>
						</div>
					)}
					{event_props && event_props.transferBill && to_order_id && (
						<div className='order_history-card-container-item'>
							<div className='card-concept'>Transferencia: </div>
							<div className='card-value'>
								Se transfieren datos de facturación de la orden{' '}
								<b>{order_id}</b> a la orden <b>{to_order_id}</b>
							</div>
						</div>
					)}
					{details && details.reprogramAt && (
						<div className='order_history-card-container-item'>
							<span className='card-concept'>Fecha de programación: </span>
							<span className='card-value'>{details.reprogramAt}</span>
						</div>
					)}
					{details && details.comment && (
						<div className='order_history-card-container-item'>
							<span className='card-concept'>Comentario: </span>
							<q className='card-value'>{details.comment}</q>
						</div>
					)}
				</div>
			}
		/>
	);
}
