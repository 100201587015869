// Libs
import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import general from '../../../../../utils/constants/general';
// Styles
import '../../VirtualizedList.css';
import '../Orders/BillingMaterialCard';
import './WarehouseCard.css';

const { ENV } = general;
const { Meta } = Card;

export default function WarehouseCard(props) {
	const {
		counter,
		id,
		name,
		owner_name,
		entity_name,
		owner_id,
		type,
		country,
		address,
		creator_name,
		created_at,
		updated_at,
		customProps,
	} = props;
	const { stock, control, mutate1ObjectApi, getStockArticles } = customProps;

	return (
		<Meta
			id='warehouseCard-meta'
			title={
				<div className='warehouseCard-container-title'>
					<div className='warehouseCard-container-title-data'>
						<Link
							className='warehouseCard-name'
							to={ENV.ROUTES.PATHS.WAREHOUSES_STOCK_ARTICLES_MOBILE}
							onClick={() => {
								mutate1ObjectApi('stock', {
									...stock,
									warehouseId: id,
									ownerId: owner_id,
								});
								getStockArticles(id, owner_id, control.mode);
							}}
						>
							<div>{name}</div>
						</Link>
						<span className='warehouseCard-owner'>
							{owner_name} - {entity_name}
						</span>
					</div>
				</div>
			}
			description={
				<div className='warehouseCard-container-description'>
					<span className='warehouseCard-creator'>{creator_name}</span>
					<span className='warehouseCard-created_at'>{created_at}</span>
				</div>
			}
		/>
	);
}
