// Libs
import { useSelector } from 'react-redux';
// Services
import ApiServiceV2 from 'services/apiService/apiServiceV2';
// Selectors
import { selectSelectedDataFilterId } from 'core/selectors';

const endpoint = '/modules';
const useOrderApi = () => {
	const selectedDataFilterId = useSelector(selectSelectedDataFilterId);

	return {
		/**
		 * Get element overview data
		 * @param {int} orderId
		 * @returns Object
		 */
		getOrderDetails: orderId =>
			ApiServiceV2.post({
				url: `${endpoint}/v1/overview/${selectedDataFilterId}/${orderId}`,
			}),
	};
};

export default useOrderApi;
