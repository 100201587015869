import React from 'react';

const Icon = ({ fill, fillOpacity = '0.80', onClick }) => (
	<svg
		onClick={onClick}
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='maskSyncIcon24'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='4'
			y='1'
			width='16'
			height='22'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12 1V4C16.42 4 20 7.58 20 12C20 13.57 19.54 15.03 18.76 16.26L17.3 14.8C17.75 13.97 18 13.01 18 12C18 8.69 15.31 6 12 6V9L8 5L12 1ZM6 12C6 15.31 8.69 18 12 18V15L16 19L12 23V20C7.58 20 4 16.42 4 12C4 10.43 4.46 8.97 5.24 7.74L6.7 9.2C6.25 10.03 6 10.99 6 12Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#maskSyncIcon24)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);

export default Icon;
