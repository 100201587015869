import { Steps } from 'antd';
import styled from 'styled-components';

const { Step } = Steps;

const StepsStyled = styled(Steps)`
	/* Title Waiting*/
	.ant-steps-item-wait
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title {
		color: ${props => props.theme.steps.title.waiting.color};
	}
	/* Title Process */
	.ant-steps-item-process
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title {
		color: ${props => props.theme.steps.title.process.color};
	}
	/* Title Finish */
	.ant-steps-item-finish
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title {
		color: ${props => props.theme.steps.title.finish.color};
	}
	/* Title Error */
	.ant-steps-item-error
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title {
		color: ${props => props.theme.steps.title.error.color};
	}
	/* Icon Waiting*/
	.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
		color: ${props => props.theme.steps.icon.waiting.color};
	}
`;
const StepStyled = styled(Step)`
	color: white;
`;

export default {
	Wrap: StepsStyled,
	Step: StepStyled,
};
