// Libs
import React from 'react';
import { Wrapper, Title } from 'components';
// Illustrations
import Empty from './RealtimeAnalytics';

const RealtimeAnalyticsIllustration = ({
	title,
	width,
	fontSize,
	className,
}) => (
	<Wrapper
		className={className}
		width='100%'
		height='80%'
		justifyContent='center'
		alignItems='center'
	>
		<Wrapper flexDirection='column' alignItems='center'>
			<Empty width={width} />
			{title && (
				<Title textAlign='center' margin='10px 0 0 0' fontSize={fontSize}>
					{title}
				</Title>
			)}
		</Wrapper>
	</Wrapper>
);

export default RealtimeAnalyticsIllustration;
