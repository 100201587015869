// Libs
import React from 'react';
// Utils
import { getSelectedPayments } from 'utils/filters/Billings/payments';
// Components
import { AddIcon, IconButton } from 'components';
import { CustomToolbar } from '../../sharedFunctions';
import ToolbarBackButton from '../../ToolbarBackButton';
// Styles
import '../../index.css';

export function PaymentLines(props) {
	const {
		title,
		handleOnClick,
		payments,
		pays,
		selectNotification,
		mutate1ObjectInBillings,
	} = props;

	const selectedPayments = getSelectedPayments(
		payments.pays.data,
		pays.selected.items,
	);
	const firstSelectedPayment = selectedPayments[0];

	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={title}
			left={<ToolbarBackButton handleOnClick={handleOnClick} />}
			right={
				<>
					{/* ADD LINE */}
					{selectedPayments.length === 1 &&
						firstSelectedPayment?.state === 'open' && (
							<AddIcon
								button
								onClick={() =>
									mutate1ObjectInBillings('createLineModal', { isOpen: true })
								}
							/>
						)}
				</>
			}
		/>
	);
}
