// Libs
import React from 'react';
import styled from 'styled-components';
// Components
import IconButton from '@mui/material/IconButton';
import icon from './Calendar.svg';

const Icon = styled.div`
	background-image: url(${icon});
	width: 24px;
	height: 24px;
`;

const CalendarIcon = ({ button, onClick, onMouseEnter, onMouseLeave }) =>
	button ? (
		<IconButton
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<Icon />
		</IconButton>
	) : (
		<Icon
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	);

export default CalendarIcon;
