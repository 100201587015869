import React from 'react';
import styled, { useTheme } from 'styled-components';

// Components
import { Avatar } from 'components';
import { Button as _Button } from 'antd';

// Utils
import { CloudStorage } from 'components/AppConfig.json';

const Button = styled(_Button)`
	border: none;
	background-color: #3a3b3c;
	height: ${({ height }) => (height ? `${height}px` : '40px')};
	border-radius: 25px;
	font-size: medium;
`;

const PostWrapper = styled.div`
	background-color: #242526;
	border-radius: 10px;
	width: 100%;
	/* width: 95%;*/
	color: whitesmoke;
	margin-bottom: 10px;
`;

const ContentWrapper = styled.div`
	padding: 15px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ActionCard = ({ userId = undefined, onClick, buttonText }) => {
	const theme = useTheme();
	return (
		<PostWrapper>
			<ContentWrapper>
				<div
					style={{
						display: 'inline-flex',
						justifyContent: 'center',
						width: '10%',
					}}
				>
					<Avatar
						size='medium'
						fit
						avatar={{
							src: CloudStorage.concat(
								`/syn4pse/users/avatars/${
									userId ? `${userId}.jpg` : `default.svg`
								}`,
							),
						}}
					/>
				</div>
				<div
					style={{
						display: 'inline-flex',
						justifyContent: 'flex-end',
						width: '90%',
					}}
				>
					<Button
						height={theme.sizes.medium.height}
						onClick={onClick}
						style={{ width: '98%', color: 'lightgrey' }}
					>
						{buttonText}
					</Button>
				</div>
			</ContentWrapper>
		</PostWrapper>
	);
};

export default ActionCard;
