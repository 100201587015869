// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='normalWarehouseIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='3'
			y='2'
			width='18'
			height='20'
		>
			<path
				d='M16 20H8V18H16V20ZM16 22H8H16ZM16 16H8V14H16V16ZM18 13V11H6V13V22H3V8L12 2L21 8V22H18V13ZM12 9C12.8284 9 13.5 8.32843 13.5 7.5C13.5 6.67157 12.8284 6 12 6C11.1716 6 10.5 6.67157 10.5 7.5C10.5 8.32843 11.1716 9 12 9Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#normalWarehouseIcon)'>
			<path d='M24 0H0V24H24V0Z' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
