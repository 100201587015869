// Libs
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
// Utils
import GENERAL from 'utils/constants/general';
import { ModalBuilderConfig } from 'core/ModalBuilder';
import CollectorEcosystemAuthorization from 'components/Collector/Authorization';
import PhotoUtils from 'utils/PhotoUtils';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Contexts
import { ModalBuilderContext } from 'core/context';
// Hooks
import { useTheme } from 'hooks';
// Components
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ActionsBox from 'core/ElementBuilder/elements/Supervisor/ActionsBox';
import { CreatedBy, MoreIcon, Dropdown, Menu, Watermark } from 'components';
// Skeleton
import CollectorPhotoSkeleton from './CollectorPhotoSkeleton';
// Styles
import styles from './CollectorPhoto.module.css';

const { RESOURCES } = GENERAL.ENV;

const MenuViewButton = ({
	photo,
	otdId,
	serviceTaskId,
	collectorValueId,
	onUpdatedCoordSuccess,
}) => {
	const { setModal } = useContext(ModalBuilderContext);
	const modalKeys = ModalBuilderConfig.getAuthorizedModalKeys();

	return (
		<Menu.Wrapper>
			<Menu.Item
				key='changeCoords'
				onClick={() => {
					setModal(modalKeys.supervisorChangeCoords, {
						photoId: photo.id,
						photoName: photo.name,
						latitude: photo.latitude,
						longitude: photo.longitude,
						updatedAt: photo.updatedAt,
						otdId,
						serviceTaskId,
						collectorValueId,
						onSuccess: onUpdatedCoordSuccess,
					});
				}}
			>
				Cambiar Coordenadas
			</Menu.Item>
		</Menu.Wrapper>
	);
};

const CollectorPhoto = ({
	isMobile,
	collectorValueId,
	photo,
	otdId,
	serviceTaskId,
	logo,
	reviewing,
	openDetailsDrawer,
	onUpdatedCoordSuccess,
}) => {
	const { theme, isDarkTheme } = useTheme();

	const profile = useSelector(selectProfile);
	const canEditPhotoData = new CollectorEcosystemAuthorization(
		profile,
	).checkEditPhotoData();

	const thumbnailSrc = PhotoUtils.getThumbnail({
		src: photo.src,
		folder: 'photoCollectors',
		size: 400,
	});

	return (
		<div
			style={{
				backgroundColor: isDarkTheme
					? theme._secondaryBackgroundColor
					: theme._primaryBackgroundColor,
			}}
			className={styles.mainWrapper}
		>
			<div className={styles.top}>
				<div
					style={{
						color: theme._primaryColor,
					}}
					className={styles.nameWrapper}
				>
					<span className={styles.photoName}>{photo.name}</span>
				</div>
				{canEditPhotoData && (
					<div className={styles.more}>
						<Dropdown.Wrapper
							trigger={['hover']}
							overlay={
								<MenuViewButton
									photo={photo}
									otdId={otdId}
									serviceTaskId={serviceTaskId}
									collectorValueId={collectorValueId}
									onUpdatedCoordSuccess={onUpdatedCoordSuccess}
								/>
							}
						>
							<div>
								<MoreIcon button hoverable />
							</div>
						</Dropdown.Wrapper>
					</div>
				)}
			</div>
			<div className={styles.content}>
				<LazyLoadImage
					src={thumbnailSrc}
					onClick={() =>
						openDetailsDrawer({
							photoId: photo.id,
							showPhotos: true,
							showComments: !isMobile,
							showButtons: !isMobile,
						})
					}
					alt={`${photo.name}`}
					className={styles.preview}
				/>
				<Watermark coords={photo} updatedAt={photo.updatedAt} logo={logo} />
			</div>
			{reviewing && (
				<div className={styles.bottom}>
					<CreatedBy
						userId={photo.createdBy}
						userName={photo.userName}
						date={photo.updatedAt}
					/>
					<div className={styles.actionsWrapper}>
						<ActionsBox
							autonomous={true}
							resourceKey={RESOURCES.KEYS.COLLECTOR_PHOTO}
							resourceId={photo.id}
							justButtons={true}
							onClickCommentsButton={() =>
								openDetailsDrawer({
									photoId: photo.id,
									showPhotos: !isMobile,
									showComments: true,
								})
							}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

CollectorPhoto.Skeleton = CollectorPhotoSkeleton;

export default CollectorPhoto;
