// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.33333 2H12.6667C13.4067 2 14 2.6 14 3.33333V12.6667C14 13.4 13.4067 14 12.6667 14H3.33333C2.59333 14 2 13.4 2 12.6667V3.33333C2 2.6 2.59333 2 3.33333 2ZM3.33333 7.99997L6.66667 11.3333L12.6667 5.33331L11.7267 4.38664L6.66667 9.44664L4.27333 7.05997L3.33333 7.99997Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
