import React from 'react';

// Hooks
import { useTheme } from 'hooks';

// Components
import { Acronym } from 'components';

// Utils
import { getHumanize } from 'utils/libs/dateFormats';

// Skeleton
import CreatedBySkeleton from './CreatedBySkeleton';

import styles from './CreatedBy.module.css';

function CreatedBy({ userName, date }) {
	const { theme } = useTheme();
	const hasValidDate = !!new Date(date).valueOf();
	return (
		<div className={styles.createdBy}>
			<Acronym text={userName} />
			<div className={styles.infoWrapper}>
				<span
					style={{ color: theme._primaryColor }}
					className={styles.userName}
				>
					{userName}
				</span>
				<span style={{ color: theme._primaryColor }} className={styles.pubDate}>
					{hasValidDate ? getHumanize({ date }) : 'No disponible'}
				</span>
			</div>
		</div>
	);
}

CreatedBy.Skeleton = CreatedBySkeleton;

export default CreatedBy;
