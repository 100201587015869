// Libs
import React from 'react';
// Utils
import ChartUtils from './utils';
// Components
import { Table } from 'antd';
import { Wrapper, Span } from 'components';
// Styles
import './Table.css';

const CrossTableFooter = React.memo(({ keys, pageData }) => {
	const totals = ChartUtils.getCrossTableTotals({ keys, pageData });
	return (
		<Wrapper
			padding={`0 ${200 / keys.length}px 0 0`}
			justifyContent='space-between'
		>
			<Span color='black'>
				<strong>Total</strong>
			</Span>
			{keys.map((key, idx) => (
				<Span key={idx} color='black'>
					<strong>{totals[key]}</strong>
				</Span>
			))}
		</Wrapper>
	);
});

const VerticalTableFooter = React.memo(({ pageData }) => {
	const total = ChartUtils.getVerticalTableTotals({ pageData });
	return (
		<Wrapper padding='0' justifyContent='space-between'>
			<Span color='black'>
				<strong>Total</strong>
			</Span>
			<Span color='black'>
				<strong>{total}</strong>
			</Span>
		</Wrapper>
	);
});

// Handle the rendering: Tables & Pivot Tables
const MyTable = ({ type, data, orientation, config }) => {
	const indexedBy = ChartUtils.getIndexedByFromConfig({ config });
	const keys = ChartUtils.getKeys({ type, data, indexedBy });
	const tables = {
		horizontal: function () {
			// TODO
			return <></>;
		},
		vertical: function () {
			const rows = ChartUtils.getVerticalTableRows({ keys, data });
			const columns = ChartUtils.getVerticalTableColumns();

			return (
				<div style={{ width: '100%' }}>
					<Table
						rowKey='header'
						showHeader={false}
						columns={columns}
						dataSource={rows}
						scroll={{ x: 'max-content', y: 440 }}
						pagination={false}
						footer={pageData => <VerticalTableFooter pageData={pageData} />}
					/>
				</div>
			);
		},
		crosstab: function () {
			const rows = ChartUtils.getCrossTableRows({ keys, data, indexedBy });
			const columns = ChartUtils.getCrossTableColumns({
				keys,
				indexedBy,
				width: 90,
			});

			return (
				<Table
					rowKey={indexedBy}
					columns={columns}
					dataSource={rows}
					scroll={{ x: 'max-content', y: 440 }}
					pagination={false}
					footer={pageData => (
						<CrossTableFooter keys={keys} pageData={pageData} />
					)}
				/>
			);
		},
		default: () => null,
	};

	return (tables[orientation] ?? tables.default)();
};

export default MyTable;
