// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Utils
import { startCompareSeries } from 'screens/Warehouses/utils';
// Components
import {
	Wrapper,
	IconButton,
	Popover,
	Icon,
	Button,
	Spin,
	TextField,
	Menu,
	Dropdown,
	Span,
	EyeIcon,
	CheckIcon,
	CloseIcon,
	RefreshIcon,
	LogoIcon,
	AddIcon,
} from 'components';
import { SyncIcon, ToolsIcon } from 'components/Icons';

const { Input } = TextField;

export default function TransactionsInventoryToTransfer({
	inventory,
	createTransaction,
	createPackage,
	createNewEntryItem,
	getInventoryToTransfer,
	inventoryToTransferManage,
	mutate1ObjectInWarehouses,
	sendTransferTransaction,
	sendToast,
}) {
	const theme = useTheme();
	const NewEntryContent = (
		<ul className='warehouses-create-items-popover-list'>
			<li
				className='warehouses-create-items-popover-list-item'
				onClick={() =>
					mutate1ObjectInWarehouses('createNewEntryItem', {
						isOpen: true,
						showPopoverCreate: false,
					})
				}
			>
				Artículo
			</li>
			<li
				className='warehouses-create-items-popover-list-item'
				onClick={() => {
					mutate1ObjectInWarehouses('createNewEntryItem', {
						showPopoverCreate: false,
					});
					onClickPackagedSeries();
				}}
			>
				Serializado
			</li>
		</ul>
	);
	const CompareSeriesContent = (
		<div>
			<Input
				autoFocus
				style={{ width: 'auto' }}
				placeholder='Ingrese las series'
				value={inventoryToTransferManage.compareSeries}
				onKeyPress={e =>
					e.key === 'Enter' &&
					startCompareSeries(
						createTransaction.serialized,
						inventoryToTransferManage.compareSeries,
						mutate1ObjectInWarehouses,
						sendToast,
					)
				}
				onChange={e =>
					mutate1ObjectInWarehouses('inventoryToTransferManage', {
						compareSeries: e.target.value,
					})
				}
				suffix={
					inventoryToTransferManage.comparing ? (
						<Icon id='icon-standard' type='loading' />
					) : (
						<Icon
							id='icon-standard'
							type='check'
							onClick={() =>
								startCompareSeries(
									createTransaction.serialized,
									inventoryToTransferManage.compareSeries,
									mutate1ObjectInWarehouses,
									sendToast,
								)
							}
						/>
					)
				}
			/>
		</div>
	);
	const MenuViewButton = (
		<Menu.Wrapper>
			<Menu.Group title='Equipos'>
				<Menu.Item
					style={
						inventoryToTransferManage.viewOptions.devices === 'found'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInWarehouses('inventoryToTransferManage', {
							viewOptions: {
								...inventoryToTransferManage.viewOptions,
								devices: 'found',
							},
						});
					}}
				>
					Encontrados
				</Menu.Item>
				<Menu.Item
					style={
						inventoryToTransferManage.viewOptions.devices === 'notFound'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInWarehouses('inventoryToTransferManage', {
							viewOptions: {
								...inventoryToTransferManage.viewOptions,
								devices: 'notFound',
							},
						});
					}}
				>
					No Encontrados
				</Menu.Item>
			</Menu.Group>
		</Menu.Wrapper>
	);

	function quantitySeriesInNewSerializedPackage(series, package_id) {
		return series.reduce((acc, serie) => {
			if (serie.warehouse_package_id === package_id) acc++;
			return acc;
		}, 0);
	}

	function onCheckCreatedPackage() {
		if (
			quantitySeriesInNewSerializedPackage(
				createTransaction.serialized,
				createPackage.id,
			) <= 0
		)
			sendToast({
				message: 'Debe agregar al menos una serie al paquete',
				type: 'warn',
			});
		else {
			mutate1ObjectInWarehouses('createPackage', {
				operation: undefined,
				serialized: false,
				id: undefined,
				item_id: undefined,
				state_id: undefined,
				amount: undefined,
				warehouse_area_id: undefined,
				warehouse_zone_id: undefined,
				created: false,
				mustCreateZone: false,
				mustCreateArea: false,
				zone: undefined,
				area: undefined,
			});

			mutate1ObjectInWarehouses('inventoryToTransferManage', {
				searchInData: {
					...inventoryToTransferManage.searchInData,
					renderToolbar: false,
					searchBy: 'all',
				},
			});
		}
	}

	function onCancelCreatedPackage() {
		const serialized = createTransaction.serialized.filter(
			serie => serie.warehouse_package_id !== createPackage.id,
		);
		mutate1ObjectInWarehouses('createTransaction', { serialized });
		mutate1ObjectInWarehouses('createPackage', {
			operation: undefined,
			serialized: false,
			id: undefined,
			item_id: undefined,
			state_id: undefined,
			amount: undefined,
			warehouse_area_id: undefined,
			warehouse_zone_id: undefined,
			created: false,
			mustCreateZone: false,
			mustCreateArea: false,
			zone: undefined,
			area: undefined,
		});
		mutate1ObjectInWarehouses('inventoryToTransferManage', {
			searchInData: {
				...inventoryToTransferManage.searchInData,
				renderToolbar: false,
				searchBy: 'all',
			},
		});
	}

	function onClickPackagedSeries() {
		mutate1ObjectInWarehouses('createPackage', {
			operation: createTransaction.mode,
			isOpen: true,
			serialized: true,
			id: undefined,
			item_id: undefined,
			state_id: undefined,
			amount: undefined,
			warehouse_area_id: undefined,
			warehouse_zone_id: undefined,
			created: false,
			mustCreateZone: false,
			mustCreateArea: false,
			zone: undefined,
			area: undefined,
		});
	}

	return (
		<Wrapper height='48px' justifyContent='space-between'>
			{/* LEFT */}
			<Wrapper padding='0'>
				{inventoryToTransferManage.viewOptions.devices === 'notFound' &&
					createTransaction.notFoundSerialized.length > 0 && (
						<Span fontWeight='bold' color={theme.fonts.colors.default}>
							No encontrados: {createTransaction.notFoundSerialized.length}
						</Span>
					)}
				{inventoryToTransferManage.viewOptions.devices === 'found' &&
					createPackage.created &&
					createPackage.serialized && (
						<div className='subtoolbar-text'>
							{`Paquete: #${
								createPackage.id
							} / Series: ${quantitySeriesInNewSerializedPackage(
								createTransaction.serialized,
								createPackage.id,
							)}`}
						</div>
					)}

				{/* VIEW FOUND AND NOT FOUND SERIALIZED */}
				{createTransaction.mode === 'trsReceive' && (
					<Dropdown.Wrapper trigger={['click']} overlay={MenuViewButton}>
						<EyeIcon button />
					</Dropdown.Wrapper>
				)}
			</Wrapper>

			{/* RIGHT */}
			<Wrapper padding='0'>
				{/* CHECK OR CANCEL SERIES PACKAGE */}
				{createPackage.created && createPackage.serialized && (
					<div>
						{/* CANCEL PACKAGE */}
						<CloseIcon button onClick={onCancelCreatedPackage} />

						{/* CHECK PACKAGE */}
						<CheckIcon button onClick={onCheckCreatedPackage} />
					</div>
				)}

				{/* PACKAGED SERIES BUTTON */}
				{createTransaction.mode === 'trsReceive' &&
					!createTransaction.preview &&
					!createPackage.created &&
					!createTransaction.loading && (
						<Button
							color={theme.colors.text.high}
							height='30px'
							margin='0 10px 0 0'
							onClick={onClickPackagedSeries}
						>
							Empaquetar series
						</Button>
					)}

				{/* TOOLS */}
				{createTransaction.mode === 'trsSend' && (
					<ToolsIcon
						button
						onClick={() => {
							mutate1ObjectInWarehouses('toolsOptionsManage', {
								isOpen: true,
							});
						}}
					/>
				)}

				{/* LOADING */}
				{createTransaction.mode !== 'newEntry' && (
					<div>
						{!inventory.loading ? (
							<RefreshIcon
								onClick={() =>
									getInventoryToTransfer(createTransaction.owarehouseId)
								}
							/>
						) : (
							<LogoIcon button spin />
						)}
					</div>
				)}

				{/* PREVIEW BUTTON */}
				{(createTransaction.mode === 'trsReceive' ||
					createTransaction.mode === 'trsSend') &&
					!createPackage.created && (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{/* PREVIEW */}
							{!createTransaction.preview ? (
								<Button
									mode='primary'
									height='30px'
									onClick={() => {
										mutate1ObjectInWarehouses('createTransaction', {
											preview: true,
										});
										mutate1ObjectInWarehouses('inventoryToTransferManage', {
											filters: {},
										});
									}}
								>
									Resumen
								</Button>
							) : (
								// INVENTORY
								<Button
									color={theme.colors.text.high}
									height='30px'
									onClick={() =>
										mutate1ObjectInWarehouses('createTransaction', {
											preview: false,
										})
									}
								>
									Volver
								</Button>
							)}
						</div>
					)}

				{/* COMPARE SERIES */}
				{createTransaction.mode === 'newEntry' && !createPackage.created && (
					<Popover
						content={CompareSeriesContent}
						placement='bottom'
						title={null}
						trigger='click'
						visible={inventoryToTransferManage.showPopoverCompareSeries}
						onVisibleChange={() =>
							mutate1ObjectInWarehouses('inventoryToTransferManage', {
								showPopoverCompareSeries:
									!inventoryToTransferManage.showPopoverCompareSeries,
							})
						}
					>
						<div>
							<SyncIcon button />
						</div>
					</Popover>
				)}

				{/* ADD ITEM */}
				{createTransaction.mode === 'newEntry' && !createPackage.created && (
					<Popover
						content={NewEntryContent}
						placement='bottom'
						title={null}
						trigger='click'
						visible={createNewEntryItem.showPopoverCreate}
						onVisibleChange={() =>
							mutate1ObjectInWarehouses('createNewEntryItem', {
								showPopoverCreate: !createNewEntryItem.showPopoverCreate,
							})
						}
					>
						<div>
							<AddIcon button />
						</div>
					</Popover>
				)}

				{/* SAVE & UPDATE BUTTON */}
				{((createTransaction.mode === 'newEntry' && !createPackage.created) ||
					createTransaction.preview) && (
					<Spin
						spinning={createTransaction.loading}
						size='small'
						delay={50}
						tip={''}
					>
						<Button
							height='30px'
							margin='0 10px 0 10px'
							mode='primary'
							onClick={() =>
								sendTransferTransaction(
									createTransaction,
									createTransaction.mode,
								)
							}
						>
							Completar
						</Button>
					</Spin>
				)}
			</Wrapper>
		</Wrapper>
	);
}
