// Libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Utils
import { debounce } from 'utils/libs';
// Actions
import {
	mutate1Object as mutate1ObjectInCore,
	setSearchDatasource,
} from 'core/actions';
// Selectors
import { selectSearchDatasource } from 'core/selectors';
// Components
import { SearchIcon, CloseIcon, TextField } from 'components';

const { Input } = TextField;

const SearchValue = () => {
	const dispatch = useDispatch();
	const searchDatasource = useSelector(selectSearchDatasource);

	const handleSearchDatasource = props => dispatch(setSearchDatasource(props));

	const onSearch = React.useCallback(
		debounce(() => {
			dispatch(
				mutate1ObjectInCore('dataFilters', {
					lastRowId: 0,
				}),
			);
			dispatch(
				mutate1ObjectInCore('datasource', {
					reload: true,
				}),
			);
		}, 1000),
		[],
	);

	const handleOnChange = e => {
		let value = e.target.value.toLowerCase();
		if (!value) value = undefined;
		handleSearchDatasource({ value });
		onSearch();
	};

	return searchDatasource.isOpen ? (
		<Input
			autoFocus
			placeholder='Buscar'
			size='small'
			value={searchDatasource.value}
			onChange={handleOnChange}
			suffix={
				<CloseIcon
					cursorPointer
					onClick={() => {
						handleSearchDatasource({ isOpen: false, value: undefined });
						if (searchDatasource.value) onSearch();
					}}
				/>
			}
		/>
	) : (
		<SearchIcon onClick={() => handleSearchDatasource({ isOpen: true })} />
	);
};

export default SearchValue;
