// Libs
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
// Utils
import ModuleUtils from './utils';
import getRoutes from './routes';
// Actions
import {
	getMyActiveModules,
	mutate1Object as mutate1ObjectInModules,
} from './actions';
import { getMyProfile } from 'screens/Login/actions';
// Selectors
import { selectAccessControl } from './selectors';
import { selectProfile } from 'screens/Login/selectors';
import { selectModeView } from 'screens/Dashboard/selectors';
import { debounce } from 'utils/libs';

const ModuleAccessControl = ({
	// Props,
	rootApp,
	// State
	accessControl,
	profile,
	modeView,
	// Actions
	getMyActiveModules,
	getMyProfile,
	mutate1ObjectInModules,
}) => {
	const history = useHistory();
	const location = useLocation();
	const [intro, setIntro] = React.useState(true);
	const [currentPathname, setCurrentPathname] = React.useState(undefined);
	const { state: accessControlState, modules } = accessControl;
	const initialRoute = useRef();

	// Must fetch data
	const debouncedCallback = React.useCallback(
		debounce(callback => callback(), 200),
		[],
	);

	// Wait & Set intro
	React.useEffect(() => {
		setTimeout(() => setIntro(false), 10);
	}, []);

	// Get async data
	React.useEffect(() => {
		getMyActiveModules();
		getMyProfile();
	}, []);

	// Set initial window loaded pathname
	React.useEffect(() => {
		initialRoute.current = location.pathname;
	}, []);

	// Set moduleId and moduleItemId
	React.useEffect(() => {
		const { moduleId, moduleItemId } = ModuleUtils.getModuleItemIdFromPathname(
			location.pathname,
			modules,
		);

		setCurrentPathname(location.pathname);

		mutate1ObjectInModules('accessControl', {
			selectedModuleId: moduleId,
			selectedSubModuleId: moduleItemId,
		});
	}, [location.pathname, accessControlState]);

	// Unauthorized user?
	const unauthorized = React.useMemo(
		() =>
			ModuleUtils.checkAccessControlUnauthorized({
				accessControlState,
				profile,
			}),
		[accessControlState, profile],
	);

	// Is fetching modules?
	const isFetchingModules = React.useMemo(
		() => ModuleUtils.checkIsFetchingModules(accessControlState),
		[accessControlState],
	);

	// Theme
	// TODO: DEPRACATED // To delete comming soon when new theming method is done
	const theme = React.useMemo(() => ModuleUtils.getSelectedTheme(), []);

	// Routes
	const routes = React.useMemo(
		() =>
			getRoutes({
				modules,
				intro,
				isFetchingModules,
				unauthorized,
				modeView,
			}),
		[modules, intro, isFetchingModules, unauthorized],
	);

	// Auto Redirects
	React.useEffect(() => {
		debouncedCallback(() => {
			ModuleUtils.redirectRoute({
				intro,
				isFetchingModules,
				unauthorized,
				profile,
				currentPathname,
				modules,
				history,
				routes,
				initialRoute,
			});
		});
	}, [
		// intro,
		// isFetchingModules,
		// unauthorized,
		// modules,
		profile,
		currentPathname,
		routes,
	]);

	// Screen props
	const screenProps = React.useMemo(
		() => ModuleUtils.getScreenProps({ theme, routes }),
		[theme, routes],
	);

	// Render App
	return rootApp(screenProps);
};

const mapStateToProps = state => ({
	accessControl: selectAccessControl(state),
	profile: selectProfile(state),
	modeView: selectModeView(state),
});

const actions = {
	getMyActiveModules,
	getMyProfile,
	mutate1ObjectInModules,
};

export default connect(mapStateToProps, actions)(ModuleAccessControl);
