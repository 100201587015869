// Libs
import React, { Suspense } from 'react';
import { useTheme } from 'styled-components';
// Utils
import {
	filterAreas,
	filterPackages,
} from 'utils/filters/Warehouses/transactions';
// Hooks
import { useSetDefaultsData } from 'hooks/Warehouses/Transactions/Reception';
// Components
import {
	Wrapper,
	Span,
	Collapse as _Collapse,
	Spin,
	TextField,
	IconButton,
} from 'components';
const ArticlePathView = React.lazy(() => import('./views/ArticlePathView'));

// Lazy Components
const ScanSeriesView = React.lazy(() => import('./views/ScanSeriesView'));

const { Search } = TextField;
const { Collapse, Panel } = _Collapse;

function isScanMode(modeView) {
	return modeView === 'scan';
}

// -----RENDERS------
const PanelHeaderTitle = ({ title, theme }) => (
	<Span fontSize='s18' margin='0 10px 0 0' color={theme.colors.text.high}>
		{title}
	</Span>
);

const FindInData = ({
	searchingPlaceHolder,
	searchingValue,
	searching,
	mutate1Object,
}) => (
	<Wrapper width='100%' padding='0 0 0 10px' justifyContent='space-between'>
		<Search
			autoFocus={true}
			placeholder={searchingPlaceHolder}
			value={searchingValue}
			onChange={e =>
				mutate1Object('inventoryReception', {
					[searching]: e.target.value,
				})
			}
		/>
		<IconButton
			type='plus'
			margin='0 20px'
			boxShadow='3px 2px .5rem white'
			specialHoverable={true}
		/>
	</Wrapper>
);

export default function InventoryReceptionDesktop(props) {
	const theme = useTheme();
	const {
		// WAREHOUSES
		warehouseZones,
		warehouseAreas,
		warehousePackages,
		inventoryReception,
		transactions,
		mutate1Object,
	} = props;

	// Filter Areas
	const filteredAreas = filterAreas(inventoryReception, warehouseAreas);

	// Filter Packages
	const filteredPackages = filterPackages(
		inventoryReception,
		warehousePackages,
	);

	// Set default selected data
	// Objective: Select the first record of the zones and areas
	useSetDefaultsData(
		warehouseZones,
		filteredAreas,
		inventoryReception,
		mutate1Object,
	);

	function getSections() {
		return [
			{
				key: 1,
				cardId: 'warehouseZoneCard',
				title: 'Zonas',
				searching: 'zoneSearching',
				searchingPlaceHolder: 'Escriba la zona',
				searchingValue: inventoryReception.zoneSearching,
				dataLoading: warehouseZones.loading,
				loadingLabel: 'Obteniendo zonas...',
				data: warehouseZones.data,
			},
			{
				key: 2,
				cardId: 'warehouseAreaCard',
				title: 'Areas',
				searching: 'areaSearching',
				searchingPlaceHolder: 'Escriba el área',
				searchingValue: inventoryReception.areaSearching,
				dataLoading: warehouseAreas.loading,
				loadingLabel: 'Obteniendo áreas...',
				data: filteredAreas,
			},
			{
				key: 3,
				cardId: 'packageCard',
				title: 'Paquetes',
				searching: 'packageSearching',
				searchingPlaceHolder: 'Buscar paquete...',
				searchingValue: inventoryReception.packageSearching,
				dataLoading: warehousePackages.loading,
				loadingLabel: 'Obteniendo paquetes...',
				data: filteredPackages,
			},
		];
	}

	function onChangeCollapse(panelKey, searching) {
		const collapseKey = panelKey || -1;
		const searchValue =
			collapseKey === 1
				? inventoryReception.zoneSearching
				: collapseKey === 2
				? inventoryReception.areaSearching
				: undefined;
		mutate1Object('inventoryReception', {
			collapseActiveKey: collapseKey,
			[searching]: collapseKey === -1 ? undefined : searchValue,
		});
	}

	return (
		<Wrapper width='100%' padding='0px' flexDirection='column'>
			{isScanMode(inventoryReception.modeView) ? (
				<Suspense fallback={null}>
					<ScanSeriesView
						inventoryReception={inventoryReception}
						transactionItems={transactions.items}
						mutate1Object={mutate1Object}
						theme={theme}
					/>
				</Suspense>
			) : (
				getSections().map(
					({
						key,
						cardId,
						title,
						searching,
						searchingPlaceHolder,
						searchingValue,
						dataLoading,
						loadingLabel,
						data,
					}) => (
						<Collapse
							style={{ height: '100%' }}
							key={key}
							accordion
							bordered={false}
							defaultActiveKey={['-1']}
							activeKey={[inventoryReception.collapseActiveKey]}
							onChange={panelKey => onChangeCollapse(panelKey, searching)}
						>
							<Panel
								key={key}
								header={<PanelHeaderTitle title={title} theme={theme} />}
							>
								<Wrapper padding='0' flexDirection='column'>
									<FindInData
										searchingPlaceHolder={searchingPlaceHolder}
										searchingValue={searchingValue}
										searching={searching}
										mutate1Object={mutate1Object}
									/>
									<Spin
										spinning={dataLoading}
										size='small'
										delay={50}
										tip={loadingLabel}
									>
										<Suspense fallback={null}>
											<ArticlePathView
												data={data}
												cardId={cardId}
												inventoryReception={inventoryReception}
												mutate1Object={mutate1Object}
											/>
										</Suspense>
									</Spin>
								</Wrapper>
							</Panel>
						</Collapse>
					),
				)
			)}
		</Wrapper>
	);
}
