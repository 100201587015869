// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
//  Utils
import GENERAL from 'utils/constants/general';
import {
	filterStockArticlesSearchInData,
	getMyEntityActiveUsers,
} from 'utils/libs';
// Components
import { Modal, VirtualizedList } from 'screens/Utils';
import { Wrapper } from 'components';

const { ENV } = GENERAL;

function StockArticlesMobile(props) {
	if (!props.stock.warehouseId || !props.stock.ownerId || !props.control.mode)
		return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSES_MANAGE_MOBILE} />;

	const {
		control,
		stock,
		stockArticlesManage,
		getStockSeries,
		mutate1ObjectApi,
		mutate1Object,
		completeAdjust,
		sendTransferTransaction,
		profile,
	} = props;
	const users = getMyEntityActiveUsers(props.users.data, profile);

	let filteredStockItems = [...stock.articles.data];
	if (stockArticlesManage.mode === 'adjust')
		filteredStockItems = filteredStockItems.filter(item => !item.is_serialized);
	filteredStockItems = filterStockArticlesSearchInData(
		filteredStockItems,
		stockArticlesManage.searchInData.searchBy,
		stockArticlesManage.searchInData.data,
	);

	return (
		<Wrapper padding='0'>
			<VirtualizedList
				cardId='stockArticleItemCard'
				interpolatedRowStyle
				listStyle={{
					height: document.documentElement.clientHeight * 0.84,
					width: document.documentElement.clientWidth * 1.05,
				}}
				rowHeight={120}
				spinTitle='Consultando artículos...'
				rows={filteredStockItems}
				customProps={{
					control,
					stock,
					mutate1ObjectApi,
					getStockSeries,
				}}
			/>

			<Modal
				modalId='CompleteAdjustModal'
				title='Completar Ajuste'
				visible={completeAdjust.isOpen}
				onCancel={() => mutate1Object('completeAdjust', { isOpen: false })}
				footer={null}
				isSpinning={completeAdjust.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					stockArticlesManage,
					completeAdjust,
					users,
					ownerId: stock.ownerId,
					warehouseId: stock.warehouseId,
					mutate1ObjectInWarehouses: mutate1Object,
					sendTransferTransaction,
				}}
			/>
		</Wrapper>
	);
}

export default StockArticlesMobile;
