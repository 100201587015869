export function getControlSeriesValidator(request) {
	const { view, mode, states_id, owner_id, entity_id, limit, last_row_id } =
		request;

	let errors = '';

	if (!view || (view !== 'individual' && view !== 'entities')) {
		errors += '[Se debe indicar el tipo de visualización]';
	}

	if (view === 'individual' && !owner_id) {
		errors +=
			'[Si el tipo de visualización es <Individual> la solicitud debe contener el id del propietario]';
	}
	if (view === 'entities' && !entity_id) {
		errors +=
			'[Si el tipo de visualización es <Entidad> la solicitud debe contener el id de la entidad]';
	}
	if (
		!mode ||
		(mode !== 'peps' &&
			mode !== 'installed' &&
			mode !== 'uninstalled' &&
			mode !== 'states')
	) {
		errors += '[Se debe especificar el modo de la solicitud]';
	}
	if (mode === 'states' && (!states_id || states_id.length === 0)) {
		errors +=
			'[Si el modo de la solicitud es <Estados> debe indicar los estados a visualizar]';
	}
	if (mode !== 'peps' && (limit === undefined || typeof limit !== 'number')) {
		errors += '[La solicitud debe incluir la propiedad <Limite>]';
	}
	if (
		mode !== 'peps' &&
		(last_row_id === undefined || typeof last_row_id !== 'number')
	) {
		errors += '[La solicitud debe incluir la propiedad <Last Row>]';
	}

	if (errors === '') return null;
	return errors;
}

export function getPaycutsValidator(request) {
	const { type, last_row_id, limit } = request;
	let errors = '';

	if (!type || (type !== 'payment' && type !== 'invoice')) {
		errors += '[La solicitud debe especificar el tipo de consulta]';
	}
	if (last_row_id === undefined || typeof last_row_id !== 'number') {
		errors += '[La solicitud debe incluir la propiedad <Last Row>]';
	}
	if (limit === undefined || typeof limit !== 'number') {
		errors += '[La solicitud debe incluir la propiedad <Límite>]';
	}

	if (errors === '') return null;
	return errors;
}

export function getPaycutsResumeValidator(request) {
	const { paycutIds, excludePayrollUsers } = request;
	let errors = '';

	if (!paycutIds || paycutIds.length === 0) {
		errors += '[La solicitud debe especificar los cortes]';
	}
	if (
		excludePayrollUsers === undefined ||
		typeof excludePayrollUsers !== 'boolean'
	) {
		errors +=
			'[La solicitud debe especificar el valor de la propiedad <Excluir usuarios de planilla>]';
	}

	if (errors === '') return null;
	return errors;
}

export function getPaymentsValidator(paycutIds) {
	let errors = '';

	if (!paycutIds || paycutIds.length === 0) {
		errors += '[La solicitud debe especificar los cortes]';
	}

	if (errors === '') return null;
	return errors;
}

export function getTemplatesValidator(request) {
	const { prop, type } = request;
	let errors = '';

	if ((!prop || prop === '') && !type) {
		errors += '[La solicitud debe tener la propiedad <prop> o <type>]';
	}

	if (errors === '') return null;
	return errors;
}
