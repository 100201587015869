import React from 'react';
import { Wrapper, InBuilding } from 'components';

export default function InventoryReceptionMobile() {
	return (
		<Wrapper padding='0'>
			<InBuilding />
		</Wrapper>
	);
}
