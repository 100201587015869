// Libs
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import axios from 'axios';
// Utils
import asyncErrorsHandler from 'store/asyncErrorsHandler';
import AuthService from 'utils/libs/auth/AuthService';
import GENERAL from 'utils/constants/general';
import API from 'utils/constants/api';
// Actions
import { mutate1Object } from './actions';

const { ANALYTICS } = GENERAL;

// const URL_SERVICE =
//   "https://ucdoecphh2.execute-api.us-east-1.amazonaws.com/dev";
const URL_SERVICE = 'http://localhost:3003';

const auth = new AuthService();

/** **** WATCHER SAGAS *******/
function* getNewOrdersVsBilledWatcher() {
	yield takeLatest(ANALYTICS.GET_NEW_ORDERS_VS_BILLED, getNewOrdersVsBilled);
}

function* getBilledByEntitiesWatcher() {
	yield takeLatest(ANALYTICS.GET_BILLED_BY_ENTITY, getBilledByEntity);
}

function* getChartsWatcher() {
	yield takeLatest(ANALYTICS.GET_CHARTS, getChartsWorker);
}

/** **** WORKER SAGAS *******/
function* getNewOrdersVsBilled(action) {
	// const params = action.payload;
	// try {
	//   const response = yield call(axios.post, `${URL_SERVICE}/events`, params);
	//   yield put({
	//     type: ANALYTICS.MUTATE_1OBJECT,
	//     payload: {
	//       obj1Name: "orders",
	//       keyValuePairs: { newOrdersVsBilled: response.data },
	//     },
	//   });
	// } catch (err) {
	//   yield asyncErrorsHandler(err, undefined, function* () {
	//     yield getNewOrdersVsBilled(action);
	//   });
	// }
}

function* getBilledByEntity(action) {
	const params = action.payload;
	try {
		const response = yield call(axios.post, `${URL_SERVICE}/segment`, params);
		yield put({
			type: ANALYTICS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'orders',
				keyValuePairs: { billedByEntities: [response.data] },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getBilledByEntity(action);
		});
	}
}

function* getChartsWorker(action) {
	const body = action.payload;

	try {
		yield put(
			mutate1Object('chartDashboard', {
				loading: true,
			}),
		);
		const { data } = yield call(
			axios.post,
			API.DOMAIN.concat('/charts'),
			body,
			auth.sendToken(),
		);

		yield put(
			mutate1Object('chartDashboard', {
				charts: data,
				loading: false,
			}),
		);
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getChartsWorker(action);
		});
	}
}

/** **** EXPORT DEFAULT ROOT SAGA *******/
export default function* rootSaga() {
	yield all([
		getNewOrdersVsBilledWatcher(),
		getBilledByEntitiesWatcher(),
		getChartsWatcher(),
	]);
}
