// Libs
import React from 'react';
import { connect } from 'react-redux';
// Actions
import {
	mutate1Object as mutate1ObjectInCore,
	resetDataFilters,
	resetDatasource,
} from 'core/actions';
import { getOrderTargets } from 'components/Modules/actions';
// Selectors
import {
	selectSelectedProjectId,
	selectOrderTargetFilters,
	selectSelectedOrderTargetFilterId,
	selectOrderTargetFiltersIsFetching,
} from 'core/selectors';
// Components
import {
	Wrapper,
	LogoIcon,
	OrderTargetIcon,
	Caption,
	Section,
	SectionTitle,
	Span,
} from 'components';

const styles = {
	container: {
		padding: '0',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
	item: {
		padding: '0',
	},
};

const OrderTargetFilterItem = React.memo(
	({ style, filterItem, selectedOrderTargetFilterId, onClick }) => {
		const { id, name, icon } = filterItem;
		const { type, value } = icon;
		const isSelected = React.useMemo(
			() => id === selectedOrderTargetFilterId,
			[selectedOrderTargetFilterId],
		);

		return (
			<Wrapper {...style}>
				<Wrapper
					padding='0'
					width='250px'
					height='20px'
					justifyContent='stretch'
					alignItems='center'
					overflow='hidden'
					onClick={onClick}
				>
					<OrderTargetIcon value={value} isSelected={isSelected} />
					<Span width='100%' hoverable selected={isSelected}>
						{name}
					</Span>
				</Wrapper>
			</Wrapper>
		);
	},
);

const _OrderTargetFilterPicker = React.memo(
	({
		// Props
		moduleItemId,
		selectedOrderTargetFilterId,
		selectedProjectId,
		isFetching,
		orderTargetFilters,
		// projects,
		getOrderTargets,
		mutate1ObjectInCore,
		resetDataFilters,
		resetDatasource,
	}) => {
		const [seeMore, setSeeMore] = React.useState('hide'); // hide, show, selected

		// Get order targets
		React.useEffect(() => {
			if (!moduleItemId || !selectedProjectId) return;
			getOrderTargets({
				moduleItemId,
				projectId: selectedProjectId,
				onLoading: () => [
					mutate1ObjectInCore('orderTargetFilters', {
						data: [],
						isFetching: true,
						selectedOrderTargetFilterId: undefined,
					}),
				],
				onSuccess: data => [
					// mutate1ObjectInCore("services", {
					//   data: [],
					// }),
					mutate1ObjectInCore('orderTargetFilters', {
						data,
						isFetching: false,
						selectedOrderTargetFilterId:
							data.find(df => !!df?.config?.default)?.id || data[0]?.id,
					}),
				],
			});
		}, [selectedProjectId]);

		// Handle see more
		React.useEffect(() => {
			if (orderTargetFilters.length <= 3) setSeeMore('hide');
			else if (orderTargetFilters.length > 3 && seeMore === 'hide') {
				setSeeMore('show');
			}
		}, [seeMore, orderTargetFilters]);

		// Filter with see more
		const filteredOrderTargetFilters = React.useMemo(
			() =>
				orderTargetFilters.length > 3 && seeMore !== 'selected'
					? orderTargetFilters.filter((p, idx) => idx <= 2)
					: orderTargetFilters,
			[seeMore, orderTargetFilters],
		);

		const containerStyle = React.useMemo(() => styles.container, [isFetching]);

		return (
			<Section>
				<SectionTitle
					title='Línea de Negocio'
					empty={filteredOrderTargetFilters.length === 0}
				/>
				<Wrapper {...containerStyle}>
					{isFetching && <LogoIcon spin={true} />}
					{!isFetching &&
						filteredOrderTargetFilters.map((filterItem, idx) => (
							<div key={idx}>
								<OrderTargetFilterItem
									filterItem={filterItem}
									selectedOrderTargetFilterId={selectedOrderTargetFilterId}
									style={styles.item}
									onClick={() => {
										if (filterItem.id === selectedOrderTargetFilterId) return;
										resetDatasource();
										resetDataFilters();
										mutate1ObjectInCore('services', {
											data: [],
										});
										mutate1ObjectInCore('orderTargetFilters', {
											selectedOrderTargetFilterId: filterItem.id,
										});
									}}
								/>
							</div>
						))}

					{seeMore === 'show' && seeMore !== 'selected' && (
						<Caption
							emphasis
							hoverable
							margin='10px 0 0 0'
							onClick={() => setSeeMore('selected')}
						>
							Ver más
						</Caption>
					)}
				</Wrapper>
			</Section>
		);
	},
);

const mapStateToProps = state => ({
	selectedProjectId: selectSelectedProjectId(state),
	selectedOrderTargetFilterId: selectSelectedOrderTargetFilterId(state),
	isFetching: selectOrderTargetFiltersIsFetching(state),
	orderTargetFilters: selectOrderTargetFilters(state),
});
const actions = {
	getOrderTargets,
	mutate1ObjectInCore,
	resetDataFilters,
	resetDatasource,
};

export default connect(mapStateToProps, actions)(_OrderTargetFilterPicker);
