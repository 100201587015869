// Libs
import formatNumber from '../formatNumber';
// Utils
import exportMultiSheetToExcel from './MultiSheet';

export function exportBillingOrdersToExcel(
	fileName,
	data,
	profile,
	coin_symbol,
	getOrders,
) {
	const amountCellName = getOrders === 'orders_paid' ? 'Cobrado' : 'Precio';

	data = data.reduce((acc, row) => {
		const { price, amount } = row;

		const amountCellValue =
			getOrders === 'orders_paid'
				? formatNumber.new(amount, coin_symbol, 2)
				: formatNumber.new(price.price, price.symbol || '', 2);

		let exportedRow = {
			Orden: row.odt_id,
			Servicio: row.service_name,
			Tecnico: row.expert_name,
			'Código Completado': row.completed_code_name,
			[amountCellName]: amountCellValue,
		};

		if (getOrders === 'orders_paid') {
			const charge = formatNumber.new(price.price, price.symbol || '', 2);
			const difference = formatNumber.new(amount - price.price, coin_symbol, 2);

			exportedRow = {
				...exportedRow,
				Cobrar: charge,
				Diferencia: difference,
				Bono: row.bonus,
				Creado: row.created_at,
				Actualizado: row.updated_at,
			};
		} else if (getOrders === 'orders_notpay') {
			exportedRow = {
				...exportedRow,
				Pagando: row.payment_name,
			};
		}

		acc.push(exportedRow);
		return acc;
	}, []);

	exportMultiSheetToExcel(fileName, [
		{
			name: fileName,
			data,
		},
	]);
}

export function exportPaycutResumeToExcel(
	fileName,
	data,
	coin_symbol,
	profile,
) {
	data = data.reduce((acc, row) => {
		const productionFormatted = formatNumber.new(
			row.production,
			coin_symbol,
			2,
		);
		const chargeFormatted = formatNumber.new(row.charge, coin_symbol, 2);
		const discountsFormatted = formatNumber.new(row.discounts, coin_symbol, 2);
		const subtotalFormatted = formatNumber.new(row.subtotal, coin_symbol, 2);
		const totalTaxes = formatNumber.new(row.subtotal_with_iva, coin_symbol, 2);
		const totalFormatted = formatNumber.new(row.total, coin_symbol, 2);

		const exportedRow = {
			'Nombre Fac': row.payment_name || row.name_billing || row.name,
			Ordenes: row.orders_count,
			'Producción Técnico (Sin IVA)': productionFormatted,
			Descuentos: discountsFormatted,
			Subtotal: subtotalFormatted,
			Impuestos: totalTaxes,
			Total: totalFormatted,
			[`Producción ${profile?.entity?.name} (Sin IVA)`]: chargeFormatted,
		};

		acc.push(exportedRow);
		return acc;
	}, []);

	exportMultiSheetToExcel(fileName, [
		{
			name: fileName,
			data,
		},
	]);
}
