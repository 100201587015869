// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mask0_21958:744'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='4'
			y='2'
			width='16'
			height='20'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14 2L20 8V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H14ZM18 20V9H13V4H6V20H18ZM11.55 9.04C10.15 9.08 10.68 11.54 10.92 12.31C10.918 12.3139 10.9141 12.3307 10.9074 12.3599C10.845 12.6284 10.5377 13.9506 9.23 16.07C9.23 16.07 6.43 17.5 7.1 18.61C7.64 19.5 8.96 18.58 10.09 16.47C10.09 16.47 11.55 15.95 13.5 15.81C13.5 15.81 16.57 17.2 17 15.72C17.4 14.24 14.55 14.57 14.05 14.72C14.05 14.72 12.42 13.65 12.03 12.16C12.03 12.16 12.95 9 11.55 9.04ZM11.57 13.15C11.17 14.45 10.37 15.84 10.37 15.84C11.22 15.5 13.08 15.11 13.08 15.11C12.0899 14.2415 11.6957 13.4107 11.5961 13.2007C11.581 13.1689 11.5726 13.1513 11.57 13.15ZM14.71 15.32C14.71 15.32 16.46 15.97 16.5 15.71C16.57 15.44 15.17 15.2 14.71 15.32ZM9.05 16.81C8.28 17.11 7.54 18.39 7.72 18.39C7.9 18.4 8.63 17.79 9.05 16.81ZM11.5908 11.1591C11.578 11.2168 11.57 11.2532 11.57 11.26C11.56 11.22 11.27 9.5 11.57 9.53C11.9412 9.57317 11.6715 10.794 11.5908 11.1591Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mask0_21958:744)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
