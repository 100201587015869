//Libs
import React, { useContext } from 'react';
//Context
import { PhotoContext } from 'context';
//Components
import { Caption, Checkbox, Subtitle, Wrapper } from 'components';

const PhotoBackupCheck = () => {
	const { isPhotoBackupActive, setSetting } = useContext(PhotoContext);
	return (
		<Checkbox
			checked={isPhotoBackupActive}
			onChange={e => setSetting('isPhotoBackupActive', e.target.checked)}
		>
			<Wrapper padding='0' flexDirection='column' alignItems='flex-start'>
				<Subtitle>Respaldo fotográfico en galería</Subtitle>
				<Caption whiteSpace='wrap'>
					Seleccione esta opción si desea guardar una copia de la foto en
					galería.
				</Caption>
			</Wrapper>
		</Checkbox>
	);
};

export default PhotoBackupCheck;
