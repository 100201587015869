// Libs
import React from 'react';
import { Card } from 'antd';
// Styles
import './StockSerieItemCard.css';

const { Meta } = Card;

export default function StockSerieItemCard(props) {
	const {
		id,
		item_id,
		serie,
		item_name,
		item_code,
		allowed_to_use,
		doc_number,
		allowed_to_use_label,
		state_name,
		state_id,
		warehouse_transaction_doc_id,
		created_at,
		updated_at,
		customProps,
	} = props;
	const { mutate1ObjectApi, getSerieHistory } = customProps;

	return (
		<Meta
			id='stock_serie-meta'
			title={
				<div className='stock_serie-container-title-card'>
					<span className='stock_serie-card-creator'>{item_name}</span>
					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '10px' }}>
							<span className='stock_serie-body-concept-item'>Creado:</span>
							<span className='stock_serie-card-created_at'>{created_at}</span>
						</div>
						<div>
							<span className='stock_serie-body-concept-item'>
								Actualizado:
							</span>
							<span className='stock_serie-card-created_at'>{updated_at}</span>
						</div>
					</div>
				</div>
			}
			description={
				<div className='stock_serie-container-data-input'>
					<div className='stock_serie-data'>
						<div className='stock_serie-body-container-item'>
							<div className='stock_serie-body-concept-item'>
								Código: <b>{item_code}</b>
							</div>
						</div>
						<div className='stock_serie-body-container-item'>
							<div className='stock_serie-body-concept-item'>
								Serie:
								<b
									className='stock_serie-body-value-item-serie'
									onClick={() => {
										mutate1ObjectApi('serieHistory', { isOpen: true });
										getSerieHistory(serie);
									}}
								>
									{' '}
									{serie}
								</b>
							</div>
						</div>
					</div>

					<div className='stock_serie-data'>
						{/* <div className='stock_serie-body-container-item'>
							<div className='stock_serie-body-concept-item'>
								Permitido: <b>{allowed_to_use_label}</b>
							</div>
						</div> */}
						<div className='stock_serie-body-container-item'>
							<div className='stock_serie-body-concept-item'>
								Documento: <b>{doc_number}</b>
							</div>
						</div>
						<div className='stock_serie-body-container-item'>
							<div className='stock_serie-body-concept-item'>
								Estado: <b>{state_name}</b>
							</div>
						</div>
					</div>
				</div>
			}
		/>
	);
}
