// Libs
import React from 'react';
// Components
import { AtomSpinner } from 'react-epic-spinners';
import { Wrapper } from 'components';

const PortraitOrientation = ({ children }) => (
	<Wrapper
		position='absolute'
		bottom='5px'
		left='calc(50% - 30px)'
		padding='0'
		backgroundColor='rgb(0,0,0)'
		borderRadius='50%'
	>
		{children}
	</Wrapper>
);

const LandscapeOrientation = ({ children }) => (
	<Wrapper
		position='absolute'
		top='calc(50% - 30px)'
		right='5px'
		padding='0'
		backgroundColor='rgb(0,0,0)'
		borderRadius='50%'
	>
		{children}
	</Wrapper>
);

const TakePhotoButton = ({ canPlay, takePhoto, orientation }) => {
	const OrientationContainer =
		orientation === 'landscape' ? LandscapeOrientation : PortraitOrientation;

	return (
		<OrientationContainer>
			<AtomSpinner
				color={canPlay ? 'white' : 'black'}
				onClick={canPlay ? takePhoto : null}
			/>
		</OrientationContainer>
	);
};

export default TakePhotoButton;
