// Libs
import React from 'react';
import { Card, Tooltip } from 'antd';
// Utils
import { formatNumber } from '../../../../../../utils/libs';
// Styles
import './PaymentLineResumeCard.css';

const { Meta } = Card;

export default function PaymentLineResumeCard(props) {
	const {
		id,
		line,
		order_id,
		odt_id,
		description,
		amount,
		tracts,
		apply_to_name,
		payment_id,
		action_label,
		creator_name,
		entity_name,
		created_at,
		updated_at,
		customProps,
	} = props;
	const { coinSymbol, orders, getOrder, mutate1ObjectInOrders } = customProps;
	const amountPrice = formatNumber.new(amount, coinSymbol, 2);

	return (
		<Meta
			id='payment_line-resume-card-container'
			title={
				<div className='payment_line-resume-body'>
					<div className='payment_line-resume-title'>
						<div className='payment_line-resume-title-line-creator-container'>
							<Tooltip
								placement='top'
								className='payment_line-resume-title-line'
								title={description}
							>
								<span>{line}</span>
							</Tooltip>
							<div style={{ height: '20px', overflow: 'auto hidden' }}>
								<span className='payment_line-resume-concept-item'>
									Creado por:
								</span>
								<span className='payment_line-resume-concept-value'>
									{creator_name} - {entity_name}
								</span>
							</div>
						</div>

						<div className='payment_line-resume-price-action-container'>
							<span className='payment_line-resume-price'>{amountPrice}</span>
							<span className='payment_line-resume-action'>{action_label}</span>
						</div>
					</div>

					<div className='payment_line-resume-footer'>
						<div>
							<div style={{ height: '18px' }}>
								<span className='payment_line-resume-concept-item'>
									Tracto:
								</span>
								<span className='payment_line-resume-concept-value'>
									{tracts}
								</span>
							</div>
							<div style={{ height: '18px' }}>
								<span className='payment_line-resume-concept-item'>Fecha:</span>
								<span className='payment_line-resume-concept-value'>
									{created_at}
								</span>
							</div>
							<div style={{ height: '18px' }}>
								<span className='payment_line-resume-concept-item'>
									Actualizado:
								</span>
								<span className='payment_line-resume-concept-value'>
									{updated_at}
								</span>
							</div>
							<div>
								<span className='payment_line-resume-concept-item'>Orden:</span>
								{order_id && (
									<span
										className='payment_line-resume-order'
										onClick={() => {
											if (!orders.filter(ord => ord.order_id === order_id)[0])
												getOrder(order_id);
											mutate1ObjectInOrders('getOrderInformationModal', {
												order_id,
												isOpen: true,
											});
										}}
									>
										{odt_id}
									</span>
								)}
							</div>
						</div>
					</div>
				</div>
			}
		/>
	);
}
