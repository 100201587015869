// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='maskDoubleCheckIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='0'
			y='5'
			width='24'
			height='14'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.83 5L11.25 15.58L7.07 11.41L5.66 12.82L11.25 18.41L23.25 6.41L21.83 5ZM17.59 6.41L16.18 5L9.84 11.34L11.25 12.75L17.59 6.41ZM5.59 18.41L0 12.82L1.42 11.41L7 17L5.59 18.41Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#maskDoubleCheckIcon)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
