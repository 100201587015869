// Utils
import GlobalUtils from 'utils/GlobalUtils';

export default class ChartUtils {
	// GETTERS

	// Get keys by chart type
	static getKeys({ type, data, indexedBy }) {
		const chartTypes = {
			BAR: function () {
				const keys = GlobalUtils.checkArray(data).reduce((acc, item) => {
					acc = acc.concat(Object.keys(item));
					return acc;
				}, []);
				return GlobalUtils.excludeArrayKey(
					indexedBy,
					GlobalUtils.removeDuplicates(keys),
				);
			},
			PIE: function () {
				return GlobalUtils.checkArray(data).reduce((acc, item) => {
					acc.push(item.id);
					return acc;
				}, []);
			},
		};
		return chartTypes[type]();
	}

	// Get vertical table rows
	static getVerticalTableRows({ keys, data }) {
		return keys.map(key => ({
			header: key,
			value: data.find(
				point => String(point.id).toLowerCase() === String(key).toLowerCase(),
			)?.value,
		}));
	}

	// Get cross table rows
	static getCrossTableRows({ keys, data, indexedBy }) {
		return data.map(point => {
			const row = {};
			row[indexedBy] = point[indexedBy];
			keys.forEach(key => (row[key] = point[key]));
			return row;
		});
	}

	// Get vertical table columns
	static getVerticalTableColumns() {
		return [
			{
				title: '',
				dataIndex: 'header',
				key: 'header',
			},
			{
				title: '',
				dataIndex: 'value',
				key: 'value',
				align: 'right',
			},
		];
	}

	// Get cross table columns
	static getCrossTableColumns({ keys, indexedBy, width }) {
		const columns = keys
			.filter(key => key !== indexedBy)
			.map(key => ({
				title: key,
				dataIndex: key,
				key,
				align: 'center',
				width: 110,
			}));

		columns.unshift({
			key: indexedBy,
			dataIndex: indexedBy,
			title: '',
			width,
		});

		return columns;
	}

	// Get indexed by from config
	static getIndexedByFromConfig({ config }) {
		return config?.indexedBy;
	}

	// Get vertical table totals
	static getVerticalTableTotals({ pageData }) {
		return pageData.reduce((total, { value }) => {
			const numValue = Number(value);
			if (!isNaN(numValue)) {
				total += numValue;
			}
			return total;
		}, 0);
	}

	// Get cross table totals
	static getCrossTableTotals({ keys, pageData }) {
		return pageData.reduce((totals, row) => {
			keys.forEach(key => {
				if (!totals[key]) totals[key] = 0;
				const numValue = Number(row[key]);
				if (!isNaN(numValue)) {
					totals[key] += numValue;
				}
			});
			return totals;
		}, {});
	}
}
