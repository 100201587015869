// Libs
import React, { useMemo } from 'react';
// Components
import { Wrapper, Span, Tooltip, CloseIcon } from 'components';
import HamburguerIcon from '../../../../../components/Icons/HamburguerIcon';

function MapDrawerToolbar({ onClose, quickNavigation }) {
	return (
		<Wrapper
			mediumBackground
			padding='10px'
			height='56px'
			width='100%'
			justifyContent='space-between'
		>
			<div>
				<HamburguerIcon button onClick={quickNavigation} />
			</div>
			<div>
				<CloseIcon button onClick={onClose} />
			</div>
		</Wrapper>
	);
}

export default MapDrawerToolbar;
