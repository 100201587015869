/**
 * Welcome to ToolbarBuilderConfig
 * Max steps to implement new Toolbar: 3
 */

import KEYWORDS from '../keywords';

/**
 * Step 1: Import custom toolbar
 */
import { PhotoReportToolbar } from 'core/ElementViewBuilder/views/PhotoReportViewer/Toolbar';
const { ROUTES } = KEYWORDS;

/**
 * Step 2: Add custom toolbar keys
 */
const authorizedToolbarKeys = {
	SUPERVISOR_ELEMENT: `${ROUTES.FEED.PATH}/supervisor/:elementId`,
	// <--HERE
};

/**
 * Step 3: Add custom toolbar key value pairs
 */
const authorizedToolbarComponents = {
	[authorizedToolbarKeys.SUPERVISOR_ELEMENT]: PhotoReportToolbar,
	// <--HERE
};

export default class ToolbarBuilderConfig {
	static getAuthorizedToolbarReactNodes() {
		return authorizedToolbarComponents;
	}
}
