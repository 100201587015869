// Libs
import React, { useCallback, useState } from 'react';
// Utils
import { debounce } from 'utils/libs';
// Hooks
import { useUserApi } from 'hooks';
// Components
import { Span, TextField } from 'components';
import { PointTextIcon } from 'components/Icons';
// Styles
import styles from '../User.module.css';

const { Input } = TextField;

const HeaderItem = ({ concept, value }) => (
	<div className={styles.general_values2}>
		<Span disabled>{concept}</Span>
		<Span>{value}</Span>
	</div>
);

const ContainerItems = ({ props }) => {
	return (
		<div className={styles.general_values__item}>
			{/* TODO Falta Dato */}
			<HeaderItem concept={props.concept1} value={props.value1} />
			{props.point && <PointTextIcon />}
			<HeaderItem concept={props.concept2} value={props.value2} />
		</div>
	);
};

const WorkCode = ({ userId, workCode }) => {
	const { changeWorkCodeWorker } = useUserApi();
	const [codeState, setCodeState] = useState(workCode);

	const onWorkCodeChange = ({ userId, workCode }) => {
		changeWorkCodeWorker({
			userId,
			workCode,
		});
	};
	const debouncedWorkCodeChange = useCallback(
		debounce(callback => callback(), 1500),
		[],
	);

	const onChange = e => {
		let workCode = e.target.value;
		if (workCode && workCode !== '') workCode = workCode.trim();
		setCodeState(workCode);
		debouncedWorkCodeChange(() => onWorkCodeChange({ userId, workCode }));
	};

	return (
		<div className={styles.code}>
			<Span disabled>Código</Span>
			<Input
				id={`input-code${userId}`}
				name='work_code'
				placeholder={codeState}
				value={codeState}
				onChange={onChange}
			/>
		</div>
	);
};

const Body = ({ userId, idc, workCode, birthday, gender }) => {
	return (
		<div className={styles.general_values__list}>
			<ContainerItems
				props={{
					concept1: 'Nac:',
					value1: birthday || 'ND',
					concept2: 'Sexo:',
					value2: gender || 'ND',
					point: true,
				}}
			/>
			<ContainerItems
				props={{
					concept1: 'Cédula:',
					value1: idc || 'ND',
					concept2: 'Residencia:',
					value2: 'ND',
					point: true,
				}}
			/>
			<WorkCode userId={userId} workCode={workCode} />
		</div>
	);
};

export default Body;
