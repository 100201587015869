// Libs
import React, { useState } from 'react';
// Components
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import { Title } from 'components';
// Styles
import styles from './CustomActiveShapePie.module.css';

const renderActiveShape = (values, props) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		percent,
		value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g>
			<text x={cx} y={cy} dy={8} textAnchor='middle' fill='#fff'>
				{payload.name}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill[0]}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill[1]}
			/>
			<path
				d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
				stroke='#fff'
				fill='none'
			/>
			<circle cx={ex} cy={ey} r={2} fill='#fff' stroke='#fff' />
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				textAnchor={textAnchor}
				fill='#fff'
			>
				{`${
					String(values[0]).charAt(0).toUpperCase() + String(values[0]).slice(1)
				} ${value}`}
			</text>
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				dy={18}
				textAnchor={textAnchor}
				fill='#fff'
			>
				{`(Porcentaje ${(percent * 100).toFixed(2)}%)`}
			</text>
		</g>
	);
};

const CustomActiveShapePie = ({ data, config = {}, onClickChartSegment }) => {
	const [state, setState] = useState({ activeIndex: 0 });
	const { width = 500, height = 300, values = ['value'], colors } = config;
	const dataKey = values[0];
	const onPieEnter = (_, index) => {
		setState({ activeIndex: index });
	};

	return (
		<div
			className={styles.customActiveShapePieContainer}
			style={{ width, height }}
		>
			<ResponsiveContainer width='100%' height='100%'>
				<PieChart width={width} height={height}>
					<Pie
						activeIndex={state.activeIndex}
						activeShape={props => renderActiveShape(values, props)}
						data={data}
						cx='50%'
						cy='50%'
						innerRadius={60}
						outerRadius={80}
						fill={colors}
						dataKey={dataKey}
						onMouseEnter={onPieEnter}
						onClick={onClickChartSegment}
					/>
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

export default CustomActiveShapePie;
