import React from 'react';
import { Image } from 'components';
import ImageLogo from 'assets/media/img/logo-synapse.svg';

const Logo = ({ position, top, left, opacity }) => (
	<Image
		position={position}
		top={top}
		left={left}
		opacity={opacity}
		width='110px'
		height='110px'
		objectFit='contain'
		src={ImageLogo}
	/>
);

export default Logo;
