// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='smallUserIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='2'
			y='2'
			width='12'
			height='12'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.00008 2.66663C6.52675 2.66663 5.33341 3.85996 5.33341 5.33329C5.33341 6.80663 6.52675 7.99996 8.00008 7.99996C9.47341 7.99996 10.6667 6.80663 10.6667 5.33329C10.6667 3.85996 9.47341 2.66663 8.00008 2.66663ZM9.40008 5.33329C9.40008 4.55996 8.77341 3.93329 8.00008 3.93329C7.22675 3.93329 6.60008 4.55996 6.60008 5.33329C6.60008 6.10663 7.22675 6.73329 8.00008 6.73329C8.77341 6.73329 9.40008 6.10663 9.40008 5.33329ZM12.0667 11.3333C12.0667 10.9066 9.98008 9.93329 8.00008 9.93329C6.02008 9.93329 3.93341 10.9066 3.93341 11.3333V12.0666H12.0667V11.3333ZM2.66675 11.3333C2.66675 9.55996 6.22008 8.66663 8.00008 8.66663C9.78008 8.66663 13.3334 9.55996 13.3334 11.3333V13.3333H2.66675V11.3333Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#smallUserIcon)'>
			<rect width='16' height='16' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
