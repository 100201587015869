// Libs
import React from 'react';
// Components
import {
	// Icons
	LogoIcon,
	RefreshIcon,
	OpenInNewIcon,
	Wrapper,
} from 'components';
// Hooks
import useApi from '../Utils';

const RegenerateMergedPDFReports = ({
	resourceId,
	typeMergeButton,
	isLoading,
	setLoading,
}) => {
	const { onMergePDFReports, onMergeContractPdfReports } = useApi();

	const onMerge = {
		serviceMergedReports: onMergePDFReports,
		contractMergedReports: onMergeContractPdfReports,
	};
	// Handlers
	const handleOnClick = () => {
		setLoading(true);
		onMerge[typeMergeButton](resourceId)
			.then(() => setLoading(false))
			.catch(() => setLoading(false));
	};

	return (
		<Wrapper padding='0' onClick={handleOnClick}>
			<RefreshIcon onlyIcon />
			{isLoading && <LogoIcon spin />}
		</Wrapper>
	);
};

const ViewMergedPDFReports = ({ mergedPdfUrl }) => (
	<Wrapper padding='0' height='100%' onClick={() => window.open(mergedPdfUrl)}>
		<OpenInNewIcon selected />
	</Wrapper>
);

export { RegenerateMergedPDFReports, ViewMergedPDFReports };
