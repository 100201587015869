import styled from 'styled-components';

const CenteredText = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

export default CenteredText;
