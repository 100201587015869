// Libs
import React, { useState } from 'react';
// Components
import {
	// Icons
	ReportIcon,
	LogoIcon,
	// Components
	Wrapper,
	Dropdown,
	Menu,
	Span,
} from 'components';
// CustomComponents;
import {
	RegenerateMergedPDFReports,
	ViewMergedPDFReports,
} from '../ActionButtons';

const useLocalState = () => {
	const [state, _setState] = useState({
		isOpen: false,
	});
	const setState = newState => {
		_setState(current => ({ ...current, ...newState }));
	};
	// State Handlers
	const setIsOpen = newStateOpen => {
		setState({ isOpen: newStateOpen });
	};
	return { state, setIsOpen };
};

const OptionMenu = ({
	resourceId,
	typeMergeButton,
	isLoading,
	mergedPdfUrl,
	setLoading,
}) => {
	return (
		<Menu.Wrapper>
			{isLoading ? (
				<Menu.Item key='LogoIconSpin'>
					<LogoIcon spin />
				</Menu.Item>
			) : (
				<>
					{mergedPdfUrl && (
						<Menu.Item key='viewMergedPDFReports'>
							<ViewMergedPDFReports mergedPdfUrl={mergedPdfUrl} />
						</Menu.Item>
					)}
					<Menu.Item key='regenerateMergedPDFReports'>
						<RegenerateMergedPDFReports
							resourceId={resourceId}
							typeMergeButton={typeMergeButton}
							isLoading={isLoading}
							setLoading={setLoading}
						/>
					</Menu.Item>
				</>
			)}
		</Menu.Wrapper>
	);
};

// Styles
const IconStyle = ({
	resourceId,
	nameButton,
	typeMergeButton,
	isLoading,
	mergedPdfUrl,
	setLoading,
}) => {
	const { state, setIsOpen } = useLocalState();
	return (
		<Dropdown.Wrapper
			open={state.isOpen}
			trigger={['click']}
			onOpenChange={setIsOpen}
			overlay={
				<OptionMenu
					resourceId={resourceId}
					typeMergeButton={typeMergeButton}
					isLoading={isLoading}
					mergedPdfUrl={mergedPdfUrl}
					setLoading={setLoading}
				/>
			}
		>
			<Wrapper padding='0'>
				<ReportIcon selected button />
				{nameButton && <Span margin='0 0 0 10px'>{nameButton}</Span>}
			</Wrapper>
		</Dropdown.Wrapper>
	);
};

export default IconStyle;
