import React, { useEffect, useState, useRef, createRef } from 'react';
import styled from 'styled-components';

// Components
import Column from './Column';
import { Droppable } from 'react-beautiful-dnd';
import { Paddle } from 'components';

const Container = styled.div`
	position: relative;
	background-color: transparent;
	min-height: 40vh;
	/* like display:flex but will allow bleeding over the window width */
	min-width: 100vw;
	max-width: 80vw;
	display: inline-flex;
	overflow-y: hidden;
	overflow-x: auto;
`;

const BoardRows = ({
	zone,
	columns,
	ordered,
	containerHeight,
	withScrollableColumns,
}) => {
	const shouldSplit = ordered.length >= 12;

	const boardIndexes = shouldSplit ? [0, 1] : [0];

	const [showPaddles, setShowPaddles] = useState(
		boardIndexes.map(() => ({
			left: true,
			right: true,
		})),
	);

	const boardRefs = useRef([]);

	useEffect(() => {
		boardRefs.current = boardIndexes.map(
			(_, index) => boardRefs.current[index] ?? createRef(),
		);
	}, [boardIndexes]);

	const switchPaddles = (containerElement, index) => {
		const isAtEndOfcontent =
			containerElement.scrollWidth - containerElement.scrollLeft ===
			containerElement.clientWidth;
		const isAtStartOfContent = containerElement.scrollLeft === 0;

		const isContainerScrollable = !(isAtStartOfContent && isAtEndOfcontent);
		if (!isContainerScrollable) {
			setShowPaddles(prevState => {
				prevState[index] = { left: false, right: false };
				return [...prevState];
			});

			return;
		}

		if (isAtStartOfContent) {
			setShowPaddles(prevState => {
				prevState[index] = { ...prevState[index], left: false };
				return [...prevState];
			});

			return;
		}

		if (isAtEndOfcontent) {
			setShowPaddles(prevState => {
				prevState[index] = { ...prevState[index], right: false };
				return [...prevState];
			});

			return;
		}

		// containerElement scroll is in the middle
		if (!showPaddles[index].right || !showPaddles[index].left)
			setShowPaddles(prevState => {
				prevState[index] = { left: true, right: true };
				return [...prevState];
			});
	};

	const handleOnClickPaddle = (elementRef, direction) => {
		const directionValue = {
			left: -500,
			right: 500,
		};

		elementRef.scrollTo({
			left: elementRef.scrollLeft + directionValue[direction] ?? 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		boardRefs.current.forEach((board, index) => switchPaddles(board, index));
	}, []);

	return (
		<>
			{boardIndexes.map((id, idx) => (
				<Droppable
					key={idx}
					droppableId={`board-${zone.zoneId}-${id}`}
					type='COLUMN'
					direction='horizontal'
					ignoreContainerClipping={Boolean(containerHeight)}
				>
					{provided => (
						<Container>
							<Paddle
								alignment={'left'}
								visible={showPaddles[id].left}
								onClick={() =>
									handleOnClickPaddle(boardRefs.current[idx], 'left')
								}
							>
								{'<'}
							</Paddle>
							<Container
								{...provided.droppableProps}
								ref={ref => {
									provided.innerRef(ref);
									boardRefs.current[idx] = ref;
								}}
								onScroll={() => switchPaddles(boardRefs.current[idx], idx)}
							>
								{ordered.map(
									(key, index) =>
										(!shouldSplit ||
											(shouldSplit &&
												((id === 0 && index < ordered.length / 2) ||
													(id === 1 && index >= ordered.length / 2)))) && (
											<Column
												key={key}
												id={key}
												zoneId={zone.zoneId}
												index={index}
												title={columns[key].assignedTechName}
												counters={{
													left: columns[key].realizableOrderCount,
													right: columns[key].affectationOrderCount,
												}}
												orders={columns[key].orders}
												isScrollable={withScrollableColumns}
											/>
										),
								)}
								{provided.placeholder}
							</Container>
							<Paddle
								alignment={'right'}
								visible={showPaddles[id].right}
								onClick={() =>
									handleOnClickPaddle(boardRefs.current[idx], 'right')
								}
							>
								{'>'}
							</Paddle>
						</Container>
					)}
				</Droppable>
			))}
		</>
	);
};

export default BoardRows;
