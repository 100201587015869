import AbsAuthorization from 'core/Authorization/manager';

export class PaymentAuthorization extends AbsAuthorization {
	constructor(profile) {
		super(profile);
	}
	checkEditOrderAmount() {
		return (
			!!this.roles[this.checkPaymentL2Manager()] 
		);
	}
}

export default PaymentAuthorization;
