// Libs
import React from 'react';
import _ from 'underscore';
import { Card, Button } from 'antd';
// Styles
import './ItemTransactionCard.css';
//  Utils
import GENERAL from '../../../../../utils/constants/general';
import { getArea, getZone } from '../../../../Warehouses/utils';

const { Meta } = Card;
const { DIVISION_ID } = GENERAL;

export default function ItemTransactionCard(props) {
	const {
		id,
		item_id,
		item_code,
		item_name,
		is_serialized,
		amount,
		serie,
		state_name,
		action_label,
		warehouse_package_id,
		created_at,
		updated_at,
		customProps,
	} = props;
	const {
		itemsData,
		transactions,
		itemsTransactionDoc,
		createPackage,
		warehousePackages,
		mutate1ObjectInWarehouses,
		mutate1ObjectApi,
		sendToast,
		warehouseAreas,
		warehouseZones,
		transaction,
		profile,
	} = customProps;
	const idx = _.findIndex(itemsData, item => item.id === id);

	// Delete serie from package
	function onDeleteSerieFromPackage() {
		itemsData[idx].warehouse_package_id = null;
		mutate1ObjectApi('transactions', {
			items: { ...transactions.items, data: itemsData },
		});
	}

	// create non-serialized package
	function onCreateNonSerializedPackage() {
		// Is there an open series package?
		if (createPackage.created && createPackage.serialized)
			sendToast({
				message: 'Primero complete el paquete de series abierto',
				type: 'warn',
			});
		// Was a package already created for the article found?
		const packages = warehousePackages.data.filter(
			pkg => pkg.item_id === item_id,
		);
		if (packages.length === 1) {
			itemsData[idx].warehouse_package_id = packages[0].id;
			mutate1ObjectApi('transactions', {
				items: { ...transactions.items, data: itemsData },
			});
		} else
			mutate1ObjectInWarehouses('createPackage', {
				operation: itemsTransactionDoc.operation,
				isOpen: true,
				serialized: false,
				id: undefined,
				item_id,
				state_id: undefined,
				amount: undefined,
				warehouse_area_id: undefined,
				warehouse_zone_id: undefined,
				created: false,
				mustCreateZone: false,
				mustCreateArea: false,
				zone: undefined,
				area: undefined,
			});
	}

	function onAddSerieToPackage(serie, warehouse_package_id) {
		const data = itemsData.map(item => {
			if (item.serie === serie)
				item.warehouse_package_id = warehouse_package_id;
			return item;
		});
		mutate1ObjectApi('transactions', {
			items: { ...transactions.items, data },
		});
	}

	return (
		<Meta
			id='item_transaction-container-meta'
			title={
				<div className='item_transaction-container-title-card'>
					<div className='item_transaction-container-title-name'>
						<span className='item_transaction-card-creator'>{item_name}</span>

						<div>
							{itemsTransactionDoc.operation === 'reception' ? (
								!is_serialized ? (
									!warehouse_package_id && (
										<Button
											size='small'
											className='item_transaction-button'
											onClick={onCreateNonSerializedPackage}
										>
											Nuevo
										</Button>
									)
								) : warehouse_package_id ? (
									<Button
										size='small'
										className='item_transaction-button'
										style={{ color: 'darkgray' }}
										onClick={onDeleteSerieFromPackage}
									>
										Quitar
									</Button>
								) : (
									createPackage.created &&
									createPackage.serialized && (
										<Button
											size='small'
											className='item_transaction-button'
											onClick={() =>
												onAddSerieToPackage(serie, createPackage.id)
											}
										>
											Agregar
										</Button>
									)
								)
							) : (
								<div />
							)}
						</div>
					</div>

					<div style={{ display: 'flex' }}>
						<div
							style={{ marginRight: '10px', width: '50%', overflow: 'auto' }}
						>
							<span className='item_transaction-body-concept-item'>
								Creado:
							</span>
							<span className='item_transaction-card-created_at'>
								{created_at}
							</span>
						</div>
						<div style={{ width: '50%', overflow: 'auto' }}>
							<span className='item_transaction-body-concept-item'>
								Actualizado:
							</span>
							<span className='item_transaction-card-created_at'>
								{updated_at}
							</span>
						</div>
					</div>
				</div>
			}
			description={
				<div className='item_transaction-container-data-input'>
					<div className='item_transaction-data'>
						{transaction && transaction.transaction_type_id === 28 && (
							<div className='item_transaction-body-container-item'>
								<div className='item_transaction-body-concept-item'>
									Acción: <b>{action_label}</b>
								</div>
							</div>
						)}
						<div className='item_transaction-body-container-item'>
							<div className='item_transaction-body-concept-item'>
								Código: <b>{item_code}</b>
							</div>
						</div>
						{!is_serialized ? (
							<div className='item_transaction-body-container-item'>
								<div className='item_transaction-body-concept-item'>
									Cantidad: <b>{amount}</b>
								</div>
							</div>
						) : (
							<div className='item_transaction-body-container-item'>
								<div className='item_transaction-body-concept-item'>
									Serie: <b>{serie}</b>
								</div>
							</div>
						)}
						{is_serialized && (
							<div className='item_transaction-body-container-item'>
								<div className='item_transaction-body-concept-item'>
									Estado: <b>{state_name}</b>
								</div>
							</div>
						)}
					</div>

					<div className='item_transaction-data'>
						{transaction &&
							transaction.dwh_owner_division_id === DIVISION_ID.ENTITY &&
							transaction.dwh_owner_id === profile.entity.id && (
								<div className='item_transaction-body-container-item'>
									<div className='item_transaction-body-concept-item'>
										Zona:{' '}
										<b>
											{getZone(
												warehouse_package_id,
												warehousePackages.data,
												warehouseAreas.data,
												warehouseZones.data,
											)}
										</b>
									</div>
								</div>
							)}
						{transaction &&
							transaction.dwh_owner_division_id === DIVISION_ID.ENTITY &&
							transaction.dwh_owner_id === profile.entity.id && (
								<div className='item_transaction-body-container-item'>
									<div className='item_transaction-body-concept-item'>
										Area:{' '}
										<b>
											{getArea(
												warehouse_package_id,
												warehousePackages.data,
												warehouseAreas.data,
											)}
										</b>
									</div>
								</div>
							)}
						{transaction &&
							transaction.dwh_owner_division_id === DIVISION_ID.ENTITY &&
							transaction.dwh_owner_id === profile.entity.id && (
								<div className='item_transaction-body-container-item'>
									<div className='item_transaction-body-concept-item'>
										Paquete: <b>{warehouse_package_id}</b>
									</div>
								</div>
							)}
					</div>
				</div>
			}
		/>
	);
}
