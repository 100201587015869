// Libs
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
// Selectors
import { selectEvents } from 'store/api/selectors';
// Components
import { Wrapper, TextField, TimePicker, DatePicker } from 'components';
import Select from 'components/Select';
import { filterOption } from 'utils/libs';
// Styles
import '../../index.css';

const { Option } = Select;
const { TextArea } = TextField;

function SendEventModal({ events, sendEventModal, mutate1Object }) {
	const { eventId, reprogramDate, reprogramTime, comment } = sendEventModal;
	const idx = _.findIndex(events.data, event => event.id === eventId);

	return (
		<Wrapper justifyContent='center'>
			<Wrapper
				width='220px'
				padding='0'
				flexDirection='column'
				alignItems='stretch'
			>
				{/* ORDER EVENT */}
				<Select
					margin='0 0 5px 0'
					showSearch
					value={eventId}
					filterOption={filterOption}
					onChange={value =>
						mutate1Object('sendEventModal', { eventId: value })
					}
				>
					<Option value=''>
						<em>Seleccione</em>
					</Option>
					{events.data.map((event, idx) => (
						<Option key={idx} value={event.id}>
							{event.name}
						</Option>
					))}
				</Select>

				{idx !== -1 && events.data[idx]?.props?.reprogram && (
					<Wrapper
						padding='0'
						margin='0 0 5px 0'
						flexDirection='column'
						alignItems='stretch'
					>
						{/* PROGRAM DATE */}
						<DatePicker
							margin='0 0 5px 0'
							placeholder='Fecha*'
							value={reprogramDate}
							onChange={value =>
								mutate1Object('sendEventModal', { reprogramDate: value })
							}
						/>

						{/* PROGRAM TIME */}
						<TimePicker
							placeholder='Hora*'
							value={reprogramTime}
							onChange={value =>
								mutate1Object('sendEventModal', { reprogramTime: value })
							}
						/>
					</Wrapper>
				)}

				{/* COMMENT */}
				<TextArea
					placeholder='Comentario...'
					rows={3}
					value={comment}
					onChange={e =>
						mutate1Object('sendEventModal', { comment: e.target.value })
					}
				/>
			</Wrapper>
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	events: selectEvents(state),
});
export default connect(mapStateToProps, null)(SendEventModal);
