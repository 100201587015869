// Libs
import mixpanel from 'mixpanel-browser';
// Check production env
const envCheck = process.env.NODE_ENV === 'production';
// Init Mixpanel
if (envCheck) {
	mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
}

export default class MixpanelService {
	static track(name, props) {
		if (envCheck) mixpanel.track(name, props);
	}

	static TYPES = {
		LOG_ROCKET: 'LOG_ROCKET',
	};
}
