// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';

const SaveIcon = ({ button, onClick }) => {
	const theme = useTheme();
	return button ? (
		<IconButton onClick={onClick}>
			<Icon fill={theme.colors.text.high} />
		</IconButton>
	) : (
		<Icon fill={theme.colors.text.high} onClick={onClick} />
	);
};

export default SaveIcon;
