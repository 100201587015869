export default class PhotoUtils {
	static getThumbnail({ src, folder, size }) {
		return src?.replace(`/${folder}/`, `/${folder}/thumbnails/${size}/`);
	}

	static async getRemoteImageResource({
		src,
		thumbnailSrc,
		defaultSrc = '',
		noCached,
	}) {
		function buildNonCachedSrc(path) {
			return noCached ? `${path}?t=${new Date().getMilliseconds()}` : path;
		}
		// Check loaded image
		function checkImage(path) {
			return new Promise(resolve => {
				const img = new Image();
				img.onload = () => resolve('ok');
				img.onerror = () => resolve('error');
				img.src = path;
			});
		}
		let imageSrc = buildNonCachedSrc(thumbnailSrc || src);
		let status = await checkImage(imageSrc);
		if (status === 'error') {
			imageSrc = buildNonCachedSrc(src);
			status = await checkImage(imageSrc);
			if (status === 'error') return defaultSrc;
		}
		return imageSrc;
	}
}
