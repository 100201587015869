// Libs
import Immutable from 'immutable';
import moment from 'moment';
// Utils
import sortBy from 'utils/orderBy';

export default class TableUtils {
	static filter(data, filters) {
		let _data = Immutable.List(data).toJS();
		if (!Object.keys(filters).length) return _data;

		for (const dataKey in filters) {
			const { checkedList } = filters[dataKey];
			const lowerCheckedList = checkedList.map(item => item.toLowerCase());

			_data = _data.filter(row => {
				const rowValue = row[dataKey]?.toString().toLowerCase();
				if (rowValue) return lowerCheckedList.indexOf(rowValue) !== -1;
			});
		}

		return _data;
	}

	/**
	 * The prop <dateFormat> is only required when <type> is "date"
	 * The prop <objectKey> is only required when <type> is "object"
	 * */
	static sort({ data, fields, dataKey, dateFormat, reverse } = {}) {
		if (!fields.length || !dataKey) return data;
		const field = fields.find(field => field.id === dataKey);
		if (!field) return data;

		const type =
			{
				date: 'objectDate',
				datetime: 'objectDate',
			}[field.type] || 'object';

		sortBy(data, type, {
			dateFormat,
			objectKey: dataKey,
		});

		return data;
	}
}

// return {
//   text: data.sort(),
//   numeric: data.sort((a, b) => a - b),
//   date: data.sort((a, b) => moment(a, dateFormat) - moment(b, dateFormat)),
//   objectDate: data.sort(function (a, b) {
//     if (reverse)
//       return (
//         moment(b[objectKey], dateFormat) - moment(a[objectKey], dateFormat)
//       );
//     return (
//       moment(a[objectKey], dateFormat) - moment(b[objectKey], dateFormat)
//     );
//   }),
//   object: data.sort(function (a, b) {
//     if (a[objectKey] > b[objectKey]) return 1;
//     if (a[objectKey] < b[objectKey]) return -1;
//     // a must be equal to b
//     return 0;
//   }),
// }[type];
