export default Object.freeze({
	SEGMENT: {
		ORDERS: 'orders',
		WAREHOUSES: 'warehouses',
		BILLINGS: 'billings',
	},
	PARAMS: {
		PROJECTS: 'projectsParam',
		SERVICES: 'servicesParam',
		FROM_DATE_PARAM: {
			NAME: 'fromDateParam',
			DEFAULT_VALUES: {
				LAST_3_MONTH: 'last3Month',
				LAST_1_MONTH: 'last1Month',
				LAST_2_WEEK: 'last2Week',
				LAST_1_WEEK: 'last1Week',
				START_OF_WEEK: 'startOfWeek',
				START_OF_DAY: 'startOfDay',
			},
		},
	},
});
