// Libs
import React, { useMemo } from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import IconContainer from 'components/IconContainer';
import IconButton from '@mui/material/IconButton';
import MediumIcon from './MediumIcon';
import NormalIcon from './NormalIcon';

const SelectedIcon = ({ size, fill }) => {
	return {
		// large: <LargeIcon fill={fill} />,
		medium: <MediumIcon fill={fill} />,
		normal: <NormalIcon fill={fill} />,
		// small: <SmallIcon fill={fill} />,
	}[size];
};

const SupervisorIcon = ({
	hoverable,
	size = 'normal',
	selected,
	button,
	iconButton,
	onClick,
}) => {
	const { theme } = useTheme();
	const [isHovered, setIsHover] = React.useState(false);
	const fill = useMemo(() => {
		if (hoverable && (isHovered || selected)) return theme._secondaryColor;
		return theme._mediumEmphasisColor;
	}, [isHovered, hoverable, selected, theme]);

	return (
		<div
			onMouseEnter={hoverable ? () => setIsHover(true) : null}
			onMouseLeave={hoverable ? () => setIsHover(false) : null}
		>
			{iconButton ? (
				<IconButton onClick={onClick}>
					<SelectedIcon size={size} fill={fill} />
				</IconButton>
			) : button ? (
				<IconContainer size={size}>
					<SelectedIcon size={size} fill={fill} />
				</IconContainer>
			) : (
				<SelectedIcon size={size} fill={fill} />
			)}
		</div>
	);
};

export default SupervisorIcon;
