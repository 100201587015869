import React from 'react';
import { ResponsivePie } from '@nivo/pie';

const MyResponsivePie = ({ data, theme, config }) => {
	const settedData =
		data.length > 0
			? data
			: [
					{
						id: 'Sin datos que mostrar',
						label: 'Sin datos que mostrar',
						value: 0,
					},
			  ];
	return (
		<ResponsivePie
			theme={theme}
			data={settedData}
			margin={{ top: 40, right: 20, bottom: 40, left: 20 }}
			startAngle={-45}
			innerRadius={0.5}
			padAngle={0.7}
			cornerRadius={3}
			activeOuterRadiusOffset={8}
			borderWidth={1}
			borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
			arcLinkLabelsSkipAngle={config.arcLinkLabelsSkipAngle}
			arcLinkLabelsDiagonalLength={config.arcLinkLabelsDiagonalLength}
			arcLinkLabelsStraightLength={config.arcLinkLabelsStraightLength}
			arcLinkLabelsTextOffset={config.arcLinkLabelsTextOffset}
			// arcLinkLabelsTextColor="#333333"
			arcLinkLabelsThickness={2}
			arcLinkLabelsColor={{ from: 'color' }}
			arcLabelsSkipAngle={config.arcLabelsSkipAngle}
			arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
			defs={config.defs}
			fill={config.fill}
			// no legends, unless specified
			legends={config.legends}
		/>
	);
};

export default React.memo(MyResponsivePie);
