// Libs
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import { lsHandler, isRecordSigned, isPaymentSigned } from '../libs';
// Utils
import { getNameByUserId, capitalize } from '.';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);

const defaultDateFormat = 'YYYY-MM-DD';
const defaultDateTimeFormat = 'YYYY-MM-DD HH:mm';
const defaultTimeFormat = 'HH:mm:ss';

export const toMyTimezone = (utcDatetime, tz) => moment.tz(utcDatetime, tz);

export function toUTC({ date, time, datetime, inputFormat, tz }) {
	let dateFormatted, timeFormatted, dateTimeFormatted, myTimezone;

	if (datetime) {
		dateTimeFormatted = moment(datetime, inputFormat).format(
			defaultDateTimeFormat,
		);
		myTimezone = moment.tz(dateTimeFormatted, tz);
		return myTimezone.utc();
	} else if (date && time) {
		dateFormatted = moment(date, inputFormat).format(defaultDateFormat);
		timeFormatted = moment(time).format(defaultTimeFormat);
		dateTimeFormatted = `${dateFormatted} ${timeFormatted}`;
		myTimezone = moment.tz(dateTimeFormatted, tz);
		return myTimezone.utc();
	}
	return null;
}

export function unifyDatetime(date, time, tz) {
	const dateFormatted = moment(date).format(defaultDateFormat);
	const timeFormatted = moment(time, defaultTimeFormat).format(
		defaultTimeFormat,
	);
	const dateTimeFormatted = `${dateFormatted} ${timeFormatted}`;
	const myTimezone = moment.tz(dateTimeFormatted, tz);

	return myTimezone.format(defaultDateTimeFormat);
}

export function convertNotificationsDates(record, tz, date_format) {
	record.createdAt =
		record.createdAt &&
		toMyTimezone(record.createdAt, tz).format(
			date_format || defaultDateTimeFormat,
		);
	return record;
}

export function convertOrderHistoryDatesToMyTimezone(record, tz, date_format) {
	record.created_at =
		record.created_at &&
		toMyTimezone(record.created_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	if (record.details && record.details.reprogramAt)
		record.details.reprogramAt = toMyTimezone(
			record.details.reprogramAt,
			tz,
		).format(date_format || defaultDateTimeFormat);
	return record;
}

// TODO: si no se va a usar, entonces quitarlo:
// export function convertOrderDatesToMyTimezone(order, tz, date_format) {
//   if (order.execution_at) {
//     order.formatExecutionAt = toMyTimezone(order.execution_at, tz).format(
//       date_format || defaultDateTimeFormat
//     );
//   }
//   if (order.reservation_at) {
//     order.formatReservationAt = toMyTimezone(order.reservation_at, tz).format(
//       date_format || defaultDateTimeFormat
//     );
//   }
//   if (order.program_at) {
//     order.formatProgramAt = toMyTimezone(order.program_at, tz).format(
//       date_format || defaultDateTimeFormat
//     );
//   }
//   if (order.reprogram_at) {
//     order.formatReprogramAt = toMyTimezone(order.reprogram_at, tz).format(
//       date_format || defaultDateTimeFormat
//     );
//   }
//   if (order.last_event_at) {
//     order.formatLastEventAt = toMyTimezone(order.last_event_at, tz).format(
//       date_format || defaultDateTimeFormat
//     );
//   }
//   order.created_at =
//     order.created_at &&
//     toMyTimezone(order.created_at, tz).format(
//       date_format || defaultDateTimeFormat
//     );
//   order.last_att_position_at =
//     order.last_att_position_at &&
//     toMyTimezone(order.last_att_position_at, tz).format(
//       date_format || defaultDateTimeFormat
//     );
//   return order;
// }

export function convertBillingMaterialsDatesToMyTimezone(
	record,
	tz,
	date_format,
) {
	record.wtd_items_created_at =
		record.wtd_items_created_at &&
		toMyTimezone(record.wtd_items_created_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.wtd_items_updated_at =
		record.wtd_items_updated_at &&
		toMyTimezone(record.wtd_items_updated_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.created_at =
		record.created_at &&
		toMyTimezone(record.created_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.updated_at =
		record.updated_at &&
		toMyTimezone(record.updated_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	return record;
}

export function convertWarehousesDatesToMyTimezone(record, tz, date_format) {
	record.created_at =
		record.created_at &&
		toMyTimezone(record.created_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.updated_at =
		record.updated_at &&
		toMyTimezone(record.updated_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	return record;
}

export function formatArticlesData(record, tz, date_format) {
	record.created_at =
		record.created_at &&
		toMyTimezone(record.created_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.updated_at =
		record.updated_at &&
		toMyTimezone(record.updated_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.priceFormatted =
		record.price && `${record.coin_symbol} ${record.price}`;
	record.ivFormatted = record.iv && `${record.iv}%`;
	return record;
}

export function convertTransactionDocsDates(record, tz, date_format) {
	record.created_at =
		record.created_at &&
		toMyTimezone(record.created_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.updated_at =
		record.updated_at &&
		toMyTimezone(record.updated_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	return record;
}

export function convertStockDates(record, tz, date_format) {
	record.created_at =
		record.created_at &&
		toMyTimezone(record.created_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.updated_at =
		record.updated_at &&
		toMyTimezone(record.updated_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	return record;
}

export function convertPaymentsDates(record, tz, date_format) {
	record.created_at =
		record.created_at &&
		toMyTimezone(record.created_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.updated_at =
		record.updated_at &&
		toMyTimezone(record.updated_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.from_date =
		record.from_date &&
		toMyTimezone(record.from_date, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.to_date =
		record.to_date &&
		toMyTimezone(record.to_date, tz).format(
			date_format || defaultDateTimeFormat,
		);
	return record;
}

export function convertUsersDates(record, tz, date_format) {
	record.created_at =
		record.created_at &&
		toMyTimezone(record.created_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.updated_at =
		record.updated_at &&
		toMyTimezone(record.updated_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.application_status_at =
		record.application_status_at &&
		toMyTimezone(record.application_status_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.actived_at =
		record.actived_at &&
		toMyTimezone(record.actived_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	record.last_logged_at =
		record.last_logged_at &&
		toMyTimezone(record.last_logged_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	return record;
}

export function formatData(record, timezone, dateFormat, props = {}) {
	const profile = lsHandler.get('synapse_profile');
	const tz = timezone || profile.organization.tz;
	const date_format = dateFormat || profile.user.settings.date_format;
	const { users = [], isPayments } = props;

	// User
	if (record.created_at)
		record.created_at = toMyTimezone(record.created_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	if (record.updated_at)
		record.updated_at = toMyTimezone(record.updated_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	if (record.application_status_at)
		record.application_status_at = toMyTimezone(
			record.application_status_at,
			tz,
		).format(date_format || defaultDateTimeFormat);
	if (record.actived_at)
		record.actived_at = toMyTimezone(record.actived_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	if (record.last_logged_at)
		record.last_logged_at = toMyTimezone(record.last_logged_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	// Order
	if (record.execution_at)
		record.formatExecutionAt = toMyTimezone(record.execution_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	if (record.reservation_at)
		record.formatReservationAt = toMyTimezone(record.reservation_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	if (record.program_at)
		record.formatProgramAt = toMyTimezone(record.program_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	if (record.reprogram_at)
		record.formatReprogramAt = toMyTimezone(record.reprogram_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	if (record.last_event_at)
		record.formatLastEventAt = toMyTimezone(record.last_event_at, tz).format(
			date_format || defaultDateTimeFormat,
		);
	if (record.last_att_position_at)
		record.last_att_position_at = toMyTimezone(
			record.last_att_position_at,
			tz,
		).format(date_format || defaultDateTimeFormat);
	if (record.client_name)
		record.client_name = capitalize(record.client_name, true);
	if (record.city) record.city = capitalize(record.city, true);
	if (record.township) record.township = capitalize(record.township, true);
	if (record.zone) record.zone = capitalize(record.zone, true);
	if (record.address) record.address = capitalize(record.address, true);
	// Billings
	if (record.from_date)
		record.from_date = toUTC({ datetime: record.from_date, tz }).format(
			defaultDateFormat,
		);
	if (record.to_date)
		record.to_date = toUTC({ datetime: record.to_date, tz }).format(
			defaultDateFormat,
		);
	if (record.paycut_from_date)
		record.paycut_from_date = toUTC({
			datetime: record.paycut_from_date,
			tz,
		}).format(defaultDateFormat);
	if (record.paycut_to_date)
		record.paycut_to_date = toUTC({
			datetime: record.paycut_to_date,
			tz,
		}).format(defaultDateFormat);
	if (record.amount) record.amount = Number(record.amount);
	if (record.bonus) record.bonus = Number(record.bonus);
	if (record.signed || record.signed === null) {
		if (isPayments) {
			record.signed_label = isPaymentSigned(record, profile.user.id)
				? 'Si'
				: 'No';
		} else {
			record.signed_label = isRecordSigned(record.signed, profile.user.id)
				? 'Si'
				: 'No';
		}
	}
	// Order History
	if (record.details && record.details.reprogramAt)
		record.details.reprogramAt = toMyTimezone(
			record.details.reprogramAt,
			tz,
		).format(date_format || defaultDateTimeFormat);
	// Billing Material
	if (record.wtd_items_created_at)
		record.wtd_items_created_at = toMyTimezone(
			record.wtd_items_created_at,
			tz,
		).format(date_format || defaultDateTimeFormat);
	if (record.wtd_items_updated_at)
		record.wtd_items_updated_at = toMyTimezone(
			record.wtd_items_updated_at,
			tz,
		).format(date_format || defaultDateTimeFormat);
	if (record.comment && record.user_id)
		record.user_name = getNameByUserId(record.user_id, users);
	if (record.comment && record.entity_id)
		record.entity_name = getNameByUserId(record.entity_id, users);
	return record;
}

export function formatDate(value, profile, optionalFormat) {
	dayjs.locale('es');
	try {
		const tz = profile?.organization?.tz;
		const dateFormat =
			optionalFormat ??
			profile?.user?.settings?.date_format ??
			'dddd DD/MM HH:mm';

		return tz
			? dayjs(value).tz(tz).format(dateFormat)
			: dayjs(value).format(dateFormat);
	} catch (err) {
		return '';
	}
}

export function getHumanize({ date, now = dayjs(), locale = 'es' }) {
	const evaluatedDate = dayjs(date);
	return evaluatedDate.isValid()
		? now.locale(locale).to(evaluatedDate)
		: 'No disponible';
}
export function getFormattedDuration({ interval, unit, locale = 'es' }) {
	return dayjs.duration(interval, unit).locale(locale).humanize();
}
