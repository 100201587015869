// Libs
import React from 'react';
// Components
import { LogoIcon } from 'components';

const Updating = ({ className }) => (
	<div className={className}>
		<LogoIcon spin={true} />
	</div>
);

export default Updating;
