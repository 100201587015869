// Libs
import React from 'react';
import styled, { css, useTheme } from 'styled-components';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';
import SmallIcon from './SmallIcon';

const SMALL_SIZE = 'small';

const Button = styled(IconButton)`
	${props =>
		props.shadow &&
		css`
			-webkit-box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
			box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
		`}
`;

const RenderIcon = ({ size, success, onClick }) => {
	const theme = useTheme();
	const fillColor = success
		? theme.colors.success.color
		: theme.colors.text.high;

	if (size === SMALL_SIZE) {
		return <SmallIcon fill={fillColor} onClick={onClick} />;
	}
	return <Icon fill={fillColor} onClick={onClick} />;
};

const DoubleCheckIcon = ({ button, onClick, shadow, size, success }) => {
	if (button) {
		return (
			<Button shadow={shadow} onClick={onClick}>
				<RenderIcon size={size} success={success} />
			</Button>
		);
	}

	return <RenderIcon size={size} onClick={onClick} success={success} />;
};

export default DoubleCheckIcon;
