//Libs
import Immutable from 'immutable';
import { idbHandler, PhotoUtils } from 'utils/libs';
//Utils
import GENERAL from 'utils/constants/general';
import { formatDate } from 'utils/libs/dateFormats';
//Keywords
import COLLECTOR_KEYWORDS from 'components/Collector/keywords';
import CollectorUtils from 'components/Collector/CollectorUtils';

const { ENV } = GENERAL;
const { DUPLICATION } = COLLECTOR_KEYWORDS.COLLECTORS;

export default class UploadResourceUtils {
	//Check is array
	static checkArray(arr) {
		return Array.isArray(arr) ? arr : [];
	}
	//Check success collector values in offline collector values
	static checkNewResourcesInOfflineResources(offlineResource, newResources) {
		//Get new resource object
		const newResource = this.checkArray(newResources).find(r => {
			const newResourceFileprops = this.getCollectorResourceFileProps(
				{ order_id: r.auditOrderId, docId: r.docId },
				r,
				r,
			);
			return (
				r.id === offlineResource.id ||
				newResourceFileprops.id === offlineResource.id
			);
		});

		const checkOfflineResourceCreatedAtIsOlder = () => {
			//Get offline resource created at
			const offlineResourceCreatedAt = new Date(offlineResource?.createdAt);
			//Get new resource created at
			const newResourceCreatedAt = new Date(newResource?.createdAt);
			/**
			 * Si la <fecha de creación del offlineCollectorValue> - <la fecha de creación del newCollectorValue>
			 * es superior a 0, significa que el offlineCollectorValue se creó después que el newCollectorValue,
			 * por lo que seguiremos guardando el offlineCollectorValue
			 */
			return offlineResourceCreatedAt - newResourceCreatedAt > 0;
		};

		/**
		 * Importante
		 * El siguiente return, retornará el offlineCollectorValue para seguirlo
		 * manteniendo almacenado. Los demás serán eliminados.
		 * El offlineCollectorValue sólo se debe retornar si:
		 * 1) Si no viene una versión más nueva en newCollectorValues
		 * 2) Si la fecha de creación del offlineCollectorValue es superior a
		 * la fecha de creación del newCollectorValue.
		 */
		return !newResource || checkOfflineResourceCreatedAtIsOlder();
	}
	//Select and include resource by your fileProps
	static selectResourceByProps(r, fileProps) {
		return r.id === fileProps.id;
	}
	//Filter and exclude resource by your fileProps
	static excludeResourceByProps(r, fileProps) {
		return r.id !== fileProps.id;
	}
	//Combine success collectors
	static async getLastestUpdateResources(newResources) {
		return this.reloadOfflineResources().then(offlineResources =>
			offlineResources.filter(or =>
				this.checkNewResourcesInOfflineResources(or, newResources),
			),
		);
	}
	static async combineResources(newResources, { format } = {}) {
		let _newResources = Immutable.List(newResources).toJS();

		if (format === 'beforeMatch')
			_newResources = this.getFormattedResources(_newResources);

		const latestUpdateResources =
			await this.getLastestUpdateResources(_newResources);

		//The purpose of this formatting is mainly to update the value of the "id" prop
		if (format === 'afterMatch')
			_newResources = this.getFormattedResources(_newResources, ['id']);

		const combinedResources = [
			...latestUpdateResources,
			..._newResources,
		].filter(p => !p.photoDeleted);

		this.mutateOfflineResources(combinedResources);
		return _newResources;
	}
	//Get Photo config
	static getPhotoConfig(config = {}) {
		const {
			ACCEPT,
			GET_COORDS,
			CAPTURE,
			COMPRESS: ZIP,
			VERSION,
		} = ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.KEYS;
		const { STRATEGIES } = ENV.UPLOAD_RESOURCE.COLLECTOR_PHOTO.COMPRESS;

		//Compress config
		const compress = config[ZIP.NAME] ?? {};
		//Active compress?
		const isPicaStrategy = compress[ZIP.STRATEGY] === STRATEGIES.PICA;
		const isPhotonStrategy = compress[ZIP.STRATEGY] === STRATEGIES.PHOTON;
		const isResampleStrategy = compress[ZIP.STRATEGY] === STRATEGIES.RESAMPLE;
		const samplingFilter = compress[ZIP.SAMPLING_FILTER];
		//Width
		const width = compress[ZIP.WIDTH];
		//Height
		const height = compress[ZIP.HEIGHT];
		//CompressFormat
		const compressFormat = compress[ZIP.FORMAT] ?? 'image/jpeg';
		//Accept
		const accept = compress[ACCEPT] ?? 'image/*';
		//Quality
		const quality = compress[ZIP.QUALITY] ?? 0.8;
		//Must get coords
		const getCoords = config[GET_COORDS];
		//Capture
		const capture = config[CAPTURE];
		const version = config[VERSION];

		return {
			width,
			height,
			isPicaStrategy,
			isPhotonStrategy,
			samplingFilter,
			isResampleStrategy,
			compressFormat,
			quality,
			getCoords,
			capture,
			accept,
			version,
		};
	}
	//Get resource object from resrouces array
	static async getResourceFromResources(fileProps) {
		if (!fileProps) return {};
		const resources = await this.reloadOfflineResources();
		return resources.find(r => this.selectResourceByProps(r, fileProps)) ?? {};
	}
	//Get resource index from resources array
	static getResourceIdxFromResources(resources = [], fileProps) {
		if (!fileProps || !fileProps.resourceType) return -1;

		if (
			fileProps.resourceType ===
			ENV.UPLOAD_RESOURCE.RESOURCE_TYPES.COLLECTOR_PHOTO
		) {
			return resources.findIndex(r => this.selectResourceByProps(r, fileProps));
		}
	}
	//Get dynamic and configured api url by resource type validation
	static getDynamicApiUrlByResourceType(resourceType) {
		if (resourceType === ENV.UPLOAD_RESOURCE.RESOURCE_TYPES.COLLECTOR_PHOTO) {
			return `/collector_values/v2/saveAuditedOrderPhoto`;
		}
		return null;
	}
	//Get status of a resource
	static getStatus(status) {
		return {
			isLoading: status === ENV.UPLOAD_RESOURCE.STATUS.LOADING,
			isError: status === ENV.UPLOAD_RESOURCE.STATUS.ERROR,
			isSuccess: status === ENV.UPLOAD_RESOURCE.STATUS.SUCCESS,
		};
	}
	static async getSegmentedResources({
		orderId,
		docId,
		serviceTaskId,
		reviewId,
		groupId,
		subgroupId,
	}) {
		const resources = await this.reloadOfflineResources();
		const checkEquals = (value1, value2) => Number(value1) === Number(value2);

		const checkBase = r =>
			(docId
				? checkEquals(r.docId, docId)
				: checkEquals(r.auditOrderId, orderId)) &&
			checkEquals(r.serviceTaskId, serviceTaskId) &&
			checkEquals(r.reviewId, reviewId);
		const checkGroup = r => checkEquals(r.groupId, groupId);
		const checkSubGroup = r => checkEquals(r.subgroupId, subgroupId);

		if (subgroupId)
			return resources.filter(
				r =>
					checkBase(r) && checkGroup(r) && (!r.subgroupId || checkSubGroup(r)),
			);

		if (groupId)
			return resources.filter(
				r => checkBase(r) && (!r.groupId || checkGroup(r)),
			);

		if (reviewId)
			return resources.filter(r => checkBase(r) && !r.groupId && !r.subgroupId);
	}

	static async getResourcesToSaveInState(segmentData) {
		const segmentedResources = await this.getSegmentedResources(segmentData);
		// return segmentedResources;
		return segmentedResources.map(resource => {
			delete resource.file;
			return resource;
		});
	}
	//Add file resource
	static addFileResource({ file, fileProps, coords }) {
		const _coords = coords || {};
		return {
			file,
			fileProps: {
				...fileProps,
				coords: { ..._coords, createdAt: new Date().toISOString() },
				createdAt: new Date().toISOString(),
			},
		};
	}
	//Get offline resources
	static reloadOfflineResources() {
		return idbHandler.getUploadResources();
	}
	//Remove resource from resources
	static async removeResourceFromResources(fileProps) {
		if (!fileProps) return;
		return this.reloadOfflineResources().then(resources => {
			const newResources = resources.filter(r =>
				this.excludeResourceByProps(r, fileProps),
			);
			this.mutateOfflineResources(newResources);
		});
	}
	//Check unsucess resource
	static checkUnsuccessResource = resource =>
		resource.status !== ENV.UPLOAD_RESOURCE.STATUS.SUCCESS;
	//Get unsuccess resources
	static getUnsuccessResources(resources = []) {
		return resources.filter(r => this.checkUnsuccessResource(r));
	}
	//Get unsuccess resource count from order id
	static async getUnsuccessResourceCountFromOrder(orderId) {
		const resources = await this.reloadOfflineResources();
		return resources
			.filter(r => r.auditOrderId === orderId)
			.filter(r => this.checkUnsuccessResource(r)).length;
	}
	//Get pending resource to upload
	static async getPendingResourceToUpload() {
		const resources = await this.reloadOfflineResources();
		return resources.find(r => this.checkUnsuccessResource(r));
	}
	//Remove success resources
	static async removeSuccessResources() {
		const resources = await this.reloadOfflineResources();
		const unsuccessResources = this.getUnsuccessResources(resources);
		this.mutateOfflineResources(unsuccessResources);
	}
	//Fetch remote image url
	static async getRemoteImageResource({ src, file, noCached }) {
		function buildNonCachedSrc(path) {
			return noCached ? `${path}?t=${new Date().getMilliseconds()}` : path;
		}
		//Check loaded image
		function checkImage(path) {
			return new Promise(resolve => {
				const img = new Image();
				img.onload = () => resolve('ok');
				img.onerror = () => resolve('error');
				img.src = path;
			});
		}

		const thumbnailSrc = PhotoUtils.getThumbnail({
			src,
			folder: 'photoCollectors',
			size: 70,
		});
		let imageSrc = buildNonCachedSrc(thumbnailSrc);
		let status = await checkImage(imageSrc);
		if (status === 'error') {
			imageSrc = buildNonCachedSrc(src);
			status = await checkImage(imageSrc);
			if (status === 'error') return file;
		}
		return imageSrc;
	}
	//Get duplicated photo level Idx
	static getDuplicatedPhotoIdx({
		orderId,
		serviceId,
		serviceTaskId,
		reviewId,
		groupId,
		subgroupId,
		collectorId,
		duplicatedPhotoName,
	}) {
		return `order${orderId}service${serviceId}serviceTask${serviceTaskId}review${reviewId}group${groupId}subgroup${subgroupId}collector${collectorId}photo${duplicatedPhotoName}`;
	}
	//COLLECTOR UTILS
	static getCollectorPhotoIdx(order, photo, collector) {
		return `order${collector.auditOrderId || order.order_id}layoutPhotoId${
			photo.layoutPhotoId
		}photo${photo.photoId || photo.id}collector${
			collector.collectorId || collector.id
		}subgroup${collector.subgroupId}group${collector.groupId}review${
			collector.reviewId
		}serviceTaskId${collector.serviceTaskId}resourceType${
			ENV.UPLOAD_RESOURCE.RESOURCE_TYPES.COLLECTOR_PHOTO
		}`;
	}
	//Get collector resource fileProps object
	static getCollectorResourceFileProps(order, collector, photo) {
		return {
			id: this.getCollectorPhotoIdx(order, photo, collector),
			layoutId: collector.layoutId,
			layoutPhotoId: photo.layoutPhotoId,
			photoId: photo.photoId || photo.id,
			photoName: photo.photoName || photo.name,
			auditOrderId: collector.auditOrderId || order.order_id,
			docId: collector.docId ?? order.docId,
			collectorId: collector.collectorId || collector.id,
			collectorName: collector.collectorName || collector.name,
			subgroupId: collector.subgroupId,
			subgroupName: collector.subgroupName,
			groupId: collector.groupId,
			groupName: collector.groupName,
			reviewId: collector.reviewId,
			reviewName: collector.reviewName,
			serviceTaskId: collector.serviceTaskId,
			serviceId: collector.serviceId,
			templateId: collector.templateId,
			sort: photo.sort,
			rliSort: photo.rliSort || collector.sort,
			required: photo.required,
			resourceType: ENV.UPLOAD_RESOURCE.RESOURCE_TYPES.COLLECTOR_PHOTO,
		};
	}
	//Is there a pending collector resource to complete order auditory?
	static isRequiredCollectorResourcePending(order, collectors, resources) {
		//I'm looking for a required resource that hasn't been completed in <resources>
		return collectors.reduce((acc, collector) => {
			if (!Array.isArray(collector.photos) || !collector.photos.length)
				return acc;

			collector.photos.map(photo => {
				if (!photo.required) return acc;

				const fileProps = this.getCollectorResourceFileProps(
					order,
					collector,
					photo,
				);
				const resourceIdx = this.getResourceIdxFromResources(
					resources,
					fileProps,
				);
				const resource = resources[resourceIdx];
				if (resource?.status === ENV.UPLOAD_RESOURCE.STATUS.SUCCESS) return acc;

				acc = {
					...acc,
					...CollectorUtils.setHighlightCollector({
						...fileProps,
						id: fileProps.collectorId,
					}),
					[`id_${fileProps.id}`]: true,
				};
				return acc;
			});
			return acc;
		}, {});
	}
	//Get formatted resources
	static getFormattedResources(resources, onlyKeys) {
		return resources.map(r => {
			const fileProps = this.getCollectorResourceFileProps(
				{ order_id: r.auditOrderId, docId: r.docId },
				r,
				r,
			);
			if (!onlyKeys)
				return {
					...r,
					...fileProps,
				};
			for (let key of onlyKeys) {
				r[key] = fileProps[key];
			}
			return r;
		});
	}
	static getFormattedResourceFromCollectorValues(order, collectorValues) {
		return collectorValues
			.filter(cv => !!cv.photos?.length)
			.reduce((acc, cv) => {
				const photos = cv.photos.map(photo => {
					const fileProps = this.getCollectorResourceFileProps(
						order,
						cv,
						photo,
					);
					return { ...photo, ...fileProps };
				});
				return [...acc, ...photos];
			}, []);
	}
	//Increment photo sort
	static incrementCollectorPhotoSort(incrementedSortSequence, collectors) {
		return collectors.map(c => {
			c.photos = c.photos.map(p => {
				if (p.sort) p.sort = p.sort + incrementedSortSequence;
				return p;
			});
			return c;
		});
	}
	//Mutate Offline Resources
	static mutateOfflineResources(resources) {
		if (!Array.isArray(resources)) return;
		idbHandler.setUploadResources(resources);
	}
	//Get duplicated photo last names
	static getDuplicatedPhotoLastNames(photos) {
		return photos.reduce((acc, photo) => {
			//Get original element name (without sequencial number)
			const { originalElementName, lastSequenceNumber } =
				CollectorUtils.getCurrentLastElement(
					DUPLICATION.LEVELS.PHOTO,
					photo.name,
					photos,
				);
			if (!originalElementName || !lastSequenceNumber) return acc;

			acc[`${originalElementName} #${lastSequenceNumber}`] = true;
			return acc;
		}, {});
	}
	//Download photo
	static downloadPhoto(filename, base64Image) {
		try {
			var a = document.createElement('a'); //Create <a>
			a.href = base64Image; //Image Base64 Goes here
			a.download = `${filename}.jpg`; //File name Here
			a.click(); //Downloaded file
		} catch (err) {}
	}
	//Download unsuccess photos
	static async downloadUnsuccessResources(profile) {
		return this.reloadOfflineResources()
			.then(resources => this.getUnsuccessResources(resources))
			.then(unsuccessResources =>
				unsuccessResources.forEach(resource => {
					this.downloadPhoto(
						formatDate(resource.createdAt, profile, 'YYYY-MM-DD.HH.mm.ss'),
						resource.file,
					);
				}),
			);
	}
}
