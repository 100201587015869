//  Constants
import GENERAL from '../../utils/constants/general';
import { onApproveCompletedAuthorizationCodeValidator } from './actionsValidators';
const { ORDERS, DASHBOARD } = GENERAL;

//* ******* HANDLERS ***********/
export function onSendOrdersToUserAssignment(
	orders,
	dateFormat,
	validationTemplateId,
	odtField,
	assignedTechField,
	validateFieldsTemplate,
) {
	return {
		type: ORDERS.SEND_ORDERS_TO_USER_ASSIGNMENT,
		payload: {
			orders,
			dateFormat,
			validationTemplateId,
			odtField,
			assignedTechField,
			validateFieldsTemplate,
		},
	};
}

export function onSendImportOrdersToProcessAndSave(
	orders,
	dateFormat,
	validationTemplateId,
) {
	return {
		type: ORDERS.SEND_IMPORT_ORDERS_TO_PROCESS_AND_SAVE,
		payload: { orders, dateFormat, validationTemplateId },
	};
}

export function onResetEnterNewOrders(props) {
	return {
		type: ORDERS.RESEST_ENTER_NEW_ORDERS,
		payload: props,
	};
}

export function onResetOrdersReassignmentModal(props) {
	return {
		type: ORDERS.RESET_ORDERS_REASSIGNMENT_MODAL,
		payload: props,
	};
}

export function mutateDirectProps(keyValuePairs) {
	return {
		type: ORDERS.MUTATE_DIRECT_PROPS,
		payload: keyValuePairs,
	};
}

export function mutate1Object(obj1Name, keyValuePairs) {
	return {
		type: ORDERS.MUTATE_1OBJECT,
		payload: { obj1Name, keyValuePairs },
	};
}

//* ******* GET DATA FROM BACKEND ***********/
export function getPhotoReport(orderId) {
	return {
		type: ORDERS.GET_PHOTO_REPORT,
		payload: orderId,
	};
}

export function getInfoOrdersIncome(getInfoOrdersIncomeModal) {
	const { fromDate, toDate } = getInfoOrdersIncomeModal;

	if (!fromDate || !toDate) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Por favor ingrese el rango de fecha a consultar',
				type: 'error',
			},
		};
	}
	return {
		type: ORDERS.GET_INFO_ORDERS_INCOME,
		payload: getInfoOrdersIncomeModal,
	};
}

export function getCountOrdersToReassignment(assignedTechIds) {
	if (assignedTechIds.length === 0) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: 'No se encontraron candidatos', type: 'error' },
		};
	}
	return {
		type: ORDERS.GET_COUNT_ORDERS_TO_REASSIGNMENT,
		payload: assignedTechIds,
	};
}

export function getControlOrders(getOrdersQueryModal) {
	return {
		type: ORDERS.GET_CONTROL_ORDERS,
		payload: getOrdersQueryModal,
	};
}

export function makeDeepSearchOrder(data) {
	return {
		type: ORDERS.MAKE_DEEP_SEARCH,
		payload: data,
	};
}

export function getOrder(orderId, { onLoading, onSuccess, onError }) {
	return {
		type: ORDERS.GET_ORDER,
		payload: { orderId, onLoading, onSuccess, onError },
	};
}

export function resetOrderInformation() {
	return {
		type: ORDERS.RESET_ORDER_INFORMATION,
	};
}

//* ******* SEND DATA TO BACKEND *************/
export function onSaveOrdersReassignmentToUser(orderIds, userId) {
	return {
		type: ORDERS.SAVE_ORDERS_REASSIGNMENT_TO_USER,
		payload: { orderIds, userId },
	};
}

export const saveOrdersReassignmentToUserV2 = (orderIds, userId) => ({
	type: ORDERS.SAVE_ORDERS_REASSIGNMENT_TO_USER_V2,
	payload: { orderIds, userId },
});

export function sendOrderEvents(selectedOrders, sendEventModal, users, tz) {
	if (sendEventModal.comment === '') {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: 'Por favor escribe un comentario', type: 'warn' },
		};
	}
	return {
		type: ORDERS.SEND_ORDER_EVENTS,
		payload: { selectedOrders, sendEventModal, users, tz },
	};
}

export function saveNewIncomeZones(newIncomeZones) {
	newIncomeZones = newIncomeZones.filter(
		newZone => newZone.distance && newZone.zone_type,
	);

	return {
		type: ORDERS.SEND_NEW_INCOME_ZONES,
		payload: newIncomeZones,
	};
}

export function confirmAutoAssignedOrders(
	pureJsonOrders,
	departmentId,
	dateFormat,
) {
	let ordersWithoutAssignment = false;
	const totalRecords = pureJsonOrders.length;
	const depurateRecords = 0;
	const sentRecords = pureJsonOrders.length;
	const summary = {
		Registros: totalRecords,
		Depurados: depurateRecords,
		Enviados: sentRecords,
	};

	pureJsonOrders.map(order => {
		if (
			order.previewAssignment &&
			(order['Identificador Tecnico'] === '' || order['Técnico'] === 'N/E')
		)
			ordersWithoutAssignment = true;
	});

	if (ordersWithoutAssignment) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Todas las ordenes deben estar asignadas para continuar',
				type: 'error',
			},
		};
	}

	return {
		type: ORDERS.SEND_ORDERS_TO_BACKEND,
		payload: { pureJsonOrders, summary, departmentId, dateFormat },
	};
}

export function updateOrdersSort(updateOrders) {
	if (updateOrders.length <= 0) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Debe seleccionar al menos un técnico',
				type: 'warn',
				duration: 5,
			},
		};
	}

	return {
		type: ORDERS.UPDATE_ORDERS_SORT,
		payload: { updateOrders },
	};
}

export function sendCommentReport(comment, order_id, report) {
	if (comment) comment = comment.trim();

	if (!comment || comment === '') {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: 'Ingresa el comentario', type: 'warn' },
		};
	}

	if (report === '3') report = 'billingReport';
	else if (report === '4') report = 'photoReport';

	return {
		type: ORDERS.SEND_COMMENT_REPORT,
		payload: { comment, order_id, report },
	};
}

export function sendRejectInventoryValidation(idRow) {
	return {
		type: ORDERS.SEND_REJECT_INVENTORY_VALIDATION,
		payload: idRow,
	};
}

export function setOrderProps(order_id, action, props, events) {
	return {
		type: ORDERS.SET_ORDER_PROPS,
		payload: { order_id, action, props, events },
	};
}

export function onOrdersExistsValidate(request, odtField) {
	return {
		type: ORDERS.ORDERS_EXISTS_VALIDATE,
		payload: { request, odtField },
	};
}

export function onCreateOneOrder(payload) {
	return {
		type: ORDERS.CREATE_ONE_ORDER,
		payload,
	};
}

export function revertBill(order_id) {
	return {
		type: ORDERS.REVERT_BILL,
		payload: order_id,
	};
}

export function onUpdateOrderModal(payload) {
	return {
		type: ORDERS.UPDATE_ORDER_IN_MODAL,
		payload,
	};
}

export function approveCompletedAuthorizationCode(payload) {
	const err = onApproveCompletedAuthorizationCodeValidator(payload);
	if (err) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: err,
				type: 'warn',
			},
		};
	}
	return {
		type: ORDERS.APPROVE_COMPLETED_AUTHORIZATION_CODE,
		payload,
	};
}

export const getAllExpertsInfo = payload => ({
	type: ORDERS.GET_ALL_EXPERTS_INFO,
	payload,
});

export const updateOrderLocally = payload => ({
	type: ORDERS.UPDATE_ORDER_IN_CONTROL_ORDERS,
	payload,
});

export const doReset = () => {
	return {
		type: ORDERS.RESET,
	};
};
