// Libs
import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';
import {
	Wrapper,
	Popover,
	Radio,
	Button,
	Span,
	Tooltip,
	Menu,
	Chip,
	FiltersIcon,
	RefreshIcon,
	LogoIcon,
	CloseIcon,
} from 'components';
import { ExcelIcon, PersonIcon } from 'components/Icons';
// Utils
import { OrderUtils } from 'utils';
import { filterOrdersByProjects } from 'utils/filters/Orders/FilterOrdersByProjects';
import { filterOrders } from 'utils/filters/Orders/controlOrders';
import {
	filterOrdersByUserBoAssigned,
	getSpecificUsers,
	getMyEntityActiveUsers,
	filterOrdersCategory,
	formatNumber,
	xlsx,
} from 'utils/libs';
import { getUserAssignmentZoneSegmentsConcatenated } from 'utils/filters/Orders/ordersReassignment';
import GENERAL from 'utils/constants/general';

const { ENV } = GENERAL;

// FUNCTIONS
// Filter cards orders
function filterOrderToExport(control, getOrdersQueryModal, profile) {
	let orders = filterOrdersByProjects(control.filteredProjects, control.orders);
	orders = filterOrders(orders, control.filters);

	if (getOrdersQueryModal.searchTo !== 'order')
		orders = filterOrdersCategory(
			orders,
			getOrdersQueryModal.eventCategoryToSearch,
		);
	if (control.searchInData.data !== '')
		orders = OrderUtils.filterOrdersSearchInData(
			orders,
			control.searchInData.searchBy,
			control.searchInData.data,
			profile.organization.settings.order_prefix,
		);
	if (control.myAssignedZones.selectedZone)
		orders = filterOrdersByUserBoAssigned(
			orders,
			control.myAssignedZones.selectedZone,
		);
	return orders;
}

// JSX COMPONENTS

// GET ORDERS CONTENT MOBILE
function GetOrdersContentMobile({
	control,
	getOrdersQueryModal,
	mutate1ObjectInOrders,
	getControlOrders,
	allProjects,
	profile,
	theme,
	onVisibleChange,
}) {
	return (
		<Menu.Wrapper
			mode='inline'
			style={{ width: 290 }}
			openKeys={control.filterOptionSubmenuOpenKeys}
		>
			{/* FILTERS */}
			<Menu.SubMenu
				key='subFilters'
				title='Filtros'
				maxHeight={`${document.documentElement.clientHeight * 0.5}px`}
				onTitleClick={e => {
					mutate1ObjectInOrders('control', {
						filterOptionSubmenuOpenKeys:
							OrderUtils.addOrRemoveFilteredArrayItem(
								e.key,
								control.filterOptionSubmenuOpenKeys,
							),
					});
				}}
			>
				<Menu.Item
					key='dailyTracking'
					style={
						getOrdersQueryModal.eventCategoryToSearch === 'dailyTracking'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('getOrdersQueryModal', {
							eventCategoryToSearch: 'dailyTracking',
						});
						getControlOrders({
							...getOrdersQueryModal,
							eventCategoryToSearch: 'dailyTracking',
						});
					}}
				>
					Diario
				</Menu.Item>

				<Menu.Item
					key='totalTracking'
					style={
						getOrdersQueryModal.eventCategoryToSearch === 'totalTracking'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('getOrdersQueryModal', {
							eventCategoryToSearch: 'totalTracking',
						});
						getControlOrders({
							...getOrdersQueryModal,
							eventCategoryToSearch: 'totalTracking',
						});
					}}
				>
					Total
				</Menu.Item>

				<Menu.Item
					key='realizable'
					style={
						getOrdersQueryModal.eventCategoryToSearch === 'realizable'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('getOrdersQueryModal', {
							eventCategoryToSearch: 'realizable',
						});
						getControlOrders({
							...getOrdersQueryModal,
							eventCategoryToSearch: 'realizable',
						});
					}}
				>
					Factibles
				</Menu.Item>

				<Menu.Item
					key='warranties'
					style={
						getOrdersQueryModal.eventCategoryToSearch === 'warranties'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('getOrdersQueryModal', {
							eventCategoryToSearch: 'warranties',
						});
						getControlOrders({
							...getOrdersQueryModal,
							eventCategoryToSearch: 'warranties',
						});
					}}
				>
					Garantías
				</Menu.Item>

				<Menu.Item
					key='routing'
					style={
						getOrdersQueryModal.eventCategoryToSearch === 'routing'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('getOrdersQueryModal', {
							eventCategoryToSearch: 'routing',
						});
						getControlOrders({
							...getOrdersQueryModal,
							eventCategoryToSearch: 'routing',
						});
					}}
				>
					Enrutadas
				</Menu.Item>

				<Menu.Item
					key='view-expert-mode'
					style={
						getOrdersQueryModal.eventCategoryToSearch === 'view-expert-mode'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('getOrdersQueryModal', {
							eventCategoryToSearch: 'view-expert-mode',
						});
						getControlOrders({
							...getOrdersQueryModal,
							eventCategoryToSearch: 'view-expert-mode',
						});
					}}
				>
					Técnico
				</Menu.Item>

				<Menu.Item
					key='billed'
					style={
						getOrdersQueryModal.eventCategoryToSearch === 'billed'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('getOrdersQueryModal', {
							eventCategoryToSearch: 'billed',
						});
						getControlOrders({
							...getOrdersQueryModal,
							eventCategoryToSearch: 'billed',
						});
					}}
				>
					Facturadas
				</Menu.Item>

				<Menu.Item
					key='pending-bill'
					style={
						getOrdersQueryModal.eventCategoryToSearch === 'pending-bill'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('getOrdersQueryModal', {
							eventCategoryToSearch: 'pending-bill',
						});
						getControlOrders({
							...getOrdersQueryModal,
							eventCategoryToSearch: 'pending-bill',
						});
					}}
				>
					No Facturadas
				</Menu.Item>

				<Menu.Item
					key='more'
					onClick={() => {
						mutate1ObjectInOrders('getOrdersQueryModal', { isOpen: true });
						onVisibleChange();
					}}
				>
					Más...
				</Menu.Item>
			</Menu.SubMenu>

			{/* VISUALIZATION */}
			<Menu.SubMenu
				key='subVisualization'
				title='Visualización'
				onTitleClick={e => {
					mutate1ObjectInOrders('control', {
						filterOptionSubmenuOpenKeys:
							OrderUtils.addOrRemoveFilteredArrayItem(
								e.key,
								control.filterOptionSubmenuOpenKeys,
							),
					});
				}}
			>
				<Menu.Item
					key='users'
					style={
						control.view === 'users'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('control', { view: 'users' });
					}}
				>
					Usuarios
				</Menu.Item>

				<Menu.Item
					key='events'
					style={
						control.view === 'events'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('control', { view: 'events' });
					}}
				>
					Eventos
				</Menu.Item>

				<Menu.Item
					key='services'
					style={
						control.view === 'services'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('control', { view: 'services' });
					}}
				>
					Servicios
				</Menu.Item>

				<Menu.Item
					key={ENV.DEVICE_TYPE === 'Mobile' ? 'list' : 'table'}
					style={
						(ENV.DEVICE_TYPE === 'Mobile' && control.view === 'list') ||
						(ENV.DEVICE_TYPE === 'Desktop' && control.view === 'table')
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						ENV.DEVICE_TYPE === 'Mobile'
							? mutate1ObjectInOrders('control', { view: 'list' })
							: mutate1ObjectInOrders('control', { view: 'table' });
					}}
				>
					{ENV.DEVICE_TYPE === 'Mobile' ? 'Lista' : 'Tabla'}
				</Menu.Item>
			</Menu.SubMenu>

			{/* PROJECTS */}
			<Menu.SubMenu
				key='subProjects'
				title='Proyectos'
				onTitleClick={e => {
					mutate1ObjectInOrders('control', {
						filterOptionSubmenuOpenKeys:
							OrderUtils.addOrRemoveFilteredArrayItem(
								e.key,
								control.filterOptionSubmenuOpenKeys,
							),
					});
				}}
			>
				{Array.isArray(profile?.user?.assignment?.projects) &&
					profile.user.assignment.projects.map(projectId => {
						const project = OrderUtils.getProject(projectId, allProjects);

						return (
							project && (
								<Menu.Item
									key={projectId}
									style={
										control.filteredProjects.indexOf(projectId) !== -1
											? {
													backgroundColor:
														theme.menu.item.selected.backgroundColor,
											  }
											: undefined
									}
									onClick={() => {
										mutate1ObjectInOrders('control', {
											filteredProjects: OrderUtils.addOrRemoveFilteredArrayItem(
												projectId,
												control.filteredProjects,
											),
										});
									}}
								>
									{project.name}
								</Menu.Item>
							)
						);
					})}
			</Menu.SubMenu>
		</Menu.Wrapper>
	);
}

// GET ORDERS CONTENT DESKTOP
function GetOrdersContentDesktop({
	control,
	theme,
	allProjects,
	profile,
	mutate1ObjectInOrders,
}) {
	return (
		<Menu.Wrapper
			mode='inline'
			style={{ width: 300, overflow: 'hidden' }}
			openKeys={control.filterOptionSubmenuOpenKeys}
		>
			<Menu.Item
				onClick={() =>
					mutate1ObjectInOrders('getOrdersQueryModal', { isOpen: true })
				}
			>
				Historial
			</Menu.Item>

			{/* VISUALIZATION */}
			<Menu.SubMenu
				key='subVisualization'
				title='Visualización'
				onTitleClick={e => {
					mutate1ObjectInOrders('control', {
						filterOptionSubmenuOpenKeys:
							OrderUtils.addOrRemoveFilteredArrayItem(
								e.key,
								control.filterOptionSubmenuOpenKeys,
							),
					});
				}}
			>
				<Menu.Item
					key='users'
					style={
						control.view === 'users'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('control', { view: 'users' });
					}}
				>
					Usuarios
				</Menu.Item>

				<Menu.Item
					key='events'
					style={
						control.view === 'events'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('control', { view: 'events' });
					}}
				>
					Eventos
				</Menu.Item>

				<Menu.Item
					key='services'
					style={
						control.view === 'services'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('control', { view: 'services' });
					}}
				>
					Servicios
				</Menu.Item>

				<Menu.Item
					key='table'
					style={
						control.view === 'table'
							? { backgroundColor: theme.menu.item.selected.backgroundColor }
							: undefined
					}
					onClick={() => {
						mutate1ObjectInOrders('control', { view: 'table' });
					}}
				>
					Tabla
				</Menu.Item>
			</Menu.SubMenu>

			{/* PROJECTS */}
			<Menu.SubMenu
				key='subProjects'
				title='Proyectos'
				onTitleClick={e => {
					mutate1ObjectInOrders('control', {
						filterOptionSubmenuOpenKeys:
							OrderUtils.addOrRemoveFilteredArrayItem(
								e.key,
								control.filterOptionSubmenuOpenKeys,
							),
					});
				}}
			>
				{Array.isArray(profile?.user?.assignment?.projects) &&
					profile.user.assignment.projects.map(projectId => {
						const project = OrderUtils.getProject(projectId, allProjects);

						return (
							project && (
								<Menu.Item
									key={projectId}
									style={
										control.filteredProjects.indexOf(projectId) !== -1
											? {
													backgroundColor:
														theme.menu.item.selected.backgroundColor,
											  }
											: undefined
									}
									onClick={() => {
										mutate1ObjectInOrders('control', {
											filteredProjects: OrderUtils.addOrRemoveFilteredArrayItem(
												projectId,
												control.filteredProjects,
											),
										});
									}}
								>
									{project.name}
								</Menu.Item>
							)
						);
					})}
			</Menu.SubMenu>
		</Menu.Wrapper>
	);
}

// BO ASSIGNMENT
function BoAssignmentContent(props) {
	const { control, users, mutate1ObjectInOrders } = props;
	const theme = useTheme();

	return (
		<Menu.Wrapper mode='inline'>
			<Menu.Item
				style={
					!control.myAssignedZones.selectedZone
						? { backgroundColor: theme.menu.item.selected.backgroundColor }
						: {}
				}
				onClick={() =>
					mutate1ObjectInOrders('control', {
						myAssignedZones: {
							...control.myAssignedZones,
							selectedZone: undefined,
						},
					})
				}
			>
				Todos
			</Menu.Item>

			{control.myAssignedZones.userBoZones.map(userId => {
				const user = users.find(usr => usr.id === userId);
				if (!user) return;
				return (
					<Menu.Item
						key={user.id}
						style={
							control.myAssignedZones.selectedZone === userId
								? {
										backgroundColor: theme.menu.item.selected.backgroundColor,
								  }
								: {}
						}
						onClick={() =>
							mutate1ObjectInOrders('control', {
								myAssignedZones: {
									...control.myAssignedZones,
									selectedZone: user.id,
								},
							})
						}
					>
						{user.name}
					</Menu.Item>
				);
			})}
		</Menu.Wrapper>
	);
}

// GAINS
function Gains({ gains, profile }) {
	return profile?.user?.settings?.orders?.price?.viewGains ? (
		<Chip id='chip' size='small' label={gains} />
	) : (
		<div />
	);
}

// ORDERS CATEGORY BUTTONS
function OrdersCategoryButtons({
	getOrdersQueryModal,
	mutate1ObjectInOrders,
	getControlOrders,
}) {
	return (
		<Radio.Group
			value={getOrdersQueryModal.eventCategoryToSearch}
			onChange={e => {
				mutate1ObjectInOrders('getOrdersQueryModal', {
					eventCategoryToSearch: e.target.value,
				});
				getControlOrders({
					...getOrdersQueryModal,
					eventCategoryToSearch: e.target.value,
				});
			}}
		>
			<Radio.Button value='dailyTracking'>Diario</Radio.Button>
			<Radio.Button value='totalTracking'>Total</Radio.Button>
			<Radio.Button value='realizable'>Factibles</Radio.Button>
			<Radio.Button value='warranties'>Garantías</Radio.Button>
			<Radio.Button value='routing'>Enrutadas</Radio.Button>
			<Radio.Button value='view-expert-mode'>Técnico</Radio.Button>
			<Radio.Button value='billed'>Facturadas</Radio.Button>
			<Radio.Button value='pending-bill'>No Facturadas</Radio.Button>
		</Radio.Group>
	);
}

// CONTROL BUTTONS
function ControlButtonsGroup({
	profile,
	control,
	getOrdersQueryModal,
	users,
	mutate1ObjectInOrders,
	getControlOrders,
	GetOrdersContent,
}) {
	const [showFilterOrders, setShowFilterOrders] = React.useState(false);
	return (
		<Wrapper padding='0'>
			{/* EXPORT EXCEL */}
			{control.subToolbarStyle === 'DESKTOP' && (
				<Tooltip title='Exporte las ordenes a Excel'>
					<ExcelIcon
						button
						onClick={() =>
							xlsx.exportOrdersToExcel(
								getOrdersQueryModal.eventCategoryToSearch,
								filterOrderToExport(control, getOrdersQueryModal, profile),
								profile,
							)
						}
					/>
				</Tooltip>
			)}

			{/* BO ASSIGNMENT */}
			<Popover
				content={
					<BoAssignmentContent
						control={control}
						users={users}
						mutate1ObjectInOrders={mutate1ObjectInOrders}
					/>
				}
				placement='bottom'
				title={null}
				trigger={control.subToolbarStyle === 'DESKTOP' ? 'hover' : 'click'}
				visible={control.myAssignedZones.isOpen}
				onVisibleChange={() =>
					mutate1ObjectInOrders('control', {
						myAssignedZones: {
							...control.myAssignedZones,
							isOpen: !control.myAssignedZones.isOpen,
						},
					})
				}
			>
				<Tooltip
					placement='left'
					title='Filtre las ordenes por su BackOffice asignado'
				>
					<div>
						<PersonIcon button />
					</div>
				</Tooltip>
			</Popover>

			{/* INFO IMPORTED ORDERS */}
			{/* {profile?.entity?.id &&
        profile.entity.id === profile?.organization?.settings?.mainEntity && (
          <Tooltip title="Control de ordenes importadas">
            <IconButton
              type="info-circle"
              onClick={() =>
                mutate1ObjectInOrders("getInfoOrdersIncomeModal", {
                  isOpen: true,
                })
              }
            />
          </Tooltip>
        )} */}

			{/* LOADING ORDERS BUTTON */}
			{!getOrdersQueryModal.loading ? (
				<RefreshIcon onClick={() => getControlOrders(getOrdersQueryModal)} />
			) : (
				<LogoIcon button spin />
			)}

			{/* GET ORDERS */}
			<Popover
				content={
					<GetOrdersContent
						onVisibleChange={() => setShowFilterOrders(!showFilterOrders)}
					/>
				}
				placement='bottomRight'
				title={null}
				trigger='click'
				visible={showFilterOrders}
				onVisibleChange={() => setShowFilterOrders(!showFilterOrders)}
			>
				<div>
					<FiltersIcon button />
				</div>
			</Popover>
		</Wrapper>
	);
}

// MAIN DESKTOP SUBTOOLBAR
function MainDesktopSubtoolbar({
	control,
	getOrdersQueryModal,
	gains,
	users,
	theme,
	mutate1ObjectInOrders,
	getControlOrders,
	allProjects,
	profile,
}) {
	return (
		<Wrapper
			height='48px'
			padding='10px 0 10px 10px'
			justifyContent='space-between'
			mediumBackground
		>
			<OrdersCategoryButtons
				getOrdersQueryModal={getOrdersQueryModal}
				mutate1ObjectInOrders={mutate1ObjectInOrders}
				getControlOrders={getControlOrders}
			/>

			<Gains gains={gains} profile={profile} />

			<ControlButtonsGroup
				profile={profile}
				control={control}
				getOrdersQueryModal={getOrdersQueryModal}
				users={users}
				mutate1ObjectInOrders={mutate1ObjectInOrders}
				getControlOrders={getControlOrders}
				GetOrdersContent={() =>
					GetOrdersContentDesktop({
						control,
						theme,
						allProjects,
						profile,
						mutate1ObjectInOrders,
					})
				}
			/>
		</Wrapper>
	);
}

// MAIN MOBILE SUBTOOLBAR
function MainMobileSubtoolbar({
	control,
	getOrdersQueryModal,
	gains,
	users,
	mutate1ObjectInOrders,
	getControlOrders,
	allProjects,
	profile,
	theme,
}) {
	return (
		<Wrapper
			height='48px'
			padding='10px 0 10px 10px'
			justifyContent='space-between'
			mediumBackground
		>
			<Gains gains={gains} profile={profile} />

			<ControlButtonsGroup
				profile={profile}
				control={control}
				getOrdersQueryModal={getOrdersQueryModal}
				users={users}
				mutate1ObjectInOrders={mutate1ObjectInOrders}
				getControlOrders={getControlOrders}
				GetOrdersContent={({ onVisibleChange }) =>
					GetOrdersContentMobile({
						control,
						getOrdersQueryModal,
						mutate1ObjectInOrders,
						getControlOrders,
						allProjects,
						profile,
						theme,
						onVisibleChange,
					})
				}
			/>
		</Wrapper>
	);
}

// ORDERS SELECTED SUBTOOLBAR
function OrdersSelectedSubtoolbar({ control, mutate1ObjectInOrders }) {
	const theme = useTheme();
	return (
		<Wrapper height='48px' justifyContent='space-between' mediumBackground>
			{/* ORDERS SELECTED */}
			<div>
				<CloseIcon
					button
					onClick={() =>
						mutate1ObjectInOrders('control', { selectedOrders: [] })
					}
				/>
				<Span
					fontWeight='bold'
					fontSize='xl'
					color={theme.fonts.colors.default}
				>
					{control.subToolbarStyle === 'DESKTOP'
						? `Seleccionadas: ${control.selectedOrders.length}`
						: `${control.selectedOrders.length} Orden(es)`}
				</Span>
			</div>

			{/* ACTION CONTROL BUTTONS */}
			<div>
				<Button
					fontSize='l'
					margin='0 10px 0 0'
					color='white'
					onClick={() => {
						mutate1ObjectInOrders('ordersReassignmentModal', {
							isOpen: true,
							assignment: 'absolute',
							filterUsers: getUserAssignmentZoneSegmentsConcatenated(
								control.selectedOrders,
							),
						});
					}}
				>
					Reasignar
				</Button>
				<Button
					fontSize='l'
					color='white'
					onClick={() =>
						mutate1ObjectInOrders('sendEventModal', { isOpen: true })
					}
				>
					Evento
				</Button>
			</div>
		</Wrapper>
	);
}

export default function Manage(props) {
	const {
		users,
		orders,
		mutate1ObjectInOrders,
		getControlOrders,
		allProjects,
		profile,
	} = props;
	const theme = useTheme();
	const { control, getOrdersQueryModal } = orders;
	const gains = formatNumber.new(
		Number(props.gains.gain_sales) + Number(props.gains.gain_orders),
		props.gains.coin_symbol,
		2,
	);

	function setSubToolbarStyle() {
		if (window.innerWidth > 1100) {
			mutate1ObjectInOrders('control', {
				subToolbarStyle: 'DESKTOP',
			});
		} else if (window.innerWidth <= 1100) {
			mutate1ObjectInOrders('control', {
				subToolbarStyle: 'MOBILE',
			});
		}
	}

	useEffect(() => {
		setSubToolbarStyle();
		window.onresize = () => {
			setSubToolbarStyle();
		};
	}, []);

	if (control.selectedOrders.length > 0) {
		return (
			<OrdersSelectedSubtoolbar
				control={control}
				mutate1ObjectInOrders={mutate1ObjectInOrders}
			/>
		);
	} else if (control.subToolbarStyle === 'DESKTOP') {
		return (
			<MainDesktopSubtoolbar
				control={control}
				getOrdersQueryModal={getOrdersQueryModal}
				gains={gains}
				users={users}
				allProjects={allProjects}
				profile={profile}
				theme={theme}
				mutate1ObjectInOrders={mutate1ObjectInOrders}
				getControlOrders={getControlOrders}
			/>
		);
	} else {
		return (
			<MainMobileSubtoolbar
				control={control}
				getOrdersQueryModal={getOrdersQueryModal}
				gains={gains}
				users={users}
				allProjects={allProjects}
				profile={profile}
				theme={theme}
				mutate1ObjectInOrders={mutate1ObjectInOrders}
				getControlOrders={getControlOrders}
			/>
		);
	}
}
