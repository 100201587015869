import { Cascader } from 'antd';
import styled from 'styled-components';

const CascaderStyled = styled(Cascader)`
	&.ant-cascader-picker {
		background: transparent;
	}
`;

export default CascaderStyled;
