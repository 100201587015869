// Components
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from 'recharts';
// Styles
import styles from './StackedArea.module.css';

/**
 * See data structure example:
 * https://recharts.org/en-US/examples/StackedAreaChart
 */

const StackedArea = ({ data, config = {} }) => {
	const {
		width = 500,
		height = 400,
		xDataKey = 'name',
		values = ['amount', 'px'],
		stroke = ['#8884d8', '#82ca9d', '#ffc658'],
		fill = ['#8884d8', '#82ca9d', '#ffc658'],
	} = config;

	return (
		<div className={styles.stackedAreaContainer} style={{ width, height }}>
			<ResponsiveContainer width='100%' height='100%'>
				<AreaChart
					width={width}
					height={height}
					data={data}
					margin={{
						top: 10,
						right: 30,
						left: 0,
						bottom: 0,
					}}
				>
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis dataKey={xDataKey} />
					<YAxis />
					<Tooltip />
					{values.map((vl, index) => (
						<Area
							type='monotone'
							dataKey={vl}
							stackId='1'
							stroke={stroke[index]}
							fill={fill[index]}
						/>
					))}
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
};

export default StackedArea;
