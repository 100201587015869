// Libs
import React from 'react';
// Components
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
// Styles
import styles from './StraightAnglePie.module.css';

const StraightAnglePie = ({ data, config = {}, onClickChartSegment }) => {
	const {
		width = 400,
		height = 400,
		fill = '#8884d8',
		values = ['value'],
	} = config;
	const dataKey = values[0];

	return (
		<div className={styles.straightAnglePieContainer} style={{ width, height }}>
			<ResponsiveContainer width='100%' height='100%'>
				<PieChart width={width} height={height}>
					<Pie
						dataKey={dataKey}
						startAngle={180}
						endAngle={0}
						data={data}
						cx='50%'
						cy='50%'
						outerRadius={80}
						fill={fill}
						label
						onClick={onClickChartSegment}
					/>
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

export default StraightAnglePie;
