// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='maskBackIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='4'
			y='4'
			width='16'
			height='16'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12 20L13.41 18.59L7.83 13L20 13L20 11L7.83 11L13.42 5.42L12 4L4 12L12 20Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#maskBackIcon)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
