import React from 'react';

// Components
import { CreatedBy } from 'components';
import { Skeleton } from 'components';
import ActionsBox from 'core/ElementBuilder/elements/Supervisor/ActionsBox';

import styles from './CollectorPhoto.module.css';
import { useTheme } from 'hooks';

const CollectorPhotoSkeleton = ({ active = false }) => {
	const { theme } = useTheme();
	return (
		<div
			style={{ backgroundColor: theme._secondaryBackgroundColor }}
			className={styles.mainWrapper}
		>
			<div className={styles.top}>
				<div className={styles.nameWrapper}>
					<Skeleton
						active={active}
						title={{ width: '100px', style: { margin: 0 } }}
						paragraph={false}
					/>
				</div>
			</div>
			<div className={styles.content}>
				<Skeleton.Image
					className={styles.preview}
					style={{ height: '100%', width: '100%' }}
				/>
			</div>
			<div className={styles.bottom}>
				<CreatedBy.Skeleton active={active} />
				<div className={styles.actionsWrapper}>
					<ActionsBox.Skeleton active={active} justButtons={true} />
				</div>
			</div>
		</div>
	);
};

export default CollectorPhotoSkeleton;
