// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Utils
import {
	checkDayWord,
	checkAddZero,
	checkExpiredTime,
	calculateDifference,
} from './utils';
import { getHumanize } from 'utils/libs/dateFormats';
// Components
import { Span } from 'components';

const NOT_CONFIGURED = 'No configurado';
const EXPIRED = 'Agotado';

const UnAvailableTime = ({ expired }) => (
	<Span size='m' color='mediumaquamarine'>
		{expired ? EXPIRED : NOT_CONFIGURED}
	</Span>
);

const AvailableTime = ({ value }) => {
	const theme = useTheme();
	return (
		<Span size='m' color={theme.colors.text.high}>
			{value}
		</Span>
	);
};

const TimeRemaining = ({ time = {}, humanize }) => {
	const { timeRemaining, expirationAt } = time;
	const expired = checkExpiredTime(timeRemaining);

	if (Object.keys(time).length === 0 || expired) {
		return <UnAvailableTime expired={expired} />;
	}

	if (expirationAt && humanize) {
		return <AvailableTime value={getHumanize({ date: expirationAt })} />;
	}

	const calculateDiff = calculateDifference(timeRemaining);
	const { days, hours, minutes, seconds } = calculateDiff;
	const dayWord = checkDayWord(days);
	return (
		<AvailableTime
			value={`${dayWord} ${checkAddZero(hours)}:${checkAddZero(
				minutes,
			)}:${checkAddZero(seconds)}`}
		/>
	);
};

export default TimeRemaining;
