// Libs
import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
// Contexts
import { ModalBuilderContext } from 'core/context';
// Hooks
import useCollectorManager from 'hooks/useCollectorManager';
// Components
import {
	Button,
	InputNumber,
	Modal,
	DatePicker,
	TimePicker,
	Wrapper,
	Tooltip,
	LogoIcon,
} from 'components';

const ChangePhotoCoords = () => {
	const dispatch = useDispatch();
	const { updatePhotoCoords } = useCollectorManager();
	const { payload, cleanModal } = useContext(ModalBuilderContext);

	const [state, setState] = useState(() => ({
		latitude: payload.latitude,
		longitude: payload.longitude,
		createdAt: payload.updatedAt,
		isFetching: false,
	}));

	const onChangeValue = (input, value) =>
		setState(current => ({ ...current, [input]: value }));

	const onConfirm = () => {
		onChangeValue('isFetching', true);
		updatePhotoCoords({
			photoId: payload.photoId,
			latitude: state.latitude,
			longitude: state.longitude,
			createdAt: state.createdAt,
			otdId: payload.otdId,
			serviceTaskId: payload.serviceTaskId,
		})
			.then(() => {
				payload.onSuccess();
				dispatch(
					sendToast({
						message: 'Coordenadas actualizadas!',
						type: 'success',
					}),
				);
				cleanModal();
			})
			.catch(err => {
				onChangeValue('isFetching', false);
				dispatch(
					sendToast({
						message:
							err.message || 'Hubo un problema. Por favor inténtelo más tarde',
						type: 'error',
					}),
				);
			});
	};

	return (
		<Modal
			title={`Cambiar datos de la foto: ${payload.photoName}`}
			visible={!payload.closing}
			centered={false}
			onCancel={cleanModal}
			footer={null}
		>
			<Wrapper display='flex' flexDirection='column'>
				<Wrapper
					display='flex'
					flexDirection='column'
					padding='0px 0px 16px 0px '
					justifyContent='space-between'
				>
					<Tooltip title='Latitud' placement='right'>
						<InputNumber
							margin='0 0 16px 0'
							placeholder='Latitud'
							value={state.latitude}
							onChange={value => onChangeValue('latitude', value)}
						/>
					</Tooltip>
					<Tooltip title='Longitud' placement='right'>
						<InputNumber
							placeholder='Longitud'
							value={state.longitude}
							onChange={value => onChangeValue('longitude', value)}
						/>
					</Tooltip>
					<Wrapper display='flex' justifyContent='space-between'>
						<DatePicker
							width='100%'
							allowClear={false}
							placeholder={'Fecha'}
							value={moment(state.createdAt)}
							onChange={date => onChangeValue('createdAt', date.utc().format())}
						/>
						<TimePicker
							width='100%'
							allowClear={false}
							placeholder={'Hora'}
							value={moment(state.createdAt)}
							onChange={date => onChangeValue('createdAt', date.utc().format())}
						/>
					</Wrapper>
				</Wrapper>
				{state.isFetching ? (
					<LogoIcon spin />
				) : (
					<Button margin='0 0 16px 0' onClick={onConfirm}>
						Comfirmar
					</Button>
				)}
			</Wrapper>
		</Modal>
	);
};

export default ChangePhotoCoords;
