// Libs
import React, { useState, useEffect, useContext } from 'react';
// Context
import { ModalBuilderContext } from 'core/context';
// Hooks
import { useCommentManager } from 'hooks';
// Components
import { CommentIcon, Wrapper, AntButton, Badge } from 'components';
import { ModalBuilderConfig } from 'core/ModalBuilder';

const Comments = ({
	autonomous,
	resourceKey,
	resourceId,
	commentCount,
	onClickCommentsButton,
}) => {
	const { getCommentCount } = useCommentManager();
	const [_commentCount, setCommetCount] = useState(commentCount);

	const { setModal } = useContext(ModalBuilderContext);
	const modalKeys = ModalBuilderConfig.getAuthorizedModalKeys();

	const handleOnClickCommentsButton = () =>
		setModal(modalKeys.orderExpandedModal, {
			selectedOTDId: resourceId,
		});

	// Get comments count
	useEffect(() => {
		let isMounted = true;
		if (!autonomous) return;
		getCommentCount({ resourceKey, resourceId }).then(
			count => isMounted && setCommetCount(Number(count)),
		);
		return () => {
			isMounted = false;
		};
	}, [resourceId, resourceKey]);

	return (
		<AntButton
			onClick={
				onClickCommentsButton
					? onClickCommentsButton
					: handleOnClickCommentsButton
			}
			type='link'
		>
			<Wrapper position='relative' padding='0' justifyContent='center'>
				<Badge value={_commentCount}>
					<CommentIcon />
				</Badge>
			</Wrapper>
		</AntButton>
	);
};

export default Comments;
