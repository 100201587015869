// Libs
import React from 'react';
import { connect } from 'react-redux';
// Hooks
import { useClientRect } from 'hooks';
// Utils
import ModuleUtils from '../utils';
// Actions
import {
	mutate1ObjectInMarketplace,
	getMarketBanners,
	getMarketModules,
	getMarketItems,
	updateMarketItemInstallationStatus,
} from '../actions';
// Selectors
import {
	selectMarketplaceBanners,
	selectMarketplaceItems,
	selectMarketplaceModules,
} from '../selectors';
// Components
import { Wrapper } from 'components';
import AdvertisingBanners from './AdvertisingBanners';
import Categories from './Categories';
import ListItems from './ListItems';
import UpdateStatusMarketItem from './UpdateStatusMarketItem';
import { selectModeView } from 'screens/Dashboard/selectors';

// updateMarketItemInstallationStatus({
//   ...marketItemUpdateStatus,
//   marketItemId,
//   newStatus,
// });

const Marketplace = ({
	// State
	modeView,
	marketplaceBanners,
	marketplaceModules,
	marketplaceItems,
	// Actions
	mutate1ObjectInMarketplace,
	getMarketBanners,
	getMarketModules,
	getMarketItems,
	updateMarketItemInstallationStatus,
}) => {
	const [rect, ref] = useClientRect();
	const [isOpenMarketplaceUpdater, setIsOpenMarketplaceUpdater] =
		React.useState(false);
	const [marketItemUpdateStatus, setMarketItemStatus] = React.useState({
		marketItemId: undefined,
		newStatus: undefined,
	});
	const mutateMarketItemStatus = newState =>
		setMarketItemStatus({ ...marketItemUpdateStatus, ...newState });

	const selectedMarketplaceItem = React.useMemo(
		() =>
			marketplaceItems.data.find(
				i => i.id === marketItemUpdateStatus.marketItemId,
			),
		[marketItemUpdateStatus.marketItemId, marketplaceItems.data],
	);

	const banners = React.useMemo(
		() =>
			isOpenMarketplaceUpdater
				? selectedMarketplaceItem?.photos?.map(photo => ({ src: photo })) ?? []
				: marketplaceBanners.data,
		[
			isOpenMarketplaceUpdater,
			selectedMarketplaceItem,
			marketplaceBanners.data,
		],
	);

	// Handlers
	const handleOnClickUpdateStatus = React.useCallback(
		({ marketItemId, currentStatus }) => {
			const newStatus = ModuleUtils.getMarketItemNewStatus(currentStatus);

			mutateMarketItemStatus({ marketItemId, newStatus });
			setIsOpenMarketplaceUpdater(true);
		},
		[marketItemUpdateStatus],
	);
	const handleOnConfirmUpdateClick = React.useCallback(
		(coverage, users) =>
			updateMarketItemInstallationStatus({
				...marketItemUpdateStatus,
				coverage,
				users,
			})[marketItemUpdateStatus],
	);

	// Getters
	React.useEffect(() => {
		getMarketBanners();
		getMarketModules();
		getMarketItems({
			moduleId: 'all',
			limit: 10,
		});
	}, []);

	return (
		<Wrapper padding='0' className='animated fadeIn' highBackground>
			<Wrapper padding='0' justifyContent='center'>
				<Wrapper
					elementRef={ref}
					padding='0'
					width='100%'
					maxWidth='900px'
					flexDirection='column'
					alignItems='center'
				>
					<AdvertisingBanners
						isFetching={marketplaceBanners.isFetching}
						modeView={modeView}
						banners={banners}
					/>
					<Wrapper
						padding='0'
						margin='30px 0 0 0'
						maxWidth={`${rect?.width}px`}
						flexDirection='column'
						alignItems='stretch'
					>
						{isOpenMarketplaceUpdater ? (
							<UpdateStatusMarketItem
								setIsOpen={setIsOpenMarketplaceUpdater}
								selectedMarketplaceItem={selectedMarketplaceItem}
								marketItemUpdateStatus={marketItemUpdateStatus}
								handleOnConfirmUpdateClick={handleOnConfirmUpdateClick}
							/>
						) : (
							<>
								<Categories
									rect={rect}
									marketplaceModules={marketplaceModules}
									mutate1ObjectInMarketplace={mutate1ObjectInMarketplace}
									getMarketItems={getMarketItems}
								/>
								<ListItems
									rect={rect}
									handleOnClickUpdateStatus={handleOnClickUpdateStatus}
									marketplaceItems={marketplaceItems}
								/>
							</>
						)}
					</Wrapper>
				</Wrapper>
			</Wrapper>
		</Wrapper>
	);
};

const mapStateToProps = state => ({
	modeView: selectModeView(state),
	marketplaceBanners: selectMarketplaceBanners(state),
	marketplaceModules: selectMarketplaceModules(state),
	marketplaceItems: selectMarketplaceItems(state),
});
const actions = {
	mutate1ObjectInMarketplace,
	getMarketItems,
	getMarketBanners,
	getMarketModules,
	updateMarketItemInstallationStatus,
};

export default connect(mapStateToProps, actions)(Marketplace);
