//  Dependencies
import { takeLatest, put, call, all } from 'redux-saga/effects';
import axios from 'axios';
// Utils
import asyncErrorsHandler from '../../store/asyncErrorsHandler';
import API from '../../utils/constants/api';
import GENERAL from '../../utils/constants/general';
const { SIGNUP, DIVISION_ID } = GENERAL;

//* ******* WATCHER SAGAS ***********/
function* getSignupDataWatcher() {
	yield takeLatest(SIGNUP.GET_SIGNUP_DATA, getSignupDataWorker);
}

function* signupWatcher() {
	yield takeLatest(SIGNUP.SIGNUP, signupWorker);
}

//* ******* WORKERS SAGAS ***********/
function* getSignupDataWorker() {
	try {
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/users/getSignupData'),
		);
		yield put({
			type: SIGNUP.MUTATE_DIRECT_PROPS,
			payload: {
				companies: response.data.companies,
				organizations: response.data.organizations,
				entities: response.data.entities,
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getSignupDataWorker();
		});
	}
}

function* signupWorker(action) {
	const newUser = action.payload;

	try {
		yield put({ type: SIGNUP.MUTATE_DIRECT_PROPS, payload: { loading: true } });
		yield call(axios.post, API.DOMAIN.concat('/users/signup'), {
			...newUser,
			division_id: DIVISION_ID.ADM,
		});
		yield put({
			type: SIGNUP.TOAST_MESSAGE,
			payload: {
				message:
					'Registro completado! Le enviaremos un mensaje de texto cuando su cuenta esté activada',
				type: 'success',
				duration: 10,
			},
		});
		yield put({ type: SIGNUP.RESET_REGISTRY });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: SIGNUP.MUTATE_DIRECT_PROPS,
					payload: { loading: false, status: 'error' },
				});
				// yield put({
				//   type: SIGNUP.TOAST_MESSAGE,
				//   payload: {
				//     message: err.response.data.message,
				//     type: err.response.data.type,
				//   },
				// });
			},
			function* () {
				yield signupWorker(action);
			},
		);
	}
}

//  Export default Root Saga
export default function* rootSaga() {
	yield all([signupWatcher(), getSignupDataWatcher()]);
}
