const convertFileToBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});

const base64Encode = data =>
	Buffer.from(JSON.stringify(data)).toString('base64');

export { convertFileToBase64, base64Encode };
