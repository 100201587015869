// Libs
import React, { useEffect } from 'react';
// Context
import { useCoordContext } from 'components/CoordManager';
// Components
import CoordPreview from './CoordPreview';
import TakePhotoButton from './TakePhotoButton';
import TakenPhotoConfirmation from './TakenPhotoConfirmation';
import ControlPanelAccess from './ControlPanelAccess';

const VideoControl = ({
	isPhotoTaken,
	takenPhotoConfirmationHeight,
	profile,
	orientation,
	canPlay,
	confirming,
	takePhoto,
	onTakenPhotoConfirm,
	onTakenPhotoCancel,
	onControlPanelOpen,
	onManualOrientationToggle,
}) => {
	const {
		coords,
		coordSync,
		manuallyDisabledGPS,
		setState: setCoordManagerState,
	} = useCoordContext();

	//Play auto sync coords
	useEffect(() => {
		setCoordManagerState({ stopAutoSync: isPhotoTaken }, false);
		return () => {
			setCoordManagerState({ stopAutoSync: false }, false);
		};
	}, [isPhotoTaken]);

	return (
		<>
			{isPhotoTaken ? (
				<TakenPhotoConfirmation
					height={takenPhotoConfirmationHeight}
					confirming={confirming}
					onTakenPhotoCancel={onTakenPhotoCancel}
					onTakenPhotoConfirm={() => onTakenPhotoConfirm(coords)}
				/>
			) : (
				<>
					<CoordPreview
						coords={coords}
						coordSync={coordSync}
						manuallyDisabledGPS={manuallyDisabledGPS}
						profile={profile}
					/>
					<ControlPanelAccess
						orientation={orientation}
						onControlPanelOpen={onControlPanelOpen}
						onManualOrientationToggle={onManualOrientationToggle}
					/>
					{!manuallyDisabledGPS && (
						<TakePhotoButton
							canPlay={canPlay}
							takePhoto={() => takePhoto(coords)}
							orientation={orientation}
						/>
					)}
				</>
			)}
		</>
	);
};

export default VideoControl;
