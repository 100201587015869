class Field {
	constructor(data) {
		if (new.target === Field) {
			throw new Error('Cannot instantiate abstract class');
		}
		this.id = data.id;
		this.name = data.name;
		this.type = data.type;
		this.uiValueProp = data.uiValueProp;
	}
}

export class StandardField extends Field {
	constructor(data) {
		super(data);
		this.uiValuePropLevel = data.uiValuePropLevel;
		this.filterable = data.filterable;
		this.unselectable = data.unselectable;
		this.width = data.width;
		this.show = data.show;
		this.onChange = data.onChange;
	}
}

export class OverviewField extends Field {
	constructor(data) {
		super(data);
		this.value = data.value;
		this.uiValue = data.uiValue;
		this.main = data.main;
	}
}
