// Libs
import React from 'react';
// Utils
import { filterOption } from 'utils/libs';
// Components
import { Button, DatePicker, TextField } from 'components';
import Select from 'components/Select';
// Styles
import '../../../index.css';

const { Option } = Select;
const { Input } = TextField;

function CreateInvoicePaycutModal({
	departments,
	coins,
	createPaycutModal,
	createPaycut,
	mutate1ObjectInBillings,
	getDepartments,
}) {
	React.useEffect(() => {
		getDepartments();
	}, []);

	return (
		<div className='modal-container'>
			<div className='create-article-form-items'>
				{/* NAME */}
				<Input
					className='form-item-standard'
					placeholder='Nombre*'
					maxLength={30}
					value={createPaycutModal.name}
					onChange={e =>
						mutate1ObjectInBillings('createPaycutModal', {
							name: e.target.value,
						})
					}
				/>

				{/* FROM DATE */}
				<DatePicker
					style={{ marginBottom: '10px' }}
					placeholder='Desde*'
					value={createPaycutModal.from_date}
					onChange={value =>
						mutate1ObjectInBillings('createPaycutModal', { from_date: value })
					}
				/>

				{/* TO DATE */}
				<DatePicker
					style={{ marginBottom: '10px' }}
					placeholder='Hasta*'
					value={createPaycutModal.to_date}
					onChange={value =>
						mutate1ObjectInBillings('createPaycutModal', { to_date: value })
					}
				/>

				<Select
					className='form-item-standard create-article-form-items-select'
					showSearch
					placeholder='Departamento*'
					value={createPaycutModal.department_id}
					filterOption={filterOption}
					onChange={value =>
						mutate1ObjectInBillings('createPaycutModal', {
							department_id: value,
						})
					}
				>
					{departments.map((department, idx) => (
						<Option key={idx} value={department.id}>
							<em>{department.name}</em>
						</Option>
					))}
				</Select>

				<Select
					className='form-item-standard create-article-form-items-select'
					showSearch
					placeholder='Moneda*'
					value={createPaycutModal.coin_id}
					filterOption={filterOption}
					onChange={value =>
						mutate1ObjectInBillings('createPaycutModal', { coin_id: value })
					}
				>
					{coins.map((coin, idx) => (
						<Option key={idx} value={coin.id}>
							<em>{coin.name}</em>
						</Option>
					))}
				</Select>

				<div className='form-action-button'>
					<Button
						mode='primary'
						onClick={() => createPaycut(createPaycutModal)}
					>
						Crear
					</Button>
				</div>
			</div>
		</div>
	);
}

export default CreateInvoicePaycutModal;
