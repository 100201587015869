// Libs
import React, { useEffect, useState } from 'react';
import { Badge, Divider, MoreIcon, Span, Subtitle, Wrapper } from 'components';
// Hooks
import useTheme from 'hooks/useTheme';
// Styles
import styles from './MoreActions.module.css';

const ChildrenComponent = ({ onClick }) => (
	<Wrapper padding='0' onClick={onClick}>
		<MoreIcon button hoverable />
	</Wrapper>
);

const Header = ({ children, title }) => (
	<Wrapper flexDirection='column' width='100%' padding='0 0 0 15px'>
		<Wrapper padding='0' width='100%' justifyContent='space-between'>
			<Subtitle emphasis>{title}</Subtitle>
			{children}
		</Wrapper>
		<Divider margin='5px 0' />
	</Wrapper>
);

const Item = ({ index, rest, state, setBackgroundColor, setBadgeValue }) => {
	const { theme } = useTheme();

	const defaultValues = {
		title: '',
		onClick: () => {},
		onBadgeValue: undefined,
		isDisable: false,
		color: undefined,
		icon: () => <></>,
		component: null,
	};

	const {
		title = defaultValues.title,
		onClick = defaultValues.onClick,
		onBadgeValue = defaultValues.onBadgeValue,
		isDisable = defaultValues.isDisable,
		color = defaultValues.color,
		icon = defaultValues.icon,
		component = defaultValues.component,
	} = rest;

	useEffect(() => {
		setBackgroundColor({ [index]: 'transparent' });
		setBadgeValue({ [index]: onBadgeValue });
	}, []);

	return (
		<Wrapper
			cursor='pointer'
			key={index}
			padding='0px 15px'
			width='100%'
			height='40px'
			justifyContent='space-between'
			onClick={(!isDisable && onClick) || defaultValues.onClick}
			backgroundColor={state.backgroundColor[index]}
			onMouseEnter={() =>
				!isDisable &&
				setBackgroundColor({ [index]: theme._disabledBackgroundColor })
			}
			onMouseLeave={() => setBackgroundColor({ [index]: 'transparent' })}
		>
			<Badge value={state.onBadgeValue[index]}>
				{!!component ? (
					<Wrapper padding='0' width='100%' justifyContent='space-between'>
						<Span
							fontWeight='900'
							ellipsis
							width='175px'
							color={color}
							disabled={isDisable}
						>
							{title}
						</Span>

						{component}
					</Wrapper>
				) : (
					<>
						<Span
							fontWeight='900'
							ellipsis
							width='175px'
							color={color}
							disabled={isDisable}
						>
							{title}
						</Span>
						{icon({ color, onlyIcon: true })}
					</>
				)}
			</Badge>
		</Wrapper>
	);
};

const Items = ({ list }) => {
	const [state, _setState] = useState({
		backgroundColor: {},
		onBadgeValue: {},
	});

	const setBackgroundColor = newState =>
		_setState(current => ({
			...current,
			backgroundColor: { ...current.backgroundColor, ...newState },
		}));

	const setBadgeValue = newState =>
		_setState(current => ({
			...current,
			onBadgeValue: { ...current.onBadgeValue, ...newState },
		}));

	return list.map((props, index) => {
		if (!props) return null;
		return (
			<Item
				key={index}
				index={index}
				rest={props}
				state={state}
				setBackgroundColor={setBackgroundColor}
				setBadgeValue={setBadgeValue}
			/>
		);
	});
};

const MoreActions = ({ title = 'Más Opciones', list = [] }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handdleClick = () => setIsOpen(current => !current);
	const { theme } = useTheme();
	return isOpen ? (
		<div className={styles.dropdown} onMouseLeave={handdleClick}>
			<div
				className={styles.container}
				style={{
					background: theme._secondaryBackgroundColor,
				}}
			>
				<Header title={title}>
					<ChildrenComponent onClick={handdleClick} />
				</Header>
				<Items list={list} />
			</div>
		</div>
	) : (
		<ChildrenComponent onClick={handdleClick} />
	);
};

export default MoreActions;
