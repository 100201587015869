// Libs
import React from 'react';
import { connect } from 'react-redux';
// Actions
import { toggleDatasource } from './actions';
// Selectors
import { selectIsOpenDatasource } from './selectors';
// Context
import { ElementViewBuilderProvider, ModalBuilderProvider } from 'core/context';
// Components
import { DisplayBuilder } from './DisplayBuilder';
import { MenuBuilder } from './MenuBuilder';
import { FormBuilder } from './FormBuilder';
import { Wrapper, Grid } from 'components';

const Connect = React.memo(
	({
		// Props
		isMobile,
		profile,
		// State
		isOpen,
		// Actions
		toggleDatasource,
	}) => {
		return (
			<ElementViewBuilderProvider>
				<ModalBuilderProvider>
					<Wrapper
						highBackground
						padding='0'
						width='100%'
						height='100%'
						className='animated fadeIn'
					>
						<Grid
							width='100vw'
							height='100%'
							columns={isOpen ? '300px 1fr' : '1fr'}
						>
							<MenuBuilder
								isOpen={isOpen}
								isMobile={isMobile}
								profile={profile}
								toggleDatasource={toggleDatasource}
							/>
							<DisplayBuilder menuIsOpen={isOpen} isMobile={isMobile} />
						</Grid>
						<FormBuilder isMobile={isMobile} />
					</Wrapper>
				</ModalBuilderProvider>
			</ElementViewBuilderProvider>
		);
	},
);

const mapStateToProps = state => ({
	isOpen: selectIsOpenDatasource(state),
});

const actions = {
	toggleDatasource,
};

export default connect(mapStateToProps, actions)(Connect);
