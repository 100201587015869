import { Progress } from 'antd';
import styled from 'styled-components';

const ProgressStyled = styled(Progress)`
	&.ant-progress {
		line-height: 0;
	}
`;

export default ProgressStyled;
