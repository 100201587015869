// Services
import { ApiServiceV2 } from 'services';
// Hooks
import { useToastMessage } from 'hooks';

const useChartManager = () => {
	const sendToast = useToastMessage();

	return {
		getChartPayload: async (id, userParams, { onError } = {}) => {
			const base64UserParams = Buffer.from(JSON.stringify(userParams)).toString(
				'base64',
			);
			return ApiServiceV2.get({
				url: `/charts/v1/payload/${id}/${base64UserParams}`,
			}).catch(err => {
				if (onError) onError();
				sendToast({
					description: err.message,
				});
			});
		},
	};
};
export default useChartManager;
