// Libs
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Utils
import reorder, { reorderDataMap } from './reorder';

// Components
import BoardRows from './BoardRows';
import { DragDropContext } from 'react-beautiful-dnd';

const ZoneContainer = styled.div`
	background-color: rgba(0, 0, 0, 0.3);
	/* todo: change this */
	border-radius: 5;
	margin-bottom: 10px;
	display: inline-block;
	overflow-x: hidden;
	overflow-y: hidden;
`;

const ZoneTitle = styled.div`
	color: #1eb980;
	font-size: 18px;
	margin: 10px 0 0 30px;
`;

const getZonesFromInitial = initial => {
	const zones = {};

	Object.keys(initial).forEach(exp => {
		zones[initial[exp].zoneId] = {
			zoneId: initial[exp].zoneId,
			zoneName: initial[exp].zoneName,
			experts: {
				...(zones[initial[exp].zoneId]?.experts ?? {}),
				[exp]: initial[exp],
			},
		};
	});

	return Object.values(zones);
};

const getInitialStateFromZones = zones =>
	zones.reduce((acc, zone) => {
		acc[zone.zoneId] = {
			columns: zone.experts,
			ordered: Object.keys(zone.experts),
		};

		return acc;
	}, {});

export default ({
	initial,
	containerHeight,
	withScrollableColumns,
	// actions from parent
	saveOrdersReassignmentToUserV2,
}) => {
	const zones = getZonesFromInitial(initial);

	const [state, setState] = useState(getInitialStateFromZones(zones));

	useEffect(() => {
		const newZones = getZonesFromInitial(initial);
		setState(getInitialStateFromZones(newZones));
	}, [initial]);

	const onDragEnd = result => {
		// dropped nowhere
		if (!result.destination) {
			return;
		}

		const { source, destination } = result;

		// did not move anywhere - can bail early
		if (
			source.droppableId === destination.droppableId &&
			source.index === destination.index
		)
			return;

		// reordering column
		if (result.type === 'COLUMN') {
			const sourceZoneId = source.droppableId.split('-')[1];
			const destinationZoneId = destination.droppableId.split('-')[1];

			// zoneIds must match
			if (sourceZoneId !== destinationZoneId) return;

			// moving inside the same board row
			if (source.droppableId === destination.droppableId) {
				const boardIndex = destination.droppableId.split('-')[2];

				const ordered = reorder(
					state[sourceZoneId].ordered,
					source.index,
					boardIndex == 1
						? destination.index +
								Math.ceil(state[sourceZoneId].ordered.length / 2)
						: destination.index,
				);

				setState(prevState => ({
					...prevState,
					[sourceZoneId]: {
						...prevState[sourceZoneId],
						ordered,
					},
				}));

				return;
			}

			const destinationBoardIndex = destination.droppableId.split('-')[2];
			const destinationIndex =
				destinationBoardIndex == 1
					? destination.index +
					  Math.ceil(state[sourceZoneId].ordered.length / 2 - 1)
					: destination.index;

			const ordered = reorder(
				state[sourceZoneId].ordered,
				source.index,
				destinationIndex,
			);

			setState(prevState => ({
				...prevState,
				[sourceZoneId]: {
					...prevState[sourceZoneId],
					ordered,
				},
			}));

			return;
		}

		const destinationZoneId = destination.droppableId.split('-')[1];
		const draggableZoneId = result.draggableId.split('-')[1];

		// source column and destination column must be in the same zone
		if (destinationZoneId !== draggableZoneId) return;

		const sourceTechId = source.droppableId.split('-')[0];
		const targetOrderId =
			state[draggableZoneId].columns[sourceTechId].orders[source.index]
				.order_id;
		const targetTechId = parseInt(destination.droppableId.split('-')[0]);

		saveOrdersReassignmentToUserV2([targetOrderId], targetTechId);

		const data = reorderDataMap({
			dataMap: state[draggableZoneId].columns,
			source,
			destination,
		});

		setState(prevState => ({
			...prevState,
			[draggableZoneId]: {
				...prevState[draggableZoneId],
				columns: data.dataMap,
			},
		}));
	};

	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				{zones.map((zone, idx) => (
					<ZoneContainer key={idx}>
						<ZoneTitle>{zone.zoneName}</ZoneTitle>
						<BoardRows
							key={idx}
							zone={zone}
							columns={state[zone.zoneId].columns}
							ordered={state[zone.zoneId].ordered}
							containerHeight={containerHeight}
							withScrollableColumns={withScrollableColumns}
						/>
					</ZoneContainer>
				))}
			</DragDropContext>
		</>
	);
};
