// Libs
import React from 'react';
import styled from 'styled-components';
// Components
import IconButton from '@mui/material/IconButton';
import icon from './analytics.svg';

const Icon = styled.div`
	background-image: url(${icon});
	width: 24px;
	height: 24px;
`;

const AnalyticsIcon = ({ button, onClick }) =>
	button ? (
		<IconButton onClick={onClick}>
			<Icon />
		</IconButton>
	) : (
		<Icon onClick={onClick} />
	);

export default AnalyticsIcon;
