const Wrapper = ({ className, justChildren, children, ...props }) =>
	justChildren ? (
		children
	) : (
		<div className={className} {...props}>
			{children}
		</div>
	);

export default Wrapper;
