// Libs
import React, { useState, useEffect } from 'react';
// Components
import { Dropdown, Menu } from 'components';
import { AddIcon, MoreIcon } from 'components/Icons';
// Config
import ActionBuilderConfig from './action.config';
import { ApiServiceV2 } from 'services';

const ActionsMenu = ({ authorizedActions }) => (
	<Menu.Wrapper mode='inline'>
		{/* Iterate & Render all authorized actions */}
		{authorizedActions.map(action => {
			const Action = ActionBuilderConfig.getAuthorizedActionComponentReactNode(
				action.key,
			);
			return (
				<Menu.Item key={action.key} style={{ padding: 0 }}>
					<Action key={action.id} action={action} icon={<AddIcon button />} />
				</Menu.Item>
			);
		})}
	</Menu.Wrapper>
);

/**
 * @param {int} moduleItemId
 * @param {int} orderTargetId
 * @returns A React component
 */
const ActionBuilder = ({ moduleItemId, orderTargetId }) => {
	const [authorizedActions, setAuthorizedActions] = useState([]);

	useEffect(() => {
		if (!moduleItemId || !orderTargetId) return;
		setAuthorizedActions([]);
		ApiServiceV2.get({
			url: `/modules/v1/getModuleItemActions/${moduleItemId}/${orderTargetId}`,
		})
			.then(setAuthorizedActions)
			.catch(() => setAuthorizedActions([]));
	}, [moduleItemId, orderTargetId]);

	if (!authorizedActions.length) return null;
	return (
		<Dropdown.Wrapper
			onClick={e => e.stopPropagation()}
			trigger={['click']}
			overlay={<ActionsMenu authorizedActions={authorizedActions} />}
		>
			{/* Si se quita el siguiente div, el dropdown no funciona */}
			<div>
				<MoreIcon horizontal hoverable />
			</div>
		</Dropdown.Wrapper>
	);
};
export default ActionBuilder;
