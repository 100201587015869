// Libs
import React from 'react';
// Components
import { TextField } from 'components';

const { Input } = TextField;

// Example custom component: Not currently in use
const CustomExample = ({ title, value, onChange }) => {
	return (
		<Input
			width='100%'
			value={value}
			placeholder={title}
			onChange={e => onChange(e.target.value)}
		/>
	);
};

export default CustomExample;
