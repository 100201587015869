import React from 'react';
import styles from './Orders.module.css';

// Components
import { Title, Subtitle, Button, Divider, Caption, Span } from 'components';

const StyleGuideV1 = ({ isMobile, record, screenKey, isNearScreen }) => {
	if (!isNearScreen) return null; // Lazy loading
	return (
		<div className={styles.card}>
			<Title>Title</Title>
			<Divider />
			<Subtitle>Subtitle</Subtitle>
			<Divider />
			<Span>
				Span: lorem ipsum dolor sit amet, consectetur adipiscing lorem ipsum
				dolor sit amet, consectetur adipiscing lorem ipsum dolor sit amet,
				consectetur adipiscing lorem ipsum dolor sit amet, consectetur
				adipiscing
			</Span>
			<Divider />
			<Caption ellipsis width={'100px'}>
				Caption: lorem ipsum dolor sit amet, consectetur adipiscing lorem ipsum
			</Caption>
			<Divider />
			<Button primary>Primary Buttom</Button>
			<Divider />
			<Button secondary>Secondary Buttom</Button>
		</div>
	);
};

export default StyleGuideV1;
