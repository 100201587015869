// Libs
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
// Utils
import ChartUtils from './utils';

const MyResponsiveBar = ({ type, data, config, theme }) => {
	const indexedBy = config?.indexedBy;
	const keys = ChartUtils.getKeys({ type, data, indexedBy });
	const settedData =
		data.length > 0
			? data
			: [
					{
						id: 'Sin datos que mostrar',
						label: 'Sin datos que mostrar',
						value: 0,
					},
			  ];

	return (
		<ResponsiveBar
			data={settedData}
			theme={theme}
			keys={keys}
			indexBy={indexedBy}
			groupMode={'grouped'}
			margin={{ top: 40, right: 190, bottom: 40, left: 60 }}
			padding={0.3}
			valueScale={{ type: 'linear' }}
			indexScale={{ type: 'band', round: true }}
			valueFormat={{ format: '', enabled: false }}
			colors={{ scheme: 'nivo' }}
			defs={config.defs}
			fill={config.fill}
			borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
			}}
			labelSkipWidth={12}
			labelSkipHeight={12}
			labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 120,
					translateY: 0,
					itemsSpacing: 10,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 20,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
		/>
	);
};

export default React.memo(MyResponsiveBar);
