import { memo } from 'react';

import { DatePicker, TimePicker } from 'components';

import styles from './DatetimeField.module.css';

const DatetimeField = memo(({ title, value, onChange }) => (
	<div className={styles.mainWrapper}>
		<DatePicker
			width='100%'
			allowClear={false}
			placeholder={title?.split(',')[0] || 'Fecha'}
			value={value}
			onChange={date => onChange(date.utc().format())}
		/>
		<TimePicker
			width='100%'
			allowClear={false}
			placeholder={title?.split(',')[1] || 'Hora'}
			value={value}
			onChange={date => onChange(date.utc().format())}
		/>
	</div>
));

export default DatetimeField;
