// Libs
import axios from 'axios';

// interface RequestProps {
// 	url: string;
// 	params?: Object;
// 	data?: Object;
// 	withAuth?: boolean;
// }

// interface FetchProps extends RequestProps {
// 	method: Method;
// 	headers?: Object;
// }

const apiService = axios.create({
	baseURL: process.env.REACT_APP_REPORTS_API_URL,
});

const run = ({
	url,
	params, // the URL parameters to be sent with the request
	data, // the data to be sent as the request body, only applicable for request methods 'PUT', 'POST', and 'PATCH'
	method,
	headers = {},
}) => {
	return new Promise(async (resolve, reject) => {
		const axiosConfig = {
			url,
			params,
			data,
			method,
			headers: {
				...headers,
				'Auth-Token': process.env.REACT_APP_REPORTS_API_AUTH_TOKEN,
			},
		};

		return apiService(axiosConfig)
			.then(response => resolve(response.data))
			.catch(err => {
				const resData = err?.response?.data || {};
				const resStatus = err?.response?.status || 0;
				// Show multiple errors?
				if (
					(resData.showError || resStatus >= 500) &&
					Array.isArray(resData.errors) &&
					resData.errors.length > 0
				) {
					return reject(resData.errors);
				}
				return reject(resData);
			});
	});
};

export default class ReportsApiService {
	async get(props) {
		return run({ ...props, method: 'get' });
	}

	async post(props) {
		return run({ ...props, method: 'post' });
	}

	async put(props) {
		return run({ ...props, method: 'put' });
	}

	async delete(props) {
		return run({ ...props, method: 'delete' });
	}
}
