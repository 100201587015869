import styled from 'styled-components';

export const TableResponsive = styled.div`
	width: 90%;
	display: grid;
	border: 1px solid #00abd1;
	grid-template-columns: 1fr minmax(200px, 1fr);
	grid-template-rows: 2fr;
	margin: 0 20px;
`;

export const TableHeader = styled.div`
	grid-column: 1 / span 1;
	color: white;
	background-color: #262626;
	padding: 1rem;
	div {
		font-weight: bold;
	}
	@media (min-width: 400px) {
		grid-column: 1/-1;
	}
`;

export const TableBody = styled.div`
	grid-column: 2/-1;
	overflow-x: auto;
	display: grid;
	grid-template-columns: repeat(${props => props.size && props.size}, 1fr);
	grid-gap: 1rem;
	color: white;
	padding: 1rem;
	div {
		font-size: 1em;
	}
	@media (min-width: 400px) {
		grid-column: 1/-1;
		grid-template-columns: 1fr;
	}
`;
export const TableRow = styled.div`
	position: relative;
	min-width: 100px;
	display: grid;
	padding: 10px 0;
	grid-gap: 1rem;
	align-items: center;
	justify-items: start;
	@media (min-width: 400px) and (min-width: 400px) {
		grid-template-columns: repeat(
			${props => props.size && props.size + 1},
			1fr
		);
		grid-template-rows: 1fr;
		justify-items: center;
	}
`;
