// Libs
import React from 'react';
// Utils
import { filterOption } from 'utils/libs';
// Components
import {
	Button,
	Spin,
	Divider,
	CloseIcon,
	AddIcon,
	TextField,
} from 'components';
import Select from 'components/Select';
// Styles
import '../../css/CreatePath.css';

const { Input } = TextField;
const { Option } = Select;

const CreatePath = props => {
	const {
		createPath,
		mutate1Object,
		handleOnClickCreatePath,
		zones,
		areas,
		profile,
	} = props;

	return (
		<div className='create-path-container'>
			{/* TITLE */}
			<div className='create-path-title'>Crear Nueva</div>

			<div className='create-path-form-items'>
				{createPath.addZone ? (
					// ADD ZONE
					<Input
						autoFocus
						className='form-item-standard'
						id='zone'
						placeholder='Zona*'
						maxLength={15}
						value={createPath.zone}
						onChange={e =>
							mutate1Object('createPath', { [e.target.id]: e.target.value })
						}
						suffix={
							<CloseIcon
								onClick={() =>
									mutate1Object('createPath', { addZone: false, zone: '' })
								}
							/>
						}
					/>
				) : (
					// ZONE
					<Select
						className='form-item-standard'
						showSearch
						placeholder='Zona*'
						// value={createPath.details.type}
						onChange={value => mutate1Object('createPath', { zone: value })}
						filterOption={filterOption}
						dropdownRender={menu => (
							<div>
								{menu}
								<Divider style={{ margin: '4px 0' }} />
								<div
									style={{ padding: '4px 8px', cursor: 'pointer' }}
									onMouseDown={e => e.preventDefault()}
									onClick={() =>
										mutate1Object('createPath', { addZone: true, zone: '' })
									}
								>
									<AddIcon />
								</div>
							</div>
						)}
					>
						{zones.map(zone => (
							<Option value={zone.name}>
								<em>{zone.name}</em>
							</Option>
						))}
					</Select>
				)}

				{/* AREA */}
				<Input
					className='form-item-standard'
					id='area'
					placeholder='Area*'
					maxLength={15}
					value={createPath.area}
					onChange={e =>
						mutate1Object('createPath', { [e.target.id]: e.target.value })
					}
				/>
			</div>

			<div className='create-path-form-action-button'>
				{/* CREATE BUTTON */}
				<Spin spinning={createPath.loading} size='large' delay={50}>
					<Button
						className='button-style-1'
						onClick={() => handleOnClickCreatePath(createPath)}
					>
						Crear
					</Button>
				</Spin>
			</div>
		</div>
	);
};
export default CreatePath;
