import Immutable from 'immutable';
import _ from 'underscore';
import { filterDataByFilterOptions } from '..';

function filterPaymentLines(lines, filters) {
	let filteredPaymentLines = Immutable.List(lines).toJS();

	filteredPaymentLines = filterDataByFilterOptions(
		filteredPaymentLines,
		filters,
	);
	return filteredPaymentLines;
}

export { filterPaymentLines };
