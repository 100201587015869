import styled from 'styled-components';
import { Table } from 'antd';
// Hooks
import useTheme from 'hooks/useTheme';

const StyledTable = styled(Table)`
	.ant-table-tbody > tr.ant-table-row-selected > td {
		background: ${props => props.theme._surfaceBackgroundColor};
	}
	tr.ant-table-row {
		color: ${props => props.theme._mediumEmphasisColor};
	}
`;

const StyledTableTransfer = props => {
	const { theme } = useTheme();

	return (
		<StyledTable theme={theme} {...props}>
			{props.children}
		</StyledTable>
	);
};

export default StyledTableTransfer;
