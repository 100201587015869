//  Libs
import React from 'react';
import _ from 'underscore';
import { Redirect } from 'react-router-dom';
// Utils
import { getFlagColorOrderCard, thisBelongsToMyEntity } from '../../../utils';
import { formatNumber, coinsUtils } from 'utils/libs';
import { filterPaymentOrders } from 'utils/filters/Billings/orders';
import { getSelectedPayments } from 'utils/filters/Billings/payments';
import GENERAL from 'utils/constants/general';
// Components
import {
	Wrapper,
	TableVirtualized,
	Flag,
	Span,
	Spin,
	InputNumber,
} from 'components';

const { ENV } = GENERAL;
const { Table, Column, Header, Row, Cell } = TableVirtualized;

function PaymentsOrdersDesktop(props) {
	if (
		!Array.isArray(props?.pays?.selected?.items) ||
		props?.pays?.selected?.items?.length === 0 ||
		!props?.pays?.getOrders
	) {
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYS_DESKTOP} />;
	}

	const {
		// BILLINGS
		pays,
		orders,
		mutate1Object,
		// ORDERS
		mutate1ObjectInOrders,
		ordersState,
		getOrder,
		// API
		payments,
		coins,
		// LOGIN
		profile,
	} = props;
	const allOrders = payments.orders.data;
	const { control } = ordersState;

	const selectedPayments = getSelectedPayments(
		payments.pays.data,
		pays.selected.items,
	);
	const firstSelectedPayment = selectedPayments[0];

	const filteredPaymentOrders = filterPaymentOrders(allOrders, orders.filters);

	// ------RENDERS------

	// HEADER
	function getHeaderRenderer({ dataKey, label }, orderType) {
		const onChangedFilter = updatedFilters =>
			mutate1Object('orders', { filters: updatedFilters });

		return (
			<Header
				filter={{
					dataKey,
					data: allOrders,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: orders.filters,
					onChangedFilter,
				}}
			>
				{label}
			</Header>
		);
	}

	// ROW
	function getRowRenderer({ key, columns, style }) {
		return (
			<Row key={key} style={style}>
				{columns}
			</Row>
		);
	}

	// INDEX
	function getIndexCellRenderer({ rowData, rowIndex }) {
		return (
			<Cell>
				<Flag
					style={getFlagColorOrderCard(rowData, pays.getOrders, profile)}
					height='20px'
				/>
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	}

	// ORDER
	function getOrderCellRenderer({ rowData }) {
		return (
			<Cell
				onClick={() => {
					if (
						!control.orders.filter(ord => ord.order_id === rowData.order_id)[0]
					) {
						getOrder(rowData.order_id);
					}
					mutate1ObjectInOrders('getOrderInformationModal', {
						order_id: rowData.order_id,
						isOpen: true,
					});
				}}
			>
				{rowData.odt_id}
			</Cell>
		);
	}

	// AMOUNT
	function getAmountCellRenderer({ rowData }) {
		const { price, amount } = rowData;
		const coinId =
			firstSelectedPayment && pays.getOrders === 'orders_paid'
				? firstSelectedPayment.coin_id
				: price.coinId;
		const coinSymbol = coinsUtils.getDataByCoinId(coinId, 'symbol', coins.data);

		return (
			<Cell>
				{pays.getOrders === 'orders_paid'
					? formatNumber.new(amount, coinSymbol, 2)
					: formatNumber.new(price.price, coinSymbol, 2)}
			</Cell>
		);
	}

	// CHARGE
	function getChargeCellRenderer({ rowData }) {
		const { price, charge } = rowData;

		if (thisBelongsToMyEntity(firstSelectedPayment?.entity_id, profile)) {
			var coinId = firstSelectedPayment.coin_id;
		} else {
			var coinId = price.coinId;
		}
		const coinSymbol = coinsUtils.getDataByCoinId(coinId, 'symbol', coins.data);

		return (
			<Cell>
				{thisBelongsToMyEntity(firstSelectedPayment?.entity_id, profile)
					? formatNumber.new(charge, coinSymbol, 2)
					: formatNumber.new(price.price, coinSymbol, 2)}
			</Cell>
		);
	}

	// DIFFERENCE
	function getDifferenceAmountsCellRenderer({ rowData }) {
		const { price, amount, charge } = rowData;

		if (thisBelongsToMyEntity(firstSelectedPayment?.entity_id, profile)) {
			var difference = amount - charge;
			var coinId = firstSelectedPayment.coin_id;
		} else {
			var difference = amount - price.price;
			var coinId = price.coinId;
		}

		const coinSymbol = coinsUtils.getDataByCoinId(coinId, 'symbol', coins.data);

		return <Cell>{formatNumber.new(difference, coinSymbol, 2)}</Cell>;
	}

	// BONUS
	function getBonusCellRenderer({ rowData }) {
		let editedOrders = [...orders.editedOrders];
		const idx = _.findIndex(editedOrders, ord => ord.id === rowData.id);

		const coinSymbol = coinsUtils.getDataByCoinId(
			firstSelectedPayment?.coin_id,
			'symbol',
			coins.data,
		);

		return (
			<Wrapper padding='0' height='40px'>
				{orders.mode === 'view' ? (
					<div>{formatNumber.new(rowData.bonus, coinSymbol, 2)}</div>
				) : (
					<InputNumber
						placeholder='Bono*'
						min={0.0}
						step={0.1}
						value={
							idx !== -1
								? orders.editedOrders[idx].bonus
								: rowData.bonus >= 0.01
								? rowData.bonus
								: undefined
						}
						formatter={value =>
							`${coinSymbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}
						parser={value => {
							value = value.replace(coinSymbol, '');
							value = value.replace(/\$\s?|(,*)/g, '');
							value = value.replace(/ /g, '');
							return value;
						}}
						onChange={value => {
							if (value >= 0.01 || value != rowData.bonus) {
								if (idx === -1) {
									editedOrders.push({
										id: rowData.id,
										bonus: value,
									});
								} else editedOrders[idx].bonus = value || 0.0;
							} else {
								if (idx !== -1) {
									editedOrders = editedOrders.filter(
										ord => ord.id !== rowData.id,
									);
								}
							}
							mutate1Object('orders', { editedOrders });
						}}
					/>
				)}
			</Wrapper>
		);
	}

	return (
		<Wrapper padding='0' className='animated fadeIn'>
			<Spin
				spinning={payments.orders.loading}
				size='large'
				delay={50}
				tip={'Consultando Órdenes...'}
			>
				<Table
					highBackground
					width={document.documentElement.clientWidth}
					height={document.documentElement.clientHeight - 115}
					headerHeight={44}
					rowHeight={25}
					rowCount={filteredPaymentOrders.length}
					rowGetter={({ index }) => filteredPaymentOrders[index]}
					rowRenderer={getRowRenderer}
				>
					<Column
						dataKey='index'
						label='#'
						width={50}
						headerRenderer={({ label }) => <Header>{label}</Header>}
						cellRenderer={getIndexCellRenderer}
					/>
					<Column
						dataKey='odt_id'
						label='Orden'
						width={110}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getOrderCellRenderer}
					/>
					<Column
						dataKey='service_name'
						label='Servicio'
						width={260}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='expert_name'
						label='Técnico'
						width={180}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='completed_code_name'
						label='Código Completado'
						width={350}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='amount'
						label={pays.getOrders === 'orders_paid' ? 'Cobrado' : 'Precio'}
						width={150}
						headerRenderer={({ label }) => <Header>{label}</Header>}
						cellRenderer={getAmountCellRenderer}
					/>
					{pays.getOrders === 'orders_paid' && (
						<Column
							dataKey='invoice'
							label='Cobrar'
							width={150}
							headerRenderer={props => getHeaderRenderer(props, 'numeric')}
							cellRenderer={getChargeCellRenderer}
						/>
					)}
					{pays.getOrders === 'orders_paid' && (
						<Column
							dataKey='different'
							label='Diferencia'
							width={150}
							headerRenderer={props => getHeaderRenderer(props, 'numeric')}
							cellRenderer={getDifferenceAmountsCellRenderer}
						/>
					)}
					{pays.getOrders === 'orders_paid' && (
						<Column
							dataKey='bonus'
							label='Bono'
							width={150}
							headerRenderer={props => getHeaderRenderer(props, 'numeric')}
							cellRenderer={getBonusCellRenderer}
						/>
					)}
					{pays.getOrders === 'orders_paid' && (
						<Column
							dataKey='created_at'
							label='Creado'
							width={130}
							headerRenderer={props => getHeaderRenderer(props, 'date')}
						/>
					)}
					{pays.getOrders === 'orders_paid' && (
						<Column
							dataKey='updated_at'
							label='Actualizado'
							width={130}
							headerRenderer={props => getHeaderRenderer(props, 'date')}
						/>
					)}
					{pays.getOrders === 'orders_notpay' && (
						<Column
							dataKey='payment_name'
							label='Pagando'
							width={220}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
						/>
					)}
				</Table>
			</Spin>
		</Wrapper>
	);
}

export default PaymentsOrdersDesktop;
