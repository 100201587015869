// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M25.3333 4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V6.66667C28 5.2 26.8 4 25.3333 4ZM12 22.6667H9.33333V16H12V22.6667ZM17.3333 22.6667H14.6667V18.6667H17.3333V22.6667ZM17.3333 16H14.6667V13.3333H17.3333V16ZM22.6667 22.6667H20V9.33333H22.6667V22.6667Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
