// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
// Utils
import { Modal, VirtualizedList } from 'screens/Utils';
import { filterStockSeriesSearchInData } from 'utils/libs';
import GENERAL from 'utils/constants/general';
import { onScanStockSeries } from '../../../utils';

const { ENV } = GENERAL;

function StockSeriesMobile(props) {
	const {
		stock,
		control,
		stockSeriesManage,
		mutate1Object,
		getItemsTransactionDocs,
		getTransactionDocs,
		getSearchingSeries,
		getSerieHistory,
		mutate1ObjectApi,
		serieHistory,
		sendToast,
		modeView,
	} = props;

	if (
		stockSeriesManage.searchInData.searchBy !== 'scan' &&
		stockSeriesManage.searchInData.searchBy !== 'serie' &&
		(!stock.warehouseId ||
			!stock.ownerId ||
			!control.mode ||
			!stock.itemId ||
			!stock.stateId)
	)
		return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSES_STOCK_ARTICLES_MOBILE} />;

	const filteredStockSeries = filterStockSeriesSearchInData(
		stock.series.data,
		stockSeriesManage.searchInData.searchBy,
		stockSeriesManage.searchInData.data,
	);
	if (stockSeriesManage.searchInData.searchBy === 'scan')
		onScanStockSeries(
			stockSeriesManage.searchInData,
			getSearchingSeries,
			mutate1Object,
		);

	return (
		<div>
			<VirtualizedList
				cardId='stockSerieItemCard'
				interpolatedRowStyle
				listStyle={{
					height: document.documentElement.clientHeight * 0.83,
					width: document.documentElement.clientWidth * 1.04,
				}}
				rowHeight={120}
				rows={filteredStockSeries}
				customProps={{
					mutate1ObjectApi,
					getSerieHistory,
				}}
			/>

			<Modal
				modalId='SerieHistoryModal'
				title='Movimientos'
				visible={serieHistory.isOpen}
				onCancel={() => mutate1ObjectApi('serieHistory', { isOpen: false })}
				footer={null}
				isSpinning={serieHistory.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					serieHistory,
					sendToast,
					modeView,
				}}
			/>
		</div>
	);
}

export default StockSeriesMobile;
