//Libs
import React from 'react';
import { connect } from 'react-redux';
//Types
import COLLECTOR_KEYWORDS from '../keywords';
//Actions
import { mutate1Object } from '../actions';
//Selectors
import { selectReviewManage, selectReadOnlyCollector } from '../selectors';
//Components
import { Button, Span } from 'components';
import CollectorUtils from '../CollectorUtils';

const ReportState = ({ state }) => (
	<Span fontWeight='bold'>
		{state === COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.APPROVED
			? 'Enviando'
			: state === COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.VALIDATING
			? 'Validando Reporte...'
			: state === COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.REQUIRED
			? 'Campos requeridos'
			: state === COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.WAITING &&
			  'Enviar Reporte'}
	</Span>
);

const SendReportButton = ({
	//Props
	itemStyle,
	//Redux
	reviewManage,
	readOnlyCollector,
	mutate1Object,
}) => {
	const { collectorRequiredValidation, sendingCollectorValues, autoSync } =
		reviewManage;

	const onClick = () =>
		collectorRequiredValidation.state ===
			COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.WAITING &&
		mutate1Object('reviewManage', {
			showSendConfirmationModal: true,
		});

	return (
		!readOnlyCollector &&
		!CollectorUtils.checkAutoSyncActive(
			autoSync.active,
			sendingCollectorValues,
		) &&
		(itemStyle ? (
			<div onClick={onClick}>
				<ReportState state={collectorRequiredValidation.state} />
			</div>
		) : (
			<Button onClick={onClick}>
				<ReportState state={collectorRequiredValidation.state} />
			</Button>
		))
	);
};

const mapStateToProps = state => ({
	reviewManage: selectReviewManage(state),
	readOnlyCollector: selectReadOnlyCollector(state),
});
const actions = {
	mutate1Object,
};

export default connect(mapStateToProps, actions)(SendReportButton);
