// Libs
import React from 'react';
export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mediumChartIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='2'
			y='8'
			width='28'
			height='16'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.3334 8L24.3867 11.0533L17.8801 17.56L12.5467 12.2267L2.66675 22.12L4.54675 24L12.5467 16L17.8801 21.3333L26.2801 12.9467L29.3334 16V8H21.3334Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mediumChartIcon)'>
			<rect width='32' height='32' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
