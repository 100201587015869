// Libs
import { combineReducers } from 'redux';
// Reducers
import feed from 'core/reducer';
import dashboard from 'screens/Dashboard/reducer';
import login from 'screens/Login/reducer';
import signup from 'screens/Signup/reducer';
import clients from 'screens/Clients/reducer';
import contracts from 'screens/Contracts/reducer';
import orders from 'screens/Orders/reducer';
import billings from 'screens/Billings/reducer';
import users from 'screens/Users/reducer';
import warehouses from 'screens/Warehouses/reducer';
import analytics from 'screens/Analytics/reducer';
import reports from 'screens/Reports/reports.reducer';
import collector from 'components/Collector/reducer';
import uploadResource from 'components/UploadResource/reducer';
import manageInventory from 'components/ConsumeInventory/reducer';
import autoscan from 'components/Autoscan/reducer';
import modules from 'components/Modules/reducer';
import supervisor from 'screens/Supervisor/reducer';
import api from './api/reducer';

//  Root Reducer
const rootReducer = combineReducers({
	feed,
	dashboard,
	login,
	signup,
	clients,
	contracts,
	orders,
	billings,
	users,
	warehouses,
	analytics,
	reports,
	collector,
	uploadResource,
	manageInventory,
	modules,
	supervisor,
	autoscan,
	api,
});

export default rootReducer;
