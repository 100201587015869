//Libs
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import axios from 'axios';
//Keywords
import KEYWORDS from './keywords';
//Types
import CONSUME_INVENTORY_TYPES from './types';
//Utils
import GENERAL from 'utils/constants/general';
import ConsumeInventoryUtils from './ConsumeInventoryUtils';
import asyncErrorsHandler from 'store/asyncErrorsHandler';
import AuthService from 'utils/libs/auth/AuthService';
//Selectors
import { selectCompleteOrderId } from 'screens/Orders/selectors';
import { selectWorkflow } from './selectors';

const { APIDATA } = GENERAL;
const auth = new AuthService();

//******** WATCHER SAGAS ***********/
function* getCurrentConsumedItemsWatcher() {
	yield takeLatest(
		CONSUME_INVENTORY_TYPES.GET_CURRENT_CONSUMED_ITEMS,
		getCurrentConsumedItemsWorker,
	);
}
function* saveConsumedItemsWatcher() {
	yield takeLatest(
		CONSUME_INVENTORY_TYPES.SAVE_CONSUMED_INVENTORY,
		saveConsumedItemsWorker,
	);
}
function* autoSyncConsumedItemsWatcher() {
	yield takeLatest(
		CONSUME_INVENTORY_TYPES.START_AUTO_SYNC_CONSUMED_ITEMS,
		autoSyncConsumedItemsWorker,
	);
}
function* generateResumeWatcher() {
	yield takeLatest(
		CONSUME_INVENTORY_TYPES.GENERATE_RESUME,
		generateResumeWorker,
	);
}

//******** WORKERS SAGAS ***********/
function* getCurrentConsumedItemsWorker(action) {
	const workflow = yield select(selectWorkflow);
	const { orderId } = action.payload;

	//Get URI
	const uri = ConsumeInventoryUtils.getURI({
		orderId,
		context: KEYWORDS.URI_CONTEXT.GET_CURRENT_CONSUMED_ITEMS,
		workflow,
	});
	if (!uri) return null;

	try {
		//Set isFetching
		yield put({
			type: CONSUME_INVENTORY_TYPES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'control',
				keyValuePairs: { isFetching: true },
			},
		});
		//Call API & get order consumedItems
		const { data: consumedItems } = yield call(
			axios.post,
			process.env.REACT_APP_API_URL.concat(uri),
			{ orderId },
			auth.sendToken(),
		);
		//Get formatted saved DB Consumed Items
		const savedDBConsumedItems =
			ConsumeInventoryUtils.getFormattedTransactionItemsToConsumedItems(
				{ orderId },
				consumedItems,
			);
		//Get offline consumed items
		const offlineConsumedItems =
			yield ConsumeInventoryUtils.reloadOfflineConsumedItems();
		//Combine consumed items with success consumed items
		const combinedConsumedItems = ConsumeInventoryUtils.combineConsumedItems(
			offlineConsumedItems,
			savedDBConsumedItems,
		);
		//Update redux state
		yield put({
			type: CONSUME_INVENTORY_TYPES.GET_CURRENT_CONSUMED_ITEMS_SUCCESS,
			payload: combinedConsumedItems,
		});
		//Update offline consumed items
		yield ConsumeInventoryUtils.mutateOfflineConsumedItems(
			combinedConsumedItems,
		);
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getCurrentConsumedItemsWorker(action);
		});
	}
}

function* saveConsumedItemsWorker(action) {
	const workflow = yield select(selectWorkflow);
	const orderId = yield select(selectCompleteOrderId);
	const { consumedItems } = action.payload;

	//Get URI
	const uri = ConsumeInventoryUtils.getURI({
		context: KEYWORDS.URI_CONTEXT.SAVE_CONSUMED_INVENTORY,
		workflow,
	});
	if (!uri) return null;

	try {
		//Set sending
		yield put({
			type: CONSUME_INVENTORY_TYPES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'control',
				keyValuePairs: { sending: true },
			},
		});
		//Call API & get success consumed items
		const { data: successConsumedItems } = yield call(
			axios.post,
			process.env.REACT_APP_API_URL.concat(uri),
			{ consumedItems },
			auth.sendToken(),
		);

		//Get offline consumed items
		const offlineConsumedItems =
			yield ConsumeInventoryUtils.reloadOfflineConsumedItems();
		//Combine consumed items with success consumed items
		const combinedConsumedItems = ConsumeInventoryUtils.combineConsumedItems(
			offlineConsumedItems,
			successConsumedItems,
		);
		//Update redux state
		yield put({
			type: CONSUME_INVENTORY_TYPES.SAVE_CONSUMED_INVENTORY_SUCCESS,
			payload: { orderId, consumedItems: combinedConsumedItems },
		});
		//Update offline consumed items
		yield ConsumeInventoryUtils.mutateOfflineConsumedItems(
			combinedConsumedItems,
		);
		//Active AutoSync
		yield put({
			type: CONSUME_INVENTORY_TYPES.SET_AUTO_SYNC,
			payload: { active: true },
		});
		yield put({
			type: APIDATA.GET_INVENTORY_TO_INVOICE,
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				//Get offline consumed items
				const offlineConsumedItems =
					yield ConsumeInventoryUtils.reloadOfflineConsumedItems();
				//Set error consumed items
				const errorConsumedItems =
					ConsumeInventoryUtils.setStatusToConsumedItems(
						consumedItems,
						KEYWORDS.STATUS.ERROR,
					);
				//Combine consumed items with error consumed items
				const combinedConsumedItems =
					ConsumeInventoryUtils.combineConsumedItems(
						offlineConsumedItems,
						errorConsumedItems,
					);
				//Update redux state
				yield put({
					type: CONSUME_INVENTORY_TYPES.SAVE_CONSUMED_INVENTORY_SUCCESS,
					payload: { orderId, consumedItems: combinedConsumedItems },
				});
				//Update offline consumed items
				yield ConsumeInventoryUtils.mutateOfflineConsumedItems(
					combinedConsumedItems,
				);
				//Active AutoSync
				yield put({
					type: CONSUME_INVENTORY_TYPES.SET_AUTO_SYNC,
					payload: { active: true },
				});
			},
			undefined,
		);
	}
}

function* autoSyncConsumedItemsWorker(action) {
	const workflow = yield select(selectWorkflow);
	const orderId = yield select(selectCompleteOrderId);
	const { consumedItems } = action.payload;

	//Get URI
	const uri = ConsumeInventoryUtils.getURI({
		workflow,
		context: KEYWORDS.URI_CONTEXT.AUTO_SYNC_CONSUMED_ITEMS,
	});
	if (!uri) return null;

	try {
		//Set auto sync
		yield put({
			type: CONSUME_INVENTORY_TYPES.SET_AUTO_SYNC,
			payload: { active: false, isFetching: true },
		});
		//Call to api & get success consumed items
		const { data: successConsumedItems } = yield call(
			axios.post,
			process.env.REACT_APP_API_URL.concat(uri),
			{ consumedItems },
			auth.sendToken(),
		);
		//Get offline consumed items
		const offlineConsumedItems =
			yield ConsumeInventoryUtils.reloadOfflineConsumedItems();
		//Combine consumed items with success consumed items
		const combinedConsumedItems = ConsumeInventoryUtils.combineConsumedItems(
			offlineConsumedItems,
			successConsumedItems,
		);
		//Update redux state
		yield put({
			type: CONSUME_INVENTORY_TYPES.SAVE_CONSUMED_INVENTORY_SUCCESS,
			payload: { orderId, consumedItems: combinedConsumedItems },
		});
		//Update offline consumed items
		yield ConsumeInventoryUtils.mutateOfflineConsumedItems(
			combinedConsumedItems,
		);
		//Set auto sync
		yield put({
			type: CONSUME_INVENTORY_TYPES.SET_AUTO_SYNC,
			payload: { active: true, isFetching: false },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				//Get offline consumed items
				const offlineConsumedItems =
					yield ConsumeInventoryUtils.reloadOfflineConsumedItems();
				//Set error consumed items
				const errorConsumedItems =
					ConsumeInventoryUtils.setStatusToConsumedItems(
						consumedItems,
						KEYWORDS.STATUS.ERROR,
					);
				//Combine consumed items with error consumed items
				const combinedConsumedItems =
					ConsumeInventoryUtils.combineConsumedItems(
						offlineConsumedItems,
						errorConsumedItems,
					);
				//Update redux state
				yield put({
					type: CONSUME_INVENTORY_TYPES.SAVE_CONSUMED_INVENTORY_SUCCESS,
					payload: { orderId, consumedItems: combinedConsumedItems },
				});
				//Update offline consumed items
				yield ConsumeInventoryUtils.mutateOfflineConsumedItems(
					combinedConsumedItems,
				);
			},
			undefined,
		);
	}
}

function* generateResumeWorker() {
	const workflow = yield select(selectWorkflow);
	const orderId = yield select(selectCompleteOrderId);

	//Get URI
	const uri = ConsumeInventoryUtils.getURI({
		orderId,
		workflow,
		context: KEYWORDS.URI_CONTEXT.GENERATE_RESUME,
	});
	if (!uri) return null;

	try {
		//Set isFetching
		yield put({
			type: CONSUME_INVENTORY_TYPES.MUTATE_RESUME_CONTROL,
			payload: { status: KEYWORDS.STATUS.LOADING },
		});
		//Call to api & generate resume
		yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(uri),
			auth.sendToken(),
		);
		yield put({
			type: CONSUME_INVENTORY_TYPES.MUTATE_RESUME_CONTROL,
			payload: { status: KEYWORDS.STATUS.SUCCESS },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: CONSUME_INVENTORY_TYPES.MUTATE_RESUME_CONTROL,
					payload: { status: KEYWORDS.STATUS.ERROR },
				});
			},
			function* () {
				yield generateResumeWorker();
			},
		);
	}
}

//  Export default Root Saga
export default function* rootSaga() {
	yield all([
		getCurrentConsumedItemsWatcher(),
		saveConsumedItemsWatcher(),
		autoSyncConsumedItemsWatcher(),
		generateResumeWatcher(),
	]);
}
