// Libs
import Raven from 'raven-js';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
// NPM Package
import npmPackage from '../../package.json';
// Check production env
const env_check = process.env.NODE_ENV === 'production';

export default class SentryService {
	static run() {
		if (env_check) {
			// Sentry
			Sentry.init({
				dsn: process.env.REACT_APP_SENTRY_DSN,

				// Alternatively, use `process.env.npm_package_version` for a dynamic release version
				// if your build tool supports it.
				release: npmPackage.version,
				integrations: [new Integrations.BrowserTracing()],

				// Set tracesSampleRate to 1.0 to capture 100%
				// of transactions for performance monitoring.
				// We recommend adjusting this value in production
				tracesSampleRate: 1.0,
			});
			// Raven
			Raven.config(process.env.REACT_APP_SENTRY_DSN, {
				release: npmPackage.version, // process.env.REACT_APP_SENTRY_RELEASE_TRACKING
				autoBreadcrumbs: true, // Enable autoBreadcrumbs
				ignoreErrors: [
					// onsen-ui
					'Another splitter-side action is already running',
					// Play music
					"NotAllowedError: play() failed because the user didn't interact with the document first",
					// Abort music
					'AbortError: The play() request was interrupted by a call to pause()',
					// User not permission
					'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
				],
			}).install();
		}
	}
	static reduxMiddleware = () => (next) => (action) => {
    try {
      return next(action);
    } catch (error) {
      this.sendError(error);
      throw error;
    }
  };

	// Send error to sentry
	static sendError(err, props) {
		if (env_check) {
			Raven.captureException(err, props);
		}
	}
}
