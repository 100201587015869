// Libs
import { selectProfile } from 'screens/Login/selectors';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
// Utils
import GENERAL from 'utils/constants/general';
import ApiUtils from './utils';
const { ENV } = GENERAL;

const selectApi = state => state.api;
const selectOrders = state => state.orders;
const selectCollector = state => state.collector;
const selectLogin = state => state.login;

export const selectStates = createSelector([selectApi], api => ({
	...api.states,
	data: Immutable.List(api.states.data).toJS(),
}));

export const selectEvents = createSelector([selectApi], api => ({
	...api.events,
	data: Immutable.List(api.events.data).toJS(),
}));

export const selectUsers = createSelector([selectApi], api => ({
	...api.users,
	data: Immutable.List(api.users.data).toJS(),
}));

export const selectDepartments = createSelector([selectApi], api => ({
	...api.departments,
	data: Immutable.List(api.departments.data).toJS(),
}));

export const selectMyDepartments = createSelector(
	[selectDepartments, selectProfile],
	({ data }, profile) =>
		data.filter(d => profile?.user?.assignment?.projects.indexOf(d.id) !== -1),
);

export const selectCoins = createSelector([selectApi], api => ({
	...api.coins,
	data: Immutable.List(api.coins.data).toJS(),
}));

export const selectZones = createSelector([selectApi], api => ({
	...api.zones,
	data: Immutable.List(api.zones.data).toJS(),
}));

export const selectRoles = createSelector([selectApi], ({ roles }) =>
	Immutable.List(roles).toJS(),
);
export const selectPermissions = createSelector(
	[selectApi],
	({ permissions }) => Immutable.List(permissions).toJS(),
);

export const selectTownships = createSelector([selectZones], zones =>
	zones.data.reduce((acc, value) => {
		if (acc.findIndex(t => t.id === value.township_id) === -1) {
			acc.push({
				id: value.township_id,
				name: value.township_name,
				cityId: value.city_id,
			});
		}

		return acc;
	}, []),
);

export const selectCities = createSelector([selectZones], zones =>
	zones.data.reduce((acc, value) => {
		if (acc.findIndex(c => c.id === value.city_id) === -1) {
			acc.push({
				id: value.city_id,
				name: value.city_name,
			});
		}

		return acc;
	}, []),
);

export const selectStructuredLocations = createSelector(
	[selectZones, selectCities, selectTownships],
	(zones, cities, townships) =>
		cities.reduce((acc, city) => {
			if (acc.findIndex(c => c.id === city.id) === -1) {
				const subs = townships.filter(ts => ts.cityId === city.id);

				subs.forEach(ts => {
					ts.subs = zones.data.reduce((ac, zone) => {
						if (
							ac.findIndex(zn => zone.zone_id === zn.id) === -1 &&
							zone.city_id === city.id &&
							zone.township_id === ts.id
						) {
							ac.push({
								id: zone.zone_id,
								name: zone.zone_name,
							});
						}

						return ac;
					}, []);
				});

				acc.push({
					id: city.id,
					name: city.name,
					subs,
				});
			}

			return acc;
		}, []),
);

export const selectServices = createSelector([selectApi], api => ({
	...api.services,
	data: Immutable.List(api.services.data).toJS(),
}));

export const selectClients = createSelector([selectApi], api => ({
	...api.clients,
	data: Immutable.List(api.clients.data).toJS(),
}));

export const selectClientCategories = createSelector([selectApi], api => ({
	...api.clientCategories,
	data: Immutable.List(api.clientCategories.data).toJS(),
}));

export const selectContracts = createSelector([selectApi], api => ({
	loading: api.contracts.loading,
	data: Immutable.List(api.contracts.data).toJS(),
}));

export const selectContractTypes = createSelector([selectApi], api => ({
	...api.contractTypes,
	data: Immutable.List(api.contractTypes.data).toJS(),
}));

// export const selectContractFrequencies = createSelector([selectApi], (api) => ({
//   ...api.contracts.frequencies,
//   data: Immutable.List(api.contracts.frequencies.data).toJS(),
// }));

export const selectTemplates = createSelector([selectApi], ({ templates }) => ({
	...templates,
	data: Immutable.List(templates.data).toJS(),
}));

export const selectCreateOrderTemplates = createSelector(
	[selectTemplates, selectLogin],
	(templates, { profile }) => ({
		...templates,
		data: ApiUtils.getTemplatesByType({
			templates: templates.data,
			type: ENV.TEMPLATES.TYPES.CREATE_ORDER,
			profile,
		}),
	}),
);

export const selectImportOrderTemplates = createSelector(
	[selectTemplates, selectLogin],
	(templates, { profile }) => ({
		...templates,
		data: ApiUtils.getTemplatesByType({
			templates: templates.data,
			type: ENV.TEMPLATES.TYPES.IMPORT_ORDERS,
			profile,
		}),
	}),
);

export const selectAuditOrderTemplates = createSelector(
	[selectTemplates, selectLogin],
	(templates, { profile }) => ({
		...templates,
		data: ApiUtils.getTemplatesByType({
			templates: templates.data,
			type: ENV.TEMPLATES.TYPES.AUDIT_TO_ORDER,
			profile,
		}),
	}),
);

export const selectReviewLayouts = createSelector(
	[selectApi, selectOrders, selectCollector],
	({ templates }, { control }, { reviewManage }) => {
		// Get Selected Order
		const order = control.orders.find(
			ord => ord.order_id === reviewManage.orderId,
		);
		if (!order) return [];

		if (reviewManage.collectorLayout.length > 0) {
			return Immutable.List(reviewManage.collectorLayout).toJS();
		}

		const docId = reviewManage.transactionDocs.docId;
		if (docId) {
			const auditedOTD = reviewManage.transactionDocs.data.find(
				otd => otd.id === docId,
			);
			const template = templates.data.find(
				t => t.id === auditedOTD?.templateId,
			);
			const collectorLayout = template?.data_structure_object;
			if (!Array.isArray(collectorLayout)) return [];

			const filteredCollectorLayout = collectorLayout.filter(
				r => !r.serviceId || Number(r.serviceId) === Number(order.service_id),
			);

			return filteredCollectorLayout;
		}

		return [];
	},
);
