// Libs
import React from 'react';
import { connect } from 'react-redux';
// Utils
import GENERAL from 'utils/constants/general';
// Actions
import {
	onSearchClient,
	onCreateClient,
	mutate1Object as mutate1ObjectInClients,
} from 'screens/Clients/actions';
import { onCreateOneOrder } from 'screens/Orders/actions';
import {
	getClientCategories,
	getContractsByClientId,
	getClientsWithQueryVariant,
	onGetTemplates,
	getServices,
} from 'store/api/actions';
// Selectors
import {
	selectServices,
	selectContracts,
	selectClients,
	selectCreateOrderTemplates,
	selectClientCategories,
} from 'store/api/selectors';
import { selectMyEntityActiveUsers } from 'screens/Users/selectors';
import { selectEnterNewOrdersModal } from 'screens/Orders/selectors';
import { selectCreateClient } from 'screens/Clients/selectors';
// Tabs
import CreateOrder from './CreateOrder';
import CreateClient from './CreateClient';
import CreateContract from './CreateContract';

const { ENV } = GENERAL;

function CreateOrderTab({
	// Props
	clientCategories,
	clients,
	services,
	contracts,
	templates,
	users,
	enterNewOrdersModal,
	createClient,
	// Actions
	getClientCategories,
	getContractsByClientId,
	getClientsWithQueryVariant,
	onGetTemplates,
	onSearchClient,
	getServices,
	onCreateOneOrder,
	onCreateClient,
	mutate1ObjectInClients,
}) {
	const [myState, setMyState] = React.useState({
		activeForm: ENV.ORDERS.CREATE.TABS.CREATE_ORDER,
		templateId: undefined,
		clientId: undefined,
	});
	const myChangeState = newState => setMyState({ ...myState, ...newState });

	// Get departmentId
	const departmentId = React.useMemo(
		() => templates.data.find(t => t.id === myState.templateId)?.departmentId,
		[myState.templateId, templates.data],
	);

	React.useEffect(() => {
		if (departmentId > 0) getServices(departmentId);
	}, [departmentId]);

	return (
		<>
			{myState.activeForm === ENV.ORDERS.CREATE.TABS.CREATE_ORDER && (
				<CreateOrder
					// Props
					selectedTemplateId={myState.templateId}
					selectedClientId={myState.clientId}
					departmentId={departmentId}
					templates={templates}
					clients={clients}
					services={services}
					contracts={contracts}
					users={users}
					enterNewOrdersModal={enterNewOrdersModal}
					// Actions
					myChangeState={myChangeState}
					getClientCategories={getClientCategories}
					getContractsByClientId={getContractsByClientId}
					onSearchClient={onSearchClient}
					onGetTemplates={onGetTemplates}
					getClientsWithQueryVariant={getClientsWithQueryVariant}
					onCreateOneOrder={onCreateOneOrder}
				/>
			)}
			{myState.activeForm === ENV.ORDERS.CREATE.TABS.CREATE_CLIENT && (
				<CreateClient
					clientCategories={clientCategories}
					myChangeState={myChangeState}
					onCreateClient={onCreateClient}
					createClient={createClient}
					mutate1ObjectInClients={mutate1ObjectInClients}
				/>
			)}
			{myState.activeForm === ENV.ORDERS.CREATE.TABS.CREATE_CONTRACT && (
				<CreateContract
					myChangeState={myChangeState}
					selectedClientId={myState.clientId}
					departmentId={departmentId}
				/>
			)}
		</>
	);
}

const mapStateToProps = state => ({
	clients: selectClients(state),
	services: selectServices(state),
	contracts: selectContracts(state),
	templates: selectCreateOrderTemplates(state),
	users: selectMyEntityActiveUsers(state),
	clientCategories: selectClientCategories(state),
	enterNewOrdersModal: selectEnterNewOrdersModal(state),
	createClient: selectCreateClient(state),
});

const actions = {
	getClientCategories,
	getContractsByClientId,
	getClientsWithQueryVariant,
	onGetTemplates,
	onSearchClient,
	getServices,
	onCreateOneOrder,
	onCreateClient,
	mutate1ObjectInClients,
};

export default connect(mapStateToProps, actions)(CreateOrderTab);
