// Libs
import React from 'react';
import { connect } from 'react-redux';
// Utils
import CoreUtils from '../utils';
import { debounce } from 'utils/libs';
// Actions
import {
	mutate1Object as mutate1ObjectInCore,
	getLatestDataFromProcessedSQL,
	setDatasource,
	resetDatasource,
} from '../actions';
// Selectors
import {
	selectSelectedProjectId,
	selectSelectedOrderTargetFilterId,
	selectSelectedServiceIds,
	selectSelectedDataFilterId,
	selectDataFilters,
	selectDataFilterUserParams,
	selectSelectedDataFilterLastRowId,
	selectReloadDatasource,
	selectSearchDatasource,
} from 'core/selectors';
import { selectSelectedSubModule } from 'components/Modules/selectors';
// Filter pickers
import {
	ProjectPicker,
	OrderTargetFilterPicker,
	ServicePicker,
	DatasourceAccessPicker,
	ParamFilterPicker,
	ModulePicker,
} from './filterPickers';

const DatasourceBuilder = ({
	// State
	selectedProjectId,
	selectedOrderTargetFilterId,
	selectedServiceIds,
	selectedSubModule,
	searchDatasource,
	dataFilters,
	selectedDataFilterId,
	isReloadDatasource,
	dataFilterLastRowId,
	dataFilterUserParams,
	// Actions
	mutate1ObjectInCore,
	getLatestDataFromProcessedSQL,
	setDatasource,
	resetDatasource,
}) => {
	// Get data filter config
	const dataFilterItem = React.useMemo(
		() => dataFilters.find(df => df.id === selectedDataFilterId),
		[selectedDataFilterId, dataFilters],
	);

	// Get onEventActions
	const onEventActions = React.useMemo(
		() =>
			CoreUtils.onGettedDataEvents({
				dataFilterLastRowId,
				mutate1ObjectInCore,
				setDatasource,
				resetDatasource,
			}),
		[dataFilterLastRowId],
	);

	// Must fetch data
	const fetchDebouncedData = React.useCallback(
		debounce(callback => callback(), 200),
		[],
	);

	React.useEffect(() => {
		fetchDebouncedData(() => {
			if (
				CoreUtils.checkMustFetchData({
					isReloadDatasource,
					// User params
					dataFilterUserParams,
					subDataFilters: dataFilterItem?.filters,
				})
			) {
				mutate1ObjectInCore('datasource', { reload: false });
				getLatestDataFromProcessedSQL({
					selectedDataFilterId,
					limit: dataFilterItem?.config?.limit,
					lastRowId: dataFilterLastRowId,
					searchValue: searchDatasource.value,
					userParams: {
						...dataFilterUserParams,
						selectedProjectId,
						selectedOrderTargetFilterId,
						selectedServiceIds,
					},
					...onEventActions,
				});
			}
		});
	}, [isReloadDatasource, dataFilterUserParams]);

	return (
		<>
			{/* MODULES */}
			<ModulePicker />
			{selectedSubModule && (
				<>
					{/* PROJECTS */}
					<ProjectPicker moduleItemId={selectedSubModule.id} />
					{/* ORDER TARGETS */}
					<OrderTargetFilterPicker moduleItemId={selectedSubModule.id} />
					{/* SERVICES */}
					<ServicePicker />
					{/* CUSTOM DATA FILTERS */}
					<DatasourceAccessPicker moduleItemId={selectedSubModule.id} />
					{/* CUSTOM SUB DATA FILTERS */}
					<ParamFilterPicker />
				</>
			)}
		</>
	);
};

const mapStateToProps = state => ({
	selectedProjectId: selectSelectedProjectId(state),
	selectedOrderTargetFilterId: selectSelectedOrderTargetFilterId(state),
	selectedServiceIds: selectSelectedServiceIds(state),
	searchDatasource: selectSearchDatasource(state),
	dataFilters: selectDataFilters(state),
	selectedDataFilterId: selectSelectedDataFilterId(state),
	isReloadDatasource: selectReloadDatasource(state),
	dataFilterLastRowId: selectSelectedDataFilterLastRowId(state),
	dataFilterUserParams: selectDataFilterUserParams(state),
	selectedSubModule: selectSelectedSubModule(state),
});

const actions = {
	mutate1ObjectInCore,
	getLatestDataFromProcessedSQL,
	setDatasource,
	resetDatasource,
};

export default connect(mapStateToProps, actions)(DatasourceBuilder);
