// Libs
import React from 'react';
import { Avatar, Card } from 'antd';
import { CloudStorage } from 'components/AppConfig.json';
// Styles
import '../../VirtualizedList.css';
import './IncomeOrderHistoryCard.css';

const { Meta } = Card;

export default function IncomeOrderHistoryCard(props) {
	const { details, entity_name, creator_name, created_by, created_at } = props;
	const receivedOrdersDetails = [];
	const processedOrdersDetails = [];

	if (details && details.receivedOrdersDetails) {
		for (const record in details.receivedOrdersDetails) {
			receivedOrdersDetails.push({
				concept: record,
				value: details.receivedOrdersDetails[record],
			});
		}
	}

	if (details && details.processedOrdersDetails) {
		for (const record in details.processedOrdersDetails) {
			if (record !== 'Documento')
				processedOrdersDetails.push({
					concept: record,
					value: details.processedOrdersDetails[record],
				});
		}
	}

	return (
		<Meta
			avatar={
				<Avatar
					size='normal'
					avatar={{
						src: CloudStorage.concat(
							`/syn4pse/users/avatars/${
								created_by ? `${created_by}.jpg` : `default.svg`
							}`,
						),
					}}
				/>
			}
			title={
				<div className='container-title-card'>
					<h4 className='card-creator'>
						<b>{creator_name}</b>
						<span className='card-company'>({entity_name})</span>
					</h4>
					<span className='card-created-at'>{created_at}</span>
				</div>
			}
			description={
				<div className='income_orders-container-description-card'>
					{/* Document ID */}
					{details &&
						details.processedOrdersDetails &&
						details.processedOrdersDetails.Documento && (
							<div className='card-assign'>
								Documento: <b>{details.processedOrdersDetails.Documento}</b>
							</div>
						)}
					<div className='container-details'>
						{/* RECEIVED RECORDS */}
						{details && details.receivedOrdersDetails && (
							<div className='card-details'>
								Registros recibidos:
								<ul>
									{receivedOrdersDetails.map((record, idx) => (
										<li
											key={idx}
											style={{ listStyle: 'none' }}
											className='card-assign'
										>
											{' '}
											{record.concept}: <b>{record.value}</b>
										</li>
									))}
								</ul>
							</div>
						)}
						{/* PROCESSED RECORDS */}
						{details && details.processedOrdersDetails && (
							<div className='card-details'>
								Registros procesados:
								<ul>
									{processedOrdersDetails.map((record, idx) => (
										<li
											key={idx}
											style={{ listStyle: 'none' }}
											className='card-assign'
										>
											{' '}
											{record.concept}: <b>{record.value}</b>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
				</div>
			}
		/>
	);
}
