// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		style={{ cursor: 'pointer' }}
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='adminIcon'
			style={{ maskType: 'luminance' }}
			maskUnits='userSpaceOnUse'
			x='1'
			y='1'
			width='23'
			height='23'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.084 5.04872C15.084 7.28902 13.289 9.09745 11.0487 9.09745C8.80843 9.09745 7 7.28902 7 5.04872C7 2.80843 8.80843 1 11.0487 1C13.289 1 15.084 2.80843 15.084 5.04872ZM15.4303 12.9773C13.7379 12.3803 11.8471 12.0564 10.4429 12.0067L10.447 12C10.386 12 10.3238 12.0006 10.2604 12.0018C10.1971 12.0006 10.1348 12 10.0738 12L10.0738 12.0069C6.83067 12.1228 1 13.699 1 16.7235V20.0974H12.0855C12.0292 19.7399 12 19.3734 12 19C12 16.4379 13.3765 14.1973 15.4303 12.9773ZM22.74 19C22.74 19.17 22.72 19.33 22.7 19.49L23.77 20.32C23.86 20.4 23.89 20.53 23.83 20.64L22.83 22.37C22.76 22.48 22.63 22.52 22.52 22.48L21.27 21.98C21.02 22.18 20.73 22.34 20.43 22.47L20.24 23.79C20.22 23.91 20.11 24 19.99 24H17.99C17.86 24 17.76 23.91 17.74 23.79L17.55 22.47C17.24 22.35 16.96 22.18 16.7 21.98L15.46 22.48C15.34 22.52 15.21 22.48 15.15 22.37L14.15 20.64C14.09 20.53 14.12 20.4 14.21 20.32L15.27 19.49C15.25 19.33 15.24 19.17 15.24 19C15.24 18.83 15.25 18.67 15.27 18.51L14.21 17.68C14.11 17.6 14.09 17.47 14.15 17.36L15.15 15.63C15.22 15.52 15.35 15.48 15.46 15.52L16.7 16.02C16.96 15.82 17.25 15.66 17.55 15.53L17.74 14.21C17.76 14.09 17.87 14 17.99 14H19.99C20.11 14 20.22 14.09 20.23 14.21L20.42 15.53C20.73 15.65 21.01 15.82 21.27 16.02L22.51 15.52C22.63 15.48 22.76 15.52 22.82 15.63L23.82 17.36C23.88 17.47 23.85 17.6 23.76 17.68L22.7 18.51C22.73 18.67 22.74 18.83 22.74 19ZM17.49 19C17.49 19.83 18.16 20.5 18.99 20.5C19.82 20.5 20.49 19.83 20.49 19C20.49 18.17 19.82 17.5 18.99 17.5C18.16 17.5 17.49 18.17 17.49 19Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#adminIcon)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
