// Libs
import React from 'react';
import _ from 'underscore';
import {
	Wrapper,
	Span,
	Collapse as _Collapse,
	Spin,
	Checkbox,
	TextField,
} from 'components';
// Styles
import '../../index.css';

const { Collapse, Panel } = _Collapse;
const { Input } = TextField;

export function HeaderOrdersReassignmentModal({
	ordersReassignmentModal,
	mutate1Object,
}) {
	const { userSearching } = ordersReassignmentModal;

	return (
		<Wrapper padding='0 30px 0 0' justifyContent='space-between'>
			{/* REASSIGN TO */}
			<Span minWidth='75px' margin='0 10px 0 0' color='white' fontSize='l'>
				Reasignar a
			</Span>
			{/* SEARCH USER */}
			<Input
				autoFocus
				placeholder='Buscar'
				size='small'
				// margin="0 0 5px 0"
				value={userSearching}
				onChange={e =>
					mutate1Object('ordersReassignmentModal', {
						userSearching: e.target.value,
					})
				}
			/>
		</Wrapper>
	);
}

// EVENTS
function onChangeCollapse(
	collapseKey,
	collapseActiveKeyActual,
	userId,
	mutate1Object,
	getCountOrdersToReassignment,
	data,
) {
	const collapseActiveKey =
		collapseKey !== collapseActiveKeyActual ? collapseKey : -1;

	mutate1Object('ordersReassignmentModal', {
		collapseActiveKey,
	});

	if (collapseActiveKey !== -1) {
		const idx = _.findIndex(data, item => item.assigned_tech_id === userId);
		if (idx === -1) getCountOrdersToReassignment([userId]);
	}
}

const Service = ({ item }) => (
	<Wrapper width='100%' justifyContent='space-between'>
		<Span fontSize='l' color='mediumseagreen'>
			{item.service_name}
		</Span>
		<Span fontSize='l' color='mediumseagreen'>
			{item.count_orders}
		</Span>
	</Wrapper>
);

export default function OrdersReassignmentModal(props) {
	const {
		ordersReassignmentModal,
		users,
		mutate1Object,
		getCountOrdersToReassignment,
		onReassignment,
	} = props;
	const {
		collapseActiveKey,
		loading,
		data,
		assignment,
		filterUsers,
		viewTotalUsers,
		userSearching,
	} = ordersReassignmentModal;

	const filteredByViewTotal = React.useMemo(
		() =>
			!viewTotalUsers && filterUsers.length > 0
				? users.filter(user => filterUsers.indexOf(user.id) !== -1)
				: users,
		[viewTotalUsers, users, filterUsers],
	);
	const filteredUsers = React.useMemo(
		() =>
			userSearching?.length > 0
				? filteredByViewTotal.filter(user =>
						user.name.toUpperCase().includes(userSearching.toUpperCase()),
				  )
				: filteredByViewTotal,
		[filteredByViewTotal, userSearching],
	);

	return (
		<Wrapper
			maxHeight={`${document.documentElement.clientHeight * 0.8}px`}
			flexDirection='column'
			overflow='auto'
			alignItems='flex-start'
		>
			<Checkbox
				fontSize='l'
				checked={viewTotalUsers}
				onChange={() =>
					mutate1Object('ordersReassignmentModal', {
						viewTotalUsers: !viewTotalUsers,
					})
				}
			>
				Mostrar Todos
			</Checkbox>

			{filteredUsers.map((user, collapseKey) => (
				<Wrapper
					key={collapseKey}
					width='100%'
					padding='0'
					justifyContent='space-between'
					alignItems='baseline'
				>
					{/* USER COLLAPSE */}
					<Collapse
						key={collapseKey}
						accordion
						bordered={false}
						defaultActiveKey={['-1']}
						activeKey={[collapseActiveKey]}
						onChange={() =>
							onChangeCollapse(
								collapseKey,
								collapseActiveKey,
								user.id,
								mutate1Object,
								getCountOrdersToReassignment,
								data,
							)
						}
					>
						<Panel
							key={collapseKey}
							header={
								<Wrapper padding='0' justifyContent='space-between'>
									<Span
										fontSize='l'
										color='rgba(255, 255, 255, 0.9)'
										hoverColor='white'
									>
										{user.name}
									</Span>
								</Wrapper>
							}
						>
							<Spin
								spinning={loading}
								size='large'
								delay={50}
								tip={'Obteniendo datos...'}
							>
								<Wrapper flexDirection='column' alignItems='flex-start'>
									<h4 style={{ color: 'gray' }}>Factibles</h4>
									{data
										.filter(
											item =>
												item.assigned_tech_id === user.id &&
												item.category === 'realizable',
										)
										.map((item, idx) => (
											<Service key={idx} item={item} />
										))}

									<h4 style={{ color: 'gray' }}>Afectadas</h4>
									{data
										.filter(
											item =>
												item.assigned_tech_id === user.id &&
												item.category === 'affectation',
										)
										.map((item, idx) => (
											<Service key={idx} item={item} />
										))}
								</Wrapper>
							</Spin>
						</Panel>
					</Collapse>
					{/* REASSIGN BUTTON */}
					<Span
						fontSize='l'
						cursorPointer
						color='burlywood'
						hoverColor='lightcyan'
						onClick={() => onReassignment(user)}
					>
						{assignment === 'local' ? 'Asignar' : 'Reasignar'}
					</Span>
				</Wrapper>
			))}
		</Wrapper>
	);
}
