// Libs
import React from 'react';
import styled from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Utils
import GENERAL from 'utils/constants/general';
// Components
import Claro16 from './Claro/Claro16';
import Claro24 from './Claro/Claro24';
import Ice16 from './Ice/Ice16';
import Ice24 from './Ice/Ice24';

const { DEPARTMENTS } = GENERAL.ENV;

const heightSizes = {
	small: '16px',
	normal: '24px',
};

const IconContainer = styled.div`
	height: ${({ size }) => heightSizes[size]};
	margin-right: 10px;
	text-align: center;
`;

/**
 * Step 1: Add your new project icon object sizes
 */
const PROJECT_ICONS = {
	[DEPARTMENTS.ICON.ICONS.CLARO]: {
		small: Claro16,
		normal: Claro24,
	},
	[DEPARTMENTS.ICON.ICONS.ICE]: {
		small: Ice16,
		normal: Ice24,
	},
	// <--HERE
};

const ProjectIcon = ({ value, isSelected, size = 'small' }) => {
	const { theme } = useTheme();
	const fill = React.useMemo(
		() => (isSelected ? theme._secondaryColor : theme._mediumEmphasisColor),
		[isSelected],
	);

	const Icon = PROJECT_ICONS[value]?.[size];
	if (!Icon) return null;

	return (
		<IconContainer size={size}>
			<Icon fill={fill} />
		</IconContainer>
	);
};

export default ProjectIcon;
