import { useEffect, useState, useRef } from 'react';

export default function useNearScreen({
	root,
	rootMargin,
	threshold,
	externalRef,
	observeOnce = true,
	observeChildren = false,
	onIntersection,
} = {}) {
	const [isNearScreen, setNearScreen] = useState(false);
	const [intersectionRatio, setIntersectionRatio] = useState(0);
	const fromRef = useRef();

	useEffect(() => {
		let observer;

		const element = externalRef ? externalRef.current : fromRef.current;

		function defaultOnIntersection(entries) {
			const el = entries[0];

			if (el.isIntersecting) {
				setNearScreen(true);
				setIntersectionRatio(el.intersectionRatio);
				observeOnce && observer.disconnect();
			} else if (!observeOnce) {
				setNearScreen(false);
				setIntersectionRatio(el.intersectionRatio);
			}
		}

		observer = new IntersectionObserver(
			onIntersection !== undefined ? onIntersection : defaultOnIntersection,
			{
				root,
				rootMargin,
				threshold,
			},
		);

		if (observeChildren && root?.children?.length) {
			Array.from(root?.children)?.forEach(child => observer.observe(child));
		} else if (element) {
			observer.observe(element);
		}

		return () => observer?.disconnect();
	});

	return [isNearScreen, fromRef, intersectionRatio];
}
