// Libs
import React from 'react';
// Components
import {
	BarChart,
	Bar,
	ResponsiveContainer,
	CartesianGrid,
	XAxis,
	YAxis,
} from 'recharts';
// Styles
import styles from './TinyBar.module.css';

/**
 * See data structure example:
 * https://recharts.org/en-US/examples/TinyBarChart
 */

const TinyBar = ({ data, config = {} }) => {
	const {
		values = ['value'],
		width = 600,
		height = 400,
		colors,
		xDataKey = 'name',
	} = config;
	return (
		<div className={styles.barContainer} style={{ width, height }}>
			<ResponsiveContainer width='100%' height='100%'>
				<BarChart width={width} height={height} data={data}>
					<CartesianGrid strokeDasharray='5 5' />
					<XAxis dataKey={xDataKey} />
					<YAxis />
					<Bar dataKey={values[0]} fill={colors[0]} />
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default TinyBar;
