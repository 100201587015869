// Libs
import React from 'react';
// Services
import { SentryService } from 'services';
// Utils
import { PhotoUtils } from 'utils/libs';
import { formatDate } from 'utils/libs/dateFormats';
import UploadResourceUtils from './UploadResourceUtils';
// Components
import { AddIcon } from 'components';
import { PhotoManager, PhotoZipper } from 'components/PhotoManager';

const onDownloadPhoto = ({ filename, file, profile, isPhotoBackupActive }) => {
	if (!isPhotoBackupActive) return;
	return UploadResourceUtils.downloadPhoto(
		formatDate(filename, profile, 'YYYY-MM-DD.HH.mm.ss'),
		file,
	);
};

const CustomPhotoManager = ({
	authConfig,
	isPhotoBackupActive,
	fileProps,
	profile,
	setResource,
	uploadResource,
	onPhotoManagerClose,
	sendToast,
}) => {
	const onTakenPhotoConfirm = async ({
		originalPhoto,
		watermarkedPhoto,
		coords,
		onError,
	}) => {
		const downloadProps = {
			profile,
			isPhotoBackupActive,
			filename: fileProps.createdAt,
			file: watermarkedPhoto,
		};
		try {
			const zipStrategy = authConfig.isPicaStrategy
				? PhotoZipper.getStrategies().PICA
				: authConfig.isPhotonStrategy
				? PhotoZipper.getStrategies().PHOTON
				: null;

			const canvasOrImgPhoto =
				zipStrategy === PhotoZipper.getStrategies().PICA
					? await PhotoUtils.loadImageFromCanvas(originalPhoto)
					: originalPhoto;

			await new PhotoZipper(zipStrategy, authConfig)
				.zip(canvasOrImgPhoto)
				.then(zippedBase64Photo => {
					setResource(prev => ({
						...prev,
						file: zippedBase64Photo,
						isLoading: true,
					}));
					uploadResource(
						UploadResourceUtils.addFileResource({
							file: zippedBase64Photo,
							fileProps,
							coords,
						}),
					);
					onDownloadPhoto(downloadProps);
				});
		} catch (err) {
			onDownloadPhoto(downloadProps);
			sendToast({ message: err.message, type: 'error' });
			if (onError) onError();
			SentryService.sendError(err);
		}
	};

	return (
		<PhotoManager
			videoSettings={{
				filename: fileProps.createdAt,
				width: authConfig.width,
				height: authConfig.height,
			}}
			onTakenPhotoConfirm={onTakenPhotoConfirm}
			onPhotoManagerClose={onPhotoManagerClose}
			profile={profile}
		/>
	);
};

const UploadResourceV2 = ({
	authConfig,
	isPhotoBackupActive,
	fileProps,
	profile,
	setResource,
	uploadResource,
	sendToast,
}) => {
	const [open, setOpen] = React.useState(false);

	const onPhotoManagerOpen = () => {
		if (!navigator.mediaDevices.getUserMedia) {
			sendToast({
				message:
					'Este navegador no soporta la interfaz mediaDevices. Por favor utilice otro navegador',
				type: 'error',
			});
			return;
		}
		setOpen(true);
	};
	const onPhotoManagerClose = () => setOpen(false);

	return (
		<>
			<AddIcon onClick={onPhotoManagerOpen} />
			{open && (
				<CustomPhotoManager
					authConfig={authConfig}
					isPhotoBackupActive={isPhotoBackupActive}
					fileProps={fileProps}
					profile={profile}
					setResource={setResource}
					uploadResource={uploadResource}
					onPhotoManagerClose={onPhotoManagerClose}
					sendToast={sendToast}
				/>
			)}
		</>
	);
};

export default UploadResourceV2;
