// Libs
import cx from 'classnames';
// Hooks
import useTheme from 'hooks/useTheme';
// Styles
import styled, { css } from 'styled-components';
import styles from './Wrapper.module.css';

const StyledWrapper = styled.div`
	position: ${({ position, theme }) => position || theme.wrapper.position};
	color: ${({ color }) => color};
	width: ${({ width, theme }) => width || theme.wrapper.width};
	max-width: ${({ maxWidth, theme }) => maxWidth || theme.wrapper.maxWidth};
	min-width: ${({ minWidth, theme }) => minWidth || theme.wrapper.minWidth};
	height: ${({ height, theme }) => height || theme.wrapper.height};
	min-height: ${({ minHeight, theme }) => minHeight || theme.wrapper.minHeight};
	max-height: ${({ maxHeight, theme }) => maxHeight || theme.wrapper.maxHeight};
	margin: ${({ margin, theme }) => margin || theme.wrapper.margin};
	padding: ${({ padding, theme }) => padding || theme.wrapper.padding};
	display: ${({ display, theme }) => display || theme.wrapper.display};
	flex-direction: ${({ flexDirection, theme }) =>
		flexDirection || theme.wrapper.flexDirection};
	flex-wrap: ${({ flexWrap, theme }) => flexWrap || theme.wrapper.flexWrap};
	justify-content: ${({ justifyContent, theme }) =>
		justifyContent || theme.wrapper.justifyContent};
	align-items: ${({ alignItems, theme }) =>
		alignItems || theme.wrapper.alignItems};
	align-self: ${({ alignSelf }) => alignSelf};
	overflow: ${({ overflow, theme }) => overflow || theme.wrapper.overflow};
	overflow-x: ${({ overflowX }) => overflowX && overflowX};
	overflow-y: ${({ overflowY }) => overflowY && overflowY};
	background-color: ${({ backgroundColor, theme }) =>
		backgroundColor || theme.wrapper.backgroundColor};
	border: ${({ border }) => border};
	border-radius: ${({ borderRadius, theme }) =>
		borderRadius || theme.wrapper.borderRadius};
	box-shadow: ${({ boxShadow, theme }) => boxShadow || theme.wrapper.boxShadow};
	box-sizing: ${({ boxSizing }) => boxSizing};
	transition: ${({ transition, theme }) =>
		transition || theme.wrapper.transition};
	top: ${({ top }) => top};
	bottom: ${({ bottom }) => bottom};
	left: ${({ left }) => left};
	right: ${({ right }) => right};
	z-index: ${({ zIndex }) => zIndex};
	backdrop-filter: ${({ backdropFilter }) => backdropFilter};
	opacity: ${({ opacity }) => opacity};
	gap: ${({ gap }) => gap};
	text-align: ${({ textAlign }) => textAlign};
	font-size: ${({ fontSize }) => fontSize};
	font-weight: ${({ fontWeight }) => fontWeight};
	cursor: ${({ cursor, theme }) => cursor || theme.wrapper.cursor};
	${({ highBackground }) =>
		highBackground &&
		css`
			background-color: ${({ $newTheme }) => $newTheme._primaryBackgroundColor};
		`}
	${({ mediumBackground }) =>
		mediumBackground &&
		css`
			background-color: ${({ $newTheme, $isDarkTheme }) =>
				$isDarkTheme
					? $newTheme._secondaryBackgroundColor
					: $newTheme._primaryBackgroundColor};
		`}
  ${({ disabledBackground }) =>
		disabledBackground &&
		css`
			background-color: ${({ $newTheme }) =>
				$newTheme._disabledBackgroundColor};
		`}
  ${({ hover }) =>
		hover &&
		css`
			&:hover {
				box-shadow: ${({ hover }) => hover?.boxShadow};
				background: ${({ theme }) => theme.colors.hover.color};
			}
		`};

	${({ lightScroll }) =>
		lightScroll &&
		css`
			&::-webkit-scrollbar {
				width: ${({ theme }) => theme.scrollbar.light.width};
				height: ${({ theme }) => theme.scrollbar.light.height};
			}
			&::-webkit-scrollbar-thumb {
				background: ${({ theme }) => theme.scrollbar.light.thumb.background};
				border-radius: ${({ theme }) =>
					theme.scrollbar.light.thumb.borderRadius};
				box-shadow: ${({ theme }) => theme.scrollbar.light.thumb.boxShadow};
			}
			&::-webkit-scrollbar-track {
				background: ${({ theme }) => theme.scrollbar.light.track.background};
			}
		`}
`;

export default props => {
	const { theme, sharedStyles, isDarkTheme } = useTheme();
	return (
		<StyledWrapper
			ref={props.elementRef}
			$newTheme={theme}
			$isDarkTheme={isDarkTheme}
			className={cx(
				styles.WrapperStyled,
				theme.transparentBackgroundColor,

				{
					[sharedStyles.ellipsis]: !!props.ellipsis,
					[sharedStyles.cursorPointer]: !!props.cursorPointer,
					[sharedStyles[`textAlign_${props.textAlign}`]]: !!props.textAlign,
				},
			)}
			{...props}
		>
			{props.children}
		</StyledWrapper>
	);
};
