// Libs
import styled from 'styled-components';
// Components
import { Checkbox } from 'antd';
import { Span } from 'components';

const CheckboxStyled = styled(Checkbox)`
	margin: ${props => props.margin || props.theme.checkbox.margin};
`;

export default props => (
	<CheckboxStyled {...props}>
		<Span>{props.children}</Span>
	</CheckboxStyled>
);
