// Libs
import React from 'react';
// Components
import { Wrapper, Title } from 'components';
import { LogoIcon } from '../Icons';

const SynapseFeedLoader = () => (
	<Wrapper
		className='animated fadeIn'
		padding='0'
		position='relative'
		width='700px'
		height='100%'
		justifyContent='center'
		flexDirection='column'
	>
		<LogoIcon spin size='l' />
		<Title margin='10px 0 0 0' fontSize='28px'>
			Synapse
		</Title>
	</Wrapper>
);

export default SynapseFeedLoader;
