// Libs
import { Collapse as _Collapse } from 'antd';
import styled, { css } from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';

const { Panel: _Panel } = _Collapse;

const StyledCollapse = styled(_Collapse)`
	background-color: ${({ $newTheme, $isDarkTheme, background }) =>
		!!background
			? background
			: $isDarkTheme
			? undefined
			: $newTheme._primaryBackgroundColor};
	width: ${props => (props.width ? props.width : props.theme.collapse.width)};
	padding: ${props =>
		props.padding ? props.padding : props.theme.collapse.padding};

	.ant-collapse-content-box {
		background-color: ${({ $newTheme, $isDarkTheme, background }) =>
			!!background
				? background
				: $isDarkTheme
				? undefined
				: $newTheme._primaryBackgroundColor} !important;
	}
	.ant-collapse-item {
		border-bottom: 1px solid ${({ $newTheme }) => $newTheme._borderColor} !important;
	}
`;

const Panel = styled(_Panel)`
	${props =>
		!props.transparentBackground &&
		css`
			.ant-collapse-content-box {
				background-color: ${props =>
					!!props.background
						? props.background
						: props.theme.colors.background.high};
			}
		`}
`;

const Collapse = props => {
	const { theme, isDarkTheme } = useTheme();

	return (
		<StyledCollapse $newTheme={theme} $isDarkTheme={isDarkTheme} {...props}>
			{props.children}
		</StyledCollapse>
	);
};

export default {
	Collapse,
	Panel,
};
