// Libs
import React, { createContext, useEffect, useState } from 'react';
// Components
import { IconStyle, PostStyle } from './Styles';
// Hooks
import useApi from './Utils';

const MergedPDFReportContext = createContext({});
const { Provider } = MergedPDFReportContext;

const MergedPDFReportProvider = ({ children, resourceId, typeMergeButton }) => {
	const { onGetMergedReportUrl, onGetMergedContractReportUrl } = useApi();
	const [state, _setState] = useState({
		isLoading: false,
		mergedPdfUrl: '',
		mergedPdfUrlAt: '',
	});

	const setState = newState =>
		_setState(current => ({
			...current,
			...newState,
		}));

	// State Handlers
	const setLoading = isLoading => {
		setState({ isLoading });
	};

	const onGetMergeReport = {
		serviceMergedReports: onGetMergedReportUrl,
		contractMergedReports: onGetMergedContractReportUrl,
	};

	// Initial State
	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		onGetMergeReport[typeMergeButton](resourceId)
			.then(({ mergedPdfUrl, mergedPdfUrlAt } = {}) => {
				if (!isMounted) return;
				setLoading(false);
				setState({ mergedPdfUrl, mergedPdfUrlAt });
			})
			.catch(() => {
				if (!isMounted) return;
				setLoading(false);
				setState({ mergedPdfUrl: false });
			});
		return () => {
			isMounted = false;
		};
	}, [resourceId, typeMergeButton]);

	return (
		<Provider
			value={{
				...state,
				resourceId,
				typeMergeButton,
				setLoading,
			}}
		>
			{children({
				isLoading: state.isLoading,
				mergedPdfUrl: state.mergedPdfUrl,
				mergedPdfUrlAt: state.mergedPdfUrlAt,
				setLoading,
			})}
		</Provider>
	);
};

const MergedPDFReport = ({
	resourceId,
	nameButton,
	typeMergeButton = 'serviceMergedReports',
	style,
}) => {
	if (!resourceId) return null;
	return (
		<MergedPDFReportProvider
			resourceId={resourceId}
			typeMergeButton={typeMergeButton}
		>
			{({ isLoading, mergedPdfUrl, mergedPdfUrlAt, setLoading }) =>
				style === 'postCard' ? (
					<PostStyle
						resourceId={resourceId}
						nameButton={nameButton}
						typeMergeButton={typeMergeButton}
						isLoading={isLoading}
						mergedPdfUrl={mergedPdfUrl}
						mergedPdfUrlAt={mergedPdfUrlAt}
						setLoading={setLoading}
					/>
				) : (
					<IconStyle
						resourceId={resourceId}
						nameButton={nameButton}
						typeMergeButton={typeMergeButton}
						isLoading={isLoading}
						mergedPdfUrl={mergedPdfUrl}
						setLoading={setLoading}
					/>
				)
			}
		</MergedPDFReportProvider>
	);
};

export { MergedPDFReport as default, MergedPDFReportContext };
