// Libs
import React from 'react';
// Components
import { Wrapper, Title, Button, LogoIcon } from 'components';

const HeaderPreview = ({ onContinue, onCancel }) => {
	const [isFetching, setIsFetching] = React.useState(false);

	return (
		<Wrapper width='100%' justifyContent='space-between'>
			<Title>Previsualización de órdenes</Title>
			<Wrapper padding='0'>
				<Button primary onClick={onCancel} margin='0 16px 0 0'>
					Cancelar
				</Button>
				{isFetching ? (
					<Wrapper padding='0'>
						<Title>Subiendo...</Title>
						<LogoIcon spin={true} />
					</Wrapper>
				) : (
					<Button primary onClick={() => onContinue(setIsFetching)}>
						Continuar
					</Button>
				)}
			</Wrapper>
		</Wrapper>
	);
};

export default HeaderPreview;
