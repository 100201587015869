// Libs
import styled from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Select as _Select } from 'antd';

const SelectStyled = styled(_Select)`
	width: ${props => (props.width ? props.width : props.theme.select.width)};
	margin: ${props => (props.margin ? props.margin : props.theme.select.margin)};
	padding: ${props =>
		props.padding ? props.padding : props.theme.select.padding};
	&.ant-select-selection__clear {
		border-radius: 10px;
	}
	&.ant-select-multiple .ant-select-selection-item {
		background: transparent;
	}
	&.ant-select-multiple .ant-select-selection-item-remove {
		color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
	}
	&.ant-select-focused:not(.ant-select-disabled) {
		border: 1px solid transparent;
	}
	&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border: 1px solid transparent;
	}
`;

const Select = props => {
	const { theme } = useTheme();
	return <SelectStyled {...props} $newTheme={theme} />;
};

Select.Option = SelectStyled.Option;
export default Select;
