//libs
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//Hooks
import { useToastMessage } from 'hooks';
//Components
import { TableVirtualized, EditIcon, InputNumber } from 'components';
//Utils
import { ApiServiceV2 } from 'services';
//Services
import { formatNumber, coinsUtils } from 'utils/libs';
//Action
import { amountUpdate } from 'screens/Billings/actions';
import PaymentAuthorization from 'modules/Payments/PaymentAuthorization';
//Selectors
import { selectProfile } from 'screens/Login/selectors';

const getRawValue = ({ dataKey, amount, charge, price, pays }) =>
	dataKey === 'amount'
		? amount
		: pays.getOrders !== 'orders_paid'
		? price.price
		: charge;

const { Cell } = TableVirtualized;
const AmountCell = ({ rowData, dataKey, pays, coins }) => {
	const dispatch = useDispatch();
	const profile = useSelector(selectProfile);
	const sendToast = useToastMessage();
	const inputRef = useRef(null);
	const { id, amount, price, charge } = rowData;
	const coinSymbol = coinsUtils.getDataByCoinId(
		price.coinId,
		'symbol',
		coins.data,
	);
	const [isEditing, setIsEditing] = useState(false);
	const [inputValue, setInputValue] = useState(null);
	const rawValue = getRawValue({ dataKey, amount, charge, price, pays });

	const submitAmountChange = () => {
		setIsEditing(false);
		if (!inputValue) return;

		ApiServiceV2.put({
			url: `/payments_orders/${id}/amount`,
			data: { newAmount: inputValue },
		})
			.then(() => {
				dispatch(amountUpdate(id, inputValue));
				sendToast({ message: 'Precio actualizado', type: 'success' });
			})
			.catch(err => sendToast({ message: err.message, type: err.type }));
	};

	const toggleEdit = () => setIsEditing(prevIsEditing => !prevIsEditing);

	useEffect(() => {
		if (isEditing) {
			setInputValue(rawValue);
			inputRef.current?.focus();
		}
	}, [isEditing]);

	if (isEditing) {
		return (
			<InputNumber
				ref={inputRef}
				style={{ width: '100%' }}
				defaultValue={inputValue}
				formatter={value =>
					`${coinSymbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				}
				onPressEnter={submitAmountChange}
				onBlur={toggleEdit}
				onChange={setInputValue}
			/>
		);
	}

	const formattedRawValue = formatNumber.new(rawValue, coinSymbol || '', 2);
	return (
		<Cell>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<span>{isEditing ? inputValue : formattedRawValue}</span>
				{dataKey === 'amount' &&
					new PaymentAuthorization(profile).checkEditOrderAmount() && (
						<EditIcon onClick={toggleEdit} />
					)}
			</div>
		</Cell>
	);
};

export default AmountCell;
