// Libs
import React from 'react';
// Utils
import { formatDate } from 'utils/libs/dateFormats';
// Components
import { Span } from 'components';

const StartAt = ({ startAt, profile }) => {
	const value = !startAt ? 'No configurado' : formatDate(startAt, profile);
	return (
		<Span size='m' color='mediumaquamarine'>
			{value}
		</Span>
	);
};

export default StartAt;
