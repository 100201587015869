// Utils
import GENERAL from 'utils/constants/general';
import KEYWORD_FEED from 'core/keywords';
// Components
import App from 'components/App';
import {
	Login,
	Signup,
	RecoveryPassword,
	Dashboard,
	Users,
	Orders,
	Billings,
	Warehouses,
	Analytics,
	Reports,
	Feed,
} from 'screens';
import {
	// PAYMENTS
	Payments,
	PaymentsPaycutsMobile,
	PaymentsPaycutsDesktop,
	PaymentsPaysMobile,
	PaymentsPaysDesktop,
	PaymentsLinesMobile,
	PaymentsLinesDesktop,
	PaymentsOrdersMobile,
	PaymentsOrdersDesktop,
	// INVOICES
	Invoices,
	InvoicesPaycutsMobile,
	InvoicesPaycutsDesktop,
	InvoicesPaysMobile,
	InvoicesPaysDesktop,
	InvoicesLinesMobile,
	InvoicesLinesDesktop,
	InvoicesOrdersMobile,
	InvoicesOrdersDesktop,
} from 'screens/Billings/subs';
import {
	WarehousesList,
	WarehousesListMobile,
	WarehousesListDesktop,
	StockArticlesMobile,
	StockArticlesDesktop,
	StockSeriesMobile,
	StockSeriesDesktop,
	Articles,
	ArticlesMobile,
	ArticlesDesktop,
	Transactions,
	TransactionsMobile,
	TransactionsDesktop,
	TransactionNewEntryMobile,
	TransactionNewEntryDesktop,
	TransactionInventoryMobile,
	TransactionInventoryDesktop,
	TransactionItemsMobile,
	TransactionItemsDesktop,
	TransactionReception,
	ControlSeries,
	ControlSeriesMobile,
	ControlSeriesDesktop,
} from 'screens/Warehouses/subs';
// import { Marketplace } from "components";
/// /TODO: DEPRECATED:
import ConflictImportOrders from 'screens/Orders/ConflictsImporting';
// Screens
import FetchingModules from './FetchingModules';
import NoModuleEstablished from './NoModuleEstablished';
// Utils
import KEYWORDS from './keywords';
import ModuleUtils from './utils';

const { ENV } = GENERAL;
const { ROUTES } = ENV;

const ONBOARDING_ROUTES = KEYWORDS.ROUTES.ONBOARDING;

const AppRoute = {
	path: ROUTES.PATHS.ROOT,
	component: App,
	routes: [],
};

// FETCHING MODULES
const fetchingModulesRoute = [
	{
		...AppRoute,
		routes: [
			{
				path: ONBOARDING_ROUTES.FETCHING_MODULES,
				component: FetchingModules,
			},
		],
	},
];

// NO MODULE ESTABLISHED
const noModuleEstablishedRoute = [
	{
		...AppRoute,
		routes: [
			// FETCHING MODULES
			{
				path: ONBOARDING_ROUTES.NO_MODULE_ESTABLISHED,
				component: NoModuleEstablished,
			},
		],
	},
];

// UNAUTHORIZED
const unauthorizedRoutes = [
	{
		...AppRoute,
		routes: [
			// LOGIN
			{
				path: ROUTES.PATHS.LOGIN,
				component: Login,
			},
			// SIGNUP
			{
				path: ROUTES.PATHS.SIGNUP,
				component: Signup,
			},
			// RECOVERY_PASSWORD
			{
				path: ROUTES.PATHS.RECOVERY_PASSWORD,
				component: RecoveryPassword,
			},
		],
	},
];

// AUTHORIZED
const authorizedRoutes = ({ modules, modeView }) => {
	const authRoutes = [];

	// ANALYTICS
	const analyticsModule = ModuleUtils.findModuleByKey(
		KEYWORDS.MODULES.KEYS.ANALYTICS,
		modules,
	);
	if (
		analyticsModule &&
		ModuleUtils.checkDeviceAccess(
			modeView,
			ModuleUtils.findModuleItemByComponentKey(
				KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
				KEYWORDS.MODULES.ITEMS.KEYS.ANALYTICS.CONTROL,
				analyticsModule.moduleItems,
			),
		)
	) {
		authRoutes.push({
			id: ROUTES.IDS.ANALYTICS,
			item: ROUTES.ITEMS.ANALYTICS,
			title: ROUTES.TITLES.ANALYTICS,
			path: ROUTES.PATHS.ANALYTICS,
			component: Analytics,
		});
	}

	// USERS
	const usersModule = ModuleUtils.findModuleByKey(
		KEYWORDS.MODULES.KEYS.USERS,
		modules,
	);
	if (
		usersModule &&
		ModuleUtils.checkDeviceAccess(
			modeView,
			ModuleUtils.findModuleItemByComponentKey(
				KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
				KEYWORDS.MODULES.ITEMS.KEYS.USERS.CONTROL,
				usersModule.moduleItems,
			),
		)
	) {
		authRoutes.push({
			id: ROUTES.IDS.USERS,
			item: ROUTES.ITEMS.USERS,
			path: ROUTES.PATHS.USERS,
			title: ROUTES.TITLES.USERS,
			component: Users,
		});
	}

	// ORDERS
	const ordersModule = ModuleUtils.findModuleByKey(
		KEYWORDS.MODULES.KEYS.ORDERS,
		modules,
	);

	if (
		ordersModule &&
		ModuleUtils.checkDeviceAccess(
			modeView,
			ModuleUtils.findModuleItemByComponentKey(
				KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
				KEYWORDS.MODULES.ITEMS.KEYS.ORDERS.CONTROL,
				ordersModule.moduleItems,
			),
		)
	) {
		authRoutes.push({
			id: ROUTES.IDS.ORDERS,
			item: ROUTES.ITEMS.ORDERS,
			path: ROUTES.PATHS.ORDERS,
			title: ROUTES.TITLES.ORDERS,
			component: Orders,
		});
		/// /TODO: DEPRECATED:
		// CONFLICT ORDERS
		authRoutes.push({
			id: ROUTES.IDS.CONFLICT_IMPORTED_ORDERS,
			path: ROUTES.PATHS.CONFLICT_IMPORTED_ORDERS,
			title: ROUTES.TITLES.CONFLICT_IMPORTED_ORDERS,
			component: ConflictImportOrders,
		});
	}

	// SUPERVISOR
	// const supervisorModule = ModuleUtils.findModuleByKey(
	//   KEYWORDS.MODULES.KEYS.SUPERVISOR,
	//   modules
	// );
	// if (
	//   supervisorModule &&
	//   ModuleUtils.checkDeviceAccess(
	//     modeView,
	//     ModuleUtils.findModuleItemByComponentKey(
	//       KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
	//       KEYWORDS.MODULES.ITEMS.KEYS.SUPERVISOR.CONTROL,
	//       supervisorModule.moduleItems
	//     )
	//   )
	// ) {
	//   authRoutes.push({
	//     id: ROUTES.IDS.SUPERVISOR,
	//     item: ROUTES.ITEMS.SUPERVISOR,
	//     path: ROUTES.PATHS.SUPERVISOR,
	//     title: ROUTES.TITLES.SUPERVISOR,
	//     component: Supervisor,
	//   });
	//   authRoutes.push({
	//     id: ROUTES.IDS.SUPERVISOR_TARGET,
	//     path: ROUTES.PATHS.SUPERVISOR_TARGET,
	//     title: ROUTES.TITLES.SUPERVISOR_TARGET,
	//     component: Supervisor,
	//   });
	// }

	// REPORTS
	const reportsModule = ModuleUtils.findModuleByKey(
		KEYWORDS.MODULES.KEYS.REPORTS,
		modules,
	);
	if (
		reportsModule &&
		ModuleUtils.checkDeviceAccess(
			modeView,
			ModuleUtils.findModuleItemByComponentKey(
				KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
				KEYWORDS.MODULES.ITEMS.KEYS.REPORTS.CONTROL,
				reportsModule.moduleItems,
			),
		)
	) {
		authRoutes.push({
			id: ROUTES.IDS.REPORTS,
			item: ROUTES.ITEMS.REPORTS,
			path: ROUTES.PATHS.REPORTS,
			title: ROUTES.TITLES.REPORTS,
			component: Reports,
		});
	}

	// BILLINGS
	const billingsModule = ModuleUtils.findModuleByKey(
		KEYWORDS.MODULES.KEYS.BILLINGS,
		modules,
	);
	if (billingsModule) {
		const routes = [];

		// PAYMENTS
		if (
			ModuleUtils.checkDeviceAccess(
				modeView,
				ModuleUtils.findModuleItemByComponentKey(
					KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
					KEYWORDS.MODULES.ITEMS.KEYS.BILLINGS.PAYMENTS,
					billingsModule.moduleItems,
				),
			)
		)
			routes.push({
				id: ROUTES.IDS.BILLINGS_PAYMENTS,
				item: ROUTES.ITEMS.BILLINGS_PAYMENTS,
				path: ROUTES.PATHS.BILLINGS_PAYMENTS,
				component: Payments,
				routes: [
					// PAYCUTS MOBILE
					{
						id: ROUTES.IDS.BILLINGS_PAYMENTS_PAYCUTS_MOBILE,
						title: ROUTES.TITLES.BILLINGS_PAYMENTS_PAYCUTS_MOBILE,
						path: ROUTES.PATHS.BILLINGS_PAYMENTS_PAYCUTS_MOBILE,
						component: PaymentsPaycutsMobile,
					},
					// PAYCUTS DESKTOP
					{
						id: ROUTES.IDS.BILLINGS_PAYMENTS_PAYCUTS_DESKTOP,
						title: ROUTES.TITLES.BILLINGS_PAYMENTS_PAYCUTS_DESKTOP,
						path: ROUTES.PATHS.BILLINGS_PAYMENTS_PAYCUTS_DESKTOP,
						component: PaymentsPaycutsDesktop,
					},
					// PAYS MOBILE
					{
						id: ROUTES.IDS.BILLINGS_PAYMENTS_PAYS_MOBILE,
						title: ROUTES.TITLES.BILLINGS_PAYMENTS_PAYS_MOBILE,
						path: ROUTES.PATHS.BILLINGS_PAYMENTS_PAYS_MOBILE,
						component: PaymentsPaysMobile,
					},
					// PAYS DESKTOP
					{
						id: ROUTES.IDS.BILLINGS_PAYMENTS_PAYS_DESKTOP,
						title: ROUTES.TITLES.BILLINGS_PAYMENTS_PAYS_DESKTOP,
						path: ROUTES.PATHS.BILLINGS_PAYMENTS_PAYS_DESKTOP,
						component: PaymentsPaysDesktop,
					},
					// LINES MOBILE
					{
						id: ROUTES.IDS.BILLINGS_PAYMENTS_LINES_MOBILE,
						title: ROUTES.TITLES.BILLINGS_PAYMENTS_LINES_MOBILE,
						path: ROUTES.PATHS.BILLINGS_PAYMENTS_LINES_MOBILE,
						component: PaymentsLinesMobile,
					},
					// LINES DESKTOP
					{
						id: ROUTES.IDS.BILLINGS_PAYMENTS_LINES_DESKTOP,
						title: ROUTES.TITLES.BILLINGS_PAYMENTS_LINES_DESKTOP,
						path: ROUTES.PATHS.BILLINGS_PAYMENTS_LINES_DESKTOP,
						component: PaymentsLinesDesktop,
					},
					// ORDERS MOBILE
					{
						id: ROUTES.IDS.BILLINGS_PAYMENTS_ORDERS_MOBILE,
						title: ROUTES.TITLES.BILLINGS_PAYMENTS_ORDERS_MOBILE,
						path: ROUTES.PATHS.BILLINGS_PAYMENTS_ORDERS_MOBILE,
						component: PaymentsOrdersMobile,
					},
					// ORDERS DESKTOP
					{
						id: ROUTES.IDS.BILLINGS_PAYMENTS_ORDERS_DESKTOP,
						title: ROUTES.TITLES.BILLINGS_PAYMENTS_ORDERS_DESKTOP,
						path: ROUTES.PATHS.BILLINGS_PAYMENTS_ORDERS_DESKTOP,
						component: PaymentsOrdersDesktop,
					},
				],
			});

		// INVOICES
		if (
			ModuleUtils.checkDeviceAccess(
				modeView,
				ModuleUtils.findModuleItemByComponentKey(
					KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
					KEYWORDS.MODULES.ITEMS.KEYS.BILLINGS.INVOICES,
					billingsModule.moduleItems,
				),
			)
		)
			routes.push({
				id: ROUTES.IDS.BILLINGS_INVOICES,
				item: ROUTES.ITEMS.BILLINGS_INVOICES,
				path: ROUTES.PATHS.BILLINGS_INVOICES,
				component: Invoices,
				routes: [
					// PAYCUTS MOBILE
					{
						id: ROUTES.IDS.BILLINGS_INVOICES_PAYCUTS_MOBILE,
						title: ROUTES.TITLES.BILLINGS_INVOICES_PAYCUTS_MOBILE,
						path: ROUTES.PATHS.BILLINGS_INVOICES_PAYCUTS_MOBILE,
						component: InvoicesPaycutsMobile,
					},
					// PAYCUTS DESKTOP
					{
						id: ROUTES.IDS.BILLINGS_INVOICES_PAYCUTS_DESKTOP,
						title: ROUTES.TITLES.BILLINGS_INVOICES_PAYCUTS_DESKTOP,
						path: ROUTES.PATHS.BILLINGS_INVOICES_PAYCUTS_DESKTOP,
						component: InvoicesPaycutsDesktop,
					},
					// PAYS MOBILE
					{
						id: ROUTES.IDS.BILLINGS_INVOICES_PAYS_MOBILE,
						title: ROUTES.TITLES.BILLINGS_INVOICES_PAYS_MOBILE,
						path: ROUTES.PATHS.BILLINGS_INVOICES_PAYS_MOBILE,
						component: InvoicesPaysMobile,
					},
					// PAYS DESKTOP
					{
						id: ROUTES.IDS.BILLINGS_INVOICES_PAYS_DESKTOP,
						title: ROUTES.TITLES.BILLINGS_INVOICES_PAYS_DESKTOP,
						path: ROUTES.PATHS.BILLINGS_INVOICES_PAYS_DESKTOP,
						component: InvoicesPaysDesktop,
					},
					// LINES MOBILE
					{
						id: ROUTES.IDS.BILLINGS_INVOICES_LINES_MOBILE,
						title: ROUTES.TITLES.BILLINGS_INVOICES_LINES_MOBILE,
						path: ROUTES.PATHS.BILLINGS_INVOICES_LINES_MOBILE,
						component: InvoicesLinesMobile,
					},
					// LINES DESKTOP
					{
						id: ROUTES.IDS.BILLINGS_INVOICES_LINES_DESKTOP,
						title: ROUTES.TITLES.BILLINGS_INVOICES_LINES_DESKTOP,
						path: ROUTES.PATHS.BILLINGS_INVOICES_LINES_DESKTOP,
						component: InvoicesLinesDesktop,
					},
					// ORDERS MOBILE
					{
						id: ROUTES.IDS.BILLINGS_INVOICES_ORDERS_MOBILE,
						title: ROUTES.TITLES.BILLINGS_INVOICES_ORDERS_MOBILE,
						path: ROUTES.PATHS.BILLINGS_INVOICES_ORDERS_MOBILE,
						component: InvoicesOrdersMobile,
					},
					// ORDERS DESKTOP
					{
						id: ROUTES.IDS.BILLINGS_INVOICES_ORDERS_DESKTOP,
						title: ROUTES.TITLES.BILLINGS_INVOICES_ORDERS_DESKTOP,
						path: ROUTES.PATHS.BILLINGS_INVOICES_ORDERS_DESKTOP,
						component: InvoicesOrdersDesktop,
					},
				],
			});

		if (routes.length > 0)
			authRoutes.push({
				id: ROUTES.IDS.BILLINGS,
				item: ROUTES.ITEMS.BILLINGS,
				path: ROUTES.PATHS.BILLINGS,
				component: Billings,
				routes,
			});
	}

	// WAREHOUSES
	const warehousesModule = ModuleUtils.findModuleByKey(
		KEYWORDS.MODULES.KEYS.INVENTORY,
		modules,
	);
	if (warehousesModule) {
		const routes = [];

		// ARTICLES
		if (
			ModuleUtils.checkDeviceAccess(
				modeView,
				ModuleUtils.findModuleItemByComponentKey(
					KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
					KEYWORDS.MODULES.ITEMS.KEYS.INVENTORY.ITEMS.CONTROL,
					warehousesModule.moduleItems,
				),
			)
		)
			routes.push({
				id: ROUTES.IDS.WAREHOUSES_ARTICLES,
				item: ROUTES.ITEMS.WAREHOUSES_ARTICLES,
				path: ROUTES.PATHS.WAREHOUSES_ARTICLES,
				component: Articles,
				routes: [
					{
						id: ROUTES.IDS.WAREHOUSES_ARTICLES_MOBILE,
						title: ROUTES.TITLES.WAREHOUSES_ARTICLES_MOBILE,
						path: ROUTES.PATHS.WAREHOUSES_ARTICLES_MOBILE,
						component: ArticlesMobile,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_ARTICLES_DESKTOP,
						title: ROUTES.TITLES.WAREHOUSES_ARTICLES_DESKTOP,
						path: ROUTES.PATHS.WAREHOUSES_ARTICLES_DESKTOP,
						component: ArticlesDesktop,
					},
				],
			});

		// WAREHOUSES
		if (
			ModuleUtils.checkDeviceAccess(
				modeView,
				ModuleUtils.findModuleItemByComponentKey(
					KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
					KEYWORDS.MODULES.ITEMS.KEYS.INVENTORY.WAREHOUSES.CONTROL,
					warehousesModule.moduleItems,
				),
			)
		)
			routes.push({
				id: ROUTES.IDS.WAREHOUSES_MANAGE,
				item: ROUTES.ITEMS.WAREHOUSES_MANAGE,
				path: ROUTES.PATHS.WAREHOUSES_MANAGE,
				component: WarehousesList,
				routes: [
					// WAREHOUSE LIST
					{
						id: ROUTES.IDS.WAREHOUSES_MANAGE_MOBILE,
						title: ROUTES.TITLES.WAREHOUSES_MANAGE_MOBILE,
						path: ROUTES.PATHS.WAREHOUSES_MANAGE_MOBILE,
						component: WarehousesListMobile,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_MANAGE_DESKTOP,
						title: ROUTES.TITLES.WAREHOUSES_MANAGE_DESKTOP,
						path: ROUTES.PATHS.WAREHOUSES_MANAGE_DESKTOP,
						component: WarehousesListDesktop,
					},
					// STOCK ARTICLES
					{
						id: ROUTES.IDS.WAREHOUSES_STOCK_ARTICLES_MOBILE,
						title: ROUTES.TITLES.WAREHOUSES_STOCK_ARTICLES_MOBILE,
						path: ROUTES.PATHS.WAREHOUSES_STOCK_ARTICLES_MOBILE,
						component: StockArticlesMobile,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_STOCK_ARTICLES_DESKTOP,
						title: ROUTES.TITLES.WAREHOUSES_STOCK_ARTICLES_DESKTOP,
						path: ROUTES.PATHS.WAREHOUSES_STOCK_ARTICLES_DESKTOP,
						component: StockArticlesDesktop,
					},
					// STOCK SERIES
					{
						id: ROUTES.IDS.WAREHOUSES_STOCK_SERIES_MOBILE,
						title: ROUTES.TITLES.WAREHOUSES_STOCK_SERIES_MOBILE,
						path: ROUTES.PATHS.WAREHOUSES_STOCK_SERIES_MOBILE,
						component: StockSeriesMobile,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_STOCK_SERIES_DESKTOP,
						title: ROUTES.TITLES.WAREHOUSES_STOCK_SERIES_DESKTOP,
						path: ROUTES.PATHS.WAREHOUSES_STOCK_SERIES_DESKTOP,
						component: StockSeriesDesktop,
					},
				],
			});

		// CONTROL SERIES
		if (
			ModuleUtils.checkDeviceAccess(
				modeView,
				ModuleUtils.findModuleItemByComponentKey(
					KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
					KEYWORDS.MODULES.ITEMS.KEYS.INVENTORY.SERIES.CONTROL,
					warehousesModule.moduleItems,
				),
			)
		)
			routes.push({
				id: ROUTES.IDS.WAREHOUSES_CONTROL_SERIES,
				item: ROUTES.ITEMS.WAREHOUSES_CONTROL_SERIES,
				path: ROUTES.PATHS.WAREHOUSES_CONTROL_SERIES,
				component: ControlSeries,
				routes: [
					{
						id: ROUTES.IDS.WAREHOUSES_CONTROL_SERIES_MOBILE,
						title: ROUTES.TITLES.WAREHOUSES_CONTROL_SERIES_MOBILE,
						path: ROUTES.PATHS.WAREHOUSES_CONTROL_SERIES_MOBILE,
						component: ControlSeriesMobile,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_CONTROL_SERIES_DESKTOP,
						title: ROUTES.TITLES.WAREHOUSES_CONTROL_SERIES_DESKTOP,
						path: ROUTES.PATHS.WAREHOUSES_CONTROL_SERIES_DESKTOP,
						component: ControlSeriesDesktop,
					},
				],
			});

		// TRANSACTIONS
		if (
			ModuleUtils.checkDeviceAccess(
				modeView,
				ModuleUtils.findModuleItemByComponentKey(
					KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
					KEYWORDS.MODULES.ITEMS.KEYS.INVENTORY.TRANSACTIONS.CONTROL,
					warehousesModule.moduleItems,
				),
			)
		)
			routes.push({
				id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS,
				item: ROUTES.ITEMS.WAREHOUSES_TRANSACTIONS,
				path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS,
				component: Transactions,
				routes: [
					{
						id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_MOBILE,
						title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_MOBILE,
						path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_MOBILE,
						component: TransactionsMobile,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_DESKTOP,
						title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_DESKTOP,
						path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_DESKTOP,
						component: TransactionsDesktop,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_MOBILE,
						title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_MOBILE,
						path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_MOBILE,
						component: TransactionNewEntryMobile,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_DESKTOP,
						title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_DESKTOP,
						path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_DESKTOP,
						component: TransactionNewEntryDesktop,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_INVENTORY_MOBILE,
						title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_INVENTORY_MOBILE,
						path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_INVENTORY_MOBILE,
						component: TransactionInventoryMobile,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_INVENTORY_DESKTOP,
						title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_INVENTORY_DESKTOP,
						path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_INVENTORY_DESKTOP,
						component: TransactionInventoryDesktop,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_ITEMS_MOBILE,
						title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_ITEMS_MOBILE,
						path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_ITEMS_MOBILE,
						component: TransactionItemsMobile,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP,
						title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP,
						path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP,
						component: TransactionItemsDesktop,
					},
					{
						id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_RECEPTION,
						title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_RECEPTION,
						path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_RECEPTION,
						component: TransactionReception,
					},
				],
			});

		if (routes.length > 0)
			authRoutes.push({
				id: ROUTES.IDS.WAREHOUSES,
				item: ROUTES.ITEMS.WAREHOUSES,
				path: ROUTES.PATHS.WAREHOUSES,
				component: Warehouses,
				routes,
			});
	}

	// MARKETPLACE
	// authRoutes.push({
	//   id: ROUTES.IDS.MARKETPLACE,
	//   path: ROUTES.PATHS.MARKETPLACE,
	//   title: ROUTES.TITLES.MARKETPLACE,
	//   component: Marketplace,
	// });

	return [
		{
			...AppRoute,
			routes: [
				// FEED
				{
					id: KEYWORD_FEED.ROUTES.FEED.ID,
					title: KEYWORD_FEED.ROUTES.FEED.TITLE,
					path: KEYWORD_FEED.ROUTES.FEED.PATH,
					component: Feed,
				},
				// DASHBOARD //TODO: Esta ruta se deberá eliminar cuando se haya terminado de migrar a todos los clientes a la versión 2
				{
					title: ROUTES.TITLES.DASHBOARD,
					path: ROUTES.PATHS.DASHBOARD,
					component: Dashboard,
					routes: authRoutes,
				},
			],
		},
	];
};

// Get Routes
const getRoutes = ({
	modules,
	intro,
	isFetchingModules,
	unauthorized,
	modeView,
}) => {
	if (intro || isFetchingModules) return fetchingModulesRoute;
	if (unauthorized) return unauthorizedRoutes;
	return authorizedRoutes({ modules, modeView });
};

export default getRoutes;
