// Libs
import React from 'react';
// Utils
import { formatNumber, coinsUtils } from 'utils/libs';
// Components
import { Card } from 'antd';
import IconButton from '@mui/material/IconButton';
import { Button, Tooltip, LogoIcon } from 'components';
// Styles
import './PaymentLineCard.css';

const { Meta } = Card;

export default function PaymentLineCard(props) {
	const {
		id,
		line,
		order_id,
		odt_id,
		description,
		amount,
		tracts,
		payment_id,
		action_label,
		creator_name,
		entity_name,
		created_at,
		updated_at,
		updatingPaymentId,
		coin_id,
		customProps,
	} = props;
	const {
		selectedPayments,
		firstSelectedPayment,
		orders,
		coins,
		getOrder,
		mutate1ObjectInOrders,
		updatePaymentIdLine,
		seeResume,
	} = customProps;
	const coinSymbol = coinsUtils.getDataByCoinId(coin_id, 'symbol', coins);
	const amountPrice = formatNumber.new(amount, coinSymbol, 2);
	const flagLineState = payment_id
		? { backgroundColor: 'green' }
		: { backgroundColor: 'white' };

	return (
		<div>
			{!seeResume && (
				<div
					className='payment_line-flag-payment-state'
					style={flagLineState}
				/>
			)}
			<Meta
				id='payment_line-card-container'
				title={
					<div className='payment_line-body'>
						<div className='payment_line-title'>
							<div className='payment_line-title-line-creator-container'>
								<Tooltip
									placement='top'
									className='payment_line-title-line'
									title={description}
								>
									<span>{line}</span>
								</Tooltip>
								<div style={{ height: '18px', overflow: 'auto' }}>
									<span className='payment_line-concept-item'>Creado por:</span>
									<span className='payment_line-concept-value'>
										{creator_name} - {entity_name}
									</span>
								</div>
							</div>

							<div className='payment_line-price-action-container'>
								<span className='payment_line-price'>{amountPrice}</span>
								<span className='payment_line-action'>{action_label}</span>
							</div>
						</div>

						<div className='payment_line-footer'>
							<div>
								<div style={{ height: '18px' }}>
									<span className='payment_line-concept-item'>Tracto:</span>
									<span className='payment_line-concept-value'>{tracts}</span>
								</div>
								<div style={{ height: '18px' }}>
									<span className='payment_line-concept-item'>Fecha:</span>
									<span className='payment_line-concept-value'>
										{created_at}
									</span>
								</div>
								<div style={{ height: '18px' }}>
									<span className='payment_line-concept-item'>
										Actualizado:
									</span>
									<span className='payment_line-concept-value'>
										{updated_at}
									</span>
								</div>
								<div style={{ height: '18px' }}>
									<span className='payment_line-concept-item'>Orden:</span>
									{order_id && (
										<span
											className='payment_line-order'
											onClick={() => {
												if (
													!orders.filter(ord => ord.order_id === order_id)[0]
												) {
													getOrder(order_id);
												}
												mutate1ObjectInOrders('getOrderInformationModal', {
													order_id,
													isOpen: true,
												});
											}}
										>
											{odt_id}
										</span>
									)}
								</div>
							</div>

							{!seeResume &&
								selectedPayments.length === 1 &&
								firstSelectedPayment?.state === 'open' &&
								!firstSelectedPayment?.signed && (
									<div>
										{updatingPaymentId ? (
											<LogoIcon spin button />
										) : payment_id ? (
											<Button
												style={{ height: '28px' }}
												onClick={() =>
													updatePaymentIdLine(
														id,
														null,
														firstSelectedPayment.id,
														firstSelectedPayment.paycut_id,
														firstSelectedPayment.paid_to,
													)
												}
											>
												Quitar
											</Button>
										) : (
											<Button
												style={{ height: '28px' }}
												onClick={() =>
													updatePaymentIdLine(
														id,
														firstSelectedPayment.id,
														firstSelectedPayment.id,
														firstSelectedPayment.paycut_id,
														firstSelectedPayment.paid_to,
													)
												}
											>
												Aplicar
											</Button>
										)}
									</div>
								)}
						</div>
					</div>
				}
			/>
		</div>
	);
}
