// Return an ARRAY segment from user assignment zone
export function getUserAssignmentZoneSegmentsConcatenated(orders) {
	return orders.reduce((acc, order) => {
		// Get Department
		const { department_id } = order;
		// Get Categories
		const { entity, normal, special, support } = order;

		// ADD ENTITY
		if (entity) {
			if (Array.isArray(entity) && entity.length > 0) {
				acc = acc.concat(entity);
			} else if (
				Array.isArray(entity[department_id]) &&
				entity[department_id].length > 0
			) {
				acc = acc.concat(entity[department_id]);
			} else if (Array.isArray(entity.shared) && entity.shared.length > 0) {
				acc = acc.concat(entity.shared);
			}
		}
		// ADD NORMAL
		if (normal) {
			if (Array.isArray(normal) && normal.length > 0) {
				acc = acc.concat(normal);
			} else if (
				Array.isArray(normal[department_id]) &&
				normal[department_id].length > 0
			) {
				acc = acc.concat(normal[department_id]);
			} else if (Array.isArray(normal.shared) && normal.shared.length > 0) {
				acc = acc.concat(normal.shared);
			}
		}
		// ADD SPECIAL
		if (special) {
			if (Array.isArray(special) && special.length > 0) {
				acc = acc.concat(special);
			} else if (
				Array.isArray(special[department_id]) &&
				special[department_id].length > 0
			) {
				acc = acc.concat(special[department_id]);
			} else if (Array.isArray(special.shared) && special.shared.length > 0) {
				acc = acc.concat(special.shared);
			}
		}
		// ADD SUPPORT
		if (support) {
			if (Array.isArray(support) && support.length > 0) {
				acc = acc.concat(support);
			} else if (
				Array.isArray(support[department_id]) &&
				support[department_id].length > 0
			) {
				acc = acc.concat(support[department_id]);
			} else if (Array.isArray(support.shared) && support.shared.length > 0) {
				acc = acc.concat(support.shared);
			}
		}
		return acc;
	}, []);
}
