// Libs
import React from 'react';
// Utils
import GENERAL from 'utils/constants/general';
// Components
import { Wrapper, Button, Link } from 'components';
// import { OrderUtils } from "utils";

/// /TODO: DEPRECATED:
import {
	resetOrderConflicts,
	isCanContinueProcessImportOrders,
	getValidatorFieldsTemplate,
} from 'screens/Orders/utils';
const OrderUtils = {
	resetOrderConflicts,
	isCanContinueProcessImportOrders,
	getValidatorFieldsTemplate,
};

const { ENV } = GENERAL;

export default function ConflictsImporting(props) {
	const {
		ordersManage,
		mutate1ObjectInOrders,
		onResetEnterNewOrders,
		templates,
	} = props;
	const { validationTemplateId, importedFile, userAssignment } =
		ordersManage.enterNewOrdersModal;
	// Get templateValidator
	const templateValidator =
		validationTemplateId &&
		templates.data.filter(template => template.id === validationTemplateId)[0];
	// Get array fields from template validator
	// const templateValidatorFields =
	// OrderUtils.getTemplateValidatorFields(templateValidator);

	/// /TODO: DEPRECATED:
	const templateValidatorFields =
		OrderUtils.getValidatorFieldsTemplate(templateValidator);

	return (
		<Wrapper justifyContent='space-between' height='48px' mediumBackground>
			<Link
				to={ENV.ROUTES.PATHS.ORDERS}
				onClick={() =>
					onResetEnterNewOrders({
						isOpen: true,
					})
				}
			>
				<Button>Cancelar</Button>
			</Link>
			{OrderUtils.isCanContinueProcessImportOrders(
				importedFile.data,
				templateValidatorFields,
				userAssignment,
			) && (
				<Link
					to={ENV.ROUTES.PATHS.ORDERS}
					onClick={() =>
						mutate1ObjectInOrders('enterNewOrdersModal', {
							isOpen: true,
							importedFile: {
								...importedFile,
								data: OrderUtils.resetOrderConflicts(importedFile.data),
							},
							userAssignment:
								userAssignment === 'withConflicts' ||
								userAssignment === 'withoutConflicts'
									? 'confirmed'
									: userAssignment,
							mappingValidationOk: true,
							selected: {
								items: [],
							},
							filters: {},
							onHoverCells: {},
						})
					}
				>
					<Button mode='primary'>Continuar</Button>
				</Link>
			)}
		</Wrapper>
	);
}
