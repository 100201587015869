// Libs
import React from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';

const EyeIcon = ({ button, onClick }) => {
	const { theme } = useTheme();
	return button ? (
		<IconButton onClick={onClick}>
			<Icon fill={theme._mediumEmphasisColor} />
		</IconButton>
	) : (
		<Icon fill={theme._mediumEmphasisColor} onClick={onClick} />
	);
};

export default EyeIcon;
