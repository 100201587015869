// Libs
import React from 'react';
import _ from 'underscore';
import { Card } from 'antd';
import { InputNumber, Button } from 'components';
import Select from 'components/Select';
// Utils
import {
	isSerieInTransaction,
	onAddSerie,
	onDeleteSerie,
} from '../../../../Warehouses/utils';
// Styles
import './InventoryTransactionCard.css';

const { Meta } = Card;
const { Option } = Select;

function getAmountSelected(item_id, articles) {
	const idx = _.findIndex(articles, article => article.item_id === item_id);
	return idx !== -1 ? articles[idx].amount : undefined;
}

function onChangeArticleQuantity(
	value,
	item_id,
	warehouse_package_id,
	articles,
	mutate1Object,
) {
	const idx = _.findIndex(articles, article => article.item_id === item_id);

	if (!value || value === 0) {
		if (idx !== -1)
			mutate1Object('createTransaction', {
				articles: articles.filter(article => article.item_id !== item_id),
			});
	} else {
		if (idx !== -1) articles[idx].amount = value;
		else
			articles.push({
				item_id,
				amount: value,
				warehouse_package_id,
			});
		mutate1Object('createTransaction', { articles });
	}
}

function getState(serie, state_name, serialized, itemStates, mutate1Object) {
	const idx = _.findIndex(serialized, item => item.serie === serie);

	return idx === -1 ? (
		<b>{state_name}</b>
	) : (
		<Select
			style={{ width: '100%' }}
			placeholder={`${state_name}`}
			onChange={value => {
				serialized[idx].state_id = value;
				mutate1Object('createTransaction', { serialized });
			}}
		>
			{itemStates.map((state, idx) => (
				<Option key={idx} value={state.id}>
					<em>{state.name}</em>
				</Option>
			))}
		</Select>
	);
}

export default function InventoryTransactionCard(props) {
	const {
		item_id,
		item_code,
		item_name,
		is_serialized,
		amount,
		serie,
		state_id,
		state_name,
		created_at,
		updated_at,
		warehouse_package_id,
		customProps,
	} = props;
	const {
		serialized,
		articles,
		mutate1Object,
		inventoryToTransferManage,
		itemStates,
	} = customProps;

	return (
		<Meta
			id='inventory_transaction-meta'
			title={
				<div className='inventory_transaction-container-title-card'>
					<span className='inventory_transaction-card-creator'>
						{item_name}
					</span>

					<div style={{ display: 'flex' }}>
						<div
							style={{ marginRight: '10px', width: '50%', overflow: 'auto' }}
						>
							<span className='inventory_transaction-body-concept-item'>
								Creado:
							</span>
							<span className='inventory_transaction-card-created_at'>
								{created_at}
							</span>
						</div>
						<div style={{ width: '50%', overflow: 'auto' }}>
							<span className='inventory_transaction-body-concept-item'>
								Actualizado:
							</span>
							<span className='inventory_transaction-card-created_at'>
								{updated_at}
							</span>
						</div>
					</div>
				</div>
			}
			description={
				<div className='inventory_transaction-container-data-input'>
					<div className='inventory_transaction-data'>
						<div className='inventory_transaction-body-container-item'>
							<div className='inventory_transaction-body-concept-item'>
								Código: <b>{item_code}</b>
							</div>
						</div>
						{!is_serialized ? (
							<div className='inventory_transaction-body-container-item'>
								<div className='inventory_transaction-body-concept-item'>
									Cantidad: <b>{amount}</b>
								</div>
							</div>
						) : (
							<div className='inventory_transaction-body-container-item'>
								<div className='inventory_transaction-body-concept-item'>
									Serie: <b>{serie}</b>
								</div>
							</div>
						)}
						{is_serialized && (
							<div className='inventory_transaction-body-container-item'>
								<div className='inventory_transaction-body-concept-item'>
									Estado:{' '}
									{getState(
										serie,
										state_name,
										serialized,
										itemStates,
										mutate1Object,
									)}
								</div>
							</div>
						)}
					</div>

					<div className='inventory_transaction-button'>
						{!is_serialized ? (
							amount > 0 && (
								<InputNumber
									style={{ width: '100%' }}
									placeholder='Cantidad'
									value={getAmountSelected(item_id, articles)}
									min={0}
									max={amount}
									onChange={value =>
										onChangeArticleQuantity(
											value,
											item_id,
											warehouse_package_id,
											articles,
											mutate1Object,
										)
									}
								/>
							)
						) : isSerieInTransaction(serialized, serie) ? (
							<Button
								className='desktop-transactions-button-add-delete-serie'
								style={{ color: 'darkgray' }}
								onClick={() =>
									onDeleteSerie(
										serie,
										inventoryToTransferManage,
										serialized,
										mutate1Object,
									)
								}
							>
								Quitar
							</Button>
						) : (
							<Button
								className='desktop-transactions-button-add-delete-serie'
								onClick={() =>
									onAddSerie(
										item_id,
										serie,
										state_id,
										warehouse_package_id,
										serialized,
										mutate1Object,
									)
								}
							>
								Agregar
							</Button>
						)}
					</div>
				</div>
			}
		/>
	);
}
