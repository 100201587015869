// Dependencies
import { createStore, applyMiddleware } from 'redux';
// Services
import { SentryService } from 'services';
// Middlewares
import createSagaMiddleware from 'redux-saga';
// Root Saga
import rootSaga from './sagas';
// Root Reducer
import rootReducer from './reducers';
// Middlewares
const sagaMiddleware = createSagaMiddleware({
	onError: SentryService.sendError,
});
const buildMiddlewares = () =>
	applyMiddleware(sagaMiddleware, SentryService.reduxMiddleware);
const middlewares = buildMiddlewares(); //  Separate different middlewares by comma (,) into "applyMiddleware()"

const buildStore = () => {
	if (process.env.NODE_ENV === 'production')
		return createStore(rootReducer, middlewares);

	//Development env with devTools
	const composeWithDevTools =
		require('redux-devtools-extension').composeWithDevTools;
	return createStore(rootReducer, composeWithDevTools(middlewares));
};
const store = buildStore();

sagaMiddleware.run(rootSaga);

export default store;
