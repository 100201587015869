export default class ProjectUtils {
	static getProjectPropsFromOrder(key, order) {
		if (!key || !order) return {};

		const props = order.departmentProps;
		if (!props) return {};

		if (typeof props === 'object' && !props[key]) return {};
		if (typeof props[key] !== 'object') return {};
		return props[key];
	}
}
