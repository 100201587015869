// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='normalAddShopingCartIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='1'
			y='1'
			width='21'
			height='21'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13 9H11V6H8V4H11V1H13V4H16V6H13V9ZM5.01 20C5.01 18.9 5.9 18 7 18C8.1 18 9 18.9 9 20C9 21.1 8.1 22 7 22C5.9 22 5.01 21.1 5.01 20ZM17 18C15.9 18 15.01 18.9 15.01 20C15.01 21.1 15.9 22 17 22C18.1 22 19 21.1 19 20C19 18.9 18.1 18 17 18ZM15.55 13H8.1L7 15H19V17H7C5.48 17 4.52 15.37 5.25 14.03L6.6 11.59L3 4H1V2H4.27L8.53 11H15.55L19.41 4L21.16 4.96L17.3 11.97C16.96 12.59 16.3 13 15.55 13Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#normalAddShopingCartIcon)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
