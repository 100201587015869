// Libs
import React from 'react';
import { Card } from 'antd';
// hooks
import { useTheme } from 'hooks';
// Components
import { GetSerieHistory } from 'components';
// Styles
import '../../VirtualizedList.css';
import './BillingMaterialCard.css';

const { Meta } = Card;

export default function BillingMaterialCard({
	id,
	item_id,
	item_name,
	item_code,
	amount,
	serie,
	wtd_items_props,
	wtd_items_edit,
	wtd_items_created_at,
	wtd_items_updated_at,
	customProps,
}) {
	const { theme } = useTheme();
	const {
		onUpdateTransactionItems,
		completed,
		// mutate1Object
	} = customProps;

	return (
		<Meta
			id='billingMaterialCard'
			title={
				<div className='billing_mat-container-title-card'>
					<div className='billing_mat-container-title-card-data'>
						<span
							style={{ color: theme._primaryColor }}
							className='card-creator'
						>
							{item_name}
						</span>
						<span className='card-created-at'>{wtd_items_created_at}</span>
					</div>
					{!completed && wtd_items_props && (
						<div className='billing_mat-container-title-card-reject-button'>
							{wtd_items_props.disapprove ? (
								<div
									className='billing_mat-disapproved-inventory-item'
									onClick={() => onUpdateTransactionItems({ id, value: false })}
								>
									Rechazado
								</div>
							) : (
								<div
									className='billing_mat-disapprove-inventory-item'
									onClick={() => onUpdateTransactionItems({ id, value: true })}
								>
									Rechazar
								</div>
							)}
						</div>
					)}
				</div>
			}
			description={
				<div className='billing_mat-container-description-card scrollable'>
					<div className='billing_mat-container-data'>
						<div className='card-container-item'>
							<div className='card-concept'>
								Código: <b>{item_code || item_id}</b>
							</div>
						</div>
						{amount && (
							<div className='card-container-item'>
								<div className='card-concept'>
									Cantidad: <b>{amount}</b>
								</div>
							</div>
						)}
						{serie && (
							<div className='card-container-item'>
								<div className='card-concept'>
									Serie:
									<b>
										<GetSerieHistory
											serie={serie}
											// onClickCustomAction={() =>
											//   mutate1Object("getOrderInformationModal", {
											//     isOpen: false,
											//   })
											// }
										/>
									</b>
								</div>
							</div>
						)}
						{wtd_items_updated_at !== wtd_items_created_at && (
							<div>
								<span className='card-concept'>Actualizado: </span>
								<span className='card-value'>{wtd_items_updated_at}</span>
							</div>
						)}
					</div>
					<div className='billing_mat-container-reject'>
						{wtd_items_props && wtd_items_props.reject && (
							<div>
								<span className='card-concept'>Validación: </span>
								<q className='card-value'>{wtd_items_props.reason}</q>
							</div>
						)}
					</div>
				</div>
			}
		/>
	);
}
