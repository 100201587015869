// Libs
import React from 'react';
import styled from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';

const IconContainer = styled.div`
	height: 24px;
`;

const HamburguerIcon = ({ button, onClick, isSelected }) => {
	const { theme } = useTheme();
	const fill = React.useMemo(
		() => (isSelected ? theme._secondaryColor : theme._mediumEmphasisColor),
		[isSelected],
	);
	return button ? (
		<IconButton onClick={onClick}>
			<Icon fill={fill} />
		</IconButton>
	) : (
		<IconContainer onClick={onClick}>
			<Icon fill={fill} />
		</IconContainer>
	);
};

export default HamburguerIcon;
