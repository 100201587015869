/**
 * Welcome to ScreenBuilderConfig
 * Max steps to implement new Action: 3
 */

/**
 * Step 1: Import new Screen Picker Icon
 */
import {
	ListIcon,
	StreamIcon,
	GridIcon,
	// <--HERE
} from 'components/Icons';

/**
 * Step 2: Import new Screen Component
 */
import {
	StandardTableScreen,
	StandardListScreen,
	StandardStreamScreen,
	StandardGridScreen,
	StandardMapScreen,
	// <--HERE
} from './screens';

/**
 * Step 3: Add new authorized Screen key from <schema.feed_screens.key>
 */
const authorizedScreenKeys = {
	standardTableV1: 'standardTableV1',
	standardListV1: 'standardListV1',
	standardStreamV1: 'standardStreamV1',
	standardGridV1: 'standardGridV1',
	standardMapV1: 'standardMapV1',
	// <--HERE
};

/**
 * Step 4: Add new screen picker icon
 */
const screenPickerIcons = {
	[authorizedScreenKeys.standardTableV1]: ListIcon,
	[authorizedScreenKeys.standardListV1]: ListIcon,
	[authorizedScreenKeys.standardStreamV1]: StreamIcon,
	[authorizedScreenKeys.standardGridV1]: GridIcon,
	[authorizedScreenKeys.standardMapV1]: GridIcon,
};

/**
 * Step 5: Add new key:value pair screen
 */
const authorizedScreens = {
	[authorizedScreenKeys.standardTableV1]: StandardTableScreen,
	[authorizedScreenKeys.standardListV1]: StandardListScreen,
	[authorizedScreenKeys.standardStreamV1]: StandardStreamScreen,
	[authorizedScreenKeys.standardGridV1]: StandardGridScreen,
	[authorizedScreenKeys.standardMapV1]: StandardMapScreen,
	// <--HERE
};

class ScreenBuilderConfig {
	static authorizedScreenKeys() {
		return authorizedScreenKeys;
	}

	static getAuthorizedPickerIcons() {
		return screenPickerIcons;
	}

	/**
	 * @param {Object[]} screens - The module setted screens array from selected data filter item
	 * @returns Authorized screens to use in rest of code
	 */
	static getAuthorizedScreens(screens) {
		return screens.filter(
			screen => !!this.authorizedScreenKeys()[screen.key], // Call to gateway to access the authorized screen keys
		);
	}

	/**
	 * @param {Object[]} screenKey - The screen key from <feed_screens.key>
	 * @returns Authorized screen React Node
	 */
	static getAuthorizedScreenReactNode(screenKey) {
		return authorizedScreens[screenKey];
	}

	static getPickerIcon(screenKey) {
		return this.getAuthorizedPickerIcons()[screenKey];
	}

	static getSelectedScreen(selectedScreenId, authorizedScreens) {
		const selectedScreen = authorizedScreens.find(
			({ id }) => id === selectedScreenId,
		);
		return selectedScreen;
	}
}

export default ScreenBuilderConfig;
