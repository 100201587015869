// Libs
import React from 'react';
// Utils
import ElementBuilderConfig from './element.config';
// Components
import { Wrapper, Subtitle, Span } from 'components';

const ElementPickerItem = ({
	selectedElementId,
	elementId,
	elementKey,
	elementTitle,
	onClick,
}) => {
	const isSelected = React.useMemo(
		() => elementId === selectedElementId,
		[selectedElementId],
	);

	const Icon = ElementBuilderConfig.getPickerIcon(elementKey);
	if (!Icon) return null;

	return (
		<Wrapper
			padding='5px'
			width='100%'
			height='34px'
			cursor='pointer'
			onClick={onClick}
		>
			<Icon selected={isSelected} />
			<Span margin='0 0 0 10px'>{elementTitle}</Span>
		</Wrapper>
	);
};

function ElementPicker({ selectedElementId, elements, onMutateElement }) {
	if (elements.length === 1) return null;
	return (
		<>
			<Subtitle disabled>Selecciona el elemento</Subtitle>
			{elements.map(({ id, key, title }) => (
				<ElementPickerItem
					key={id.toString()}
					selectedElementId={selectedElementId}
					elementId={id}
					elementKey={key}
					elementTitle={title}
					onClick={() => onMutateElement(id)}
				/>
			))}
		</>
	);
}

export default ElementPicker;
