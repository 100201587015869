export function generatePaycutPDFValidator(request) {
	const { paycutIds, excludePayrollUsers } = request;
	let errors = '';

	if (!paycutIds || paycutIds.length === 0) {
		errors += '[La solicitud debe especificar los cortes]';
	}
	if (
		excludePayrollUsers === undefined ||
		typeof excludePayrollUsers !== 'boolean'
	) {
		errors +=
			'[La solicitud debe especificar el valor de la propiedad <Excluir usuarios de planilla>]';
	}

	if (errors === '') return null;
	return errors;
}
