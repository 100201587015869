//Libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
//Utils
import { debounce } from 'utils/libs';
import UploadResourceUtils from 'components/UploadResource/UploadResourceUtils';
import CollectorUtils from 'components/Collector/CollectorUtils';
//Services
// import AuthService from "utils/libs/auth/AuthService";
//Hooks
import { useCollectorData, useNetwork, useRealtime } from 'hooks';
//Context
import { RealtimeUtils, TOPICS } from 'context/RealtimeContext';
//Actions
import {
	updateResources,
	updateAutoSync,
} from 'components/UploadResource/actions';
import {
	mutate1Object as mutate1ObjectInCollector,
	resetAutoFillCollector,
	setAutoSync,
} from 'components/Collector/actions';
import { mutateConsumeInventoryControl } from 'components/ConsumeInventory/actions';
import { mutate1Object as mutate1ObjectInCore } from 'core/actions';
//Selectors
import { selectSelectedTransactionDocId } from '../Collector/selectors';
import { selectCompleteOrderId } from 'screens/Orders/selectors';
import { selectAutoSyncOfflineData } from 'core/selectors';
import { selectProfile } from 'screens/Login/selectors';
//Components
import {
	Wrapper,
	Drawer,
	Title,
	DeleteIcon,
	Checkbox,
	Button,
	DownloadIcon,
	Subtitle,
} from 'components';
import { AutoSyncUploadResources } from 'components/UploadResource';
import { AutoSyncConsumedItems } from 'components/ConsumeInventory';
import AutoSyncCollectors from 'components/Collector/AutoSyncCollectors';
import {
	DownloadFileIllustration,
	ThrowAwayIllustration,
} from 'components/Illustrations';
import { ConsumeInventoryUtils } from 'components/ConsumeInventory';

// const auth = new AuthService();

const { DUPLICATED_COLLECTOR_LAYOUT } = TOPICS.ACTIONS.COLLECTOR_ECOSYSTEM;

// Items
const ITEMS = {
	RESOURCES: 'RESOURCES',
	COLLECTORS: 'COLLECTORS',
	DUPLICATED_COllECTOR_LAYOUT: 'DUPLICATED_COllECTOR_LAYOUT',
	CONSUMED_ITEMS: 'CONSUMED_ITEMS',
};

// Actions
const ACTIONS = {
	DOWNLOAD: 'DOWNLOAD',
	DELETE: 'DELETE',
};

const HeaderTitle = ({ title }) => (
	<Title ellipsis width='225px' margin='0 1em 0 0'>
		{title}
	</Title>
);

const Item = ({ item, name, checked, onChange }) => (
	<Wrapper padding='0' margin='10px 0'>
		<Checkbox name={name} checked={checked} onChange={e => onChange(e, item)}>
			<Subtitle>{name}</Subtitle>
		</Checkbox>
	</Wrapper>
);

const ActionButton = ({ disabled, executingAction, onExecuteAction }) => (
	<Wrapper margin='20px 0 0 0' width='100%' flexDirection='column'>
		{!executingAction && (
			<Button
				disabled={disabled}
				margin='20px 0 0 0'
				mode='primary'
				onClick={!disabled ? onExecuteAction : undefined}
			>
				Confirmar
			</Button>
		)}
	</Wrapper>
);

const DeletePanel = ({
	state,
	setState,
	updateResources,
	mutate1ObjectInCollector,
	resetAutoFillCollector,
	mutateConsumeInventoryControl,
	onChangeItem,
}) => {
	const onExecuteAction = () => {
		setState(prev => ({ ...prev, executingAction: true }));
		setTimeout(() => {
			if (state[ITEMS.RESOURCES]) {
				updateResources([]);
				UploadResourceUtils.mutateOfflineResources([]);
			}
			if (state[ITEMS.COLLECTORS]) {
				mutate1ObjectInCollector('reviewManage', {
					collectorValues: [],
				});
				resetAutoFillCollector();
				CollectorUtils.mutateOfflineCollectorValuesV2([]);
				CollectorUtils.mutateOfflineAutoFillCollector({});
			}
			if (state[ITEMS.DUPLICATED_COllECTOR_LAYOUT]) {
				CollectorUtils.mutateOfflineDuplicatedCollectorLayout([]);
				mutate1ObjectInCollector('reviewManage', {
					duplicatedCollectorLayout: [],
				});
			}
			if (state[ITEMS.CONSUMED_ITEMS]) {
				mutateConsumeInventoryControl({ consumedItems: [] });
				ConsumeInventoryUtils.mutateOfflineConsumedItems([]);
			}

			setState(prev => ({
				...prev,
				showPanel: undefined,
				executingAction: false,
			}));
		}, [500]);
	};

	const disabled =
		!state[ITEMS.RESOURCES] &&
		!state[ITEMS.COLLECTORS] &&
		!state[ITEMS.DUPLICATED_COllECTOR_LAYOUT] &&
		!state[ITEMS.CONSUMED_ITEMS];

	return (
		<>
			<Title margin='0 0 15px 0'>¿Qué desea borrar?</Title>
			<Wrapper flexDirection='column' alignItems='flex-start'>
				<Item
					item={ITEMS.RESOURCES}
					name='FOTOS'
					checked={state[ITEMS.RESOURCES]}
					onChange={onChangeItem}
				/>
				<Item
					item={ITEMS.COLLECTORS}
					name='DATOS'
					checked={state[ITEMS.COLLECTORS]}
					onChange={onChangeItem}
				/>
				<Item
					item={ITEMS.DUPLICATED_COllECTOR_LAYOUT}
					name='ESTRUCTURA DE DUPLICADORES'
					checked={state[ITEMS.DUPLICATED_COllECTOR_LAYOUT]}
					onChange={onChangeItem}
				/>
				<Item
					item={ITEMS.CONSUMED_ITEMS}
					name='INVENTARIO'
					checked={state[ITEMS.CONSUMED_ITEMS]}
					onChange={onChangeItem}
				/>
			</Wrapper>

			<ThrowAwayIllustration loading={state.executingAction} />
			<ActionButton
				disabled={disabled}
				executingAction={state.executingAction}
				onExecuteAction={onExecuteAction}
			/>
		</>
	);
};

const DownloadPanel = ({ state, setState, profile, onChangeItem }) => {
	const onExecuteAction = () => {
		setState(prev => ({ ...prev, executingAction: true }));
		setTimeout(() => {
			if (state[ITEMS.RESOURCES])
				UploadResourceUtils.downloadUnsuccessResources(profile).then(() => {
					setState(prev => ({
						...prev,
						showPanel: undefined,
						executingAction: false,
					}));
				});
		}, [500]);
	};

	const disabled = !state[ITEMS.RESOURCES];

	return (
		<>
			<Title margin='0 0 15px 0'>¿Qué desea descargar?</Title>
			<Wrapper flexDirection='column' alignItems='flex-start'>
				<Item
					item={ITEMS.RESOURCES}
					name='FOTOS AÚN NO SUBIDAS'
					checked={state[ITEMS.RESOURCES]}
					onChange={onChangeItem}
				/>
			</Wrapper>

			<DownloadFileIllustration loading={state.executingAction} />
			<ActionButton
				disabled={disabled}
				executingAction={state.executingAction}
				onExecuteAction={onExecuteAction}
			/>
		</>
	);
};

const Header = ({ title, onShowPanel }) => (
	<Wrapper width='92%' padding='0 10px 0 0' justifyContent='space-between'>
		<HeaderTitle title={title} />
		<Wrapper padding='0'>
			<DownloadIcon button onClick={() => onShowPanel(ACTIONS.DOWNLOAD)} />
			<DeleteIcon button onClick={() => onShowPanel(ACTIONS.DELETE)} />
		</Wrapper>
	</Wrapper>
);

const Panel = ({
	showPanel,
	state,
	setState,
	profile,
	updateResources,
	mutate1ObjectInCollector,
	resetAutoFillCollector,
	mutateConsumeInventoryControl,
	onChangeItem,
}) => {
	if (showPanel === ACTIONS.DELETE)
		return (
			<DeletePanel
				state={state}
				setState={setState}
				updateResources={updateResources}
				mutate1ObjectInCollector={mutate1ObjectInCollector}
				resetAutoFillCollector={resetAutoFillCollector}
				mutateConsumeInventoryControl={mutateConsumeInventoryControl}
				onChangeItem={onChangeItem}
			/>
		);

	if (showPanel === ACTIONS.DOWNLOAD)
		return (
			<DownloadPanel
				state={state}
				setState={setState}
				profile={profile}
				onChangeItem={onChangeItem}
			/>
		);
};

class DuplicatedCollectorLayoutSync {
	static instance;
	constructor(duplicatedCollectorLayout) {
		this.duplicatedCollectorLayout = duplicatedCollectorLayout;
		this.createTasks = [];
		this.deleteTasks = [];
	}
	static getInstance(duplicatedCollectorLayout) {
		if (!this.instance)
			this.instance = new DuplicatedCollectorLayoutSync(
				duplicatedCollectorLayout,
			);
		else this.instance.duplicatedCollectorLayout = duplicatedCollectorLayout;
		return this.instance;
	}
	resetTasks() {
		this.createTasks = [];
		this.deleteTasks = [];
	}
	setTask({ level, newDuplicatedElement, deletedElement }) {
		if (newDuplicatedElement) {
			this.createTasks.push(() =>
				CollectorUtils.combineDuplicatedCollectorLayout(
					'create',
					this.duplicatedCollectorLayout,
					{
						level,
						newDuplicatedElement,
					},
				),
			);
		}

		if (deletedElement) {
			if (!level) return;
			this.deleteTasks.push(() =>
				CollectorUtils.combineDuplicatedCollectorLayout(
					'delete',
					this.duplicatedCollectorLayout,
					{
						level,
						deletedElement,
					},
				),
			);
		}
	}

	async run({ mutate1ObjectInCollector, setAutoSync }) {
		for (let task of this.createTasks) {
			this.duplicatedCollectorLayout = task();
		}
		for (let task of this.deleteTasks) {
			this.duplicatedCollectorLayout = task();
		}
		CollectorUtils.mutateOfflineDuplicatedCollectorLayout(
			this.duplicatedCollectorLayout,
		);
		mutate1ObjectInCollector('reviewManage', {
			duplicatedCollectorLayout: this.duplicatedCollectorLayout,
		});
		setAutoSync({ duplicatedLayoutIdsActive: true });
		this.resetTasks();
	}
}

const AutoSyncOfflineData = ({
	//State
	orderId,
	docId,
	autoSyncOfflineData,
	profile,
	//Actions
	mutate1ObjectInCore,
	updateResources,
	// updateAutoSync,
	mutate1ObjectInCollector,
	resetAutoFillCollector,
	mutateConsumeInventoryControl,
	setAutoSync,
}) => {
	const { duplicatedCollectorLayout } = useCollectorData();
	let topics = {};
	const duplicatedCollectorLayoutSyncTopic =
		orderId && `${DUPLICATED_COLLECTOR_LAYOUT.SYNC}/${orderId}/+/#`;
	if (!!duplicatedCollectorLayoutSyncTopic)
		topics[duplicatedCollectorLayoutSyncTopic] = true;

	const { payload } = useRealtime({ topics });
	const [state, setState] = useState({
		showPanel: undefined, // [delete, download]
		executingAction: false,
		[ITEMS.RESOURCES]: false,
		[ITEMS.COLLECTORS]: false,
		[ITEMS.DUPLICATED_COllECTOR_LAYOUT]: false,
		[ITEMS.CONSUMED_ITEMS]: false,
	});
	//Use Network to know isOnline
	const isOnline = useNetwork();
	//Set Title
	const title = React.useMemo(
		() => (!isOnline ? 'Sin conexión' : 'Conectado'),
		[isOnline],
	);
	// const broadcast = React.useMemo(
	//   () =>
	//     new BroadcastChannel("collectorEcosystemManagerAutoSyncBroadcastChannel"),
	//   []
	// );

	const debounced = React.useCallback(
		debounce(callback => callback(), 2500), // Hold at 2 seconds
		[],
	);

	//Handle onChange checkbox item
	const onChangeItem = (e, item) =>
		setState(prev => ({
			...prev,
			[item]: e.target.checked,
		}));

	//Handle on show panel
	const onShowPanel = showPanel =>
		setState(prev => ({
			...prev,
			showPanel: prev.showPanel !== showPanel ? showPanel : undefined,
		}));

	const onDuplicatedCollectorLayoutSync = React.useCallback(
		({ level, newDuplicatedElement, deletedElement }) => {
			if (!orderId) return;

			const sync = DuplicatedCollectorLayoutSync.getInstance(
				duplicatedCollectorLayout,
			);

			sync.setTask({
				level,
				newDuplicatedElement,
				deletedElement,
			});

			debounced(() => {
				sync.run({ mutate1ObjectInCollector, setAutoSync });
			});
		},
		[orderId, docId, duplicatedCollectorLayout],
	);

	const handleOnReceiveRealtimeMessage = ({ action, message }) => {
		if (!action) return;

		const handler = {
			[duplicatedCollectorLayoutSyncTopic]: () =>
				onDuplicatedCollectorLayoutSync(message),
		}[
			RealtimeUtils.applySubscribedTopicLevelMask(action, [
				duplicatedCollectorLayoutSyncTopic,
			])
		];
		if (!handler) return;
		handler(message);
	};

	useEffect(() => {
		if (!payload) return;
		handleOnReceiveRealtimeMessage(payload);
	}, [payload]);

	// useEffect(() => {
	//   broadcast.onmessage = (event) => {
	//     const active = !!event.data?.active;
	//     updateAutoSync({ active });
	//   };

	//   // const messageChannel = new MessageChannel();
	//   if (navigator.serviceWorker?.controller?.postMessage)
	//     navigator.serviceWorker.controller.postMessage(
	//       {
	//         control: {
	//           type: "COLLECTOR_RESOURCE_AUTO_SYNC",
	//           dbName: IndexedDB.getDatabaseNames().UPLOAD_RESOURCES,
	//           userToken: auth.getToken(),
	//         },
	//       }
	//       // [messageChannel.port2]
	//     );

	//   // Listen to the response
	//   // messageChannel.port1.onmessage = (event) => {
	//   //   console.log("Recurso Sincronizando: ", event.data)
	//   // };
	// }, []);

	return (
		<>
			{!autoSyncOfflineData.isOpen ? (
				<>
					<AutoSyncUploadResources silent={true} />
					<AutoSyncCollectors silent={true} />
					<AutoSyncConsumedItems silent={true} />
				</>
			) : (
				<Drawer
					key='autoSyncOfflineDataDrawer'
					className='animated fadeIn'
					placement='top'
					height='100%'
					title={<Header title={title} onShowPanel={onShowPanel} />}
					visible={autoSyncOfflineData.isOpen}
					width={`${document.documentElement.clientWidth}px`}
					closable={true}
					onClose={() => {
						setState(prev => ({ ...prev, showPanel: undefined }));
						mutate1ObjectInCore('autoSyncOfflineData', {
							isOpen: false,
						});
					}}
				>
					<Wrapper width='100%' flexDirection='column' alignItems='flex-start'>
						{!!state.showPanel ? (
							<Panel
								showPanel={state.showPanel}
								state={state}
								setState={setState}
								profile={profile}
								updateResources={updateResources}
								mutate1ObjectInCollector={mutate1ObjectInCollector}
								resetAutoFillCollector={resetAutoFillCollector}
								mutateConsumeInventoryControl={mutateConsumeInventoryControl}
								onChangeItem={onChangeItem}
							/>
						) : (
							<>
								<AutoSyncUploadResources />
								<AutoSyncCollectors />
								<AutoSyncConsumedItems />
							</>
						)}
					</Wrapper>
				</Drawer>
			)}
		</>
	);
};

const mapStateToProps = state => ({
	orderId: selectCompleteOrderId(state),
	docId: selectSelectedTransactionDocId(state),
	autoSyncOfflineData: selectAutoSyncOfflineData(state),
	profile: selectProfile(state),
});
const actions = {
	mutate1ObjectInCore,
	updateResources,
	updateAutoSync,
	mutate1ObjectInCollector,
	resetAutoFillCollector,
	mutateConsumeInventoryControl,
	setAutoSync,
};

export default connect(mapStateToProps, actions)(AutoSyncOfflineData);
