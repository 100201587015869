// Libs
import React from 'react';
// Utils
import { ImportUtils } from 'utils/Orders/ImportProcess';
// Components
import { CloseIcon } from 'components';
import { OnlyReadField } from 'components/DataFieldManager';

const ShowError = ({ rowData, field }) =>
	rowData[ImportUtils.getValidationField(field)] ===
	ImportUtils.getStatus().UNSUCCESS ? (
		<CloseIcon />
	) : null;

const Read = ({ className, field, rowData, setOpen = () => {} }) => (
	<div className={className} onClick={() => setOpen(true)}>
		<OnlyReadField rowData={rowData} field={field} />
		<ShowError rowData={rowData} field={field} />
	</div>
);

export default Read;
