// Libs
import React, { Component } from 'react';
import _ from 'underscore';
// Utils
import { formatNumber } from 'utils/libs';
import GENERAL from 'utils/constants/general';
import { OrderUtils } from 'utils';
// Context
import { ElementViewBuilderContext } from 'core/context';
// Config
import { ElementViewBuilderConfig } from 'core';
// Components
import { Badge, Tag } from 'antd';
import {
	Wrapper,
	Timer,
	TimeRemaining,
	TableVirtualized,
	Collapse as _Collapse,
	Flag,
	Span,
} from 'components';
// Styles
import './Desktop.css';
const { ENV } = GENERAL;

const { Collapse, Panel } = _Collapse;
const { Table, Column, Header, Row, Cell } = TableVirtualized;

class Control extends Component {
	constructor(props) {
		super(props);

		this.getOrderCellRenderer = this.getOrderCellRenderer.bind(this);
		this.getRowRenderer = this.getRowRenderer.bind(this);
		this.handleOnClickRow = this.handleOnClickRow.bind(this);
		this.handleOnRightClickRow = this.handleOnRightClickRow.bind(this);
		this.getHeaderRenderer = this.getHeaderRenderer.bind(this);
		this.getIndexCellRenderer = this.getIndexCellRenderer.bind(this);
		this.getPriceCellRenderer = this.getPriceCellRenderer.bind(this);
	}

	// ------------------------------------------------------ FUNCTIONS -----------------------------------------------------------------
	handleOnClickRow(order, selectedOrders) {
		const { mutate1Object } = this.props;

		const idx = _.findIndex(
			selectedOrders,
			selOrder => selOrder.order_id === order.order_id,
		);
		if (idx === -1) {
			selectedOrders.push(order);
			mutate1Object('control', { selectedOrders });
		}
	}

	handleOnRightClickRow(e, order, selectedOrders) {
		const { mutate1Object } = this.props;
		e.preventDefault();

		const idx = _.findIndex(
			selectedOrders,
			selOrder => selOrder.order_id === order.order_id,
		);
		if (idx !== -1)
			selectedOrders = selectedOrders.filter(
				selOrder => selOrder.order_id !== order.order_id,
			);
		else selectedOrders = [];
		mutate1Object('control', { selectedOrders });
	}

	// ------------------------------------------------------ JSX RENDERS -----------------------------------------------------------------
	// HEADER
	getHeaderRenderer({ dataKey, label, isAllOrdersSelectable }, orderType) {
		const { control, orders, profile, mutate1Object } = this.props;
		return (
			<Header
				onClick={() =>
					isAllOrdersSelectable &&
					mutate1Object('control', { selectedOrders: orders })
				}
				filter={{
					dataKey,
					data: control.orders,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: control.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('control', {
							filters: updatedFilters,
						}),
				}}
			>
				{label}
			</Header>
		);
	}

	getOrderCellRenderer = ({ cellData, rowData }) => {
		return (
			<div
				className='table-order-cell'
				onClick={e => {
					e.stopPropagation();
					this.context.setElementView({
						elementId: rowData.order_id,
						elementViewKey:
							ElementViewBuilderConfig.getAuthorizedElementViewKeys()
								.ORDER_ELEMENT,
					});
				}}
			>
				{cellData}
			</div>
		);
	};

	getRowRenderer = props => {
		const { key, columns, style, rowData } = props;
		const selectedOrders = [...this.props.control.selectedOrders];
		let styleRow = { ...style };
		// flagPriorityStyle = {};

		// if (rowData.priority) flagPriorityStyle = { ...rowData.priority.style };

		// Is order selected?
		const isOrderSelected = _.findIndex(
			selectedOrders,
			order => order.order_id === rowData.order_id,
		);
		if (isOrderSelected !== -1) {
			styleRow = {
				...styleRow,
				color: 'lightseagreen',
				backgroundColor: 'rgb(44,44,54)',
			};
		}

		return (
			<Row
				key={key}
				style={styleRow}
				onClick={() => this.handleOnClickRow(rowData, selectedOrders)}
				onContextMenu={e =>
					this.handleOnRightClickRow(e, rowData, selectedOrders)
				}
			>
				{columns}
			</Row>
		);
	};

	getIndexCellRenderer = props => {
		const { rowData, rowIndex } = props;
		let flagPriorityStyle = {};

		if (rowData.priority) flagPriorityStyle = { ...rowData.priority.style };
		return (
			<Cell>
				<Flag style={flagPriorityStyle} height='20px' />
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	};

	getPriceCellRenderer = props => {
		const { price } = props.rowData;
		const orderPrice = formatNumber.new(price.price, price.symbol || '', 2);

		return (
			<Cell>
				<Span>{orderPrice}</Span>
			</Cell>
		);
	};

	getCountdownCellRenderer = ({ rowData }) => {
		const { start_at, time_remaining, event_props } = rowData;
		return (
			<Timer
				startAt={start_at}
				timeRemaining={time_remaining}
				freezeTimer={event_props?.[ENV.MANAGE_PROPS.FLAGS.FREEZE_TIMER]}
				render={({ timeRemaining }) => (
					/* REMAINING */
					<TimeRemaining time={timeRemaining} />
				)}
			/>
		);
	};

	render() {
		const { control, orders, profile } = this.props;
		let segmented = [];

		if (control.view === 'users')
			segmented = OrderUtils.getExpertListOrders(orders);
		else if (control.view === 'events')
			segmented = OrderUtils.getEventListOrders(orders);
		else if (control.view === 'services')
			segmented = OrderUtils.getServiceListOrders(orders);

		return (
			<Wrapper padding='0'>
				{control.view === 'table' && (
					<Table
						highBackground
						width={document.documentElement.clientWidth}
						height={document.documentElement.clientHeight - 115}
						headerHeight={44}
						rowHeight={25}
						rowCount={orders.length}
						rowGetter={({ index }) => orders[index]}
						rowRenderer={this.getRowRenderer}
					>
						<Column
							dataKey='index'
							label='#'
							width={80}
							headerRenderer={({ label }) => <Header>{label}</Header>}
							cellRenderer={this.getIndexCellRenderer}
						/>
						<Column
							dataKey='formatExecutionAt'
							label='Fecha'
							width={130}
							headerRenderer={props => this.getHeaderRenderer(props, 'date')}
						/>
						<Column
							dataKey='client_idc'
							label='ID Cliente'
							width={150}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='client_name'
							label='Cliente'
							width={290}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
						/>
						{/* <Column
              dataKey="contract_number"
              label="Contrato"
              width={150}
              headerRenderer={(props) => this.getHeaderRenderer(props, "text")}
            /> */}
						<Column
							dataKey='city'
							label='Provincia'
							width={130}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='township'
							label='Canton'
							width={130}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='zone'
							label='Zona'
							width={150}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='odt_id'
							label='Orden'
							width={150}
							headerRenderer={props =>
								this.getHeaderRenderer(
									{ ...props, isAllOrdersSelectable: true },
									'text',
								)
							}
							cellRenderer={this.getOrderCellRenderer}
						/>
						<Column
							dataKey='service_name'
							label='Servicio'
							width={290}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='expert_name'
							label='Tecnico'
							width={200}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='entity_name'
							label='Entidad'
							width={170}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='event_name'
							label='Evento'
							width={140}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='state_name'
							label='Estado'
							width={140}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='dilation'
							label='Dilacion'
							width={120}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey='external_range'
							label='Rango'
							width={100}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
						/>
						<Column
							dataKey=''
							label='Temporizador'
							width={200}
							headerRenderer={props => this.getHeaderRenderer(props, 'text')}
							cellRenderer={this.getCountdownCellRenderer}
						/>
						{profile &&
							profile.user.settings.orders &&
							profile.user.settings.orders.price.viewIndividual && (
								<Column
									dataKey=''
									label='Precio'
									width={110}
									headerRenderer={props =>
										this.getHeaderRenderer(props, 'numeric')
									}
									cellRenderer={this.getPriceCellRenderer}
								/>
							)}
					</Table>
				)}

				{control.view === 'users' && (
					<Collapse
						bordered={false}
						defaultActiveKey={['-1']}
						style={{ width: '100%' }}
					>
						{segmented.map((expert, idx) => {
							return (
								<Panel
									key={idx}
									style={{ padding: '8px 8px 0 8px' }}
									header={
										<div className='control-mobile-title-list'>
											<Tag
												style={{
													width: '70px',
													textAlign: 'center',
													color: 'rgba(255,255,255,0.6)',
													backgroundColor: 'transparent',
													boxShadow: '0 0 0 1px #000 inset',
													marginRight: '5px',
												}}
											>
												{expert.work_code}
											</Tag>
											<span className='control-desktop-title-expert'>
												{expert.name}
											</span>
											<Badge
												count={expert.count}
												style={{
													backgroundColor: 'transparent',
													color: 'lightcoral',
													boxShadow: '0 0 0 1px #d9d9d9 inset',
													fontFamily: 'Roboto',
												}}
												offset={[0, 0]}
												overflowCount={999}
											/>
										</div>
									}
								>
									<Table
										highBackground
										width={document.documentElement.clientWidth}
										height={document.documentElement.clientHeight * 0.7}
										headerHeight={44}
										rowHeight={25}
										rowCount={
											orders.filter(
												order => order.assigned_tech_id === expert.user_id,
											).length
										}
										rowGetter={({ index }) =>
											orders.filter(
												order => order.assigned_tech_id === expert.user_id,
											)[index]
										}
										rowRenderer={this.getRowRenderer}
									>
										<Column
											dataKey='index'
											label='#'
											width={50}
											headerRenderer={({ label }) => <Header>{label}</Header>}
											cellRenderer={this.getIndexCellRenderer}
										/>
										<Column
											dataKey='formatExecutionAt'
											label='Fecha'
											width={130}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'date')
											}
										/>
										<Column
											dataKey='client_idc'
											label='ID Cliente'
											width={150}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='client_name'
											label='Cliente'
											width={250}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='contract_number'
											label='Contrato'
											width={150}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='city'
											label='Provincia'
											width={130}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='township'
											label='Canton'
											width={130}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='zone'
											label='Zona'
											width={150}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='odt_id'
											label='Orden'
											width={150}
											headerRenderer={props =>
												this.getHeaderRenderer(
													{ ...props, isAllOrdersSelectable: true },
													'text',
												)
											}
											cellRenderer={this.getOrderCellRenderer}
										/>
										<Column
											dataKey='service_name'
											label='Servicio'
											width={290}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='event_name'
											label='Evento'
											width={140}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='state_name'
											label='Estado'
											width={140}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='dilation'
											label='Dilacion'
											width={120}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='external_range'
											label='Rango'
											width={100}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey=''
											label='Temporizador'
											width={200}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
											cellRenderer={this.getCountdownCellRenderer}
										/>
										{profile &&
											profile.user.settings.orders &&
											profile.user.settings.orders.price.viewIndividual && (
												<Column
													dataKey=''
													label='Precio'
													width={130}
													headerRenderer={props =>
														this.getHeaderRenderer(props, 'numeric')
													}
													cellRenderer={this.getPriceCellRenderer}
												/>
											)}
									</Table>
								</Panel>
							);
						})}
					</Collapse>
				)}

				{control.view === 'events' && (
					<Collapse
						bordered={false}
						defaultActiveKey={['-1']}
						style={{ width: '100%' }}
					>
						{segmented.map((event, idx) => {
							return (
								<Panel
									key={idx}
									style={{ padding: '8px 8px 0 8px' }}
									header={
										<div className='control-mobile-title-list'>
											<span className='control-desktop-title-expert'>
												{event.name}
											</span>
											<Badge
												count={event.count}
												style={{
													backgroundColor: 'transparent',
													color: 'lightcoral',
													boxShadow: '0 0 0 1px #d9d9d9 inset',
													fontFamily: 'Roboto',
												}}
												offset={[0, 0]}
												overflowCount={999}
											/>
										</div>
									}
								>
									<Table
										highBackground
										width={document.documentElement.clientWidth}
										height={document.documentElement.clientHeight * 0.7}
										headerHeight={44}
										rowHeight={25}
										rowCount={
											orders.filter(order => order.event_id === event.event_id)
												.length
										}
										rowGetter={({ index }) =>
											orders.filter(order => order.event_id === event.event_id)[
												index
											]
										}
										rowRenderer={this.getRowRenderer}
									>
										<Column
											dataKey='index'
											label='#'
											width={50}
											headerRenderer={({ label }) => <Header>{label}</Header>}
											cellRenderer={this.getIndexCellRenderer}
										/>
										<Column
											dataKey='formatExecutionAt'
											label='Fecha'
											width={130}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'date')
											}
										/>
										<Column
											dataKey='client_idc'
											label='ID Cliente'
											width={150}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='client_name'
											label='Cliente'
											width={250}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='contract_number'
											label='Contrato'
											width={150}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='city'
											label='Provincia'
											width={130}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='township'
											label='Canton'
											width={130}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='zone'
											label='Zona'
											width={150}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='odt_id'
											label='Orden'
											width={150}
											headerRenderer={props =>
												this.getHeaderRenderer(
													{ ...props, isAllOrdersSelectable: true },
													'text',
												)
											}
											cellRenderer={this.getOrderCellRenderer}
										/>
										<Column
											dataKey='service_name'
											label='Servicio'
											width={290}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='expert_name'
											label='Tecnico'
											width={250}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='entity_name'
											label='Entidad'
											width={170}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='state_name'
											label='Estado'
											width={140}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='dilation'
											label='Dilacion'
											width={120}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='external_range'
											label='Rango'
											width={100}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey=''
											label='Temporizador'
											width={200}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
											cellRenderer={this.getCountdownCellRenderer}
										/>
										{profile &&
											profile.user.settings.orders &&
											profile.user.settings.orders.price.viewIndividual && (
												<Column
													dataKey=''
													label='Precio'
													width={140}
													headerRenderer={props =>
														this.getHeaderRenderer(props, 'numeric')
													}
													cellRenderer={this.getPriceCellRenderer}
												/>
											)}
									</Table>
								</Panel>
							);
						})}
					</Collapse>
				)}

				{control.view === 'services' && (
					<Collapse
						bordered={false}
						defaultActiveKey={['-1']}
						style={{ width: '100%' }}
					>
						{segmented.map((service, idx) => {
							return (
								<Panel
									key={idx}
									style={{ padding: '8px 8px 0 8px' }}
									header={
										<div className='control-mobile-title-list'>
											<span className='control-desktop-title-expert'>
												{service.name}
											</span>
											<Badge
												count={service.count}
												style={{
													backgroundColor: 'transparent',
													color: 'lightcoral',
													boxShadow: '0 0 0 1px #d9d9d9 inset',
													fontFamily: 'Roboto',
												}}
												offset={[0, 0]}
												overflowCount={999}
											/>
										</div>
									}
								>
									<Table
										highBackground
										width={document.documentElement.clientWidth}
										height={document.documentElement.clientHeight * 0.7}
										headerHeight={44}
										rowHeight={25}
										rowCount={
											orders.filter(
												order => order.service_id === service.service_id,
											).length
										}
										rowGetter={({ index }) =>
											orders.filter(
												order => order.service_id === service.service_id,
											)[index]
										}
										rowRenderer={this.getRowRenderer}
									>
										<Column
											dataKey='index'
											label='#'
											width={50}
											headerRenderer={({ label }) => <Header>{label}</Header>}
											cellRenderer={this.getIndexCellRenderer}
										/>
										<Column
											dataKey='formatExecutionAt'
											label='Fecha'
											width={130}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'date')
											}
										/>
										<Column
											dataKey='client_idc'
											label='Cliente'
											width={150}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='client_name'
											label='Cliente'
											width={250}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='contract_number'
											label='Contrato'
											width={150}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='city'
											label='Provincia'
											width={130}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='township'
											label='Canton'
											width={130}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='zone'
											label='Zona'
											width={150}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='odt_id'
											label='Orden'
											width={150}
											headerRenderer={props =>
												this.getHeaderRenderer(
													{ ...props, isAllOrdersSelectable: true },
													'text',
												)
											}
											cellRenderer={this.getOrderCellRenderer}
										/>
										<Column
											dataKey='expert_name'
											label='Tecnico'
											width={200}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='entity_name'
											label='Entidad'
											width={170}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='event_name'
											label='Evento'
											width={140}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='state_name'
											label='Estado'
											width={140}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='dilation'
											label='Dilacion'
											width={120}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey='external_range'
											label='Rango'
											width={100}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
										/>
										<Column
											dataKey=''
											label='Temporizador'
											width={200}
											headerRenderer={props =>
												this.getHeaderRenderer(props, 'text')
											}
											cellRenderer={this.getCountdownCellRenderer}
										/>
										{profile &&
											profile.user.settings.orders &&
											profile.user.settings.orders.price.viewIndividual && (
												<Column
													dataKey=''
													label='Precio'
													width={130}
													headerRenderer={props =>
														this.getHeaderRenderer(props, 'numeric')
													}
													cellRenderer={this.getPriceCellRenderer}
												/>
											)}
									</Table>
								</Panel>
							);
						})}
					</Collapse>
				)}
			</Wrapper>
		);
	}
}

Control.contextType = ElementViewBuilderContext;

export default Control;
