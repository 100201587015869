// Libs
import React from 'react';
import _ from 'underscore';
import Immutable from 'immutable';
// Utils
import { filterConflictsImporting } from 'utils/filters/Orders/ConflictsImporting';
import { getMyEntityActiveUsers, getSpecificUsers } from 'utils/libs';
import { getCities, getTownships, getZones } from 'utils/filters/Zones';
import orderBy from 'utils/orderBy';
// Components
import {
	Wrapper,
	TableVirtualized,
	Checkbox,
	Span,
	Tooltip,
	CloseIcon,
	CheckIcon,
} from 'components';
import Select from 'components/Select';
const {
	Table,
	Column,
	Header,
	Row,
	Cell,
	onClickRow,
	onDoubleClickRow,
	onRightClickRow,
} = TableVirtualized;

const { Option } = Select;

function ConflictsImportingDesktop(props) {
	const {
		// Props
		enterNewOrdersModal,
		fieldsTemplateValidator,
		zones,
		profile,
		// Functions
		mutate1Object,
	} = props;
	const {
		importedFile,
		filters,
		onHoverCells,
		selected,
		userAssignment,
		mappingValidationOk,
	} = enterNewOrdersModal;
	const {
		odtField,
		serviceField,
		stateField,
		assignedTechField,
		cityField,
		townshipField,
		zoneField,
	} = fieldsTemplateValidator;
	const orders = importedFile.data;
	const filteredOrders = filterConflictsImporting(
		orders,
		filters,
		userAssignment,
	);
	const users = getMyEntityActiveUsers(props.users.data, profile);
	const experts = getSpecificUsers(users, { experts: true });
	const entities = getSpecificUsers(users, { entities: true });
	const selectUsers = [...experts, ...entities];
	// Order Users to Select
	orderBy(selectUsers, 'object', { objectKey: 'name' });

	// Handlers
	function onMouseHover(rowIndex, columnIndex, value) {
		mutate1Object('enterNewOrdersModal', {
			onHoverCells: {
				...onHoverCells,
				[rowIndex]: { ...onHoverCells[rowIndex], [columnIndex]: value },
			},
		});
	}
	function onChangeZone(odtId, field, option) {
		const data = Immutable.List(orders).toJS();

		// Setter
		function setNewValues(order) {
			if (order) {
				// City Column
				if (field.name === cityField.name) {
					// Set City
					order[field.id] = option.id;
					order[field.name] = option.name;
					// Reset Township
					order[townshipField.id] = undefined;
					order[townshipField.name] = '';
					// Reset Zone
					order[zoneField.id] = undefined;
					order[zoneField.name] = '';
				} // Township Column
				else if (field.name === townshipField.name) {
					// Set Township
					order[field.id] = option.id;
					order[field.name] = option.name;
					// Reset Zone
					order[zoneField.id] = undefined;
					order[zoneField.name] = '';
				} // Zone Column
				else if (field.name === zoneField.name) {
					// Set zone
					order[field.id] = option.id;
					order[field.name] = option.name;
				}
			}
		}

		// Hay ordenes seleccionadas?
		if (selected.items.length > 0) {
			// Se recorren las ordenes seleccionadas
			[...selected.items, odtId].map(orderSelected => {
				const orderIdx = _.findIndex(
					data,
					ord => ord[odtField.id] === orderSelected,
				);
				setNewValues(data[orderIdx]);
			});
		} else {
			const orderIdx = _.findIndex(data, ord => ord[odtField.id] === odtId);
			setNewValues(data[orderIdx]);
		}

		mutate1Object('enterNewOrdersModal', {
			importedFile: {
				...importedFile,
				data,
			},
			onHoverCells: {},
		});
	}
	function onChangeUser(odtId) {
		mutate1Object('ordersReassignmentModal', {
			isOpen: true,
			assignment: 'local',
		});
		mutate1Object('enterNewOrdersModal', {
			selected: {
				items:
					selected.items.indexOf(odtId) === -1
						? [...selected.items, odtId]
						: selected.items,
			},
		});
	}

	const onChangedFilter = updatedFilters =>
		mutate1Object('enterNewOrdersModal', { filters: updatedFilters });

	const onFilterItems = filteredItems =>
		mutate1Object('enterNewOrdersModal', {
			selected: { ...selected, items: filteredItems },
		});

	return (
		<Wrapper padding='0'>
			<Table
				highBackground
				width={document.documentElement.clientWidth}
				height={document.documentElement.clientHeight - 115}
				headerHeight={44}
				rowHeight={40}
				rowCount={filteredOrders.length}
				rowGetter={({ index }) => filteredOrders[index]}
				rowRenderer={({ key, columns, style, rowData }) => (
					<Row
						key={key}
						style={style}
						onContextMenu={e => {
							onRightClickRow(
								e,
								odtField.id,
								selected.items,
								filteredOrders,
								onFilterItems,
							);
						}}
					>
						{columns}
					</Row>
				)}
			>
				{/* INDEX */}
				<Column
					dataKey='index'
					label='#'
					width={80}
					headerRenderer={({ label }) => (
						<Header justifyContent='space-around'>{label}</Header>
					)}
					cellRenderer={({ rowIndex, rowData }) => (
						<Tooltip title='Pulsa dos veces sobre cualquier índice para seleccionar todas las filas visibles'>
							<Cell
								onDoubleClick={() => {
									onDoubleClickRow(
										odtField.id,
										selected,
										filteredOrders,
										mutate1Object,
										'enterNewOrdersModal',
									);
								}}
							>
								<Checkbox
									margin='2px 0 2px 5px'
									checked={selected.items.indexOf(rowData[odtField.id]) !== -1}
									onChange={() =>
										onClickRow(
											rowData[odtField.id],
											selected,
											mutate1Object,
											'enterNewOrdersModal',
										)
									}
								/>
								<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
							</Cell>
						</Tooltip>
					)}
				/>
				{/* ODT ID */}
				<Column
					dataKey={odtField.id}
					label='Orden'
					width={200}
					headerRenderer={({ dataKey, label }) => (
						<Header
							filter={{
								dataKey,
								data: orders,
								orderType: 'text',
								filters,
								onChangedFilter,
							}}
						>
							{label}
						</Header>
					)}
				/>
				{/* SERVICE */}
				{serviceField && (
					<Column
						dataKey={serviceField.name}
						label='Servicio'
						width={260}
						headerRenderer={({ dataKey, label }) => (
							<Header
								filter={{
									dataKey,
									data: orders,
									orderType: 'text',
									filters,
									onChangedFilter,
								}}
							>
								{label}
							</Header>
						)}
					/>
				)}
				{/* STATE */}
				{stateField && (
					<Column
						dataKey={stateField.name}
						label='Estado'
						width={260}
						headerRenderer={({ dataKey, label }) => (
							<Header
								filter={{
									dataKey,
									data: orders,
									orderType: 'text',
									filters,
									onChangedFilter,
								}}
							>
								{label}
							</Header>
						)}
					/>
				)}
				{/* CITY */}
				{cityField && (
					<Column
						dataKey={cityField.name}
						label='Ciudad'
						width={300}
						headerRenderer={({ dataKey, label }) => (
							<Header
								filter={{
									dataKey,
									data: orders,
									orderType: 'text',
									filters,
									onChangedFilter,
								}}
							>
								{label}
							</Header>
						)}
						cellRenderer={({ rowData, dataKey, rowIndex, columnIndex }) => (
							<Cell justifyContent='space-between'>
								{onHoverCells[rowIndex] &&
								onHoverCells[rowIndex][columnIndex] ? (
									<Select
										width='100%'
										value={rowData[cityField.id]}
										onChange={(value, { props }) => {
											const option = { id: value, name: props.children };
											onChangeZone(rowData[odtField.id], cityField, option);
										}}
									>
										{getCities(zones.data).map((zone, idx) => (
											<Option key={idx} value={zone.city_id}>
												{zone.city_name}
											</Option>
										))}
									</Select>
								) : (
									<>
										<Span margin='0 0 0 5px' overflow='auto'>
											{rowData[dataKey]}
										</Span>
										{!rowData[cityField.id] ? (
											<CloseIcon
												button
												color='orange'
												onClick={() =>
													onMouseHover(rowIndex, columnIndex, true)
												}
											/>
										) : (
											<CheckIcon
												button
												onClick={() =>
													!mappingValidationOk &&
													rowData.mappingErrors === 'N/A' &&
													onMouseHover(rowIndex, columnIndex, true)
												}
											/>
										)}
									</>
								)}
							</Cell>
						)}
					/>
				)}
				{/* TOWNSHIP */}
				{townshipField && (
					<Column
						dataKey={townshipField.name}
						label='Cantón'
						width={300}
						headerRenderer={({ dataKey, label }) => (
							<Header
								filter={{
									dataKey,
									data: orders,
									orderType: 'text',
									filters,
									onChangedFilter,
								}}
							>
								{label}
							</Header>
						)}
						cellRenderer={({ rowData, dataKey, rowIndex, columnIndex }) => (
							<Cell justifyContent='space-between'>
								{onHoverCells[rowIndex] &&
								onHoverCells[rowIndex][columnIndex] ? (
									<Select
										width='100%'
										value={rowData[townshipField.id]}
										onChange={(value, { props }) => {
											const option = { id: value, name: props.children };
											onChangeZone(rowData[odtField.id], townshipField, option);
										}}
									>
										{getTownships(zones.data, rowData[cityField.id]).map(
											(zone, idx) => (
												<Option key={idx} value={zone.township_id}>
													{zone.township_name}
												</Option>
											),
										)}
									</Select>
								) : (
									<>
										<Span margin='0 0 0 5px' overflow='auto'>
											{rowData[dataKey]}
										</Span>
										{!rowData[townshipField.id] ? (
											<CloseIcon
												button
												color='orange'
												onClick={() =>
													onMouseHover(rowIndex, columnIndex, true)
												}
											/>
										) : (
											<CheckIcon
												button
												onClick={() =>
													!mappingValidationOk &&
													rowData.mappingErrors === 'N/A' &&
													onMouseHover(rowIndex, columnIndex, true)
												}
											/>
										)}
									</>
								)}
							</Cell>
						)}
					/>
				)}
				{/* ZONE */}
				{zoneField && (
					<Column
						dataKey={zoneField.name}
						label='Distrito'
						width={300}
						headerRenderer={({ dataKey, label }) => (
							<Header
								filter={{
									dataKey,
									data: orders,
									orderType: 'text',
									filters,
									onChangedFilter,
								}}
							>
								{label}
							</Header>
						)}
						cellRenderer={({ rowData, dataKey, rowIndex, columnIndex }) => (
							<Cell justifyContent='space-between'>
								{onHoverCells[rowIndex] &&
								onHoverCells[rowIndex][columnIndex] ? (
									<Select
										width='100%'
										value={rowData[zoneField.id]}
										onChange={(value, { props }) => {
											const option = { id: value, name: props.children };
											onChangeZone(rowData[odtField.id], zoneField, option);
										}}
									>
										{getZones(
											zones.data,
											rowData[cityField.id],
											rowData[townshipField.id],
										).map((zone, idx) => (
											<Option key={idx} value={zone.zone_id}>
												{zone.zone_name}
											</Option>
										))}
									</Select>
								) : (
									<>
										<Span margin='0 0 0 5px' overflow='auto'>
											{rowData[dataKey]}
										</Span>
										{!rowData[zoneField.id] ? (
											<CloseIcon
												button
												color='orange'
												onClick={() =>
													onMouseHover(rowIndex, columnIndex, true)
												}
											/>
										) : (
											<CheckIcon
												button
												onClick={() =>
													!mappingValidationOk &&
													rowData.mappingErrors === 'N/A' &&
													onMouseHover(rowIndex, columnIndex, true)
												}
											/>
										)}
									</>
								)}
							</Cell>
						)}
					/>
				)}
				{/* EXPERT */}
				{assignedTechField &&
					(userAssignment === 'withConflicts' ||
						userAssignment === 'withoutConflicts') && (
						<Column
							dataKey={assignedTechField.name}
							label='Usuario'
							width={300}
							headerRenderer={({ dataKey, label }) => (
								<Header
									filter={{
										dataKey,
										data: orders,
										orderType: 'text',
										filters,
										onChangedFilter,
									}}
								>
									{label}
								</Header>
							)}
							cellRenderer={({ rowData, dataKey, rowIndex, columnIndex }) => (
								<Cell justifyContent='space-between'>
									<>
										<Span margin='0 0 0 5px' overflow='auto' cursor='pointer'>
											{rowData[dataKey]}
										</Span>
										{!rowData[assignedTechField.id] ? (
											<CloseIcon
												button
												color='orange'
												onClick={() => onChangeUser(rowData[odtField.id])}
											/>
										) : (
											<CheckIcon
												button
												onClick={() => onChangeUser(rowData[odtField.id])}
											/>
										)}
									</>
								</Cell>
							)}
						/>
					)}
				{/* WARRANTY */}
				{(userAssignment === 'withConflicts' ||
					userAssignment === 'withoutConflicts') && (
					<Column
						dataKey='warranty_label'
						label='Garantía'
						width={200}
						headerRenderer={({ dataKey, label }) => (
							<Header
								filter={{
									dataKey,
									data: orders,
									orderType: 'text',
									filters,
									onChangedFilter,
								}}
							>
								{label}
							</Header>
						)}
					/>
				)}
				{/* CONFLICT FIELD */}
				<Column
					dataKey='mappingErrors'
					label='Conflictos'
					width={200}
					headerRenderer={({ dataKey, label }) => (
						<Header
							filter={{
								dataKey,
								data: orders,
								orderType: 'text',
								filters,
								onChangedFilter,
							}}
						>
							{label}
						</Header>
					)}
				/>
			</Table>
		</Wrapper>
	);
}

export default ConflictsImportingDesktop;
