//  Constants
import CONSUME_INVENTORY_TYPES from "./types";
import KEYWORDS from "./keywords";

//  InitialState
const initialState = {
  control: {
    wtd: null,
    workflow: KEYWORDS.WORKFLOW.CONSUME,
    preview: false,
    editableInventory: false,
    controlledInventory: false,
    consumedItems: [],
    sending: false,
    isFetching: false,
    isFetchingWTD: false,
    resumeControl: {
      status: undefined,
    },
    uninstallInventoryControl: {
      show: false,
      selectedItemId: undefined,
      value: undefined,
      searchInData: {
        show: false,
        data: "",
      },
    },
  },
  autoSync: {
    active: true,
    isFetching: false,
  },
};

const consumeInventoryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CONSUME_INVENTORY_TYPES.MUTATE_1OBJECT: {
      const { obj1Name, keyValuePairs } = action.payload,
        newObj = { ...state[obj1Name], ...keyValuePairs };
      return {
        ...state,
        [obj1Name]: newObj,
      };
    }
    case CONSUME_INVENTORY_TYPES.MUTATE_CONSUME_INVENTORY_CONTROL: {
      return {
        ...state,
        control: {
          ...state.control,
          ...payload,
        },
      };
    }
    case CONSUME_INVENTORY_TYPES.MUTATE_UNINSTALL_INVENTORY_CONTROL: {
      return {
        ...state,
        control: {
          ...state.control,
          uninstallInventoryControl: {
            ...state.control.uninstallInventoryControl,
            ...payload,
          },
        },
      };
    }
    case CONSUME_INVENTORY_TYPES.MUTATE_RESUME_CONTROL: {
      return {
        ...state,
        control: {
          ...state.control,
          resumeControl: {
            ...state.control.resumeControl,
            ...payload,
          },
        },
      };
    }
    case CONSUME_INVENTORY_TYPES.RESET_RESUME_CONTROL: {
      return {
        ...state,
        control: {
          ...state.control,
          resumeControl: {
            ...initialState.control.resumeControl,
          },
        },
      };
    }
    case CONSUME_INVENTORY_TYPES.GET_CURRENT_CONSUMED_ITEMS_SUCCESS: {
      return {
        ...state,
        control: {
          ...state.control,
          consumedItems: action.payload,
          isFetching: false,
        },
      };
    }
    case CONSUME_INVENTORY_TYPES.SAVE_CONSUMED_INVENTORY_SUCCESS: {
      const { orderId, consumedItems } = action.payload;

      if (!orderId) {
        return state;
      }
      return {
        ...state,
        control: {
          ...state.control,
          consumedItems,
          sending: false,
        },
      };
    }
    case CONSUME_INVENTORY_TYPES.SET_AUTO_SYNC: {
      return {
        ...state,
        autoSync: {
          ...state.autoSync,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default consumeInventoryReducer;
