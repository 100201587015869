// Libs
import { takeLatest, put, call, all, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
// Utils
import asyncErrorsHandler from 'store/asyncErrorsHandler';
import AuthService from 'utils/libs/auth/AuthService';
import API from 'utils/constants/api';
import KEYWORDS from './keywords';
// Actions
import { mutate1Object } from './actions';
const { ACTIONS } = KEYWORDS;

const auth = new AuthService();

/** **** WATCHER SAGAS *******/
function* getProjectsWatcher() {
	yield takeLatest(ACTIONS.GET_PROJECTS, getProjectsWorker);
}
function* getServicesWatcher() {
	yield takeLatest(ACTIONS.GET_SERVICES, getServicesWorker);
}
function* getExpertsWatcher() {
	yield takeLatest(ACTIONS.GET_EXPERTS, getExpertsWorker);
}
function* getEveryDataFromProcessedSQLWatcher() {
	yield takeEvery(
		ACTIONS.GET_EVERY_DATA_FROM_PROCESSED_SQL,
		getDataFromProcessedSQLWorker,
	);
}
function* getLatestDataFromProcessedSQLWatcher() {
	yield takeLatest(
		ACTIONS.GET_LATEST_DATA_FROM_PROCESSED_SQL,
		getDataFromProcessedSQLWorker,
	);
}
function* getElementViewWatcher() {
	yield takeLatest(ACTIONS.GET_ELEMENT_VIEW, getElementViewWorker);
}

//* ******* WORKERS SAGAS ***********/
function* getProjectsWorker(action) {
	try {
		yield put(
			mutate1Object('projects', {
				isFetching: true,
				selectedProjectId: undefined,
			}),
		);
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat(
				`/departments/v1/projects/${action.payload.moduleItemId}`,
			),
			auth.sendToken(),
		);
		yield put(
			mutate1Object('projects', {
				isFetching: false,
				data,
				selectedProjectId: data[0]?.id,
			}),
		);
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getProjectsWorker(action);
		});
	}
}
function* getServicesWorker(action) {
	const { orderTargetId } = action.payload;

	try {
		yield put(
			mutate1Object('services', {
				isFetching: true,
				selectedServiceIds: [],
				data: [],
			}),
		);
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat(`/services/v1/${orderTargetId}`),
			auth.sendToken(),
		);
		yield put(
			mutate1Object('services', {
				isFetching: false,
				data,
				selectedServiceIds: data.map(s => s.id),
			}),
		);
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getServicesWorker(action);
		});
	}
}
function* getExpertsWorker(action) {
	const { projectId } = action.payload;

	try {
		yield put(mutate1Object('experts', { isFetching: true }));
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat(`/command_center/v1/experts/${projectId}`),
			auth.sendToken(),
		);
		yield put(mutate1Object('experts', { isFetching: false, data }));
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getExpertsWorker(action);
		});
	}
}
function* getDataFromProcessedSQLWorker(action) {
	const {
		selectedDataFilterId,
		subDataFilterId,
		searchValue,
		userParams,
		lastRowId = 0,
		limit = 15,
		onLoading,
		onError,
		onSuccess,
		singleSql,
	} = action.payload;
	try {
		if (onLoading) yield onLoading();
		const { data: response } = yield call(
			axios.post,
			API.DOMAIN.concat(
				`/modules/v1/getDataFromProcessedSQL/${selectedDataFilterId}/${subDataFilterId}/${lastRowId}/${limit}`,
			),
			{
				searchValue,
				userParams,
				singleSql,
			},
			auth.sendToken(),
		);
		if (onSuccess) yield onSuccess(response);
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				if (onError) yield onError();
			},
			function* () {
				yield getDataFromProcessedSQLWorker(action);
			},
		);
	}
}
function* getElementViewWorker(action) {
	const { selectedDataFilterId, elementId, onLoading, onSuccess, onError } =
		action.payload;

	try {
		if (onLoading) yield onLoading();
		const { data: response } = yield call(
			axios.get,
			API.DOMAIN.concat(
				`/datafilterElementView/v1/${selectedDataFilterId}/${elementId}`,
			),
			auth.sendToken(),
		);
		if (onSuccess) yield onSuccess(response);
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				if (onError) yield onError(err);
			},
			function* () {
				yield getElementViewWorker(action);
			},
		);
	}
}

// todo: use this file for something
/** **** EXPORT DEFAULT ROOT SAGA *******/
export default function* rootSaga() {
	yield all([
		getProjectsWatcher(),
		getServicesWatcher(),
		getExpertsWatcher(),
		getEveryDataFromProcessedSQLWatcher(),
		getLatestDataFromProcessedSQLWatcher(),
		getElementViewWatcher(),
	]);
}
