// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='maskAddSmallIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='1'
			y='1'
			width='14'
			height='14'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7 1V7H1V9H7V15H9V9H15V7H9V1H7Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#maskAddSmallIcon)'>
			<rect width='16' height='16' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
