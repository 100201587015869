// Libs
import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
// Config
import ToolbarBuilderConfig from './toolbar.config';
import KEYWORDS from '../keywords';
// Actions
import { toggleDatasource } from 'core/actions';
import { mutate1Object as mutate1ObjectInCore } from 'core/actions';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Wrapper, Notifications, HamburguerIcon, BackIcon } from 'components';
import { DisplayPicker, DataFilterSearchValue } from 'core';
import ShowedPagination from '../ShowedPagination';
import DataExport from './components/DataExport';

const { ROUTES } = KEYWORDS;

const toolbars = ToolbarBuilderConfig.getAuthorizedToolbarReactNodes();

const GoBackButton = ({ history, dispatch }) => (
	<BackIcon
		onClick={() => {
			dispatch(
				mutate1ObjectInCore('display', {
					isElementViewerActive: false,
				}),
			);
			history.goBack();
		}}
	/>
);

const LeftSideSwitcher = ({ menuIsOpen, history, dispatch }) => {
	return (
		<Switch>
			<Route
				exact
				path={ROUTES.FEED_MODULE_ELEMENT.PATH}
				children={
					<Wrapper padding='0'>
						{!menuIsOpen && (
							<HamburguerIcon
								button
								onClick={() => dispatch(toggleDatasource())}
							/>
						)}
						<Wrapper padding='0 0 0 10px'>
							<GoBackButton history={history} dispatch={dispatch} />
						</Wrapper>
					</Wrapper>
				}
			/>
			<Route
				exact
				path={ROUTES.FEED_MODULE.PATH}
				children={
					<Wrapper padding='0'>
						{!menuIsOpen && (
							<HamburguerIcon
								button
								onClick={() => dispatch(toggleDatasource())}
							/>
						)}
						<ShowedPagination />
					</Wrapper>
				}
			/>
			<Route
				path={ROUTES.FEED.PATH}
				children={
					<Wrapper padding='0'>
						{!menuIsOpen && (
							<HamburguerIcon
								button
								onClick={() => dispatch(toggleDatasource())}
							/>
						)}
					</Wrapper>
				}
			/>
		</Switch>
	);
};

const CenterSideSwitcher = () => {
	return (
		<Switch>
			{Object.keys(toolbars).map(path => {
				const Toolbar = toolbars[path];
				if (!Toolbar) return null;
				return (
					<Route
						exact
						key={path}
						path={path}
						children={
							<Wrapper padding='0' width='100%'>
								<Toolbar />
							</Wrapper>
						}
					/>
				);
			})}
		</Switch>
	);
};

const RightSideSwitcher = ({ datasource }) => {
	return (
		<Switch>
			<Route
				exact
				path={ROUTES.FEED_MODULE_ELEMENT.PATH}
				children={<Wrapper padding='0'></Wrapper>}
			/>
			<Route
				exact
				path={ROUTES.FEED_MODULE.PATH}
				children={
					<Wrapper padding='0'>
						<DataFilterSearchValue />
						<DataExport datasource={datasource} />
						<DisplayPicker />
					</Wrapper>
				}
			/>
			<Route
				path={ROUTES.FEED.PATH}
				children={<Wrapper padding='0'></Wrapper>}
			/>
		</Switch>
	);
};

const ToolbarBuilder = ({
	menuIsOpen,
	screenKey,
	elementKey,
	isMobile,
	datasource,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { theme, isDarkTheme } = useTheme();

	return (
		<Wrapper
			padding='10px 0'
			width='100%'
			height='56px'
			justifyContent='space-between'
			backgroundColor={
				isDarkTheme
					? theme._secondaryBackgroundColor
					: theme._primaryBackgroundColor
			}
		>
			<LeftSideSwitcher
				menuIsOpen={menuIsOpen}
				history={history}
				dispatch={dispatch}
			/>
			<CenterSideSwitcher />
			<Wrapper padding='0'>
				<RightSideSwitcher datasource={datasource} />
				<Notifications isMobile={isMobile} />
			</Wrapper>
		</Wrapper>
	);
};

export default ToolbarBuilder;
