// Libs
import React from 'react';
// Components
import { VirtualizedList } from 'screens/Utils';
import { Wrapper, LogoIcon } from 'components';

const ListItems = ({ rect, marketplaceItems, handleOnClickUpdateStatus }) => {
	if (marketplaceItems.isFetching) {
		return (
			<Wrapper
				width={`${rect?.width}px`}
				padding='50px 0 0 0'
				alignItems='flex-start'
				justifyContent='center'
				height={`${document.documentElement.clientHeight}px`}
			>
				<LogoIcon spin={true} />
			</Wrapper>
		);
	}
	return (
		<Wrapper padding='0' margin='50px 0 0 0'>
			<VirtualizedList
				cardId='marketItemCard'
				listStyle={{
					width: Math.round(rect?.width),
					height: document.documentElement.clientHeight,
				}}
				cardStyle={{
					height: '100px',
					padding: '10px 10px 10px 0',
				}}
				rowHeight={110}
				rows={marketplaceItems.data}
				customProps={{
					rect,
					handleOnClickUpdateStatus,
				}}
			/>
		</Wrapper>
	);
};

export default ListItems;
