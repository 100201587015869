// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Components
import {
	Wrapper,
	Span,
	Button,
	Spin,
	RefreshIcon,
	LogoIcon,
	CloseIcon,
	CheckIcon,
} from 'components';
import { ExcelIcon } from 'components/Icons';
// Utils
import { xlsx } from 'utils/libs';
import GENERAL from 'utils/constants/general';
// Styles
import '../index.css';

const { DIVISION_ID } = GENERAL;

const ItemsTransactionDoc = ({
	items,
	createPackage,
	mutate1ObjectInWarehouses,
	mutate1ObjectApi,
	updateTransaction,
	sendToast,
	itemsTransactionDoc,
	getItemsTransactionDocs,
	transactionsManage,
	transactions,
	profile,
}) => {
	const theme = useTheme();
	const transaction = React.useMemo(
		() => transactions.data.find(trn => trn.id === transactionsManage.docId),
		[transactionsManage.docId, transactions.data],
	);

	function quantitySeriesInNewSerializedPackage(items, package_id) {
		return items.reduce((acc, item) => {
			if (item.is_serialized && item.warehouse_package_id === package_id) acc++;
			return acc;
		}, 0);
	}

	function unpackedSeries(items) {
		const unpacked = items.filter(
			item => item.is_serialized && !item.warehouse_package_id,
		);
		return unpacked.length > 0;
	}

	function onCheckCreatedPackage() {
		if (quantitySeriesInNewSerializedPackage(items.data, createPackage.id) <= 0)
			sendToast({
				message: 'Debe agregar al menos una serie al paquete',
				type: 'warn',
			});
		else {
			mutate1ObjectInWarehouses('createPackage', {
				operation: undefined,
				serialized: false,
				id: undefined,
				item_id: undefined,
				state_id: undefined,
				amount: undefined,
				warehouse_area_id: undefined,
				warehouse_zone_id: undefined,
				created: false,
				mustCreateZone: false,
				mustCreateArea: false,
				zone: undefined,
				area: undefined,
			});
		}
	}

	function onCancelCreatedPackage() {
		const data = items.data.map(item => {
			if (item.is_serialized && item.warehouse_package_id === createPackage.id)
				item.warehouse_package_id = null;
			return item;
		});
		mutate1ObjectApi('transactions', { items: { ...items, data } });
		mutate1ObjectInWarehouses('createPackage', {
			operation: undefined,
			serialized: false,
			id: undefined,
			item_id: undefined,
			state_id: undefined,
			amount: undefined,
			warehouse_area_id: undefined,
			warehouse_zone_id: undefined,
			created: false,
			mustCreateZone: false,
			mustCreateArea: false,
			zone: undefined,
			area: undefined,
		});
	}

	function onClickPackagedSeries() {
		mutate1ObjectInWarehouses('createPackage', {
			operation: itemsTransactionDoc.operation,
			isOpen: true,
			serialized: true,
			id: undefined,
			item_id: undefined,
			state_id: undefined,
			amount: undefined,
			warehouse_area_id: undefined,
			warehouse_zone_id: undefined,
			created: false,
			mustCreateZone: false,
			mustCreateArea: false,
			zone: undefined,
			area: undefined,
		});
	}

	return (
		<Wrapper
			padding='10px 0 10px 10px'
			width='100%'
			height='48px'
			justifyContent='flex-end'
			mediumBackground
		>
			<div className='subtoolbar-content'>
				{/* Left content */}
				<div>
					{transaction && createPackage.created && createPackage.serialized && (
						<Span fontWeight='bold' color={theme.fonts.colors.default}>
							{`Paquete: #${
								createPackage.id
							} / Series: ${quantitySeriesInNewSerializedPackage(
								items.data,
								createPackage.id,
							)}`}
						</Span>
					)}
				</div>

				{/* Right content */}
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{transaction && (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{/* TODO: Habilitar EDIT BUTTON cuando ya se pueda y terminar de configurar la tabla de edición */}
							{/* EDIT BUTTON */}
							{/* {(!transaction.completed && !itemsTransactionDoc.operation && transaction.created_by === profile.user.id) &&
                <Button
                  style={{ marginRight: '10px', height: '30px' }}
                  onClick={() => mutate1ObjectInWarehouses('itemsTransactionDoc', { operation: 'editing' })}>
                  Editar
              </Button>} */}

							{/* RECEPTION BUTTON */}
							{!transaction.completed &&
								!itemsTransactionDoc.operation &&
								transaction.dwh_owner_division_id === DIVISION_ID.ENTITY &&
								// TODO: Aqui debería poner la condición de que el almacén destino tiene que ser de tipo "Físico", pero el tipo de almacén debe ser un id, no un nombre
								transaction.dwh_owner_id === profile.entity.id && (
									<Button
										style={{ marginRight: '10px', height: '30px' }}
										onClick={() =>
											mutate1ObjectInWarehouses('itemsTransactionDoc', {
												operation: 'reception',
											})
										}
									>
										Recepción
									</Button>
								)}

							{/* CHECK OR CANCEL SERIES PACKAGE */}
							{itemsTransactionDoc.operation === 'reception' &&
								createPackage.created &&
								createPackage.serialized && (
									<div>
										{/* CANCEL PACKAGE */}
										<CloseIcon button onClick={onCancelCreatedPackage} />

										{/* CHECK PACKAGE */}
										<CheckIcon button onClick={onCheckCreatedPackage} />
									</div>
								)}

							{/* PACKAGED SERIES BUTTON */}
							{itemsTransactionDoc.operation === 'reception' &&
								unpackedSeries(items.data) &&
								!createPackage.created &&
								!itemsTransactionDoc.updating && (
									<Button
										style={{ height: '30px' }}
										onClick={onClickPackagedSeries}
									>
										Empaquetar series
									</Button>
								)}

							{/* SAVE & UPDATE BUTTON */}
							{itemsTransactionDoc.operation && !createPackage.created && (
								<Spin
									spinning={itemsTransactionDoc.updating}
									size='small'
									delay={50}
									tip={''}
								>
									<Button
										style={{
											marginLeft: '10px',
											marginRight: '10px',
											height: '30px',
										}}
										className='button-style-1'
										onClick={() => {
											if (unpackedSeries(items.data)) {
												sendToast({
													message: 'Todas las series deben estar empaquetadas',
													type: 'warn',
												});
												return;
											}
											updateTransaction(
												items.data,
												itemsTransactionDoc.operation,
											);
										}}
									>
										Guardar
									</Button>
								</Spin>
							)}
						</div>
					)}
					<ExcelIcon
						button
						onClick={() =>
							xlsx.exportItemsTransactionDoc(
								'ItemsTransactionDoc',
								items.data,
								transaction,
							)
						}
					/>

					{/* LOADING */}
					{!items.loading ? (
						<RefreshIcon
							button
							onClick={() => getItemsTransactionDocs(transactionsManage.docId)}
						/>
					) : (
						<LogoIcon button spin />
					)}
				</div>
			</div>
		</Wrapper>
	);
};

export default ItemsTransactionDoc;
