// Libs
import React, { useEffect, useContext, useState } from 'react';
import ReactMapGL, {
	Marker,
	Popup,
	Source,
	Layer,
	FlyToInterpolator,
	MapContext,
} from 'react-map-gl';
// Styles
import {
	PopupContainer,
	PopupHeader,
	PopupRow,
	PopupBoldLabel,
	PopupValue,
	PopupImage,
} from './Map.styles';

// Static Resources
import pinPost from './post.png';

// Data and Configurations
import { heatmapLayer, getLineGeoJSON, getGeoJSON } from './map-config';

// Hooks
import { useCollectorManager } from 'hooks';
const { getCollectorLayout } = useCollectorManager();
import { useToastMessage } from 'hooks';

const Map = ({
	photoReport,
	serviceTaskId,
	selectedMarkerId,
	width = '100%',
	height = '100%',
	zoom = 5,
}) => {
	const { id: otdId, templateId, serviceId } = photoReport || {};
	const { map } = useContext(MapContext);
	const [viewport, setViewport] = useState({
		latitude: 90,
		longitude: -80,
		zoom,
	});
	const [markers, setMarkers] = useState([]);
	const [popupInfo, setPopupInfo] = useState(null);
	const sendToast = useToastMessage();
	useEffect(() => {
		if (!map || !otdId || !templateId || !serviceId || !serviceTaskId) return;

		let isMounted = true;
		getCollectorLayout({
			otdId,
			templateId,
			serviceId,
			serviceTaskId,
		})
			.then(markers => {
				if (!isMounted) return;

				const fetchedMarkers = markers.map(marker => ({
					id: marker.id,
					latitude: marker.latitude,
					longitude: marker.longitude,
					label: marker.label,
					order: marker.order,
					lugar: marker.lugar,
					date: marker.date,
					coverImgUrl: marker.coverimgurl,
				}));
				setMarkers(fetchedMarkers);
				if (fetchedMarkers.length > 0 && map) {
					const firstMarker = fetchedMarkers[0];
					setViewport(v => ({
						...v,
						latitude: firstMarker.latitude,
						longitude: firstMarker.longitude,
						zoom: 16,
						transitionDuration: 3000,
						transitionInterpolator: new FlyToInterpolator(),
					}));
				}
			})
			.catch(err => {
				sendToast(err);
			});

		const handleMapLoad = () => {
			if (!isMounted) return;

			if (markers.length > 0) {
				const firstMarker = markers[0];
				setViewport(v => ({
					...v,
					latitude: firstMarker.latitude,
					longitude: firstMarker.longitude,
					zoom: 16,
					transitionDuration: 3000,
					transitionInterpolator: new FlyToInterpolator(),
				}));
			}
		};

		map.on('load', handleMapLoad);
		return () => {
			isMounted = false;
			map.off('load', handleMapLoad);
		};
	}, [map, otdId, templateId, serviceId, serviceTaskId]);

	const lineGeoJSON = getLineGeoJSON(markers);
	const geojson = getGeoJSON(markers);

	useEffect(() => {
		if (!selectedMarkerId) return;

		const selectedMarker = markers.find(m => m.id === selectedMarkerId);
		if (!selectedMarker) return;

		setViewport(prev => ({
			...prev,
			longitude: selectedMarker.longitude,
			latitude: selectedMarker.latitude,
			zoom: 18,
			transitionDuration: 3000,
			transitionInterpolator: new FlyToInterpolator(),
		}));
	}, [selectedMarkerId]);

	const showHeatmap = viewport.zoom <= 15;

	const handleMarkerClick = ({ longitude, latitude }) => {
		setViewport(prev => ({
			...prev,
			longitude,
			latitude,
			zoom: 18,
			transitionDuration: 3000,
			transitionInterpolator: new FlyToInterpolator(),
		}));
	};

	return (
		<>
			<MapContext.Provider>
				<ReactMapGL
					{...viewport}
					width={width}
					height={height}
					onViewportChange={nextViewport => setViewport(nextViewport)}
					mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
					mapStyle='mapbox://styles/mapbox/streets-v11'
				>
					{!showHeatmap &&
						markers.map((marker, index) => (
							<Marker
								key={index}
								latitude={marker.latitude}
								longitude={marker.longitude}
								onClick={() =>
									handleMarkerClick({
										longitude: marker.longitude,
										latitude: marker.latitude,
									})
								}
							>
								<img
									src={pinPost}
									alt='Pin'
									style={{ height: '24px', width: '24px' }}
									onClick={() => setPopupInfo(marker)}
								/>
							</Marker>
						))}

					{popupInfo && !showHeatmap && (
						<Popup
							latitude={popupInfo.latitude}
							longitude={popupInfo.longitude}
							onClose={() => setPopupInfo(null)}
							anchor='top'
						>
							<PopupContainer>
								<PopupHeader>
									<strong>{popupInfo.label}</strong>
								</PopupHeader>
								<PopupRow>
									<PopupBoldLabel>Orden:</PopupBoldLabel>
									<PopupValue>{popupInfo.order}</PopupValue>
								</PopupRow>
								<PopupRow>
									<PopupBoldLabel>Tipo de Nap:</PopupBoldLabel>
									<PopupValue>{popupInfo.type}</PopupValue>
								</PopupRow>
								<PopupRow>
									<PopupBoldLabel>Modelo de Nap:</PopupBoldLabel>
									<PopupValue>{popupInfo.model}</PopupValue>
								</PopupRow>
								<PopupRow>
									<PopupBoldLabel>Lugar:</PopupBoldLabel>
									<PopupValue>{popupInfo.lugar}</PopupValue>
								</PopupRow>
								<PopupRow>
									<PopupBoldLabel>Fecha:</PopupBoldLabel>
									<PopupValue>{popupInfo.date}</PopupValue>
								</PopupRow>
								<PopupRow>
									<PopupBoldLabel>Latitud:</PopupBoldLabel>
									<PopupValue>{popupInfo.latitude}</PopupValue>
								</PopupRow>
								<PopupRow>
									<PopupBoldLabel>Longitud:</PopupBoldLabel>
									<PopupValue>{popupInfo.longitude}</PopupValue>
								</PopupRow>
								<PopupImage src={popupInfo.coverImgUrl} alt='Foto' />
							</PopupContainer>
						</Popup>
					)}

					<Source id='myData' type='geojson' data={geojson}>
						<Layer
							{...heatmapLayer}
							layout={{ visibility: showHeatmap ? 'visible' : 'none' }}
						/>
					</Source>

					<Source id='lineSource' type='geojson' data={lineGeoJSON}>
						<Layer
							id='lineLayer'
							type='line'
							source='lineSource'
							layout={{ 'line-join': 'round', 'line-cap': 'round' }}
							paint={{ 'line-color': '#888', 'line-width': 2 }}
						/>
					</Source>
				</ReactMapGL>
			</MapContext.Provider>
		</>
	);
};

export default props => (
	<MapContext.Provider>
		<Map {...props} />
	</MapContext.Provider>
);
