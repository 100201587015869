// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mediumSupervisorIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='2'
			y='1'
			width='28'
			height='34'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.1334 2.33337C14.1334 1.75337 14.5067 1.33337 15.0667 1.33337H16.9334C17.5121 1.33337 17.8667 1.75337 17.8667 2.33337V8.00004H19.7334V3.33337C19.7334 3.33337 23.9334 5.05337 23.9334 10.8334C23.9334 10.8334 25.2401 11.1134 25.3334 13.3334H6.66675C6.66675 11.1134 8.06675 10.8334 8.06675 10.8334C8.06675 5.05337 12.2667 3.33337 12.2667 3.33337V8.00004H14.1334V2.33337ZM8.66675 14.6667C8.66675 19.085 11.95 22.6667 16.0001 22.6667C20.0502 22.6667 23.3334 19.085 23.3334 14.6667H8.66675ZM2.66675 32V34.6667H29.3334V32H2.66675ZM29.3078 30.6667C28.878 26.9451 23.0869 24 16.0001 24C8.9133 24 3.12215 26.9451 2.69234 30.6667H29.3078Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mediumSupervisorIcon)'>
			<rect width='32' height='32' fill={fill} fillOpacity={fillOpacity} />
		</g>
		<mask
			id='mediumSupervisorIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='1'
			y='2'
			width='30'
			height='26'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.0666 4.22218C13.0666 3.31996 13.6533 2.66663 14.5333 2.66663H17.4666C18.3759 2.66663 18.9333 3.31996 18.9333 4.22218V13.5555H21.8666V5.77774C21.8666 5.77774 28.4666 8.45329 28.4666 17.4444C28.4666 17.4444 30.5199 17.88 30.6666 21.3333H1.33325C1.33325 17.88 3.53325 17.4444 3.53325 17.4444C3.53325 8.45329 10.1333 5.77774 10.1333 5.77774V13.5555H13.0666V4.22218ZM30.6666 25.3333H1.33325V28H30.6666V25.3333Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mediumSupervisorIcon)'></g>
	</svg>
);
