import Immutable from 'immutable';
import { filterDataByFilterOptions } from '../';

function filterWarehouseList(warehouses, filters) {
	let filteredSeries = Immutable.List(warehouses).toJS();
	filteredSeries = filterDataByFilterOptions(filteredSeries, filters);
	return filteredSeries;
}

export { filterWarehouseList };
