// Libs
import { Radio } from 'antd';
import styled from 'styled-components';

const { Group, Button } = Radio;

const ItemStyled = styled(Radio)`
	display: ${props =>
		props.display ? props.display : props.theme.radio.item.display};
	margin: ${props =>
		props.margin ? props.margin : props.theme.radio.item.margin};
	color: ${props => (props.color ? props.color : props.theme.radio.item.color)};
	.ant-radio-disabled + span {
		color: ${props => props.theme.radio.item.disabled.color};
	}
`;
const GroupStyled = styled(Group)`
	padding: ${props =>
		props.padding ? props.padding : props.theme.radio.group.padding};
	background-color: ${props =>
		props.backgroundColor
			? props.backgroundColor
			: props.theme.radio.group.backgroundColor};
`;
const ButtonStyled = styled(Button)`
	display: ${props =>
		props.display ? props.display : props.theme.radio.button.display};
`;

export default {
	Item: ItemStyled,
	Group: GroupStyled,
	Button: ButtonStyled,
};
