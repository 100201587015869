// Libs
import React from 'react';
import { debounce, filterOption } from 'utils/libs';
// Utils
import GENERAL from 'utils/constants/general';
// Components
import {
	Wrapper,
	Span,
	Divider,
	Button,
	TextField,
	Switch,
	DatePicker,
	AddIcon,
	Spin,
	InputNumber,
	Collapse as _Collapse,
	LogoIcon,
} from 'components';
import Select from 'components/Select';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const { Input, TextArea } = TextField;
const { Collapse, Panel } = _Collapse;

const { ENV } = GENERAL;
const { Option } = Select;

const FORM_ELEMENTS = {
	ODT_ID: 'odtId',
	CONTRACT_ID: 'contractId',
	SERVICE_ID: 'serviceId',
	PROGRAM_AT: 'programAt',
	ASSIGNED_TECH_ID: 'assignedTechId',
	WARRANTY_EXTERNAL: 'warrantyExternal',
	NOTES: 'notes',
	PRIORITY: 'priority',
	RESERVED_FUEL: 'reservedFuel',
	DEVICES: 'devices',
};

const initialState = {
	searchClient: {
		value: undefined,
	},
	[FORM_ELEMENTS.ODT_ID]: {
		value: undefined,
		required: false,
	},
	[FORM_ELEMENTS.CONTRACT_ID]: {
		value: undefined,
		required: true,
	},
	[FORM_ELEMENTS.SERVICE_ID]: {
		value: undefined,
		required: true,
	},
	[FORM_ELEMENTS.PROGRAM_AT]: {
		value: undefined,
		required: false,
	},
	[FORM_ELEMENTS.WARRANTY_EXTERNAL]: {
		value: false,
		required: false,
	},
	[FORM_ELEMENTS.ASSIGNED_TECH_ID]: {
		value: undefined,
		required: true,
	},
	[FORM_ELEMENTS.NOTES]: {
		value: undefined,
		required: false,
	},
	[FORM_ELEMENTS.PRIORITY]: {
		value: undefined,
		required: false,
	},
	[FORM_ELEMENTS.RESERVED_FUEL]: {
		value: undefined,
		required: false,
	},
	[FORM_ELEMENTS.DEVICES]: {
		value: undefined,
		required: false,
	},
};

function CreateOrder({
	// Props
	selectedTemplateId,
	selectedClientId,
	departmentId,
	templates,
	clients,
	services,
	contracts,
	users,
	enterNewOrdersModal,
	// Actions
	myChangeState,
	getClientCategories,
	getContractsByClientId,
	onSearchClient,
	onGetTemplates,
	getClientsWithQueryVariant,
	onCreateOneOrder,
}) {
	const [orderInfo, setOrderInfo] = React.useState(initialState);

	const changeOrderInfo = newState =>
		setOrderInfo({ ...orderInfo, ...newState });

	const onChange = (key, value) =>
		changeOrderInfo({ [key]: { ...orderInfo[key], value } });

	const startSearchClient = React.useCallback(
		debounce(
			({ value, lastRowId }) => onSearchClient({ value, lastRowId }),
			700,
		),
		[],
	);

	// Is required data ready
	const isRequiredDataReady = React.useMemo(
		() =>
			!Object.keys(orderInfo).some(
				key =>
					orderInfo[key].required &&
					(orderInfo[key].value === undefined ||
						orderInfo[key].value === null ||
						orderInfo[key].value === ''),
			),
		[orderInfo],
	);

	// Filter department users
	const filteredUsers = React.useMemo(
		() =>
			users.filter(
				u => departmentId && u.projects?.indexOf(departmentId) !== -1,
			),
		[departmentId, users],
	);

	const buildFormData = () => {
		const form = {
			odtId: orderInfo[FORM_ELEMENTS.ODT_ID].value,
			programAt:
				orderInfo[FORM_ELEMENTS.PROGRAM_AT].value?.format('YYYY-MM-DD'),
			notes: orderInfo[FORM_ELEMENTS.NOTES].value,
			assignedTechId: orderInfo[FORM_ELEMENTS.ASSIGNED_TECH_ID].value,
			contractId: orderInfo[FORM_ELEMENTS.CONTRACT_ID].value,
			serviceId: orderInfo[FORM_ELEMENTS.SERVICE_ID].value,
			warrantyExternal: orderInfo[FORM_ELEMENTS.WARRANTY_EXTERNAL].value,
			priority: orderInfo[FORM_ELEMENTS.PRIORITY].value,
			reservedFuel: orderInfo[FORM_ELEMENTS.RESERVED_FUEL].value,
			devices: orderInfo[FORM_ELEMENTS.DEVICES].value,
			departmentId,
			templateId: selectedTemplateId,
			clientIdc: clients.data.find(c => c.id === selectedClientId)?.idc,
			entityId: users.find(
				u => u.id === orderInfo[FORM_ELEMENTS.ASSIGNED_TECH_ID].value,
			)?.entity_id,
		};

		return Object.keys(form).reduce((acc, key) => {
			if (form[key] !== undefined && form[key] !== null && form[key] !== '')
				acc[key] = form[key];
			return acc;
		}, {});
	};

	return (
		<Wrapper flexDirection='column' overflow='auto' alignItems='stretch'>
			{/* TEMPLATE */}
			<Span required>Plantilla de validación</Span>
			<Select
				margin='0 0 10px 0'
				showSearch
				filterOption={filterOption}
				value={selectedTemplateId}
				placeholder='Seleccione'
				onChange={value => myChangeState({ templateId: value })}
				onDropdownVisibleChange={isOpen =>
					isOpen &&
					onGetTemplates({
						...templates.request,
						prop: ENV.TEMPLATES.PROPS.VALIDATE_CREATE_ORDER,
					})
				}
				dropdownRender={menu => (
					<Spin
						size='small'
						spinning={templates.loading && templates.data.length === 0}
					>
						{menu}
					</Spin>
				)}
			>
				{templates.data.map(tmp => (
					<Option key={tmp.id} value={tmp.id}>
						{tmp.name}
					</Option>
				))}
			</Select>

			{/* CLIENTS */}
			<Span required>Cliente</Span>
			<Select
				margin='0 0 10px 0'
				value={selectedClientId}
				showSearch
				searchValue={orderInfo.searchClient.value}
				disabled={!selectedTemplateId}
				placeholder='Tarjeta de Identificación'
				filterOption={filterOption}
				onSearch={value => {
					onChange('searchClient', value);
					startSearchClient({ value });
				}}
				onChange={value => {
					myChangeState({ clientId: value });
					onChange(FORM_ELEMENTS.CONTRACT_ID, undefined);
					getContractsByClientId({ clientId: value, departmentId });
				}}
				onDropdownVisibleChange={isOpen =>
					isOpen &&
					getClientsWithQueryVariant({
						[ENV.CLIENTS.QUERY_VARIANTS.GET_LATEST_CLIENTS]: { limit: 10 },
					})
				}
				dropdownRender={menu => (
					<Spin
						size='small'
						spinning={
							clients.loading &&
							(clients.data.length === 0 || !!orderInfo.searchClient.value)
						}
					>
						{menu}
						<Divider style={{ margin: '4px 0' }} />
						<Wrapper
							padding='0'
							margin='4px 0 6px 8px'
							cursor='pointer'
							onMouseDown={e => e.preventDefault()}
							onClick={() => {
								getClientCategories({ departmentId });
								myChangeState({
									clientId: undefined,
									activeForm: ENV.ORDERS.CREATE.TABS.CREATE_CLIENT,
								});
							}}
						>
							<AddIcon />
							Crear cliente
						</Wrapper>
					</Spin>
				)}
			>
				{clients.data.map(client => (
					<Option key={client.id} value={client.id}>
						{`${client.idc} - ${client.name}`}
					</Option>
				))}
			</Select>

			{/* CONTRACTS */}
			<Span required>Número de Contrato</Span>
			<Select
				margin='0 0 10px 0'
				showSearch
				filterOption={filterOption}
				value={orderInfo[FORM_ELEMENTS.CONTRACT_ID].value}
				placeholder='Seleccione un Contrato'
				disabled={!selectedTemplateId || !selectedClientId}
				onChange={value => onChange(FORM_ELEMENTS.CONTRACT_ID, value)}
				dropdownRender={menu => (
					<Spin spinning={contracts.loading} size='small'>
						{menu}
						<Divider style={{ margin: '4px 0' }} />
						<Wrapper
							padding='0'
							margin='4px 0 6px 8px'
							cursor='pointer'
							onMouseDown={e => e.preventDefault()}
							onClick={() => {
								onChange(FORM_ELEMENTS.CONTRACT_ID, undefined);
								myChangeState({
									activeForm: ENV.ORDERS.CREATE.TABS.CREATE_CONTRACT,
								});
							}}
						>
							<AddIcon />
							Crear contrato
						</Wrapper>
					</Spin>
				)}
			>
				{contracts.data.map(
					({ id, contractNumber, cityName, townshipName, zoneName }) => (
						<Option key={id} value={id}>
							{contractNumber} - {cityName} - {townshipName} - {zoneName}
						</Option>
					),
				)}
			</Select>

			{/* SERVICES */}
			<Span required>Servicio</Span>
			<Select
				margin='0 0 10px 0'
				showSearch
				filterOption={filterOption}
				value={orderInfo[FORM_ELEMENTS.SERVICE_ID].value}
				disabled={!orderInfo[FORM_ELEMENTS.CONTRACT_ID].value}
				placeholder='Tipo de Servicio'
				onChange={value => onChange(FORM_ELEMENTS.SERVICE_ID, value)}
			>
				{services.data
					.filter(service => service.department_id === departmentId)
					.map(service => (
						<Option key={service.id} value={service.id}>
							{service.name}
						</Option>
					))}
			</Select>

			{/* USERS */}
			<Span required>Asignar a</Span>
			<Select
				margin='0 0 10px 0'
				showSearch
				filterOption={filterOption}
				value={orderInfo[FORM_ELEMENTS.ASSIGNED_TECH_ID].value}
				disabled={!orderInfo[FORM_ELEMENTS.CONTRACT_ID].value}
				placeholder='Asigna la orden a un usuario...'
				onChange={value => onChange(FORM_ELEMENTS.ASSIGNED_TECH_ID, value)}
			>
				{filteredUsers.map(user => (
					<Option key={user.id} value={user.id}>
						{user.name}
					</Option>
				))}
			</Select>

			{/* ODT ID */}
			<Span>Número de orden</Span>
			<Input
				maxLength={25}
				margin='0 0 10px 0'
				disabled={!orderInfo[FORM_ELEMENTS.CONTRACT_ID].value}
				value={orderInfo[FORM_ELEMENTS.ODT_ID].value}
				onChange={e => onChange(FORM_ELEMENTS.ODT_ID, e.target.value)}
				placeholder='Opcional'
			/>

			{/* PROGRAM DATE */}
			<Wrapper padding='0' margin='0 0 10px 0' justifyContent='space-between'>
				<Span>Fecha de Programación</Span>
				<DatePicker
					style={{ width: '50%' }}
					disabled={!orderInfo[FORM_ELEMENTS.CONTRACT_ID].value}
					placeholder='Seleccione una Fecha'
					value={orderInfo[FORM_ELEMENTS.PROGRAM_AT].value}
					onChange={value => onChange(FORM_ELEMENTS.PROGRAM_AT, value)}
				/>
			</Wrapper>

			{/* EXTERNAL WARRANTY */}
			<Wrapper margin='0 0 10px 0' padding='0' justifyContent='space-between'>
				<Span>Garantía Externa</Span>
				<Switch
					disabled={!orderInfo[FORM_ELEMENTS.CONTRACT_ID].value}
					checkedChildren={<CheckOutlined />}
					unCheckedChildren={<CloseOutlined />}
					checked={orderInfo[FORM_ELEMENTS.WARRANTY_EXTERNAL].value}
					onChange={value => onChange(FORM_ELEMENTS.WARRANTY_EXTERNAL, value)}
				/>
			</Wrapper>

			{/* NOTES */}
			<Span>Notas</Span>
			<TextArea
				maxLength={1000}
				rows={3}
				disabled={!orderInfo[FORM_ELEMENTS.CONTRACT_ID].value}
				value={orderInfo[FORM_ELEMENTS.NOTES].value}
				placeholder='Deja al colaborador instrucciones sobre la orden...'
				onChange={e => onChange(FORM_ELEMENTS.NOTES, e.target.value)}
			/>

			<Collapse timeout='auto' bordered={false} unmountOnExit>
				<Panel header={<Span>Ver más...</Span>}>
					<Wrapper padding='0' flexDirection='column' alignItems='stretch'>
						<Span>Equipos</Span>
						<InputNumber
							margin='0 0 10px 0'
							placeholder='¿Cantidad de equipos?'
							disabled={!orderInfo[FORM_ELEMENTS.CONTRACT_ID].value}
							value={orderInfo[FORM_ELEMENTS.DEVICES].value}
							min={1}
							onChange={value => onChange(FORM_ELEMENTS.DEVICES, value)}
						/>
						<Span>Prioridad</Span>
						<InputNumber
							margin='0 0 10px 0'
							placeholder='Establece una prioridad'
							disabled={!orderInfo[FORM_ELEMENTS.CONTRACT_ID].value}
							value={orderInfo[FORM_ELEMENTS.PRIORITY].value}
							min={1}
							onChange={value => onChange(FORM_ELEMENTS.PRIORITY, value)}
						/>
						<Span>Reserva de Combustible</Span>
						<InputNumber
							margin='0 0 10px 0'
							placeholder='Ingresa una cantidad'
							disabled={!orderInfo[FORM_ELEMENTS.CONTRACT_ID].value}
							value={orderInfo[FORM_ELEMENTS.RESERVED_FUEL].value}
							min={1}
							onChange={value => onChange(FORM_ELEMENTS.RESERVED_FUEL, value)}
						/>
					</Wrapper>
				</Panel>
			</Collapse>

			{/* CREATE ORDER */}
			{enterNewOrdersModal.request.status === 'sending' ? (
				<Wrapper padding='0' width='100%' justifyContent='center'>
					<LogoIcon spin />
				</Wrapper>
			) : (
				<Button
					margin='20px 0 0 0'
					mode='primary'
					disabled={!isRequiredDataReady}
					onClick={() => onCreateOneOrder(buildFormData())}
				>
					Generar Orden
				</Button>
			)}
		</Wrapper>
	);
}

export default CreateOrder;
