// Libs
import React from 'react';
import _ from 'underscore';
import { Breadcrumb } from 'antd';
import {
	filterOption,
	getSigners,
	getSignersWTransactionsAndMyAdmUsers,
} from 'utils/libs';
// Components
import {
	Wrapper,
	Modal,
	TextField,
	InputNumber,
	Empty,
	Button,
	Divider,
	Checkbox,
	CheckIcon,
	LogoIcon,
	CloseIcon,
	AddIcon,
	DatePicker,
} from 'components';
import Select from 'components/Select';
import _Tabs from 'components/Tabs';
import {
	CreateWarehouse,
	CreatePath,
	CreateArticle,
} from 'screens/Warehouses/subs';
import { PersonIcon, HomeIcon } from 'components/Icons';
import {
	GetOrdersModal,
	SendEventModal,
	CreateOrdersModal,
	OrdersReassignmentModal,
	// PAYMENTS
	CreatePaymentModal,
	CreateLineModal,
	SignPaycutModal,
	GetPaycutsModal,
	SignPaymentModal,
	ConfigViewSaleCost,
	// INVOICES
	CreateInvoicePaycutModal,
	CreateInvoicePaymentModal,
	EnterInvoiceOrdersModal,
	// WAREHOUSES
	SerieHistoryModal,
	ControlSeriesTransactionModal,
	CreateTransactionModal,
	ToolsOptionsManage,
	// REPORTS
	GenerateReportModal,
} from './subs';
// Styles
import './index.css';
// Libs
import VirtualizedList from '../Lists/VirtualizedList';

const { Option } = Select;
const { Input, TextArea } = TextField;
const { Wrap: Tabs, Pane: TabPane } = _Tabs;

const SetUserConfigurationModal = props => {
	const {
		roles,
		permissions,
		control,
		setPermissions,
		setUserConfigurationModal,
		mutate1ObjectInUsers,
	} = props;
	const uniqueRoles = getUniqueRoles(roles);
	const activities = getActivities(permissions);

	function getUniqueRoles(roles) {
		return roles.reduce((acc, item) => {
			if (_.findIndex(acc, accItem => accItem.id === item.id) === -1)
				acc.push({ id: item.id, name: item.name });
			return acc;
		}, []);
	}

	function getActivities(permissions) {
		return permissions.reduce((acc, item) => {
			if (
				_.findIndex(
					acc,
					accItem => accItem.activity_id === item.activity_id,
				) === -1
			)
				acc.push(item);
			return acc;
		}, []);
	}

	function onChangeSelectRole(value) {
		const rolePermissions = roles.reduce((acc, role) => {
			if (_.findIndex(value, roleId => role.id === roleId) !== -1)
				acc[role.transaction_type_id] = true;
			else acc[role.transaction_type_id] = undefined;
			return acc;
		}, {});

		mutate1ObjectInUsers('setUserConfigurationModal', {
			selectedRoles: value,
			userPermissions: {
				...setUserConfigurationModal.userPermissions,
				...rolePermissions,
			},
		});
	}

	return (
		<Tabs
			style={{ height: document.documentElement.clientHeight * 0.7 }}
			tabPosition='top'
		>
			<TabPane tab='Roles y Permisos' key='1'>
				<div className='tabs-container'>
					<div>
						<Divider style={{ color: 'gray' }} orientation='center'>
							Roles
						</Divider>
						<Select
							mode='multiple'
							style={{ width: '100%', minWidth: '150px' }}
							placeholder='Seleccione los roles'
							value={setUserConfigurationModal.selectedRoles}
							onChange={onChangeSelectRole}
						>
							{uniqueRoles.map((role, idx) => (
								<Option key={idx} value={role.id}>
									<em>{role.name}</em>
								</Option>
							))}
						</Select>
					</div>

					<div>
						<Divider style={{ color: 'gray' }} orientation='center'>
							Permisos
						</Divider>
						<div className='permissions-content overflow'>
							{activities.map((activity, idx) => {
								return (
									<div key={idx}>
										<Divider style={{ color: 'gray' }} orientation='left'>
											{activity.activity_name}
										</Divider>
										<div className='actions-container'>
											{permissions.map(
												(permission, idx) =>
													permission.activity_id === activity.activity_id && (
														<Checkbox
															key={idx}
															className='action-checkbox'
															checked={
																setUserConfigurationModal.userPermissions[
																	permission.id
																]
															}
															onChange={e =>
																mutate1ObjectInUsers(
																	'setUserConfigurationModal',
																	{
																		userPermissions: {
																			...setUserConfigurationModal.userPermissions,
																			[permission.id]: e.target.checked
																				? true
																				: undefined,
																		},
																	},
																)
															}
														>
															{permission.permission_name ||
																permission.action_name}
														</Checkbox>
													),
											)}
										</div>
									</div>
								);
							})}
						</div>
					</div>
					{setUserConfigurationModal.loading ? (
						<LogoIcon button spin />
					) : (
						<CheckIcon
							button
							id='save-permissions-button'
							onClick={() =>
								setPermissions(
									control.selectedUsers,
									setUserConfigurationModal.userPermissions,
								)
							}
						/>
					)}
				</div>
			</TabPane>
			{/* <TabPane tab="Plantillas" key="2">
        <div className="container-dark">

        </div>
      </TabPane> */}
		</Tabs>
	);
};

const GetInfoOrdersIncomeModal = props => {
	const { getInfoOrdersIncomeModal, mutate1Object, modeView } = props;
	const { fromDate, toDate, state, data } = getInfoOrdersIncomeModal;

	return (
		<div className='container-info-orders-income'>
			{state === 'loading' ? (
				<div style={{ minHeight: '80px' }} />
			) : state === 'start' ? (
				<div className='container-info-orders-income-dates'>
					{/* FROM DATE */}
					<DatePicker
						className='date-custom'
						placeholder='Desde*'
						value={fromDate}
						onChange={value =>
							mutate1Object('getInfoOrdersIncomeModal', { fromDate: value })
						}
					/>

					{/* TO DATE */}
					<DatePicker
						className='date-custom'
						placeholder='Hasta*'
						value={toDate}
						onChange={value =>
							mutate1Object('getInfoOrdersIncomeModal', { toDate: value })
						}
					/>
				</div>
			) : state === 'loaded' && data.length === 0 ? (
				<Empty />
			) : (
				state === 'loaded' &&
				data.length > 0 && (
					<VirtualizedList
						cardId='incomeOrderHistory'
						interpolatedRowStyle
						listStyle={{
							height: document.documentElement.clientHeight * 0.66,
							width:
								modeView === 'table'
									? 520
									: document.documentElement.clientWidth * 0.98,
						}}
						rowHeight={225}
						spinTitle='Consultando registros...'
						rows={data}
						isSpinning={state === 'loading'}
					/>
				)
			)}
		</div>
	);
};

const CreateWarehouseModal = props => {
	const {
		entities,
		createWarehouse,
		profile,
		mutate1Object,
		handleOnClickCreateWarehouse,
	} = props;

	return (
		<CreateWarehouse
			entities={entities}
			createWarehouse={createWarehouse}
			profile={profile}
			mutate1Object={mutate1Object}
			handleOnClickCreateWarehouse={handleOnClickCreateWarehouse}
		/>
	);
};

const CreateArticleModal = props => {
	const {
		createArticle,
		measuredUnits,
		coins,
		itemFamilies,
		mutate1Object,
		handleOnClickCreateArticle,
	} = props;

	return (
		<CreateArticle
			createArticle={createArticle}
			measuredUnits={measuredUnits}
			coins={coins}
			itemFamilies={itemFamilies}
			mutate1Object={mutate1Object}
			handleOnClickCreateArticle={handleOnClickCreateArticle}
		/>
	);
};

const CompleteAdjustModal = props => {
	const {
		users,
		stockArticlesManage,
		ownerId,
		warehouseId,
		completeAdjust,
		sendTransferTransaction,
		mutate1ObjectInWarehouses,
	} = props;

	const signers = getSigners(users, ownerId);

	return (
		<Wrapper justifyContent='center'>
			<Wrapper
				width='220px'
				padding='0'
				flexDirection='column'
				alignItems='stretch'
			>
				<Select
					placeholder='Autorizado Por*'
					value={completeAdjust.signer}
					onChange={value =>
						mutate1ObjectInWarehouses('completeAdjust', { signer: value })
					}
				>
					{signers.map(user => (
						<Option value={user.id}>
							<em>{user.name}</em>
						</Option>
					))}
				</Select>

				<TextArea
					margin='5px 0 10px 0'
					className='form-item-standard'
					allowClear
					placeholder='Comentario*'
					rows={3}
					value={completeAdjust.comment}
					onChange={e =>
						mutate1ObjectInWarehouses('completeAdjust', {
							comment: e.target.value,
						})
					}
				/>

				{/* CREATE BUTTON */}
				<Button
					className='button-style-1'
					onClick={() =>
						sendTransferTransaction(
							{
								adjustType: stockArticlesManage.adjustType,
								adjustArticles: stockArticlesManage.adjustArticles,
								warehouseId,
								signer: completeAdjust.signer,
								comment: completeAdjust.comment,
							},
							stockArticlesManage.mode,
						)
					}
				>
					Completar
				</Button>
			</Wrapper>
		</Wrapper>
	);
};

const GetTransactionsModal = props => {
	const {
		getTransactionsModal,
		getTransactionDocs,
		mutate1ObjectInWarehouses,
	} = props;

	return (
		<Wrapper width='100%' justifyContent='center'>
			<Wrapper
				padding='0'
				width='50%'
				flexDirection='column'
				alignItems='stretch'
			>
				<Select
					className='form-item-standard create-article-form-items-select'
					placeholder='Buscar*'
					value={getTransactionsModal.mode}
					onChange={value =>
						mutate1ObjectInWarehouses('getTransactionsModal', { mode: value })
					}
				>
					<Option value='document'>
						<em>Documento</em>
					</Option>
					<Option value='completed'>
						<em>Completadas</em>
					</Option>
				</Select>

				{getTransactionsModal.mode === 'document' && (
					<Input
						margin='20px 0 0 0'
						autoFocus
						className='form-item-standard'
						id='docNumber'
						placeholder='Documento*'
						maxLength={25}
						value={getTransactionsModal.docNumber}
						onChange={e =>
							mutate1ObjectInWarehouses('getTransactionsModal', {
								[e.target.id]: e.target.value,
							})
						}
					/>
				)}

				{getTransactionsModal.mode === 'completed' && (
					<DatePicker
						style={{ marginTop: '20px' }}
						placeholder='Desde*'
						value={getTransactionsModal.fromDate}
						onChange={value =>
							mutate1ObjectInWarehouses('getTransactionsModal', {
								fromDate: value,
							})
						}
					/>
				)}

				{getTransactionsModal.mode === 'completed' && (
					<DatePicker
						style={{ marginTop: '10px' }}
						placeholder='Hasta*'
						value={getTransactionsModal.toDate}
						onChange={value =>
							mutate1ObjectInWarehouses('getTransactionsModal', {
								toDate: value,
							})
						}
					/>
				)}

				<div className='form-action-button'>
					<Button
						className='button-style-1'
						onClick={() => getTransactionDocs(getTransactionsModal)}
					>
						Consultar
					</Button>
				</div>
			</Wrapper>
		</Wrapper>
	);
};

const CreatePackage = props => {
	const {
		articles,
		itemStates,
		warehouseAreas,
		warehouseZones,
		createPackage,
		mutate1ObjectInWarehouses,
		sendCreatePackage,
	} = props;
	const areas = warehouseAreas.data.filter(
		area => area.warehouse_zone_id === createPackage.warehouse_zone_id,
	);
	const arts =
		createPackage.operation === 'newEntry'
			? articles.filter(article => article.is_serialized)
			: [];

	return (
		<Wrapper justifyContent='center'>
			<Wrapper
				padding='0'
				width='220px'
				flexDirection='column'
				alignItems='stretch'
			>
				{/* ARTICLE */}
				{createPackage.operation === 'newEntry' && createPackage.serialized && (
					<Select
						autofocus
						margin='0 0 5px 0'
						showSearch
						placeholder='Artículo*'
						value={createPackage.item_id}
						filterOption={filterOption}
						onChange={value =>
							mutate1ObjectInWarehouses('createPackage', { item_id: value })
						}
					>
						{arts.map((art, idx) => (
							<Option key={idx} value={art.item_id}>
								<em>{`${art.item}-${art.name}`}</em>
							</Option>
						))}
					</Select>
				)}

				{/* STATES */}
				{createPackage.operation === 'newEntry' && createPackage.serialized && (
					<Select
						margin='0 0 5px 0'
						placeholder='Estado*'
						value={createPackage.state_id}
						onChange={value =>
							mutate1ObjectInWarehouses('createPackage', { state_id: value })
						}
					>
						{itemStates.map((state, idx) => (
							<Option key={idx} value={state.id}>
								<em>{state.name}</em>
							</Option>
						))}
					</Select>
				)}

				{/* SELECT ZONE */}
				{!createPackage.mustCreateZone && (
					<Select
						margin='0 0 5px 0'
						showSearch
						placeholder='Zona*'
						value={createPackage.warehouse_zone_id}
						filterOption={filterOption}
						onChange={value =>
							mutate1ObjectInWarehouses('createPackage', {
								warehouse_zone_id: value,
								warehouse_area_id: undefined,
							})
						}
						dropdownRender={menu => (
							<div>
								{menu}
								<Divider style={{ margin: '4px 0' }} />
								<div
									style={{ padding: '4px 8px', cursor: 'pointer' }}
									onMouseDown={e => e.preventDefault()}
									onClick={() =>
										mutate1ObjectInWarehouses('createPackage', {
											mustCreateZone: true,
											mustCreateArea: true,
											zone: '',
											area: '',
											warehouse_zone_id: undefined,
											warehouse_area_id: undefined,
										})
									}
								>
									<AddIcon /> Añadir zona
								</div>
							</div>
						)}
					>
						{warehouseZones.data.map((zone, idx) => (
							<Option key={idx} value={zone.id}>
								<em>{zone.name}</em>
							</Option>
						))}
					</Select>
				)}

				{/* ADD ZONE */}
				{createPackage.mustCreateZone && (
					<Input
						autoFocus
						margin='0 0 5px 0'
						id='zone'
						placeholder='Zona*'
						maxLength={15}
						value={createPackage.zone}
						onChange={e =>
							mutate1ObjectInWarehouses('createPackage', {
								[e.target.id]: e.target.value,
							})
						}
						suffix={
							<CloseIcon
								onClick={() =>
									mutate1ObjectInWarehouses('createPackage', {
										mustCreateZone: false,
										mustCreateArea: false,
										zone: undefined,
										area: undefined,
									})
								}
							/>
						}
					/>
				)}

				{/* SELECT AREA */}
				{!createPackage.mustCreateArea && (
					<Select
						margin='0 0 5px 0'
						showSearch
						placeholder='Area*'
						value={createPackage.warehouse_area_id}
						filterOption={filterOption}
						onChange={value =>
							mutate1ObjectInWarehouses('createPackage', {
								warehouse_area_id: value,
							})
						}
						dropdownRender={menu => (
							<div>
								{menu}
								<Divider style={{ margin: '4px 0' }} />

								{createPackage.warehouse_zone_id && (
									<div
										style={{ padding: '4px 8px', cursor: 'pointer' }}
										onMouseDown={e => e.preventDefault()}
										onClick={() => {
											const idx = _.findIndex(
												warehouseZones.data,
												zone => zone.id === createPackage.warehouse_zone_id,
											);
											mutate1ObjectInWarehouses('createPackage', {
												warehouse_area_id: undefined,
												mustCreateArea: true,
												area: '',
												zone: warehouseZones.data[idx].name,
											});
										}}
									>
										<AddIcon /> Añadir area
									</div>
								)}
							</div>
						)}
					>
						{areas.map((area, idx) => (
							<Option key={idx} value={area.id}>
								<em>{area.name}</em>
							</Option>
						))}
					</Select>
				)}

				{/* ADD AREA */}
				{createPackage.mustCreateArea && (
					<Input
						margin='0 0 10px 0'
						id='area'
						placeholder='Area*'
						maxLength={15}
						value={createPackage.area}
						onChange={e =>
							mutate1ObjectInWarehouses('createPackage', {
								[e.target.id]: e.target.value,
							})
						}
						suffix={
							!createPackage.mustCreateZone && (
								<CloseIcon
									onClick={() =>
										mutate1ObjectInWarehouses('createPackage', {
											mustCreateArea: false,
											area: undefined,
											zone: undefined,
										})
									}
								/>
							)
						}
					/>
				)}

				{/* CREATE */}
				<Button mode='primary' onClick={() => sendCreatePackage(createPackage)}>
					Crear
				</Button>
			</Wrapper>
		</Wrapper>
	);
};

const NewEntryArticleModal = props => {
	const {
		articles,
		createNewEntryItem, // newArticleEntry
		mutate1ObjectInWarehouses,
		addedArticles,
		dwarehouseName,
		sendToast,
		modeView,
	} = props;
	const arts = articles.filter(article => !article.is_serialized);

	function onCreateNewArticleEntry() {
		// Required Field completed?
		if (!createNewEntryItem.item_id || !createNewEntryItem.amount) {
			sendToast({
				message: 'Debe completar los campos requeridos [*]',
				type: 'warn',
				duration: 2,
			});
			return;
		}

		if (
			_.findIndex(addedArticles, article =>
				createNewEntryItem.serie
					? article.serie === createNewEntryItem.serie
					: article.item_id === createNewEntryItem.item_id,
			) !== -1
		) {
			sendToast({
				message: 'El artículo ya fue añadido',
				type: 'warn',
				duration: 2,
			});
		} else {
			const art = articles.find(
				article => article.item_id === createNewEntryItem.item_id,
			);

			addedArticles.push({
				item_id: art.item_id,
				item_code: art.item,
				item_name: art.name,
				is_serialized: art.is_serialized,
				serialized: art.serialized,
				amount: createNewEntryItem.amount,
				serie: createNewEntryItem.serie,
				warehouse_name: dwarehouseName,
			});
			mutate1ObjectInWarehouses('createTransaction', {
				articles: addedArticles,
			});
			mutate1ObjectInWarehouses('createNewEntryItem', {
				item_id: undefined,
				amount: undefined,
				serie: undefined,
			});
			sendToast({ message: 'Artículo añadido!', type: 'success', duration: 2 });
		}
	}

	const width = modeView === 'cards' ? '220px' : '370px';

	return (
		<Wrapper justifyContent='center'>
			<Wrapper
				padding='0'
				width={width}
				flexDirection='column'
				alignItems='stretch'
			>
				{/* ARTICLE */}
				<Select
					margin='0 0 5px 0'
					autofocus
					showSearch
					placeholder='Artículo*'
					value={createNewEntryItem.item_id}
					filterOption={filterOption}
					onChange={value =>
						mutate1ObjectInWarehouses('createNewEntryItem', { item_id: value })
					}
				>
					{arts.map((art, idx) => (
						<Option key={idx} value={art.item_id}>
							<em>{`${art.item}-${art.name}`}</em>
						</Option>
					))}
				</Select>

				{/* AMOUNT */}
				<InputNumber
					margin='0 0 10px 0'
					placeholder='Cantidad*'
					min={0.01}
					value={createNewEntryItem.amount}
					onChange={value =>
						mutate1ObjectInWarehouses('createNewEntryItem', { amount: value })
					}
				/>

				{/* ACCOUNTED SERIE */}
				<Input
					maxLength={45}
					margin='0 0 10px 0'
					value={createNewEntryItem.serie}
					placeholder='Serie'
					onChange={e =>
						mutate1ObjectInWarehouses('createNewEntryItem', {
							serie: e.target.value,
						})
					}
				/>

				{/* ADD BUTTON */}
				<Button mode='primary' onClick={onCreateNewArticleEntry}>
					Agregar
				</Button>
			</Wrapper>
		</Wrapper>
	);
};

const SignWTDModal = props => {
	const {
		profile,
		totalUsers,
		signWTDModal,
		transactions,
		mutate1ObjectInWarehouses,
		transactionsManage,
		signWTD,
		updateWTDSigner,
	} = props;
	const transaction = transactions.data.filter(
		transaction => transaction.id === transactionsManage.docId,
	)[0];
	const signers = getSignersWTransactionsAndMyAdmUsers(
		totalUsers,
		transaction,
		profile,
	);

	return (
		<Wrapper flexDirection='column' alignItems='stretch'>
			<Divider style={{ color: 'gray', marginBottom: 0 }} orientation='left'>
				Firmas
			</Divider>
			<div className='sign_wtd-container-signs'>
				<div className='form-signs'>
					<Breadcrumb separator='>'>
						<Breadcrumb.Item>
							{/* <span>Firmado por</span> */}
							<HomeIcon />
						</Breadcrumb.Item>
						{transaction &&
							transaction.signed &&
							transaction.signed.map((sign, i) => {
								const idx = _.findIndex(
									totalUsers,
									user => user.id === sign.user_id,
								);
								let style = { color: 'rgba(255, 255, 255, 0.6)' };

								if (sign.released) style = { color: 'yellow' };
								else if (sign.agreed) style = { color: 'deepskyblue' };

								return (
									idx !== -1 && (
										<Breadcrumb.Item key={i}>
											<PersonIcon style={style} />
											<span style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
												{totalUsers[idx].name}
											</span>
										</Breadcrumb.Item>
									)
								);
							})}
					</Breadcrumb>
				</div>
			</div>

			<Wrapper
				alignSelf='center'
				padding='0'
				width='220px'
				flexDirection='column'
				alignItems='stretch'
			>
				{transaction && !transaction.completed && (
					<Select
						showSearch
						placeholder='Enviar a*'
						value={signWTDModal.signer}
						filterOption={filterOption}
						onChange={value =>
							mutate1ObjectInWarehouses('signWTDModal', { signer: value })
						}
					>
						{Array.isArray(signers) &&
							signers.map((signer, idx) => (
								<Option key={idx} value={signer.id}>
									<em>{signer.name}</em>
								</Option>
							))}
					</Select>
				)}

				{transaction && !transaction.completed && (
					<Wrapper justifyContent='space-around'>
						<Button
							onClick={() =>
								updateWTDSigner(transaction.id, signWTDModal.signer)
							}
						>
							Reasignar
						</Button>

						<Button
							className='button-style-1'
							onClick={() =>
								signWTD(
									transaction.id,
									signWTDModal.signer,
									false,
									'transaction',
								)
							}
						>
							Firmar
						</Button>
					</Wrapper>
				)}
			</Wrapper>
		</Wrapper>
	);
};

export default function RenderModal(props) {
	const {
		modalId,
		title,
		visible,
		onOk,
		closable = true,
		centered = false,
		width = 520,
		onCancel,
		footer,
		isSpinning,
		sizeSpinning,
		delaySpinning,
		tipSpinning,
		customProps,
	} = props;
	return (
		<Modal
			closable={closable}
			centered={centered}
			width={width}
			title={title}
			visible={visible}
			onOk={onOk}
			onCancel={onCancel}
			footer={footer}
		>
			{/* <Spin
        spinning={isSpinning || false}
        size={sizeSpinning || "large"}
        delay={delaySpinning || 50}
        tip={tipSpinning || "Cargando..."}
      > */}
			{modalId === 'SetUserConfigurationModal' && (
				<SetUserConfigurationModal {...customProps} />
			)}
			{modalId === 'OrdersReassignmentModal' && (
				<OrdersReassignmentModal {...customProps} />
			)}
			{modalId === 'GetOrdersModal' && <GetOrdersModal {...customProps} />}
			{modalId === 'SendEventModal' && <SendEventModal {...customProps} />}
			{modalId === 'GetInfoOrdersIncomeModal' && (
				<GetInfoOrdersIncomeModal {...customProps} />
			)}
			{modalId === 'CreateOrdersModal' && (
				<CreateOrdersModal {...customProps} />
			)}
			{modalId === 'CreateWarehouseModal' && (
				<CreateWarehouseModal {...customProps} />
			)}
			{modalId === 'CreatePath' && <CreatePath {...customProps} />}
			{modalId === 'CreateArticleModal' && (
				<CreateArticleModal {...customProps} />
			)}
			{modalId === 'CompleteAdjustModal' && (
				<CompleteAdjustModal {...customProps} />
			)}
			{modalId === 'GetTransactionsModal' && (
				<GetTransactionsModal {...customProps} />
			)}
			{modalId === 'CreateTransactionModal' && (
				<CreateTransactionModal {...customProps} />
			)}
			{modalId === 'CreatePackage' && <CreatePackage {...customProps} />}
			{modalId === 'NewEntryArticleModal' && (
				<NewEntryArticleModal {...customProps} />
			)}
			{modalId === 'SignWTDModal' && <SignWTDModal {...customProps} />}
			{modalId === 'CreateInvoicePaycutModal' && (
				<CreateInvoicePaycutModal {...customProps} />
			)}
			{modalId === 'CreateInvoicePaymentModal' && (
				<CreateInvoicePaymentModal {...customProps} />
			)}
			{modalId === 'EnterInvoiceOrdersModal' && (
				<EnterInvoiceOrdersModal {...customProps} />
			)}
			{modalId === 'CreatePaymentModal' && (
				<CreatePaymentModal {...customProps} />
			)}
			{modalId === 'CreateLineModal' && <CreateLineModal {...customProps} />}
			{modalId === 'SignPaycutModal' && <SignPaycutModal {...customProps} />}
			{modalId === 'GetPaycutsModal' && <GetPaycutsModal {...customProps} />}
			{modalId === 'SignPaymentModal' && <SignPaymentModal {...customProps} />}
			{modalId === 'ConfigViewSaleCost' && (
				<ConfigViewSaleCost {...customProps} />
			)}
			{modalId === 'SerieHistoryModal' && (
				<SerieHistoryModal {...customProps} />
			)}
			{modalId === 'ControlSeriesTransactionModal' && (
				<ControlSeriesTransactionModal {...customProps} />
			)}
			{modalId === 'ToolsOptionsManage' && (
				<ToolsOptionsManage {...customProps} />
			)}
			{modalId === 'GenerateReportModal' && (
				<GenerateReportModal {...customProps} />
			)}
			{modalId === 'FiltersQueryModal' && (
				<FiltersQueryModal {...customProps} />
			)}
		</Modal>
	);
}
