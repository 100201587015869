// Libs
import React from 'react';

const Edit = ({ fill, fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='editIconMask'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='3'
			y='3'
			width='19'
			height='19'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M20.71 5.6325C21.1 6.0225 21.1 6.6525 20.71 7.0425L18.88 8.8725L15.13 5.1225L16.96 3.2925C17.35 2.9025 17.98 2.9025 18.37 3.2925L20.71 5.6325ZM3 21.0025V17.2525L14.06 6.1925L17.81 9.9425L6.75 21.0025H3Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#editIconMask)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);

export default Edit;
