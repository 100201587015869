// Utils
import GENERAL from 'utils/constants/general';
const { CLIENTS, ENV } = GENERAL;

const initialState = {
	createClient: {
		status: ENV.STATUS.INITIAL,
	},
};

export default function clientReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CLIENTS.MUTATE_DIRECT_PROPS: {
			const keyValuePairs = payload;
			return {
				...state,
				...keyValuePairs,
			};
		}
		case CLIENTS.MUTATE_1OBJECT: {
			const { obj1Name, keyValuePairs } = payload;
			const newObj = { ...state[obj1Name], ...keyValuePairs };
			return {
				...state,
				[obj1Name]: newObj,
			};
		}
		case CLIENTS.RESET: {
			return initialState;
		}
		default:
			return state;
	}
}
