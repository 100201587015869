import { useEffect } from 'react';

// Private Methods
function setDefaultSelectedFromData(data, { key, value }, mutate1Object) {
	if (data.length > 0 && !value) {
		mutate1Object('inventoryReception', {
			[key]: data[0].id,
		});
	}
}

export function useSetDefaultsData(
	warehouseZones,
	filteredAreas,
	inventoryReception,
	mutate1Object,
) {
	useEffect(() => {
		setDefaultSelectedFromData(
			warehouseZones.data,
			{ key: 'zoneId', value: inventoryReception.zoneId },
			mutate1Object,
		);
		setDefaultSelectedFromData(
			filteredAreas,
			{ key: 'areaId', value: inventoryReception.areaId },
			mutate1Object,
		);
	}, [warehouseZones.data, filteredAreas]);
}
