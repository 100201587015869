import Immutable from 'immutable';
import { filterDataByFilterOptions } from '../';

function filterArticleStock(articles, filters) {
	let filteredArticles = Immutable.List(articles).toJS();
	filteredArticles = filterDataByFilterOptions(filteredArticles, filters);
	return filteredArticles;
}

export { filterArticleStock };
