// Libs
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
// Actions
import * as reportActions from 'screens/Reports/reports.actions';
// Selectors
import { selectReportControl } from 'screens/Reports/reports.selectors';
import DashboardAuthorization from 'screens/Dashboard/Authorization';
// Utils
import GENERAL from 'utils/constants/general';
import { onKeyPressedSearchStockSeries } from 'screens/Warehouses/utils';
import WAREHOUSE_KEYWORDS from 'screens/Warehouse/keywords';
// Components
import Input from '@mui/material/Input';
import {
	SearchIcon,
	Popover,
	HamburguerIcon,
	AddIcon,
	CloseIcon,
} from 'components';
const Select = React.lazy(() => import('components/Select'));
import ToolbarBackButton from './ToolbarBackButton';
import { OrdersManage, SearchOrdersManage } from './Orders';
import {
	// PAYMENTS
	PaymentPaycuts,
	PaymentPays,
	PaymentOrders,
	PaymentLines,
	// INVOICES
	InvoicePays,
} from './Billings';
import {
	ControlSeriesToolbar,
	SearchControlSeriesToolbar,
	Reception,
	TransactionsInventoryToTransfer,
	SearchTransactionsInventoryToTransfer,
} from './Warehouses';
import { UsersManage, SearchUsersManage } from './Users';
import { SearchReportsToolbar, ReportsToolbar } from './Reports';
// Styles
import './index.css';
import { CustomSearchToolbar, CustomToolbar } from './sharedFunctions';

const { ENV } = GENERAL;
const { ROUTES: WAREHOUSE_ROUTES } = WAREHOUSE_KEYWORDS;

// ----------------------------------------------- JSX RENDER COMPONENTS ------------------------------------------------
const WarehousesListToolbar = props => {
	const {
		showNavBar,
		title,
		selectNotification,
		mutate1ObjectInWarehouses,
		warehouses,
	} = props;

	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={title}
			left={<HamburguerIcon button onClick={showNavBar} />}
			right={
				<>
					{/* SEARCH */}
					<SearchIcon
						onClick={() =>
							mutate1ObjectInWarehouses('control', {
								searchInData: {
									...warehouses.control.searchInData,
									renderToolbar: true,
								},
							})
						}
					/>

					{/* CREATE */}
					<AddIcon
						button
						onClick={() =>
							mutate1ObjectInWarehouses('createWarehouse', { isOpen: true })
						}
					/>
				</>
			}
		/>
	);
};

const SearchWarehousesToolbar = props => {
	const { Option } = Select;
	const { mutate1ObjectInWarehouses, searchInData } = props;
	const SearchBy = (
		<Select
			width='100%'
			className='select-searchBy'
			defaultValue='all'
			value={searchInData.searchBy}
			onChange={value =>
				mutate1ObjectInWarehouses('control', {
					searchInData: {
						...searchInData,
						searchBy: value,
						showPopover: false,
					},
				})
			}
		>
			<Option value='all'>Todos</Option>
			<Option value='name'>Almacén</Option>
			<Option value='owner'>Propietario</Option>
			<Option value='entity'>Entidad</Option>
			<Option value='creator'>Creador</Option>
		</Select>
	);

	function setPlaceholderSearchBy(searchBy) {
		if (searchBy === 'name') return 'Almacén';
		else if (searchBy === 'owner') return 'Propietario';
		else if (searchBy === 'entity') return 'Entidad';
		else if (searchBy === 'creator') return 'Creador';
		else return 'Todos';
	}

	return (
		<CustomSearchToolbar
			left={
				<Input
					id='toolbar-search-order-input'
					style={{ height: '100%', width: '100%' }}
					autoFocus
					name='data'
					placeholder={setPlaceholderSearchBy(searchInData.searchBy)}
					value={searchInData.data}
					onChange={e =>
						mutate1ObjectInWarehouses('control', {
							searchInData: {
								...searchInData,
								[e.target.name]: e.target.value,
							},
						})
					}
					startAdornment={
						<Popover
							content={SearchBy}
							placement='rightBottom'
							title='Buscar por'
							trigger='click'
							visible={searchInData.showPopover}
							onVisibleChange={() =>
								mutate1ObjectInWarehouses('control', {
									searchInData: {
										...searchInData,
										showPopover: !searchInData.showPopover,
									},
								})
							}
						>
							<div>
								<SearchIcon />
							</div>
						</Popover>
					}
					endAdornment={
						<CloseIcon
							button
							onClick={() =>
								mutate1ObjectInWarehouses('control', {
									searchInData: {
										...searchInData,
										renderToolbar: false,
										data: '',
										searchBy: 'all',
									},
								})
							}
						/>
					}
				/>
			}
		/>
	);
};

const StockArticlesToolbar = props => {
	const {
		title,
		handleOnClick,
		selectNotification,
		mutate1ObjectInWarehouses,
		warehouses,
	} = props;

	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={title}
			left={<ToolbarBackButton handleOnClick={handleOnClick} />}
			right={
				<>
					<SearchIcon
						onClick={() =>
							mutate1ObjectInWarehouses('stockArticlesManage', {
								searchInData: {
									...warehouses.stockArticlesManage.searchInData,
									renderToolbar: true,
								},
							})
						}
					/>
				</>
			}
		/>
	);
};

const SearchStockArticleToolbar = props => {
	const { Option } = Select;
	const { mutate1ObjectInWarehouses, searchInData } = props;
	const SearchBy = (
		<Select
			width='100%'
			className='select-searchBy'
			defaultValue='all'
			value={searchInData.searchBy}
			onChange={value =>
				mutate1ObjectInWarehouses('stockArticlesManage', {
					searchInData: {
						...searchInData,
						searchBy: value,
						showPopover: false,
					},
				})
			}
		>
			<Option value='all'>Todos</Option>
			<Option value='code'>Código</Option>
			<Option value='name'>Nombre</Option>
		</Select>
	);

	function setPlaceholderSearchBy(searchBy) {
		if (searchBy === 'code') return 'Código';
		else if (searchBy === 'name') return 'Nombre';
		else return 'Todos';
	}

	return (
		<CustomSearchToolbar
			left={
				<Input
					id='toolbar-search-order-input'
					style={{ height: '100%', width: '100%' }}
					autoFocus
					name='data'
					placeholder={setPlaceholderSearchBy(searchInData.searchBy)}
					value={searchInData.data}
					onChange={e =>
						mutate1ObjectInWarehouses('stockArticlesManage', {
							searchInData: {
								...searchInData,
								[e.target.name]: e.target.value,
							},
						})
					}
					startAdornment={
						<Popover
							content={SearchBy}
							placement='rightBottom'
							title='Buscar por'
							trigger='click'
							visible={searchInData.showPopover}
							onVisibleChange={() =>
								mutate1ObjectInWarehouses('stockArticlesManage', {
									searchInData: {
										...searchInData,
										showPopover: !searchInData.showPopover,
									},
								})
							}
						>
							<div>
								<SearchIcon />
							</div>
						</Popover>
					}
					endAdornment={
						<CloseIcon
							button
							onClick={() =>
								mutate1ObjectInWarehouses('stockArticlesManage', {
									searchInData: {
										...searchInData,
										renderToolbar: false,
										data: '',
										searchBy: 'all',
									},
								})
							}
						/>
					}
				/>
			}
		/>
	);
};

const StockSeriesToolbar = props => {
	const {
		title,
		handleOnClick,
		selectNotification,
		mutate1ObjectInWarehouses,
		warehouses,
	} = props;

	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={title}
			left={<ToolbarBackButton handleOnClick={handleOnClick} />}
			right={
				<>
					<SearchIcon
						onClick={() =>
							mutate1ObjectInWarehouses('stockSeriesManage', {
								searchInData: {
									...warehouses.stockSeriesManage.searchInData,
									renderToolbar: true,
								},
							})
						}
					/>
				</>
			}
		/>
	);
};

const SearchStockSerieToolbar = props => {
	const {
		mutate1ObjectInWarehouses,
		searchInData,
		history,
		modeView,
		mutateDirectProps,
	} = props;
	const { Option } = Select;
	const SearchBy = (
		<Select
			width='100%'
			className='select-searchBy'
			defaultValue='all'
			value={searchInData.searchBy}
			onChange={value =>
				mutate1ObjectInWarehouses('stockSeriesManage', {
					searchInData: {
						...searchInData,
						searchBy: value,
						showPopover: false,
					},
				})
			}
		>
			<Option value='scan'>Escáner</Option>
			<Option value='serie'>Serie</Option>
			<Option value='code'>Código</Option>
			<Option value='name'>Nombre</Option>
			<Option value='warehouse_name'>Almacén</Option>
			<Option value='all'>Todos</Option>
		</Select>
	);

	function setPlaceholderSearchBy(searchBy) {
		if (searchBy === 'scan') return 'Escáner';
		else if (searchBy === 'serie') return 'Serie';
		else if (searchBy === 'code') return 'Código';
		else if (searchBy === 'name') return 'Nombre';
		else if (searchBy === 'warehouse_name') return 'Almacén';
		else return 'Todos';
	}

	return (
		<CustomSearchToolbar
			left={
				<Input
					id='toolbar-search-order-input'
					style={{ height: '100%', width: '100%' }}
					autoFocus
					name='data'
					placeholder={setPlaceholderSearchBy(searchInData.searchBy)}
					value={searchInData.data}
					onKeyPress={e =>
						onKeyPressedSearchStockSeries(
							e,
							searchInData,
							history,
							modeView,
							mutate1ObjectInWarehouses,
							mutateDirectProps,
						)
					}
					onChange={e =>
						mutate1ObjectInWarehouses('stockSeriesManage', {
							searchInData: {
								...searchInData,
								[e.target.name]: e.target.value,
							},
						})
					}
					startAdornment={
						<Popover
							content={SearchBy}
							placement='rightBottom'
							title='Buscar por'
							trigger='click'
							visible={searchInData.showPopover}
							onVisibleChange={() =>
								mutate1ObjectInWarehouses('stockSeriesManage', {
									searchInData: {
										...searchInData,
										showPopover: !searchInData.showPopover,
									},
								})
							}
						>
							<div>
								<SearchIcon />
							</div>
						</Popover>
					}
					endAdornment={
						<CloseIcon
							button
							onClick={() =>
								mutate1ObjectInWarehouses('stockSeriesManage', {
									searchInData: {
										...searchInData,
										renderToolbar: false,
										data: '',
										searchBy: 'all',
									},
								})
							}
						/>
					}
				/>
			}
		/>
	);
};

const ArticlesToolbar = props => {
	const {
		showNavBar,
		title,
		profile,
		selectNotification,
		mutate1ObjectInWarehouses,
		articlesManage,
	} = props;
	const CreateContent = (
		<ul className='warehouses-create-items-popover-list'>
			<li
				className='warehouses-create-items-popover-list-item'
				onClick={() => {
					mutate1ObjectInWarehouses('createArticle', {
						isOpen: true,
						showPopoverCreate: false,
					});
					mutate1ObjectInWarehouses('articlesManage', {
						showPopoverCreate: false,
					});
				}}
			>
				Artículo
			</li>
			<li
				className='warehouses-create-items-popover-list-item'
				onClick={() => {
					mutate1ObjectInWarehouses('createFamily', {
						isOpen: true,
						showPopoverCreate: false,
					});
					mutate1ObjectInWarehouses('articlesManage', {
						showPopoverCreate: false,
					});
				}}
			>
				Familia
			</li>
		</ul>
	);
	const isAuthorized = new DashboardAuthorization(profile).checkEditCatalogue();
	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={title}
			left={<HamburguerIcon button onClick={showNavBar} />}
			right={
				<>
					{/* SEARCH WAREHOUSE */}
					<SearchIcon
						onClick={() =>
							mutate1ObjectInWarehouses('articlesManage', {
								searchInData: {
									...articlesManage.searchInData,
									renderToolbar: true,
								},
							})
						}
					/>

					{/* CREATE */}
					{!!isAuthorized && (
						<Popover
							content={CreateContent}
							placement='bottom'
							title={null}
							trigger='click'
							visible={articlesManage.showPopoverCreate}
							onVisibleChange={() =>
								mutate1ObjectInWarehouses('articlesManage', {
									showPopoverCreate: !articlesManage.showPopoverCreate,
								})
							}
						>
							<div>
								<AddIcon button />
							</div>
						</Popover>
					)}
				</>
			}
		/>
	);
};

const SearchArticlesToolbar = props => {
	const { Option } = Select;
	const { mutate1ObjectInWarehouses, searchInData } = props;
	const SearchBy = (
		<Select
			width='100%'
			className='select-searchBy'
			defaultValue='all'
			value={searchInData.searchBy}
			onChange={value =>
				mutate1ObjectInWarehouses('articlesManage', {
					searchInData: {
						...searchInData,
						searchBy: value,
						showPopover: false,
					},
				})
			}
		>
			<Option value='all'>Todos</Option>
			<Option value='name'>Nombre</Option>
			<Option value='code'>Código</Option>
			<Option value='creator'>Creador</Option>
			<Option value='entity'>Entidad</Option>
		</Select>
	);

	function setPlaceholderSearchBy(searchBy) {
		if (searchBy === 'name') return 'Almacén';
		else if (searchBy === 'code') return 'Código';
		else if (searchBy === 'creator') return 'Creador';
		else if (searchBy === 'entity') return 'Entidad';
		else return 'Todos';
	}

	return (
		<CustomSearchToolbar
			left={
				<Input
					id='toolbar-search-order-input'
					style={{ height: '100%', width: '100%' }}
					autoFocus
					name='data'
					placeholder={setPlaceholderSearchBy(searchInData.searchBy)}
					value={searchInData.data}
					onChange={e =>
						mutate1ObjectInWarehouses('articlesManage', {
							searchInData: {
								...searchInData,
								[e.target.name]: e.target.value,
							},
						})
					}
					startAdornment={
						<Popover
							content={SearchBy}
							placement='rightBottom'
							title='Buscar por'
							trigger='click'
							visible={searchInData.showPopover}
							onVisibleChange={() =>
								mutate1ObjectInWarehouses('articlesManage', {
									searchInData: {
										...searchInData,
										showPopover: !searchInData.showPopover,
									},
								})
							}
						>
							<div>
								<SearchIcon />
							</div>
						</Popover>
					}
					endAdornment={
						<CloseIcon
							button
							onClick={() =>
								mutate1ObjectInWarehouses('articlesManage', {
									searchInData: {
										...searchInData,
										renderToolbar: false,
										data: '',
										searchBy: 'all',
									},
								})
							}
						/>
					}
				/>
			}
		/>
	);
};

const TransactionsToolbar = props => {
	const {
		showNavBar,
		title,
		selectNotification,
		mutate1ObjectInWarehouses,
		transactionsManage,
		profile,
		modeView,
	} = props;

	const isAuthorized = new DashboardAuthorization(profile).checkEditInventory();
	const CreateContent = (
		<ul className='warehouses-create-items-popover-list'>
			<li disabled={true} className='disabled-item'>
				¿Qué acción desea realizar?
			</li>
			{/* New Income */}
			{!!isAuthorized && (
				<li
					className='warehouses-create-items-popover-list-item'
					onClick={() => {
						mutate1ObjectInWarehouses('transactionsManage', {
							showPopoverCreate: false,
							titleToolbar: 'Ingresando',
						});
						mutate1ObjectInWarehouses('createTransaction', {
							isOpen: true,
							mode: 'newEntry',
						});
					}}
				>
					Ingresar inventario nuevo
				</li>
			)}
			{/* Transfer */}
			<li
				className='warehouses-create-items-popover-list-item'
				onClick={() => {
					mutate1ObjectInWarehouses('transactionsManage', {
						showPopoverCreate: false,
						titleToolbar: 'Enviando',
					});
					mutate1ObjectInWarehouses('createTransaction', {
						isOpen: true,
						mode: 'trsSend',
					});
				}}
			>
				Transferir inventario
			</li>
			{/* Receive */}
			{/* <li
          className="warehouses-create-items-popover-list-item"
          onClick={() => {
            mutate1ObjectInWarehouses("transactionsManage", {
              showPopoverCreate: false,
              titleToolbar: "Recibiendo",
            });
            mutate1ObjectInWarehouses("createTransaction", {
              isOpen: true,
              mode: "trsReceive",
            });
          }}
        >
          Recibir
        </li> */}
		</ul>
	);

	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={title}
			left={<HamburguerIcon button onClick={showNavBar} />}
			right={
				<>
					{/* SEARCH WAREHOUSE */}
					{modeView === 'cards' && (
						<SearchIcon
							onClick={() =>
								mutate1ObjectInWarehouses('transactionsManage', {
									searchInData: {
										...transactionsManage.searchInData,
										renderToolbar: true,
									},
								})
							}
						/>
					)}

					{/* CREATE */}
					<Popover
						content={CreateContent}
						placement='bottomRight'
						title={null}
						trigger='click'
						visible={transactionsManage.showPopoverCreate}
						onVisibleChange={() =>
							mutate1ObjectInWarehouses('transactionsManage', {
								showPopoverCreate: !transactionsManage.showPopoverCreate,
							})
						}
					>
						<div>
							<AddIcon button />
						</div>
					</Popover>
				</>
			}
		/>
	);
};

const SearchTransactionsToolbar = props => {
	const { Option } = Select;
	const { mutate1ObjectInWarehouses, searchInData } = props;
	const SearchBy = (
		<Select
			width='100%'
			className='select-searchBy'
			defaultValue='all'
			value={searchInData.searchBy}
			onChange={value =>
				mutate1ObjectInWarehouses('transactionsManage', {
					searchInData: {
						...searchInData,
						searchBy: value,
						showPopover: false,
					},
				})
			}
		>
			<Option value='all'>Todos</Option>
			<Option value='doc_number'>Documento</Option>
			<Option value='transaction_type'>Tipo</Option>
			<Option value='method'>Método</Option>
			<Option value='owarehouse'>Origen</Option>
			<Option value='dwarehouse'>Destino</Option>
			<Option value='signer'>Firmante</Option>
			<Option value='creator'>Creador</Option>
			<Option value='entity'>Entidad</Option>
			<Option value='completed'>Completado</Option>
			<Option value='edit'>Editable</Option>
		</Select>
	);

	function setPlaceholderSearchBy(searchBy) {
		if (searchBy === 'doc_number') return 'Documento';
		else if (searchBy === 'transaction_type') return 'Tipo';
		else if (searchBy === 'method') return 'Método';
		else if (searchBy === 'owarehouse') return 'Origen';
		else if (searchBy === 'dwarehouse') return 'Destino';
		else if (searchBy === 'signer') return 'Firmante';
		else if (searchBy === 'creator') return 'Creador';
		else if (searchBy === 'entity') return 'Entidad';
		else if (searchBy === 'completed') return 'Completado';
		else if (searchBy === 'edit') return 'Editable';
		else return 'Todos';
	}

	return (
		<CustomSearchToolbar
			left={
				<Input
					id='toolbar-search-order-input'
					style={{ height: '100%' }}
					autoFocus
					name='data'
					placeholder={setPlaceholderSearchBy(searchInData.searchBy)}
					value={searchInData.data}
					onChange={e =>
						mutate1ObjectInWarehouses('transactionsManage', {
							searchInData: {
								...searchInData,
								[e.target.name]: e.target.value,
							},
						})
					}
					startAdornment={
						<Popover
							content={SearchBy}
							placement='rightBottom'
							title='Buscar por'
							trigger='click'
							visible={searchInData.showPopover}
							onVisibleChange={() =>
								mutate1ObjectInWarehouses('transactionsManage', {
									searchInData: {
										...searchInData,
										showPopover: !searchInData.showPopover,
									},
								})
							}
						>
							<div>
								<SearchIcon />
							</div>
						</Popover>
					}
					endAdornment={
						<CloseIcon
							button
							onClick={() =>
								mutate1ObjectInWarehouses('transactionsManage', {
									searchInData: {
										...searchInData,
										renderToolbar: false,
										data: '',
										searchBy: 'all',
									},
								})
							}
						/>
					}
				/>
			}
		/>
	);
};

const ItemsTransactionDocToolbar = props => {
	const {
		selectNotification,
		mutate1ObjectInWarehouses,
		itemsTransactionDoc,
		handleOnClick,
		transactionsManage,
	} = props;

	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={transactionsManage.titleToolbar}
			left={<ToolbarBackButton handleOnClick={handleOnClick} />}
			right={
				<>
					{/* SEARCH WAREHOUSE */}
					<SearchIcon
						onClick={() =>
							mutate1ObjectInWarehouses('itemsTransactionDoc', {
								searchInData: {
									...itemsTransactionDoc.searchInData,
									renderToolbar: true,
								},
							})
						}
					/>
				</>
			}
		/>
	);
};

const SearchItemsTransactionDocToolbar = props => {
	const { Option } = Select;
	const { mutate1ObjectInWarehouses, searchInData } = props;
	const SearchBy = (
		<Select
			width='100%'
			className='select-searchBy'
			defaultValue='all'
			value={searchInData.searchBy}
			onChange={value =>
				mutate1ObjectInWarehouses('inventoryToTransferManage', {
					searchInData: {
						...searchInData,
						searchBy: value,
						showPopover: false,
					},
				})
			}
		>
			<Option value='all'>Todos</Option>
			<Option value='code'>Código</Option>
			<Option value='name'>Nombre</Option>
			<Option value='serie'>Serie</Option>
		</Select>
	);

	function setPlaceholderSearchBy(searchBy) {
		if (searchBy === 'item_code') return 'Código';
		else if (searchBy === 'item_name') return 'Nombre';
		else if (searchBy === 'serie') return 'Serie';
		else return 'Todos';
	}

	return (
		<CustomSearchToolbar
			left={
				<Input
					id='toolbar-search-order-input'
					style={{ height: '100%', width: '100%' }}
					autoFocus
					name='data'
					placeholder={setPlaceholderSearchBy(searchInData.searchBy)}
					value={searchInData.data}
					onChange={e =>
						mutate1ObjectInWarehouses('itemsTransactionDoc', {
							searchInData: {
								...searchInData,
								[e.target.name]: e.target.value,
							},
						})
					}
					startAdornment={
						<Popover
							content={SearchBy}
							placement='rightBottom'
							title='Buscar por'
							trigger='click'
							visible={searchInData.showPopover}
							onVisibleChange={() =>
								mutate1ObjectInWarehouses('itemsTransactionDoc', {
									searchInData: {
										...searchInData,
										showPopover: !searchInData.showPopover,
									},
								})
							}
						>
							<div>
								<SearchIcon />
							</div>
						</Popover>
					}
					endAdornment={
						<CloseIcon
							button
							onClick={() =>
								mutate1ObjectInWarehouses('itemsTransactionDoc', {
									searchInData: {
										...searchInData,
										renderToolbar: false,
										data: '',
										searchBy: 'all',
									},
								})
							}
						/>
					}
				/>
			}
		/>
	);
};

function RenderToolbar({
	history,
	modeView,
	api,
	showNavBar,
	orders,
	users,
	payments,
	warehouses,
	mutate1ObjectInOrders,
	mutate1ObjectInUsers,
	mutate1ObjectInBillings,
	mutate1ObjectInWarehouses,
	mutateDirectProps,
	resetCreateTransaction,
	selectNotification,
	profile,
	getPaycuts,
	getPayments,
	getInvoicePays,
	mutate1ObjectApi,
	getControlOrders,
	onResetEnterNewOrders,
	onGetOrderDataFromUninstalledSerie,
	sendToast,
	// PROPS
	// Reports
	reportControl,
	// ACTIONS
	// Reports
	mutate1ObjectInReports,
}) {
	const location = useLocation();

	const { id, title } = React.useMemo(() => {
		let id, title;
		for (const path in ENV.ROUTES.PATHS) {
			if (location.pathname === ENV.ROUTES.PATHS[path]) {
				title = ENV.ROUTES.TITLES[path];
				id = ENV.ROUTES.IDS[path];
				return {
					id,
					title,
				};
			}
		}

		// Add your individual route
		const moduleRoutes = { ...WAREHOUSE_ROUTES };
		for (const moduleRoute in moduleRoutes) {
			const route = moduleRoutes[moduleRoute];
			if (location.pathname === route.PATH) {
				title = route.TITLE;
				id = route.ID;
				return {
					id,
					title,
				};
			}
		}
		return { id, title };
	}, [location]);

	switch (id) {
		case ENV.ROUTES.IDS.USERS:
			return users.control.searchInData.renderToolbar ? (
				<SearchUsersManage
					searchInData={users.control.searchInData}
					mutate1ObjectInUsers={mutate1ObjectInUsers}
				/>
			) : (
				<UsersManage
					title={title}
					showNavBar={showNavBar}
					selectNotification={selectNotification}
					mutate1ObjectInUsers={mutate1ObjectInUsers}
					searchInData={users.control.searchInData}
				/>
			);
		case ENV.ROUTES.IDS.ORDERS:
			return orders.control.searchInData.renderToolbar ? (
				<SearchOrdersManage
					orders={orders}
					searchInData={orders.control.searchInData}
					mutate1ObjectInOrders={mutate1ObjectInOrders}
					history={history}
					modeView={modeView}
					getControlOrders={getControlOrders}
					mutateDirectProps={mutateDirectProps}
				/>
			) : (
				<OrdersManage
					title={title}
					showNavBar={showNavBar}
					selectNotification={selectNotification}
					mutate1ObjectInOrders={mutate1ObjectInOrders}
					onResetEnterNewOrders={onResetEnterNewOrders}
					profile={profile}
					searchInData={orders.control.searchInData}
				/>
			);
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_PAYCUTS_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_PAYCUTS_DESKTOP:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_PAYCUTS_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_PAYCUTS_DESKTOP:
			return (
				<PaymentPaycuts
					title={title}
					showNavBar={showNavBar}
					selectNotification={selectNotification}
					mutate1ObjectInBillings={mutate1ObjectInBillings}
					getPaycutsModal={payments.getPaycutsModal}
				/>
			);
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_PAYS_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_PAYS_DESKTOP:
			return (
				<PaymentPays
					title={title}
					selectNotification={selectNotification}
					mutate1ObjectInBillings={mutate1ObjectInBillings}
					payments={api.payments}
					paycuts={payments.paycuts}
					handleOnClick={() => {
						history.goBack();
						mutate1ObjectInOrders('control', { orders: [] });
						mutate1ObjectInBillings('paycuts', {
							selected: { ...payments.paycuts.selected, items: [] },
						});
						mutate1ObjectInBillings('pays', {
							filters: {},
						});
						getPaycuts(payments.getPaycutsModal);
					}}
				/>
			);
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_PAYS_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_PAYS_DESKTOP:
			return (
				<InvoicePays
					title={title}
					showNavBar={showNavBar}
					selectNotification={selectNotification}
					mutate1ObjectInBillings={mutate1ObjectInBillings}
					apiPayments={api.payments}
					paycutsManage={payments.paycuts}
					profile={profile}
					onClickBackButton={() => {
						history.goBack();
						mutate1ObjectInOrders('control', { orders: [] });
						mutate1ObjectInBillings('paycuts', {
							selected: { ...payments.paycuts.selected, items: [] },
						});
						mutate1ObjectInBillings('pays', {
							filters: {},
						});
						getPaycuts(payments.getPaycutsModal);
					}}
				/>
			);
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_LINES_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_LINES_DESKTOP:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_LINES_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_LINES_DESKTOP:
			return (
				<PaymentLines
					title={title}
					selectNotification={selectNotification}
					mutate1ObjectInBillings={mutate1ObjectInBillings}
					payments={api.payments}
					pays={payments.pays}
					handleOnClick={() => {
						history.goBack();
						mutate1ObjectInOrders('control', { orders: [] });
						mutate1ObjectInBillings('pays', {
							selected: { ...payments.pays.selected, items: [] },
						});
						mutate1ObjectInBillings('lines', {
							filters: {},
						});
						if (
							id === ENV.ROUTES.IDS.BILLINGS_PAYMENTS_LINES_MOBILE ||
							id === ENV.ROUTES.IDS.BILLINGS_PAYMENTS_LINES_DESKTOP
						) {
							getPayments(payments.paycuts.selected.items);
						} else {
							getInvoicePays(payments.paycuts.selected.items);
						}
					}}
				/>
			);
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_ORDERS_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_PAYMENTS_ORDERS_DESKTOP:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_ORDERS_MOBILE:
		case ENV.ROUTES.IDS.BILLINGS_INVOICES_ORDERS_DESKTOP:
			return (
				<PaymentOrders
					title={title}
					selectNotification={selectNotification}
					mutate1ObjectInBillings={mutate1ObjectInBillings}
					payments={payments}
					api={api}
					handleOnClick={() => {
						history.goBack();
						mutate1ObjectInOrders('control', { orders: [] });
						mutate1ObjectInBillings('pays', {
							selected: { ...payments.pays.selected, items: [] },
							getOrders: undefined,
						});
						mutate1ObjectInBillings('orders', {
							mode: 'view',
							editedOrders: [],
							filters: {},
						});
						if (
							id === ENV.ROUTES.IDS.BILLINGS_PAYMENTS_ORDERS_MOBILE ||
							id === ENV.ROUTES.IDS.BILLINGS_PAYMENTS_ORDERS_DESKTOP
						) {
							getPayments(payments.paycuts.selected.items);
						} else {
							getInvoicePays(payments.paycuts.selected.items);
						}
					}}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_MANAGE_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_MANAGE_DESKTOP:
			return warehouses.control.searchInData.renderToolbar ? (
				<SearchWarehousesToolbar
					searchInData={warehouses.control.searchInData}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
				/>
			) : (
				<WarehousesListToolbar
					title={title}
					showNavBar={showNavBar}
					selectNotification={selectNotification}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					profile={profile}
					warehouses={warehouses}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_STOCK_ARTICLES_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_STOCK_ARTICLES_DESKTOP:
			return warehouses.stockArticlesManage.searchInData.renderToolbar ? (
				<SearchStockArticleToolbar
					searchInData={warehouses.stockArticlesManage.searchInData}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
				/>
			) : (
				<StockArticlesToolbar
					title={title}
					handleOnClick={() => {
						history.goBack();
						mutate1ObjectInWarehouses('control', { mode: 'individual' });
						mutate1ObjectApi('stock', {
							articles: { loading: false, data: [] },
						});
					}}
					selectNotification={selectNotification}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					warehouses={warehouses}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_STOCK_SERIES_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_STOCK_SERIES_DESKTOP:
			return warehouses.stockSeriesManage.searchInData.renderToolbar ? (
				<SearchStockSerieToolbar
					searchInData={warehouses.stockSeriesManage.searchInData}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					history={history}
					modeView={modeView}
					mutateDirectProps={mutateDirectProps}
				/>
			) : (
				<StockSeriesToolbar
					title={title}
					handleOnClick={() => {
						history.goBack();
						mutate1ObjectApi('stock', { series: { loading: false, data: [] } });
					}}
					selectNotification={selectNotification}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					warehouses={warehouses}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_CONTROL_SERIES_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_CONTROL_SERIES_DESKTOP:
			return warehouses.controlSeries.searchInData.renderToolbar ? (
				<SearchControlSeriesToolbar
					searchInData={warehouses.controlSeries.searchInData}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					history={history}
					modeView={modeView}
				/>
			) : (
				<ControlSeriesToolbar
					title={title}
					showNavBar={showNavBar}
					selectNotification={selectNotification}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					warehouses={warehouses}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_ARTICLES_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_ARTICLES_DESKTOP:
			return warehouses.articlesManage.searchInData.renderToolbar ? (
				<SearchArticlesToolbar
					searchInData={warehouses.articlesManage.searchInData}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
				/>
			) : (
				<ArticlesToolbar
					title={title}
					showNavBar={showNavBar}
					selectNotification={selectNotification}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					profile={profile}
					articlesManage={warehouses.articlesManage}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_DESKTOP:
			return warehouses.transactionsManage.searchInData.renderToolbar ? (
				<SearchTransactionsToolbar
					searchInData={warehouses.transactionsManage.searchInData}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
				/>
			) : (
				<TransactionsToolbar
					title={title}
					showNavBar={showNavBar}
					selectNotification={selectNotification}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					transactionsManage={warehouses.transactionsManage}
					profile={profile}
					modeView={modeView}
				/>
			);
		// Transfer
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_INVENTORY_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_INVENTORY_DESKTOP:
		// New entry
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_DESKTOP:
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_MOBILE:
			return warehouses.inventoryToTransferManage.searchInData.renderToolbar ? (
				<SearchTransactionsInventoryToTransfer
					warehousesManage={warehouses}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					transactions={api.transactions}
					onGetOrderDataFromUninstalledSerie={
						onGetOrderDataFromUninstalledSerie
					}
					sendToast={sendToast}
				/>
			) : (
				<TransactionsInventoryToTransfer
					selectNotification={selectNotification}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					inventoryToTransferManage={warehouses.inventoryToTransferManage}
					transactionsManage={warehouses.transactionsManage}
					modeView={modeView}
					handleOnClick={() => {
						history.goBack();
						resetCreateTransaction();
						mutate1ObjectApi('transactions', {
							inventory: { loading: false, data: [] },
						});
					}}
				/>
			);
		// RECEPTION
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_RECEPTION:
			return (
				<Reception
					selectNotification={selectNotification}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					transactionsManage={warehouses.transactionsManage}
					inventoryReception={warehouses.inventoryReception}
					handleOnClick={() => {
						// history.goBack();
					}}
				/>
			);
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_ITEMS_MOBILE:
		case ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP:
			return warehouses.itemsTransactionDoc.searchInData.renderToolbar ? (
				<SearchItemsTransactionDocToolbar
					searchInData={warehouses.itemsTransactionDoc.searchInData}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
				/>
			) : (
				<ItemsTransactionDocToolbar
					title={title}
					selectNotification={selectNotification}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					itemsTransactionDoc={warehouses.itemsTransactionDoc}
					transactionsManage={warehouses.transactionsManage}
					handleOnClick={() => {
						history.goBack();
						mutate1ObjectInWarehouses('transactionsManage', {
							docId: undefined,
						});
						mutate1ObjectApi('transactions', {
							items: { loading: false, data: [] },
						});
					}}
				/>
			);
		case ENV.ROUTES.IDS.REPORTS:
			return reportControl.searchInData.renderToolbar ? (
				<SearchReportsToolbar
					searchInData={reportControl.searchInData}
					mutate1ObjectInReports={mutate1ObjectInReports}
				/>
			) : (
				<ReportsToolbar
					title={title}
					showNavBar={showNavBar}
					selectNotification={selectNotification}
					mutate1ObjectInReports={mutate1ObjectInReports}
					searchInData={reportControl.searchInData}
				/>
			);
		default:
			return (
				<CustomToolbar
					selectNotification={selectNotification}
					left={<HamburguerIcon button onClick={showNavBar} />}
					title={title}
				/>
			);
	}
}

const mapStateToProps = state => ({
	reportControl: selectReportControl(state),
});

const mapDispatchToProps = dispatch => ({
	mutate1ObjectInReports: (obj1Name, keyValuePairs) =>
		dispatch(reportActions.mutate1Object(obj1Name, keyValuePairs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RenderToolbar);
