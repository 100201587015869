// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='maskDoubleCheckSmallIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='0'
			y='3'
			width='16'
			height='10'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.5534 3.33337L7.50003 10.3867L4.71336 7.60671L3.77336 8.54671L7.50003 12.2734L15.5 4.27337L14.5534 3.33337ZM11.7266 4.27337L10.7866 3.33337L6.55997 7.56004L7.49997 8.50004L11.7266 4.27337ZM3.72667 12.2734L0 8.5467L0.946667 7.6067L4.66667 11.3334L3.72667 12.2734Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#maskDoubleCheckSmallIcon)'>
			<rect width='16' height='16' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
