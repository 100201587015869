//  Constants
import GENERAL from 'utils/constants/general';
const { DASHBOARD } = GENERAL;

//  Functions
export function showNavBar() {
	return {
		type: DASHBOARD.SHOW_NAV_BAR,
	};
}

export function sendToast({ autor, message, duration, type, onClick }) {
	return {
		type: DASHBOARD.TOAST_MESSAGE,
		payload: { message: autor, description: message, duration, type, onClick },
	};
}

export function mutateDirectProps(keyValuePairs) {
	return {
		type: DASHBOARD.MUTATE_DIRECT_PROPS,
		payload: keyValuePairs,
	};
}

export function mutate1Object(obj1Name, keyValuePairs) {
	return {
		type: DASHBOARD.MUTATE_1OBJECT,
		payload: { obj1Name, keyValuePairs },
	};
}

export const updateUserProfile = payload => ({
	type: DASHBOARD.UPDATE_USER_PROFILE,
	payload,
});

export const changeUsersAssignedEntity = payload => ({
	type: DASHBOARD.CHANGE_USERS_ASSIGNED_ENTITY,
	payload,
});

//  SEND DATA TO BACKEND
export function reportMe(id, name) {
	return {
		type: DASHBOARD.REPORT_ME,
		payload: { id, name },
	};
}

export function markNotification(payload) {
	return {
		type: DASHBOARD.MARK_NOTIFICATION,
		payload,
	};
}

//  SOCKET EVENTS
export function getNotifications(payload = {}) {
	return {
		type: DASHBOARD.GET_NOTIFICATIONS,
		payload,
	};
}

export const doReset = () => {
	return {
		type: DASHBOARD.RESET,
	};
};

export const resetDndOrdersDrawer = () => ({
	type: DASHBOARD.RESET_DND_ORDERS,
});

export const setOnReceivedNotification = payload => ({
	type: DASHBOARD.SET_ON_RECEIVED_NOTIFICATION,
	payload,
});

export const resetEditProfileDrawer = () => ({
	type: DASHBOARD.RESET_EDIT_PROFILE,
});
