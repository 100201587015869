// Charts
import {
	Pie,
	SimpleArea,
	StackedArea,
	SimpleLine,
	SimpleBar,
	StraightAnglePie,
	TwoSimplePie,
	CustomActiveShapePie,
	TinyBar,
	TwoLevelPie,
} from './charts';

// Chart Tables
import { SimpleTable } from './tables';

/**
 * Step 1: Add new authorized type
 */
const authorizedChartTypes = {
	PIE: 're.pie',
	SIMPLE_AREA: 're.simpleArea',
	STACKED_AREA: 're.stackedArea',
	SIMPLE_LINE: 're.simpleLine',
	SIMPLE_BAR: 're.simpleBar',
	TINY_BAR: 're.tinyBar',
	SIMPLE_STRAIGHT_ANGLE_PIE: 're.sAnglePie',
	SIMPLE_TWO_SIMPLE_PIE: 're.twoSimplePie',
	SIMPLE_CUSTOM_ACTIVE_SHAPE_PIE: 're.cActShaPie',
	TWO_LEVEL_PIE: 're.twoLevelPie',
	// <--HERE
};

/**
 * Step 2: Add new authorized chart component
 */
const authorizedChartComponents = {
	[authorizedChartTypes.PIE]: Pie,
	[authorizedChartTypes.SIMPLE_AREA]: SimpleArea,
	[authorizedChartTypes.STACKED_AREA]: StackedArea,
	[authorizedChartTypes.SIMPLE_LINE]: SimpleLine,
	[authorizedChartTypes.SIMPLE_BAR]: SimpleBar,
	[authorizedChartTypes.TINY_BAR]: TinyBar,
	[authorizedChartTypes.SIMPLE_STRAIGHT_ANGLE_PIE]: StraightAnglePie,
	[authorizedChartTypes.SIMPLE_TWO_SIMPLE_PIE]: TwoSimplePie,
	[authorizedChartTypes.SIMPLE_CUSTOM_ACTIVE_SHAPE_PIE]: CustomActiveShapePie,
	[authorizedChartTypes.TWO_LEVEL_PIE]: TwoLevelPie,
	// <--HERE
};

/**
 * Step 3: Add new authorized chart table component
 */
const authorizedChartTableComponents = {
	[authorizedChartTypes.PIE]: SimpleTable,
	[authorizedChartTypes.SIMPLE_AREA]: SimpleTable,
	[authorizedChartTypes.STACKED_AREA]: SimpleTable,
	[authorizedChartTypes.SIMPLE_LINE]: SimpleTable,
	[authorizedChartTypes.SIMPLE_BAR]: SimpleTable,
	[authorizedChartTypes.TINY_BAR]: SimpleTable,
	[authorizedChartTypes.SIMPLE_STRAIGHT_ANGLE_PIE]: SimpleTable,
	[authorizedChartTypes.SIMPLE_CUSTOM_ACTIVE_SHAPE_PIE]: SimpleTable,
	// <--HERE
};

export default class ChartBuilderConfig {
	static getAuthorizedChartReactNode(key) {
		return authorizedChartComponents[key];
	}

	static getAuthorizedChartTableReactNode(key) {
		return authorizedChartTableComponents[key];
	}
}
