import { createSelector } from 'reselect';

const selectWarehouseState = state => state.warehouses;
const selectApiState = state => state.api;

export const selectInventoryItems = createSelector(
	[selectApiState],
	api => api.articles.data,
);

export const selectWarehouses = createSelector(
	[selectApiState],
	api => api.warehouses.data,
);

export const selectCreateWarehouse = createSelector(
	[selectWarehouseState],
	warehouses => warehouses.createWarehouse,
);

export const selectCreateArticle = createSelector(
	[selectWarehouseState],
	warehouses => warehouses.createArticle,
);

export const selectCoins = createSelector([selectApiState], api => {
	return api.coins.data;
});

export const selectMeasuredUnits = createSelector(
	[selectApiState],
	api => api.measuredUnits,
);

export const selectItemFamilies = createSelector(
	[selectApiState],
	api => api.itemFamilies,
);
