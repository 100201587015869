// Libs
import React from 'react';
// Components
import { Carousel, Image } from 'components';

const AdvertisingBanners = ({ isFetching, modeView, banners }) => {
	const height = modeView === 'cards' ? '250px' : '450px';
	return (
		<Carousel
			autoPlay={true}
			infiniteLoop={true}
			showIndicators={false}
			showThumbs={false}
			showStatus={false}
			showArrows={true}
			// onChange={onChange}
			// onClickItem={onClickItem}
			// onClickThumb={onClickThumb}
		>
			{banners.map(({ src }, idx) => (
				<div key={idx}>
					<Image height={height} src={src} />
				</div>
			))}
		</Carousel>
	);
};

export default AdvertisingBanners;
