import React from 'react';

function ArticlesMobile(props) {
	const { history } = props;

	history.goBack();

	return <div></div>;
}

export default ArticlesMobile;
