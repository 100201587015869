// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Components
import { Wrapper, Span, Button } from 'components';

function ScannedPath({ theme, selectedZone, selectedArea, scannedPackage }) {
	return (
		<>
			<Span fontWeight='bold' color={theme.fonts.colors.default}>
				{`${selectedZone?.name || '[Zona]'} / ${
					selectedArea?.name || '[Area]'
				} /`}
			</Span>
			<Span fontWeight='bold' margin='0 0 0 5px' color={theme.colors.text.high}>
				[{scannedPackage?.id || 'Paquete'}]
			</Span>
		</>
	);
}

function StartStopScan({
	theme,
	inventoryReception,
	mutate1ObjectInWarehouses,
}) {
	return (
		inventoryReception.modeView === 'editPath' && (
			<Button
				color={theme.colors.text.high}
				onClick={() => {
					mutate1ObjectInWarehouses('inventoryReception', {
						modeView: 'scan',
					});
					mutate1ObjectInWarehouses('transactionsManage', {
						titleToolbar: 'Escaneando Series',
					});
				}}
			>
				Empezar a escanear
			</Button>
		)
	);
}

export default function Reception({
	inventoryReception,
	warehouseZones,
	warehouseAreas,
	warehousePackages,
	mutate1ObjectInWarehouses,
}) {
	const theme = useTheme();

	const selectedZone =
		inventoryReception.zoneId &&
		warehouseZones.data.filter(
			zone => zone.id === inventoryReception.zoneId,
		)[0];
	const selectedArea =
		inventoryReception.areaId &&
		warehouseAreas.data.filter(
			area => area.id === inventoryReception.areaId,
		)[0];
	const scannedPackage =
		inventoryReception.scannedPackageId &&
		warehousePackages.data.filter(
			pckg => pckg.id === inventoryReception.scannedPackageId,
		)[0];

	return (
		<Wrapper height='48px' justifyContent='space-between' mediumBackground>
			{/* LEFT */}
			<Wrapper padding='0'>
				<ScannedPath
					theme={theme}
					selectedZone={selectedZone}
					selectedArea={selectedArea}
					scannedPackage={scannedPackage}
				/>
			</Wrapper>

			{/* RIGHT */}
			<Wrapper padding='0'>
				<StartStopScan
					theme={theme}
					inventoryReception={inventoryReception}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
				/>
			</Wrapper>
		</Wrapper>
	);
}
