import { createSelector } from 'reselect';

const selectContracts = state => state.contracts;

export const selectUpdateContractModal = createSelector(
	[selectContracts],
	contracts => contracts.updateContractModal,
);
export const selectCreateContract = createSelector(
	[selectContracts],
	contracts => contracts.createContract,
);
