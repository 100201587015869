// Services
import ApiServiceV2 from 'services/apiService/apiServiceV2';

const endpoint = '/order_transaction_docs';

const useOrderTransactionDoc = () => {
	return {
		/**
		 * Delete Audited OTD
		 * @param {int} otdId
		 * @returns orders with user assignment
		 */
		deleteAuditedOTD: otdId =>
			ApiServiceV2.delete({
				url: `${endpoint}/deleteAuditedOTD/${otdId}`,
			}),
	};
};
export default useOrderTransactionDoc;
