// Libs
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
// Utils
import { idbHandler } from 'utils/libs';
// Actions
import {
	mutate1Object as mutate1ObjectInCollector,
	updateCollectorRequiredValidation,
	getSavedCollectorValuesByTransactionDoc,
	getAuditedTransactionDocs,
	doReset as doResetCollector,
	resetAuditedTransactionDocs,
} from 'components/Collector/actions';
import { mutate1ObjectApi } from 'store/api/actions';
// Selectors
import { selectIsMobile } from 'screens/Dashboard/selectors';
import { selectReviewManage } from 'components/Collector/selectors';
import { selectProfile } from 'screens/Login/selectors';
import { getOrderInformation } from 'screens/Orders/selectors';
// Hooks
import { useCollectorLayout } from 'hooks';
// Components
import { Wrapper, AvatarCarousel, SettingsIcon } from 'components';
import CollectorUtils from 'components/Collector/CollectorUtils';
import CollectorLayout from 'components/Collector/CollectorLayout';
import CollectorOptionList from 'components/Collector/options/OptionList';
import { EmptyIllustration } from 'components/Illustrations';
import Spining from '../Spining';

function Header({ docId, order, mutate1ObjectInCollector }) {
	return (
		<Wrapper
			padding='0'
			flexDirection='column'
			position='absolute'
			right='-10px'
		>
			{docId && (
				<SettingsIcon
					onClick={() =>
						mutate1ObjectInCollector('reviewManage', {
							isControlPanelOpen: true,
						})
					}
				/>
			)}
			<CollectorOptionList order={order} />
		</Wrapper>
	);
}

function ReviewsTab({
	// Props
	order,
	// Redux
	profile,
	reviewManage,
	mutate1ObjectInCollector,
	mutate1ObjectApi,
	updateCollectorRequiredValidation,
	getSavedCollectorValuesByTransactionDoc,
	getAuditedTransactionDocs,
	resetAuditedTransactionDocs,
	doResetCollector,
}) {
	const isMobile = useSelector(selectIsMobile);
	const { transactionDocs } = reviewManage;
	const {
		readOnlyCollector,
		collectorLayout,
		duplicatedCollectorLayout,
		filteredCollectorLayout,
		collectorValues,
		resources,
	} = useCollectorLayout({
		orderId: order.order_id,
		docId: transactionDocs.docId,
	});

	// TODO: Recargar los transactionDocs de indexedDB
	const onClick = useCallback(
		({ docId }) => {
			idbHandler
				.getTemplates()
				.then(data => mutate1ObjectApi('templates', { data }));

			if (!docId) {
				// setState({ showCreateReport: true });
			} else {
				getSavedCollectorValuesByTransactionDoc(docId, order.order_id);
				CollectorUtils.enterToCollectorEcosystem({
					orderId: order.order_id,
					mutate1ObjectInCollector,
					updateCollectorRequiredValidation,
				});
			}
		},
		[transactionDocs.data],
	);

	// Get selected avatar id
	const selectedAvatarId = useMemo(
		() =>
			CollectorUtils.getAvatarIdFromSelectedTransactionDoc(
				transactionDocs.data,
				transactionDocs.docId,
			),
		[transactionDocs.data, transactionDocs.docId],
	);

	useEffect(() => {
		getAuditedTransactionDocs(order.order_id);

		return () => {
			CollectorUtils.leftFromCollectorEcosystem(doResetCollector);
			resetAuditedTransactionDocs();
		};
	}, [order.order_id]);

	if (transactionDocs.isFetching) return <Spining />;

	return (
		<Wrapper padding='0' flexDirection='column' alignItems='stretch'>
			<Wrapper padding='0' justifyContent='space-between' alignItems='baseline'>
				{!transactionDocs?.data?.length ? (
					<EmptyIllustration
						title='No hay reportes registrados'
						width={!isMobile ? '300px' : '100px'}
						fontSize='2em'
					/>
				) : (
					<AvatarCarousel
						size='large'
						selectedAvatarId={selectedAvatarId}
						avatarList={CollectorUtils.formatAvatars(
							transactionDocs.data,
							profile,
						)}
						skeleton={transactionDocs.isFetching}
						spin={reviewManage.isFetching}
						onClick={onClick}
					/>
				)}
				<Header
					docId={transactionDocs.docId}
					order={order}
					mutate1ObjectInCollector={mutate1ObjectInCollector}
				/>
			</Wrapper>
			<CollectorLayout
				order={order}
				docId={transactionDocs.docId}
				readOnlyCollector={readOnlyCollector}
				collectorLayout={collectorLayout}
				duplicatedCollectorLayout={duplicatedCollectorLayout}
				filteredCollectorLayout={filteredCollectorLayout}
				collectorValues={collectorValues}
				resources={resources}
			/>
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	profile: selectProfile(state),
	reviewManage: selectReviewManage(state),
	orderInformation: getOrderInformation(state),
});
const actions = {
	mutate1ObjectInCollector,
	updateCollectorRequiredValidation,
	getSavedCollectorValuesByTransactionDoc,
	mutate1ObjectApi,
	getAuditedTransactionDocs,
	resetAuditedTransactionDocs,
	doResetCollector,
};

export default connect(mapStateToProps, actions)(ReviewsTab);
