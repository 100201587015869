// Libs
import React, { useEffect } from 'react';
// Components
import { LogoIcon } from 'components';
// Config
import FormBuilderConfig from './form.config';

const SwitchDefaultComponent = ({
	isSubmit,
	submitForm,
	children,
	onChange,
	...rest
}) => {
	const DefaultComponent = children;
	return isSubmit ? (
		rest.sending ? (
			<LogoIcon spin={true} />
		) : (
			<DefaultComponent {...rest} onClick={!rest.disabled ? submitForm : null}>
				{rest.title}
			</DefaultComponent>
		)
	) : (
		<DefaultComponent {...rest} onChange={onChange} />
	);
};

const FormComponentBuilder = ({ externalDisabled, values, ...rest }) => {
	const authorizedFormComponentTypes =
		FormBuilderConfig.getAuthorizedFormComponentTypes();
	const value = values[rest.componentParam];
	const paramValues = FormBuilderConfig.getParamValues(rest.params, values);
	const isSubmit = rest.type === authorizedFormComponentTypes.submit;
	const disabled =
		externalDisabled ||
		FormBuilderConfig.hasRequiredParamEmpty(rest.params, values);

	const onChange = value =>
		rest.setValues(prev => ({ ...prev, [rest.componentParam]: value }));

	useEffect(() => {
		onChange(undefined);
	}, [FormBuilderConfig.checkParamValuesChange(paramValues)]);

	// Custom component selected by param
	const CustomComponent =
		FormBuilderConfig.getAuthorizedCustomFormComponentReactNode(
			rest.componentParam,
		);
	if (CustomComponent)
		return (
			<CustomComponent
				{...rest}
				value={value}
				paramValues={paramValues}
				disabled={disabled}
				onChange={onChange}
			/>
		);

	// Default component selected by type
	const DefaultComponent = FormBuilderConfig.getDefaultFormComponentReactNode(
		rest.type,
	);
	if (!DefaultComponent) return null;

	return (
		<SwitchDefaultComponent
			{...rest}
			value={value}
			paramValues={paramValues}
			disabled={disabled}
			isSubmit={isSubmit}
			children={DefaultComponent}
			onChange={onChange}
		/>
	);
};

export default FormComponentBuilder;
