export const heatmapLayer = {
	id: 'heatmapLayer',
	type: 'heatmap',
	maxzoom: 15,
	paint: {
		'heatmap-weight': {
			property: 'mag',
			type: 'exponential',
			stops: [
				[0, 0],
				[6, 1],
			],
		},
		'heatmap-color': [
			'interpolate',
			['linear'],
			['heatmap-density'],
			0,
			'rgba(33,102,172,0)',
			0.2,
			'blue',
			0.4,
			'cyan',
			0.6,
			'lime',
			0.8,
			'yellow',
			1,
			'red',
		],
	},
};

export const getLineGeoJSON = markers => ({
	type: 'FeatureCollection',
	features: [
		{
			type: 'Feature',
			properties: {},
			geometry: {
				type: 'LineString',
				coordinates: markers.map(marker => [marker.longitude, marker.latitude]),
			},
		},
	],
});

export const getGeoJSON = markers => ({
	type: 'FeatureCollection',
	features: markers.map(marker => ({
		type: 'Feature',
		properties: { mag: 1 },
		geometry: {
			type: 'Point',
			coordinates: [marker.longitude, marker.latitude],
		},
	})),
});
