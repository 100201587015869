module.exports = Object.freeze({
	EXPERT: {
		L1_EXPERT: 1,
	},
	ORDERS: {
		L1_MANAGER: 3,
		L2_MANAGER: 22,
		L3_MANAGER: 24,
	},
	INVENTORY: {
		CATALOGUE: {
			L1: 7,
			L2: 31,
		},
		WAREHOUSE_MANAGER: 4,
		REQUEST_MANAGER: 5,
		TRANSACTION: {
			L1: 2,
			L2: 30,
		},
	},
	SUPERVISOR: {
		L1_MANAGER: 11,
		L2_MANAGER: 10,
		L3_MANAGER: 25,
	},
	PAYMENT: {
		PAY_MANAGER: 8,
		CHARGE_MANAGER: 9,
		L2_MANAGER: 29,
	},
	ANALYST: {
		ORDER_ANALYST: 17,
	},
	USER: {
		USER_MANAGER: 16,
	},
	REPORT: {
		REPORT_MANAGER: 23,
	},
});
