// Libs
import React from 'react';

const Delete = ({ fill, fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='maskDeleteIcon24'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='5'
			y='3'
			width='14'
			height='18'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.5 4H19V6H5V4H8.5L9.5 3H14.5L15.5 4ZM8 21C6.9 21 6 20.1 6 19V7H18V19C18 20.1 17.1 21 16 21H8Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#maskDeleteIcon24)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);

export default Delete;
