// Libs
import React from 'react';
// Components
import { CloseIcon, Tooltip } from 'components';

const Status = ({ className, message, onRetry }) => (
	<Tooltip title={message}>
		<div className={className} onDoubleClick={onRetry}>
			<CloseIcon />
		</div>
	</Tooltip>
);

export default Status;
