// Constants
import GENERAL from 'utils/constants/general';
const { SUPERVISOR } = GENERAL;

//  InitialState
const initialState = {
	control: {
		state: undefined, // KEYWORDS_FEED.STATE.LOADING,
	},
	// -------------------------------------MODALS------------------------------------
	commentDetailsDrawer: {
		isOpen: false,
		selectedElementId: null,
		showPhotos: true,
		showComments: true,
		showButtons: true,
	},
	commentModal: {
		isOpen: false,
		selectedElement: null,
	},
};

const supervisorReducer = (state = initialState, action) => {
	switch (action.type) {
		case SUPERVISOR.MUTATE_DIRECT_PROPS: {
			const keyValuePairs = action.payload;
			return {
				...state,
				...keyValuePairs,
			};
		}
		case SUPERVISOR.MUTATE_1OBJECT: {
			const { obj1Name, keyValuePairs } = action.payload;
			const newObj = { ...state[obj1Name], ...keyValuePairs };
			return {
				...state,
				[obj1Name]: newObj,
			};
		}
		case SUPERVISOR.RESET_COMMENT_DETAILS_DRAWER: {
			return {
				...state,
				commentDetailsDrawer: {
					...initialState.commentDetailsDrawer,
				},
			};
		}
		case SUPERVISOR.RESET: {
			return initialState;
		}
		default:
			return state;
	}
};

export default supervisorReducer;
