// Libs
import React from 'react';
// Hooks
import useNearScreen from 'hooks/useNearScreen';
// Components
import { Wrapper } from 'components';

function LastIntersectedElement({
	rootObserverRef,
	lastRowId,
	isTotalLastRow,
	isLoading,
	dataFilterLastRowId,
	setLastRowId,
	reloadDatasource,
}) {
	// Near screen to update seen
	const [isNearScreen, fromRef] = useNearScreen({
		root: rootObserverRef?.current,
		rootMargin: '200px',
		observeOnce: false,
	});
	React.useEffect(() => {
		if (
			!isLoading &&
			!isTotalLastRow &&
			isNearScreen &&
			lastRowId >= 2 &&
			setLastRowId
		) {
			setLastRowId(lastRowId);
			if (
				dataFilterLastRowId !== undefined &&
				lastRowId !== dataFilterLastRowId &&
				reloadDatasource
			)
				reloadDatasource();
		}
	}, [isNearScreen, lastRowId, isLoading, isTotalLastRow]);

	return <Wrapper padding='2px 0' elementRef={fromRef} />;
}

export default LastIntersectedElement;
