// Libs
import React from 'react';
// Utils
import ModuleUtils from '../utils';
import KEYWORDS from '../keywords';
// Components
import { Wrapper, Title, Button, Radio } from 'components';

const COVERAGE = KEYWORDS.MODULES.ITEMS.COVERAGE;

const UpdateStatusMarketItem = ({
	setIsOpen,
	selectedMarketplaceItem,
	marketItemUpdateStatus,
	handleOnConfirmUpdateClick,
}) => {
	const { newStatus } = marketItemUpdateStatus;
	const [myState, setMyState] = React.useState({
		coverage: KEYWORDS.MODULES.ITEMS.COVERAGE.MY_USER,
		users: [],
	});
	const changeMyState = newState => setMyState({ ...myState, ...newState });

	const actionTitle =
		ModuleUtils.getMarketplaceItemActionByStatusKey(newStatus); // Actions: instalar || desinstalar
	const marketItemName = selectedMarketplaceItem?.name; // MarketItem name
	const radioStyle = {
		display: 'block',
		height: '30px',
		lineHeight: '30px',
		marginBottom: '25px',
	};

	return (
		<Wrapper justifyContent='center'>
			<Wrapper
				padding='0'
				maxWidth='900px'
				flexDirection='column'
				alignItems='stretch'
			>
				<Title margin='0 0 50px 0'>
					Para quién desea {actionTitle} la funcionalidad: {marketItemName} ?
				</Title>
				<Radio.Group
					value={myState.coverage}
					onChange={e => changeMyState({ coverage: e.target.value })}
				>
					<Radio.Button style={radioStyle} value={COVERAGE.MY_USER}>
						Para mí
					</Radio.Button>
					<Radio.Button style={radioStyle} value={COVERAGE.ENTITY}>
						Para mi Entidad (LGB Costa Rica)
					</Radio.Button>
					<Radio.Button style={radioStyle} value={COVERAGE.ORGANIZATION}>
						Para toda la organización (LGB Costa Rica y empresas asociadas)
					</Radio.Button>
					<Radio.Button style={radioStyle} value={COVERAGE.USERS}>
						Sólo para los siguientes usuarios
					</Radio.Button>
				</Radio.Group>

				<Wrapper
					width='250px'
					padding='0'
					margin='20px 0 50px 0'
					justifyContent='space-around'
					alignSelf='center'
				>
					<Button mode='secondary' onClick={() => setIsOpen(false)}>
						Cancelar
					</Button>
					<Button
						onClick={() =>
							handleOnConfirmUpdateClick(myState.coverage, myState.users)
						}
					>
						Confirmar
					</Button>
				</Wrapper>
			</Wrapper>
		</Wrapper>
	);
};

// const UpdateStatusMarketItem = ({
//   isOpen,
//   setIsOpen,
//   marketplaceItems,
//   marketItemUpdateStatus,
//   handleOnConfirmUpdateClick,
// }) => {
//   const [out, setOut] = React.useState(false);
//   const theme = useTheme();

//   React.useEffect(() => {
//     let timeout;
//     if (out) {
//       timeout = setTimeout(() => setOut(false), 1000);
//     }
//     return () => clearTimeout(timeout);
//   }, [out]);

//   React.useEffect(() => {
//     //TODO: if (isOpen) getUsers();
//   }, [isOpen]);

//   const handleOnBackButtonClick = React.useCallback(() => {
//     console.log("alsdjf");
//     setOut(true);
//     setTimeout(() => setIsOpen(false), 600);
//   }, []);

//   return (
//     <Drawer
//       key={"updateStatusMarketplaceItem"}
//       className={out ? "animated slideOutUp faster" : ""}
//       height="100%"
//       width={`${document.documentElement.clientWidth}px`}
//       header_font_weight="bold"
//       title={
//         <UpdateStatusMarketItemTitle
//           handleOnBackButtonClick={handleOnBackButtonClick}
//         />
//       }
//       placement={"top"}
//       closable={false}
//       visible={isOpen}
//       key={"top"}
//     >

//     </Drawer>
//   );
// };

export default UpdateStatusMarketItem;
