// Libs
import React, { useState, useCallback } from 'react';
// Utils
import GENERAL from 'utils/constants/general';
import { debounce, filterOption } from 'utils/libs';
import DataFieldManagerUtils from '../DataFieldManagerUtils';
// Hooks
import { useOrderZoneAssignment } from 'hooks';
// Components
import { Wrapper, LogoIcon, Select } from 'components';
import Read from '../Read';
import Updating from '../Updating';
import Status from '../Status';
// Styles
import styles from './ZoneId.module.css';

const { UNSUCCESS } = GENERAL.ENV.STATUS;
const { SERVICE_ID, EVENT_ID } = DataFieldManagerUtils.getFieldIds();

const Selection = ({
	field,
	rowData,
	isLoading,
	zones,
	onGetZoneAssignment,
	onSetZoneAssignment,
	setOpen,
}) => {
	const fetchDebouncedZoneAssignment = useCallback(
		debounce(onGetZoneAssignment, 700),
		[],
	);

	const onChange = field.onChange || onSetZoneAssignment;

	const filterData = {
		serviceId: rowData[SERVICE_ID],
		eventId: rowData[EVENT_ID],
	};

	return (
		<div className={styles.container}>
			<Select
				width='100%'
				showSearch
				filterOption={filterOption}
				value={rowData[field.id]}
				onSearch={searchValue =>
					fetchDebouncedZoneAssignment({
						...filterData,
						searchValue,
					})
				}
				onChange={(_value, { props }) =>
					onChange(
						{
							field,
							rowData,
							option: {
								id: _value,
								name: props.children,
							},
						},
						() => setOpen(false),
					)
				}
				onDropdownVisibleChange={isOpen =>
					isOpen && onGetZoneAssignment(filterData)
				}
				dropdownRender={menu =>
					isLoading && !zones.length ? (
						<Wrapper width='100%' justifyContent='center'>
							<LogoIcon spin={true} />
						</Wrapper>
					) : (
						menu
					)
				}
			>
				{zones.map(zone => (
					<Select.Option key={zone.id} value={zone.id}>
						{zone.name}
					</Select.Option>
				))}
			</Select>
		</div>
	);
};

const ZoneId = ({ rowData, field }) => {
	const [isOpen, setOpen] = useState(false);
	const { state, onGetZoneAssignment, onSetZoneAssignment, onRetry } =
		useOrderZoneAssignment();

	if (state.status === UNSUCCESS)
		return (
			<Status
				className={styles.container}
				message={state.message}
				onRetry={onRetry}
			/>
		);

	if (state.isSetting) return <Updating className={styles.container} />;

	if (!isOpen)
		return (
			<Read
				className={styles.container}
				field={field}
				rowData={rowData}
				setOpen={setOpen}
			/>
		);

	return (
		<Selection
			field={field}
			rowData={rowData}
			isLoading={state.isLoading}
			zones={state.data}
			onGetZoneAssignment={onGetZoneAssignment}
			onSetZoneAssignment={onSetZoneAssignment}
			setOpen={setOpen}
		/>
	);
};

export default ZoneId;
