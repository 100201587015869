// Utils
import ImportUtils from '../../ImportUtils';

const getMatchedValue = ({ matchedValues, castType }) => {
	if (!Array.isArray(matchedValues) || !matchedValues.length) return '';

	const checkedValue = !castType
		? matchedValues[0]
		: ImportUtils.checkScrappedValueType({
				value: matchedValues[0],
				castType,
		  });

	if (!checkedValue) return '';
	return String(checkedValue).trim();
};

const regexFieldScraping = ({ txtOrder, field }) => {
	if (!Array.isArray(field.regex) || !field.regex.length)
		return field.default ? String(field.default) : '';

	const scrappedValue = field.regex.reduce((_fieldValue, regex) => {
		if (!regex.re) return _fieldValue;
		const re = new RegExp(regex.re, regex.flags || 'g');
		_fieldValue = _fieldValue.match(re);
		return getMatchedValue({
			matchedValues: _fieldValue,
			castType: field.cast,
		});
	}, txtOrder);

	return scrappedValue || (field.default ? String(field.default) : '');
};

const concatFieldScraping = ({ txtOrder, field, currentFieldValue }) => {
	if (!Array.isArray(field.concat) || !field.concat.length)
		return currentFieldValue;

	return field.concat.reduce((_fieldValue, concatField) => {
		const concatBy = concatField.by || '';
		const concatValue = regexFieldScraping({ txtOrder, field: concatField });
		if (concatValue) _fieldValue += concatBy + concatValue;
		return _fieldValue.trim();
	}, currentFieldValue);
};

export default class TxtScrapingProcess {
	constructor(txtOrder, fields) {
		this.txtOrder = txtOrder;
		this.fields = fields;
	}

	run() {
		return this.fields.reduce((_order, field) => {
			let value = regexFieldScraping({ txtOrder: this.txtOrder, field }).trim();

			value = concatFieldScraping({
				txtOrder: this.txtOrder,
				field,
				currentFieldValue: value,
			}).trim();

			_order[field.id] = value;

			return _order;
		}, {});
	}
}
