// Libs
import React from 'react';
// import { useTheme } from "styled-components";
import useTheme from 'hooks/useTheme';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';

const CheckCircleIcon = ({ active, button, onClick }) => {
	// const theme = useTheme();
	const { theme } = useTheme();
	const fillColor = active ? theme._secondaryColor : theme._inactiveColor;

	return button ? (
		<IconButton onClick={onClick}>
			<Icon fill={fillColor} />
		</IconButton>
	) : (
		<Icon fill={fillColor} onClick={onClick} />
	);
};

export default CheckCircleIcon;
