// Libs
import React from 'react';
// Components
import { HamburguerIcon, AddIcon } from 'components';
import { CustomToolbar } from '../../sharedFunctions';
// Styles
import '../../index.css';

export function PaymentPaycuts(props) {
	const {
		showNavBar,
		title,
		selectNotification,
		mutate1ObjectInBillings,
		getPaycutsModal,
	} = props;

	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={title}
			left={<HamburguerIcon button onClick={showNavBar} />}
			right={
				<>
					{/* CREATE INVOICE PAYCUT */}
					{getPaycutsModal.type === 'invoice' && (
						<AddIcon
							button
							onClick={() =>
								mutate1ObjectInBillings('createPaycutModal', { isOpen: true })
							}
						/>
					)}
				</>
			}
		/>
	);
}
