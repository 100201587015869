// Libs
import React from 'react';
// Components
import { Wrapper, Empty } from 'components';
import { VirtualizedList } from 'screens/Utils';
// Utils
import { filterPaycuts } from 'utils/filters/Billings/paycuts';

function PaycutsMobile({
	payments,
	paycuts,
	coins,
	mutate1Object,
	getPayments,
	syncPaycut,
	profile,
	getPaycutsModal,
	getPaycutResume,
	getPaycutsResumeModal,
	mutate1ObjectApi,
}) {
	const allPaycuts = payments.paycuts.data;
	const filteredPaycuts = filterPaycuts(allPaycuts, paycuts.filters);

	return (
		<Wrapper padding='8px' justifyContent='center' className='animated fadeIn'>
			{!payments.paycuts.loading && filteredPaycuts.length === 0 ? (
				<Empty style={{ marginTop: '20px' }} />
			) : (
				<VirtualizedList
					cardId='paycutCard'
					listStyle={{
						height: document.documentElement.clientHeight,
						width: document.documentElement.clientWidth,
					}}
					rowHeight={220}
					spinTitle='Consultando Cortes...'
					isSpinning={payments.paycuts.loading}
					rows={filteredPaycuts}
					customProps={{
						paycutsManage: paycuts,
						getPaycutsResumeModal,
						profile,
						coins: coins.data,
						apiPayments: payments,
						syncPaycut,
						getPayments,
						getPaycutsModal,
						getPaycutResume,
						mutate1ObjectInBillings: mutate1Object,
						mutate1ObjectApi,
					}}
				/>
			)}
		</Wrapper>
	);
}

export default PaycutsMobile;
