// Libs
import React from 'react';
import { Empty } from 'antd';
import VirtualizedList from '../../../Lists/VirtualizedList';

export default function SerieHistoryModal(props) {
	const { serieHistory, sendToast, modeView } = props;

	return (
		<div className='container-info-orders-income'>
			{serieHistory.data.length === 0 ? (
				<Empty />
			) : (
				<VirtualizedList
					cardId='serieHistoryCard'
					interpolatedRowStyle
					listStyle={{
						height: document.documentElement.clientHeight * 0.66,
						width:
							modeView === 'table'
								? 520
								: document.documentElement.clientWidth * 0.98,
					}}
					rowHeight={190}
					rows={serieHistory.data}
					isSpinning={false}
					customProps={{
						sendToast,
					}}
				/>
			)}
		</div>
	);
}
