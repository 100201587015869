// Libs
import React from 'react';
// Components
import { Wrapper, InBuilding } from 'components';

function NewEntryMobile(props) {
	const { history } = props;

	history.goBack();

	return (
		<Wrapper className='animated fadeIn'>
			<InBuilding />
		</Wrapper>
	);
}

export default NewEntryMobile;
