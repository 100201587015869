export function requestGenerateReportValidator(payload, reportParams) {
	const { reportId, reportTable, toEmails, params } = payload;

	const checkInvalidParam = param => {
		const reportParam = reportParams[param];
		if (!reportParam?.required) return false;

		const valueParam = params[param];
		if (reportParam.type === 'array' || reportParam.multiple) {
			return !Array.isArray(valueParam) || valueParam.length === 0;
		}

		return !params[param];
	};

	// Validating complete params
	let errors = '';
	if (
		!reportId ||
		!reportTable ||
		!Array.isArray(toEmails) ||
		toEmails.length === 0 ||
		typeof params !== 'object'
	) {
		errors = 'Debe ingresar todos los campos requeridos [*]';
	}

	if (reportParams) {
		const reportParamKeys = Object.keys(reportParams);
		const incompleteParam = reportParamKeys.find(param =>
			checkInvalidParam(param),
		);

		if (incompleteParam) {
			errors = 'Debe ingresar todos los campos requeridos [*]';
		}
	}

	if (errors === '') return null;
	return errors;
}
