import GENERAL from 'utils/constants/general';

const { CLIENTS } = GENERAL;

export const onSearchClient = client => ({
	type: CLIENTS.SEARCH_CLIENT,
	payload: client,
});

export const onCreateClient = client => ({
	type: CLIENTS.CREATE_CLIENT,
	payload: client,
});

export const mutate1Object = (obj1Name, keyValuePairs) => ({
	type: CLIENTS.MUTATE_1OBJECT,
	payload: { obj1Name, keyValuePairs },
});
