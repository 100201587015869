// Libs
import React from 'react';
import { useTheme } from 'styled-components';
import {
	Wrapper,
	Link,
	IconButton,
	Span,
	Dropdown,
	Menu,
	EyeIcon,
	RefreshIcon,
	LogoIcon,
	CloseIcon,
} from 'components';
// Utils
import GENERAL from 'utils/constants/general';
import { fusionPaycutValid } from 'screens/Billings/utils';
import { getSelectedPaycuts } from 'utils/filters/Billings/paycuts';

const { ENV } = GENERAL;

export default function Paycuts({
	paymentsApi,
	payments,
	mutate1ObjectInBillings,
	getPaycuts,
	getPaycutResume,
	history,
	getPayments,
	getInvoicePays,
}) {
	const { paycuts, getPaycutsModal, getPaycutsResumeModal } = payments;
	const theme = useTheme();

	const selectedPaycuts = getSelectedPaycuts(
		paymentsApi.paycuts.data,
		paycuts.selected.items,
	);

	const MenuViewButton = (
		<Menu.Wrapper style={{ width: 300 }}>
			<Menu.Item
				onClick={() => {
					mutate1ObjectInBillings('signPaycutModal', { isOpen: true });
					getPaycutResume({
						...getPaycutsResumeModal,
						isInvoice: getPaycutsModal.type === 'invoice',
						paycutIds: paycuts.selected.items,
					});
				}}
			>
				Resumen
			</Menu.Item>
			<Menu.Item
				onClick={() => {
					getPaycutsModal.type === 'payment'
						? getPayments(paycuts.selected.items)
						: getPaycutsModal.type === 'invoice' &&
						  getInvoicePays(paycuts.selected.items);
				}}
			>
				<Link
					to={
						getPaycutsModal.type === 'payment'
							? ENV.DEVICE_TYPE === 'Mobile'
								? ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_PAYS_MOBILE
								: ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_PAYS_DESKTOP
							: getPaycutsModal.type === 'invoice' &&
							  ENV.DEVICE_TYPE === 'Mobile'
							? ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYS_MOBILE
							: ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYS_DESKTOP
					}
				>
					<Span fontSize='l'>Pagos</Span>
				</Link>
			</Menu.Item>
		</Menu.Wrapper>
	);

	return (
		<Wrapper
			height='48px'
			justifyContent='flex-start'
			padding='10px 0 10px 0'
			mediumBackground
		>
			<Wrapper width='100%' padding='0'>
				{selectedPaycuts.length > 0 ? (
					<>
						<CloseIcon
							button
							onClick={() => {
								mutate1ObjectInBillings('paycuts', {
									selected: { ...paycuts.selected, items: [] },
								});
							}}
						/>
						<Span
							fontWeight='bold'
							fontSize='l'
							color={theme.fonts.colors.default}
						>
							Seleccionados: {selectedPaycuts.length}
						</Span>
					</>
				) : (
					<Span
						fontWeight='bold'
						margin='0 0 0 10px'
						fontSize='l'
						color={theme.fonts.colors.default}
					>
						Registros: {paymentsApi.paycuts.data.length}/
						{paymentsApi.paycuts.data[0]?.total_rows}
					</Span>
				)}
			</Wrapper>
			<Wrapper padding='0'>
				{/* UPDATE PAYCUTS */}
				{!paymentsApi.paycuts.loading ? (
					<RefreshIcon button onClick={() => getPaycuts(getPaycutsModal)} />
				) : (
					<LogoIcon button spin />
				)}

				{/* ANALYTICS */}
				{/* {(history.location.pathname ===
          ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_PAYCUTS_MOBILE ||
          history.location.pathname ===
            ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_PAYCUTS_DESKTOP) && (
          <IconButton
            type="line-chart"
            onClick={() =>
              mutate1ObjectInBillings("paycuts", {
                analytics: { ...paycuts.analytics, isOpen: true },
              })
            }
          />
        )} */}

				{/* VIEW OPTIONS */}
				{selectedPaycuts.length > 0 && fusionPaycutValid(selectedPaycuts) && (
					<Dropdown.Wrapper
						trigger={['click']}
						overlay={MenuViewButton}
						placement='bottomRight'
					>
						<EyeIcon button />
					</Dropdown.Wrapper>
				)}
			</Wrapper>
		</Wrapper>
	);
}
