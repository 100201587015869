//Libs
import React from 'react';
//Hooks
import { useDispatch } from 'react-redux';
//Utils
import UploadResourceUtils from './UploadResourceUtils';
import { CommentDetailsDrawer } from 'core/ElementViewBuilder/views';
// Actions
import { mutate1Object as _mutate1ObjectInSupervisor } from 'screens/Supervisor/actions';
//Components
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Wrapper, Span, Button, Modal, DeleteIcon, LogoIcon } from 'components';
import defaultImage from 'assets/media/img/noImage.png';

const PreviewResource = ({
	//Props
	width,
	height,
	file,
	src,
	collector,
	fileProps,
	authOptions,
	isLoading,
	isError,
	isSuccess,
	duplicableButtons,
	uploadResource,
}) => {
	//My State
	const dispatch = useDispatch();
	const [myState, setMyState] = React.useState({
		fetchedFile: undefined,
		preview: false,
		showPhotoDeleteConfirmation: false,
	});
	//Set state preview button
	const setPreviewButton = newState => setMyState({ ...myState, ...newState });
	//Handle Preview Image
	const onOpenPreview = async () => {
		if (!collector) return;
		const _photos = await Promise.all(
			collector.photos.map(async photo => {
				const { auditOrderId, docId } = fileProps;
				const _fileProps = UploadResourceUtils.getCollectorResourceFileProps(
					{ order_id: auditOrderId, docId },
					collector,
					photo,
				);
				const { src, photoId, createdAt } =
					await UploadResourceUtils.getResourceFromResources(_fileProps);
				return {
					id: photoId,
					src,
					latitude: '',
					longitude: '',
					updatedAt: createdAt,
				};
			}),
		);
		dispatch(
			_mutate1ObjectInSupervisor('commentDetailsDrawer', {
				isOpen: true,
				selectedElementId: 1674,
				data: _photos,
				showPhotos: true,
				showComments: true,
				showButtons: true,
			}),
		);
	};

	const onClosePreview = () =>
		setPreviewButton({ preview: false, showPhotoDeleteConfirmation: false });

	//Handle Delete Image
	// const handleDeletePhoto = () =>
	//   UploadResourceUtils.removeResourceFromResources(fileProps).then(() =>
	//     UploadResourceUtils.getResourcesToSaveInState({
	//       docId: fileProps.docId,
	//       orderId: fileProps.auditOrderId,
	//       serviceTaskId: fileProps.serviceTaskId,
	//       reviewId: fileProps.reviewId,
	//       groupId: fileProps.groupId,
	//     }).then((resources) => updateResources(resources))
	//   );

	const handleDeletePhoto = () =>
		uploadResource(
			UploadResourceUtils.addFileResource({
				fileProps,
			}),
		);

	//Fetch Image Resource
	React.useEffect(() => {
		let isMounted = true;
		UploadResourceUtils.getRemoteImageResource({
			src,
			file,
			noCached: true,
		}).then(fetchedFile => {
			if (!isMounted) return;
			setPreviewButton({ fetchedFile });
		});

		return () => {
			isMounted = false;
		};
	}, [src, file]);

	return (
		<>
			<Wrapper
				position='relative'
				padding='0'
				width={width}
				height={height}
				justifyContent='center'
			>
				<Wrapper padding='0' position='relative'>
					<LazyLoadImage
						style={{ width, height, objectFit: 'cover' }}
						alt=''
						src={myState.fetchedFile || defaultImage}
						onClick={onOpenPreview}
					/>

					{/* DELETE PHOTO */}
					{authOptions.showActions && (
						<div
							style={{
								position: 'absolute',
								backgroundColor: 'rgba(0,0,0,.5)',
								bottom: 0,
								right: 0,
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<DeleteIcon
								light
								onClick={() =>
									setPreviewButton({
										preview: true,
										showPhotoDeleteConfirmation: true,
									})
								}
							/>
						</div>
					)}
				</Wrapper>

				{(isLoading || isError) && (
					<Wrapper padding='0' position='absolute' top='5px' right='5px'>
						<LogoIcon spin={true} />
					</Wrapper>
				)}

				{duplicableButtons && duplicableButtons()}
			</Wrapper>

			<CommentDetailsDrawer />

			{myState.preview && (
				<Modal visible={true} footer={null} onCancel={onClosePreview}>
					<img
						alt='Foto'
						style={{ width: '100%', maxHeight: '540px' }}
						src={
							`${src}?t=${new Date().getMilliseconds()}` || file || defaultImage
						}
					/>
					{myState.showPhotoDeleteConfirmation && (
						<Wrapper padding='1em 0' flexDirection='column'>
							<Span margin='0 0 0.5em 0'>¿Desea borrar ésta foto?</Span>
							<Button onClick={handleDeletePhoto}>
								<Span>Confirmar</Span>
							</Button>
						</Wrapper>
					)}
				</Modal>
			)}
		</>
	);
};

export default React.memo(PreviewResource);
