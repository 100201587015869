// Libs
import React from 'react';
// Components
import { Wrapper } from 'components';
import SynapseFeedLoader from 'components/SynapseFeedLoader/SynapseFeedLoader';

const FetchingModules = () => {
	return (
		<Wrapper
			highBackground
			width='100vw'
			height='100vh'
			padding='0'
			justifyContent='center'
		>
			<SynapseFeedLoader />
		</Wrapper>
	);
};

export default FetchingModules;
