// Libs
import React from 'react';
import { useTheme } from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// Components
import Wrapper from './Wrapper/Wrapper';
import Span from './Span/Span';

const LineDescription = ({
	wrapperStyle,
	conceptStyle,
	valueStyle,
	concept,
	value,
	padding,
	conceptEllipsis,
	valueEllipsis,
	textAlign = 'end',
	onClickValue,
}) => {
	const theme = useTheme();
	return (
		<Wrapper
			style={{ ...wrapperStyle }}
			padding={padding || '2px'}
			justifyContent='space-between'
			alignItems='baseline'
		>
			<Span
				color={theme.colors.text.medium}
				ellipsis={conceptEllipsis}
				style={{ marginRight: '10px', ...conceptStyle }}
			>
				{concept}
			</Span>
			<CopyToClipboard text={value}>
				<Span
					textAlign={textAlign}
					ellipsis={valueEllipsis}
					style={{ ...valueStyle }}
					onClick={onClickValue}
				>
					{value}
				</Span>
			</CopyToClipboard>
		</Wrapper>
	);
};

export default LineDescription;
