// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'underscore';
// Utils
import GENERAL from 'utils/constants/general';
import { filterTransactions } from 'utils/filters/Warehouses/transactions';
// Components
import {
	Wrapper,
	InputNumber,
	Button,
	TableVirtualized,
	Span,
	DeleteIcon,
} from 'components';
import Select from 'components/Select';
import { filterInventoryToTransferSearchInData } from '../../../utils';
// Styles
import '../../../css/Transactions.css';

const { ENV } = GENERAL;
const { Option } = Select;

const { Table, Column, Header, Row, Cell } = TableVirtualized;

function NewEntryDesktop(props) {
	const {
		inventoryToTransferManage,
		createTransaction,
		createPackage,
		warehousePackages,
		warehouseAreas,
		warehouseZones,
		itemStates,
		sendToast,
		mutate1Object,
		profile,
	} = props;
	const articles = [...createTransaction.articles];
	const serialized = [...createTransaction.serialized];
	const inventoryData = articles.concat(serialized);

	React.useEffect(() => {
		if (inventoryToTransferManage.searchInData.searchBy === 'scan') onScan();
	}, [
		inventoryToTransferManage.searchInData.searchBy,
		inventoryToTransferManage.searchInData.data,
		inventoryToTransferManage.searchInData.pressEnter,
	]);

	let filteredInventoryItems = React.useMemo(
		() => filterTransactions(inventoryData, inventoryToTransferManage.filters),
		[inventoryData, inventoryToTransferManage.filters],
	);

	filteredInventoryItems = filterInventoryToTransferSearchInData(
		filteredInventoryItems,
		inventoryToTransferManage.searchInData.searchBy,
		inventoryToTransferManage.searchInData.data,
	);

	if (
		!createTransaction.mode ||
		!createTransaction.incomeMethod ||
		!createTransaction.owhOwnerId ||
		!createTransaction.owarehouseId ||
		!createTransaction.dwarehouseId ||
		!createTransaction.dwarehouseName ||
		!createTransaction.signer
	) {
		return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_DESKTOP} />;
	}

	//  Functions
	function isSerieInTransaction(serie) {
		const idx = _.findIndex(serialized, row => row.serie === serie);
		return idx !== -1;
	}

	function onAddSerie(item_id, serie, state_id, warehouse_package_id) {
		const state = itemStates.find(ste => ste.id === state_id);
		const art = props.articles.data.find(
			art => art.is_serialized && art.item_id === item_id,
		);

		serialized.unshift({
			item_id,
			item_code: art.item,
			item_name: art.name,
			is_serialized: art.is_serialized,
			serialized: art.serialized,
			serie,
			state_id,
			state_name: state.name,
			warehouse_package_id,
			warehouse_name: createTransaction.dwarehouseName,
		});
		mutate1Object('createTransaction', { serialized });
	}

	function onDeleteSerie(serie) {
		mutate1Object('createTransaction', {
			serialized: serialized.filter(row => row.serie !== serie),
		});
	}

	function onDeleteArticle(item_id) {
		mutate1Object('createTransaction', {
			articles: articles.filter(row => row.item_id !== item_id),
		});
	}

	function onScan() {
		if (
			createPackage.created &&
			createPackage.serialized &&
			inventoryToTransferManage.searchInData.data !== ''
		) {
			// MULTI-SERIES
			if (inventoryToTransferManage.searchInData.data.includes(',')) {
				const series = inventoryToTransferManage.searchInData.data.split(',');
				let added = 0;
				let omitted = 0;

				series.map(serie => {
					if (serie && serie !== '') {
						serie = serie.trim();
						if (isSerieInTransaction(serie)) omitted++;
						else {
							onAddSerie(
								createPackage.item_id,
								serie,
								createPackage.state_id,
								createPackage.id,
							);
							added++;
						}
					}
				});
				sendToast({
					message: `Añadidas: ${added} - Omitidas: ${omitted}`,
					duration: 3,
					type: 'success',
				});
				mutate1Object('inventoryToTransferManage', {
					searchInData: { ...inventoryToTransferManage.searchInData, data: '' },
				});
			}
			// ENTER PRESSED
			else if (inventoryToTransferManage.searchInData.pressEnter) {
				let serie = inventoryToTransferManage.searchInData.data;
				if (serie && serie !== '') {
					serie = serie.trim();
					if (isSerieInTransaction(serie))
						sendToast({ message: `Serie omitida`, duration: 2, type: 'warn' });
					else {
						onAddSerie(
							createPackage.item_id,
							serie,
							createPackage.state_id,
							createPackage.id,
						);
						sendToast({
							message: `Serie añadida!`,
							duration: 1,
							type: 'success',
						});
					}
				}
				mutate1Object('inventoryToTransferManage', {
					searchInData: {
						...inventoryToTransferManage.searchInData,
						data: '',
						pressEnter: false,
					},
				});
			}
		}
	}

	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: inventoryData,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: inventoryToTransferManage.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('inventoryToTransferManage', {
							filters: updatedFilters,
						}),
				}}
			>
				{label}
			</Header>
		);
	}

	function getDeleteCellRenderer({ rowData }) {
		function onClickDelete() {
			if (rowData.is_serialized) onDeleteSerie(rowData.serie);
			else onDeleteArticle(rowData.item_id);
		}

		return (
			<Cell>
				<DeleteIcon onClick={onClickDelete} />
			</Cell>
		);
	}

	function getIndexCellRenderer({ rowIndex }) {
		return (
			<Cell>
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	}

	function getAmountCellRendered({ rowData }) {
		const idx = _.findIndex(articles, row => row.item_id === rowData.item_id);
		return (
			<div className='desktop-transactions-table-index-cell-container'>
				{!rowData.is_serialized && (
					<InputNumber
						style={{ width: '100%' }}
						placeholder='Cantidad*'
						value={articles[idx].amount}
						min={1}
						onChange={value => {
							if (value > 0) {
								articles[idx].amount = value;
								mutate1Object('createTransaction', { articles });
							}
						}}
					/>
				)}
			</div>
		);
	}

	function getStateCellRendered({ rowData }) {
		const idx = _.findIndex(serialized, serie => serie.serie === rowData.serie);
		return (
			<div className='desktop-transactions-table-index-cell-container'>
				{rowData.is_serialized && (
					<Select
						style={{ width: '100%' }}
						placeholder={`${rowData.state_name}`}
						value={rowData.state_id}
						onChange={value => {
							serialized[idx].state_id = value;
							mutate1Object('createTransaction', { serialized });
						}}
					>
						{itemStates.map((state, idx) => (
							<Option key={idx} value={state.id}>
								<em>{state.name}</em>
							</Option>
						))}
					</Select>
				)}
			</div>
		);
	}

	function getPackageCellRenderer({ rowData }) {
		const idxArticles = articles.findIndex(
			row => row.item_id === rowData.item_id,
		);

		// create non-serialized package
		function onCreateNonSerializedPackage() {
			// Is there an open series package?
			if (createPackage.created && createPackage.serialized)
				sendToast({
					message: 'Primero complete el paquete de series abierto',
					type: 'warn',
				});
			// Was a package already created for the article found?
			const packages = warehousePackages.data.filter(
				pkg => pkg.item_id === rowData.item_id,
			);
			if (packages.length === 1) {
				articles[idxArticles].warehouse_package_id = packages[0].id;
				mutate1Object('createTransaction', { articles });
			} else
				mutate1Object('createPackage', {
					operation: createTransaction.mode,
					isOpen: true,
					serialized: false,
					id: undefined,
					item_id: rowData.item_id,
					state_id: undefined,
					amount: undefined,
					warehouse_area_id: undefined,
					warehouse_zone_id: undefined,
					created: false,
					mustCreateZone: false,
					mustCreateArea: false,
					zone: undefined,
					area: undefined,
				});
		}

		return (
			<div className='desktop-transactions-table-index-cell-container'>
				{!rowData.is_serialized ? (
					rowData.warehouse_package_id ? (
						<div>{rowData.warehouse_package_id}</div>
					) : (
						<Button
							className='desktop-transactions-button-add-delete-serie'
							onClick={onCreateNonSerializedPackage}
						>
							Nuevo
						</Button>
					)
				) : (
					rowData.warehouse_package_id && (
						<div>{rowData.warehouse_package_id}</div>
					)
				)}
			</div>
		);
	}

	function getAreaCellRenderer({ rowData }) {
		const packageIdx = warehousePackages.data.findIndex(
			pkg => pkg.id === rowData.warehouse_package_id,
		);
		let areaIdx = -1;

		if (packageIdx !== -1)
			areaIdx = warehouseAreas.data.findIndex(
				area =>
					area.id === warehousePackages.data[packageIdx].warehouse_area_id,
			);

		return (
			<Cell>
				<Span>
					{areaIdx !== -1 ? warehouseAreas.data[areaIdx].name : undefined}
				</Span>
			</Cell>
		);
	}

	function getZoneCellRenderer({ rowData }) {
		const packageIdx = warehousePackages.data.findIndex(
			pkg => pkg.id === rowData.warehouse_package_id,
		);
		let areaIdx = -1;
		let zoneIdx = -1;

		if (packageIdx !== -1)
			areaIdx = warehouseAreas.data.findIndex(
				area =>
					area.id === warehousePackages.data[packageIdx].warehouse_area_id,
			);
		if (areaIdx !== -1)
			zoneIdx = warehouseZones.data.findIndex(
				zone => zone.id === warehouseAreas.data[areaIdx].warehouse_zone_id,
			);

		return (
			<Cell>
				<Span>
					{zoneIdx !== -1 ? warehouseZones.data[zoneIdx].name : undefined}
				</Span>
			</Cell>
		);
	}

	function getRowRenderer({ key, columns, style, rowData }) {
		const selectedItem = inventoryToTransferManage.selectedItems.find(
			itemCode => itemCode === rowData.item_code,
		);
		let styleRow = { ...style };

		// Is transaction selected?
		if (selectedItem) {
			styleRow = {
				...styleRow,
				color: 'lightseagreen',
				backgroundColor: 'rgb(44,44,54)',
			};
		}

		return (
			<Row key={key} style={styleRow}>
				{columns}
			</Row>
		);
	}

	//  Render
	return (
		<Wrapper padding='0' className='animated fadeIn'>
			<Table
				highBackground
				width={document.documentElement.clientWidth}
				height={document.documentElement.clientHeight - 115}
				headerHeight={44}
				rowHeight={40}
				rowCount={filteredInventoryItems.length}
				rowGetter={({ index }) => filteredInventoryItems[index]}
				rowRenderer={getRowRenderer}
			>
				<Column
					dataKey='index'
					label='#'
					width={60}
					headerRenderer={({ label }) => <Header>{label}</Header>}
					cellRenderer={getIndexCellRenderer}
				/>
				<Column
					dataKey='item_code'
					label='Código'
					width={120}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='item_name'
					label='Nombre'
					width={300}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='amount'
					label='Cantidad'
					width={100}
					headerRenderer={props => getHeaderRenderer(props, 'numeric')}
					cellRenderer={getAmountCellRendered}
				/>
				<Column
					dataKey='serialized'
					label='Serializado'
					width={110}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='serie'
					label='Serie'
					width={180}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='state_name'
					label='Estado'
					width={120}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
					cellRenderer={getStateCellRendered}
				/>
				<Column
					dataKey='warehouse_name'
					label='Almacén'
					width={180}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='zone_name'
					label='Zona'
					width={130}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
					cellRenderer={getZoneCellRenderer}
				/>
				<Column
					dataKey='area_name'
					label='Area'
					width={130}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
					cellRenderer={getAreaCellRenderer}
				/>
				<Column
					dataKey='warehouse_package_id'
					label='Paquete'
					width={100}
					headerRenderer={props => getHeaderRenderer(props, 'numeric')}
					cellRenderer={getPackageCellRenderer}
				/>
				<Column
					dataKey=''
					label='Eliminar'
					width={130}
					headerRenderer={({ label }) => <Header>{label}</Header>}
					cellRenderer={getDeleteCellRenderer}
				/>
			</Table>
		</Wrapper>
	);
}

export default NewEntryDesktop;
