export default class UserUtils {
	static getUserPropsFromProfile(key, profile) {
		if (!key || !profile) return {};

		const props = profile.user?.settings;
		if (!props) return {};

		if (typeof props === 'object' && !props[key]) return {};
		if (typeof props[key] !== 'object') return {};
		return props[key];
	}

	static isUserBelongsMainEntity(profile) {
		return profile?.entity?.id === profile?.organization?.settings?.mainEntity;
	}
}
