// Libs
import Raven from 'raven-js';

export default function getCoords() {
	return new Promise((resolve, reject) => {
		function success(position) {
			resolve({
				coords: {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
					altitude: position.coords.altitude,
					speed: position.coords.speed,
					heading: position.coords.heading,
					accuracy: position.coords.accuracy,
					altitudeAccuracy: position.coords.altitudeAccuracy,
				},
			});
		}

		function error(err) {
			if (err.code === err.TIMEOUT) {
				Raven.captureException(err.TIMEOUT);
				resolve({ message: 'GPS Timeout', type: 'warn' });
			} else if (err.code === err.POSITION_UNAVAILABLE) {
				Raven.captureException(err.POSITION_UNAVAILABLE);
				resolve({ message: 'GPS No disponible', type: 'warn' });
			} else if (err.code === err.PERMISSION_DENIED) {
				reject({
					err,
					message: 'Se debe activar el GPS de su dispositivo para continuar',
					type: 'warn',
				});
			}
		}

		navigator.geolocation.getCurrentPosition(success, error, {
			timeout: 7000,
		});
	});
}
