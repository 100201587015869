// Libs
import React from 'react';
import cx from 'classnames';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import _ShowMoreText from 'react-show-more-text';
// Styles
import spanStyles from 'components/Span/Span.module.css';

const ShowMoreText = ({
	lines = 2,
	more = 'Ver más',
	less = 'Ver menos',
	hoverable = false,
	onContentClick,
	className,
	anchorClass,
	children,
	...rest
}) => {
	const { theme } = useTheme();
	return (
		<_ShowMoreText
			lines={lines}
			more={more}
			less={less}
			className={cx(spanStyles.span, theme.inactiveColor, className)}
			anchorClass={cx(theme.mediumEmphasisColor, theme.hoverable)}
			{...rest}
		>
			{children}
		</_ShowMoreText>
	);
};

export default ShowMoreText;
