// Libs
import React from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
// Styles
import styles from './Acronym.module.css';
import titleStyles from 'components/Title/Title.module.css';

const getAcronym = text => {
	const na = 'N/A';
	if (!text) return na;

	const splittedText = text.replaceAll('  ', ' ').split(' ');
	const firstLetter = splittedText[0]?.[0] || '';
	const secondLetter = splittedText[1]?.[0] || '';
	if (!firstLetter && !secondLetter) return na;

	return `${firstLetter}${secondLetter}`.toUpperCase();
};

const Acronym = ({ text }) => {
	const { theme } = useTheme();
	return (
		<div
			className={styles.acronym}
			style={{
				color: theme._primaryBackgroundColor,
				backgroundColor: theme._highEmphasisColor,
			}}
		>
			<span className={titleStyles.title}>{getAcronym(text)}</span>
		</div>
	);
};

export default Acronym;
