import AbsAuthorization from 'core/Authorization/manager';

export class CollectorEcosystemAuthorization extends AbsAuthorization {
	constructor(profile) {
		super(profile);
	}
	checkEditCollectors() {
		return !!this.roles[this.checkSupervisorL2Manager()];
	}
	checkEditPhotoData() {
		return !!this.roles[this.checkSupervisorL2Manager()];
	}
	checkDeleteOtdReport() {
		return !!this.roles[this.checkSupervisorL3Manager()];
	}
}

export default CollectorEcosystemAuthorization;
