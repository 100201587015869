// Libs
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
// Utils
import * as billingActions from './actions';
import * as dashboardActions from '../Dashboard/actions';
import * as contractsActions from '../Contracts/actions';
import * as orderActions from '../Orders/actions';
import * as apiActions from 'store/api/actions';
// Selectors
import { selectCoins, selectDepartments } from 'store/api/selectors';
// Components
import ModalBillings from './Modals';
import { Wrapper } from 'components';
// Styles
import './index.css';

const actions = {
	...billingActions,
	// DASHBOARD
	sendToast: dashboardActions.sendToast,
	// CONTRACTS
	mutate1ObjectInContracts: contractsActions.mutate1Object,
	onUpdateContract: contractsActions.onUpdateContract,
	// ORDERS
	mutate1ObjectInOrders: orderActions.mutate1Object,
	revertBill: orderActions.revertBill,
	setOrderProps: orderActions.setOrderProps,
	resetOrderInformation: orderActions.resetOrderInformation,
	sendCommentReport: orderActions.sendCommentReport,
	getOrder: orderActions.getOrder,
	// API
	mutate1ObjectApi: apiActions.mutate1ObjectApi,
	getCoins: apiActions.getCoins,
	getDepartments: apiActions.getDepartments,
	getServices: apiActions.getServices,
	getPaycuts: apiActions.getPaycuts,
	getPayments: apiActions.getPayments,
	getPaymentResume: apiActions.getPaymentResume,
	getPaycutResume: apiActions.getPaycutResume,
	getPaymentLines: apiActions.getPaymentLines,
	getPaymentOrders: apiActions.getPaymentOrders,
	updatePaymentIdLine: apiActions.updatePaymentIdLine,
	getInvoicePays: apiActions.getInvoicePays,
	signWTD: apiActions.signWTD,
	getLineItems: apiActions.getLineItems,
	doResetApiData: apiActions.doResetApiData,
};

class Billings extends Component {
	constructor(...props) {
		super(...props);
	}

	componentDidMount() {
		this.props.getCoins();
	}

	componentWillUnmount() {
		this.props.doReset();
		this.props.doResetApiData('PAYMENTS');
	}

	render() {
		return (
			<Wrapper padding='0'>
				{this.props.routes.map((route, i) => {
					return (
						<Route
							key={i}
							path={route.path}
							render={props => (
								<route.component
									{...props}
									{...this.props}
									routes={route.routes}
								/>
							)}
						/>
					);
				})}
				<ModalBillings {...this.props} />
			</Wrapper>
		);
	}
}

export default connect(
	state => ({
		...state.billings,
		//* ************EXTERNAL PROPS**************
		// ORDERS
		ordersState: {
			control: {
				orders: state.orders.control.orders,
			},
			getOrderInformationModal: {
				...state.orders.getOrderInformationModal,
			},
		},
		// CONTRACTS
		updateContractModal: state.contracts.updateContractModal,
		// API
		coins: selectCoins(state),
		departments: selectDepartments(state),
		templates: state.api.templates,
		services: {
			...state.api.services,
		},
		payments: {
			paycuts: {
				...state.api.payments.paycuts,
			},
			pays: {
				...state.api.payments.pays,
			},
			lines: {
				...state.api.payments.lines,
			},
			orders: {
				...state.api.payments.orders,
			},
			resume: {
				...state.api.payments.resume,
			},
			resume_paycut: {
				...state.api.payments.resume_paycut,
			},
		},
		line_items: state.api.line_items,
		users: {
			data: state.api.users.data,
		},
		zones: {
			...state.api.zones,
		},
		profile: state.login.profile,
		modeView: state.dashboard.modeView,
	}),
	actions,
)(Billings);
