// Libs
import React, {
	useState,
	useMemo,
	memo,
	useEffect,
	useContext,
	useCallback,
} from 'react';
import Map, { Marker, MapContext, FlyToInterpolator } from 'react-map-gl';
// Utils
import { getFormattedDuration } from 'utils/libs/dateFormats';
import { formatNumber, getCoords } from 'utils/libs';
import { UserUtils } from 'utils';
import { RealtimeSubscriber, RealtimeUtils } from 'context/RealtimeContext';
import { usePrevious } from 'hooks';
import { CloudStorage } from 'components/AppConfig.json';
// Components
import {
	Avatar,
	Drawer,
	Wrapper,
	TimeElapsed,
	Timer,
	TimeRemaining,
	LineDescription,
	Title,
	Subtitle,
	StartAt,
	Caption,
	Select,
} from 'components';
import { LogoIcon, CloseIcon } from 'components/Icons';
import { ApiServiceV2 } from 'services';
import { RealtimeAnalyticsIllustration } from 'components/Illustrations';
import MapUtils from './MapUtils';

// const { Option } = Select;

const currentGeolocationTopic = 'currentGeolocation';
const onWayToOrderTopic = 'onWayToOrder';

const MemoizedMarker = memo(
	({ userId, lineColor, latitude, longitude, onUserClick }) => (
		<Marker
			latitude={latitude}
			longitude={longitude}
			offsetLeft={-20}
			offsetTop={-10}
		>
			<div>
				<Wrapper backgroundColor={lineColor} borderRadius='50%' padding='3px'>
					<Avatar
						size='normal'
						fit
						onClick={() => onUserClick(userId, { latitude, longitude })}
						avatar={{
							src: CloudStorage.concat(
								`/syn4pse/users/avatars/${
									userId ? `${userId}.jpg` : `default.svg`
								}`,
							),
						}}
					/>
				</Wrapper>
			</div>
		</Marker>
	),
);

function StandardMapScreen({
	// Props
	elementKey,
	screenKey,
	isMobile,
	isLoading,
	dataFilterLastRowId,
	datasource,
	profile,
	// Actions
	mutate1ObjectInCore,
	// Mqtt
	currentGeolocationUser,
	currentUserOnWayToOrder,
}) {
	// States
	// const [renderMarkers, setRenderMarkers] = useState(0);
	const [mapScreenState, setMapScreenState] = useState({
		selectedUserId: null,
		routeEntries: [],
		monitoring: {
			active: true,
			isFocusAnimation: false,
			isBlockAnimation: false,
		},
	});
	const { map } = useContext(MapContext);
	const [viewport, setViewport] = useState({
		longitude: -80,
		latitude: 90,
		zoom: 5,
	});

	// Update current geolocation user
	const prevCurrentGeolocationUserHash = usePrevious(
		currentGeolocationUser?.hash,
	);
	const prevCurrentUserOnWayToOrderHash = usePrevious(
		currentUserOnWayToOrder?.hash,
	);
	const users = useMemo(() => {
		if (
			currentGeolocationUser &&
			prevCurrentGeolocationUserHash !== currentGeolocationUser.hash
		) {
			const { userId, connected, coords } = currentGeolocationUser;
			datasource = MapUtils.setUserCoords({
				users: datasource,
				userId,
				connected,
				coords,
			});
		}
		if (
			currentUserOnWayToOrder &&
			prevCurrentUserOnWayToOrderHash !== currentUserOnWayToOrder.hash
		) {
			const { userId, startCoords } = currentUserOnWayToOrder;
			datasource = MapUtils.setUserCoords({
				users: datasource,
				userId,
				connected: true,
				coords: startCoords,
			});
		}
		// Remove disconnected or non coords users
		return datasource
			.filter(
				user =>
					user.connected && user.coords?.latitude && user.coords.longitude,
			)
			.map(user => {
				user.lineColor = user.lineColor || MapUtils.getRandomLineColor();
				return user;
			});
	}, [currentGeolocationUser?.hash, currentUserOnWayToOrder?.hash]);

	// Get my own coords
	useEffect(
		() =>
			getCoords().then(({ coords }) =>
				coords
					? setViewport(currentViewport => ({
							...currentViewport,
							longitude: coords.longitude,
							latitude: coords.latitude,
					  }))
					: setViewport(currentViewport => ({ ...currentViewport, zoom: 2 })),
			),
		[],
	);

	// Add realtime event to monitoring entries
	useEffect(() => {
		if (!currentUserOnWayToOrder) return;
		const { userId, orderId, startCoords, endCoords } = currentUserOnWayToOrder;
		setMapScreenState(mapScreenState => ({
			...mapScreenState,
			routeEntries: [
				...mapScreenState.routeEntries.filter(e => e.userId !== userId),
				{
					userId,
					orderId,
					startCoords,
					endCoords,
					isAnimationPending: mapScreenState.monitoring.active,
				},
			],
		}));
	}, [currentUserOnWayToOrder?.hash]);

	const run = (ms, cb) =>
		new Promise(resolve => setTimeout(() => resolve(cb()), ms));

	// Animate realtime monitoring entries
	useEffect(() => {
		// Allowed animation?
		if (!mapScreenState.active || isBlockAnimation) return;
		let t1, t2, t3, t4, t5, t6;
		let isMounted = true;
		// Get next entry to animate
		const entry = mapScreenState.routeEntries.find(e => e.isAnimationPending);
		// Is valid entry?
		if (!entry || !entry.startCoords || !entry.endCoords) return;
		// Block animation
		setMapScreenState(currentMapScreenState => ({
			...currentMapScreenState,
			isBlockAnimation: true,
		}));

		const { userId, startCoords, endCoords } = entry;
		const user = users.find(u => u.id === userId);

		// Async call to get route
		MapUtils.getRouteData({
			startCoords,
			endCoords,
		}).then(async routeData => {
			// Focus animation inactive?
			if (!mapScreenState.isFocusAnimation) {
				// Set start point in map
				MapUtils.setPoint({
					map,
					layerId: `start${userId}`,
					coords: startCoords,
				});
				// Set route in map
				MapUtils.setRoute({
					routeData,
					map,
					routeId: `route${userId}`,
					lineColor: user.lineColor,
				});
				// Set end point in map
				MapUtils.setPoint({
					map,
					layerId: `end${userId}`,
					coords: endCoords,
				});
			}
			// Focus animation active?
			else {
				// Zoom to start coords
				t1 = await run(0, () => {
					if (isMounted) {
						// Set start point in map
						MapUtils.setPoint({
							map,
							layerId: `start${userId}`,
							coords: startCoords,
						});
						// Set viewport to start point
						setViewport(currentViewport => ({
							...currentViewport,
							longitude: startCoords.longitude,
							latitude: startCoords.latitude,
							zoom: 14,
							transitionDuration: 2000,
							transitionInterpolator: new FlyToInterpolator(),
						}));
					}
				});
				// Set viewport zoom out
				t2 = await run(2500, () => {
					if (isMounted) {
						setViewport(currentViewport => ({
							...currentViewport,
							zoom: 3,
							transitionDuration: 2000,
							transitionInterpolator: new FlyToInterpolator(),
						}));
					}
				});
				// Set route in map
				t3 = await run(2500, () => {
					if (isMounted) {
						MapUtils.setRoute({
							routeData,
							map,
							routeId: `route${userId}`,
							lineColor: user.lineColor,
						});
					}
				});
				// Zoom to end coords
				t4 = await run(500, () => {
					if (isMounted) {
						// Set end point in map
						MapUtils.setPoint({
							map,
							layerId: `end${userId}`,
							coords: endCoords,
						});
						// Set viewport to end point
						setViewport(currentViewport => ({
							...currentViewport,
							longitude: endCoords.longitude,
							latitude: endCoords.latitude,
							zoom: 14,
							transitionDuration: 2000,
							transitionInterpolator: new FlyToInterpolator(),
						}));
					}
				});
				// Zoom out
				t5 = await run(
					3000,
					() =>
						isMounted &&
						setViewport(currentViewport => ({
							...currentViewport,
							zoom: 3,
							transitionDuration: 2000,
							transitionInterpolator: new FlyToInterpolator(),
						})),
				);
			}

			// Clear entry & unblock animation
			t6 = await run(
				2500,
				() =>
					isMounted &&
					setMapScreenState(currentMapScreenState => ({
						...currentMapScreenState,
						isBlockAnimation: false,
						routeEntries: [
							...currentMapScreenState.routeEntries.filter(
								e => e.userId !== entry.userId,
							),
							{
								...entry,
								distance: routeData.distance,
								duration: routeData.duration,
								isAnimationPending: false,
							},
						],
					})),
			);

			return () => {
				isMounted = false;
				clearTimeout(t1);
				clearTimeout(t2);
				clearTimeout(t3);
				clearTimeout(t4);
				clearTimeout(t5);
				clearTimeout(t6);
			};
		});
	}, [map, mapScreenState.routeEntries.length]);

	const getCurrentRoute = useCallback(
		(userId, lineColor) => {
			ApiServiceV2.get({
				url: `/users/getOnWayRoute/${userId}`,
			})
				.then(async ({ orderId, onWay, startCoords, endCoords } = {}) => {
					if (!onWay) {
						console.log('No tiene ruta activa');
						return;
					}
					if (
						!Array.isArray(startCoords) ||
						!Array.isArray(endCoords) ||
						startCoords.length != 2 ||
						endCoords.length != 2
					) {
						console.log('Coordenas Incompletas');
						return;
					}
					MapUtils.setPoint({
						map,
						layerId: `start${userId}`,
						coords: startCoords,
					});
					MapUtils.setPoint({
						map,
						layerId: `end${userId}`,
						coords: endCoords,
					});
					const routeData = await MapUtils.getRouteData({
						startCoords,
						endCoords,
					});
					MapUtils.setRoute({
						routeData,
						map,
						routeId: `route${userId}`,
						lineColor,
					});
					setMapScreenState(currentMapScreenState => ({
						...currentMapScreenState,
						routeEntries: [
							...currentMapScreenState.routeEntries.filter(
								e => e.userId !== userId,
							),
							{
								userId,
								orderId,
								startCoords,
								endCoords,
								distance: routeData.distance,
								duration: routeData.duration,
							},
						],
					}));
				})
				.catch(err => {
					console.log(err);
				});
		},
		[map],
	);

	// Onclick
	const onUserClick = useCallback(
		(userId, { latitude, longitude }) => {
			const routeEntry = mapScreenState.routeEntries.find(
				e => e.userId === userId,
			);
			if (!routeEntry) {
				const user = users.find(u => u.id === userId);
				getCurrentRoute(userId, user.lineColor);
			}
			setMapScreenState(currentMapScreenState => ({
				...currentMapScreenState,
				selectedUserId:
					currentMapScreenState.selectedUserId === userId ? null : userId,
			}));
			setViewport(currentViewport => ({
				...currentViewport,
				longitude,
				latitude,
				zoom: 15,
				transitionDuration: 2000,
				transitionInterpolator: new FlyToInterpolator(),
			}));
		},
		[map, mapScreenState.routeEntries],
	);

	// Get markers to render
	const markers = useMemo(
		() =>
			!map
				? []
				: users
						// .filter(
						//   (user) =>
						//     renderMarkers === 0 ||
						//     MapUtils.coordsInBounds(map.getBounds(), user.coords)
						// )
						.map(user => (
							<MemoizedMarker
								key={user.id}
								userId={user.id}
								lineColor={user.lineColor}
								latitude={user.coords.latitude}
								longitude={user.coords.longitude}
								onUserClick={onUserClick}
							/>
						)),
		[
			users,
			map,
			// renderMarkers
		],
	);

	useEffect(() => {
		map?.on('load', () => {
			setViewport(currentViewport => ({
				...currentViewport,
				zoom: 10,
				transitionDuration: 2000,
				transitionInterpolator: new FlyToInterpolator(),
			}));
		});
	}, [map]);

	return (
		<Wrapper
			padding='0'
			width='100%'
			height='100%'
			flexDirection='column'
			position='relative'
		>
			<Map
				{...viewport}
				mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
				width='100%'
				height='100vh'
				mapStyle={MapUtils.getMapStyle()}
				attributionControl={true}
				onViewportChange={setViewport}
				// onMouseUp={() => setRenderMarkers((count) => count + 1)}
				// onTouchEnd={() => setRenderMarkers((count) => count + 1)}
			>
				{markers}
			</Map>
			{mapScreenState.selectedUserId && (
				<MapUserViewer
					userId={mapScreenState.selectedUserId}
					routeEntries={mapScreenState.routeEntries}
					users={users}
					profile={profile}
					setMapScreenState={setMapScreenState}
				/>
			)}

			{mapScreenState.routeEntries[0] && (
				<Wrapper padding='0' position='absolute' left='5px' bottom='65px'>
					<Select
						width='200px'
						placeholder='Rutas'
						onSelect={value => {
							const user = users.find(u => u.id === value);
							if (!user || !user.coords?.longitude || !user.coords.latitude)
								return;
							setViewport(currentViewport => ({
								...currentViewport,
								longitude: user.coords.longitude,
								latitude: user.coords.latitude,
								zoom: 15,
								transitionDuration: 2000,
								transitionInterpolator: new FlyToInterpolator(),
							}));
						}}
					>
						{mapScreenState.routeEntries.map(e => {
							const user = users.find(u => u.id === e.userId);
							if (!user) return;
							return (
								<Option value={e.userId}>
									<Wrapper padding='0'>
										<Wrapper
											margin='0 5px 0 0'
											padding='0'
											width='10px'
											height='10px'
											borderRadius='50%'
											backgroundColor={user.lineColor}
										/>
										{user.name}
									</Wrapper>
								</Option>
							);
						})}
					</Select>
				</Wrapper>
			)}
		</Wrapper>
	);
}

const MapUserViewer = ({
	userId,
	routeEntries,
	users,
	profile,
	setMapScreenState,
}) => {
	const [userMapProfile, setUserMapProfile] = useState({});
	const user = useMemo(() => users.find(u => u.id === userId), [userId, users]);
	const routeEntry = useMemo(
		() => routeEntries.find(e => e.userId === userId),
		[userId, routeEntries],
	);
	useEffect(() => {
		if (!routeEntry?.orderId) return;
		setUserMapProfile({ isFetching: true });
		setTimeout(() => {
			ApiServiceV2.get({
				url: `/users/getUserMapProfile/${routeEntry.orderId}`,
			}).then((props = {}) => setUserMapProfile(props));
		}, 200);
	}, [routeEntry]);

	return (
		<Drawer
			key='mapUserProfileDrawer'
			className='animated fadeIn faster'
			width='100%'
			$maxWidth='300px'
			header_font_weight='bold'
			placement='right'
			closable={false}
			visible={!!userId}
		>
			<Wrapper
				padding='10px'
				width='100%'
				height='100%'
				flexDirection='column'
				alignItems='stretch'
				overflow='auto'
				mediumBackground
			>
				<Wrapper padding='0' width='100%' justifyContent='flex-end'>
					<div>
						<CloseIcon
							button
							onClick={() =>
								setMapScreenState(currentMapScreenState => ({
									...currentMapScreenState,
									selectedUserId: null,
								}))
							}
						/>
					</div>
				</Wrapper>
				<Wrapper
					padding='0'
					width='100%'
					flexDirection='column'
					margin='0 0 16px 0'
				>
					<Avatar
						size='large'
						avatar={{
							src: CloudStorage.concat(
								`/syn4pse/users/avatars/${
									userId ? `${userId}.jpg` : `default.svg`
								}`,
							),
						}}
					/>
					<Title>{user.name}</Title>
					<Subtitle>{user.profile?.emails[0]}</Subtitle>
				</Wrapper>
				<Wrapper padding='0' justifyContent='space-around' margin='0 0 16px 0'>
					<Wrapper padding='0' flexDirection='column'>
						<Subtitle>{user.profile?.phones[0]}</Subtitle>
						<Caption disabled>Telefono</Caption>
					</Wrapper>
					<div />
				</Wrapper>

				{userMapProfile.isFetching ? (
					<Wrapper width='100%' justifyContent='center'>
						<LogoIcon spin />
					</Wrapper>
				) : (
					<Timer
						startAt={userMapProfile.startAt}
						timeElapsed={userMapProfile.timeElapsed}
						timeRemaining={userMapProfile.timeRemaining}
						freezeTimer={userMapProfile.freezeTimer}
						delay={0}
						render={({ timeElapsed, timeRemaining }) => (
							<>
								{/* ORDER ON WAY */}
								<LineDescription
									concept='Orden en camino'
									value={
										userMapProfile.isFetching
											? 'Cargando...'
											: userMapProfile.odtId
									}
								/>

								{/* TRAVEL TIME */}
								<LineDescription
									concept='Distancia del viaje'
									value={
										!routeEntry?.distance
											? 'Calculando...'
											: `${formatNumber.new(
													Number(routeEntry?.distance),
													'',
													0,
											  )} Km`
									}
								/>
								<LineDescription
									concept='Duración del viaje'
									value={
										!routeEntry?.duration
											? 'Calculando...'
											: getFormattedDuration({
													interval: routeEntry?.duration,
													unit: 'seconds',
											  })
									}
								/>

								{/* START */}
								<LineDescription
									concept='Temporizador activo'
									value={
										<StartAt
											startAt={userMapProfile.startAt}
											profile={profile}
										/>
									}
								/>

								{/* ELAPSED */}
								{timeElapsed && (
									<LineDescription
										concept='Tiempo transcurrido'
										value={<TimeElapsed time={timeElapsed} />}
									/>
								)}

								{/* REMAINING */}
								{timeRemaining && (
									<LineDescription
										concept='Expira en'
										value={
											<TimeRemaining time={timeRemaining} humanize={true} />
										}
									/>
								)}
							</>
						)}
					/>
				)}

				<RealtimeAnalyticsIllustration />
			</Wrapper>
		</Drawer>
	);
};

export default props => (
	<RealtimeSubscriber
		topics={[currentGeolocationTopic, onWayToOrderTopic]}
		subscriber={RealtimeUtils.getMyProfileSubscriber(props.profile, {
			userId: '+',
			entityId: UserUtils.isUserBelongsMainEntity(props.profile) && '+',
		})}
	>
		{({ payload }) => (
			<MapContext.Provider>
				<StandardMapScreen
					{...props}
					currentGeolocationUser={payload[currentGeolocationTopic]}
					currentUserOnWayToOrder={payload[onWayToOrderTopic]}
				/>
			</MapContext.Provider>
		)}
	</RealtimeSubscriber>
);
