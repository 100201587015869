// Libs
import React from 'react';
import Input from '@mui/material/Input';
// Utils
import { OrderUtils } from 'utils';
// Components
import {
	Popover,
	CloseIcon,
	HamburguerIcon,
	SearchIcon,
	AddIcon,
} from 'components';
import { CustomToolbar, CustomSearchToolbar } from '../sharedFunctions';
// Styles
import '../index.css';

const Select = React.lazy(() => import('components/Select'));

export function OrdersManage(props) {
	const {
		showNavBar,
		title,
		selectNotification,
		mutate1ObjectInOrders,
		onResetEnterNewOrders,
		profile,
		searchInData,
	} = props;

	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={title}
			left={<HamburguerIcon button onClick={showNavBar} />}
			right={
				<>
					<SearchIcon
						onClick={() =>
							OrderUtils.onClickSearchButton(
								mutate1ObjectInOrders,
								searchInData,
							)
						}
					/>

					{/* Add Orders */}
					{profile.entity.id === profile.organization.settings.mainEntity && (
						<AddIcon
							button
							onClick={() =>
								onResetEnterNewOrders({
									isOpen: true,
								})
							}
						/>
					)}
				</>
			}
		/>
	);
}

export function SearchOrdersManage({
	mutate1ObjectInOrders,
	searchInData,
	history,
	modeView,
	mutateDirectProps,
	orders,
	getControlOrders,
}) {
	const { Option } = Select;
	const { getOrdersQueryModal } = orders;
	const SearchBy = (
		<Select
			className='select-searchBy'
			defaultValue='all'
			value={searchInData.searchBy}
			onChange={value =>
				mutate1ObjectInOrders('control', {
					searchInData: {
						...searchInData,
						searchBy: value,
						showPopover: false,
					},
				})
			}
		>
			<Option value='deepSearch'>Profunda</Option>
			<Option value='order'>Orden</Option>
			<Option value='contract'>Contrato</Option>
			<Option value='client'>Cliente</Option>
			<Option value='city'>Provincia</Option>
			<Option value='township'>Cantón</Option>
			<Option value='zone'>Distrito</Option>
			<Option value='service'>Servicio</Option>
			<Option value='expert'>Técnico</Option>
			<Option value='company'>Entidad</Option>
			<Option value='event'>Evento</Option>
			<Option value='state'>Estado</Option>
			<Option value='priority'>Prioridad</Option>
			<Option value='external_range'>Rango</Option>
			<Option value='all'>Todos</Option>
		</Select>
	);

	function setPlaceholderSearchBy(searchBy) {
		if (searchBy === 'deepSearch') return 'Buscar orden';
		else if (searchBy === 'order') return 'Orden';
		else if (searchBy === 'contract') return 'Contrato';
		else if (searchBy === 'client') return 'Cliente';
		else if (searchBy === 'city') return 'Provincia';
		else if (searchBy === 'township') return 'Cantón';
		else if (searchBy === 'zone') return 'Distrito';
		else if (searchBy === 'service') return 'Servicio';
		else if (searchBy === 'expert') return 'Técnico';
		else if (searchBy === 'company') return 'Entidad';
		else if (searchBy === 'event') return 'Evento';
		else if (searchBy === 'state') return 'Estado';
		else if (searchBy === 'priority') return 'Prioridad';
		else if (searchBy === 'external_range') return 'Rango';
		else return 'Todos';
	}

	return (
		<CustomSearchToolbar
			left={
				<Input
					id='toolbar-search-order-input'
					style={{ width: '100%', height: '100%' }}
					autoFocus
					name='data'
					placeholder={setPlaceholderSearchBy(searchInData.searchBy)}
					value={searchInData.data}
					onKeyPress={e =>
						OrderUtils.onKeyPressedSearchOrder(
							e,
							searchInData,
							history,
							modeView,
							mutate1ObjectInOrders,
							mutateDirectProps,
						)
					}
					onChange={e =>
						mutate1ObjectInOrders('control', {
							searchInData: {
								...searchInData,
								[e.target.name]: e.target.value,
							},
						})
					}
					startAdornment={
						<Popover
							content={SearchBy}
							placement='rightBottom'
							title='Buscar por'
							trigger='click'
							visible={searchInData.showPopover}
							onVisibleChange={() =>
								mutate1ObjectInOrders('control', {
									searchInData: {
										...searchInData,
										showPopover: !searchInData.showPopover,
									},
								})
							}
						>
							<div>
								<SearchIcon />
							</div>
						</Popover>
					}
					endAdornment={
						<CloseIcon
							button
							onClick={() =>
								OrderUtils.onClickCloseSearchButton(
									mutate1ObjectInOrders,
									searchInData,
									getControlOrders,
									getOrdersQueryModal,
								)
							}
						/>
					}
				/>
			}
		/>
	);
}
