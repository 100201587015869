//Libs
import React, { useState } from 'react';
//Hooks
import { useTheme } from 'hooks';
//Components
import { LogoIcon, PDFIcon } from 'components/Icons';
// Utils
import { getHumanize } from 'utils/libs/dateFormats';
//Styles
import styles from './PostStyle.module.css';
// CustomComponents
import {
	RegenerateMergedPDFReports,
	ViewMergedPDFReports,
} from '../ActionButtons';

const ReportName = ({ name }) => {
	const { theme } = useTheme();
	return (
		<span
			style={{
				color: theme._primaryColor,
				display: 'block',
				fontWeight: 'bold',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				maxWidth: '250px',
			}}
		>
			{name}
		</span>
	);
};

/**
 * @param {int} otdId - Order transaction doc ID
 * @param {boolean} isMergePdf - Show if can merge PDF
 * @param {string} reportName - Name of the report
 * @returns
 */
const IconReport = ({ nameButton }) => {
	return (
		<div className={styles.sectionIcons}>
			<div
				style={{
					display: 'inline-flex',
					justifyContent: 'center',
					width: '10%',
				}}
			>
				<PDFIcon />
			</div>
			<div style={{ paddingLeft: '10px' }}>
				<ReportName name={nameButton} />
			</div>
		</div>
	);
};

const Date = ({ date }) => {
	const { theme } = useTheme();
	return (
		<span style={{ color: theme._primaryColor, fontSize: 'small' }}>
			{getHumanize({ date })}
		</span>
	);
};

/**
 * @param {Object} report - Service task report
 * @param {int} otdId - Order transaction doc ID
 * @param {string} odtId - External & Presentational order to work ID
 * @param {function} sendToast - Show message to user action
 * @returns
 */
const ActionButtonWrapper = ({
	resourceId,
	typeMergeButton,
	isLoading,
	mergedPdfUrl,
	mergedPdfUrlAt,
	setLoading,
}) => (
	<div className={styles.actionButtons}>
		{isLoading ? (
			<LogoIcon spin />
		) : (
			<>
				{mergedPdfUrl && (
					<>
						{mergedPdfUrlAt && <Date date={mergedPdfUrlAt} />}
						<div className={styles.iconWrapper}>
							<ViewMergedPDFReports mergedPdfUrl={mergedPdfUrl} />
						</div>
					</>
				)}
				<div className={styles.iconWrapper}>
					<RegenerateMergedPDFReports
						resourceId={resourceId}
						typeMergeButton={typeMergeButton}
						isLoading={isLoading}
						setLoading={setLoading}
					/>
				</div>
			</>
		)}
	</div>
);

/**
 * @param {Object} report - Service task report
 * @param {int} otdId - Order transaction doc ID
 * @param {string} odtId - External & Presentational order to work ID
 * @param {function} sendToast - Show message to user action
 * @returns
 */
const PostStyle = ({
	resourceId,
	nameButton,
	typeMergeButton,
	isLoading,
	mergedPdfUrl,
	mergedPdfUrlAt,
	setLoading,
}) => {
	const { theme } = useTheme();
	const [isHovered, setIsHovered] = useState(false);

	return (
		<div
			className={styles.postWrapper}
			style={{
				backgroundColor: isHovered
					? theme._secondaryBackgroundColor
					: theme._primaryBackgroundColor,
			}}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<IconReport nameButton={nameButton} />
			<ActionButtonWrapper
				resourceId={resourceId}
				typeMergeButton={typeMergeButton}
				isLoading={isLoading}
				mergedPdfUrl={mergedPdfUrl}
				mergedPdfUrlAt={mergedPdfUrlAt}
				setLoading={setLoading}
			/>
		</div>
	);
};

export default PostStyle;
