// Libs
import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import axios from 'axios';
// Utils
import asyncErrorsHandler from '../../store/asyncErrorsHandler';
import GENERAL from '../../utils/constants/general';
import AuthService from '../../utils/libs/auth/AuthService';
import API from '../../utils/constants/api';

const { CONTRACTS, DASHBOARD, ORDERS, ENV } = GENERAL;
const auth = new AuthService();

const getOrdersQueryModal = state => state.orders.getOrdersQueryModal;

/** **** WATCHER SAGAS *******/
function* onUpdateContractWatcher() {
	yield takeLatest(CONTRACTS.UPDATE_CONTRACT, onUpdateContractWorker);
}

function* createContractWatcher() {
	yield takeLatest(CONTRACTS.CREATE_CONTRACT, createContractWorker);
}

/** **** WORKER SAGAS *******/
function* onUpdateContractWorker(action) {
	const { contractIds, fieldsToUpdate, getControlOrders } = action.payload;
	const ordersQueryModal = yield select(getOrdersQueryModal);

	try {
		// Set loading: true
		yield put({
			type: CONTRACTS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'updateContractModal',
				keyValuePairs: { loading: true },
			},
		});
		// Send orders
		yield call(
			axios.put,
			API.DOMAIN.concat('/contracts/v1'),
			{ contractIds, contract: fieldsToUpdate },
			auth.sendToken(),
		);
		// On Response:
		yield put({
			type: CONTRACTS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'updateContractModal',
				keyValuePairs: { isOpen: false, loading: false },
			},
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Contrato actualizado!',
				type: 'success',
			},
		});
		// Get Control Orders?
		if (getControlOrders) {
			yield put({
				type: ORDERS.GET_CONTROL_ORDERS,
				payload: ordersQueryModal,
			});
		}
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: CONTRACTS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'updateContractModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield onUpdateContractWorker(action);
			},
		);
	}
}

function* createContractWorker(action) {
	const payload = action.payload;

	try {
		yield put({
			type: CONTRACTS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createContract',
				keyValuePairs: { status: ENV.STATUS.LOADING },
			},
		});

		yield call(
			axios.post,
			API.DOMAIN.concat(`/contracts/v1`),
			payload,
			auth.sendToken(),
		);

		yield put({
			type: CONTRACTS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createContract',
				keyValuePairs: { status: ENV.STATUS.SUCCESS },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: CONTRACTS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'createContract',
						keyValuePairs: { status: ENV.STATUS.ERROR },
					},
				});
			},
			function* () {
				yield createContractWorker(action);
			},
		);
	}
}

export default function* rootSaga() {
	yield all([onUpdateContractWatcher(), createContractWatcher()]);
}
