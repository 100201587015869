// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M21 9V11H3L4 10L10 4L11.42 5.41L7.83 9H21ZM3 15V13H21L20 14L14 20L12.58 18.59L16.17 15H3Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
