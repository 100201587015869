// Libs
import { createSelector } from 'reselect';
import Immutable from 'immutable';

const selectAnalyticsState = state => state.analytics;

export const selectChartDashboard = createSelector(
	[selectAnalyticsState],
	({ chartDashboard }) => chartDashboard,
);
export const selectGetChartsControl = createSelector(
	[selectAnalyticsState],
	({ getChartsControl }) => getChartsControl,
);

export const selectCharts = createSelector(
	[selectChartDashboard],
	({ charts }) => Immutable.List(charts).toJS(),
);
