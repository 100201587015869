//  Libs
import React from 'react';
import { Select, Button } from 'antd';
import { filterOption } from '../../../../../../utils/libs';
import GENERAL from '../../../../../../utils/constants/general';
//  Styles
import '../../../index.css';

const { DIVISION_ID } = GENERAL;
const { Option } = Select;

export default function CreatePaymentModal(props) {
	const {
		receivers,
		createPaymentModal,
		paycutId,
		profile,
		mutate1ObjectInBillings,
		createPayment,
	} = props;
	const filteredReceivers = receivers.filter(
		receiver =>
			receiver.division_id === createPaymentModal.division_id &&
			receiver.id !== profile.entity.id,
	);

	return (
		<div className='modal-container'>
			<div className='create-article-form-items'>
				<Select
					className='form-item-standard create-article-form-items-select'
					showSearch
					placeholder='División*'
					value={createPaymentModal.division_id}
					filterOption={filterOption}
					onChange={value =>
						mutate1ObjectInBillings('createPaymentModal', {
							division_id: value,
							paid_to: undefined,
							signer: undefined,
						})
					}
				>
					<Option value={DIVISION_ID.ENTITY}>
						<em>Entidad</em>
					</Option>
					<Option value={DIVISION_ID.EXP}>
						<em>Técnico</em>
					</Option>
				</Select>

				<Select
					className='form-item-standard create-article-form-items-select'
					showSearch
					placeholder='Destinatario*'
					value={createPaymentModal.paid_to}
					filterOption={filterOption}
					onChange={value =>
						mutate1ObjectInBillings('createPaymentModal', {
							paid_to: value,
							signer: undefined,
						})
					}
				>
					{filteredReceivers.map((receiver, idx) => (
						<Option key={idx} value={receiver.id}>
							<em>{receiver.name}</em>
						</Option>
					))}
				</Select>

				<div className='form-action-button'>
					<Button
						className='button-style-1'
						onClick={() =>
							createPayment(paycutId, createPaymentModal, 'payment')
						}
					>
						Crear
					</Button>
				</div>
			</div>
		</div>
	);
}
