// Libs
import React from 'react';
// Components
import TaskPicker from './TaskPicker';
import ReviewPicker from './ReviewPicker.jsx';
import GroupPicker from './GroupPicker';
import SubgroupPicker from './SubgroupPicker';
// Styles
import styles from './Menu.module.css';

function PhotoReportMenu({
	isMobile,
	width = '100%',
	photoReport,
	selectedIds,
	onMenuItemClick,
}) {
	return (
		<div
			style={{ width }}
			className={
				isMobile ? styles.mobileMenuContainer : styles.desktopMenuContainer
			}
		>
			<TaskPicker
				photoReport={photoReport}
				selectedIds={selectedIds}
				onMenuItemClick={onMenuItemClick}
			/>
			<ReviewPicker
				photoReport={photoReport}
				selectedIds={selectedIds}
				onMenuItemClick={onMenuItemClick}
			/>
			<GroupPicker
				photoReport={photoReport}
				selectedIds={selectedIds}
				onMenuItemClick={onMenuItemClick}
			/>
			<SubgroupPicker
				photoReport={photoReport}
				selectedIds={selectedIds}
				onMenuItemClick={onMenuItemClick}
			/>
		</div>
	);
}

export default PhotoReportMenu;
