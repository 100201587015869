// Libs
import React, { useState, useEffect, useMemo } from 'react';
// Hooks
import { useCollectorManager } from 'hooks';
// Components
import {
	Wrapper,
	RadioV2,
	Span,
	Section,
	SectionTitle,
	LogoIcon,
} from 'components';

const styles = {
	container: {
		padding: '0',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'stretch',
		overflow: 'auto',
		maxHeight: '250px',
	},
	item: {
		padding: '0',
		margin: '0 0 5px 0',
	},
};

const addUnsubgrouped = data =>
	[{ id: null, name: 'Desagrupados' }].concat(data);

const SubgroupItem = React.memo(
	({ style, subgroup, selectedSubgroupId, onClick }) => {
		// Is selected
		const isSelected = useMemo(
			() => subgroup.id === selectedSubgroupId,
			[selectedSubgroupId],
		);

		return (
			<Wrapper style={style}>
				<Wrapper
					padding='0'
					width='100%'
					height='20px'
					justifyContent='stretch'
					alignItems='center'
					overflow='hidden'
				>
					<RadioV2 isSelected={isSelected} />
					<Span
						ellipsis
						width='100%'
						hoverable
						selected={isSelected}
						onClick={onClick}
					>
						{subgroup.name}
					</Span>
				</Wrapper>
			</Wrapper>
		);
	},
);

const SubgroupPicker = ({ photoReport, selectedIds, onMenuItemClick }) => {
	const { id: otdId, templateId, serviceId } = photoReport;
	const {
		selectedTaskId,
		selectedReviewId,
		selectedGroupId,
		selectedSubgroupId,
	} = selectedIds;
	const { getSubgroupLayout } = useCollectorManager();
	const [subgroups, _setSubgroups] = useState({
		isFetching: false,
		data: [],
	});
	const setSubgroups = newProps =>
		_setSubgroups(current => ({ ...current, ...newProps }));

	// Clear data
	useEffect(() => {
		if (selectedSubgroupId === undefined) setSubgroups({ data: [] });
	}, [selectedSubgroupId]);

	// Get subgroups data
	useEffect(() => {
		let isMounted = true;
		if (!selectedTaskId || !selectedReviewId || selectedGroupId === undefined)
			return;
		setSubgroups({ isFetching: true });
		if (!selectedGroupId) {
			setSubgroups({
				data: addUnsubgrouped([]),
				isFetching: false,
			});
		} else {
			getSubgroupLayout({
				otdId,
				templateId,
				serviceId,
				serviceTaskId: selectedTaskId,
				reviewId: selectedReviewId,
				groupId: selectedGroupId,
				filled: true, // TODO: Quitar hardcoded
			}).then(
				data =>
					isMounted &&
					setSubgroups({
						data,
						isFetching: false,
					}),
			);
		}
		return () => {
			isMounted = false;
		};
	}, [selectedGroupId]);

	// Autoselect first subgroup
	useEffect(() => {
		const firstSubgroup = subgroups.data[0];
		if (subgroups.isFetching || !firstSubgroup) return;
		onMenuItemClick({
			selectedSubgroupId: firstSubgroup.id,
			isCollectorFetching: true,
		});
	}, [subgroups.data, subgroups.isFetching]);

	// Memoized subgroup data
	const subgroupData = useMemo(
		() =>
			subgroups.data.map(subgroup => (
				<SubgroupItem
					key={subgroup.id}
					subgroup={subgroup}
					selectedSubgroupId={selectedSubgroupId}
					style={styles.item}
					onClick={() =>
						onMenuItemClick({
							selectedSubgroupId: subgroup.id,
							isCollectorFetching: true,
						})
					}
				/>
			)),
		[subgroups.data, selectedSubgroupId],
	);

	return (
		<Section>
			<SectionTitle title='Subgrupo' empty={!subgroups.data[0]} />
			<Wrapper {...styles.container}>
				{subgroups.isFetching ? <LogoIcon spin={true} /> : subgroupData}
			</Wrapper>
		</Section>
	);
};

export default SubgroupPicker;
