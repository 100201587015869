import { useState } from 'react';
import { fetchMaterials as fetchMaterialsService } from '../services/inventoryService';

export const useInventory = initialInventory => {
	const [data, setData] = useState(initialInventory);
	const [editingKey, setEditingKey] = useState('');
	const [materials, setMaterials] = useState([]);
	const [targetKeys, setTargetKeys] = useState([]);
	const [showTransfer, setShowTransfer] = useState(false);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
	const [paginationTransfer, setPaginationTransfer] = useState({
		current: 1,
		pageSize: 10,
	});

	const fetchMaterials = async () => {
		await fetchMaterialsService(setMaterials);
	};

	return {
		data,
		setData,
		editingKey,
		setEditingKey,
		materials,
		setMaterials,
		targetKeys,
		setTargetKeys,
		showTransfer,
		setShowTransfer,
		pagination,
		setPagination,
		paginationTransfer,
		setPaginationTransfer,
		fetchMaterials,
	};
};
