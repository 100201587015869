// Libs
import React, { useEffect, useState, useMemo } from 'react';
// Hooks
import { useCollectorManager } from 'hooks';
// Components
import {
	Wrapper,
	RadioV2,
	Span,
	Section,
	SectionTitle,
	LogoIcon,
} from 'components';

const styles = {
	container: {
		padding: '0',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'stretch',
		overflow: 'auto',
		maxHeight: '250px',
	},
	item: {
		padding: '0',
		margin: '0 0 5px 0',
	},
};

const TaskItem = React.memo(({ style, task, selectedTaskId, onClick }) => {
	// Is selected
	const isSelected = React.useMemo(
		() => task.id === selectedTaskId,
		[selectedTaskId],
	);

	return (
		<Wrapper style={style}>
			<Wrapper
				padding='0'
				width='100%'
				height='20px'
				justifyContent='stretch'
				alignItems='center'
				overflow='hidden'
			>
				<RadioV2 isSelected={isSelected} />
				<Span
					ellipsis
					width='100%'
					hoverable
					selected={isSelected}
					onClick={onClick}
				>
					{task.name}
				</Span>
			</Wrapper>
		</Wrapper>
	);
});

const TaskPicker = ({ photoReport, selectedIds, onMenuItemClick }) => {
	const { id: otdId, templateId, serviceId } = photoReport;
	const { selectedTaskId } = selectedIds;
	const { getTaskLayout } = useCollectorManager();
	const [tasks, _setTasks] = useState({
		isFetching: false,
		data: [],
	});
	const setTasks = newProps =>
		_setTasks(current => ({ ...current, ...newProps }));

	// Get tasks data
	useEffect(() => {
		let isMounted = true;
		setTasks({ isFetching: true });
		getTaskLayout({
			otdId,
			templateId,
			serviceId,
			filled: true, // TODO: Quitar hardcoded
		}).then(data => isMounted && setTasks({ data, isFetching: false }));
		return () => {
			isMounted = false;
		};
	}, [otdId]);

	// Autoselect first task
	useEffect(() => {
		const firstTask = tasks.data[0];
		if (tasks.isFetching || !firstTask) return;
		onMenuItemClick({
			selectedTaskId: firstTask.id,
			selectedReviewId: undefined,
			selectedGroupId: undefined,
			selectedSubgroupId: undefined,
		});
	}, [tasks.data, tasks.isFetching]);

	// Memoized task data
	const taskData = useMemo(
		() =>
			tasks.data.map(task => (
				<TaskItem
					key={task.id}
					task={task}
					selectedTaskId={selectedTaskId}
					style={styles.item}
					onClick={() =>
						onMenuItemClick({
							selectedTaskId: task.id,
							selectedReviewId: undefined,
							selectedGroupId: undefined,
							selectedSubgroupId: undefined,
						})
					}
				/>
			)),
		[tasks.data, selectedTaskId],
	);

	return (
		<Section>
			<SectionTitle title='Tarea' empty={!tasks.data.length} />
			<Wrapper {...styles.container}>
				{tasks.isFetching ? <LogoIcon spin={true} /> : taskData}
			</Wrapper>
		</Section>
	);
};

export default TaskPicker;
