// Libs
import React from 'react';
import { useTheme } from 'styled-components';
import { connect } from 'react-redux';
// Selectors
import {
	selectReportList,
	selectGenerateReportModal,
	selectIsFetching,
} from './reports.selectors';
import { selectMyProfile, selectAllUsers } from 'screens/Users/selectors';
import { selectModeView } from 'screens/Dashboard/selectors';
// Actions
import {
	mutate1Object as mutate1ObjectInReports,
	fetchReports,
	resetGenerateReportModal,
} from './reports.actions';
// Utils
import { getMyEntityActiveUsers } from 'utils/libs/filterUsers';
import REPORT_KEYWORDS from './keywords';
// Components
import { Wrapper, Grid, Span, Card, Empty, LogoIcon } from 'components';
import ModalReports from './Modals';

const { REPORT_TABLES } = REPORT_KEYWORDS;

const Container = ({ children, justifyContent }) => (
	<Wrapper padding='0' className='animated fadeIn'>
		<Wrapper
			width='100%'
			height={`${document.documentElement.clientHeight - 56}px`}
			highBackground
			justifyContent={justifyContent || 'center'}
			alignItems='flex-start'
			overflow='auto'
		>
			{children}
		</Wrapper>
	</Wrapper>
);

const Content = ({
	modeView,
	reports,
	users,
	theme,
	mutate1ObjectInReports,
	generateReportModal,
	resetGenerateReportModal,
}) => (
	<>
		<Grid
			columns='repeat(auto-fit, minmax(258px, 300px))'
			width={modeView === 'table' ? '100%' : undefined}
			justifyContent={modeView === 'table' ? 'flex-start' : 'center'}
			gap='.5em'
		>
			{reports.map((report, idx) => {
				return (
					<Card
						key={idx}
						title={report.name}
						shadow1
						actions={[
							<Span
								cursor='pointer'
								hoverColor={theme.fonts.colors.emphasis_1}
								onClick={() =>
									mutate1ObjectInReports('generateReportModal', {
										isOpen: true,
										reportId: report.id,
										reportTable: REPORT_TABLES.REPORT,
									})
								}
							>
								Generar Reporte
							</Span>,
						]}
					/>
				);
			})}
		</Grid>
		<ModalReports
			reports={reports}
			generateReportModal={generateReportModal}
			users={users}
			mutate1ObjectInReports={mutate1ObjectInReports}
			resetGenerateReportModal={resetGenerateReportModal}
		/>
	</>
);

const Reports = ({
	// State
	modeView,
	isFetching,
	reports,
	users,
	generateReportModal,
	// Actions
	fetchReports,
	resetGenerateReportModal,
	mutate1ObjectInReports,
}) => {
	const theme = useTheme();
	React.useEffect(() => {
		fetchReports();
	}, []);

	if (isFetching) {
		return (
			<Container justifyContent='center'>
				<LogoIcon margin='20px 0 0 0' spin={true} size='m' />
			</Container>
		);
	}

	if (!isFetching && reports.length === 0) {
		return (
			<Container justifyContent='center'>
				<Empty
					margin='20px 0 0 0'
					image={Empty.PRESENTED_IMAGE_SIMPLE}
					description='No tiene reportes asignados'
				/>
			</Container>
		);
	}
	return (
		<Container>
			<Content
				modeView={modeView}
				reports={reports}
				users={users}
				theme={theme}
				mutate1ObjectInReports={mutate1ObjectInReports}
				generateReportModal={generateReportModal}
				resetGenerateReportModal={resetGenerateReportModal}
			/>
		</Container>
	);
};

const mapStateToProps = state => ({
	modeView: selectModeView(state),
	isFetching: selectIsFetching(state),
	reports: selectReportList(state),
	users: getMyEntityActiveUsers(selectAllUsers(state), selectMyProfile(state)),
	generateReportModal: selectGenerateReportModal(state),
});

const actions = {
	fetchReports,
	resetGenerateReportModal,
	mutate1ObjectInReports,
};

export default connect(mapStateToProps, actions)(Reports);
