// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M4 5V11H21V5H4ZM4 18H21V12H4V18Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
