// Libs
import React from 'react';
// Components
import { Wrapper, InBuilding } from 'components';

export default function ZonesNotFoundMobile(props) {
	return (
		<Wrapper>
			<InBuilding />
		</Wrapper>
	);
}
