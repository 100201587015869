//  Libs
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

//  Styles
import './index.css';

const styles = {
	card: {
		borderRadius: '20px',
		maxWidth: 350,
		height: 'max-content',
	},
	media: {
		height: 140,
		width: 350,
	},
	cardactions: {
		width: '100%',
		height: '100%',
	},
};

function MediaCard(props) {
	const { classes, summary = {}, apiSummary = {} } = props;
	return (
		<Card className={classes.card}>
			<CardActionArea>
				<CardMedia
					className={classes.media}
					image={props.image}
					title='Resumen de ingreso'
				/>
				<CardContent className='card-content'>
					<div className='card-head-div-lectura'>
						<label className='card-head-title'>Lectura</label>
						{Object.getOwnPropertyNames(summary).map((prop, idx) => (
							<div className='card-item-div' key={idx}>
								<label className='card-item-text' key={idx}>
									{prop}
								</label>{' '}
								<label className='card-item-text'>{summary[prop]}</label>
							</div>
						))}
					</div>
					<div className='card-head-div-recibidas'>
						<label className='card-head-title'>Recibidas</label>
						{apiSummary.receivedOrdersDetails
							? Object.getOwnPropertyNames(
									apiSummary.receivedOrdersDetails,
							  ).map((prop, idx) => (
									<div className='card-item-div' key={idx}>
										<label className='card-item-text' key={idx}>
											{prop}
										</label>{' '}
										<label className='card-item-text'>
											{apiSummary.receivedOrdersDetails[prop]}
										</label>
									</div>
							  ))
							: null}
					</div>
					<div className='card-head-div-procesadas'>
						<label className='card-head-title'>Procesadas</label>
						{apiSummary.processedOrdersDetails
							? Object.getOwnPropertyNames(
									apiSummary.processedOrdersDetails,
							  ).map((prop, idx) => (
									<div className='card-item-div' key={idx}>
										<label className='card-item-text' key={idx}>
											{prop}
										</label>{' '}
										<label className='card-item-text'>
											{apiSummary.processedOrdersDetails[prop]}
										</label>
									</div>
							  ))
							: null}
					</div>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

MediaCard.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MediaCard);
