//Libs
import React, { useState } from 'react';
//Utils
import { getHumanize } from 'utils/libs/dateFormats';
import KEYWORDS from 'components/Collector/keywords';
//Hooks
import { useTheme } from 'hooks';
//Components
import { PDFIcon, OpenInNewIcon, RefreshIcon } from 'components/Icons';
//Styles
import styles from './ReportCard.module.css';
import ApiService from 'services/apiService/apiService';

const ReportName = ({ name }) => (
	<span
		style={{
			display: 'block',
			fontWeight: 'bold',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			maxWidth: '250px',
		}}
	>
		{name}
	</span>
);

const Date = ({ date }) => {
	const { theme } = useTheme();

	return (
		<span style={{ color: theme._primaryColor, fontSize: 'small' }}>
			{getHumanize({ date })}
		</span>
	);
};

/**
 * @param {int} otdId - Order transaction doc ID
 * @param {boolean} isMergePdf - Show if can merge PDF
 * @param {Object} reportName - Name of the report
 * @returns
 */
const IconReport = ({ reportName }) => {
	const { theme } = useTheme();
	return (
		<div className={styles.sectionIcons}>
			<div
				style={{
					display: 'inline-flex',
					justifyContent: 'center',
					width: '10%',
				}}
			>
				<PDFIcon />
			</div>
			<div style={{ color: theme._primaryColor, paddingLeft: '10px' }}>
				<ReportName name={reportName} />
			</div>
		</div>
	);
};

/**
 * @param {Object} report - Service task report
 * @param {int} otdId - Order transaction doc ID
 * @param {string} odtId - External & Presentational order to work ID
 * @param {function} sendToast - Show message to user action
 * @returns
 */
const ActionButtons = ({ report, otdId, odtId, sendToast }) => {
	const { id: reportId, updatedAt, pdfReportUrl } = report;

	const handleCreateReport = () => {
		ApiService.fetch(ApiService.endpoints().GENERATE_REPORT, {
			bodyParams: {
				odtId,
				otdId,
				serviceTaskId: reportId,
				format: KEYWORDS.REPORTS.OUTPUT_FORMATS.PDF,
			},
		}).then(() =>
			sendToast({
				message:
					'Solicitud recibida... Cuando esté listo, encontrará el reporte en la bandeja de notificaciones',
				duration: 8,
			}),
		);
	};
	return (
		<div className={styles.actionButtons}>
			{updatedAt && (
				<div className={styles.dateWrapper}>
					<Date date={updatedAt} />
				</div>
			)}
			{pdfReportUrl && (
				<a href={pdfReportUrl} target='_blank'>
					<div className={styles.iconWrapper}>
						<OpenInNewIcon />
					</div>
				</a>
			)}
			<div className={styles.iconWrapper}>
				<RefreshIcon button onClick={handleCreateReport} />
			</div>
		</div>
	);
};

/**
 * @param {Object} report - Service task report
 * @param {int} otdId - Order transaction doc ID
 * @param {string} odtId - External & Presentational order to work ID
 * @param {function} sendToast - Show message to user action
 * @returns
 */
const ReportCard = ({ report, otdId, odtId, sendToast }) => {
	const [isHovered, setIsHovered] = useState(false);
	const { theme } = useTheme();

	return (
		<div
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			style={{
				backgroundColor: isHovered
					? theme._secondaryBackgroundColor
					: theme._primaryBackgroundColor,
			}}
			className={styles.postWrapper}
		>
			<IconReport reportName={report.name} />

			<ActionButtons
				report={report}
				otdId={otdId}
				odtId={odtId}
				sendToast={sendToast}
			/>
		</div>
	);
};

export default ReportCard;
