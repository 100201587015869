// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12.9183 11.6667H12.2567L12.0267 11.4383C12.8392 10.4908 13.3333 9.2625 13.3333 7.91667C13.3333 4.925 10.9083 2.5 7.91667 2.5C4.925 2.5 2.5 4.925 2.5 7.91667C2.5 10.9083 4.925 13.3333 7.91667 13.3333C9.2625 13.3333 10.49 12.84 11.4375 12.0283L11.6675 12.2567V12.9167L15.8325 17.0758L17.075 15.8333L12.9183 11.6667ZM7.91667 11.6667C5.845 11.6667 4.16667 9.98834 4.16667 7.91667C4.16667 5.84584 5.845 4.16667 7.91667 4.16667C9.9875 4.16667 11.6667 5.84584 11.6667 7.91667C11.6667 9.98834 9.9875 11.6667 7.91667 11.6667Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
