// Libs
import React, {
	useState,
	useRef,
	useCallback,
	useContext,
	useEffect,
} from 'react';
// Services
import { SentryService } from 'services';
// Context
import { PhotoContext } from 'context';
// Utils
import { PhotonImage } from 'utils/libs';
import Watermark from '../Watermark';
// Components
import { Wrapper } from 'components';
import CloseButton from '../CloseButton';
import VideoControl from './VideoControl';
import VideoStream from './VideoStream';

let lastVideoRefreshTime = 0;
const videoRefreshInterval = 1000 / 30; // intervalo de tiempo en milisegundos para 30 fps

const Capturing = ({
	videoSettings,
	videoPreviewSettings,
	onTakenPhotoConfirm,
	onControlPanelOpen,
	onPhotoManagerClose,
	profile,
}) => {
	const {
		isPhotoTaken,
		deviceId,
		orientation,
		setSetting: setPhotoManagerSetting,
		onManualOrientationToggle,
		clientLogo,
	} = useContext(PhotoContext);

	const [state, _setState] = useState({
		canPlay: false,
		confirming: false,
	});
	const setState = newState => _setState(prev => ({ ...prev, ...newState }));

	const videoRef = useRef();
	const previewVideoCanvasRef = useRef();
	const previewPhotoCanvasCtxRef = useRef();
	const takenPhotoCanvasRef = useRef();
	const takenPhotoImgRef = useRef();
	const scaledPreviewDimsRef = useRef();
	const animationIdRef = useRef();
	const currentStream = useRef();

	const scaledVideoIntoPreviewCanvas = () => {
		const previewCanvasWidth = previewVideoCanvasRef.current.width;
		const previewCanvasHeight = previewVideoCanvasRef.current.height;
		const videoWidth = videoRef.current.videoWidth;
		const videoHeight = videoRef.current.videoHeight;
		const ratio = Math.min(
			previewCanvasWidth / videoWidth,
			previewCanvasHeight / videoHeight,
		);
		const scaledWidth = videoWidth * ratio;
		const scaledHeight = videoHeight * ratio;
		return {
			scaledWidth: scaledWidth + (previewCanvasWidth - scaledWidth),
			scaledHeight: scaledHeight + (previewCanvasHeight - scaledHeight),
		};
	};

	const startProcessCamera = () => {
		if (!videoRef.current || !previewPhotoCanvasCtxRef.current) return;
		const currentTime = new Date();
		const timeSinceLastExecution = currentTime - lastVideoRefreshTime;

		if (timeSinceLastExecution >= videoRefreshInterval) {
			previewPhotoCanvasCtxRef.current.drawImage(
				videoRef.current,
				0,
				0,
				scaledPreviewDimsRef.current.scaledWidth,
				scaledPreviewDimsRef.current.scaledHeight,
			);
			lastVideoRefreshTime = currentTime;
		}

		animationIdRef.current = window.requestAnimationFrame(startProcessCamera);
	};

	const stopProcessCamera = () => {
		if (currentStream?.current?.active)
			currentStream.current.getTracks().forEach(track => track.stop());
		if (animationIdRef.current)
			window.cancelAnimationFrame(animationIdRef.current);
	};

	const showCamera = useCallback(
		() =>
			navigator.mediaDevices
				.getUserMedia({
					video: {
						deviceId,
						facingMode: { exact: 'environment' },
						width: {
							min: 640,
							ideal: videoSettings.width || 1280,
							max: videoSettings.width || 1920,
						},
						height: {
							min: 480,
							ideal: videoSettings.height || 720,
							max: videoSettings.height || 1080,
						},
					},
				})
				.then(stream => {
					currentStream.current = stream;
					videoRef.current.srcObject = currentStream.current;
					videoRef.current.onloadedmetadata = function (e) {
						// Get preview canvas context
						previewPhotoCanvasCtxRef.current =
							previewVideoCanvasRef.current.getContext('2d');
						//Scaled photo to put into preview canvas
						scaledPreviewDimsRef.current = scaledVideoIntoPreviewCanvas();
						//Init video process
						videoRef.current.play();
						startProcessCamera();
					};
				})
				.catch(SentryService.sendError),
		[deviceId, videoSettings.width, videoSettings.height],
	);

	const getVideoSettings = async () => {
		//Check video canvas ref
		if (!videoRef?.current) return {};
		const track = videoRef.current.srcObject?.getTracks()[0];
		if (track?.getSettings) return track.getSettings();
		await new Promise(resolve => (videoRef.current.onloadedmetadata = resolve));
		return {
			width: videoRef.current.videoWidth,
			height: videoRef.current.videoHeight,
		};
	};

	const onVideoHasDimensions = async () => {
		let { width, height } = await getVideoSettings();
		while (!width || !height) {
			await new Promise(resolve => setTimeout(resolve, 150));
			({ width, height } = await getVideoSettings());
		}
		return { width, height };
	};

	const takePhoto = useCallback(
		coords => {
			(async () => {
				try {
					// Check video ref
					if (!videoRef?.current || !takenPhotoImgRef?.current) return;
					// Get video width/height settings
					const { width, height } = await getVideoSettings();
					if (!width || !height) return;

					//Update state
					setPhotoManagerSetting('isPhotoTaken', true);

					// Resize canvas and draw image
					takenPhotoCanvasRef.current = document.createElement('canvas');
					const takenPhotoCtx = takenPhotoCanvasRef.current.getContext('2d');
					takenPhotoCtx.canvas.width = width;
					takenPhotoCtx.canvas.height = height;
					takenPhotoCtx.drawImage(videoRef.current, 0, 0);
					if (orientation === 'landscape' && width < height) {
						await PhotonImage.getInstance().then(photon =>
							photon.rotate(takenPhotoCanvasRef.current, takenPhotoCtx, {
								width,
								height,
							}),
						);
					}

					//Set preview taken photo to image element
					takenPhotoImgRef.current.src =
						takenPhotoCanvasRef.current.toDataURL();

					let _takenPhotoImgRef = takenPhotoImgRef.current;
					new Promise(async resolve => {
						try {
							await new Promise(res => setTimeout(res, 50));
							_takenPhotoImgRef.src = await Watermark.addWatermarkText({
								canvas: takenPhotoCanvasRef.current,
								coords,
								profile,
								clientLogo,
							});
							takenPhotoImgRef.current = _takenPhotoImgRef;
						} catch (err) {
							SentryService.sendError(err);
						} finally {
							_takenPhotoImgRef = null;
							resolve();
						}
					});
				} catch (err) {
					setPhotoManagerSetting('isPhotoTaken', false);
					SentryService.sendError(err);
				}
			})();
		},
		[orientation, profile?.user?.id],
	);

	const onTakenPhotoConfirmHandler = useCallback(coords => {
		setState({ confirming: true });
		clearTimeout(window.takenPhotoConfirmT1);
		window.takenPhotoConfirmT1 = setTimeout(
			() =>
				onTakenPhotoConfirm({
					originalPhoto: takenPhotoCanvasRef.current,
					watermarkedPhoto: takenPhotoImgRef.current.src,
					coords,
					onError: () => {
						setState({ confirming: false });
						setPhotoManagerSetting('isPhotoTaken', false);
					},
				}),
			100,
		);
	}, []);

	//Reset PhotoContext state
	useEffect(() => {
		return () => {
			setPhotoManagerSetting('isPhotoTaken', false);
			clearTimeout(window.takenPhotoConfirmT1);
		};
	}, []);

	//Auto show camera
	useEffect(() => {
		if (!isPhotoTaken && videoRef.current && previewVideoCanvasRef.current)
			showCamera();
		return () => {
			stopProcessCamera();
		};
	}, [deviceId, orientation, isPhotoTaken]);

	//Play/Pause
	useEffect(() => {
		if (!videoRef.current) return;
		if (isPhotoTaken) videoRef.current.pause();
		else {
			videoRef.current.play();
			takenPhotoImgRef.current.src = null;
		}
	}, [isPhotoTaken]);

	//Check CanPlay video
	useEffect(() => {
		let isMounted = true;
		if (!videoRef.current) return;
		videoRef.current.oncanplay = function () {
			if (!isMounted) return;
			onVideoHasDimensions().then(
				() => isMounted && setState({ canPlay: true }),
			);
		};
		videoRef.current.onplay = function () {
			if (!isMounted) return;
			onVideoHasDimensions().then(
				() => isMounted && setState({ canPlay: true }),
			);
		};
		videoRef.current.onpause = function () {
			if (!isMounted) return;
			setState({ canPlay: false });
		};
		return () => {
			isMounted = false;
		};
	}, []);

	const takenPhotoConfirmationHeight = 50;
	return (
		<Wrapper padding='0' flexDirection='column' position='relative'>
			<CloseButton onClose={onPhotoManagerClose} />
			<VideoStream
				videoRef={videoRef}
				previewVideoCanvasRef={previewVideoCanvasRef}
				takenPhotoConfirmationHeight={takenPhotoConfirmationHeight}
				takenPhotoImgRef={takenPhotoImgRef}
				videoPreviewSettings={videoPreviewSettings}
				isPhotoTaken={isPhotoTaken}
			/>
			<VideoControl
				isPhotoTaken={isPhotoTaken}
				takenPhotoConfirmationHeight={takenPhotoConfirmationHeight}
				profile={profile}
				orientation={orientation}
				canPlay={state.canPlay}
				confirming={state.confirming}
				takePhoto={takePhoto}
				onTakenPhotoConfirm={onTakenPhotoConfirmHandler}
				onTakenPhotoCancel={() => setPhotoManagerSetting('isPhotoTaken', false)}
				onControlPanelOpen={onControlPanelOpen}
				onManualOrientationToggle={onManualOrientationToggle}
			/>
		</Wrapper>
	);
};

export default Capturing;
