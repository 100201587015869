// Libs
import React from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import IconButton from '@mui/material/IconButton';
import NormalIcon from './NormalIcon';
import HorizontalNormalIcon from './HorizontalNormalIcon';

const ICONS = {
	normal: NormalIcon,
};
const HORIZONTAL_ICONS = {
	normal: HorizontalNormalIcon,
};
const ICON_BUTTONS = {};

const SelectedIcon = ({ size, fill, button, horizontal }) => {
	if (button) var _Icon = ICON_BUTTONS[size] || ICON_BUTTONS.normal;
	if (horizontal) var _Icon = HORIZONTAL_ICONS[size] || HORIZONTAL_ICONS.normal;
	else var _Icon = ICONS[size] || ICONS.normal;
	return <_Icon fill={fill} />;
};

const MoreIcon = ({ hoverable, size, selected, button, horizontal }) => {
	const { theme } = useTheme();
	const [hover, setHover] = React.useState(false);

	const fill = React.useMemo(
		() =>
			hoverable && (hover || selected)
				? theme._secondaryColor
				: theme._inactiveColor,
		[hover, selected],
	);

	return (
		<div
			onMouseEnter={hoverable && (() => setHover(true))}
			onMouseLeave={hoverable && (() => setHover(false))}
			style={{ cursor: hoverable && 'pointer' }}
		>
			{button ? (
				<IconButton
					style={{
						width: button ? '40px' : '24px',
						height: button ? '40px' : '24px',
					}}
				>
					<SelectedIcon
						size={size}
						fill={fill}
						button={button}
						horizontal={horizontal}
					/>
				</IconButton>
			) : (
				<SelectedIcon size={size} fill={fill} horizontal={horizontal} />
			)}
		</div>
	);
};

export default MoreIcon;
