// Libs
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
// Utils
import GENERAL from 'utils/constants/general';
import PaymentsAnalytics from '../Analytics';

const { ENV } = GENERAL;

function Payments(props) {
	const {
		routes,
		history,
		modeView,
		getPaycuts,
		getPaycutsModal,
		mutate1Object,
		resetGetPaycuts,
		doResetApiData,
		payments,
		paycuts,
		pays,
		orders,
		lines,
		getPayments,
		coins,
	} = props;

	useEffect(() => {
		resetGetPaycuts();
		doResetApiData('PAYMENTS');
		mutate1Object('getPaycutsModal', { type: 'payment' });
		getPaycuts({ ...getPaycutsModal, type: 'payment' });
	}, []);

	if (
		history.location.pathname === ENV.ROUTES.PATHS.BILLINGS_PAYMENTS &&
		modeView === 'cards'
	)
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_PAYCUTS_MOBILE} />;
	else if (
		history.location.pathname === ENV.ROUTES.PATHS.BILLINGS_PAYMENTS &&
		modeView === 'table'
	)
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_PAYCUTS_DESKTOP} />;

	return (
		<>
			{routes.map((route, i) => {
				return (
					<Route
						key={i}
						path={route.path}
						render={oProps => (
							<route.component {...oProps} {...props} routes={route.routes} />
						)}
					/>
				);
			})}
			{/* ANALYTICS */}
			{(paycuts.analytics.isOpen ||
				pays.analytics.isOpen ||
				orders.analytics.isOpen ||
				lines.analytics.isOpen) && (
				<PaymentsAnalytics
					paycutsAnalytics={paycuts.analytics}
					paymentsAnalytics={pays.analytics}
					ordersAnalytics={orders.analytics}
					linesAnalytics={lines.analytics}
					paycuts={payments.paycuts}
					payments={payments.pays}
					orders={payments.orders}
					lines={payments.lines}
					mutate1Object={mutate1Object}
					getPayments={getPayments}
					coins={coins.data}
				/>
			)}
		</>
	);
}

export default Payments;
