// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
// Utils
import { filterPaycuts } from 'utils/filters/Billings/paycuts';
import GENERAL from 'utils/constants/general';
// Components
import { VirtualizedList } from 'screens/Utils';
import { Wrapper, Empty } from 'components';

const { ENV } = GENERAL;

function PaycutsMobile(props) {
	if (
		!props?.profile?.entity?.id ||
		!props?.profile?.organization?.settings?.mainEntity ||
		props?.profile?.entity?.id !==
			props?.profile?.organization?.settings?.mainEntity
	) {
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYS_MOBILE} />;
	}

	const {
		coins,
		payments,
		paycuts,
		mutate1Object,
		getInvoicePays,
		profile,
		getPaycutResume,
		getPaycutsModal,
		getPaycutsResumeModal,
	} = props;
	const allPaycuts = payments.paycuts.data;
	const filteredPaycuts = filterPaycuts(allPaycuts, paycuts.filters);

	return (
		<Wrapper padding='8px' justifyContent='center' className='animated fadeIn'>
			{!payments.paycuts.loading && filteredPaycuts.length === 0 ? (
				<Empty style={{ marginTop: '20px' }} />
			) : (
				<VirtualizedList
					cardId='invoicePaycutCard'
					listStyle={{
						height: document.documentElement.clientHeight,
						width: document.documentElement.clientWidth,
					}}
					rowHeight={220}
					spinTitle='Consultando Cortes...'
					isSpinning={payments.paycuts.loading}
					rows={filteredPaycuts}
					customProps={{
						paycutsManage: paycuts,
						getPaycutsResumeModal,
						profile,
						coins: coins.data,
						getInvoicePays,
						getPaycutsModal,
						getPaycutResume,
						mutate1ObjectInBillings: mutate1Object,
					}}
				/>
			)}
		</Wrapper>
	);
}

export default PaycutsMobile;
