// Libs
import React, { useState } from 'react';
// Components
import { Avatar, Comment, ShowMoreText } from 'components';
// Icons
import { LoadingOutlined } from '@ant-design/icons';
// Utils
import { CloudStorage } from 'components/AppConfig.json';
import { getHumanize } from 'utils/libs/dateFormats';
// Hooks
import { useTheme } from 'hooks';
// Components
import AddReply from './AddReply';
// CSS
import styles from './CommentsBox.module.css';

const CommentWithReplies = ({
	comment,
	onFetchReplies,
	noReplies,
	profileUserId,
	submitReply,
	children,
}) => {
	const { theme } = useTheme();
	const [showReplyBox, setShowReplyBox] = useState(false);

	const [inputValue, setInputValue] = useState('');
	const handleChange = e => setInputValue(e.target.value);

	// DATA FETCHING
	const [isFetching, setIsFetching] = useState(false);
	const handleViewMoreComments = async () => {
		setIsFetching(true);
		await onFetchReplies(comment.id, comment?.replies?.at(-1)?.id);
		setIsFetching(false);
	};

	// SUBMISSION
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = async e => {
		if (e.target.value !== null && e.target.value !== undefined) {
			const value = e.target.value.trim();
			if (value !== '') {
				setIsSubmitting(true);
				await submitReply(comment.id, value);
				setIsSubmitting(false);
			}
		}
		setTimeout(() => setInputValue(''), 200);
	};

	const hasRepliesToFetch =
		comment?.replies?.length < Number(comment.totalRepliesCount) ||
		(Number(comment.totalRepliesCount) > 0 && !comment?.replies);

	const repliesLeftToFetchCount = hasRepliesToFetch
		? Number(comment.totalRepliesCount) - (comment?.replies?.length ?? 0)
		: 0;

	return (
		<Comment
			author={comment.createdName}
			datetime={getHumanize({ date: comment.updatedAt })}
			content={
				<ShowMoreText
					lines={1}
					hoverable={true}
					onContentClick={() => navigator.clipboard.writeText(comment.message)}
				>
					{comment.message}
				</ShowMoreText>
			}
			actions={
				!noReplies && [
					<a
						style={{ color: theme._primaryColor }}
						className={styles.commentAction}
						onClick={() => setShowReplyBox(true)}
					>
						Responder
					</a>,
				]
			}
			avatar={
				<Avatar
					size='normal'
					fit
					avatar={{
						src: CloudStorage.concat(
							`/syn4pse/users/avatars/${
								comment.createdBy ? `${comment.createdBy}.jpg` : `default.svg`
							}`,
						),
					}}
				/>
			}
		>
			{/* ADD REPLY */}
			{!noReplies && showReplyBox && (
				<AddReply
					userId={profileUserId}
					value={inputValue}
					onChange={handleChange}
					onSubmit={handleSubmit}
					submitting={isSubmitting}
				/>
			)}

			{/* REPLIES */}
			{children}
			{isFetching && !noReplies ? (
				<div className={styles.loadingWrapper}>
					<LoadingOutlined />
				</div>
			) : (
				hasRepliesToFetch &&
				!noReplies && (
					<div className={styles.viewMoreContainer}>
						<a
							style={{ color: theme._primaryColor }}
							className={styles.viewMoreLink}
							onClick={handleViewMoreComments}
						>
							Ver {repliesLeftToFetchCount} respuestas más
						</a>
					</div>
				)
			)}
		</Comment>
	);
};

export default CommentWithReplies;
