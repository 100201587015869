// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='radiobaseIcon16'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='0'
			y='0'
			width='16'
			height='16'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.33341 4V6H2.00008V3.33333H0.666748L4.33341 0L7.95501 3.29236L7.90666 3.33333H6.66675V4.38386L5.33342 5.51354V4H3.33341ZM11.3334 6.66667V6.88188L9.42527 5.24028L12.3334 2.66667L16.0001 6H14.6976V8.66667H13.4081L13.3334 8.60246V6.66667H11.3334ZM6.56008 12L6.66675 15.3333H4.00008V11.3322H2.00008L8.00008 6L14.0107 11.3322H12.0001V15.3333H9.33341V12H6.56008Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#radiobaseIcon16)'>
			<rect width='16' height='16' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
