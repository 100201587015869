import React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

const Item = React.memo(
	({ description = '', original, originalAlt = '', coords }) => {
		const itemSrc = original;
		return (
			<TransformWrapper>
				<TransformComponent
					wrapperStyle={{
						height: '100%',
						width: '100%',
						position: 'relative',
					}}
					contentStyle={{ width: '100%' }}
				>
					<img
						className='image-gallery-image'
						src={itemSrc}
						alt={originalAlt}
					/>
					{description && (
						<span className='image-gallery-description'>{description}</span>
					)}
				</TransformComponent>
			</TransformWrapper>
		);
	},
);

export default Item;
