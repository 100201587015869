import styled, { css } from 'styled-components';
// Hooks
import { useTheme } from 'hooks';
// components
import { Comment as _Comment } from 'antd';

const StyledComment = styled(_Comment)`
	span.ant-comment-content-author-name {
		color: ${({ $newTheme }) => $newTheme._primaryColor};
		font-weight: bold;
	}

	ul.ant-comment-actions {
		margin: 2px 0;
		padding-left: 8px;
	}

	div.ant-comment-content-author,
	div.ant-comment-content-detail {
		background-color: ${({ $newTheme }) => $newTheme._disabledBackgroundColor};
	}

	.ant-comment-avatar img {
		width: 100%;
		height: 100%;
	}

	&
		> .ant-comment-inner
		> .ant-comment-content
		> div.ant-comment-content-author {
		${({ $isReplyBox }) =>
			$isReplyBox
				? css`
						display: none;
				  `
				: css`
						border-radius: 15px 15px 0 0;
						margin: 0;
						padding: 3px 8px 3px 8px;
				  `}
	}

	&
		> .ant-comment-inner
		> .ant-comment-content
		> div.ant-comment-content-detail {
		${({ $isReplyBox }) =>
			$isReplyBox
				? css`
						border-radius: 15px;
				  `
				: css`
						border-radius: 0 0 15px 15px;
						padding: 0 8px 3px 8px;
				  `}
	}

	div.ant-comment-inner {
		padding: 5px 0;
	}

	p {
		margin: 0;
	}
`;

export default props => {
	const { theme } = useTheme();
	return (
		<StyledComment $newTheme={theme} {...props}>
			{props.children}
		</StyledComment>
	);
};
