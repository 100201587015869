// Libs
import { takeLatest, put, call, all } from 'redux-saga/effects';
import axios from 'axios';
import _ from 'underscore';
// Utils
import AuthService from 'utils/libs/auth/AuthService';
import GENERAL from 'utils/constants/general';
import API from 'utils/constants/api';
import asyncErrorsHandler from 'store/asyncErrorsHandler';

const { APIDATA, CLIENTS, ENV } = GENERAL;
const auth = new AuthService();

/** **** WATCHER SAGAS *******/
function* searchClientWatcher() {
	yield takeLatest(CLIENTS.SEARCH_CLIENT, searchClientWorker);
}
function* createClientWatcher() {
	yield takeLatest(CLIENTS.CREATE_CLIENT, createClientWorker);
}

/** **** WORKER SAGAS *******/
function* searchClientWorker(action) {
	const { value, lastRowId = 0, limit = 10 } = action.payload;
	if (!value) return null;

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'clients',
				keyValuePairs: {
					loading: true,
				},
			},
		});

		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat(
				`/clients/v1/searchClient/${value}/${lastRowId}/${limit}`,
			),
			auth.sendToken(),
		);

		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'clients',
				keyValuePairs: {
					loading: false,
					data,
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'clients',
						keyValuePairs: {
							loading: false,
						},
					},
				});
			},
			function* () {
				yield searchClientWorker(action);
			},
		);
	}
}

function* createClientWorker(action) {
	const client = action.payload;

	try {
		yield put({
			type: CLIENTS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createClient',
				keyValuePairs: { status: ENV.STATUS.LOADING },
			},
		});

		yield call(
			axios.post,
			API.DOMAIN.concat(`/clients/v1`),
			client,
			auth.sendToken(),
		);

		yield put({
			type: CLIENTS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createClient',
				keyValuePairs: { status: ENV.STATUS.SUCCESS },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: CLIENTS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'createClient',
						keyValuePairs: { status: ENV.STATUS.ERROR },
					},
				});
			},
			function* () {
				yield createClientWorker(action);
			},
		);
	}
}

/** **** EXPORT DEFAULT ROOT SAGA *******/
export default function* rootSaga() {
	yield all([searchClientWatcher(), createClientWatcher()]);
}
