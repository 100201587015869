/**
 * Welcome to FormBuilderConfig
 * Max steps to implement new form component: 2
 */

/* FORMS */

// Custom
import { ImportOrders } from 'modules/Orders/forms';
import { GenerateReport } from 'modules/Reports/forms';
import CreateInventoryItem from 'modules/Warehouse/catalog/CreateInventoryItem';
import Warehouse from 'modules/Warehouse/Warehouse';

/* COMPONENTS */
// Default
import { Button } from 'components';
import {
	BooleanField,
	StringField,
	NumberField,
	TimeField,
	DateField,
	DatetimeField,
	SelectionField,
} from './components/default';
// Custom
import { CustomExample } from './components/custom';

/**
 * Step 1: Add custom form key from <master.forms.key>
 */
const authorizedCustomFormKeys = {
	importOrders: 'importOrders',
	generateReport: 'generateReport',
	createArticle: 'createArticle',
	createWarehouse: 'createWarehouse',
	// <--HERE
};

/**
 * Step 2: Add custom form <key:React> pairs
 */
const authorizedCustomForms = {
	[authorizedCustomFormKeys.importOrders]: ImportOrders,
	[authorizedCustomFormKeys.generateReport]: GenerateReport,
	[authorizedCustomFormKeys.createArticle]: CreateInventoryItem,
	[authorizedCustomFormKeys.createWarehouse]: Warehouse,
	// <--HERE
};

/****************************************************************/

/**
 * FORM COMPONENTS
 */

const authorizedFormComponentTypes = {
	string: 'string',
	number: 'number',
	time: 'time',
	date: 'date',
	datetime: 'datetime',
	boolean: 'boolean',
	list: 'list',
	submit: 'submit',
};

const authorizedDefaultFormComponents = {
	[authorizedFormComponentTypes.string]: StringField,
	[authorizedFormComponentTypes.number]: NumberField,
	[authorizedFormComponentTypes.time]: TimeField,
	[authorizedFormComponentTypes.date]: DateField,
	[authorizedFormComponentTypes.datetime]: DatetimeField,
	[authorizedFormComponentTypes.boolean]: BooleanField,
	[authorizedFormComponentTypes.list]: SelectionField,
	[authorizedFormComponentTypes.submit]: Button,
};

/**
 * Step 1: Add custom form component param from <master.form_components.param>
 */
const authorizedCustomFormComponentParams = {
	example: 'example',
	// <--HERE
};

/**
 * Step 2: Add custom form component <param:React> pairs
 */
const authorizedReactCustomFormComponents = {
	[authorizedCustomFormComponentParams.example]: CustomExample,
	// <--HERE
};

class FormBuilderConfig {
	static checkParamValuesChange(paramValues) {
		return Object.keys(paramValues).reduce(
			(acc, param) =>
				paramValues[param] ? acc.concat(String(paramValues[param])) : acc,
			'',
		);
	}

	static getAuthorizedFormComponentTypes() {
		return authorizedFormComponentTypes;
	}

	/**
	 * @param {string} formKey - The form key
	 * @returns Custom form React Node
	 */
	static getFormReactNode(formKey) {
		return authorizedCustomForms[formKey];
	}

	/**
	 * @param {string} componentParam - The form component param
	 * @returns Custom form component React Node
	 */
	static getAuthorizedCustomFormComponentReactNode(componentParam) {
		return authorizedReactCustomFormComponents[componentParam];
	}

	/**
	 * @param {string} componentType - The form component type
	 * @returns Custom form component React Node
	 */
	static getDefaultFormComponentReactNode(componentType) {
		return authorizedDefaultFormComponents[componentType];
	}

	/**
	 * @param {Object} componentParams - The form component params
	 * @param {Object} values - The user input values
	 * @returns Custom form component React Node
	 */
	static hasRequiredParamEmpty(componentParams, values) {
		return !!Object.keys(componentParams).find(
			paramKey => componentParams[paramKey].required && !values[paramKey],
		);
	}

	static setDefaultValue(componentParamValue) {
		return componentParamValue === true ? componentParamValue : null;
	}

	static getParamValues(componentParams, values) {
		return Object.keys(componentParams).reduce((acc, paramKey) => {
			acc[paramKey] =
				values[paramKey] ??
				FormBuilderConfig.setDefaultValue(componentParams[paramKey]);
			return acc;
		}, {});
	}
}

export default FormBuilderConfig;
