//Libs
import React from 'react';
import styled from 'styled-components';
//Components
import IconButton from '@mui/material/IconButton';
import icon2 from './vertical-divider.svg';

const Icon = styled.div`
	background-image: url(${icon2});
	width: 24px;
	height: 24px;
`;

const VerticalDividerIcon = ({ button, bold, onClick }) =>
	button ? (
		<IconButton onClick={onClick}>
			<Icon bold={bold} />
		</IconButton>
	) : (
		<Icon bold={bold} onClick={onClick} />
	);

export default VerticalDividerIcon;
