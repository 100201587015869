// Libs
import styled from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Input } from 'antd';

const { Search, TextArea } = Input;

const InputStyled = styled(Input)`
	width: ${props => (props.width ? props.width : props.theme.textField.width)};
	margin: ${props =>
		props.margin ? props.margin : props.theme.textField.margin};
	min-height: ${props =>
		props.minHeight ? props.minHeight : props.theme.textField.minHeight};
	text-align: ${props =>
		props.textalign ? props.textalign : props.theme.textField.textAlign};
	&.ant-input {
		border: ${props => props.theme.textField.border};
		border-bottom: ${props => props.theme.textField.borderBottom};
		height: ${props =>
			props.height ? props.height : props.theme.textField.height};
		font-size: ${props =>
			props.fontSize
				? props.theme.fonts.size[props.fontSize]
				: props.theme.fonts.size.default};
		font-family: ${props =>
			props.fontFamily ? props.fontFamily : props.theme.fonts.family};
		font-weight: ${props =>
			props.fontWeight ? props.fontWeight : props.theme.textField.fontWeight};
		font-stretch: ${props =>
			props.fontStretch
				? props.fontStretch
				: props.theme.textField.fontStretch};
		font-style: ${props =>
			props.fontStyle ? props.fontStyle : props.theme.textField.fontStyle};
		line-height: ${props =>
			props.lineHeight ? props.lineHeight : props.theme.textField.lineHeight};
		letter-spacing: ${props =>
			props.letterSpacing
				? props.letterSpacing
				: props.theme.textField.letterSpacing};
		color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
		background-color: transparent;
	}
	.ant-input[disabled] {
		background-color: ${({ $newTheme }) => $newTheme._disabledBackgroundColor};
	}
	&.ant-input[disabled] {
		background-color: ${({ $newTheme }) => $newTheme._disabledBackgroundColor};
	}
	&.ant-input-affix-wrapper {
		background-color: transparent;
	}
`;

const SearchStyled = styled(Search)`
	margin: ${props =>
		props.margin ? props.margin : props.theme.textField.margin};
	min-height: ${props =>
		props.minHeight ? props.minHeight : props.theme.textField.minHeight};
	&.ant-input {
		border: ${props => props.theme.textField.border};
		border-bottom: ${props => props.theme.textField.borderBottom};
		height: ${props =>
			props.height ? props.height : props.theme.textField.height};
		font-size: ${props =>
			props.fontSize
				? props.theme.fonts.size[props.fontSize]
				: props.theme.fonts.size.default};
		font-family: ${props =>
			props.fontFamily ? props.fontFamily : props.theme.fonts.family};
		font-weight: ${props =>
			props.fontWeight ? props.fontWeight : props.theme.textField.fontWeight};
		font-stretch: ${props =>
			props.fontStretch
				? props.fontStretch
				: props.theme.textField.fontStretch};
		font-style: ${props =>
			props.fontStyle ? props.fontStyle : props.theme.textField.fontStyle};
		line-height: ${props =>
			props.lineHeight ? props.lineHeight : props.theme.textField.lineHeight};
		letter-spacing: ${props =>
			props.letterSpacing
				? props.letterSpacing
				: props.theme.textField.letterSpacing};
		color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
		background-color: transparent;
	}
	&.ant-input-search-icon {
		font-size: ${props =>
			props.fontSize
				? props.theme.fonts.size[props.fontSize]
				: props.theme.fonts.size.default};
		color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
	}
`;

const TextAreaStyled = styled(TextArea)`
	margin: ${props =>
		props.margin ? props.margin : props.theme.textField.margin};
	min-height: ${props =>
		props.minHeight ? props.minHeight : props.theme.textField.minHeight};
	&.ant-input {
		border: ${props => props.theme.textField.border};
		border-bottom: ${props => props.theme.textField.borderBottom};
		height: ${props =>
			props.height ? props.height : props.theme.textField.textArea.height};
		font-size: ${props =>
			props.fontSize
				? props.theme.fonts.size[props.fontSize]
				: props.theme.fonts.size.default};
		font-family: ${props =>
			props.fontFamily ? props.fontFamily : props.theme.fonts.family};
		font-weight: ${props =>
			props.fontWeight ? props.fontWeight : props.theme.textField.fontWeight};
		font-stretch: ${props =>
			props.fontStretch
				? props.fontStretch
				: props.theme.textField.fontStretch};
		font-style: ${props =>
			props.fontStyle ? props.fontStyle : props.theme.textField.fontStyle};
		line-height: ${props =>
			props.lineHeight ? props.lineHeight : props.theme.textField.lineHeight};
		letter-spacing: ${props =>
			props.letterSpacing
				? props.letterSpacing
				: props.theme.textField.letterSpacing};
		color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
		background-color: transparent;
	}
`;

export default {
	Input: props => {
		const { theme } = useTheme();
		return <InputStyled {...props} $newTheme={theme} />;
	},
	Search: props => {
		const { theme } = useTheme();
		return <SearchStyled {...props} $newTheme={theme} />;
	},
	TextArea: props => {
		const { theme } = useTheme();
		return <TextAreaStyled {...props} $newTheme={theme} />;
	},
};
