//  Libs
import React, { useRef } from 'react';
import { useTheme } from 'styled-components';
import _ from 'underscore';
import {
	Divider,
	Wrapper,
	Span,
	Button,
	Empty,
	Popover,
	Spin,
	Menu,
	FiltersIcon,
	SettingsIcon,
	LogoIcon,
	RefreshIcon,
	PersonIcon,
} from 'components';
import {
	formatPaymentsToPaycutReport,
	recordsHaveAllowedStates,
} from 'screens/Billings/utils';
import { getSelectedPaycuts } from 'utils/filters/Billings/paycuts';
// Utils
import { CloudStorage, platform } from 'components/AppConfig.json';
import { coinsUtils, xlsx } from 'utils/libs';
import GENERAL from 'utils/constants/general';
import orderBy from 'utils/orderBy';
// Styles
import './SignPaycutModal.css';
import '../../../index.css';
// Component
import VirtualizedList from '../../../../Lists/VirtualizedList';

const { ENV } = GENERAL;

function canOpenReport(selectedPaycuts, getPaycutsResumeModal, profile) {
	const env = process.env.NODE_ENV;
	const organization = profile.organization.settings.photoStore.main;
	const company = profile.company.settings.photoStore.main;
	const entity_id = profile.entity.id;
	const reportUri = `${CloudStorage}/${platform}/${env}/${company}/${organization}/reports/paycuts/${entity_id}`;
	let reportId = null;

	if (
		selectedPaycuts.length === 1 &&
		(selectedPaycuts[0].pdf_generated || getPaycutsResumeModal.pdfGenerated)
	) {
		reportId = `${selectedPaycuts[0].id}.pdf`;
	} else if (selectedPaycuts.length > 1 && getPaycutsResumeModal.pdfGenerated) {
		reportId = `${ENV.PAYCUTS.FUSION_PAYCUTS_KEY}${profile.user.id}.pdf`;
	}

	return reportId ? `${reportUri}/${reportId}` : null;
}

function GetOptionsResumeContent(props) {
	const {
		selectedPaycutsIds,
		paycuts,
		profile,
		resume_paycut,
		getPaycutsModal,
		getPaycutsResumeModal,
		generatingPDF,
		generatePaycutPDF,
	} = props;

	const selectedPaycuts = getSelectedPaycuts(paycuts, selectedPaycutsIds);
	const firstSelectedPaycut = selectedPaycuts[0];
	const allPaycutsHaveAllowedStates = recordsHaveAllowedStates(
		selectedPaycuts,
		['released', 'paid'],
	);
	const canOpenReportURI = canOpenReport(
		selectedPaycuts,
		getPaycutsResumeModal,
		profile,
	);

	return (
		<Spin spinning={generatingPDF} size='small' delay={50} tip={''}>
			<Menu.Wrapper>
				<Menu.Group title='Exportar'>
					<Menu.Item
						disabled={!allPaycutsHaveAllowedStates}
						onClick={() => {
							if (!canOpenReportURI) {
								generatePaycutPDF(
									{
										...getPaycutsResumeModal,
										isInvoice: getPaycutsModal.type === 'invoice',
										paycutIds: selectedPaycutsIds,
									},
									'paycuts',
								);
							} else if (canOpenReportURI) {
								window.open(canOpenReportURI);
							}
						}}
					>
						PDF ({canOpenReportURI ? 'Abrir' : 'Generar'})
					</Menu.Item>
					<Menu.Item
						onClick={() => {
							xlsx.exportPaycutResumeToExcel(
								'Resumen',
								resume_paycut.pays,
								firstSelectedPaycut.coin_symbol,
								profile,
							);
						}}
					>
						Excel
					</Menu.Item>
				</Menu.Group>
			</Menu.Wrapper>
		</Spin>
	);
}

function GetFilterResumeContent(props) {
	const {
		loading,
		selectedPaycutsIds,
		getPaycutsResumeModal,
		getPaycutsModal,
		mutate1ObjectInBillings,
		getPaycutResume,
	} = props;
	const theme = useTheme();

	return (
		<Spin spinning={loading} size='small' delay={50} tip={''}>
			<Menu.Wrapper>
				<Menu.Item
					style={
						getPaycutsResumeModal.excludePayrollUsers && {
							backgroundColor: theme.colors.primary[500],
						}
					}
					onClick={() => {
						mutate1ObjectInBillings('getPaycutsResumeModal', {
							excludePayrollUsers: !getPaycutsResumeModal.excludePayrollUsers,
						});
						getPaycutResume({
							...getPaycutsResumeModal,
							excludePayrollUsers: !getPaycutsResumeModal.excludePayrollUsers,
							isInvoice: getPaycutsModal.type === 'invoice',
							paycutIds: selectedPaycutsIds,
						});
					}}
				>
					Excluir técnicos internos
				</Menu.Item>
			</Menu.Wrapper>
		</Spin>
	);
}

export function HeadSignPaycutModal(props) {
	const {
		resume_paycut,
		selectedPaycutsIds,
		paycuts,
		profile,
		getPaycutsModal,
		getPaycutResume,
		generatingPDF,
		generatePaycutPDF,
		getPaycutsResumeModal,
		mutate1ObjectInBillings,
	} = props;

	return (
		<Wrapper width='95%' height='28px' justifyContent='space-between'>
			<Span fontSize='xl'>Resumen</Span>
			<Wrapper padding='0'>
				{/* FILTER */}
				<Popover
					content={
						<GetFilterResumeContent
							loading={resume_paycut.loading}
							selectedPaycutsIds={selectedPaycutsIds}
							getPaycutsResumeModal={getPaycutsResumeModal}
							getPaycutsModal={getPaycutsModal}
							mutate1ObjectInBillings={mutate1ObjectInBillings}
							getPaycutResume={getPaycutResume}
						/>
					}
					placement='bottomRight'
					title={null}
					trigger='hover'
				>
					<div>
						<FiltersIcon button />
					</div>
				</Popover>

				{/* SETTINGS */}
				<Popover
					content={
						<GetOptionsResumeContent
							selectedPaycutsIds={selectedPaycutsIds}
							paycuts={paycuts}
							profile={profile}
							resume_paycut={resume_paycut}
							getPaycutsModal={getPaycutsModal}
							getPaycutsResumeModal={getPaycutsResumeModal}
							generatePaycutPDF={generatePaycutPDF}
							generatingPDF={generatingPDF}
						/>
					}
					placement='bottomRight'
					title={null}
					trigger='hover'
				>
					<div>
						<SettingsIcon />
					</div>
				</Popover>

				{/* UPDATE */}
				{resume_paycut.loading ? (
					<LogoIcon button spin />
				) : (
					<RefreshIcon
						onClick={() =>
							getPaycutResume({
								...getPaycutsResumeModal,
								isInvoice: getPaycutsModal.type === 'invoice',
								paycutIds: selectedPaycutsIds,
							})
						}
					/>
				)}
			</Wrapper>
		</Wrapper>
	);
}

function GetResumeItem({ concept, value, color }) {
	const theme = useTheme();

	return (
		<Wrapper padding='5px' justifyContent='space-between' width='95%'>
			<Span fontSize='l' color={color || theme.fonts.colors.concept}>
				{concept}
			</Span>
			<Span fontSize='l' color={color || theme.fonts.colors.value}>
				{value}
			</Span>
		</Wrapper>
	);
}

export default function SignPaycutModal(props) {
	const {
		totalUsers,
		selectedPaycutsIds,
		paycuts,
		coins,
		signPaycut,
		resume_paycut,
		signPaycutModal,
		profile,
	} = props;
	const theme = useTheme();
	const targetRef = useRef();
	const selectedPaycuts = getSelectedPaycuts(paycuts, selectedPaycutsIds);
	const coinSymbol = coinsUtils.getDataByCoinId(
		selectedPaycuts[0]?.coin_id,
		'symbol',
		coins,
	);
	const totals = formatPaymentsToPaycutReport(resume_paycut.pays, coinSymbol);
	const signed = resume_paycut.paycuts.reduce((acc, paycut) => {
		if (Array.isArray(paycut.signed)) acc = acc.concat(paycut.signed);
		return acc;
	}, []);
	orderBy(signed, 'objectDate', {
		objectKey: 'created_at',
		dateFormat: profile?.user?.settings?.date_format,
	});

	return (
		<Wrapper padding='0' flexDirection='column'>
			<Wrapper
				elementRef={targetRef}
				width='100%'
				flexDirection='column'
				justifyContent='space-between'
			>
				{/* PAYMENTS */}
				<Divider color='gray'>Pagos</Divider>
				<Wrapper>
					{resume_paycut.pays.length === 0 ? (
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					) : (
						<VirtualizedList
							cardId='paycutResumeCard'
							listStyle={{
								height: document.documentElement.clientHeight * 0.5,
								width: targetRef.current ? targetRef.current.offsetWidth : 0,
							}}
							rowHeight={200}
							spinTitle='Consultando registros...'
							rows={resume_paycut.pays}
							customProps={{
								coin_symbol: coinSymbol,
							}}
						/>
					)}
				</Wrapper>

				{/* RESUME */}
				<Divider color='gray'>Resumen</Divider>
				<Wrapper flexDirection='column' width='100%'>
					<GetResumeItem concept='Ordenes' value={totals.orders_count} />
					<GetResumeItem concept='Producción' value={totals.production} />
					<GetResumeItem concept='Créditos' value={totals.credits} />
					<GetResumeItem concept='Débitos' value={totals.discounts} />
					<GetResumeItem concept='Subtotal' value={totals.subtotal} />
					<GetResumeItem concept='IVA' value={`${totals.iva}%`} />
					<GetResumeItem concept='Impuestos' value={totals.subtotal_with_iva} />
					<GetResumeItem
						concept='Total a Pagar'
						value={totals.total}
						color={theme.fonts.colors.emphasis_1}
					/>
				</Wrapper>

				{/* SIGNED */}
				<Divider color='gray'>Firmas</Divider>
				<Wrapper
					width='100%'
					margin='20px 0 10px 0'
					borderRadius='20px'
					backgroundColor={theme.colors.secondary[800]}
					overflow='auto'
				>
					{signed.map((sign, idx) => {
						const user = totalUsers.filter(user => user.id === sign.user_id)[0];
						let style = { color: 'rgba(255, 255, 255, 0.6)' };

						if (sign.paid) style = { color: 'green' };
						else if (sign.released) style = { color: 'yellow' };
						else if (sign.agreed) style = { color: 'deepskyblue' };

						return (
							user && (
								<Wrapper key={idx} padding='0px'>
									<PersonIcon style={style} />
									<Wrapper
										margin='0 10px 10px 0'
										padding='0px'
										flexDirection='column'
										alignItems='baseline'
									>
										<Span style={style} margin='0 10px'>
											{user.name} ({sign.paycut_id})
										</Span>
										<Span margin='0 10px' color='cadetblue'>
											{sign.created_at}
										</Span>
									</Wrapper>
								</Wrapper>
							)
						);
					})}
				</Wrapper>

				{recordsHaveAllowedStates(selectedPaycuts, ['ready', 'released']) && (
					<Wrapper width='100%' justifyContent='flex-end'>
						{signPaycutModal.loading ? (
							<LogoIcon spin button />
						) : (
							<Button
								mode='primary'
								onClick={() => signPaycut(selectedPaycutsIds)}
							>
								Firmar
							</Button>
						)}
					</Wrapper>
				)}
			</Wrapper>
		</Wrapper>
	);
}
