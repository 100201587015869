export function onApproveCompletedAuthorizationCodeValidator(payload) {
	const completedAuthorizationCode = payload.completedAuthorizationCode
		?.toString()
		.trim();

	let errors = '';

	if (!completedAuthorizationCode) {
		errors += '[Debe ingresar el código]';
	}

	if (errors === '') return null;
	return errors;
}
