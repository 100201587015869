import Immutable from 'immutable';
import _ from 'underscore';
import { filterDataByFilterOptions } from '..';

function filterUsers(users, filters) {
	let filteredUsers = Immutable.List(users).toJS();

	filteredUsers = filterDataByFilterOptions(filteredUsers, filters);
	return filteredUsers;
}

export { filterUsers };
