// Libs
import React from 'react';
import cx from 'classnames';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Span, Watermark, Wrapper } from 'components';
// Styles
import styles from './ImageGrid.module.css';
import defaultImage from 'assets/media/img/noImage.png';
import PhotoUtils from 'utils/PhotoUtils';

const DEFAULT_IMAGE = {
	URL: defaultImage,
};

const ImageGrid = ({
	gridView = false,
	cardView = false,
	sources = [],
	onClick,
	totalCount,
	isNearScreen,
}) => {
	let notVisibleCount = '';

	if (totalCount > 4) {
		notVisibleCount = `+${totalCount - sources.length}`;
	}

	const hasImages = Array.isArray(sources) && sources.length > 0;

	return (
		<div className={styles.contentWrapper} onClick={onClick}>
			{hasImages ? (
				sources.map(
					({ id, src, altText }, idx) =>
						idx <= 3 && (
							<ImagePreview
								key={id.toString()}
								lastImage={idx === 3}
								src={src}
								altText={altText}
								gridView={gridView}
								cardView={cardView}
								notVisibleCount={notVisibleCount}
								countImages={sources.length}
								isNearScreen={isNearScreen}
							/>
						),
				)
			) : (
				<ImagePreview
					src={DEFAULT_IMAGE.URL}
					gridView={gridView}
					cardView={cardView}
					lastImage={true}
					countImages={1}
				/>
			)}
		</div>
	);
};

const ImagePreview = ({
	gridView,
	cardView,
	src,
	altText = '',
	lastImage = false,
	notVisibleCount = '',
	countImages,
	isNearScreen,
}) => {
	const [_src, setSrc] = React.useState(DEFAULT_IMAGE.URL);
	const { theme } = useTheme();

	React.useEffect(() => {
		let isMounted = true;
		if (isNearScreen)
			PhotoUtils.getRemoteImageResource({
				src,
				thumbnailSrc: PhotoUtils.getThumbnail({
					src,
					folder: 'photoCollectors',
					size: gridView ? 150 : 400,
				}),
				defaultSrc: DEFAULT_IMAGE.URL,
				noCached: true,
			}).then(_src => isMounted && setSrc(_src));
		return () => {
			isMounted = false;
		};
	}, [isNearScreen]);

	return (
		<div
			className={cx(
				{ [styles.imgWrapperGrid]: gridView },
				{ [styles.imgWrapperCard]: cardView },
				{ [styles.imgWrapperList]: !gridView && !cardView },
				{ [styles.fullImageHeightCardView]: cardView && countImages <= 2 },
			)}
		>
			<LazyLoadImage src={_src} alt={altText} className={styles.preview} />
			<div
				className={styles.imgMask}
				style={{
					backgroundColor: lastImage
						? theme._inactiveColor
						: theme._transparentBackgroundColor,
				}}
			>
				<Span overlayColor>{lastImage ? notVisibleCount : ''}</Span>
			</div>
		</div>
	);
};

export default ImageGrid;
