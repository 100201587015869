/** **** CONSTANTS *******/
import GENERAL from '../../utils/constants/general';
import moment from 'moment';

const { BILLINGS } = GENERAL;

/** **** INITIALSTATE *******/
const initialState = {
	paycuts: {
		synchronizing: false,
		generatingPDF: false,
		paycutId: undefined,
		selected: {
			items: [],
		},
		filters: {},
		searchInData: {
			renderToolbar: false,
			showPopover: false,
			data: '',
			searchBy: 'all',
		},
		analytics: {
			isOpen: false,
		},
	},
	pays: {
		generatingPDF: false,
		updateState: false,
		selectedPays: [],
		selected: {
			items: [],
		},
		getOrders: undefined,
		filters: {},
		searchInData: {
			renderToolbar: false,
			showPopover: false,
			data: '',
			searchBy: 'all',
		},
		analytics: {
			isOpen: false,
		},
	},
	orders: {
		mode: 'view', // view, edit
		selectedOrders: [],
		editedOrders: [],
		filters: {},
		searchInData: {
			renderToolbar: false,
			showPopover: false,
			data: '',
			searchBy: 'all',
		},
		analytics: {
			isOpen: false,
		},
	},
	lines: {
		selectedLines: [],
		filters: {},
		searchInData: {
			renderToolbar: false,
			showPopover: false,
			data: '',
			searchBy: 'all',
		},
		analytics: {
			isOpen: false,
		},
	},
	// MODALS
	getPaycutsResumeModal: {
		excludePayrollUsers: false,
		pdfGenerated: false,
	},
	getPaymentsResumeModal: {
		pdfGenerated: false,
	},
	getPaycutsModal: {
		isOpen: false,
		type: 'payment', // payment, invoice
		last_row_id: 0,
		limit: 50,
	},
	createLineModal: {
		isOpen: false,
		loading: false,
		// LINE
		line_item_id: undefined,
		description: undefined,
		// ITEM
		name: undefined,
		action: undefined,
		props: { applyTo: 'payment' },
		coin_id: undefined,
		// LINE & ITEM
		amount: undefined,
		tracts: undefined,
		// OPERATION
		operation: 'createLine', // createLine, editItem, createItem
	},
	createPaycutModal: {
		isOpen: false,
		loading: false,
		name: undefined,
		from_date: moment(),
		to_date: moment(),
		department_id: undefined,
		coin_id: undefined,
	},
	createPaymentModal: {
		isOpen: false,
		loading: false,
		division_id: undefined,
		paid_to: undefined,
		name: undefined,
		iva: undefined,
		service_ids: [],
	},
	signPaycutModal: {
		isOpen: false,
		loading: false,
	},
	signPaymentModal: {
		isOpen: false,
		loading: false,
		groupOrders: true,
	},
	enterInvoiceOrdersModal: {
		isOpen: false,
		loading: false,
		selectedFile: undefined,
	},
	configViewSaleCostModal: {
		isOpen: false,
		filters: [
			{
				value: undefined,
				operator: undefined,
				color: '#000000',
			},
		],
	},
};
const billingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case BILLINGS.MUTATE_1OBJECT: {
			const { obj1Name, keyValuePairs } = action.payload;
			const newObj = { ...state[obj1Name], ...keyValuePairs };
			return {
				...state,
				[obj1Name]: newObj,
			};
		}
		case BILLINGS.RESET_CREATE_PAYMENT: {
			return {
				...state,
				createPaymentModal: {
					isOpen: false,
					loading: false,
					division_id: undefined,
					paid_to: undefined,
					name: undefined,
					iva: undefined,
					service_ids: [],
				},
			};
		}
		case BILLINGS.RESET_CREATE_PAYMENT_LINE: {
			return {
				...state,
				createLineModal: {
					isOpen: false,
					loading: false,
					// LINE
					line_item_id: undefined,
					description: undefined,
					// ITEM
					name: undefined,
					action: undefined,
					props: { applyTo: 'payment' },
					coin_id: undefined,
					// LINE & ITEM
					amount: undefined,
					tracts: undefined,
					// OPERATION
					operation: 'createLine', // createLine, editItem, createItem
				},
			};
		}
		case BILLINGS.RESET_CREATE_INVOICE_PAYCUT: {
			return {
				...state,
				createPaycutModal: {
					isOpen: false,
					loading: false,
					name: undefined,
					from_date: moment(),
					to_date: moment(),
					department_id: undefined,
					coin_id: undefined,
				},
			};
		}
		case BILLINGS.RESET_GET_PAYCUTS: {
			return {
				...state,
				paycuts: {
					...state.paycuts,
					selected: {
						...state.paycuts.selected,
						items: [],
					},
				},
				getPaycutsModal: {
					isOpen: false,
					type: 'payment', // payment, invoice
					last_row_id: 0,
					limit: 50,
				},
			};
		}
		case BILLINGS.RESET: {
			return initialState;
		}
		default:
			return state;
	}
};

export default billingsReducer;
