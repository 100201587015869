// Hooks
import { useCollectorManager } from 'hooks';
import { useDispatch } from 'react-redux';
// Actions
import { sendToast } from 'screens/Dashboard/actions';

//Events
const useApi = () => {
	const dispatch = useDispatch();
	const {
		getMergedReportUrl,
		mergePDFReports,
		getMergedContractReportUrl,
		mergeContractPdfReports,
	} = useCollectorManager();

	const onMergePDFReports = async otdId => {
		return mergePDFReports(otdId)
			.then(() => {
				dispatch(
					sendToast({
						type: 'success',
						message:
							'Reporte en camino... Revisa la bandeja de notificaciones en unos segundos',
					}),
				);
				return Promise.resolve('Success');
			})
			.catch(({ type, message } = {}) => {
				dispatch(sendToast({ type, message }));
				throw err;
			});
	};

	const onGetMergedReportUrl = async otdId => {
		return getMergedReportUrl(otdId).catch(({ err, type, message } = {}) => {
			dispatch(sendToast({ message, type }));
			throw err;
		});
	};

	const onMergeContractPdfReports = async otdId => {
		return mergeContractPdfReports(otdId)
			.then(() => {
				dispatch(
					sendToast({
						type: 'success',
						message:
							'Reporte en camino... Revisa la bandeja de notificaciones en unos segundos',
					}),
				);
				return Promise.resolve('Success');
			})
			.catch(({ type, message } = {}) => {
				dispatch(sendToast({ type, message }));
				throw err;
			});
	};

	const onGetMergedContractReportUrl = async otdId => {
		return getMergedContractReportUrl(otdId).catch(
			({ err, type, message } = {}) => {
				dispatch(sendToast({ message, type }));
				throw err;
			},
		);
	};

	return {
		onGetMergedReportUrl,
		onMergePDFReports,
		onGetMergedContractReportUrl,
		onMergeContractPdfReports,
	};
};

export default useApi;
