// Libs
import React from 'react';
// Services
import MQTTService from 'services/mqttService';
// Realtime context
import { RealtimeConsumer } from '../RealtimeContext';
// Hooks
import { usePrevious } from 'hooks';

// Single subscriber to wrap a children
const StandardSubscriber = ({
	// Realtime context
	children,
	isConnected,
	payloadMessage,
	mqttSub,
	mqttUnSub,
	mqttPublish,
	// Props
	topics, // Topic relative path
	subscriber,
	qos,
}) => {
	const [payload, setPayload] = React.useState({});

	// Subscriptions/Unsubscriptions
	// TODO: Revisar la comparacion entre los topics
	const prevSusbscriber = usePrevious(subscriber);
	const prevAbsoluteTopics = usePrevious(
		topics.map(topic => MQTTService.setTopic(topic, subscriber)),
	);

	// Subscribe/Unsubscribe
	React.useEffect(() => {
		const absoluteTopics = topics.map(topic =>
			MQTTService.setTopic(topic, subscriber),
		);
		topics.map((topic, idx) => {
			if (prevAbsoluteTopics?.[idx] !== absoluteTopics[idx]) {
				// Unsubscribe
				mqttUnSub(topic, prevSusbscriber);
				// Subscribe
				mqttSub(topic, subscriber, { qos });
			} else {
				// Subscribe
				mqttSub(topic, subscriber, { qos });
			}
		});
		return () => {
			topics.map(topic => {
				mqttUnSub(topic, subscriber);
			});
		};
	}, [topics, subscriber]);

	// Receive new payload message
	React.useEffect(() => {
		if (!payloadMessage) return;
		const { action, message } = payloadMessage;

		const topic = topics.find(t => t === action);
		if (!topic) return;

		setPayload({
			[topic]: message,
		});
	}, [payloadMessage, topics]);

	return children({
		payload,
		mqttPublish,
	});
};

const RealtimeSubscriber = ({ topics, subscriber, qos, children }) => (
	<RealtimeConsumer>
		{({ isConnected, payload, mqttSub, mqttUnSub, mqttPublish }) => (
			<StandardSubscriber
				isConnected={isConnected}
				payloadMessage={payload}
				mqttSub={mqttSub}
				mqttUnSub={mqttUnSub}
				mqttPublish={mqttPublish}
				topics={topics}
				subscriber={subscriber}
				qos={qos}
			>
				{children}
			</StandardSubscriber>
		)}
	</RealtimeConsumer>
);

export default RealtimeSubscriber;
