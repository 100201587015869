const FIELD_IDS = {
	ODT_ID: 'odtId',
	SERVICE_ID: 'serviceId',
	ZONE_ID: 'zoneId',
	CENTRAL_ID: 'centralId',
	EVENT_ID: 'eventId',
	ENTITY_ID: 'entityId',
	ENTITY_NAME: 'entityName',
	ASSIGNED_TECH_ID: 'assignedTechId',
	IS_WARRANTY: 'isWarranty',
};

const FIELD_TYPES = {
	TEXT: 'text',
	NUMERIC: 'numeric',
	BOOLEAN: 'boolean',
	DATE: 'date',
	DATETIME: 'datetime',
};

export default class DataFieldManagerUtils {
	static getFieldIds() {
		return FIELD_IDS;
	}

	static getFieldTypes() {
		return FIELD_TYPES;
	}
}
