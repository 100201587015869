// Libs
import React from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { IconButton } from '@mui/material';
import Icon from './BackIcon';

const BackIcon = ({ onClick }) => {
	const { theme } = useTheme();
	return (
		<IconButton onClick={onClick}>
			<Icon fill={theme._mediumEmphasisColor} />
		</IconButton>
	);
};

export default BackIcon;
