// Services
import ApiServiceV2 from 'services/apiService/apiServiceV2';

const useCommentManager = () => {
	return {
		// Get comment count
		getCommentCount: ({ resourceKey, resourceId }) =>
			ApiServiceV2.get({
				url: `/commentManager/v1/count/${resourceKey}/${resourceId}`,
			}),
	};
};

export default useCommentManager;
