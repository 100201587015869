// Libs
import React from 'react';
// Selectors
import { selectDatasourceFields } from 'core/selectors';
// Hooks
import useTheme from 'hooks/useTheme';
import { useSelector } from 'react-redux';
// Components
import { Wrapper } from 'components';
import { LogoIcon } from 'components/Icons';
import SharedGridScreen from './SharedGridScreen';
import { ElementBuilder } from '../../ElementBuilder';
import LastIntersectedElement from '../../LastIntersectedElement';

const StandardStreamScreen = ({
	elementKey,
	screenKey,
	isMobile,
	isLoading,
	dataFilterLastRowId,
	datasourcePaging,
	datasource,
	profile,
	setLastRowId,
	reloadDatasource,
}) => {
	const { theme } = useTheme();
	const rootObserverRef = React.useRef();
	const lastRowId = React.useMemo(() => datasource.at(-1)?.id, [datasource]);
	const isTotalLastRow = React.useMemo(
		() => Number(lastRowId) === Number(datasourcePaging.totalLastRowId),
		[lastRowId],
	);

	const fields = useSelector(selectDatasourceFields);

	return (
		<Wrapper width='100%' padding='0' position='relative'>
			<SharedGridScreen
				isMobile={isMobile}
				rootObserverRef={rootObserverRef}
				customGridConfig={{
					columns: 'minmax(300px, 700px)',
				}}
			>
				{datasource.map(record => (
					<ElementBuilder
						key={record.id}
						theme={theme}
						isMobile={isMobile}
						elementKey={elementKey}
						screenKey={screenKey}
						record={record}
						fields={fields}
						profile={profile}
						rootObserverRef={rootObserverRef}
					/>
				))}
				<LastIntersectedElement
					rootObserverRef={rootObserverRef}
					lastRowId={lastRowId}
					isTotalLastRow={isTotalLastRow}
					isLoading={isLoading}
					dataFilterLastRowId={dataFilterLastRowId}
					setLastRowId={setLastRowId}
					reloadDatasource={reloadDatasource}
				/>
			</SharedGridScreen>

			{isLoading && dataFilterLastRowId > 0 && (
				<Wrapper padding='0' position='absolute' right='16px' bottom='16px'>
					<LogoIcon spin={true} size='m' />
				</Wrapper>
			)}
		</Wrapper>
	);
};

export default StandardStreamScreen;
