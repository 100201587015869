//Libs
import { useState } from 'react';
import { useDispatch } from 'react-redux';
//Services
import ApiServiceV2 from 'services/apiService/apiServiceV2';
// Actions
import { sendToast } from 'screens/Dashboard/actions';

const useContractorData = () => {
	const dispatch = useDispatch();
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);

	const getContractorData = async entityId => {
		try {
			const response = await ApiServiceV2.get({
				url: `/contractors/v1/getProjects/${entityId}/projects-with-modules`,
			});
			setData(response);
			setError(null);
		} catch (error) {
			setError(error.message);
			setData(null);
		}
	};

	const assignModule = async (entityId, projectId, orderTargetId, moduleId) => {
		try {
			const url = `/contractors/v1/projects/${entityId}/assignModule`;
			const requestBody = {
				projectId,
				orderTargetId,
				moduleId,
			};
			const result = await ApiServiceV2.post({ url, data: requestBody });
			dispatch(
				sendToast({
					message: result.message,
					type: 'success',
				}),
			);
			getContractorData(entityId);
		} catch (error) {
			setError(error.message);
		}
	};

	const unassignModule = async (
		entityId,
		projectId,
		orderTargetId,
		moduleId,
	) => {
		try {
			const url = `/contractors/v1/projects/${entityId}/unassignModule`;
			const requestBody = {
				projectId,
				orderTargetId,
				moduleId,
			};

			const result = await ApiServiceV2.put({ url, data: requestBody });
			dispatch(
				sendToast({
					message: result.message,
					type: 'success',
				}),
			);
			getContractorData(entityId);
		} catch (error) {
			setError(error.message);
		}
	};

	return {
		data,
		error,
		getContractorData,
		assignModule,
		unassignModule,
	};
};

export default useContractorData;
