//Libs
import React from 'react';
//Components
import {
	Wrapper,
	Collapse as _Collapse,
	AddIcon,
	DeleteIcon,
} from 'components';
import Collector from 'components/Collector/Collector';
import CollectorUtils from 'components/Collector/CollectorUtils';
import DynamicHeaderTitle from './DynamicHeaderTitle';
//Utils
import COLLECTOR_KEYWORDS from './keywords';

const { DUPLICATION } = COLLECTOR_KEYWORDS.COLLECTORS;
const { Collapse, Panel } = _Collapse;

const SubgroupHeader = React.memo(
	({
		subgroup,
		isDuplicable,
		highlightRequired,
		selectedCurrentCollector,
		subgroupedCollectors,
		collectorValues,
		handleOnDuplicateElement,
		handleOnDeleteElement,
	}) => {
		const collectors = React.useMemo(
			() =>
				CollectorUtils.getSubgroupedCollectorsFromSubgroup(
					subgroupedCollectors,
					subgroup.subgroupId,
				),
			[subgroup.subgroupId, subgroupedCollectors],
		);
		return (
			<Wrapper padding='0' justifyContent='space-between'>
				<DynamicHeaderTitle
					level={DUPLICATION.LEVELS.SUBGROUP}
					highlightElement={subgroup}
					defaultTitle={subgroup.subgroupName}
					highlightRequired={highlightRequired}
					selectedCurrentCollector={selectedCurrentCollector}
					collectors={collectors}
					collectorValues={collectorValues}
				/>
				{isDuplicable && (
					<Wrapper padding='0'>
						<Wrapper padding='0' margin='0 10px 0 0'>
							<DeleteIcon
								onClick={e => {
									e.stopPropagation();
									handleOnDeleteElement({
										level: DUPLICATION.LEVELS.SUBGROUP,
										element: subgroup, //Formatted duplicate element already
									});
								}}
							/>
						</Wrapper>

						<AddIcon
							onClick={e => {
								e.stopPropagation();
								handleOnDuplicateElement({
									level: DUPLICATION.LEVELS.SUBGROUP,
									element: subgroup, //Formatted duplicate element already
								});
							}}
						/>
					</Wrapper>
				)}
			</Wrapper>
		);
	},
);

const Subgroup = React.memo(
	({
		profile,
		order,
		docId,
		readOnlyCollector,
		subgroup,
		highlightRequired,
		selectedCurrentCollector,
		isPhotoBackupActive,
		isSynapseCameraActive,
		subgroupedCollectors,
		filteredCollectorLayout,
		collectorValues,
		resources,
		collectorEcosystemAutoSyncRunning,
		handleOnDuplicateElement,
		handleOnDeleteElement,
	}) => {
		const collectors = React.useMemo(
			() =>
				CollectorUtils.getSubgroupedCollectorsFromSubgroup(
					subgroupedCollectors,
					subgroup.subgroupId,
				),
			[subgroup.subgroupId, subgroupedCollectors],
		);
		return CollectorUtils.checkArray(collectors).map((collector, idx2) => (
			<Wrapper
				key={idx2}
				padding='5px'
				width='100%'
				flexDirection='column'
				alignItems='flex-start'
			>
				<Collector
					profile={profile}
					order={order}
					docId={docId}
					collector={collector}
					readOnlyCollector={readOnlyCollector}
					highlightRequired={highlightRequired}
					selectedCurrentCollector={selectedCurrentCollector}
					isPhotoBackupActive={isPhotoBackupActive}
					isSynapseCameraActive={isSynapseCameraActive}
					filteredCollectorLayout={filteredCollectorLayout}
					collectorValues={collectorValues}
					resources={resources}
					collectorEcosystemAutoSyncRunning={collectorEcosystemAutoSyncRunning}
					handleOnDuplicateElement={handleOnDuplicateElement}
					handleOnDeleteElement={handleOnDeleteElement}
				/>
			</Wrapper>
		));
	},
);

const SubgroupedCollectors = React.memo(
	({
		profile,
		order,
		docId,
		readOnlyCollector,
		review,
		group,
		subgroups,
		subgroupedCollectors,
		highlightRequired,
		selectedCurrentCollector,
		isPhotoBackupActive,
		isSynapseCameraActive,
		filteredCollectorLayout,
		collectorValues,
		resources,
		collectorEcosystemAutoSyncRunning,
		handleOnDuplicateElement,
		handleOnDeleteElement,
		onLoadSegmentedResources,
	}) => {
		if (subgroups.length === 0) return null;

		const duplicatedSubgroupLastNames = React.useMemo(
			() =>
				subgroups.reduce((acc, subgroup) => {
					//Get current last element
					const { originalElementName, lastSequenceNumber } =
						CollectorUtils.getCurrentLastElement(
							DUPLICATION.LEVELS.SUBGROUP,
							subgroup.subgroupName,
							subgroupedCollectors,
							{
								docId,
								orderId: order.order_id,
								review: {
									name: review.name,
									serviceId: review.serviceId,
								},
							},
						);
					if (!originalElementName || !lastSequenceNumber) return acc;

					acc[`${originalElementName} #${lastSequenceNumber}`] = true;
					return acc;
				}, {}),
			[
				docId,
				order.order_id,
				review.name,
				review.serviceId,
				subgroups,
				subgroupedCollectors,
			],
		);

		return (
			<Collapse
				accordion
				bordered={false}
				defaultActiveKey={['-1']}
				destroyInactivePanel={true}
				onChange={activeKey => {
					onLoadSegmentedResources({
						docId,
						orderId: order.order_id,
						serviceTaskId: review.serviceTaskId,
						reviewId: review.id,
						groupId: group.groupId,
						subgroupId: activeKey,
					});
				}}
			>
				{CollectorUtils.checkArray(subgroups).map(subgroup => (
					<Panel
						key={subgroup.subgroupId}
						style={{
							padding: '8px 8px 0 8px',
							margin: '0 0 8px 0',
							boxShadow: 'rgb(0 0 0 / 35%) 0px 5px 15px',
						}}
						header={
							<SubgroupHeader
								subgroup={subgroup}
								isDuplicable={
									duplicatedSubgroupLastNames[subgroup.subgroupName]
								}
								highlightRequired={highlightRequired}
								selectedCurrentCollector={selectedCurrentCollector}
								subgroupedCollectors={subgroupedCollectors}
								collectorValues={collectorValues}
								handleOnDuplicateElement={handleOnDuplicateElement}
								handleOnDeleteElement={handleOnDeleteElement}
							/>
						}
					>
						<Subgroup
							profile={profile}
							order={order}
							docId={docId}
							readOnlyCollector={readOnlyCollector}
							subgroup={subgroup}
							highlightRequired={highlightRequired}
							selectedCurrentCollector={selectedCurrentCollector}
							isPhotoBackupActive={isPhotoBackupActive}
							isSynapseCameraActive={isSynapseCameraActive}
							subgroupedCollectors={subgroupedCollectors}
							filteredCollectorLayout={filteredCollectorLayout}
							collectorValues={collectorValues}
							resources={resources}
							collectorEcosystemAutoSyncRunning={
								collectorEcosystemAutoSyncRunning
							}
							handleOnDuplicateElement={handleOnDuplicateElement}
							handleOnDeleteElement={handleOnDeleteElement}
						/>
					</Panel>
				))}
			</Collapse>
		);
	},
);

export default SubgroupedCollectors;
