// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	// <svg
	// 	width="16"
	// 	height="16"
	// 	viewBox="0 0 16 16"
	// 	fill="none"
	// 	xmlns="http://www.w3.org/2000/svg"
	// >
	// 	<mask
	// 		id="arrowUpIcon16"
	// 		style={{ maskType: "alpha" }}
	// 		maskUnits="userSpaceOnUse"
	// 		x="4"
	// 		y="6"
	// 		width="9"
	// 		height="5"
	// 	>
	// 		<path
	// 			d="M11.2563 10.97L8.19629 7.91664L5.13629 10.97L4.19629 10.03L8.19629 6.02997L12.1963 10.03L11.2563 10.97Z"
	// 			fill="white"
	// 		/>
	// 	</mask>
	// 	<g mask="url(#arrowUpIcon16)">
	// 		<rect width="16" height="16" fill={fill} fillOpacity={fillOpacity} />
	// 	</g>
	// </svg>

	<svg
		width='16'
		height='16'
		viewBox='4 4 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mask0_21958_139'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='6'
			y='9'
			width='11'
			height='6'
		>
			<path d='M16.5 14.5L11.5 9.5L6.5 14.5H16.5Z' fill={fill} />
		</mask>
		<g mask='url(#mask0_21958_139)'>
			<rect width='16' height='16' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
