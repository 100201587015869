// Libs
import axios from 'axios';

// interface RequestProps {
// 	url: string;
// 	params?: Object;
// 	data?: Object;
// 	withAuth?: boolean;
// }

// interface FetchProps extends RequestProps {
// 	method: Method;
// 	headers?: Object;
// }

const apiService = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

const run = ({
	url,
	params, // the URL parameters to be sent with the request
	data, // the data to be sent as the request body, only applicable for request methods 'PUT', 'POST', and 'PATCH'
	withAuth = true,
	method,
	headers,
}) => {
	return new Promise(async (resolve, reject) => {
		const authHeader = withAuth
			? {
					Authorization: `Bearer ${localStorage.getItem('synapse_token')}`,
			  }
			: undefined;
		const axiosConfig = {
			url,
			params,
			data,
			method,
			headers: withAuth ? authHeader : undefined,
		};

		return apiService(axiosConfig)
			.then(response => resolve(response.data))
			.catch(err => {
				const resData = err?.response?.data || {};
				const resStatus = err?.response?.status || 0;
				// Show multiple errors?
				if (
					(resData.showError || resStatus >= 500) &&
					Array.isArray(resData.errors) &&
					resData.errors.length > 0
				) {
					return reject(resData.errors);
				}
				return reject(resData);
			});
	});
};

export default class ApiService {
	static async get(props) {
		return run({ ...props, method: 'get' });
	}
	
	static async post(props) {
		return run({ ...props, method: 'post' });
	}

	static async put(props) {
		return run({ ...props, method: 'put' });
	}

	static async delete(props) {
		return run({ ...props, method: 'delete' });
	}
}
