// Libs
import React from 'react';
// Components
import { LogoIcon, Wrapper } from 'components';

const Spining = () => (
	<Wrapper height='500px' justifyContent='center' alignItems='center'>
		<LogoIcon spin size='m' />
	</Wrapper>
);

export default Spining;
