// Libs
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
// Utils
import GENERAL from 'utils/constants/general';

const { ENV } = GENERAL;

function Invoices(props) {
	const {
		routes,
		history,
		modeView,
		getPaycuts,
		getInvoicePays,
		getPaycutsModal,
		profile,
		mutate1Object,
		resetGetPaycuts,
		doResetApiData,
	} = props;

	useEffect(() => {
		resetGetPaycuts();
		doResetApiData('PAYMENTS');
		if (profile?.entity?.id && profile?.organization?.settings?.mainEntity) {
			if (profile.entity.id === profile.organization.settings.mainEntity) {
				mutate1Object('getPaycutsModal', {
					type: 'invoice',
				});
				getPaycuts({
					...getPaycutsModal,
					type: 'invoice',
				});
			} else {
				getInvoicePays([]);
			}
		}
	}, []);

	if (profile.entity.id === profile.organization.settings.mainEntity) {
		if (
			history.location.pathname === ENV.ROUTES.PATHS.BILLINGS_INVOICES &&
			modeView === 'cards'
		)
			return (
				<Redirect to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYCUTS_MOBILE} />
			);
		else if (
			history.location.pathname === ENV.ROUTES.PATHS.BILLINGS_INVOICES &&
			modeView === 'table'
		)
			return (
				<Redirect to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYCUTS_DESKTOP} />
			);
	} else {
		if (
			history.location.pathname === ENV.ROUTES.PATHS.BILLINGS_INVOICES &&
			modeView === 'cards'
		)
			return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYS_MOBILE} />;
		else if (
			history.location.pathname === ENV.ROUTES.PATHS.BILLINGS_INVOICES &&
			modeView === 'table'
		)
			return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYS_DESKTOP} />;
	}

	return (
		<>
			{routes.map((route, i) => {
				return (
					<Route
						key={i}
						path={route.path}
						render={oProps => (
							<route.component {...oProps} {...props} routes={route.routes} />
						)}
					/>
				);
			})}
		</>
	);
}

export default Invoices;
