// Libs
import React from 'react';
// Hooks
import { useOrderApi } from 'hooks';
// Components
import { Wrapper, FilesDragAndDrop } from 'components';
import { PROCESS_STATUS } from 'components/FilesDragAndDrop';
import {
	ReadOrderFromFiles,
	OrderFieldScraping,
	ImportUtils,
} from 'utils/Orders/ImportProcess';

const readOrderFromFiles = async ({ files, boundary, setProcessing }) => {
	setProcessing({
		status: ImportUtils.getStatus().LOADING,
		message: `Leyendo ${files.length} archivo${files.length !== 1 ? 's' : ''}`,
	});
	return new ReadOrderFromFiles(files, boundary).run();
};

const orderFieldScraping = async ({
	xlsxOrders,
	txtOrders,
	fields,
	formComponentValues,
	setProcessing,
}) => {
	setProcessing({
		status: ImportUtils.getStatus().LOADING,
		message: 'Obteniendo datos',
	});
	return new OrderFieldScraping(
		{ xlsxOrders, txtOrders },
		fields,
		formComponentValues,
	).run();
};

const setOrderAssignments = ({
	orders,
	dateFormat,
	validationTemplateId,
	setProcessing,
	getCreationAssignment,
}) => {
	setProcessing({
		status: ImportUtils.getStatus().LOADING,
		message: 'Procesando datos',
	});
	return getCreationAssignment(orders, {
		dateFormat,
		validationTemplateId,
	});
};

const setDragAndDropStatus = status => {
	return {
		[ImportUtils.getStatus().LOADING]: PROCESS_STATUS.LOADING,
		[ImportUtils.getStatus().SUCCESS]: PROCESS_STATUS.SUCCESS,
		[ImportUtils.getStatus().UNSUCCESS]: PROCESS_STATUS.UNSUCCESS,
	}[status];
};

const ImportButton = ({
	disabled,
	dateFormat,
	templateValidator,
	profile,
	formComponentValues,
	onSetState,
	onResetOrders,
	sendToast,
}) => {
	const [processing, setProcessing] = React.useState({
		status: undefined,
		message: undefined,
	});
	const { getCreationAssignment } = useOrderApi();

	const handleChange = async files => {
		try {
			if (!files || !files.length) return;

			// Reset income order states
			onResetOrders();

			const { xlsxOrders, txtOrders } = await readOrderFromFiles({
				files,
				boundary: templateValidator?.dataStructureObject?.boundary,
				setProcessing,
			});

			const scrapedOrders = await orderFieldScraping({
				xlsxOrders,
				txtOrders,
				fields: templateValidator?.dataStructureObject?.fields,
				formComponentValues,
				setProcessing,
			});

			if (!scrapedOrders.length) {
				sendToast({
					message: 'El archivo importado debe tener al menos un registro',
					type: 'warn',
				});
				onResetOrders();
				return;
			}

			const assignedOrders = await setOrderAssignments({
				orders: scrapedOrders,
				dateFormat,
				validationTemplateId: templateValidator.id,
				setProcessing,
				getCreationAssignment,
			});

			onSetState({
				preview: true,
				orders: assignedOrders,
			});
		} catch (err) {
			sendToast({
				message: err?.message,
				type: err?.type,
			});
			setProcessing({
				status: undefined,
			});
		}
	};

	return (
		<Wrapper width='100%' padding='0' margin='16px 0 0 0'>
			{profile?.organization?.algorithms?.orders?.import && (
				<FilesDragAndDrop
					disabled={disabled || !!processing.status}
					count={templateValidator?.dataStructureObject?.maxFiles}
					fileTypes={templateValidator?.dataStructureObject?.fileTypes}
					processing={{
						message: processing.message,
						status: setDragAndDropStatus(processing.status),
					}}
					handleChange={handleChange}
				/>
			)}
		</Wrapper>
	);
};

export default ImportButton;
