// Utils
import ImportUtils from '../../ImportUtils';

const fieldAnchor = ({ order, field, currentFieldValue }) => {
	if (!Array.isArray(field.fieldAnchor) || !field.fieldAnchor.length)
		return String(currentFieldValue).trim();

	let fieldValue = currentFieldValue;
	field.fieldAnchor.find(fieldAnchorId => {
		const checkedValue = !field.cast
			? order[fieldAnchorId]
			: ImportUtils.checkScrappedValueType({
					value: order[fieldAnchorId],
					castType: field.cast,
			  });

		if (checkedValue) {
			fieldValue = checkedValue;
			return true;
		}
	});

	return String(fieldValue).trim();
};

const concatFieldAnchor = ({ order, field, currentFieldValue }) => {
	if (!Array.isArray(field.concat) || !field.concat.length)
		return currentFieldValue;

	return field.concat.reduce((_fieldValue, concatField) => {
		if (
			!Array.isArray(concatField.fieldAnchor) ||
			!concatField.fieldAnchor.length
		)
			return _fieldValue;

		const concatBy = concatField.by || '';
		const concatValue = fieldAnchor({
			order,
			field: concatField,
			currentFieldValue,
		});
		if (concatValue) _fieldValue += concatBy + concatValue;
		return _fieldValue;
	}, currentFieldValue);
};

export default class FieldAnchorProcess {
	constructor(order, fields) {
		this.order = order;
		this.fields = fields;
	}

	run() {
		return this.fields.reduce((_order, field) => {
			let value = _order[field.id];

			value = fieldAnchor({
				order: this.order,
				field,
				currentFieldValue: value,
			});

			value = concatFieldAnchor({
				order: this.order,
				field,
				currentFieldValue: value,
			}).trim();

			_order[field.id] = value;

			return _order;
		}, this.order);
	}
}
