//Libs
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Components
import { LogoIcon, Badge, WarehouseIcon, Wrapper } from 'components';
// Hooks
import { useWarehouseApi } from 'hooks';
// Actions
import { sendToast } from 'screens/Dashboard/actions';

const catchErr = ({ err: { message, type }, isMounted, dispatch }) => {
	if (isMounted === false) return;
	dispatch(
		sendToast({
			message:
				message ||
				'Tuve un problema al realizar la acción. Por favor inténtalo más tarde',
			type: type || 'warn',
		}),
	);
};

const CollectorInventorySync = ({ otdId, setHandleSync = () => {} }) => {
	if (!otdId) return null;

	const dispatch = useDispatch();
	const { collectorInventorySyncStatus, collectorInventorySync } =
		useWarehouseApi();
	const [state, _setState] = useState({
		unsyncInventoryItems: 0,
		isloading: false,
	});

	const setSate = newState =>
		_setState(current => ({ ...current, ...newState }));

	const onSetInventory = () => {
		setSate({ isloading: true });
		collectorInventorySync(otdId)
			.then(inventory => {
				setSate({
					unsyncInventoryItems: inventory.filter(item => item.missingAmount > 0)
						.length,
					isloading: false,
				});
			})
			.catch(err => {
				setSate({ isloading: false });
				catchErr({ err, dispatch });
			});
	};

	useEffect(() => {
		let isMounted = true;

		collectorInventorySyncStatus(otdId)
			.then(
				inventory =>
					isMounted && setSate({ unsyncInventoryItems: inventory.length }),
			)
			.catch(err => catchErr({ err, isMounted, dispatch }));

		setHandleSync({
			onClick: onSetInventory,
			onEvent: state.unsyncInventoryItems && 'alert',
		});
		return () => {
			isMounted = false;
		};
	}, [otdId]);

	return (
		<Wrapper padding='0' onClick={onSetInventory}>
			{state.isloading ? (
				<LogoIcon spin />
			) : (
				<Wrapper padding='0' position='relative'>
					<Badge
						value={state.unsyncInventoryItems}
						event={!state.unsyncInventoryItems && 'check'}
					>
						<WarehouseIcon disabled />
					</Badge>
				</Wrapper>
			)}
		</Wrapper>
	);
};
export default CollectorInventorySync;
