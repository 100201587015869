import _ from 'underscore';
import { normalize } from './normalize';

function filterOption(input, option) {
	if (typeof option?.props?.children === 'string') {
		return (
			normalize(option.props.children)
				.toLowerCase()
				.indexOf(normalize(input).toLowerCase()) >= 0
		);
	}
	if (typeof option?.props?.children?.props?.children === 'string') {
		return (
			normalize(option.props.children.props.children)
				.toLowerCase()
				.indexOf(normalize(input).toLowerCase()) >= 0
		);
	}
	if (Array.isArray(option?.props?.children)) {
		return (
			option.props.children.filter(
				opt =>
					normalize(opt)
						.toLowerCase()
						.indexOf(normalize(input).toLowerCase()) >= 0,
			).length > 0
		);
	}
}

function isRecordSigned(signed, userId) {
	return !Array.isArray(signed)
		? false
		: signed.filter(sign => sign.user_id === userId)[0];
}

function isPaymentSigned(record, userId) {
	const { id, signed } = record;

	if (!Array.isArray(signed)) return false;
	if (!Array.isArray(id))
		return signed.filter(sign => sign.user_id === userId)[0];

	let signedToUser = true;
	const paymentIds = id.reduce((acc, paymentId) => {
		acc.push(paymentId);
		return acc;
	}, []);

	for (const paymentId of paymentIds) {
		const idx = _.findIndex(
			signed,
			sign => sign.payment_id === paymentId && sign.user_id === userId,
		);
		if (idx === -1) {
			signedToUser = false;
			break;
		}
	}
	return signedToUser;
}

export { filterOption, isRecordSigned, isPaymentSigned };
