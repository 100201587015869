import { createSelector } from 'reselect';
import { getMyEntityActiveUsers, getSpecificUsers } from 'utils/libs';

const selectUserState = state => state.users;
const selectLoginState = state => state.login;
const selectApiState = state => state.api;

export const selectMyProfile = createSelector(
	[selectLoginState],
	login => login.profile,
);
export const selectControl = createSelector(
	[selectUserState],
	({ control }) => control,
);
export const selectMyUserProfileEdit = createSelector(
	[selectUserState],
	({ userProfileModal }) => userProfileModal,
);
export const selectUserConfigurationEdit = createSelector(
	[selectUserState],
	({ setUserConfigurationModal }) => setUserConfigurationModal,
);
export const selectAllUsers = createSelector(
	[selectApiState],
	api => api.users.data,
);
export const selectMyEntityActiveUsers = createSelector(
	[selectApiState, selectLoginState],
	(api, login) =>
		getSpecificUsers(getMyEntityActiveUsers(api.users.data, login.profile), {
			entities: true,
			experts: true,
		}),
);
