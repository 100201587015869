// Libs
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// Utils
import GENERAL from 'utils/constants/general';
import { GlobalUtils } from 'utils';
import { getCoords } from 'utils/libs';
import DataFieldManagerUtils from 'components/DataFieldManager/DataFieldManagerUtils';
import { getUIValueProp } from 'components/DataFieldManager/OnlyReadField/OnlyReadField';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
import { updateDatasourceItem } from 'core/actions';
// Hooks
import useOrderApi from './useOrderApi';

const { UNSUCCESS } = GENERAL.ENV.STATUS;
const { ENTITY_ID, ENTITY_NAME } = DataFieldManagerUtils.getFieldIds();

const useOrderUserAssignment = () => {
	const dispatch = useDispatch();
	const { getUserAssignment, setUserAssignment } = useOrderApi();

	const [state, setState] = useState({
		isLoading: false,
		isSetting: false,
		data: [],
		status: undefined,
		message: undefined,
	});

	const onRetry = () =>
		setState(prev => ({ ...prev, status: undefined, message: undefined }));

	const onGetUserAssignment = ({
		serviceId,
		zoneId,
		centralId,
		searchValue,
	}) => {
		setState(prev => ({
			...prev,
			isLoading: true,
		}));
		getUserAssignment(
			[
				{
					serviceId,
					zoneId,
					centralId,
				},
			],
			{
				serviceId,
				searchValue,
			},
		)
			.then(res =>
				setState(prev => ({
					...prev,
					isLoading: false,
					data: GlobalUtils.checkArray(res[0]?.userAssignment),
				})),
			)
			.catch(err =>
				dispatch(sendToast({ message: err.message, type: err.type })),
			);
	};

	const onSetUserAssignment = async ({ field, rowData, option }) => {
		setState(prev => ({
			...prev,
			isSetting: true,
		}));

		getCoords()
			.then(({ coords }) => {
				setTimeout(() => {
					setUserAssignment(
						[
							{
								id: rowData.id,
								serviceId: rowData.serviceId,
								zoneId: rowData.zoneId,
								centralId: rowData.centralId,
								assignedTechId: option.id,
							},
						],
						{
							serviceId: rowData.serviceId,
							coords,
						},
					)
						.then(processedOrders => {
							const processedOrder = processedOrders.find(
								order => order.id === rowData.id,
							);
							if (!processedOrder) return;
							if (processedOrder.status === UNSUCCESS) {
								setState(prev => ({
									...prev,
									isSetting: false,
									status: processedOrder.status,
									message: processedOrder.message,
								}));
								return;
							}

							dispatch(
								updateDatasourceItem({
									updateRecords: [
										{
											id: rowData.id,
											fields: {
												[field.id]: option.id,
												[getUIValueProp(field) || field.name]: option.name,
												[ENTITY_ID]: option.entityId,
												[ENTITY_NAME]: option.entityName,
											},
										},
									],
								}),
							);

							setTimeout(() => {
								setState(prev => ({ ...prev, isSetting: false }));
							}, 100);
						})
						.catch(err => {
							setState(prev => ({
								...prev,
								isSetting: false,
								status: UNSUCCESS,
								message: err.message || '',
							}));
							dispatch(sendToast({ message: err.message, type: err.type }));
						});
				}, 100);
			})
			.catch(({ message, type }) => {
				setState(prev => ({ ...prev, isSetting: false }));
				dispatch(sendToast({ message, type }));
			});
	};

	return { state, onGetUserAssignment, onSetUserAssignment, onRetry };
};

export default useOrderUserAssignment;
