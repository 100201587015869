// Libs
import _ from 'underscore';
// Constants
import GENERAL from '../../utils/constants/general';
import Immutable from 'immutable';

const { APIDATA } = GENERAL;
const initialState = {
	// USERS
	roles: [],
	permissions: [],
	users: {
		loading: false,
		data: [],
	},
	// CLIENTS
	clients: {
		loading: false,
		data: [],
	},
	// CLIENT CATEGORIES
	clientCategories: {
		loading: false,
		data: [],
	},
	// CLIENT CONTRACTS
	contracts: {
		loading: false,
		data: [],
	},
	contractTypes: {
		loading: false,
		data: [],
	},
	contractFrequencies: {
		loading: false,
		data: [],
	},
	// ORDERS
	states: {
		loading: false,
		data: [],
	},
	events: {
		loading: false,
		data: [],
	},
	departments: {
		loading: false,
		data: [],
	},
	services: {
		loading: false,
		data: [],
	},
	// BILLINGS
	coins: {
		loading: false,
		data: [],
	},
	payments: {
		paycuts: {
			loading: false,
			data: [],
		},
		pays: {
			loading: false,
			data: [],
		},
		orders: {
			loading: false,
			data: [],
		},
		lines: {
			loading: false,
			data: [],
		},
		resume: {
			loading: false,
			payments: [],
			orders: [],
			lines: [],
		},
		resume_paycut: {
			loading: false,
			pays: [],
			paycuts: [],
		},
	},
	gains: {
		gain_orders: 0.0,
		gain_sales: 0.0,
		coin_symbol: null,
	},
	line_items: [],
	// WAREHOUSES
	transactionTypes: [],
	incomeMethods: [],
	measuredUnits: [],
	itemStates: [],
	warehousePackages: {
		loading: false,
		data: [],
	},
	warehouseAreas: {
		loading: false,
		data: [],
	},
	warehouseZones: {
		loading: false,
		data: [],
	},
	itemFamilies: [],
	articles: {
		loading: false,
		data: [],
	},
	warehouse: {
		toInvoice: {
			loading: false,
			data: [],
		},
		toUninstall: [],
	},
	warehouses: {
		loading: false,
		data: [],
	},
	stock: {
		warehouseId: undefined,
		ownerId: undefined,
		itemId: undefined,
		stateId: undefined,
		articles: {
			loading: false,
			data: [],
		},
		series: {
			loading: false,
			data: [],
		},
	},
	serieHistory: {
		isOpen: false,
		loading: false,
		data: [],
	},
	transactions: {
		loading: false,
		data: [],
		items: {
			loading: false,
			data: [],
		},
		inventory: {
			loading: false,
			data: [],
		},
	},
	// ZONES
	zones: {
		loading: false,
		data: [],
	},
	// TEMPLATES
	templates: {
		loading: false,
		data: [],
		request: {
			prop: undefined,
			type: undefined,
		},
	},
};

const apiReducer = (state = initialState, action) => {
	switch (action.type) {
		// GENERAL
		case APIDATA.MUTATE_DIRECT_PROPS: {
			const keyValuePairs = action.payload;
			return {
				...state,
				...keyValuePairs,
			};
		}
		case APIDATA.MUTATE_1OBJECT: {
			const { obj1Name, keyValuePairs } = action.payload;
			const newObj = { ...state[obj1Name], ...keyValuePairs };
			return {
				...state,
				[obj1Name]: newObj,
			};
		}
		// USERS
		case APIDATA.UPDATE_USER_COORD: {
			const newCoord = action.payload;
			const dataUsers = [...state.users.data];

			dataUsers.map(user => {
				if (user.id === newCoord.id) user.coords = newCoord.coords;
				return user;
			});

			return {
				...state,
				users: {
					...state.users,
					data: dataUsers,
				},
			};
		}
		// ORDERS
		case APIDATA.UPDATE_AMOUNT: {
			const orderData = Immutable.List(state.payments.orders.data).toJS();
			const idx = orderData.findIndex(order => order.id === action.payload.id);

			if (idx !== -1) orderData[idx].amount = action.payload.newAmount;

			return {
				...state,
				payments: {
					...state.payments,
					orders: {
						...state.payments.orders,
						data: orderData,
					},
				},
			};
		}
		// BILLINGS
		// WAREHOUSES
		// RESET MODULE DATA
		case APIDATA.RESET_MODULE_DATA: {
			const resetModuleData = action.payload;

			switch (resetModuleData) {
				case APIDATA.RESET.USERS:
					return {
						...state,
						roles: [],
						permissions: [],
					};
				case APIDATA.RESET.ORDERS:
					return {
						...state,
						states: {
							loading: false,
							data: [],
						},
						events: {
							loading: false,
							data: [],
						},
						departments: {
							loading: false,
							data: [],
						},
					};
				case APIDATA.RESET.PAYMENTS:
					return {
						...state,
						payments: {
							paycuts: {
								loading: false,
								data: [],
							},
							pays: {
								loading: false,
								data: [],
							},
							lines: {
								loading: false,
								data: [],
							},
							orders: {
								loading: false,
								data: [],
							},
							resume: {
								loading: false,
								payments: [],
								orders: [],
								lines: [],
							},
							resume_paycut: {
								loading: false,
								pays: [],
								paycuts: [],
							},
						},
					};
				case APIDATA.RESET.WAREHOUSES:
					return {
						...state,
						transactionTypes: [],
						incomeMethods: [],
						measuredUnits: [],
						itemStates: [],
						warehousePackages: {
							loading: false,
							data: [],
						},
						warehouseAreas: {
							loading: false,
							data: [],
						},
						warehouseZones: {
							loading: false,
							data: [],
						},
						itemFamilies: [],
						articles: {
							loading: false,
							data: [],
						},
						warehouses: {
							loading: false,
							data: [],
						},
						stock: {
							warehouseId: undefined,
							ownerId: undefined,
							itemId: undefined,
							stateId: undefined,
							articles: {
								loading: false,
								data: [],
							},
							series: {
								loading: false,
								data: [],
							},
						},
						transactions: {
							loading: false,
							data: [],
							items: {
								loading: false,
								data: [],
							},
							inventory: {
								loading: false,
								data: [],
							},
						},
					};
				case APIDATA.RESET.ZONES:
					return {
						...state,
						zones: {
							loading: false,
							data: [],
						},
					};
			}
		}
		default:
			return state;
	}
};

export default apiReducer;
