// Libs
import React from 'react';
import { connect } from 'react-redux';
// Selectors
import { selectSupervisorControl } from 'screens/Supervisor/selectors';
// Components
import { DisplayPicker } from 'core';
import ShowedPagination from 'core/ShowedPagination';
// Styles
import styles from './index.module.css';

const SupervisorToolbar = ({
	// State
	supervisorControl,
}) => {
	return (
		<div className={styles.stContainer}>
			<div className={styles.textDetailsContainer}>
				<ShowedPagination datasource={supervisorControl.data} />
			</div>
			<div className={styles.buttonsContainer}>
				<DisplayPicker />
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	supervisorControl: selectSupervisorControl(state),
});
export default connect(mapStateToProps, null)(SupervisorToolbar);
