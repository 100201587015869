import React from 'react';
import { Form, Input } from 'antd';
import { InputNumberStyle } from '../styledComponents';

const EditableCell = ({
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	editable,
	children,
	...restProps
}) => {
	const inputNode = inputType === 'number' ? <InputNumberStyle /> : <Input />;
	return (
		<td {...restProps}>
			{editing && editable ? (
				<Form.Item
					name={dataIndex}
					style={{ margin: 0 }}
					rules={[
						{
							required: true,
							message: `Please Input ${title}!`,
						},
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

export default EditableCell;
