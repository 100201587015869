//  Constants
import GENERAL from '../../utils/constants/general';
const { USERS } = GENERAL;

// GENERAL
export function mutateDirectProps(keyValuePairs) {
	return {
		type: USERS.MUTATE_DIRECT_PROPS,
		payload: keyValuePairs,
	};
}

export function mutate1Object(obj1Name, keyValuePairs) {
	return {
		type: USERS.MUTATE_1OBJECT,
		payload: { obj1Name, keyValuePairs },
	};
}

// UPDATES
export function changeWorkCode(userId, workCodeAssign) {
	if (workCodeAssign === '') workCodeAssign = null;
	return {
		type: USERS.CHANGE_WORK_CODE,
		payload: { userId, workCodeAssign },
	};
}

export function changeActivation(userId, active) {
	return {
		type: USERS.CHANGE_ACTIVATION,
		payload: { userId, active },
	};
}

export function setRelation(userId, relation) {
	return {
		type: USERS.CHANGE_RELATION,
		payload: { userId, relation },
	};
}

export function setPermissions(users, permissions) {
	return {
		type: USERS.SET_PERMISSIONS,
		payload: { users, permissions },
	};
}

// RESET
export const doReset = () => {
	return {
		type: USERS.RESET,
	};
};
