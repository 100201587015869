// Libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Components
import AddReply from './AddReply';
import CommentWithReplies from './CommentWithReplies';
// Utils
import { fetchComments, submitComment } from './utils';
// CSS
import styles from './CommentsBox.module.css';
import LogoIcon from 'components/Icons/LogoIcon';
const CommentsBox = ({
	resourceKey,
	resourceId,
	showReplyBox,
	// State
	profile,
}) => {
	const { id: userId } = profile.user;

	const [resTotalCommentCount, setResTotalCommentCount] = useState(0);
	const [comments, setComments] = useState([]);
	const [resIsFetching, setResIsFetching] = useState(false);

	const [isMounted, setIsMounted] = useState(true);
	const [wasReset, setWasReset] = useState(false);

	const loadData = async ({ reload = false } = {}) => {
		setResIsFetching(true);
		const { comments: data, totalRowCount: totalCommentCount } =
			await fetchComments({
				resourceId,
				resourceKey,
				lastRowId: reload ? 0 : comments.at(-1)?.id ?? 0,
				limit: reload && comments?.length ? comments.length + 1 : 10,
			});
		// avoid memory leaks
		if (!isMounted) return;

		if (Array.isArray(data)) {
			setComments(reload ? [...data] : prev => [...prev, ...data]);
		}
		if (totalCommentCount !== undefined) {
			setResTotalCommentCount(totalCommentCount);
		}
		setResIsFetching(false);
	};

	const resetState = () => {
		setComments([]);
		setResTotalCommentCount(0);
		setResIsFetching(false);
		setResIsSubmitting(false);
		setInputValue('');
		setWasReset(true);
	};

	// TODO: (e) this was a quick fix, it should be improved
	useEffect(() => {
		return () => setIsMounted(false);
	}, []);

	useEffect(() => {
		if (wasReset) {
			loadData();
			setWasReset(false);
		}
	}, [wasReset]);

	useEffect(() => {
		resetState();
	}, [resourceId]);

	// TOP LEVEL FETCHING
	const hasCommentsToFetch = comments.length < resTotalCommentCount;
	const commentsLeftToFetchCount = resTotalCommentCount - comments.length;

	const handleViewMoreComments = async () => {
		setResIsFetching(true);
		const { totalRowCount: totalCommentCount, comments: data } =
			await fetchComments({
				resourceId,
				resourceKey,
				limit: 10,
				lastRowId: comments.at(-1)?.id ?? 0,
			});

		// avoid memory leaks
		if (!isMounted) return;

		setResIsFetching(false);
		if (Array.isArray(data)) {
			setComments(comments => [...comments, ...data]);
		}
		setResTotalCommentCount(totalCommentCount);
	};

	const [inputValue, setInputValue] = useState('');
	const handleChange = e => setInputValue(e.target.value);

	// TOP LEVEL SUBMISSION
	const [resIsSubmitting, setResIsSubmitting] = useState(false);
	const handleSubmit = async e => {
		if (e.target.value !== null && e.target.value !== undefined) {
			const value = e.target.value.trim();
			if (value !== '') {
				setResIsSubmitting(true);
				const data = await submitComment({
					resourceId,
					resourceKey,
					content: value,
				});
				setResIsSubmitting(false);
				if (data !== undefined) {
					loadData({ reload: true });
				}
			}
		}
		setTimeout(() => setInputValue(''), 200);
	};

	const handleRepliesFetch = async (commentId, lastRowId) => {
		const commentIndex = comments.findIndex(com => com.id === commentId);

		if (commentIndex !== -1) {
			const { totalRowCount: totalReplies, comments: data } =
				await fetchComments({
					resourceId,
					resourceKey,
					parentId: commentId,
					limit: 10,
					lastRowId,
				});

			// avoid memory leaks
			if (!isMounted) return;

			if (Array.isArray(data)) {
				setComments(prevComms => {
					prevComms[commentIndex].replies = [
						...(prevComms[commentIndex]?.replies ?? []),
						...data,
					];
					prevComms[commentIndex].totalRepliesCount = totalReplies;

					return [...prevComms];
				});
			}
		}
	};

	const handleSubmitReply = async (commentId, content) => {
		const commentIndex = comments.findIndex(com => com.id === commentId);

		// guard against non-existing comment
		if (commentIndex === -1) return;
		const subRes = await submitComment({
			resourceId,
			resourceKey,
			parentId: commentId,
			content,
		});

		// guard against submission failure
		if (!subRes) return;
		const { comments: data, totalRowCount: totalRepliesCount } =
			await fetchComments({
				resourceId,
				resourceKey,
				lastRowId: 0,
				limit: comments[commentIndex].replies?.length
					? comments[commentIndex].replies?.length + 1
					: 10,
				parentId: commentId,
			});

		// avoid memory leaks
		if (!isMounted) return;

		if (data) {
			setComments(prevComms => {
				prevComms[commentIndex].replies = [...data];
				prevComms[commentIndex].totalRepliesCount = totalRepliesCount;
				return [...prevComms];
			});
		}
	};

	return !comments.length && !showReplyBox ? null : (
		<div className={styles.commentsBoxContainer}>
			{/* ADD REPLY */}
			{showReplyBox && (
				<AddReply
					userId={userId}
					value={inputValue}
					onChange={handleChange}
					onSubmit={handleSubmit}
					submitting={resIsSubmitting}
				/>
			)}

			{/* COMMENTS */}
			{comments.map(comment => (
				<CommentWithReplies
					key={comment.id}
					comment={comment}
					onFetchReplies={handleRepliesFetch}
					profileUserId={userId}
					submitReply={handleSubmitReply}
				>
					{Array.isArray(comment?.replies) &&
						comment.replies.map(reply => (
							<CommentWithReplies
								noReplies
								key={reply.id}
								comment={reply}
								onFetchReplies={handleRepliesFetch}
								profileUserId={userId}
							/>
						))}
				</CommentWithReplies>
			))}

			{/* VIEW MORE REPLIES */}
			{resIsFetching ? (
				<div className={styles.loadingWrapper}>
					<LogoIcon spin />
				</div>
			) : (
				hasCommentsToFetch && (
					<div className={styles.loadingWrapper}>
						<a
							className={styles.viewMoreLinkLarge}
							onClick={handleViewMoreComments}
						>
							Ver {commentsLeftToFetchCount} más
						</a>
					</div>
				)
			)}
		</div>
	);
};

const mapStateToProps = state => ({
	profile: selectProfile(state),
});

export default connect(mapStateToProps, null)(CommentsBox);
