export default Object.freeze({
	NOTIFICATIONS: {
		TYPES: {
			GLOBAL: 'GLOBAL',
			COPY_TO_CLIPBOARD: 'COPY_TO_CLIPBOARD',
		},
	},
	DIVISION_ID: {
		ENTITY: 1,
		ADM: 2,
		EXP: 3,
	},
	ENV: {
		DEVICE_TYPE:
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent,
			)
				? 'Mobile'
				: 'Desktop',
		MODE_VIEW: {
			MOBILE: 'cards',
			DESKTOP: 'table',
		},
		RESOURCES: {
			KEYS: {
				PHOTO_REPORT: 'photoReport',
				COLLECTOR: 'collector',
				COLLECTOR_PHOTO: 'collectorPhoto',
			},
		},
		STATUS: {
			INITIAL: undefined,
			LOADING: 'loading',
			ERROR: 'error',
			SUCCESS: 'success',
			UNSUCCESS: 'unsuccess',
		},
		MANAGE_PROPS: {
			PROPS: {
				COLLECTOR_AUTO_FILL: {
					NAME: 'autoFillCollector',
				},
			},
			FLAGS: {
				FREEZE_TIMER: 'freezeTimer',
			},
		},
		// UPLOAD_RESOURCE
		UPLOAD_RESOURCE: {
			RESOURCE_TYPES: {
				COLLECTOR_PHOTO: 'collectorPhoto',
				PROFILE_PHOTO: 'profilePhoto',
			},
			STATUS: {
				LOADING: 'loading',
				ERROR: 'error',
				SUCCESS: 'success',
			},
			COLLECTOR_PHOTO: {
				COMPRESS: {
					STRATEGIES: {
						PHOTON: 'photon',
						PICA: 'pica',
						RESAMPLE: 'resample',
						BACKEND: 'backend',
					},
				},
			},
		},
		// DEPARTMENTS
		DEPARTMENTS: {
			PROPS: {
				EXP: {
					NAME: 'exp',
					ACTIONS: {
						ORDERS: {
							BILL_ORDER: { NAME: 'billOrder' },
							AUDIT_ORDER: { NAME: 'auditOrder' },
						},
					},
				},
				COLLECTOR_PHOTO: {
					NAME: 'collectorPhoto',
					KEYS: {
						REQUIRED: 'required',
						GET_COORDS: 'getCoords',
						CAPTURE: 'capture',
						COMPRESS: {
							NAME: 'compress',
							SIDE: 'side',
							FORMAT: 'format',
							MAX_DIM: 'maxDim',
							QUALITY: 'quality',
						},
					},
				},
				EDITABLE_INVENTORY: {
					NAME: 'editableInventory',
					INTERVAL: 'interval',
					UNIT: 'unit',
				},
			},
			ICON: {
				TYPES: {
					INITIAL: 'initial',
					IMAGE: 'image',
					ICON: 'icon',
				},
				ICONS: {
					CLARO: 'claro',
					ICE: 'ice',
				},
			},
		},
		// SERVICES
		SERVICES: {
			ICON: {
				TYPES: {
					INITIAL: 'initial',
					IMAGE: 'image',
				},
			},
		},
		// ORDER_EVENTS
		ORDER_EVENTS: {
			PROPS: {
				AUDITED_ORDER: 'auditedOrder',
			},
		},
		// PAYCUTS
		PAYCUTS: {
			FUSION_PAYCUTS_KEY: 'fusion_',
		},
		// REPORTS
		REPORTS: {
			OUTPUT_FORMATS: {
				XLSX: 'XLSX',
				PDF: 'PDF',
			},
		},
		// ORDER TRANSACTION DOCS
		ORDER_TRANSACTION_DOCS: {
			STATES: {
				OPEN: 'OPEN', // Utilizada cuando por ejemplo se sube una foto de una transacción (auditedOrder) que aún no se ha completado/cerrado
				CLOSED: 'CLOSED', // Utilizada cuando el usuario necesita cerrar una transacción, como una revisión de orden (auditedOrder)
				CONFIRM: 'CONFIRM', // Utilizada para que un cliente confirme un evento que se está reportando de su orden
				APPROVED: 'APPROVED', // Utilizada por un administrador cuando necesita aprobar un evento reportado
				DENIED: 'DENIED', /// /Utilizada por un administrador cuando necesita rechazar un evento reportado
			},
		},
		CLIENTS: {
			QUERY_VARIANTS: {
				GET_LATEST_CLIENTS: 'getLatestClients',
			},
		},
		ORDERS: {
			CREATE: {
				TABS: {
					CREATE_ORDER: 'createOrder',
					CREATE_CLIENT: 'createClient',
					CREATE_CONTRACT: 'createContract',
				},
			},
		},
		TEMPLATES: {
			TYPES: {
				PHOTO_REPORT: 1,
				INVENTORY_VALIDATOR: 2,
				AUDIT_TO_EXPERT: 3,
				AUDIT_TO_ORDER: 4,
				AUDIT_TO_WAREHOUSE: 5,
				PAYMENT_INVOICE: 6,
				IMPORT_ORDERS: 8,
				CREATE_ORDER: 9,
			},
			PROPS: {
				VALIDATE_CREATE_ORDER: 'validateCreateOrder',
				VALIDATE_IMPORT_ORDERS: 'validateImportOrders',
			},
		},
		// ROUTES
		ROUTES: {
			IDS: {
				USERS: 'urs',
				COMMAND_CENTER: 'cmd_cntr',
				ORDERS: 'ors',
				CONFLICT_IMPORTED_ORDERS: 'cnft_iptd_ords',
				BILLINGS: 'bllngs',
				BILLINGS_PAYMENTS: 'bllngs_pmts',
				BILLINGS_PAYMENTS_PAYCUTS_MOBILE: 'bllngs_pmts_pycts_mble',
				BILLINGS_PAYMENTS_PAYCUTS_DESKTOP: 'bllngs_pmts_pycts_dsktp',
				BILLINGS_PAYMENTS_PAYS_MOBILE: 'bllngs_pmts_pays_mble',
				BILLINGS_PAYMENTS_PAYS_DESKTOP: 'bllngs_pmts_pays_dsktp',
				BILLINGS_PAYMENTS_LINES_MOBILE: 'bllngs_pmts_lns_mble',
				BILLINGS_PAYMENTS_LINES_DESKTOP: 'bllngs_pmts_lns_dsktp',
				BILLINGS_PAYMENTS_ORDERS_MOBILE: 'bllngs_pmts_ors_mble',
				BILLINGS_PAYMENTS_ORDERS_DESKTOP: 'bllngs_pmts_ors_dsktp',
				BILLINGS_INVOICES: 'bllngs_invs',
				BILLINGS_INVOICES_PAYCUTS_MOBILE: 'bllngs_invs_pycts_mble',
				BILLINGS_INVOICES_PAYCUTS_DESKTOP: 'bllngs_invs_pycts_dsktp',
				BILLINGS_INVOICES_PAYS_MOBILE: 'bllngs_invs_pays_mble',
				BILLINGS_INVOICES_PAYS_DESKTOP: 'bllngs_invs_pays_dsktp',
				BILLINGS_INVOICES_LINES_MOBILE: 'bllngs_invs_lns_mble',
				BILLINGS_INVOICES_LINES_DESKTOP: 'bllngs_invs_lns_dsktp',
				BILLINGS_INVOICES_ORDERS_MOBILE: 'bllngs_invs_ors_mble',
				BILLINGS_INVOICES_ORDERS_DESKTOP: 'bllngs_invs_ors_dsktp',
				WAREHOUSES: 'wrs',
				WAREHOUSES_MANAGE: 'wrs_mng',
				WAREHOUSES_MANAGE_MOBILE: 'wrs_mng_mble',
				WAREHOUSES_MANAGE_DESKTOP: 'wrs_mng_dsktp',
				WAREHOUSES_STOCK_ARTICLES_MOBILE: 'wrs_stk_arts_mble',
				WAREHOUSES_STOCK_ARTICLES_DESKTOP: 'wrs_stk_arts_dsktp',
				WAREHOUSES_STOCK_SERIES_MOBILE: 'wrs_stk_srs_mble',
				WAREHOUSES_STOCK_SERIES_DESKTOP: 'wrs_stk_srs_dsktp',
				WAREHOUSES_ARTICLES: 'wrs_arts',
				WAREHOUSES_ARTICLES_MOBILE: 'wrs_arts_mble',
				WAREHOUSES_ARTICLES_DESKTOP: 'wrs_arts_dsktp',
				WAREHOUSES_TRANSACTIONS: 'wrs_trs',
				WAREHOUSES_TRANSACTIONS_MOBILE: 'wrs_trs_mble',
				WAREHOUSES_TRANSACTIONS_DESKTOP: 'wrs_trs_dsktp',
				WAREHOUSES_TRANSACTIONS_NEW_ENTRY_MOBILE: 'wrs_trs_entry_mble',
				WAREHOUSES_TRANSACTIONS_NEW_ENTRY_DESKTOP: 'wrs_trs_entry_dsktp',
				WAREHOUSES_TRANSACTIONS_INVENTORY_MOBILE: 'wrs_trs_inty_mble',
				WAREHOUSES_TRANSACTIONS_INVENTORY_DESKTOP: 'wrs_trs_inty_dsktp',
				WAREHOUSES_TRANSACTIONS_ITEMS_MOBILE: 'wrs_trs_itms_mble',
				WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP: 'wrs_trs_itms_dsktp',
				WAREHOUSES_TRANSACTIONS_RECEPTION: 'wrs_trs_rcptn',
				WAREHOUSES_CONTROL_SERIES: 'wrs_ctrl_srs',
				WAREHOUSES_CONTROL_SERIES_MOBILE: 'wrs_ctrl_srs_mble',
				WAREHOUSES_CONTROL_SERIES_DESKTOP: 'wrs_ctrl_srs_dsktp',
				ANALYTICS: 'alytcs',
				REPORTS: 'rpts',
				MARKETPLACE: 'mktp',
				SUPERVISOR: 'spvr',
				SUPERVISOR_TARGET: 'spvr_trgt',
			},
			ITEMS: {
				USERS: 'Usuarios',
				COMMAND_CENTER: 'Centro de Control',
				ORDERS: 'Órdenes',
				BILLINGS: 'Facturacion',
				BILLINGS_PAYMENTS: 'Pagos',
				BILLINGS_INVOICES: 'Cobros',
				WAREHOUSES: 'Inventario',
				WAREHOUSES_MANAGE: 'Almacenes',
				WAREHOUSES_CONTROL_SERIES: 'Series',
				WAREHOUSES_ARTICLES: 'Artículos',
				WAREHOUSES_TRANSACTIONS: 'Transacciones',
				ANALYTICS: 'Análisis & KPIs',
				REPORTS: 'Informes',
				MARKETPLACE: 'Tienda',
				SUPERVISOR: 'Supervisor',
			},
			TITLES: {
				DASHBOARD: 'Synapse ADM',
				USERS: 'Usuarios',
				COMMAND_CENTER: 'Centro de Control',
				ORDERS: 'Órdenes',
				CONFLICT_IMPORTED_ORDERS: 'Conflictos de importación',
				BILLINGS_PAYMENTS_PAYCUTS_MOBILE: 'Cortes',
				BILLINGS_PAYMENTS_PAYCUTS_DESKTOP: 'Cortes de Pago',
				BILLINGS_PAYMENTS_PAYS_MOBILE: 'Pagos',
				BILLINGS_PAYMENTS_PAYS_DESKTOP: 'Pagos',
				BILLINGS_PAYMENTS_LINES_MOBILE: 'Lineas',
				BILLINGS_PAYMENTS_LINES_DESKTOP: 'Lineas',
				BILLINGS_PAYMENTS_ORDERS_MOBILE: 'Órdenes',
				BILLINGS_PAYMENTS_ORDERS_DESKTOP: 'Órdenes',
				BILLINGS_INVOICES_PAYCUTS_MOBILE: 'Cortes',
				BILLINGS_INVOICES_PAYCUTS_DESKTOP: 'Cortes de Cobros',
				BILLINGS_INVOICES_PAYS_MOBILE: 'Cobros',
				BILLINGS_INVOICES_PAYS_DESKTOP: 'Cobros',
				BILLINGS_INVOICES_LINES_MOBILE: 'Lineas',
				BILLINGS_INVOICES_LINES_DESKTOP: 'Lineas',
				BILLINGS_INVOICES_ORDERS_MOBILE: 'Órdenes',
				BILLINGS_INVOICES_ORDERS_DESKTOP: 'Órdenes',
				WAREHOUSES_MANAGE_MOBILE: 'Almacenes',
				WAREHOUSES_MANAGE_DESKTOP: 'Almacenes',
				WAREHOUSES_STOCK_ARTICLES_MOBILE: 'Inventario',
				WAREHOUSES_STOCK_ARTICLES_DESKTOP: 'Inventario',
				WAREHOUSES_STOCK_SERIES_MOBILE: 'Series',
				WAREHOUSES_STOCK_SERIES_DESKTOP: 'Series',
				WAREHOUSES_CONTROL_SERIES_MOBILE: 'Series',
				WAREHOUSES_CONTROL_SERIES_DESKTOP: 'Series',
				WAREHOUSES_ARTICLES_MOBILE: 'Artículos',
				WAREHOUSES_ARTICLES_DESKTOP: 'Artículos',
				WAREHOUSES_TRANSACTIONS_MOBILE: 'Transax',
				WAREHOUSES_TRANSACTIONS_DESKTOP: 'Transacciones',
				WAREHOUSES_TRANSACTIONS_NEW_ENTRY_MOBILE: 'Ingresar',
				WAREHOUSES_TRANSACTIONS_NEW_ENTRY_DESKTOP: 'Ingresar',
				WAREHOUSES_TRANSACTIONS_INVENTORY_MOBILE: 'Transferir',
				WAREHOUSES_TRANSACTIONS_INVENTORY_DESKTOP: 'Transferir',
				WAREHOUSES_TRANSACTIONS_ITEMS_MOBILE: 'Transfiriendo',
				WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP: 'Transfiriendo',
				WAREHOUSES_TRANSACTIONS_RECEPTION: 'Recibiendo',
				WAREHOUSES_REPORTS_MOBILE: 'Reportes',
				WAREHOUSES_REPORTS_DESKTOP: 'Reportes',
				REPORTS: 'Informes',
				ANALYTICS: 'Análisis & KPIs',
				MARKETPLACE: 'Tienda',
				SUPERVISOR: 'Supervisor',
				SUPERVISOR_TARGET: 'Supervisando',
			},
			PATHS: {
				ROOT: '/',
				LOGIN: '/login',
				SIGNUP: '/signup',
				RECOVERY_PASSWORD: '/recovery-password',
				DASHBOARD: '/dashboard',
				USERS: '/dashboard/users',
				COMMAND_CENTER: '/dashboard/command-center',
				ORDERS: '/dashboard/orders',
				CONFLICT_IMPORTED_ORDERS: '/dashboard/conflict/imported-orders',
				BILLINGS: '/dashboard/billings',
				BILLINGS_PAYMENTS: '/dashboard/billings/payments',
				BILLINGS_PAYMENTS_PAYCUTS_MOBILE:
					'/dashboard/billings/payments/paycuts/mobile',
				BILLINGS_PAYMENTS_PAYCUTS_DESKTOP:
					'/dashboard/billings/payments/paycuts/desktop',
				BILLINGS_PAYMENTS_PAYS_MOBILE:
					'/dashboard/billings/payments/pays/mobile',
				BILLINGS_PAYMENTS_PAYS_DESKTOP:
					'/dashboard/billings/payments/pays/desktop',
				BILLINGS_PAYMENTS_LINES_MOBILE:
					'/dashboard/billings/payments/lines/mobile',
				BILLINGS_PAYMENTS_LINES_DESKTOP:
					'/dashboard/billings/payments/lines/desktop',
				BILLINGS_PAYMENTS_ORDERS_MOBILE:
					'/dashboard/billings/payments/orders/mobile',
				BILLINGS_PAYMENTS_ORDERS_DESKTOP:
					'/dashboard/billings/payments/orders/desktop',
				BILLINGS_INVOICES: '/dashboard/billings/invoices',
				BILLINGS_INVOICES_PAYCUTS_MOBILE:
					'/dashboard/billings/invoices/paycuts/mobile',
				BILLINGS_INVOICES_PAYCUTS_DESKTOP:
					'/dashboard/billings/invoices/paycuts/desktop',
				BILLINGS_INVOICES_PAYS_MOBILE:
					'/dashboard/billings/invoices/pays/mobile',
				BILLINGS_INVOICES_PAYS_DESKTOP:
					'/dashboard/billings/invoices/pays/desktop',
				BILLINGS_INVOICES_LINES_MOBILE:
					'/dashboard/billings/invoices/lines/mobile',
				BILLINGS_INVOICES_LINES_DESKTOP:
					'/dashboard/billings/invoices/lines/desktop',
				BILLINGS_INVOICES_ORDERS_MOBILE:
					'/dashboard/billings/invoices/orders/mobile',
				BILLINGS_INVOICES_ORDERS_DESKTOP:
					'/dashboard/billings/invoices/orders/desktop',
				WAREHOUSES: '/dashboard/warehouses',
				WAREHOUSES_MANAGE: '/dashboard/warehouses/manage',
				WAREHOUSES_MANAGE_MOBILE: '/dashboard/warehouses/manage/mobile',
				WAREHOUSES_MANAGE_DESKTOP: '/dashboard/warehouses/manage/desktop',
				WAREHOUSES_STOCK_ARTICLES_MOBILE:
					'/dashboard/warehouses/manage/stock/articles/mobile',
				WAREHOUSES_STOCK_ARTICLES_DESKTOP:
					'/dashboard/warehouses/manage/stock/articles/desktop',
				WAREHOUSES_STOCK_SERIES_MOBILE:
					'/dashboard/warehouses/manage/stock/series/mobile',
				WAREHOUSES_STOCK_SERIES_DESKTOP:
					'/dashboard/warehouses/manage/stock/series/desktop',
				WAREHOUSES_CONTROL_SERIES: '/dashboard/warehouses/control-series',
				WAREHOUSES_CONTROL_SERIES_MOBILE:
					'/dashboard/warehouses/control-series/mobile',
				WAREHOUSES_CONTROL_SERIES_DESKTOP:
					'/dashboard/warehouses/control-series/desktop',
				WAREHOUSES_ARTICLES: '/dashboard/warehouses/articles',
				WAREHOUSES_ARTICLES_MOBILE: '/dashboard/warehouses/articles/mobile',
				WAREHOUSES_ARTICLES_DESKTOP: '/dashboard/warehouses/articles/desktop',
				WAREHOUSES_TRANSACTIONS: '/dashboard/warehouses/transactions',
				WAREHOUSES_TRANSACTIONS_MOBILE:
					'/dashboard/warehouses/transactions/mobile',
				WAREHOUSES_TRANSACTIONS_DESKTOP:
					'/dashboard/warehouses/transactions/desktop',
				WAREHOUSES_TRANSACTIONS_NEW_ENTRY_MOBILE:
					'/dashboard/warehouses/transactions/new-entry/mobile',
				WAREHOUSES_TRANSACTIONS_NEW_ENTRY_DESKTOP:
					'/dashboard/warehouses/transactions/new-entry/desktop',
				WAREHOUSES_TRANSACTIONS_INVENTORY_MOBILE:
					'/dashboard/warehouses/transactions/inventory/mobile',
				WAREHOUSES_TRANSACTIONS_INVENTORY_DESKTOP:
					'/dashboard/warehouses/transactions/inventory/desktop',
				WAREHOUSES_TRANSACTIONS_ITEMS_MOBILE:
					'/dashboard/warehouses/transactions/items/mobile',
				WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP:
					'/dashboard/warehouses/transactions/items/desktop',
				WAREHOUSES_TRANSACTIONS_RECEPTION:
					'/dashboard/warehouses/transactions/reception',
				WAREHOUSES_REPORTS: '/dashboard/warehouses/reports',
				WAREHOUSES_REPORTS_MOBILE: '/dashboard/warehouses/reports/mobile',
				WAREHOUSES_REPORTS_DESKTOP: '/dashboard/warehouses/reports/desktop',
				WAREHOUSE_CATALOG: '/dashboard/warehouse/catalog',
				ANALYTICS: '/dashboard/analytics',
				REPORTS: '/dashboard/reports',
				MARKETPLACE: '/dashboard/marketplace',
				SUPERVISOR: '/dashboard/supervisor',
				SUPERVISOR_TARGET:
					'/dashboard/supervisor/:action/:dispatchableType/:dispatchableId/:data',
			},
		},
	},
	APIDATA: {
		// GENERAL
		MUTATE_DIRECT_PROPS: 'APIDATA_MUTATE_DIRECT_PROPS',
		MUTATE_1OBJECT: 'APIDATA_MUTATE_1OBJECT',
		// CLIENTS
		GET_CLIENTS_WITH_QUERY_VARIANT: 'APIDATA_GET_CLIENTS_WITH_QUERY_VARIANT',
		GET_CONTRACTS_BY_CLIENT_ID: 'APIDATA_GET_CONTRACTS_BY_CLIENT_ID',
		GET_CONTRACT_TYPES: 'APIDATA_GET_CONTRACT_TYPES',
		GET_FREQUENCIES: 'APIDATA_GET_FREQUENCIES',
		// USERS
		GET_ROLES: 'APIDATA_GET_ROLES',
		GET_PERMISSIONS: 'APIDATA_GET_PERMISSIONS',
		GET_ORGANIZATION_USERS: 'APIDATA_GET_ORGANIZATION_USERS',
		UPDATE_USER_COORD: 'APIDATA_UPDATE_USER_COORD',
		// ORDERS
		GET_DEPARTMENTS: 'APIDATA_GET_DEPARTMENTS',
		GET_SERVICES: 'APIDATA_GET_SERVICES',
		GET_ORDER_STATES: 'APIDATA_GET_ORDER_STATES',
		GET_ORDER_EVENTS: 'APIDATA_GET_ORDER_EVENTS',
		UPDATE_AMOUNT: 'APIDATA_UPDATE_AMOUNT',
		// CLIENT CATEGORIES
		GET_CLIENT_CATEGORIES: 'APIDATA_GET_CLIENT_CATEGORIES',
		// PAYMENTS
		GET_COINS: 'APIDATA_GET_COINS',
		GET_PAYCUTS: 'APIDATA_GET_PAYCUTS',
		GET_PAYMENTS: 'APIDATA_GET_PAYMENTS',
		GET_PAYCUT_RESUME: 'APIDATA_GET_PAYCUT_RESUME',
		GET_PAYMENT_RESUME: 'APIDATA_GET_PAYMENT_RESUME',
		GET_PAYMENTS_LINES: 'APIDATA_GET_PAYMENTS_LINES',
		GET_PAYMENTS_ORDERS: 'APIDATA_GET_PAYMENTS_ORDERS',
		UPDATE_PAYMENT_ID_LINE: 'APIDATA_UPDATE_PAYMENT_ID_LINE',
		GET_GAINS_ENTITY: 'APIDATA_GET_GAINS_ENTITY',
		GET_LINE_ITEMS: 'APIDATA_GET_LINE_ITEMS',
		// INVOICES
		GET_INVOICE_PAYS: 'APIDATA_GET_INVOICE_PAYS',
		// WAREHOUSES
		GET_TRANSACTION_TYPES: 'APIDATA_GET_TRANSACTION_TYPES',
		GET_INCOME_METHODS: 'APIDATA_GET_INCOME_METHODS',
		GET_MEASURED_UNITS: 'APIDATA_GET_MEASURED_UNITS',
		GET_ITEM_STATES: 'APIDATA_GET_ITEM_STATES',
		GET_WAREHOUSE_PACKAGES: 'APIDATA_GET_WAREHOUSE_PACKAGES',
		GET_WAREHOUSE_ZONES_AREAS: 'APIDATA_GET_ZONES_AREAS',
		GET_ITEM_FAMILIES: 'APIDATA_GET_ITEM_FAMILIES',
		GET_ARTICLES: 'APIDATA_GET_ARTICLES',
		GET_WAREHOUSES: 'APIDATA_GET_WAREHOUSES',
		GET_STOCK_ARTICLES: 'APIDATA_GET_STOCK_ARTICLES',
		GET_STOCK_SERIES: 'APIDATA_GET_STOCK_SERIES',
		GET_CONTROL_SERIES: 'APIDATA_GET_CONTROL_SERIES',
		GET_TRANSACTION_DOCS: 'APIDATA_GET_TRANSACTION_DOCS',
		GET_INVENTORY_TO_TRANSFER: 'APIDATA_GET_INVENTORY_TO_TRANSFER',
		GET_ITEMS_TRANSACTION_DOCS: 'APIDATA_GET_ITEMS_TRANSACTION_DOCS',
		GET_SERIE_HISTORY: 'APIDATA_GET_SERIE_HISTORY',
		UPDATE_WTD_SIGNER: 'APIDATA_UPDATE_WTD_SIGNER',
		SIGN_WTD: 'APIDATA_SIGN_WTD',
		// ZONES
		GET_ZONES_TO_ASSIGN_ORDERS: 'APIDATA_GET_ZONES_TO_ASSIGN_ORDERS',
		// TEMPLATES
		GET_TEMPLATES: 'APIDATA_GET_TEMPLATES',
		// RESET MODULE DATA
		RESET_MODULE_DATA: 'APIDATA_RESET_MODULE_DATA',
		// RESETS
		RESET: {
			USERS: 'USERS',
			ORDERS: 'ORDERS',
			PAYMENTS: 'PAYMENTS',
			WAREHOUSES: 'WAREHOUSES',
			ZONES: 'ZONES',
		},
	},
	SIGNUP: {
		MUTATE_DIRECT_PROPS: 'SIGNUP_MUTATE_DIRECT_PROPS',
		SIGNUP: 'SIGNUP_LOGIN',
		GET_SIGNUP_DATA: 'SIGNUP_GET_SIGNUP_DATA',
		TOAST_MESSAGE: 'SIGNUP_TOAST_MESSAGE',
		RESET_REGISTRY: 'SIGNUP_RESET_REGISTRY',
		RESET: 'SIGNUP_RESET',
	},
	LOGIN: {
		CHANGE_INPUT: 'LOGIN_CHANGE_INPUT',
		SHOW_PASSWORD: 'LOGIN_SHOW_PASSWORD',
		LOGIN: 'LOGIN_LOGIN',
		LOGGED: 'LOGIN_LOGGED',
		DO_LOGOUT: 'LOGIN_DO_LOGOUT',
		MUTATE_DIRECT_PROPS: 'LOGIN_MUTATE_DIRECT_PROPS',
		SHOW_NEW_PASSWORD_INPUT: 'LOGIN_SHOW_NEW_PASSWORD_INPUT',
		SET_MY_PROFILE: 'LOGIN_SET_MY_PROFILE',
		GET_MY_PROFILE: 'LOGIN_GET_MY_PROFILE',
		RESET: 'LOGIN_RESET',
	},
	DASHBOARD: {
		SHOW_NAV_BAR: 'DASHBOARD_SHOW_NAV_BAR',
		MUTATE_DIRECT_PROPS: 'DASHBOARD_MUTATE_DIRECT_PROPS',
		MUTATE_1OBJECT: 'DASHBOARD_MUTATE_1OBJECT',
		GET_NOTIFICATIONS: 'DASHBOARD_GET_NOTIFICATIONS',
		GET_NOTIFICATIONS_SUCCESS: 'DASHBOARD_GET_NOTIFICATIONS_SUCCESS',
		MARK_NOTIFICATION: 'DASHBOARD_MARK_NOTIFICATION',
		MARK_NOTIFICATION_SUCCESS: 'DASHBOARD_MARK_NOTIFICATION_SUCCESS',
		TOAST_MESSAGE: 'DASHBOARD_TOAST_MESSAGE',
		SHOW_MULTIPLE_ERRORS: 'DASHBOARD_SHOW_MULTIPLE_ERRORS',
		REPORT_ME: 'DASHBOARD_REPORT_ME',
		UPDATE_USER_PROFILE: 'DASHBOARD_UPDATE_USER_PROFILE',
		CHANGE_USERS_ASSIGNED_ENTITY: 'DASHBOARD_CHANGE_USERS_ASSIGNED_ENTITY',
		SET_ON_RECEIVED_NOTIFICATION: 'DASHBOARD_SET_ON_RECEIVED_NOTIFICATION',
		RESET: 'DASHBOARD_RESET',
		RESET_DND_ORDERS: 'DASHBOARD_RESET_DND_ORDERS',
		RESET_EDIT_PROFILE: 'DASHBOARD_RESET_EDIT_PROFILE',
	},
	USERS: {
		MUTATE_DIRECT_PROPS: 'USERS_MUTATE_DIRECT_PROPS',
		MUTATE_1OBJECT: 'USERS_MUTATE_1OBJECT',
		CHANGE_ACTIVATION: 'USERS_CHANGE_ACTIVATION',
		SET_PERMISSIONS: 'USERS_SET_PERMISSIONS',
		CHANGE_RELATION: 'USERS_CHANGE_RELATION',
		CHANGE_WORK_CODE: 'USERS_CHANGE_WORK_CODE',
		RESET: 'USERS_RESET',
	},
	CLIENTS: {
		MUTATE_DIRECT_PROPS: 'CLIENTS_MUTATE_DIRECT_PROPS',
		MUTATE_1OBJECT: 'CLIENTS_MUTATE_1OBJECT',
		SEARCH_CLIENT: 'CLIENTS_SEARCH_CLIENT',
		CREATE_CLIENT: 'CLIENTS_CREATE_CLIENT',
		RESET: 'CLIENTS_RESET',
	},
	CONTRACTS: {
		MUTATE_DIRECT_PROPS: 'CONTRACTS_MUTATE_DIRECT_PROPS',
		MUTATE_1OBJECT: 'CONTRACTS_MUTATE_1OBJECT',
		CREATE_CONTRACT: 'CONTRACTS_CREATE_CONTRACT',
		UPDATE_CONTRACT: 'CONTRACTS_UPDATE_CONTRACT',
		RESET: 'CONTRACTS_RESET',
	},
	ORDERS: {
		CHANGE_EXCEL_FILE_TO_LOAD_ORDERS_IN_BACKEND:
			'ORDERS_CHANGE_EXCEL_FILE_TO_LOAD_ORDERS_IN_BACKEND',
		SEND_ORDERS_TO_BACKEND: 'ORDERS_SEND_ORDERS_TO_BACKEND',
		RESULT_ORDERS_SENT_TO_BACKEND: 'ORDERS_RESULT_ORDERS_SENT_TO_BACKEND',
		GET_PHOTO_REPORT: 'ORDERS_GET_PHOTO_REPORT',
		MUTATE_DIRECT_PROPS: 'ORDERS_MUTATE_DIRECT_PROPS',
		MUTATE_1OBJECT: 'ORDERS_MUTATE_1OBJECT',
		GET_CONTROL_ORDERS: 'ORDERS_GET_CONTROL_ORDERS',
		GET_INFO_ORDERS_INCOME: 'ORDERS_GET_INFO_ORDERS_INCOME',
		GET_COUNT_ORDERS_TO_REASSIGNMENT: 'ORDERS_GET_COUNT_ORDERS_TO_REASSIGNMENT',
		GET_ALL_EXPERTS_INFO: 'ORDERS_GET_ALL_EXPERTS_INFO',
		CREATE_ONE_ORDER: 'ORDERS_CREATE_ONE_ORDER',
		GET_COUNT_ORDERS_TO_REASSIGNMENT_SUCCESS:
			'ORDERS_GET_COUNT_ORDERS_TO_REASSIGNMENT_SUCCESS',
		RESET_ORDERS_REASSIGNMENT_MODAL: 'ORDERS_RESET_ORDERS_REASSIGNMENT_MODAL',
		RESET_ORDER_INFORMATION: 'ORDERS_RESET_ORDER_INFORMATION',
		MAKE_DEEP_SEARCH: 'ORDERS_MAKE_DEEP_SEARCH',
		SAVE_ORDERS_REASSIGNMENT_TO_USER: 'ORDERS_SAVE_ORDERS_REASSIGNMENT_TO_USER',
		SAVE_ORDERS_REASSIGNMENT_TO_USER_V2:
			'ORDERS_SAVE_ORDERS_REASSIGNMENT_TO_USER_V2',
		SEND_NEW_INCOME_ZONES: 'ORDERS_SEND_NEW_INCOME_ZONES',
		SEND_REJECT_INVENTORY_VALIDATION: 'ORDERS_SEND_REJECT_INVENTORY_VALIDATION',
		SEND_ORDER_EVENTS: 'ORDERS_SEND_ORDER_EVENT',
		SEND_ORDER_EVENTS_SUCCESS: 'ORDERS_RESET_ORDER_EVENTS',
		GET_ORDER: 'ORDERS_GET_ORDER',
		GET_ORDER_SUCCESS: 'ORDERS_GET_ORDER_SUCCESS',
		UPDATE_ORDERS_SORT: 'ORDERS_UPDATE_ORDERS_SORT',
		SEND_COMMENT_REPORT: 'ORDERS_SEND_COMMENT_REPORT',
		REVERT_BILL: 'ORDERS_REVERT_BILL',
		SET_ORDER_PROPS: 'ORDERS_SET_ORDER_PROPS',
		SEND_ORDERS_TO_USER_ASSIGNMENT: 'ORDERS_SEND_ORDERS_TO_USER_ASSIGNMENT',
		SET_USER_ASSIGNMENT_TO_ORDERS: 'ORDERS_SET_USER_ASSIGNMENT_TO_ORDERS',
		ORDERS_EXISTS_VALIDATE: 'ORDERS_ORDERS_EXISTS_VALIDATE',
		ORDERS_EXISTS_VALIDATE_SUCCESS: 'ORDERS_ORDERS_EXISTS_VALIDATE_SUCCESS',
		SEND_IMPORT_ORDERS_TO_PROCESS_AND_SAVE:
			'ORDERS_SEND_IMPORT_ORDERS_TO_PROCESS_AND_SAVE',
		RESEST_ENTER_NEW_ORDERS: 'ORDERS_RESEST_ENTER_NEW_ORDERS',
		UPDATE_ORDER_IN_MODAL: 'ORDERS_UPDATE_ORDER_IN_MODAL',
		APPROVE_COMPLETED_AUTHORIZATION_CODE:
			'ORDERS_APPROVE_COMPLETED_AUTHORIZATION_CODE',
		UPDATE_ORDER_IN_CONTROL_ORDERS: 'ORDERS_UPDATE_ORDER_IN_CONTROL_ORDERS',
		RESET: 'ORDERS_RESET',
	},
	SUPERVISOR: {
		MUTATE_DIRECT_PROPS: 'SUPERVISOR_MUTATE_DIRECT_PROPS',
		MUTATE_1OBJECT: 'SUPERVISOR_MUTATE_1OBJECT',
		RESET_COMMENT_DETAILS_DRAWER: 'SUPERVISOR_RESET_COMMENT_DETAILS_DRAWER',
		RESET: 'SUPERVISOR_RESET',
		FETCH_ORDER_REPORTS: 'SUPERVISOR_FETCH_ORDER_REPORTS',
	},
	WAREHOUSES: {
		CREATE_WAREHOUSE: 'WAREHOUSES_CREATE_WAREHOUSE',
		CREATE_WAREHOUSE_PATH: 'WAREHOUSES_MANAGE_CREATE_WAREHOUSE_PATH',
		CREATE_ARTICLE: 'WAREHOUSES_CREATE_ARTICLE',
		CREATE_PACKAGE: 'WAREHOUSES_CREATE_PACKAGE',
		GET_ARTICLES: 'WAREHOUSES_GET_ARTICLES',
		GET_SEARCHING_SERIES: 'WAREHOUSES_GET_SEARCHING_SERIES',
		MUTATE_DIRECT_PROPS: 'WAREHOUSES_MUTATE_DIRECT_PROPS',
		MUTATE_1OBJECT: 'WAREHOUSES_MUTATE_1OBJECT',
		SEND_TRANSFER_TRANSACTION: 'WAREHOUSES_SEND_TRANSFER_TRANSACTION',
		CREATE_SERIES_CONTROL_TRANSACTION:
			'WAREHOUSES_CREATE_SERIES_CONTROL_TRANSACTION',
		UPDATE_TRANSACTION: 'WAREHOUSES_UPDATE_TRANSACTION',
		GET_ORDER_DATA_FROM_UNINSTALLED_SERIE:
			'WAREHOUSES_GET_ORDER_DATA_FROM_UNINSTALLED_SERIE',
		DELETE_TRANSACTION: 'WAREHOUSES_DELETE_TRANSACTION',
		RESET_ADJUST_ARTICLES: 'WAREHOUSES_RESET_ADJUST_ARTICLES',
		RESET_CREATE_TRANSACTION: 'WAREHOUSES_RESET_CREATE_TRANSACTION',
		RESET_CREATE_CONTROL_SERIES_TRANSACTION:
			'WAREHOUSES_RESET_CREATE_CONTROL_SERIES_TRANSACTION',
		RESET: 'WAREHOUSES_RESET',
	},
	BILLINGS: {
		CREATE_PAYMENT: 'BILLINGS_CREATE_PAYMENT',
		RESET_CREATE_PAYMENT: 'BILLINGS_RESET_CREATE_PAYMENT',
		CREATE_PAYMENT_LINE: 'BILLINGS_CREATE_PAYMENT_LINE',
		CREATE_PAYMENT_LINE_ITEM: 'BILLINGS_CREATE_PAYMENT_LINE_ITEM',
		RESET_CREATE_PAYMENT_LINE: 'BILLINGS_RESET_CREATE_PAYMENT_LINE',
		SIGN_PAYCUT: 'BILLINGS_SIGN_PAYCUT',
		SIGN_PAYMENT: 'BILLINGS_SIGN_PAYMENT',
		UPDATE_PAYMENT_ORDERS: 'BILLINGS_UPDATE_PAYMENT_ORDERS',
		MUTATE_1OBJECT: 'BILLINGS_MUTATE_1OBJECT',
		GENERATE_PAYMENT_PDF: 'BILLINGS_GENERATE_PAYMENT_PDF',
		GENERATE_PAYCUT_PDF: 'BILLINGS_GENERATE_PAYCUT_PDF',
		SET_PAYMENT_REOPEN: 'BILLINGS_SET_PAYMENT_REOPEN',
		SET_PAYMENT_READY: 'BILLINGS_SET_PAYMENT_READY',
		CREATE_PAYCUT: 'BILLINGS_CREATE_PAYCUT',
		SYNC_PAYCUT: 'BILLINGS_SYNC_PAYCUT',
		ENTER_INVOICE_ORDERS: 'BILLINGS_ENTER_INVOICE_ORDERS',
		RESET_CREATE_INVOICE_PAYCUT: 'BILLINGS_RESET_CREATE_INVOICE_PAYCUT',
		RESET_GET_PAYCUTS: 'BILLINGS_RESET_GET_PAYCUTS',
		RESET: 'BILLINGS_RESET',

	},
	ANALYTICS: {
		GET_NEW_ORDERS_VS_BILLED: 'ANALYTICS_GET_NEW_ORDERS_VS_BILLED',
		GET_BILLED_BY_ENTITY: 'ANALYTICS_GET_BILLED_BY_ENTITY',
		MUTATE_1OBJECT: 'ANALYTICS_MUTATE_1OBJECT',
		MUTATE_DIRECT_PROPS: 'ANALYTICS_DIRECT_PROPS',
		GET_CHARTS: 'ANALYTICS_GET_CHARTS',
		RESET: 'ANALYTICS_RESET',
	},
	COMMAND_CENTER: {
		MUTATE_1OBJECT: 'COMMAND_CENTER_MUTATE_1OBJECT',
		MUTATE_DIRECT_PROPS: 'COMMAND_CENTER_DIRECT_PROPS',
		MUTATE_1OBJECT_IN_REGIONS: 'COMMAND_CENTER_MUTATE_1OBJECT_IN_REGIONS',
		MUTATE_1OBJECT_IN_AREAS: 'COMMAND_CENTER_MUTATE_1OBJECT_IN_AREAS',
		MUTATE_1OBJECT_IN_CENTRALS: 'COMMAND_CENTER_MUTATE_1OBJECT_IN_CENTRALS',
		MUTATE_1OBJECT_IN_DEALERS: 'COMMAND_CENTER_MUTATE_1OBJECT_IN_DEALERS',
		MUTATE_1OBJECT_IN_ORDERS: 'COMMAND_CENTER_MUTATE_1OBJECT_IN_ORDERS',
		GET_REGIONS: 'COMMAND_CENTER_GET_REGIONS',
		GET_AREAS: 'COMMAND_CENTER_GET_AREAS',
		GET_CENTRALS: 'COMMAND_CENTER_GET_CENTRALS',
		GET_DEALERS: 'COMMAND_CENTER_GET_DEALERS',
		GET_ORDERS: 'COMMAND_CENTER_GET_ORDERS',
		GET_CHARTS: 'COMMAND_CENTER_GET_CHARTS',
		RESET: 'COMMAND_CENTER_RESET',
		RESET_FILTERS_QUERY_MODAL: 'COMMAND_CENTER_RESET_FILTERS_QUERY_MODAL',
	},
	REPORTS: {
		MUTATE_DIRECT_PROPS: 'REPORTS_MUTATE_DIRECT_PROPS',
		MUTATE_1OBJECT: 'REPORTS_MUTATE_1OBJECT',
		FETCH_REPORTS: 'REPORTS_FETCH_REPORTS',
		RESET_GENERATE_REPORT_MODAL: 'REPORTS_RESET_GENERATE_REPORT_MODAL',
		REQUEST_GENERATE_REPORT: 'REPORTS_REQUEST_GENERATE_REPORT',
	},
	UPLOAD_RESOURCE: {
		UPLOAD: 'UPLOAD_RESOURCE_UPLOAD',
		UPDATE_RESOURCES: 'UPLOAD_RESOURCE_UPDATE_RESOURCES',
		UPDATE_AUTO_SYNC: 'UPLOAD_RESOURCE_UPDATE_AUTO_SYNC',
		AUTO_SYNC: 'UPLOAD_RESOURCE_AUTO_SYNC',
	},
	MODULES: {
		MUTATE_DIRECT_PROPS: 'MODULES_MUTATE_DIRECT_PROPS',
		MUTATE_1OBJECT: 'MODULES_MUTATE_1OBJECT',
		MUTATE_MARKETPLACE: 'MODULES_MUTATE_MARKETPLACE',
		GET_MY_ACTIVE_MODULES: 'MODULES_GET_MY_ACTIVE_MODULES',
		GET_MARKET_BANNERS: 'MODULES_GET_MARKET_BANNERS',
		GET_MARKET_MODULES: 'MODULES_GET_MARKET_MODULES',
		GET_MARKET_ITEMS: 'MODULES_GET_MARKET_ITEMS',
		UPDATE_MARKETITEM_INSTALLATION_STATUS:
			'MODULES_UPDATE_MARKETITEM_INSTALLATION_STATUS',
		GET_MY_DATASOURCE_ACCESS: 'MODULES_GET_MY_DATASOURCE_ACCESS',
		GET_ORDER_TARGETS: 'MODULES_GET_ORDER_TARGETS',
	},
});
