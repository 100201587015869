// Libs
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import axios from 'axios';
import _ from 'underscore';
// Utils
import asyncErrorsHandler from '../../store/asyncErrorsHandler';
import AuthService from '../../utils/libs/auth/AuthService';
import GENERAL from '../../utils/constants/general';
import * as xlsx from '../../utils/libs/excel';
import API from '../../utils/constants/api';
import { convertStockDates } from '../../utils/libs/dateFormats';
import { getPreview } from './utils';

const { WAREHOUSES, DASHBOARD, ORDERS, APIDATA } = GENERAL;
const auth = new AuthService();
const getProfile = state => state.login.profile;
const getControlSeries = state => state.warehouses.controlSeries;
const getArticlesCreateTransaction = state =>
	state.warehouses.createTransaction.articles;
const getSearchInDataStockSeries = state =>
	state.warehouses.stockSeriesManage.searchInData;
const getDocumentItems = state => state.api.transactions.items.data;
const getInventoryToTransfer = state => state.api.transactions.inventory;
const getApi = state => state.api;
const getInventoryToTransferManage = state =>
	state.warehouses.inventoryToTransferManage;
const getDocId = state => state.warehouses.transactionsManage.docId;
const getTransactionsModal = state => state.warehouses.getTransactionsModal;
const getCreateTransaction = state => state.warehouses.createTransaction;
const getOrderIdFromInformationModal = state =>
	state.orders.getOrderInformationModal.order_id;

/** **** WATCHER SAGAS *******/
function* createWarehouseWatcher() {
	yield takeLatest(WAREHOUSES.CREATE_WAREHOUSE, createWarehouseWorker);
}

function* createWarehousePathWatcher() {
	yield takeLatest(WAREHOUSES.CREATE_WAREHOUSE_PATH, createPathWorker);
}

function* createArticleWatcher() {
	yield takeLatest(WAREHOUSES.CREATE_ARTICLE, createArticleWorker);
}

function* createTransactionWatcher() {
	yield takeLatest(
		WAREHOUSES.SEND_TRANSFER_TRANSACTION,
		createTransactionWorker,
	);
}

function* createSeriesControlTransactionWatcher() {
	yield takeLatest(
		WAREHOUSES.CREATE_SERIES_CONTROL_TRANSACTION,
		createSeriesControlTransactionWorker,
	);
}

function* updateTransactionDocumentItemsWatcher() {
	yield takeLatest(
		WAREHOUSES.UPDATE_TRANSACTION,
		updateTransactionDocumentItemsWorker,
	);
}

function* deleteTransactionWatcher() {
	yield takeLatest(WAREHOUSES.DELETE_TRANSACTION, deleteTransactionWorker);
}

function* createPackageWatcher() {
	yield takeLatest(WAREHOUSES.CREATE_PACKAGE, createPackageWorker);
}

function* getSearchingSeriesWatcher() {
	yield takeLatest(WAREHOUSES.GET_SEARCHING_SERIES, getSearchingSeriesWorker);
}

function* getOrderDataFromUninstalledSerieWatcher() {
	yield takeLatest(
		WAREHOUSES.GET_ORDER_DATA_FROM_UNINSTALLED_SERIE,
		getOrderDataFromUninstalledSerieWorker,
	);
}

/** **** WORKER SAGAS *******/
function* createWarehouseWorker(action) {
	const newWarehouse = action.payload;
	try {
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createWarehouse',
				keyValuePairs: { loading: true },
			},
		});
		const response = yield call(
			axios.post,
			API.DOMAIN.concat('/warehouses'),
			newWarehouse,
			auth.sendToken(),
		);
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createWarehouse',
				keyValuePairs: {
					loading: false,
					name: undefined,
					owner_id: undefined,
					details: {
						type: undefined,
						country: undefined,
						address: undefined,
						description: undefined,
						coords: undefined,
					},
				},
			},
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: `Almacén [${response.data}] creado`,
				type: 'success',
			},
		});
		yield put({ type: APIDATA.GET_WAREHOUSES });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: WAREHOUSES.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'createWarehouse',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield createWarehouseWorker(action);
			},
		);
	}
}

function* createPathWorker(action) {
	const newPath = action.payload;

	try {
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: { obj1Name: 'createPath', keyValuePairs: { loading: true } },
		});
		yield call(
			axios.post,
			API.DOMAIN.concat('/warehouses/create-path'),
			newPath,
			auth.sendToken(),
		);
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createPath',
				keyValuePairs: { loading: false, area: undefined },
			},
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: `Ruta de almacén creada!`, type: 'success' },
		});
		yield put({ type: WAREHOUSES.GET_WAREHOUSE_ZONES_AREAS });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: WAREHOUSES.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'createPath',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield createPathWorker(action);
			},
		);
	}
}

function* createArticleWorker(action) {
	const newArticle = action.payload;

	try {
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: { obj1Name: 'createArticle', keyValuePairs: { loading: true } },
		});
		yield call(
			axios.post,
			API.DOMAIN.concat('/items'),
			newArticle,
			auth.sendToken(),
		);
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createArticle',
				keyValuePairs: {
					loading: false,
					code: undefined,
					name: undefined,
					family_id: undefined,
					coin_id: undefined,
					price: undefined,
					iv: undefined,
					measured_unit_id: undefined,
					is_serialized: false,
					unavailable: false,
				},
			},
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: `Articulo creado!`, type: 'success' },
		});
		yield put({ type: APIDATA.GET_ARTICLES });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: WAREHOUSES.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'createArticle',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield createArticleWorker(action);
			},
		);
	}
}

function* createPackageWorker(action) {
	const inventoryToTransferManage = yield select(getInventoryToTransferManage);
	const {
		item_id,
		amount,
		serialized,
		warehouse_area_id,
		warehouse_zone_id,
		zone,
		area,
		mustCreateZone,
		mustCreateArea,
		operation,
	} = action.payload;
	const pkg = {
		item_id,
		amount,
		warehouse_area_id,
		warehouse_zone_id,
		zone,
		area,
		mustCreateZone,
		mustCreateArea,
	};

	let arts;
	if (operation === 'trsReceive' || operation === 'newEntry')
		arts = yield select(getArticlesCreateTransaction);
	else if (operation === 'reception') arts = yield select(getDocumentItems);

	try {
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createPackage',
				keyValuePairs: { loading: true, created: false },
			},
		});
		const response = yield call(
			axios.post,
			API.DOMAIN.concat('/warehouses_packages'),
			pkg,
			auth.sendToken(),
		);
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'createPackage',
				keyValuePairs: {
					isOpen: false,
					loading: false,
					serialized,
					id: response.data.id,
					created: serialized,
				},
			},
		});
		if (serialized) {
			yield put({
				type: WAREHOUSES.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'inventoryToTransferManage',
					keyValuePairs: {
						searchInData: {
							...inventoryToTransferManage.searchInData,
							renderToolbar: true,
							searchBy: 'scan',
						},
					},
				},
			});
		} else {
			const articles = [...arts];
			const idx = _.findIndex(articles, article => article.item_id === item_id);

			if (idx !== -1) {
				articles[idx].warehouse_package_id = response.data.id;
				if (operation === 'trsReceive' || operation === 'newEntry')
					yield put({
						type: WAREHOUSES.MUTATE_1OBJECT,
						payload: {
							obj1Name: 'createTransaction',
							keyValuePairs: { articles },
						},
					});
				else if (operation === 'reception')
					yield put({
						type: APIDATA.MUTATE_1OBJECT,
						payload: {
							obj1Name: 'transactions',
							keyValuePairs: { items: { loading: false, data: articles } },
						},
					});
			}
		}
		yield put({ type: APIDATA.GET_WAREHOUSE_PACKAGES });
		yield put({ type: APIDATA.GET_WAREHOUSE_ZONES_AREAS });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: WAREHOUSES.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'createPackage',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield createPackageWorker(action);
			},
		);
	}
}

function* getSearchingSeriesWorker(action) {
	const profile = yield select(getProfile);
	const searchInData = yield select(getSearchInDataStockSeries);
	const series = action.payload;

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'stock',
				keyValuePairs: { series: { data: [], loading: true } },
			},
		});
		const response = yield call(
			axios.post,
			API.DOMAIN.concat(`/warehouses_items/series/searching-series`),
			series,
			auth.sendToken(),
		);
		const data = response.data.map(row =>
			convertStockDates(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'stockSeriesManage',
				keyValuePairs: { searchInData: { ...searchInData, data: '' } },
			},
		});
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'stock',
				keyValuePairs: { series: { data, loading: false } },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getSearchingSeriesWorker(action);
		});
	}
}

function* getOrderDataFromUninstalledSerieWorker(action) {
	const serie = action.payload;
	const inventoryToTransfer = yield select(getInventoryToTransfer);
	const inventoryToTransferManage = yield select(getInventoryToTransferManage);

	try {
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'inventoryToTransferManage',
				keyValuePairs: {
					getSerieOrderData: {
						...inventoryToTransferManage.getSerieOrderData,
						serie,
					},
				},
			},
		});
		const { data } = yield call(
			axios.post,
			API.DOMAIN.concat(
				`/warehouses_items/v1/getOrderDataFromUninstalledSerie`,
			),
			{ serie },
			auth.sendToken(),
		);
		const rows = inventoryToTransfer.data.map(item => {
			if (item.serie === serie && data?.order_id) {
				item.order_id = data.order_id;
				item.odt_id = data.odt_id;
				item.expert_name = data.expert_name;
				item.client_name = data.client_name;
			}
			return item;
		});
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'transactions',
				keyValuePairs: { inventory: { loading: false, data: rows } },
			},
		});
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'inventoryToTransferManage',
				keyValuePairs: {
					getSerieOrderData: {
						...inventoryToTransferManage.getSerieOrderData,
						serie: undefined,
					},
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: WAREHOUSES.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'inventoryToTransferManage',
						keyValuePairs: {
							getSerieOrderData: {
								...inventoryToTransferManage.getSerieOrderData,
								serie: undefined,
							},
						},
					},
				});
			},
			function* () {
				yield getOrderDataFromUninstalledSerieWorker(action);
			},
		);
	}
}

function* createTransactionWorker(action) {
	const { transaction, transactionMode } = action.payload;
	const { mode, docNumber, fromDate, toDate } = yield select(
		getTransactionsModal,
	);
	const createTransaction = yield select(getCreateTransaction);
	const inventoryToTransfer = yield select(getInventoryToTransfer);
	const api = yield select(getApi);

	try {
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name:
					transactionMode === 'transfer'
						? 'createTransaction'
						: 'completeAdjust',
				keyValuePairs: { loading: true },
			},
		});
		const response = yield call(
			axios.post,
			API.DOMAIN.concat('/warehouses_transactions_docs/manual'),
			transaction,
			auth.sendToken(),
		);
		if (transactionMode === 'adjust') {
			yield put({
				type: WAREHOUSES.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'stockArticlesManage',
					keyValuePairs: { mode: 'view' },
				},
			});
			yield put({ type: WAREHOUSES.RESET_ADJUST_ARTICLES });
		} else if (transactionMode === 'transfer') {
			if (createTransaction.mode === 'trsReceive') {
				yield xlsx.exportResumeReceiveInventory(
					'resumen',
					getPreview(
						inventoryToTransfer.data,
						createTransaction.serialized,
						createTransaction.articles,
					).reduce((acc, item) => {
						if (item.is_serialized) {
							const serializedIdx = _.findIndex(
								createTransaction.serialized,
								row => row.serie === item.serie,
							);
							acc.push({
								...item,
								...createTransaction.serialized[serializedIdx],
							});
						} else {
							const articleIdx = _.findIndex(
								createTransaction.articles,
								row => row.item_id === item.item_id,
							);
							acc.push({
								...item,
								...createTransaction.articles[articleIdx],
							});
						}
						return acc;
					}, []),
					createTransaction.notFoundSerialized,
					api.warehouseZones.data,
					api.warehouseAreas.data,
					api.warehousePackages.data,
				);
			}
			yield put({ type: WAREHOUSES.RESET_CREATE_TRANSACTION });
		}
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: `Documento creado: [${response.data}]`, duration: 4 },
		});
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'transactions',
				keyValuePairs: { inventory: { loading: false, data: [] } },
			},
		});
		yield put({
			type: APIDATA.GET_TRANSACTION_DOCS,
			payload: { mode, docNumber, fromDate, toDate },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: WAREHOUSES.MUTATE_1OBJECT,
					payload: {
						obj1Name:
							transactionMode === 'transfer'
								? 'createTransaction'
								: 'completeAdjust',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield createTransactionWorker(action);
			},
		);
	}
}

function* createSeriesControlTransactionWorker(action) {
	const { series, dwarehouseId, stateId, incomeMethodId, comment } =
		action.payload;
	const controlSeries = yield select(getControlSeries);

	try {
		// loading: true
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'controlSeriesTransactionModal',
				keyValuePairs: {
					loading: true,
				},
			},
		});
		// Requesting
		yield call(
			axios.post,
			API.DOMAIN.concat('/warehouses_transactions_docs/controlSeries'),
			{ series, dwarehouseId, stateId, incomeMethodId, comment },
			auth.sendToken(),
		);
		// Reset Data: controlSeriesTransactionModal
		yield put({
			type: WAREHOUSES.RESET_CREATE_CONTROL_SERIES_TRANSACTION,
		});
		// Reset Data: controlSeries
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'controlSeries',
				keyValuePairs: {
					view: 'data',
					selected: {
						...controlSeries.selected,
						items: [],
					},
				},
			},
		});
		// Success message
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: `Transacción completada!`,
				duration: 3,
				type: 'success',
			},
		});
		// Get control series
		yield put({
			type: APIDATA.GET_CONTROL_SERIES,
			payload: { request: controlSeries.request, pagination: false },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: WAREHOUSES.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'controlSeriesTransactionModal',
						keyValuePairs: {
							loading: false,
						},
					},
				});
			},
			function* () {
				yield createSeriesControlTransactionWorker(action);
			},
		);
	}
}

function* updateTransactionDocumentItemsWorker(action) {
	const { items, operation } = action.payload;

	try {
		if (operation === 'reception') {
			const docId = yield select(getDocId);

			yield put({
				type: WAREHOUSES.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'itemsTransactionDoc',
					keyValuePairs: { updating: true },
				},
			});
			yield call(
				axios.put,
				API.DOMAIN.concat('/warehouses_transactions_docs_items/'),
				{ items, operation },
				auth.sendToken(),
			);
			yield put({
				type: WAREHOUSES.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'itemsTransactionDoc',
					keyValuePairs: { updating: false },
				},
			});
			yield put({
				type: DASHBOARD.TOAST_MESSAGE,
				payload: {
					message: `Documento actualizado`,
					duration: 4,
					type: 'success',
				},
			});
			yield put({ type: APIDATA.GET_ITEMS_TRANSACTION_DOCS, payload: docId });
		}
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: WAREHOUSES.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'itemsTransactionDoc',
						keyValuePairs: { updating: false },
					},
				});
			},
			function* () {
				yield updateTransactionDocumentItemsWorker(action);
			},
		);
	}
}

function* deleteTransactionWorker(action) {
	const document_id = action.payload;
	const { mode, docNumber, fromDate, toDate } = yield select(
		getTransactionsModal,
	);

	try {
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'transactionsManage',
				keyValuePairs: { deleting: true },
			},
		});
		yield call(
			axios.delete,
			API.DOMAIN.concat(`/warehouses_transactions_docs/${document_id}`),
			auth.sendToken(),
		);
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'transactionsManage',
				keyValuePairs: { deleting: false },
			},
		});
		yield put({
			type: APIDATA.GET_TRANSACTION_DOCS,
			payload: { mode, docNumber, fromDate, toDate },
		});
	} catch (err) {
		yield asyncErrorsHandler(err, function* () {
			yield put({
				type: WAREHOUSES.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'transactionsManage',
					keyValuePairs: { deleting: false },
				},
			});
		});
	}
}

/** **** EXPORT DEFAULT ROOT SAGA *******/
export default function* rootSaga() {
	yield all([
		createWarehouseWatcher(),
		createWarehousePathWatcher(),
		createArticleWatcher(),
		createTransactionWatcher(),
		createSeriesControlTransactionWatcher(),
		updateTransactionDocumentItemsWatcher(),
		createPackageWatcher(),
		getSearchingSeriesWatcher(),
		deleteTransactionWatcher(),
		getOrderDataFromUninstalledSerieWatcher(),
	]);
}
