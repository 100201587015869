// Libs
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
// Utils
import { getHumanize } from 'utils/libs/dateFormats';
// Hooks
import { useTheme } from 'hooks';
import useContractorData from '../../../hooks/useContractorData';
// Components
import {
	Title,
	Acronym,
	Span,
	Tooltip,
	SubCaption,
	Dropdown,
	Menu,
	SupervisorIcon,
	AdminIcon,
	BusinessIcon,
} from 'components';
// Styles
import styles from './Contractor.module.css';

const ContractorPostV1 = ({ record }) => {
	const { theme } = useTheme();
	const { data, getContractorData, assignModule, unassignModule } =
		useContractorData();

	useEffect(() => {
		getContractorData(record.id);
	}, [record.id]);

	function handleAssignedModule(projectId, orderTargetId, moduleId) {
		assignModule(record.id, projectId, orderTargetId, moduleId);
	}

	function handleUnassignedModule(projectId, orderTargetId, moduleId) {
		unassignModule(record.id, projectId, orderTargetId, moduleId);
	}

	return (
		<div className={cx(styles.card, theme.secondaryBackgroundColor)}>
			<div className={styles.titles__header}>
				<Acronym text={record.name} />
				<div className={styles.title__header}>
					<div className={styles.grid1row}>
						<Title width='190px' ellipsis>
							{record.name}
						</Title>
					</div>
					<Span>Creado: {getHumanize({ date: record.created_at })}</Span>
				</div>
			</div>
			<div className={styles.detailContainer}>
				<div className={styles.detailRow}>
					<Span>Tipo de usuario:</Span>
					<Span>Compañia</Span>
				</div>
				<div className={styles.detailRow}>
					<Span>Email:</Span>
					<Span>{record.email ? record.email : 'Sin correo electrónico'}</Span>
				</div>
				<div className={styles.detailRow}>
					<Span>Estado:</Span>
					<Span>
						{data?.projects?.some(project => {
							return project.orderTargets.some(orderTarget =>
								orderTarget.modules.some(module => module.assigned),
							);
						})
							? 'Activo'
							: 'Inactivo'}
					</Span>
				</div>
			</div>
			<div className={styles.footer}>
				<Tooltip title='Administradores'>
					<SubCaption>{record.administrators}</SubCaption>
					<AdminIcon hoverable button />
				</Tooltip>
				<Tooltip title='Técnicos'>
					<SubCaption>{record.tecnicos}</SubCaption>
					<SupervisorIcon
						hoverable
						iconButton
						className={styles.footerButton}
					/>
				</Tooltip>
				<Tooltip title='Proyectos'>
					<SubCaption>5</SubCaption>
					<Dropdown.Wrapper
						trigger={['click']}
						overlay={
							<Menu.Wrapper
								mode='vertical'
								$newTheme={theme}
								triggerSubMenuAction={'click'}
							>
								{data &&
									data.projects.map(project => {
										const hasAssignedOrderTargets = project.orderTargets.some(
											orderTarget =>
												orderTarget.modules.some(module => module.assigned),
										);
										const projectTitleWithIcon = (
											<div
												style={{
													color: theme._primaryColor,
													display: 'flex',
													flexDirection: 'row',
												}}
											>
												{hasAssignedOrderTargets ? (
													<CheckCircleOutlined
														style={{
															color: theme._primaryColor,
															marginRight: '12px',
														}}
													/>
												) : (
													<CloseCircleOutlined
														style={{ color: 'red', marginRight: '12px' }}
													/>
												)}
												{project.name}
											</div>
										);

										return (
											<Menu.SubMenu
												key={project.id}
												title={projectTitleWithIcon}
												$newTheme={theme}
											>
												<Menu.Group title='Lineas de Negocio Asignadas'>
													{project.orderTargets
														.filter(orderTarget => orderTarget.assigned)
														.map(orderTarget => (
															<Menu.SubMenu
																key={`${project.id}-${orderTarget.id}`}
																title={orderTarget.name}
																icon={
																	<CheckCircleOutlined
																		style={{ color: theme._primaryColor }}
																	/>
																}
																$newTheme={theme}
															>
																<Menu.Group title='Módulos Asignados'>
																	{orderTarget.modules
																		.filter(module => module.assigned)
																		.map(module => (
																			<Menu.Item
																				key={`${project.id}-${orderTarget.id}-${module.id}`}
																				onClick={() =>
																					handleUnassignedModule(
																						project.id,
																						orderTarget.id,
																						module.id,
																					)
																				}
																				icon={
																					<CheckCircleOutlined
																						style={{
																							color: theme._primaryColor,
																						}}
																					/>
																				}
																				$newTheme={theme}
																			>
																				{module.name}
																			</Menu.Item>
																		))}
																</Menu.Group>
																<Menu.Group title='Módulos No Asignados'>
																	{orderTarget.modules
																		.filter(module => !module.assigned)
																		.map(module => (
																			<Menu.Item
																				key={`${project.id}-${orderTarget.id}-${module.id}`}
																				onClick={() =>
																					handleAssignedModule(
																						project.id,
																						orderTarget.id,
																						module.id,
																					)
																				}
																				icon={
																					<CloseCircleOutlined
																						style={{ color: 'red' }}
																					/>
																				}
																				$newTheme={theme}
																			>
																				{module.name}
																			</Menu.Item>
																		))}
																</Menu.Group>
															</Menu.SubMenu>
														))}
												</Menu.Group>
												<Menu.Group title='Lineas de Negocio No Asignadas'>
													{project.orderTargets
														.filter(orderTarget => !orderTarget.assigned)
														.map(orderTarget => (
															<Menu.SubMenu
																key={`${project.id}-${orderTarget.id}`}
																title={orderTarget.name}
																icon={
																	<CloseCircleOutlined
																		style={{ color: 'red' }}
																	/>
																}
																$newTheme={theme}
															>
																<Menu.Group title='Módulos No Asignados'>
																	{orderTarget.modules
																		.filter(module => !module.assigned)
																		.map(module => (
																			<Menu.Item
																				key={`${project.id}-${orderTarget.id}-${module.id}`}
																				icon={
																					<CloseCircleOutlined
																						style={{ color: 'red' }}
																					/>
																				}
																				onClick={() =>
																					handleAssignedModule(
																						project.id,
																						orderTarget.id,
																						module.id,
																					)
																				}
																				$newTheme={theme}
																			>
																				{module.name}
																			</Menu.Item>
																		))}
																</Menu.Group>
															</Menu.SubMenu>
														))}
												</Menu.Group>
											</Menu.SubMenu>
										);
									})}
							</Menu.Wrapper>
						}
					>
						<BusinessIcon hoverable button />
					</Dropdown.Wrapper>
				</Tooltip>
			</div>
		</div>
	);
};

export default ContractorPostV1;
