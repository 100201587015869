// Libs
import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
// Utils
import {
	processImportOrders,
	validateDataMapping,
	cleanImportedOrdersToSave,
	readTxtFile,
	getValidatorFieldsTemplate,
} from 'screens/Orders/utils';
import GENERAL from 'utils/constants/general';
import { xlsx } from 'utils/libs';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
import { onGetTemplates, getClientCategories } from 'store/api/actions';
import {
	onSendOrdersToUserAssignment,
	onResetEnterNewOrders,
	onOrdersExistsValidate,
	onSendImportOrdersToProcessAndSave,
	mutate1Object,
} from 'screens/Orders/actions';
// Selectors
import {
	selectClientCategories,
	selectImportOrderTemplates,
	selectServices,
	selectStates,
	selectZones,
} from 'store/api/selectors';
// Components
import {
	Wrapper,
	Span,
	Divider,
	Steps,
	Button,
	Link,
	Spin,
	LogoIcon,
} from 'components';
import Select from 'components/Select';
import { selectEnterNewOrdersModal } from 'screens/Orders/selectors';
import { selectProfile } from 'screens/Login/selectors';
import { UploadIcon } from 'components/Icons';

const { ENV } = GENERAL;

const { Option } = Select;

function determineFileTypeImported(file) {
	if (!file) throw new Error('File not found');

	const { type } = file;

	switch (type) {
		case 'text/plain':
			return 'txt';
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			return 'xlsx';
		default:
			return null;
	}
}

function steps(enterNewOrdersModal) {
	const {
		importedFile,
		mappingValidationOk,
		userAssignment,
		request,
		ordersExistsValidationState,
	} = enterNewOrdersModal;

	const readingImportFile = () => importedFile.loaded === false;
	const importFileReadAndDepurated = () =>
		importedFile.loaded && importedFile.data.length > 0;
	const ordersExistsValidated = () =>
		ordersExistsValidationState === 'validated';
	const dataMappingValidated = () => mappingValidationOk;
	const assignOrdersToUsers = () => userAssignment === 'confirmed';
	const dataProcessed = () => request.status === 'success';

	const getCurrentStep = () => {
		if (dataProcessed()) return 6;
		if (assignOrdersToUsers()) return 4;
		if (dataMappingValidated()) return 3;
		if (ordersExistsValidated()) return 2;
		if (importFileReadAndDepurated()) return 1;
		if (readingImportFile()) return 0;
		return -1;
	};

	return {
		readingImportFile,
		importFileReadAndDepurated,
		ordersExistsValidated,
		dataMappingValidated,
		assignOrdersToUsers,
		dataProcessed,
		getCurrentStep,
	};
}

const ImportButton = ({
	disabled,
	mutate1Object,
	sendToast,
	profile,
	states,
	services,
	clientCategories,
	templateValidator,
	validateFieldsTemplate,
	odtField,
	stateField,
	serviceField,
	clientCategoryField,
	onResetEnterNewOrders,
}) => (
	<label>
		<UploadIcon />
		<input
			disabled={disabled}
			style={{ display: 'none' }}
			accept='.xlsx, .txt'
			type='file'
			onChange={async e => {
				e.preventDefault();

				// Reset income orders states
				onResetEnterNewOrders();

				const file = e.target.files[0];
				if (!file) return;

				try {
					const fileType = determineFileTypeImported(file);

					let data = null;
					if (fileType === 'txt') {
						data = await readTxtFile(
							file,
							templateValidator.data_structure_object,
						); // Must return {fileName, jsonFile}
					} else if (fileType === 'xlsx') {
						data = await xlsx.readExcel(e, true); // Must return {fileName, jsonFile}
					}

					// If not enter any file?
					if (!data) {
						return;
					}

					// If file not have rows
					if (data.jsonFile.length === 0) {
						sendToast({
							message: 'El archivo importado debe tener al menos un registro',
							type: 'warn',
						});
						return;
					}

					// Save file name in store
					mutate1Object('enterNewOrdersModal', {
						importedFile: {
							loaded: true,
							name: data.fileName,
							data:
								processImportOrders(
									data.jsonFile,
									odtField,
									stateField,
									serviceField,
									clientCategoryField,
									templateValidator,
									validateFieldsTemplate,
									profile,
									states.data,
									services.data,
									clientCategories.data,
									sendToast,
								) || [],
						},
					});
				} catch (err) {
					sendToast({ message: err.message, type: 'warn' });
				}
			}}
		/>
	</label>
);

function ImportOrdersTab({
	// Props
	enterNewOrdersModal,
	zones,
	states,
	services,
	clientCategories,
	templates,
	profile,
	// Actions
	mutate1Object,
	sendToast,
	onSendOrdersToUserAssignment,
	onResetEnterNewOrders,
	onOrdersExistsValidate,
	onGetTemplates,
	onSendImportOrdersToProcessAndSave,
	getClientCategories,
}) {
	// Destructuring enterNewOrdersModal
	const {
		dateFormat,
		importedFile,
		ordersExistsValidationState,
		mappingValidationOk,
		validationTemplateId,
		userAssignment,
		request,
	} = enterNewOrdersModal;
	// Get templateValidator
	const templateValidator =
		validationTemplateId &&
		templates.data.filter(template => template.id === validationTemplateId)[0];
	// Get array fields from template validator
	const validateFieldsTemplate = getValidatorFieldsTemplate(templateValidator);
	// Get ODT field
	const odtFieldIdx = _.findIndex(
		validateFieldsTemplate,
		field => field.id === 'order_odt_id',
	);
	const odtField = odtFieldIdx !== -1 && validateFieldsTemplate[odtFieldIdx];
	// Get State Field
	const stateFieldIdx = _.findIndex(
		validateFieldsTemplate,
		field => field.id === 'order_state_id',
	);
	const stateField =
		stateFieldIdx !== -1 && validateFieldsTemplate[stateFieldIdx];
	// Get Service Field
	const serviceFieldIdx = _.findIndex(
		validateFieldsTemplate,
		field => field.id === 'order_service_id',
	);
	const serviceField =
		serviceFieldIdx !== -1 && validateFieldsTemplate[serviceFieldIdx];
	// Get Client Category Field
	const clientCategoryFieldIdx = _.findIndex(
		validateFieldsTemplate,
		field => field.id === 'client_category_id',
	);
	const clientCategoryField =
		clientCategoryFieldIdx !== -1 &&
		validateFieldsTemplate[clientCategoryFieldIdx];
	// Get Assigned Tech Field
	const assignedTechFieldIdx = _.findIndex(
		validateFieldsTemplate,
		field => field.id === 'assigned_tech_id',
	);
	const assignedTechField =
		assignedTechFieldIdx !== -1 && validateFieldsTemplate[assignedTechFieldIdx];

	React.useEffect(() => {
		// Validate Orders Exists
		if (
			steps(enterNewOrdersModal).importFileReadAndDepurated() &&
			ordersExistsValidationState === 'waiting'
		) {
			onOrdersExistsValidate(
				{
					findBykey: 'odt_id',
					returnFields: ['odt_id'],
					ids: importedFile.data.reduce((acc, order) => {
						acc.push(order[odtField.id]);
						return acc;
					}, []),
				},
				odtField,
			);
		}
		// Validate Mapping
		if (
			steps(enterNewOrdersModal).ordersExistsValidated() &&
			mappingValidationOk === undefined
		) {
			validateDataMapping(
				enterNewOrdersModal,
				zones.data,
				validateFieldsTemplate,
				profile?.organization?.algorithms?.orders?.import,
				mutate1Object,
				sendToast,
			);
		}
		// User Assignment
		if (
			steps(enterNewOrdersModal).dataMappingValidated() &&
			userAssignment === 'waiting'
		) {
			onSendOrdersToUserAssignment(
				importedFile.data,
				dateFormat,
				validationTemplateId,
				odtField,
				assignedTechField,
				validateFieldsTemplate,
			);
		}
		// Process & Save Orders
		if (
			steps(enterNewOrdersModal).assignOrdersToUsers() &&
			request.status === 'waiting'
		) {
			onSendImportOrdersToProcessAndSave(
				cleanImportedOrdersToSave(importedFile.data, validateFieldsTemplate),
				dateFormat,
				validationTemplateId,
			);
		}
	});

	return (
		<Wrapper flexDirection='column'>
			{/* VALIDATION TEMPLATE */}
			<Wrapper padding='0' width='100%' margin='0 0 10px 0'>
				<Span
					width='40%'
					fontSize='m'
					margin='0 10px 0 0'
					color='rgba(255, 255, 255, 0.6)'
				>
					Plantilla de validación
				</Span>
				<Select
					width='60%'
					placeholder='Seleccione'
					value={validationTemplateId}
					disabled={steps(enterNewOrdersModal).importFileReadAndDepurated()}
					onChange={value => {
						mutate1Object('enterNewOrdersModal', {
							validationTemplateId: value,
						});
						const departmentId = templates.data.find(
							t => t.id === value,
						)?.departmentId;
						if (departmentId) getClientCategories({ departmentId });
					}}
					onDropdownVisibleChange={isOpen =>
						isOpen &&
						onGetTemplates({
							...templates.request,
							prop: ENV.TEMPLATES.PROPS.VALIDATE_IMPORT_ORDERS,
						})
					}
					dropdownRender={menu => (
						<Spin
							size='small'
							spinning={templates.loading && templates.data.length === 0}
						>
							{menu}
						</Spin>
					)}
				>
					{templates.data.map(template => (
						<Option key={template.id} value={template.id}>
							{template.name}
						</Option>
					))}
				</Select>
			</Wrapper>

			{/* DATE FORMAT */}
			<Wrapper padding='0' width='100%' margin='0 0 10px 0'>
				<Span
					width='40%'
					fontSize='m'
					margin='0 10px 0 0'
					color='rgba(255, 255, 255, 0.6)'
				>
					Formato de Fechas
				</Span>
				<Select
					width='60%'
					placeholder='Seleccione'
					value={dateFormat}
					disabled={!validationTemplateId}
					onChange={value =>
						mutate1Object('enterNewOrdersModal', { dateFormat: value })
					}
				>
					<Option value='YYYY/MM/DD HH:mm'>
						<em>aaaa/mm/dd</em>
					</Option>
					<Option value='DD/MM/YYYY HH:mm'>
						<em>dd/mm/aaaa</em>
					</Option>
					<Option value='DD.MM.YY HH:mm'>
						<em>dd.mm.aa</em>
					</Option>
					<Option value='DD/MM/YY HH:mm'>
						<em>dd/mm/aa</em>
					</Option>
					<Option value='MM/DD/YYYY HH:mm:ss'>
						<em>mm/dd/aaaa</em>
					</Option>
				</Select>
			</Wrapper>

			{/* IMPORT FILE */}
			<Wrapper padding='0' width='100%'>
				<Span
					width='40%'
					fontSize='m'
					margin='0 10px 0 0'
					color='rgba(255, 255, 255, 0.6)'
				>
					{!importedFile.name ? 'Importar archivo:' : 'Archivo importado:'}
				</Span>

				{/* WAITING ALGORITHM */}
				{(!profile.organization.algorithms ||
					!profile.organization.algorithms.orders ||
					!profile.organization.algorithms.orders.import) && (
					<>
						<LogoIcon button spin />
						<Span>Esperando algoritmo de importación...</Span>
					</>
				)}

				{/* IMPORTED FILE */}
				{importedFile.name && <Span>{importedFile.name}</Span>}

				{/* IMPORT FILE */}
				{profile?.organization?.algorithms?.orders?.import && (
					<ImportButton
						disabled={!dateFormat}
						mutate1Object={mutate1Object}
						sendToast={sendToast}
						profile={profile}
						states={states}
						services={services}
						clientCategories={clientCategories}
						templateValidator={templateValidator}
						validateFieldsTemplate={validateFieldsTemplate}
						odtField={odtField}
						stateField={stateField}
						serviceField={serviceField}
						clientCategoryField={clientCategoryField}
						onResetEnterNewOrders={onResetEnterNewOrders}
					/>
				)}
			</Wrapper>
			{/* PROCESS */}
			<Divider orientation='left'>Proceso</Divider>
			<Wrapper
				width='100%'
				overflow='auto'
				height='inherit'
				margin='10px 0 0 0'
			>
				<Steps.Wrap
					direction='vertical'
					size='small'
					current={steps(enterNewOrdersModal).getCurrentStep()}
				>
					<Steps.Step title='Leyendo y Depurando datos' />
					<Steps.Step title='Validando órdenes existentes' />
					<Steps.Step
						title='Validando Mapeo de Indices'
						status={mappingValidationOk === false ? 'error' : undefined}
						description={
							mappingValidationOk === false && (
								<Wrapper>
									<Span fontSize='s' color='orange'>
										Hubo un conflicto con el mapeo de los datos. Se requiere
										intervención manual
									</Span>
									<Link
										to={ENV.ROUTES.PATHS.CONFLICT_IMPORTED_ORDERS}
										onClick={() =>
											mutate1Object('enterNewOrdersModal', {
												isOpen: false,
											})
										}
									>
										<Button>Resolver</Button>
									</Link>
								</Wrapper>
							)
						}
					/>
					<Steps.Step
						title='Asignando usuarios'
						status={
							userAssignment === 'error' || userAssignment === 'withConflicts'
								? 'error'
								: undefined
						}
						description={
							<Wrapper justifyContent='space-between'>
								{/* ERROR */}
								{userAssignment === 'error' && (
									<>
										<Span fontSize='s' color='orange'>
											Lo siento, no pude procesar su solicitud. Por favor vuelva
											a intentarlo
										</Span>
										<Button
											color='white'
											onClick={() =>
												onSendOrdersToUserAssignment(
													importedFile.data,
													dateFormat,
													validationTemplateId,
													odtField,
													assignedTechField,
													validateFieldsTemplate,
												)
											}
										>
											Reintentar
										</Button>
									</>
								)}
								{/* WITH CONFLICTS */}
								{userAssignment === 'withConflicts' && (
									<>
										<Span fontSize='s' color='orange'>
											Algunas ordenes no fueron asignadas. Se requiere
											intervención manual
										</Span>
										<Link
											to={ENV.ROUTES.PATHS.CONFLICT_IMPORTED_ORDERS}
											onClick={() =>
												mutate1Object('enterNewOrdersModal', {
													isOpen: false,
												})
											}
										>
											<Button>Resolver</Button>
										</Link>
									</>
								)}
								{/* WITHOUT CONFLICTS */}
								{userAssignment === 'withoutConflicts' && (
									<>
										<Link
											to={ENV.ROUTES.PATHS.CONFLICT_IMPORTED_ORDERS}
											onClick={() =>
												mutate1Object('enterNewOrdersModal', {
													isOpen: false,
												})
											}
										>
											<Button>Revisar</Button>
										</Link>
										<Button
											mode='primary'
											onClick={() =>
												mutate1Object('enterNewOrdersModal', {
													userAssignment: 'confirmed',
													selected: {
														items: [],
													},
													filters: {},
													onHoverCells: {},
												})
											}
										>
											Continuar
										</Button>
									</>
								)}
							</Wrapper>
						}
					/>
					<Steps.Step title='Guardando datos' />
					<Steps.Step title='Finalizado!' />
				</Steps.Wrap>
			</Wrapper>
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	enterNewOrdersModal: selectEnterNewOrdersModal(state),
	zones: selectZones(state),
	states: selectStates(state),
	services: selectServices(state),
	clientCategories: selectClientCategories(state),
	profile: selectProfile(state),
	templates: selectImportOrderTemplates(state),
});

const actions = {
	mutate1Object,
	sendToast,
	onSendOrdersToUserAssignment,
	onResetEnterNewOrders,
	onOrdersExistsValidate,
	onSendImportOrdersToProcessAndSave,
	onGetTemplates,
	getClientCategories,
};

export default connect(mapStateToProps, actions)(ImportOrdersTab);
