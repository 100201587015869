import React from 'react';
import { Card } from 'antd';
import styled, { css } from 'styled-components';
import Avatar from './Avatar';
import Image from './Image';

const { Meta } = Card;

const CardStyled = styled(Card)`
	width: ${props => (props.width ? props.width : props.theme.card.width)};
	height: ${props => (props.height ? props.height : props.theme.card.height)};
	padding: ${props => props.theme.card.padding};
	background-color: ${props =>
		props.backgroundColor
			? props.backgroundColor
			: props.theme.card.backgroundColor};
	.ant-card-meta-title {
		color: ${props =>
			props.titleColor ? props.titleColor : props.theme.card.title.color};
	}
	.ant-card-meta-description {
		color: ${props =>
			props.descriptionColor
				? props.descriptionColor
				: props.theme.card.description.color};
		font-size: ${props =>
			props.descriptionFontSize
				? props.theme.fonts.size[props.descriptionFontSize]
				: props.theme.fonts.size.default};
	}
	.ant-card-actions {
		display: ${props => props.theme.card.actions.display};
		align-items: ${props => props.theme.card.actions.alignItems};
		margin: ${props =>
			props.$actionsMargin
				? props.$actionsMargin
				: props.theme.card.actions.margin};
		padding: ${props =>
			props.$actionsPadding
				? props.$actionsPadding
				: props.theme.card.actions.padding};
		border-top: ${props =>
			props.$actionsBorderTop
				? props.$actionsBorderTop
				: props.theme.card.actions.borderTop};
		background: ${props =>
			props.$actionsBackgroundColor
				? props.$actionsBackgroundColor
				: props.theme.card.actions.backgroundColor};
	}
	.ant-card-actions > li:not(:last-child) {
		border-right: ${props =>
			props.actionsItemsBorderRight
				? props.actionsItemsBorderRight
				: props.theme.card.actions.items.borderRight};
	}
	.ant-card-actions > li > span > .anticon {
		color: ${props =>
			props.actionsItemsColor
				? props.actionsItemsColor
				: props.theme.card.actions.items.colors.default};
	}
	${props =>
		props.shadow1 &&
		css`
			&.ant-card {
				box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.75);
			}
		`}
`;

export default function WrapperCard({
	imgCover,
	imgCoverParams,
	imgAvatar,
	actions,
	title,
	description,
	extra,
	style,
	actionsPadding,
	actionsMargin,
	actionsBorderTop,
	actionsBackgroundColor,
	children,
	shadow1,
}) {
	return (
		<CardStyled
			shadow1={shadow1}
			style={style}
			$actionsPadding={actionsPadding}
			$actionsMargin={actionsMargin}
			$actionsBorderTop={actionsBorderTop}
			$actionsBackgroundColor={actionsBackgroundColor}
			// cover={ImgCover}
			extra={extra}
			actions={actions}
			cover={
				imgCover && (
					<Image
						height={imgCoverParams?.height || '100px'}
						objectFit={imgCoverParams?.objectFit || 'cover'}
						src={imgCover}
					/>
				)
			}
		>
			{children || (
				<Meta
					avatar={
						imgAvatar && <Avatar size='normal' avatar={{ src: imgAvatar }} />
					}
					title={title}
					description={description}
				/>
			)}
		</CardStyled>
	);
}
