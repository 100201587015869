// Libs
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Context
import { ElementViewBuilderContext } from 'core/context';
// Selectors
import { selectDatasource, selectDatasourceSingleData } from 'core/selectors';
// Hooks
import { useCollectorManager } from 'hooks';
// Components
import { CommentDetailsDrawer } from 'core/ElementViewBuilder/views/Supervisor';
import { LogoIcon, Wrapper } from 'components';
import { PhotoReportMenu } from './Menu';
import { PhotoReportToolbar } from './Toolbar';
import CollectorBuilder from './CollectorBuilder';
import styles from './PhotoReportViewer.module.css';
import { CommentModal } from '../Supervisor';

const checkPhotoReport = photoReport =>
	photoReport?.id && photoReport.templateId && photoReport.serviceId;

const PhotoReportViewer = ({ isMobile }) => {
	const routeParams = useParams();
	const { getPhotoReport } = useCollectorManager();
	const datasource = useSelector(selectDatasource);
	const { logo } = useSelector(selectDatasourceSingleData);
	const { payload = {}, cleanElementView } = useContext(
		ElementViewBuilderContext,
	);
	const elementId = routeParams.elementId || payload.elementId;
	const [s, _setState] = useState({
		selectedTaskId: undefined,
		selectedReviewId: undefined,
		selectedGroupId: undefined,
		selectedSubgroupId: undefined,
		isCollectorFetching: false,
		photoReport: undefined,
		reviewing: false,
	});
	const setState = newProps =>
		_setState(current => ({ ...current, ...newProps }));

	// Get PhotoReport
	useEffect(() => {
		const _photoReport = datasource.data.find(
			item => item.id.toString() === elementId,
		);
		if (_photoReport) {
			setState({ photoReport: _photoReport });
			return;
		}
		getPhotoReport({ elementId }).then(photoReport =>
			setState({ photoReport }),
		);
		return () => {
			cleanElementView();
		};
	}, [elementId]);

	if (!checkPhotoReport(s.photoReport))
		return (
			<Wrapper width='100%' height='100%' justifyContent='center'>
				<LogoIcon spin size='l' />
			</Wrapper>
		);

	return (
		<>
			<PhotoReportToolbar
				selectedGroupId={s.selectedGroupId}
				selectedSubgroupId={s.selectedSubgroupId}
				reviewing={s.reviewing}
				onSetState={setState}
				groups={payload.groups}
				subgroups={payload.subgroups}
				onClose={cleanElementView}
			/>
			<div
				className={
					isMobile
						? styles.overviewMobileContainer
						: styles.overviewDesktopContainer
				}
			>
				<PhotoReportMenu
					width={!isMobile && '15%'}
					isMobile={isMobile}
					photoReport={s.photoReport}
					selectedIds={{
						selectedTaskId: s.selectedTaskId,
						selectedReviewId: s.selectedReviewId,
						selectedGroupId: s.selectedGroupId,
						selectedSubgroupId: s.selectedSubgroupId,
					}}
					onMenuItemClick={setState}
				/>
				<CollectorBuilder
					isMobile={isMobile}
					photoReport={s.photoReport}
					selectedIds={{
						selectedTaskId: s.selectedTaskId,
						selectedReviewId: s.selectedReviewId,
						selectedGroupId: s.selectedGroupId,
						selectedSubgroupId: s.selectedSubgroupId,
					}}
					logo={logo}
					reviewing={s.reviewing}
					isCollectorFetching={s.isCollectorFetching}
					onFetchedCollector={() => setState({ isCollectorFetching: false })}
					onUpdatedCoordSuccess={() => setState({ isCollectorFetching: true })}
				/>
			</div>
			<CommentDetailsDrawer />
			<CommentModal />
		</>
	);
};

export default PhotoReportViewer;
