// Libs
import React, { useContext, useState } from 'react';
// Context
import { ElementViewBuilderContext } from 'core/context';
// Components
import { Wrapper, CloseIcon, ChartBuilder } from 'components';
import ChartTableBuilder from 'components/ChartBuilder/ChartTableBuilder';

const Title = ({ cleanElementView }) => (
	<Wrapper width='100%' justifyContent='flex-end'>
		<CloseIcon button onClick={cleanElementView} />
	</Wrapper>
);

const ChartViewer = ({ isMobile }) => {
	const { payload, cleanElementView } = useContext(ElementViewBuilderContext);
	const { elementId } = payload;
	const [displayChartStack, setDisplayChartStack] = useState(() => [
		{
			id: elementId,
		},
	]);

	const onNextChildrenChart = (childrenId, parentSegmentIdParam) => {
		if (!childrenId || !parentSegmentIdParam) return;
		setDisplayChartStack(prev => [
			...prev,
			{ id: childrenId, parentSegmentIdParam },
		]);
	};

	const onBackChildrenChart = () =>
		setDisplayChartStack(prev => {
			const newStack = [...prev];
			newStack.pop();
			return newStack;
		});

	return (
		<Wrapper width='100%' padding='0' flexDirection='column'>
			<Title cleanElementView={cleanElementView} />
			<ChartBuilder
				chart={displayChartStack.at(-1)}
				onNextChildrenChart={onNextChildrenChart}
				onBackChildrenChart={
					displayChartStack.length >= 2 && onBackChildrenChart
				}
				tableRenderer={ChartTableBuilder}
			/>
		</Wrapper>
	);
};

export default ChartViewer;
