import Immutable from 'immutable';
import { filterDataByFilterOptions, filterDataBySelectedOptions } from '../';

function filterControlSeries(series, view, filters, selected) {
	let filteredSeries = Immutable.List(series).toJS();

	if (view === 'resume') {
		filteredSeries = filterDataBySelectedOptions(
			filteredSeries,
			selected,
			'serie',
		);
	} else {
		filteredSeries = filterDataByFilterOptions(filteredSeries, filters);
	}
	return filteredSeries;
}

export { filterControlSeries };
