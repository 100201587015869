// Libs
import React from 'react';
// Components
import Select from 'components/Select';

const { Option } = Select;

const DateFormat = ({ dateFormat, disabled, onChange }) => {
	return (
		<Select
			width='100%'
			margin='16px 0 0 0'
			placeholder='Seleccione el formato de fecha'
			value={dateFormat}
			disabled={disabled}
			onChange={onChange}
		>
			<Option value='YYYY/MM/DD HH:mm:ss'>
				<em>aaaa/mm/dd</em>
			</Option>
			<Option value='DD/MM/YYYY HH:mm:ss'>
				<em>dd/mm/aaaa</em>
			</Option>
			<Option value='DD.MM.YY HH:mm:ss'>
				<em>dd.mm.aa</em>
			</Option>
			<Option value='DD/MM/YY HH:mm:ss'>
				<em>dd/mm/aa</em>
			</Option>
			<Option value='MM/DD/YYYY HH:mm:ss'>
				<em>mm/dd/aaaa</em>
			</Option>
		</Select>
	);
};

export default DateFormat;
