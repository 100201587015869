// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
// Utils
import GENERAL from 'utils/constants/general';
// Components
import { VirtualizedList } from 'screens/Utils';
import { Wrapper } from 'components';

const { ENV } = GENERAL;

function ItemsTransactionDocMobile(props) {
	if (!props.transactionsManage.docId) {
		return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_MOBILE} />;
	}

	const {
		transactions,
		itemsTransactionDoc,
		transactionsManage,
		createPackage,
		warehousePackages,
		warehouseAreas,
		warehouseZones,
		filterItemsTransactionDocsSearchInData,
		mutate1Object,
		mutate1ObjectApi,
		sendToast,
		profile,
	} = props;
	const itemsData = [...transactions.items.data];
	const transaction =
		transactionsManage.docId &&
		transactions.data.filter(trn => trn.id === transactionsManage.docId)[0];

	// Filter Items
	const filteredInventoryItems = filterItemsTransactionDocsSearchInData(
		itemsData,
		itemsTransactionDoc.searchInData.searchBy,
		itemsTransactionDoc.searchInData.data,
	);

	return (
		<Wrapper padding='0' className='animated fadeIn'>
			<VirtualizedList
				cardId='itemTransactionCard'
				interpolatedRowStyle
				listStyle={{
					height: document.documentElement.clientHeight * 0.83,
					width: document.documentElement.clientWidth * 1.04,
				}}
				rowHeight={
					transaction && transaction.transaction_type_id === 28 ? 135 : 120
				}
				rows={filteredInventoryItems}
				customProps={{
					itemsData,
					transactions,
					itemsTransactionDoc,
					createPackage,
					warehousePackages,
					mutate1ObjectInWarehouses: mutate1Object,
					mutate1ObjectApi,
					sendToast,
					warehouseAreas,
					warehouseZones,
					transaction,
					profile,
				}}
			/>
		</Wrapper>
	);
}

export default ItemsTransactionDocMobile;
