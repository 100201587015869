export function getNameSplitted(
	name,
	{ getFirstName, getSecondName, getFirstLastName, getSecondLastName },
) {
	if (!name || name === '') return '';

	const space = ' ';
	const nameSplitted = name.split(space);
	const firstName = nameSplitted[0];
	const secondName = nameSplitted[1] || '';
	const firstLastName = nameSplitted[2] || nameSplitted[1] || '';
	const secondLastName = nameSplitted[3] || nameSplitted[2] || '';

	let response = '';

	function requireSpace() {
		if (response !== '') {
			response += space;
		}
	}

	if (getFirstName) {
		response = firstName;
	}
	if (getSecondName && secondName !== '') {
		requireSpace();
		response += secondName;
	}
	if (getFirstLastName && firstLastName !== '') {
		requireSpace();
		response += firstLastName;
	}
	if (getSecondLastName && secondLastName !== '') {
		requireSpace();
		response += secondLastName;
	}
	return response;
}
