// Libs
import React from 'react';
import { connect } from 'react-redux';
// Utils
import { getMyEntityActiveUsers } from 'utils/libs/filterUsers';
import REPORT_KEYWORDS from 'screens/Reports/keywords';
// Actions
import { getCharts } from './actions';
import {
	mutate1Object as mutate1ObjectInReports,
	resetGenerateReportModal,
} from 'screens/Reports/reports.actions';
// Selectors
import {
	selectChartDashboard,
	selectCharts,
	selectGetChartsControl,
} from './selectors';
import { selectGenerateReportModal } from 'screens/Reports/reports.selectors';
import { selectMyProfile, selectAllUsers } from 'screens/Users/selectors';
// Components
import { Card, Col, Row } from 'antd';
import {
	Wrapper,
	Chart,
	Table,
	FullscreenIcon,
	IconButton,
	Empty,
	LogoIcon,
} from 'components';
import ModalReports from 'screens/Reports/Modals';
// Styles
import { default as chartsTheme } from './theme';
import { ExcelIcon } from 'components/Icons';

const { REPORT_TABLES } = REPORT_KEYWORDS;
const chartOrTableDivStyle = {
	width: '100%',
	height: '500px',
	alignContent: 'center',
};
// Tabs
const TABS = {
	CHART: {
		KEY: '1',
		TABNAME: 'Gráfico',
	},
	TABLE: {
		KEY: '2',
		TABNAME: 'Tabla',
	},
};
const tabList = [
	{
		key: TABS.CHART.KEY,
		tab: TABS.CHART.TABNAME,
	},
	{
		key: TABS.TABLE.KEY,
		tab: TABS.TABLE.TABNAME,
	},
];

const Container = ({ children }) => (
	<Wrapper
		padding='0'
		className='animated fadeIn'
		overflow='auto'
		height={`${document.documentElement.clientHeight - 104}px`}
		justifyContent='center'
	>
		{children}
	</Wrapper>
);

const Analytics = ({
	// state
	charts,
	chartDashboard,
	getChartsControl,
	generateReportModal,
	users,
	// actions
	getCharts,
	mutate1ObjectInReports,
	resetGenerateReportModal,
}) => {
	const { loading } = chartDashboard;
	const [activeTabKeys, setActiveTabKeys] = React.useState({});
	const [isChartExpanded, setIsChartExpanded] = React.useState({});

	React.useEffect(() => {
		getCharts({ segment: getChartsControl.segment });
	}, []);

	React.useEffect(() => {
		charts.length !== 0 &&
			setActiveTabKeys(
				Object.fromEntries(charts.map(chart => [chart.id, TABS.CHART.KEY])),
			);

		charts.length !== 0 &&
			setIsChartExpanded(
				Object.fromEntries(
					charts.map(chart => [chart.id, chart.extendedWithTable]),
				),
			);
	}, [charts]);

	if (loading) {
		return (
			<Container>
				<Wrapper justifyContent='center'>
					<LogoIcon margin='20px 0 0 0' spin={true} size='m' />
				</Wrapper>
			</Container>
		);
	}

	if (!loading && charts.length === 0) {
		return (
			<Container>
				<Empty
					margin='20px 0 0 0'
					image={Empty.PRESENTED_IMAGE_SIMPLE}
					description='No tiene gráficos asignados'
				/>
			</Container>
		);
	}

	return (
		<Container>
			<Row gutter={[16, 16]}>
				{charts.map((chart, idx) => (
					<Col key={idx} span={isChartExpanded[chart.id] ? 24 : 12}>
						<Card
							title={chart.title}
							style={{
								backgroundColor: '#2F2F2F',
							}}
							headStyle={{
								color: 'white',
								alignContent: 'center',
								textAlign: 'center',
							}}
							tabList={
								chart.hasTable && !isChartExpanded[chart.id] ? tabList : null
							}
							activeTabKey={activeTabKeys[chart.id]}
							onTabChange={key =>
								setActiveTabKeys({
									...activeTabKeys,
									[chart.id]: key,
								})
							}
							extra={
								<Wrapper padding='0'>
									{chart.hasSqlXlsx && (
										<ExcelIcon
											button
											onClick={() =>
												mutate1ObjectInReports('generateReportModal', {
													isOpen: true,
													reportId: chart.id,
													reportTable: REPORT_TABLES.CHART,
													params: getChartsControl.params,
												})
											}
										/>
									)}
									{chart.hasTable && (
										<FullscreenIcon
											button
											selected={isChartExpanded[chart.id]}
											onClick={() =>
												setIsChartExpanded({
													...isChartExpanded,
													[chart.id]: !isChartExpanded[chart.id],
												})
											}
										/>
									)}
								</Wrapper>
							}
						>
							{isChartExpanded[chart.id] ? (
								<Row>
									<Col span={12}>
										<div style={chartOrTableDivStyle}>
											<Chart
												type={chart.type}
												data={chart.data}
												config={chart.config}
												theme={chartsTheme}
											/>
										</div>
									</Col>
									<Col span={12}>
										<div style={chartOrTableDivStyle}>
											<Table
												type={chart.type}
												data={chart.data}
												config={chart.config}
												orientation={chart.tableOrientation}
											/>
										</div>
									</Col>
								</Row>
							) : (
								<div style={chartOrTableDivStyle}>
									{activeTabKeys[chart.id] === TABS.CHART.KEY && (
										<Chart
											type={chart.type}
											data={chart.data}
											config={chart.config}
											theme={chartsTheme}
										/>
									)}
									{activeTabKeys[chart.id] === TABS.TABLE.KEY && (
										<Table
											type={chart.type}
											data={chart.data}
											config={chart.config}
											orientation={chart.tableOrientation}
										/>
									)}
								</div>
							)}
						</Card>
					</Col>
				))}
			</Row>

			{/* MODAL */}
			<ModalReports
				reports={charts}
				generateReportModal={generateReportModal}
				users={users}
				mutate1ObjectInReports={mutate1ObjectInReports}
				resetGenerateReportModal={resetGenerateReportModal}
			/>
		</Container>
	);
};

const mapStateToProps = state => ({
	charts: selectCharts(state),
	chartDashboard: selectChartDashboard(state),
	getChartsControl: selectGetChartsControl(state),
	generateReportModal: selectGenerateReportModal(state),
	users: getMyEntityActiveUsers(selectAllUsers(state), selectMyProfile(state)),
});

const actions = {
	getCharts,
	mutate1ObjectInReports,
	resetGenerateReportModal,
};

export default connect(mapStateToProps, actions)(Analytics);
