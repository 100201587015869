// Libs
import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { Wrapper } from 'components';
import { selectProfile } from 'screens/Login/selectors';
import { selectSelectedSubModuleId } from 'components/Modules/selectors';
import { ModalBuilder } from 'core/ModalBuilder';
// Illustrations
import { SuccessFactorsIllustration } from 'components/Illustrations';
// Utils
import KEYWORDS from '../keywords';
// Selectors
import {
	selectElementKey,
	selectScreenKey,
	selectDatasourceData,
	selectDatasourceStatus,
	selectIsFetchingDisplay,
	selectSelectedDataFilterLastRowId,
	selectDatasourceFields,
	selectDatasourcePaging,
} from '../selectors';
// Components
import { ScreenBuilder } from '../ScreenBuilder';
import { ToolbarBuilder } from '../ToolbarBuilder';
import { ElementViewBuilder } from '../ElementViewBuilder';

const { ROUTES } = KEYWORDS;

const Switcher = ({
	isMobile,
	elementKey,
	screenKey,
	selectedSubModuleId,
	isFetchingDisplay,
	dataFilterLastRowId,
	datasourceStatus,
	datasourcePaging,
	datasourceFields,
	datasource,
	profile,
}) => (
	<Switch>
		<Route
			exact
			path={ROUTES.FEED_MODULE_ELEMENT.PATH}
			children={
				<ElementViewBuilder
					shortcutPath={true}
					isMobile={isMobile}
					elementKey={elementKey}
				/>
			}
		/>
		<Route
			exact
			path={ROUTES.FEED_MODULE.PATH}
			children={
				<>
					<ScreenBuilder
						elementKey={elementKey}
						screenKey={screenKey}
						isMobile={isMobile}
						selectedSubModuleId={selectedSubModuleId}
						isFetchingDisplay={isFetchingDisplay}
						dataFilterLastRowId={dataFilterLastRowId}
						datasourceStatus={datasourceStatus}
						datasourcePaging={datasourcePaging}
						datasourceFields={datasourceFields}
						datasource={datasource}
						profile={profile}
					/>
					<ElementViewBuilder isMobile={isMobile} elementKey={elementKey} />
				</>
			}
		/>
		<Route
			path={ROUTES.FEED.PATH}
			children={
				<SuccessFactorsIllustration
					className='animated backInRight'
					title='Bienvenido a Synapse Feed'
					width={isMobile ? '200px' : '400px'}
					fontSize='2em'
				/>
			}
		/>
	</Switch>
);

const DisplayBuilder = React.memo(
	({
		// Props
		menuIsOpen,
		isMobile,
		// State
		elementKey,
		screenKey,
		selectedSubModuleId,
		isFetchingDisplay,
		dataFilterLastRowId,
		datasourceStatus,
		datasourcePaging,
		datasourceFields,
		datasourceData,
		profile,
	}) => (
		<Wrapper
			style={isMobile ? { gridColumn: '1 / -1' } : undefined}
			padding='0'
			flexDirection='column'
			width='100%'
			overflow='hidden'
		>
			<ToolbarBuilder
				menuIsOpen={menuIsOpen}
				screenKey={screenKey}
				elementKey={elementKey}
				isMobile={isMobile}
				datasource={datasourceData}
			/>
			<Switcher
				isMobile={isMobile}
				elementKey={elementKey}
				screenKey={screenKey}
				selectedSubModuleId={selectedSubModuleId}
				isFetchingDisplay={isFetchingDisplay}
				dataFilterLastRowId={dataFilterLastRowId}
				datasourceStatus={datasourceStatus}
				datasourcePaging={datasourcePaging}
				datasourceFields={datasourceFields}
				datasource={datasourceData}
				profile={profile}
			/>
			<ModalBuilder />
		</Wrapper>
	),
);

const mapStateToProps = state => ({
	elementKey: selectElementKey(state),
	screenKey: selectScreenKey(state),
	selectedSubModuleId: selectSelectedSubModuleId(state),
	isFetchingDisplay: selectIsFetchingDisplay(state),
	dataFilterLastRowId: selectSelectedDataFilterLastRowId(state),
	datasourceStatus: selectDatasourceStatus(state),
	datasourcePaging: selectDatasourcePaging(state),
	datasourceFields: selectDatasourceFields(state),
	datasourceData: selectDatasourceData(state),
	profile: selectProfile(state),
});

export default connect(mapStateToProps, null)(DisplayBuilder);
