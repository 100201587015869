import { useState, useEffect, useCallback } from 'react';

const useActiveApp = () => {
	const [active, setActive] = useState('visible');

	const handleVisibilityChange = useCallback(() => {
		setActive(document.visibilityState === 'visible');
	}, []);

	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	return [active];
};

export default useActiveApp;
