// Libs
import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
// Realtime context
import { RealtimeConsumer, TOPICS } from '../RealtimeContext';
// Actions
import {
	mutate1Object as mutate1ObjectInDashboard,
	getNotifications,
	setOnReceivedNotification,
	markNotification,
	sendToast,
} from 'screens/Dashboard/actions';
import {
	getOrder,
	mutate1Object as mutate1ObjectInOrders,
} from 'screens/Orders/actions';
import { mutate1Object as mutate1ObjectInWarehouses } from 'screens/Warehouses/actions';
import { getTransactionDocs } from 'store/api/actions';
// Selectors
import {
	selectModeView,
	selectNotificationControl,
} from 'screens/Dashboard/selectors';
import { selectProfile } from 'screens/Login/selectors';
// Utils
import RealtimeUtils from '../utils';
import GENERAL from 'utils/constants/general';
import { selectControlOrders } from 'screens/Orders/selectors';
// Hooks
import { usePrevious } from 'hooks';

const { ENV } = GENERAL;

const onSavedPdf = ({ message, setOnReceivedNotification }) => {
	setOnReceivedNotification(message);
};

// Subscribed topics
const subscribedActions = {
	[TOPICS.ACTIONS.REPORTS.SAVED.PDF]: onSavedPdf,
};

// Actions
const selectOrderNotification = ({
	history,
	dispatchableId,
	orders,
	mutate1ObjectInOrders,
	getOrder,
}) => {
	const order = orders.find(ord => ord.order_id === dispatchableId);
	if (!order) getOrder(dispatchableId);
	mutate1ObjectInOrders('getOrderInformationModal', {
		order_id: Number(dispatchableId),
		isOpen: true,
	});
	history.push(ENV.ROUTES.PATHS.ORDERS);
};
const selectWTDNotification = ({
	history,
	modeView,
	dispatchableId,
	mutate1ObjectInWarehouses,
	getTransactionDocs,
}) => {
	mutate1ObjectInWarehouses('transactionsManage', {
		docId: dispatchableId,
		titleToolbar: 'Detalle de transacción',
	});
	mutate1ObjectInWarehouses('getTransactionsModal', {
		mode: 'document',
		docNumber: dispatchableId,
		fromDate: dayjs(),
		toDate: dayjs(),
	});
	getTransactionDocs({
		mode: 'document',
		docNumber: dispatchableId,
		fromDate: dayjs(),
		toDate: dayjs(),
	});
	// getItemsTransactionDocs(dispatchableId);
	if (modeView === 'cards')
		history.push(ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_MOBILE);
	else if (modeView === 'table')
		history.push(ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_DESKTOP);
};
const selectCollectorValueReport = ({
	history,
	dispatchableId,
	dispatchableType,
	action,
	data,
}) => {
	window.open(data.url);
	return;
	const path = generatePath(
		'/dashboard/supervisor/:action/:dispatchableType/:dispatchableId/:data',
		{
			action,
			dispatchableType,
			dispatchableId,
			data: encodeURIComponent(JSON.stringify(data)),
		},
	);
	history.push(path);
};

const NotificationHandler = ({
	// Realtime context
	children,
	payloadMessage,
	mqttSub,
	mqttUnSub,
	// State
	modeView,
	profile,
	notificationControl,
	orders,
	// Actions
	getNotifications,
	getOrder,
	getTransactionDocs,
	markNotification,
	setOnReceivedNotification,
	mutate1ObjectInDashboard,
	mutate1ObjectInOrders,
	mutate1ObjectInWarehouses,
	sendToast,
}) => {
	const history = useHistory();

	// Subscriptions/Unsubscriptions
	const prevProfile = usePrevious(profile);
	React.useEffect(() => {
		if (prevProfile?.user?.profileId !== profile?.user?.profileId) {
			// Unsubscribe
			if (prevProfile)
				Object.keys(subscribedActions).map(topic =>
					mqttUnSub(topic, RealtimeUtils.getMyProfileSubscriber(prevProfile)),
				);
			// Subscribe
			if (profile)
				Object.keys(subscribedActions).map(topic =>
					mqttSub(topic, RealtimeUtils.getMyProfileSubscriber(profile)),
				);
		} else {
			// Subscribe
			if (profile)
				Object.keys(subscribedActions).map(topic =>
					mqttSub(topic, RealtimeUtils.getMyProfileSubscriber(profile)),
				);
		}
		return () => {
			Object.keys(subscribedActions).map(topic =>
				mqttUnSub(topic, RealtimeUtils.getMyProfileSubscriber(profile)),
			);
		};
	}, [profile?.user?.profileId]);

	// Receive new payload message
	React.useEffect(() => {
		if (!payloadMessage) return;

		const { action, message } = payloadMessage;
		const subscribedAction = RealtimeUtils.findTopicInSubscribedTopics(
			action,
			subscribedActions,
		);
		if (!subscribedAction) return;

		onReceiveMessageHandler({ action, message });
	}, [payloadMessage]);

	// Handler on receive message
	const onReceiveMessageHandler = React.useCallback(({ action, message }) => {
		if (typeof subscribedActions[action] === 'function') {
			subscribedActions[action]({
				message,
				setOnReceivedNotification,
			});
		}
	}, []);

	const selectNotification = ({
		userNotificationId,
		dispatchableId,
		dispatchableType,
		action,
		seen,
		read,
		data,
	}) => {
		switch (dispatchableType) {
			case 'Orders':
				selectOrderNotification({
					history,
					dispatchableId,
					orders,
					mutate1ObjectInOrders,
					getOrder,
				});
				break;
			case 'WarehouseTransactionDocs':
				selectWTDNotification({
					history,
					modeView,
					dispatchableId,
					mutate1ObjectInWarehouses,
					getTransactionDocs,
				});
				break;
			case TOPICS.DISPATCHABLE_TYPES.COLLECTOR_VALUES.CONTRACT:
			case TOPICS.DISPATCHABLE_TYPES.COLLECTOR_VALUES.OTD:
			case TOPICS.DISPATCHABLE_TYPES.COLLECTOR_VALUES.WTD:
			case TOPICS.DISPATCHABLE_TYPES.COLLECTOR_VALUES.UTD:
				selectCollectorValueReport({
					history,
					dispatchableId,
					dispatchableType,
					action,
					data,
				});
				break;
		}

		if (!read) markNotification({ userNotificationId, seen, read: true });
		mutate1ObjectInDashboard('notificationControl', {
			show: false,
		});
	};

	return children({
		profile,
		notificationControl,
		mutate1ObjectInDashboard,
		getNotifications,
		selectNotification,
		markNotification,
	});
};

// Redux connect
const actions = {
	getNotifications,
	getOrder,
	getTransactionDocs,
	markNotification,
	setOnReceivedNotification,
	mutate1ObjectInDashboard,
	mutate1ObjectInOrders,
	mutate1ObjectInWarehouses,
	sendToast,
};
const mapStateToProps = state => ({
	modeView: selectModeView(state),
	profile: selectProfile(state),
	notificationControl: selectNotificationControl(state),
	orders: selectControlOrders(state),
});
const RealtimeNotificationHandler = connect(
	mapStateToProps,
	actions,
)(
	({
		// Props
		children,
		// State
		profile,
		notificationControl,
		orders,
		// Actions
		getNotifications,
		getOrder,
		getTransactionDocs,
		markNotification,
		setOnReceivedNotification,
		mutate1ObjectInDashboard,
		mutate1ObjectInOrders,
		mutate1ObjectInWarehouses,
		sendToast,
	}) => (
		<RealtimeConsumer>
			{({ payload, mqttSub, mqttUnSub }) => (
				<NotificationHandler
					// Realtime context
					payloadMessage={payload}
					mqttSub={mqttSub}
					mqttUnSub={mqttUnSub}
					// State
					profile={profile}
					notificationControl={notificationControl}
					orders={orders}
					// Actions
					getNotifications={getNotifications}
					getOrder={getOrder}
					getTransactionDocs={getTransactionDocs}
					markNotification={markNotification}
					setOnReceivedNotification={setOnReceivedNotification}
					mutate1ObjectInDashboard={mutate1ObjectInDashboard}
					mutate1ObjectInOrders={mutate1ObjectInOrders}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					sendToast={sendToast}
				>
					{children}
				</NotificationHandler>
			)}
		</RealtimeConsumer>
	),
);

export default RealtimeNotificationHandler;
