// Libs
import React from 'react';
import styled, { css } from 'styled-components';

// Hooks
import { useTheme } from 'hooks';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';

const Button = styled(IconButton)`
	${props =>
		props.shadow &&
		css`
			-webkit-box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
			box-shadow:
				-10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
		`}
`;

const RenderIcon = ({ onClick, color }) => {
	const { theme } = useTheme();

	return <Icon fill={color ?? theme._primaryColor} onClick={onClick} />;
};

const OpenInNewIcon = ({ button, onClick, shadow, color }) => {
	if (button) {
		return (
			<Button shadow={shadow} onClick={onClick}>
				<RenderIcon color={color} />
			</Button>
		);
	}

	return <RenderIcon onClick={onClick} color={color} />;
};

export default OpenInNewIcon;
