// Libs
import cx from 'classnames';
// Hooks
import useTheme from 'hooks/useTheme';
// Styles
import styles from './CaptionMultylines.module.css';

const CaptionMultylines = props => {
	const { children } = props;
	const { theme, sharedStyles } = useTheme();
	return (
		<span
			style={{ width: props.width, margin: props.margin, color: props.color }}
			className={cx(
				styles.caption,
				theme.secondaryColor,
				sharedStyles.standardTextStructure,
				sharedStyles.ellipsisMultylines,
				{
					[sharedStyles.cursorPointer]: !!props.cursorPointer,
					[sharedStyles.wordBreak]: !!props.wordBreak,
					[sharedStyles.cursorPointer]: !!props.cursorPointer,
					[sharedStyles[`textAlign_${props.textAlign}`]]: !!props.textAlign,
					[sharedStyles[`webkitLineClamp_${props.ellipsisMultylines}`]]:
						!!parseInt(props.ellipsisMultylines),
					[theme.inactiveColor]: !!props.disabled,
					[theme.hoverable]: !!props.hover,
					[theme.actived]: !!props.selected,
					[theme[props.color]]:
						!!props.color === 'primaryColor' ||
						props.color === 'secondaryColor' ||
						props.color === 'tertiaryColor',
				},
			)}
		>
			{children}
		</span>
	);
};

export default CaptionMultylines;
