import styled from 'styled-components';

export const PopupContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const PopupHeader = styled.h3`
	text-align: center;
`;

export const PopupRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
`;

export const PopupBoldLabel = styled.p`
	margin: 0;
	font-weight: bold;
`;

export const PopupValue = styled.p`
	margin: 0;
`;

export const PopupImage = styled.img`
	width: 250px;
	height: 250px;
`;
