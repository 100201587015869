// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11 5V11H5V13H11V19H13V13H19V11H13V5H11Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
