// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Utils
import { getSelectedPaycuts } from 'utils/filters/Billings/paycuts';
// Components
import {
	Wrapper,
	Dropdown,
	Menu,
	Span,
	RefreshIcon,
	LogoIcon,
	EyeIcon,
} from 'components';

export default function InvoicePays({
	billingsState,
	api,
	modeView,
	getPaymentResume,
	mutate1ObjectInBillings,
	getInvoicePays,
}) {
	const theme = useTheme();

	const selectedPaycuts = getSelectedPaycuts(
		api.payments.paycuts.data,
		billingsState.paycuts.selected.items,
	);

	const MenuViewButton = (
		<Menu.Wrapper>
			<Menu.Item
				onClick={() => {
					mutate1ObjectInBillings('signPaymentModal', { isOpen: true });
					getPaymentResume(billingsState.pays.selected.items);
				}}
			>
				Resumen
			</Menu.Item>
		</Menu.Wrapper>
	);

	return (
		<Wrapper
			height='48px'
			justifyContent='flex-start'
			padding='10px 0 10px 10px'
			mediumBackground
		>
			<Wrapper width='100%' padding='0'>
				{selectedPaycuts.length > 1 && (
					<Span
						fontWeight='bold'
						fontSize='l'
						color={theme.fonts.colors.default}
					>
						Cortes fusionados: {selectedPaycuts.length}
					</Span>
				)}
			</Wrapper>

			<Wrapper padding='0'>
				{/* UPDATE PAYMENTS */}
				{!api.payments.pays.loading ? (
					<RefreshIcon
						button
						onClick={() => getInvoicePays(billingsState.paycuts.selected.items)}
					/>
				) : (
					<LogoIcon button spin />
				)}

				{/* VIEW */}
				{modeView === 'table' &&
					billingsState.pays.selected.items.length >= 1 && (
						<Dropdown.Wrapper
							trigger={['click']}
							placement='bottomRight'
							overlay={MenuViewButton}
						>
							<EyeIcon button />
						</Dropdown.Wrapper>
					)}
			</Wrapper>
		</Wrapper>
	);
}
