// Libs
import React from 'react';
import { Modal } from '../Utils';

export default function ModalsWarehouses(props) {
	const {
		// WAREHOUSES
		modeView,
		warehouses,
		itemStates,
		incomeMethods,
		controlSeries,
		controlSeriesTransactionModal,
		serieHistory,
		mutate1Object,
		getTransactionsModal,
		onCreateControlSeriesTransaction,
		resetCreateControlSeriesTransaction,
		signWTD,
		signWTDModal,
		transactionsManage,
		createNewEntryItem,
		toolsOptionsManage,
		createTransaction,
		createPackage,
		transactions,
		getInventoryToTransfer,
		sendCreatePackage,
		// LOGIN
		profile,
		// DASHBOARD
		sendToast,
		// ORDERS
		ordersState,
		// API
		warehouseZones,
		warehouseAreas,
		articles,
		users,
		history,
		mutate1ObjectApi,
		getTransactionDocs,
		updateWTDSigner,
	} = props;
	const totalUsers = users.data;
	const { control, getOrderInformationModal } = ordersState;
	const order =
		getOrderInformationModal.order_id &&
		control.orders.filter(
			ord => ord.order_id === getOrderInformationModal.order_id,
		)[0];
	const addedArticles = [...createTransaction.articles];

	return (
		<>
			{/* GET TRANSACTIONS */}
			<Modal
				modalId='GetTransactionsModal'
				title='Consultar Transacciones'
				visible={getTransactionsModal.isOpen}
				onCancel={() =>
					mutate1Object('getTransactionsModal', { isOpen: false })
				}
				footer={null}
				isSpinning={transactions.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					getTransactionsModal,
					getTransactionDocs,
					mutate1ObjectInWarehouses: mutate1Object,
				}}
			/>

			{/* SIGN WAREHOUSE TRANSACTION DOC */}
			<Modal
				modalId='SignWTDModal'
				title='Enviar a'
				visible={signWTDModal.isOpen}
				onCancel={() => mutate1Object('signWTDModal', { isOpen: false })}
				footer={null}
				isSpinning={signWTDModal.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					profile,
					totalUsers,
					signWTDModal,
					transactions,
					mutate1ObjectInWarehouses: mutate1Object,
					transactionsManage,
					signWTD,
					updateWTDSigner,
				}}
			/>

			{/* NEW ENTRY ARTICLE */}
			<Modal
				modalId='NewEntryArticleModal'
				title='Nueva Artículo'
				visible={createNewEntryItem.isOpen}
				onCancel={() => mutate1Object('createNewEntryItem', { isOpen: false })}
				footer={null}
				isSpinning={createNewEntryItem.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					articles: articles.data,
					createNewEntryItem,
					mutate1ObjectInWarehouses: mutate1Object,
					addedArticles,
					dwarehouseName: createTransaction.dwarehouseName,
					sendToast,
					modeView,
				}}
			/>

			{/* CONTROL SERIES TRANSACTION */}
			{controlSeriesTransactionModal.isOpen && (
				<Modal
					modalId='ControlSeriesTransactionModal'
					title='Crear Transacción'
					visible={controlSeriesTransactionModal.isOpen}
					onCancel={() => resetCreateControlSeriesTransaction()}
					footer={null}
					isSpinning={controlSeriesTransactionModal.loading}
					sizeSpinning='large'
					delaySpinning={50}
					tipSpinning='Un momento porfavor...'
					customProps={{
						// Props
						warehouses,
						itemStates,
						incomeMethods,
						controlSeries,
						controlSeriesTransactionModal,
						// Actions
						mutate1ObjectInWarehouses: mutate1Object,
						onCreateControlSeriesTransaction,
					}}
				/>
			)}

			{/* SERIE HISTORY */}
			<Modal
				modalId='SerieHistoryModal'
				title='Movimientos'
				visible={serieHistory.isOpen}
				onCancel={() => mutate1ObjectApi('serieHistory', { isOpen: false })}
				footer={null}
				isSpinning={serieHistory.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					serieHistory,
					sendToast,
					modeView,
				}}
			/>

			{/* TOOLS OPTIONS MANAGE */}
			<Modal
				modalId='ToolsOptionsManage'
				title='Herramientas'
				visible={toolsOptionsManage.isOpen}
				onCancel={() => mutate1Object('toolsOptionsManage', { isOpen: false })}
				footer={null}
				isSpinning={toolsOptionsManage.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					toolsOptionsManage,
				}}
			/>

			{/* CREATE TRANSACTION MODAL */}
			<Modal
				modalId='CreateTransactionModal'
				title='Nueva Transacción'
				visible={createTransaction.isOpen}
				onCancel={() =>
					mutate1Object('createTransaction', {
						isOpen: false,
						mode: undefined,
						incomeMethod: undefined,
						owhOwnerId: undefined,
						owarehouseId: undefined,
						dwarehouseId: undefined,
						signer: undefined,
					})
				}
				footer={null}
				isSpinning={createTransaction.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					createTransaction,
					incomeMethods,
					totalUsers,
					warehouses: warehouses.data,
					mutate1ObjectInWarehouses: mutate1Object,
					inventory: transactions.inventory,
					getInventoryToTransfer,
					history,
					profile,
				}}
			/>

			{/* CREATE PACKAGE */}
			<Modal
				modalId='CreatePackage'
				title='Nuevo Paquete'
				visible={createPackage.isOpen}
				onCancel={() => mutate1Object('createPackage', { isOpen: false })}
				footer={null}
				isSpinning={createPackage.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					articles: articles.data,
					itemStates,
					warehouseZones,
					warehouseAreas,
					createPackage,
					mutate1ObjectInWarehouses: mutate1Object,
					sendCreatePackage,
				}}
			/>
		</>
	);
}
