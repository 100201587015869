// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mediumWarehouseIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='4'
			y='2'
			width='24'
			height='28'
		>
			<path
				d='M21.3333 26.6666H10.6667V24H21.3333V26.6666ZM21.3333 29.3333H10.6667H21.3333ZM21.3333 21.3333H10.6667V18.6666H21.3333V21.3333ZM24 17.3333V14.6666H8V17.3333V29.3333H4V10.6666L16 2.66663L28 10.6666V29.3333H24V17.3333ZM16 12C17.1046 12 18 11.1045 18 9.99996C18 8.89539 17.1046 7.99996 16 7.99996C14.8954 7.99996 14 8.89539 14 9.99996C14 11.1045 14.8954 12 16 12Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mediumWarehouseIcon)'>
			<path d='M32 0H0V32H32V0Z' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
