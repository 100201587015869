// Libs
import React from 'react';
// Components
import {
	PieChart,
	Pie as _Pie,
	Cell,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';
// Styles
import styles from './Pie.module.css';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (
	dataKey,
	{ cx, cy, midAngle, innerRadius, outerRadius, ...res },
) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);
	return (
		<text
			x={x}
			y={y}
			fill='white'
			textAnchor={x > cx ? 'start' : 'end'}
			dominantBaseline='central'
		>
			{res[dataKey]}
			{/* {`${(percent * 100).toFixed(0)}%`} */}
		</text>
	);
};

/**
 * See data structure example:
 * https://recharts.org/en-US/examples/PieChartWithCustomizedLabel
 */
const Pie = ({ data, config = {}, onClickChartSegment }) => {
	const {
		colors,
		values = ['value'],
		width = 300,
		height = 300,
		labelLine = false,
	} = config;
	const dataKey = values[0];
	return (
		<div className={styles.pieContainer} style={{ width, height }}>
			<ResponsiveContainer width='100%' height='100%'>
				<PieChart width={width} height={height}>
					<Tooltip />
					<_Pie
						dataKey={dataKey}
						data={data}
						cx='50%'
						cy='50%'
						labelLine={labelLine}
						label={props => renderCustomizedLabel(dataKey, props)}
						outerRadius={80}
						fill='#8884d8'
						onClick={onClickChartSegment}
					>
						{data.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={colors[index % colors.length]}
							/>
						))}
					</_Pie>
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

export default Pie;
