// Libs
import React, { useContext } from 'react';
//Actions
import { doLogout } from 'screens/Login/actions';
//Hooks
import { useDispatch } from 'react-redux';
// Utils
import { CloudStorage } from 'components/AppConfig.json';
import { lsHandler } from 'utils/libs';
// Context
import { ModalBuilderContext } from 'core/context';
// Components
import { Wrapper, Modal, Avatar, Button } from 'components';
import { SelectUserProfileActive } from 'components/DataFieldManager';

const Header = ({ userId }) => {
	return (
		<Wrapper
			width='100%'
			gap='15px'
			margin='0'
			justifyContent='center'
			flexDirection='column'
		>
			<Avatar
				fit
				avatar={{
					src: CloudStorage.concat(
						`/syn4pse/users/avatars/${
							userId ? `${userId}.jpg` : `default.svg`
						}`,
					),
				}}
			/>
			<Wrapper
				padding='0 16px'
				width='50%'
				gap='5px'
				flexDirection='column'
				justifyContent='center'
			>
				<SelectUserProfileActive userId={userId} />
			</Wrapper>
		</Wrapper>
	);
};

const Profile = () => {
	const {
		payload: { userId },
		cleanModal,
	} = useContext(ModalBuilderContext);

	const dispatch = useDispatch();

	const logout = () => dispatch(doLogout());

	return (
		<Modal
			title={null}
			closable={false}
			centered={false}
			visible={true}
			onCancel={cleanModal}
			footer={null}
		>
			<Wrapper
				width='100%'
				margin='0'
				justifyContent='center'
				flexDirection='column'
			>
				<Header userId={userId} />
				<Wrapper width='100%' margin='0' justifyContent='center'>
					<Button onClick={logout}>Cerrar sesión</Button>
				</Wrapper>
			</Wrapper>
		</Modal>
	);
};

export default Profile;
