// Libs
import React from 'react';

// Components
import { Skeleton } from 'components';

const ReactionsSkeleton = ({ active = false, buttonCount = 2 }) => {
	return (
		<>
			{[...Array(Number(buttonCount)).keys()].map(reaction => (
				<Skeleton.Button
					key={reaction}
					shape='circle'
					style={{
						marginRight: '3px',
					}}
					active={active}
				/>
			))}
		</>
	);
};

export default ReactionsSkeleton;
