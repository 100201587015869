export function onUpdateContractValidation(request) {
	const { contractIds, fieldsToUpdate } = request;
	let errors = '';

	if (!Array.isArray(contractIds) || contractIds.length === 0) {
		errors += '[La solicitud debe especificar los ids de contratos]';
	}
	if (
		!fieldsToUpdate ||
		typeof fieldsToUpdate !== 'object' ||
		Object.keys(fieldsToUpdate).length === 0
	) {
		errors += '[La solicitud debe incluir al menos un dato para actualizar]';
	}

	return errors === '' ? null : errors;
}
