export default class FormComponentProcess {
	constructor(order, fields, formComponentValues) {
		this.order = order;
		this.fields = fields;
		this.formComponentValues = formComponentValues;
	}

	run() {
		return this.fields.reduce((order, field) => {
			if (this.formComponentValues[field.id])
				order[field.id] = this.formComponentValues[field.id];
			return order;
		}, this.order);
	}
}
