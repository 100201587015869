// Libs
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import axios from 'axios';
// Utils
import asyncErrorsHandler from '../../store/asyncErrorsHandler';
import AuthService from '../../utils/libs/auth/AuthService';
import GENERAL from '../../utils/constants/general';
import API from '../../utils/constants/api';

const { APIDATA, USERS, DASHBOARD } = GENERAL;
const auth = new AuthService();
const getUsers = state => state.api.users.data;

//* ******* WATCHER SAGAS ***********/
function* changeWorkCodeWatcher() {
	yield takeLatest(USERS.CHANGE_WORK_CODE, changeWorkCodeWorker);
}

function* changeActivationWatcher() {
	yield takeLatest(USERS.CHANGE_ACTIVATION, changeActivationWorker);
}

function* changeRelationWatcher() {
	yield takeLatest(USERS.CHANGE_RELATION, changeRelationWorker);
}

function* setPermissionsWatcher() {
	yield takeLatest(USERS.SET_PERMISSIONS, setPermissionsWorker);
}

//* ******* WORKERS SAGAS ***********/
function* changeWorkCodeWorker(action) {
	const { userId, workCodeAssign } = action.payload;
	const usersData = yield select(getUsers);
	const users = [...usersData];

	function setUpdatingWorkCode(value) {
		return users.map(user => {
			if (user.id === userId) user.updatingWorkCode = value;
			return user;
		});
	}

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'users',
				keyValuePairs: { data: setUpdatingWorkCode(true) },
			},
		});
		yield call(
			axios.put,
			API.DOMAIN.concat(`/users/assign/work_code`),
			{ userId, workCodeAssign },
			auth.sendToken(),
		);
		yield put({ type: APIDATA.GET_ORGANIZATION_USERS });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'users',
						keyValuePairs: { data: setUpdatingWorkCode(false) },
					},
				});
			},
			function* () {
				yield changeWorkCodeWorker(action);
			},
		);
	}
}

function* changeActivationWorker(action) {
	const { userId, active } = action.payload;
	const usersData = yield select(getUsers);
	const users = [...usersData];

	function setUpdatingActivation(value) {
		return users.map(user => {
			if (user.id === userId) user.updatingActivation = value;
			return user;
		});
	}

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'users',
				keyValuePairs: { data: setUpdatingActivation(true) },
			},
		});
		yield call(
			axios.put,
			API.DOMAIN.concat(`/users/setActive`),
			{ userId, active },
			auth.sendToken(),
		);
		yield put({
			type: USERS.MUTATE_1OBJECT,
			payload: { obj1Name: 'control', keyValuePairs: { selectedUsers: [] } },
		});
		yield put({ type: APIDATA.GET_ORGANIZATION_USERS });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'users',
						keyValuePairs: { data: setUpdatingActivation(false) },
					},
				});
			},
			function* () {
				yield changeActivationWorker(action);
			},
		);
	}
}

function* changeRelationWorker(action) {
	const { userId, relation } = action.payload;
	const usersData = yield select(getUsers);
	const users = [...usersData];

	function setUpdatingRelation(value) {
		return users.map(user => {
			if (user.id === userId) user.updatingRelation = value;
			return user;
		});
	}

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'users',
				keyValuePairs: { data: setUpdatingRelation(true) },
			},
		});
		yield call(
			axios.put,
			API.DOMAIN.concat(`/users/setRelation`),
			{ userId, relation },
			auth.sendToken(),
		);
		yield put({
			type: USERS.MUTATE_1OBJECT,
			payload: { obj1Name: 'control', keyValuePairs: { selectedUsers: [] } },
		});
		yield put({ type: APIDATA.GET_ORGANIZATION_USERS });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'users',
						keyValuePairs: { data: setUpdatingRelation(false) },
					},
				});
			},
			function* () {
				yield changeRelationWorker(action);
			},
		);
	}
}

function* setPermissionsWorker(action) {
	const { users, permissions } = action.payload;

	try {
		yield put({
			type: USERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'setUserConfigurationModal',
				keyValuePairs: { loading: true },
			},
		});
		yield call(
			axios.put,
			API.DOMAIN.concat(`/users/permissions`),
			{ users, permissions },
			auth.sendToken(),
		);
		yield put({
			type: USERS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'setUserConfigurationModal',
				keyValuePairs: { loading: false },
			},
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: 'Permisos actualizados', type: 'success' },
		});
		yield put({ type: APIDATA.GET_ORGANIZATION_USERS });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: USERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'setUserConfigurationModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield setPermissionsWorker(action);
			},
		);
	}
}

//  Export default Root Saga
export default function* rootSaga() {
	yield all([
		changeWorkCodeWatcher(),
		changeActivationWatcher(),
		changeRelationWatcher(),
		setPermissionsWatcher(),
	]);
}
