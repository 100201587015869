// Libs
import { Menu } from 'antd';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';

const { SubMenu, ItemGroup, Item } = Menu;

const MenuStyled = styled(Menu)`
	height: ${props => (props.height ? props.height : props.theme.menu.height)};
	weight: ${props => (props.weight ? props.weight : props.theme.menu.weight)};
	overflow: ${props =>
		props.overflow ? props.overflow : props.theme.menu.overflow};
	border-right: ${props => props.theme.menu.borderRight};
	padding: ${({ padding }) => padding};
	& {
		background-color: ${({ $newTheme, secondaryBackgroundColor }) =>
			secondaryBackgroundColor
				? $newTheme._secondaryBackgroundColor
				: $newTheme._primaryBackgroundColor};
	}
	.ant-menu-title-content {
		color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
		background-color: transparent;
	}
	.ant-menu-item-disabled {
		color: ${props =>
			props.disabledColor
				? props.disabledColor
				: props.theme.menu.item.disabled.color} !important;
	}
	.ant-dropdown-menu-item,
	.ant-dropdown-menu-submenu-title {
		color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
	}
	.ant-menu-item:active,
	.ant-menu-submenu-title:active {
		background-color: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
	}
	&:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
	}
	.ant-dropdown-menu-submenu-title:hover {
		background-color: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
	}
`;

const SubMenuStyled = styled(SubMenu)`
	.ant-menu-sub.ant-menu-inline {
		max-height: ${props =>
			props.maxHeight ? props.maxHeight : props.theme.menu.submenu.maxHeight};
		overflow: ${props =>
			props.overflow ? props.overflow : props.theme.menu.submenu.overflow};
		background-color: ${({ $newTheme }) => $newTheme._primaryBackgroundColor};
	}
`;

const ItemStyled = styled(Item)`
	.ant-dropdown-menu-title-content {
		color: ${({ $newTheme }) => $newTheme._primaryColor};
	}

	&:hover {
		background-color: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
	}
`;

const GroupStyled = styled(ItemGroup)`
	.ant-dropdown-menu-title-content {
		color: ${({ $newTheme }) => $newTheme._primaryColor};
	}
	.ant-dropdown-menu-submenu {
		background-color: ${({ $newTheme }) => $newTheme._primaryBackgroundColor};
	}

	.ant-dropdown-menu-item-group-title {
		color: ${props =>
			props.groupColor ? props.groupColor : props.theme.menu.group.color};
	}
`;

export default {
	Wrapper: ({ children, ...props }) => {
		const { theme } = useTheme();
		return (
			<MenuStyled {...props} $newTheme={theme}>
				{children}
			</MenuStyled>
		);
	},
	SubMenu: ({ children, ...props }) => {
		const { theme } = useTheme();
		return (
			<SubMenuStyled {...props} $newTheme={theme}>
				{children}
			</SubMenuStyled>
		);
	},
	Item: ({ children, icon, ...props }) => {
		const { theme } = useTheme();
		return (
			<ItemStyled icon={icon} {...props} $newTheme={theme}>
				{children}
			</ItemStyled>
		);
	},
	Group: ({ children, ...props }) => {
		const { theme } = useTheme();
		return (
			<GroupStyled {...props} $newTheme={theme}>
				{children}
			</GroupStyled>
		);
	},
};
