// Libs
import React from 'react';
// Components
import DataFieldSwitcher from 'components/DataFieldManager/Switcher';
// Cells
const CellRenderer = ({
	rowIndex,
	rowData,
	field,
	selected,
	fieldActionTypes,
	profile,
	setState,
	Container,
}) => {
	return (
		<DataFieldSwitcher
			rowIndex={rowIndex}
			rowData={rowData}
			field={field}
			selected={selected}
			fieldActionTypes={fieldActionTypes}
			profile={profile}
			setState={setState}
			Container={Container}
		/>
	);
};

export default CellRenderer;
