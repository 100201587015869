// Libs
import React from 'react';
// Utils
import { xlsx } from 'utils/libs';
import { processIncomingOrders } from 'screens/Billings/utils';
// Components
import { AddIcon } from 'components';
import Select from 'components/Select';
// Styles
import '../../../index.css';

const { Option } = Select;

function getImportedFiles(profile) {
	if (
		profile &&
		profile.entity &&
		profile.entity.settings &&
		profile.entity.settings.invoices &&
		profile.entity.settings.invoices.orders &&
		profile.entity.settings.invoices.orders.imported_files
	) {
		return profile.entity.settings.invoices.orders.imported_files;
	} else {
		return [];
	}
}

export default function CreateInvoicePaymentModal(props) {
	const {
		pay,
		enterInvoiceOrdersModal,
		profile,
		mutate1ObjectInBillings,
		enterInvoiceOrders,
		sendToast,
	} = props;
	const importedFiles = getImportedFiles(profile);
	const selectedFile =
		enterInvoiceOrdersModal.selectedFile &&
		importedFiles.filter(
			file => file.filename === enterInvoiceOrdersModal.selectedFile,
		)[0];

	return (
		<div className='modal-container'>
			<div className='create-article-form-items'>
				<Select
					className='form-item-standard'
					style={{ width: '100%' }}
					placeholder='Seleccione la plantilla*'
					onChange={value =>
						mutate1ObjectInBillings('enterInvoiceOrdersModal', {
							selectedFile: value,
						})
					}
				>
					{importedFiles.map(file => (
						<Option key={file.filename}>{file.filename}</Option>
					))}
				</Select>

				<div>
					<input
						id='enterInvoiceOrders'
						style={{ display: 'none' }}
						disabled={!selectedFile}
						accept='.xlsx'
						type='file'
						onChange={async e => {
							try {
								const data = await xlsx.readExcel(e, true);
								if (data) {
									const ordersProcessed = processIncomingOrders(
										data.jsonFile,
										selectedFile,
									);
									enterInvoiceOrders(ordersProcessed, pay.id);
								}
							} catch (err) {
								sendToast({ message: err.message, type: 'warn' });
							}
						}}
					/>
					<label htmlFor='enterInvoiceOrders'>
						<AddIcon button disabled={!selectedFile} />
					</label>
				</div>
			</div>
		</div>
	);
}
