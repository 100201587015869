// Libs
import React from 'react';
// Components
import { Wrapper, LogoIcon, RefreshIcon } from 'components';

export default ({ dndOrdersDrawer, mutate1Object, setOut, onClose }) => {
	// const { loading } = dndOrdersDrawer;

	return (
		<Wrapper padding='0' height='28px' justifyContent='space-between'>
			<Wrapper padding='0'>
				<div
					className='back-icon'
					onClick={() => {
						setOut(true);
						setTimeout(() => onClose(), 700);
					}}
				/>

				{/* <Span fontSize="l" margin="0 10px">
          {profile.user.name}
        </Span> */}
			</Wrapper>

			<Wrapper padding='0' alignItems='center'>
				{/* DETAIL ORDER */}
				<div>
					{/* {isEditing ? (
            <>
              <Tooltip title="Descartar cambios">
                <IconButton
                  size="m"
                  type="close"
                  onClick={discardChanges}
                />
              </Tooltip>
              {(someFieldsChanged || employerInfoChanged) && (
                <Tooltip title="Guardar cambios">
                  <IconButton
                    size="m"
                    type="save"
                    onClick={() =>
                      mutate1Object("editProfileDrawer", { isEditing: false })
                    }
                  />
                </Tooltip>
              )}
            </>
          ) : (
            <Tooltip title="Editar">
              <IconButton
                size="m"
                type="edit"
                onClick={() =>
                  mutate1Object("editProfileDrawer", { isEditing: true })
                }
              />
            </Tooltip>
          )} */}

					{dndOrdersDrawer.loading ? (
						<LogoIcon button />
					) : (
						<RefreshIcon
						// onClick={getMyProfile}
						/>
					)}
				</div>
			</Wrapper>
		</Wrapper>
	);
};
