// Libs
import React from 'react';
import { Link } from 'react-router-dom';
// Utils
import GENERAL from 'utils/constants/general';
// Components
import { DeleteIcon, LogoIcon, Button, Spin } from 'components';
import { Card } from 'antd';
// Styles
import './TransactionCard.css';

const { ENV, DIVISION_ID } = GENERAL;
const { Meta } = Card;

export default function TransactionCard(props) {
	const {
		id,
		doc_number,
		transaction_type_name,
		method_name,
		owarehouse_name,
		dwarehouse_name,
		creator_name,
		entity_name,
		signer_name,
		created_at,
		updated_at,
		completed,
		completed_label,
		dwh_owner_id,
		dwh_owner_division_id,
		signer_id,
		created_by,
		customProps,
	} = props;
	const {
		mutate1ObjectInWarehouses,
		getItemsTransactionDocs,
		signWTD,
		profile,
		signWTDModal,
		transactionsManage,
		deleteTransaction,
	} = customProps;
	const showCompleteButton =
		!completed &&
		dwh_owner_division_id === DIVISION_ID.ENTITY && // Is the owner an entity?
		dwh_owner_id === profile.entity.id; // Is my entity the owner of the destination warehouse?

	return (
		<Meta
			id='transaction_meta-card-container'
			title={
				<div className='transaction-container-title-card'>
					<span className='transaction-card-document'>{doc_number}</span>

					<div style={{ height: '18px' }}>
						<span className='transaction-body-concept-item'>Creado por:</span>
						<span className='transaction-card-creator'>
							{creator_name} - {entity_name}
						</span>
					</div>
					<div style={{ height: '18px' }}>
						<span className='transaction-body-concept-item'>Fecha:</span>
						<span className='transaction-card-created_at'>{created_at}</span>
					</div>
					<div style={{ height: '18px' }}>
						<span className='transaction-body-concept-item'>Actualizado:</span>
						<span className='transaction-card-created_at'>{updated_at}</span>
					</div>
				</div>
			}
			description={
				<div>
					<div className='transaction-container-data-input'>
						<div className='transaction-data'>
							<div className='transaction-body-container-item'>
								<div className='transaction-body-concept-item'>
									Tipo: <b>{transaction_type_name}</b>
								</div>
							</div>
							<div className='transaction-body-container-item'>
								<div className='transaction-body-concept-item'>
									Método: <b>{method_name}</b>
								</div>
							</div>
							<div className='transaction-body-container-item'>
								<div className='transaction-body-concept-item'>
									Firmante: <b>{signer_name}</b>
								</div>
							</div>
						</div>

						<div className='transaction-data'>
							<div className='transaction-body-container-item'>
								<div className='transaction-body-concept-item'>
									Origen: <b>{owarehouse_name}</b>
								</div>
							</div>
							<div className='transaction-body-container-item'>
								<div className='transaction-body-concept-item'>
									Destino: <b>{dwarehouse_name}</b>
								</div>
							</div>
						</div>
					</div>

					<div className='transaction-buttons-container'>
						{!completed && created_by === profile.user.id && (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{transactionsManage.deleting ? (
									<LogoIcon spin={true} />
								) : (
									<DeleteIcon onClick={() => deleteTransaction(id)} />
								)}
							</div>
						)}

						<Link
							to={ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_ITEMS_MOBILE}
							onClick={() => {
								mutate1ObjectInWarehouses('transactionsManage', {
									docId: id,
									titleToolbar: 'Detalle',
								});
								getItemsTransactionDocs(id);
							}}
						>
							<Button style={{ height: '28px' }}>Ver detalle</Button>
						</Link>

						{!signWTDModal.loading && (
							<Button
								style={{ height: '28px' }}
								onClick={() => {
									mutate1ObjectInWarehouses('transactionsManage', {
										docId: id,
									});
									mutate1ObjectInWarehouses('signWTDModal', { isOpen: true });
								}}
							>
								Firmas
							</Button>
						)}

						{showCompleteButton && (
							<Spin
								spinning={signWTDModal.loading}
								size='small'
								delay={50}
								tip={''}
							>
								<Button
									className='button-style-1'
									style={{ height: '28px' }}
									onClick={() => signWTD(id, signer_id, true, 'transaction')}
								>
									Completar
								</Button>
							</Spin>
						)}
					</div>
				</div>
			}
		/>
	);
}
