// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	// <svg
	// 	width="16"
	// 	height="16"
	// 	viewBox="0 0 16 16"
	// 	fill="none"
	// 	xmlns="http://www.w3.org/2000/svg"
	// >
	// 	<mask
	// 		id="arrowDownIcon16"
	// 		style={{ maskType: "alpha" }}
	// 		maskUnits="userSpaceOnUse"
	// 		x="4"
	// 		y="5"
	// 		width="8"
	// 		height="6"
	// 	>
	// 		<path
	// 			d="M4.94 5.72668L8 8.78002L11.06 5.72668L12 6.66668L8 10.6667L4 6.66668L4.94 5.72668Z"
	// 			fill="white"
	// 		/>
	// 	</mask>
	// 	<g mask="url(#arrowDownIcon16)">
	// 		<rect width="16" height="16" fill={fill} fillOpacity={fillOpacity} />
	// 	</g>
	// </svg>

	<svg
		width='16'
		height='16'
		viewBox='4 4 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mask0_21958_125'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='7'
			y='10'
			width='10'
			height='5'
		>
			<path d='M7 10L12 15L17 10H7Z' fill={fill} />
		</mask>
		<g mask='url(#mask0_21958_125)'>
			<rect width='16' height='16' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
