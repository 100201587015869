export default Object.freeze({
	// background: "#ffffff",
	textColor: '#ffffff',
	fontSize: 14,
	// axis: {
	//   domain: {
	//     line: {
	//       stroke: "#777777",
	//       strokeWidth: 1,
	//     }
	//   },
	//   ticks: {
	//     line: {
	//       stroke: "#777777",
	//       strokeWidth: 1,
	//     }
	//   }
	// },
	// grid: {
	//   line: {
	//     stroke: "#dddddd",
	//     strokeWidth: 1,
	//   }
	// },
});
