// Libs
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
// Context
import { ModalBuilderContext } from 'core/context';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
import { removeDatasourceItem } from 'core/actions';
// Hooks
import { useOrderTransactionDoc } from 'hooks/Orders';
// Components
import {
	Wrapper,
	Modal,
	Title,
	Subtitle,
	Span,
	AntButton,
	LogoIcon,
} from 'components';
// Styles
import styles from './OrderDeleteAuditedOTD.module.css';

const DeleteAuditedOTD = ({ otdId, dispatch, cleanModal }) => {
	const [isFetching, setFetching] = useState(false);
	const { deleteAuditedOTD } = useOrderTransactionDoc();
	const handleDeleteAuditedOTD = () => {
		setFetching(true);
		deleteAuditedOTD(otdId)
			.then(() => {
				dispatch(
					sendToast({
						message: 'Reporte eliminado correctamente',
						type: 'success',
					}),
				);
				dispatch(removeDatasourceItem(otdId));
				cleanModal();
			})
			.catch(({ message, type }) => {
				setFetching(false);
				dispatch(
					sendToast({
						message:
							message ||
							'Tuve un problema al subir el archivo. Inténtalo más tarde',
						type: type || 'warn',
					}),
				);
			});
	};

	return (
		<Wrapper>
			{isFetching ? (
				<LogoIcon spin />
			) : (
				<>
					<AntButton onClick={handleDeleteAuditedOTD} type='link'>
						<div className={styles.deleteButton}>
							<Span warning>Eliminar</Span>
						</div>
					</AntButton>
					<AntButton onClick={cleanModal} type='link'>
						<div className={styles.buttonModal}>
							<Span>Cancelar</Span>
						</div>
					</AntButton>
				</>
			)}
		</Wrapper>
	);
};
const OrderDeleteAuditedOTD = () => {
	const dispatch = useDispatch();
	const { payload: modalPayload, cleanModal } = useContext(ModalBuilderContext);

	return (
		<Modal
			title={null}
			closable={false}
			centered={false}
			visible={true}
			onCancel={cleanModal}
			footer={null}
		>
			<Wrapper
				highBackground
				width='100%'
				margin='0'
				flexDirection='column'
				alignItems='center'
				justifyContent='flex-start'
			>
				<Title>¿Está seguro que desea eliminar el Reporte?</Title>
				<Subtitle margin='16px 0 0 0'>
					ID: {modalPayload.selectedOTDId}
				</Subtitle>
				<DeleteAuditedOTD
					otdId={modalPayload.selectedOTDId}
					dispatch={dispatch}
					cleanModal={cleanModal}
				/>
			</Wrapper>
		</Modal>
	);
};

export default OrderDeleteAuditedOTD;
