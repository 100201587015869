// Libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// Actions
import {
	mutate1Object as mutate1ObjectInDashboard,
	resetEditProfileDrawer,
	updateUserProfile,
	changeUsersAssignedEntity,
} from 'screens/Dashboard/actions';
import { getSignupDataWorker, doReset } from 'screens/Signup/actions';
import { getMyProfile } from 'screens/Login/actions';
// Utils
import { getCompanyIdAndOrgIdFromEntityId, someFieldsChanged } from './utils';
import UploadResourceUtils from 'components/UploadResource/UploadResourceUtils';
import {
	USER_FIELDS,
	userFieldsInitialState,
	EMPLOYER_FIELDS,
	employerFieldsInitialState,
	SETTINGS_FIELDS,
	settingsFieldsInitialState,
} from './form';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
import { selectEditProfileDrawer } from 'screens/Dashboard/selectors';
import {
	selectCompanies,
	selectOrganizations,
	selectEntities,
} from 'screens/Signup/selectors';
import { Select, Button as _Button, Cascader as _Cascader, Tag } from 'antd';
// Components
import {
	Drawer,
	Wrapper,
	Card,
	IconButton,
	Collapse as _Collapse,
	DeleteIcon,
} from 'components';
import { UploadResource } from 'components/UploadResource';
import primitives from './primitives';
import HeaderEditProfileDrawer from './Header';

const { Option } = Select;

const { Collapse, Panel } = _Collapse;

const { FieldName, Input, SectionDescription, LineDescription } = primitives;

const EmployerSection = ({
	profile,
	changeUsersAssignedEntity,
	companies,
	organizations,
	entities,
}) => {
	const [companyIdFromProfile, organizationIdFromProfile] =
		getCompanyIdAndOrgIdFromEntityId(
			profile.entity.id,
			companies,
			organizations,
			entities,
		);

	const [employerInfoValues, setEmployerInfoValues] = useState(
		employerFieldsInitialState,
	);

	const onChangeEmployerInfoValues = newEmployerInfo =>
		setEmployerInfoValues(prevState => ({
			...prevState,
			...newEmployerInfo,
		}));

	const onChangeCompany = newCompanyId => {
		const value =
			newCompanyId === companyIdFromProfile ? undefined : newCompanyId;
		const newObject = {
			...employerInfoValues[EMPLOYER_FIELDS.COMPANY_ID],
			value,
		};
		onChangeEmployerInfoValues({
			[EMPLOYER_FIELDS.COMPANY_ID]: newObject,
			[EMPLOYER_FIELDS.ORGANIZATION_ID]:
				employerFieldsInitialState[EMPLOYER_FIELDS.ORGANIZATION_ID],
			[EMPLOYER_FIELDS.ENTITY_ID]:
				employerFieldsInitialState[EMPLOYER_FIELDS.ENTITY_ID],
		});
	};

	const onChangeOrganization = newOrganizationId => {
		const value =
			newOrganizationId === organizationIdFromProfile
				? undefined
				: newOrganizationId;
		const newObject = {
			...employerInfoValues[EMPLOYER_FIELDS.ORGANIZATION_ID],
			value,
		};
		onChangeEmployerInfoValues({
			[EMPLOYER_FIELDS.ORGANIZATION_ID]: newObject,
			[EMPLOYER_FIELDS.ENTITY_ID]:
				employerFieldsInitialState[EMPLOYER_FIELDS.ENTITY_ID],
		});
	};

	const onChangeEntity = newEntityId => {
		const value = newEntityId === profile.entity.id ? undefined : newEntityId;
		const newObject = {
			...employerInfoValues[EMPLOYER_FIELDS.ENTITY_ID],
			value,
		};
		onChangeEmployerInfoValues({ [EMPLOYER_FIELDS.ENTITY_ID]: newObject });
		if (value)
			changeUsersAssignedEntity({
				entityId: value,
				userIds: [profile.user.id],
			});
	};

	const filteredOrganizations = organizations.filter(
		org =>
			org.company_id ===
			(employerInfoValues[EMPLOYER_FIELDS.COMPANY_ID].value ??
				companyIdFromProfile),
	);

	const filteredEntities = entities.filter(
		entity =>
			entity.organization_id ===
			(employerInfoValues[EMPLOYER_FIELDS.ORGANIZATION_ID].value ??
				organizationIdFromProfile),
	);

	return (
		<Collapse bordered={false} defaultActiveKey={['-1']} accordion>
			<Panel
				transparentBackground
				header={
					<Tag
						style={{
							width: '100%',
							fontSize: '16px',
							textAlign: 'center',
							color: 'rgb(255,255,255)',
							borderColor: 'rgba(255,255,255, 0.6)',
							backgroundColor: 'transparent',
						}}
					>
						Empleador
					</Tag>
				}
			>
				<div style={{ padding: '0 15px 0 15px' }}>
					<FieldName required>Empresa</FieldName>
					<Select
						style={{ width: '100%', marginBottom: '10px' }}
						placeholder={profile.company.name}
						value={employerInfoValues[EMPLOYER_FIELDS.COMPANY_ID].value}
						onChange={onChangeCompany}
					>
						{companies.map((comp, idx) => (
							<Option key={idx} value={comp.id}>
								{comp.name}
							</Option>
						))}
					</Select>

					<FieldName required>Organización</FieldName>
					<Select
						style={{ width: '100%', marginBottom: '10px' }}
						placeholder={
							employerInfoValues[EMPLOYER_FIELDS.COMPANY_ID].value
								? 'Seleccione una organización'
								: profile.organization.name
						}
						value={employerInfoValues[EMPLOYER_FIELDS.ORGANIZATION_ID].value}
						onChange={onChangeOrganization}
						// disabled={!employerInfoToUpdate.company_id}
					>
						{filteredOrganizations.map((org, idx) => (
							<Option key={idx} value={org.id}>
								{org.name}
							</Option>
						))}
					</Select>
					{/* ENTITY */}
					<FieldName required>Entidad</FieldName>
					<Select
						style={{ width: '100%', marginBottom: '10px' }}
						placeholder={
							employerInfoValues[EMPLOYER_FIELDS.COMPANY_ID].value ||
							employerInfoValues[EMPLOYER_FIELDS.ORGANIZATION_ID].value
								? 'Seleccione una entidad'
								: profile.entity.name
						}
						value={employerInfoValues[EMPLOYER_FIELDS.ENTITY_ID].value}
						onChange={onChangeEntity}
						disabled={
							employerInfoValues[EMPLOYER_FIELDS.COMPANY_ID].value &&
							!employerInfoValues[EMPLOYER_FIELDS.ORGANIZATION_ID].value
						}
					>
						{filteredEntities.map((ent, idx) => (
							<Option key={idx} value={ent.id}>
								{ent.name}
							</Option>
						))}
					</Select>
				</div>
			</Panel>
		</Collapse>
	);
};

const UpdateProfileForm = ({ profile, userInfoValues, setUserInfoValues }) => {
	const onChangeUserInfo = newValues =>
		setUserInfoValues(prevState => ({ ...prevState, ...newValues }));

	const onChangeName = name => {
		const value = name === '' || name === profile.user.name ? undefined : name;
		const newObject = { ...userInfoValues[USER_FIELDS.NAME], value };
		onChangeUserInfo({ [USER_FIELDS.NAME]: newObject });
	};

	const onChangeEmail = (email, index) => {
		let idx = profile.user.profile.emails.findIndex(item => item === email);
		idx =
			idx === -1
				? userInfoValues[USER_FIELDS.EMAILS].values.findIndex(
						item => item !== undefined && item === email,
				  )
				: idx;
		const value = idx !== -1 || email === '' ? undefined : email;
		let values = userInfoValues[USER_FIELDS.EMAILS].values;

		if (values[0] === undefined && values[1] === undefined)
			values = [...profile.user.profile.emails];
		values[index] = value;

		if (
			value === undefined &&
			profile.user.profile.emails.includes(values[(index + 1) % 2])
		)
			values = [undefined, undefined];

		const newObject = { ...userInfoValues[USER_FIELDS.EMAILS], values };
		onChangeUserInfo({ [USER_FIELDS.EMAILS]: newObject });
	};

	const onRemoveEmail = index => {
		const values = [...userInfoValues[USER_FIELDS.EMAILS].values];
		values[index] = '';
		const otherIndex = (index + 1) % 2;

		if (values[otherIndex] === undefined)
			values[otherIndex] = profile.user.profile.emails[otherIndex];

		const newObject = { ...userInfoValues[USER_FIELDS.EMAILS], values };
		onChangeUserInfo({ [USER_FIELDS.EMAILS]: newObject });
	};

	const onChangePhone = (phone, index) => {
		let idx = profile.user.profile.phones.findIndex(item => item === phone);
		idx =
			idx === -1
				? userInfoValues[USER_FIELDS.PHONES].values.findIndex(
						item => item !== undefined && item === phone,
				  )
				: idx;
		const value = idx !== -1 || phone === '' ? undefined : phone;
		let values = userInfoValues[USER_FIELDS.PHONES].values;

		if (values[0] === undefined && values[1] === undefined)
			values = [...profile.user.profile.phones];
		values[index] = value;

		if (
			value === undefined &&
			profile.user.profile.phones.includes(values[(index + 1) % 2])
		)
			values = [undefined, undefined];

		const newObject = { ...userInfoValues[USER_FIELDS.PHONES], values };
		onChangeUserInfo({ [USER_FIELDS.PHONES]: newObject });
	};

	const onRemovePhone = index => {
		const values = [...userInfoValues[USER_FIELDS.PHONES].values];
		values[index] = '';
		const otherIndex = (index + 1) % 2;

		if (values[otherIndex] === undefined)
			values[otherIndex] = profile.user.profile.phones[otherIndex];

		const newObject = { ...userInfoValues[USER_FIELDS.PHONES], values };
		onChangeUserInfo({ [USER_FIELDS.PHONES]: newObject });
	};

	return (
		<Wrapper flexDirection='column' overflow='auto' alignItems='flex-start'>
			<FieldName required={!userInfoValues[USER_FIELDS.NAME].nullable}>
				Nombre
			</FieldName>
			<Input
				placeholder={profile.user.name}
				value={userInfoValues[USER_FIELDS.NAME].value}
				onChange={e => onChangeName(e.target.value)}
			/>
			{/* EMAILS */}
			<FieldName required>Correo electrónico 1</FieldName>
			<Input
				placeholder={profile.user.profile.emails[0]}
				value={userInfoValues[USER_FIELDS.EMAILS].values[0]}
				onChange={e => onChangeEmail(e.target.value, 0)}
			/>
			{/* SECOND EMAIL */}
			<FieldName required={!userInfoValues[USER_FIELDS.EMAILS].nullable}>
				Correo electrónico 2
			</FieldName>
			<Input
				addonAfter={
					profile.user.profile.emails[1] && (
						<DeleteIcon onClick={() => onRemoveEmail(1)} />
					)
				}
				placeholder={
					!profile.user.profile.emails[1] ||
					(profile.user.profile.emails[1] &&
						userInfoValues[USER_FIELDS.EMAILS].values[1] === '')
						? 'Escriba para agregar un segundo correo'
						: profile.user.profile.emails[1]
				}
				value={userInfoValues[USER_FIELDS.EMAILS].values[1]}
				onChange={e => onChangeEmail(e.target.value, 1)}
			/>

			{/* PHONES */}
			<FieldName required>Teléfono 1</FieldName>
			<Input
				placeholder={profile.user.profile.phones[0]}
				value={userInfoValues[USER_FIELDS.PHONES].values[0]}
				onChange={e => onChangePhone(e.target.value, 0)}
			/>
			{/* SECOND PHONE */}
			<FieldName required={!userInfoValues[USER_FIELDS.PHONES].nullable}>
				Teléfono 2
			</FieldName>
			<Input
				addonAfter={
					profile.user.profile.phones[1] && (
						<IconButton
							size='s'
							type='delete'
							style={{
								height: 15,
							}}
							onClick={() => onRemovePhone(1)}
						/>
					)
				}
				placeholder={
					!profile.user.profile.phones[1] ||
					(profile.user.profile.phones[1] &&
						userInfoValues[USER_FIELDS.PHONES].values[1] === '')
						? 'Escriba para agregar un segundo número'
						: profile.user.profile.phones[1]
				}
				value={userInfoValues[USER_FIELDS.PHONES].values[1]}
				onChange={e => onChangePhone(e.target.value, 1)}
			/>
		</Wrapper>
	);
};

function ProfileSection({
	editProfileDrawer,
	changeUsersAssignedEntity,
	updateUserProfile,
	profile,
	companies,
	organizations,
	entities,
	userInfoValues,
	setUserInfoValues,
}) {
	const titleStyle = {
		textAlign: 'center',
		width: '100%',
		fontSize: '18px',
	};

	const styleProps = {
		conceptStyle: {
			fontSize: '18px',
			color: '#BEBEBE',
		},
		valueStyle: {
			fontSize: '18px',
		},
	};

	const dateFormats = [
		'DD/MM/YYYY HH:mm',
		'MM/DD/YYYY HH:mm',
		'YYYY/MM/DD HH:mm',
	];

	const [settingsValues, setSettingsValues] = useState(
		settingsFieldsInitialState,
	);

	const onChangeSettingsValues = newSettings =>
		setSettingsValues(prevState => ({ ...prevState, ...newSettings }));

	const onChangeDateFormat = dateFormat => {
		const value =
			dateFormat === profile.user.settings.date_format ? undefined : dateFormat;
		const newObject = { ...settingsValues[SETTINGS_FIELDS.DATE_FORMAT], value };
		onChangeSettingsValues({ [SETTINGS_FIELDS.DATE_FORMAT]: newObject });
		if (value)
			updateUserProfile({
				dateFormat: value,
			});
	};

	// todo: enable once the local storage function is being used
	// const onChangeDarkTheme = (isChecked) => {
	//   const value = isChecked === profile.user.settings?.darkTheme ? undefined : isChecked;
	//   const newObject = { ...settingsToUpdate.darkTheme, value};
	//   onChangeSettingsToUpdate({ darkTheme: newObject });
	// };

	const profilePhotoProps =
		UploadResourceUtils.getProfilePhotoResourceFileProps(profile.user.id);

	return (
		<>
			<div
				style={{
					padding: 20,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					backgroundColor: 'rgba(0,0,0,0.5)',
				}}
			>
				<UploadResource
					config={{
						compress: {
							name: 'compress',
							side: 'frontend',
							format: 'image/jpeg',
							maxDim: 500,
							quality: 0.8,
						},
					}}
					fileProps={profilePhotoProps}
					options={{
						profilePhoto: true,
					}}
				/>
			</div>
			<Card
				style={{ width: '100%', marginBottom: '3px' }}
				title={
					!editProfileDrawer.isEditing ? (
						<LineDescription
							{...styleProps}
							padding='0'
							concept='Nombre'
							value={profile.user.name}
						/>
					) : (
						<div style={titleStyle}>Actualice los campos</div>
					)
				}
				description={
					<SectionDescription>
						{!editProfileDrawer.isEditing ? (
							<>
								<LineDescription
									{...styleProps}
									concept='ID'
									value={profile?.user?.id}
								/>
								<LineDescription
									{...styleProps}
									concept='Correo electrónico'
									value={profile?.user?.profile?.emails.join(', ')}
								/>
								<LineDescription
									{...styleProps}
									concept='Teléfonos'
									value={profile?.user?.profile?.phones.join(', ')}
								/>
								<LineDescription
									{...styleProps}
									concept='Formato de fechas'
									value={profile?.user?.settings?.date_format}
								/>
							</>
						) : (
							<UpdateProfileForm
								profile={profile}
								userInfoValues={userInfoValues}
								setUserInfoValues={setUserInfoValues}
							/>
						)}
					</SectionDescription>
				}
			/>
			<Card
				style={{ width: '100%', marginBottom: '3px' }}
				title={
					editProfileDrawer.isEditing ? null : (
						<div style={titleStyle}>Preferencias</div>
					)
				}
				description={
					<SectionDescription>
						{editProfileDrawer.isEditing ? (
							<EmployerSection
								profile={profile}
								changeUsersAssignedEntity={changeUsersAssignedEntity}
								companies={companies}
								organizations={organizations}
								entities={entities}
							/>
						) : (
							<>
								<FieldName>Formato de Fechas</FieldName>
								<Select
									style={{ width: '100%', marginBottom: '10px' }}
									placeholder={profile.user.settings.date_format}
									value={settingsValues[SETTINGS_FIELDS.DATE_FORMAT].value}
									onChange={onChangeDateFormat}
								>
									{dateFormats.map((dateFormat, idx) => (
										<Option key={idx} value={dateFormat}>
											{dateFormat}
										</Option>
									))}
								</Select>
								{/* todo: (ed) implement this properly */}
								{/* <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <FieldName required={!settingsToUpdate.darkTheme.nullable}>Tema Oscuro</FieldName>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={settingsToUpdate.darkTheme.value}
                    onChange={onChangeDarkTheme}
                  />
                </div> */}
							</>
						)}
					</SectionDescription>
				}
			/>
		</>
	);
}

const EditProfileDrawer = ({
	// state
	editProfileDrawer,
	profile,
	companies,
	organizations,
	entities,
	// actions
	mutate1ObjectInDashboard,
	updateUserProfile,
	changeUsersAssignedEntity,
	resetEditProfileDrawer,
	getMyProfile,
	getSignupDataWorker,
	doReset,
}) => {
	const { isOpen } = editProfileDrawer;

	const [userInfoHasChanged, setUserInfoHasChanged] = useState(false);
	const [userInfoValues, setUserInfoValues] = useState(userFieldsInitialState);

	useEffect(() => {
		if (!editProfileDrawer.isEditing) setUserInfoValues(userFieldsInitialState);
	}, [editProfileDrawer.isEditing]);

	useEffect(() => {
		const infoHasChanged = someFieldsChanged(userInfoValues);
		setUserInfoHasChanged(infoHasChanged);
	}, [userInfoValues]);

	useEffect(() => {
		if (isOpen) getSignupDataWorker();
		else doReset();
	}, [isOpen]);

	return !profile ? null : (
		<Drawer
			$noOverflow
			height='100%'
			width='40vw'
			header_font_weight='bold'
			backgroundColor='rgba(0,0,0, 0.2)'
			headerBackgroundColor='rgba(0,0,0, 0.2)'
			backdropFilter='blur(8px)'
			title={
				<HeaderEditProfileDrawer
					profile={profile}
					editProfileDrawer={editProfileDrawer}
					someFieldsChanged={userInfoHasChanged}
					userInfoValues={userInfoValues}
					getMyProfile={getMyProfile}
					mutate1Object={mutate1ObjectInDashboard}
					updateUserProfile={updateUserProfile}
					onClose={resetEditProfileDrawer}
				/>
			}
			placement='left'
			onClose={resetEditProfileDrawer}
			closable={false}
			visible={isOpen}
			key={'top'}
		>
			<Wrapper padding='0' justifyContent='center'>
				<Wrapper
					padding='0'
					flexDirection='column'
					overflow='scroll'
					width='100%'
					height={`${document.documentElement.clientHeight - 60}px`}
				>
					<ProfileSection
						editProfileDrawer={editProfileDrawer}
						changeUsersAssignedEntity={changeUsersAssignedEntity}
						updateUserProfile={updateUserProfile}
						profile={profile}
						companies={companies}
						organizations={organizations}
						entities={entities}
						userInfoValues={userInfoValues}
						setUserInfoValues={setUserInfoValues}
					/>
				</Wrapper>
			</Wrapper>
		</Drawer>
	);
};

const mapStateToProps = state => ({
	editProfileDrawer: selectEditProfileDrawer(state),
	profile: selectProfile(state),
	companies: selectCompanies(state),
	organizations: selectOrganizations(state),
	entities: selectEntities(state),
});

const actions = {
	mutate1ObjectInDashboard,
	resetEditProfileDrawer,
	updateUserProfile,
	changeUsersAssignedEntity,
	getMyProfile,
	getSignupDataWorker,
	doReset,
};

export default connect(mapStateToProps, actions)(EditProfileDrawer);
