// Libs
import styled from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Divider } from 'antd';

const DividerStyled = styled(Divider)`
	margin: ${({ margin }) => margin};
	min-width: ${({ width }) => width};
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	&.ant-divider-inner-text {
		color: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
	}
	.ant-divider-inner-text {
		padding: 0;
	}
	&.ant-divider {
		background: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
	}
	&.ant-divider-horizontal.ant-divider-with-text {
		color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
	}
`;

export default props => {
	const { theme } = useTheme();
	return <DividerStyled {...props} $newTheme={theme} />;
};
