// Libs
import mqtt from 'mqtt';
import { v4 as uuidv4 } from 'uuid';
// Utils
import { replaceAll } from 'utils/libs';

// Events
export const EVENTS = {
	CONNECT: 'connect',
	RECONNECT: 'reconnect',
	ERROR: 'error',
	MESSAGE: 'message',
};
// Status of connection
export const STATUS = {
	CONNECTING: 'connecting',
	RECONNECTING: 'reconnecting',
	CONNECTED: 'connected',
	DISCONNECTED: 'disconnected',
};
// Topics
export const TOPICS = {
	BASE: process.env.REACT_APP_MQTT_TOPIC,
	ACTIONS: {
		COMMAND_CENTER: {
			UDPATED: {
				ORDER: {
					COORDS: 'command_center/updated/order/coords',
					EXPERT_ASSIGNMENT: 'command_center/updated/order/expertAssignment',
				},
				USER: {
					COORDS: 'comman_center/updated/user/coords',
				},
			},
		},
		REPORTS: {
			GENERATED: {
				PDF: 'reports/generated/pdf',
				XLSX: 'reports/generated/xlsx',
			},
			MERGED: {
				PDF: 'reports/merged/pdf',
				XLSX: 'reports/merged/xlsx',
			},
			COMPRESSED: {
				PDF: 'reports/compressed/pdf',
				XLSX: 'reports/compressed/xlsx',
			},
			SAVED: {
				PDF: 'reports/saved/pdf',
				XLSX: 'reports/saved/xlsx',
			},
			SUMMARY: {
				PDF: 'reports/summary/pdf',
				XLSX: 'reports/summary/xlsx',
			},
		},
		COLLECTOR_ECOSYSTEM: {
			DUPLICATED_COLLECTOR_LAYOUT: {
				SYNC: 'collectorEcosystem/duplicatedCollectorLayout/sync',
			},
		},
	},
	DISPATCHABLE_TYPES: {
		COLLECTOR_VALUES: {
			CONTRACT: 'collector_value/contract',
			OTD: 'collector_value/otd',
			WTD: 'collector_value/wtd',
			UTD: 'collector_value/utd',
		},
		WAREHOUSE_TRANSACTION_DOC: 'warehouse_transaction_doc',
	},
};

// Config
const CONFIG = {
	host: `${process.env.REACT_APP_MQTT_PROTOCOL}://${
		process.env.REACT_APP_MQTT_HOST
	}:${Number(process.env.REACT_APP_MQTT_PORT)}`,
	options: {
		clientId: `mqttSynapseADM${uuidv4()}`,
		username: process.env.REACT_APP_MQTT_USERNAME,
		password: process.env.REACT_APP_MQTT_PASSWORD,
		reconnectPeriod: 60000,
	},
};

export default class MQTTService {
	static client;
	static connect() {
		if (!this.client) this.client = mqtt.connect(CONFIG.host, CONFIG.options);
		return this.client;
	}

	static setTopic(topic, { companyId, organizationId, entityId, userId } = {}) {
		if (!topic || !companyId || !organizationId || !entityId || !userId) return;
		return `${TOPICS.BASE}/${companyId}/${organizationId}/${entityId}/${userId}/${topic}`;
	}

	static setPublisherPayload(payload) {
		return JSON.stringify(payload);
	}

	// Get receive message
	static getMessage(message) {
		try {
			const _message = JSON.parse(message.toString());
			return _message;
		} catch (err) {}
	}

	// Validate subscriber
	static validateSubscriber({
		companyId,
		organizationId,
		entityId,
		userId,
	} = {}) {
		return companyId && organizationId && entityId && userId;
	}

	// Validate payload
	static validatePayload(payload) {
		return typeof payload === 'object' && Object.keys(payload).length > 0;
	}

	// Decode topic in (action & subscriber)
	static getDecodedTopic(topic) {
		const levels = topic.split('/');
		const action = replaceAll(
			levels.filter((level, idx) => idx >= 6).join(),
			',',
			'/',
		);
		const [companyId, organizationId, entityId, userId] = levels.filter(
			(level, idx) => idx > 1 && idx < 6,
		);
		return {
			action,
			subscriber: {
				companyId,
				organizationId,
				entityId,
				userId,
			},
		};
	}
}
