// Libs
import React, { useMemo } from 'react';
// Components
import { Wrapper, Span, Tooltip, CloseIcon, CheckCircleIcon } from 'components';

function PhotoReportToolbar({
	selectedGroupId,
	selectedSubgroupId,
	reviewing,
	onSetState,
	groups = [],
	subgroups = [],
	onClose,
}) {
	const selectedSubgroupName = useMemo(
		() =>
			subgroups.length
				? subgroups.find(subgroup => subgroup.id === selectedSubgroupId)?.name
				: groups.find?.(group => group.id === selectedGroupId)?.name ?? '',
		[selectedSubgroupId, selectedGroupId, subgroups, groups],
	);

	return (
		<Wrapper
			mediumBackground
			padding='10px'
			height='56px'
			width='100%'
			justifyContent='space-between'
		>
			<Tooltip title={selectedSubgroupName}>
				<Span ellipsis>{selectedSubgroupName}</Span>
			</Tooltip>
			<div>
				<CheckCircleIcon
					active={reviewing}
					button
					onClick={() => onSetState({ reviewing: !reviewing })}
				/>
				<CloseIcon button onClick={onClose} />
			</div>
		</Wrapper>
	);
}

export default PhotoReportToolbar;
