// Libs
import React from 'react';
// Components
import { Collapse as _Collapse, Wrapper, Span } from 'components';

const { Collapse, Panel } = _Collapse;

export default function ControlSeriesMobile(props) {
	return (
		<Wrapper padding='0px'>
			<Collapse accordion key={0} bordered={false} defaultActiveKey={['-1']}>
				<Panel
					header={
						<Span fontSize='l' color='rgba(255, 255, 255, 0.9)'>
							En construcción
						</Span>
					}
				></Panel>
			</Collapse>
		</Wrapper>
	);
}
