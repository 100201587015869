// Libs
import React, { useCallback, useState } from 'react';
// Utils
import { debounce } from 'utils/libs';
import OrderAuthorization from 'modules/Orders/Authorization';
// Hooks
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { setOrderProps } from 'screens/Orders/actions';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Components
import { Switch } from 'antd';
import { Wrapper, Icon, Span } from 'components';
import Read from '../Read';
import Updating from '../Updating';
// Styles
import styles from './Warranty.module.css';

const Warranty = ({ rowData, field }) => {
	const dispatch = useDispatch();
	const profile = useSelector(selectProfile);

	const [state, _setState] = useState({
		warrantyStatus: field.value,
		isLoading: false,
	});

	const setState = newState =>
		_setState(currentState => ({ ...currentState, ...newState }));

	const debounced = useCallback(
		debounce(callback => callback(), 300),
		[],
	);

	const handleWarrantyChange = value =>
		debounced(() => {
			setState({ isLoading: true });
			dispatch(
				setOrderProps(
					rowData.id,
					'setWarranty',
					{
						warranty: value,
					},
					{
						onSuccess: function* (warrantyStatus) {
							setState({
								isLoading: false,
								warrantyStatus,
							});
						},

						onError: function* () {
							setState({
								isLoading: false,
							});
						},
					},
				),
			);
		});

	if (state.isLoading) return <Updating className={styles.container} />;

	const isAuthorized = new OrderAuthorization(profile).checkEditOrderWarranty();

	return (
		<Wrapper padding='0' width='100%' margin='0'>
			{isAuthorized && rowData.isBilled ? (
				<Wrapper padding='0' width='100%' margin='0'>
					<Switch
						size='small'
						checked={state.warrantyStatus}
						checkedChildren={<Icon type='check' />}
						unCheckedChildren={<Icon type='close' />}
						onChange={handleWarrantyChange}
					/>
				</Wrapper>
			) : (
				<Read className={styles.container} field={field} rowData={rowData} />
			)}
		</Wrapper>
	);
};

export default Warranty;
