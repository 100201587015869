// Libs
import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
// Utils
import GENERAL from '../../../../../utils/constants/general';
// Styles
import './StockArticleItemCard.css';

const { ENV } = GENERAL;
const { Meta } = Card;

export default function StockArticleItemCard(props) {
	const {
		id,
		item_name,
		item_code,
		amount,
		amount_transit,
		is_serialized,
		allowed_to_use,
		allowed_to_use_label,
		state_name,
		state_id,
		warehouse_transaction_doc_id,
		created_at,
		updated_at,
		customProps,
	} = props;
	const { control, stock, mutate1ObjectApi, getStockSeries } = customProps;

	return (
		<Meta
			id='stockArticleItemCard-meta'
			title={
				<div className='stock_article-container-title-card'>
					{is_serialized ? (
						<Link
							className='stock_article-card-creator-clickeable'
							to={ENV.ROUTES.PATHS.WAREHOUSES_STOCK_SERIES_MOBILE}
							onClick={() => {
								mutate1ObjectApi('stock', {
									...stock,
									itemId: id,
									stateId: state_id,
								});
								getStockSeries(
									stock.warehouseId,
									stock.ownerId,
									control.mode,
									id,
									state_id,
								);
							}}
						>
							<span>{item_name}</span>
						</Link>
					) : (
						<span className='stock_article-card-creator'>{item_name}</span>
					)}

					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '10px' }}>
							<span className='stock_article-body-concept-item'>Creado:</span>
							<span className='stock_article-card-created_at'>
								{created_at}
							</span>
						</div>
						<div>
							<span className='stock_article-body-concept-item'>
								Actualizado:
							</span>
							<span className='stock_article-card-created_at'>
								{updated_at}
							</span>
						</div>
					</div>
				</div>
			}
			description={
				<div className='stock_article-container-data-input'>
					<div className='stock_article-data'>
						<div className='stock_article-body-container-item'>
							<div className='stock_article-body-concept-item'>
								Código: <b>{item_code}</b>
							</div>
						</div>
						<div className='stock_article-body-container-item'>
							<div className='stock_article-body-concept-item'>
								Cantidad: <b>{amount}</b>
							</div>
						</div>
						{!is_serialized && (
							<div className='stock_article-body-container-item'>
								<div className='stock_article-body-concept-item'>
									Tránsito: <b>{amount_transit}</b>
								</div>
							</div>
						)}
					</div>

					<div className='stock_article-data'>
						{/* <div className='stock_article-body-container-item'>
							<div className='stock_article-body-concept-item'>
								Permitido: <b>{allowed_to_use_label}</b>
							</div>
						</div> */}
						<div className='stock_article-body-container-item'>
							<div className='stock_article-body-concept-item'>
								Documento: <b>{warehouse_transaction_doc_id}</b>
							</div>
						</div>
						{is_serialized && (
							<div className='stock_article-body-container-item'>
								<div className='stock_article-body-concept-item'>
									Estado: <b>{state_name}</b>
								</div>
							</div>
						)}
					</div>
				</div>
			}
		/>
	);
}
