// Libs
import React from 'react';
import styled, { useTheme } from 'styled-components';
// Components
import Down16 from './Down16';
import Up16 from './Up16';

const Icon = styled.div`
	height: 16px;
	width: 16px;
	display: flex;
	justify-content: center;
	text-align: center;

	margin: ${({ margin }) => margin};
`;

const ArrowIcon = ({ disabled, direction, margin }) => {
	const theme = useTheme();
	const fill = React.useMemo(
		() => (disabled ? theme.colors.text.disabled : theme.colors.text.high),
		[disabled],
	);
	return (
		<Icon margin={margin}>
			{direction === 'down' && <Down16 fill={fill} />}
			{direction === 'up' && <Up16 fill={fill} />}
		</Icon>
	);
};

export default ArrowIcon;
