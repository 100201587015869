// Libs
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Utils
import GENERAL from 'utils/constants/general';
import { GlobalUtils } from 'utils';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
import { mutate1Object as mutate1ObjectInCore } from 'core/actions';
// Selectors
import { selectDatasourceData } from 'core/selectors';
// Hooks
import useOrderApi from './useOrderApi';

const { UNSUCCESS } = GENERAL.ENV.STATUS;

const useOrderZoneAssignment = () => {
	const dispatch = useDispatch();
	const { getZoneAssignment, setZoneAssignment } = useOrderApi();
	const datasource = useSelector(selectDatasourceData);

	const [state, setState] = useState({
		isLoading: false,
		isSetting: false,
		data: [],
		status: undefined,
		message: undefined,
	});

	const onRetry = () =>
		setState(prev => ({ ...prev, status: undefined, message: undefined }));

	const onGetZoneAssignment = ({ serviceId, searchValue }) => {
		setState(prev => ({
			...prev,
			isLoading: true,
		}));
		getZoneAssignment(
			[
				{
					serviceId,
				},
			],
			{
				serviceId,
				searchValue,
			},
		)
			.then(res =>
				setState(prev => ({
					...prev,
					isLoading: false,
					data: GlobalUtils.checkArray(res[0]?.zoneAssignment),
				})),
			)
			.catch(err =>
				dispatch(sendToast({ message: err.message, type: err.type })),
			);
	};

	const onSetZoneAssignment = async ({ field, rowData, option }, cb) => {
		setState(prev => ({
			...prev,
			isSetting: true,
		}));
		if (cb) cb();
		setTimeout(() => {
			setZoneAssignment(
				[
					{
						id: rowData.id,
						serviceId: rowData.serviceId,
					},
				],
				{
					serviceId: rowData.serviceId,
				},
			)
				.then(processedOrders => {
					const datasourceIdx = datasource.findIndex(
						order => order.id === rowData.id,
					);
					const processedOrderIdx = processedOrders.findIndex(
						order => order.id === rowData.id,
					);
					if (datasourceIdx !== -1 && processedOrderIdx !== -1) {
						if (processedOrders[processedOrderIdx].status === UNSUCCESS) {
							setState(prev => ({
								...prev,
								isSetting: false,
								status: processedOrders[processedOrderIdx].status,
								message: processedOrders[processedOrderIdx].message,
							}));
							return;
						}
						datasource[datasourceIdx][field.id] = option.id;
						datasource[datasourceIdx][field.name] = option.name;
						dispatch(mutate1ObjectInCore('datasource', { data: datasource }));
						setTimeout(() => {
							setState(prev => ({
								...prev,
								isSetting: false,
							}));
						}, 200);
					}
				})
				.catch(err => {
					dispatch(sendToast({ message: err.message, type: err.type }));
					setState(prev => ({
						...prev,
						isSetting: false,
						status: UNSUCCESS,
						message: err.message || '',
					}));
				});
		}, 200);
	};

	return { state, onGetZoneAssignment, onSetZoneAssignment, onRetry };
};

export default useOrderZoneAssignment;
