// Libs
import React from 'react';
import _ from 'underscore';
import { Switch } from 'antd';
// Utils
import { Modal } from 'screens/Utils';
import { getMyEntityUsers, getActiveUsers } from 'utils/libs';
import { filterUsers } from 'utils/filters/Users/users';
// Components
import {
	Wrapper,
	Icon,
	TextField,
	TableVirtualized,
	Flag,
	Span,
} from 'components';
import Select from 'components/Select';
// Styles
import '../css/Control.css';

const { Table, Column, Header, Row, Cell } = TableVirtualized;

const { Option } = Select;
const { Input } = TextField;

function UserControlDesktop(props) {
	const {
		users,
		mutate1Object,
		userProfileModal,
		control,
		profile,
		changeWorkCode,
		changeActivation,
		setRelation,
		mutate1ObjectApi,
		permissions,
		setUserConfigurationModal,
		setPermissions,
		roles,
	} = props;
	const allUsers = users.data;
	let filteredUsers = allUsers;
	filteredUsers = getMyEntityUsers(filteredUsers, profile);

	if (control.filterView === 'active')
		filteredUsers = getActiveUsers(filteredUsers);
	else if (control.filterView === 'inactive')
		filteredUsers = filteredUsers.filter(
			user => user.application_status === 'approved' && !user.active,
		);
	else if (control.filterView === 'applicants')
		filteredUsers = filteredUsers.filter(
			user => user.application_status === 'pending',
		);
	else if (control.filterView === 'rejected')
		filteredUsers = filteredUsers.filter(
			user => user.application_status === 'reject',
		);
	// filteredUsers = filterUsersSearchInData(
	//   filteredUsers,
	//   control.searchInData.searchBy,
	//   control.searchInData.data
	// );
	filteredUsers = filterUsers(filteredUsers, control.filters);

	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: allUsers,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: control.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('control', {
							filters: updatedFilters,
						}),
				}}
			>
				{label}
			</Header>
		);
	}

	function getIndexCellRenderer({ rowData, rowIndex }) {
		let flagPriorityStyle = {};

		if (rowData.application_status === 'reject')
			flagPriorityStyle = { backgroundColor: 'red' };
		else if (rowData.application_status === 'pending')
			flagPriorityStyle = { backgroundColor: 'white' };
		// else if (rowData.application_status === 'approved' && rowData.active) flagPriorityStyle = { backgroundColor: 'yellow' };
		else if (!rowData.active) flagPriorityStyle = { backgroundColor: 'gray' };

		return (
			<Cell>
				<Flag style={flagPriorityStyle} height='20px' />
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	}

	function getWorkCodeCellRenderer({ rowData }) {
		const usersData = [...users.data];
		const idx = _.findIndex(usersData, user => user.id === rowData.id);

		function onMouseEnter() {
			usersData[idx].changeWorkCode = true;
			mutate1ObjectApi('users', { data: usersData });
		}

		function onMouseLeave() {
			usersData[idx].changeWorkCode = false;
			mutate1ObjectApi('users', { data: usersData });
		}

		return (
			<div
				className='desktop-warehouses-list-table-name-cell-container'
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{profile.entity.id !== profile.organization.settings.mainEntity ||
				rowData.application_status !== 'approved' ? (
					<Cell>
						<Span>{rowData.work_code}</Span>
					</Cell>
				) : !rowData.changeWorkCode && !rowData.workCodechanged ? (
					<Cell>
						<Span>{rowData.work_code}</Span>
					</Cell>
				) : (
					<Input
						id='input-table'
						name='work_code'
						placeholder={rowData.work_code}
						value={rowData.work_code}
						onChange={e => {
							let value = e.target.value;
							if (value && value !== '') value = value.trim();
							usersData[idx].workCodechanged = true;
							usersData[idx].work_code = value;
							mutate1ObjectApi('users', { data: usersData });
						}}
						suffix={
							rowData.updatingWorkCode ? (
								<Icon style={{ color: 'white' }} type='loading' />
							) : (
								<>
									<Icon
										style={{ color: 'white' }}
										type='check'
										onClick={() =>
											changeWorkCode(rowData.id, rowData.work_code)
										}
									/>
								</>
							)
						}
					/>
				)}
			</div>
		);
	}

	function getActiveCellRenderer({ rowData }) {
		return (
			<div className='desktop-warehouses-list-table-name-cell-container'>
				<Switch
					size='small'
					loading={rowData.updatingActivation}
					disabled={rowData.application_status !== 'approved'}
					checked={rowData.active}
					onChange={checked => changeActivation(rowData.id, checked)}
				/>
			</div>
		);
	}

	function getConnectedCellRenderer({ rowData }) {
		return (
			<div className='desktop-warehouses-list-table-name-cell-container item-center'>
				{rowData.connected ? (
					<Icon type='check' style={{ fontSize: '14px' }} />
				) : (
					<div></div>
				)}
			</div>
		);
	}

	function getRelationCellRenderer({ rowData }) {
		const usersData = [...users.data];
		const idx = _.findIndex(usersData, user => user.id === rowData.id);

		function onMouseEnter() {
			usersData[idx].changeRelation = true;
			mutate1ObjectApi('users', { data: usersData });
		}

		function onMouseLeave() {
			usersData[idx].changeRelation = false;
			mutate1ObjectApi('users', { data: usersData });
		}

		return (
			<div
				className='desktop-warehouses-list-table-name-cell-container'
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{profile.entity.id !== profile.organization.settings.mainEntity ? (
					<Cell>
						<Span>{rowData.application_status_label}</Span>
					</Cell>
				) : rowData.updatingRelation ? (
					<Icon style={{ fontSize: '17px' }} type='loading' />
				) : !rowData.changeRelation ? (
					<Cell>
						<Span>{rowData.application_status_label}</Span>
					</Cell>
				) : (
					<Select
						style={{ width: '100%' }}
						placeholder={`${rowData.application_status_label}`}
						onChange={value => setRelation(rowData.id, value)}
					>
						<Option value='pending'>
							<em>Pendiente</em>
						</Option>
						<Option value='approved'>
							<em>Aprobado</em>
						</Option>
						<Option value='reject'>
							<em>Rechazado</em>
						</Option>
					</Select>
				)}
			</div>
		);
	}

	function handleOnClickRow(userClicked, selectedUsers) {
		const idx = _.findIndex(selectedUsers, userId => userId === userClicked);
		if (idx === -1) selectedUsers.push(userClicked);
		if (selectedUsers.length === 1) {
			const idx = _.findIndex(users.data, user => user.id === selectedUsers[0]);
			if (idx !== -1)
				mutate1Object('setUserConfigurationModal', {
					userPermissions: users.data[idx].permissions,
				});
		} else mutate1Object('setUserConfigurationModal', { userPermissions: {} });
		mutate1Object('control', { selectedUsers });
	}

	function handleOnRightClickRow(e, userClicked, selectedUsers) {
		e.preventDefault();

		const idx = _.findIndex(selectedUsers, userId => userId === userClicked);
		if (idx !== -1)
			selectedUsers = selectedUsers.filter(userId => userId !== userClicked);
		else selectedUsers = [];
		if (selectedUsers.length === 1) {
			const idx = _.findIndex(users.data, user => user.id === selectedUsers[0]);
			if (idx !== -1)
				mutate1Object('setUserConfigurationModal', {
					userPermissions: users.data[idx].permissions,
				});
		} else mutate1Object('setUserConfigurationModal', { userPermissions: {} });
		mutate1Object('control', { selectedUsers });
	}

	function getRowRenderer({ key, columns, style, rowData }) {
		const selectedUsers = [...control.selectedUsers];
		let styleRow = { ...style };

		// Is warehouse selected?
		const idx = _.findIndex(selectedUsers, userId => userId === rowData.id);
		if (idx !== -1) {
			styleRow = {
				...styleRow,
				color: 'lightseagreen',
				backgroundColor: 'rgb(44,44,54)',
			};
		}

		return (
			<Row
				key={key}
				style={styleRow}
				onClick={() => handleOnClickRow(rowData.id, selectedUsers)}
				onContextMenu={e => handleOnRightClickRow(e, rowData.id, selectedUsers)}
			>
				{columns}
			</Row>
		);
	}

	//  Render
	return (
		<Wrapper padding='0' className='animated fadeIn'>
			<Table
				highBackground
				width={document.documentElement.clientWidth}
				height={document.documentElement.clientHeight - 115}
				headerHeight={44}
				rowHeight={40}
				rowCount={filteredUsers.length}
				rowGetter={({ index }) => filteredUsers[index]}
				rowRenderer={getRowRenderer}
			>
				<Column
					dataKey='index'
					label='#'
					width={50}
					headerRenderer={({ label }) => <Header>{label}</Header>}
					cellRenderer={getIndexCellRenderer}
				/>
				{/* <Column dataKey="id" label="Id" width={50} headerRenderer={props => getHeaderRenderer(props, "")} cellRenderer={getIdCellRenderer} /> */}
				<Column
					dataKey='idc'
					label='Identidad'
					width={100}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='name'
					label='Nombre'
					width={260}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='work_code'
					label='Código'
					width={150}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
					cellRenderer={getWorkCodeCellRenderer}
				/>
				<Column
					dataKey='division_name'
					label='Division'
					width={100}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='connected'
					label='Conectado'
					width={120}
					headerRenderer={({ label }) => <Header>{label}</Header>}
					cellRenderer={getConnectedCellRenderer}
				/>
				<Column
					dataKey='active'
					label='Activo'
					width={70}
					headerRenderer={({ label }) => <Header>{label}</Header>}
					cellRenderer={getActiveCellRenderer}
				/>
				<Column
					dataKey='active_days'
					label='Dias'
					width={60}
					headerRenderer={props => getHeaderRenderer(props, 'numeric')}
				/>
				<Column
					dataKey='application_status_label'
					label='Relacion'
					width={115}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
					cellRenderer={getRelationCellRenderer}
				/>
				<Column
					dataKey='last_logged_at'
					label='Ultima actividad'
					width={125}
					headerRenderer={props => getHeaderRenderer(props, 'date')}
				/>
				<Column
					dataKey='actived_at'
					label='Activacion'
					width={125}
					headerRenderer={props => getHeaderRenderer(props, 'date')}
				/>
				<Column
					dataKey='application_status_at'
					label='Fecha Relacion'
					width={125}
					headerRenderer={props => getHeaderRenderer(props, 'date')}
				/>
				<Column
					dataKey='created_at'
					label='Creado'
					width={125}
					headerRenderer={props => getHeaderRenderer(props, 'date')}
				/>
				<Column
					dataKey='updated_at'
					label='Actualizado'
					width={125}
					headerRenderer={props => getHeaderRenderer(props, 'date')}
				/>
			</Table>

			<Modal
				modalId='UserProfileModal'
				title='Perfil'
				visible={userProfileModal.isOpen}
				onCancel={() => mutate1Object('userProfileModal', { isOpen: false })}
				footer={null}
				isSpinning={userProfileModal.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					userProfileModal,
				}}
			/>

			<Modal
				modalId='SetUserConfigurationModal'
				title='Configuración'
				visible={setUserConfigurationModal.isOpen}
				onCancel={() =>
					mutate1Object('setUserConfigurationModal', { isOpen: false })
				}
				footer={null}
				isSpinning={setUserConfigurationModal.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					setUserConfigurationModal,
					roles,
					control,
					permissions,
					mutate1ObjectInUsers: mutate1Object,
					setPermissions,
				}}
			/>
		</Wrapper>
	);
}

export default UserControlDesktop;
