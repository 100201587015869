// Libs
import React from 'react';
// Components
import ilustration from './illustration.svg';

const BuildingBlocks = ({ width = '200px' }) => {
	return <img src={ilustration} style={{ width }} />;
};

export default BuildingBlocks;
