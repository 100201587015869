// Libs
import React from 'react';
import cx from 'classnames';
// Config
import ActionBuilderConfig from 'core/ActionBuilder/action.config';
import REPORT_KEYWORDS from 'screens/Reports/keywords';
// Components
import { ExcelIcon, Title } from 'components';
// Styles
import styles from './PostV1.module.css';

const { REPORT_TABLES } = REPORT_KEYWORDS;

const PostV1 = ({ theme, record }) => {
	const Action = ActionBuilderConfig.getAuthorizedActionComponentReactNode(
		ActionBuilderConfig.getAuthorizedActionKeys().generateReport,
	);

	const action = {
		id: 3,
		title: 'Generar Reporte',
		key: 'generateReport',
		formId: 3,
	};

	return (
		<div className={cx(styles.container, theme.secondaryBackgroundColor)}>
			<Title>{record.name}</Title>
			<Action
				key={action.id}
				action={action}
				icon={<ExcelIcon button />}
				payload={{
					reportId: record.id,
					reportTable: REPORT_TABLES.REPORT,
					reportParams: record.reportParams,
					reportProps: record.reportProps,
				}}
			/>
		</div>
	);
};

export default PostV1;
