// Libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';

// Actions
import {
	mutate1Object as mutate1ObjectInDashboard,
	resetDndOrdersDrawer,
} from 'screens/Dashboard/actions';
import {
	getControlOrders,
	getAllExpertsInfo,
	saveOrdersReassignmentToUserV2,
} from 'screens/Orders/actions';

// Selectors
import { selectDndOrdersDrawer } from 'screens/Dashboard/selectors';
import {
	selectControlOrdersByExpert,
	selectGetOrdersQueryModal,
	getOrderInformation,
	selectAllExpertsInfo,
} from 'screens/Orders/selectors';

// Components
import { Drawer, Wrapper, Collapse as _Collapse } from 'components';

import {
	Input as _Input,
	Button as _Button,
	Cascader as _Cascader,
} from 'antd';

import Header from './Header';
import Board from './Board';

// Hooks
import useClientRect from 'hooks/useClientRect';

const DndOrdersDrawer = ({
	// state
	dndOrdersDrawer,
	controlOrdersByExpert,
	getOrdersQueryModal,
	getOrderInformation,
	allExpertsInfo,
	// actions
	mutate1ObjectInDashboard,
	resetDndOrdersDrawer,
	getControlOrders,
	getAllExpertsInfo,
	saveOrdersReassignmentToUserV2,
}) => {
	const theme = useTheme();

	const [out, setOut] = useState(false);

	const [rect, ref] = useClientRect();

	if (Object.keys(controlOrdersByExpert).length === 0) {
		// TODO:Edgard: Creo que es por esto que estaba dando problema a veces el dnd, se debería
		// corregir si lo movemos a un useEffect ya que lo que hacen esas funciones es ejecutar
		// eventos asincronos
		if (!getOrdersQueryModal.loading) getControlOrders(getOrdersQueryModal);
		if (!allExpertsInfo.isLoading) getAllExpertsInfo();
	}

	useEffect(() => {
		if (out) setTimeout(() => setOut(false), 800);
	}, [out]);

	return (
		<Drawer
			className={out ? 'animated slideOutUp faster' : ''}
			height='100%'
			width={`${document.documentElement.clientWidth}px`}
			header_font_weight='bold'
			title={
				<Header
					dndOrdersDrawer={dndOrdersDrawer}
					mutate1Object={mutate1ObjectInDashboard}
					setOut={setOut}
					onClose={resetDndOrdersDrawer}
				/>
			}
			placement={'top'}
			closable={false}
			visible={dndOrdersDrawer.isOpen && !getOrderInformation.isOpen}
			key={'top'}
		>
			<Wrapper padding='0' justifyContent='center'>
				<Wrapper padding='0' width='100%' elementRef={ref}>
					<Wrapper
						padding='0'
						flexDirection='column'
						overflow='auto'
						width='100%'
						height='100%'
					>
						{Object.keys(controlOrdersByExpert).length > 0 && (
							<Board
								initial={controlOrdersByExpert}
								saveOrdersReassignmentToUserV2={saveOrdersReassignmentToUserV2}
								withScrollableColumns
							/>
						)}
					</Wrapper>
				</Wrapper>
			</Wrapper>
		</Drawer>
	);
};

const mapStateToProps = state => ({
	dndOrdersDrawer: selectDndOrdersDrawer(state),
	controlOrdersByExpert: selectControlOrdersByExpert(state),
	getOrdersQueryModal: selectGetOrdersQueryModal(state),
	// order information drawer
	getOrderInformation: getOrderInformation(state),
	allExpertsInfo: selectAllExpertsInfo(state),
});

const actions = {
	mutate1ObjectInDashboard,
	resetDndOrdersDrawer,
	getControlOrders,
	getAllExpertsInfo,
	saveOrdersReassignmentToUserV2,
};

export default connect(mapStateToProps, actions)(DndOrdersDrawer);
