import Immutable from 'immutable';
import _ from 'underscore';
import { filterDataByFilterOptions } from '../';

function filterPaymentOrders(orders, filters) {
	let filteredPaymentOrders = Immutable.List(orders).toJS();

	filteredPaymentOrders = filterDataByFilterOptions(
		filteredPaymentOrders,
		filters,
	);
	return filteredPaymentOrders;
}

export { filterPaymentOrders };
