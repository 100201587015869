// Libs
import React from 'react';
import styled from 'styled-components';
import useTheme from 'hooks/useTheme';
// Components
import Checked16 from './Checked16';
import Unchecked16 from './Unchecked16';

const Icon = styled.div`
	height: 16px;
	margin-right: 10px;
	text-align: center;
`;

const CheckboxV2 = ({ isSelected }) => {
	const { theme } = useTheme();
	const fill = React.useMemo(
		() => (isSelected ? theme._secondaryColor : theme._mediumEmphasisColor),
		[isSelected],
	);
	return (
		<Icon>
			{isSelected ? <Checked16 fill={fill} /> : <Unchecked16 fill={fill} />}
		</Icon>
	);
};

export default CheckboxV2;
