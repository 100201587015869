// Utils
import ImportUtils from '../../ImportUtils';

const replaceFieldValue = ({ order, field, currentFieldValue }) => {
	if (!Array.isArray(field.replace) || !field.replace.length)
		return currentFieldValue;

	return field.replace.reduce((_fieldValue, replaceField) => {
		if (
			(!Array.isArray(replaceField.oldFieldAnchor) ||
				!replaceField.oldFieldAnchor.length) &&
			!replaceField.oldStr
		)
			return _fieldValue;
		if (
			(!Array.isArray(replaceField.newFieldAnchor) ||
				!replaceField.newFieldAnchor.length) &&
			(replaceField.newStr === undefined || replaceField.newStr === null)
		)
			return _fieldValue;

		let oldValue = replaceField.oldStr;
		replaceField.oldFieldAnchor?.find(fieldAnchorId => {
			const checkedValue = !field.cast
				? order[fieldAnchorId]
				: ImportUtils.checkScrappedValueType({
						value: order[fieldAnchorId],
						castType: field.cast,
				  });

			if (checkedValue) {
				oldValue = String(checkedValue).trim();
				return true;
			}
		});
		if (!oldValue) return _fieldValue;
		const re = new RegExp(oldValue, replaceField.flags || 'g');

		let newValue = replaceField.newStr;
		replaceField.newFieldAnchor?.find(fieldAnchorId => {
			const checkedValue = !field.cast
				? order[fieldAnchorId]
				: ImportUtils.checkScrappedValueType({
						value: order[fieldAnchorId],
						castType: field.cast,
				  });
			if (checkedValue) {
				newValue = String(checkedValue).trim();
				return true;
			}
		});
		if (newValue === undefined || newValue === null) return _fieldValue;

		_fieldValue = _fieldValue.replaceAll(re, newValue);
		return _fieldValue;
	}, currentFieldValue);
};

export default class ReplacementValueProcess {
	constructor(order, fields) {
		this.order = order;
		this.fields = fields;
	}

	run() {
		return this.fields.reduce((_order, field) => {
			let value = _order[field.id];

			value = replaceFieldValue({
				order: this.order,
				field,
				currentFieldValue: value,
			}).trim();

			_order[field.id] = value;

			return _order;
		}, this.order);
	}
}
