// Libs
import React from 'react';
// Components
import { Wrapper } from 'components';
import npmPackage from '../../../package.json';

const VersionApp = () => (
	<Wrapper padding='0' position='absolute' bottom='5px' right='0'>
		<span className='version'>{`v${npmPackage.version}`}</span>
	</Wrapper>
);

export default VersionApp;
