// Libs
import { useSelector, useDispatch } from 'react-redux';
//Actions
import {
	mutate1Object,
	setAutoFillCollector,
} from 'components/Collector/actions';
// Selectors
import { selectResources } from 'components/UploadResource/selectors';
import {
	selectReadOnlyCollector,
	selectCollectorLayouts,
	selectDuplicatedCollectorLayout,
	selectCollectorValues,
} from 'components/Collector/selectors';

const useCollectorData = () => {
	//Selectors
	const readOnlyCollector = useSelector(selectReadOnlyCollector);
	const collectorLayout = useSelector(selectCollectorLayouts);
	const duplicatedCollectorLayout = useSelector(
		selectDuplicatedCollectorLayout,
	);
	const collectorValues = useSelector(selectCollectorValues);
	const resources = useSelector(selectResources);
	//Actions
	const dispatch = useDispatch();
	const mutate1ObjectInCollector = (obj1Name, keyValuePairs) =>
		dispatch(mutate1Object(obj1Name, keyValuePairs));

	return {
		readOnlyCollector,
		collectorLayout,
		duplicatedCollectorLayout,
		collectorValues,
		resources,
		mutate1ObjectInCollector,
		setAutoFillCollector,
	};
};

export default useCollectorData;
