// Utils
import { GlobalUtils } from 'utils';
import GENERAL from 'utils/constants/general';
// Config
import ApiServiceConfig from './apiService.config';
// Components
import { notification } from 'antd';

const { NOTIFICATIONS } = GENERAL;

const apiService = ApiServiceConfig.getInstance();

const ERROR = {
	DEFAULT_MESSAGE:
		'Tuve un problema al procesar su solicitud. Por favor inténtalo más tarde',
};

const showErrorMessage = ({
	message = ERROR.DEFAULT_MESSAGE,
	type = 'warn',
}) => {
	notification.warn({
		key: NOTIFICATIONS.TYPES.GLOBAL,
		message,
		type,
		duration: 5,
		className: 'notification-dark-style',
	});
};

export default class ApiService {
	/**
	 * @returns all authorized endpoints to use
	 */
	static endpoints() {
		return ApiServiceConfig.getEndpointKeys();
	}

	/**
	 * Step 3: Call fetch method with:
	 * @param {string} endpoint - Any ENDPOINT_KEYS prop
	 * @param {Object} reqParams - Custom props to inject on request params
	 * @param {Object} bodyParams - Custom props to inject on body params
	 * @returns {Object || Object[]} return api response data object || Error[]
	 */
	static async fetch(endpoint, { reqParams, bodyParams } = {}) {
		return new Promise(async (resolve, reject) => {
			const authorizedEndpoint = ApiServiceConfig.authorizedEndpoints(
				endpoint,
				reqParams,
			);
			const headers = ApiServiceConfig.getEndpointHeaders()[endpoint];
			if (!authorizedEndpoint || !headers) {
				showErrorMessage({ message: 'Parámetros inválidos' });
				return;
			}
			try {
				const { data } = await apiService(
					ApiServiceConfig.buildRequest({
						authorizedEndpoint,
						headers,
						bodyParams,
					}),
				);
				return resolve(data);
			} catch (err) {
				const resData = err?.response?.data || {};
				const resStatus = err?.response?.status || 0;
				// Show multiple errors?
				if (
					(resData.showError || resStatus >= 500) &&
					GlobalUtils.checkArray(resData.errors).length > 0
				) {
					return reject(resData.errors);
				}
				showErrorMessage({
					message: resData.message,
					type: resData.type,
				});
			}
		});
	}
}
