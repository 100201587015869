//Libs
import React from 'react';
//Context
// import { TOPICS } from "context/RealtimeContext";
//Hooks
// import { useRealtime, useNavigatorInstance } from "hooks";
//Components
import AutoFillServiceTaskCollectors from './AutoFillServiceTaskCollectors';
import SendReport from './SendReport';
import DuplicateCollectorElement from './DuplicateCollectorElement';
import DeleteCollectorElement from './DeleteCollectorElement';
import Settings from './Settings';
import CollectorUtils from '../CollectorUtils';

const CollectorModals = ({
	//State
	profile,
	order,
	docId,
	showSendConfirmationModal,
	autoFillCollector,
	serviceTasks,
	collectorLayout,
	duplicatedCollectorLayout,
	filteredCollectorLayout,
	collectorValues,
	resources,
	saveCollectorValues,
	duplicateElement,
	deleteElement,
	//Actions
	handleOnCloseDuplicateElement,
	handleOnCloseDeleteElement,
	mutate1ObjectInCollector,
	updateCollectorRequiredValidation,
	uploadResource,
	completeAuditedReport,
	setAutoSync,
	sendToast,
}) => {
	// const { navigatorInstance } = useNavigatorInstance();
	// const { publish } = useRealtime();
	// const { DUPLICATED_COLLECTOR_LAYOUT } = TOPICS.ACTIONS.COLLECTOR_ECOSYSTEM;

	return (
		<>
			{/* SEND REPORT */}
			{showSendConfirmationModal && (
				<SendReport
					visible={showSendConfirmationModal}
					onConfirm={() =>
						CollectorUtils.handleOnClickConfirmSendReport({
							profile,
							order,
							docId,
							filteredCollectorLayout,
							collectorValues,
							resources,
							updateCollectorRequiredValidation,
							mutate1ObjectInCollector,
							completeAuditedReport,
							sendToast,
						})
					}
					onCancel={() =>
						mutate1ObjectInCollector('reviewManage', {
							showSendConfirmationModal: false,
						})
					}
				/>
			)}

			{/* AUTO FILL SERVICE TASK COLLECTORS */}
			{autoFillCollector.isOpenConfirmation && (
				<AutoFillServiceTaskCollectors
					visible={autoFillCollector.isOpenConfirmation}
					autoFillCollector={autoFillCollector}
					serviceTasks={serviceTasks}
					onConfirm={() =>
						CollectorUtils.handleOnSetAutoFillServiceTaskCollectors(
							order,
							docId,
							filteredCollectorLayout,
							collectorValues,
							autoFillCollector,
							mutate1ObjectInCollector,
							saveCollectorValues,
						)
					}
					onCancel={() =>
						CollectorUtils.handleOnCancelAutoFillServiceTaskCollectors(
							autoFillCollector,
							mutate1ObjectInCollector,
						)
					}
				/>
			)}

			{/* DUPLICATE COLLECTOR */}
			{!!duplicateElement && (
				<DuplicateCollectorElement
					order={order}
					docId={docId}
					visible={!!duplicateElement}
					duplicateElement={duplicateElement}
					collectorLayout={collectorLayout}
					onCancel={handleOnCloseDuplicateElement}
					onConfirm={async (newDuplicatedElement, incrementedSortSequence) => {
						await CollectorUtils.handleOnConfirmDuplicateElement({
							level: duplicateElement.level,
							newDuplicatedElement,
							incrementedSortSequence,
							mutate1ObjectInCollector,
							setAutoSync,
						});

						// Send to Realtime
						//TODO: Cuando ya se cree el OTD antes de empezar la reportería, se debe utilizar el <docId> en lugar
						//del <order.order_id>
						// publish({
						//   topic: `${DUPLICATED_COLLECTOR_LAYOUT.SYNC}/${
						//     order.order_id
						//   }/${navigatorInstance}/${new Date().getTime()}`,
						//   retain: true,
						//   payload: {
						//     level: duplicateElement.level,
						//     newDuplicatedElement,
						//   },
						// });
					}}
				/>
			)}

			{/* DELETE COLLECTOR */}
			{!!deleteElement && (
				<DeleteCollectorElement
					visible={!!deleteElement}
					deleteElement={deleteElement}
					onCancel={handleOnCloseDeleteElement}
					onConfirm={async (deletedElement, deleteCollectorValues) => {
						if (deleteCollectorValues)
							CollectorUtils.handleOnConfirmDeleteElementCollectorValues(
								deleteElement.level,
								deletedElement,
								filteredCollectorLayout,
								collectorValues,
								mutate1ObjectInCollector,
								saveCollectorValues,
								uploadResource,
							);
						await CollectorUtils.handleOnConfirmDeleteElement(
							deleteElement.level,
							deletedElement,
							mutate1ObjectInCollector,
						);

						// publish({
						//   topic: `${DUPLICATED_COLLECTOR_LAYOUT.SYNC}/${
						//     order.order_id
						//   }/${navigatorInstance}/${new Date().getTime()}`,
						//   retain: true,
						//   payload: {
						//     level: deleteElement.level,
						//     deletedElement,
						//   },
						// });
						handleOnCloseDeleteElement();
					}}
				/>
			)}

			<Settings
				onClose={() =>
					mutate1ObjectInCollector('reviewManage', {
						isControlPanelOpen: false,
					})
				}
			/>
		</>
	);
};

export default CollectorModals;
