// Libs
import React from 'react';
import { Card } from 'antd';
// hooks
import { useTheme } from 'hooks';
// Components
import { TextField, LogoIcon, CheckIcon } from 'components';
// Styles
import './OrderHistoryCard.css';
import './BillingMaterialCard.css';

const { Meta } = Card;
const { TextArea } = TextField;

export default function CommentsCard(props) {
	const { theme } = useTheme();

	const {
		user_name,
		entity_name,
		created_at,
		comment,
		newComment,
		customProps,
	} = props;
	const { mutate1Object, getOrderInformationModal, sendCommentReport } =
		customProps;

	return newComment ? (
		<Meta
			id='billingMaterialCard'
			description={
				<div style={{ paddingRight: '10px' }}>
					<TextArea
						autoFocus
						value={getOrderInformationModal.newComment}
						placeholder='Escriba su comentario'
						onChange={e =>
							mutate1Object('getOrderInformationModal', {
								newComment: e.target.value,
							})
						}
					/>

					{getOrderInformationModal.sendingComment ? (
						<LogoIcon button spin />
					) : (
						<CheckIcon
							button
							onClick={() =>
								sendCommentReport(
									getOrderInformationModal.newComment,
									getOrderInformationModal.order_id,
									getOrderInformationModal.activeTab,
								)
							}
						/>
					)}
				</div>
			}
		/>
	) : (
		<Meta
			id='billingMaterialCard'
			title={
				<div className='container-title-card'>
					<span style={{ color: theme._primaryColor }} className='card-creator'>
						{user_name}
						<span className='card-company'> - {entity_name}</span>
					</span>
					<span className='card-created-at'>{created_at}</span>
				</div>
			}
			description={
				<div className='container-description-card scrollable'>
					<div className='card-value'>{comment}</div>
				</div>
			}
		/>
	);
}
