// Libs
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as orderActions from './actions';
import {
	getDepartments,
	getOrderEvents,
	doResetApiData,
	mutate1ObjectApi,
} from 'store/api/actions';
import {
	mutateDirectProps as mutateDirectPropsInDashboard,
	mutate1Object as mutate1ObjectInDashboard,
} from '../Dashboard/actions';
// Utils
import { filterOrdersByProjects } from 'utils/filters/Orders/FilterOrdersByProjects';
import { filterOrdersCategory, filterOrdersByUserBoAssigned } from 'utils/libs';
import { filterOrders } from 'utils/filters/Orders/controlOrders';
import GENERAL from 'utils/constants/general';
import { OrderUtils } from 'utils';
// Selectors
import {
	selectDepartments,
	selectTemplates,
	selectUsers,
} from 'store/api/selectors';
import { selectProfile } from 'screens/Login/selectors';
import { selectModeView } from 'screens/Dashboard/selectors';
// Components
import OrdersDesktop from './Control/Desktop';
import OrdersMobile from './Control/Mobile';
import ModalOrders from './Modals';
import { Wrapper } from 'components';
// Styles
import './index.css';

const { PATHS } = GENERAL.ENV.ROUTES;
function OrdersControl(props) {
	const {
		control,
		modeView,
		getOrdersQueryModal,
		getDepartments,
		getOrderEvents,
		getControlOrders,
		mutate1Object,
		makeDeepSearchOrder,
		doReset,
		doResetApiData,
		profile,
	} = props;
	const history = useHistory();
	let orders = filterOrdersByProjects(control.filteredProjects, control.orders);

	orders = filterOrders(orders, control.filters);

	// Filters
	if (control.myAssignedZones.selectedZone)
		orders = filterOrdersByUserBoAssigned(
			orders,
			control.myAssignedZones.selectedZone,
		);
	if (control.searchInData.data !== '')
		orders = OrderUtils.filterOrdersSearchInData(
			orders,
			control.searchInData.searchBy,
			control.searchInData.data,
			profile.organization.settings.order_prefix,
		);
	if (control.searchInData.searchBy === 'deepSearch')
		OrderUtils.onDeepSearchOrder(
			control.searchInData,
			makeDeepSearchOrder,
			mutate1Object,
		);
	else {
		orders = filterOrdersCategory(
			orders,
			getOrdersQueryModal.eventCategoryToSearch,
		);
	}

	// TODO:Sort orders by timer
	// setOrderSortTimer({ orders, time: "remaining" });

	React.useEffect(() => {
		getDepartments();
		getOrderEvents();
		if (control.searchInData.searchBy !== 'deepSearch')
			getControlOrders(getOrdersQueryModal);

		return () => {
			if (history.location.pathname !== PATHS.CONFLICT_IMPORTED_ORDERS) {
				doReset();
				doResetApiData('ORDERS');
			}
		};
	}, []);
	return (
		<Wrapper
			padding='0'
			className='animated fadeIn'
			maxHeight={`${document.documentElement.clientHeight - 115}px`}
		>
			{modeView === 'table' && <OrdersDesktop {...props} orders={orders} />}
			{modeView === 'cards' && <OrdersMobile {...props} orders={orders} />}
			<ModalOrders {...props} orders={orders} />
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	...state.orders,
	departments: selectDepartments(state),
	users: selectUsers(state),
	templates: selectTemplates(state),
	profile: selectProfile(state),
	modeView: selectModeView(state),
});
const actions = {
	...orderActions,
	// API
	mutate1ObjectApi,
	getDepartments,
	getOrderEvents,
	doResetApiData,
	// DASHBOARD
	mutateDirectPropsInDashboard,
	mutate1ObjectInDashboard,
};
export default connect(mapStateToProps, actions)(OrdersControl);
