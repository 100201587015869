// Libs
import React, { useContext } from 'react';
import _ from 'underscore';
import { useTheme } from 'styled-components';
// Utils
import { formatNumber } from 'utils/libs';
// Context
import { ElementViewBuilderContext } from 'core/context';
// Components
import { ElementViewBuilderConfig } from 'core';
import { Card, Divider, Button, Checkbox } from 'antd';
// Styles
import './OrderCard.css';

const { Meta } = Card;

const Title = props => {
	// if true, it will remove information and disable functionality
	const { compactMode } = props;

	const {
		order_id,
		service_props,
		client_name,
		odt_id,
		created_at,
		price,
		event_name,
		event_props,
		priority,
		coords,
	} = props;
	const { profile, mutate1Object, selectedOrders = [] } = props.customProps;

	const idxSelected = _.findIndex(
		selectedOrders,
		selOrder => selOrder.order_id === order_id,
	);
	const orderPrice = formatNumber.new(price.price, price.symbol, 2);
	const contractLocation =
		coords && !compactMode
			? `https://waze.com/ul?ll=${coords.latitude},${coords.longitude}&z=13`
			: null;
	function onChangeSelectOrder() {
		if (idxSelected === -1) {
			selectedOrders.push({
				...props,
				customProps: undefined,
			});
			mutate1Object('control', { selectedOrders });
		} else
			mutate1Object('control', {
				selectedOrders: selectedOrders.filter(
					selOrder => selOrder.order_id !== order_id,
				),
			});
	}

	return (
		<div className='order_card-title'>
			<div
				className='order_card-flagpriority'
				style={priority && priority.style}
			/>
			<div className='order_card-title-avatar'>
				{service_props && service_props.initial}
			</div>
			<div>
				<div
					className='order_card-title-data-client'
					style={
						compactMode && {
							width: '200px',
							justifyContent: 'flex-start',
						}
					}
				>
					<div
						className='order_card-title-data-client-name'
						style={
							compactMode && {
								width: 'auto',
								display: 'inline-block',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							}
						}
					>
						{client_name}
					</div>
					{!compactMode && (
						<Checkbox
							style={{ width: 'auto' }}
							checked={idxSelected !== -1}
							onChange={onChangeSelectOrder}
						/>
					)}
				</div>
				<div>
					<span
						className='order_card-title-data-odt_id'
						style={
							compactMode && {
								width: 'auto',
								display: 'block',
							}
						}
					>
						{!compactMode ? `${odt_id} -` : odt_id}
					</span>
					<span
						className='order_card-title-data-created_at'
						style={
							compactMode && {
								width: 'auto',
								display: 'block',
							}
						}
					>
						{created_at}
					</span>
				</div>
				<div className='order_card-title-data-price-event_name'>
					<span className='order_card-title-data-price'>
						{profile &&
							profile.user.settings.orders &&
							profile.user.settings.orders.price.viewIndividual &&
							orderPrice}
					</span>
					<div
						disabled={event_props && event_props.freezeEvent}
						style={
							event_props &&
							event_props.freezeEvent && {
								color: 'lightgray',
								borderColor: 'gray',
							}
						}
						className='order_card-title-data-event_name'
					>
						{event_name}
					</div>

					{contractLocation && (
						<a
							style={{ marginLeft: 16 }}
							className='navigate-icon'
							href={contractLocation}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const Description = ({
	order_id,
	zone,
	address,
	counter,
	// if true, it will remove information and disable functionality
	compactMode,
	...rest
}) => {
	const { setElementView } = useContext(ElementViewBuilderContext);

	return (
		<div
			className='order_card-content'
			style={
				compactMode && {
					height: '57px',
				}
			}
		>
			<div
				className='order_card-content-zone-address'
				style={
					compactMode && {
						height: '55px',
						overflow: 'hidden',
					}
				}
			>
				<span className='order_card-content-zone'>{zone} - </span>
				<span className='order_card-content-address'>
					{!compactMode || address.length < 100
						? address
						: `${address.substr(0, 100).trim()}…`}
				</span>
			</div>

			{!compactMode && (
				<div className='order_card-content-actions'>
					<span className='order_card-content-index'>
						{counter < 10 ? `0${counter}` : counter}
					</span>
					<Divider
						type='vertical'
						style={{
							backgroundColor: 'gray',
							margin: '0 15px',
							height: '20px',
						}}
					/>
					<div className='order_card-content-btns'>
						<Button
							id='order_card-content-btn-details'
							onClick={() =>
								setElementView({
									elementId: order_id,
									elementViewKey:
										ElementViewBuilderConfig.getAuthorizedElementViewKeys()
											.ORDER_ELEMENT,
								})
							}
						>
							Ver detalle
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

export default function OrderCard(props) {
	const style4CompactMode = props.compactMode
		? {
				height: '160px',
		  }
		: {};

	const theme = useTheme();
	return (
		<Meta
			id='order_card-meta'
			style={{
				backgroundColor: theme.colors.background.high,
				...style4CompactMode,
			}}
			title={<Title {...props} ellipsis />}
			description={<Description {...props} />}
		/>
	);
}
