function checkArray(arr) {
	return Array.isArray(arr) ? arr : [];
}

class ApiUtils {
	static getTemplatesByType({ templates, type, profile }) {
		const myAssignedProjects = checkArray(profile?.user?.assignment?.projects);

		return (
			checkArray(templates)
				// Filter by template type
				.filter(t => t.template_type_id === type)
				// Filter by my assigned projects
				.filter(t => myAssignedProjects.indexOf(t.departmentId) !== -1)
		);
	}
}

export default ApiUtils;
