//Libs
import React from 'react';
//Utils
import COLLECTOR_KEYWORDS from './keywords';
//Components
import { Wrapper, DeleteIcon, AddIcon } from 'components';
import CollectorUtils from 'components/Collector/CollectorUtils';
import GroupedCollectors from './GroupedCollectors';
import UngroupedCollectors from './UngroupedCollectors';
import DynamicHeaderTitle from './DynamicHeaderTitle';

const { DUPLICATION } = COLLECTOR_KEYWORDS.COLLECTORS;

export const ReviewHeader = React.memo(
	({
		docId,
		orderId,
		review,
		isDuplicable,
		highlightRequired,
		selectedCurrentCollector,
		collectorValues,
		handleOnDuplicateElement,
		handleOnDeleteElement,
	}) => {
		return (
			<Wrapper padding='0' justifyContent='space-between'>
				<DynamicHeaderTitle
					level={DUPLICATION.LEVELS.REVIEW}
					highlightElement={{
						reviewId: review.id,
						serviceTaskId: review.serviceTaskId,
					}}
					defaultTitle={review.name}
					highlightRequired={highlightRequired}
					selectedCurrentCollector={selectedCurrentCollector}
					collectors={review.collectors}
					collectorValues={collectorValues}
				/>
				{isDuplicable && (
					<Wrapper padding='0'>
						<Wrapper padding='0' margin='0 5px 0 0'>
							<DeleteIcon
								onClick={e => {
									e.stopPropagation();
									handleOnDeleteElement({
										level: DUPLICATION.LEVELS.REVIEW,
										element: CollectorUtils.getFormattedDuplicateElement(
											DUPLICATION.LEVELS.REVIEW,
											{ docId, orderId, review },
										),
									});
								}}
							/>
						</Wrapper>

						<AddIcon
							button
							onClick={e => {
								e.stopPropagation();
								handleOnDuplicateElement({
									level: DUPLICATION.LEVELS.REVIEW,
									element: CollectorUtils.getFormattedDuplicateElement(
										DUPLICATION.LEVELS.REVIEW,
										{ docId, orderId, review },
									),
								});
							}}
						/>
					</Wrapper>
				)}
			</Wrapper>
		);
	},
);

export const ReviewSection = React.memo(
	({
		profile,
		order,
		docId,
		readOnlyCollector,
		review,
		highlightRequired,
		selectedCurrentCollector,
		isPhotoBackupActive,
		isSynapseCameraActive,
		duplicatedCollectorLayout,
		filteredCollectorLayout,
		collectorValues,
		resources,
		collectorEcosystemAutoSyncRunning,
		handleOnDuplicateElement,
		handleOnDeleteElement,
		onLoadSegmentedResources,
	}) => {
		const { collectors } = review;

		//This photo count is used by duplicated photo feature to re-render
		//and auto show the new photo frame
		const photoCount = React.useMemo(
			() =>
				collectors.reduce((acc, collector) => {
					acc += collector.photos.length;
					return acc;
				}, 0),
			[collectors],
		);
		const [ungroupedCollectors, groupedCollectors] = React.useMemo(
			() => [
				CollectorUtils.getUngroupedCollectorsFromReview(collectors),
				CollectorUtils.getGroupedCollectorsFromReview(collectors),
			],
			[collectors.length, photoCount, resources, duplicatedCollectorLayout],
		);

		const groups = React.useMemo(
			() =>
				CollectorUtils.getGroupsFromGroupedCollectors(
					DUPLICATION.LEVELS.GROUP,
					{
						docId,
						orderId: order.order_id,
						review: {
							name: review.name,
							serviceId: review.serviceId,
						},
					},
					groupedCollectors,
				),
			[docId, order.order_id, review.name, review.serviceId, groupedCollectors],
		);

		return (
			<>
				{/* UNGROUPED COLLECTORS */}
				<UngroupedCollectors
					profile={profile}
					order={order}
					docId={docId}
					ungroupedCollectors={ungroupedCollectors}
					readOnlyCollector={readOnlyCollector}
					highlightRequired={highlightRequired}
					selectedCurrentCollector={selectedCurrentCollector}
					isPhotoBackupActive={isPhotoBackupActive}
					isSynapseCameraActive={isSynapseCameraActive}
					filteredCollectorLayout={filteredCollectorLayout}
					collectorValues={collectorValues}
					resources={resources}
					collectorEcosystemAutoSyncRunning={collectorEcosystemAutoSyncRunning}
					handleOnDuplicateElement={handleOnDuplicateElement}
					handleOnDeleteElement={handleOnDeleteElement}
				/>

				{/* GROUPED COLLECTORS */}
				{!!groups.length && (
					<GroupedCollectors
						profile={profile}
						order={order}
						docId={docId}
						readOnlyCollector={readOnlyCollector}
						review={review}
						groups={groups}
						groupedCollectors={groupedCollectors}
						photoCount={photoCount}
						highlightRequired={highlightRequired}
						selectedCurrentCollector={selectedCurrentCollector}
						isPhotoBackupActive={isPhotoBackupActive}
						isSynapseCameraActive={isSynapseCameraActive}
						filteredCollectorLayout={filteredCollectorLayout}
						collectorValues={collectorValues}
						resources={resources}
						collectorEcosystemAutoSyncRunning={
							collectorEcosystemAutoSyncRunning
						}
						handleOnDuplicateElement={handleOnDuplicateElement}
						handleOnDeleteElement={handleOnDeleteElement}
						onLoadSegmentedResources={onLoadSegmentedResources}
					/>
				)}
			</>
		);
	},
);
