//Libs
import React from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
//Components
import Wrapper from './Wrapper/Wrapper';
import { CheckCircleOutlined } from '@ant-design/icons';

const Badge = ({
	children,
	value,
	event,
	size = '15px',
	offset = '-2px',
	overflowCount = 99,
}) => {
	const { theme, isDarkTheme } = useTheme();

	if (!value && !event) return children;

	const isValidNumber = num => !!num && !isNaN(num) && num;

	const overFlowNumber = num =>
		num > overflowCount ? `${overflowCount}+` : num;

	const valueFormatted = isValidNumber(value) && overFlowNumber(value);

	const typeEvent = {
		alert: { color: 'red' },
		check: {
			color: theme._secondaryBackgroundColor,
			icon: (
				<CheckCircleOutlined
					size='large'
					style={{ color: theme._primaryColor, fontSize: '14px' }}
				/>
			),
		},
	}[event];

	return (
		<>
			<Wrapper
				position='absolute'
				top={offset}
				right={offset}
				width={size}
				height={size}
				backgroundColor={
					!!typeEvent?.color ? typeEvent.color : theme._secondaryBackgroundColor
				}
				justifyContent='center'
				color={theme.theme}
				fontSize='9px'
				fontWeight='900'
				padding='0'
				border={`1.7px solid ${
					!!typeEvent?.color
						? 'transparent'
						: isDarkTheme
						? 'white'
						: theme._borderColor
				}`}
				borderRadius='100%'
			>
				{!!typeEvent?.icon ? typeEvent?.icon : !typeEvent && valueFormatted}
			</Wrapper>
			{children}
		</>
	);
};

export default Badge;
