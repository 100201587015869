import React, { useEffect, useState } from 'react';
import IconIce from './Build.svg';
import { Switch } from 'antd';
//Hooks
import useProfileData from 'core/hooks/useProfileData';
import { useTheme } from 'hooks';
// Components
import {
	Dropdown,
	Menu,
	Modal,
	SearchBar,
	Section,
	SectionTitle,
	Span,
	Wrapper,
	Title,
	PointTextIcon,
	StarIcon,
} from 'components';
// Styles
import styles from './ProfileActivationModal.module.css';
import { CheckOutlined } from '@ant-design/icons';

const HeaderTitle = ({ name, jobPosition, rating }) => {
	return (
		<div style={{ marginTop: '24px' }}>
			<Title textAlign='left'>{name}</Title>
			<div style={{ display: 'flex' }}>
				<StarIcon />
				<Span disabled>{rating}</Span>
				<PointTextIcon />
				<Span disabled>{jobPosition}</Span>
			</div>
		</div>
	);
};

const MenuSection = ({
	selectedProjectId,
	setSelectedProjectId,
	selectedBussinesLineId,
	setSelectedBussinesLineId,
	selectedModuleId,
	setSelectedModuleId,
	getProfiles,
	dataFromMenus,
}) => {
	const [state, setState] = useState({
		departmentName: undefined,
		businessLineName: undefined,
		moduleName: undefined,
	});

	useEffect(() => {
		setState(prev => ({
			...prev,
			businessLineName: undefined,
			moduleName: undefined,
		}));
		setSelectedBussinesLineId([]);
		setSelectedModuleId([]);
	}, [selectedProjectId]);

	useEffect(() => {
		setState(prev => ({
			...prev,
			moduleName: undefined,
		}));
		setSelectedModuleId([]);
	}, [selectedBussinesLineId]);

	const handleDepartmentSelection = item => {
		const project = dataFromMenus?.find(
			department => department.id === parseInt(item.key),
		);
		setSelectedProjectId(item.key);
		setState(prev => ({
			...prev,
			departmentName: project.name,
		}));
	};

	const handleBusinessLineSelection = item => {
		const project = dataFromMenus?.find(
			department => department.id === parseInt(selectedProjectId),
		);
		const orderTarget = project?.order_targets?.find(
			businessLine => businessLine.id === parseInt(item.key),
		);
		setSelectedBussinesLineId(item.key);
		setState(prev => ({
			...prev,
			businessLineName: orderTarget.name,
		}));
	};

	const handleModuleSelection = item => {
		const project = dataFromMenus?.find(
			department => department.id === parseInt(selectedProjectId),
		);
		const orderTarget = project?.order_targets?.find(
			businessLine => businessLine.id === parseInt(selectedBussinesLineId),
		);
		const module = orderTarget?.modules?.find(
			module => module.id === parseInt(item.key),
		);
		setSelectedModuleId(item.key);
		setState(prev => ({
			...prev,
			moduleName: module.name,
		}));
		getProfiles(item.key);
	};

	const getBusinessLines = () => {
		const project = dataFromMenus?.find(
			department => department.id === parseInt(selectedProjectId),
		);
		return project?.order_targets || [];
	};

	const getModules = () => {
		const businessLines = getBusinessLines();
		const orderTarget = businessLines.find(
			businessLine => businessLine.id === parseInt(selectedBussinesLineId),
		);
		return orderTarget?.modules || [];
	};

	return (
		<div
			style={{
				marginTop: '24px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '100%',
			}}
		>
			<div style={{ width: '100%', marginRight: '15px' }}>
				<Span disabled>Proyectos</Span>
				<Section margin={0}>
					<Dropdown.Wrapper
						trigger={['click']}
						overlay={
							<Menu.Wrapper
								secondaryBackgroundColor
								mode='vertical'
								selectedKeys={selectedProjectId ? [selectedProjectId] : []}
								onClick={handleDepartmentSelection}
								style={{ width: '100%' }}
							>
								{dataFromMenus?.map(department => (
									<Menu.Item
										key={department.id}
										icon={
											department.assigned ? (
												<CheckOutlined />
											) : (
												<div style={{ width: '12px' }}></div>
											)
										}
									>
										{department.name}
									</Menu.Item>
								))}
							</Menu.Wrapper>
						}
					>
						<div>
							<SectionTitle
								title={
									state.departmentName
										? state.departmentName
										: 'Escoja el Proyecto'
								}
							/>
						</div>
					</Dropdown.Wrapper>
				</Section>
			</div>

			<div style={{ width: '100%', marginRight: '15px' }}>
				<Span disabled>Lineas de Negocio</Span>
				<Section margin={0}>
					<Dropdown.Wrapper
						trigger={['click']}
						overlay={
							<Menu.Wrapper
								secondaryBackgroundColor
								mode='vertical'
								selectedKeys={
									selectedBussinesLineId ? [selectedBussinesLineId] : []
								}
								onClick={handleBusinessLineSelection}
								style={{ width: '100%' }}
							>
								{getBusinessLines().map(line => (
									<Menu.Item
										key={line.id}
										icon={
											line.assigned ? (
												<CheckOutlined />
											) : (
												<div style={{ width: '12px' }}></div>
											)
										}
									>
										{line.name}
									</Menu.Item>
								))}
							</Menu.Wrapper>
						}
					>
						<div>
							<SectionTitle
								title={
									state.businessLineName
										? state.businessLineName
										: 'Escoja la linea de negocio'
								}
							/>
						</div>
					</Dropdown.Wrapper>
				</Section>
			</div>

			<div style={{ width: '100%' }}>
				<Span disabled>Moduló</Span>
				<Section margin={0}>
					<Dropdown.Wrapper
						trigger={['click']}
						overlay={
							<Menu.Wrapper
								secondaryBackgroundColor
								mode='vertical'
								selectedKeys={selectedModuleId ? [selectedModuleId] : []}
								onClick={handleModuleSelection}
								style={{ width: '100%' }}
							>
								{getModules().map(module => (
									<Menu.Item
										key={module.id}
										icon={
											module.assigned ? (
												<CheckOutlined />
											) : (
												<div style={{ width: '12px' }}></div>
											)
										}
									>
										{module.name}
									</Menu.Item>
								))}
							</Menu.Wrapper>
						}
					>
						<div>
							<SectionTitle
								title={state.moduleName ? state.moduleName : 'Escoja el moduló'}
							/>
						</div>
					</Dropdown.Wrapper>
				</Section>
			</div>
		</div>
	);
};

const ContentDisplay = ({ profiles, updateUserProfile }) => {
	const toggleProfile = async (profileId, active) => {
		updateUserProfile(profileId, active);
	};

	const ContainItem = ({ profileId, name, description, active }) => (
		<>
			<div
				style={{
					margin: '10px 0',
					padding: '0 5px',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<div style={{ margin: '0 10px', background: 'white' }}>
						<img src={IconIce} alt='' />
					</div>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Span>{name}</Span>
						<Span disabled>{description}</Span>
					</div>
				</div>
				<div>
					<Switch
						checked={active}
						onChange={checked => toggleProfile(profileId, checked)}
					/>
				</div>
			</div>
		</>
	);
	return (
		<div
			style={{
				marginTop: '24px',
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				maxHeight: '300px',
				overflowY: 'scroll',
			}}
		>
			{profiles?.map(profile => {
				return (
					<ContainItem
						key={profile.id}
						profileId={profile.id}
						name={profile.name}
						description={profile.description}
						active={profile.assigned}
					/>
				);
			})}
		</div>
	);
};

const ProfileActivationModal = ({
	userData,
	isModalOpen,
	handleCancelModal,
}) => {
	const { theme, isDarkTheme } = useTheme();
	const [state, setState] = useState({
		filteredProfiles: [],
		searchTerm: '',
	});
	const {
		dataFromMenus,
		profiles,
		selectedModuleId,
		selectedProjectId,
		selectedBussinesLineId,
		fetchDataFromMenus,
		updateUserProfile,
		getProfiles,
		setSelectedModuleId,
		setSelectedProjectId,
		setSelectedBussinesLineId,
	} = useProfileData(userData.userId);

	useEffect(() => {
		fetchDataFromMenus();
	}, [fetchDataFromMenus]);

	useEffect(() => {
		if (state.searchTerm === '') {
			setState(prevState => ({
				...prevState,
				filteredProfiles: profiles,
			}));
		} else {
			const filtered = profiles.filter(profile =>
				profile.name.toLowerCase().includes(state.searchTerm.toLowerCase()),
			);
			setState(prevState => ({
				...prevState,
				filteredProfiles: filtered,
			}));
		}
	}, [state.searchTerm, profiles]);

	return (
		<div className={styles.modalWrapper}>
			<div
				className={styles.blurBackground}
				style={{ display: isModalOpen ? 'block' : 'none' }}
			>
				<Modal
					title={null}
					closable={false}
					onCancel={handleCancelModal}
					centered={true}
					visible={isModalOpen}
					footer={null}
					width='768px'
					secondaryBackgroundColor={
						isDarkTheme
							? theme._secondaryBackgroundColor
							: theme._primaryBackgroundColor
					}
				>
					<Wrapper
						width='100%'
						margin='0'
						justifyContent='center'
						alignItems='flex-start'
						flexDirection='column'
					>
						{Object.keys(selectedModuleId).length ? (
							<SearchBar
								placeholder='Buscar Roles'
								searchTerm={state.searchTerm}
								setSearchTerm={value =>
									setState(prevState => ({
										...prevState,
										searchTerm: value,
									}))
								}
							/>
						) : (
							''
						)}
						<HeaderTitle
							name={userData.name}
							jobPosition={userData.jobPosition}
							rating={userData.rating}
						/>

						{dataFromMenus && (
							<MenuSection
								userId={userData.userId}
								selectedProjectId={selectedProjectId}
								setSelectedProjectId={setSelectedProjectId}
								selectedBussinesLineId={selectedBussinesLineId}
								setSelectedBussinesLineId={setSelectedBussinesLineId}
								selectedModuleId={selectedModuleId}
								setSelectedModuleId={setSelectedModuleId}
								getProfiles={getProfiles}
								dataFromMenus={dataFromMenus}
							/>
						)}
						{Object.keys(selectedModuleId).length ? (
							<ContentDisplay
								profiles={state.filteredProfiles || profiles}
								updateUserProfile={updateUserProfile}
							/>
						) : (
							''
						)}
					</Wrapper>
				</Modal>
			</div>
		</div>
	);
};

export default ProfileActivationModal;
