/** **** CONSTANTS *******/
import GENERAL from '../../utils/constants/general';
import { generatePaycutPDFValidator } from './actionsValidator';
const { DASHBOARD, BILLINGS, APIDATA } = GENERAL;

/** **** FUNCTIONS *******/
export function createPayment(paycut_id, createPaymentModal, type) {
	const { paid_to, name, service_ids } = createPaymentModal;

	if (!paycut_id || !type || (type !== 'payment' && type !== 'invoice')) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Parametros incompletos. Por favor vuélvalo a intentar',
				type: 'warn',
			},
		};
	}

	if (type === 'payment') {
		if (!paid_to) {
			return {
				type: DASHBOARD.TOAST_MESSAGE,
				payload: {
					message: 'Debe completar todos los campos requeridos[*]',
					type: 'warn',
				},
			};
		}
	} else if (type === 'invoice') {
		if (!name || name === '' || !service_ids || service_ids.length === 0) {
			return {
				type: DASHBOARD.TOAST_MESSAGE,
				payload: {
					message: 'Debe completar todos los campos requeridos[*]',
					type: 'warn',
				},
			};
		}
	}

	if (!createPaymentModal.iva) createPaymentModal.iva = 0.0;

	return {
		type: BILLINGS.CREATE_PAYMENT,
		payload: { ...createPaymentModal, paycut_id, type },
	};
}

export function createPaymentLine(createLineModal, apply_to, payId = false) {
	const {
		// LINE
		line_item_id,
		description,
		action,
		amount,
		tracts,
	} = createLineModal;

	if (!line_item_id || !amount || amount <= 0 || !tracts || tracts <= 0) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Debe completar todos los campos requeridos[*]',
				type: 'warn',
			},
		};
	}

	return {
		type: BILLINGS.CREATE_PAYMENT_LINE,
		payload: {
			// LINE
			line_item_id,
			description,
			action,
			amount,
			tracts,
			apply_to,
			payId,
		},
	};
}

export function createPaymentLineItem(createLineModal) {
	const {
		// LINE
		line_item_id,
		// ITEM
		name,
		action,
		props,
		coin_id,
		// LINE && ITEM
		amount,
		tracts,
		operation,
	} = createLineModal;

	if (
		!name ||
		!action ||
		!props ||
		!coin_id ||
		(operation === 'editItem' && !line_item_id)
	) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Debe completar todos los campos requeridos[*]',
				type: 'warn',
			},
		};
	}

	return {
		type: BILLINGS.CREATE_PAYMENT_LINE_ITEM,
		payload: {
			// LINE
			line_item_id,
			// ITEM
			name,
			action,
			props,
			coin_id,
			// LINE && ITEM
			amount,
			tracts,
			operation,
		},
	};
}

export function mutate1Object(obj1Name, keyValuePairs) {
	return {
		type: BILLINGS.MUTATE_1OBJECT,
		payload: { obj1Name, keyValuePairs },
	};
}

export function signPaycut(paycutIds) {
	return {
		type: BILLINGS.SIGN_PAYCUT,
		payload: paycutIds,
	};
}

export function signPayment(payment_id, inModule) {
	return {
		type: BILLINGS.SIGN_PAYMENT,
		payload: { payment_id, inModule },
	};
}

export function updatePaymentOrders(paycutId, payId, editedOrders) {
	return {
		type: BILLINGS.UPDATE_PAYMENT_ORDERS,
		payload: { paycutId, payId, editedOrders },
	};
}

export function syncPaycut(paycut_id) {
	return {
		type: BILLINGS.SYNC_PAYCUT,
		payload: paycut_id,
	};
}

export function resetCreatePaymentLine() {
	return {
		type: BILLINGS.RESET_CREATE_PAYMENT_LINE,
	};
}

export function generatePaymentPDF(paymentIds) {
	return {
		type: BILLINGS.GENERATE_PAYMENT_PDF,
		payload: paymentIds,
	};
}

export function generatePaycutPDF(request, type) {
	const errors = generatePaycutPDFValidator(request);

	if (errors) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: errors,
				type: 'warn',
				duration: 60,
			},
		};
	}

	return {
		type: BILLINGS.GENERATE_PAYCUT_PDF,
		payload: { ...request, type },
	};
}

export function setPaymentReopen(payment_id, paycut_id) {
	return {
		type: BILLINGS.SET_PAYMENT_REOPEN,
		payload: { payment_id, paycut_id },
	};
}

export function setPaymentReady(payment_id) {
	return {
		type: BILLINGS.SET_PAYMENT_READY,
		payload: { payment_id },
	};
}

export function createPaycut(createPaycutModal) {
	return {
		type: BILLINGS.CREATE_PAYCUT,
		payload: createPaycutModal,
	};
}

export function enterInvoiceOrders(orders, payment_id) {
	if (
		!orders ||
		orders.length === 0 ||
		!orders[0].odt_id ||
		!orders[0].amount
	) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message:
					'El archivo no cumple con lo requerido. Debe tener mínimo las columnas [odt_id, amount] y almenos un registro que ingresar',
				type: 'warn',
			},
		};
	}

	return {
		type: BILLINGS.ENTER_INVOICE_ORDERS,
		payload: { orders, payment_id },
	};
}

export function resetGetPaycuts() {
	return {
		type: BILLINGS.RESET_GET_PAYCUTS,
	};
}

export function doReset() {
	return {
		type: BILLINGS.RESET,
	};
}

export const amountUpdate = (id, newAmount) => ({
	type: APIDATA.UPDATE_AMOUNT,
	payload: {
		id,
		newAmount,
	},
});
