// Libs
import React from 'react';
// Utils
import { onScanInventoryTransaction } from 'screens/Warehouses/utils';
// Components
import Input from '@mui/material/Input';
import { CloseIcon, Popover, SearchIcon, BackIcon } from 'components';
const Select = React.lazy(() => import('components/Select'));
import { CustomToolbar, CustomSearchToolbar } from '../sharedFunctions';
// Styles
import '../index.css';

export const TransactionsInventoryToTransfer = ({
	transactionsManage,
	selectNotification,
	mutate1ObjectInWarehouses,
	inventoryToTransferManage,
	modeView,
	handleOnClick,
}) => {
	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={transactionsManage.titleToolbar}
			left={<CloseIcon button onClick={handleOnClick} />}
			right={
				<>
					{/* SEARCH WAREHOUSE */}
					<SearchIcon
						onClick={() =>
							mutate1ObjectInWarehouses('inventoryToTransferManage', {
								searchInData: {
									...inventoryToTransferManage.searchInData,
									searchBy: modeView === 'table' ? 'scan' : 'all',
									renderToolbar: true,
								},
							})
						}
					/>
				</>
			}
		/>
	);
};

export const SearchTransactionsInventoryToTransfer = props => {
	const { Option } = Select;
	const {
		warehousesManage,
		mutate1ObjectInWarehouses,
		transactions,
		onGetOrderDataFromUninstalledSerie,
		sendToast,
	} = props;
	const { inventoryToTransferManage, createTransaction, createPackage } =
		warehousesManage;
	const serialized = [...createTransaction.serialized];
	const { searchInData } = inventoryToTransferManage;
	const SearchBy = (
		<Select
			width='100%'
			defaultValue='all'
			value={searchInData.searchBy}
			onChange={value =>
				mutate1ObjectInWarehouses('inventoryToTransferManage', {
					searchInData: {
						...searchInData,
						searchBy: value,
						showPopover: false,
					},
				})
			}
		>
			<Option value='all'>Todos</Option>
			<Option value='scan'>Escáner</Option>
		</Select>
	);

	function setPlaceholderSearchBy(searchBy) {
		if (searchBy === 'item_code') return 'Código';
		else if (searchBy === 'item_name') return 'Nombre';
		else if (searchBy === 'serie') return 'Serie';
		else if (searchBy === 'scan') return 'Escáner';
		else return 'Todos';
	}

	function keyPressed(e) {
		if (e.key === 'Enter') {
			const value = e.target.value?.trim();
			// TRIM INPUT DATA
			mutate1ObjectInWarehouses('inventoryToTransferManage', {
				searchInData: {
					...searchInData,
					[e.target.name]: value,
					pressEnter: true,
				},
			});
			// SCAN
			onScanInventoryTransaction(
				createTransaction,
				createPackage,
				inventoryToTransferManage,
				transactions,
				serialized,
				mutate1ObjectInWarehouses,
				sendToast,
				onGetOrderDataFromUninstalledSerie,
			);
		}
	}

	return (
		<CustomSearchToolbar
			left={
				<Input
					id='toolbar-search-order-input'
					style={{ height: '100%', width: '100%' }}
					autoFocus
					name='data'
					placeholder={setPlaceholderSearchBy(searchInData.searchBy)}
					value={searchInData.data}
					onKeyPress={keyPressed}
					onChange={e =>
						mutate1ObjectInWarehouses('inventoryToTransferManage', {
							searchInData: {
								...searchInData,
								[e.target.name]: e.target.value,
							},
						})
					}
					startAdornment={
						<BackIcon
							onClick={() =>
								mutate1ObjectInWarehouses('inventoryToTransferManage', {
									searchInData: {
										...searchInData,
										renderToolbar: false,
										data: '',
										searchBy: 'all',
									},
								})
							}
						/>
					}
					endAdornment={
						<Popover
							content={SearchBy}
							placement='rightBottom'
							title='Buscar por'
							trigger='click'
							visible={searchInData.showPopover}
							onVisibleChange={() =>
								mutate1ObjectInWarehouses('inventoryToTransferManage', {
									searchInData: {
										...searchInData,
										showPopover: !searchInData.showPopover,
									},
								})
							}
						>
							<div>
								<SearchIcon />
							</div>
						</Popover>
					}
				/>
			}
		/>
	);
};
