//  Libs
import React from 'react';
import {
	Wrapper,
	InputNumber,
	TextField,
	Button,
	Spin,
	Checkbox,
} from 'components';
import Select from 'components/Select';
//  Styles
import '../../css/CreateArticle.css';

const { Option } = Select;
const { Input } = TextField;

/** ***************************************************** JSX RENDER ******************************************************************/
const CreateArticle = props => {
	const {
		createArticle,
		measuredUnits,
		coins,
		itemFamilies,
		mutate1Object,
		handleOnClickCreateArticle,
	} = props;
	const coinSymbol = coins.reduce((acc, coin) => {
		if (coin.id === createArticle.coin_id) acc = coin.symbol;
		return acc;
	}, '');

	return (
		<Spin spinning={createArticle.loading} size='large' delay={50}>
			<Wrapper justifyContent='center'>
				{/* TITLE */}

				<Wrapper
					width='220px'
					padding='0'
					flexDirection='column'
					alignItems='stretch'
				>
					{/* CODE */}
					<Input
						id='code'
						placeholder='Código'
						maxLength={15}
						value={createArticle.code}
						onChange={e =>
							mutate1Object('createArticle', { [e.target.id]: e.target.value })
						}
					/>

					{/* NAME */}
					<Input
						margin='5px 0 0 0'
						id='name'
						placeholder='Nombre*'
						maxLength={100}
						value={createArticle.name}
						onChange={e =>
							mutate1Object('createArticle', { [e.target.id]: e.target.value })
						}
					/>

					{/* FAMILY */}
					<Select
						margin='5px 0 0 0'
						placeholder='Familia'
						value={createArticle.family_id}
						onChange={value =>
							mutate1Object('createArticle', { family_id: value })
						}
					>
						{itemFamilies.map((itemFamily, idx) => (
							<Option key={idx} value={itemFamily.id}>
								<em>{itemFamily.name}</em>
							</Option>
						))}
					</Select>

					{/* COIN */}
					<Select
						margin='5px 0 0 0'
						placeholder='Moneda*'
						value={createArticle.coin_id}
						onChange={value =>
							mutate1Object('createArticle', { coin_id: value })
						}
					>
						{coins.map((coin, idx) => (
							<Option key={idx} value={coin.id}>
								<em>{coin.name}</em>
							</Option>
						))}
					</Select>

					{/* PRICE */}
					<InputNumber
						margin='5px 0 0 0'
						placeholder='Precio*'
						min={0.0}
						step={0.1}
						value={createArticle.price}
						formatter={value =>
							createArticle.coin_id
								? `${coinSymbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								: undefined
						}
						parser={value => {
							value = value.replace(coinSymbol, '');
							value = value.replace(/\$\s?|(,*)/g, '');
							value = value.replace(/ /g, '');
							return value;
						}}
						// parser={value => value.replace(/\$\s?|(,*)/g, '')}
						onChange={value => mutate1Object('createArticle', { price: value })}
					/>

					{/* IVI */}
					<InputNumber
						margin='5px 0 0 0'
						placeholder='ivi*'
						min={0.0}
						step={0.1}
						value={createArticle.iv}
						onChange={value => mutate1Object('createArticle', { iv: value })}
					/>

					{/* MEASURED UNIT */}
					<Select
						margin='5px 0 0 0'
						placeholder='Medida*'
						value={createArticle.measured_unit_id}
						onChange={value =>
							mutate1Object('createArticle', { measured_unit_id: value })
						}
					>
						{measuredUnits.map((measuredUnit, idx) => (
							<Option key={idx} value={measuredUnit.id}>
								<em>{measuredUnit.name}</em>
							</Option>
						))}
					</Select>

					<Wrapper
						padding='0'
						margin='0 0 10px 0'
						flexDirection='column'
						alignItems='flex-start'
					>
						{/* IS SERIALIZED */}
						<Checkbox
							margin='5px 0 0 0'
							checked={createArticle.is_serialized}
							onChange={e =>
								mutate1Object('createArticle', {
									is_serialized: e.target.checked,
								})
							}
						>
							Serializado
						</Checkbox>

						{/* UNAVAILABLE */}
						<Checkbox
							margin='5px 0 0 0'
							style={{ margin: 0 }}
							checked={createArticle.unavailable}
							onChange={e =>
								mutate1Object('createArticle', {
									unavailable: e.target.checked,
								})
							}
						>
							Artículo no disponible
						</Checkbox>
					</Wrapper>

					{/* CREATE BUTTON */}
					<Button
						className='button-style-1'
						onClick={() => handleOnClickCreateArticle(createArticle)}
					>
						Crear
					</Button>
				</Wrapper>
			</Wrapper>
		</Spin>
	);
};

export default CreateArticle;
