import GENERAL from '../../utils/constants/general';
import { lsHandler } from '../../utils/libs';

const { LOGIN } = GENERAL;

const initialState = {
	user: '',
	password: '',
	profile: lsHandler.get('synapse_profile'),
	loading: false,
};

const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN.CHANGE_INPUT: {
			return {
				...state,
				[action.payload.name]: action.payload.value,
			};
		}
		case LOGIN.MUTATE_DIRECT_PROPS: {
			const keyValuePairs = action.payload;
			return {
				...state,
				...keyValuePairs,
			};
		}
		case LOGIN.LOGGED: {
			const { logged, message } = action.payload;
			return {
				...state,
				logged,
			};
		}
		case LOGIN.SHOW_NEW_PASSWORD_INPUT: {
			return {
				...state,
				showNewPasswordInput: action.payload,
				newPassword: '',
			};
		}
		case LOGIN.SHOW_PASSWORD: {
			return {
				...state,
				[action.payload]: !state[action.payload],
			};
		}
		case LOGIN.SET_MY_PROFILE: {
			return {
				...state,
				profile: action.payload,
			};
		}
		case LOGIN.RESET: {
			return initialState;
		}
		default:
			return state;
	}
};

export default loginReducer;
