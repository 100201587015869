// Libs
import { useSelector } from 'react-redux';
// Services
import ApiServiceV2 from 'services/apiService/apiServiceV2';
// Selectors
import {
	selectSelectedProjectId,
	// selectSelectedOrderTargetFilterId,
} from 'core/selectors';

const endpoint = '/order_events';
const useEventApi = () => {
	const selectedProjectId = useSelector(selectSelectedProjectId);
	// const selectedOrderTargetFilterId = useSelector(
	//   selectSelectedOrderTargetFilterId
	// );

	return {
		/**
		 * Get events
		 * @param {string} searchValue
		 * @returns Object[] events
		 */
		getEvents: ({ searchValue }) => {
			const _searchValue = searchValue
				? Buffer.from(searchValue).toString('base64')
				: undefined;

			return ApiServiceV2.get({
				url: `${endpoint}/v1/${selectedProjectId}/${_searchValue}`,
			});
		},
	};
};

export default useEventApi;
