// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='normalSupervisorIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='2'
			y='1'
			width='20'
			height='25'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.6 1.75C10.6 1.315 10.88 1 11.3 1H12.7C13.134 1 13.4 1.315 13.4 1.75V6H14.8V2.5C14.8 2.5 17.95 3.79 17.95 8.125C17.95 8.125 18.93 8.335 19 10H5C5 8.335 6.05 8.125 6.05 8.125C6.05 3.79 9.2 2.5 9.2 2.5V6H10.6V1.75ZM6.5 11C6.5 14.3137 8.96243 17 12 17C15.0376 17 17.5 14.3137 17.5 11H6.5ZM2 24V26H22V24H2ZM21.9808 23C21.6584 20.2088 17.3151 18 12 18C6.68491 18 2.34155 20.2088 2.01919 23H21.9808Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#normalSupervisorIcon)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
		<mask
			id='mask1_21958_780'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='1'
			y='2'
			width='22'
			height='19'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.8 3.16667C9.8 2.49 10.24 2 10.9 2H13.1C13.782 2 14.2 2.49 14.2 3.16667V10.1667H16.4V4.33333C16.4 4.33333 21.35 6.34 21.35 13.0833C21.35 13.0833 22.89 13.41 23 16H1C1 13.41 2.65 13.0833 2.65 13.0833C2.65 6.34 7.6 4.33333 7.6 4.33333V10.1667H9.8V3.16667ZM23 19H1V21H23V19Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mask1_21958_780)'></g>
	</svg>
);
