//Libs
import React from 'react';
import styled from 'styled-components';
//Components
import IconButton from '@mui/material/IconButton';
import icon from './icon.svg';

const Icon = styled.div`
	margin: ${props => props.margin};
	background-image: url(${icon});
	width: 24px;
	height: 24px;
`;

const ShoppingCartIcon = ({ button, onClick, margin, style }) =>
	button ? (
		<IconButton onClick={onClick} style={style}>
			<Icon />
		</IconButton>
	) : (
		<Icon margin={margin} onClick={onClick} style={style} />
	);

export default ShoppingCartIcon;
