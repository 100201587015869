// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Components
import IconContainer from 'components/IconContainer';
import NormalIcon from './NormalIcon';

const ICONS = {
	normal: NormalIcon,
};
const ICON_BUTTONS = {};

const SelectedIcon = ({ size, fill, button }) => {
	if (button) var _Icon = ICON_BUTTONS[size] || ICON_BUTTONS.medium;
	else var _Icon = ICONS[size] || ICONS.normal;
	return <_Icon fill={fill} />;
};

const AddShopingCartIcon = ({ hoverable, size, selected, button }) => {
	const theme = useTheme();
	const [hover, setHover] = React.useState(false);

	const fill = React.useMemo(
		() =>
			hoverable && (hover || selected)
				? theme.colors.hover.color
				: theme.colors.text.high,
		[hover, selected],
	);

	return (
		<div
			onMouseEnter={hoverable && (() => setHover(true))}
			onMouseLeave={hoverable && (() => setHover(false))}
			style={{
				maxHeight: theme.sizes[size]?.height || theme.sizes.normal.height,
			}}
		>
			{button ? (
				<IconContainer size={size}>
					<SelectedIcon size={size} fill={fill} button={button} />
				</IconContainer>
			) : (
				<SelectedIcon size={size} fill={fill} />
			)}
		</div>
	);
};

export default AddShopingCartIcon;
