// Libs
import React, { useEffect, useMemo, useState } from 'react';
// Hooks
import { useCollectorManager } from 'hooks';
// Components
import {
	Wrapper,
	RadioV2,
	Span,
	Section,
	SectionTitle,
	LogoIcon,
} from 'components';

const styles = {
	container: {
		padding: '0',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'stretch',
		overflow: 'auto',
		maxHeight: '250px',
	},
	item: {
		padding: '0',
		margin: '0 0 5px 0',
	},
};

const ReviewItem = React.memo(
	({ style, review, selectedReviewId, onClick }) => {
		// Is selected
		const isSelected = useMemo(
			() => review.id === selectedReviewId,
			[selectedReviewId],
		);

		return (
			<Wrapper style={style}>
				<Wrapper
					padding='0'
					width='100%'
					height='20px'
					justifyContent='stretch'
					alignItems='center'
					overflow='hidden'
				>
					<RadioV2 isSelected={isSelected} />
					<Span
						ellipsis
						width='100%'
						hoverable
						selected={isSelected}
						onClick={onClick}
					>
						{review.name}
					</Span>
				</Wrapper>
			</Wrapper>
		);
	},
);

const ReviewPicker = ({ photoReport, selectedIds, onMenuItemClick }) => {
	const { id: otdId, templateId, serviceId } = photoReport;
	const { selectedTaskId, selectedReviewId } = selectedIds;
	const { getReviewLayout } = useCollectorManager();
	const [reviews, _setReviews] = useState({
		isFetching: false,
		data: [],
	});
	const setReviews = newProps =>
		_setReviews(current => ({ ...current, ...newProps }));

	// Clear data
	useEffect(() => {
		if (!selectedReviewId) setReviews({ data: [] });
	}, [selectedReviewId]);

	// Get reviews data
	useEffect(() => {
		let isMounted = true;
		if (!selectedTaskId) return;
		setReviews({ isFetching: true });
		getReviewLayout({
			otdId,
			templateId,
			serviceId,
			serviceTaskId: selectedTaskId,
			filled: true, // TODO: Quitar hardcoded
		}).then(data => isMounted && setReviews({ data, isFetching: false }));
		return () => {
			isMounted = false;
		};
	}, [selectedTaskId]);

	// Autoselect first review
	useEffect(() => {
		const firstReview = reviews.data[0];
		if (reviews.isFetching || !firstReview) return;
		onMenuItemClick({
			selectedReviewId: firstReview.id,
			selectedGroupId: undefined,
			selectedSubgroupId: undefined,
		});
	}, [reviews.data, reviews.isFetching]);

	// Memoized review data
	const reviewData = useMemo(
		() =>
			reviews.data.map(review => (
				<ReviewItem
					key={review.id}
					review={review}
					selectedReviewId={selectedReviewId}
					style={styles.item}
					onClick={() =>
						onMenuItemClick({
							selectedReviewId: review.id,
							selectedGroupId: undefined,
							selectedSubgroupId: undefined,
						})
					}
				/>
			)),
		[reviews.data, selectedReviewId],
	);

	return (
		<Section>
			<SectionTitle title='Revisión' empty={!reviews.data[0]} />
			<Wrapper {...styles.container}>
				{reviews.isFetching ? <LogoIcon spin={true} /> : reviewData}
			</Wrapper>
		</Section>
	);
};

export default ReviewPicker;
