// Libs
import React from 'react';
import styled, { css } from 'styled-components';
// Components
import IconButton from '@mui/material/IconButton';
import icon from './Fullscreen.svg';
import selectedIcon from './FullscreenExit.svg';

const Icon = styled.div`
	background-image: ${props =>
		css`url(${props.selected ? selectedIcon : icon})`};
	width: 24px;
	height: 24px;
`;

const FullscreenIcon = ({
	button,
	selected,
	onClick,
	onMouseEnter,
	onMouseLeave,
}) =>
	button ? (
		<IconButton
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<Icon selected={selected} />
		</IconButton>
	) : (
		<Icon
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			selected={selected}
		/>
	);

export default FullscreenIcon;
