import { utils, writeFile } from 'xlsx';

export default function exportMultiSheetToExcel(fileName, sheets) {
	// sheets: Array de objetos json que contienen 2 propiedades:
	//  name: Nombre de la hoja
	//  data: Array de los datos

	// Example: [{name: nombreHoja1, data: [{...}, {...}]}, {name: nombreHoja1, data: [{...}, {...}]}]

	const wb = utils.book_new();

	sheets.forEach(sheet => {
		utils.book_append_sheet(wb, utils.json_to_sheet(sheet.data), sheet.name);
	});

	writeFile(wb, `${fileName}.xlsx`);
}
