// Libs
import React from 'react';
// Components
import { Span, Wrapper } from 'components';
import ControlPanelOpenButton from '../../ControlPanel/OpenButton';

const ControlPanelAccess = ({
	orientation,
	onControlPanelOpen,
	onManualOrientationToggle,
}) => (
	<Wrapper
		padding='10px 16px'
		position='absolute'
		bottom='0'
		width='100%'
		height='50px'
		justifyContent='space-between'
		backgroundColor='rgba(0,0,0,.5)'
	>
		<ControlPanelOpenButton onControlPanelOpen={onControlPanelOpen} />
		<Span color='white' opacity='0.54' onClick={onManualOrientationToggle}>
			{orientation === 'landscape' ? 'Landscape' : 'Portrait'}
		</Span>
	</Wrapper>
);

export default ControlPanelAccess;
