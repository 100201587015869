// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mediumBillingIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='9'
			y='4'
			width='14'
			height='24'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.6399 14.5333C13.6133 13.7467 12.6399 12.9333 12.6399 11.6667C12.6399 10.2133 13.9866 9.2 16.2399 9.2C18.6133 9.2 19.4933 10.3333 19.5733 12H22.5199C22.4266 9.70667 21.0266 7.6 18.2399 6.92V4H14.2399V6.88C11.6533 7.44 9.57325 9.12 9.57325 11.6933C9.57325 14.7733 12.1199 16.3067 15.8399 17.2C19.1733 18 19.8399 19.1733 19.8399 20.4133C19.8399 21.3333 19.1866 22.8 16.2399 22.8C13.4933 22.8 12.4133 21.5733 12.2666 20H9.33325C9.49325 22.92 11.6799 24.56 14.2399 25.1067V28H18.2399V25.1333C20.8399 24.64 22.9066 23.1333 22.9066 20.4C22.9066 16.6133 19.6666 15.32 16.6399 14.5333Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mediumBillingIcon)'>
			<rect width='32' height='32' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
