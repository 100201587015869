// Libs
import React from 'react';
// Components
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from 'recharts';
// Styles
import styles from './SimpleArea.module.css';

/**
 * See data structure example:
 * https://recharts.org/en-US/examples/SimpleAreaChart
 */

const SimpleArea = ({ data, config = {} }) => {
	const {
		width = 500,
		height = 400,
		stroke = '#8884d8',
		fill = '#8884d8',
		values = ['uv'],
		xDataKey = 'name',
	} = config;

	return (
		<div className={styles.simpleAreaContainer} style={{ width, height }}>
			<ResponsiveContainer width='100%' height='100%'>
				<AreaChart
					width={width}
					height={height}
					data={data}
					margin={{
						top: 10,
						right: 30,
						left: 0,
						bottom: 0,
					}}
				>
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis dataKey={xDataKey} />
					<YAxis />
					<Tooltip />
					<Area
						type='monotone'
						dataKey={values[0]}
						stroke={stroke}
						fill={fill}
					/>
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
};

export default SimpleArea;
