// Libs
import React from 'react';
// Components
import { TableVirtualized } from 'components';

const { Row, onRightClickRow } = TableVirtualized;

const RowRenderer = ({
	rowKey,
	columns,
	style,
	filteredDatasource,
	selected,
	setState,
}) => {
	const onFilterItems = filteredItems =>
		setState(prev => ({
			...prev,
			selected: { ...prev.selected, items: filteredItems },
		}));

	return (
		<Row
			key={rowKey}
			style={style}
			onContextMenu={e =>
				onRightClickRow(
					e,
					'id',
					selected.items,
					filteredDatasource,
					onFilterItems,
				)
			}
		>
			{columns}
		</Row>
	);
};

export default RowRenderer;
