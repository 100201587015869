// Libs
import React from 'react';
import _ from 'underscore';
// Utils
import { filterArticles } from 'utils/filters/Warehouses/articles';
// Components
import { Modal } from 'screens/Utils';
import { Wrapper, TableVirtualized, Span, Flag } from 'components';
// Styles
import '../../css/Articles.css';

const { Table, Column, Header, Row, Cell } = TableVirtualized;

function ArticlesDesktop({
	createArticle,
	measuredUnits,
	coins,
	itemFamilies,
	articles,
	articlesManage,
	filterArticlesSearchInData,
	mutate1Object,
	handleOnClickCreateArticle,
	profile,
}) {
	const allArticles = React.useMemo(() => [...articles.data], [articles.data]);
	let filteredArticles = filterArticlesSearchInData(
		allArticles,
		articlesManage.searchInData.searchBy,
		articlesManage.searchInData.data,
	);

	filteredArticles = filterArticles(allArticles, articlesManage.filters);

	// HEADER
	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: allArticles,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: articlesManage.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('articlesManage', {
							filters: updatedFilters,
						}),
				}}
			>
				{label}
			</Header>
		);
	}

	// INDEX
	function getIndexCellRenderer({ rowData, rowIndex }) {
		let flagPriorityStyle = {};

		if (rowData.priority) flagPriorityStyle = { ...rowData.priority.style };
		return (
			<Cell>
				<Flag style={flagPriorityStyle} height='20px' />
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	}

	// ROW
	function getRowRenderer({ key, columns, style, rowData }) {
		const selectedArticles = [...articlesManage.selectedArticles];
		let styleRow = { ...style };

		// Is article selected?
		const idx = _.findIndex(
			selectedArticles,
			articleName => articleName === rowData.name,
		);
		if (idx !== -1) {
			styleRow = {
				...styleRow,
				color: 'lightseagreen',
				backgroundColor: 'rgb(44,44,54)',
			};
		}

		return (
			<Row key={key} style={styleRow}>
				{columns}
			</Row>
		);
	}

	//  Render
	return (
		<Wrapper padding='0' className='animated fadeIn'>
			<Table
				highBackground
				width={document.documentElement.clientWidth}
				height={document.documentElement.clientHeight - 115}
				headerHeight={44}
				rowHeight={40}
				rowCount={filteredArticles.length}
				rowGetter={({ index }) => filteredArticles[index]}
				rowRenderer={getRowRenderer}
			>
				<Column
					dataKey='index'
					label='#'
					width={70}
					headerRenderer={({ label }) => <Header>{label}</Header>}
					cellRenderer={getIndexCellRenderer}
				/>
				<Column
					dataKey='item'
					label='Código'
					width={100}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='name'
					label='Nombre'
					width={280}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='family_name'
					label='Familia'
					width={140}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='measured_unit_name'
					label='Medida'
					width={80}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='serialized'
					label='Serializado'
					width={100}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='unavailable'
					label='Disponible'
					width={90}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='coin_name'
					label='Moneda'
					width={150}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='priceFormatted'
					label='Precio'
					width={100}
					headerRenderer={props => getHeaderRenderer(props, 'numeric')}
				/>
				<Column
					dataKey='ivFormatted'
					label='IVI'
					width={90}
					headerRenderer={props => getHeaderRenderer(props, 'numeric')}
				/>
				<Column
					dataKey='creator_name'
					label='Creador'
					width={180}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='entity_name'
					label='Entidad'
					width={180}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='created_at'
					label='Creado'
					width={130}
					headerRenderer={props => getHeaderRenderer(props, 'date')}
				/>
				<Column
					dataKey='updated_at'
					label='Actualizado'
					width={130}
					headerRenderer={props => getHeaderRenderer(props, 'date')}
				/>
			</Table>

			<Modal
				modalId='CreateArticleModal'
				title='Nuevo Artículo'
				visible={createArticle.isOpen}
				onCancel={() => mutate1Object('createArticle', { isOpen: false })}
				footer={null}
				isSpinning={createArticle.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					createArticle,
					measuredUnits,
					coins: coins.data,
					itemFamilies,
					mutate1Object,
					handleOnClickCreateArticle,
				}}
			/>
		</Wrapper>
	);
}

export default ArticlesDesktop;
