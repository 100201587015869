// Libs
import React from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import IconButton from '@mui/material/IconButton';
import SmallIcon from './SmallSearch';
import Icon from './Search';

const SearchIcon = ({ onClick, small, onlyIcon }) => {
	const { theme } = useTheme();

	return onlyIcon ? (
		small ? (
			<SmallIcon fill={theme._mediumEmphasisColor} onClick={onClick} />
		) : (
			<Icon fill={theme._mediumEmphasisColor} onClick={onClick} />
		)
	) : (
		<IconButton onClick={onClick}>
			{small ? (
				<SmallIcon fill={theme._mediumEmphasisColor} />
			) : (
				<Icon fill={theme._mediumEmphasisColor} />
			)}
		</IconButton>
	);
};

export default SearchIcon;
