export default class ServiceTaskUtils {
	static getServiceTaskProps(key, serviceTaskProps) {
		if (!key || !serviceTaskProps) return {};

		if (typeof serviceTaskProps === 'object' && !serviceTaskProps[key])
			return {};
		if (typeof serviceTaskProps[key] !== 'object') return {};
		return { ...serviceTaskProps[key] };
	}
}
