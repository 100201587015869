//Libs
import React from 'react';
import { useDispatch } from 'react-redux';
//Utils
import {
	GlobalUtils,
	ProjectUtils,
	ServiceUtils,
	ServiceTaskUtils,
	OrderUtils,
	UserUtils,
} from 'utils';
import GENERAL from 'utils/constants/general';
import UploadResourceUtils from 'components/UploadResource/UploadResourceUtils';
//Actions
import { updateResources } from 'components/UploadResource/actions';
// Hooks
import useTheme from 'hooks/useTheme';
//Components
import { Wrapper, Span, Collapse as _Collapse, Switch } from 'components';
import KEYWORDS from './keywords';
import CollectorUtils from './CollectorUtils';
import DynamicHeaderTitle from './DynamicHeaderTitle';
import { ReviewSection, ReviewHeader } from './ReviewSection';

const { ENV } = GENERAL;
const { DUPLICATION } = KEYWORDS.COLLECTORS;
const { Collapse, Panel } = _Collapse;

export const ServiceTaskHeader = React.memo(
	({
		task,
		autoFillCollector,
		highlightRequired,
		selectedCurrentCollector,
		readOnlyCollector,
		order,
		profile,
		mutate1ObjectInCollector,
	}) => {
		const { serviceTaskId, serviceTaskName, serviceTaskProps } = task;

		//Collector photo config
		const collectorAutoFillProps = GlobalUtils.selectCurrentProps([
			ProjectUtils.getProjectPropsFromOrder(
				ENV.MANAGE_PROPS.PROPS.COLLECTOR_AUTO_FILL.NAME,
				order,
			),
			ServiceUtils.getServicePropsFromOrder(
				ENV.MANAGE_PROPS.PROPS.COLLECTOR_AUTO_FILL.NAME,
				order,
			),
			ServiceTaskUtils.getServiceTaskProps(
				ENV.MANAGE_PROPS.PROPS.COLLECTOR_AUTO_FILL.NAME,
				serviceTaskProps,
			),
			OrderUtils.getOrderPropsFromOrder(
				ENV.MANAGE_PROPS.PROPS.COLLECTOR_AUTO_FILL.NAME,
				order,
			),
			UserUtils.getUserPropsFromProfile(
				ENV.MANAGE_PROPS.PROPS.COLLECTOR_AUTO_FILL.NAME,
				profile,
			),
		]);

		//Get combine order and service task
		const combineOrderAndServiceTaskId =
			CollectorUtils.getCombineOrderAndServiceTask(order, serviceTaskId);

		return (
			<Wrapper padding='0' justifyContent='space-between'>
				<DynamicHeaderTitle
					level={DUPLICATION.LEVELS.SERVICE_TASK}
					highlightElement={{ serviceTaskId }}
					defaultTitle={serviceTaskName}
					highlightRequired={highlightRequired}
					selectedCurrentCollector={selectedCurrentCollector}
				/>
				{/* AUTOFILL NA */}
				{collectorAutoFillProps.na && !readOnlyCollector && (
					<Wrapper padding='0' onClick={e => e.stopPropagation()}>
						<Span fontSize='l' margin='0 5px 0 0'>
							NA
						</Span>
						<Switch
							size='small'
							checked={autoFillCollector.na[combineOrderAndServiceTaskId]}
							onChange={isActive =>
								mutate1ObjectInCollector('reviewManage', {
									autoFillCollector: {
										...autoFillCollector,
										isOpenConfirmation: true,
										serviceTaskId,
										combineOrderAndServiceTaskId,
										isActive,
									},
								})
							}
						/>
					</Wrapper>
				)}
			</Wrapper>
		);
	},
);

export const ServiceTaskSection = ({
	collectorEcosystemAutoSync,
	task,
	profile,
	order,
	docId,
	readOnlyCollector,
	reviewActiveKey,
	highlightRequired,
	selectedCurrentCollector,
	isPhotoBackupActive,
	isSynapseCameraActive,
	collectorLayout,
	duplicatedCollectorLayout,
	filteredCollectorLayout,
	collectorValues,
	resources,
	handleOnDuplicateElement,
	handleOnDeleteElement,
	onChangeCollapse,
}) => {
	const dispatch = useDispatch();
	const { theme, isDarkTheme } = useTheme();

	const duplicatedReviewLastNames = React.useMemo(() => {
		const reviews = collectorLayout.filter(
			r => r.serviceTaskId === task.serviceTaskId,
		);
		return reviews.reduce((acc, review) => {
			//Get current last element
			const { originalElementName, lastSequenceNumber } =
				CollectorUtils.getCurrentLastElement(
					DUPLICATION.LEVELS.REVIEW,
					review.name,
					reviews,
					{ docId, orderId: order.order_id, review },
				);
			if (!originalElementName || !lastSequenceNumber) return acc;

			acc[`${originalElementName} #${lastSequenceNumber}`] = true;
			return acc;
		}, {});
	}, [docId, order.order_id, task.serviceTaskId, collectorLayout]);

	const onLoadSegmentedResources = segmentData => {
		setTimeout(
			() =>
				UploadResourceUtils.getResourcesToSaveInState(segmentData).then(
					resources => dispatch(updateResources(resources)),
				),
			200,
		);
	};

	React.useEffect(() => {
		if (!reviewActiveKey) return;
		onLoadSegmentedResources({
			docId,
			orderId: order.order_id,
			serviceTaskId: task.serviceTaskId,
			reviewId: reviewActiveKey,
		});
	}, [
		collectorEcosystemAutoSync.resource,
		docId,
		order.order_id,
		task.serviceTaskId,
		reviewActiveKey,
	]);

	const isDuplicated = review => !!review.reviewIdx;
	const isSameReview = ({ reviewActiveKey, review }) =>
		!reviewActiveKey ||
		(isDuplicated(review) && reviewActiveKey === review.name) ||
		String(reviewActiveKey) === String(review.id);
	const setSelectedReview = review =>
		isDuplicated(review) ? review.name : review.id;

	return (
		<Collapse
			background={!isDarkTheme && theme._secondaryBackgroundColor}
			accordion
			bordered={false}
			defaultActiveKey={['-1']}
			activeKey={reviewActiveKey}
			destroyInactivePanel={true}
			onChange={activeKey => {
				onChangeCollapse(activeKey);
				if (!activeKey) {
					dispatch(updateResources([]));
					return;
				}
				onLoadSegmentedResources({
					docId,
					orderId: order.order_id,
					serviceTaskId: task.serviceTaskId,
					reviewId: activeKey,
				});
			}}
		>
			{filteredCollectorLayout
				.filter(review => review.serviceTaskId === task.serviceTaskId)
				.map(
					review =>
						isSameReview({ reviewActiveKey, review }) && (
							<Panel
								key={setSelectedReview(review)}
								header={
									<ReviewHeader
										docId={docId}
										orderId={order.order_id}
										review={review}
										isDuplicable={duplicatedReviewLastNames[review.name]}
										highlightRequired={highlightRequired}
										selectedCurrentCollector={selectedCurrentCollector}
										collectorValues={collectorValues}
										handleOnDuplicateElement={handleOnDuplicateElement}
										handleOnDeleteElement={handleOnDeleteElement}
									/>
								}
							>
								<ReviewSection
									profile={profile}
									order={order}
									docId={docId}
									readOnlyCollector={readOnlyCollector}
									review={review}
									highlightRequired={highlightRequired}
									selectedCurrentCollector={selectedCurrentCollector}
									isPhotoBackupActive={isPhotoBackupActive}
									isSynapseCameraActive={isSynapseCameraActive}
									duplicatedCollectorLayout={duplicatedCollectorLayout}
									filteredCollectorLayout={filteredCollectorLayout}
									collectorValues={collectorValues}
									resources={resources}
									collectorEcosystemAutoSyncRunning={
										collectorEcosystemAutoSync.running
									}
									handleOnDuplicateElement={handleOnDuplicateElement}
									handleOnDeleteElement={handleOnDeleteElement}
									onLoadSegmentedResources={onLoadSegmentedResources}
								/>
							</Panel>
						),
				)}
		</Collapse>
	);
};
