// Libs
import _ from 'underscore';
import Immutable from 'immutable';
import dayjs from 'dayjs';
// Utils
import { normalize } from 'utils/libs';
import GENERAL from 'utils/constants/general';
const { ENV } = GENERAL;

export function onKeyPressedSearchOrder(
	e,
	searchInData,
	history,
	modeView,
	mutate1ObjectInOrders,
	mutateDirectPropsInDashboard,
) {
	const { data, searchBy } = searchInData;

	if (e.key === 'Enter' && searchBy === 'deepSearch' && data && data !== '') {
		mutate1ObjectInOrders('control', {
			searchInData: { ...searchInData, pressEnter: true },
		});
		mutateDirectPropsInDashboard({ isOpen: false });
		history.push(ENV.ROUTES.PATHS.ORDERS);
	}
}

export function onDeepSearchOrder(
	searchInData,
	makeDeepSearchOrder,
	mutate1Object,
) {
	const { data, pressEnter } = searchInData;

	if (pressEnter && data && data !== '') {
		mutate1Object('control', {
			searchInData: { ...searchInData, pressEnter: false },
		});
		makeDeepSearchOrder(data.trim());
	}
}

export function handleOnClickCheckSortBtn(orders, sorting) {
	let updateOrders = [];

	for (const expertId in sorting.selectedUsers) {
		updateOrders = updateOrders.concat(orders[expertId]);
	}

	return updateOrders;
}

export function canCreateNewComment(getOrderInformationModal) {
	const { activeTab, billingReport, photoReport, viewComments } =
		getOrderInformationModal;

	if (activeTab === '3') {
		return (
			viewComments &&
			(!billingReport.comments ||
				_.findIndex(billingReport.comments, comment => comment.newComment) ===
					-1)
		);
	} else if (activeTab === '4') {
		return (
			viewComments &&
			(!photoReport.comments ||
				_.findIndex(photoReport.comments, comment => comment.newComment) === -1)
		);
	}
}

export function getCommentsCount(getOrderInformationModal) {
	const { activeTab, billingReport, photoReport } = getOrderInformationModal;

	if (activeTab === '3') {
		if (billingReport && billingReport.comments)
			return billingReport.comments.length;
	} else if (activeTab === '4') {
		if (photoReport && photoReport.comments) return photoReport.comments.length;
	}
	return 0;
}

export function onAddComment(getOrderInformationModal, mutate1Object) {
	const { activeTab, billingReport, photoReport } = getOrderInformationModal;

	if (activeTab === '3') {
		const comments = billingReport.comments ? [...billingReport.comments] : [];

		if (_.findIndex(comments, comment => comment.newComment) === -1) {
			comments.push({ newComment: true });
			mutate1Object('getOrderInformationModal', {
				billingReport: { ...billingReport, comments },
			});
		}
	} else if (activeTab === '4') {
		const comments = photoReport.comments ? [...photoReport.comments] : [];

		if (_.findIndex(comments, comment => comment.newComment) === -1) {
			comments.push({ newComment: true });
			mutate1Object('getOrderInformationModal', {
				photoReport: { ...photoReport, comments },
			});
		}
	}
}

export function onViewComments(getOrderInformationModal, mutate1Object) {
	const { activeTab, billingReport, photoReport, viewComments } =
		getOrderInformationModal;

	if (activeTab === '3') {
		if (!viewComments)
			mutate1Object('getOrderInformationModal', {
				viewComments: !getOrderInformationModal.viewComments,
			});
		else {
			mutate1Object('getOrderInformationModal', {
				viewComments: !getOrderInformationModal.viewComments,
				billingReport: {
					...billingReport,
					comments: billingReport.comments
						? billingReport.comments.filter(comment => !comment.newComment)
						: [],
				},
			});
		}
	} else if (activeTab === '4') {
		if (!viewComments)
			mutate1Object('getOrderInformationModal', {
				viewComments: !getOrderInformationModal.viewComments,
			});
		else {
			mutate1Object('getOrderInformationModal', {
				viewComments: !getOrderInformationModal.viewComments,
				photoReport: {
					...photoReport,
					comments: photoReport.comments
						? photoReport.comments.filter(comment => !comment.newComment)
						: [],
				},
			});
		}
	}
}

export function onClickSearchButton(mutate1ObjectInOrders, searchInData) {
	mutate1ObjectInOrders('control', {
		searchInData: {
			...searchInData,
			renderToolbar: true,
			searchBy: 'deepSearch',
		},
	});
}

export function onClickCloseSearchButton(
	mutate1ObjectInOrders,
	searchInData,
	getControlOrders,
	getOrdersQueryModal,
) {
	mutate1ObjectInOrders('control', {
		searchInData: {
			...searchInData,
			renderToolbar: false,
			searchBy: 'all',
			data: '',
		},
	});
	getControlOrders(getOrdersQueryModal);
}

export function filterOrdersSearchInData(orders, searchBy, data, orderPrefix) {
	const formattedData = filterPrefixOrder(data, orderPrefix);

	switch (searchBy) {
		case 'order':
			return orders.filter(order => order.odt_id.includes(formattedData));
		case 'contract':
			return orders.filter(order =>
				order.contract_number.includes(formattedData),
			);
		case 'client':
			return orders.filter(order =>
				order.client_name.toUpperCase().includes(formattedData.toUpperCase()),
			);
		case 'city':
			return orders.filter(order =>
				order.city?.toUpperCase().includes(formattedData.toUpperCase()),
			);
		case 'township':
			return orders.filter(order =>
				order.township?.toUpperCase().includes(formattedData.toUpperCase()),
			);
		case 'zone':
			return orders.filter(order =>
				order.zone?.toUpperCase().includes(formattedData.toUpperCase()),
			);
		case 'service':
			return orders.filter(order =>
				order.service_name.toUpperCase().includes(formattedData.toUpperCase()),
			);
		case 'expert':
			return orders.filter(order =>
				order.expert_name.toUpperCase().includes(formattedData.toUpperCase()),
			);
		case 'company':
			return orders.filter(order =>
				order.entity_name.toUpperCase().includes(formattedData.toUpperCase()),
			);
		case 'event':
			return orders.filter(order =>
				order.event_name.toUpperCase().includes(formattedData.toUpperCase()),
			);
		case 'state':
			return orders.filter(order =>
				order.state_name.toUpperCase().includes(formattedData.toUpperCase()),
			);
		case 'priority':
			return orders.filter(
				order =>
					order.priority &&
					order.priority.label &&
					order.priority.label
						.toUpperCase()
						.includes(formattedData.toUpperCase()),
			);
		case 'external_range':
			return orders.filter(order =>
				order.external_range
					.toUpperCase()
					.includes(formattedData.toUpperCase()),
			);
		case 'deepSearch':
		case 'all':
			return orders.filter(
				order =>
					order.order_id.toString().includes(formattedData) ||
					order.odt_id.includes(formattedData) ||
					order.contract_number.includes(formattedData) ||
					order.client_name
						.toUpperCase()
						.includes(formattedData.toUpperCase()) ||
					order.city?.toUpperCase().includes(formattedData.toUpperCase()) ||
					order.township?.toUpperCase().includes(formattedData.toUpperCase()) ||
					order.zone?.toUpperCase().includes(formattedData.toUpperCase()) ||
					order.service_name
						.toUpperCase()
						.includes(formattedData.toUpperCase()) ||
					order.expert_name
						.toUpperCase()
						.includes(formattedData.toUpperCase()) ||
					order.entity_name
						.toUpperCase()
						.includes(formattedData.toUpperCase()) ||
					order.event_name
						.toUpperCase()
						.includes(formattedData.toUpperCase()) ||
					order.state_name
						.toUpperCase()
						.includes(formattedData.toUpperCase()) ||
					(order.priority &&
						order.priority.label &&
						order.priority.label
							.toUpperCase()
							.includes(formattedData.toUpperCase())) ||
					order.external_range
						.toUpperCase()
						.includes(formattedData.toUpperCase()),
			);
	}
}

export function getMyAssignedZones(orders) {
	const myAssignedZones = [];

	orders.map(order => {
		if (order.userBoAssigned) {
			for (let userId in order.userBoAssigned) {
				userId = Number(userId);

				const idx = _.findIndex(myAssignedZones, id => id === userId);
				if (idx === -1) myAssignedZones.push(userId);
			}
		}
	});

	return myAssignedZones;
}

export function getExpertListOrders(orders) {
	const expertListOrders = [];

	orders.map(order => {
		const idx = _.findIndex(
			expertListOrders,
			exp => exp.user_id === order.assigned_tech_id,
		);

		if (idx === -1)
			expertListOrders.push({
				user_id: order.assigned_tech_id,
				name: order.expert_name,
				work_code: order.work_code,
				count: 1,
			});
		else expertListOrders[idx].count++;
	});

	return expertListOrders;
}

export function getEventListOrders(orders) {
	const eventListOrders = [];

	orders.map(order => {
		const idx = _.findIndex(
			eventListOrders,
			evt => evt.event_id === order.event_id,
		);

		if (idx === -1)
			eventListOrders.push({
				event_id: order.event_id,
				name: order.event_name,
				count: 1,
			});
		else eventListOrders[idx].count++;
	});

	return eventListOrders;
}

export function getServiceListOrders(orders) {
	const serviceListOrders = [];

	orders.map(order => {
		const idx = _.findIndex(
			serviceListOrders,
			srv => srv.service_id === order.service_id,
		);

		if (idx === -1)
			serviceListOrders.push({
				service_id: order.service_id,
				name: order.service_name,
				count: 1,
			});
		else serviceListOrders[idx].count++;
	});

	return serviceListOrders;
}

export function filterPrefixOrder(order, orderPrefix) {
	return order.replace(orderPrefix, '');
}

export function processImportOrders(
	items,
	odtField,
	stateField,
	serviceField,
	clientCategoryField,
	templateValidator,
	validateFieldsTemplate,
	profile,
	states,
	services,
	clientCategories,
	sendToast,
) {
	const { organization } = profile;
	const algorithm = organization?.algorithms?.orders?.import;
	const orderPrefix = organization?.settings?.order_prefix;
	const now = dayjs();

	if (!odtField) {
		sendToast({
			message: `El template seleccionado no cuenta con el campo requerido: [odt_id]. Por favor contacte a su administrador`,
			type: 'warn',
		});
		return;
	}
	if (!stateField) {
		sendToast({
			message: `El template seleccionado no cuenta con el campo requerido: [order_state_id]. Por favor contacte a su administrador`,
			type: 'warn',
		});
		return;
	}
	if (!serviceField) {
		sendToast({
			message: `El template seleccionado no cuenta con el campo requerido: [order_service_id]. Por favor contacte a su administrador`,
			type: 'warn',
		});
		return;
	}

	// CONFIGURE ALGORITHMS

	//* ** IMPORT V1 ****//
	function import_v1() {
		const duplicatedOrders = {};
		const rows = Immutable.List(items).toJS();

		// ADD DYNAMIC DATA?
		if (templateValidator.props?.addDynamicData) {
			rows.forEach((row, idx) => {
				if (!row.dynamicData) row.dynamicData = items[idx];
			});
		}

		return (
			rows
				// REMOVE INVALID OR EMPTY ROWS
				.filter(row => {
					let returnRow = true;
					// REMOVE NULLISH VALUES
					if (
						row[odtField.name] === undefined ||
						row[odtField.name] === null ||
						row[odtField.name] === ''
					) {
						returnRow = false;
					}
					// IGNORE SPECIFIC STATES
					else if (
						stateField &&
						Array.isArray(stateField.ignore) &&
						stateField.ignore.length > 0 &&
						stateField.ignore.indexOf(
							normalize(row[stateField.name]).toUpperCase().trim(),
						) !== -1
					) {
						returnRow = false;
					}
					return returnRow;
				})
				// REMOVE DUPLICATE ROWS
				.filter(row => {
					const exists = !duplicatedOrders[row[odtField.name]] || false;
					duplicatedOrders[row[odtField.name]] = true;
					return exists;
				})
				// RESET, NORMALIZE & MAPPING DATA
				.map(row => {
					// Remove order prefix
					row[odtField.name] = filterPrefixOrder(
						row[odtField.name],
						orderPrefix,
					);

					// Iterate Validate Fields Template
					for (const field of validateFieldsTemplate) {
						// Reset
						if (!row[field.name]) row[field.name] = '';
						// Normalize
						row[field.name] =
							field.type === 'number'
								? row[field.name]
								: normalize(row[field.name]).toUpperCase().trim();

						// Get Data (Special Method)
						if (field.getData?.method) {
							switch (field.getData.method) {
								case 'firstNumberSequence': {
									const values = row[field.name]
										.split(
											field.getData.splitSeparator,
											field.getData.splitNumberSeparators,
										)
										.map(val => val.trim());

									const numberFound = values.find(val => {
										const numValue = parseInt(val[0]);
										return !isNaN(numValue) && numValue > 0;
									});

									if (numberFound) {
										row[field.id] = numberFound;
									}
								}
								// Add new manage method case
							}
						}

						// Concat Data?
						if (!field.concat) {
							row[field.id] = row[field.name];
						} else {
							// Options:
							const keepOriginalId =
								Array.isArray(field.concat.options) &&
								field.concat.options.indexOf('keepOriginalId') !== -1;

							if (field.concat.value) {
								if (field.concat.value === 'now') {
									row[field.id] = `${
										keepOriginalId ? row[field.id] : row[field.name]
									} ${now.format(field.concat.format)}`;
								} else {
									row[field.id] = `${
										keepOriginalId ? row[field.id] : row[field.name]
									} ${field.concat.value}`;
								}
							} else if (field.concat.field) {
								row[field.id] = `${
									keepOriginalId ? row[field.id] : row[field.name]
								} ${row[field.concat.field]}`;
							}
						}

						// Hash values
						if (field.hash && row[field.id]) {
							row[field.id] = row[field.id].replaceAll(' ', '.');
						}

						// Replace values
						if (Array.isArray(field.replace) && field.replace.length > 0) {
							row[field.id] = field.replace.reduce(
								(acc, { oldStr, newStr }) => {
									return acc.replaceAll(oldStr, newStr);
								},
								row[field.id],
							);
						}

						// Trim values
						if (typeof row[field.id] === 'string')
							row[field.id] = row[field.id].trim();
						if (typeof row[field.name] === 'string')
							row[field.name] = row[field.name].trim();
					}

					// Set State ID
					const defaultStateId = Number(stateField.default);
					if (!isNaN(defaultStateId)) {
						const stateIdx = _.findIndex(
							states,
							state => state.id === defaultStateId,
						);
						if (stateIdx === -1) {
							throw Error(
								`No tengo registrado el estado con ID [${defaultStateId}]. Por favor contacte a su administrador`,
							);
						}
						row[stateField.id] = states[stateIdx].id;
						row[stateField.name] = states[stateIdx].name;
					} else {
						const stateIdx = _.findIndex(
							states,
							state => state.name.toUpperCase() === row[stateField.name],
						);
						if (stateIdx === -1) {
							throw Error(
								`No tengo registrado el estado [${
									row[stateField.name]
								}]. Por favor contacte a su administrador`,
							);
						}
						row[stateField.id] = states[stateIdx].id;
					}

					// Set Service ID
					const defaultServiceId = Number(serviceField.default);
					if (!isNaN(defaultServiceId)) {
						const serviceIdx = _.findIndex(
							services,
							service => service.id === defaultServiceId,
						);
						if (serviceIdx === -1) {
							throw Error(
								`No tengo registrado el servicio con ID [${defaultServiceId}]. Por favor contacte a su administrador`,
							);
						}
						row[serviceField.id] = services[serviceIdx].id;
						row[serviceField.name] = services[serviceIdx].name;
						row.department_id = services[serviceIdx].department_id;
					} else {
						const serviceIdx = _.findIndex(
							services,
							service => service.name.toUpperCase() === row[serviceField.name],
						);
						if (serviceIdx === -1) {
							throw Error(
								`No tengo registrado el servicio [${
									row[serviceField.name]
								}]. Por favor contacte a su administrador`,
							);
						}
						row[serviceField.id] = services[serviceIdx].id;
						row.department_id = services[serviceIdx].department_id;
					}

					// Set Client Categories
					if (clientCategoryField) {
						const defaultClientCategoryId = Number(clientCategoryField.default);
						if (!isNaN(defaultClientCategoryId)) {
							const clientCategoryIdx = _.findIndex(
								clientCategories,
								category => category.id === defaultClientCategoryId,
							);
							if (clientCategoryIdx === -1) {
								throw Error(
									`No tengo registrada la categoría de cliente con ID [${defaultClientCategoryId}]. Por favor contacte a su administrador`,
								);
							}
							row[clientCategoryField.id] =
								clientCategories[clientCategoryIdx].id;
							row[clientCategoryField.name] =
								clientCategories[clientCategoryIdx].name;
						} else {
							const clientCategoryIdx = _.findIndex(
								clientCategories,
								category =>
									category.name.toUpperCase() === row[clientCategoryField.name],
							);
							if (clientCategoryIdx === -1) {
								throw Error(
									`No tengo registrada la categoría de cliente [${
										row[clientCategoryField.name]
									}]. Por favor contacte a su administrador`,
								);
							}
							row[clientCategoryField.id] =
								clientCategories[clientCategoryIdx].id;
						}
					}

					return row;
				}) // REMOVE UNKNOWN FIELDS
				// Remove all the fields that are not found in the validator template
				.map(row => {
					for (const field in row) {
						if (field !== 'department_id' && field !== 'dynamicData') {
							const idx = _.findIndex(
								validateFieldsTemplate,
								validField =>
									validField.id === field || validField.name === field,
							);
							if (idx === -1) {
								delete row[field];
							}
						}
					}
					return row;
				})
		);
	}

	switch (algorithm) {
		case 'import_v1':
			return import_v1();
		default:
			throw new Error(
				'El algoritmo que tiene asignado aún no lo tengo configurado. Por favor contacte a su administrador',
			);
	}
}

export function validateDataMapping(
	enterNewOrdersModal,
	zones,
	validateFieldsTemplate,
	algorithm,
	mutate1Object,
	sendToast,
) {
	const { importedFile } = enterNewOrdersModal;

	function import_v1() {
		// REQUIRED FIELDS VALIDATION
		const fieldsNotFound = [];

		// City Field
		const cityFieldIdx = _.findIndex(
			validateFieldsTemplate,
			field => field.id === 'city_id',
		);
		const cityField =
			cityFieldIdx !== -1 && validateFieldsTemplate[cityFieldIdx];
		// Township Field
		const townshipFieldIdx = _.findIndex(
			validateFieldsTemplate,
			field => field.id === 'township_id',
		);
		const townshipField =
			townshipFieldIdx !== -1 && validateFieldsTemplate[townshipFieldIdx];
		// Zone Field
		const zoneFieldIdx = _.findIndex(
			validateFieldsTemplate,
			field => field.id === 'zone_id',
		);
		const zoneField =
			zoneFieldIdx !== -1 && validateFieldsTemplate[zoneFieldIdx];

		if (!cityField) fieldsNotFound.push('city_id');
		if (!townshipField) fieldsNotFound.push('township_id');
		if (!zoneField) fieldsNotFound.push('zone_id');

		if (fieldsNotFound.length > 0) {
			const fieldsRequired = fieldsNotFound.reduce((acc, field) => {
				return acc + `[${field}]`;
			}, '');
			sendToast({
				message: `El template seleccionado no cuenta con los campos requeridos: ${fieldsRequired}. Por favor contacte a su administrador`,
				type: 'warn',
			});
			return;
		}

		// MAPPING DATA VALIDATION
		let mappingValidationOk = true;
		const data = importedFile.data.map(order => {
			order.mappingErrors = 'Ok';

			// VALIDATE ZONE
			// City exists?
			const cityIdx = _.findIndex(
				zones,
				zone => zone.city_name.toUpperCase() === order[cityField.name],
			);
			// Township exists?
			const townshipIdx = _.findIndex(
				zones,
				zone =>
					zone.city_name.toUpperCase() === order[cityField.name] &&
					zone.township_name.toUpperCase() === order[townshipField.name],
			);
			// Zone exists?
			const zoneIdx = _.findIndex(
				zones,
				zone =>
					zone.city_name.toUpperCase() === order[cityField.name] &&
					zone.township_name.toUpperCase() === order[townshipField.name] &&
					zone.zone_name.toUpperCase() === order[zoneField.name],
			);
			// Set city_id
			if (cityIdx === -1) order[cityField.id] = undefined;
			else order[cityField.id] = zones[cityIdx].city_id;
			// Set township_id
			if (townshipIdx === -1) order[townshipField.id] = undefined;
			else order[townshipField.id] = zones[townshipIdx].township_id;
			// Set zone_id
			if (zoneIdx === -1) {
				order[zoneField.id] = undefined;
				if (!order.exists) {
					order.mappingErrors = 'N/A'; // El valor aquí es "N/A" y no <undefined>, porque se necesita ingresar este campo en el filtro para que los datos puedan ser filtrados por éste valor y así sólo se muestren los registros que tuvieron un error. Si se ingresa <undefined> el checkboxGroup daría error
					mappingValidationOk = false;
				}
			} else order[zoneField.id] = zones[zoneIdx].zone_id;

			// VALIDATE ANOTHER FIELDS
			if (!order.exists) {
				for (const field of validateFieldsTemplate) {
					if (field.required && order[field.id] === undefined) {
						order.mappingErrors = 'N/A';
						mappingValidationOk = false;
					}
				}
			}

			return order;
		});

		return { data, mappingValidationOk };
	}

	switch (algorithm) {
		case 'import_v1':
			var { data, mappingValidationOk } = import_v1();
			break;
		default:
			throw new Error(
				'El algoritmo que tiene asignado aún no lo tengo configurado. Por favor contacte a su administrador',
			);
	}

	// Set filters
	const filters = {};
	if (!mappingValidationOk) {
		filters.mappingErrors = {
			checkedList: ['N/A'],
			indeterminate: true,
			checkedAll: false,
		};
	}

	mutate1Object('enterNewOrdersModal', {
		mappingValidationOk,
		importedFile: {
			...importedFile,
			data,
		},
		filters,
	});
}

export function resetOrderConflicts(orders) {
	return orders.map(order => {
		order.mappingErrors = 'Ok';
		return order;
	});
}

export function isCanContinueProcessImportOrders(
	orders,
	validateFieldsTemplate,
	userAssignment,
) {
	return (
		// Return all invalid fields (With conflicts). If no record is returned, it means that all the fields are correct
		orders.filter(order => {
			if (
				userAssignment === 'withConflicts' &&
				!order.exists &&
				!order.entity_id
			) {
				return true;
			}
			if (!order.exists) {
				for (const field of validateFieldsTemplate) {
					if (order[field.id] === undefined) return true;
				}
			}
		}).length === 0
	);
}

export function getValidatorFieldsTemplate(templateValidator) {
	if (!templateValidator) return [];

	if (Array.isArray(templateValidator.data_structure_object)) {
		return templateValidator.data_structure_object;
	} else if (
		Array.isArray(templateValidator.data_structure_object?.validateFields)
	) {
		return templateValidator.data_structure_object.validateFields;
	}
	return [];
}

export function getDivisorsFromTemplateDataStructure(template) {
	if (Array.isArray(template?.data_structure_object?.divisors))
		return template.data_structure_object.divisors;
	return [];
}

/* Antes de enviar las ordenes importadas al api para ser procesadas y guardadas,
se llama esta función para limpiar el payload eliminando los campos innecesarios */
export function cleanImportedOrdersToSave(orders, validateFieldsTemplate) {
	return Immutable.List(orders)
		.toJS()
		.map(order => {
			for (const prop in order) {
				if (
					prop !== 'entity_id' &&
					prop !== 'department_id' &&
					prop !== 'dynamicData' &&
					prop !== 'exists' &&
					prop !== 'warranty' &&
					prop !== 'requireNotification' &&
					_.findIndex(validateFieldsTemplate, field => field.id === prop) === -1
				) {
					delete order[prop];
				}
			}
			return order;
		});
}

export const readTxtFile = (file, dataStructureObject) => {
	if (!file || !dataStructureObject)
		throw new Error(
			'No se encontró el archivo o el objeto de estructura de datos',
		);

	return new Promise((resolve, reject) => {
		let actualDivisor = null;
		let actualColumn = 0;

		const getDivisor = () => {
			return actualDivisor;
		};

		const setDivisor = divisor => {
			actualDivisor = divisor;
		};

		const getColumn = () => {
			return actualColumn;
		};

		const setColumn = colNumber => {
			actualColumn = colNumber;
		};

		const getDataItemBySeparator = (subLine, separatorIdx) => {
			const keySlice = subLine.slice(0, separatorIdx).trim().toUpperCase();
			const valueSlice = subLine.slice(separatorIdx + 1).trim();

			// ADD KEY -> ORDER DATA
			if (!keySlice || keySlice === '') return null;
			return {
				key: keySlice,
				value: valueSlice || '',
			};
		};

		const addKeyToAddedList = (key, addedKeys) => {
			if (!Array.isArray(addedKeys)) {
				return [key];
			} else {
				addedKeys.push(key);
				return addedKeys;
			}
		};

		const replaceLine = (line, stringToReplace) => {
			return line.replace(stringToReplace, '').trim();
		};

		const getKeyInLineIdx = (line, key) => {
			return line.toLowerCase().indexOf(key.toLowerCase());
		};

		const canAddItemFromLine = (key, addedKeys) => {
			return !Array.isArray(addedKeys) || addedKeys.indexOf(key) === -1;
		};

		const getFoundInLine = (
			line,
			{
				keys = [],
				columns = [],
				discardColumns = [],
				discardKeys = [],
				separator = '',
			} = {},
		) => {
			return !![...keys, ...columns, ...discardColumns, ...discardKeys].find(
				key =>
					line.toLowerCase().indexOf(`${key}${separator}`.toLowerCase()) !== -1,
			);
		};

		const normalizeText = text => {
			if (
				!Array.isArray(dataStructureObject?.normalize?.replace) ||
				dataStructureObject.normalize.replace.length === 0
			) {
				return text;
			}

			return dataStructureObject.normalize.replace
				.reduce((acc, replaceObj) => {
					return acc.replaceAll(replaceObj.oldStr, replaceObj.newStr);
				}, text)
				.trim();
		};

		const getSplittedLine = (text, isNormalizeCol) => {
			// TODO: Aun no se quitará esta porción de código comentada, hasta que se compruebe que el código fue
			// refactorizado exitosamente. LUNES 4/1/2021
			// FECHA LIMITE DE REVISION: 1/2/2021

			// let newText = text.replaceAll("  ", "&&");

			// if (!!replaceAllSpaces) {
			//   newText = newText.replaceAll(" ", "&&");
			// }

			// return newText
			//   .replaceAll("&&", "\n")
			//   .split("\n")
			//   .map((ln) => ln.trim())
			//   .filter((ln) => ln !== "" && ln !== "@");

			const replace =
				dataStructureObject?.normalize?.splittedLineSettings?.replace;
			const splitChar =
				dataStructureObject?.normalize?.splittedLineSettings?.splitChar || '\n';
			const filterLines =
				dataStructureObject?.normalize?.splittedLineSettings?.filterLines || [];
			let newText = text;

			if (
				isNormalizeCol &&
				Array.isArray(getDivisor()?.normalizeColumn?.replace) &&
				getDivisor().normalizeColumn.replace.length > 0
			) {
				newText = getDivisor().normalizeColumn.replace.reduce(
					(acc, obj) => acc.replaceAll(obj.oldStr, obj.newStr),
					newText,
				);
			}

			if (Array.isArray(replace) && replace.length > 0) {
				newText = replace.reduce(
					(acc, obj) => acc.replaceAll(obj.oldStr, obj.newStr),
					newText,
				);
			}

			return newText
				.split(splitChar)
				.map(ln => ln.trim())
				.filter(ln => filterLines.indexOf(ln) === -1);
		};

		const getSplittedOrders = textFormatted => {
			return (
				textFormatted
					// Splitting
					.split(dataStructureObject?.orderLimiter)
					// Clear unnecessary data
					.filter(
						order =>
							order
								.toLowerCase()
								.indexOf(dataStructureObject?.orderIdentifier) !== -1,
					)
			);
		};

		const getOrders = splittedOrders => {
			const setDivisorLineSuccess = (line, accOrderData) => {
				const divisor =
					Array.isArray(dataStructureObject?.divisors) &&
					dataStructureObject.divisors.find(
						div => line.indexOf(div.text) !== -1,
					);

				if (divisor) {
					setDivisor({ ...divisor });
					setColumn(0);

					accOrderData.push({
						key: divisor.id,
						value: divisor.text,
					});
				}

				return divisor;
			};

			const getLines = (order, divisors) => {
				const newOrder = divisors.reduce((acc, divisor) => {
					return acc.replaceAll(divisor.text, `${divisor.text}\n`);
				}, order);

				return newOrder
					.split('\n')
					.map(ln => ln.trim())
					.filter(ln => ln !== '' && ln !== '@');
			};

			const getTemplateProps = () => {
				const keys = Array.isArray(getDivisor().keys) ? getDivisor().keys : [];
				const columns = Array.isArray(getDivisor().columns)
					? getDivisor().columns
					: [];
				const discardColumns = Array.isArray(getDivisor().discardColumns)
					? getDivisor().discardColumns
					: [];
				const discardKeys = Array.isArray(getDivisor().discardKeys)
					? getDivisor().discardKeys
					: [];

				return { keys, columns, discardColumns, discardKeys };
			};

			const concatFloatLineToPrevConcept = (keyFoundInLine, accOrderData) => {
				return (
					!keyFoundInLine &&
					Array.isArray(getDivisor().addedKeys) &&
					getDivisor().addedKeys.length > 0 &&
					accOrderData.length > 0
				);
			};

			const runDivisorTypeKeyValue = (line, accOrderData) => {
				const { keys, discardKeys } = getTemplateProps();
				const splittedLine = getSplittedLine(line);

				for (let spLine of splittedLine) {
					// KEY FOUND?
					const keyFoundInLine = getFoundInLine(spLine, {
						keys,
						separator: getDivisor().separator,
					});

					// MUST CONCAT FLOAT VALUE?
					if (concatFloatLineToPrevConcept(keyFoundInLine, accOrderData)) {
						const lastIndex = accOrderData.length - 1;
						const lastAddedKey =
							getDivisor().addedKeys[getDivisor().addedKeys.length - 1];

						accOrderData[lastIndex] = {
							key: lastAddedKey.trim(),
							value: `${accOrderData[lastIndex].value} ${spLine}`.trim(),
						};

						// REPLACE LINE
						spLine = replaceLine(spLine, spLine);
					}

					// LINE INCLUDE A KEYWORD?
					for (const key of keys) {
						const keyInLineIdx = getKeyInLineIdx(spLine, key);

						if (
							keyInLineIdx !== -1 &&
							canAddItemFromLine(key, getDivisor().addedKeys)
						) {
							const subLine = spLine.slice(keyInLineIdx);

							// KEY : VALUE SPLIT
							const separatorIdx = subLine.indexOf(getDivisor().separator);

							if (separatorIdx !== -1) {
								const item = getDataItemBySeparator(subLine, separatorIdx);
								if (item) {
									accOrderData.push(item);

									// PUSH KEY -> ADDEDKEYS
									setDivisor({
										...getDivisor(),
										addedKeys: addKeyToAddedList(key, getDivisor().addedKeys),
									});

									// REPLACE LINE
									spLine = replaceLine(spLine, subLine);
								}
							}
						}
					}
				}

				return accOrderData;
			};

			const runDivisorTypeColumn = (line, accOrderData) => {
				const { columns, keys, discardColumns } = getTemplateProps();

				const runLineIncludeSeparator = () => {
					const splittedLine = getSplittedLine(line);

					for (let spLine of splittedLine) {
						const keyFoundInLine = getFoundInLine(spLine, {
							keys,
							separator: getDivisor().separator,
						});

						// LINE INCLUDE KEYWORD?
						if (keyFoundInLine) {
							for (const key of keys) {
								const keyInLineIdx = getKeyInLineIdx(spLine, key);

								if (
									keyInLineIdx !== -1 &&
									canAddItemFromLine(key, getDivisor().addedKeys)
								) {
									const subLine = spLine.slice(keyInLineIdx);

									// KEY : VALUE SPLIT
									const separatorIdx = subLine.indexOf(getDivisor().separator);

									if (separatorIdx !== -1) {
										const item = getDataItemBySeparator(subLine, separatorIdx);
										if (item) {
											accOrderData.push(item);

											// PUSH KEY -> ADDEDKEYS
											setDivisor({
												...getDivisor(),
												addedKeys: addKeyToAddedList(
													key,
													getDivisor().addedKeys,
												),
											});

											// REPLACE LINE
											spLine = replaceLine(spLine, subLine);
										}
									}
								}
							}
						}
					}
				};

				const runLineIncludeColumnValue = () => {
					const columnFoundInLine = getFoundInLine(line, {
						columns,
					});

					if (!columnFoundInLine) {
						const splittedLine = getSplittedLine(line, true);

						// for (let spLine of splittedLine) {
						for (const spLine of splittedLine) {
							const discardColumnFoundInLine = getFoundInLine(spLine, {
								discardColumns,
							});

							if (!discardColumnFoundInLine) {
								if (
									getColumn() === columns.length ||
									getColumn() === splittedLine.length
								) {
									setColumn(0);
								}

								const key = columns[getColumn()];

								accOrderData.push({ key, value: spLine.trim() });

								// PUSH KEY -> ADDEDKEYS
								setDivisor({
									...getDivisor(),
									addedKeys: addKeyToAddedList(key, getDivisor().addedKeys),
								});

								setColumn(getColumn() + 1);
							}
						}
					}
				};

				const separatorIdx = line.indexOf(getDivisor().separator);

				// LINE INCLUDE SEPARATOR?
				if (separatorIdx !== -1) runLineIncludeSeparator();
				// LINE INCLUDE COLUMN AND NOT SEPARATOR?
				else runLineIncludeColumnValue();

				return accOrderData;
			};

			return splittedOrders.reduce((accOrders, order) => {
				const lines = getLines(order, dataStructureObject?.divisors);

				setDivisor(null);
				setColumn(0);

				const orderData = lines.reduce((accOrderData, line) => {
					// Get Actual Divisor
					// LINE IS DIVISOR?
					if (setDivisorLineSuccess(line, accOrderData)) return accOrderData;
					// LINE IS NOT DIVISOR?
					if (getDivisor()) {
						// DIVISOR TYPE: KEY:VALUE
						if (getDivisor().type === 'key:value') {
							return runDivisorTypeKeyValue(line, accOrderData);
						}
						// DIVISOR TYPE: COLUMN
						else if (getDivisor().type === 'column') {
							return runDivisorTypeColumn(line, accOrderData);
						}
					}
					return accOrderData;
				}, []);

				accOrders.push(orderData);

				return accOrders;
			}, []);
		};

		const formatOrders = orders => {
			return orders.reduce((acc, order) => {
				const row = {};
				order.forEach(col => {
					if (col.key) {
						if (!row[col.key]) {
							row[col.key] = col.value;
						} else {
							row[col.key] = row[col.key].concat('\n').concat(col.value);
						}
					}
				});
				acc.push(row);
				return acc;
			}, []);
		};

		const reader = new FileReader();
		reader.onload = e => {
			const text = e.target.result;
			// Normalize Data
			const textFormatted = normalizeText(text);
			// Get Splitted orders
			const splittedOrders = getSplittedOrders(textFormatted);
			// Get Orders
			const orders = getOrders(splittedOrders);
			// Format Orders
			const ordersFormatted = formatOrders(orders);

			resolve({
				fileName: file.name,
				jsonFile: Immutable.List(ordersFormatted).toJS(),
			});
		};

		reader.onerror = err => {
			reject(err);
		};

		reader.readAsText(file);
	});
};

export const addOrRemoveFilteredArrayItem = (itemId, filteredArrayIds) => {
	const result = Immutable.List(filteredArrayIds).toJS();

	// Not exists:
	if (result.indexOf(itemId) === -1) {
		result.push(itemId);
		return result;
	}
	// Exists:
	else {
		return result.filter(itemValue => itemValue !== itemId);
	}
};

export const getProject = (projectId, projects) => {
	return projects.find(project => project.id === projectId);
};
