// Libs
import { Drawer } from 'antd';
import styled, { css } from 'styled-components';
// hooks
import { useTheme } from 'hooks';

const DrawerStyled = styled(Drawer)`
	.ant-drawer-content-wrapper {
		width: ${({ $maxWidth, theme }) =>
			$maxWidth || theme.drawer.maxWidth} !important;
	}
	${({ $maxWidth, placement }) =>
		$maxWidth &&
		placement === 'left' &&
		css`
			.ant-drawer-mask {
				width: ${$maxWidth} !important;
			}
		`}
	.ant-drawer-title {
		font-weight: ${props =>
			props.header_font_weight
				? props.header_font_weight
				: props.theme.drawer.header.fontWeight};
		color: ${props =>
			props.headerColor ? props.headerColor : props.theme.drawer.header.color};
	}
	.ant-drawer-header {
		width: ${props => (props.width ? props.width : props.theme.drawer.width)};
		background-color: ${props =>
			props.headerBackgroundColor
				? props.headerBackgroundColor
				: props.theme.drawer.header.backgroundColor};
		backdrop-filter: ${({ backdropFilter }) => backdropFilter ?? ''};
		box-shadow: ${props =>
			props.boxShadow ? props.boxShadow : props.theme.drawer.header.boxShadow};
		z-index: ${props =>
			props.zIndex ? props.zIndex : props.theme.drawer.header.zIndex};
		padding: 0;
	}
	.ant-drawer-content {
		background-color: ${({ backgroundColor, $newTheme }) =>
			backgroundColor ? backgroundColor : $newTheme._primaryBackgroundColor};
		backdrop-filter: ${({ backdropFilter }) => backdropFilter ?? ''};
		border: ${props =>
			props.content_border
				? props.content_border
				: props.theme.drawer.content.border};
	}
	.ant-drawer-body {
		padding: ${props =>
			props.padding ? props.padding : props.theme.drawer.padding};
		overflow: ${props => (props.$noOverflow ? 'hidden' : 'auto')};
	}
	${props =>
		props.$noOverflow &&
		css`
			.ant-drawer-wrapper-body {
				overflow: hidden;
			}
		`}
`;

export default props => {
	const { theme } = useTheme();
	return <DrawerStyled $newTheme={theme} {...props} />;
};
