// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mediumUserIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='5'
			y='5'
			width='22'
			height='22'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.9999 5.33337C13.0533 5.33337 10.6666 7.72004 10.6666 10.6667C10.6666 13.6134 13.0533 16 15.9999 16C18.9466 16 21.3333 13.6134 21.3333 10.6667C21.3333 7.72004 18.9466 5.33337 15.9999 5.33337ZM18.7999 10.6667C18.7999 9.12004 17.5466 7.86671 15.9999 7.86671C14.4533 7.86671 13.1999 9.12004 13.1999 10.6667C13.1999 12.2134 14.4533 13.4667 15.9999 13.4667C17.5466 13.4667 18.7999 12.2134 18.7999 10.6667ZM24.1333 22.6667C24.1333 21.8134 19.9599 19.8667 15.9999 19.8667C12.0399 19.8667 7.86659 21.8134 7.86659 22.6667V24.1334H24.1333V22.6667ZM5.33325 22.6667C5.33325 19.12 12.4399 17.3334 15.9999 17.3334C19.5599 17.3334 26.6666 19.12 26.6666 22.6667V26.6667H5.33325V22.6667Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mediumUserIcon)'>
			<rect width='32' height='32' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
