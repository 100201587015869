import DataFieldManagerUtils from 'components/DataFieldManager/DataFieldManagerUtils';

const AssignedTechIdRowData = metadata => {
	return {
		zoneId: metadata.zoneId,
		serviceId: metadata.serviceId,
		centralId: metadata.centralId,
		assignedTechId: metadata.assignedTechId,
		assignedTechName: metadata.assignedTechName,
	};
};

const EventIdRowData = metadata => {
	return {
		odtId: metadata.odtId,
		eventId: metadata.eventId,
		eventName: metadata.eventName,
	};
};

const WarrantyRowData = metadata => {
	return {
		isBilled: metadata.eventProps?.billed,
	};
};

export default class BuildDependencyRowData {
	constructor(field, metadata) {
		this.field = field;
		this.metadata = metadata;
	}

	_getBuilder() {
		const { ASSIGNED_TECH_ID, EVENT_ID, IS_WARRANTY } =
			DataFieldManagerUtils.getFieldIds();

		return {
			[ASSIGNED_TECH_ID]: AssignedTechIdRowData,
			[EVENT_ID]: EventIdRowData,
			[IS_WARRANTY]: WarrantyRowData,
		}[this.field.id];
	}

	run() {
		const builder = this._getBuilder();
		const customConfig = builder ? builder(this.metadata) : {};

		return {
			[this.field.id]: this.field.uiValue || this.field.value,
			...customConfig,
			id: this.metadata.orderId,
		};
	}
}
