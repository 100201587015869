// Libs
import React from 'react';
import styled, { css } from 'styled-components';
import useTheme from 'hooks/useTheme';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';

const IconContainer = styled.div`
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	padding: 3px;
	${({ activable }) =>
		activable &&
		css`
      background-color: ${({ $newTheme }) =>
				$newTheme._surfaceBackgroundColor}};
    `};
	${({ hoverable }) =>
		hoverable &&
		css`
      &:hover{
        background-color: ${({ $newTheme }) =>
					$newTheme._surfaceBackgroundColor}};
      };
    `};
`;

const FilterIcon = ({
	activable,
	hoverable,
	button,
	onClick,
	onMouseEnter,
	onMouseLeave,
}) => {
	const { theme } = useTheme();
	return button ? (
		<IconButton
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<Icon fill={theme._mediumEmphasisColor} />
		</IconButton>
	) : (
		<IconContainer
			activable={activable}
			hoverable={hoverable}
			$newTheme={theme}
		>
			<Icon
				fill={theme._mediumEmphasisColor}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			/>
		</IconContainer>
	);
};

export default FilterIcon;
