// Libs
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import _ from 'underscore';
// Utils
import GENERAL from 'utils/constants/general';
import { getValidatorFieldsTemplate } from '../utils';
// Actions
import { mutate1Object } from '../actions';
// Selectors
import { selectEnterNewOrdersModal } from '../selectors';
import { selectTemplates, selectUsers, selectZones } from 'store/api/selectors';
import { selectModeView } from 'screens/Dashboard/selectors';
import { selectProfile } from 'screens/Login/selectors';
// Components
import { Wrapper } from 'components';
import ModalOrders from 'screens/Orders/Modals';
// Views
import ConflictsImportingDesktop from './Desktop';
import ConflictsImportingMobile from './Mobile';

const { ENV } = GENERAL;

function ConflictsImporting(props) {
	const { modeView, templates, enterNewOrdersModal, profile } = props;
	const { validationTemplateId, mappingValidationOk, userAssignment } =
		enterNewOrdersModal;

	const templateValidator = React.useMemo(
		() =>
			validationTemplateId &&
			templates.data.find(template => template.id === validationTemplateId),
		[validationTemplateId, templates.data],
	);
	const validateFieldsTemplate = getValidatorFieldsTemplate(templateValidator);
	const fieldsTemplateValidator = {};

	// VALIDATE REQUIRED FIELDS
	// ODT field
	const odtFieldIdx = _.findIndex(
		validateFieldsTemplate,
		field => field.id === 'order_odt_id',
	);
	fieldsTemplateValidator.odtField =
		odtFieldIdx !== -1 && validateFieldsTemplate[odtFieldIdx];

	switch (profile?.organization?.algorithms?.orders?.import) {
		case 'import_v1':
			// State Field
			const stateFieldIdx = _.findIndex(
				validateFieldsTemplate,
				field => field.id === 'order_state_id',
			);
			fieldsTemplateValidator.stateField =
				stateFieldIdx !== -1 && validateFieldsTemplate[stateFieldIdx];
			// Service Field
			const serviceFieldIdx = _.findIndex(
				validateFieldsTemplate,
				field => field.id === 'order_service_id',
			);
			fieldsTemplateValidator.serviceField =
				serviceFieldIdx !== -1 && validateFieldsTemplate[serviceFieldIdx];
			// Assigned Tech Field
			const assignedTechFieldIdx = _.findIndex(
				validateFieldsTemplate,
				field => field.id === 'assigned_tech_id',
			);
			fieldsTemplateValidator.assignedTechField =
				assignedTechFieldIdx !== -1 &&
				validateFieldsTemplate[assignedTechFieldIdx];
			// City Field
			const cityFieldIdx = _.findIndex(
				validateFieldsTemplate,
				field => field.id === 'city_id',
			);
			fieldsTemplateValidator.cityField =
				cityFieldIdx !== -1 && validateFieldsTemplate[cityFieldIdx];
			// Township Field
			const townshipFieldIdx = _.findIndex(
				validateFieldsTemplate,
				field => field.id === 'township_id',
			);
			fieldsTemplateValidator.townshipField =
				townshipFieldIdx !== -1 && validateFieldsTemplate[townshipFieldIdx];
			// Zone Field
			const zoneFieldIdx = _.findIndex(
				validateFieldsTemplate,
				field => field.id === 'zone_id',
			);
			fieldsTemplateValidator.zoneField =
				zoneFieldIdx !== -1 && validateFieldsTemplate[zoneFieldIdx];
			break;
	}

	if (
		mappingValidationOk === undefined &&
		userAssignment !== 'withConflicts' &&
		userAssignment !== 'withoutConflicts'
	) {
		return <Redirect to={ENV.ROUTES.PATHS.ORDERS} />;
	}

	return (
		<Wrapper padding='0' className='animated fadeIn'>
			{modeView === 'table' ? (
				<ConflictsImportingDesktop
					{...props}
					fieldsTemplateValidator={fieldsTemplateValidator}
				/>
			) : (
				modeView === 'cards' && (
					<ConflictsImportingMobile
						{...props}
						fieldsTemplateValidator={fieldsTemplateValidator}
					/>
				)
			)}

			<ModalOrders />
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	modeView: selectModeView(state),
	templates: selectTemplates(state),
	zones: selectZones(state),
	enterNewOrdersModal: selectEnterNewOrdersModal(state),
	profile: selectProfile(state),
	users: selectUsers(state),
});
const actions = {
	mutate1Object,
};

export default connect(mapStateToProps, actions)(ConflictsImporting);
