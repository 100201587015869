// Libs
import React from 'react';
// Hooks
import { useTheme } from 'hooks';
// Styles
import {
	TableResponsive,
	TableHeader,
	TableBody,
	TableRow,
} from './StyleElements';

const SimpleTable = ({ data, config = {} }) => {
	const { values } = config;
	const { theme } = useTheme();
	return (
		<>
			<br />
			<TableResponsive>
				<TableHeader>
					<TableRow size={data.length}>
						<div
							style={{
								textTransform: 'uppercase',
							}}
						>
							Categoría
						</div>
						{data.map((dt, index) => (
							<div key={index}>{dt.name}</div>
						))}
					</TableRow>
				</TableHeader>
				<TableBody size={values.length}>
					{values.map((vl, ind) => (
						<TableRow key={ind} size={data.length}>
							<div
								style={{
									color: theme._primaryColor,
									textTransform: 'uppercase',
								}}
							>
								{vl}
							</div>
							{data.map((dt, i) => (
								<div
									style={{
										color: theme._primaryColor,
									}}
									key={i}
								>
									{dt[vl]}
								</div>
							))}
						</TableRow>
					))}
				</TableBody>
			</TableResponsive>
		</>
	);
};

export default SimpleTable;
