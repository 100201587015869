// Libs
import { takeLatest, put, call, all, select, delay } from 'redux-saga/effects';
import axios from 'axios';
import _ from 'underscore';
// Utils
import { selectSelectedProjectId } from 'core/selectors';
import AuthService from 'utils/libs/auth/AuthService';
import GENERAL from 'utils/constants/general';
import API from 'utils/constants/api';
import asyncErrorsHandler from 'store/asyncErrorsHandler';
import APPCONFIG from 'components/AppConfig.json';
import {
	formatData,
	convertTransactionDocsDates,
	convertStockDates,
	formatArticlesData,
	convertUsersDates,
} from 'utils/libs/dateFormats';
import { setFifoFlag } from 'screens/Warehouses/utils';
import { getCostSale } from 'screens/Billings/utils';
import { idbHandler, base64Encode } from 'utils/libs';

const { APIDATA, DASHBOARD, BILLINGS, WAREHOUSES, ORDERS, ENV } = GENERAL;
const auth = new AuthService();
const getProfile = state => state.login.profile;
const getBillings = state => state.billings;
const getPayments = state => state.api.payments;
const getStockSeries = state => state.api.stock.series;
const transactionsData = state => state.api.transactions.data;
const getTransactionsModal = state => state.warehouses.getTransactionsModal;
const getSerializedInTransaction = state =>
	state.warehouses.createTransaction.serialized;
const getOrderIdFromInformationModal = state =>
	state.orders.getOrderInformationModal.order_id;
const getOrdersQueryModal = state => state.orders.getOrdersQueryModal;

/** **** WATCHER SAGAS *******/

// USERS
function* getRolesWatcher() {
	yield takeLatest(APIDATA.GET_ROLES, getRolesWorker);
}

function* getPermissionsWatcher() {
	yield takeLatest(APIDATA.GET_PERMISSIONS, getPermissionsWorker);
}

function* getOrganizationUsersWatcher() {
	yield takeLatest(APIDATA.GET_ORGANIZATION_USERS, getOrganizationUsersWorker);
}

// ORDERS
function* getDepartmentsWatcher() {
	yield takeLatest(APIDATA.GET_DEPARTMENTS, getDepartmentsWorker);
}

function* getServicesWatcher() {
	yield takeLatest(APIDATA.GET_SERVICES, getServicesWorker);
}

function* getClientsWithQueryVariantWatcher() {
	yield takeLatest(
		APIDATA.GET_CLIENTS_WITH_QUERY_VARIANT,
		getClientsWithQueryVariantWorker,
	);
}
function* getClientCategoriesWatcher() {
	yield takeLatest(APIDATA.GET_CLIENT_CATEGORIES, getClientCategoriesWorker);
}

function* getContractsByClientIdWatcher() {
	yield takeLatest(
		APIDATA.GET_CONTRACTS_BY_CLIENT_ID,
		getContractsByClientIdWorker,
	);
}

function* getContractTypesWatcher() {
	yield takeLatest(APIDATA.GET_CONTRACT_TYPES, getContractTypesWorker);
}

function* getFrequenciesWatcher() {
	yield takeLatest(APIDATA.GET_FREQUENCIES, getFrequenciesWorker);
}

function* getOrderStatesWatcher() {
	yield takeLatest(APIDATA.GET_ORDER_STATES, getOrderStatesWorker);
}

function* getOrderEventsWatcher() {
	yield takeLatest(APIDATA.GET_ORDER_EVENTS, getOrderEventsWorker);
}

// BILLINGS
function* getCoinsWatcher() {
	yield takeLatest(APIDATA.GET_COINS, getCoinsWorker);
}

function* getPaycutsWatcher() {
	yield takeLatest(APIDATA.GET_PAYCUTS, getPaycutsWorker);
}

function* getPaymentsWatcher() {
	yield takeLatest(APIDATA.GET_PAYMENTS, getPaymentsWorker);
}

function* getInvoicePaysWatcher() {
	yield takeLatest(APIDATA.GET_INVOICE_PAYS, getInvoicePaysWorker);
}

function* getPaymentResumeWatcher() {
	yield takeLatest(APIDATA.GET_PAYMENT_RESUME, getPaymentResumeWorker);
}

function* getPaycutResumeWatcher() {
	yield takeLatest(APIDATA.GET_PAYCUT_RESUME, getPaycutResumeWorker);
}

function* getPaymentLinesWatcher() {
	yield takeLatest(APIDATA.GET_PAYMENTS_LINES, getPaymentLinesWorker);
}

function* getPaymentOrdersWatcher() {
	yield takeLatest(APIDATA.GET_PAYMENTS_ORDERS, getPaymentOrdersWorker);
}

function* getGainsEntityWatcher() {
	yield takeLatest(APIDATA.GET_GAINS_ENTITY, getGainsEntityWorker);
}

function* getLineItemsWatcher() {
	yield takeLatest(APIDATA.GET_LINE_ITEMS, getLineItemsWorker);
}

// WAREHOUSES
function* getTransactionTypesWatcher() {
	yield takeLatest(APIDATA.GET_TRANSACTION_TYPES, getTransactionTypesWorker);
}

function* getIncomeMethodsWatcher() {
	yield takeLatest(APIDATA.GET_INCOME_METHODS, getIncomeMethodsWorker);
}

function* getMeasuredUnitsWatcher() {
	yield takeLatest(APIDATA.GET_MEASURED_UNITS, getMeasuredUnitsWorker);
}

function* getItemStatesWatcher() {
	yield takeLatest(APIDATA.GET_ITEM_STATES, getItemStatesWorker);
}

function* getPackagesWatcher() {
	yield takeLatest(APIDATA.GET_WAREHOUSE_PACKAGES, getPackagesWorker);
}

function* getZonesAreasWatcher() {
	yield takeLatest(APIDATA.GET_WAREHOUSE_ZONES_AREAS, getZonesAreasWorker);
}

function* getItemFamiliesWatcher() {
	yield takeLatest(APIDATA.GET_ITEM_FAMILIES, getItemFamiliesWorker);
}

function* getArticlesWatcher() {
	yield takeLatest(APIDATA.GET_ARTICLES, getArticlesWorker);
}

function* getWarehousesWatcher() {
	yield takeLatest(APIDATA.GET_WAREHOUSES, getWarehousesWorker);
}

function* getStockArticlesWatcher() {
	yield takeLatest(APIDATA.GET_STOCK_ARTICLES, getStockArticlesWorker);
}

function* getStockSeriesWatcher() {
	yield takeLatest(APIDATA.GET_STOCK_SERIES, getStockSeriesWorker);
}
function* getControlSeriesWatcher() {
	yield takeLatest(APIDATA.GET_CONTROL_SERIES, getControlSeriesWorker);
}

function* getTransactionDocsWatcher() {
	yield takeLatest(APIDATA.GET_TRANSACTION_DOCS, getTransactionDocsWorker);
}

function* getInventoryToTransferWatcher() {
	yield takeLatest(
		APIDATA.GET_INVENTORY_TO_TRANSFER,
		getInventoryToTransferWorker,
	);
}

function* getItemsTransactionDocsWatcher() {
	yield takeLatest(
		APIDATA.GET_ITEMS_TRANSACTION_DOCS,
		getItemsTransactionDocsWorker,
	);
}

function* getSerieHistoryWatcher() {
	yield takeLatest(APIDATA.GET_SERIE_HISTORY, getSerieHistoryWorker);
}

function* updateWTDSignerWatcher() {
	yield takeLatest(APIDATA.UPDATE_WTD_SIGNER, updateWTDSignerWorker);
}

function* updatePaymentIdLineWatcher() {
	yield takeLatest(APIDATA.UPDATE_PAYMENT_ID_LINE, updatePaymentIdLineWorker);
}

function* signWTDWatcher() {
	yield takeLatest(APIDATA.SIGN_WTD, signWTDWorker);
}

// ZONES
function* getZonesToAssignOrdersWatcher() {
	yield takeLatest(
		APIDATA.GET_ZONES_TO_ASSIGN_ORDERS,
		getZonesToAssignOrdersWorker,
	);
}

// TEMPLATES
function* getTemplatesWatcher() {
	yield takeLatest(APIDATA.GET_TEMPLATES, getTemplatesWorker);
}

/** **** WORKER SAGAS *******/

// USERS
function* getRolesWorker() {
	try {
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/users/roles'),
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.MUTATE_DIRECT_PROPS,
			payload: { roles: response.data },
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getRolesWorker();
	}
}

function* getPermissionsWorker() {
	try {
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/users/permissions'),
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.MUTATE_DIRECT_PROPS,
			payload: { permissions: response.data },
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getPermissionsWorker();
	}
}

function* getOrganizationUsersWorker() {
	const profile = yield select(getProfile);

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: { obj1Name: 'users', keyValuePairs: { loading: true } },
		});
		const response = yield call(
			axios.get,
			`${API.DOMAIN.concat(`/users/getOrganizationUsers`)}`,
			auth.sendToken(),
		);
		const data = response.data.map(row =>
			convertUsersDates(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: { obj1Name: 'users', keyValuePairs: { data, loading: false } },
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getOrganizationUsersWorker();
	}
}

// ORDERS
function* getDepartmentsWorker() {
	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'departments',
				keyValuePairs: { loading: true },
			},
		});
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat('/departments'),
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'departments',
				keyValuePairs: { loading: false, data },
			},
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getDepartmentsWorker();
	}
}

function* getServicesWorker(action) {
	const department_id = action.payload;

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'services',
				keyValuePairs: {
					loading: true,
				},
			},
		});
		const { data } = yield call(
			axios.post,
			API.DOMAIN.concat(`/services`),
			{ department_id },
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'services',
				keyValuePairs: {
					loading: false,
					data,
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'services',
						keyValuePairs: {
							loading: false,
						},
					},
				});
			},
			function* () {
				if (err?.response?.status >= 500) yield getServicesWorker(action);
			},
		);
	}
}

// CLIENTS
function* getClientsWithQueryVariantWorker(action) {
	const queryVariant = action.payload;

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'clients',
				keyValuePairs: {
					loading: true,
				},
			},
		});

		const { data } = yield call(
			axios.post,
			API.DOMAIN.concat(`/clients/v1/getWithQueryVariant`),
			queryVariant,
			auth.sendToken(),
		);

		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'clients',
				keyValuePairs: {
					loading: false,
					data,
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'clients',
						keyValuePairs: {
							loading: false,
						},
					},
				});
			},
			function* () {
				yield getClientsWithQueryVariantWorker(action);
			},
		);
	}
}

// CLIENT CATEGORIES
function* getClientCategoriesWorker(action) {
	const { serviceId, departmentId } = action.payload;

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'clientCategories',
				keyValuePairs: {
					loading: true,
				},
			},
		});

		const { data } = yield call(
			axios.post,
			API.DOMAIN.concat(`/client_categories`),
			{ serviceId, departmentId },
			auth.sendToken(),
		);

		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'clientCategories',
				keyValuePairs: {
					loading: false,
					data,
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getClientCategoriesWorker(action);
		});
	}
}

function* getContractsByClientIdWorker(action) {
	const { clientId, departmentId } = action.payload;

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'contracts',
				keyValuePairs: {
					loading: true,
				},
			},
		});

		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat(`/contracts/v1/${clientId}/${departmentId}`),
			auth.sendToken(),
		);

		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'contracts',
				keyValuePairs: {
					loading: false,
					data,
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'contracts',
						keyValuePairs: {
							loading: false,
						},
					},
				});
			},
			function* () {
				yield getContractsByClientIdWorker(action);
			},
		);
	}
}

function* getContractTypesWorker(action) {
	const { departmentId } = action.payload;

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'contractTypes',
				keyValuePairs: {
					loading: true,
				},
			},
		});

		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat(`/contract_types/${departmentId}`),
			auth.sendToken(),
		);

		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'contractTypes',
				keyValuePairs: {
					loading: false,
					data,
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'contractTypes',
						keyValuePairs: {
							loading: false,
						},
					},
				});
			},
			function* () {
				yield getContractTypesWorker(action);
			},
		);
	}
}

function* getFrequenciesWorker(action) {
	// const departmentId = action.payload;
	// try {
	//   put({
	//     type: APIDATA.MUTATE_1OBJECT,
	//     payload: {
	//       obj1Name: "contracts",
	//       keyValuePairs: {
	//         frequencies: {
	//           loading: true,
	//           data: [],
	//         },
	//       },
	//     },
	//   });
	//   const { data } = yield call(
	//     axios.post,
	//     API.DOMAIN.concat(`/frequencies`),
	//     { departmentId },
	//     auth.sendToken()
	//   );
	//   yield put({
	//     type: APIDATA.MUTATE_1OBJECT,
	//     payload: {
	//       obj1Name: "contracts",
	//       keyValuePairs: {
	//         frequencies: {
	//           loading: false,
	//           data,
	//         },
	//       },
	//     },
	//   });
	// } catch (err) {
	//   yield asyncErrorsHandler(err, undefined, function* () {
	//     yield getFrequenciesWorker(action);
	//   });
	// }
}

function* getOrderStatesWorker() {
	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'states',
				keyValuePairs: {
					loading: true,
				},
			},
		});
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat('/order_states'),
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'states',
				keyValuePairs: {
					loading: false,
					data,
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getOrderStatesWorker();
		});
	}
}

function* getOrderEventsWorker() {
	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'events',
				keyValuePairs: {
					loading: true,
				},
			},
		});
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat('/order_events'),
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'events',
				keyValuePairs: {
					loading: false,
					data,
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'events',
						keyValuePairs: {
							loading: false,
						},
					},
				});
			},
			function* () {
				yield getOrderEventsWorker();
			},
		);
	}
}

// BILLINGS
function* getCoinsWorker() {
	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: { obj1Name: 'coins', keyValuePairs: { loading: true } },
		});
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat('/coins'),
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: { obj1Name: 'coins', keyValuePairs: { loading: false, data } },
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getCoinsWorker();
	}
}

function* getPaycutsWorker(action) {
	const { type, last_row_id, limit } = action.payload;
	const profile = yield select(getProfile);
	const payments = yield select(getPayments);

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { paycuts: { ...payments.paycuts, loading: true } },
			},
		});
		const response = yield call(
			axios.post,
			API.DOMAIN.concat(`/paycuts/getPaycuts`),
			{ type, last_row_id, limit },
			auth.sendToken(),
		);
		const data = response.data
			.map(row =>
				formatData(
					row,
					profile.organization.tz,
					profile.user.settings.date_format,
				),
			)
			.map(row => {
				const { cost, sale } = getCostSale(
					row.production_amount,
					row.production_charge,
				);
				row.cost = cost;
				row.sale = sale;
				return row;
			});
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { paycuts: { data, loading: false } },
			},
		});
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'paycuts',
				keyValuePairs: { paycutId: undefined },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'payments',
						keyValuePairs: {
							paycuts: { ...payments.paycuts, loading: false },
						},
					},
				});
			},
			function* () {
				yield getPaycutsWorker(action);
			},
		);
	}
}

function* getPaymentsWorker(action) {
	const paycutIds = action.payload;
	const profile = yield select(getProfile);
	const payments = yield select(getPayments);
	const billingsState = yield select(getBillings);

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { pays: { ...payments.pays, loading: true } },
			},
		});
		const response = yield call(
			axios.post,
			API.DOMAIN.concat(`/payments/getPayments`),
			{ paycutIds },
			auth.sendToken(),
		);
		const data = response.data
			.map(row =>
				formatData(
					row,
					profile.organization.tz,
					profile.user.settings.date_format,
					{ isPayments: true },
				),
			)
			.map(row => {
				const { cost, sale } = getCostSale(
					row.production_amount,
					row.production_charge,
				);
				row.cost = cost;
				row.sale = sale;
				return row;
			});
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { pays: { data, loading: false } },
			},
		});
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'pays',
				keyValuePairs: {
					selected: { ...billingsState.pays.selected, items: [] },
					getOrders: undefined,
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'payments',
						keyValuePairs: { pays: { ...payments.pays, loading: false } },
					},
				});
			},
			function* () {
				yield getPaymentsWorker(action);
			},
		);
	}
}

function* getInvoicePaysWorker(action) {
	const paycutIds = action.payload;
	const profile = yield select(getProfile);
	const payments = yield select(getPayments);
	const billingsState = yield select(getBillings);

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { pays: { ...payments.pays, loading: true } },
			},
		});
		const response = yield call(
			axios.post,
			API.DOMAIN.concat(`/payments/invoices`),
			{ paycutIds },
			auth.sendToken(),
		);
		const data = response.data.map(row =>
			formatData(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
				{ isPayments: true },
			),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { pays: { data, loading: false } },
			},
		});
		yield put({
			type: BILLINGS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'pays',
				keyValuePairs: {
					selected: { ...billingsState.pays.selected, items: [] },
					getOrders: undefined,
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'payments',
						keyValuePairs: { pays: { data: [], loading: false } },
					},
				});
			},
			function* () {
				yield getInvoicePaysWorker(action);
			},
		);
	}
}

function* getPaymentResumeWorker(action) {
	const payIds = action.payload;
	const profile = yield select(getProfile);

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: {
					resume: { payments: [], orders: [], lines: [], loading: true },
				},
			},
		});
		const response = yield call(
			axios.post,
			API.DOMAIN.concat(`/payments/resume`),
			{ payIds },
			auth.sendToken(),
		);
		const resume = {
			loading: false,
			payments: response.data.payments.map(payment => {
				payment.signed =
					Array.isArray(payment.signed) &&
					payment.signed.map(sign => ({
						...formatData(
							sign,
							profile.organization.tz,
							profile.user.settings.date_format,
						),
						payment_id: payment.id,
					}));
				return payment;
			}),
			orders: response.data.orders.map(row =>
				formatData(
					row,
					profile.organization.tz,
					profile.user.settings.date_format,
				),
			),
			lines: response.data.lines.map(row =>
				formatData(
					row,
					profile.organization.tz,
					profile.user.settings.date_format,
				),
			),
		};
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: { obj1Name: 'payments', keyValuePairs: { resume } },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'payments',
						keyValuePairs: {
							resume: { loading: false, payments: [], orders: [], lines: [] },
						},
					},
				});
			},
			function* () {
				yield getPaymentResumeWorker(action);
			},
		);
	}
}

function* getPaycutResumeWorker(action) {
	const { paycutIds, excludePayrollUsers, isInvoice } = action.payload;
	const profile = yield select(getProfile);

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: {
					resume_paycut: { loading: true, pays: [], paycuts: [] },
				},
			},
		});
		const response = yield call(
			axios.post,
			API.DOMAIN.concat(`/paycuts/resume`),
			{ paycutIds, excludePayrollUsers, isInvoice },
			auth.sendToken(),
		);
		const resume_paycut = {
			loading: false,
			paycuts: response.data.paycuts.map(paycut => {
				paycut.signed =
					Array.isArray(paycut.signed) &&
					paycut.signed.map(sign => ({
						...formatData(
							sign,
							profile.organization.tz,
							profile.user.settings.date_format,
						),
						paycut_id: paycut.id,
					}));
				return paycut;
			}),
			pays:
				response.data.pays &&
				response.data.pays.map(row =>
					formatData(
						row,
						profile.organization.tz,
						profile.user.settings.date_format,
					),
				),
		};
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: { obj1Name: 'payments', keyValuePairs: { resume_paycut } },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'payments',
						keyValuePairs: {
							resume_paycut: { loading: false, pays: [], paycuts: [] },
						},
					},
				});
			},
			function* () {
				yield getPaycutResumeWorker(action);
			},
		);
	}
}

function* getPaymentLinesWorker(action) {
	const { payIds, paidTo } = action.payload;
	const profile = yield select(getProfile);
	const payments = yield select(getPayments);

	try {
		// loading: true
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { lines: { ...payments.lines, loading: true } },
			},
		});
		// Requesting
		const response = yield call(
			axios.post,
			API.DOMAIN.concat('/payments_lines/getLines'),
			{ payIds, paidTo },
			auth.sendToken(),
		);
		// Set response
		const data = response.data.map(row =>
			formatData(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);
		// loading: false
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { lines: { data, loading: false } },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'payments',
						keyValuePairs: { lines: { data: [], loading: false } },
					},
				});
			},
			function* () {
				yield getPaymentLinesWorker(action);
			},
		);
	}
}

function* getPaymentOrdersWorker(action) {
	const { payIds, getOrders } = action.payload;
	const profile = yield select(getProfile);
	const payments = yield select(getPayments);

	try {
		// loading: true
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { orders: { ...payments.orders, loading: true } },
			},
		});
		// Getting Data
		const response = yield call(
			axios.post,
			API.DOMAIN.concat('/payments_orders/getOrders'),
			{ payIds, getOrders },
			auth.sendToken(),
		);
		// Format response
		const data = response.data
			.map(row =>
				formatData(
					row,
					profile.organization.tz,
					profile.user.settings.date_format,
				),
			)
			.map(row => {
				const { cost, sale } = getCostSale(row.amount, row.charge);
				row.cost = cost;
				row.sale = sale;
				return row;
			});
		// loading: false
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { orders: { data, loading: false } },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'payments',
						keyValuePairs: { orders: { data: [], loading: false } },
					},
				});
			},
			function* () {
				yield getPaymentOrdersWorker(action);
			},
		);
	}
}

function* updatePaymentIdLineWorker(action) {
	const { line_id, assign_in, payment_id, paycut_id, paidTo } = action.payload;
	const payments = yield select(getPayments);
	const lines = [...payments.lines.data];

	function setUpdatingPaymentId(value) {
		return lines.map(line => {
			if (line.id === line_id) line.updatingPaymentId = value;
			return line;
		});
	}

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'payments',
				keyValuePairs: { lines: { data: setUpdatingPaymentId(true) } },
			},
		});
		yield call(
			axios.put,
			API.DOMAIN.concat('/payments_lines/line'),
			{ line_id, assign_in, payment_id, paycut_id },
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.GET_PAYMENTS_LINES,
			payload: { payIds: payment_id, paidTo },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'payments',
						keyValuePairs: { lines: { data: setUpdatingPaymentId(false) } },
					},
				});
			},
			function* () {
				yield updatePaymentIdLineWorker(action);
			},
		);
	}
}

function* getGainsEntityWorker() {
	const profile = yield select(getProfile);
	const selectedProjectId = yield select(selectSelectedProjectId);
	const profileProjectIds = profile?.user?.assignment?.projects || [];
	const projectIds = [...profileProjectIds, selectedProjectId];

	try {
		const hashedProjectIds = base64Encode(projectIds);
		const { data: gains } = yield call(
			axios.get,
			API.DOMAIN.concat(`/users/gainsEntity/${hashedProjectIds}`),
			auth.sendToken(),
		);
		yield put({ type: APIDATA.MUTATE_DIRECT_PROPS, payload: { gains } });
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getGainsEntityWorker();
		});
	}
}

function* getLineItemsWorker() {
	const profile = yield select(getProfile);

	try {
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/payments_lines_items'),
			auth.sendToken(),
		);
		const line_items = response.data.map(row =>
			formatData(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);
		yield put({ type: APIDATA.MUTATE_DIRECT_PROPS, payload: { line_items } });
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getLineItemsWorker();
		});
	}
}

// WAREHOUSES
function* getTransactionTypesWorker() {
	try {
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/warehouses_transactions_types'),
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.MUTATE_DIRECT_PROPS,
			payload: { transactionTypes: response.data },
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getTransactionTypesWorker();
	}
}

function* getIncomeMethodsWorker() {
	try {
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/warehouses_inv_income_methods'),
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.MUTATE_DIRECT_PROPS,
			payload: { incomeMethods: response.data },
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getIncomeMethodsWorker();
	}
}

function* getMeasuredUnitsWorker() {
	try {
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/warehouses_inv_measured_units'),
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.MUTATE_DIRECT_PROPS,
			payload: { measuredUnits: response.data },
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getMeasuredUnitsWorker();
	}
}

function* getItemStatesWorker() {
	try {
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/warehouses_items_states'),
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.MUTATE_DIRECT_PROPS,
			payload: { itemStates: response.data },
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getItemStatesWorker();
	}
}

function* getPackagesWorker() {
	const profile = yield select(getProfile);

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'warehousePackages',
				keyValuePairs: {
					loading: true,
				},
			},
		});
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/warehouses_packages'),
			auth.sendToken(),
		);
		const data = response.data.map(row =>
			convertStockDates(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'warehousePackages',
				keyValuePairs: {
					loading: false,
					data,
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'warehousePackages',
						keyValuePairs: {
							loading: false,
						},
					},
				});
			},
			function* () {
				yield getPackagesWorker();
			},
		);
	}
}

function* getZonesAreasWorker() {
	const profile = yield select(getProfile);

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'warehouseZones',
				keyValuePairs: {
					loading: true,
				},
			},
		});
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'warehouseAreas',
				keyValuePairs: {
					loading: true,
				},
			},
		});
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/warehouses/zones-areas'),
			auth.sendToken(),
		);
		const { areas, zones } = response.data;
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'warehouseZones',
				keyValuePairs: {
					loading: false,
					data: zones.map(row =>
						convertStockDates(
							row,
							profile.organization.tz,
							profile.user.settings.date_format,
						),
					),
				},
			},
		});
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'warehouseAreas',
				keyValuePairs: {
					loading: false,
					data: areas.map(row =>
						convertStockDates(
							row,
							profile.organization.tz,
							profile.user.settings.date_format,
						),
					),
				},
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'warehouseZones',
						keyValuePairs: {
							loading: false,
						},
					},
				});
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'warehouseAreas',
						keyValuePairs: {
							loading: false,
						},
					},
				});
			},
			function* () {
				yield getZonesAreasWorker();
			},
		);
	}
}

function* getItemFamiliesWorker() {
	try {
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/item_families'),
			auth.sendToken(),
		);
		yield put({
			type: APIDATA.MUTATE_DIRECT_PROPS,
			payload: { itemFamilies: response.data },
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getItemFamiliesWorker();
	}
}

function* getArticlesWorker() {
	const profile = yield select(getProfile);

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'articles',
				keyValuePairs: { data: [], loading: true },
			},
		});
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/items'),
			auth.sendToken(),
		);
		const data = response.data.map(row =>
			formatArticlesData(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'articles',
				keyValuePairs: { data, loading: false },
			},
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getArticlesWorker();
	}
}

function* getWarehousesWorker() {
	const profile = yield select(getProfile);

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'warehouses',
				keyValuePairs: { data: [], loading: true },
			},
		});
		const response = yield call(
			axios.get,
			API.DOMAIN.concat('/warehouses'),
			auth.sendToken(),
		);
		const data = response.data.map(warehouse =>
			formatData(
				warehouse,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'warehouses',
				keyValuePairs: { data, loading: false },
			},
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getWarehousesWorker();
	}
}

function* getStockArticlesWorker(action) {
	const profile = yield select(getProfile);
	const { warehouseId, ownerId, queryType } = action.payload;

	if (
		queryType !== 'individual' &&
		queryType !== 'consolidate' &&
		queryType !== 'all'
	)
		return;
	const level = queryType
		.replace('individual', 'warehouse')
		.replace('consolidate', 'entityWithUsers')
		.replace('all', 'organization');

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'stock',
				keyValuePairs: { articles: { data: [], loading: true } },
			},
		});
		const ids = Buffer.from(
			JSON.stringify({ level, warehouseId, entityId: ownerId }),
		).toString('base64');
		const response = yield call(
			axios.get,
			API.DOMAIN.concat(`/inventoryManager/v1/stock/${ids}`),
			auth.sendToken(),
		);
		const data = response.data.map(row => {
			row = convertStockDates(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
			);
			row.serialized = row.is_serialized ? 'Si' : 'No';
			return row;
		});
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'stock',
				keyValuePairs: { articles: { data, loading: false } },
			},
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getStockArticlesWorker(action);
	}
}

function* getStockSeriesWorker(action) {
	const profile = yield select(getProfile);
	const { warehouseId, ownerId, queryType, itemId, stateId } = action.payload;

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'stock',
				keyValuePairs: { series: { data: [], loading: true } },
			},
		});
		const response = yield call(
			axios.get,
			API.DOMAIN.concat(
				`/warehouses_items/series/${warehouseId}/${ownerId}/${queryType}/${itemId}/${stateId}`,
			),
			auth.sendToken(),
		);
		const data = response.data.map(row =>
			convertStockDates(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'stock',
				keyValuePairs: { series: { data, loading: false } },
			},
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getStockSeriesWorker(action);
	}
}

function* getControlSeriesWorker(action) {
	const profile = yield select(getProfile);
	const stockSeries = yield select(getStockSeries);
	const { request, pagination } = action.payload;

	try {
		if (pagination) {
			yield put({
				type: APIDATA.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'stock',
					keyValuePairs: { series: { ...stockSeries, loading: true } },
				},
			});
		} else {
			yield put({
				type: APIDATA.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'stock',
					keyValuePairs: { series: { data: [], loading: true } },
				},
			});
		}
		const response = yield call(
			axios.post,
			API.DOMAIN.concat(`/warehouses_items/control-series`),
			request,
			auth.sendToken(),
		);
		let data = response.data.map(row =>
			convertStockDates(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);

		if (request.mode === 'peps') {
			data = setFifoFlag(data);
		}

		if (pagination) {
			yield put({
				type: APIDATA.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'stock',
					keyValuePairs: {
						series: { data: [...stockSeries.data, ...data], loading: false },
					},
				},
			});
		} else {
			yield put({
				type: APIDATA.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'stock',
					keyValuePairs: { series: { data, loading: false } },
				},
			});
		}
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'stock',
						keyValuePairs: { series: { data: [], loading: false } },
					},
				});
				yield put({
					type: WAREHOUSES.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'controlSeries',
						keyValuePairs: { collapseActiveKey: -1 },
					},
				});
			},
			function* () {
				yield getControlSeriesWorker(action);
			},
		);
	}
}

function* getTransactionDocsWorker(action) {
	const profile = yield select(getProfile);
	const { mode, docNumber, fromDate, toDate } = action.payload;

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'transactions',
				keyValuePairs: { data: [], loading: true },
			},
		});
		const response = yield call(
			axios.get,
			API.DOMAIN.concat(
				`/warehouses_transactions_docs/getDocs/${mode}/${docNumber}/${fromDate.format(
					'YYYY-MM-DD',
				)}/${toDate.format('YYYY-MM-DD')}`,
			),
			auth.sendToken(),
		);
		const data = response.data.map(row =>
			convertTransactionDocsDates(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'transactions',
				keyValuePairs: { data, loading: false },
			},
		});
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'getTransactionsModal',
				keyValuePairs: { isOpen: false },
			},
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getTransactionDocsWorker(action);
	}
}

function* getInventoryToTransferWorker(action) {
	const serialized = yield select(getSerializedInTransaction);
	const warehouseId = action.payload;

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'transactions',
				keyValuePairs: { inventory: { data: [], loading: true } },
			},
		});
		const ids = Buffer.from(
			JSON.stringify({ level: 'transferable', warehouseId }),
		).toString('base64');
		const response = yield call(
			axios.get,
			API.DOMAIN.concat(`/inventoryManager/v1/stock/${ids}`),
			auth.sendToken(),
		);
		const data = response.data.map(row => {
			row.serialized = row.is_serialized ? 'Si' : 'No';
			row.allowed_to_use = row.allowed_to_use ? 'Si' : 'No';
			return row;
		});
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'transactions',
				keyValuePairs: { inventory: { data, loading: false } },
			},
		});

		// Verifica si hay alguna serie añadida a la transacción que ya no es parte del inventario disponible y la elimina
		if (serialized.length > 0)
			yield put({
				type: WAREHOUSES.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'createTransaction',
					keyValuePairs: {
						serialized: serialized.filter(serie => {
							const idx = _.findIndex(data, row => row.serie === serie.serie);
							return idx !== -1;
						}),
					},
				},
			});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getInventoryToTransferWorker(action);
	}
}

function* getItemsTransactionDocsWorker(action) {
	const profile = yield select(getProfile);
	const wtdId = action.payload;

	try {
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'itemsTransactionDoc',
				keyValuePairs: { operation: undefined },
			},
		});
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'transactions',
				keyValuePairs: { items: { data: [], loading: true } },
			},
		});
		const response = yield call(
			axios.get,
			API.DOMAIN.concat(`/warehouses_transactions_docs/getItems/${wtdId}`),
			auth.sendToken(),
		);
		const data = response.data.map(row =>
			formatData(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'transactions',
				keyValuePairs: { items: { data, loading: false } },
			},
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getItemsTransactionDocsWorker(action);
	}
}

function* getSerieHistoryWorker(action) {
	const profile = yield select(getProfile);
	const serie = action.payload;

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: { obj1Name: 'serieHistory', keyValuePairs: { loading: true } },
		});
		const response = yield call(
			axios.get,
			API.DOMAIN.concat(
				`/warehouses_transactions_docs_items/v1/history/serie/${serie}`,
			),
			auth.sendToken(),
		);
		const data = response.data.map(row =>
			formatData(
				row,
				profile.organization.tz,
				profile.user.settings.date_format,
			),
		);
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'serieHistory',
				keyValuePairs: { data, loading: false },
			},
		});
	} catch (err) {
		yield delay(APPCONFIG.Times.TimeToReSendRequest);
		yield getSerieHistoryWorker(action);
	}
}

function* updateWTDSignerWorker(action) {
	const { document_id, nextSigner } = action.payload;
	const { mode, docNumber, fromDate, toDate } =
		yield select(getTransactionsModal);
	const data = yield select(transactionsData);
	const transactions = [...data];

	function setUpdatingSigner(value) {
		return transactions.map(transaction => {
			if (transaction.id === document_id) transaction.updatingSigner = value;
			return transaction;
		});
	}

	try {
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'transactions',
				keyValuePairs: { data: setUpdatingSigner(true) },
			},
		});
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: { obj1Name: 'signWTDModal', keyValuePairs: { loading: true } },
		});
		const response = yield call(
			axios.put,
			API.DOMAIN.concat('/warehouses_transactions_docs/reassign'),
			{ document_id, nextSigner },
			auth.sendToken(),
		);
		yield put({
			type: WAREHOUSES.MUTATE_1OBJECT,
			payload: { obj1Name: 'signWTDModal', keyValuePairs: { loading: false } },
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: `Documento [${response.data}] reasignado!`,
				duration: 2,
				type: 'success',
			},
		});
		yield put({
			type: APIDATA.GET_TRANSACTION_DOCS,
			payload: { mode, docNumber, fromDate, toDate },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'transactions',
						keyValuePairs: { data: setUpdatingSigner(false) },
					},
				});
				yield put({
					type: WAREHOUSES.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'signWTDModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield updateWTDSignerWorker(action);
			},
		);
	}
}

function* signWTDWorker(action) {
	const { document_id, nextSigner, complete, operation } = action.payload;

	try {
		if (operation === 'transaction') {
			const { mode, docNumber, fromDate, toDate } =
				yield select(getTransactionsModal);

			yield put({
				type: WAREHOUSES.MUTATE_1OBJECT,
				payload: { obj1Name: 'signWTDModal', keyValuePairs: { loading: true } },
			});
			const response = yield call(
				axios.put,
				API.DOMAIN.concat('/warehouses_transactions_docs/sign'),
				{ document_id, nextSigner, complete },
				auth.sendToken(),
			);
			yield put({
				type: WAREHOUSES.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'signWTDModal',
					keyValuePairs: { loading: false },
				},
			});
			yield put({
				type: DASHBOARD.TOAST_MESSAGE,
				payload: {
					message: `Documento [${response.data}] firmado!`,
					duration: 2,
				},
			});
			yield put({
				type: APIDATA.GET_TRANSACTION_DOCS,
				payload: { mode, docNumber, fromDate, toDate },
			});
		} else if (operation === 'billing') {
			const ordersQueryModal = yield select(getOrdersQueryModal);
			const orderId = yield select(getOrderIdFromInformationModal);

			yield put({
				type: ORDERS.MUTATE_1OBJECT,
				payload: {
					obj1Name: 'getOrderInformationModal',
					keyValuePairs: { loading: true },
				},
			});
			yield call(
				axios.put,
				API.DOMAIN.concat('/warehouses_transactions_docs/sign'),
				{ document_id, nextSigner, complete },
				auth.sendToken(),
			);
			yield put({
				type: DASHBOARD.TOAST_MESSAGE,
				payload: {
					message: `Transferencia completada`,
					duration: 2,
					type: 'success',
				},
			});
			yield put({ type: ORDERS.GET_BILLING_MATERIALS, payload: orderId });
			yield put({ type: ORDERS.GET_CONTROL_ORDERS, payload: ordersQueryModal });
		}
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: WAREHOUSES.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'signWTDModal',
						keyValuePairs: { loading: false },
					},
				});
				yield put({
					type: ORDERS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'getOrderInformationModal',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield signWTDWorker(action);
			},
		);
	}
}

// ZONES
function* getZonesToAssignOrdersWorker() {
	try {
		// Start loading
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'zones',
				keyValuePairs: { loading: true },
			},
		});
		// GetZones
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat('/zones/zonesToAssignOrders'),
			auth.sendToken(),
		);
		// Set data & Stop loading
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'zones',
				keyValuePairs: { loading: false, data },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				// Stop loading
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'zones',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield getZonesToAssignOrdersWorker();
			},
		);
	}
}

// TEMPLATES
function* getTemplatesWorker(action) {
	const request = action.payload;

	try {
		// Start loading
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'templates',
				keyValuePairs: { loading: true },
			},
		});
		// Get templates
		const { data } = yield call(
			axios.post,
			API.DOMAIN.concat('/templates/get'),
			request,
			auth.sendToken(),
		);
		// Set data & Stop loading
		yield put({
			type: APIDATA.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'templates',
				keyValuePairs: { loading: false, data },
			},
		});
		if (request?.type === ENV.TEMPLATES.TYPES.AUDIT_TO_ORDER) {
			idbHandler.setTemplates(data);
		}
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				// Stop loading
				yield put({
					type: APIDATA.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'templates',
						keyValuePairs: { loading: false },
					},
				});
			},
			function* () {
				yield getTemplatesWorker(action);
			},
		);
	}
}

/** **** EXPORT DEFAULT ROOT SAGA *******/
export default function* rootSaga() {
	yield all([
		// USERS
		getRolesWatcher(),
		getPermissionsWatcher(),
		getOrganizationUsersWatcher(),
		// ORDERS
		getDepartmentsWatcher(),
		getServicesWatcher(),
		getClientsWithQueryVariantWatcher(),
		getClientCategoriesWatcher(),
		getContractsByClientIdWatcher(),
		getContractTypesWatcher(),
		getFrequenciesWatcher(),
		getOrderStatesWatcher(),
		getOrderEventsWatcher(),
		// BILLINGS
		getCoinsWatcher(),
		getPaycutsWatcher(),
		getPaymentsWatcher(),
		getInvoicePaysWatcher(),
		getPaycutResumeWatcher(),
		getPaymentResumeWatcher(),
		getPaymentLinesWatcher(),
		getPaymentOrdersWatcher(),
		updatePaymentIdLineWatcher(),
		getGainsEntityWatcher(),
		getLineItemsWatcher(),
		// WAREHOUSES
		getTransactionTypesWatcher(),
		getIncomeMethodsWatcher(),
		getMeasuredUnitsWatcher(),
		getItemStatesWatcher(),
		getPackagesWatcher(),
		getZonesAreasWatcher(),
		getItemFamiliesWatcher(),
		getArticlesWatcher(),
		getWarehousesWatcher(),
		getStockArticlesWatcher(),
		getStockSeriesWatcher(),
		getControlSeriesWatcher(),
		getTransactionDocsWatcher(),
		getInventoryToTransferWatcher(),
		getItemsTransactionDocsWatcher(),
		getSerieHistoryWatcher(),
		updateWTDSignerWatcher(),
		signWTDWatcher(),
		// ZONES
		getZonesToAssignOrdersWatcher(),
		// TEMPLATES
		getTemplatesWatcher(),
	]);
}
