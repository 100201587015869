// Libs
import React from 'react';
// Components
import { Wrapper, Subtitle, ArrowIcon, Divider } from 'components';

const SectionTitle = ({ title, empty }) => {
	return (
		<>
			<Wrapper padding='0' justifyContent='space-between' cursor='pointer'>
				<Subtitle emphasis disabled={empty}>
					{title}
				</Subtitle>
				<Wrapper padding='0'>
					<ArrowIcon
						margin='0 8px 0 0'
						direction={empty ? 'up' : 'down'}
						disabled={empty}
					/>
				</Wrapper>
			</Wrapper>
			<Divider margin='4px 0 10px 0' />
		</>
	);
};

export default SectionTitle;
