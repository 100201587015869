/** **** CONSTANTS *******/
import GENERAL from '../../utils/constants/general';
import _ from 'underscore';
import moment from 'moment';
const { WAREHOUSES } = GENERAL;

/** **** INITIALSTATE *******/
const initialState = {
	// -------------------------------------MANAGE------------------------------------
	control: {
		selectedWarehouses: [],
		filters: {},
		showPopoverMode: false,
		mode: 'individual', // individual, consolidate, all
		searchInData: {
			renderToolbar: false,
			showPopover: false,
			data: '',
			searchBy: 'all', // name, owner, entity, creator
		},
	},
	stockArticlesManage: {
		mode: 'view', // view, adjust
		view: 'articles', // articles, series-grouped, series-peps
		selectedItems: [],
		filters: {},
		searchInData: {
			renderToolbar: false,
			showPopover: false,
			data: '',
			searchBy: 'all', //
		},
		adjustType: 'relative',
		relativeOption: {},
		adjustArticles: [],
	},
	stockSeriesManage: {
		selectedSeries: [],
		filters: {},
		searchInData: {
			renderToolbar: false,
			showPopover: false,
			data: '',
			searchBy: 'all', // all, scan, serie
			pressEnter: false,
		},
	},
	articlesManage: {
		selectedArticles: [],
		filters: {},
		showPopoverCreate: false,
		searchInData: {
			renderToolbar: false,
			showPopover: false,
			data: '',
			searchBy: 'all', // name, code
		},
	},
	transactionsManage: {
		titleToolbar: 'Transacciones',
		docId: undefined,
		deleting: false,
		selectedTransactions: [],
		filters: {},
		showPopoverCreate: false,
		searchInData: {
			renderToolbar: false,
			showPopover: false,
			data: '',
			searchBy: 'all', // doc_number, oWarehouse, dWarehouse
		},
	},
	inventoryToTransferManage: {
		comparing: false, // Compare new entry series with external series
		compareSeries: undefined,
		showPopoverCompareSeries: false,
		selectedItems: [],
		filters: {},
		getSerieOrderData: {
			serie: undefined,
		},
		searchInData: {
			renderToolbar: false,
			data: '',
			searchBy: 'all', // all, scan
		},
		viewOptions: {
			devices: 'found', // found, notFound
		},
	},
	inventoryReception: {
		viewportDimensions: { width: 0, height: 0 },
		collapseActiveKey: -1,
		modeView: 'editPath', // editPath, scan
		selected: {
			items: [],
		},
		filters: {},
		// ZONE
		zoneId: undefined,
		zoneSearching: undefined,
		// AREA
		areaId: undefined,
		areaSearching: undefined,
		// PACKAGE
		scannedPackageId: undefined,
		packageSearching: undefined,
		updatedPackage: {},
	},
	toolsOptionsManage: {
		isOpen: false,
	},
	itemsTransactionDoc: {
		updating: false,
		operation: undefined, // reception, editing
		selectedItems: [],
		filters: {},
		searchInData: {
			renderToolbar: false,
			showPopover: false,
			data: '',
			searchBy: 'all', // code, name, serie
		},
	},
	controlSeries: {
		collapseActiveKey: -1,
		view: 'data', // data, resume
		visibleMenuFilter: undefined,
		dropdownStateActive: false,
		selected: {
			items: [],
		},
		searchInData: {
			renderToolbar: false,
			showPopover: false,
			data: '',
			searchBy: 'all',
		},
		filters: {
			/**
			 * [dataKey]: {
			 *  checkedList: [],
			 *  indeterminate: false,
			 *  checkedAll: false,
			 *  inputSearch: undefined
			 * }
			 */
		},
		request: {
			mode: 'peps', // peps, installed, uninstalled, states
			view: 'individual', // individual, entities
			states_id: [],
			owner_id: undefined,
			entity_id: undefined,
			limit: 50,
			last_row_id: 0,
		},
	},
	// -------------------------------------MODALS------------------------------------
	controlSeriesTransactionModal: {
		isOpen: false,
		loading: false,
		dwarehouseId: undefined,
		stateId: undefined,
		incomeMethodId: undefined,
		comment: undefined,
	},
	createWarehouse: {
		isOpen: false,
		loading: false,
		name: undefined,
		owner_id: undefined,
		details: {
			type: undefined,
			country: undefined,
			address: undefined,
			description: undefined,
			coords: undefined,
		},
	},
	createPath: {
		isOpen: false,
		loading: false,
		addZone: false,
		zone: undefined,
		area: undefined,
	},
	createArticle: {
		isOpen: false,
		loading: false,
		code: undefined,
		name: undefined,
		family_id: undefined,
		coin_id: undefined,
		price: undefined,
		iv: undefined,
		measured_unit_id: undefined,
		is_serialized: false,
		unavailable: false,
	},
	createPackage: {
		isOpen: false,
		loading: false,
		operation: undefined, // newEntry, trsReceive, reception
		serialized: false,
		id: undefined,
		item_id: undefined,
		state_id: undefined,
		amount: undefined,
		warehouse_area_id: undefined,
		warehouse_zone_id: undefined,
		created: false,
		// Create Path
		mustCreateZone: false,
		mustCreateArea: false,
		zone: undefined,
		area: undefined,
	},
	createNewEntryItem: {
		isOpen: false,
		loading: false,
		showPopoverCreate: false,
		item_id: undefined,
		amount: undefined,
	},
	createFamily: {
		isOpen: false,
		loading: false,
		name: undefined,
		fifo_amount: undefined,
		main_device: undefined,
	},
	completeAdjust: {
		isOpen: false,
		loading: false,
		signer: undefined,
		comment: undefined,
	},
	createTransaction: {
		isOpen: false,
		loading: false,
		preview: false,
		mode: undefined, // newEntry, trsSend, trsReceive
		incomeMethod: undefined,
		owhOwnerId: undefined,
		owarehouseId: undefined,
		dwarehouseId: undefined,
		dwarehouseName: undefined,
		signer: undefined,
		comment: undefined,
		articles: [],
		serialized: [],
		notFoundSerialized: [],
	},
	signWTDModal: {
		isOpen: false,
		loading: false,
		signer: undefined,
	},
	getTransactionsModal: {
		isOpen: false,
		mode: 'pending', // assignment, pending, document, completed
		docNumber: undefined,
		fromDate: moment(),
		toDate: moment(),
	},
};

const warehouseReducer = (state = initialState, action) => {
	switch (action.type) {
		case WAREHOUSES.MUTATE_1OBJECT: {
			const { obj1Name, keyValuePairs } = action.payload;
			const newObj = { ...state[obj1Name], ...keyValuePairs };
			return {
				...state,
				[obj1Name]: newObj,
			};
		}
		case WAREHOUSES.MUTATE_DIRECT_PROPS: {
			const keyValuePairs = action.payload;
			return {
				...state,
				...keyValuePairs,
			};
		}
		case WAREHOUSES.RESET_ADJUST_ARTICLES: {
			return {
				...state,
				stockArticlesManage: {
					...state.stockArticlesManage,
					relativeOption: {},
					adjustArticles: [],
				},
				completeAdjust: {
					isOpen: false,
					loading: false,
					signer: undefined,
					comment: undefined,
				},
			};
		}
		case WAREHOUSES.RESET_CREATE_TRANSACTION: {
			return {
				...state,
				createPackage: {
					isOpen: false,
					loading: false,
					serialized: false,
					id: undefined,
					item_id: undefined,
					amount: undefined,
					warehouse_area_id: undefined,
					warehouse_zone_id: undefined,
					created: false,
				},
				createTransaction: {
					isOpen: false,
					loading: false,
					preview: false,
					mode: undefined, // newEntry, trsSend, trsReceive
					incomeMethod: undefined,
					owhOwnerId: undefined,
					owarehouseId: undefined,
					dwarehouseId: undefined,
					dwarehouseName: undefined,
					signer: undefined,
					comment: undefined,
					articles: [],
					serialized: [],
					notFoundSerialized: [],
				},
			};
		}
		case WAREHOUSES.RESET_CREATE_CONTROL_SERIES_TRANSACTION: {
			return {
				...state,
				controlSeriesTransactionModal: {
					isOpen: false,
					loading: false,
					dwarehouseId: undefined,
					stateId: undefined,
					incomeMethodId: undefined,
					comment: undefined,
				},
			};
		}
		case WAREHOUSES.RESET: {
			return initialState;
		}
		default:
			return state;
	}
};

export default warehouseReducer;
