// Libs
import React from 'react';
import { connect } from 'react-redux';
// Actions
import { getOrderStates } from 'store/api/actions';
// Selectors
import { selectStates, selectEvents } from 'store/api/selectors';
// Components
import { Wrapper, Span, DatePicker, TimePicker } from 'components';
import Select from 'components/Select';
import { filterOption, getSpecificUsers } from 'utils/libs';
// Styles
import '../../index.css';

const { Option } = Select;

function GetOrdersModal({
	getOrdersQueryModal,
	users,
	states,
	events,
	mutate1Object,
	getOrderStates,
}) {
	const {
		fromDate,
		fromTime,
		toDate,
		toTime,
		eventCategoryToSearch,
		eventToSearch,
		stateToSearch,
		searchTo,
		expertToSearch,
		entityToSearch,
	} = getOrdersQueryModal;
	const experts = getSpecificUsers(users, { experts: true });
	const entities = getSpecificUsers(users, { entities: true });

	React.useEffect(() => {
		getOrderStates();
	}, []);

	return (
		<Wrapper justifyContent='center'>
			<Wrapper
				width='220px'
				padding='0'
				flexDirection='column'
				alignItems='stretch'
			>
				{/* SEARCH TO: */}
				<Select
					margin='0 0 5px 0'
					value={searchTo}
					onChange={value =>
						mutate1Object('getOrdersQueryModal', { searchTo: value })
					}
				>
					<Option value='expert'>
						<em>Tecnico</em>
					</Option>
					<Option value='company'>
						<em>Entidad</em>
					</Option>
				</Select>

				{searchTo === 'company' ? (
					// COMPANY
					<Select
						placeholder='Entidad'
						margin='0 0 5px 0'
						showSearch
						value={entityToSearch}
						filterOption={filterOption}
						onChange={value =>
							mutate1Object('getOrdersQueryModal', { entityToSearch: value })
						}
					>
						<Option value='all'>
							<em>Todas</em>
						</Option>
						{entities.map((entity, idx) => (
							<Option key={idx} value={entity.id}>
								{entity.name}
							</Option>
						))}
					</Select>
				) : (
					searchTo === 'expert' && (
						//  EXPERT
						<Select
							margin='0 0 5px 0'
							showSearch
							value={expertToSearch}
							filterOption={filterOption}
							onChange={value =>
								mutate1Object('getOrdersQueryModal', { expertToSearch: value })
							}
						>
							<Option value='all'>
								<em>Todos</em>
							</Option>
							{experts.map((expert, idx) => (
								<Option key={idx} value={expert.id}>
									{expert.name}
								</Option>
							))}
						</Select>
					)
				)}

				{searchTo !== 'order' && (
					/* EVENT CATEGORY */
					<Select
						margin='0 0 5px 0'
						showSearch
						value={eventCategoryToSearch}
						filterOption={filterOption}
						onChange={value =>
							mutate1Object('getOrdersQueryModal', {
								eventCategoryToSearch: value,
							})
						}
					>
						<Option value='all'>
							<em>Todos</em>
						</Option>
						<Option value='affectation'>
							<em>Afectadas</em>
						</Option>
						<Option value='billed'>
							<em>Facturadas</em>
						</Option>
						<Option value='warranties-with-dates'>
							<em>Garantías</em>
						</Option>
						<Option value='cancel'>
							<em>Canceladas</em>
						</Option>
					</Select>
				)}

				{eventCategoryToSearch === 'all' && (
					<div className='container-search-orders-filters'>
						{/* EVENT */}
						<Select
							margin='0 0 5px 0'
							showSearch
							placeholder='Evento*'
							value={eventToSearch}
							filterOption={filterOption}
							onChange={value =>
								mutate1Object('getOrdersQueryModal', { eventToSearch: value })
							}
						>
							<Option value='all'>
								<em>Todos</em>
							</Option>
							{events.data.map((event, idx) => (
								<Option key={idx} value={event.id}>
									{event.name}
								</Option>
							))}
						</Select>

						{/* STATE */}
						<Select
							margin='0 0 5px 0'
							showSearch
							placeholder='Estado*'
							value={stateToSearch}
							filterOption={filterOption}
							onChange={value =>
								mutate1Object('getOrdersQueryModal', { stateToSearch: value })
							}
						>
							<Option value='all'>
								<em>Todos</em>
							</Option>
							{states.data.map((state, idx) => (
								<Option key={idx} value={state.id}>
									{state.name}
								</Option>
							))}
						</Select>
					</div>
				)}

				{(eventCategoryToSearch === 'all' ||
					eventCategoryToSearch === 'billed' ||
					eventCategoryToSearch === 'warranties-with-dates' ||
					eventCategoryToSearch === 'cancel' ||
					eventCategoryToSearch === 'affectation') &&
					searchTo !== 'order' && (
						<>
							<Wrapper
								padding='0'
								flexDirection='column'
								alignItems='flex-start'
							>
								{/* FROM DATE */}
								<Span>Desde</Span>
								<Wrapper padding='0' margin='0 0 5px 0' flexDirection='column'>
									<DatePicker
										margin='0 0 5px 0'
										placeholder='Desde*'
										value={fromDate}
										onChange={value =>
											mutate1Object('getOrdersQueryModal', { fromDate: value })
										}
									/>
									{eventCategoryToSearch === 'affectation' && (
										<TimePicker
											className='date-custom'
											placeholder='De las*'
											value={fromTime}
											onChange={value =>
												mutate1Object('getOrdersQueryModal', {
													fromTime: value,
												})
											}
										/>
									)}
								</Wrapper>

								{/* TO DATE */}
								<Span>Hasta</Span>
								<Wrapper padding='0' flexDirection='column'>
									<DatePicker
										margin='0 0 5px 0'
										placeholder='Hasta*'
										value={toDate}
										onChange={value =>
											mutate1Object('getOrdersQueryModal', { toDate: value })
										}
									/>
									{eventCategoryToSearch === 'affectation' && (
										<TimePicker
											className='date-custom'
											placeholder='A las*'
											value={toTime}
											onChange={value =>
												mutate1Object('getOrdersQueryModal', { toTime: value })
											}
										/>
									)}
								</Wrapper>
							</Wrapper>
						</>
					)}
			</Wrapper>
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	states: selectStates(state),
	events: selectEvents(state),
});
const actions = { getOrderStates };
export default connect(mapStateToProps, actions)(GetOrdersModal);
