// Libs
import React, { useEffect } from 'react';
// Utils
import { filterOption, formatNumber } from 'utils/libs';
// Components
import {
	Button,
	Divider,
	TextField,
	InputNumber,
	AddIcon,
	EditIcon,
} from 'components';
import Select from 'components/Select';
// Styles
import '../../../index.css';
import './CreateLineModal.css';

const { Input } = TextField;

const { Option } = Select;
const clearModal = {
	line_item_id: undefined,
	name: undefined,
	description: undefined,
	action: undefined,
	coin_id: undefined,
	amount: undefined,
	tracts: undefined,
};

export function HeadCreateLineModal(props) {
	const { mutate1ObjectInBillings, createLineModal } = props;

	return (
		<div className='create_line-head'>
			{createLineModal.operation === 'createLine' ? (
				<span>Nueva linea</span>
			) : createLineModal.operation === 'editItem' ? (
				<span>Editar Item</span>
			) : (
				<span>Nuevo Item</span>
			)}

			{!createLineModal.loading && createLineModal.line_item_id && (
				<EditIcon
					onClick={() => {
						const update =
							createLineModal.operation === 'editItem' ? { ...clearModal } : {};
						mutate1ObjectInBillings('createLineModal', {
							operation:
								createLineModal.operation === 'createLine'
									? 'editItem'
									: 'createLine',
							...update,
						});
					}}
				/>
			)}
		</div>
	);
}

export default function CreateLineModal(props) {
	useEffect(() => {
		props.getLineItems();
	}, []);

	const {
		line_items,
		payId,
		paidTo,
		createLineModal,
		coins,
		mutate1ObjectInBillings,
		createPaymentLine,
		createPaymentLineItem,
	} = props;
	const lineItem =
		createLineModal.line_item_id &&
		line_items.filter(item => item.id === createLineModal.line_item_id)[0];

	return (
		<div className='modal-container'>
			<div className='create-article-form-items'>
				{createLineModal.operation === 'editItem' ||
				createLineModal.operation === 'createItem' ? (
					<CreateItem
						createLineModal={createLineModal}
						mutate1ObjectInBillings={mutate1ObjectInBillings}
						coins={coins}
						createPaymentLineItem={createPaymentLineItem}
					/>
				) : (
					<CreateLine
						createLineModal={createLineModal}
						payId={payId}
						paidTo={paidTo}
						mutate1ObjectInBillings={mutate1ObjectInBillings}
						createPaymentLine={createPaymentLine}
						lineItem={lineItem}
						line_items={line_items}
					/>
				)}
			</div>
		</div>
	);
}

function CreateItem(props) {
	const {
		createLineModal,
		mutate1ObjectInBillings,
		coins,
		createPaymentLineItem,
	} = props;
	const coin =
		createLineModal.coin_id &&
		coins.filter(con => con.id === createLineModal.coin_id)[0];

	return (
		<div className='create_line-form-select-item'>
			<Input
				disabled={createLineModal.operation === 'editItem'}
				id='name'
				className='form-item-standard'
				placeholder='Item*'
				maxLength={45}
				value={createLineModal.name}
				onChange={e =>
					mutate1ObjectInBillings('createLineModal', {
						[e.target.id]: e.target.value,
					})
				}
			/>

			<Select
				disabled={createLineModal.operation === 'editItem'}
				className='form-item-standard create-article-form-items-select'
				showSearch
				placeholder='Moneda*'
				value={createLineModal.coin_id}
				filterOption={filterOption}
				onChange={value =>
					mutate1ObjectInBillings('createLineModal', { coin_id: value })
				}
			>
				{coins.map((con, idx) => (
					<Option key={idx} value={con.id}>
						<em>{con.name}</em>
					</Option>
				))}
			</Select>

			{coin && (
				<InputNumber
					className='form-item-standard'
					placeholder='Monto*'
					min={0.0}
					step={0.1}
					value={createLineModal.amount}
					formatter={value =>
						`${coin.symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
					}
					parser={value => {
						value = value.replace(coin.symbol, '');
						value = value.replace(/\$\s?|(,*)/g, '');
						value = value.replace(/ /g, '');
						return value;
					}}
					onChange={value =>
						mutate1ObjectInBillings('createLineModal', { amount: value })
					}
				/>
			)}

			<InputNumber
				className='form-item-standard'
				placeholder='Tractos*'
				min={1}
				value={createLineModal.tracts}
				onChange={value =>
					mutate1ObjectInBillings('createLineModal', { tracts: value })
				}
			/>

			<Select
				disabled={createLineModal.operation === 'editItem'}
				className='form-item-standard create-article-form-items-select'
				showSearch
				placeholder='Acción*'
				value={createLineModal.action}
				filterOption={filterOption}
				onChange={value =>
					mutate1ObjectInBillings('createLineModal', { action: value })
				}
			>
				<Option value='debit'>
					<em>Debitar</em>
				</Option>
				<Option value='credit'>
					<em>Acreditar</em>
				</Option>
			</Select>

			<div className='form-action-button'>
				<Button
					className='button-style-1'
					onClick={() => createPaymentLineItem(createLineModal)}
				>
					{createLineModal.operation === 'editItem' ? 'Actualizar' : 'Crear'}
				</Button>
			</div>
		</div>
	);
}

function CreateLine(props) {
	const {
		createLineModal,
		payId,
		paidTo,
		mutate1ObjectInBillings,
		createPaymentLine,
		lineItem,
		line_items,
	} = props;

	return (
		<div className='create_line-form-select-item'>
			<Select
				className='form-item-standard create-article-form-items-select'
				showSearch
				placeholder='Línea*'
				value={createLineModal.line_item_id}
				filterOption={filterOption}
				onChange={value => {
					const item = line_items.filter(item => item.id === value)[0];
					mutate1ObjectInBillings('createLineModal', {
						line_item_id: value,
						name: item.name,
						action: item.action,
						props: item.props,
						coin_id: item.coin_id,
						amount: item.amount > 0 ? item.amount : undefined,
						tracts: item.tracts > 0 ? item.tracts : undefined,
					});
				}}
				dropdownRender={menu => (
					<div>
						{menu}
						<Divider style={{ margin: '4px 0' }} />
						<div
							style={{ padding: '4px 8px', cursor: 'pointer' }}
							onMouseDown={e => e.preventDefault()}
							onClick={() =>
								mutate1ObjectInBillings('createLineModal', {
									...clearModal,
									operation: 'createItem',
								})
							}
						>
							<AddIcon />
						</div>
					</div>
				)}
			>
				{line_items.map((line, idx) => (
					<Option key={idx} value={line.id}>
						<em>{line.name}</em>
					</Option>
				))}
			</Select>

			<Input
				id='description'
				className='form-item-standard'
				placeholder='Descripción'
				maxLength={100}
				value={createLineModal.description}
				onChange={e =>
					mutate1ObjectInBillings('createLineModal', {
						[e.target.id]: e.target.value,
					})
				}
			/>

			{lineItem && (!lineItem.amount || lineItem.amount <= 0) && (
				<InputNumber
					className='form-item-standard'
					placeholder='Monto*'
					min={0.0}
					step={0.1}
					value={createLineModal.amount}
					formatter={value =>
						`${lineItem.coin_symbol} ${value}`.replace(
							/\B(?=(\d{3})+(?!\d))/g,
							',',
						)
					}
					parser={value => {
						value = value.replace(lineItem.coin_symbol, '');
						value = value.replace(/\$\s?|(,*)/g, '');
						value = value.replace(/ /g, '');
						return value;
					}}
					onChange={value =>
						mutate1ObjectInBillings('createLineModal', { amount: value })
					}
				/>
			)}

			{lineItem && (!lineItem.tracts || lineItem.tracts <= 0) && (
				<InputNumber
					className='form-item-standard'
					placeholder='Tractos*'
					min={1}
					value={createLineModal.tracts}
					onChange={value =>
						mutate1ObjectInBillings('createLineModal', { tracts: value })
					}
				/>
			)}

			{lineItem && lineItem.amount && lineItem.amount > 0 ? (
				<div className='create_line-item-default'>
					<span>Monto</span>
					<span>
						{formatNumber.new(lineItem.amount, lineItem.coin_symbol, 2)}
					</span>
				</div>
			) : (
				<div />
			)}

			{lineItem && lineItem.tracts && lineItem.tracts > 0 ? (
				<div className='create_line-item-default'>
					<span>Tractos</span>
					<span>{lineItem.tracts}</span>
				</div>
			) : (
				<div />
			)}

			{lineItem && (
				<div className='create_line-item-default'>
					<span>Acción</span>
					<span>{lineItem.action_label}</span>
				</div>
			)}

			<div className='form-action-button'>
				<Button
					className='button-style-1'
					onClick={() => createPaymentLine(createLineModal, paidTo, payId)}
				>
					Crear
				</Button>
			</div>
		</div>
	);
}
