// Libs
import { useDispatch } from 'react-redux';
// Services
import { ApiServiceV2 } from 'services';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
import { updateDatasourceItem } from 'core/actions';

const catchErr = ({ err, dispatch, onError }) => {
	dispatch(
		sendToast({
			message:
				err.message ||
				'Tuve un problema al realizar la acción. Por favor inténtalo más tarde',
			type: err.type || 'warn',
		}),
	);
	if (onError) onError(err);
};

const endpoint = '/users';
const useUserApi = () => {
	const dispatch = useDispatch();

	return {
		// Set on way order
		recoveryPasswordRequest: idc =>
			ApiServiceV2.post({
				url: `${endpoint}/recovery_password_request/${idc}`,
			}),
		recoveryPasswordConfirm: (idc, recoveryCode) =>
			ApiServiceV2.put({
				url: `${endpoint}/recovery_password_confirm/${idc}/${recoveryCode}`,
			}),
		recoveryPasswordUpdate: (idc, newPassword) =>
			ApiServiceV2.put({
				url: `${endpoint}/recovery_password_update/${idc}/${newPassword}`,
			}),
		setRelation: (userId, relation, { onError }) =>
			ApiServiceV2.put({
				url: `${endpoint}/setRelation`,
				data: { userId, relation },
			}).catch(err => catchErr({ err, dispatch, onError })),
		changeActivationWorker: ({ userId, active, onError }) =>
			ApiServiceV2.put({
				url: `${endpoint}/updateUserProfileActive/${userId}/${profileId}`,
			})
				.then(() => {
					dispatch(
						updateDatasourceItem({
							updateRecords: [
								{
									id: userId,
									fields: {
										active,
									},
								},
							],
						}),
					);
				})
				.catch(err => catchErr({ err, dispatch, onError })),
		changeWorkCodeWorker: ({ userId, workCode, onError }) =>
			ApiServiceV2.put({
				url: `${endpoint}/assign/work_code`,
				data: { userId, workCodeAssign: workCode },
			})
				.then(() => {
					dispatch(
						updateDatasourceItem({
							updateRecords: [
								{
									id: userId,
									fields: {
										workCode,
									},
								},
							],
						}),
					);
					dispatch(
						sendToast({
							message: 'Código modificado',
							type: 'success',
						}),
					);
				})
				.catch(err => catchErr({ err, dispatch, onError })),

		getEntityAssignment: userId =>
			ApiServiceV2.get({
				url: `${endpoint}/getEntityAssignment/${userId}`,
			}),

		setUserProfileActive: ({ userId, entityId }) =>
			ApiServiceV2.put({
				url: `${endpoint}/setUserProfileActive`,
				data: { userId, entityId },
			}),
	};
};

export default useUserApi;
