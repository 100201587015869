import { createSelector } from 'reselect';

const selectSignup = state => state.signup;

export const selectCompanies = createSelector(
	[selectSignup],
	signup => signup.companies,
);

export const selectOrganizations = createSelector(
	[selectSignup],
	signup => signup.organizations,
);

export const selectEntities = createSelector(
	[selectSignup],
	signup => signup.entities,
);
