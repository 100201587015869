// Libs
import React from 'react';
// Components
import Header from './Header';
import Body from './Body';
// Styles
import styles from '../User.module.css';

const UserData = ({ record }) => {
	return (
		<div className={styles.header}>
			<Header
				name={record.name}
				rating={record.rating}
				createdAt={record.createdAt}
			/>
			<Body
				userId={record.id}
				idc={record.idc}
				workCode={record.workCode}
				birthday={record.birthday}
				gender={record.gender}
			/>
		</div>
	);
};

export default UserData;
