// Libs
import React from 'react';
import { Table, Column } from 'react-virtualized';
import styled, { css } from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { debounce } from 'utils/libs';
import Subtitle from './SubTitle/Subtitle';
import Popover from './Popover';
import Wrapper from './Wrapper/Wrapper';
import Checkbox from './Checkbox';
import CheckboxGroup from './CheckboxGroup';
import TextField from './TextField';
import { FiltersIcon } from './Icons';
// Utils
import {
	getFilterOptionsList,
	onChangeFilter,
	getSearchInputProps,
	getCheckProps,
	getCheckAllProps,
} from '../utils/filters';

const { Input } = TextField;

// EVENTS
/** onClickRow:
 * Propósito: Seleccionar o deseleccionar el id del item que reciba como parámetro
 * Parámetros(
 *  id: Id del item seleccionado que sera incluido o excluido del array <items>
 *  selected: { Objeto donde que contiene el array de ids de los items que se ha seleccionado
 *    items: []
 *  },
 *  mutate1Object: Funcion que se encarga de actualizar el estado
 *  mutate1ObjectKey: Objeto principal que sera actualizado por <mutate1Object>
 * )
 */
function onClickRow(id, selected, onClickedRow) {
	if (selected.items.indexOf(id) === -1) {
		const items = [...selected.items];
		items.push(id);
		onClickedRow(items);
	} else {
		onClickedRow(selected.items.filter(item => item !== id));
	}
}

/** onDoubleClickRow:
 * Propósito: Seleccionar todos los id de los items de la data que reciba como parámetro
 * Parámetros(
 *  key: Nombre de la propiedad que será evaluada en cada item
 *  selected: { Objeto donde que contiene el array de ids de los items que se ha seleccionado
 *    items: []
 *  },
 *  data: La data de todos los items que seran incluidos o excluidos
 *  mutate1Object: Funcion que se encarga de actualizar el estado
 *  mutate1ObjectKey: Objeto principal que sera actualizado por <mutate1Object>
 * )
 */
function onDoubleClickRow(
	key,
	selected,
	data,
	mutate1Object,
	mutate1ObjectKey,
) {
	const items = data.reduce(
		(acc, item) => {
			if (acc.indexOf(item[key]) === -1) {
				acc.push(item[key]);
			}
			return acc;
		},
		[...selected.items],
	);

	mutate1Object(mutate1ObjectKey, { selected: { ...selected, items } });
}

/** onRightClickRow:
 * Propósito: Deseleccionar todos los id de los items de la data que reciba como parámetro
 * Parámetros(
 *  key: Nombre de la propiedad que será evaluada en cada item
 *  selected: { Objeto donde que contiene el array de ids de los items que se ha seleccionado
 *    items: []
 *  },
 *  data: La data de todos los items que seran incluidos o excluidos
 *  mutate1Object: Funcion que se encarga de actualizar el estado
 *  mutate1ObjectKey: Objeto principal que sera actualizado por <mutate1Object>
 * )
 */
function onRightClickRow(e, key, selectedItems, data, onFilterItems) {
	e.preventDefault();
	const filteredItems = data.reduce(
		(acc, selectedItem) => {
			acc = acc.filter(itm => itm !== selectedItem[key]);
			return acc;
		},
		[...selectedItems],
	);
	onFilterItems(filteredItems);
}

// JSX RENDER
const TableStyled = styled(Table)`
	border-radius: ${({ theme, borderRadius }) =>
		borderRadius || theme.table.borderRadius};
	box-shadow: ${({ theme, boxShadow }) => boxShadow || theme.table.boxShadow};
	${({ highBackground }) =>
		highBackground &&
		css`
			background-color: ${({ $newTheme }) => $newTheme._primaryBackgroundColor};
		`}
	${({ mediumBackground }) =>
		mediumBackground &&
		css`
			background-color: ${({ $newTheme }) =>
				$newTheme._secondaryBackgroundColor};
		`}
`;

const HeaderStyled = styled.div`
	margin: ${({ theme, margin }) => margin || theme.table.header.margin};
	padding: ${({ theme, padding }) => padding || theme.table.header.padding};
	display: ${({ theme }) => theme.table.header.display};
	justify-content: ${({ theme, justifyContent }) =>
		justifyContent || theme.table.header.justifyContent};
	align-items: ${({ theme }) => theme.table.header.alignItems};
	border-radius: ${({ theme, borderRadius }) =>
		borderRadius || theme.table.header.borderRadius};
	font-family: ${({ theme, fontFamily }) => fontFamily || theme.fonts.family};
	font-size: ${({ theme, fontSize }) =>
		fontSize ? theme.fonts.size[fontSize] : theme.fonts.size.s};
	font-weight: ${({ theme, fontWeight }) =>
		fontWeight || theme.table.header.fontWeight};
	font-stretch: ${({ theme, fontStretch }) =>
		fontStretch || theme.table.header.fontStretch};
	font-style: ${({ theme, fontStyle }) =>
		fontStyle || theme.table.header.fontStyle};
	line-height: ${({ theme, lineHeight }) =>
		lineHeight || theme.table.header.lineHeight};
	letter-spacing: ${({ theme, letterSpacing }) =>
		letterSpacing || theme.table.header.letterSpacing};
	text-transform: ${({ theme, textTransform }) =>
		textTransform || theme.table.header.textTransform};
	color: ${({ theme, color }) => color || theme.table.header.color};
`;

const HeaderFilterPopoverContent = ({
	dataKey,
	data,
	orderType,
	dateFormat,
	filters,
	filterProps,
	onChangedFilter,
}) => {
	const options = getFilterOptionsList(
		dataKey,
		filters,
		data,
		orderType,
		dateFormat,
	);

	const debouncedOnChange = React.useCallback(
		debounce(callback => callback(), 300),
		[filterProps?.inputSearch],
	);

	return (
		<Wrapper flexDirection='column' alignItems='flex-start'>
			{/* <Wrapper padding="0"> */}
			<Input
				autoFocus
				placeholder='Buscar'
				size='small'
				margin='0 0 5px 0'
				// value={filterProps ? filterProps.inputSearch : undefined}
				onChange={e => {
					debouncedOnChange(() =>
						onChangeFilter(
							getSearchInputProps(e, options),
							dataKey,
							filters,
							onChangedFilter,
						),
					);
				}}
			/>
			{/* <Span>ASC</Span> */}
			{/* </Wrapper> */}
			<Checkbox
				indeterminate={filterProps ? filterProps.indeterminate : false}
				onChange={e =>
					onChangeFilter(
						getCheckAllProps(e, options),
						dataKey,
						filters,
						onChangedFilter,
					)
				}
				checked={filterProps ? filterProps.checkAll : true}
			>
				Todos
			</Checkbox>
			<Wrapper
				width='100%'
				display='list-item'
				maxHeight='350px'
				overflow='auto'
			>
				<CheckboxGroup
					flex_direction='column'
					options={options}
					value={filterProps ? filterProps.checkedList : options}
					onChange={checkedList =>
						onChangeFilter(
							getCheckProps(checkedList, options),
							dataKey,
							filters,
							onChangedFilter,
						)
					}
				/>
			</Wrapper>
		</Wrapper>
	);
};

function Header(props) {
	const { children, filter } = props;

	if (!filter) {
		return (
			<HeaderStyled {...props}>
				<Subtitle>{children}</Subtitle>
			</HeaderStyled>
		);
	} else {
		const { dataKey, filters } = filter;
		const filterProps = filters[dataKey];

		return (
			<HeaderStyled {...props}>
				<Subtitle>{children}</Subtitle>
				<Popover
					placement='bottom'
					content={
						<HeaderFilterPopoverContent {...filter} filterProps={filterProps} />
					}
				>
					<div style={{ marginLeft: '5px' }}>
						<FiltersIcon hoverable activable={!!filterProps} />
					</div>
				</Popover>
			</HeaderStyled>
		);
	}
}

const RowStyled = styled.div`
	display: ${({ theme, display }) => display || theme.table.row.display};
	align-items: ${({ theme, alignItems }) =>
		alignItems || theme.table.row.alignItems};
	cursor: ${({ theme, cursor }) => cursor || theme.table.row.cursor};
	border-bottom: ${({ theme, borderBottom }) =>
		borderBottom || theme.table.row.borderBottom};
	font-size: ${({ theme, fontSize }) =>
		fontSize ? theme.fonts.size[fontSize] : theme.fonts.size.default};
	font-family: ${({ theme, fontFamily }) => fontFamily || theme.fonts.family};
	font-weight: ${({ theme, fontWeight }) =>
		fontWeight || theme.table.row.fontWeight};
	font-stretch: ${({ theme, fontStretch }) =>
		fontStretch || theme.table.row.fontStretch};
	font-style: ${({ theme, fontStyle }) =>
		fontStyle || theme.table.row.fontStyle};
	line-height: ${({ theme, lineHeight }) =>
		lineHeight || theme.table.row.lineHeight};
	letter-spacing: ${({ theme, letterSpacing }) =>
		letterSpacing || theme.table.row.letterSpacing};
	color: ${({ theme, color }) => color || theme.table.row.color};
	&:hover {
		font-size: ${({ theme, fontSizeHover }) =>
			fontSizeHover || theme.fonts.size.default};
		font-weight: ${({ theme, fontWeightHover }) =>
			fontWeightHover || theme.table.row.hover.fontWeight};
	}
	&:active {
		font-size: ${({ theme, fontSizeActive }) =>
			fontSizeActive || theme.fonts.size.default};
		font-weight: ${({ theme, fontWeightActive }) =>
			fontWeightActive || theme.table.row.active.fontWeight};
	}
`;

const CellStyled = styled.div`
	font-size: ${({ theme, fontSize }) =>
		fontSize ? theme.fonts.size[fontSize] : theme.fonts.size.default};
	color: ${({ theme, color }) => color || theme.table.cell.color};
	display: ${({ theme, display }) => display || theme.table.cell.display};
	align-items: ${({ theme, alignItems }) =>
		alignItems || theme.table.cell.alignItems};
	justify-content: ${({ theme, justifyContent }) =>
		justifyContent || theme.table.cell.justifyContent};
`;

export default {
	Table: ({ children, ...props }) => {
		const { theme } = useTheme();
		return (
			<TableStyled {...props} $newTheme={theme}>
				{children}
			</TableStyled>
		);
	},
	Column,
	Header,
	Row: RowStyled,
	Cell: CellStyled,
	onClickRow,
	onDoubleClickRow,
	onRightClickRow,
};
