import { Tabs } from 'antd';
import styled, { css } from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';

const { TabPane } = Tabs;

const TabsStyled = styled(Tabs)`
		width: ${props => (props.width ? props.width : props.theme.tabs.width)};
		height: ${props => (props.height ? props.height : props.theme.tabs.height)};
		.ant-tabs-tab {
			color: ${props =>
				props.colorTab ? props.colorTab : props.theme.tabs.tabPane.color};
		}
		.ant-tabs-tab-prev-icon {
			color: ${props =>
				props.colorTab ? props.colorTab : props.theme.tabs.tabPane.color};
		}

		.ant-tabs-tab-next-icon {
			color: ${props =>
				props.colorTab ? props.colorTab : props.theme.tabs.tabPane.color};
		}
		.ant-tabs-bar {
			margin: 0;
		}
		${props =>
			props.smallTabs &&
			css`
				.ant-tabs-nav .ant-tabs-tab {
					margin: 0 10px 0 0;
				}
			`}
			${({ $newTheme, $isDarkTheme }) =>
				!$isDarkTheme &&
				css`
					.ant-tabs-tab-btn {
						color: ${$newTheme._mediumEmphasisColor};
					}
				`}
		}
	`;

const CustomTabs = props => {
	const { theme, isDarkTheme } = useTheme();

	return (
		<TabsStyled $newTheme={theme} $isDarkTheme={isDarkTheme} {...props}>
			{props.children}
		</TabsStyled>
	);
};
const TabPaneStyled = styled(TabPane)`
	background-color: ${props =>
		props.backgroundColor
			? props.backgroundColor
			: props.theme.tabs.tabPane.backgroundColor};
`;

export default {
	Wrap: CustomTabs,
	Pane: TabPaneStyled,
};
