// Libs
import React from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import IconButton from '@mui/material/IconButton';
import Icon from './Icon';

const SIZES = {
	SMALL: 'small',
	NORMAL: 'normal',
};

const CommentIcon = ({ size, button, onClick }) => {
	const { theme } = useTheme();
	const fill = theme._inactiveColor;

	return button ? (
		<IconButton onClick={onClick}>
			<Icon
				fillColor={fill}
				width={size === SIZES.SMALL ? '16px' : '24px'}
				height={size === SIZES.SMALL ? '16px' : '24px'}
			/>
		</IconButton>
	) : (
		<Icon
			fillColor={fill}
			onClick={onClick}
			width={size === SIZES.SMALL ? '16px' : '24px'}
			height={size === SIZES.SMALL ? '16px' : '24px'}
		/>
	);
};

export default CommentIcon;
