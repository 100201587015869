//Libs
import React from 'react';
//Utils
import COLLECTOR_KEYWORDS from './keywords';
//Components
import {
	Wrapper,
	Collapse as _Collapse,
	AddIcon,
	DeleteIcon,
} from 'components';
import CollectorUtils from 'components/Collector/CollectorUtils';
import UnsubgroupedCollectors from './UnsubgroupedCollectors';
import SubgroupedCollectors from './SubgroupedCollectors';
import DynamicHeaderTitle from './DynamicHeaderTitle';

const { DUPLICATION } = COLLECTOR_KEYWORDS.COLLECTORS;
const { Collapse, Panel } = _Collapse;

const GroupHeader = React.memo(
	({
		group,
		isDuplicable,
		highlightRequired,
		selectedCurrentCollector,
		groupedCollectors,
		collectorValues,
		handleOnDuplicateElement,
		handleOnDeleteElement,
	}) => {
		const collectors = React.useMemo(
			() =>
				CollectorUtils.getGroupedCollectorsFromGroup(
					groupedCollectors,
					group.groupId,
				),
			[group.groupId, groupedCollectors],
		);
		return (
			<Wrapper padding='0' justifyContent='space-between'>
				<DynamicHeaderTitle
					level={DUPLICATION.LEVELS.GROUP}
					highlightElement={group}
					defaultTitle={group.groupName}
					highlightRequired={highlightRequired}
					selectedCurrentCollector={selectedCurrentCollector}
					collectors={collectors}
					collectorValues={collectorValues}
				/>
				{isDuplicable && (
					<Wrapper padding='0'>
						<Wrapper padding='0' margin='0 10px 0 0'>
							<DeleteIcon
								onClick={e => {
									e.stopPropagation();
									handleOnDeleteElement({
										level: DUPLICATION.LEVELS.GROUP,
										element: group, //Formatted duplicate element already
									});
								}}
							/>
						</Wrapper>

						<AddIcon
							onClick={e => {
								e.stopPropagation();
								handleOnDuplicateElement({
									level: DUPLICATION.LEVELS.GROUP,
									element: group, //Formatted duplicate element already
								});
							}}
						/>
					</Wrapper>
				)}
			</Wrapper>
		);
	},
);

const Group = React.memo(
	({
		profile,
		order,
		docId,
		readOnlyCollector,
		review,
		group,
		photoCount,
		groupedCollectors,
		highlightRequired,
		selectedCurrentCollector,
		isPhotoBackupActive,
		isSynapseCameraActive,
		filteredCollectorLayout,
		collectorValues,
		resources,
		collectorEcosystemAutoSyncRunning,
		handleOnDuplicateElement,
		handleOnDeleteElement,
		onLoadSegmentedResources,
	}) => {
		const collectors = React.useMemo(
			() =>
				CollectorUtils.getGroupedCollectorsFromGroup(
					groupedCollectors,
					group.groupId,
				),
			[group.groupId, groupedCollectors],
		);

		const [unsubgroupedCollectors, subgroupedCollectors] = React.useMemo(
			() => [
				CollectorUtils.getUnsubgroupedCollectors(collectors),
				CollectorUtils.getSubgroupedCollectors(collectors),
			],
			[collectors.length, photoCount, groupedCollectors],
		);

		const subgroups = React.useMemo(
			() =>
				CollectorUtils.getGroupsFromGroupedCollectors(
					DUPLICATION.LEVELS.SUBGROUP,
					{
						docId,
						orderId: order.order_id,
						review: {
							name: review.name,
							serviceId: review.serviceId,
						},
					},
					subgroupedCollectors,
				),
			[
				docId,
				order.order_id,
				review.name,
				review.serviceId,
				subgroupedCollectors,
			],
		);

		return (
			<>
				{/* UNSUBGROUPED COLLECTORS */}
				<UnsubgroupedCollectors
					profile={profile}
					order={order}
					docId={docId}
					unsubgroupedCollectors={unsubgroupedCollectors}
					readOnlyCollector={readOnlyCollector}
					highlightRequired={highlightRequired}
					selectedCurrentCollector={selectedCurrentCollector}
					isPhotoBackupActive={isPhotoBackupActive}
					isSynapseCameraActive={isSynapseCameraActive}
					filteredCollectorLayout={filteredCollectorLayout}
					collectorValues={collectorValues}
					resources={resources}
					collectorEcosystemAutoSyncRunning={collectorEcosystemAutoSyncRunning}
					handleOnDuplicateElement={handleOnDuplicateElement}
					handleOnDeleteElement={handleOnDeleteElement}
				/>

				{/* SUBGROUPED COLLECTORS */}
				<SubgroupedCollectors
					profile={profile}
					order={order}
					docId={docId}
					readOnlyCollector={readOnlyCollector}
					review={review}
					group={group}
					subgroups={subgroups}
					subgroupedCollectors={subgroupedCollectors}
					highlightRequired={highlightRequired}
					selectedCurrentCollector={selectedCurrentCollector}
					isPhotoBackupActive={isPhotoBackupActive}
					isSynapseCameraActive={isSynapseCameraActive}
					filteredCollectorLayout={filteredCollectorLayout}
					collectorValues={collectorValues}
					resources={resources}
					collectorEcosystemAutoSyncRunning={collectorEcosystemAutoSyncRunning}
					handleOnDuplicateElement={handleOnDuplicateElement}
					handleOnDeleteElement={handleOnDeleteElement}
					onLoadSegmentedResources={onLoadSegmentedResources}
				/>
			</>
		);
	},
);

const GroupedCollectors = React.memo(
	({
		profile,
		order,
		docId,
		readOnlyCollector,
		review,
		groups,
		groupedCollectors,
		photoCount,
		highlightRequired,
		selectedCurrentCollector,
		isPhotoBackupActive,
		isSynapseCameraActive,
		filteredCollectorLayout,
		collectorValues,
		resources,
		collectorEcosystemAutoSyncRunning,
		handleOnDuplicateElement,
		handleOnDeleteElement,
		onLoadSegmentedResources,
	}) => {
		const duplicatedGroupLastNames = React.useMemo(
			() =>
				groups.reduce((acc, group) => {
					//Get current last element
					const { originalElementName, lastSequenceNumber } =
						CollectorUtils.getCurrentLastElement(
							DUPLICATION.LEVELS.GROUP,
							group.groupName,
							groupedCollectors,
							{
								docId,
								orderId: order.order_id,
								review: {
									name: review.name,
									serviceId: review.serviceId,
								},
							},
						);
					if (!originalElementName || !lastSequenceNumber) return acc;

					acc[`${originalElementName} #${lastSequenceNumber}`] = true;
					return acc;
				}, {}),
			[
				docId,
				order.order_id,
				review.name,
				review.serviceId,
				groups,
				groupedCollectors,
			],
		);

		return (
			<Collapse
				accordion
				bordered={false}
				defaultActiveKey={['-1']}
				destroyInactivePanel={true}
				onChange={activeKey => {
					onLoadSegmentedResources({
						docId,
						orderId: order.order_id,
						serviceTaskId: review.serviceTaskId,
						reviewId: review.id,
						groupId: activeKey,
					});
				}}
			>
				{CollectorUtils.checkArray(groups).map(group => (
					<Panel
						key={group.groupId}
						style={{
							padding: '8px 8px 0 8px',
							margin: '0 0 8px 0',
							boxShadow: 'rgb(0 0 0 / 35%) 0px 5px 15px',
						}}
						header={
							<GroupHeader
								group={group}
								isDuplicable={duplicatedGroupLastNames[group.groupName]}
								highlightRequired={highlightRequired}
								selectedCurrentCollector={selectedCurrentCollector}
								groupedCollectors={groupedCollectors}
								collectorValues={collectorValues}
								handleOnDuplicateElement={handleOnDuplicateElement}
								handleOnDeleteElement={handleOnDeleteElement}
							/>
						}
					>
						<Group
							profile={profile}
							order={order}
							docId={docId}
							readOnlyCollector={readOnlyCollector}
							review={review}
							group={group}
							photoCount={photoCount}
							groupedCollectors={groupedCollectors}
							highlightRequired={highlightRequired}
							selectedCurrentCollector={selectedCurrentCollector}
							isPhotoBackupActive={isPhotoBackupActive}
							isSynapseCameraActive={isSynapseCameraActive}
							filteredCollectorLayout={filteredCollectorLayout}
							collectorValues={collectorValues}
							resources={resources}
							collectorEcosystemAutoSyncRunning={
								collectorEcosystemAutoSyncRunning
							}
							handleOnDuplicateElement={handleOnDuplicateElement}
							handleOnDeleteElement={handleOnDeleteElement}
							onLoadSegmentedResources={onLoadSegmentedResources}
						/>
					</Panel>
				))}
			</Collapse>
		);
	},
);

export default GroupedCollectors;
