// Libs
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// Utils
import GENERAL from 'utils/constants/general';
import { getCoords } from 'utils/libs';
import { getUIValueProp } from 'components/DataFieldManager/OnlyReadField/OnlyReadField';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
import { updateDatasourceItem } from 'core/actions';
// Hooks
import { useOrderApi, useEventApi } from 'hooks';
import { GlobalUtils } from 'utils';

const { UNSUCCESS } = GENERAL.ENV.STATUS;

const useOrderEventAssignment = () => {
	const dispatch = useDispatch();
	const { getEvents } = useEventApi();
	const { setEventAssignmentV0 } = useOrderApi();

	const [state, setState] = useState({
		isLoading: false,
		isSetting: false,
		data: [],
		status: undefined,
		message: undefined,
	});

	const onRetry = () =>
		setState(prev => ({ ...prev, status: undefined, message: undefined }));

	const onGetEventAssignment = ({ searchValue } = {}) => {
		setState(prev => ({ ...prev, isLoading: true }));
		getEvents({ searchValue })
			.then(data => {
				setState(prev => ({
					...prev,
					isLoading: false,
					data: GlobalUtils.checkArray(data),
				}));
			})
			.catch(err =>
				dispatch(sendToast({ message: err.message, type: err.type })),
			);
	};

	const onSetEventAssignment = async (
		{ field, rowData, option, ...rest },
		callback,
	) => {
		if (!_checkEventAssignment({ option, ...rest })) return;
		setState(prev => ({ ...prev, isSetting: true }));
		getCoords()
			.then(({ coords }) => {
				setTimeout(() => {
					setEventAssignmentV0(
						[
							{
								id: rowData.id,
							},
						],
						{
							...rest,
							eventId: option.id,
							coords,
						},
					)
						.then(processedOrders => {
							const processedOrder = processedOrders.find(
								order => order.id === rowData.id,
							);
							if (!processedOrder) return;
							if (processedOrder.status === UNSUCCESS) {
								setState(prev => ({
									...prev,
									isSetting: false,
									status: processedOrder.status,
									message: processedOrder.message,
								}));
								return;
							}

							dispatch(
								updateDatasourceItem({
									updateRecords: [
										{
											id: rowData.id,
											fields: {
												[field.id]: option.id,
												[getUIValueProp(field) || field.name]: option.name,
											},
										},
									],
								}),
							);

							setTimeout(() => {
								setState(prev => ({ ...prev, isSetting: false }));
							}, 100);

							if (callback) callback();
						})
						.catch(err => {
							setState(prev => ({
								...prev,
								isSetting: false,
								status: UNSUCCESS,
								message: err.message || '',
							}));
							dispatch(sendToast({ message: err.message, type: err.type }));
						});
				}, 100);
			})
			.catch(({ message, type }) => {
				setState(prev => ({ ...prev, isSetting: false }));
				dispatch(sendToast({ message, type }));
			});
	};

	const _checkEventAssignment = ({ option, reprogramDate, reprogramTime }) => {
		if (option.props?.reprogram && (!reprogramDate || !reprogramTime)) {
			dispatch(
				sendToast({
					type: 'warn',
					message: 'Te falta completar campos requeridos (*)',
				}),
			);
			return false;
		}
		return true;
	};

	return { state, onGetEventAssignment, onSetEventAssignment, onRetry };
};

export default useOrderEventAssignment;
