/**
 * Step 1: Import custom modal
 */
import {
	SupervisorOrderExpanded,
	SupervisorOrderReports,
	SupervisorChangeCoords,
	// <--HERE
} from './Supervisor';
import {
	UpdateOrderEvent,
	OrderDeleteAuditedOTD,
	// <--HERE
} from './Orders';
import {
	UserProfile,
	// <--HERE
} from './User';

/**
 * Step 2: Create modal key
 */
const authorizedModalKeys = {
	orderExpandedModal: 'orderExpandedModal',
	orderReportsModal: 'orderReportsModal',
	supervisorChangeCoords: 'supervisorChangeCoords',
	updateOrderEvent: 'updateOrderEvent',
	userProfile: 'userProfile',
	orderDeleteAuditedOTD: 'orderDeleteAuditedOTD',
	// <--HERE
};

/**
 * Step 3: Match modal key with React Node
 */
const authorizedModalComponents = {
	[authorizedModalKeys.orderExpandedModal]: SupervisorOrderExpanded,
	[authorizedModalKeys.orderReportsModal]: SupervisorOrderReports,
	[authorizedModalKeys.supervisorChangeCoords]: SupervisorChangeCoords,
	[authorizedModalKeys.updateOrderEvent]: UpdateOrderEvent,
	[authorizedModalKeys.userProfile]: UserProfile,
	[authorizedModalKeys.orderDeleteAuditedOTD]: OrderDeleteAuditedOTD,
	// <--HERE
};

export default class ModalBuilderConfig {
	static getAuthorizedModalKeys() {
		return authorizedModalKeys;
	}

	static getAuthorizedModalReactNode(modalKey) {
		return authorizedModalComponents[modalKey];
	}
}
