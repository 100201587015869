// Libs
import React from 'react';
import _ from 'underscore';
import { Redirect } from 'react-router-dom';
// Utils
import { getFlagColorOrderCard } from '../../../utils';
import { formatNumber, coinsUtils } from 'utils/libs';
import { filterPaymentOrders } from 'utils/filters/Billings/orders';
import GENERAL from 'utils/constants/general';
// Components
import {
	Wrapper,
	TableVirtualized,
	Flag,
	Span,
	Spin,
	InputNumber,
} from 'components';
import AmountCell from './components/AmountCell';

const { ENV } = GENERAL;
const { Table, Column, Header, Row, Cell } = TableVirtualized;

function PaymentsOrdersDesktop(props) {
	if (
		!Array.isArray(props?.pays?.selected?.items) ||
		props.pays.selected.items.length === 0 ||
		!props.pays.getOrders
	) {
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_PAYS_DESKTOP} />;
	}
	const {
		// BILLINGS
		pays,
		orders,
		mutate1Object,
		// ORDERS
		mutate1ObjectInOrders,
		ordersState,
		getOrder,
		// API
		payments,
		coins,
		// LOGIN
		profile,
	} = props;
	const allOrders = payments.orders.data;
	const { control } = ordersState;

	const filteredPaymentOrders = filterPaymentOrders(allOrders, orders.filters);

	// HEADER
	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: allOrders,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: orders.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('orders', { filters: updatedFilters }),
				}}
			>
				{label}
			</Header>
		);
	}

	// ROW
	function getRowRenderer({ key, columns, style }) {
		return (
			<Row key={key} style={style}>
				{columns}
			</Row>
		);
	}

	// INDEX
	function getIndexCellRenderer({ rowData, rowIndex }) {
		return (
			<Cell>
				<Flag
					style={getFlagColorOrderCard(rowData, pays.getOrders, profile)}
					height='20px'
				/>
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	}

	// ORDER
	function getOrderCellRenderer({ rowData }) {
		return (
			<Cell
				onClick={() => {
					if (
						!control.orders.filter(ord => ord.order_id === rowData.order_id)[0]
					) {
						getOrder(rowData.order_id);
					}
					mutate1ObjectInOrders('getOrderInformationModal', {
						order_id: rowData.order_id,
						isOpen: true,
					});
				}}
			>
				{rowData.odt_id}
			</Cell>
		);
	}

	// COST / SALE
	function getCostSaleCellRenderer({ rowData }) {
		const { cost, sale } = rowData;
		return !isNaN(cost) && !isNaN(sale) ? (
			<Cell>
				{cost}% / {sale}%
			</Cell>
		) : (
			<div />
		);
	}

	// BONUS
	function getBonusCellRenderer({ rowData }) {
		let editedOrders = [...orders.editedOrders];
		const idx = _.findIndex(editedOrders, ord => ord.id === rowData.id);

		const coinSymbol = coinsUtils.getDataByCoinId(
			rowData.price?.coinId,
			'symbol',
			coins.data,
		);

		return (
			<Wrapper padding='0' height='40px'>
				{orders.mode === 'view' ? (
					<div>{formatNumber.new(rowData.bonus, coinSymbol, 2)}</div>
				) : (
					<InputNumber
						placeholder='Bono*'
						min={0.0}
						step={0.1}
						value={
							idx !== -1
								? orders.editedOrders[idx].bonus
								: rowData.bonus >= 0.01
								? rowData.bonus
								: undefined
						}
						formatter={value =>
							`${coinSymbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}
						parser={value => {
							value = value.replace(coinSymbol, '');
							value = value.replace(/\$\s?|(,*)/g, '');
							value = value.replace(/ /g, '');
							return value;
						}}
						onChange={value => {
							if (value >= 0.01 || value != rowData.bonus) {
								if (idx === -1) {
									editedOrders.push({
										id: rowData.id,
										bonus: value,
									});
								} else editedOrders[idx].bonus = value || 0.0;
							} else {
								if (idx !== -1) {
									editedOrders = editedOrders.filter(
										ord => ord.id !== rowData.id,
									);
								}
							}
							mutate1Object('orders', { editedOrders });
						}}
					/>
				)}
			</Wrapper>
		);
	}

	return (
		<Wrapper padding='0' className='animated fadeIn'>
			<Spin
				spinning={payments.orders.loading}
				size='large'
				delay={50}
				tip={'Consultando Órdenes...'}
			>
				<Table
					highBackground
					className='desktop-warehouses-list-table'
					width={document.documentElement.clientWidth}
					height={document.documentElement.clientHeight - 115}
					headerHeight={44}
					rowHeight={25}
					rowCount={filteredPaymentOrders.length}
					rowGetter={({ index }) => filteredPaymentOrders[index]}
					rowRenderer={getRowRenderer}
				>
					<Column
						dataKey='index'
						label='#'
						width={50}
						headerRenderer={({ label }) => <Header>{label}</Header>}
						cellRenderer={getIndexCellRenderer}
					/>
					<Column
						dataKey='odt_id'
						label='Orden'
						width={110}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getOrderCellRenderer}
					/>
					<Column
						dataKey='service_name'
						label='Servicio'
						width={260}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='expert_name'
						label='Técnico'
						width={180}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='completed_code_name'
						label='Código Completado'
						width={350}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='amount'
						label={pays.getOrders === 'orders_paid' ? 'Costo' : 'Precio'}
						width={150}
						headerRenderer={({ label }) => <Header>{label}</Header>}
						cellRenderer={cellProps => (
							<AmountCell
								{...cellProps}
								pays={pays}
								coins={coins}
							/>
						)}
					/>
					{pays.getOrders === 'orders_paid' && [
						<Column
							key='charge'
							dataKey='charge'
							label='Venta'
							width={150}
							headerRenderer={props => getHeaderRenderer(props, 'numeric')}
							cellRenderer={cellProps => (
								<AmountCell
									{...cellProps}
									pays={pays}
									coins={coins}
								/>
							)}
						/>,
						<Column
							key=''
							dataKey=''
							label='Costo / Venta'
							width={150}
							headerRenderer={({ label }) => <Header>{label}</Header>}
							cellRenderer={getCostSaleCellRenderer}
						/>,
						<Column
							key='bonus'
							dataKey='bonus'
							label='Bono'
							width={150}
							headerRenderer={props => getHeaderRenderer(props, 'numeric')}
							cellRenderer={getBonusCellRenderer}
						/>,
						<Column
							key='created_at'
							dataKey='created_at'
							label='Creado'
							width={130}
							headerRenderer={props => getHeaderRenderer(props, 'date')}
						/>,
						<Column
							key='updated_at'
							dataKey='updated_at'
							label='Actualizado'
							width={130}
							headerRenderer={props => getHeaderRenderer(props, 'date')}
						/>,
					]}
				</Table>
			</Spin>
		</Wrapper>
	);
}

export default PaymentsOrdersDesktop;
