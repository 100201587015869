import styled from 'styled-components';

const CardContainer3D = styled.div`
	position: ${props =>
		props.position ? props.position : props.theme.cardContainer3D.position};
	display: ${props =>
		props.display ? props.display : props.theme.cardContainer3D.display};
	width: ${props =>
		props.width ? props.width : props.theme.cardContainer3D.width};
	min-width: ${props =>
		props.minWidth ? props.minWidth : props.theme.cardContainer3D.minWidth};
	height: ${props =>
		props.height ? props.height : props.theme.cardContainer3D.height};
	min-height: ${props =>
		props.minHeight ? props.minHeight : props.theme.cardContainer3D.minHeight};
	padding: ${props =>
		props.padding ? props.padding : props.theme.cardContainer3D.padding};
	flex-direction: ${props =>
		props.flexDirection
			? props.flexDirection
			: props.theme.cardContainer3D.flexDirection};
	justify-content: ${props =>
		props.justifyContent
			? props.justifyContent
			: props.theme.cardContainer3D.justifyContent};
	align-items: ${props =>
		props.alignItems
			? props.alignItems
			: props.theme.cardContainer3D.alignItems};
	border-radius: ${props =>
		props.borderRadius
			? props.borderRadius
			: props.theme.cardContainer3D.borderRadius};
	background-color: ${props =>
		props.backgroundColor
			? props.backgroundColor
			: props.theme.cardContainer3D.backgroundColor};
	box-shadow: ${props =>
		props.boxShadow ? props.boxShadow : props.theme.cardContainer3D.boxShadow};
	transition: ${props =>
		props.transition
			? props.transition
			: props.theme.cardContainer3D.transition};
	&:hover {
		transform: ${props => props.theme.cardContainer3D.hover.transform};
	}
	&:hover ~ & {
		transform: ${props =>
			props.theme.cardContainer3D.hover.ampersand.transform};
	}
	&:not(:first-child) {
		margin-left: ${props =>
			props.theme.cardContainer3D.hover.not.firstChild.marginLeft};
	}
`;

export default CardContainer3D;
