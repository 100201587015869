// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Components
import { Wrapper } from 'components';

export default function ToolsOptionsManage(props) {
	const {
		// Props
		warehouses,
		itemStates,
		incomeMethods,
		controlSeries,
		controlSeriesTransactionModal,
		// Actions
		mutate1ObjectInWarehouses,
		onCreateControlSeriesTransaction,
	} = props;
	const theme = useTheme();

	return (
		<Wrapper
			flexDirection='column'
			backgroundColor={theme.colors.primary[500]}
		></Wrapper>
	);
}
