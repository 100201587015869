// Libs
import React, { useContext } from 'react';
// Context
import { ModalBuilderContext } from 'core/context';
// Config
import ModalBuilderConfig from './modal.config';

const ModalBuilder = () => {
	const { payload } = useContext(ModalBuilderContext);

	const CustomModal = ModalBuilderConfig.getAuthorizedModalReactNode(
		payload.modalKey,
	);
	if (!CustomModal) return null;

	return <CustomModal />;
};

export default ModalBuilder;
