// Libs
import React from 'react';
// Components
import Input from '@mui/material/Input';
import { Popover, HamburguerIcon, SearchIcon, CloseIcon } from 'components';
const Select = React.lazy(() => import('components/Select'));
import { CustomToolbar, CustomSearchToolbar } from '../sharedFunctions';
// Styles
import '../index.css';

export function UsersManage(props) {
	const {
		showNavBar,
		title,
		selectNotification,
		mutate1ObjectInUsers,
		searchInData,
	} = props;

	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={title}
			left={<HamburguerIcon button onClick={showNavBar} />}
		/>
	);
}

export function SearchUsersManage(props) {
	const { Option } = Select;
	const { mutate1ObjectInUsers, searchInData } = props;
	const SearchBy = (
		<Select
			className='select-searchBy'
			defaultValue='all'
			value={searchInData.searchBy}
			onChange={value =>
				mutate1ObjectInUsers('control', {
					searchInData: {
						...searchInData,
						searchBy: value,
						showPopover: false,
					},
				})
			}
		>
			<Option value='idc'>Identidad</Option>
			<Option value='name'>Nombre</Option>
			<Option value='division'>División</Option>
			<Option value='application_status'>Relación</Option>
			<Option value='all'>Todos</Option>
		</Select>
	);

	function setPlaceholderSearchBy(searchBy) {
		if (searchBy === 'idc') return 'Identidad';
		else if (searchBy === 'name') return 'Nombre';
		else if (searchBy === 'division') return 'División';
		else if (searchBy === 'application_status') return 'Relación';
		else return 'Todos';
	}

	return (
		<CustomSearchToolbar
			left={
				<Input
					id='toolbar-search-order-input'
					style={{ height: '100%', width: '100%' }}
					autoFocus
					name='data'
					placeholder={setPlaceholderSearchBy(searchInData.searchBy)}
					value={searchInData.data}
					onChange={e =>
						mutate1ObjectInUsers('control', {
							searchInData: {
								...searchInData,
								[e.target.name]: e.target.value,
							},
						})
					}
					startAdornment={
						<Popover
							content={SearchBy}
							placement='rightBottom'
							title='Buscar por'
							trigger='click'
							visible={searchInData.showPopover}
							onVisibleChange={() =>
								mutate1ObjectInUsers('control', {
									searchInData: {
										...searchInData,
										showPopover: !searchInData.showPopover,
									},
								})
							}
						>
							<div>
								<SearchIcon />
							</div>
						</Popover>
					}
					endAdornment={
						<CloseIcon
							button
							onClick={() =>
								mutate1ObjectInUsers('control', {
									searchInData: {
										...searchInData,
										renderToolbar: false,
										data: '',
										searchBy: 'all',
									},
								})
							}
						/>
					}
				/>
			}
		/>
	);
}
