// Utils
import GENERAL from 'utils/constants/general';
const { MODULES } = GENERAL;

// Getters
export const getMyActiveModules = () => ({
	type: MODULES.GET_MY_ACTIVE_MODULES,
});
export const getMyDatasourceAccess = payload => ({
	type: MODULES.GET_MY_DATASOURCE_ACCESS,
	payload,
});
export const getOrderTargets = payload => ({
	type: MODULES.GET_ORDER_TARGETS,
	payload,
});
export const getMarketBanners = () => ({
	type: MODULES.GET_MARKET_BANNERS,
});
export const getMarketModules = () => ({
	type: MODULES.GET_MARKET_MODULES,
});
export const getMarketItems = payload => ({
	type: MODULES.GET_MARKET_ITEMS,
	payload,
});

// Setters
export const mutateDirectProps = keyValuePairs => ({
	type: MODULES.MUTATE_DIRECT_PROPS,
	payload: keyValuePairs,
});
export const mutate1Object = (obj1Name, keyValuePairs) => ({
	type: MODULES.MUTATE_1OBJECT,
	payload: { obj1Name, keyValuePairs },
});
export const mutate1ObjectInMarketplace = (obj1Name, keyValuePairs) => ({
	type: MODULES.MUTATE_MARKETPLACE,
	payload: { obj1Name, keyValuePairs },
});
export const updateMarketItemInstallationStatus = payload => ({
	type: MODULES.UPDATE_MARKETITEM_INSTALLATION_STATUS,
	payload,
});
