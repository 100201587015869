//Libs
import React, { useMemo } from 'react';
//Components
import { Wrapper, Span, Caption, Button, Modal } from 'components';
import CollectorUtils from '../CollectorUtils';

const Confirmation = ({
	docId,
	order,
	duplicateElement,
	collectorLayout,
	onConfirm,
	onCancel,
}) => {
	const [incrementedSortSequence, setIncrementSortSequence] = React.useState(1);
	const { level, element } = duplicateElement;
	const { newElementName, newDuplicatedElement } = useMemo(
		() =>
			CollectorUtils.runDuplicateElementProcess({
				docId,
				orderId: order.order_id,
				level,
				element,
				collectorLayout,
			}),
		[docId, order.order_id, level, element, collectorLayout],
	);
	if (!newElementName || !newDuplicatedElement) return null;

	const newNameLabel = CollectorUtils.getDuplicateLabel(level);

	return (
		<>
			<Span>{`El nombre ${newNameLabel} será:`}</Span>
			<Caption light>{newElementName}</Caption>
			<Wrapper width='100%' justifyContent='space-around'>
				<div style={{ marginRight: '10px' }}>
					<Button mode='secondary' onClick={onCancel}>
						Cancelar
					</Button>
				</div>
				<div>
					<Button
						mode='primary'
						onClick={() => {
							onConfirm(newDuplicatedElement, incrementedSortSequence);
							setIncrementSortSequence(prev => prev + 1);
						}}
					>
						Confirmar
					</Button>
				</div>
			</Wrapper>
		</>
	);
};

const DuplicateCollectorElement = ({
	order,
	docId,
	visible,
	duplicateElement,
	collectorLayout,
	onConfirm,
	onCancel,
}) => {
	return (
		<Modal
			title='Duplicando elemento'
			visible={visible}
			footer={null}
			onCancel={onCancel}
		>
			<Wrapper
				height='160px'
				flexDirection='column'
				justifyContent='space-around'
				alignItems='flex-start'
			>
				<Confirmation
					order={order}
					docId={docId}
					duplicateElement={duplicateElement}
					collectorLayout={collectorLayout}
					onConfirm={onConfirm}
					onCancel={onCancel}
				/>
			</Wrapper>
		</Modal>
	);
};

export default DuplicateCollectorElement;
