// Libs
import React from 'react';
import { Wrapper } from 'components';

export default function AppContainer({ children }) {
	return (
		<Wrapper
			padding='0'
			width='100%'
			height='100%'
			position='relative'
			justifyContent='center'
			highBackground
		>
			{children}
		</Wrapper>
	);
}
