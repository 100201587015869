// Colors
const background = {
	high: '#1F1B24',
	medium: '#262626',
	light: '#3C3C3C',
	disabled: 'rgb(51, 51, 51)',
	transparent: 'transparent',
};
const primary = {
	100: '#CFD8DC',
	500: '#3f3f49',
	900: '#000A12',
};
const text = {
	high: 'rgba(255, 255, 255, .87)',
	medium: '#1eb980',
	disabled: 'rgba(255, 255, 255, 0.2)',
	strongifyDisabled: 'rgba(255, 255, 255, 0.50)',
};

const colors = Object.freeze({
	background,
	primary,
	secondary: {
		100: '#BAE6D1',
		800: 'transparent',
		900: '#003008',
		old: 'transparent',
	},
	text,
	border: {
		color: 'rgba(255, 255, 255, 0.38)',
	},
	inactive: {
		color: 'rgba(255, 255, 255, 0.54)',
	},
	success: {
		color: '#deb887',
	},
	unsuccess: {
		color: '#37474F',
	},
	error: {
		color: '#D32F2F',
	},
	focused: {
		color: 'rgba(255, 255, 255, 0.9)',
	},
	hover: {
		color: text.medium,
		// color: "rgb(35, 161, 239)",
		// color: "rgba(255, 255, 255, 1)",
	},
	fillOpacity: '0.54',
	selected: {
		500: '#37474F',
		12: 'rgba(0,0,0,.2)',
	},
});

const sizes = Object.freeze({
	small: {
		name: 'small',
		width: 16,
		height: 16,
	},
	normal: {
		name: 'normal',
		width: 24,
		height: 24,
	},
	medium: {
		name: 'medium',
		width: 32,
		height: 32,
	},
	large: {
		name: 'large',
		width: 50,
		height: 50,
	},
	xLarge: {
		name: 'xLarge',
		width: 66,
		height: 66,
	},
	text: {
		default: '14px',
		xs: '11px',
		s: '12px',
		m: '13px',
		l: '14px',
		xl: '15px',
		s16: '16px',
		s18: '18px',
		s19: '19px',
		s20: '20px',
		em1: '1em',
		em2: '2em',
		em3: '3em',
	},
});

export default Object.freeze({
	colors,
	sizes,
	// NUEVO DISEÑO:
	caption: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '0.85em',
		lineHeight: '1em',
		letterSpacing: '0.025em',
		color: colors.text.high,
		mixBlendMode: 'normal',
	},
	span: {
		width: 'auto',
		minWidth: 'unset',
		maxWidth: 'unset',
		height: 'auto',
		maxHeight: 'unset',
		margin: 0,
		padding: 0,
		color: colors.text.high,
		fontWeight: 'unset',
		backgroundColor: 'transparent',
		cursor: 'unset',
		overflow: 'unset',
		fontSize: sizes.text.default,
		hover: {
			color: colors.hover.color,
		},
		active: {
			color: colors.text.medium,
		},
		textAlign: 'left',
	},
	title: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: '700',
		fontSize: '1.25em',
		lineHeight: '1.4375em',
		letterSpacing: '0.009375em',
		color: colors.text.high,
		mixBlendMode: 'normal',
	},
	subtitle: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '0.95em',
		letterSpacing: '0.015625em',
		color: colors.text.high,
		mixBlendMode: 'normal',
	},
	caption: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '0.85em',
		lineHeight: '1em',
		letterSpacing: '0.025em',
		color: colors.text.high,
		mixBlendMode: 'normal',
	},
	button: {
		margin: 0,
		height: '2em',
		padding: 0,
		primary: {
			fontFamily: 'Roboto',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '0.78125em',
			lineHeight: '0.8125em',
			textAlign: 'center',
			letterSpacing: '0.046875em',
			textTransform: 'uppercase',
			color: colors.text.high,
		},
		secondary: {
			color: colors.text.medium,
			fontWeight: 'normal',
			focus: {
				color: '#40a9ff',
				backgroundColor: '#3f3f49',
				borderColor: '#40a9ff',
			},
		},
	},
	toolbar: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: '23px',
		letterSpacing: '0.15px',
		mixBlendMode: 'normal',
		opacity: 0.87,
		color: colors.text.high,
		backgroundColor: colors.background.light,
		borders: {
			bottom: `1px solid ${colors.border.color}`,
		},
	},
	// VIEJO DISEÑO:
	fonts: {
		family: 'Roboto',
		secondaryFamily: 'Eczar',
		size: {
			default: '14px',
			xs: '11px',
			s: '12px',
			m: '13px',
			l: '14px',
			xl: '15px',
			s16: '16px',
			s18: '18px',
			s19: '19px',
			s20: '20px',
			em1: '1em',
			em2: '2em',
			em3: '3em',
		},
		colors: {
			default: 'rgba(255, 255, 255, 0.6)',
			primary: 'white',
			secondary: 'gray',
			warn: '#f70',
			concept: 'lightblue',
			value: 'lightgray',
			emphasis_1: 'burlywood',
		},
	},
	tag: {
		margin: '0',
	},
	wrapper: {
		position: 'unset',
		width: 'auto',
		maxWidth: 'unset',
		minWidth: 'unset',
		height: 'auto',
		minHeight: 'unset',
		maxHeight: 'unset',
		margin: '0px',
		padding: '16px',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'unset',
		justifyContent: 'flex-start',
		alignItems: 'center',
		overflow: 'unset',
		backgroundColor: 'transparent',
		borderRadius: 'unset',
		boxShadow: 'unset',
		transition: 'unset',
		cursor: 'unset',
	},
	grid: {
		margin: 0,
		padding: 0,
		display: 'grid',
		position: 'static',
		top: 0,
		left: 0,
		width: 'auto',
		height: 'auto',
		minHeight: 0,
		columns: 'none',
		rows: 'none',
		gridAutoFlow: 'row',
		gap: 0,
		overflowX: 'visible',
		overflowY: 'visible',
		border: 'none',
		zIndex: 0,
	},
	text: {
		position: 'inline-block',
		width: 'auto',
		height: 'auto',
		margin: 0,
		padding: 0,
		textDecoration: 'none',
		border: 0,
		hoverable: {
			background: 'linear-gradient(90deg, #ff8a00, #e52e71)',
			textShadow: 'none',
			backgroundClip: 'text',
			webkitTextfillColor: 'transparent',
			webkitBackgroundClip: 'text',
			cursor: 'pointer',
		},
	},
	checkbox: {
		margin: '0px',
		color: 'rgba(255, 255, 255, 0.6)',
	},
	checkboxGroup: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	iconButton: {
		size: {
			default: {
				height: '48px',
				width: '48px',
				fontSize: '22px',
				padding: '12px',
			},
			s: {
				height: '32px',
				width: '32px',
				fontSize: '14px',
				padding: '9px',
			},
			m: {
				height: '40px',
				width: '40px',
				fontSize: '20px',
				padding: '10px',
			},
			l: {
				height: '48px',
				width: '48px',
				fontSize: '22px',
				padding: '12px',
			},
		},
		margin: 0,
		color: 'rgba(255, 255, 255, 0.54)',
		cursor: 'pointer',
		borderRadius: '50%',
		boxShadow: 'none',
		hover: {
			backgroundColor: 'rgba(0, 0, 0, .08)',
		},
		active: {
			backgroundColor: 'rgba(0, 0, 0, .12)',
		},
		specialHoverable: {
			boxShadow: '3px 2px .5rem #59d',
		},
		transition: 'background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		touchAction: 'manipulation',
	},
	icon: {
		color: 'rgba(255, 255, 255, 0.54)',
	},
	link: {
		margin: 0,
		padding: 0,
		color: 'white',
		backgroundColor: 'transparent',
		hover: {
			color: 'lightskyblue',
		},
		active: {
			color: 'lightskyblue',
		},
	},
	popover: {
		backgroundColor: 'rgba(0,0,0,.85)',
		inner: {
			maxHeight: 'none',
			borderRadius: '5px',
			boxShadow: '0 0 4px white',
			overflow: 'visible',
		},
	},
	tooltip: {
		backgroundColor: 'rgba(0,0,0,.85)',
	},
	collapse: {
		width: '100%',
		padding: 0,
		borders: {
			bottom: '1px solid #3f3f49',
		},
	},
	table: {
		borderRadius: '1px',

		// 076 Premium Dark
		// backgroundImage: "linear-gradient(to right, #434343 0%, black 100%)",

		// 091 Eternal Constance
		// backgroundImage: "linear-gradient(to top, #09203f 0%, #537895 100%)",

		header: {
			margin: 0,
			padding: 0,
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			borderRadius: '1px',
			fontWeight: 'bold',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: '1.41',
			letterSpacing: '.8px',
			textTransform: 'capitalize',
			color: 'white',
			backgroundColor: 'transparent',
		},
		column: {},
		row: {
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
			borderBottom: '0.4px rgba(224,224,225, 0.4) solid',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: '1.41',
			letterSpacing: '0.25px',
			color: 'rgba(255,255,255,.7)',
			hover: {
				fontWeight: 'bold',
			},
			active: {
				fontWeight: 'normal',
			},
		},
		cell: {
			color: 'rgba(255,255,255,.7)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'unset',
		},
	},
	spin: {
		width: 'auto',
		height: 'auto',
		color: 'white',
		borderRadius: '20px',
	},
	flag: {
		minWidth: '5px',
		height: '20px',
		backgroundColor: 'transparent',
		borderRadius: '50%',
		absolute: {
			position: 'absolute',
			left: 0,
		},
	},
	textField: {
		width: 'auto',
		margin: 0,
		height: '33px',
		minHeight: '33px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 1.48,
		letterSpacing: '0.15px',
		textAlign: 'unset',
		color: 'rgba(255, 255, 255, 0.6)',
		backgroundColor: 'rgba(204, 186, 186, 0.08)',
		border: 'none',
		borderBottom: '1px solid #703',
		textArea: {
			height: '70px',
		},
	},
	radio: {
		group: {
			padding: 0,
			backgroundColor: 'transparent',
		},
		item: {
			display: 'block',
			margin: '5px 5px',
			color: 'white',
			disabled: {
				color: 'gray',
			},
		},
		button: {
			display: 'inline-block',
		},
	},
	dropdown: {
		button: {},
	},
	menu: {
		height: 'auto',
		overflow: 'auto',
		color: 'white',
		backgroundColor: 'transparent',
		borderRight: 'none',
		group: {
			color: 'gray',
		},
		submenu: {
			maxHeight: 'unset',
			overflow: 'auto',
			backgroundColor: 'transparent',
		},
		active: {
			backgroundColor: '#3f3f49',
		},
		item: {
			height: '25px',
			lineHeight: '25px',
			color: 'rgba(255, 255, 255, 0.9)',
			backgroundColor: 'transparent',
			hover: {
				backgroundColor: '#3f3f49',
			},
			selected: {
				backgroundColor: '#2c2c36',
			},
			disabled: {
				color: 'gray',
			},
		},
	},
	tabs: {
		width: '100%',
		height: 'auto',
		tabPane: {
			color: 'white',
			backgroundColor: 'transparent',
		},
	},
	divider: {
		color: colors.border.color,
		background: colors.border.color,
		margin: 'unset',
	},
	steps: {
		title: {
			waiting: {
				color: 'gray',
			},
			process: {
				color: 'lightskyblue',
			},
			finish: {
				color: 'white',
			},
			error: {
				color: 'orange',
			},
		},
		icon: {
			waiting: {
				color: 'gray',
			},
		},
	},
	select: {
		width: 'auto',
		margin: 0,
		padding: 0,
	},
	chip: {
		color: 'rgba(255, 255, 255, 0.6)',
		lineHeight: '1.56',
		backgroundColor: 'transparent',
	},
	card: {
		width: '100%',
		height: '100%',
		boxShadow: '0px 10px 13px -7px #000000',
		title: {
			color: 'mediumaquamarine',
		},
		description: {
			fontSize: '14px',
			color: 'mediumaquamarine',
		},
		backgroundColor: 'transparent',
		padding: '10px',
		actions: {
			display: 'flex',
			alignItems: 'center',
			margin: '10px 0 0 0',
			padding: 0,
			borderTop: 'none',
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
			items: {
				colors: {
					default: 'rgba(255, 255, 255, 0.54)',
					primary: 'white',
					secondary: 'orange',
				},
				borderRight: '1px solid #3f3f49',
			},
		},
	},
	cardContainer3D: {
		position: 'relative',
		display: 'flex',
		width: 'auto',
		minWidth: 0,
		height: 'auto',
		minHeight: 0,
		padding: 0,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '16px',
		backgroundColor: '#17141d',
		boxShadow: '-1rem 0 3rem #000',
		transition: '0.2s',
		hover: {
			transform: 'translateY(-1rem)',
			ampersand: {
				transform: 'translateX(130px)',
			},
			not: {
				firstChild: {
					marginLeft: '-130px',
				},
			},
		},
	},
	drawer: {
		width: '100%',
		maxWidth: 'unset',
		padding: 0,
		header: {
			fontWeight: 'unset',
			color: 'white',
			backgroundColor: colors.background.high,
			boxShadow: '0 0 8px white',
			zIndex: 10,
		},
		content: {
			border: 'unset',
			backgroundColor: colors.background.high,
		},
	},
	avatar: {
		border: {
			borderRadius: '50%',
			background1: 'rgb(41,38,96)',
			background2: colors.background.medium,
			// "linear-gradient(90deg, rgba(41,38,96,1) 12%, rgba(241,169,109,1) 48%, rgba(0,212,255,1) 100%)",
		},
		skeleton: {
			color: '#202020',
			highlightColor: '#444',
		},
	},
	inputNumber: {
		margin: 0,
		width: 'auto',
		height: '33px',
		color: 'rgba(255, 255, 255, 0.6)',
		backgroundColor: 'rgba(204, 186, 186, 0.08)',
	},
	empty: {
		margin: 0,
	},
	image: {
		position: 'static',
		top: 'auto',
		left: 'auto',
		opacity: 1,
		width: '100%',
		maxWidth: 'unset',
		height: '100%',
		objectFit: 'unset',
	},
	scrollbar: {
		width: '0px',
		height: '0px',
		thumb: {
			background: '#201c29',
			borderRadius: '10px',
			boxShadow:
				'inset 2px 2px 2px hsla(0, 0%, 100%, 0.6), inset -2px -2px 2px rgba(0, 0, 0, 0.25)',
		},
		track: {
			background:
				'linear-gradient(90deg, #201c29, #201c29 1px, #17141d 0, #17141d)',
		},
		light: {
			width: '10px',
			height: '100%',
			thumb: {
				background: colors.primary[100],
				borderRadius: '10px',
				boxShadow: 'transparent',
			},
			track: {
				background: colors.background.medium,
			},
		},
	},
	datepicker: {
		margin: 0,
		backgroundColor: 'rgba(204, 186, 186, 0.08)',
	},
	timepicker: {
		margin: 0,
		backgroundColor: 'rgba(204, 186, 186, 0.08)',
	},
});
