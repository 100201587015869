// Libs
import React from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import Icon from './Icon';

const PointTextIcon = ({ margin = '0 10px', onClick }) => {
	const { theme } = useTheme();

	return (
		<div style={{ margin }}>
			<Icon fill={theme._inactiveColor} onClick={onClick} />
		</div>
	);
};

export default PointTextIcon;
