import Immutable from 'immutable';
import { filterDataByFilterOptions } from '../';

export function filterTransactions(data, filters) {
	let filteredData = Immutable.List(data).toJS();

	filteredData = filterDataByFilterOptions(filteredData, filters);
	return filteredData;
}

export function filterAreas(inventoryReception, warehouseAreas) {
	return inventoryReception.zoneId
		? warehouseAreas.data.filter(
				area => area.warehouse_zone_id === inventoryReception.zoneId,
		  )
		: [];
}

export function filterPackages(inventoryReception, warehousePackages) {
	return inventoryReception.areaId
		? warehousePackages.data.filter(
				pckg => pckg.warehouse_area_id === inventoryReception.areaId,
		  )
		: [];
}
