import {
	TxtScrapingProcess,
	ReplacementValueProcess,
	FieldAnchorProcess,
	FormComponentProcess,
} from './Process';

export default class TxtOrderScraping {
	constructor(txtOrders, fields, formComponentValues) {
		this.txtOrders = txtOrders;
		this.fields = fields;
		this.formComponentValues = formComponentValues;
	}

	async run() {
		return this.txtOrders
			.map(txtOrder => new TxtScrapingProcess(txtOrder, this.fields).run())
			.map(order =>
				new FormComponentProcess(
					order,
					this.fields,
					this.formComponentValues,
				).run(),
			)
			.map(order => new FieldAnchorProcess(order, this.fields).run())
			.map(order => new ReplacementValueProcess(order, this.fields).run());
	}
}
