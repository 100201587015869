import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { grid, borderRadius } from './constants';
import { Draggable } from 'react-beautiful-dnd';
import OrderList from './primatives/order-list';
import Title from './primatives/title';

// components
import { Badge } from 'antd';

const Container = styled.div`
	margin: ${grid / 2}px;
	margin-top: ${grid}px;
	display: flex;
	flex-direction: column;
	height: 40vh;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-top-left-radius: ${borderRadius}px;
	border-top-right-radius: ${borderRadius}px;
	background-color: ${({ isDragging }) => (isDragging ? 'blue' : '#222526')};
	transition: background-color 0.2s ease;
	color: white;
	width: 330px;

	&:hover {
		background-color: green;
	}
`;

const Column = (
	{ id, title, counters, orders, zoneId, index, isScrollable },
	ref,
) => {
	return (
		<Draggable draggableId={id} index={index}>
			{(provided, snapshot) => (
				<Container
					{...provided.draggableProps}
					ref={containerRef => {
						provided.innerRef(containerRef);

						if (!ref) return;
						ref.current = containerRef;
					}}
				>
					<Header isDragging={snapshot.isDragging}>
						<Title
							isDragging={snapshot.isDragging}
							{...provided.dragHandleProps}
							aria-label={`${title} order list`}
							ellipsis
						>
							{title}
						</Title>
						<div style={{ margin: '0px 10px 10px 0px' }}>
							<Badge
								count={counters.left ?? 0}
								style={{
									background: 'teal',
								}}
							/>
							<Badge
								count={counters.right ?? 0}
								style={{
									background: 'orange',
									marginLeft: 5,
								}}
							/>
						</div>
					</Header>
					<OrderList
						listId={id}
						zoneId={zoneId}
						listType='ORDER'
						style={{
							backgroundColor: snapshot.isDragging ? 'blue' : null,
						}}
						orders={orders}
						internalScroll={isScrollable}
					/>
				</Container>
			)}
		</Draggable>
	);
};

export default forwardRef(Column);
