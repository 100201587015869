//Libs
import React from 'react';
//Components
import { Wrapper, Span, Button, Modal } from 'components';

const CompleteConfirmation = ({ onConfirm, onCancel }) => {
	return (
		<>
			<Span>
				Una vez que el reporte sea finalizado, ya no se podrá modificar. ¿Desea
				continuar?
			</Span>
			<Wrapper width='100%' justifyContent='space-around'>
				<div style={{ marginRight: '10px' }}>
					<Button mode='secondary' onClick={onCancel}>
						Cancelar
					</Button>
				</div>
				<div>
					<Button mode='primary' onClick={onConfirm}>
						Confirmar
					</Button>
				</div>
			</Wrapper>
		</>
	);
};

const SendReport = ({ visible, onConfirm, onCancel }) => {
	return (
		<Modal
			visible={visible}
			title='Enviando Reporte'
			footer={null}
			onCancel={onCancel}
		>
			<Wrapper
				height='160px'
				flexDirection='column'
				justifyContent='space-around'
			>
				<CompleteConfirmation onConfirm={onConfirm} onCancel={onCancel} />
			</Wrapper>
		</Modal>
	);
};

export default SendReport;
