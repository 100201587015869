//Libs
import React, { useContext } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import CustomUseTheme from 'hooks/useTheme';
// Utils
import GENERAL from 'utils/constants/general';
import { GlobalUtils, ProjectUtils, ServiceUtils, OrderUtils } from 'utils';
//Context
import { ModalBuilderContext } from 'core/context';
//Config
import ModalBuilderConfig from 'core/ModalBuilder/modal.config';
//Actions
import { setOrderProps } from 'screens/Orders/actions';
//Components
import { Wrapper, Dropdown, IconButton, Menu, MoreIcon } from 'components';
//Selectors
import { selectProfile } from 'screens/Login/selectors';

import SendReportButton from './SendReportButton';
import { updateStateAuditedTransactionDoc } from '../actions';
import { selectReviewManage } from '../selectors';
//Keywords
import CollectorUtils from '../CollectorUtils';
//Keywords
import COLLECTOR_KEYWORDS from '../keywords';

const { ENV } = GENERAL;

const EditButton = ({ reviewManage, updateStateAuditedTransactionDoc }) => {
	const { orderId, transactionDocs } = reviewManage;
	const { docId, updateState } = transactionDocs;
	const onClick = state =>
		updateState[docId] !== COLLECTOR_KEYWORDS.TRANSACTION_DOCS.STATUS.LOADING &&
		updateStateAuditedTransactionDoc({ docId, orderId, state });

	if (!docId) return null;

	return (
		<Wrapper
			padding='0'
			onClick={() => onClick(COLLECTOR_KEYWORDS.TRANSACTION_DOCS.STATE.OPEN)}
		>
			Editar
			{updateState[docId] ===
				COLLECTOR_KEYWORDS.TRANSACTION_DOCS.STATUS.LOADING && (
				<IconButton type='loading' size='s' />
			)}
		</Wrapper>
	);
};

const UpdateReportState = ({
	doc,
	reviewManage,
	updateStateAuditedTransactionDoc,
}) => {
	return (
		<Menu.Group title='Estado del reporte'>
			<Menu.Item>
				{doc.state === COLLECTOR_KEYWORDS.TRANSACTION_DOCS.STATE.OPEN && (
					<SendReportButton itemStyle />
				)}
				{doc.state === COLLECTOR_KEYWORDS.TRANSACTION_DOCS.STATE.CLOSED && (
					<EditButton
						reviewManage={reviewManage}
						updateStateAuditedTransactionDoc={updateStateAuditedTransactionDoc}
					/>
				)}
			</Menu.Item>
		</Menu.Group>
	);
};

const ReportOptions = ({ docId, order }) => {
	const theme = useTheme();
	const { theme: customTheme, isDarkTheme } = CustomUseTheme();
	const dispatch = useDispatch();

	const { setModal } = useContext(ModalBuilderContext);
	const modalKeys = ModalBuilderConfig.getAuthorizedModalKeys();

	// Collector photo config
	const collectorPhotoProps = GlobalUtils.selectCurrentProps([
		ProjectUtils.getProjectPropsFromOrder(
			ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
			order,
		),
		ServiceUtils.getServicePropsFromOrder(
			ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
			order,
		),
		OrderUtils.getOrderPropsFromOrder(
			ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
			order,
		),
	]);
	return (
		<Menu.Wrapper>
			{docId && order.odt_id && (
				<Menu.Group title='Ver Reporte PDF'>
					<Menu.Item
						key='ViewPDF'
						style={
							!collectorPhotoProps.required && {
								backgroundColor: isDarkTheme
									? theme.menu.item.selected.backgroundColor
									: customTheme._surfaceBackgroundColor,
							}
						}
						onClick={() =>
							setModal(modalKeys.orderReportsModal, {
								selectedOTDId: docId,
								selectedODTId: order.odt_id,
							})
						}
					>
						Ver PDF
					</Menu.Item>
				</Menu.Group>
			)}

			<Menu.Group title='Reporte requerido'>
				<Menu.Item
					key='required'
					style={
						collectorPhotoProps.required && {
							backgroundColor: isDarkTheme
								? theme.menu.item.selected.backgroundColor
								: customTheme._surfaceBackgroundColor,
						}
					}
					onClick={() =>
						dispatch(
							setOrderProps(order.order_id, 'setCollectorPhotoProps', {
								required: true,
							}),
						)
					}
				>
					Requerido
				</Menu.Item>
				<Menu.Item
					key='optional'
					style={
						!collectorPhotoProps.required && {
							backgroundColor: isDarkTheme
								? theme.menu.item.selected.backgroundColor
								: customTheme._surfaceBackgroundColor,
						}
					}
					onClick={() =>
						dispatch(
							setOrderProps(order.order_id, 'setCollectorPhotoProps', {
								required: false,
							}),
						)
					}
				>
					Opcional
				</Menu.Item>
			</Menu.Group>
			<Menu.Group title='Fuente de Fotos'>
				<Menu.Item
					key='camera'
					style={
						collectorPhotoProps.capture === 'environment' && {
							backgroundColor: isDarkTheme
								? theme.menu.item.selected.backgroundColor
								: customTheme._surfaceBackgroundColor,
						}
					}
					onClick={() =>
						dispatch(
							setOrderProps(order.order_id, 'setCollectorPhotoProps', {
								capture: 'environment',
							}),
						)
					}
				>
					Cámara
				</Menu.Item>
				<Menu.Item
					key='gallery'
					style={
						collectorPhotoProps.capture === false && {
							backgroundColor: isDarkTheme
								? theme.menu.item.selected.backgroundColor
								: customTheme._surfaceBackgroundColor,
						}
					}
					onClick={() =>
						dispatch(
							setOrderProps(order.order_id, 'setCollectorPhotoProps', {
								capture: false,
							}),
						)
					}
				>
					Galería
				</Menu.Item>
			</Menu.Group>
		</Menu.Wrapper>
	);
};

const OptionMenu = ({
	docId,
	order,
	doc,
	reviewManage,
	updateStateAuditedTransactionDoc,
}) => {
	return (
		<Menu.Wrapper>
			{doc && (
				<UpdateReportState
					doc={doc}
					reviewManage={reviewManage}
					updateStateAuditedTransactionDoc={updateStateAuditedTransactionDoc}
				/>
			)}

			<ReportOptions docId={docId} order={order} />
		</Menu.Wrapper>
	);
};

const OptionList = ({
	//Props
	order,
	//Redux
	reviewManage,
	updateStateAuditedTransactionDoc,
	profile,
}) => {
	const { transactionDocs } = reviewManage;
	const { docId, data } = transactionDocs;

	//Get selected transaction doc
	const doc = React.useMemo(
		() =>
			docId && CollectorUtils.getSelectedDocFromTransactionDocs(data, docId),
		[data, docId],
	);

	// if (!doc || !CollectorUtils.checkEditOTDCollectors(doc, profile)) {
	// 	return null;
	// }

	return (
		<Dropdown.Wrapper
			trigger={['click']}
			overlay={
				<OptionMenu
					docId={docId}
					order={order}
					doc={doc}
					reviewManage={reviewManage}
					updateStateAuditedTransactionDoc={updateStateAuditedTransactionDoc}
				/>
			}
		>
			<div>
				<MoreIcon button hoverable />
			</div>
		</Dropdown.Wrapper>
	);
};

const mapStateToProps = state => ({
	reviewManage: selectReviewManage(state),
	profile: selectProfile(state),
});
const actions = {
	updateStateAuditedTransactionDoc,
};

export default connect(mapStateToProps, actions)(OptionList);
