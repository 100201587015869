// Libs
import cx from 'classnames';
// Hooks
import useTheme from 'hooks/useTheme';
// Styles
import styles from './Span.module.css';

const SpanStyled = props => {
	const { theme, sharedStyles } = useTheme();
	return (
		<span
			onClick={props.onClick}
			style={{
				width: props.width,
				maxWidth: props.maxWidth,
				maxHeight: props.maxHeight,
				overflow: props.overflow,
				margin: props.margin,
				color: props.overlayColor
					? theme._secondaryBackgroundColor
					: props.color,
				fontWeight: props.fontWeight,
			}}
			className={cx(
				sharedStyles.standardTextStructure,
				styles.span,
				theme.mediumEmphasisColor,
				{
					[sharedStyles.hoverable]: !!props.hoverable,
					[sharedStyles.wordBreak]: !!props.wordBreak,
					[sharedStyles.ellipsis]: !!props.ellipsis,
					[sharedStyles[`textAlign_${props.textAlign}`]]: !!props.textAlign,
					[theme.highEmphasisColor]: !!props.emphasis,
					[theme.hoverable]: !!props.hoverable,
					[theme.secondaryColor]: !!props.selected,
					[theme.inactiveColor]: !!props.disabled,
					[theme.required]: !!props.required,
					[theme.warningColor]: !!props.warning,
				},
			)}
		>
			{props.value || props.children}
		</span>
	);
};

export default SpanStyled;
