export default class AbsAuthorization {
	static _ROLES = require('./roles');

	constructor(profile) {
		if (new.target === AbsAuthorization) {
			throw new Error("You cann't instantiate an abstract class");
		}
		this.roles = profile?.user?.roles || {};
	}

	checkSupervisorL2Manager() {
		return (
			AbsAuthorization._ROLES.SUPERVISOR.L2_MANAGER ||
			AbsAuthorization._ROLES.SUPERVISOR.L3_MANAGER
		);
	}

	checkSupervisorL3Manager() {
		return AbsAuthorization._ROLES.SUPERVISOR.L3_MANAGER;
	}

	checkOrderL2Manager() {
		return (
			AbsAuthorization._ROLES.ORDERS.L2_MANAGER ||
			AbsAuthorization._ROLES.ORDERS.L3_MANAGER
		);
	}
	checkCatalogueL2Manager() {
		return AbsAuthorization._ROLES.INVENTORY.CATALOGUE.L2;
	}
	checkTransaccionL2Manager() {
		return AbsAuthorization._ROLES.INVENTORY.TRANSACTION.L2;
	}

	checkPaymentL2Manager() {
		return AbsAuthorization._ROLES.PAYMENT.L2_MANAGER;
	}
}
