// Libs
import _ from 'underscore';
import Immutable from 'immutable';
import dayjs from 'dayjs';
// Utils
import { normalize } from 'utils/libs';
import GENERAL from 'utils/constants/general';
import { filterConflictsImporting } from './filters/Orders/ConflictsImporting';
import GlobalUtils from './GlobalUtils';
const { ENV } = GENERAL;

export default class OrderUtils {
	static getOrderPropsFromOrder(key, order) {
		if (!key || !order) return {};

		const props = order.props || order.orderProps;
		if (!props) return {};

		if (typeof props === 'object' && !props[key]) return {};
		if (typeof props[key] !== 'object') return {};
		return props[key];
	}

	static checkImportedFileType(file) {
		if (!file) throw new Error('File not found');
		switch (file.type) {
			case 'text/plain':
				return 'txt';
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				return 'xlsx';
			default:
				return null;
		}
	}

	static async readTxtFile(file, dataStructureObject) {
		if (!file || !dataStructureObject)
			throw new Error(
				'No se encontró el archivo o el objeto de estructura de datos',
			);

		return new Promise((resolve, reject) => {
			let actualDivisor = null;
			let actualColumn = 0;

			const getDivisor = () => {
				return actualDivisor;
			};

			const setDivisor = divisor => {
				actualDivisor = divisor;
			};

			const getColumn = () => {
				return actualColumn;
			};

			const setColumn = colNumber => {
				actualColumn = colNumber;
			};

			const getDataItemBySeparator = (subLine, separatorIdx) => {
				const keySlice = subLine.slice(0, separatorIdx).trim().toUpperCase();
				const valueSlice = subLine.slice(separatorIdx + 1).trim();

				// ADD KEY -> ORDER DATA
				if (!keySlice || keySlice === '') return null;
				return {
					key: keySlice,
					value: valueSlice || '',
				};
			};

			const addKeyToAddedList = (key, addedKeys) => {
				if (!Array.isArray(addedKeys)) {
					return [key];
				} else {
					addedKeys.push(key);
					return addedKeys;
				}
			};

			const replaceLine = (line, stringToReplace) => {
				return line.replace(stringToReplace, '').trim();
			};

			const getKeyInLineIdx = (line, key) => {
				return line.toLowerCase().indexOf(key.toLowerCase());
			};

			const canAddItemFromLine = (key, addedKeys) => {
				return !Array.isArray(addedKeys) || addedKeys.indexOf(key) === -1;
			};

			const getFoundInLine = (
				line,
				{
					keys = [],
					columns = [],
					discardColumns = [],
					discardKeys = [],
					separator = '',
				} = {},
			) => {
				return !![...keys, ...columns, ...discardColumns, ...discardKeys].find(
					key =>
						line.toLowerCase().indexOf(`${key}${separator}`.toLowerCase()) !==
						-1,
				);
			};

			const normalizeText = text => {
				if (
					!Array.isArray(dataStructureObject?.normalize?.replace) ||
					dataStructureObject.normalize.replace.length === 0
				) {
					return text;
				}

				return dataStructureObject.normalize.replace
					.reduce((acc, replaceObj) => {
						return acc.replaceAll(replaceObj.oldStr, replaceObj.newStr);
					}, text)
					.trim();
			};

			const getSplittedLine = (text, isNormalizeCol) => {
				const replace =
					dataStructureObject?.normalize?.splittedLineSettings?.replace;
				const splitChar =
					dataStructureObject?.normalize?.splittedLineSettings?.splitChar ||
					'\n';
				const filterLines =
					dataStructureObject?.normalize?.splittedLineSettings?.filterLines ||
					[];
				let newText = text;

				if (
					isNormalizeCol &&
					Array.isArray(getDivisor()?.normalizeColumn?.replace) &&
					getDivisor().normalizeColumn.replace.length > 0
				) {
					newText = getDivisor().normalizeColumn.replace.reduce(
						(acc, obj) => acc.replaceAll(obj.oldStr, obj.newStr),
						newText,
					);
				}

				if (Array.isArray(replace) && replace.length > 0) {
					newText = replace.reduce(
						(acc, obj) => acc.replaceAll(obj.oldStr, obj.newStr),
						newText,
					);
				}

				return newText
					.split(splitChar)
					.map(ln => ln.trim())
					.filter(ln => filterLines.indexOf(ln) === -1);
			};

			const getSplittedOrders = textFormatted => {
				return (
					textFormatted
						// Splitting
						.split(dataStructureObject?.orderLimiter)
						// Clear unnecessary data
						.filter(
							order =>
								order
									.toLowerCase()
									.indexOf(dataStructureObject?.orderIdentifier) !== -1,
						)
				);
			};

			const getOrders = splittedOrders => {
				const setDivisorLineSuccess = (line, accOrderData) => {
					const divisor =
						Array.isArray(dataStructureObject?.divisors) &&
						dataStructureObject.divisors.find(
							div => line.indexOf(div.text) !== -1,
						);

					if (divisor) {
						setDivisor({ ...divisor });
						setColumn(0);

						accOrderData.push({
							key: divisor.id,
							value: divisor.text,
						});
					}

					return divisor;
				};

				const getLines = (order, divisors) => {
					const newOrder = divisors.reduce((acc, divisor) => {
						return acc.replaceAll(divisor.text, `${divisor.text}\n`);
					}, order);

					return newOrder
						.split('\n')
						.map(ln => ln.trim())
						.filter(ln => ln !== '' && ln !== '@');
				};

				const getTemplateProps = () => {
					const keys = Array.isArray(getDivisor().keys)
						? getDivisor().keys
						: [];
					const columns = Array.isArray(getDivisor().columns)
						? getDivisor().columns
						: [];
					const discardColumns = Array.isArray(getDivisor().discardColumns)
						? getDivisor().discardColumns
						: [];
					const discardKeys = Array.isArray(getDivisor().discardKeys)
						? getDivisor().discardKeys
						: [];

					return { keys, columns, discardColumns, discardKeys };
				};

				const concatFloatLineToPrevConcept = (keyFoundInLine, accOrderData) => {
					return (
						!keyFoundInLine &&
						Array.isArray(getDivisor().addedKeys) &&
						getDivisor().addedKeys.length > 0 &&
						accOrderData.length > 0
					);
				};

				const isNotConcatenableKey = key => {
					const notConcatenableKeys = getDivisor().notConcatenableKeys;
					return (
						Array.isArray(notConcatenableKeys) &&
						notConcatenableKeys.findIndex(_key => _key === key) !== -1
					);
				};

				const runDivisorTypeKeyValue = (line, accOrderData) => {
					const { keys, discardKeys } = getTemplateProps();
					const splittedLine = getSplittedLine(line);

					for (let spLine of splittedLine) {
						// KEY FOUND?
						const keyFoundInLine = getFoundInLine(spLine, {
							keys,
							separator: getDivisor().separator,
						});

						// MUST CONCAT FLOAT VALUE?
						if (concatFloatLineToPrevConcept(keyFoundInLine, accOrderData)) {
							const lastIndex = accOrderData.length - 1;
							const lastAddedKey =
								getDivisor().addedKeys[getDivisor().addedKeys.length - 1];

							if (lastAddedKey && !isNotConcatenableKey(lastAddedKey))
								accOrderData[lastIndex] = {
									key: lastAddedKey.trim(),
									value: `${accOrderData[lastIndex].value} ${spLine}`.trim(),
								};

							// REPLACE LINE
							spLine = replaceLine(spLine, spLine);
						}

						// LINE INCLUDE A KEYWORD?
						for (const key of keys) {
							const keyInLineIdx = getKeyInLineIdx(spLine, key);

							if (
								keyInLineIdx !== -1 &&
								canAddItemFromLine(key, getDivisor().addedKeys)
							) {
								const subLine = spLine.slice(keyInLineIdx);

								// KEY : VALUE SPLIT
								const separatorIdx = subLine.indexOf(getDivisor().separator);

								if (separatorIdx !== -1) {
									const item = getDataItemBySeparator(subLine, separatorIdx);
									if (item) {
										accOrderData.push(item);

										// PUSH KEY -> ADDEDKEYS
										setDivisor({
											...getDivisor(),
											addedKeys: addKeyToAddedList(key, getDivisor().addedKeys),
										});

										// REPLACE LINE
										spLine = replaceLine(spLine, subLine);
									}
								}
							}
						}
					}

					return accOrderData;
				};

				const runDivisorTypeColumn = (line, accOrderData) => {
					const { columns, keys, discardColumns } = getTemplateProps();

					const runLineIncludeSeparator = () => {
						const splittedLine = getSplittedLine(line);

						for (let spLine of splittedLine) {
							const keyFoundInLine = getFoundInLine(spLine, {
								keys,
								separator: getDivisor().separator,
							});

							// LINE INCLUDE KEYWORD?
							if (keyFoundInLine) {
								for (const key of keys) {
									const keyInLineIdx = getKeyInLineIdx(spLine, key);

									if (
										keyInLineIdx !== -1 &&
										canAddItemFromLine(key, getDivisor().addedKeys)
									) {
										const subLine = spLine.slice(keyInLineIdx);

										// KEY : VALUE SPLIT
										const separatorIdx = subLine.indexOf(
											getDivisor().separator,
										);

										if (separatorIdx !== -1) {
											const item = getDataItemBySeparator(
												subLine,
												separatorIdx,
											);
											if (item) {
												accOrderData.push(item);

												// PUSH KEY -> ADDEDKEYS
												setDivisor({
													...getDivisor(),
													addedKeys: addKeyToAddedList(
														key,
														getDivisor().addedKeys,
													),
												});

												// REPLACE LINE
												spLine = replaceLine(spLine, subLine);
											}
										}
									}
								}
							}
						}
					};

					const runLineIncludeColumnValue = () => {
						const columnFoundInLine = getFoundInLine(line, {
							columns,
						});

						if (!columnFoundInLine) {
							const splittedLine = getSplittedLine(line, true);

							// for (let spLine of splittedLine) {
							for (const spLine of splittedLine) {
								const discardColumnFoundInLine = getFoundInLine(spLine, {
									discardColumns,
								});

								if (!discardColumnFoundInLine) {
									if (
										getColumn() === columns.length ||
										getColumn() === splittedLine.length
									) {
										setColumn(0);
									}

									const key = columns[getColumn()];

									accOrderData.push({ key, value: spLine.trim() });

									// PUSH KEY -> ADDEDKEYS
									setDivisor({
										...getDivisor(),
										addedKeys: addKeyToAddedList(key, getDivisor().addedKeys),
									});

									setColumn(getColumn() + 1);
								}
							}
						}
					};

					const separatorIdx = line.indexOf(getDivisor().separator);

					// LINE INCLUDE SEPARATOR?
					if (separatorIdx !== -1) runLineIncludeSeparator();
					// LINE INCLUDE COLUMN AND NOT SEPARATOR?
					else runLineIncludeColumnValue();

					return accOrderData;
				};

				return splittedOrders.reduce((accOrders, order) => {
					const lines = getLines(order, dataStructureObject?.divisors);

					setDivisor(null);
					setColumn(0);

					const orderData = lines.reduce((accOrderData, line) => {
						// Get Actual Divisor
						// LINE IS DIVISOR?
						if (setDivisorLineSuccess(line, accOrderData)) return accOrderData;
						// LINE IS NOT DIVISOR?
						if (getDivisor()) {
							// DIVISOR TYPE: KEY:VALUE
							if (getDivisor().type === 'key:value') {
								return runDivisorTypeKeyValue(line, accOrderData);
							}
							// DIVISOR TYPE: COLUMN
							else if (getDivisor().type === 'column') {
								return runDivisorTypeColumn(line, accOrderData);
							}
						}
						return accOrderData;
					}, []);

					accOrders.push(orderData);

					return accOrders;
				}, []);
			};

			const formatOrders = orders => {
				return orders.reduce((acc, order) => {
					const row = {};
					order.forEach(col => {
						if (col.key) {
							if (!row[col.key]) {
								row[col.key] = col.value;
							} else {
								row[col.key] = row[col.key].concat('\n').concat(col.value);
							}
						}
					});
					acc.push(row);
					return acc;
				}, []);
			};

			const reader = new FileReader();
			reader.onload = e => {
				const text = e.target.result;
				// Normalize Data
				const textFormatted = normalizeText(text);
				// Get Splitted orders
				const splittedOrders = getSplittedOrders(textFormatted);
				// Get Orders
				const orders = getOrders(splittedOrders);
				// Format Orders
				const ordersFormatted = formatOrders(orders);

				resolve({
					fileName: file.name,
					jsonFile: Immutable.List(ordersFormatted).toJS(),
				});
			};

			reader.onerror = err => {
				reject(err);
			};

			reader.readAsText(file);
		});
	}

	static processImportOrders({
		items,
		selectedProjectId,
		templateValidator,
		templateValidatorFields,
		profile,
		states,
		services,
		clientCategories,
		sendToastHandler,
	}) {
		const { organization } = profile;
		const algorithm = organization?.algorithms?.orders?.import;
		const orderPrefix = organization?.settings?.order_prefix;
		const now = dayjs();

		const { odtIdField, stateIdField, serviceIdField, clientCategoryIdField } =
			templateValidatorFields;

		if (!odtIdField) {
			sendToastHandler({
				message: `El template seleccionado no cuenta con el campo requerido: [odt_id]. Por favor contacte a su administrador`,
				type: 'warn',
			});
			return;
		}
		if (!stateIdField) {
			sendToastHandler({
				message: `El template seleccionado no cuenta con el campo requerido: [order_state_id]. Por favor contacte a su administrador`,
				type: 'warn',
			});
			return;
		}
		if (!serviceIdField) {
			sendToastHandler({
				message: `El template seleccionado no cuenta con el campo requerido: [order_service_id]. Por favor contacte a su administrador`,
				type: 'warn',
			});
			return;
		}

		//* ** IMPORT V1 ****//
		function importV1() {
			const duplicatedOrders = {};
			const rows = Immutable.List(items).toJS();

			// ADD DYNAMIC DATA?
			if (templateValidator.props?.addDynamicData) {
				rows.forEach((row, idx) => {
					if (!row.dynamicData) row.dynamicData = items[idx];
				});
			}

			return (
				rows
					// REMOVE INVALID OR EMPTY ROWS
					.filter(row => {
						let returnRow = true;
						// REMOVE NULLISH VALUES
						if (
							row[odtIdField.name] === undefined ||
							row[odtIdField.name] === null ||
							row[odtIdField.name] === ''
						) {
							returnRow = false;
						}
						// IGNORE SPECIFIC STATES
						else if (
							stateIdField &&
							Array.isArray(stateIdField.ignore) &&
							stateIdField.ignore.length > 0 &&
							stateIdField.ignore.indexOf(
								normalize(row[stateIdField.name]).toUpperCase().trim(),
							) !== -1
						) {
							returnRow = false;
						}
						return returnRow;
					})
					// REMOVE DUPLICATE ROWS
					.filter(row => {
						const exists = !duplicatedOrders[row[odtIdField.name]] || false;
						duplicatedOrders[row[odtIdField.name]] = true;
						return exists;
					})
					// RESET, NORMALIZE & MAPPING DATA
					.map(row => {
						// Remove order prefix
						row[odtIdField.name] = OrderUtils.filterPrefixOrder(
							row[odtIdField.name],
							orderPrefix,
						);

						// Iterate Validate Fields Template
						for (const fieldKey in templateValidatorFields) {
							const field = templateValidatorFields[fieldKey];
							// Reset
							if (!row[field.name]) row[field.name] = '';
							// Normalize
							row[field.name] =
								field.type === 'number'
									? row[field.name]
									: normalize(row[field.name]).toUpperCase().trim();

							// Get Data (Special Method)
							if (field.getData?.method) {
								switch (field.getData.method) {
									case 'firstNumberSequence': {
										const values = row[field.name]
											.split(
												field.getData.splitSeparator,
												field.getData.splitNumberSeparators,
											)
											.map(val => val.trim());

										const numberFound = values.find(val => {
											const numValue = parseInt(val[0]);
											return !isNaN(numValue) && numValue > 0;
										});

										if (numberFound) {
											row[field.id] = numberFound;
										}
									}
									// Add new manage method case
								}
							}

							// Concat Data?
							if (!field.concat) {
								row[field.id] = row[field.name];
							} else {
								// Options:
								const keepOriginalId =
									Array.isArray(field.concat.options) &&
									field.concat.options.indexOf('keepOriginalId') !== -1;

								if (field.concat.value) {
									if (field.concat.value === 'now') {
										row[field.id] = `${
											keepOriginalId ? row[field.id] : row[field.name]
										} ${now.format(field.concat.format)}`;
									} else {
										row[field.id] = `${
											keepOriginalId ? row[field.id] : row[field.name]
										} ${field.concat.value}`;
									}
								} else if (field.concat.field) {
									row[field.id] = `${
										keepOriginalId ? row[field.id] : row[field.name]
									} ${row[field.concat.field]}`;
								}
							}

							// Hash values
							if (field.hash && row[field.id]) {
								row[field.id] = row[field.id].replaceAll(' ', '.');
							}

							// Replace values
							if (Array.isArray(field.replace) && field.replace.length > 0) {
								row[field.id] = field.replace.reduce(
									(acc, { oldStr, newStr }) => {
										return acc.replaceAll(oldStr, newStr);
									},
									row[field.id],
								);
							}

							// Trim values
							if (typeof row[field.id] === 'string')
								row[field.id] = row[field.id].trim();
							if (typeof row[field.name] === 'string')
								row[field.name] = row[field.name].trim();
						}

						// Set State ID
						const defaultStateId = Number(stateIdField.default);
						if (!isNaN(defaultStateId)) {
							const stateIdx = states.findIndex(
								state => state.id === defaultStateId,
							);
							if (stateIdx === -1) {
								throw Error(
									`No tengo registrado el estado con ID [${defaultStateId}]. Por favor contacte a su administrador`,
								);
							}
							row[stateIdField.id] = states[stateIdx].id;
							row[stateIdField.name] = states[stateIdx].name;
						} else {
							const stateIdx = states.findIndex(
								state => state.name.toUpperCase() === row[stateIdField.name],
							);
							if (stateIdx === -1) {
								throw Error(
									`No tengo registrado el estado [${
										row[stateIdField.name]
									}]. Por favor contacte a su administrador`,
								);
							}
							row[stateIdField.id] = states[stateIdx].id;
						}

						// Set Service ID
						const defaultServiceId = Number(serviceIdField.default);
						if (!isNaN(defaultServiceId)) {
							const serviceIdx = services.findIndex(
								service => service.id === defaultServiceId,
							);
							if (serviceIdx === -1) {
								throw Error(
									`No tengo registrado el servicio con ID [${defaultServiceId}]. Por favor contacte a su administrador`,
								);
							}
							row[serviceIdField.id] = services[serviceIdx].id;
							row[serviceIdField.name] = services[serviceIdx].name;
							row.departmentId = selectedProjectId;
							// row.departmentId = services[serviceIdx].departmentId;
						} else {
							const serviceIdx = services.findIndex(
								service =>
									service.name.toUpperCase() === row[serviceIdField.name],
							);
							if (serviceIdx === -1) {
								throw Error(
									`No tengo registrado el servicio [${
										row[serviceIdField.name]
									}]. Por favor contacte a su administrador`,
								);
							}
							row[serviceIdField.id] = services[serviceIdx].id;
							row.departmentId = selectedProjectId;
							// row.departmentId = services[serviceIdx].departmentId;
						}

						// Set Client Categories
						if (clientCategoryIdField) {
							const defaultClientCategoryId = Number(
								clientCategoryIdField.default,
							);
							if (!isNaN(defaultClientCategoryId)) {
								const clientCategoryIdx = clientCategories.findIndex(
									category => category.id === defaultClientCategoryId,
								);
								if (clientCategoryIdx === -1) {
									throw Error(
										`No tengo registrada la categoría de cliente con ID [${defaultClientCategoryId}]. Por favor contacte a su administrador`,
									);
								}
								row[clientCategoryIdField.id] =
									clientCategories[clientCategoryIdx].id;
								row[clientCategoryIdField.name] =
									clientCategories[clientCategoryIdx].name;
							} else {
								const clientCategoryIdx = clientCategories.findIndex(
									category =>
										category.name.toUpperCase() ===
										row[clientCategoryIdField.name],
								);
								if (clientCategoryIdx === -1) {
									throw Error(
										`No tengo registrada la categoría de cliente [${
											row[clientCategoryIdField.name]
										}]. Por favor contacte a su administrador`,
									);
								}
								row[clientCategoryIdField.id] =
									clientCategories[clientCategoryIdx].id;
							}
						}

						return row;
					}) // REMOVE UNKNOWN FIELDS
					// Remove all the fields that are not found in the validator template
					.map(row => {
						for (const rowField in row) {
							if (rowField !== 'departmentId' && rowField !== 'dynamicData') {
								const idx = Object.keys(templateValidatorFields).findIndex(
									key => {
										const field = templateValidatorFields[key];
										return field.id === rowField || field.name === rowField;
									},
								);
								if (idx === -1) delete row[rowField];
							}
						}
						return row;
					})
			);
		}

		switch (algorithm) {
			case 'import_v1':
				return importV1();
			default:
				throw new Error(
					'El algoritmo que tiene asignado aún no lo tengo configurado. Por favor contacte a su administrador',
				);
		}
	}

	static validateDataMapping({
		importedFile,
		zones,
		templateValidatorFields,
		algorithm,
		setState,
	}) {
		function importV1() {
			const { cityIdField, townshipIdField, zoneIdField } =
				templateValidatorFields;

			// MAPPING DATA VALIDATION
			let mappingValidationOk = true;
			const data = importedFile.data.map(order => {
				order.mappingErrors = 'Ok';

				// VALIDATE ZONE
				// City exists?
				const cityIdx = zones.findIndex(
					zone =>
						zone.cityName.toUpperCase() ===
						order[cityIdField.name]?.toUpperCase(),
				);
				// Township exists?
				const townshipIdx = zones.findIndex(
					zone =>
						zone.cityName.toUpperCase() ===
							order[cityIdField.name]?.toUpperCase() &&
						zone.townshipName.toUpperCase() ===
							order[townshipIdField.name]?.toUpperCase(),
				);
				// Zone exists?
				const zoneIdx = zones.findIndex(
					zone =>
						zone.cityName.toUpperCase() ===
							order[cityIdField.name]?.toUpperCase() &&
						zone.townshipName.toUpperCase() ===
							order[townshipIdField.name]?.toUpperCase() &&
						zone.zoneName.toUpperCase() ===
							order[zoneIdField.name]?.toUpperCase(),
				);
				// Set cityId
				if (cityIdx === -1) order[cityIdField.id] = undefined;
				else order[cityIdField.id] = zones[cityIdx].cityId;
				// Set townshipId
				if (townshipIdx === -1) order[townshipIdField.id] = undefined;
				else order[townshipIdField.id] = zones[townshipIdx].townshipId;
				// Set zoneId
				if (zoneIdx === -1) {
					order[zoneIdField.id] = undefined;
					if (!order.exists) {
						order.mappingErrors = 'N/A'; // El valor aquí es "N/A" y no <undefined>, porque se necesita ingresar este campo en el filtro para que los datos puedan ser filtrados por éste valor y así sólo se muestren los registros que tuvieron un error. Si se ingresa <undefined> el checkboxGroup daría error
						mappingValidationOk = false;
					}
				} else order[zoneIdField.id] = zones[zoneIdx].zoneId;

				// VALIDATE ANOTHER FIELDS
				if (!order.exists) {
					for (const fieldKey in templateValidatorFields) {
						const field = templateValidatorFields[fieldKey];
						if (field.required && order[field.id] === undefined) {
							order.mappingErrors = 'N/A';
							mappingValidationOk = false;
						}
					}
				}

				return order;
			});

			return { data, mappingValidationOk };
		}

		switch (algorithm) {
			case 'import_v1':
				var { data, mappingValidationOk } = importV1();
				break;
			default:
				throw new Error(
					'El algoritmo que tiene asignado aún no lo tengo configurado. Por favor contacte a su administrador',
				);
		}

		// Set filters
		const filters = {};
		if (!mappingValidationOk) {
			filters.mappingErrors = {
				checkedList: ['N/A'],
				indeterminate: true,
				checkedAll: false,
			};
		}

		setState(prev => ({
			...prev,
			mappingValidationOk,
			filters,
			importedFile: {
				...prev.importedFile,
				data,
			},
		}));
	}

	/* Antes de enviar las ordenes importadas al api para ser procesadas y guardadas,
  se llama esta función para limpiar el payload eliminando los campos innecesarios */
	static cleanImportedOrdersToSave(orders, templateValidatorFields) {
		return Immutable.List(orders)
			.toJS()
			.map(order => {
				for (const prop in order) {
					if (
						prop !== 'entityId' &&
						prop !== 'departmentId' &&
						prop !== 'dynamicData' &&
						prop !== 'exists' &&
						prop !== 'warranty' &&
						prop !== 'requireNotification' &&
						Object.keys(templateValidatorFields).findIndex(
							fieldKey => templateValidatorFields[fieldKey].id === prop,
						) === -1
					)
						delete order[prop];
				}
				return order;
			});
	}

	static getDivisorsFromTemplateDataStructure(template) {
		if (Array.isArray(template?.data_structure_object?.divisors)) {
			return template.data_structure_object.divisors.reduce(
				(_divisors, divisor) => {
					_divisors.push({
						name: divisor.text,
						fields: GlobalUtils.checkArray(divisor.keys)
							.concat(GlobalUtils.checkArray(divisor.columns))
							.reduce((_fields, fieldId) => {
								_fields.push({
									id: fieldId,
									name: fieldId,
								});
								return _fields;
							}, []),
					});
					return _divisors;
				},
				[],
			);
		}
		if (Array.isArray(template?.data_structure_object?.fields)) {
			const divisors = {};
			return template.data_structure_object.fields
				.reduce((_divisors, field) => {
					const name = field.groupName || `Agrupación general`;
					if (divisors[name]) return _divisors;
					divisors[name] = true;
					_divisors.push({
						name,
						fields: [],
					});
					return _divisors;
				}, [])
				.map(divisor => {
					divisor.fields = template.data_structure_object.fields.reduce(
						(_fields, field) => {
							if (!field.groupName || field.groupName === divisor.name)
								_fields.push({
									id: field.id,
									name: field.name,
								});
							return _fields;
						},
						[],
					);
					return divisor;
				});
		}
		return [];
	}

	static onClickSearchButton(mutate1ObjectInOrders, searchInData) {
		mutate1ObjectInOrders('control', {
			searchInData: {
				...searchInData,
				renderToolbar: true,
				searchBy: 'deepSearch',
			},
		});
	}

	static resetOrderConflicts(orders) {
		return orders.map(order => {
			order.mappingErrors = 'Ok';
			return order;
		});
	}

	static isCanContinueProcessImportOrders(
		orders,
		templateValidatorFields,
		status,
	) {
		return (
			// Return all invalid fields (With conflicts). If no record is returned, it means that all the fields are correct
			orders.filter(order => {
				if (status === 'conflicts' && !order.exists && !order.entityId) {
					return true;
				}
				if (!order.exists) {
					for (const fieldKey in templateValidatorFields) {
						const field = templateValidatorFields[fieldKey];
						if (order[field.id] === undefined) return true;
					}
				}
			}).length === 0
		);
	}

	static onKeyPressedSearchOrder(
		e,
		searchInData,
		history,
		modeView,
		mutate1ObjectInOrders,
		mutateDirectPropsInDashboard,
	) {
		const { data, searchBy } = searchInData;

		if (e.key === 'Enter' && searchBy === 'deepSearch' && data && data !== '') {
			mutate1ObjectInOrders('control', {
				searchInData: { ...searchInData, pressEnter: true },
			});
			mutateDirectPropsInDashboard({ isOpen: false });
			history.push(ENV.ROUTES.PATHS.ORDERS);
		}
	}

	static onDeepSearchOrder(searchInData, makeDeepSearchOrder, mutate1Object) {
		const { data, pressEnter } = searchInData;

		if (pressEnter && data && data !== '') {
			mutate1Object('control', {
				searchInData: { ...searchInData, pressEnter: false },
			});
			makeDeepSearchOrder(data.trim());
		}
	}

	static canCreateNewComment(getOrderInformationModal) {
		const { activeTab, billingReport, photoReport, viewComments } =
			getOrderInformationModal;

		if (activeTab === '3') {
			return (
				viewComments &&
				(!billingReport.comments ||
					_.findIndex(billingReport.comments, comment => comment.newComment) ===
						-1)
			);
		} else if (activeTab === '4') {
			return (
				viewComments &&
				(!photoReport.comments ||
					_.findIndex(photoReport.comments, comment => comment.newComment) ===
						-1)
			);
		}
	}

	static getCommentsCount(getOrderInformationModal) {
		const { activeTab, billingReport, photoReport } = getOrderInformationModal;

		if (activeTab === '3') {
			if (billingReport && billingReport.comments)
				return billingReport.comments.length;
		} else if (activeTab === '4') {
			if (photoReport && photoReport.comments)
				return photoReport.comments.length;
		}
		return 0;
	}

	static onAddComment(getOrderInformationModal, mutate1Object) {
		const { activeTab, billingReport, photoReport } = getOrderInformationModal;

		if (activeTab === '3') {
			const comments = billingReport.comments
				? [...billingReport.comments]
				: [];

			if (_.findIndex(comments, comment => comment.newComment) === -1) {
				comments.push({ newComment: true });
				mutate1Object('getOrderInformationModal', {
					billingReport: { ...billingReport, comments },
				});
			}
		} else if (activeTab === '4') {
			const comments = photoReport.comments ? [...photoReport.comments] : [];

			if (_.findIndex(comments, comment => comment.newComment) === -1) {
				comments.push({ newComment: true });
				mutate1Object('getOrderInformationModal', {
					photoReport: { ...photoReport, comments },
				});
			}
		}
	}

	static onViewComments(getOrderInformationModal, mutate1Object) {
		const { activeTab, billingReport, photoReport, viewComments } =
			getOrderInformationModal;

		if (activeTab === '3') {
			if (!viewComments)
				mutate1Object('getOrderInformationModal', {
					viewComments: !getOrderInformationModal.viewComments,
				});
			else {
				mutate1Object('getOrderInformationModal', {
					viewComments: !getOrderInformationModal.viewComments,
					billingReport: {
						...billingReport,
						comments: billingReport.comments
							? billingReport.comments.filter(comment => !comment.newComment)
							: [],
					},
				});
			}
		} else if (activeTab === '4') {
			if (!viewComments)
				mutate1Object('getOrderInformationModal', {
					viewComments: !getOrderInformationModal.viewComments,
				});
			else {
				mutate1Object('getOrderInformationModal', {
					viewComments: !getOrderInformationModal.viewComments,
					photoReport: {
						...photoReport,
						comments: photoReport.comments
							? photoReport.comments.filter(comment => !comment.newComment)
							: [],
					},
				});
			}
		}
	}

	static onClickCloseSearchButton(
		mutate1ObjectInOrders,
		searchInData,
		getControlOrders,
		getOrdersQueryModal,
	) {
		mutate1ObjectInOrders('control', {
			searchInData: {
				...searchInData,
				renderToolbar: false,
				searchBy: 'all',
				data: '',
			},
		});
		getControlOrders(getOrdersQueryModal);
	}

	static filterOrdersSearchInData(orders, searchBy, data, orderPrefix) {
		const formattedData = this.filterPrefixOrder(data, orderPrefix);

		switch (searchBy) {
			case 'order':
				return orders.filter(order => order.odt_id.includes(formattedData));
			case 'contract':
				return orders.filter(order =>
					order.contract_number.includes(formattedData),
				);
			case 'client':
				return orders.filter(order =>
					order.client_name.toUpperCase().includes(formattedData.toUpperCase()),
				);
			case 'city':
				return orders.filter(order =>
					order.city?.toUpperCase().includes(formattedData.toUpperCase()),
				);
			case 'township':
				return orders.filter(order =>
					order.township?.toUpperCase().includes(formattedData.toUpperCase()),
				);
			case 'zone':
				return orders.filter(order =>
					order.zone?.toUpperCase().includes(formattedData.toUpperCase()),
				);
			case 'service':
				return orders.filter(order =>
					order.service_name
						.toUpperCase()
						.includes(formattedData.toUpperCase()),
				);
			case 'expert':
				return orders.filter(order =>
					order.expert_name.toUpperCase().includes(formattedData.toUpperCase()),
				);
			case 'company':
				return orders.filter(order =>
					order.entity_name.toUpperCase().includes(formattedData.toUpperCase()),
				);
			case 'event':
				return orders.filter(order =>
					order.event_name.toUpperCase().includes(formattedData.toUpperCase()),
				);
			case 'state':
				return orders.filter(order =>
					order.state_name.toUpperCase().includes(formattedData.toUpperCase()),
				);
			case 'priority':
				return orders.filter(
					order =>
						order.priority &&
						order.priority.label &&
						order.priority.label
							.toUpperCase()
							.includes(formattedData.toUpperCase()),
				);
			case 'external_range':
				return orders.filter(order =>
					order.external_range
						.toUpperCase()
						.includes(formattedData.toUpperCase()),
				);
			case 'deepSearch':
			case 'all':
				return orders.filter(
					order =>
						order.order_id.toString().includes(formattedData) ||
						order.odt_id.includes(formattedData) ||
						order.contract_number.includes(formattedData) ||
						order.client_name
							.toUpperCase()
							.includes(formattedData.toUpperCase()) ||
						order.city?.toUpperCase().includes(formattedData.toUpperCase()) ||
						order.township
							?.toUpperCase()
							.includes(formattedData.toUpperCase()) ||
						order.zone?.toUpperCase().includes(formattedData.toUpperCase()) ||
						order.service_name
							.toUpperCase()
							.includes(formattedData.toUpperCase()) ||
						order.expert_name
							.toUpperCase()
							.includes(formattedData.toUpperCase()) ||
						order.entity_name
							.toUpperCase()
							.includes(formattedData.toUpperCase()) ||
						order.event_name
							.toUpperCase()
							.includes(formattedData.toUpperCase()) ||
						order.state_name
							.toUpperCase()
							.includes(formattedData.toUpperCase()) ||
						(order.priority &&
							order.priority.label &&
							order.priority.label
								.toUpperCase()
								.includes(formattedData.toUpperCase())) ||
						order.external_range
							.toUpperCase()
							.includes(formattedData.toUpperCase()),
				);
		}
	}

	static getMyAssignedZones(orders) {
		const myAssignedZones = [];

		orders.map(order => {
			if (order.userBoAssigned) {
				for (let userId in order.userBoAssigned) {
					userId = Number(userId);

					const idx = _.findIndex(myAssignedZones, id => id === userId);
					if (idx === -1) myAssignedZones.push(userId);
				}
			}
		});

		return myAssignedZones;
	}

	static getExpertListOrders(orders) {
		const expertListOrders = [];

		orders.map(order => {
			const idx = _.findIndex(
				expertListOrders,
				exp => exp.user_id === order.assigned_tech_id,
			);

			if (idx === -1)
				expertListOrders.push({
					user_id: order.assigned_tech_id,
					name: order.expert_name,
					work_code: order.work_code,
					count: 1,
				});
			else expertListOrders[idx].count++;
		});

		return expertListOrders;
	}

	static getEventListOrders(orders) {
		const eventListOrders = [];

		orders.map(order => {
			const idx = _.findIndex(
				eventListOrders,
				evt => evt.event_id === order.event_id,
			);

			if (idx === -1)
				eventListOrders.push({
					event_id: order.event_id,
					name: order.event_name,
					count: 1,
				});
			else eventListOrders[idx].count++;
		});

		return eventListOrders;
	}

	static getServiceListOrders(orders) {
		const serviceListOrders = [];

		orders.map(order => {
			const idx = _.findIndex(
				serviceListOrders,
				srv => srv.service_id === order.service_id,
			);

			if (idx === -1)
				serviceListOrders.push({
					service_id: order.service_id,
					name: order.service_name,
					count: 1,
				});
			else serviceListOrders[idx].count++;
		});

		return serviceListOrders;
	}

	static filterPrefixOrder(order, orderPrefix) {
		return order.replace(orderPrefix, '');
	}

	static addOrRemoveFilteredArrayItem(itemId, filteredArrayIds) {
		const result = Immutable.List(filteredArrayIds).toJS();

		// Not exists:
		if (result.indexOf(itemId) === -1) {
			result.push(itemId);
			return result;
		}
		// Exists:
		else {
			return result.filter(itemValue => itemValue !== itemId);
		}
	}

	static getProject(projectId, projects) {
		return projects.find(project => project.id === projectId);
	}

	static processUserAssignmentResponse({
		orderAssignments,
		orders,
		filters: _filters,
		templateValidatorFields,
	}) {
		const { odtIdField, assignedTechIdField } = templateValidatorFields;
		let conflicts = false;
		const data = Immutable.List(orders)
			.toJS()
			.map(order => {
				// Matching order
				const orderIdx = orderAssignments.findIndex(
					ord => ord[odtIdField.id] === order[odtIdField.id],
				);
				const assignedOrder = orderIdx !== -1 && orderAssignments[orderIdx];
				// Not matched order?
				if (!assignedOrder) {
					order[assignedTechIdField.id] = undefined;
					order[assignedTechIdField.name] = '';
					order.entityId = undefined;
					order.entityName = '';
				}
				// Matched order?
				else {
					order = {
						...order,
						...assignedOrder,
						warrantyLabel: assignedOrder.warranty ? 'Si' : 'No',
					};
				}

				// Validate Assignment
				if (
					!order.exists &&
					(!order[assignedTechIdField.id] || !order.entityId)
				) {
					order.mappingErrors = 'N/A';
					conflicts = true;
				}

				return order;
			});

		// Set filters & userAssignment
		const filters = { ..._filters };
		let userAssignment;
		if (conflicts) {
			filters.mappingErrors = {
				checkedList: ['N/A'],
				indeterminate: true,
				checkedAll: false,
			};
			userAssignment = 'withConflicts';
		} else userAssignment = 'withoutConflicts';

		if (
			OrderUtils.isCanContinueProcessImportOrders(
				data,
				templateValidatorFields,
				userAssignment,
			) &&
			filterConflictsImporting(data, filters, userAssignment).length === 0
		) {
			userAssignment = 'confirmed';
		}

		return {
			data,
			userAssignment,
			filters,
		};
	}

	static processOrderExistResponse({ odtIdField, existingOrders, orders }) {
		const _orders = Immutable.List(orders).toJS();
		existingOrders.map(existingOrder => {
			const idx = _orders.findIndex(
				order => order[odtIdField.id] === existingOrder[odtIdField.id],
			);
			if (idx === -1) return;
			_orders[idx].exists = true;
		});
		return _orders;
	}
}
