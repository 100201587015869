import GENERAL from '../../utils/constants/general';
import { notification } from 'antd';

const { SIGNUP, NOTIFICATIONS } = GENERAL;

const initialState = {
	idc: undefined,
	name: undefined,
	phone: undefined,
	password: undefined,
	showPassword: false,
	entity_id: undefined,
	organization_id: undefined,
	company_id: undefined,
	loading: false,
	status: 'register', // register, error
	companies: [],
	organizations: [],
	entities: [],
	toastMessage: {
		key: NOTIFICATIONS.TYPES.GLOBAL,
		message: 'Synapse',
		description: '',
		duration: 5,
		type: 'info',
		className: 'notification-dark-style',
	},
};

const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case SIGNUP.MUTATE_DIRECT_PROPS: {
			const keyValuePairs = action.payload;
			return {
				...state,
				...keyValuePairs,
			};
		}
		case SIGNUP.RESET_REGISTRY: {
			return {
				...state,
				idc: '',
				name: '',
				phone: '',
				password: '',
				showPassword: false,
				entity_id: undefined,
				organization_id: undefined,
				company_id: undefined,
				loading: false,
				status: 'register',
			};
		}
		case SIGNUP.TOAST_MESSAGE: {
			const { message, description, duration, type } = action.payload;
			const toastMessage = {
				...state.toastMessage,
				message: message || state.toastMessage.message,
				description: description || state.toastMessage.description,
				duration: duration || state.toastMessage.duration,
				type: type || state.toastMessage.type,
			};

			switch (type) {
				case 'error':
					notification.error({ ...toastMessage });
					break;
				case 'warn':
					notification.warn({ ...toastMessage });
					break;
				case 'warning':
					notification.warning({ ...toastMessage });
					break;
				case 'success':
					notification.success({ ...toastMessage });
					break;
				default:
					notification.info({ ...toastMessage });
					break;
			}

			return {
				...state,
			};
		}
		case SIGNUP.RESET: {
			return initialState;
		}
		default:
			return state;
	}
};

export default loginReducer;
