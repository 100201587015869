import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${({ theme, size }) => `${theme.sizes[size || 'normal'].width}px`};
	height: ${({ theme, size }) => `${theme.sizes[size || 'normal'].height}px`};
`;

const IconContainer = ({ children, size }) => {
	return <Container size={size}>{children}</Container>;
};

export default IconContainer;
