// Libs
import React from 'react';
import { Badge, Tag } from 'antd';
// Utils
import { OrderUtils } from 'utils';
// Components
import { VirtualizedList } from 'screens/Utils';
import { Wrapper, Collapse as _Collapse } from 'components';
// Styles
import './Mobile.css';

const { Collapse, Panel } = _Collapse;

// ------------------------------------------------------ JSX RENDERS -----------------------------------------------------------------
function Mobile(props) {
	const { control, orders, profile, mutate1Object } = props;
	const { selectedOrders } = control;
	let segmented = [];

	if (control.view === 'users')
		segmented = OrderUtils.getExpertListOrders(orders);
	else if (control.view === 'events')
		segmented = OrderUtils.getEventListOrders(orders);
	else if (control.view === 'services')
		segmented = OrderUtils.getServiceListOrders(orders);

	return (
		<Wrapper padding='0' display='block'>
			{control.view === 'list' && (
				<VirtualizedList
					cardId='order'
					listStyle={{
						height: document.documentElement.clientHeight * 0.82,
						width: document.documentElement.clientWidth,
					}}
					cardStyle={{ padding: 0 }}
					rowHeight={195}
					spinTitle='Consultando registros...'
					rows={orders}
					customProps={{
						profile,
						mutate1Object,
						selectedOrders,
					}}
				/>
			)}

			{control.view === 'users' &&
				segmented.map((expert, idx) => (
					<Collapse
						key={idx}
						bordered={false}
						defaultActiveKey={['-1']}
						style={{ width: '100%' }}
					>
						<Panel
							transparentBackground
							style={{ padding: '8px 8px 0 8px' }}
							header={
								<div className='control-mobile-title-list'>
									<Tag
										style={{
											width: '70px',
											textAlign: 'center',
											color: 'rgba(255,255,255,0.6)',
											backgroundColor: 'transparent',
											boxShadow: '0 0 0 1px #000 inset',
											marginRight: '5px',
										}}
									>
										{expert.work_code}
									</Tag>
									<span className='control-mobile-title-name'>
										{expert.name}
									</span>
									<Badge
										count={expert.count}
										style={{
											backgroundColor: 'transparent',
											color: 'lightcoral',
											boxShadow: '0 0 0 1px #d9d9d9 inset',
											fontFamily: 'Roboto',
										}}
										offset={[0, 0]}
										overflowCount={999}
									/>
								</div>
							}
						>
							<VirtualizedList
								cardId='order'
								listStyle={{
									height: document.documentElement.clientHeight * 0.82,
									width: document.documentElement.clientWidth,
								}}
								cardStyle={{ padding: 0 }}
								rowHeight={195}
								spinTitle='Consultando registros...'
								rows={orders.filter(
									order => order.assigned_tech_id === expert.user_id,
								)}
								customProps={{
									profile,
									mutate1Object,
									selectedOrders,
								}}
							/>
						</Panel>
					</Collapse>
				))}

			{control.view === 'events' &&
				segmented.map((event, idx) => (
					<Collapse
						key={idx}
						bordered={false}
						defaultActiveKey={['-1']}
						style={{ width: '100%' }}
					>
						<Panel
							transparentBackground
							style={{ padding: '8px 8px 0 8px' }}
							header={
								<div className='control-mobile-title-list'>
									<span className='control-mobile-title-name-event'>
										{event.name}
									</span>
									<Badge
										count={event.count}
										style={{
											backgroundColor: 'transparent',
											color: 'lightcoral',
											boxShadow: '0 0 0 1px #d9d9d9 inset',
											fontFamily: 'Roboto',
										}}
										offset={[0, 0]}
										overflowCount={999}
									/>
								</div>
							}
						>
							<VirtualizedList
								cardId='order'
								listStyle={{
									height: document.documentElement.clientHeight * 0.82,
									width: document.documentElement.clientWidth,
								}}
								cardStyle={{ padding: 0 }}
								rowHeight={195}
								spinTitle='Consultando registros...'
								rows={orders.filter(order => order.event_id === event.event_id)}
								customProps={{
									profile,
									mutate1Object,
									selectedOrders,
								}}
							/>
						</Panel>
					</Collapse>
				))}

			{control.view === 'services' &&
				segmented.map((service, idx) => (
					<Collapse
						key={idx}
						bordered={false}
						defaultActiveKey={['-1']}
						style={{ width: '100%' }}
					>
						<Panel
							transparentBackground
							style={{ padding: '8px 8px 0 8px' }}
							header={
								<div className='control-mobile-title-list'>
									<span className='control-mobile-title-name-event'>
										{service.name}
									</span>
									<Badge
										count={service.count}
										style={{
											backgroundColor: 'transparent',
											color: 'lightcoral',
											boxShadow: '0 0 0 1px #d9d9d9 inset',
											fontFamily: 'Roboto',
										}}
										offset={[0, 0]}
										overflowCount={999}
									/>
								</div>
							}
						>
							<VirtualizedList
								cardId='order'
								listStyle={{
									height: document.documentElement.clientHeight * 0.82,
									width: document.documentElement.clientWidth,
								}}
								cardStyle={{ padding: 0 }}
								rowHeight={195}
								spinTitle='Consultando registros...'
								rows={orders.filter(
									order => order.service_id === service.service_id,
								)}
								customProps={{
									profile,
									mutate1Object,
									selectedOrders,
								}}
							/>
						</Panel>
					</Collapse>
				))}
		</Wrapper>
	);
}

export default Mobile;
