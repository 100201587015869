//  Constants
import GENERAL from '../../utils/constants/general';

const { DASHBOARD, ENV, NOTIFICATIONS } = GENERAL;

//  InitialState
const initialState = {
	isOpen: false,
	expandedDashboardItem: 'urs',
	notificationControl: {
		show: false,
		isFetching: false,
		notifications: [],
	},
	toastMessage: {
		key: NOTIFICATIONS.TYPES.GLOBAL,
		message: 'Synapse',
		description: '',
		duration: 5,
		type: 'info', // error, warn, warning, success, info
		className: 'notification-dark-style',
	},
	showErrors: {
		isOpen: false,
		placement: 'right',
		title: 'Ups! Hay conflictos',
		errors: [],
	},
	socket: null,
	modeView: ENV.DEVICE_TYPE === 'Mobile' ? 'cards' : 'table', // table, cards
	// Drag and drop orders drawer
	dndOrdersDrawer: {
		isOpen: false,
		isLoading: false,
	},
	// profile edition drawer
	editProfileDrawer: {
		isOpen: false,
		loading: false,
		isEditing: false,
		isUpdating: false,
	},
};

const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case DASHBOARD.SHOW_NAV_BAR: {
			return {
				...state,
				isOpen: true,
			};
		}
		case DASHBOARD.MUTATE_DIRECT_PROPS: {
			const keyValuePairs = action.payload;
			return {
				...state,
				...keyValuePairs,
			};
		}
		case DASHBOARD.MUTATE_1OBJECT: {
			const { obj1Name, keyValuePairs } = action.payload;
			const newObj = { ...state[obj1Name], ...keyValuePairs };
			return {
				...state,
				[obj1Name]: newObj,
			};
		}
		case DASHBOARD.MARK_NOTIFICATION_SUCCESS: {
			const { userNotificationId, seen, read } = action.payload;

			const notifications = [...state.notificationControl.notifications];
			const notificationIdx = notifications.findIndex(
				n => n.userNotificationId === userNotificationId,
			);
			if (notificationIdx !== -1) {
				notifications[notificationIdx].seen = !!seen;
				notifications[notificationIdx].read = !!read;
			}

			return {
				...state,
				notificationControl: {
					...state.notificationControl,
					notifications,
				},
			};
		}
		case DASHBOARD.SHOW_MULTIPLE_ERRORS: {
			const errors = action.payload;

			return {
				...state,
				showErrors: {
					...state.showErrors,
					isOpen: true,
					errors,
				},
			};
		}
		case DASHBOARD.RESET_EDIT_PROFILE: {
			return {
				...state,
				editProfileDrawer: initialState.editProfileDrawer,
			};
		}
		case DASHBOARD.RESET_DND_ORDERS: {
			return {
				...state,
				dndOrdersDrawer: initialState.dndOrdersDrawer,
			};
		}
		case DASHBOARD.SET_ON_RECEIVED_NOTIFICATION: {
			const notification = action.payload;

			const notifications = [...state.notificationControl.notifications];
			const idx = notifications.findIndex(
				n => n.userNotificationId === notification.userNotificationId,
			);
			if (idx === -1) notifications.unshift(notification);
			return {
				...state,
				notificationControl: {
					...state.notificationControl,
					notifications,
				},
			};
		}
		case DASHBOARD.GET_NOTIFICATIONS_SUCCESS:
			const newNotifications = action.payload;
			const notifications = [...state.notificationControl.notifications];

			newNotifications.map(nn => {
				const notificationIdx = notifications.findIndex(
					n => n.userNotificationId === nn.userNotificationId,
				);

				if (notificationIdx === -1) {
					notifications.push(nn);
				}
				return nn;
			});

			return {
				...state,
				notificationControl: {
					...state.notificationControl,
					isFetching: false,
					notifications,
				},
			};
		case DASHBOARD.RESET: {
			return initialState;
		}
		default:
			return state;
	}
};

export default dashboardReducer;
