// Libs
import React from 'react';
import { Parser as HtmlToReactParser } from 'html-to-react';
// import { pdfjs } from "react-pdf/dist/esm/entry.webpack";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// Hooks
import { useTheme } from 'styled-components';
import useNearScreen from 'hooks/useNearScreen';
// Utils
// import { TOPICS } from "context/RealtimeContext";
import { CloudStorage } from 'components/AppConfig.json';
import { getHumanize } from 'utils/libs/dateFormats';
import { debounce } from 'utils/libs';
// Components
import { Wrapper, Avatar, Span, Caption } from 'components';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// const PDFViewer = React.memo(({ url }) => {
//   if (!url) return;

//   return (
//     <Wrapper
//       padding="0"
//       minWidth="100px"
//       height="150px"
//       justifyContent="center"
//     >
//       <Document file={{ url }} loading={<LogoIcon spin={true} />}>
//         <Page
//           renderMode="svg"
//           height={150}
//           pageNumber={1}
//           renderAnnotationLayer={false}
//         />
//       </Document>
//     </Wrapper>
//   );
// });

const getNotificationMessage = (data, createdBy, profile) => {
	const getMessage = () => {
		return createdBy === profile.user.id && data.myMessage
			? data.myMessage
			: data.message;
	};

	const message = getMessage();
	const htmlToReactParser = new HtmlToReactParser();
	return htmlToReactParser.parse(message);
};

const NotSeenIndicator = ({ theme }) => (
	<Wrapper
		padding='5.5px'
		width='12px'
		height='12px'
		backgroundColor={theme.colors.success.color}
		borderRadius='50%'
	/>
);

function NotificationCard({
	profile,
	notification,
	selectNotification,
	markNotification,
	rootObserverRef,
}) {
	const {
		userNotificationId,
		data,
		dispatchableId,
		dispatchableType,
		action,
		createdBy,
		createdAt,
		read,
		seen,
	} = notification;
	const theme = useTheme();

	// Near screen to update seen
	const [isNearScreen, fromRef] = useNearScreen({
		root: rootObserverRef?.current,
		rootMargin: '100px',
	});

	// Build notification message
	const notificationMessage = React.useMemo(
		() => getNotificationMessage(data, createdBy, profile),
		[data.message, data.myMessage, createdBy, profile.user.id],
	);

	const handleOnSeenNotification = React.useCallback(
		debounce(
			() => markNotification({ userNotificationId, seen: true, read }),
			1000,
		),
		[userNotificationId, read],
	);

	React.useEffect(() => {
		if (isNearScreen && !seen) handleOnSeenNotification();
	}, [isNearScreen, seen]);

	return (
		<Wrapper
			elementRef={fromRef}
			mediumBackground
			cursor='pointer'
			padding='8px 0'
			hover
			onClick={() =>
				selectNotification({
					userNotificationId,
					dispatchableId,
					dispatchableType,
					action,
					seen,
					read,
					data: { url: data?.url },
				})
			}
		>
			<Avatar
				size='medium'
				avatar={{
					src: CloudStorage.concat(`/syn4pse/users/avatars/${createdBy}.jpg`),
				}}
			/>
			<Wrapper
				padding='0'
				flexDirection='column'
				alignItems='flex-start'
				width='100%'
			>
				<Span disabled={read}>{notificationMessage}</Span>
				<Caption light disabled={read}>
					{getHumanize({ date: createdAt })}
				</Caption>

				{/* CUSTOM RENDERS */}
				{/* {action === TOPICS.ACTIONS.REPORTS.SAVED.PDF && (
            <PDFViewer url={data?.url} />
          )} */}
			</Wrapper>
			{!read && <NotSeenIndicator theme={theme} />}
		</Wrapper>
	);
}

export default React.memo(NotificationCard);
