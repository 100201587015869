import moment from 'moment';

const today = moment().format('YYYY-MM-DD');
const dateNow = moment(today);

export function filterOrdersCategory(orders, category) {
	if (category === 'realizable') {
		return orders.filter(order => {
			const momReprogramAt = moment(order.reprogram_at);
			if (order.event_props.reprogram || momReprogramAt.isValid()) {
				const dateReprogramAt = moment(momReprogramAt.format('YYYY-MM-DD'));
				if (
					dateReprogramAt.isBefore(dateNow, 'day') ||
					dateReprogramAt.isSame(dateNow, 'day')
				)
					return order;
			} else if (!order.event_props.reprogram) return order;
		});
	} else if (category === 'dailyTracking') {
		return orders.filter(order => {
			const momReprogramAt = moment(order.reprogram_at);
			const momLastEventAt = moment(order.last_event_at);

			if (order.priority && order.priority.highPriority) return order;
			else if (
				order.event_props.affectation &&
				!order.event_props.reprogram &&
				momLastEventAt.isValid()
			) {
				const dateLastEventAt = moment(momLastEventAt.format('YYYY-MM-DD'));
				if (!dateLastEventAt.isSame(dateNow, 'day')) return order;
			} else if (order.event_props.reprogram || momReprogramAt.isValid()) {
				const dateReprogramAt = moment(momReprogramAt.format('YYYY-MM-DD'));
				if (
					dateReprogramAt.isBefore(dateNow, 'day') ||
					dateReprogramAt.isSame(dateNow, 'day')
				)
					return order;
			} else if (order.event_props.dailyTracking) return order;
		});
	}
	return orders;
}

export function filterOrdersCategoryCardsView(orders, category) {
	if (category === 'realizable') {
		for (const expertId in orders) {
			orders[expertId] = orders[expertId].filter(order => {
				const momReprogramAt = moment(order.reprogram_at);
				if (order.event_props.reprogram || momReprogramAt.isValid()) {
					const dateReprogramAt = moment(momReprogramAt.format('YYYY-MM-DD'));
					if (
						dateReprogramAt.isBefore(dateNow, 'day') ||
						dateReprogramAt.isSame(dateNow, 'day')
					)
						return order;
				} else if (!order.event_props.reprogram) return order;
			});
		}
	} else if (category === 'dailyTracking') {
		for (const expertId in orders) {
			orders[expertId] = orders[expertId].filter(order => {
				const momReprogramAt = moment(order.reprogram_at);
				const momLastEventAt = moment(order.last_event_at);

				if (order.priority && order.priority.highPriority) return order;
				else if (order.event_props.reprogram || momReprogramAt.isValid()) {
					const dateReprogramAt = moment(momReprogramAt.format('YYYY-MM-DD'));
					if (
						dateReprogramAt.isBefore(dateNow, 'day') ||
						dateReprogramAt.isSame(dateNow, 'day')
					)
						return order;
				} else if (order.event_props.affectation && momLastEventAt.isValid()) {
					const dateLastEventAt = moment(momLastEventAt.format('YYYY-MM-DD'));
					if (!dateLastEventAt.isSame(dateNow, 'day')) return order;
				} else if (order.event_props.dailyTracking) return order;
			});
		}
	}
	return orders;
}

export function filterOrdersSearchInDataCardsView(orders, searchBy, data) {
	switch (searchBy) {
		case 'order':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.odt_id.includes(data),
				);
			}
			break;
		case 'contract':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.contract_number.includes(data),
				);
			}
			break;
		case 'client':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.client_name.toUpperCase().includes(data.toUpperCase()),
				);
			}
			break;
		case 'city':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.city?.toUpperCase().includes(data.toUpperCase()),
				);
			}
			break;
		case 'township':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.township?.toUpperCase().includes(data.toUpperCase()),
				);
			}
			break;
		case 'zone':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.zone?.toUpperCase().includes(data.toUpperCase()),
				);
			}
			break;
		case 'service':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.service_name.toUpperCase().includes(data.toUpperCase()),
				);
			}
			break;
		case 'expert':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.expert_name.toUpperCase().includes(data.toUpperCase()),
				);
			}
			break;
		case 'company':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.entity_name.toUpperCase().includes(data.toUpperCase()),
				);
			}
			break;
		case 'event':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.event_name.toUpperCase().includes(data.toUpperCase()),
				);
			}
			break;
		case 'state':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.state_name.toUpperCase().includes(data.toUpperCase()),
				);
			}
			break;
		case 'priority':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(
					order =>
						order.priority &&
						order.priority.label &&
						order.priority.label.toUpperCase().includes(data.toUpperCase()),
				);
			}
			break;
		case 'external_range':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.external_range.toUpperCase().includes(data.toUpperCase()),
				);
			}
			break;
		case 'internal_range':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(order =>
					order.internal_range.toUpperCase().includes(data.toUpperCase()),
				);
			}
			break;
		case 'deepSearch':
		case 'all':
			for (const expertId in orders) {
				orders[expertId] = orders[expertId].filter(
					order =>
						order.odt_id.includes(data) ||
						order.contract_number.includes(data) ||
						order.client_name.toUpperCase().includes(data.toUpperCase()) ||
						order.city?.toUpperCase().includes(data.toUpperCase()) ||
						order.township?.toUpperCase().includes(data.toUpperCase()) ||
						order.zone?.toUpperCase().includes(data.toUpperCase()) ||
						order.service_name.toUpperCase().includes(data.toUpperCase()) ||
						order.expert_name.toUpperCase().includes(data.toUpperCase()) ||
						order.entity_name.toUpperCase().includes(data.toUpperCase()) ||
						order.event_name.toUpperCase().includes(data.toUpperCase()) ||
						order.state_name.toUpperCase().includes(data.toUpperCase()) ||
						(order.priority &&
							order.priority.label &&
							order.priority.label
								.toUpperCase()
								.includes(data.toUpperCase())) ||
						order.external_range.toUpperCase().includes(data.toUpperCase()),
					// || order.internal_range.toUpperCase().includes(data.toUpperCase())
				);
			}
			break;
	}
	return orders;
}

export function filterOrdersByUserBoAssigned(orders, userId) {
	return orders.filter(order => order.userBoAssigned[userId]);
}

export function filterOrdersByUserBoAssignedCardsView(orders, userId) {
	for (const expertId in orders) {
		orders[expertId] = orders[expertId].filter(
			order => order.userBoAssigned[userId],
		);
	}
	return orders;
}

export function filterOrdersByRoleCardsView(orders, role) {
	if (role === 'sort') {
		for (const expertId in orders) {
			orders[expertId] = orders[expertId].filter(order => {
				if (
					order.state_props &&
					order.state_props.pending &&
					order.event_props
				) {
					const momReprogramAt = moment(order.reprogram_at);
					if (order.event_props.reprogram || momReprogramAt.isValid()) {
						const dateReprogramAt = moment(momReprogramAt.format('YYYY-MM-DD'));
						if (
							dateReprogramAt.isBefore(dateNow, 'day') ||
							dateReprogramAt.isSame(dateNow, 'day')
						)
							return order;
						if (
							dateReprogramAt.isAfter(dateNow, 'day') &&
							dateReprogramAt.diff(dateNow, 'days') === 1
						)
							return order;
					} else if (order.event_props.routing) return order;
				}
			});
		}
	} else if (role === 'reassign') {
		for (const expertId in orders) {
			orders[expertId] = orders[expertId].filter(order => {
				if (
					order.event_props &&
					!order.event_props.photoPending &&
					!order.event_props.billed
				)
					return order;
			});
		}
	}
	return orders;
}
