// Libs
import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';
// Utils
import GENERAL from 'utils/constants/general';
import { GlobalUtils, ProjectUtils, ServiceUtils } from 'utils';
// Hooks
import {
	useWarehouseTransactionDocApi,
	useWarehouseTransactionDocItemApi,
	useTheme as customUseTheme,
} from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
// Components
import { Wrapper, Menu, Spin, Popover, Button, MoreIcon } from 'components';
import { EmptyIllustration } from 'components/Illustrations';
import BillingMaterialCard from 'screens/Utils/Lists/cards/Orders/BillingMaterialCard';
// Selectors
import { selectIsMobile } from 'screens/Dashboard/selectors';
import { getOrderInformation } from 'screens/Orders/selectors';
// Actions
import { setOrderProps, revertBill } from 'screens/Orders/actions';
import { signWTD } from 'store/api/actions';
import Spining from '../Spining';

const { ENV } = GENERAL;

const isEditableInventory = metadata => {
	if (!metadata?.props?.editableInventory) return;

	const editableInventoryConfig = GlobalUtils.selectCurrentProps([
		ProjectUtils.getProjectPropsFromOrder(
			ENV.DEPARTMENTS.PROPS.EDITABLE_INVENTORY.NAME,
			metadata,
		),
		ServiceUtils.getServicePropsFromOrder(
			ENV.DEPARTMENTS.PROPS.EDITABLE_INVENTORY.NAME,
			metadata,
		),
	]);

	if (GlobalUtils.isEmptyObject(editableInventoryConfig)) return;

	const grantedDate = new Date(metadata.props.editableInventory);
	const grantExpirationDate = dayjs(grantedDate).add(
		editableInventoryConfig.interval,
		editableInventoryConfig.unit,
	);

	return grantExpirationDate - new Date() > 0;
};

function InventoryOptionsContent({
	order: { orderId, metadata },
	settingOrderProps,
}) {
	const theme = useTheme();
	const { theme: customTheme, isDarkTheme } = customUseTheme();
	const dispatch = useDispatch();

	const editableInventory = isEditableInventory(metadata);

	return (
		<Spin spinning={settingOrderProps} size='small' delay={50} tip={''}>
			<Menu.Wrapper>
				<Menu.Group title='Edición de Inventario'>
					<Menu.Item
						key='editableInventory'
						style={{
							backgroundColor: isDarkTheme
								? theme.menu.item.selected.backgroundColor
								: customTheme._surfaceBackgroundColor,
						}}
						onClick={() =>
							dispatch(
								setOrderProps(orderId, 'setEditableInventory', {
									editableInventory: new Date(),
								}),
							)
						}
					>
						{editableInventory ? 'Editable' : 'No editable'}
					</Menu.Item>
				</Menu.Group>
				<Menu.Group title='Prioridad de Inventario'>
					<Menu.Item
						key='requireInventoryDefault'
						style={
							(!metadata.props ||
								!metadata.props.requireInventory ||
								metadata.props.requireInventory === 'default') && {
								backgroundColor: isDarkTheme
									? theme.menu.item.selected.backgroundColor
									: customTheme._surfaceBackgroundColor,
							}
						}
						onClick={() =>
							dispatch(
								setOrderProps(orderId, 'setInventoryRequired', {
									requireInventory: 'default',
								}),
							)
						}
					>
						Predeterminado
					</Menu.Item>
					<Menu.Item
						key='requireInventoryNew'
						style={
							metadata.props &&
							metadata.props.requireInventory === 'new' && {
								backgroundColor: isDarkTheme
									? theme.menu.item.selected.backgroundColor
									: customTheme._surfaceBackgroundColor,
							}
						}
						onClick={() =>
							dispatch(
								setOrderProps(orderId, 'setInventoryRequired', {
									requireInventory: 'new',
								}),
							)
						}
					>
						Nuevos
					</Menu.Item>
					<Menu.Item
						key='requireInventoryReconditioned'
						style={
							metadata.props &&
							metadata.props.requireInventory === 'reconditioned' && {
								backgroundColor: isDarkTheme
									? theme.menu.item.selected.backgroundColor
									: customTheme._surfaceBackgroundColor,
							}
						}
						onClick={() =>
							dispatch(
								setOrderProps(orderId, 'setInventoryRequired', {
									requireInventory: 'reconditioned',
								}),
							)
						}
					>
						Reacondicionados
					</Menu.Item>
					<Menu.Item
						key='requireInventoryAny'
						style={
							metadata.props &&
							metadata.props.requireInventory === 'any' && {
								backgroundColor: isDarkTheme
									? theme.menu.item.selected.backgroundColor
									: customTheme._surfaceBackgroundColor,
							}
						}
						onClick={() =>
							dispatch(
								setOrderProps(orderId, 'setInventoryRequired', {
									requireInventory: 'any',
								}),
							)
						}
					>
						Cualquiera
					</Menu.Item>
				</Menu.Group>
			</Menu.Wrapper>
		</Spin>
	);
}

const Header = ({ transactionDoc, order, settingOrderProps }) => {
	const dispatch = useDispatch();
	return (
		<Wrapper padding='0' justifyContent='flex-end'>
			{transactionDoc.order_id && (
				<Button
					style={{ marginRight: '10px', height: '24px' }}
					onClick={() => dispatch(revertBill(transactionDoc.order_id))}
				>
					Revertir
				</Button>
			)}

			{transactionDoc.id && !transactionDoc.completed && (
				<Button
					style={{ height: '24px' }}
					onClick={() =>
						dispatch(signWTD(transactionDoc.id, undefined, true, 'billing'))
					}
				>
					Aprobar
				</Button>
			)}

			<Popover
				content={
					<InventoryOptionsContent
						order={order}
						settingOrderProps={settingOrderProps}
					/>
				}
				placement='bottomRight'
				title={null}
				trigger='hover'
			>
				<div>
					<MoreIcon button />
				</div>
			</Popover>
		</Wrapper>
	);
};

export default function BillingTab({ orderId, metadata }) {
	const isMounted = useRef(true);
	const isMobile = useSelector(selectIsMobile);
	const [state, _setState] = useState({
		isFetching: false,
		transactionDoc: {},
		inventory: [],
	});
	const setState = props => _setState(current => ({ ...current, ...props }));

	const orderInformation = useSelector(getOrderInformation);

	const { settingOrderProps } = orderInformation;

	const { getBillingMaterials } = useWarehouseTransactionDocApi();
	const { updateTransactionItems } = useWarehouseTransactionDocItemApi();

	const onGetBillingMateriales = () => {
		if (!isMounted.current) return;

		setState({ isFetching: true });
		getBillingMaterials(orderId)
			.then(
				({ inventory, comments, ...transactionDoc }) =>
					isMounted.current &&
					setState({ transactionDoc, inventory, isFetching: false }),
			)
			.catch(() => isMounted.current && setState({ isFetching: false }));
	};

	const onUpdateTransactionItems = item =>
		updateTransactionItems([item], 'disapprove').then(onGetBillingMateriales);

	useEffect(() => {
		onGetBillingMateriales();

		return () => {
			isMounted.current = false;
		};
	}, [orderId]);

	if (state.isFetching) return <Spining />;

	return (
		<div className='container-dark'>
			<Header
				transactionDoc={state.transactionDoc}
				order={{
					orderId,
					metadata,
				}}
				settingOrderProps={settingOrderProps}
			/>
			{!state.inventory || state.inventory.length === 0 ? (
				<EmptyIllustration
					title='No hay material registrado'
					width={!isMobile ? '300px' : '100px'}
					fontSize='2em'
				/>
			) : (
				state.inventory.map(row => (
					<BillingMaterialCard
						{...row}
						key={row.id}
						customProps={{
							onUpdateTransactionItems,
							completed: state.transactionDoc.completed,
						}}
					/>
				))
			)}
		</div>
	);
}
