//Libs
import React from 'react';
//Components
import { Wrapper } from 'components';
import Collector from 'components/Collector/Collector';
import CollectorUtils from 'components/Collector/CollectorUtils';

const UngroupedCollectors = React.memo(
	({
		profile,
		order,
		docId,
		ungroupedCollectors,
		readOnlyCollector,
		highlightRequired,
		selectedCurrentCollector,
		isPhotoBackupActive,
		isSynapseCameraActive,
		filteredCollectorLayout,
		collectorValues,
		resources,
		collectorEcosystemAutoSyncRunning,
		handleOnDuplicateElement,
		handleOnDeleteElement,
	}) => {
		if (ungroupedCollectors.length === 0) return null;

		return (
			<Wrapper flexDirection='column' alignItems='flex-start'>
				{CollectorUtils.checkArray(ungroupedCollectors).map(
					(collector, idx) =>
						!collector.groupId && (
							<Wrapper
								key={idx}
								padding='5px 0 5px 5px'
								width='100%'
								flexDirection='column'
								alignItems='flex-start'
							>
								<Collector
									profile={profile}
									order={order}
									docId={docId}
									collector={collector}
									readOnlyCollector={readOnlyCollector}
									highlightRequired={highlightRequired}
									selectedCurrentCollector={selectedCurrentCollector}
									isPhotoBackupActive={isPhotoBackupActive}
									isSynapseCameraActive={isSynapseCameraActive}
									filteredCollectorLayout={filteredCollectorLayout}
									collectorValues={collectorValues}
									resources={resources}
									collectorEcosystemAutoSyncRunning={
										collectorEcosystemAutoSyncRunning
									}
									handleOnDuplicateElement={handleOnDuplicateElement}
									handleOnDeleteElement={handleOnDeleteElement}
								/>
							</Wrapper>
						),
				)}
			</Wrapper>
		);
	},
);

export default UngroupedCollectors;
