// Libs
import React from 'react';
import styled, { css } from 'styled-components';
// Components
import { default as _IconButton } from '@mui/material/IconButton';
import iconSmall from './logo-synapse24x24.svg';
import iconMedium from './logo-synapse40x38.svg';
import iconLarge from './logo-synapse52x50.svg';

const SIZES = {
	small: 's',
	medium: 'm',
	large: 'l',
};

const Icon = styled.div`
	${props =>
		(!props.size || props.size === SIZES.small) &&
		css`
			background-image: url(${iconSmall});
			width: 24px;
			height: 24px;
		`}
	${props =>
		props.size === SIZES.medium &&
		css`
			background-image: url(${iconMedium});
			width: 40px;
			height: 38px;
		`}
  ${props =>
		props.size === SIZES.large &&
		css`
			background-image: url(${iconLarge});
			width: 52px;
			height: 50px;
		`}
  background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	margin: ${props => props.margin};
	${props =>
		(props.spin && !props.button) !== undefined &&
		css`
			animation: spin ${props.spin ? '1.5s' : '3.5s'} linear infinite;

			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		`}
`;

const IconButton = styled(_IconButton)`
	${props =>
		props.spin !== undefined &&
		css`
			animation: spin ${props.spin ? '1.5s' : '3.5s'} linear infinite;

			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		`}
`;

const LogoIcon = ({ button, onClick, spin, margin, size }) =>
	button ? (
		<IconButton spin={spin} onClick={onClick}>
			<Icon />
		</IconButton>
	) : (
		<Icon spin={spin} margin={margin} onClick={onClick} size={size} />
	);

export default LogoIcon;
