// Keywords
import KEYWORD_FEED from 'core/keywords';
// Themes
import { dark, lightness } from 'themes';
import { GlobalUtils } from 'utils';
import KEYWORDS from './keywords';

const { ROUTES } = KEYWORDS;

const checkDashboardContext = pathname => pathname.includes('/dashboard');
const defaultSystemRoute = KEYWORD_FEED.ROUTES.FEED.PATH;

export default class ModuleUtils {
	// CHECKS
	// Check is fetching modules
	static checkIsFetchingModules(accessControlState) {
		return accessControlState === KEYWORDS.STATE.LOADING;
	}

	// Check access control unauthorized
	static checkAccessControlUnauthorized({ accessControlState, profile }) {
		return !profile || accessControlState === KEYWORDS.STATE.UNAUTHORIZED;
	}

	// Check device access
	static checkDeviceAccess(modeView, item) {
		if (!item) return false;

		return (
			(modeView === 'cards' && item.mobile) ||
			(modeView === 'table' && item.desktop)
		);
	}

	// GETTERS
	static getDefaultRoute(profile) {
		return profile?.user?.profile?.modules?.defaultRoute;
	}

	// Get Screen Props
	static getScreenProps({ theme, routes }) {
		return { theme, routes };
	}

	// Get selected user theme
	static getSelectedTheme(profileTheme) {
		if (!profileTheme || profileTheme === 'dark') {
			return dark;
		}
		return dark; // TODO: Debería ser: lightness
	}

	static getRouteFromModuleItemKey(moduleItemKey = '') {
		return moduleItemKey.replaceAll('.control', '');
	}

	static getModuleItemKeyFromPathname(pathname) {
		return (
			pathname.toLowerCase().split(`${KEYWORD_FEED.ROUTES.FEED.PATH}/`)[1] || ''
		);
	}

	static fixAndNormalizeRoute(route = '') {
		return route.replaceAll('//', '/');
	}

	static getModuleItemIdFromPathname(pathname, modules) {
		const currentRoute = this.getModuleItemKeyFromPathname(pathname);
		const response = {
			moduleId: null,
			moduleItemId: null,
		};
		modules.find(module => {
			const moduleItem = module.moduleItems.find(({ key }) =>
				currentRoute.includes(this.getRouteFromModuleItemKey(key)),
			);
			if (moduleItem) {
				response.moduleId = module.id;
				response.moduleItemId = moduleItem.id;
				return true;
			}
		});

		return response;
	}

	// Find module by key
	static findModuleByKey(key, modules) {
		return GlobalUtils.checkArray(modules).find(m => m.key === key);
	}

	// Find module item by component
	static findModuleItemByComponentKey(component, key, moduleItems) {
		return GlobalUtils.checkArray(moduleItems).find(
			item => item.component === component && item.key === key,
		);
	}

	// Get market item new status
	static getMarketItemNewStatus(currentStatus) {
		const { NO_INSTALLED, INSTALLED, INSTALL, UNINSTALL } =
			KEYWORDS.MODULES.ITEMS.STATUS;

		if (currentStatus === NO_INSTALLED) return INSTALL;
		if (currentStatus === INSTALLED) return UNINSTALL;
	}

	// Translate market item status with a market item status key
	static getMarketplaceItemActionByStatusKey(status) {
		const { INSTALL, UNINSTALL } = KEYWORDS.MODULES.ITEMS.STATUS;

		if (status === INSTALL) return 'instalar';
		if (status === UNINSTALL) return 'desinstalar';
	}

	// SETTERS
	// Redirect Route
	static redirectRoute({
		intro,
		isFetchingModules,
		unauthorized,
		profile,
		currentPathname,
		modules,
		history,
		routes,
		initialRoute,
	}) {
		// Intro || fetchingModules
		if (!currentPathname || intro || isFetchingModules) {
			return history.replace(ROUTES.ONBOARDING.FETCHING_MODULES);
		}

		// Unauthorized
		if (unauthorized) {
			const hasRoute = routes?.[0].routes.some(
				route => route.path === currentPathname,
			);
			if (!hasRoute) history.replace(ROUTES.ONBOARDING.LOGIN);
			return;
		}

		// Access to entered route
		const currentRoute = this.getModuleItemKeyFromPathname(currentPathname);
		const hasModuleRouteAccess = modules.some(module =>
			module.moduleItems.some(
				({ key }) =>
					currentRoute.includes(this.getRouteFromModuleItemKey(key)) ||
					checkDashboardContext(currentPathname), // TODO: Esta condición se deberá borrar cuando se haya terminado de migrar a la versión 2 a todos los clientes
			),
		);
		if (hasModuleRouteAccess) {
			return history.replace(this.fixAndNormalizeRoute(currentPathname));
		}

		if (currentPathname === KEYWORD_FEED.ROUTES.FEED.PATH) {
			return history.replace(defaultSystemRoute);
		}

		if (initialRoute.current) {
			const hasModuleRouteAccess = modules.some(module =>
				module.moduleItems.some(
					({ key }) =>
						initialRoute.current.includes(
							this.getRouteFromModuleItemKey(key),
						) || checkDashboardContext(initialRoute.current), // TODO: Esta condición se deberá borrar cuando se haya terminado de migrar a la versión 2 a todos los clientes
				),
			);
			if (hasModuleRouteAccess) {
				return history.replace(this.fixAndNormalizeRoute(initialRoute.current));
			}
		}

		// Default route
		const defaultUserRoute = this.getDefaultRoute(profile);
		if (defaultUserRoute) history.replace(defaultUserRoute);
		else history.replace(defaultSystemRoute);
	}
}
