// Libs
import { createSelector } from 'reselect';
import Immutable from 'immutable';
import ScreenBuilderConfig from './ScreenBuilder/screen.config';
import ElementBuilderConfig from './ElementBuilder/element.config';

const selectFeed = state => state.feed;

// Main
export const selectDatasource = createSelector(
	[selectFeed],
	({ datasource }) => ({
		...datasource,
		data: Immutable.List(datasource.data).toJS(),
	}),
);
export const selectDatasourceData = createSelector(
	[selectDatasource],
	({ data }) => data,
);
export const selectReloadDatasource = createSelector(
	[selectFeed],
	({ datasource }) => datasource.reload,
);
export const selectSearchDatasource = createSelector(
	[selectFeed],
	({ datasource }) => datasource.search,
);
export const selectIsOpenDatasource = createSelector(
	[selectDatasource],
	({ isOpen }) => isOpen,
);
export const selectDatasourceStatus = createSelector(
	[selectDatasource],
	({ status }) => status,
);
export const selectDatasourcePaging = createSelector(
	[selectDatasource],
	({ paging }) => paging,
);
export const selectDatasourceFields = createSelector(
	[selectDatasource],
	({ fields }) => fields,
);

export const selectDatasourceSingleData = createSelector(
	[selectDatasource],
	({ singleData }) => singleData,
);
export const selectActionForm = createSelector(
	[selectFeed],
	({ actionForm }) => actionForm,
);
export const selectDisplay = createSelector([selectFeed], ({ display }) => ({
	...display,
	screens: Immutable.List(display.screens).toJS(),
}));
export const selectIsFetchingDisplay = createSelector(
	[selectDisplay],
	({ isFetching }) => isFetching,
);
// Screens
export const selectSelectedScreenId = createSelector(
	[selectDisplay],
	({ selectedScreenId }) => selectedScreenId,
);
export const selectAuthorizedScreens = createSelector(
	[selectFeed],
	({ display }) => ScreenBuilderConfig.getAuthorizedScreens(display.screens),
);
export const selectSelectedScreen = createSelector(
	[selectSelectedScreenId, selectAuthorizedScreens],
	(selectedScreenId, authorizedScreens) =>
		ScreenBuilderConfig.getSelectedScreen(
			selectedScreenId,
			authorizedScreens,
		) || {},
);
export const selectScreenKey = createSelector(
	[selectSelectedScreen],
	({ key }) => key,
);

// Elements
export const selectSelectedElementId = createSelector(
	[selectDisplay],
	({ selectedElementId }) => selectedElementId,
);
export const selectAuthorizedElements = createSelector(
	[selectSelectedScreen],
	({ elements }) => ElementBuilderConfig.getAuthorizedElements(elements),
);
export const selectSelectedElement = createSelector(
	[selectSelectedElementId, selectAuthorizedElements],
	(selectedElementId, authorizedElements) =>
		ElementBuilderConfig.getSelectedElement(
			selectedElementId,
			authorizedElements,
		) || {},
);
export const selectElementKey = createSelector(
	[selectSelectedElement],
	({ key }) => key,
);

// Projects
export const selectSelectedProjectId = createSelector(
	[selectFeed],
	({ projects }) => projects.selectedProjectId,
);
export const selectSelectedProject = createSelector(
	[selectFeed],
	({ projects }) =>
		projects.data.find(project => project.id === projects.selectedProjectId),
);

export const selectProjectsIsFetching = createSelector(
	[selectFeed],
	({ projects }) => projects.isFetching,
);
export const selectProjects = createSelector([selectFeed], ({ projects }) =>
	Immutable.List(projects.data).toJS(),
);
// Order Target Filters
export const selectSelectedOrderTargetFilterId = createSelector(
	[selectFeed],
	({ orderTargetFilters }) => orderTargetFilters.selectedOrderTargetFilterId,
);
export const selectOrderTargetFiltersIsFetching = createSelector(
	[selectFeed],
	({ orderTargetFilters }) => orderTargetFilters.isFetching,
);
export const selectOrderTargetFilters = createSelector(
	[selectFeed],
	({ orderTargetFilters }) => Immutable.List(orderTargetFilters.data).toJS(),
);
// Services
export const selectSelectedServiceIds = createSelector(
	[selectFeed],
	({ services }) => Immutable.List(services.selectedServiceIds).toJS(),
);
export const selectServicesIsFetching = createSelector(
	[selectFeed],
	({ services }) => services.isFetching,
);
export const selectServices = createSelector([selectFeed], ({ services }) =>
	Immutable.List(services.data).toJS(),
);
// Data Filters
export const selectSelectedDataFilterId = createSelector(
	[selectFeed],
	({ dataFilters }) => dataFilters.selectedDataFilterId,
);
export const selectSelectedDataSourceConfig = createSelector(
	[selectFeed],
	({ dataFilters }) => dataFilters.config,
);
export const selectDataFiltersIsFetching = createSelector(
	[selectFeed],
	({ dataFilters }) => dataFilters.isFetching,
);
export const selectDataFilters = createSelector(
	[selectFeed],
	({ dataFilters }) => Immutable.List(dataFilters.data).toJS(),
);
export const selectSelectedDataFilterLastRowId = createSelector(
	[selectFeed],
	({ dataFilters }) => dataFilters.lastRowId,
);
export const selectReloadSubDataFilters = createSelector(
	[selectFeed],
	({ dataFilters }) => dataFilters.reloadSubDataFilters,
);
export const selectSubDataFilters = createSelector(
	[selectFeed],
	({ dataFilters }) => {
		const { selectedDataFilterId, data } = dataFilters;
		if (!selectedDataFilterId || data.length === 0) return [];

		const subDataFilters =
			data.find(df => df.id === selectedDataFilterId)?.filters ?? [];

		return subDataFilters.map(filter => ({
			...filter,
			data: Immutable.List(filter.data).toJS(),
		}));
	},
);
export const selectDataFilterUserParams = createSelector(
	[selectFeed],
	({ dataFilters }) => dataFilters.userParams,
);

// Experts
export const selectExpertsIsFetching = createSelector(
	[selectFeed],
	({ experts }) => experts.isFetching,
);
export const selectExperts = createSelector([selectFeed], ({ experts }) =>
	Immutable.List(experts.data).toJS(),
);
export const selectAutoSyncOfflineData = createSelector(
	[selectFeed],
	({ autoSyncOfflineData }) => autoSyncOfflineData,
);
