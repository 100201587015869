// Libs
import React from 'react';
import { useTheme } from 'styled-components';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
// Actions
import { mutate1Object, getCharts } from 'screens/Analytics/actions';
import { getDepartments, getServices } from 'store/api/actions';
// Selectors
import {
	selectChartDashboard,
	selectGetChartsControl,
} from 'screens/Analytics/selectors';
import { selectMyDepartments, selectServices } from 'store/api/selectors';
// Utils
import KEYWORDS from 'screens/Analytics/keywords';
import GlobalUtils from 'utils/GlobalUtils';
// Components
import {
	Wrapper,
	CalendarIcon,
	Popover,
	Tooltip,
	EyeIcon,
	FiltersIcon,
} from 'components';
import Menu from 'components/Menu';
import DatePicker from 'components/DatePicker';

const { SubMenu } = Menu;
const { RangePicker } = DatePicker;

const AnalyticsSubtoolbar = ({
	// state
	chartDashboard,
	getChartsControl,
	departments,
	services,
	// actions
	mutate1Object,
	getCharts,
	getDepartments,
	getServices,
}) => {
	const theme = useTheme();
	const [showDateRangePopover, setShowDateRangePopover] = React.useState(false);
	const [selectedDate, setSelectedDate] = React.useState(undefined);
	const { segment, params: userParams } = getChartsControl;

	// All projects
	const allProjects = React.useMemo(
		() => departments.map(({ id }) => id),
		[departments],
	);
	// All services
	const allServices = React.useMemo(
		() => services.data.map(({ id }) => id),
		[userParams[KEYWORDS.PARAMS.PROJECTS], services.data],
	);
	// On change range
	const onChangeRange = ([startDate, endDate]) => {
		const params = {
			...userParams,
			fromDateParam: startDate,
			toDateParam: endDate,
		};
		mutate1Object('getChartsControl', { params });
		setShowDateRangePopover(false);
		getCharts({ segment, params });
	};
	// On change segment
	const onChangeSegment = segment =>
		mutate1Object('getChartsControl', { segment });
	// On change select
	const onChangeParams = newParams => {
		mutate1Object('getChartsControl', {
			params: {
				...userParams,
				...newParams,
			},
		});
	};
	// On change select
	const onChangeSelect = React.useCallback(
		({ selectedKeys }) => {
			const projects = GlobalUtils.checkArray(selectedKeys)
				.map(key => {
					if (key.includes('project_')) {
						return key.split('project_')[1] ?? [];
					}
				})
				.filter(key => key);
			const services = selectedKeys
				.map(key => {
					if (key.includes('service_')) {
						return key.split('service_')[1] ?? [];
					}
				})
				.filter(key => key);

			onChangeParams({
				[KEYWORDS.PARAMS.PROJECTS]:
					projects.indexOf('all') !== -1 ? allProjects : projects,
				[KEYWORDS.PARAMS.SERVICES]:
					services.indexOf('all') !== -1 ? allServices : services,
			});
		},
		[allProjects, allServices],
	);
	// Search selected id
	const isSelectedId = React.useCallback((findId, ids) => {
		const findIdNum = Number(findId);
		return (
			GlobalUtils.checkArray(ids)
				.map(i => Number(i))
				.indexOf(findIdNum) !== -1
		);
	}, []);
	// Get departments
	React.useEffect(() => {
		getDepartments();
	}, []);
	// Get services
	React.useEffect(() => {
		GlobalUtils.checkArray(userParams?.[KEYWORDS.PARAMS.PROJECTS]).length > 0 &&
			getServices(userParams[KEYWORDS.PARAMS.PROJECTS]);
	}, [userParams[KEYWORDS.PARAMS.PROJECTS]]);
	// Clean service param
	React.useEffect(() => {
		// onChangeParams({ [KEYWORDS.PARAMS.SERVICES]: [] });
	}, [services.data]);

	const dateRanges = React.useMemo(() => {
		const { fromDateParam, toDateParam } = userParams;
		const dates = [fromDateParam, toDateParam];
		if (fromDateParam instanceof dayjs) {
			dates[0] = undefined;
		}
		if (toDateParam instanceof dayjs) {
			dates[1] = undefined;
		}
		return dates;
	}, [userParams.fromDateParam, userParams.toDateParam]);

	const dateRangeContent = (
		<Menu.Wrapper
			mode='inline'
			style={{
				width: 300,
			}}
		>
			<Menu.Item
				style={
					selectedDate ===
						KEYWORDS.PARAMS.FROM_DATE_PARAM.DEFAULT_VALUES.LAST_3_MONTH && {
						backgroundColor: theme.menu.item.selected.backgroundColor,
					}
				}
				onClick={() => {
					const startDate = dayjs().subtract(3, 'month');
					const endDate = dayjs();
					onChangeRange([startDate, endDate]);
					setSelectedDate(
						KEYWORDS.PARAMS.FROM_DATE_PARAM.DEFAULT_VALUES.LAST_3_MONTH,
					);
				}}
			>
				Últimos 3 mes
			</Menu.Item>
			<Menu.Item
				style={
					selectedDate ===
						KEYWORDS.PARAMS.FROM_DATE_PARAM.DEFAULT_VALUES.LAST_1_MONTH && {
						backgroundColor: theme.menu.item.selected.backgroundColor,
					}
				}
				onClick={() => {
					const startDate = dayjs().subtract(1, 'month');
					const endDate = dayjs();
					onChangeRange([startDate, endDate]);
					setSelectedDate(
						KEYWORDS.PARAMS.FROM_DATE_PARAM.DEFAULT_VALUES.LAST_1_MONTH,
					);
				}}
			>
				Último mes
			</Menu.Item>
			<Menu.Item
				style={
					selectedDate ===
						KEYWORDS.PARAMS.FROM_DATE_PARAM.DEFAULT_VALUES.LAST_2_WEEK && {
						backgroundColor: theme.menu.item.selected.backgroundColor,
					}
				}
				onClick={() => {
					const startDate = dayjs().subtract(2, 'week');
					const endDate = dayjs();
					onChangeRange([startDate, endDate]);
					setSelectedDate(
						KEYWORDS.PARAMS.FROM_DATE_PARAM.DEFAULT_VALUES.LAST_2_WEEK,
					);
				}}
			>
				Últimos 15 días
			</Menu.Item>
			<Menu.Item
				style={
					selectedDate ===
						KEYWORDS.PARAMS.FROM_DATE_PARAM.DEFAULT_VALUES.LAST_1_WEEK && {
						backgroundColor: theme.menu.item.selected.backgroundColor,
					}
				}
				onClick={() => {
					const startDate = dayjs().subtract(1, 'week');
					const endDate = dayjs();
					onChangeRange([startDate, endDate]);
					setSelectedDate(
						KEYWORDS.PARAMS.FROM_DATE_PARAM.DEFAULT_VALUES.LAST_1_WEEK,
					);
				}}
			>
				Últimos 7 días
			</Menu.Item>
			<Menu.Item
				style={
					selectedDate ===
						KEYWORDS.PARAMS.FROM_DATE_PARAM.DEFAULT_VALUES.START_OF_WEEK && {
						backgroundColor: theme.menu.item.selected.backgroundColor,
					}
				}
				onClick={() => {
					const startDate = dayjs().startOf('week');
					const endDate = dayjs();
					onChangeRange([startDate, endDate]);
					setSelectedDate(
						KEYWORDS.PARAMS.FROM_DATE_PARAM.DEFAULT_VALUES.START_OF_WEEK,
					);
				}}
			>
				Semana en curso
			</Menu.Item>
			<Menu.Item
				style={
					selectedDate ===
						KEYWORDS.PARAMS.FROM_DATE_PARAM.DEFAULT_VALUES.START_OF_DAY && {
						backgroundColor: theme.menu.item.selected.backgroundColor,
					}
				}
				onClick={() => {
					const startDate = dayjs().startOf('day');
					const endDate = dayjs().endOf('day');
					onChangeRange([startDate, endDate]);
					setSelectedDate(
						KEYWORDS.PARAMS.FROM_DATE_PARAM.DEFAULT_VALUES.START_OF_DAY,
					);
				}}
			>
				Hoy
			</Menu.Item>
			<SubMenu key='sub1' title='Rango personalizado'>
				<Menu.Item>
					<RangePicker
						placeholder='Seleccione el rango'
						value={dateRanges}
						onChange={dates => onChangeRange(dates)}
					/>
				</Menu.Item>
			</SubMenu>
		</Menu.Wrapper>
	);

	const segmentContent = (
		<Menu.Wrapper
			mode='inline'
			style={{
				width: 300,
			}}
		>
			<Menu.Item
				style={
					segment === KEYWORDS.SEGMENT.ORDERS && {
						backgroundColor: theme.menu.item.selected.backgroundColor,
					}
				}
				onClick={() => onChangeSegment(KEYWORDS.SEGMENT.ORDERS)}
			>
				Ordenes
			</Menu.Item>
			<Menu.Item
				style={
					segment === KEYWORDS.SEGMENT.WAREHOUSES && {
						backgroundColor: theme.menu.item.selected.backgroundColor,
					}
				}
				onClick={() => onChangeSegment(KEYWORDS.SEGMENT.WAREHOUSES)}
			>
				Almacenes
			</Menu.Item>
			<Menu.Item
				style={
					segment === KEYWORDS.SEGMENT.BILLINGS && {
						backgroundColor: theme.menu.item.selected.backgroundColor,
					}
				}
				onClick={() => onChangeSegment(KEYWORDS.SEGMENT.BILLINGS)}
			>
				Facturación
			</Menu.Item>
		</Menu.Wrapper>
	);

	const filterContent = (
		<Menu.Wrapper
			mode='inline'
			multiple
			style={{
				width: 300,
			}}
			onSelect={({ selectedKeys }) => onChangeSelect({ selectedKeys })}
			onDeselect={({ selectedKeys }) => onChangeSelect({ selectedKeys })}
		>
			<SubMenu title='Proyecto' maxHeight='350px' overflow='auto'>
				<Menu.Item key='project_all'>Todos</Menu.Item>
				{departments.map(({ id, name }) => (
					<Menu.Item
						key={`project_${id}`}
						style={
							isSelectedId(id, userParams?.[KEYWORDS.PARAMS.PROJECTS]) && {
								backgroundColor: theme.menu.item.selected.backgroundColor,
							}
						}
					>
						{name}
					</Menu.Item>
				))}
			</SubMenu>
			<SubMenu title='Servicio' maxHeight='350px' overflow='auto'>
				<Menu.Item key='service_all'>Todos</Menu.Item>
				{services.data.map(({ id, name }) => (
					<Menu.Item
						key={`service_${id}`}
						style={
							isSelectedId(id, userParams?.[KEYWORDS.PARAMS.SERVICES]) && {
								backgroundColor: theme.menu.item.selected.backgroundColor,
							}
						}
					>
						{name}
					</Menu.Item>
				))}
			</SubMenu>
		</Menu.Wrapper>
	);

	return (
		<Wrapper
			height='48px'
			justifyContent='space-between'
			padding='10px 0 10px 0'
			mediumBackground
		>
			<div />
			<Wrapper padding='0'>
				{/* SEGMENT */}
				{!chartDashboard.loading && (
					<Popover
						content={segmentContent}
						placement='bottomRight'
						title='Segmento de Análisis'
						trigger='click'
					>
						<div>
							<EyeIcon button />
						</div>
					</Popover>
				)}
				{/* Filter */}
				{!chartDashboard.loading && (
					<Popover
						content={filterContent}
						placement='bottomRight'
						title='Filtrar'
						trigger='click'
					>
						<div>
							<FiltersIcon button />
						</div>
					</Popover>
				)}
				{/* DATES */}
				{!chartDashboard.loading && (
					<Tooltip placement='topRight' title='Rango de fechas'>
						<Popover
							content={dateRangeContent}
							placement='bottomRight'
							title={null}
							trigger='click'
							visible={showDateRangePopover}
							onVisibleChange={() =>
								setShowDateRangePopover(!showDateRangePopover)
							}
						>
							<CalendarIcon button />
						</Popover>
					</Tooltip>
				)}
			</Wrapper>
		</Wrapper>
	);
};

const mapStateToProps = state => ({
	chartDashboard: selectChartDashboard(state),
	getChartsControl: selectGetChartsControl(state),
	departments: selectMyDepartments(state),
	services: selectServices(state),
});

const actions = {
	mutate1Object,
	getCharts,
	getDepartments,
	getServices,
};

export default connect(mapStateToProps, actions)(AnalyticsSubtoolbar);
