// Libs
import React from 'react';
// Utils
import {
	getMyEntityActiveUsers,
	getSpecificUsers,
	getMyEntityWarehouses,
	getConsolidateWarehouses,
	filterWarehousesSearchInData,
} from 'utils/libs';
// Components
import { Modal, VirtualizedList } from 'screens/Utils';
// Styles
import '../../css/WarehousesList.css';

function WarehousesListMobile(props) {
	const {
		control,
		stock,
		createWarehouse,
		profile,
		mutate1Object,
		mutate1ObjectApi,
		handleOnClickCreateWarehouse,
		getStockArticles,
	} = props;
	const users = getMyEntityActiveUsers(props.users.data, profile);
	const experts = getSpecificUsers(users, { experts: true });
	const entities = getSpecificUsers(users, { entities: true });

	let warehouses = [...props.warehouses.data];
	warehouses = getMyEntityWarehouses(props.warehouses.data, profile);
	if (control.mode === 'consolidate') {
		warehouses = getConsolidateWarehouses(warehouses, entities);
	}
	warehouses = filterWarehousesSearchInData(
		warehouses,
		control.searchInData.searchBy,
		control.searchInData.data,
	);

	return (
		<div style={{ padding: '8px' }}>
			<VirtualizedList
				cardId='warehouseCard'
				interpolatedRowStyle
				listStyle={{
					height: document.documentElement.clientHeight,
					width: document.documentElement.clientWidth,
				}}
				rowHeight={90}
				spinTitle='Consultando almacenes...'
				rows={warehouses}
				customProps={{
					stock,
					control,
					mutate1ObjectApi,
					getStockArticles,
				}}
			/>

			<Modal
				modalId='CreateWarehouseModal'
				title='Nuevo Almacén'
				visible={createWarehouse.isOpen}
				onCancel={() => mutate1Object('createWarehouse', { isOpen: false })}
				footer={null}
				isSpinning={createWarehouse.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					entities,
					createWarehouse,
					profile,
					mutate1Object,
					handleOnClickCreateWarehouse,
				}}
			/>
		</div>
	);
}

export default WarehousesListMobile;
