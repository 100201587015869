// Libs
import React from 'react';

const LeftArrows = ({ fill, fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M15 12L19 4H15L11 12L15 20H19L15 12ZM13 20L9 12L13 4L9.5 4L5.5 12L9.5 20H13Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);

export default LeftArrows;
