// Libs
import styled from 'styled-components';
// Components
import { Carousel as _Carousel } from 'react-responsive-carousel';
// Styles
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Carousel = styled(_Carousel)``;

export default Carousel;
