// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Utils
import { GlobalUtils } from 'utils';
// Selectors
import {
	selectSelectedProjectId,
	selectSelectedOrderTargetFilterId,
	selectSelectedServiceIds,
	selectDataFilterUserParams,
} from 'core/selectors';
// Hooks
import { useChartManager } from 'hooks';
// Config
import ChartBuilderConfig from './chart.config';
// Components
import { Title, Wrapper, BackIcon, LogoIcon } from 'components';
import { EmptyIllustration } from 'components/Illustrations';

const ChartBuilder = ({
	chart,
	onNextChildrenChart,
	onBackChildrenChart,
	onSetElementView,
	tableRenderer,
}) => {
	const { getChartPayload } = useChartManager();
	const [isFetching, setIsFetching] = useState(false);
	const [payload, setPayload] = useState(undefined);

	const selectedProjectId = useSelector(selectSelectedProjectId);
	const selectedOrderTargetFilterId = useSelector(
		selectSelectedOrderTargetFilterId,
	);
	const selectedServiceIds = useSelector(selectSelectedServiceIds);
	const dataFilterUserParams = useSelector(selectDataFilterUserParams);

	const { id, parentSegmentIdParam } = chart;

	useEffect(() => {
		let isMounted = true;
		setIsFetching(true);
		getChartPayload(
			id,
			{
				...dataFilterUserParams,
				selectedProjectId,
				selectedOrderTargetFilterId,
				selectedServiceIds,
				parentSegmentIdParam,
			},
			{ onError: () => setIsFetching(false) },
		).then(payload => {
			setIsFetching(false);
			if (!payload || !isMounted) return;
			setPayload(prev => ({
				...prev,
				...payload,
				...payload.data,
				data: GlobalUtils.checkArray(payload.data.data),
				data2: GlobalUtils.checkArray(payload.data.data2),
			}));
		});

		return () => {
			isMounted = false;
		};
	}, [id]);

	if (isFetching) {
		return (
			<Wrapper width='100%' height='300px' justifyContent='center'>
				<LogoIcon spin={true} />
			</Wrapper>
		);
	}

	if (!payload) return null;

	const Chart = ChartBuilderConfig.getAuthorizedChartReactNode(payload.key);
	if (!Chart) return null;

	const isEmpty = !payload.data.length;

	const onClickChartSegment = ({ id: parentSegmentIdParam }) => {
		if (!onNextChildrenChart) return;
		onNextChildrenChart(payload.childrenId, parentSegmentIdParam);
	};

	return (
		<Wrapper
			width='100%'
			padding={0}
			flexDirection='column'
			onClick={!isEmpty ? onSetElementView : undefined}
		>
			<Wrapper
				padding='0'
				width='100%'
				justifyContent={onSetElementView ? 'center' : 'flex-start'}
			>
				{onBackChildrenChart && <BackIcon onClick={onBackChildrenChart} />}
				<Title margin={!onSetElementView ? '0 0 0 10px' : undefined}>
					{payload.name}
				</Title>
			</Wrapper>
			{isEmpty ? (
				<EmptyIllustration
					title='Sin datos que mostrar'
					width='100%'
					fontSize='1em'
				/>
			) : (
				<>
					<Chart {...payload} onClickChartSegment={onClickChartSegment} />
					{tableRenderer ? tableRenderer(payload) : null}
				</>
			)}
		</Wrapper>
	);
};

export default ChartBuilder;
