// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='mask0_24242_3113'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='4'
			y='6'
			width='17'
			height='13'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9 12H4V6H9V12ZM9 19H4V13H9V19ZM10 19H15V13H10V19ZM21 19H16V13H21V19ZM10 12H15V6H10V12ZM16 12V6H21V12H16Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#mask0_24242_3113)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
