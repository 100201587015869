// Libs
import React, { useContext, useEffect, useState } from 'react';
// Context
import { ElementViewBuilderContext } from 'core/context';
// Hooks
import { useToastMessage } from 'hooks';
import useOrderApi from 'core/hooks/useCoreApi';
// Tabs
import { BillingTab, DetailsTab, HistoryTab, ReviewsTab } from './Tabs';
// Components
import { CloseIcon, Tabs, Wrapper } from 'components';
import CollectorOrderModel from 'components/Collector/OrderModel';
import Spining from './Spining';
// Styles
import './index.css';

const { Wrap: TabWrap, Pane: TabPane } = Tabs;

function reviewOrderDataMapping({ orderId, orderData, metadata }) {
	const data = {
		orderId,
		serviceId: orderData.fields.find(f => f.id === 'serviceId')?.value,
		departmentId: orderData.fields.find(f => f.id === 'departmentId')?.value,
		odtId: orderData.fields.find(f => f.id === 'odtId')?.value,

		departmentProps: metadata.departmentProps,
		serviceProps: metadata.serviceProps,
		orderProps: metadata.orderProps,
	};
	return new CollectorOrderModel(data);
}

const OrderInformation = ({ orderId }) => {
	const [state, _setState] = useState({
		isFetching: true,
		data: undefined,
		metadata: undefined,
	});
	const setState = newProps =>
		_setState(current => ({ ...current, ...newProps }));
	const [activeTap, setActivatedTap] = useState('detailKey');
	const { cleanElementView } = useContext(ElementViewBuilderContext);
	const { getOrderDetails } = useOrderApi();
	const sendToast = useToastMessage();

	useEffect(() => {
		if (!orderId) return;

		let isMounted = true;
		setState({ isFetching: true });
		getOrderDetails(orderId)
			.then(({ payload }) => {
				if (!isMounted) return;
				if (!payload.data || !payload.metadata) return;
				setState({
					data: payload.data,
					metadata: payload.metadata,
					isFetching: false,
				});
			})
			.catch(({ message, type }) => {
				if (!isMounted) return;
				if (message) sendToast({ message, type });
				setState({ isFetching: false });
			});

		return () => {
			isMounted = false;
			cleanElementView();
		};
	}, [orderId]);

	return (
		<Wrapper
			mediumBackground
			padding='0 10px'
			width='100%'
			height='100%'
			maxWidth='700px'
			justifyContent='center'
			alignItems='flex-start'
		>
			<TabWrap
				activeKey={activeTap}
				tabPosition='top'
				onChange={setActivatedTap}
			>
				<TabPane tab='Detalle' key='detailKey'>
					{state.isFetching ? (
						<Spining />
					) : (
						<DetailsTab
							orderId={orderId}
							data={state.data}
							metadata={state.metadata}
						/>
					)}
				</TabPane>
				<TabPane tab='Historial' key='historyKey'>
					<HistoryTab orderId={orderId} />
				</TabPane>
				{state.data?.order && state.metadata && (
					<TabPane tab='Reportería' key='collectorEcosystemKey'>
						<ReviewsTab
							order={reviewOrderDataMapping({
								orderId,
								orderData: state.data.order,
								metadata: state.metadata,
							})}
						/>
					</TabPane>
				)}
				<TabPane tab='Inventario' key='inventoryKey'>
					<BillingTab orderId={orderId} metadata={state.metadata} />
				</TabPane>
			</TabWrap>
			<Wrapper position='absolute' right='0px' padding='0' mediumBackground>
				<CloseIcon button onClick={cleanElementView} />
			</Wrapper>
		</Wrapper>
	);
};
export default OrderInformation;
