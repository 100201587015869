import React from 'react';
import { Input } from 'antd';
import styles from './SearchBar.module.css';
import Icon from '../Icons/SearchIcon';
// Hooks
import useTheme from 'hooks/useTheme';

const SearchBar = ({ placeholder, searchTerm, setSearchTerm, ...rest }) => {
	const { theme } = useTheme();
	return (
		<Input
			style={{ backgroundColor: theme._surfaceBackgroundColor }}
			placeholder={placeholder}
			prefix={<Icon fill={theme._mediumEmphasisColor} />}
			className={styles.searchBar}
			value={searchTerm}
			onChange={e => setSearchTerm(e.target.value)}
			{...rest}
		/>
	);
};

export default SearchBar;
