// Libs
import React from 'react';
import { Link } from 'react-router-dom';
// Utils
import GENERAL from 'utils/constants/general';
import { filterOption, getSignersWTransactionsAndMyAdmUsers } from 'utils/libs';
// Components
import { Modal } from 'screens/Utils';
import { Wrapper, TableVirtualized, Flag, Span, LogoIcon } from 'components';
import Select from 'components/Select';
// Styles
import { filterTransactions } from 'utils/filters/Warehouses/transactions';
import '../../css/Transactions.css';

const { ENV } = GENERAL;
const { Option } = Select;

const { Table, Column, Header, Row, Cell } = TableVirtualized;

function TransactionsDesktop(props) {
	const {
		users,
		transactions,
		transactionsManage,
		createTransaction,
		incomeMethods,
		warehouses,
		// filterTransactionsSearchInData,
		mutate1Object,
		mutate1ObjectApi,
		getInventoryToTransfer,
		history,
		profile,
		getItemsTransactionDocs,
		getTransactionsModal,
		getTransactionDocs,
		updateWTDSigner,
		signWTDModal,
		signWTD,
	} = props;

	// Filters in table (Only Desktop)
	const totalUsers = users.data;
	const filteredTransactions = React.useMemo(
		() => filterTransactions(transactions.data, transactionsManage.filters),
		[transactions.data, transactionsManage.filters],
	);

	// Functions
	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: transactions.data,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: transactionsManage.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('transactionsManage', {
							filters: updatedFilters,
						}),
				}}
			>
				{label}
			</Header>
		);
	}

	function getIndexCellRenderer({ rowData, rowIndex }) {
		let flagPriorityStyle = {};

		if (rowData.priority) flagPriorityStyle = { ...rowData.priority.style };
		return (
			<Cell>
				<Flag style={flagPriorityStyle} height='20px' />
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	}

	function getDocNumberCellRenderer({ rowData }) {
		return (
			<Link
				className='desktop-transactions-table-docnumber-cell-container'
				to={ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP}
				onClick={() => {
					mutate1Object('transactionsManage', {
						docId: rowData.id,
						titleToolbar: 'Detalle de transacción',
					});
					getItemsTransactionDocs(rowData.id);
				}}
			>
				<div>{rowData.doc_number}</div>
			</Link>
		);
	}

	function getSignerCellRenderer({ rowData }) {
		const transactionsData = [...transactions.data];
		const transaction = transactionsData.find(
			transaction => transaction.id === rowData.id,
		);
		const signers = getSignersWTransactionsAndMyAdmUsers(
			totalUsers,
			transaction,
			profile,
		);

		function onMouseEnter() {
			transaction.changeSigner = true;
			mutate1ObjectApi('transactions', { data: transactionsData });
		}

		function onMouseLeave() {
			transaction.changeSigner = false;
			mutate1ObjectApi('transactions', { data: transactionsData });
		}

		return (
			<div
				className='desktop-transactions-table-signer-cell-container'
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{rowData.updatingSigner ? (
					<LogoIcon button spin />
				) : rowData.completed ? (
					<div>{rowData.signer_name}</div>
				) : rowData.changeSigner ? (
					<Select
						style={{ width: '100%' }}
						showSearch
						placeholder={`${rowData.signer_name}`}
						filterOption={filterOption}
						onChange={value => updateWTDSigner(rowData.id, value)}
					>
						{Array.isArray(signers) &&
							signers.map((signer, idx) => (
								<Option key={idx} value={signer.id}>
									<em>{signer.name}</em>
								</Option>
							))}
					</Select>
				) : (
					<div>{rowData.signer_name}</div>
				)}
			</div>
		);
	}

	function getRowRenderer({ key, columns, style, rowData }) {
		let styleRow = { ...style };

		// Is transaction selected?
		if (transactionsManage.docId === rowData.id) {
			styleRow = {
				...styleRow,
				color: 'lightseagreen',
				backgroundColor: 'rgb(44,44,54)',
			};
		}

		return (
			<Row
				key={key}
				style={styleRow}
				onClick={() =>
					mutate1Object('transactionsManage', { docId: rowData.id })
				}
			>
				{columns}
			</Row>
		);
	}

	// Render
	return (
		<Wrapper padding='0'>
			<Table
				highBackground
				width={document.documentElement.clientWidth}
				height={document.documentElement.clientHeight - 115}
				headerHeight={44}
				rowHeight={40}
				rowCount={filteredTransactions.length}
				rowGetter={({ index }) => filteredTransactions[index]}
				rowRenderer={getRowRenderer}
			>
				<Column
					dataKey='index'
					label='#'
					width={50}
					headerRenderer={({ label }) => <Header>{label}</Header>}
					cellRenderer={getIndexCellRenderer}
				/>
				<Column
					dataKey='doc_number'
					label='Documento'
					width={100}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
					cellRenderer={getDocNumberCellRenderer}
				/>
				<Column
					dataKey='transaction_type_name'
					label='Tipo'
					width={100}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='method_name'
					label='Método'
					width={100}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='owarehouse_name'
					label='Origen'
					width={180}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='dwarehouse_name'
					label='Destino'
					width={180}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='signer_name'
					label='Firmante'
					width={180}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
					cellRenderer={getSignerCellRenderer}
				/>
				<Column
					dataKey='creator_name'
					label='Creador'
					width={150}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='entity_name'
					label='Entidad'
					width={150}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='completed_label'
					label='Completada'
					width={110}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				{/* <Column
          dataKey="edit_label"
          label="Editable"
          width={50}
          headerRenderer={getHeaderRenderer}
        /> */}
				{/* <Column
          dataKey="created_at"
          label="Creado"
          width={130}
          headerRenderer={getHeaderRenderer}
        /> */}
				<Column
					dataKey='updated_at'
					label='Actualizado'
					width={130}
					headerRenderer={props => getHeaderRenderer(props, 'date')}
				/>
			</Table>

			<Modal
				modalId='GetTransactionsModal'
				title='Consultar Transacciones'
				visible={getTransactionsModal.isOpen}
				onCancel={() =>
					mutate1Object('getTransactionsModal', { isOpen: false })
				}
				footer={null}
				isSpinning={transactions.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					getTransactionsModal,
					getTransactionDocs,
					mutate1ObjectInWarehouses: mutate1Object,
				}}
			/>

			<Modal
				modalId='CreateTransactionModal'
				title='Nueva Transacción'
				visible={createTransaction.isOpen}
				onCancel={() =>
					mutate1Object('createTransaction', {
						isOpen: false,
						mode: undefined,
						incomeMethod: undefined,
						owhOwnerId: undefined,
						owarehouseId: undefined,
						dwarehouseId: undefined,
						signer: undefined,
					})
				}
				footer={null}
				isSpinning={createTransaction.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					createTransaction,
					incomeMethods,
					totalUsers,
					warehouses: warehouses.data,
					mutate1ObjectInWarehouses: mutate1Object,
					inventory: transactions.inventory,
					getInventoryToTransfer,
					history,
					profile,
				}}
			/>

			<Modal
				modalId='SignWTDModal'
				title='Enviar a'
				visible={signWTDModal.isOpen}
				onCancel={() => mutate1Object('signWTDModal', { isOpen: false })}
				footer={null}
				isSpinning={signWTDModal.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					profile,
					totalUsers,
					signWTDModal,
					transactions,
					mutate1ObjectInWarehouses: mutate1Object,
					transactionsManage,
					signWTD,
					updateWTDSigner,
				}}
			/>
		</Wrapper>
	);
}

export default TransactionsDesktop;
