// Libs
import styled from 'styled-components';
import { Switch as _Switch } from 'antd';

const Switch = styled(_Switch)`
	.ant-switch-checked {
		height: 20px;
	}
	.ant-switch-handle {
		top: 1px;
		width: 8px;
		height: 8px;
	}
	& {
		height: 10px;
		width: 10px;
		min-width: 20px;
	}
`;

export default Switch;
