// Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Components
import {
	Collapse as _Collapse,
	Wrapper,
	Span,
	TableVirtualized,
	Spin,
	Checkbox,
	Flag,
	Tooltip,
} from 'components';
// Utils
import { getMyEntityActiveUsers, getSpecificUsers } from 'utils/libs';
import { filterControlSeries } from 'utils/filters/Warehouses/controlSeries';
import orderBy from 'utils/orderBy';
import { filterControlSeriesSearchInData } from '../../utils';

const { Collapse, Panel } = _Collapse;
const {
	Table,
	Column,
	Header,
	Row,
	Cell,
	onClickRow,
	onDoubleClickRow,
	onRightClickRow,
} = TableVirtualized;

// JSX COMPONENTS
const TableRendered = ({
	controlSeries,
	series,
	mutate1Object,
	getControlSeries,
	profile,
	filteredSeries,
	mutate1ObjectApi,
	getSerieHistory,
}) => {
	const theme = useTheme();

	const onFilterItems = filteredItems =>
		mutate1Object('controlSeries', {
			selected: { ...controlSeries.selected, items: filteredItems },
		});

	const onClickedRow = filteredItems =>
		mutate1Object('controlSeries', {
			selected: { ...controlSeries.selected, items: filteredItems },
		});

	return (
		<Table
			highBackground
			width={document.documentElement.clientWidth}
			height={document.documentElement.clientHeight - 115}
			headerHeight={44}
			rowHeight={25}
			rowCount={filteredSeries.length}
			rowGetter={({ index }) => filteredSeries[index]}
			rowRenderer={({ key, columns, style, rowData }) => (
				<Row
					key={key}
					style={style}
					onContextMenu={e => {
						if (controlSeries.view === 'resume') e.preventDefault();
						else
							onRightClickRow(
								e,
								'serie',
								controlSeries.selected.items,
								filteredSeries,
								onFilterItems,
							);
					}}
				>
					{columns}
				</Row>
			)}
			onRowsRendered={({ overscanStopIndex, stopIndex }) => {
				if (controlSeries.view !== 'resume') {
					if (
						overscanStopIndex + 1 === series.length &&
						series.length < series[0].total_rows
					) {
						getControlSeries(
							{
								...controlSeries.request,
								last_row_id: series[series.length - 1].id,
							},
							true,
						);
					}
				}
			}}
		>
			<Column
				dataKey='index'
				label='#'
				width={150}
				headerRenderer={({ label }) => (
					<Header justifyContent='space-around'>{label}</Header>
				)}
				cellRenderer={({ rowIndex, rowData }) => (
					<Cell
						onClick={() => {
							if (controlSeries.view !== 'resume')
								onClickRow(rowData.serie, controlSeries.selected, onClickedRow);
						}}
						onDoubleClick={() => {
							if (controlSeries.view !== 'resume')
								onDoubleClickRow(
									'serie',
									controlSeries.selected,
									filteredSeries,
									mutate1Object,
									'controlSeries',
								);
						}}
					>
						{rowData.fifoOk && (
							<Tooltip title='Esta serie debe ser utilizada'>
								<Flag backgroundColor='white' height='20px' />
							</Tooltip>
						)}
						{rowData.state_props && rowData.state_props.style && (
							<Flag height='20px' style={rowData.state_props.style} />
						)}
						{controlSeries.view !== 'resume' && (
							<Checkbox
								margin='2px 0 2px 5px'
								checked={
									controlSeries.selected.items.indexOf(rowData.serie) !== -1
								}
								onChange={() =>
									onClickRow(
										rowData.serie,
										controlSeries.selected,
										onClickedRow,
									)
								}
							/>
						)}
						<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
					</Cell>
				)}
			/>
			<Column
				dataKey='item_code'
				label='Código'
				width={100}
				headerRenderer={({ dataKey, label }) =>
					controlSeries.view === 'resume' ? (
						<Header>{label}</Header>
					) : (
						<Header
							filter={{
								dataKey,
								data: series,
								orderType: 'text',
								dateFormat: profile.user.settings.date_format,
								filters: controlSeries.filters,
								onChangedFilter: updatedFilters =>
									mutate1Object('controlSeries', {
										filters: updatedFilters,
									}),
							}}
						>
							{label}
						</Header>
					)
				}
			/>
			<Column
				dataKey='item_name'
				label='Nombre'
				width={300}
				headerRenderer={({ dataKey, label }) =>
					controlSeries.view === 'resume' ? (
						<Header>{label}</Header>
					) : (
						<Header
							filter={{
								dataKey,
								data: series,
								orderType: 'text',
								dateFormat: profile.user.settings.date_format,
								filters: controlSeries.filters,
								onChangedFilter: updatedFilters =>
									mutate1Object('controlSeries', {
										filters: updatedFilters,
									}),
							}}
						>
							{label}
						</Header>
					)
				}
			/>
			<Column
				dataKey='serie'
				label='Serie'
				width={200}
				headerRenderer={({ dataKey, label }) =>
					controlSeries.view === 'resume' ? (
						<Header>{label}</Header>
					) : (
						<Header
							filter={{
								dataKey,
								data: series,
								orderType: 'text',
								dateFormat: profile.user.settings.date_format,
								filters: controlSeries.filters,
								onChangedFilter: updatedFilters =>
									mutate1Object('controlSeries', {
										filters: updatedFilters,
									}),
							}}
						>
							{label}
						</Header>
					)
				}
				cellRenderer={({ rowData }) => (
					<Cell
						color={theme.colors.text.high}
						onClick={() => {
							mutate1ObjectApi('serieHistory', { isOpen: true });
							getSerieHistory(rowData.serie);
						}}
					>
						{rowData.serie}
					</Cell>
				)}
			/>
			<Column
				dataKey='state_name'
				label='Estado'
				width={200}
				headerRenderer={({ dataKey, label }) =>
					controlSeries.view === 'resume' ? (
						<Header>{label}</Header>
					) : (
						<Header
							filter={{
								dataKey,
								data: series,
								orderType: 'text',
								dateFormat: profile.user.settings.date_format,
								filters: controlSeries.filters,
								onChangedFilter: updatedFilters =>
									mutate1Object('controlSeries', {
										filters: updatedFilters,
									}),
							}}
						>
							{label}
						</Header>
					)
				}
			/>
			<Column
				dataKey='doc_number'
				label='Documento'
				width={140}
				headerRenderer={({ dataKey, label }) =>
					controlSeries.view === 'resume' ? (
						<Header>{label}</Header>
					) : (
						<Header
							filter={{
								dataKey,
								data: series,
								orderType: 'text',
								dateFormat: profile.user.settings.date_format,
								filters: controlSeries.filters,
								onChangedFilter: updatedFilters =>
									mutate1Object('controlSeries', {
										filters: updatedFilters,
									}),
							}}
						>
							{label}
						</Header>
					)
				}
			/>
			{(controlSeries.view === 'resume' ||
				controlSeries.request.view === 'entities') && (
				<Column
					dataKey='warehouse_name'
					label='Almacén'
					width={150}
					headerRenderer={({ dataKey, label }) =>
						controlSeries.view === 'resume' ? (
							<Header>{label}</Header>
						) : (
							<Header
								filter={{
									dataKey,
									data: series,
									orderType: 'text',
									dateFormat: profile.user.settings.date_format,
									filters: controlSeries.filters,
									onChangedFilter: updatedFilters =>
										mutate1Object('controlSeries', {
											filters: updatedFilters,
										}),
								}}
							>
								{label}
							</Header>
						)
					}
				/>
			)}
			{(controlSeries.view === 'resume' ||
				controlSeries.request.view === 'entities') && (
				<Column
					dataKey='owner_name'
					label='Propietario'
					width={150}
					headerRenderer={({ dataKey, label }) =>
						controlSeries.view === 'resume' ? (
							<Header>{label}</Header>
						) : (
							<Header
								filter={{
									dataKey,
									data: series,
									orderType: 'text',
									dateFormat: profile.user.settings.date_format,
									filters: controlSeries.filters,
									onChangedFilter: updatedFilters =>
										mutate1Object('controlSeries', {
											filters: updatedFilters,
										}),
								}}
							>
								{label}
							</Header>
						)
					}
				/>
			)}
			{controlSeries.view === 'resume' && (
				<Column
					dataKey='entity_name'
					label='Entidad'
					width={150}
					headerRenderer={({ dataKey, label }) =>
						controlSeries.view === 'resume' ? (
							<Header>{label}</Header>
						) : (
							<Header
								filter={{
									dataKey,
									data: series,
									orderType: 'text',
									dateFormat: profile.user.settings.date_format,
									filters: controlSeries.filters,
									onChangedFilter: updatedFilters =>
										mutate1Object('controlSeries', {
											filters: updatedFilters,
										}),
								}}
							>
								{label}
							</Header>
						)
					}
				/>
			)}
			<Column
				dataKey='created_at'
				label='Creada'
				width={200}
				headerRenderer={({ dataKey, label }) =>
					controlSeries.view === 'resume' ? (
						<Header>{label}</Header>
					) : (
						<Header
							filter={{
								dataKey,
								data: series,
								orderType: 'date',
								dateFormat: profile.user.settings.date_format,
								filters: controlSeries.filters,
								onChangedFilter: updatedFilters =>
									mutate1Object('controlSeries', {
										filters: updatedFilters,
									}),
							}}
						>
							{label}
						</Header>
					)
				}
			/>
			<Column
				dataKey='updated_at'
				label='Actualizada'
				width={200}
				headerRenderer={({ dataKey, label }) =>
					controlSeries.view === 'resume' ? (
						<Header>{label}</Header>
					) : (
						<Header
							filter={{
								dataKey,
								data: series,
								orderType: 'date',
								dateFormat: profile.user.settings.date_format,
								filters: controlSeries.filters,
								onChangedFilter: updatedFilters =>
									mutate1Object('controlSeries', {
										filters: updatedFilters,
									}),
							}}
						>
							{label}
						</Header>
					)
				}
			/>
		</Table>
	);
};

export default function ControlSeriesDesktop(props) {
	const {
		controlSeries,
		stock,
		mutate1Object,
		mutate1ObjectApi,
		getControlSeries,
		profile,
	} = props;

	// User/Warehouses
	const users = getMyEntityActiveUsers(props.users.data, profile);
	const experts = getSpecificUsers(users, { experts: true });
	const entities = getSpecificUsers(users, { entities: true });

	let userWarehouses = [];
	if (controlSeries.request.view === 'individual') {
		userWarehouses = [...experts];
	} else if (controlSeries.request.view === 'entities') {
		userWarehouses = [...entities];
	}

	// User Warehouses Filtering
	userWarehouses = filterControlSeriesSearchInData(
		userWarehouses,
		controlSeries.searchInData.data,
	);
	// Series Filtering
	const series = stock.series.data;

	const filteredSeries = filterControlSeries(
		series,
		controlSeries.view,
		controlSeries.filters,
		controlSeries.selected,
	);

	orderBy(filteredSeries, 'object', { objectKey: 'state_name' });

	// EVENTS
	function onChangeCollapse(user, collapseKey) {
		const collapseActiveKey =
			controlSeries.collapseActiveKey !== collapseKey ? collapseKey : -1;

		mutate1Object('controlSeries', {
			collapseActiveKey,
			filters: {},
			selected: {
				items: [],
			},
			request: {
				...controlSeries.request,
				owner_id: user.id,
				entity_id:
					controlSeries.request.view === 'individual'
						? user.entity_id
						: user.id,
			},
		});
		if (collapseActiveKey !== -1) {
			getControlSeries({
				...controlSeries.request,
				owner_id: user.id,
				entity_id:
					controlSeries.request.view === 'individual'
						? user.entity_id
						: user.id,
			});
		} else {
			mutate1ObjectApi('stock', {
				series: {
					...stock.series,
					data: [],
				},
			});
		}
	}

	if (controlSeries.view === 'resume') {
		return (
			<Wrapper padding='0px'>
				<TableRendered
					{...props}
					series={series}
					filteredSeries={filteredSeries}
				/>
			</Wrapper>
		);
	} else if (controlSeries.view === 'data') {
		const firstSerie = series[0];

		return (
			<Wrapper padding='0px' flexDirection='column'>
				{userWarehouses.map(
					(user, collapseKey) =>
						(controlSeries.collapseActiveKey === -1 ||
							(controlSeries.collapseActiveKey !== -1 &&
								collapseKey === controlSeries.collapseActiveKey)) && (
							<Collapse
								key={collapseKey}
								accordion
								bordered={false}
								defaultActiveKey={['-1']}
								activeKey={[controlSeries.collapseActiveKey]}
								onChange={() => onChangeCollapse(user, collapseKey)}
							>
								<Panel
									key={collapseKey}
									header={
										<Wrapper padding='0' alignItems='baseline'>
											<Span
												margin='0 10px 0 0'
												fontSize='l'
												color='rgba(255, 255, 255, 0.9)'
												fontWeight='bold'
											>
												{user.name}
											</Span>
											{controlSeries.collapseActiveKey === collapseKey && (
												<Span fontSize='m' color='rgba(255, 255, 255, 0.6)'>
													{filteredSeries.length < series.length
														? `Filtrando ${filteredSeries.length} series`
														: `${series.length}/
                        ${
													firstSerie && firstSerie.total_rows
														? firstSerie.total_rows
														: series.length
												}`}
												</Span>
											)}
										</Wrapper>
									}
								>
									<Spin
										spinning={stock.series.loading}
										size='large'
										delay={50}
										tip={'Obteniendo datos...'}
									>
										<TableRendered
											{...props}
											series={series}
											filteredSeries={filteredSeries}
										/>
									</Spin>
								</Panel>
							</Collapse>
						),
				)}
			</Wrapper>
		);
	}
}
