//Libs
import React from 'react';
import { connect } from 'react-redux';
//Hooks
import {
	useNetwork,
	// useWindowActive
} from 'hooks';
//Actions
import { sendToast } from 'screens/Dashboard/actions';
import { autoSyncConsumedItems, setAutoSync } from './actions';
//Selectors
import {
	selectConsumeInventoryAutoSync,
	selectManageInventoryControl,
} from './selectors';
import { selectCompleteOrderId } from 'screens/Orders/selectors';
//Utils
import ConsumeInventoryUtils from './ConsumeInventoryUtils';
//Components
import { Progress } from 'components';

const AutoSyncConsumedItems = ({
	//Props
	silent,
	//Redux
	orderId,
	consumeInventoryAutoSync,
	control,
	setAutoSync,
	autoSyncConsumedItems,
}) => {
	//Use Network to know isOnline
	const isOnline = useNetwork();
	//Validating isOnline
	React.useEffect(() => {
		if (!isOnline && consumeInventoryAutoSync.active) {
			setTimeout(() => setAutoSync({ active: false }), 500);
		} else if (isOnline && !consumeInventoryAutoSync.active) {
			setTimeout(() => setAutoSync({ active: true }), 500);
		}
	}, [isOnline]);

	//Use Window active
	// const isActive = useWindowActive();
	// React.useEffect(() => {
	//   if (isActive && !consumeInventoryAutoSync.active) {
	//     setTimeout(() => setAutoSync({ active: true }), 500);
	//   }
	// }, [isActive]);

	//Start consumeInventoryAutoSync if Active
	React.useEffect(() => {
		if (consumeInventoryAutoSync.active) {
			ConsumeInventoryUtils.reloadOfflineConsumedItems().then(
				offlineConsumedItems => {
					const unsuccessConsumedItems =
						ConsumeInventoryUtils.getUnsuccessConsumedItems({
							consumedItems: offlineConsumedItems,
							max: 10,
						});
					if (unsuccessConsumedItems.length === 0) {
						setTimeout(() => setAutoSync({ active: false }), 500);
					} else {
						setTimeout(
							() => autoSyncConsumedItems(unsuccessConsumedItems),
							500,
						);
					}
				},
			);
		}
	}, [consumeInventoryAutoSync.active]);

	//Delete success consumed items
	React.useEffect(() => {
		if (!orderId) {
			ConsumeInventoryUtils.reloadOfflineConsumedItems().then(
				offlineConsumedItems => {
					const nonRemovableCollectors =
						ConsumeInventoryUtils.getNonRemovableConsumedItems(
							offlineConsumedItems,
						);
					ConsumeInventoryUtils.mutateOfflineConsumedItems(
						nonRemovableCollectors,
					);
				},
			);
		}
	}, [orderId]);

	if (silent) return null;

	return (
		ConsumeInventoryUtils.checkAutoSyncActive(
			consumeInventoryAutoSync.active,
			control.sending,
		) && (
			<Progress
				style={{ top: '-9px', opacity: 0.3 }}
				percent={100}
				status='active'
				showInfo={false}
				size='small'
			/>
		)
	);
};

const mapStateToProps = state => ({
	orderId: selectCompleteOrderId(state),
	control: selectManageInventoryControl(state),
	consumeInventoryAutoSync: selectConsumeInventoryAutoSync(state),
});

const actions = {
	setAutoSync,
	autoSyncConsumedItems,
	sendToast,
};

export default connect(mapStateToProps, actions)(AutoSyncConsumedItems);
