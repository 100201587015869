// Libs
import React from 'react';
// Components
import { Wrapper } from 'components';

const Section = ({ children, margin }) => (
	<Wrapper
		padding='0'
		margin={margin ?? '0 0 32px 0'}
		flexDirection='column'
		alignItems='stretch'
	>
		{children}
	</Wrapper>
);

export default Section;
