// Libs
import React from 'react';
// Styles
import styles from './Catalog.module.css';
// Components
import {
	Divider,
	Wrapper,
	Subtitle,
	Title,
	Caption,
	InputNumber,
} from 'components';
import { formatDate } from 'utils/libs/dateFormats';

const Items = ({ Items }) => <Title className={styles.Items}>{Items}</Title>;

const ListV1 = ({
	record,
	readOnly = true,
	isNearScreen,
	isMobile,
	screenKey,
}) => {
	if (!isNearScreen) return null; // Lazy loading
	return (
		<div className={styles.ctgWrapper}>
			<div className={styles.headerContent}>
				<Title ellipsis width='90%'>
					{record.name}
				</Title>
				<Caption>ID:{record.item}</Caption>
			</div>
			<div className={styles.dividerContainer}>
				<Divider />
			</div>
			<Wrapper
				padding='0'
				margin={`${readOnly ? '20px' : '12px'} 0 0 0`}
				justifyContent='space-around'
			>
				<div className={readOnly ? styles.item : styles.itemRow}>
					<Subtitle disabled>Disponible</Subtitle>
					<Items Items={record.amount} />
				</div>
				<div className={readOnly ? styles.item : styles.itemRow}>
					<Subtitle disabled>Transito</Subtitle>
					<Items Items={record.amountTransit} />
				</div>

				{readOnly ? (
					<div className={styles.item}>
						<Subtitle disabled>
							{formatDate(record.accreditedUpdatedAt, null, 'YYYY')}
						</Subtitle>
						<Items
							Items={formatDate(record.accreditedUpdatedAt, null, 'DD MMM')}
						/>
					</div>
				) : (
					''
				)}
			</Wrapper>
			{readOnly ? '' : <InputNumber width='85%' placeholder='Cantidad' />}
		</div>
	);
};

export default ListV1;
