// Libs
import { Spin } from 'antd';
import styled from 'styled-components';

const SpinStyled = styled(Spin)`
	height: ${props => (props.height ? props.height : props.theme.spin.height)};
	color: ${props => (props.color ? props.color : props.theme.spin.color)};
	font-family: ${props =>
		props.fontFamily ? props.fontFamily : props.theme.fonts.family};
	border-radius: ${props =>
		props.borderRadius ? props.borderRadius : props.theme.spin.borderRadius};
`;

export default SpinStyled;
