// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M9.45464 6.5454V2.66663H6.54555L6.54555 6.5454H2.66675V9.45449H6.54555L6.54555 13.3333H9.45464V9.45449H13.3334V6.5454H9.45464Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
