// Libs
import React, { useState, useCallback, useContext } from 'react';
// Utils
import GENERAL from 'utils/constants/general';
import { debounce, filterOption } from 'utils/libs';
import ModalBuilderConfig from 'core/ModalBuilder/modal.config';
import { getValueFromRowData } from 'components/DataFieldManager/OnlyReadField/OnlyReadField';
// Context
import { ModalBuilderContext } from 'core/context';
// Hooks
import { useOrderEventAssignment } from 'hooks';
import useTheme from 'hooks/useTheme';
// Components
import { Wrapper, LogoIcon, Select } from 'components';
// Styles
import styles from './EventId.module.css';
import Updating from '../Updating';
import Status from '../Status';

const { UNSUCCESS } = GENERAL.ENV.STATUS;
const componentStyle = {
	select: {
		dark: styles.containerSelectDark,
		light: styles.containerSelectLight,
	},
	dropdown: {
		dark: styles.containerDropdownDark,
		light: styles.containerDropdownLight,
	},
};

const getEventsWithCurrentEvent = (currentEvent, events) => {
	const hasCurrentEvent = !!events.find(e => e.id === currentEvent.id);
	if (!hasCurrentEvent) events.unshift(currentEvent);
	return events;
};
const Selection = ({
	field,
	rowData,
	isLoading,
	events,
	onGetEventAssignment,
	style,
}) => {
	const { themeProfile } = useTheme();
	const { setModal } = useContext(ModalBuilderContext);
	const [isOpen, setIsOpen] = useState(false);

	const fetchDebouncedEventAssignment = useCallback(
		debounce(onGetEventAssignment, 200),
		[],
	);

	const onChange =
		field.onChange ||
		function (props) {
			setModal(
				ModalBuilderConfig.getAuthorizedModalKeys().updateOrderEvent,
				props,
			);
		};

	const currentEvent = {
		id: rowData[field.id],
		name: getValueFromRowData({ field, rowData }),
	};
	const _events = !isOpen
		? events
		: getEventsWithCurrentEvent(currentEvent, events);

	return (
		<div className={componentStyle[style][themeProfile]}>
			<Select
				width='100%'
				showSearch
				filterOption={filterOption}
				value={!isOpen || isLoading ? currentEvent.name : currentEvent.id}
				onSearch={searchValue => fetchDebouncedEventAssignment({ searchValue })}
				onChange={(_value, { props }) =>
					_value !== currentEvent.id &&
					onChange({
						field,
						rowData,
						option: {
							id: _value,
							name: props.children,
							props: props.eventProps,
						},
					})
				}
				onDropdownVisibleChange={visible => {
					if (visible) onGetEventAssignment();
					setIsOpen(visible);
				}}
				dropdownRender={menu => (
					<>
						{menu}
						{isLoading && (
							<Wrapper width='100%' justifyContent='center'>
								<LogoIcon spin={true} />
							</Wrapper>
						)}
					</>
				)}
			>
				{_events.map(event => (
					<Select.Option
						key={event.id}
						value={event.id}
						eventProps={event.props}
					>
						{event.name}
					</Select.Option>
				))}
			</Select>
		</div>
	);
};

const EventId = ({ rowData, field, style = 'select' }) => {
	const { isDarkTheme } = useTheme();
	const { state, onGetEventAssignment, onRetry } = useOrderEventAssignment();

	if (state.status === UNSUCCESS)
		return (
			<Status
				className={isDarkTheme ? styles.containerDark : styles.containerLight}
				message={state.message}
				onRetry={onRetry}
			/>
		);

	if (state.isSetting)
		return (
			<Updating
				className={isDarkTheme ? styles.containerDark : styles.containerLight}
			/>
		);

	return (
		<Selection
			field={field}
			rowData={rowData}
			isLoading={state.isLoading}
			events={state.data}
			onGetEventAssignment={onGetEventAssignment}
			style={style}
		/>
	);
};

export default EventId;
