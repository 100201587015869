// Dependencies
import { takeLatest, put, call, all } from 'redux-saga/effects';
import axios from 'axios';
// Utils
import asyncErrorsHandler from '../../store/asyncErrorsHandler';
import { lsHandler } from '../../utils/libs';
import AuthService from '../../utils/libs/auth/AuthService';
import GENERAL from '../../utils/constants/general';
import API from '../../utils/constants/api';
// Services
import { SentryService } from 'services';
// Actions
import { doReset } from 'screens/Login/actions';
import {
	mutate1Object as mutate1ObjectInDashboard,
	sendToast,
} from 'screens/Dashboard/actions';
// Service worker
import registerPushNotificationServiceWorker from 'registerPushNotificationSW';

const { LOGIN, DIVISION_ID, MODULES } = GENERAL;
const auth = new AuthService();

//* ******* WATCHER SAGAS ***********/
function* loginWatcher() {
	yield takeLatest(LOGIN.LOGIN, loginWorker);
}
function* doLogoutWatcher() {
	yield takeLatest(LOGIN.DO_LOGOUT, doLogoutWorker);
}

function* getMyProfileWatcher() {
	yield takeLatest(LOGIN.GET_MY_PROFILE, getMyProfileWorker);
}

//* ******* WORKERS SAGAS ***********/
function* loginWorker(action) {
	const { idc, password } = action.payload;

	try {
		const _idc = idc.trim();
		const _password = password.trim();
		if (!_idc || !_password) return;

		yield put({ type: LOGIN.MUTATE_DIRECT_PROPS, payload: { loading: true } });
		// yield call(auth.login, credentials);

		const response = yield call(
			axios.post,
			API.DOMAIN.concat('/users/login'),
			{ idc: _idc, password: _password, division_id: DIVISION_ID.ADM },
			auth.sendToken(),
		);
		yield auth.setToken(response.data);
		const logged = yield auth.loggedIn();

		if (logged) {
			yield getMyProfileWorker();
			yield put({
				type: MODULES.GET_MY_ACTIVE_MODULES,
			});
		}

		yield put({ type: LOGIN.MUTATE_DIRECT_PROPS, payload: { loading: false } });
		if (logged) {
			// Register Push Notification Service Worker
			yield registerPushNotificationServiceWorker().catch(error =>
				SentryService.sendError(error),
			);
		}
		yield put({ type: LOGIN.LOGGED, payload: { logged } });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: LOGIN.MUTATE_DIRECT_PROPS,
					payload: { loading: false },
				});
				yield put({
					type: LOGIN.LOGGED,
					payload: { logged: false, message: err.response.data.message },
				});
			},
			function* () {
				yield loginWorker(action);
			},
		);
	}
}

function* doLogoutWorker() {
	try {
		lsHandler.del('synapse_token');
		lsHandler.del('synapse_profile');
		yield put(doReset());
		window.location.reload();
	} catch (err) {
		yield put(sendToast({ description: err.message, type: 'error' }));
	}
}

function* getMyProfileWorker() {
	try {
		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat('/users/profile'),
			auth.sendToken(),
		);
		yield put({ type: LOGIN.SET_MY_PROFILE, payload: data });
		yield put(
			mutate1ObjectInDashboard('editProfileDrawer', {
				loading: false,
			}),
		);
		lsHandler.save('synapse_profile', data);
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getMyProfileWorker();
		});
	}
}

//  Export default Root Saga
export default function* rootSaga() {
	yield all([loginWatcher(), doLogoutWatcher(), getMyProfileWatcher()]);
}
