// Libs
import React from 'react';
import { useDispatch } from 'react-redux';
// Actions
import { mutate1Object as _mutate1ObjectInCore } from 'core/actions';
// Utils
import FEED_KEYWORDS from 'core/keywords';
// Config
import ScreenBuilderConfig from './screen.config';
// Illustrations
import {
	BuildingBlockIllustration,
	EmptyIllustration,
	PreferencesPopupIllustration,
} from 'components/Illustrations';
import { debounce } from 'utils/libs';

const ScreenBuilder = ({
	elementKey,
	screenKey,
	isMobile,
	selectedSubModuleId,
	isFetchingDisplay,
	dataFilterLastRowId,
	datasourceStatus,
	datasourcePaging,
	datasourceFields,
	datasource,
	profile,
}) => {
	const [building, setBuilding] = React.useState(true);
	const [builded, setBuilded] = React.useState(false);

	const dispatch = useDispatch();

	const mutate1ObjectInCore = (obj1Name, keyValuePairs) =>
		dispatch(_mutate1ObjectInCore(obj1Name, keyValuePairs));

	// Get Screen React Node
	const Screen = ScreenBuilderConfig.getAuthorizedScreenReactNode(screenKey);
	const isLoading =
		isFetchingDisplay || datasourceStatus === FEED_KEYWORDS.STATUS.LOADING;
	const firstDataRequest = dataFilterLastRowId === 0;

	const debounced = React.useCallback(
		debounce(callback => callback(), 700),
		[],
	);

	// Handle animation interactions
	React.useEffect(() => {
		if (!selectedSubModuleId || (isLoading && firstDataRequest)) {
			setBuilding(true);
			setBuilded(false);
		}
	}, [selectedSubModuleId, isLoading, firstDataRequest]);

	React.useEffect(() => {
		let isMounted = true;
		let t1, t2;
		t1 = setTimeout(
			() =>
				debounced(() => {
					if (Screen && !isLoading) {
						if (isMounted) setBuilding(false);
						t2 = setTimeout(() => isMounted && setBuilded(true), 300);
					}
				}),
			300,
		);
		return () => {
			isMounted = false;
			clearTimeout(t1);
			clearTimeout(t2);
		};
	}, [isLoading]);

	// Building Screen & getting data
	if (!Screen)
		return (
			<PreferencesPopupIllustration
				className={`animated ${building ? 'zoomIn' : 'zoomOut'}`}
				title='Esta consulta aún no tiene una pantalla configurada.'
				width={isMobile ? '200px' : '400px'}
				fontSize='2em'
			/>
		);
	if (!builded)
		return (
			<BuildingBlockIllustration
				className={`animated ${building ? 'zoomIn' : 'zoomOut'}`}
				title='Construyendo el Feed... Un momento'
				width={isMobile ? '200px' : '400px'}
				fontSize='2em'
				loading={true}
			/>
		);

	// No data getted
	const emptyDatasource = datasource.length === 0;
	if (!isLoading && emptyDatasource)
		return (
			<EmptyIllustration
				title='No se encontraron registros con el filtro actual'
				width={!isMobile ? '400px' : '200px'}
				fontSize='2em'
			/>
		);

	return (
		<Screen
			elementKey={elementKey}
			screenKey={screenKey}
			isMobile={isMobile}
			isLoading={isLoading}
			dataFilterLastRowId={dataFilterLastRowId}
			datasourcePaging={datasourcePaging}
			datasourceFields={datasourceFields}
			datasource={datasource}
			profile={profile}
			setLastRowId={lastRowId =>
				mutate1ObjectInCore('dataFilters', { lastRowId })
			}
			reloadDatasource={() =>
				mutate1ObjectInCore('datasource', {
					reload: true,
				})
			}
		/>
	);
};

export default ScreenBuilder;
