//  Libs
import React from 'react';
import { Wrapper, TextField, Button, Spin } from 'components';
import Select from 'components/Select';
//  Utils
import { filterOption } from 'utils/libs';
//  Styles
import '../../css/CreateWarehouse.css';

const { Option } = Select;
const { TextArea, Input } = TextField;

const CreateWarehouse = props => {
	const {
		createWarehouse,
		entities,
		mutate1Object,
		handleOnClickCreateWarehouse,
		profile,
	} = props;

	return (
		<Spin spinning={createWarehouse.loading} size='large' delay={50}>
			<Wrapper justifyContent='center'>
				<Wrapper
					width='250px'
					padding='0'
					flexDirection='column'
					alignItems='stretch'
				>
					{/* NAME */}
					<Input
						id='name'
						placeholder='Nombre*'
						value={createWarehouse.name}
						onChange={e =>
							mutate1Object('createWarehouse', {
								[e.target.id]: e.target.value,
							})
						}
					/>

					{/* OWNER */}
					<Select
						margin='10px 0 0 0'
						showSearch
						placeholder='Asignar a*'
						value={createWarehouse.owner_id}
						onChange={value =>
							mutate1Object('createWarehouse', { owner_id: value })
						}
						filterOption={filterOption}
					>
						{entities.map((user, idx) => (
							<Option key={idx} value={user.id}>
								<em>{user.name}</em>
							</Option>
						))}
					</Select>

					{/* TYPE */}
					<Select
						margin='10px 0 0 0'
						showSearch
						placeholder='Tipo*'
						value={createWarehouse.details.type}
						onChange={value =>
							mutate1Object('createWarehouse', {
								details: { ...createWarehouse.details, type: value },
							})
						}
						filterOption={filterOption}
					>
						<Option value='Fisico'>
							<em>Fisico</em>
						</Option>
						<Option value='Virtual'>
							<em>Virtual</em>
						</Option>
					</Select>

					{/* COUNTRY */}
					{createWarehouse.details.type === 'Fisico' && (
						<Select
							margin='10px 0 0 0'
							showSearch
							placeholder='País*'
							value={createWarehouse.details.country}
							onChange={value =>
								mutate1Object('createWarehouse', {
									details: { ...createWarehouse.details, country: value },
								})
							}
							filterOption={filterOption}
						>
							<Option value={profile.organization.country}>
								<em>{profile.organization.country}</em>
							</Option>
						</Select>
					)}
					{/* ADDRESS */}
					{createWarehouse.details.type === 'Fisico' && (
						<TextArea
							margin='5px 0 0 0'
							allowClear
							placeholder='Dirección*'
							rows={3}
							value={createWarehouse.details.address}
							onChange={e =>
								mutate1Object('createWarehouse', {
									details: {
										...createWarehouse.details,
										address: e.target.value,
									},
								})
							}
						/>
					)}

					{/* DESCRIPTION */}
					<TextArea
						margin='5px 0 10px 0'
						allowClear
						placeholder='Descripción'
						rows={3}
						value={createWarehouse.details.description}
						onChange={e =>
							mutate1Object('createWarehouse', {
								details: {
									...createWarehouse.details,
									description: e.target.value,
								},
							})
						}
					/>

					{/* CREATE BUTTON */}
					<Button
						className='button-style-1'
						onClick={() =>
							handleOnClickCreateWarehouse(
								createWarehouse,
								createWarehouse.loading,
							)
						}
					>
						Crear
					</Button>
				</Wrapper>
			</Wrapper>
		</Spin>
	);
};
export default CreateWarehouse;
