export default class OrderModel {
	constructor(data) {
		this.order_id = data.orderId;
		this.service_id = data.serviceId;
		this.department_id = data.departmentId;
		this.odt_id = data.odtId;
		this.departmentProps = data.departmentProps;
		this.service_props = data.serviceProps;
		this.props = data.orderProps;
	}
}
