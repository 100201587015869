// Libs
import styled from 'styled-components';
// Components
import { DatePicker as _DatePicker } from 'antd';

const DatePicker = styled(_DatePicker)`
	margin: ${props => props.margin ?? props.theme.datepicker.margin};
	&.ant-input {
		color: ${props => props.color ?? props.theme.fonts.colors.default};
		background-color: ${props =>
			props.backgroundColor ?? props.theme.datepicker.backgroundColor};
	}
	& {
		background: transparent;
	}
`;

export default DatePicker;
