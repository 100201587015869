// Libs
import {
	takeLatest,
	takeEvery,
	put,
	call,
	all,
	select,
} from 'redux-saga/effects';
import axios from 'axios';
// Utils
import asyncErrorsHandler from 'store/asyncErrorsHandler';
import AuthService from 'utils/libs/auth/AuthService';
import GENERAL from 'utils/constants/general';
import API from 'utils/constants/api';
// Actions
import {
	mutate1Object as mutate1ObjectInDashboard,
	sendToast,
} from './actions';
import { getMyProfile } from 'screens/Login/actions';
// Components
import { notification } from 'antd';

const { DASHBOARD } = GENERAL;
const auth = new AuthService();

const getInitialToastMessage = state => state.dashboard.toastMessage;

//* ******* WATCHER SAGAS ***********/
function* reportMeWatcher() {
	yield takeLatest(DASHBOARD.REPORT_ME, reportMe);
}

function* updateUserProfileWatcher() {
	yield takeLatest(DASHBOARD.UPDATE_USER_PROFILE, updateUserProfileWorker);
}

function* changeUsersAssignedEntityWatcher() {
	yield takeLatest(
		DASHBOARD.CHANGE_USERS_ASSIGNED_ENTITY,
		changeUsersAssignedEntityWorker,
	);
}

function* getNotificationsWatcher() {
	yield takeLatest(DASHBOARD.GET_NOTIFICATIONS, getNotificationsWorker);
}

function* markNotificationWatcher() {
	yield takeEvery(DASHBOARD.MARK_NOTIFICATION, markNotificationWorker);
}

function* tostMessageWatcher() {
	yield takeLatest(DASHBOARD.TOAST_MESSAGE, toastMessageWorker);
}
//* ******* WORKERS SAGAS ***********/
function* reportMe(action) {
	let { id, name } = action.payload;
	name = name.split(' ', 1);

	try {
		yield call(
			axios.put,
			API.DOMAIN.concat('/users/report_me'),
			id,
			auth.sendToken(),
		);
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield reportMe(action);
		});
	}
}

function* updateUserProfileWorker(action) {
	const { payload } = action;

	try {
		yield put(
			mutate1ObjectInDashboard('editProfileDrawer', {
				isUpdating: true,
			}),
		);

		yield call(
			axios.put,
			API.DOMAIN.concat('/users/profile'),
			payload,
			auth.sendToken(),
		);

		yield put(getMyProfile());

		yield put(
			mutate1ObjectInDashboard('editProfileDrawer', {
				isUpdating: false,
			}),
		);

		yield put(
			sendToast({
				message: `Perfil actualizado!`,
				duration: 1.5,
				type: 'success',
			}),
		);
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put(
					mutate1ObjectInDashboard('editProfileDrawer', {
						isUpdating: false,
					}),
				);
			},
			function* () {
				yield updateUserProfileWorker(action);
			},
		);
	}
}

function* changeUsersAssignedEntityWorker(action) {
	const { payload } = action;

	try {
		yield call(
			axios.put,
			API.DOMAIN.concat('/users/assignedEntity'),
			payload,
			auth.sendToken(),
		);

		yield put(getMyProfile());

		yield put(
			sendToast({
				message: `Entidad actualizada!`,
				duration: 1.5,
				type: 'success',
			}),
		);
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield changeUsersAssignedEntityWorker(action);
		});
	}
}

function* getNotificationsWorker(action) {
	const { lastRowId = 0, limit = 10 } = action.payload;
	try {
		yield put({
			type: DASHBOARD.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'notificationControl',
				keyValuePairs: { isFetching: true },
			},
		});
		const { data: notifications } = yield call(
			axios.get,
			API.DOMAIN.concat(`/user_notifications/my/${lastRowId}/${limit}`),
			auth.sendToken(),
		);
		yield put({
			type: DASHBOARD.GET_NOTIFICATIONS_SUCCESS,
			payload: notifications,
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getNotificationsWorker(action);
		});
	}
}

function* markNotificationWorker(action) {
	const { userNotificationId, seen, read } = action.payload;
	try {
		yield call(
			axios.put,
			API.DOMAIN.concat(`/user_notifications/mark/${userNotificationId}`),
			{ seen, read },
			auth.sendToken(),
		);
		yield put({
			type: DASHBOARD.MARK_NOTIFICATION_SUCCESS,
			payload: { userNotificationId, seen, read },
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield markNotificationWorker(action);
		});
	}
}

function* toastMessageWorker(action) {
	const _toastMessage = yield select(getInitialToastMessage);
	const { message, description, duration, type, onClick } = action.payload;
	const toastMessage = {
		..._toastMessage,
		message: message || _toastMessage.message,
		description: description || _toastMessage.description,
		duration: duration || _toastMessage.duration,
		type: type || _toastMessage.type,
		onClick,
	};

	switch (type) {
		case 'error':
			notification.error({ ...toastMessage });
			// playSound('error');
			break;
		case 'warn':
			notification.warn({ ...toastMessage });
			// playSound("warning");
			break;
		case 'warning':
			notification.warning({ ...toastMessage });
			// playSound("warning");
			break;
		case 'success':
			notification.success({ ...toastMessage });
			// playSound("success");
			break;
		default:
			notification.info({ ...toastMessage });
			// playSound('info');
			break;
	}
}

//  Export default Root Saga
export default function* rootSaga() {
	yield all([
		reportMeWatcher(),
		updateUserProfileWatcher(),
		changeUsersAssignedEntityWatcher(),
		getNotificationsWatcher(),
		markNotificationWatcher(),
		tostMessageWatcher(),
	]);
}
