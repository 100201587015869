// Libs
import React from 'react';
// Components
import { Wrapper, Chip, LogoIcon, Badge } from 'components';

const Categories = ({
	rect,
	marketplaceModules,
	mutate1ObjectInMarketplace,
	getMarketItems,
}) => {
	// Handlers
	const handleOnClick = React.useCallback(moduleId => {
		mutate1ObjectInMarketplace('modules', { selectedModuleId: moduleId });
		getMarketItems({ moduleId, limit: 20 });
	}, []);

	if (marketplaceModules.isFetching) {
		return (
			<Wrapper justifyContent='center'>
				<LogoIcon spin={true} />
			</Wrapper>
		);
	}

	const modules = marketplaceModules.data;

	return (
		<Wrapper overflow='auto' width={`${rect?.width}px`}>
			{modules.map(({ id, name, itemAmount }, idx) => (
				<div
					key={id}
					style={{ margin: idx + 1 === modules.length ? '' : '0 15px 0 0' }}
				>
					<Badge value={itemAmount} overflowCount={99}>
						<Chip
							label={name}
							selected={marketplaceModules.selectedModuleId === id}
							onClick={() => handleOnClick(id)}
						/>
					</Badge>
				</div>
			))}
		</Wrapper>
	);
};

export default Categories;
