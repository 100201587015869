// Libs
import React from 'react';
import styled from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Popover } from 'antd';

const PopoverWrapper = styled.div`
	display: flex;
	.ant-popover-inner {
		box-shadow: none;
		background-color: ${({ $newTheme }) => $newTheme._primaryBackgroundColor};
	}
	.ant-popover-inner-content {
		padding: 5px;
		border-radius: 15px;
		border: ${({ $newTheme }) =>
			`1px solid ${$newTheme._secondaryBackgroundColor}`};
		background-color: transparent;
		color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
	}
`;

const PopoverStyled = props => {
	const { theme } = useTheme();

	return (
		<PopoverWrapper $newTheme={theme}>
			<Popover
				{...props}
				getPopupContainer={triggerNode => triggerNode.parentNode}
			/>
		</PopoverWrapper>
	);
};

export default PopoverStyled;
