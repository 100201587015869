// Libs
import React, { useState, useEffect, useMemo } from 'react';
// Hooks
import { useCollectorManager } from 'hooks';
// Components
import {
	Wrapper,
	RadioV2,
	Span,
	Section,
	SectionTitle,
	LogoIcon,
} from 'components';

const styles = {
	container: {
		padding: '0',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'stretch',
		overflow: 'auto',
		maxHeight: '250px',
	},
	item: {
		padding: '0',
		margin: '0 0 5px 0',
	},
};

const addUngrouped = data => [{ id: null, name: 'Desagrupados' }].concat(data);

const GroupItem = React.memo(({ style, group, selectedGroupId, onClick }) => {
	// Is selected
	const isSelected = useMemo(
		() => group.id === selectedGroupId,
		[selectedGroupId],
	);

	return (
		<Wrapper style={style}>
			<Wrapper
				padding='0'
				width='100%'
				height='20px'
				justifyContent='stretch'
				alignItems='center'
				overflow='hidden'
			>
				<RadioV2 isSelected={isSelected} />
				<Span
					ellipsis
					width='100%'
					hoverable
					selected={isSelected}
					onClick={onClick}
				>
					{group.name}
				</Span>
			</Wrapper>
		</Wrapper>
	);
});

const GroupPicker = ({ photoReport, selectedIds, onMenuItemClick }) => {
	const { id: otdId, templateId, serviceId } = photoReport;
	const { selectedTaskId, selectedReviewId, selectedGroupId } = selectedIds;
	const { getGroupLayout } = useCollectorManager();
	const [groups, _setGroups] = useState({
		isFetching: false,
		data: [],
	});
	const setGroups = newProps =>
		_setGroups(current => ({ ...current, ...newProps }));

	// Clear data
	useEffect(() => {
		if (selectedGroupId === undefined) setGroups({ data: [] });
	}, [selectedGroupId]);

	// Get groups data
	useEffect(() => {
		let isMounted = true;
		if (!selectedTaskId || !selectedReviewId) return;
		setGroups({ isFetching: true });
		getGroupLayout({
			otdId,
			templateId,
			serviceId,
			serviceTaskId: selectedTaskId,
			reviewId: selectedReviewId,
			filled: true, // TODO: Quitar hardcoded
		}).then(
			data =>
				isMounted &&
				setGroups({
					data,
					isFetching: false,
				}),
		);
		return () => {
			isMounted = false;
		};
	}, [selectedReviewId]);

	// Autoselect first group
	useEffect(() => {
		const firstGroup = groups.data[0];
		if (groups.isFetching || !firstGroup) return;
		onMenuItemClick({
			selectedGroupId: firstGroup.id,
			selectedSubgroupId: undefined,
		});
	}, [groups.data, groups.isFetching]);

	// Memoized group data
	const groupData = useMemo(
		() =>
			groups.data.map(group => (
				<GroupItem
					key={group.id}
					group={group}
					selectedGroupId={selectedGroupId}
					style={styles.item}
					onClick={() =>
						onMenuItemClick({
							selectedGroupId: group.id,
							selectedSubgroupId: undefined,
						})
					}
				/>
			)),
		[groups.data, selectedGroupId],
	);

	return (
		<Section>
			<SectionTitle title='Grupo' empty={!groups.data[0]} />
			<Wrapper {...styles.container}>
				{groups.isFetching ? <LogoIcon spin={true} /> : groupData}
			</Wrapper>
		</Section>
	);
};

export default GroupPicker;
