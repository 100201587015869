import React from 'react';
import AuthService from './AuthService';
import { connect } from 'react-redux';

import * as actions from 'screens/Login/actions';
import lsHandler from '../localStorage/utils';

export default function withAuth(AuthComponent) {
	const Auth = new AuthService();

	class AuthWrapped extends React.Component {
		constructor(...props) {
			super(...props);
		}

		componentDidMount() {
			if (!Auth.loggedIn()) {
				this.props.history.replace('/login');
			} else {
				try {
					//  Set Profile
					const authProfile = Auth.getProfile(); // TODO: Optimize getProfile process
					// const profile = this.props.getMyProfile(); //TODO: Suggestion
					const userProfile = lsHandler.get('synapse_profile');
					this.props.setMyProfile({ ...authProfile, ...userProfile });
				} catch (err) {
					Auth.logout();
					this.props.history.replace('/login');
				}
			}
		}

		render() {
			const { profile } = this.props;

			if (profile) {
				return <AuthComponent {...this.props} {...this.state} />;
			} else {
				return null;
			}
		}
	}

	return connect(
		state => ({
			profile: state.login.profile,
		}),
		actions,
	)(AuthWrapped);
}
