// Setters
function save(name, content) {
	try {
		localStorage.setItem(name, JSON.stringify(content));
		return true;
	} catch (err) {
		alert(err);
		return false;
	}
}

function saveLinked(content) {
	try {
		localStorage.setItem('synapse_linked', JSON.stringify(content));
		return true;
	} catch (err) {
		return false;
	}
}

// Getters
function get(name) {
	return JSON.parse(localStorage.getItem(name));
}

function getLinked() {
	let linked = JSON.parse(localStorage.getItem('synapse_linked'));

	if (!linked) {
		const content = {
			connected: false,
			end_time: '',
			start_at: '',
			start_time: '',
			total_time_connected: '00:00:00',
			coords: {},
		};

		if (saveLinked(content)) {
			linked = JSON.parse(localStorage.getItem('synapse_linked'));
		}
	}
	return linked;
}

// Deletters
function del(name) {
	localStorage.removeItem(name);
}

export default {
	save,
	saveLinked,
	get,
	getLinked,
	del,
};
