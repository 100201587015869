import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CreateWarehouse } from 'screens/Warehouses/subs';
//Selectors
import { selectCreateWarehouse } from 'screens/Warehouses/selectors';
import { selectEntities } from 'screens/Signup/selectors';
import { selectProfile } from 'screens/Login/selectors';
//Actions
import { handleOnClickCreateWarehouse } from 'screens/Warehouses/actions';
import { mutate1Object } from 'screens/Warehouses/actions';
import { getSignupDataWorker } from 'screens/Signup/actions';
import { getMyProfile } from 'screens/Login/actions';

const Warehouse = () => {
	const createWarehouse = useSelector(selectCreateWarehouse);
	const entities = useSelector(selectEntities);
	const profile = useSelector(selectProfile);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getSignupDataWorker());
		dispatch(getMyProfile());
	}, []);

	return (
		<CreateWarehouse
			entities={entities}
			createWarehouse={createWarehouse}
			profile={profile}
			mutate1Object={(obj1Name, keyValuePairs) =>
				dispatch(mutate1Object(obj1Name, keyValuePairs))
			}
			handleOnClickCreateWarehouse={() =>
				dispatch(handleOnClickCreateWarehouse(createWarehouse))
			}
		/>
	);
};

export default Warehouse;
