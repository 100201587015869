// Libs
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'underscore';
// Utils
import GENERAL from 'utils/constants/general';
// Actions
import { mutate1Object as _mutate1ObjectInSupervisor } from 'screens/Supervisor/actions';
// Hooks
import { useTheme } from 'hooks';
// Components
import { CreatedBy } from 'components';
import ActionsBox from 'core/ElementBuilder/elements/Supervisor/ActionsBox';
import CollectorPhoto from './CollectorPhoto';
import WrapperSwitcher from './WrapperSwitcher';
//Keywords
import COLLECTOR_KEYWORDS from 'components/Collector/keywords';
// Skeleton
import CollectorSkeleton from './CollectorSkeleton';
// Styles
import styles from './Collector.module.css';
import Inventory from 'components/Inventory/Inventory';

const { RESOURCES } = GENERAL.ENV;

const Collector = ({
	isMobile,
	collector,
	otdId,
	serviceTaskId,
	logo,
	reviewing,
	onUpdatedCoordSuccess,
}) => {
	const dispatch = useDispatch();
	const { theme, isDarkTheme } = useTheme();

	const mutate1ObjectInSupervisor = (key, props) =>
		dispatch(_mutate1ObjectInSupervisor(key, props));

	const openDetailsDrawer = useCallback(
		({ photoId, showPhotos, showComments, showButtons }) =>
			mutate1ObjectInSupervisor('commentDetailsDrawer', {
				isOpen: true,
				selectedElementId: photoId,
				data: collector?.photos ?? [],
				showPhotos,
				showComments,
				showButtons,
			}),
		[collector?.photos],
	);

	const openCommentModal = () =>
		mutate1ObjectInSupervisor('commentModal', {
			isOpen: true,
			selectedElement: collector,
		});

	return (
		<div
			style={{
				backgroundColor: theme._primaryBackgroundColor,
			}}
			className={
				isMobile ? styles.collectorSectionMobile : styles.collectorSection
			}
		>
			{collector.typeKey ===
			COLLECTOR_KEYWORDS.COLLECTORS.TYPES.CONSUME_INVENTORY ? (
				<Inventory inventory={collector.inventory} />
			) : (
				<>
					<div
						style={{
							color: theme._inactiveColor,
							backgroundColor: isDarkTheme
								? theme._secondaryBackgroundColor
								: theme._primaryBackgroundColor,
						}}
						className={
							isMobile ? styles.collectorInfoMobile : styles.collectorInfo
						}
					>
						<div
							style={{
								color: theme._inactiveColor,
							}}
							className={styles.collectorName}
						>
							{collector?.name}
						</div>

						<div
							style={{
								color: theme._primaryColor,
							}}
							className={styles.valueWrapper}
						>
							{collector?.value}
						</div>
						{reviewing && (
							<div className={styles.bottom}>
								<CreatedBy
									userId={collector?.createdBy}
									userName={collector?.userName}
									date={collector?.updatedAt}
								/>
								<div className={styles.actionsWrapper}>
									<ActionsBox
										autonomous={true}
										resourceKey={RESOURCES.KEYS.COLLECTOR}
										resourceId={collector?.id}
										onClickCommentsButton={openCommentModal}
										noBackground={true}
										justButtons={true}
									/>
								</div>
							</div>
						)}
					</div>
					{!!collector?.photos?.length && (
						<WrapperSwitcher
							className={styles.collectorPhotoWrapperMobile}
							justChildren={!isMobile}
						>
							{collector?.photos.map(photo => (
								<CollectorPhoto
									key={photo.id}
									isMobile={isMobile}
									collectorValueId={collector.id}
									photo={photo}
									otdId={otdId}
									serviceTaskId={serviceTaskId}
									logo={logo}
									reviewing={reviewing}
									openDetailsDrawer={openDetailsDrawer}
									onUpdatedCoordSuccess={onUpdatedCoordSuccess}
								/>
							))}
						</WrapperSwitcher>
					)}
				</>
			)}
		</div>
	);
};

Collector.Skeleton = CollectorSkeleton;

export default Collector;
