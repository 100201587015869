// Services
import ApiServiceV2 from 'services/apiService/apiServiceV2';

const endpoint = '/inventoryManager';
const useWarehouseApi = () => {
	return {
		/**
		 * Get Inventory Status
		 * @param {int} otdId
		 * @returns Object[]
		 */
		collectorInventorySyncStatus: otdId =>
			ApiServiceV2.get({
				url: `${endpoint}/v1/consume/collectorInventorySyncStatus/${otdId}`,
			}),
		/**
		 * Set Inventory
		 * @param {int} otdId
		 * @returns Object[]
		 */
		collectorInventorySync: otdId =>
			ApiServiceV2.put({
				url: `${endpoint}/v1/consume/collectorInventorySync/${otdId}`,
			}),
	};
};

export default useWarehouseApi;
