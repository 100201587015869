// Libs
import React from 'react';
// Utils
import ScreenBuilderConfig from './screen.config';
// Components
import { Wrapper, Subtitle, Span } from 'components';

const ScreenPickerItem = ({
	selectedScreenId,
	screenId,
	screenKey,
	screenTitle,
	onClick,
}) => {
	const isSelected = React.useMemo(
		() => screenId === selectedScreenId,
		[selectedScreenId],
	);

	const Icon = ScreenBuilderConfig.getPickerIcon(screenKey);
	if (!Icon) return null;

	return (
		<Wrapper
			padding='5px'
			width='100%'
			height='34px'
			cursor='pointer'
			onClick={onClick}
		>
			<Icon selected={isSelected} />
			<Span margin='0 0 0 10px'>{screenTitle}</Span>
		</Wrapper>
	);
};

function ScreenPicker({ selectedScreenId, screens, onMutateScreen }) {
	return (
		<>
			<Subtitle margin='0 0 5px 0' disabled>
				Selecciona una pantalla
			</Subtitle>
			{screens.map(({ id, key, title }) => (
				<ScreenPickerItem
					key={id.toString()}
					selectedScreenId={selectedScreenId}
					screenId={id}
					screenKey={key}
					screenTitle={title}
					onClick={() => onMutateScreen(id)}
				/>
			))}
		</>
	);
}

export default ScreenPicker;
