// Utils
import GENERAL from 'utils/constants/general';
// Components
import { notification } from 'antd';

const { NOTIFICATIONS } = GENERAL;

const useToastMessage = () => {
	const sendToast = toastMessage => {
		const exec = toastMessage.type || 'info'; // error, warn, warning, success, info
		notification[exec]({
			message: 'Synapse',
			description: '',
			duration: 5,
			className: 'notification-dark-style',
			...toastMessage,
			key: NOTIFICATIONS.TYPES.GLOBAL,
		});
	};

	return sendToast;
};

export default useToastMessage;
