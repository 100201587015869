// Libs
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// Services
import { ApiServiceV2 } from 'services';
// Selectors
import { selectSelectedOrderTargetFilterId } from 'core/selectors';

const useGenerateReport = () => {
	const [state, _setState] = useState({
		users: [],
	});
	const setState = newProps =>
		_setState(current => ({ ...current, ...newProps }));

	const selectedOrderTargetFilterId = useSelector(
		selectSelectedOrderTargetFilterId,
	);

	useEffect(() => {
		ApiServiceV2.get({
			url: `/reports/toSendEmailUsers/${selectedOrderTargetFilterId}`,
		}).then(users => setState({ users }));
	}, []);

	return state;
};

export default useGenerateReport;
