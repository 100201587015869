// Libs
import React from 'react';
import { xlsx } from 'utils/libs';
// Components
import { ExcelIcon } from 'components';

const DataExport = ({ datasource }) => {
	return (
		<ExcelIcon
			button
			onClick={() =>
				xlsx.exportMultiSheetToExcel('Datos', [
					{
						name: 'Datos',
						data: datasource,
					},
				])
			}
		/>
	);
};

export default DataExport;
