/** **** CONSTANTS *******/
import GENERAL from '../../utils/constants/general';
const { ANALYTICS } = GENERAL;

/** **** FUNCTIONS *******/

export const mutate1Object = (obj1Name, keyValuePairs) => ({
	type: ANALYTICS.MUTATE_1OBJECT,
	payload: { obj1Name, keyValuePairs },
});

export const mutateDirectProps = keyValuePairs => ({
	type: ANALYTICS.MUTATE_DIRECT_PROPS,
	payload: keyValuePairs,
});

export const getCharts = payload => ({
	type: ANALYTICS.GET_CHARTS,
	payload,
});

export function getNewOrdersVsBilled(params) {
	return {
		type: ANALYTICS.GET_NEW_ORDERS_VS_BILLED,
		payload: params,
	};
}

export function getBilledByEntity(params) {
	return {
		type: ANALYTICS.GET_BILLED_BY_ENTITY,
		payload: params,
	};
}

export const doReset = () => {
	return {
		type: ANALYTICS.RESET,
	};
};
