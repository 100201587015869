// Libs
import React, { useContext } from 'react';
// Components
import { Span } from 'components';
// Context
import { ElementViewBuilderContext } from 'core/context';
// Config
import { ElementViewBuilderConfig } from 'core/ElementViewBuilder';

const OrderId = ({ elementId, odtId }) => {
	const { setElementView } = useContext(ElementViewBuilderContext);
	const elementViewKeys =
		ElementViewBuilderConfig.getAuthorizedElementViewKeys();

	return (
		<Span
			onClick={() =>
				setElementView({
					elementViewKey: elementViewKeys.ORDER_ELEMENT,
					elementId,
				})
			}
		>
			{odtId}
		</Span>
	);
};
export default OrderId;
