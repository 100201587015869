// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M9 5V9H21V5H9ZM9 19H21V15H9V19ZM9 14H21V10H9V14ZM4 9H8V5H4V9ZM4 19H8V15H4V19ZM4 14H8V10H4V14Z'
			fill={fill}
			fillOpacity={fillOpacity}
		/>
	</svg>
);
