// Libs
import React from 'react';
import { Card } from 'antd';
// Utils
import GENERAL from '../../../../../../utils/constants/general';
import { formatNumber, coinsUtils } from '../../../../../../utils/libs';
import { getPaymentColorState } from '../../../../../Billings/utils';
// Components
import {
	Button,
	TableVirtualized,
	Checkbox,
	Link,
	IconButton,
	LogoIcon,
} from 'components';
// Styles
import './PaycutCard.css';
import { SyncIcon } from 'components/Icons';

const { ENV } = GENERAL;
const { Meta } = Card;

const { onClickRow } = TableVirtualized;

export default function PaycutCard(props) {
	const {
		id,
		name,
		production_amount,
		production_charge,
		from_date,
		to_date,
		creator_name,
		entity_name,
		created_at,
		updated_at,
		coin_id,
		state,
		state_label,
		cost,
		sale,
		customProps,
	} = props;
	const {
		paycutsManage,
		getPaycutsResumeModal,
		coins,
		apiPayments,
		syncPaycut,
		getPayments,
		getPaycutsModal,
		getPaycutResume,
		mutate1ObjectInBillings,
		mutate1ObjectApi,
	} = customProps;
	const coinSymbol = coinsUtils.getDataByCoinId(coin_id, 'symbol', coins);
	const productionAmount = formatNumber.new(production_amount, coinSymbol, 2);
	const productionCharge = formatNumber.new(production_charge, coinSymbol, 2);

	const onClickedRow = filteredItems =>
		mutate1ObjectInBillings('paycuts', {
			selected: { ...paycutsManage.selected, items: filteredItems },
		});

	return (
		<div>
			<div
				className='paycut-flag-payment-state'
				style={getPaymentColorState(state)}
			/>
			<Meta
				id='paycut_meta-card-container'
				title={
					<div className='paycut-container-title-card'>
						<span className='paycut-card-document'>
							<Checkbox
								margin='0 5px 0 0'
								checked={paycutsManage.selected.items.indexOf(id) !== -1}
								onChange={() =>
									onClickRow(id, paycutsManage.selected, onClickedRow)
								}
							/>
							{id}-{name}
						</span>

						<div style={{ height: '18px' }}>
							<span className='paycut-body-concept-item'>Creado por:</span>
							<span className='paycut-card-creator'>
								{creator_name} - {entity_name}
							</span>
						</div>
						<div style={{ height: '18px' }}>
							<span className='paycut-body-concept-item'>Fecha:</span>
							<span className='paycut-card-created_at'>{created_at}</span>
						</div>
						<div style={{ height: '18px' }}>
							<span className='paycut-body-concept-item'>Actualizado:</span>
							<span className='paycut-card-created_at'>{updated_at}</span>
						</div>
					</div>
				}
				description={
					<div>
						<div className='paycut-container-data-input'>
							<div className='paycut-data'>
								<div className='paycut-body-container-item'>
									<div className='paycut-body-concept-item'>
										Costo: <b>{productionAmount}</b>
									</div>
								</div>
								<div className='paycut-body-container-item'>
									<div className='paycut-body-concept-item'>
										Venta: <b>{productionCharge}</b>
									</div>
								</div>
								{!isNaN(cost) && !isNaN(sale) ? (
									<div className='paycut-body-container-item'>
										<div className='paycut-body-concept-item'>
											Costo/Venta:
											<b>
												{cost}% / {sale}%
											</b>
										</div>
									</div>
								) : (
									<div />
								)}
							</div>

							<div className='paycut-data'>
								<div className='paycut-body-container-item'>
									<div className='paycut-body-concept-item'>
										Desde: <b>{from_date}</b>
									</div>
								</div>
								<div className='paycut-body-container-item'>
									<div className='paycut-body-concept-item'>
										Hasta: <b>{to_date}</b>
									</div>
								</div>
								<div className='paycut-body-container-item'>
									<div className='paycut-body-concept-item'>
										Estado: <b>{state_label}</b>
									</div>
								</div>
							</div>
						</div>

						<div className='paycut-buttons-container'>
							{state === 'ready' || state === 'released' ? (
								paycutsManage.synchronizing[id] ? (
									<LogoIcon button spin />
								) : (
									<SyncIcon button onClick={() => syncPaycut(id)} />
								)
							) : (
								<div />
							)}

							{/* CONTROL BUTTONS */}
							{paycutsManage.selected.items.length === 0 && (
								<div>
									<Link
										to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_PAYS_MOBILE}
										onClick={() => {
											mutate1ObjectInBillings('paycuts', {
												selected: { ...paycutsManage.selected, items: [id] },
											});
											mutate1ObjectApi('payments', {
												pays: { ...apiPayments.pays, data: [] },
											});
											getPayments([id]);
										}}
									>
										<Button style={{ height: '28px' }}>Ver Pagos</Button>
									</Link>

									<Button
										height='28px'
										margin='0 0 0 10px'
										color='white'
										onClick={() => {
											mutate1ObjectInBillings('paycuts', {
												selected: { ...paycutsManage.selected, items: [id] },
											});
											mutate1ObjectInBillings('signPaycutModal', {
												isOpen: true,
											});
											getPaycutResume({
												...getPaycutsResumeModal,
												isInvoice: getPaycutsModal.type === 'invoice',
												paycutIds: [id],
											});
										}}
									>
										Resumen
									</Button>
								</div>
							)}
						</div>
					</div>
				}
			/>
		</div>
	);
}
