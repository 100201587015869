import React from 'react';

const Icon = ({ fillColor = 'black', width = 24, height = 24, onClick }) => (
	<svg
		onClick={onClick}
		width={width}
		height={height}
		viewBox='0 0 24 24'
		fill={fillColor}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M21.99 4C21.99 2.9 21.1 2 20 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H18L22 22L21.99 4ZM18 14H6V12H18V14ZM18 11H6V9H18V11ZM18 8H6V6H18V8Z'
			fill={fillColor}
			fillOpacity='0.80'
		/>
	</svg>
);

export default Icon;
