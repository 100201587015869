// Libs
import React from 'react';
// Utils
import { checkAddZero, calculateDifference } from './utils';
// Components
import { Span } from 'components';

const NOT_CONFIGURED = 'No configurado';
const UnAvailableTime = () => (
	<Span size='m' color='mediumaquamarine'>
		{NOT_CONFIGURED}
	</Span>
);

const TimeElapsed = ({ time }) => {
	const { timeElapsed } = time;
	if (Object.keys(time).length === 0) {
		return <UnAvailableTime />;
	}

	// Time remaining is object?
	const calculateDiff = calculateDifference(timeElapsed);
	const { days, hours, minutes, seconds } = calculateDiff;
	const sumHours = days * 24 + hours;
	return (
		<Span size='m' color='mediumaquamarine'>
			{checkAddZero(sumHours)}:{checkAddZero(minutes)}:{checkAddZero(seconds)}
		</Span>
	);
};

export default TimeElapsed;
