// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
// Utils
import { filterPayments } from 'utils/filters/Billings/payments';
import GENERAL from 'utils/constants/general';
// Components
import { VirtualizedList } from 'screens/Utils';
import { Wrapper, Empty } from 'components';

const { ENV } = GENERAL;

function InvoicesMobile(props) {
	if (
		props?.profile?.entity?.id ===
			props?.profile?.organization?.settings?.mainEntity &&
		(!Array.isArray(props?.paycuts?.selected?.items) ||
			props?.paycuts?.selected?.items?.length === 0)
	) {
		return <Redirect to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYCUTS_MOBILE} />;
	}

	const {
		// BILLINGS
		pays,
		mutate1Object,
		getPaycutsModal,
		getPaymentResume,
		// API
		payments,
		coins,
		getPaymentOrders,
		getPaymentLines,
		// GENERAL
		profile,
	} = props;
	const allPayments = payments.pays.data;
	const filteredPays = filterPayments(allPayments, pays.filters);

	return (
		<Wrapper padding='8px' justifyContent='center' className='animated fadeIn'>
			{!payments.pays.loading && filteredPays.length === 0 ? (
				<Empty style={{ marginTop: '20px' }} />
			) : (
				<VirtualizedList
					cardId='invoicePayCard'
					listStyle={{
						height: document.documentElement.clientHeight,
						width: document.documentElement.clientWidth,
					}}
					rowHeight={220}
					spinTitle='Consultando Pagos...'
					isSpinning={payments.pays.loading}
					rows={filteredPays}
					customProps={{
						paysManage: pays,
						coins: coins.data,
						profile,
						getPaycutsModal,
						getPaymentResume,
						getPaymentOrders,
						getPaymentLines,
						mutate1ObjectInBillings: mutate1Object,
					}}
				/>
			)}
		</Wrapper>
	);
}

export default InvoicesMobile;
