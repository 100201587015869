import React from 'react';

const NoModuleEstablished = () => {
	return (
		<div>
			<span style={{ color: 'white' }}>Entorno no configurado</span>
		</div>
	);
};

export default NoModuleEstablished;
