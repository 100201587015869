// Libs
import React from 'react';
import { Link } from 'react-router-dom';
// Utils
import GENERAL from 'utils/constants/general';
import { formatNumber, coinsUtils } from 'utils/libs';
import {
	getPaymentColorState,
	thisBelongsToMyEntity,
} from 'screens/Billings/utils';
// Components
import { Card } from 'antd';
import { IconButton } from '@mui/material';
import { Button, Popover, DocumentIcon } from 'components';
// Styles
import './PayCard.css';

const { ENV } = GENERAL;
const { Meta } = Card;

export default function PayCard(props) {
	const {
		id,
		orders_total,
		orders_topay,
		orders_notpay,
		orders_previous,
		orders_pending,
		orders_paid,
		lines_quantity,
		unlines_quantity,
		production_amount,
		production_charge,
		payroll_label,
		state_label,
		state,
		paid_to,
		paid_to_name,
		creator_name,
		entity_id,
		entity_name,
		coin_id,
		created_at,
		updated_at,
		template_name,
		cost,
		sale,
		customProps,
	} = props;
	const {
		paysManage,
		coins,
		profile,
		getPaymentResume,
		getPaymentOrders,
		getPaymentLines,
		mutate1ObjectInBillings,
	} = customProps;
	const coinSymbol = coinsUtils.getDataByCoinId(coin_id, 'symbol', coins);
	const productionAmount = formatNumber.new(production_amount, coinSymbol, 2);
	const productionCharge = formatNumber.new(production_charge, coinSymbol, 2);
	const payIds = Array.isArray(id) ? [...id] : [id];

	const GetOrdersContent = (
		<ul className='pay-popover-list'>
			<li>
				<Link
					disabled={Number(orders_total) <= 0}
					id={
						Number(orders_total <= 0)
							? 'pay-popover-item-disabled'
							: 'pay-popover-item'
					}
					to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_ORDERS_MOBILE}
					onClick={() => {
						mutate1ObjectInBillings('pays', {
							selected: { ...paysManage.selected, items: payIds },
							getOrders: 'orders_total',
						});
						getPaymentOrders(payIds, 'orders_total');
					}}
				>
					Atendidas ({orders_total})
				</Link>
			</li>
			<li>
				<Link
					disabled={Number(orders_topay) <= 0}
					id={
						Number(orders_topay <= 0)
							? 'pay-popover-item-disabled'
							: 'pay-popover-item'
					}
					to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_ORDERS_MOBILE}
					onClick={() => {
						mutate1ObjectInBillings('pays', {
							selected: { ...paysManage.selected, items: payIds },
							getOrders: 'orders_topay',
						});
						getPaymentOrders(payIds, 'orders_topay');
					}}
				>
					Pagar ({orders_topay})
				</Link>
			</li>
			<li>
				<Link
					disabled={Number(orders_notpay) <= 0}
					id={
						Number(orders_notpay <= 0)
							? 'pay-popover-item-disabled'
							: 'pay-popover-item'
					}
					to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_ORDERS_MOBILE}
					onClick={() => {
						mutate1ObjectInBillings('pays', {
							selected: { ...paysManage.selected, items: payIds },
							getOrders: 'orders_notpay',
						});
						getPaymentOrders(payIds, 'orders_notpay');
					}}
				>
					No Pagar ({orders_notpay})
				</Link>
			</li>
			<li>
				<Link
					disabled={Number(orders_previous) <= 0}
					id={
						Number(orders_previous <= 0)
							? 'pay-popover-item-disabled'
							: 'pay-popover-item'
					}
					to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_ORDERS_MOBILE}
					onClick={() => {
						mutate1ObjectInBillings('pays', {
							selected: { ...paysManage.selected, items: payIds },
							getOrders: 'orders_previous',
						});
						getPaymentOrders(payIds, 'orders_previous');
					}}
				>
					Anteriores ({orders_previous})
				</Link>
			</li>
			<li>
				<Link
					disabled={Number(orders_pending) <= 0}
					id={
						Number(orders_pending <= 0)
							? 'pay-popover-item-disabled'
							: 'pay-popover-item'
					}
					to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_ORDERS_MOBILE}
					onClick={() => {
						mutate1ObjectInBillings('pays', {
							selected: { ...paysManage.selected, items: payIds },
							getOrders: 'orders_pending',
						});
						getPaymentOrders(payIds, 'orders_pending');
					}}
				>
					Pendientes de Pago ({orders_pending})
				</Link>
			</li>
			<li>
				<Link
					disabled={Number(orders_paid) <= 0}
					id={
						Number(orders_paid <= 0)
							? 'pay-popover-item-disabled'
							: 'pay-popover-item'
					}
					to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_ORDERS_MOBILE}
					onClick={() => {
						mutate1ObjectInBillings('pays', {
							selected: { ...paysManage.selected, items: payIds },
							getOrders: 'orders_paid',
						});
						getPaymentOrders(payIds, 'orders_paid');
					}}
				>
					Pago Final ({orders_paid})
				</Link>
			</li>
		</ul>
	);

	return (
		<div>
			<div
				className='pay-flag-payment-state'
				style={getPaymentColorState(state)}
			/>
			<Meta
				id='paycut_meta-card-container'
				title={
					<div className='pay-container-title-card'>
						<span className='pay-card-document'>
							{payIds.length === 1 && `${id}-`}
							{paid_to_name}
						</span>

						<div style={{ height: '18px' }}>
							<span className='pay-body-concept-item'>Creado por:</span>
							<span className='pay-card-creator'>
								{creator_name} - {entity_name}
							</span>
						</div>
						<div style={{ height: '18px' }}>
							<span className='pay-body-concept-item'>Fecha:</span>
							<span className='pay-card-created_at'>{created_at}</span>
						</div>
						<div style={{ height: '18px' }}>
							<span className='pay-body-concept-item'>Actualizado:</span>
							<span className='pay-card-created_at'>{updated_at}</span>
						</div>
					</div>
				}
				description={
					<div>
						<div className='pay-container-data-input'>
							<div className='pay-data'>
								<div className='pay-body-container-item'>
									<div className='pay-body-concept-item'>
										Costo: <b>{productionAmount}</b>
									</div>
								</div>
								<div className='pay-body-container-item'>
									<div className='pay-body-concept-item'>
										Venta: <b>{productionCharge}</b>
									</div>
								</div>
								{!isNaN(cost) && !isNaN(sale) ? (
									<div className='pay-body-container-item'>
										<div className='pay-body-concept-item'>
											Costo/Venta:
											<b>
												{cost}% / {sale}%
											</b>
										</div>
									</div>
								) : (
									<div />
								)}
							</div>

							<div className='pay-data-right'>
								<div className='pay-body-container-item'>
									<div className='pay-body-concept-item'>
										Planilla: <b>{payroll_label}</b>
									</div>
								</div>
								<div className='pay-body-container-item'>
									<div className='pay-body-concept-item'>
										Template: <b>{template_name}</b>
									</div>
								</div>
								<div className='pay-body-container-item'>
									<div className='pay-body-concept-item'>
										Estado: <b>{state_label}</b>
									</div>
								</div>
							</div>
						</div>

						<div className='pay-buttons-container'>
							<Popover
								content={GetOrdersContent}
								placement='top'
								title={null}
								trigger='click'
							>
								<Button style={{ height: '28px', fontSize: '13px' }}>
									Ordenes
								</Button>
							</Popover>

							{thisBelongsToMyEntity(entity_id, profile) ? (
								<Link
									to={ENV.ROUTES.PATHS.BILLINGS_PAYMENTS_LINES_MOBILE}
									onClick={() => {
										mutate1ObjectInBillings('pays', {
											selected: { ...paysManage.selected, items: payIds },
										});
										getPaymentLines(payIds, paid_to);
									}}
								>
									<Button
										style={{
											marginLeft: '10px',
											height: '28px',
											fontSize: '13px',
										}}
									>
										Líneas ({lines_quantity}/{unlines_quantity})
									</Button>
								</Link>
							) : (
								<span className='pay-lines-label'>
									Líneas ({lines_quantity})
								</span>
							)}

							<DocumentIcon
								onClick={() => {
									mutate1ObjectInBillings('pays', {
										selected: { ...paysManage.selected, items: payIds },
									});
									mutate1ObjectInBillings('signPaymentModal', { isOpen: true });
									getPaymentResume(payIds);
								}}
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
}
