/** **** CONSTANTS *******/
import GENERAL from '../../utils/constants/general';
import { onCreateControlSeriesTransactionValidator } from './actionsValidator';

const { WAREHOUSES, DASHBOARD } = GENERAL;

//* ***************** HANDLERS ***********************/
export function handleOnClickCreateWarehouse(createWarehouse, loading) {
	const { name, owner_id, details } = createWarehouse;

	if (loading) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Me encuentro realizando la operación en este momento',
				type: 'warn',
			},
		};
	}

	if (
		!name ||
		name === '' ||
		!owner_id ||
		owner_id === '' ||
		!details.type ||
		details.type === ''
	) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Debe completar los campos requeridos [*]',
				type: 'warn',
			},
		};
	}

	if (
		details.type === 'Fisico' &&
		(!details.country ||
			details.country === '' ||
			!details.address ||
			details.address === '')
	) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Debe completar los campos requeridos [*]',
				type: 'warn',
			},
		};
	}

	createWarehouse = {
		name,
		owner_id,
		details,
	};

	return {
		type: WAREHOUSES.CREATE_WAREHOUSE,
		payload: createWarehouse,
	};
}

export function handleOnClickCreatePath(createPath) {
	const { zone, area } = createPath;

	if (!zone || zone === '' || !area || area === '') {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Debe completar los campos requeridos [*]',
				type: 'warn',
			},
		};
	}

	return {
		type: WAREHOUSES.CREATE_WAREHOUSE_PATH,
		payload: createPath,
	};
}

export function handleOnClickCreateArticle(createArticle) {
	const { name, measured_unit_id, coin_id, price, iv } = createArticle;

	if (
		!name ||
		name === '' ||
		!measured_unit_id ||
		!coin_id ||
		iv === undefined ||
		iv === undefined
	) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: 'Debe completar los campos requeridos [*]',
				type: 'warn',
			},
		};
	}

	return {
		type: WAREHOUSES.CREATE_ARTICLE,
		payload: createArticle,
	};
}

export function getSearchingSeries(series) {
	return {
		type: WAREHOUSES.GET_SEARCHING_SERIES,
		payload: series,
	};
}

//* ***************** FUNCTIONS ***********************/
export function mutateDirectProps(keyValuePairs) {
	return {
		type: WAREHOUSES.MUTATE_DIRECT_PROPS,
		payload: keyValuePairs,
	};
}

export function mutate1Object(obj1Name, keyValuePairs) {
	return {
		type: WAREHOUSES.MUTATE_1OBJECT,
		payload: { obj1Name, keyValuePairs },
	};
}

export function sendTransferTransaction(props, mode) {
	let transaction, transaction_type_id;

	if (mode === 'newEntry' || mode === 'trsSend' || mode === 'trsReceive') {
		const {
			incomeMethod,
			owarehouseId,
			dwarehouseId,
			signer,
			comment,
			articles,
			serialized,
		} = props;

		// Validate Transaction
		if (articles.length <= 0 && serialized.length <= 0)
			return {
				type: DASHBOARD.TOAST_MESSAGE,
				payload: {
					message: 'Por favor indique los artículos que desea transferir',
					type: 'warn',
				},
			};

		if (mode === 'newEntry') transaction_type_id = 25;
		else if (mode === 'trsSend' || mode === 'trsReceive')
			transaction_type_id = 26;

		mode = 'transfer';
		transaction = {
			doc_number: null,
			transaction_type_id,
			warehouse_inv_income_method_id: incomeMethod,
			comment: comment && comment !== '' ? comment : null,
			owarehouse_id: owarehouseId,
			dwarehouse_id: dwarehouseId,
			signer,
			inventory: {
				articles: articles.reduce((acc, row) => {
					acc.push({
						item_id: row.item_id,
						serie_id: row.serie_id,
						amount: row.amount,
						serie: row.serie,
						warehouse_package_id: row.warehouse_package_id,
					});
					return acc;
				}, []),
				serialized: serialized.reduce((acc, row) => {
					acc.push({
						item_id: row.item_id,
						serie: row.serie,
						state_id: row.state_id,
						warehouse_package_id: row.warehouse_package_id,
					});
					return acc;
				}, []),
			},
		};
	} else if (mode === 'adjust') {
		const { adjustType, adjustArticles, warehouseId, signer, comment } = props;
		let warehouse_inv_income_method_id = 0;

		if (adjustType === 'absolute') warehouse_inv_income_method_id = 5;
		else if (adjustType === 'relative') warehouse_inv_income_method_id = 6;

		if (adjustArticles.length <= 0)
			return {
				type: DASHBOARD.TOAST_MESSAGE,
				payload: {
					message: 'Por favor indique los artículos que desea ajustar',
					type: 'warn',
				},
			};

		transaction = {
			doc_number: null,
			transaction_type_id: 28,
			warehouse_inv_income_method_id,
			comment: comment && comment !== '' ? comment : null,
			owarehouse_id: warehouseId,
			dwarehouse_id: warehouseId,
			signer,
			inventory: {
				articles: adjustArticles.reduce((acc, row) => {
					acc.push({
						item_id: row.item_id,
						amount: row.amount,
						action: row.action,
					});
					return acc;
				}, []),
				serialized: [],
			},
		};
	}

	return {
		type: WAREHOUSES.SEND_TRANSFER_TRANSACTION,
		payload: { transaction, transactionMode: mode },
	};
}

export function updateTransaction(items, operation) {
	function filterProps() {
		if (operation === 'reception') {
			return items.reduce((acc, item) => {
				acc.push({
					id: item.id,
					warehouse_package_id: item.warehouse_package_id,
				});
				return acc;
			}, []);
		}
	}

	return {
		type: WAREHOUSES.UPDATE_TRANSACTION,
		payload: { items: filterProps(), operation },
	};
}

export function onCreateControlSeriesTransaction(request) {
	const errors = onCreateControlSeriesTransactionValidator(request);

	if (errors) {
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: {
				message: errors,
				type: 'warn',
				duration: 60,
			},
		};
	}

	return {
		type: WAREHOUSES.CREATE_SERIES_CONTROL_TRANSACTION,
		payload: request,
	};
}

export function deleteTransaction(document_id) {
	return {
		type: WAREHOUSES.DELETE_TRANSACTION,
		payload: document_id,
	};
}

export function sendCreatePackage(pkg) {
	const {
		item_id,
		amount,
		state_id,
		serialized,
		warehouse_area_id,
		warehouse_zone_id,
		mustCreateZone,
		mustCreateArea,
		operation,
	} = pkg;
	let { zone, area } = pkg;

	if (zone) zone = zone.trim();
	if (area) area = area.trim();

	if (
		(!serialized && !item_id) ||
		(mustCreateZone && (!zone || !area)) ||
		(mustCreateArea && (!area || (!zone && !warehouse_zone_id))) ||
		(!mustCreateZone && !warehouse_zone_id) ||
		(!mustCreateArea && !warehouse_area_id) ||
		(operation === 'newEntry' && serialized && (!item_id || !state_id))
	)
		return {
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: 'Debe completar todos los campos [*]', type: 'warn' },
		};

	return {
		type: WAREHOUSES.CREATE_PACKAGE,
		payload: {
			item_id,
			amount,
			serialized,
			warehouse_area_id,
			warehouse_zone_id,
			zone,
			area,
			mustCreateZone,
			mustCreateArea,
			operation,
		},
	};
}

export function resetAdjustArticles() {
	return {
		type: WAREHOUSES.RESET_ADJUST_ARTICLES,
	};
}

export function resetCreateTransaction() {
	return {
		type: WAREHOUSES.RESET_CREATE_TRANSACTION,
	};
}

export function resetCreateControlSeriesTransaction() {
	return {
		type: WAREHOUSES.RESET_CREATE_CONTROL_SERIES_TRANSACTION,
	};
}

export function onGetOrderDataFromUninstalledSerie(serie) {
	return {
		type: WAREHOUSES.GET_ORDER_DATA_FROM_UNINSTALLED_SERIE,
		payload: serie,
	};
}

export function doReset() {
	return {
		type: WAREHOUSES.RESET,
	};
}
