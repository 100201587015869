// Libs
import React from 'react';
import { Card } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// Hooks
import { useTheme } from 'hooks';
// Components
import { GetOrderInfo } from 'components';
// Styles
import '../../VirtualizedList.css';
import '../Orders/BillingMaterialCard';
import './SerieHistoryCard.css';

const { Meta } = Card;

export default function SerieHistoryCard(props) {
	const { theme } = useTheme();

	const {
		id,
		doc_number,
		order_id,
		odt_id,
		transaction_type_name,
		income_method_name,
		item_code,
		item_id,
		serie,
		state_name,
		warehouse_package_id,
		owarehouse_id,
		dwarehouse_id,
		owarehouse_name,
		dwarehouse_name,
		created_by,
		entity_id,
		creator_name,
		entity_name,
		completed,
		created_at,
		completed_label,
		state_props,
		customProps,
	} = props;
	const { sendToast } = customProps;
	const isInvertedWarehouses = state_props?.uninstalled;

	return (
		<Meta
			title={
				<div className='serie_history-container-title-card'>
					<div
						className={`serie_history-flag ${
							completed
								? 'serie_history-flag-completed'
								: 'serie_history-flag-pending'
						}`}
					/>
					<div className='serie_history-container-title-card-data'>
						<span
							style={{ color: theme._primaryColor }}
							className='card-creator'
						>
							{doc_number}
						</span>
						<span className='card-created-at'>
							{creator_name} - {entity_name}
						</span>
						<span className='card-created-at'>{created_at}</span>
					</div>
				</div>
			}
			description={
				<div className='serie_history-container-description-card'>
					<div className='serie_history-container-data'>
						<div className='card-container-item'>
							<div className='card-concept'>
								Código:
								<CopyToClipboard
									text={item_code}
									onCopy={() =>
										sendToast({ message: `Copiado: ${item_code}`, duration: 3 })
									}
								>
									<b className='card-value'>{item_code}</b>
								</CopyToClipboard>
							</div>
						</div>
						<div className='card-container-item'>
							<div className='card-concept'>
								Serie:
								<CopyToClipboard
									text={serie}
									onCopy={() =>
										sendToast({ message: `Copiado: ${serie}`, duration: 3 })
									}
								>
									<b className='card-value'>{serie}</b>
								</CopyToClipboard>
							</div>
						</div>
						<div className='card-container-item'>
							<div className='card-concept'>
								Estado: <b className='card-value'>{state_name}</b>
							</div>
						</div>
						<div className='card-container-item'>
							<div className='card-concept'>
								Paquete: <b className='card-value'>{warehouse_package_id}</b>
							</div>
						</div>
						<div className='card-container-item'>
							<div className='card-concept'>
								Orden:
								<b className='card-value'>
									<GetOrderInfo order_id={order_id} odt_id={odt_id} />
								</b>
							</div>
						</div>
					</div>
					<div className='serie_history-container-data'>
						<div className='card-container-item'>
							<div className='card-concept'>
								Tipo: <b className='card-value'>{transaction_type_name}</b>
							</div>
						</div>
						<div className='card-container-item'>
							<div className='card-concept'>
								Método: <b className='card-value'>{income_method_name}</b>
							</div>
						</div>
						<div className='card-container-item'>
							<div className='card-concept'>
								Origen:{' '}
								<b className='card-value'>
									{isInvertedWarehouses ? dwarehouse_name : owarehouse_name}
								</b>
							</div>
						</div>
						<div className='card-container-item'>
							<div className='card-concept'>
								Destino:{' '}
								<b className='card-value'>
									{isInvertedWarehouses ? owarehouse_name : dwarehouse_name}
								</b>
							</div>
						</div>
					</div>
				</div>
			}
		/>
	);
}
