// Libs
import React from 'react';
import { Redirect } from 'react-router-dom';
// Utils
import GENERAL from 'utils/constants/general';
// Components
import { VirtualizedList } from 'screens/Utils';
import { Wrapper, Empty } from 'components';
import {
	filterInventoryToTransferSearchInData,
	getPreview,
	onScanInventoryTransaction,
} from '../../../utils';

const { ENV } = GENERAL;

function isEmptyItems(filteredInventoryItems) {
	return filteredInventoryItems.length === 0;
}

function InventoryToTransferMobile(props) {
	const {
		transactions,
		inventoryToTransferManage,
		createTransaction,
		createPackage,
		itemStates,
		mutate1Object,
		sendToast,
	} = props;
	const serialized = [...createTransaction.serialized];
	const articles = [...createTransaction.articles];

	// Search in data
	let filteredInventoryItems = filterInventoryToTransferSearchInData(
		transactions.inventory.data,
		inventoryToTransferManage.searchInData.searchBy,
		inventoryToTransferManage.searchInData.data,
	);

	// Is Preview?
	if (createTransaction.preview) {
		filteredInventoryItems = getPreview(
			filteredInventoryItems,
			serialized,
			articles,
		);
	}

	// Scan
	React.useEffect(() => {
		if (inventoryToTransferManage.searchInData.searchBy === 'scan') {
			onScanInventoryTransaction(
				createTransaction,
				createPackage,
				inventoryToTransferManage,
				transactions,
				serialized,
				mutate1Object,
				sendToast,
			);
		}
	}, [inventoryToTransferManage.searchInData.searchBy]);

	if (
		!props.createTransaction.mode ||
		!props.createTransaction.incomeMethod ||
		!props.createTransaction.owhOwnerId ||
		!props.createTransaction.owarehouseId ||
		!props.createTransaction.dwarehouseId ||
		!props.createTransaction.signer
	) {
		return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_MOBILE} />;
	}

	return (
		<Wrapper padding='0' justifyContent='center' className='animated fadeIn'>
			{isEmptyItems(filteredInventoryItems) ? (
				<Empty margin='20px 0 0 0' />
			) : (
				<VirtualizedList
					cardId='inventoryTransactionCard'
					interpolatedRowStyle
					listStyle={{
						height: document.documentElement.clientHeight * 0.84,
						width: document.documentElement.clientWidth * 1.05,
					}}
					rowHeight={serialized.length > 0 ? 155 : 120}
					rows={filteredInventoryItems}
					customProps={{
						serialized,
						articles,
						mutate1Object,
						inventoryToTransferManage,
						itemStates,
					}}
				/>
			)}
		</Wrapper>
	);
}

export default InventoryToTransferMobile;
