import Immutable from 'immutable';

export const filterOrdersByProjects = (filteredProjects, orders) => {
	const result = Immutable.List(orders).toJS();

	if (filteredProjects.length === 0) return result;

	return result.filter(
		order => filteredProjects.indexOf(order.department_id) !== -1,
	);
};
