// Libs
import React from 'react';
// Components
import Input from '@mui/material/Input';
import { Popover, HamburguerIcon, SearchIcon } from 'components';
const Select = React.lazy(() => import('components/Select'));
import { CustomToolbar, CustomSearchToolbar } from '../sharedFunctions';
// Styles
import '../index.css';

export function ControlSeriesToolbar({
	title,
	showNavBar,
	selectNotification,
}) {
	return (
		<CustomToolbar
			selectNotification={selectNotification}
			title={title}
			left={<HamburguerIcon button onClick={showNavBar} />}
		/>
	);
}

export function SearchControlSeriesToolbar({
	mutate1ObjectInWarehouses,
	searchInData,
}) {
	const { Option } = Select;
	const SearchBy = (
		<Select
			className='select-searchBy'
			defaultValue='all'
			value={searchInData.searchBy}
			onChange={value =>
				mutate1ObjectInWarehouses('controlSeries', {
					searchInData: {
						...searchInData,
						searchBy: value,
						showPopover: false,
					},
				})
			}
		>
			<Option value='scan'>Escáner</Option>
			<Option value='serie'>Serie</Option>
			<Option value='code'>Código</Option>
			<Option value='name'>Nombre</Option>
			<Option value='warehouse_name'>Almacén</Option>
			<Option value='all'>Todos</Option>
		</Select>
	);

	function setPlaceholderSearchBy(searchBy) {
		if (searchBy === 'scan') return 'Escáner';
		else if (searchBy === 'serie') return 'Serie';
		else if (searchBy === 'code') return 'Código';
		else if (searchBy === 'name') return 'Nombre';
		else if (searchBy === 'warehouse_name') return 'Almacén';
		else return 'Todos';
	}

	return (
		<CustomSearchToolbar
			left={
				<Input
					id='toolbar-search-order-input'
					style={{ height: '100%', width: '100%' }}
					autoFocus
					name='data'
					placeholder={setPlaceholderSearchBy(searchInData.searchBy)}
					value={searchInData.data}
					// onKeyPress={(e) =>
					//   onKeyPressedSearchStockSeries(
					//     e,
					//     searchInData,
					//     history,
					//     modeView,
					//     mutate1ObjectInWarehouses,
					//     mutateDirectProps
					//   )
					// }
					onChange={e =>
						mutate1ObjectInWarehouses('controlSeries', {
							searchInData: {
								...searchInData,
								[e.target.name]: e.target.value,
							},
						})
					}
					startAdornment={
						<Popover
							content={SearchBy}
							placement='rightBottom'
							title='Buscar por'
							trigger='click'
							visible={searchInData.showPopover}
							onVisibleChange={() =>
								mutate1ObjectInWarehouses('controlSeries', {
									searchInData: {
										...searchInData,
										showPopover: !searchInData.showPopover,
									},
								})
							}
						>
							<div>
								<SearchIcon />
							</div>
						</Popover>
					}
					endAdornment={
						<CloseIcon
							button
							onClick={() =>
								mutate1ObjectInWarehouses('controlSeries', {
									searchInData: {
										...searchInData,
										renderToolbar: false,
										data: '',
										searchBy: 'all',
									},
								})
							}
						/>
					}
				/>
			}
		/>
	);
}
