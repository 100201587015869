// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.80' }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask
			id='normalBillingIcon'
			style={{ maskType: 'alpha' }}
			maskUnits='userSpaceOnUse'
			x='7'
			y='3'
			width='11'
			height='18'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.48 10.9C10.21 10.31 9.48 9.7 9.48 8.75C9.48 7.66 10.49 6.9 12.18 6.9C13.96 6.9 14.62 7.75 14.68 9H16.89C16.82 7.28 15.77 5.7 13.68 5.19V3H10.68V5.16C8.74 5.58 7.18 6.84 7.18 8.77C7.18 11.08 9.09 12.23 11.88 12.9C14.38 13.5 14.88 14.38 14.88 15.31C14.88 16 14.39 17.1 12.18 17.1C10.12 17.1 9.31 16.18 9.2 15H7C7.12 17.19 8.76 18.42 10.68 18.83V21H13.68V18.85C15.63 18.48 17.18 17.35 17.18 15.3C17.18 12.46 14.75 11.49 12.48 10.9Z'
				fill='white'
			/>
		</mask>
		<g mask='url(#normalBillingIcon)'>
			<rect width='24' height='24' fill={fill} fillOpacity={fillOpacity} />
		</g>
	</svg>
);
