// Libs
import React from "react";
// Components
import { Drawer } from "components";
import Switchable from "./Switchable";

const PhotoManager = ({
  videoSettings,
  onTakenPhotoConfirm,
  onPhotoManagerClose,
  profile,
}) => {
  // Check getUserMedia available in navigator
  if (!navigator.mediaDevices.getUserMedia) return null;

  return (
    <Drawer
      key="takePhotoV2"
      placement="bottom"
      width={`${document.documentElement.clientWidth}px`}
      height="100%"
      visible={true}
      closable={false}
      destroyOnClose={true}
    >
      <Switchable
        videoSettings={videoSettings}
        videoPreviewSettings={{
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        }}
        onTakenPhotoConfirm={onTakenPhotoConfirm}
        onPhotoManagerClose={onPhotoManagerClose}
        profile={profile}
      />
    </Drawer>
  );
};

export default PhotoManager;
