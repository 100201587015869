//  Libs
import React from 'react';
import { useTheme } from 'styled-components';
// Utils
import { filterControlSeries } from 'utils/filters/Warehouses/controlSeries';
import { xlsx } from 'utils/libs';
// Components
import {
	Checkbox,
	Wrapper,
	Button,
	Popover,
	IconButton,
	Radio,
	Dropdown,
	Span,
	TextField,
	Icon,
	CloseIcon,
	EyeIcon,
	FiltersIcon,
} from 'components';
// Styles
import '../index.css';
import { ExcelIcon } from 'components/Icons';

const MenuStatesButton = ({
	controlSeries,
	itemStates = [],
	onChangeStateIds,
}) => (
	<Wrapper highBackground flexDirection='column' alignItems='flex-start'>
		<Checkbox
			margin='5px'
			checked={controlSeries.request.states_id.length === itemStates.length}
			indeterminate={
				controlSeries.request.states_id.length > 0 &&
				controlSeries.request.states_id.length < itemStates.length
			}
			onChange={e => onChangeStateIds(e, 'all')}
		>
			Todos
		</Checkbox>
		{itemStates.map(state => (
			<Checkbox
				key={state.id}
				margin='5px'
				checked={controlSeries.request.states_id.indexOf(state.id) !== -1}
				onChange={e => onChangeStateIds(e, state.id)}
			>
				{state.name}
			</Checkbox>
		))}
	</Wrapper>
);

export default function ControlSeries({
	controlSeries,
	stock,
	itemStates,
	mutate1ObjectInWarehouses,
	mutate1ObjectApi,
	modeView,
}) {
	const theme = useTheme();

	function onChangeStateIds(e, id) {
		let states_id = [...controlSeries.request.states_id];

		if (id === 'all') {
			if (e.target.checked) {
				states_id = itemStates.reduce((acc, state) => {
					acc.push(state.id);
					return acc;
				}, []);
			} else {
				states_id = [];
			}
		} else {
			if (e.target.checked) {
				if (states_id.indexOf(id) === -1) {
					states_id.push(id);
				}
			} else {
				states_id = states_id.filter(item => item !== id);
			}
		}

		mutate1ObjectInWarehouses('controlSeries', {
			request: {
				...controlSeries.request,
				states_id,
			},
		});
	}

	function onClickButtonStates() {
		mutate1ObjectInWarehouses('controlSeries', {
			collapseActiveKey: -1,
			request: {
				...controlSeries.request,
				mode: 'states',
				states_id:
					controlSeries.request.mode === 'states'
						? controlSeries.request.states_id
						: itemStates.reduce((acc, state) => {
								acc.push(state.id);
								return acc;
						  }, []),
			},
		});
		mutate1ObjectApi('stock', {
			series: { ...stock.series, data: [] },
		});
	}

	function onChangeModeButton(e) {
		mutate1ObjectInWarehouses('controlSeries', {
			collapseActiveKey: -1,
			request: {
				...controlSeries.request,
				mode: e.target.value,
				view:
					e.target.value === 'peps' ? 'individual' : controlSeries.request.view,
			},
		});
		mutate1ObjectApi('stock', {
			series: { ...stock.series, data: [] },
		});
	}

	const MenuViewButton = (
		<Wrapper highBackground flexDirection='column'>
			<Radio.Group
				padding='5px'
				value={controlSeries.request.view}
				onChange={e => {
					mutate1ObjectInWarehouses('controlSeries', {
						collapseActiveKey: -1,
						request: { ...controlSeries.request, view: e.target.value },
					});
					mutate1ObjectApi('stock', {
						series: { ...stock.series, data: [] },
					});
				}}
			>
				<Radio.Item display='block' value='individual'>
					Técnico
				</Radio.Item>
				<Radio.Item
					display='block'
					value='entities'
					disabled={controlSeries.request.mode === 'peps'}
				>
					Entidad
				</Radio.Item>
			</Radio.Group>
		</Wrapper>
	);

	const MenuModeButtonDesktop = (
		<Wrapper flexDirection='column' alignItems='flex-start'>
			<TextField.Input
				placeholder='Escriba el usuario'
				value={controlSeries.searchInData.data}
				onChange={e => {
					mutate1ObjectInWarehouses('controlSeries', {
						collapseActiveKey: -1,
						searchInData: {
							...controlSeries.searchInData,
							data: e.target.value,
						},
					});
				}}
				suffix={
					controlSeries.searchInData.data !== '' ? (
						<Icon
							type='close'
							onClick={e => {
								mutate1ObjectInWarehouses('controlSeries', {
									searchInData: {
										...controlSeries.searchInData,
										data: '',
									},
								});
							}}
						/>
					) : (
						<Icon type='search' />
					)
				}
			/>
		</Wrapper>
	);

	const MenuModeButtonMobile = (
		<Wrapper flexDirection='column' alignItems='flex-start'>
			<TextField.Search
				placeholder='Escriba el usuario'
				onChange={e => {
					mutate1ObjectInWarehouses('controlSeries', {
						searchInData: {
							...controlSeries.searchInData,
							data: e.target.value,
						},
					});
				}}
			/>

			<Radio.Group
				value={controlSeries.request.mode}
				onChange={onChangeModeButton}
			>
				<Radio.Item value='peps'>Peps</Radio.Item>
				{/* <Radio.Item value="installed">Instaladas</Radio.Item>
        <Radio.Item value="uninstalled">Desinstaladas</Radio.Item> */}
				<Radio.Item value='states'>Estados</Radio.Item>
			</Radio.Group>
		</Wrapper>
	);

	return (
		<Wrapper
			height='48px'
			justifyContent='space-between'
			padding='10px 0 10px 10px'
			mediumBackground
		>
			{/* BACK */}
			{controlSeries.view === 'resume' && (
				<Button
					color={theme.colors.text.high}
					mode='secondary'
					fontSize='l'
					margin='0 12px 0 0'
					onClick={() =>
						mutate1ObjectInWarehouses('controlSeries', { view: 'data' })
					}
				>
					Volver
				</Button>
			)}

			{/* MODES */}
			{controlSeries.view === 'data' &&
				controlSeries.selected.items.length === 0 && (
					<Wrapper padding='0px'>
						{modeView === 'table' ? (
							<Wrapper padding='0px'>
								<Radio.Group
									value={controlSeries.request.mode}
									onChange={onChangeModeButton}
								>
									<Radio.Button value='peps'>Peps</Radio.Button>
									{/* <Radio.Button value="installed">Instaladas</Radio.Button>
                <Radio.Button value="uninstalled">Desinstaladas</Radio.Button> */}
								</Radio.Group>

								<Dropdown.Wrapper
									trigger={['click']}
									placement='bottomLeft'
									overlay={
										<MenuStatesButton
											controlSeries={controlSeries}
											itemStates={itemStates}
											onChangeStateIds={onChangeStateIds}
										/>
									}
								>
									<Button
										color={theme.colors.text.high}
										mode='secondary'
										height='32px'
										style={
											controlSeries.request.mode === 'states'
												? theme.button.secondary.focus
												: undefined
										}
										onClick={onClickButtonStates}
									>
										Estados
									</Button>
								</Dropdown.Wrapper>
							</Wrapper>
						) : controlSeries.request.mode === 'states' ? (
							<Dropdown.Wrapper
								trigger={['click']}
								placement='bottomLeft'
								overlay={
									<MenuStatesButton
										controlSeries={controlSeries}
										itemStates={itemStates}
										onChangeStateIds={onChangeStateIds}
									/>
								}
							>
								<Button
									mode='secondary'
									height='32px'
									style={
										controlSeries.request.mode === 'states'
											? theme.button.secondary.focus
											: undefined
									}
									onClick={onClickButtonStates}
								>
									Seleccione
								</Button>
							</Dropdown.Wrapper>
						) : (
							<div />
						)}
					</Wrapper>
				)}

			{controlSeries.selected.items.length > 0 && (
				<div>
					<CloseIcon
						button
						onClick={() => {
							mutate1ObjectInWarehouses('controlSeries', {
								view: 'data',
								selected: { ...controlSeries.selected, items: [] },
							});
						}}
					/>
					<Span
						fontWeight='bold'
						fontSize='xl'
						color={theme.fonts.colors.default}
					>
						Seleccionadas: {controlSeries.selected.items.length}
					</Span>
				</div>
			)}

			<Wrapper padding='0px'>
				{/* EXCEL EXPORT */}
				<ExcelIcon
					button
					onClick={() =>
						xlsx.exportControlSeriesToExcel(
							'Control de Series',
							filterControlSeries(
								stock.series.data,
								controlSeries.view,
								controlSeries.filters,
								controlSeries.selected,
							),
						)
					}
				/>

				{/* ACTIONS */}
				{controlSeries.view === 'resume' && (
					<Button
						mode='primary'
						fontSize='l'
						margin='0 12px 0 5px'
						onClick={() =>
							mutate1ObjectInWarehouses('controlSeriesTransactionModal', {
								isOpen: true,
							})
						}
					>
						Continuar
					</Button>
				)}

				{/* RESUME */}
				{controlSeries.view === 'data' &&
					controlSeries.selected.items.length > 0 && (
						<Button
							mode='primary'
							fontSize='l'
							margin='0 12px 0 5px'
							onClick={() =>
								mutate1ObjectInWarehouses('controlSeries', { view: 'resume' })
							}
						>
							Resumen
						</Button>
					)}

				{/* VIEW */}
				{controlSeries.view === 'data' &&
					controlSeries.selected.items.length === 0 && (
						<Dropdown.Wrapper
							arrow
							overlay={MenuViewButton}
							placement='bottomRight'
						>
							<div>
								<EyeIcon button />
							</div>
						</Dropdown.Wrapper>
					)}

				{/* FILTER */}
				{modeView === 'table' &&
					controlSeries.view === 'data' &&
					controlSeries.selected.items.length === 0 && (
						<Popover content={MenuModeButtonDesktop} placement='bottomRight'>
							<div>
								<FiltersIcon button />
							</div>
						</Popover>
					)}

				{/* FILTER */}
				{modeView === 'cards' &&
					controlSeries.view === 'data' &&
					controlSeries.selected.items.length === 0 && (
						<Popover
							content={MenuModeButtonMobile}
							placement='bottomRight'
							style={{}}
						>
							<div>
								<FiltersIcon button />
							</div>
						</Popover>
					)}
			</Wrapper>
		</Wrapper>
	);
}
