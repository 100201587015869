// Libs
import React from 'react';
import { connect } from 'react-redux';
// Utils
import FEED_KEYWORDS from 'core/keywords';
// Selectors
import {
	selectDatasourceStatus,
	selectSelectedDataFilterLastRowId,
	selectDatasourcePaging,
	selectDatasourceData,
} from 'core/selectors';
// Components
import { Wrapper, LogoIcon, Subtitle } from 'components';
import { EyeIcon } from 'components/Icons';

function ShowedPagination({
	// State
	datasource,
	datasourceStatus,
	dataFilterLastRowId,
	datasourcePaging,
}) {
	const isLoadingData =
		datasourceStatus === FEED_KEYWORDS.STATUS.LOADING &&
		dataFilterLastRowId === 0;

	return (
		<Wrapper padding='0' margin='0 8px'>
			{isLoadingData ? (
				<LogoIcon margin='0 0 0 5px' spin={true} size='s' />
			) : datasource.length ? (
				<Wrapper padding='0' width='100px'>
					<EyeIcon />
					<Subtitle margin='0 0 0 5px'>
						{datasource.length} de {datasourcePaging.totalRowCount}
					</Subtitle>
				</Wrapper>
			) : null}
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	datasource: selectDatasourceData(state),
	datasourceStatus: selectDatasourceStatus(state),
	dataFilterLastRowId: selectSelectedDataFilterLastRowId(state),
	datasourcePaging: selectDatasourcePaging(state),
});
export default connect(mapStateToProps)(ShowedPagination);
