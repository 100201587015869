import Immutable from 'immutable';
import _ from 'underscore';
import { filterDataByFilterOptions } from '../';

function filterPayments(payments, filters) {
	let filteredPayments = Immutable.List(payments).toJS();

	filteredPayments = filterDataByFilterOptions(filteredPayments, filters);
	return filteredPayments;
}

function getSelectedPayments(payments, selectedPaymentsIds) {
	if (!Array.isArray(payments) || !Array.isArray(selectedPaymentsIds))
		return [];

	return selectedPaymentsIds.reduce((acc, paymentId) => {
		const idx = _.findIndex(payments, payment => {
			const payIds = Array.isArray(payment.id) ? [...payment.id] : [payment.id];
			return payIds.indexOf(paymentId) !== -1;
		});
		if (idx !== -1) acc.push(payments[idx]);
		return acc;
	}, []);
}

export { filterPayments, getSelectedPayments };
