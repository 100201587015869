// Libs
import React from 'react';
// Utils
import GENERAL from 'utils/constants/general';
// Components
import { Wrapper, Span, Button, TextField, Icon } from 'components';
import Select from 'components/Select';
import { PhoneOutlined } from '@ant-design/icons';

const { ENV } = GENERAL;
const { Option } = Select;
const { Input } = TextField;

const FORM_ELEMENTS = {
	IDC: 'idc',
	NAME: 'name',
	PREF1: 'pref1',
	PHONE1: 'phone1',
	PREF2: 'pref2',
	PHONE2: 'phone2',
	SUSCRIPTION_NUMBER: 'suscriptionNumber',
	CATEGORY_ID: 'categoryId',
};

const initialState = {
	[FORM_ELEMENTS.IDC]: {
		value: undefined,
		required: true,
	},
	[FORM_ELEMENTS.NAME]: {
		value: undefined,
		required: true,
	},
	[FORM_ELEMENTS.PREF1]: {
		value: undefined,
		required: true,
	},
	[FORM_ELEMENTS.PHONE1]: {
		value: undefined,
		required: true,
	},
	[FORM_ELEMENTS.PREF2]: {
		value: undefined,
		required: false,
	},
	[FORM_ELEMENTS.PHONE2]: {
		value: undefined,
		required: false,
	},
	[FORM_ELEMENTS.SUSCRIPTION_NUMBER]: {
		value: undefined,
		required: false,
	},
	[FORM_ELEMENTS.CATEGORY_ID]: {
		value: undefined,
		required: false,
	},
};

function CreateClient({
	// Props
	clientCategories,
	myChangeState,
	createClient,
	// Actions
	onCreateClient,
	mutate1ObjectInClients,
}) {
	const [clientInfo, setClientInfo] = React.useState(initialState);

	const changeClientInfo = newState =>
		setClientInfo({ ...clientInfo, ...newState });

	const onChange = (key, value) =>
		changeClientInfo({ [key]: { ...clientInfo[key], value } });

	React.useEffect(() => {
		if (createClient.status === ENV.STATUS.SUCCESS) {
			myChangeState({ activeForm: ENV.ORDERS.CREATE.TABS.CREATE_ORDER });
			mutate1ObjectInClients('createClient', { status: ENV.STATUS.INITIAL });
		}
	}, [createClient.status]);

	const isRequiredDataReady = React.useMemo(
		() =>
			!Object.keys(clientInfo).some(
				key =>
					clientInfo[key].required &&
					(clientInfo[key].value === undefined ||
						clientInfo[key].value === null ||
						clientInfo[key].value === ''),
			),
		[clientInfo],
	);

	const buildFormData = () => {
		const phones = [];
		let form = {
			[FORM_ELEMENTS.IDC]: clientInfo[FORM_ELEMENTS.IDC].value,
			[FORM_ELEMENTS.NAME]: clientInfo[FORM_ELEMENTS.NAME].value,
			[FORM_ELEMENTS.SUSCRIPTION_NUMBER]:
				clientInfo[FORM_ELEMENTS.SUSCRIPTION_NUMBER].value,
			[FORM_ELEMENTS.CATEGORY_ID]: clientInfo[FORM_ELEMENTS.CATEGORY_ID].value,
		};

		form = Object.keys(form).reduce((acc, key) => {
			if (form[key] !== undefined && form[key] !== null && form[key] !== '')
				acc[key] = form[key];
			return acc;
		}, {});

		clientInfo[FORM_ELEMENTS.PHONE1].value &&
			phones.push(
				`${clientInfo[FORM_ELEMENTS.PREF1].value}${
					clientInfo[FORM_ELEMENTS.PHONE1].value
				}`,
			);

		clientInfo[FORM_ELEMENTS.PHONE2].value &&
			phones.push(
				`${clientInfo[FORM_ELEMENTS.PREF2].value}${
					clientInfo[FORM_ELEMENTS.PHONE2].value
				}`,
			);
		if (phones.length > 0) form.phones = phones;
		return form;
	};

	const selectCountryCode1 = (
		<Select
			width='65px'
			value={clientInfo[FORM_ELEMENTS.PREF1].value}
			onChange={value => onChange(FORM_ELEMENTS.PREF1, value)}
		>
			<Option value='+52'>+52</Option>
			<Option value='+504'>+504</Option>
			<Option value='+506'>+506</Option>
			<Option value='+507'>+507</Option>
		</Select>
	);

	const selectCountryCode2 = (
		<Select
			width='65px'
			value={clientInfo[FORM_ELEMENTS.PREF2].value}
			onChange={value => onChange(FORM_ELEMENTS.PREF2, value)}
		>
			<Option value='+52'>+52</Option>
			<Option value='+504'>+504</Option>
			<Option value='+506'>+506</Option>
			<Option value='+507'>+507</Option>
		</Select>
	);

	return (
		<Wrapper flexDirection='column' overflow='auto' alignItems='space-between'>
			<Span required={clientInfo[FORM_ELEMENTS.CATEGORY_ID].required}>
				Categoría de Cliente
			</Span>
			<Select
				margin='0 0 10px 0'
				value={clientInfo[FORM_ELEMENTS.CATEGORY_ID].value}
				onChange={value => onChange(FORM_ELEMENTS.CATEGORY_ID, value)}
				placeholder='Seleccione una Categoría'
			>
				{clientCategories?.data.map(category => (
					<Option key={category.id} value={category.id}>
						{category.name}
					</Option>
				))}
			</Select>

			<Span required={clientInfo[FORM_ELEMENTS.NAME].required}>
				Nombre Completo
			</Span>
			<Input
				maxLength={50}
				margin='0 0 10px 0'
				placeholder='Ingrese Nombre del Cliente'
				value={clientInfo[FORM_ELEMENTS.NAME].value}
				onChange={e => onChange(FORM_ELEMENTS.NAME, e.target.value)}
			/>

			<Span required={clientInfo[FORM_ELEMENTS.IDC].required}>Identidad</Span>
			<Input
				maxLength={25}
				margin='0 0 10px 0'
				placeholder='Ingrese Tarjeta de Identificación'
				value={clientInfo[FORM_ELEMENTS.IDC].value}
				onChange={e => onChange(FORM_ELEMENTS.IDC, e.target.value)}
			/>

			<Span required={clientInfo[FORM_ELEMENTS.SUSCRIPTION_NUMBER].required}>
				Número de Suscripción
			</Span>
			<Input
				maxLength={25}
				margin='0 0 10px 0'
				placeholder='¿Ya posee un número de suscripción?'
				value={clientInfo[FORM_ELEMENTS.SUSCRIPTION_NUMBER].value}
				onChange={e =>
					onChange(FORM_ELEMENTS.SUSCRIPTION_NUMBER, e.target.value)
				}
			/>

			<Span required={clientInfo[FORM_ELEMENTS.PHONE1].required}>
				Teléfono 1
			</Span>
			<Input
				maxLength={20}
				type='number'
				placeholder='Ingrese el Número'
				addonBefore={selectCountryCode1}
				addonAfter={<PhoneOutlined />}
				value={clientInfo[FORM_ELEMENTS.PHONE1].value}
				onChange={e => {
					const value = e.target.value;
					if (value !== clientInfo[FORM_ELEMENTS.PHONE2].value)
						onChange(FORM_ELEMENTS.PHONE1, value);
				}}
			/>

			{clientInfo[FORM_ELEMENTS.PHONE1].value && (
				<>
					<Span
						required={clientInfo[FORM_ELEMENTS.PHONE2].required}
						margin='10px 0 0 0'
					>
						Teléfono 2
					</Span>
					<Input
						maxLength={20}
						type='number'
						placeholder='Opcional'
						addonBefore={selectCountryCode2}
						addonAfter={<PhoneOutlined />}
						value={clientInfo[FORM_ELEMENTS.PHONE2].value}
						onChange={e => {
							const value = e.target.value;
							if (value !== clientInfo[FORM_ELEMENTS.PHONE1].value)
								onChange(FORM_ELEMENTS.PHONE2, value);
						}}
					/>
				</>
			)}

			{/* CREATE CLIENT */}
			{createClient.status === ENV.STATUS.LOADING ? (
				<Icon
					margin='20px 0 0 0'
					type='loading'
					className='style-toolbar-buttons'
				/>
			) : (
				<>
					<Button
						mode='primary'
						margin='20px 0 0 0'
						disabled={!isRequiredDataReady}
						onClick={() => onCreateClient(buildFormData())}
					>
						Registrar Cliente
					</Button>

					<Button
						margin='10px 0 0 0'
						onClick={() =>
							myChangeState({ activeForm: ENV.ORDERS.CREATE.TABS.CREATE_ORDER })
						}
					>
						Cancelar
					</Button>
				</>
			)}
		</Wrapper>
	);
}

export default CreateClient;
