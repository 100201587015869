// Libs
import React, { useContext } from 'react';
import cx from 'classnames';
// Hooks
import { useHistory } from 'react-router-dom';
import useTheme from 'hooks/useTheme';
// Utils
import GENERAL from 'utils/constants/general';
// Context
import {
	ModalBuilderContext,
	// ElementViewBuilderContext
} from 'core/context';
// Config
import ModalBuilderConfig from 'core/ModalBuilder/modal.config';
// import { ElementViewBuilderConfig } from "core/ElementViewBuilder";
// Components
import ActionsBox from '../ActionsBox';
import ImageGrid from '../ImageGrid';
import { PostInfo, UserComment } from '../Shared';
// Style
import styles from '../Supervisor.module.css';

const { RESOURCES } = GENERAL.ENV;

const ListV1 = ({
	record,
	isMobile,
	//  isNearScreen
}) => {
	const { theme } = useTheme();
	const { setModal } = useContext(ModalBuilderContext);
	// const { setElementView } = useContext(ElementViewBuilderContext);
	const history = useHistory(); // TODO: Deprecated:Quitar cuando se termine de actualizar el módulo de supervisor (ElementViewBuilder)

	const [reactionHandler, setReactionHandler] = React.useState({
		reactions: [],
		setted: false,
	});

	const modalKeys = ModalBuilderConfig.getAuthorizedModalKeys();
	// const elementViewKeys =
	//   ElementViewBuilderConfig.getAuthorizedElementViewKeys();

	const handleOnClickCommentsButton = () =>
		setModal(modalKeys.orderExpandedModal, {
			selectedOTDId: record.id,
		});

	const handleOnClickReportsButton = () =>
		setModal(modalKeys.orderReportsModal, {
			selectedOTDId: record.id,
			selectedODTId: record.odtId,
		});

	// if (!isNearScreen) return null; //Lazy loading

	return (
		<div className={cx(styles.postWrapperCard, theme.secondaryBackgroundColor)}>
			<div
				className={
					!isMobile
						? styles.galleryWrapperCardDesktop
						: styles.galleryWrapperCardMobile
				}
			>
				<ImageGrid
					sources={record.images}
					totalCount={record.totalImageCount}
					cardView
					onClick={() =>
						history.push(`${history.location.pathname}/${record.id}`)
					}
					// onClick={() =>
					//   setElementView({
					//     elementViewKey: elementViewKeys.SUPERVISOR_ELEMENT,
					//     elementId: record.id,
					//   })
					// }
				/>
			</div>
			<div className={styles.infoWrapper}>
				<div className={styles.top}>
					<PostInfo record={record} />
					<UserComment text={record.userComment} />
				</div>
				<ActionsBox
					resourceKey={RESOURCES.KEYS.PHOTO_REPORT}
					resourceId={record.id}
					reportCount={record.totalReportCount}
					commentCount={record.totalCommentCount}
					onClickCommentsButton={handleOnClickCommentsButton}
					onClickReportsButton={handleOnClickReportsButton}
					externalReactionHandler={{
						extReactions: reactionHandler.reactions,
						extSetted: reactionHandler.setted,
						setExtReactions: setReactionHandler,
					}}
				/>
			</div>
		</div>
	);
};

export default ListV1;
