// Libs
import styled from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { TimePicker as _TimePicker } from 'antd';

const StyledTimePicker = styled(_TimePicker)`
	margin: ${props => props.margin ?? props.theme.timepicker.margin};
	&.ant-input {
		color: ${props => props.color ?? props.theme.fonts.colors.default};
		background-color: ${props =>
			props.backgroundColor ?? props.theme.timepicker.backgroundColor};
	}
	& {
		background: transparent;
	}
`;

const TimePicker = props => {
	const theme = useTheme();
	return (
		<StyledTimePicker
			{...props}
			$newTheme={theme}
			popupStyle={{
				background: 'black',
			}}
		/>
	);
};

export default TimePicker;
