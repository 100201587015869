// Libs
import React from 'react';
// Components
import { Wrapper, Button, Span, Divider, LogoIcon } from 'components';

const TakenPhotoConfirmation = ({
	height,
	confirming,
	onTakenPhotoCancel,
	onTakenPhotoConfirm,
}) => (
	<Wrapper
		width='100%'
		height={`${height}px`}
		justifyContent='space-around'
		position='absolute'
		bottom='0'
		right='0'
		backgroundColor='rgb(0,0,0)'
	>
		<Button mode='secondary' onClick={onTakenPhotoCancel}>
			<Span color='#e1e1e1'>Cancelar</Span>
		</Button>
		<Divider type='vertical' />
		<Button mode='primary' onClick={onTakenPhotoConfirm}>
			{confirming ? (
				<LogoIcon spin={true} />
			) : (
				<Span color='#fff'>Confirmar</Span>
			)}
		</Button>
	</Wrapper>
);

export default TakenPhotoConfirmation;
