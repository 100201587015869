// Libs
import styled from 'styled-components';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Button } from 'antd';

const StyledButton = styled(Button)`
	&:hover {
		background-color: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
	}
`;

export default ({ children, ...props }) => {
	const { theme } = useTheme();
	return (
		<StyledButton {...props} $newTheme={theme}>
			{children}
		</StyledButton>
	);
};
