// Libs
import { takeLatest, put, call, all } from 'redux-saga/effects';
import axios from 'axios';
// Utils
import AuthService from '../../utils/libs/auth/AuthService';
import GENERAL from '../../utils/constants/general';
import API from '../../utils/constants/api';
import asyncErrorsHandler from '../../store/asyncErrorsHandler';

const { REPORTS, DASHBOARD } = GENERAL;
const auth = new AuthService();

// Watchers
function* fetchReportsWatcher() {
	yield takeLatest(REPORTS.FETCH_REPORTS, fetchReportsWorker);
}
function* requestGenerateReportWatcher() {
	yield takeLatest(
		REPORTS.REQUEST_GENERATE_REPORT,
		requestGenerateReportWorker,
	);
}

// Workers
function* fetchReportsWorker() {
	try {
		yield put({
			type: REPORTS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'control',
				keyValuePairs: { isFetching: true },
			},
		});

		const { data } = yield call(
			axios.get,
			API.DOMAIN.concat('/reports'),
			auth.sendToken(),
		);
		yield put({
			type: REPORTS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'control',
				keyValuePairs: { isFetching: false, reports: data },
			},
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: REPORTS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'control',
						keyValuePairs: { isFetching: false },
					},
				});
			},
			function* () {
				yield fetchReportsWorker();
			},
		);
	}
}

function* requestGenerateReportWorker(action) {
	const body = action.payload;

	try {
		yield put({
			type: REPORTS.MUTATE_1OBJECT,
			payload: {
				obj1Name: 'generateReportModal',
				keyValuePairs: { isFetching: true },
			},
		});

		yield call(
			axios.post,
			API.DOMAIN.concat('/reports/generateReport'),
			body,
			auth.sendToken(),
		);
		yield put({
			type: REPORTS.RESET_GENERATE_REPORT_MODAL,
		});
		yield put({
			type: DASHBOARD.TOAST_MESSAGE,
			payload: { message: 'Reporte en camino!', type: 'success' },
		});
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: REPORTS.MUTATE_1OBJECT,
					payload: {
						obj1Name: 'generateReportModal',
						keyValuePairs: { isFetching: false },
					},
				});
			},
			function* () {
				yield requestGenerateReportWorker(action);
			},
		);
	}
}

//  Export default Root Saga
export default function* rootSaga() {
	yield all([fetchReportsWatcher(), requestGenerateReportWatcher()]);
}
