import _ from 'underscore';
import GENERAL from 'utils/constants/general';
import { xlsx } from 'utils/libs';

const { ENV } = GENERAL;

export function onKeyPressedSearchStockSeries(
	e,
	searchInData,
	history,
	modeView,
	mutate1ObjectInWarehouses,
	mutateDirectPropsInDashboard,
) {
	const { data, searchBy } = searchInData;

	if (e.key === 'Enter' && searchBy === 'scan' && data && data !== '') {
		mutate1ObjectInWarehouses('stockSeriesManage', {
			searchInData: { ...searchInData, pressEnter: true },
		});
		mutateDirectPropsInDashboard({ isOpen: false });
		if (modeView === 'cards')
			history.push(ENV.ROUTES.PATHS.WAREHOUSES_STOCK_SERIES_MOBILE);
		else if (modeView === 'table')
			history.push(ENV.ROUTES.PATHS.WAREHOUSES_STOCK_SERIES_DESKTOP);
	}
}

export function onScanStockSeries(
	searchInData,
	getSearchingSeries,
	mutate1Object,
) {
	const { data, pressEnter } = searchInData;
	const series = [];

	if (pressEnter && data && data !== '') {
		if (data.includes(',')) {
			data.split(',').map(serie => {
				if (serie && serie !== '') series.push(serie.trim());
			});
		} else series.push(data.trim());

		mutate1Object('stockSeriesManage', {
			searchInData: { ...searchInData, pressEnter: false },
		});
		if (series.length > 0) getSearchingSeries(series);
	}
}

export function startCompareSeries(
	scannedSeries,
	data,
	mutate1ObjectInWarehouses,
	sendToast,
) {
	const externalSeries = [];
	const scannedSeriesInconsistencies = [];
	const externalSeriesInconsistencies = [];

	if (data && data !== '') {
		mutate1ObjectInWarehouses('inventoryToTransferManage', { comparing: true });

		// Build externalSeries array
		if (data.includes(',')) {
			data.split(',').map(serie => {
				if (serie && serie !== '') externalSeries.push(serie.trim());
			});
		} else externalSeries.push(data.trim());

		// Comparing scanned series
		for (const scanSerie of scannedSeries) {
			const idx = _.findIndex(
				externalSeries,
				extSerie => extSerie === scanSerie.serie,
			);
			if (idx === -1)
				scannedSeriesInconsistencies.push({ serie: scanSerie.serie });
		}

		// Comparing external series
		for (const extSerie of externalSeries) {
			const idx = _.findIndex(
				scannedSeries,
				scanSerie => scanSerie.serie === extSerie,
			);
			if (idx === -1) externalSeriesInconsistencies.push({ serie: extSerie });
		}

		if (
			scannedSeriesInconsistencies.length === 0 &&
			externalSeriesInconsistencies.length === 0
		)
			sendToast({ message: 'Todo correcto!', type: 'success', duration: 2 });
		else
			sendToast({
				message: 'Se encontraron inconsistencias. Haz click aquí para verlas',
				type: 'warn',
				duration: 20,
				onClick: () =>
					xlsx.exportMultiSheetToExcel('Inconsistencias', [
						{
							name: 'Escaneadas',
							data: scannedSeriesInconsistencies,
						},
						{
							name: 'Externas',
							data: externalSeriesInconsistencies,
						},
					]),
			});

		mutate1ObjectInWarehouses('inventoryToTransferManage', {
			comparing: false,
			compareSeries: undefined,
		});
	}
}

export function filterControlSeriesSearchInData(userWarehouses, data) {
	if (data === '') return userWarehouses;
	return userWarehouses.filter(user =>
		user.name.toUpperCase().includes(data.toUpperCase()),
	);
}

export function filterInventoryToTransferSearchInData(
	inventory,
	searchBy,
	data,
) {
	switch (searchBy) {
		case 'code':
			return inventory.filter(
				item =>
					item.item_code &&
					String(item.item_code).toUpperCase().includes(data.toUpperCase()),
			);
		case 'name':
			return inventory.filter(
				item =>
					item.item_name &&
					String(item.item_name).toUpperCase().includes(data.toUpperCase()),
			);
		case 'serie':
		case 'scan':
			return inventory.filter(
				item =>
					item.serie &&
					String(item.serie).toUpperCase().includes(data.toUpperCase()),
			);
		case 'all':
			return inventory.filter(
				item =>
					(item.item_code &&
						String(item.item_code)
							.toUpperCase()
							.includes(data.toUpperCase())) ||
					(item.item_name &&
						String(item.item_name)
							.toUpperCase()
							.includes(data.toUpperCase())) ||
					(item.serie &&
						String(item.serie).toUpperCase().includes(data.toUpperCase())),
			);
	}
}

//  Functions
export function getPreview(inventory, serialized, articles) {
	return inventory.filter(item => {
		if (item.is_serialized) return isSerieInTransaction(serialized, item.serie);
		else return isArticleInTransaction(articles, item.item_id);
	});
}

export function isArticleInTransaction(articles, item_id) {
	const idx = _.findIndex(articles, row => row.item_id === item_id);
	return idx !== -1;
}

export function isSerieInTransaction(serialized, serie) {
	const idx = _.findIndex(serialized, row => row.serie === serie);
	return idx !== -1;
}

function scanMultiSeries(
	inventoryToTransferManage,
	transactions,
	serialized,
	mutate1Object,
	sendToast,
	packageId,
) {
	const { searchInData } = inventoryToTransferManage;
	const series = searchInData.data.split(',');
	let added = 0;
	let omitted = 0;
	let notFound = 0;
	const seriesNotFound = [];

	series.map(serie => {
		if (serie && serie !== '') {
			serie = serie.trim();

			const serieInventory = isSerieInInventory(transactions, serie);
			// Not exists in inventory
			if (!serieInventory) {
				notFound++;
				seriesNotFound.push({ serie });
			}
			// Exists in inventory
			else {
				// Exist in transaction
				if (isSerieInTransaction(serialized, serie)) omitted++;
				// Not exists in transaction
				else {
					onAddSerie(
						serieInventory.item_id,
						serieInventory.serie,
						serieInventory.state_id,
						packageId,
						serialized,
						mutate1Object,
					);
					added++;
				}
			}
		}
	});
	sendToast({
		message: `Añadidas: ${added} - Omitidas: ${omitted} - No encontradas: ${notFound}`,
		duration: notFound === 0 ? 3 : 20,
		type: notFound === 0 ? 'success' : 'warn',
		onClick: () => {
			if (notFound > 0)
				xlsx.exportMultiSheetToExcel('seriesNotFound', [
					{
						name: 'No encontradas',
						data: seriesNotFound,
					},
				]);
		},
	});
	mutate1Object('inventoryToTransferManage', {
		searchInData: { ...searchInData, data: '' },
	});
}

function scanEnterPressed(
	serie,
	inventoryToTransferManage,
	serialized,
	mutate1Object,
	sendToast,
	packageId,
	transactions,
) {
	if (serie && serie !== '') {
		serie = serie.trim();

		const serieInventory = isSerieInInventory(transactions, serie);
		// Not exists in inventory
		if (!serieInventory)
			sendToast({ message: `Serie no encontrada`, duration: 2, type: 'warn' });
		// Exists in inventory
		else {
			// Exist in transaction
			if (isSerieInTransaction(serialized, serie))
				sendToast({ message: `Serie omitida`, duration: 2, type: 'info' });
			// Not exists in transaction
			else {
				onAddSerie(
					serieInventory.item_id,
					serieInventory.serie,
					serieInventory.state_id,
					packageId,
					serialized,
					mutate1Object,
				);
				sendToast({ message: `Serie añadida!`, duration: 1, type: 'success' });
			}
		}
	}
	mutate1Object('inventoryToTransferManage', {
		searchInData: {
			...inventoryToTransferManage.searchInData,
			data: '',
		},
	});
}

export function onScanInventoryTransaction(
	createTransaction,
	createPackage,
	inventoryToTransferManage,
	transactions,
	serialized,
	mutate1Object,
	sendToast,
	onGetOrderDataFromUninstalledSerie,
	serie, // Optional
) {
	const { mode, notFoundSerialized } = createTransaction;
	const { searchInData } = inventoryToTransferManage;
	if (searchInData?.searchBy === 'scan') {
		if (mode === 'trsReceive') {
			if (serie) {
				// SINGLE-SERIE
				scanEnterPressed(
					serie,
					inventoryToTransferManage,
					serialized,
					mutate1Object,
					sendToast,
					createPackage.id,
					transactions,
				);
				const serieInventory = isSerieInInventory(transactions, serie);
				if (serieInventory?.state_props?.showOrder) {
					onGetOrderDataFromUninstalledSerie(serie);
				}
			} else if (
				createPackage.created &&
				createPackage.serialized &&
				searchInData.data !== ''
			) {
				// MULTI-SERIES
				if (searchInData.data.includes(',')) {
					scanMultiSeries(
						inventoryToTransferManage,
						transactions,
						serialized,
						mutate1Object,
						sendToast,
						createPackage.id,
					);
				} else {
					// SINGLE-SERIE
					scanEnterPressed(
						searchInData.data,
						inventoryToTransferManage,
						serialized,
						mutate1Object,
						sendToast,
						createPackage.id,
						transactions,
					);
					const serieInventory = isSerieInInventory(
						transactions,
						searchInData.data,
					);
					if (!serieInventory) {
						notFoundSerialized.push({ serie: searchInData.data.trim() });
						mutate1Object('createTransaction', { notFoundSerialized });
					} else if (serieInventory?.state_props?.showOrder) {
						onGetOrderDataFromUninstalledSerie(searchInData.data);
					}
				}
			}
		} else if (mode === 'trsSend') {
			// MULTI-SERIES
			if (searchInData.data.includes(',')) {
				scanMultiSeries(
					inventoryToTransferManage,
					transactions,
					serialized,
					mutate1Object,
					sendToast,
					undefined,
				);
			} else {
				// SINGLE-SERIE
				scanEnterPressed(
					searchInData.data,
					inventoryToTransferManage,
					serialized,
					mutate1Object,
					sendToast,
					undefined,
					transactions,
				);
			}
		}
	}
}

function isSerieInInventory(transactions, serie) {
	const idx = _.findIndex(
		transactions.inventory.data,
		row => row.serie === serie,
	);
	if (idx === -1) return null;
	return transactions.inventory.data[idx];
}

export function onAddSerie(
	item_id,
	serie,
	state_id,
	warehouse_package_id,
	serialized,
	mutate1Object,
) {
	serialized.unshift({
		item_id,
		serie,
		state_id,
		warehouse_package_id,
	});
	mutate1Object('createTransaction', { serialized });
}

export function onDeleteSerie(
	serie,
	inventoryToTransferManage,
	serialized,
	mutate1Object,
) {
	mutate1Object('inventoryToTransferManage', {
		searchInData: { ...inventoryToTransferManage.searchInData, data: '' },
	});
	mutate1Object('createTransaction', {
		serialized: serialized.filter(row => row.serie !== serie),
	});
}

export function getArea(warehouse_package_id, packages, areas) {
	const packageIdx = _.findIndex(
		packages,
		pkg => pkg.id === warehouse_package_id,
	);
	let areaIdx = -1;

	if (packageIdx !== -1)
		areaIdx = _.findIndex(
			areas,
			area => area.id === packages[packageIdx].warehouse_area_id,
		);

	return areaIdx !== -1 ? areas[areaIdx].name : undefined;
}

export function getZone(warehouse_package_id, packages, areas, zones) {
	const packageIdx = _.findIndex(
		packages,
		pkg => pkg.id === warehouse_package_id,
	);
	let areaIdx = -1;
	let zoneIdx = -1;

	if (packageIdx !== -1)
		areaIdx = _.findIndex(
			areas,
			area => area.id === packages[packageIdx].warehouse_area_id,
		);
	if (areaIdx !== -1)
		zoneIdx = _.findIndex(
			zones,
			zone => zone.id === areas[areaIdx].warehouse_zone_id,
		);

	return zoneIdx !== -1 ? zones[zoneIdx].name : undefined;
}

export function filterReconditionedSeries(series, filter) {
	if (filter === 'all') return series;
	if (filter === 'new')
		return series.filter(
			serie => !serie.item_props || !serie.item_props.reconditioned,
		);
	if (filter === 'reconditioned')
		return series.filter(
			serie => serie.item_props && serie.item_props.reconditioned,
		);
}

export function setFifoFlag(series) {
	let counter = {};
	const newInventory = filterReconditionedSeries(series, 'new').map(serie => {
		if (serie.fifo_amount === null) serie.fifoOk = true;
		else {
			if (!counter[serie.family_id]) counter[serie.family_id] = 1;
			else counter[serie.family_id]++;

			if (counter[serie.family_id] <= serie.fifo_amount) serie.fifoOk = true;
			serie.position = counter[serie.family_id];
		}
		return serie;
	});

	counter = {};
	const reconditionedInventory = filterReconditionedSeries(
		series,
		'reconditioned',
	).map(serie => {
		if (serie.fifo_amount === null) serie.fifoOk = true;
		else {
			if (!counter[serie.family_id]) counter[serie.family_id] = 1;
			else counter[serie.family_id]++;

			if (counter[serie.family_id] <= serie.fifo_amount) serie.fifoOk = true;
			serie.position = counter[serie.family_id];
		}
		return serie;
	});

	return newInventory.concat(reconditionedInventory);
}
