import Pica from 'pica';
import Raven from 'raven-js';
const pica = Pica();

function scaleImage(img, maxDim) {
	const width = img.naturalWidth || img.width;
	const height = img.naturalHeight || img.height;
	const scale = maxDim / Math.max(width, height);

	return {
		width: maxDim,
		height: scale * height,
		// width: width,
		// height: height
	};
}

function blobToBase64(blob) {
	return new Promise((resolve, reject) => {
		try {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				const base64Data = reader.result;
				resolve(base64Data);
			});
			reader.readAsDataURL(blob);
		} catch (err) {
			Raven.captureException(err);
			reject(null);
		}
	});
}

export function photoCompress(img, maxDim, compressFormat, quality) {
	const toDims = scaleImage(img, maxDim);
	const cvs = document.createElement('canvas');

	cvs.width = toDims.width;
	cvs.height = toDims.height;

	return pica
		.resize(img, cvs, {
			quality: 3,
			unsharpAmount: 80,
			unsharpRadius: 0.6,
			unsharpThreshold: 2,
		})
		.then(result => pica.toBlob(result, compressFormat, quality))
		.then(blob => blobToBase64(blob))
		.then(base64Data => base64Data)
		.catch(err => {
			Raven.captureException(err);
			return null;
		});
}

export function dataURItoBlob(dataURI, mimeType) {
	// convert base64/URLEncoded data component to raw binary data held in a string
	let byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0) {
		byteString = atob(dataURI.split(',')[1]);
	} else {
		byteString = unescape(dataURI.split(',')[1]);
	}

	// write the bytes of the string to a typed array
	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeType });
}
